<template>
  <!--modal boilerplate-->
  <mdb-modal
    id=""
    :show="addCompetencyModalShowing"
    size="md"
    style="overflow-y:auto;"
    @close="$emit('addCompetencyModalShowing', false)"
  >
    <mdb-modal-header>
      <mdb-modal-title>ADD COMPETENCE</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body class="px-4">
      <div class="d-flex align-items-center mt-4" style="height:60px">
        <i class="mr-3 fas fa-signature" style="font-size: 1.3rem;" />
        <mdb-input
          v-model="competencyName"
          type="text"
          auto-complete="jbt"
          label="Name"
          style="width:100%"
        />
      </div>
      <div class="d-flex align-items-start mt-3">
        <i class="mr-3 fas fa-signature mt-4" style="font-size: 1.3rem;" />
        <mdb-input
          v-model="competencyDescription"
          type="textarea"
          auto-complete="jbt"
          :rows="8"
          label="Description"
          style="width:100%"
        />
      </div>
    </mdb-modal-body>
    <mdb-modal-footer>
      <mdb-btn
        :disabled="competencyDescription == 0 || competencyName == ''"
        size="sm"
        style="width:fit-content;"
        class="m-0 btn font-weight-bold text-nowrap"
        @click="addCompetence()"
      >
        ADD
      </mdb-btn>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
export default {
  name: 'AddCompetenceModal',
  props: {
    addCompetencyModalShowing: Boolean
  },
  data () {
    return {
      competencyDescription: '',
      competencyName: ''
    }
  },
  watch: {
    addCompetencyModalShowing () {
      if (!this.addCompetencyModalShowing) {
        this.competencyName = ''
        this.competencyDescription = ''
      }
    }
  },
  methods: {
    addCompetence () {
      this.competencyDescription = this.competencyDescription.replace(/(?:\r\n|\r|\n)/g, '<br>')
      this.$emit('addingCompetence', {
        CompetencyName: this.competencyName,
        CompetencyDescription: this.competencyDescription,
        Elements: []
      })
    }
  }
}
</script>
