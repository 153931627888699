import Vue from 'vue'
import Router from 'vue-router'

import layout from '../src/views/layout'
import account from '../src/views/account/account'
import admin from '../src/views/admin/admin'
import candidates from '../src/views/candidates/candidates'
import forgottenPassword from './components/forgottenPassword'
import notFound from '../src/views/notfound.vue'
import setPassword from '../src/views/setPassword'
import signIn from './components/signIn'
import signUp from './views/signUp.vue'
import videolibrary from '../src/views/videolibrary/videolibrary'
import reports from './views/reports/reports'
import notifications from './views/notifications/notifications'
import meetNow from './views/meetnow/meetNow'
import shineIntros from './views/shineIntros/shineIntros'
import help from './views/help/container.vue'
import roleV2Router from './views/roles/rolesRouting'
import clientPortal from './views/clientPortal/clientPortalContainer.vue'
import myAccounts from './views/myaccounts/myaccounts.vue'
import settingsRouting from '@/views/settings/settingsRouting'

Vue.use(Router)

const router = new Router({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'layout',
      component: layout
    },
    {
      path: '/',
      name: 'signIn',
      component: signIn
    },
    {
      path: '/account',
      name: 'account',
      component: account,
      meta: { requiresLogin: true }
    },
    {
      path: '/admin',
      name: 'admin',
      component: admin,
      meta: { requiresLogin: true }
    },
    ...roleV2Router,
    ...settingsRouting,
    {
      path: '/candidates',
      name: 'candidates',
      component: candidates,
      meta: { requiresLogin: true }
    },
    {
      path: '/client_portal*',
      name: 'clientPortal',
      component: clientPortal,
      meta: { requiresLogin: true }
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: notifications,
      meta: { requiresLogin: true }
    },
    {
      path: '/meetnow',
      name: 'meetnow',
      component: meetNow,
      meta: { requiresLogin: true }
    },
    {
      path: '/help',
      name: 'help',
      component: help,
      meta: { requiresLogin: true }
    },
    {
      path: '/forgottenpassword',
      name: 'forgottenpassword',
      component: forgottenPassword
    },
    {
      path: '/setpassword',
      name: '/setpassword',
      component: setPassword
    },
    {
      path: '/shineintros',
      name: 'shineintros',
      component: shineIntros,
      meta: { requiresLogin: true }
    },
    {
      path: '/myaccounts',
      name: 'myaccounts',
      component: myAccounts,
      meta: { requiresLogin: true }
    },
    {
      path: '/reports',
      name: 'reports',
      component: reports,
      meta: { requiresLogin: true }
    },
    {
      path: '/signup',
      name: 'signup',
      component: signUp
    },
    {
      path: '/videolibrary',
      name: 'videolibrary',
      component: videolibrary,
      meta: { requiresLogin: true }
    },
    {
      path: '*',
      name: 'notfound',
      component: notFound
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresLogin) && JSON.parse(localStorage.getItem('userStore')).userData.aut === false) {
    next('/')
  } else {
    next()
  }
})

export default router
