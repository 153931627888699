<template>
  <div>
    <div
      data-v-f55e8a72
      style="float:left;color:#555;font-size:14px;display:flex;"
      class="filterBar form-check-inline cursor-pointer"
      @click="show = !show"
    >
      <i class="fas fa-sliders-h" style="margin-left:15px;" />
      <div style="margin-left:15px;font-weight:bold;">
        Filter
      </div>
      <FontAwesomeIcon
        class="ml-auto flex pr-2"
        :icon="show ? 'fa-regular fa-circle-chevron-down' : 'fa-regular fa-circle-chevron-right'"
      />
    </div>

    <LeaveFade>
      <div
        v-if="show"
        class="border-2 border-t-0 mb-2 rounded-lg shadow-sm"
      >
        <div
          v-for="(filter, index) in filters"
          :key="filter.id"
          class="flex flex-col rounded-lg pl-2 mb-1"
        >
          <div
            class="mr-auto"
            style="color:#555;font-size:14px;"
            :title="filter.tooltip"
            @click="$emit('changeFilter', filter.id)"
          >
            <mdb-input
              v-model="filterData[index].value"
              type="checkbox"
              :label="filter.name"
              size="sm"
              style="margin-left:-20px;"
              :style="{'font-weight' : filter.value ? 'bold' : 'normal'}"
            />
          </div>
        </div>

        <div class="flex flex-col rounded-lg pl-2 mb-1">
          <div
            style="color:#555;margin-left: -6px;margin-top:1px;"
            title="Show only your followed roles"
          >
            <div
              v-if="titleText === 'Followed Roles Only'"
              class="mobile-followed-only-toggle flex pb-2"
              style="font-size:14px;font-weight: bold;"
              @click="toggleAll();$emit('toggleOnlyFollowed')"
            >
              <i class="fa fa-toggle-off" style="margin-top:2px;margin-left:20px;font-size: 1.3rem;" />
              <span id="followed-only-toggle-label" style="margin-left:12px;margin-top:1px;">Followed Roles Only</span>
            </div>
            <div
              v-else
              class="mobile-followed-only-toggle flex pb-2"
              style="font-size:14px;"
              @click="toggleAll();$emit('toggleOnlyFollowed')"
            >
              <i class="fa fa-toggle-on primary-colour" style="margin-top:2px;margin-left:20px;font-size: 1.3rem;" />
              <span id="followed-only-toggle-label" style="margin-left:12px;margin-top:1px;">Followed Roles Only</span>
            </div>
          </div>
        </div>
      </div>
    </LeaveFade>
  </div>
</template>

<script>
import LeaveFade from '@/components/UI/TransitionComponents/leaveFade.vue'

export default {
  name: 'MobileFilter',
  components: {
    LeaveFade
  },
  props: {
    filters: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      show: false,
      filterData: this.filters,
      titleText: 'Followed Roles Only'
    }
  },
  methods: {
    toggleAll () {
      if (this.titleText === 'All Roles') {
        this.titleText = 'Followed Roles Only'
      } else {
        this.titleText = 'All Roles'
      }
      if (this.$parent.$parent.viewingOnlyFollowedRoles === true) {
        this.titleText = 'Followed Roles Only'
      }
      if (this.$parent.$parent.viewingOnlyFollowedRoles === false) {
        this.titleText = 'All Roles'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.filterBar[data-v-f55e8a72] {
  margin-bottom:10px;
}
.mobile-followed-only-toggle {
  display: flex;
  margin-left:-14px;
}
</style>
