<template>
  <div
    class="mx-3"
    :class="{'ml-3 ml-md-5' : navItem.Step === 1, 'mr-3 mr-md-5': navItem.Step === navItemCount }"
    style="text-align: -webkit-center;"
  >
    <a
      :class="{ 'btn-blank-primary': queryMatches(navItem.Query), 'btn-blank-outline': queryMatches(navItem.Query) === false }"
      type="button"
      class="white-text d-flex justify-content-center align-items-center btn-floating btn-sm ripple-parent"
      @click="$emit('clicked')"
    >
      <i
        v-show="navItem.ActualStep >= navItem.Step"
        :class="{ 'secondary-colour': queryMatches(navItem.Query), 'primary-colour': queryMatches(navItem.Query) === false }"
        class="fa fa-check"
        style="font-size:1.1rem;"
      />
    </a>
    <span
      style="font-size:12px;color:black;text-decoration:underline;cursor:pointer;"
    >
      {{ navItem.Text }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'CircleNavigationItems',
  props: {
    queryToMatch: {
      type: String,
      default: ''
    },
    navItem: {
      type: Object,
      default: () => {}
    },
    navItemCount: {
      type: Number,
      default: 0
    }
  },
  methods: {
    queryMatches (query) {
      return this.queryToMatch === query
    }
  }
}
</script>
