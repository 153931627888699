<template>
  <button
    :key="count"
    :title="actionButtonTitle"
    type="button"
    class="btn btn-primary mobilePlacement btn-md m-0"
    style="padding: 0.4rem 0.6rem;"
    :disabled="userData.rle === 'Inviter'"
    @click="actionButtonFunc()"
  >
    <span class="d-none d-xl-inline">{{ actionButtonText }}</span>
    <span class="d-inline d-xl-none"><i :class="actionButtonIcon" /></span>
  </button>
</template>

<script>
import { useRoleCandidate, useScheduler, useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue-demi'
import { MEET_URL, SCHEDULER_URL } from '@/common/config.js'
import moment from 'moment'

export default {
  name: 'TwoWayStageActionButton',
  props: {
    candidate: {
      type: Object,
      default: () => {}
    },
    stageItemStatus: {
      type: Number,
      default: 0
    },
    roleWorkflowStage: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {

    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)

    const roleCandidateStore = useRoleCandidate()
    const { resendSchedulerInviteEmail, resetSchedulerInvite } = useRoleCandidate()
    const { candidate: piniaCandidate, meetingDateModal, resettingTwoWay } = storeToRefs(roleCandidateStore)

    const schedulerStore = useScheduler()
    const { selectedSchedulerSetup } = storeToRefs(schedulerStore)

    const isSelfSchedule = computed(() => props.roleWorkflowStage.IsSelfSchedule)
    const meetingIsToday = computed(() => moment(props.candidate.BookingDate).isSame(moment(), 'day') || moment(props.candidate.InterviewDate).isSame(moment(), 'day'))
    const meetingIsInPast = computed(() => moment().isAfter(moment(props.candidate.BookingDate), 'minute') || moment().isAfter(moment(props.candidate.InterviewDate), 'minute'))

    const showResendSchedulerEmail = computed(() => props.stageItemStatus == 2) // INVITED only exists on self scheduled two way
    const showRescheduleThroughScheduler = computed(() => props.stageItemStatus == 3 && isSelfSchedule.value && !meetingIsInPast.value && !meetingIsToday.value)
    const showRescheduleThroughAdmin = computed(() => props.stageItemStatus == 3 && !isSelfSchedule.value && !meetingIsToday.value)
    const showEnterRoom = computed(() => props.stageItemStatus == 3 && meetingIsToday.value)
    const showResetSchedulerInvite = computed(() => isSelfSchedule.value && (props.stageItemStatus == 5 || (props.stageItemStatus == 3 && (meetingIsInPast.value && !meetingIsToday.value))))
    const showWatchInterview = computed(() => props.stageItemStatus >= 6)

    const resendSchedulerEmailLocal = async () => {
      piniaCandidate.value = props.candidate
      await resendSchedulerInviteEmail(props.candidate, props.roleWorkflowStage.RoleWorkflowStageId, () => emit('updateComponentContext'))
    }

    const rescheduleThroughScheduler = () => {
      window.open(SCHEDULER_URL + '/' + selectedSchedulerSetup.value.ScheduleUrl + '?id=' + props.candidate.SchedulerInvitationCode + '&gu=' + userData.value.uid)
    }
    const rescheduleThroughAdminLocal = async () => {
      piniaCandidate.value = props.candidate
      meetingDateModal.value = true
      resettingTwoWay.value = true
      emit('resetting')
    }
    const enterRoom = () => {
      window.open(MEET_URL + '?inv=' + props.candidate.HostLink, '_blank')
    }

    const resetSchedulerInviteLocal = async () => {
      piniaCandidate.value = props.candidate
      await resetSchedulerInvite(props.candidate.SchedulerInvitationCode, () => emit('updateComponentContext'))
    }

    const watchInterview = () => {
      piniaCandidate.value = props.candidate
      emit('initialiseReviewModal', props.candidate)
    }

    const actionButtonText = ref('')
    const actionButtonFunc = ref(() => {})
    const actionButtonIcon = ref('')
    const actionButtonTitle = ref('')

    if (showResendSchedulerEmail.value) {
      actionButtonText.value = 'RESEND INVITE'
      actionButtonFunc.value = resendSchedulerEmailLocal
      actionButtonIcon.value = 'fa fa-envelope'
      actionButtonTitle.value = 'Resend the email invite for the scheduler'
    } else if (showRescheduleThroughScheduler.value) {
      actionButtonText.value = 'RESCHEDULE'
      actionButtonFunc.value = rescheduleThroughScheduler
      actionButtonIcon.value = 'fa fa-calendar' // CHECK THIS
      actionButtonTitle.value = 'Open up the external scheduler to reschedule'
    } else if (showRescheduleThroughAdmin.value) {
      actionButtonText.value = 'RESCHEDULE'
      actionButtonFunc.value = rescheduleThroughAdminLocal
      actionButtonIcon.value = 'fa fa-calendar' // CHECK THIS
      actionButtonTitle.value = 'Set a new booking time'
    } else if (showEnterRoom.value) {
      actionButtonText.value = 'ENTER ROOM'
      actionButtonFunc.value = enterRoom
      actionButtonIcon.value = 'fa fa-tv' // CHECK THIS
      actionButtonTitle.value = 'Enter the meeting room'
    } else if (showResetSchedulerInvite.value) {
      actionButtonText.value = 'RESET BOOKING'
      actionButtonFunc.value = resetSchedulerInviteLocal
      actionButtonIcon.value = 'fa fa-sync' // CHECK THIS
      actionButtonTitle.value = 'Delete the booking and send new scheduler invite'
    } else if (showWatchInterview.value) {
      actionButtonText.value = 'WATCH INTERVIEW'
      actionButtonFunc.value = watchInterview
      actionButtonIcon.value = 'fa fa-tv' // CHECK THIS
      actionButtonTitle.value = 'Review this stages interview'
    }

    const count = ref(0)
    const stageItemStatus = computed(() => props.stageItemStatus)
    watch(stageItemStatus, () => count.value++ )

    return {
      actionButtonText,
      actionButtonFunc,
      actionButtonIcon,
      actionButtonTitle,
      count,
      userData
    }
  }
}
</script>

<style>
.opac {
  opacity: 0.4;
}
</style>
