<template>
  <div class="px-3 px-md-5 pt-4" style="margin-bottom:52px !important;">
    <div style="width:100%;float;left;">
      <div style="text-align:left;padding:2px;margin-top:0px;" class="h-full">
        <div class="grid grid-cols-1 w-1/1 mt-8 border-bottom">
          <div
            style="display:none;"
            class="cursor-pointer text-lg grey-text text-center"
            :class="{ 'default': section == 'interviews' }"
            @click="setSection('interviews')"
          >
            <span v-if="mobileView"><i class="fa fa-mobile" /></span>
            <span v-else>SHINE INTERVIEWS</span>
          </div>
          <div
            style="display:none;"
            class="cursor-pointer text-lg grey-text text-center"
            :class="{ 'default': section == 'emails' }"
            @click="setSection('emails')"
          >
            <span v-if="mobileView"><i class="fa fa-envelope" /></span>
            <span v-else>EMAIL</span>
          </div>
          <div
            class="cursor-pointer text-lg grey-text text-center"
            :class="{ 'default': section == 'sms' }"
            @click="setSection('sms')"
          >
            <span v-if="mobileView"><i class="fa fa-sms" /></span>
            <span v-else>SMS Text</span>
          </div>
        </div>
      </div>

      <div v-show="interviewsLoaded === false && emailsLoaded === false && smsLoaded === false" :class="{ 'marginTopSections': mobileView === false }">
        <h3 class="primary-colour text-left font-weight-bold" :class="{ 'mobileTopSections': mobileView }">
          Loading
          <i
            style="margin-left: 5px !important;"
            class="fa fa-spin fa-spinner ml-3"
            :class="{ 'mobileTopSectionSpinner': mobileView }"
          />
        </h3>
      </div>

      <div v-if="interviewsLoaded === true || smsLoaded === true || emailsLoaded === true">
        <!--add/edit interview stock text-->
        <div v-if="section === 'interviews'">
          <div
            class="fullW"
            style="text-align:left;padding:2px;margin-top:0px;width:65%;float:left;"
            :class="{ 'fullWidth': mobileView === true }"
          >
            <div class="titleText">
              This is your default text that is used when creating a New Role, you can override the text on a role-by-role basis as needed.
            </div>

            <div :class="{ 'stockTextContainer': mobileView === false }">
              <div :class="{ 'stockTextContent': mobileView === false }" style="margin-bottom:200px;">
                <validation-observer v-slot="{ invalid, reset }">
                  <form
                    ref="form"
                    @submit.prevent="saveStockText()"
                    @reset.prevent="reset"
                  >
                    <div class="md-form form-sm">
                      <validation-provider
                        v-slot="{ errors }"
                        mode="lazy"
                        name="Intro 1"
                        rules="required"
                      >
                        <i class="fas fa-pen prefix" style="margin-top: 0px;font-size: 1.3rem;" />
                        <div style="font-size: small;margin-left: 33px;margin-bottom: -20px;">
                          Introduction text #1 (250 characters max.)
                        </div>
                        <mdb-input
                          :ref="1"
                          v-model="stockText.InvitationIntroductionStep0"
                          type="textarea"
                          auto-complete="fna"
                          class="textAreaContainer"
                          :rows="5"
                          size="sm"
                          maxlength="250"
                          @keyup.native="charCount(stockText.InvitationIntroductionStep0, 250, 1)"
                          @focus="stockTextEdit(1)"
                        />
                        <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                          Please provide some content
                        </div>
                        <div class="grey-text remainingChars">
                          {{ chars1 }}
                        </div>
                      </validation-provider>
                    </div>
                    <div class="md-form form-sm">
                      <validation-provider
                        v-slot="{ errors }"
                        mode="lazy"
                        name="Intro 2"
                        rules="required"
                      >
                        <i class="fas fa-pen prefix" style="margin-top: 0px;font-size: 1.3rem;" />
                        <div style="font-size: small;margin-left: 33px;margin-bottom: -20px;">
                          Introduction text #2 (250 characters max.)
                        </div>
                        <mdb-input
                          :ref="2"
                          v-model="stockText.InvitationIntroductionStep1"
                          type="textarea"
                          auto-complete="sy1"
                          class="textAreaContainer"
                          :rows="5"
                          size="sm"
                          maxlength="250"
                          @keyup.native="charCount(stockText.InvitationIntroductionStep1, 250, 2)"
                          @focus="stockTextEdit(2)"
                        />
                        <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                          Please provide some content
                        </div>
                        <div class="grey-text remainingChars">
                          {{ chars2 }}
                        </div>
                      </validation-provider>
                    </div>
                    <div class="md-form form-sm">
                      <validation-provider
                        v-slot="{ errors }"
                        mode="lazy"
                        name="Intro 3"
                        rules="required"
                      >
                        <i class="fas fa-pen prefix" style="margin-top: 0px;font-size: 1.3rem;" />
                        <div style="font-size: small;margin-left: 33px;margin-bottom: -20px;">
                          Introduction text #3 (250 characters max.)
                        </div>
                        <mdb-input
                          :ref="3"
                          v-model="stockText.InvitationIntroductionStep2"
                          type="textarea"
                          auto-complete="st2"
                          class="textAreaContainer"
                          :rows="5"
                          size="sm"
                          maxlength="250"
                          @keyup.native="charCount(stockText.InvitationIntroductionStep2, 250, 3)"
                          @focus="stockTextEdit(3)"
                        />
                        <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                          Please provide some content
                        </div>
                        <div class="grey-text remainingChars">
                          {{ chars3 }}
                        </div>
                      </validation-provider>
                    </div>
                    <div class="md-form form-sm">
                      <validation-provider
                        v-slot="{ errors }"
                        mode="lazy"
                        name="Intro 4"
                        rules="required"
                      >
                        <i class="fas fa-pen prefix" style="margin-top: 0px;font-size: 1.3rem;" />
                        <div style="font-size: small;margin-left: 33px;margin-bottom: -20px;">
                          Introduction text #4 (250 characters max.)
                        </div>
                        <mdb-input
                          :ref="4"
                          v-model="stockText.InvitationIntroductionStep3"
                          type="textarea"
                          auto-complete="st3"
                          class="textAreaContainer"
                          :rows="5"
                          size="sm"
                          maxlength="250"
                          @keyup.native="charCount(stockText.InvitationIntroductionStep3, 250, 4)"
                          @focus="stockTextEdit(4)"
                        />
                        <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                          Please provide some content
                        </div>
                        <div class="grey-text remainingChars">
                          {{ chars4 }}
                        </div>
                      </validation-provider>
                    </div>
                    <div class="md-form form-sm">
                      <validation-provider
                        v-slot="{ errors }"
                        mode="lazy"
                        name="Ready To Start"
                        rules="required"
                      >
                        <i class="fas fa-pen prefix" style="margin-top: 0px;font-size: 1.3rem;" />
                        <div style="font-size: small;margin-left: 33px;margin-bottom: -20px;">
                          Ready to start content (400 characters max.)
                        </div>
                        <mdb-input
                          :ref="5"
                          v-model="stockText.ReadyToStartContent"
                          type="textarea"
                          auto-complete="st4"
                          class="textAreaContainer"
                          :rows="5"
                          size="sm"
                          maxlength="400"
                          @keyup.native="charCount(stockText.ReadyToStartContent, 400, 5)"
                          @focus="stockTextEdit(5)"
                        />
                        <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                          Please provide some content
                        </div>
                        <div class="grey-text remainingChars">
                          {{ chars5 }}
                        </div>
                      </validation-provider>
                    </div>
                    <div class="md-form form-sm">
                      <validation-provider
                        v-slot="{ errors }"
                        mode="lazy"
                        name="Content After"
                        rules="required"
                      >
                        <i class="fas fa-pen prefix" style="margin-top: 0px;font-size: 1.3rem;" />
                        <div style="font-size: small;margin-left: 33px;margin-bottom: -20px;">
                          Content to display after practice question (400 characters max.)
                        </div>
                        <mdb-input
                          :ref="6"
                          v-model="stockText.ContentAfterPractiseQuestion"
                          type="textarea"
                          auto-complete="st5"
                          class="textAreaContainer"
                          :rows="5"
                          size="sm"
                          maxlength="400"
                          @keyup.native="charCount(stockText.ContentAfterPractiseQuestion, 400, 6)"
                          @focus="stockTextEdit(6)"
                        />
                        <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                          Please provide some content
                        </div>
                        <div class="grey-text remainingChars">
                          {{ chars6 }}
                        </div>
                      </validation-provider>
                    </div>
                    <mdb-btn
                      v-show="!stockTextSaving"
                      type="submit"
                      :disabled="invalid"
                      class="float-right"
                      size="sm"
                      style="width:100px;margin-top: 10px;margin-right:10px;"
                    >
                      SAVE
                    </mdb-btn>
                    <mdb-btn
                      v-show="stockTextSaving"
                      class="float-right"
                      size="sm"
                      style="width:100px;margin-top: 10px;margin-right:10px;"
                    >
                      <mdb-icon icon="fa fa-pulse fa-spinner" />
                    </mdb-btn>
                  </form>
                </validation-observer>
              </div>
            </div>
          </div>
        </div>

        <!--add/edit sms stock text-->
        <div v-if="section === 'sms'">
          <div
            class="fullW"
            style="text-align:left;padding:2px;margin-top:0px;width:65%;float:left;"
            :class="{ 'fullWidth': mobileView === true }"
          >
            <div class="titleText">
              This is your default SMS text that is used when creating a one-way role, you can override the text on a role-by-role basis as needed.
            </div>
            <!-- <InsertText
          :style="{'pointer-events' : stockTextValue == 7  || stockTextValue == 9 ? 'auto' : 'none'}"
          :words="SMSDynamicTextList"
          @word="addDynamicSMSText($event)" /> -->
            <div :class="{ 'stockTextContainer': mobileView === false }">
              <div :class="{ 'stockTextContent': mobileView === false }" style="margin-bottom:210px;">
                <validation-observer v-slot="{ invalid, reset }">
                  <form
                    ref="form"
                    @submit.prevent="saveStockText()"
                    @reset.prevent="reset"
                  >
                    <div class="md-form form-sm" style="margin-top:3rem;">
                      <validation-provider
                        v-slot="{ errors }"
                        mode="lazy"
                        name="Intro 1"
                        :rules="{required: true, regex: /^\S*$/}"
                      >
                        <i class="fas fa-pen prefix" style="margin-top: 0px;font-size: 1.3rem;" />
                        <div style="font-size: small;margin-left: 33px;margin-bottom: -20px;">
                          SMS Display name (11 characters max.)
                        </div>
                        <mdb-input
                          v-model="stockText.SMSFriendlyName"
                          type="text"
                          auto-complete="fna"
                          class="textAreaContainerSmall"
                          maxlength="11"
                          size="sm"
                          @keyup.native="charCount(stockText.SMSFriendlyName, 11, 8),removeSpaces()"
                        />
                        <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                          Please provide some content or remove any whitespace
                        </div>
                        <div class="grey-text remainingChars">
                          {{ chars8 }}
                        </div>
                      </validation-provider>
                    </div>
                    <div class="md-form form-sm" style="margin-top:3rem;">
                      <validation-provider
                        v-slot="{ errors }"
                        mode="lazy"
                        name="Intro 1"
                        rules="required"
                      >
                        <i class="fas fa-pen prefix" style="margin-top: 0px;font-size: 1.3rem;" />
                        <div style="font-size: small;margin-left: 33px;margin-bottom: -20px;">
                          SMS Invite text 320 characters max.)
                        </div>
                        <mdb-input
                          ref="7"
                          v-model="stockText.SMSMessage"
                          type="textarea"
                          auto-complete="fna"
                          class="textAreaContainer"
                          :rows="5"
                          maxlength="320"
                          size="sm"
                          @keyup.native="charCount(stockText.SMSMessage, 320, 7)"
                          @focus="stockTextEdit(7)"
                        />
                        <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                          Please provide some content
                        </div>
                        <div class="grey-text remainingChars">
                          {{ chars7 }}
                        </div>
                      </validation-provider>
                    </div>
                    <div class="md-form form-sm" style="margin-top:3rem;">
                      <validation-provider
                        v-slot="{ errors }"
                        mode="lazy"
                        name="Intro 1"
                        rules="required"
                      >
                        <i class="fas fa-pen prefix" style="margin-top: 0px;font-size: 1.3rem;" />
                        <div style="font-size: small;margin-left: 33px;margin-bottom: -20px;">
                          SMS Reminder text (320 characters max.)
                        </div>
                        <mdb-input
                          ref="9"
                          v-model="stockText.SMSReminderMessage"
                          type="textarea"
                          auto-complete="fna"
                          class="textAreaContainer"
                          :rows="5"
                          maxlength="320"
                          size="sm"
                          @keyup.native="charCount(stockText.SMSReminderMessage, 320, 9)"
                          @focus="stockTextEdit(9)"
                        />
                        <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                          Please provide some content
                        </div>
                        <div class="grey-text remainingChars">
                          {{ chars9 }}
                        </div>
                      </validation-provider>
                    </div>
                    <mdb-btn
                      v-show="!stockTextSaving"
                      type="submit"
                      :disabled="invalid"
                      class="float-right my-3"
                      size="sm"
                      style="width:100px;"
                    >
                      SAVE
                    </mdb-btn>
                    <mdb-btn
                      v-show="stockTextSaving"
                      class="float-right my-3"
                      size="sm"
                      style="width:100px;"
                    >
                      <mdb-icon icon="fa fa-pulse fa-spinner" />
                    </mdb-btn>
                  </form>
                </validation-observer>
              </div>
            </div>
          </div>
        </div>

        <div v-if="section !== 'emails' && mobileView === false" style="transform:scale(0.92)">
          <div
            v-show="stockTextValue < 7"
            style="text-align:left;padding:2px;margin-top:0px;width:35%;float:left;margin-left:-20px;"
            class="isVis1"
          >
            <div class="background-colour isVis1" style="width: 265px;margin-left: 79px;margin-top: 50px;">
              <br><br>
            </div>
            <div style="margin-top:-15px;" class="isVis1">
              <i class="fa fa-caret-down primary-colour" style="margin-left:199px;font-size:36px" />
            </div>
          </div>
          <div
            style="text-align:left;padding:2px;margin-top:-108px;width:35%;float:left;margin-left:-20px;"
            :class="{ 'smsDisplay': stockTextValue == 7 || stockTextValue == 9}"
            class="isVis1"
          >
            <div :class="{ 'mobilePhone1': stockTextValue == 1, 'mobilePhone2': (stockTextValue == 2 || stockTextValue == 3), 'mobilePhone3': stockTextValue == 4 , 'mobilePhone': stockTextValue > 4}" style="margin-left:65px;margin-top:20px;width:341px;height:630px;">
              <div v-show="stockTextValue == 1" class="grey-text text-center mobilePhoneRender">
                <span :class="{ 'isBold': stockTextValue == 1}">{{ stockText.InvitationIntroductionStep0 }}</span> for<br><br>
                <span class="primary-colour" style="font-size:1rem;font-weight:bold;">[JOB TITLE]</span>
                <br><br>
                There will be a short introduction about the company and the job you are applying for.
              </div>
              <div v-show="(stockTextValue == 2 || stockTextValue == 3)" class="grey-text text-center mobilePhoneRender">
                {{ stockText.InvitationIntroductionStep0 }} for<br><br>
                <span class="primary-colour" style="font-size:1rem;font-weight:bold;">[JOB TITLE]</span>
                <br><br>
                <span :class="{ 'isBold': stockTextValue == 2}">{{ stockText.InvitationIntroductionStep1 }}</span>
                <br><br>
                <span :class="{ 'isBold': stockTextValue == 3}">{{ stockText.InvitationIntroductionStep2 }}</span>
              </div>
              <div v-show="stockTextValue == 4" class="grey-text text-center mobilePhoneRender">
                {{ stockText.InvitationIntroductionStep0 }} for<br><br>
                <span class="primary-colour" style="font-size:1rem;font-weight:bold;">[JOB TITLE]</span>
                <br><br>
                <span :class="{ 'isBold': stockTextValue == 4}">{{ stockText.InvitationIntroductionStep3 }}</span>
              </div>
              <div v-show="stockTextValue == 5" class="grey-text text-center mobilePhoneRender">
                <span :class="{ 'isBold': stockTextValue == 5}">{{ stockText.ReadyToStartContent }}</span>
              </div>
              <div v-show="stockTextValue == 6" class="grey-text text-center mobilePhoneRender">
                <span :class="{ 'isBold': stockTextValue == 6}">{{ stockText.ContentAfterPractiseQuestion }}</span>
              </div>
              <div v-show="stockTextValue == 7" class="grey-text text-center mobilePhoneRender">
                <div style="margin-bottom:5px;margin-top:-20px;">
                  <div style="margin-bottom: 5px;margin-top: -35px;font-size: 14px;">
                    {{ stockText.SMSFriendlyName }}
                  </div>
                  {{ new Date().toLocaleDateString([userLanguage], options) }} {{ new Date().toLocaleTimeString([userLanguage]).substring(0, 5) }}
                </div>
                <div class="txtMessageRender">
                  {{ stockText.SMSMessage }}
                </div>
              </div>
              <div v-show="stockTextValue == 9" class="grey-text text-center mobilePhoneRender">
                <div style="margin-bottom:5px;margin-top:-20px;">
                  <div style="margin-bottom: 5px;margin-top: -35px;font-size: 14px;">
                    {{ stockText.SMSFriendlyName }}
                  </div>
                  {{ new Date().toLocaleDateString([userLanguage], options) }} {{ new Date().toLocaleTimeString([userLanguage]).substring(0, 5) }}
                </div>
                <div class="txtMessageRender">
                  {{ stockText.SMSReminderMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--error modal-->
    <mdb-modal
      id="errorModal"
      :show="errorModal"
      @close="errorModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>{{ errorModalTitle.toUpperCase() }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          <span v-html="error" />
        </p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="errorModal = false"
        >
          OK
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import accountService from '@/api/services/account.service'
import logoutService from '@/api/services/logout.service'
import { useMobile } from '@/composables/useMobile'
import { logout } from '@/methods/auth.js'

import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn } from 'mdbvue'
import { userLanguage } from '@/common/userLanguage'
// import InsertText from '@/components/misc/insertText.vue'

export default {
  name: 'Stocktext',
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    return {
      authData,
      userData,
      mobileView
    }
  },
  data () {
    return {
      errorModal: false,
      buttonClick: false,
      buttonClickA: false,
      stockTextLoaded: false,
      stockText: [],
      stockTextSaving: false,
      errorModalTitle: '',
      error: '',
      section: 'sms',
      stockTextValue: 1,
      chars1: '',
      chars2: '',
      chars3: '',
      chars4: '',
      chars5: '',
      chars6: '',
      chars7: '',
      chars8: '',
      chars9: '',
      userLanguage,
      options: { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' },
      smsPlaceHolder: 'Text Message',
      smsLoaded: false,
      emailsLoaded: false,
      interviewsLoaded: false,
      SMSDynamicTextList: ['[ First Name ]', '[ Last Name ]', '[ Role Name ]']
    }
  },
  computed: {
    focusedSMSField () {
      return this.stockTextValue === 9 ? 'reminder-sms' : 'invite-sms'
    }
  },
  created () {
    this.getStockText()
    this.setSection('sms')
  },
  methods: {
    logout,
    removeSpaces () {
      this.stockText.SMSFriendlyName = this.stockText.SMSFriendlyName.replace(/\s+/g, '')
    },
    charCount (type, max, num) {
      this.maxCharacter = max
      if (num === 1) {
        this.chars1 = type.length + '/' + max
      } else if (num === 2) {
        this.chars2 = type.length + '/' + max
      } else if (num === 3) {
        this.chars3 = type.length + '/' + max
      } else if (num === 4) {
        this.chars4 = type.length + '/' + max
      } else if (num === 5) {
        this.chars5 = type.length + '/' + max
      } else if (num === 6) {
        this.chars6 = type.length + '/' + max
      } else if (num === 7) {
        this.chars7 = type.length + '/' + max
      } else if (num === 8) {
        this.chars8 = type.length + '/' + max
      } else if (num === 9) {
        this.chars9 = type.length + '/' + max
      }
    },
    reset () {
      this.$refs.form.reset()
    },
    setSection (value) {
      this.section = value
      this.smsLoaded = false
      this.emailsLoaded = false
      this.interviewsLoaded = false
      if (value === 'sms') {
        this.stockTextValue = 7
      }
      if (value === 'interviews') {
        this.stockTextValue = 1
      }
      if (value === 'sms') {
        setTimeout(() => {
          this.smsLoaded = true
        }, 3000)
      } else if (value === 'interviews') {
        setTimeout(() => {
          this.interviewsLoaded = true
        }, 3000)
      } else {
        setTimeout(() => {
          this.emailsLoaded = true
        }, 3000)
      }
    },
    toggleClosingDate () {
      if (this.stockText.DisableClosingDate === true) {
        this.stockText.DisableClosingDate = false
      } else {
        this.stockText.DisableClosingDate = true
      }
    },
    getStockText () {
      accountService.getStockText().then((response) => {
        this.stockText = response.data
        setTimeout(() => { this.stockTextLoaded = true }, 2500)
        if (this.stockText.InvitationIntroductionStep0 != null) {
          this.chars1 = (this.stockText.InvitationIntroductionStep0.length) + '/250'
        }
        if (this.stockText.InvitationIntroductionStep1 != null) {
          this.chars2 = (this.stockText.InvitationIntroductionStep1.length) + '/250'
        }
        if (this.stockText.InvitationIntroductionStep2 != null) {
          this.chars3 = (this.stockText.InvitationIntroductionStep2.length) + '/250'
        }
        if (this.stockText.InvitationIntroductionStep3 != null) {
          this.chars4 = (this.stockText.InvitationIntroductionStep3.length) + '/250'
        }
        if (this.stockText.ReadyToStartContent != null) {
          this.chars5 = (this.stockText.ReadyToStartContent.length) + '/400'
        }
        if (this.stockText.ContentAfterPractiseQuestion != null) {
          this.chars6 = (this.stockText.ContentAfterPractiseQuestion.length) + '/400'
        }
        if (this.stockText.SMSMessage != null) {
          this.chars7 = (this.stockText.SMSMessage.length) + '/320'
        }
        if (this.stockText.SMSFriendlyName != null) {
          this.chars8 = (this.stockText.SMSFriendlyName.length) + '/11'
        }
        if (this.stockText.SMSReminderMessage != null) {
          this.chars9 = (this.stockText.SMSReminderMessage.length) + '/320'
        }
        this.buttonClick = false
      }).catch((error) => {
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
        this.buttonClick = false
      })
    },
    stockTextEdit (type) {
      if (type >= 1) {
        this.stockTextValue = type
        this.$refs[type].focus()
      }
    },
    addDynamicSMSText (word) {
      if (this.stockTextValue === 7) {
        if (word.length + this.stockText.SMSMessage.length <= 320) {
          this.stockText.SMSMessage = this.stockText.SMSMessage += word
          this.charCount(this.stockText.SMSMessage, 320, 7)
        }
      } else if (this.stockTextValue === 9) {
        if (word.length + this.stockText.SMSReminderMessage.length <= 320) {
          this.stockText.SMSReminderMessage = this.stockText.SMSReminderMessage += word
          this.charCount(this.stockText.SMSReminderMessage, 320, 9)
        }
      }
    },
    saveStockText () {
      this.stockTextSaving = true
      accountService.saveStockText(this.stockText).then(() => {
        this.reset()
        this.getStockText()
        this.buttonClick = false
      }).catch((error) => {
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
        this.buttonClick = false
      })
      setTimeout(() => { this.stockTextSaving = false }, 2500)
    },
    testEmail () {
      accountService.sendTestEmail().then(() => {
        this.errorModalTitle = 'Processed!'
        this.errorAlert('Processed!', 'Please check your emails.')
        this.error = 'Please check your emails.'
        this.buttonClickA = false
      }).catch((error) => {
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
        this.buttonClickA = false
      })
    },
    errorAlert (error) {
      try {
        if (error.response.data != null) {
          if (error.response.data.Message != null) {
            this.errorModal = true
            this.error = error.response.data.Message
            if (this.error === '') { this.error = 'Sorry, an error has occured!' }
          } else if (error.response.data.error_description != null) {
            this.errorModal = true
            this.error = error.response.data.error_description
            if (this.error === '') { this.error = 'Sorry, an error has occured!' }
          } else {
            this.errorModal = true
            this.error = error.response.data
            if (this.error === '') { this.error = 'Sorry, an error has occured!' }
          }
        } else {
          this.errorModal = true
          this.error = 'Sorry an error has occurred!'
        }
        if (error.response.data.Message === 'Authorization has been denied for this request.') {
          this.errorModal = true
          this.error = 'Authorization has been denied for this request [st].'
          this.doLogOut()
        }
      } catch {
        this.errorModal = true
        this.error = 'Sorry an error has occurred!'
      }
    },
    doLogOut () {
      logoutService.logout().then(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      }).catch(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .stockTextContainer {
    float:left;
    height: 500px;
    width:100%;
    padding:3px;
  }
  .stockTextContent {
    height:490px;
    overflow-y:auto;
    overflow-x:hidden;
    background:#fff;
  }
  * {
    scrollbar-width: thin;
    scrollbar-color: #e1e1e1 white;
  }
  *::-webkit-scrollbar {
    width: 12px;
  }
  *::-webkit-scrollbar-track {
    background: white;
  }
  *::-webkit-scrollbar-thumb {
    background-color:#e1e1e1;
    border-radius: 20px;
    border: 3px solid white;
  }
  .md-form textarea.md-textarea {
    border: none !important;
    border-bottom:none !important;
  }
  .md-form input:not([type]), .md-form input[type="text"]:not(.browser-default), .md-form input[type="password"]:not(.browser-default), .md-form input[type="email"]:not(.browser-default), .md-form input[type="url"]:not(.browser-default), .md-form input[type="time"]:not(.browser-default), .md-form input[type="date"]:not(.browser-default), .md-form input[type="datetime"]:not(.browser-default), .md-form input[type="datetime-local"]:not(.browser-default), .md-form input[type="tel"]:not(.browser-default), .md-form input[type="number"]:not(.browser-default), .md-form input[type="search"]:not(.browser-default), .md-form input[type="phone"]:not(.browser-default), .md-form input[type="search-md"], .md-form textarea.md-textarea {
    border: none !important;
    border-bottom:none !important;
  }
</style>
<style>
  .fullWidth {
    width:100% !important;
  }
  .smsDisplay {
    margin-top:16px !important;
  }
</style>
