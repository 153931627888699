<template>
  <div class="flex flex-col px-8">
    <span class="font-bold text-left pt-4">
      Choose the dataset that is sent
    </span>
    <div
      v-if="eventSubscription.ServiceEndPoint != ''"
      class="grey-text"
      style="text-align: left;margin-top: 20px;margin-bottom: -30px;font-size: 11px;margin-left: 26px;"
    >
      Service Endpoint
    </div>
    <div class="flex flex-row items-center">
      <font-awesome-icon :icon="['far', 'bars-progress']" class="mr-2" />
      <mdb-input
        v-model="eventSubscription.ServiceEndPoint"
        placeholder="Service Endpoint"
        size="sm"
        class="w-full"
      />
    </div>
    <div
      v-if="eventSubscription.PayloadType > 0"
      class="grey-text"
      style="text-align: left;margin-top: 0px;margin-bottom: -30px;font-size: 11px;margin-left: 26px;"
    >
      Payload Type
    </div>
    <div class="flex flex-row items-center">
      <font-awesome-icon :icon="['fas', 'code']" class="mr-2" />
      <mdb-select
        v-model="payloadType"
        placeholder="Choose the payload type"
        size="sm"
        class="w-full"
        disabled
        @change="payloadTypeSelect($event)"
      />
    </div>
    <div
      v-if="eventSubscription.PayloadTypeData > 0"
      class="grey-text"
      style="text-align: left;margin-top: 0px;margin-bottom: -30px;font-size: 11px;margin-left: 26px;"
    >
      Payload Data
    </div>

    <div class="flex flex-row items-center">
      <font-awesome-icon :icon="['fas', 'code']" class="mr-2" />
      <mdb-select
        v-model="payloadTypeData"
        placeholder="Choose the payload type"
        size="sm"
        class="w-full"
        @change="payloadTypeDataSelect($event)"
      />
    </div>
    <div
      class="grey-text"
      style="text-align: left;margin-top: 0px;margin-bottom: -10px;font-size: 11px;margin-left: 26px;"
    >
      Data Array
    </div>
    <div class="flex flex-row items-center" style="margin-bottom:-15px">
      <font-awesome-icon
        :icon="['fas', 'gears']"
        class="mr-2"
        style="margin-top:15px;"
      />
    </div>
    <div
      v-if="plValue === 1"
      class="flex flex-row items-center"
      style="margin-bottom:-15px;margin-top:-25px;margin-left:20px;"
    >
      <mdb-input
        type="textarea"
        :rows="15"
        size="sm"
        maxlength="10000"
        disabled
        style="width:100%;pointer-events: auto !important;overflow-y: auto !important;"
        :placeholder="`  {
          EventSubscriptionType: 'string',
          ShineCandidateId: 'int',
          ExternalCandidateId: 'string',
          RoleId: 'int',
          ExternalRoleId: 'string',
          AccountId: 'int',
          NewStatus: 'string',
          StageId: 'guid',
          ExternalStageId: 'string',
          SharingLink: 'string',
          ScoreData: 'string',
          BookingDate: 'date'
    }  `"
      />
    </div>
    <div
      v-if="plValue == 2"
      class="flex flex-row items-center"
      style="margin-bottom:-10px;margin-top:-30px;margin-left:28px;"
    >
      <mdb-input
        type="text"
        size="sm"
        disabled
        style="width:100%;pointer-events: auto !important;overflow-y: auto !important;"
        :placeholder="`Currently unavailable!`"
      />
    </div>
    <div
      v-if="plValue == 3"
      class="flex flex-row items-center"
      style="margin-bottom:-15px;margin-top:-25px;margin-left:20px;"
    >
      <mdb-input
        type="textarea"
        :rows="14"
        size="sm"
        maxlength="10000"
        disabled
        style="width:100%;pointer-events: auto !important;overflow-y: auto !important;"
        :placeholder="`  {
          reference_id: 'string',
          first_name: 'string',
          last_name: 'string',
          invitation_code: 'string',
          job_title: 'string',
          review_link: 'string',
          email_address: 'string',
          review_completed: 'string',
          reviewed_by: 'string',
          status: 'string',
          total_reviewed_score: 'double'
    }  `"
      />
    </div>
    <div
      v-if="plValue == 10"
      class="flex flex-row items-center"
      style="margin-bottom:-10px;margin-top:-30px;margin-left:28px;"
    >
      <mdb-input
        type="text"
        size="sm"
        disabled
        style="width:100%;pointer-events: auto !important;overflow-y: auto !important;"
        :placeholder="`Please contact your Shine account manager to discuss the implementation of a custom payload!`"
      />
    </div>

    <!--SOAP OAUTH ONLY-->
    <div v-if="eventSubscription.ConnectionType === 2 && eventSubscription.PayloadType === 2">
      <div
        class="grey-text"
        style="text-align: left;margin-top: 0px;margin-bottom: -10px;font-size: 11px;margin-left: 26px;"
      >
        XML SOAP ENVELOPE DATASET
      </div>
      <div class="flex flex-row items-center" style="margin-bottom:-15px">
        <font-awesome-icon :icon="['fas', 'gears']" class="mr-2" />
        <mdb-input
          v-model="eventSubscription.ServiceEndPointEnvelope"
          type="textarea"
          :rows="14"
          size="sm"
          maxlength="10000"
          style="width:100%;pointer-events: auto !important;overflow-y: auto !important;"
          :placeholder="`<soapenv:Envelope xmlns:soapenv='http://schemas.xmlsoap.org/soap/envelope/' xmlns:wsse='http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-secext-1.0.xsd'>
          <sapenv:Header>
            <wsse:Security soapenv:mustUnderstand='1'>
              <wsse:UsernameToken>
                <wsse:Username><!-- enter relevant credentials here --></wsse:Username>
                <wsse:Password Type='http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-username-token-profile-1.0#PasswordText'><!-- enter relevant credentials here --></wsse:Password>
              </wsse:UsernameToken>
            </wsse:Security>
          </sapenv:Header>
          <soapenv:Body>
            <!-- your data goes here -->
          </soapenv:Body>
        </soapenv:Envelope>`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useEventSubscription, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'ConnectionForm',
  setup () {

    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()
    const eventSubscriptionStore = useEventSubscription()
    const { eventSubscription, payloadType, payloadTypeData, plValue } = storeToRefs(eventSubscriptionStore)

    return {
      eventSubscription,
      payloadType,
      payloadTypeData,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal,
      plValue
    }
  },
  created () {
    this.isValidated = false

    if (this.eventSubscription.PayloadTypeData === 0) {
      this.eventSubscription.PayloadTypeData = 1
    }
    
    if (this.eventSubscription.EventSubscriptionId > 0) {
      this.setOptions(this.eventSubscription)
    } else {
      this.$router.push('/settings/eventsubscriptions')
    }
  },
  methods: {
    setOptions (sub) {
      if (sub.PayloadType === 1) {
        this.payloadType = [
          { text: 'JSON', value: 1, selected: true },
          { text: 'XML', value: 2, selected: false }
        ]
      }
      else if (sub.PayloadType === 2) {
        this.payloadType = [
          { text: 'JSON', value: 1, selected: false },
          { text: 'XML', value: 2, selected: true }
        ]
      }
      else {
        this.payloadType = [
          { text: 'Please Select', value: 0, selected: true },
          { text: 'JSON', value: 1, selected: false },
          { text: 'XML', value: 2, selected: false }
        ]
      }

      if (sub.PayloadTypeData === 1) {
        this.payloadTypeData = [
          { text: 'Shine', value: 1, selected: true },
          { text: 'Oleeo', value: 2, selected: false },
          { text: 'Jobtrain', value: 3, selected: false },
          { text: 'Custom', value: 10, selected: false }
        ]
        this.eventSubscription.PayloadTypeData = 1
        this.plValue = 1
      }
      else if (sub.PayloadTypeData === 2) {
        this.payloadTypeData = [
          { text: 'Shine', value: 1, selected: false },
          { text: 'Oleeo', value: 2, selected: true },
          { text: 'Jobtrain', value: 3, selected: false },
          { text: 'Custom', value: 10, selected: false }
        ]
        this.eventSubscription.PayloadTypeData = 2
        this.plValue = 2
      }
      else if (sub.PayloadTypeData === 3) {
        this.payloadTypeData = [
          { text: 'Shine', value: 1, selected: false },
          { text: 'Oleeo', value: 2, selected: false },
          { text: 'Jobtrain', value: 3, selected: true },
          { text: 'Custom', value: 10, selected: false }
        ]
        this.eventSubscription.PayloadTypeData = 3
        this.plValue = 3
      }
      else if (sub.PayloadTypeData === 10) {
        this.payloadTypeData = [
          { text: 'Shine', value: 1, selected: false },
          { text: 'Oleeo', value: 2, selected: false },
          { text: 'Jobtrain', value: 3, selected: false },
          { text: 'Custom', value: 10, selected: true }
        ]
        this.eventSubscription.PayloadTypeData = 10
        this.plValue = 10
      }

      // don't need this, we are currently only supporting REST and JSON
      // if (this.eventSubscription.ConnectionType == 1) {
      //        this.payloadType = [
      // { text: 'JSON', value: 1, selected: true },
      // ]
      // this.eventSubscription.PayloadType = 1
      // }
      
      if (this.eventSubscription.ConnectionType == 2) {
        this.payloadType = [
          { text: 'XML', value: 2, selected: true },
        ]
        this.eventSubscription.PayloadType = 2
      } else {
        this.payloadType = [
          { text: 'JSON', value: 1, selected: true },
        ]
        this.eventSubscription.PayloadTypeData = 1
      }
    },
    payloadTypeSelect (value) {
      this.eventSubscription.PayloadType = value

      if (this.$parent.currentPage === 'dataForm') {
        if (this.eventSubscription.PayloadType < 1) {
          this.isValidated = false 
        } else {
          this.isValidated = true
        }
      }

      if (this.eventSubscription.ConnectionType == 1) {
        this.payloadType = [
          { text: 'JSON', value: 1, selected: true },
        ]
      }
      if (this.eventSubscription.ConnectionType == 2) {
        this.payloadType = [
          { text: 'XML', value: 2, selected: true },
        ]
      }
    },
    payloadTypeDataSelect (value) {
      this.eventSubscription.PayloadTypeData = value
      this.plValue = value
    }
  }
}
</script>

