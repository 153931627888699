<template>
  <div>
    <div class="d-flex align-items-center justify-content-between darker-grey-text flex-wrap">
      <div
        v-for="(type, i) in inputTypes"
        :key="i"
        style="width:fit-content;"
        class="mx-1 my-2">
        <div class="custom-control custom-radio">
          <input
            @change="() => changed(type)"
            type="radio"
            class="custom-control-input"
            :name="`radioGroup`"
            :id="`${i}`">
          <label class="custom-control-label" :for="`${i}`">{{type.text}}</label>
        </div>
      </div>
    </div>
    <div
      v-if="currentCustomFormField.Type"
      style="background: #fff"
      class="d-flex align-items-center flex-column mt-2 mb-2">
      <div class="md-form d-flex align-items-center">
        <i class="fas fa-pen prefix" style="margin-top: 25px;font-size: 1.3rem;" />
        <mdb-input
          v-if="currentCustomFormField.Type"
          v-model="text"
          :type="currentCustomFormField.Type"
          size="sm"
          :rows="currentCustomFormField.Type == 'textarea' ? 3 : 1"
          auto-complete="deg"
          style="width: 300px; margin-left:33px; border: none !important; outline: none !important"
          :label="currentCustomFormField.Question"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue-demi';
import { useCandidate } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'TextInputDesign',
  setup () {
    const candidateStore = useCandidate()
    const { currentCustomFormField } = storeToRefs(candidateStore)
    const inputTypes = [
      { value: 'text', text: 'Single Line Text Field' },
      { value: 'textarea', text: 'Multi Line Text Field' },
      { value: 'number', text: 'Number Field' },
    ]

    const changed = (value) => {
      currentCustomFormField.value.Type = value.value
      currentCustomFormField.value.ReadableType = value.text
      text.value = ''
    }

    const text = ref('')

    return {
      changed,
      inputTypes,
      currentCustomFormField,
      text
    }
  }
}
</script>
