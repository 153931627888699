const getRoleTableFields = (stageItemStatus, stageType, completedStatusType, mobileView, userLanguage, isSelfSchedule) => {

  // NOT INVITED - ALL STAGES
  if (stageItemStatus == 1) {
    return [
      { key: 'Selected', label: '', sortable: false, thClass: 'selectedColumnRolesTable', thStyle: { width: '40px' } },
      { key: 'Name', label: '  Candidate', tdClass: 'sortIcon', sortable: true },
      { key: 'ExternalCandidateReference', label: '  Ref.', tdClass: 'sortIcon', sortable: true },
      { key: 'Actions', label: '', tdClass: ' ', sortable: false }
    ]
  }





  // ONE WAY
  else if (stageType === 1) {
    // INVITED
    if (stageItemStatus == 2) {
      if (mobileView) {
        return [
          { key: 'Selected', label: '', sortable: false, thClass: 'selectedColumnRolesTable', thStyle: { width: '40px' } },
          { key: 'Name', label: '  Candidate', tdClass: 'sortIcon', sortable: true },
          { key: 'Actions', label: '', tdClass: 'p-0', sortable: false }
        ]
      } else {
        return [
          { key: 'Selected', label: '', sortable: false, thClass: 'selectedColumnRolesTable', thStyle: { width: '40px' } },
          { key: 'Name', label: '  Candidate', tdClass: 'sortIcon', sortable: true },
          { key: 'ExternalCandidateReference', label: '  Ref.', tdClass: 'sortIcon', sortable: true },
          { key: 'InvitationCode', label: '  Code', tdClass: 'sortIcon', sortable: false },
          { key: 'DateInvited', label: '  Invited', tdClass: ' sortIcon', sortable: true, sortByFormatted: false, formatter: (value) => { return new Date(value).toLocaleDateString([userLanguage]) } },
          { key: 'DateExpired', label: '  Expires', tdClass: ' sortIcon', sortable: true, sortByFormatted: false, formatter: (value) => { return new Date(value).toLocaleDateString([userLanguage]) } },
          { key: 'Actions', label: '', tdClass: '', sortable: false }
        ]
      }
    }
    // EXPIRED
    else if (stageItemStatus === 5) {
      if (mobileView) {
        return [
          { key: 'Selected', label: '', sortable: false, thClass: 'selectedColumnRolesTable', thStyle: { width: '40px' } },
          { key: 'Name', label: '  Candidate', tdClass: 'sortIcon', sortable: true },
          { key: 'Actions', label: '', tdClass: 'p-0', sortable: false }
        ]
      } else {
        return [
          { key: 'Selected', label: '', sortable: false, thClass: 'selectedColumnRolesTable', thStyle: { width: '40px' } },
          { key: 'Name', label: '  Candidate', tdClass: 'sortIcon', sortable: true },
          { key: 'InvitationCode', label: '  Code', tdClass: 'sortIcon', sortable: false },
          { key: 'ExternalCandidateReference', label: '  Ref.', tdClass: 'sortIcon', sortable: true },
          { key: 'DateInvited', label: '  Invited', tdClass: ' sortIcon', sortable: true, sortByFormatted: false, formatter: (value) => { return new Date(value).toLocaleDateString([userLanguage]) } },
          { key: 'DateExpired', label: '  Expired', tdClass: ' sortIcon', sortable: true, sortByFormatted: false, formatter: (value) => { return new Date(value).toLocaleDateString([userLanguage]) } },
          { key: 'Actions', label: '', tdClass: '', sortable: false }
        ]
      }
    }
    // COMPLETED
    else if (completedStatusType && stageType != 3) {
      if (mobileView) {
        return [
          { key: 'Selected', label: '', sortable: false, thClass: 'selectedColumnRolesTable', thStyle: { width: '40px' } },
          { key: 'Name', label: '  Candidate', tdClass: 'sortIcon', sortable: true },
          { key: 'Actions', label: '', tdClass: 'p-0', sortable: false }
        ]
      } else {
        return [
          { key: 'Selected', label: '', sortable: false, thClass: 'selectedColumnRolesTable', thStyle: { width: '40px' } },
          { key: 'Name', label: '  Candidate', tdClass: 'sortIcon', sortable: true },
          { key: 'ExternalCandidateReference', label: '  Ref.', tdClass: 'sortIcon', sortable: true },
          { key: 'InvitationCode', label: '  Code', tdClass: 'text-nowrap sortIcon', sortable: false },
          { key: 'DateInvited', label: '  Invited', tdClass: ' sortIcon', sortable: true, sortByFormatted: false, formatter: (value) => { return new Date(value).toLocaleDateString([userLanguage]) } },
          { key: 'DateCompleted', label: '  Completed', tdClass: 'text-nowrap sortIcon', sortable: true, sortByFormatted: false, formatter: (value) => value !== null ? new Date(value).toLocaleDateString([userLanguage]) : '' },
          { key: 'Actions', label: '', tdClass: 'pl-2', sortable: false }
        ]
      }
    }

    // IN PROGRESS
    else if (stageItemStatus === 4) {
      if (mobileView) {
        return [
          { key: 'Selected', label: '', sortable: false, thClass: 'selectedColumnRolesTable', thStyle: { width: '40px' } },
          { key: 'Name', label: '  Candidate', tdClass: 'sortIcon', sortable: true },
          { key: 'Actions', label: '', tdClass: 'p-0', sortable: false }
        ]
      } else {
        return [
          { key: 'Selected', label: '', sortable: false, thClass: 'selectedColumnRolesTable', thStyle: { width: '40px' } },
          { key: 'Name', label: '  Candidate', tdClass: 'sortIcon', sortable: true },
          { key: 'ExternalCandidateReference', label: '  Ref.', tdClass: 'sortIcon', sortable: true },
          { key: 'InvitationCode', label: '  Code', tdClass: 'text-nowrap sortIcon', sortable: false },
          { key: 'DateInvited', label: '  Invited', tdClass: ' sortIcon', sortable: true, sortByFormatted: false, formatter: (value) => { return new Date(value).toLocaleDateString([userLanguage]) } },
          { key: 'Actions', label: '', tdClass: 'pl-2', sortable: false }
        ]
      }
    }





  // SELF SCHEDULED TWO WAY
  } else if (stageType === 2 && isSelfSchedule) {
    // INVITED or EXPIRED
    if (stageItemStatus == 2 || stageItemStatus == 5) {
      if (mobileView) {
        return [
            { key: 'Selected', label: '', sortable: false, thClass: 'selectedColumnRolesTable', thStyle: { width: '40px' } },
            { key: 'Name', label: '  Candidate', tdClass: 'sortIcon', sortable: true },
            { key: 'Actions', label: '', tdClass: '', sortable: false }
          ]
        } else {
        return [
            { key: 'Selected', label: '', sortable: false, thClass: 'selectedColumnRolesTable', thStyle: { width: '40px' } },
            { key: 'Name', label: '  Candidate', tdClass: 'sortIcon', sortable: true },
            { key: 'DateInvited', label: '  Invited', tdClass: ' sortIcon', sortable: true, sortByFormatted: false, formatter: (value) => { return new Date(value).toLocaleDateString([userLanguage]) } },
            { key: 'DateExpired', label: '  Expires', tdClass: ' sortIcon', sortable: true, sortByFormatted: false, formatter: (value) => { return value ? new Date(value).toLocaleDateString([userLanguage]) : 'N/A' } },
            { key: 'Actions', label: '', tdClass: '', sortable: false }
          ]
      }
    // SCHEDULED OR COMPLETED
    } else if (stageItemStatus == 3 || stageItemStatus >= 6) {
      if (mobileView) {
        return [
            { key: 'Selected', label: '', sortable: false, thClass: 'selectedColumnRolesTable', thStyle: { width: '40px' } },
            { key: 'Name', label: '  Candidate', tdClass: 'sortIcon', sortable: true },
            { key: 'Actions', label: '', tdClass: '', sortable: false }
          ]
        } else {
        return [
            { key: 'Selected', label: '', sortable: false, thClass: 'selectedColumnRolesTable', thStyle: { width: '40px' } },
            { key: 'Name', label: '  Candidate', tdClass: 'sortIcon', sortable: true },
            { key: 'BookingDate', label: '  Booking Slot', tdClass: ' sortIcon', sortable: false },
            { key: 'BookedWithUser', label: '  Timeslot With User', tdClass: ' sortIcon', sortable: false },
            { key: 'Actions', label: '', tdClass: '', sortable: false }
          ]
      }
    }





  // REGULAR TWO WAY
  } else if (stageType === 2 && !isSelfSchedule) {
    if (mobileView) {
      return [
          { key: 'Selected', label: '', sortable: false, thClass: 'selectedColumnRolesTable', thStyle: { width: '40px' } },
          { key: 'Name', label: '  Candidate', tdClass: 'sortIcon', sortable: true },
          { key: 'Actions', label: '', tdClass: '', sortable: false }
        ]
      } else {
      return [
          { key: 'Selected', label: '', sortable: false, thClass: 'selectedColumnRolesTable', thStyle: { width: '40px' } },
          { key: 'Name', label: '  Candidate', tdClass: 'sortIcon', sortable: true },
          { key: 'ExternalCandidateReference', label: '  Ref.', tdClass: 'sortIcon', sortable: true },
          { key: 'DateInvited', label: '  Invited', tdClass: ' sortIcon', sortable: true, sortByFormatted: false, formatter: (value) => { return new Date(value).toLocaleDateString([userLanguage]) } },
          { key: 'InterviewDate', label: '  Interview Date / Time', tdClass: ' sortIcon', sortable: true, sortByFormatted: false, formatter: (value) => { return new Date(value).toLocaleString(undefined, { day: 'numeric', month: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit' }) } },
          { key: 'Actions', label: '', tdClass: '', sortable: false }
        ]
    }





  // EVENT STAGE
  } else if (stageType === 3) {
    // INVITED or EXPIRED
    if (stageItemStatus == 2 || stageItemStatus == 5) {
      if (mobileView) {
        return [
            { key: 'Selected', label: '', sortable: false, thClass: 'selectedColumnRolesTable', thStyle: { width: '40px' } },
            { key: 'Name', label: '  Candidate', tdClass: 'sortIcon', sortable: true },
            { key: 'Actions', label: '', tdClass: '', sortable: false }
          ]
        } else {
        return [
            { key: 'Selected', label: '', sortable: false, thClass: 'selectedColumnRolesTable', thStyle: { width: '40px' } },
            { key: 'Name', label: '  Candidate', tdClass: 'sortIcon', sortable: true },
            { key: 'DateInvited', label: '  Invited', tdClass: ' sortIcon', sortable: true, sortByFormatted: false, formatter: (value) => { return new Date(value).toLocaleDateString([userLanguage]) } },
            { key: 'DateExpired', label: '  Expires', tdClass: ' sortIcon', sortable: true, sortByFormatted: false, formatter: (value) => { return value ? new Date(value).toLocaleDateString([userLanguage]) : 'N/A' } },
            { key: 'Actions', label: '', tdClass: '', sortable: false }
          ]
      }
    } else if (stageItemStatus == 3 || stageItemStatus >= 6) {
      if (mobileView) {
        return [
            { key: 'Selected', label: '', sortable: false, thClass: 'selectedColumnRolesTable', thStyle: { width: '40px' } },
            { key: 'Name', label: '  Candidate', tdClass: 'sortIcon', sortable: true },
            { key: 'Actions', label: '', tdClass: '', sortable: false }
          ]
        } else {
        return [
            { key: 'Selected', label: '', sortable: false, thClass: 'selectedColumnRolesTable', thStyle: { width: '40px' } },
            { key: 'Name', label: '  Candidate', tdClass: 'sortIcon', sortable: true },
            { key: 'BookingDate', label: '  Booking Slot', tdClass: ' sortIcon', sortable: false },
            { key: 'BookedWithUser', label: '  Timeslot With User', tdClass: ' sortIcon', sortable: false },
            { key: 'Actions', label: '', tdClass: '', sortable: false }
          ]
      }
    }
  }
  return []
}

export default getRoleTableFields
