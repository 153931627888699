import { ref } from 'vue'
import { defineStore } from 'pinia'
import libraryService from '@/api/services/library.service.js'
import { useStatus, } from './useStatus'

export const useLibrary = defineStore('libraryStore', () => {
  const statusStore = useStatus()
  const action = ref('')
  const libraryNumbers = ref([])

  const showAddInsertVideoModal = ref(false)
  // CHOOSE VIDEO MODAL PANEL
  const chooseVideoLibrary = ref([])
  const getVideoQuestionLibrary = async (filterType) => {
    statusStore.setLoading({ isLoading: true, message: 'Getting Video Library' })
    try {
      const res = await libraryService.getLibrary(filterType, -1)
      if (res.data == null || res.data === '') {
        chooseVideoLibrary.value = []
      } else {
        chooseVideoLibrary.value = res.data
      }
    } catch (err) {
      chooseVideoLibrary.value = []
    }
    statusStore.setLoading({ isLoading: false, message: '' })
  }



  return {
    action,
    libraryNumbers,
    chooseVideoLibrary,
    getVideoQuestionLibrary,
    showAddInsertVideoModal
  }
})
