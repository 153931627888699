<template>
  <div class="d-flex align-items-center my-auto">
    <button
      v-if="showSetStatusButtons == false"
      :title="actionButtonTitle"
      type="button"
      class="btn btn-primary mobilePlacement btn-md m-0"
      style="padding: 0.4rem 0.6rem;"
      @click="actionButtonFunc()"
          >
      <span class="d-none d-xl-inline">{{actionButtonText}}</span>
      <span class="d-inline d-xl-none"><i :class="actionButtonIcon" /></span>
    </button>
    <div
        v-else
        class="flex flex-row space-x-4 my-auto"
      >
        <button
          v-for="(status, index) in completedStatuses"
          :key="status.RoleWorkflowStageItemId"
          type="button"
          class="btn btn-md m-0"
          :class="index === 0 ? 'btn-primary mr-2' : 'btn-outline'"
          style="padding: 0.4rem 0.5rem;"
          @click="bulkSetCandidateStatusLocal(status.RoleWorkflowStageItemId)"
        >
          <span>{{ status.RoleWorkflowStageItemName }}</span>
        </button>
      </div>
  </div>
</template>

<script>
import { useRoleCandidate, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue-demi'
import moment from 'moment'

export default {
  name: 'EventStageActionButton',
  props: {
    candidate: {
      type: Object,
      default: () => {}
    },
    stageItemStatus: {
      type: Number,
      default: 0
    },
    roleWorkflowStage: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {

    const workflowStore = useWorkflow()
    const { selectedWorkflowStage } = storeToRefs(workflowStore)

    const roleCandidateStore = useRoleCandidate()
    const { resendSchedulerInviteEmail, bulkSetCandidateStatus, resetSchedulerInvite } = useRoleCandidate()
    const { candidate: piniaCandidate } = storeToRefs(roleCandidateStore)

    const bookingIsToday = computed(() => moment(props.candidate.BookingDate).isSame(moment(), 'day'))
    const bookingIsInPast = computed(() => moment().isAfter(moment(props.candidate.BookingDate), 'minute'))

    const showResendSchedulerEmail = computed(() => props.stageItemStatus == 2)
    const showResetSchedulerInvite = computed(() => props.stageItemStatus == 5 || (props.stageItemStatus == 3 && (!bookingIsInPast.value && !bookingIsToday.value)))
    const showViewModal = computed(() => props.stageItemStatus >= 6)


    const resendSchedulerEmailLocal = async () => {
      piniaCandidate.value = props.candidate
      await resendSchedulerInviteEmail(props.candidate, props.roleWorkflowStage.RoleWorkflowStageId, () => emit('updateComponentContext'))
    }

    const resetSchedulerInviteLocal = async () => {
      piniaCandidate.value = props.candidate
      await resetSchedulerInvite(props.candidate.SchedulerInvitationCode, () => emit('updateComponentContext'))
    }

    const viewModal = () => {
      piniaCandidate.value = props.candidate
      emit('initialiseReviewModal', props.candidate)
    }

    const actionButtonText = ref('')
    const actionButtonFunc = ref(() => {})
    const actionButtonIcon = ref('')
    const actionButtonTitle = ref('')

    if (showResendSchedulerEmail.value) {
      actionButtonText.value = 'RESEND INVITE'
      actionButtonFunc.value = resendSchedulerEmailLocal
      actionButtonIcon.value = 'fa fa-envelope'
      actionButtonTitle.value = 'Resend the email invite for the scheduler'
    } else if (showResetSchedulerInvite.value) {
      actionButtonText.value = 'RESET BOOKING'
      actionButtonFunc.value = resetSchedulerInviteLocal
      actionButtonIcon.value = 'fa fa-sync' // CHECK THIS
      actionButtonTitle.value = 'Delete the booking and send new scheduler invite'
    } else if (showViewModal) {
      actionButtonText.value = 'VIEW CANDIDATE'
      actionButtonFunc.value = viewModal
      actionButtonIcon.value = 'fa fa-tv' // CHECK THIS
      actionButtonTitle.value = 'View candidates information'
    }

    // when double buttons required
    const completedStatuses = ref(props.roleWorkflowStage.OrderedRoleWorkflowStageItems.filter(f => f.StageItemStatus == 6))
    const showSetStatusButtons = ref(props.stageItemStatus == 3 && (bookingIsToday.value || bookingIsInPast.value))
    const bulkSetCandidateStatusLocal = async (stageItemId) => {
      await bulkSetCandidateStatus(stageItemId, () => emit('updateComponentContext'), props.candidate.ApplicantId)
    }

    return {
      actionButtonText,
      actionButtonFunc,
      actionButtonIcon,
      actionButtonTitle,
      showSetStatusButtons,
      completedStatuses,
      bulkSetCandidateStatusLocal
    }
  }
}
</script>

