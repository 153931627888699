<template>
  <div
  >
    <CopyToClipboardPopoverButton
      v-for="(button,i) in stringToCopy"
      :key="i"
      :string-to-copy="button.url"
      :button-text="button.text"
    />


    <!-- EXTEND DEADLINE -->
    <h6
      v-if="showExtendInviteDeadline"
      @click="extendInviteDeadlineLocal(data.item)"
    >
      <FontAwesomeIcon
        icon="fa-solid fa-arrow-right-to-line"
        class="grey-link ml-1"
        style="margin-right:7px;"
      />
      <a class="grey-link">Extend Deadline</a>
    </h6>


    <!-- OPEN THE SCHEDULER -->
    <h6
      v-if="showRescheduleThroughScheduler"
      @click="openScheduler"
    >
      <i class="fa fa-calendar ml-1 grey-link" style="margin-right:7px;" />
      <a class="grey-link"> Open Scheduler </a>
    </h6>


    <!-- RESET SCHEDULER INVITE -->
    <h6
      v-if="showResetSchedulerInvite"
      @click="resetSchedulerInviteLocal(data.item)"
      title="Delete the booking and send new scheduler invite email"
    >
      <i class="fa fa-sync ml-1 grey-link" style="margin-right:7px;" />
      <a class="grey-link"> Reset Booking</a>
    </h6>

    <!-- UNINVITE -->
    <h6
      v-if="showUninvite"
      @click="uninvite(data.item)">
      <i class="fa fa-times ml-1 grey-link" style="margin-right:10px;" />
      <a class="grey-link"> Uninvite</a>
    </h6>
  </div>

</template>

<script>
import { computed } from 'vue'
import { useUserData, useStatus, useRoleCandidate, useScheduler, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'
import { RNYID, RAFID } from '@/common/config.js'

import CopyToClipboardPopoverButton from '@/components/misc/copyToClipboardPopoverButton.vue'

import { SCHEDULER_URL } from '@/common/config.js'
import moment from 'moment'

export default {
  name: 'EventPopoverButton',
  components: {
    CopyToClipboardPopoverButton
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)

    const workflowStore = useWorkflow()
    const { role } = storeToRefs(workflowStore)

    const statusStore = useStatus()
    const { showGenericConfirmModal } = statusStore

    const roleCandidateStore = useRoleCandidate()
    const { candidateTableData, candidate, selectedCandidates, resetSchedulerInvite, extendInviteDeadlineModalShowing } = storeToRefs(roleCandidateStore)

    const schedulerStore = useScheduler()
    const { selectedSchedulerSetup } = storeToRefs(schedulerStore)

    const stageItemStatus = computed(() => candidateTableData?.value?.StageItemStatus)

    const bookingIsToday = computed(() => moment(props.data.item.BookingDate).isSame(moment(), 'day'))
    const bookingIsInPast = computed(() => moment().isAfter(moment(props.data.item.BookingDate), 'minute'))
    const isRNUser = computed(() => (userData.value.mid === RNYID || userData.value.mid === RAFID) && userData.value.rle == 'User')

    // Return popover text and urls
    const stringToCopy = computed(() => {
      if ((stageItemStatus.value == 2 || stageItemStatus.value == 3) && !isRNUser.value) {
        return [{ url: `${SCHEDULER_URL}/${selectedSchedulerSetup.value.ScheduleUrl}?id=${props.data.item.SchedulerInvitationCode}&gu=${userData.value.uid}`, text: 'Copy Scheduler Link' }]
      } else return []
    })

    const showResetSchedulerInvite = computed(() => (stageItemStatus.value == 3 && (bookingIsToday.value || bookingIsInPast.value)) || stageItemStatus.value >= 6)
    const showRescheduleThroughScheduler = computed(() => stageItemStatus.value == 3 && (bookingIsToday.value || (!bookingIsInPast.value && !bookingIsToday.value)))
    const showUninvite = computed(() => stageItemStatus.value < 6)
    const showExtendInviteDeadline = computed(() => !isRNUser.value && stageItemStatus.value == 2 && !role.value.IsEvergreen)

    return {
      SCHEDULER_URL,
      selectedSchedulerSetup,
      candidate,
      stringToCopy,
      showResetSchedulerInvite,
      showRescheduleThroughScheduler,
      showUninvite,
      showGenericConfirmModal,
      resetSchedulerInvite,
      selectedCandidates,
      userData,
      showExtendInviteDeadline,
      extendInviteDeadlineModalShowing
    }
  },
  methods: {
    extendInviteDeadlineLocal (candidate) {
      this.candidate = candidate
      this.extendInviteDeadlineModalShowing = true
    },
    openScheduler () {
      window.open(SCHEDULER_URL + '/' + this.selectedSchedulerSetup.ScheduleUrl + '?id=' + this.data.item.SchedulerInvitationCode + '&gu=' + this.userData.uid)
    },
    uninvite (candidate) {
      this.candidate = candidate
      this.selectedCandidates = []
      this.selectedCandidates.push(candidate.CandidateId)
      const message = `Are you sure you want to uninvite this candidate from the role? <br /><br />`
      const title = 'CONFIRM UNINVITE'
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: title,
        confirmMessage: message,
        confirmButtonMessage: 'UNINVITE',
        confirmButtonFunction: () => this.$emit('confirmUninviteSelected', candidate.CandidateId)
      })
    },
    async resetSchedulerInviteLocal (candidate) {
      this.candidate = candidate
      await this.resetSchedulerInvite(candidate.SchedulerInvitationCode, () => this.$emit('getRoleCandidates'))
    }
  }
}
</script>
