<template>
  <div class="min-h-full">
    <div v-if="mobileView" style="margin-top: 60px;text-align: left;margin-left: 15px;">
      <h3 class="primary-colour font-weight-bold mr-2">
        Event Subscriptions
      </h3>
      <p class="text-left grey-text" style="margin-top: 10px;font-size:small;">
        Presently, event subscription functions are only available in desktop devices.
      </p>
    </div>
    <div v-else>
      <div class="flex flex-row items-center">
        <div
          v-if="eventSubscriptionsLoading"
          style="margin-left:40px;height:80px;"
          class="d-flex align-items-center"
        >
          <h3 class="primary-colour font-weight-bold mr-2">
            Loading
          </h3> <i class="fa fa-spin fa-spinner" />
        </div>
        <div
          v-else
          style="margin-left:40px;height:80px;"
          class="d-flex align-items-center"
        >
          <h3 class="primary-colour font-weight-bold mr-2">
            Event Subscriptions
          </h3>
        </div>
      </div>
      <div v-show="eventSubscriptionsLoading === false">
        <div
          v-if="eventSubscriptions.length > 0"
          class="px-10"
        >
          <b-table
            class="text-left"
            head-variant="dark"
            :items="eventSubscriptions"
            :fields="fields"
            :per-page="eventSubscriptionsPerPage"
            :current-page="eventSubscriptionsCurrentPage"
            hover
            small
            sort-icon-left
            responsive="sm"
          >
            <template #cell(EventSubscriptionId)="data">
              <div
                style="padding-top:10px;margin-left:5px;cursor:pointer;"
                class="fiftyWide smFont"
                :title="'Event Subscription Id: ' + data.item.EventSubscriptionId"
              >
                <font-awesome-icon
                  :icon="['fas', 'gears']"
                  class="primary-colour text-lg"
                />
              </div>
            </template>
            <template #cell(Name)="data">
              <div
                style="padding-top:10px;margin-left:5px;"
                class="fiftyWide smFont"
              >
                {{ data.item.Name }}
              </div>
            </template>
            <template #cell(DateAdded)="data">
              <div
                style="padding-top:10px;margin-left:5px;"
                class="fiftyWide smFont"
              >
                {{ new Date(data.item.DateAdded).toLocaleDateString([userLanguage]) }}
              </div>
            </template>
            <template #cell(Totals)="data">
              <div
                style="padding-top:10px;margin-left:5px;"
                class="fiftyWide smFont"
              >
                {{ data.item.Totals }}
              </div>
            </template>
            <template #cell(Actions)="data">
              <div
                class="flex items-center justify-end"
                style="cursor:pointer;"
              >
                <div v-if="data.item.Complete">
                  <mdb-btn
                    v-if="data.item.Enabled"
                    class="btn-sm h-8"
                    style="background:green !important;color:white; !important;width:130px;border-radius:40px;"
                    @click="showEnableModal(data.item)"
                  >
                    RUNNING
                  </mdb-btn>
                  <mdb-btn
                    v-else
                    class="btn-sm h-8"
                    style="background:red !important;color:white !important;width:130px;border-radius:40px;"
                    @click="showEnableModal(data.item)"
                  >
                    NOT RUNNING
                  </mdb-btn>
                </div>
                <div v-else>
                  <mdb-btn
                    class="btn-sm h-8"
                    style="background:grey !important;color:white !important;width:130px;border-radius:40px;opacity:0.5;"
                    @click="edit(data.item)"
                  >
                    INCOMPLETE
                  </mdb-btn>
                </div>
                <mdb-popover
                  ref="popover"
                  trigger="click"
                  :options="{placement: 'left'}"
                  style="cursor:pointer;align-self:center;"
                  class="grey-text"
                >
                  <div slot="body">
                    <h6
                      class="d-flex mb-2"
                      style="align-items:center"
                      @click="edit(data.item)"
                    >
                      <i class="fa fa-pen ml-1 grey-link" style="margin-right:7px;" /><a class="grey-link mr-3 text-nowrap">Edit</a>
                    </h6>
                    <h6
                      class="d-flex mb-2"
                      style="align-items:center"
                      @click="showDeleteModal(data.item)"
                    >
                      <i class="fa fa-trash ml-1 grey-link" style="margin-right:7px;" /><a class="grey-link mr-3 text-nowrap">Delete</a>
                    </h6>
                    <div v-if="data.item.Complete">
                      <h6
                        v-if="data.item.Enabled"
                        class="d-flex mb-2"
                        style="align-items:center"
                        @click="showEnableModal(data.item)"
                      >
                        <i class="fa fa-stop ml-1 grey-link" style="margin-right:7px;" /><a class="grey-link mr-3 text-nowrap">Stop</a>
                      </h6>
                      <h6
                        v-else
                        class="d-flex mb-2"
                        style="align-items:center"
                        @click="showEnableModal(data.item)"
                      >
                        <i class="fa fa-play ml-1 grey-link" style="margin-right:7px;" /><a class="grey-link mr-3 text-nowrap">Start</a>
                      </h6>
                      <h6
                        class="d-flex mb-2"
                        style="align-items:center"
                        @click="showTestModal(data.item)"
                      >
                        <i class="fa fa-code ml-1 grey-link" style="margin-right:7px;" /><a class="grey-link mr-3 text-nowrap">Test Connection</a>
                      </h6>
                    </div>
                  </div>
                  <mdb-icon
                    slot="reference"
                    style="color:#555;font-size:1rem;"
                    class="ellipsisButtonHover mx-2"
                    icon="ellipsis-v"
                  />
                </mdb-popover>
              </div>
            </template>
          </b-table>

          <b-pagination
            v-show="eventSubscriptions.length > 5"
            v-model="eventSubscriptionsCurrentPage"
            class="float-right mt-4"
            :total-rows="eventSubscriptionsRows"
            :per-page="eventSubscriptionsPerPage"
            first-number
            last-number
            aria-controls="notifications-table"
            prev-text="<"
            next-text=">"
            style="margin-bottom:30px;"
          />
          <div
            v-show="mobileView === false"
            md="2"
            offset-md="10"
          >
            <button
              v-if="eventSubscriptions.length > 0"
              style="float: left;margin-right: 0px;width: 110px;padding: 6px !important;text-align: center;margin-top:25px;"
              title="Add subscription"
              class="mdc-button btn"
              @click="add(null)"
            >
              <span class="secondary-colour"><i class="fa fa-plus-circle" /> Add</span>
            </button>
          </div>
        </div>
        <div
          v-else
          class="px-10 text-left grey-text"
          style="margin-left:0px;"
        >
          You have no saved subscriptions. Click <a
            class="primary-colour"
            style="font-weight:bold;"
            @click="add()"
          ><em>here</em></a> to create a new one.
        </div>
      </div>
      <mdb-modal
        id="genericInfoModal"
        :show="infoModal"
        @close="infoModal = false"
      >
        <mdb-modal-header>
          <mdb-modal-title>{{ infoTitle.toUpperCase() }}</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body>
          <div
            style="word-break: break-all;max-height:400px;overflow-y:auto"
          >
            <div v-if="success">
              <p class="my-4" style="margin-top:5px !important;text-align:left;">
                Success
              </p>
              <p
                class="my-4 grey-text"
                style="margin-top:-10px !important;font-size:0.8rem;text-align:left;"
                v-html="infoMessage"
              />
            </div>
            <div v-else>
              <p class="my-4" style="margin-top:5px !important;text-align:left;">
                Failed
              </p>
              <p
                class="my-4 grey-text"
                style="margin-top:-10px !important;font-size:0.8rem;text-align:left;"
                v-html="infoMessage"
              />
            </div>
          </div>
        </mdb-modal-body>
        <mdb-modal-footer>
          <mdb-btn
            color="primary"
            size="sm"
            style="width:100px;"
            @click.native="infoModal = false"
          >
            CLOSE
          </mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useEventSubscription, useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router/composables'
import { userLanguage } from '@/common/userLanguage'
import { useMobile } from '@/composables/useMobile'
import eventSubscriptionService from '@/api/services/eventsubscription.service'

export default {
  name: 'EventSubscriptions',
  setup () {
    const router = useRouter()
    const userStore = useUserData()
    const { userData, authData } = storeToRefs(userStore)
    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()
    const { mobileView } = useMobile()

    const eventSubscriptionStore = useEventSubscription()
    const { defaultEventSubscription } = useEventSubscription()
    var { eventSubscription } = storeToRefs(eventSubscriptionStore)

    const add = () => {
      eventSubscription.value = {
        RequireAuthentication: true, // This needs to be before any authentication variables.
        EventSubscriptionId: 0,
        Name: '',
        OneWayCompletion: false,
        OneWayInviteExpiry: false,
        OneWayReset: false,
        OneWayAssessorScoring: false,
        OneWayModeratorScoring: false,
        OneWayStatusChange: false,
        SchedulerBooking: false,
        SchedulerInviteExpiry: false,
        SchedulerCancellation: false,
        SchedulerStatusChange: false,
        TwoWayCompletion: false,
        TwoWayAssessorScoring: false,
        TwoWayModeratorScoring: false,
        TwoWayStatusChange: false,
        TwoWayBooking: false,
        TwoWayInviteExpiry: false,
        TwoWayCancellation: false,
        ConnectionType: 0,
        ConnectionMethod: 0,
        ConnectionUrl: '',
        ClientId: '',
        ClientSecret: '',
        ServiceEndPoint: '',
        Scope: '',
        DateAdded: null,
        Enabled: false,
        PayloadType: 1,
        DeliveryMethod: 0,
        PayloadTypeData: 1
      }
      router.push('/settings/eventsubscriptions/add?page=events')
    }

    const eventSubscriptionsPerPage = ref(8)
    const eventSubscriptionsCurrentPage = ref(1)    

    const infoModal = ref(false)
    const infoTitle = ref('')
    const infoMessage = ref('')
    const success = ref(false)

    const edit = (sub) => {
      if (sub !== null) {
        eventSubscription.value = sub
      }
      router.push('/settings/eventsubscriptions/add?page=events')
    }
    const eventSubscriptions = ref([])
    const eventSubscriptionsLoading = ref(true)

    const fields = [
      { key: 'EventSubscriptionId', label: '', tdClass: ''},
      { key: 'Name', label: 'Subscription Name', tdClass: ''},
      { key: 'DateAdded', label: 'Added', tdClass: ''},
      { key: 'Totals', label: 'Subscribed Events', tdClass: ''},
      { key: 'Actions', label: '', tdClass: ''}
    ]

    return {
      add,
      edit,
      eventSubscriptions,
      fields,
      userData,
      authData,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal,
      userLanguage,
      eventSubscription,
      defaultEventSubscription,
      eventSubscriptionsLoading,
      eventSubscriptionsPerPage,
      eventSubscriptionsCurrentPage,
      mobileView,
      infoModal,
      infoTitle,
      infoMessage,
      success
    }
  },
  computed: {
    eventSubscriptionsRows () {
      return this.eventSubscriptions.length
    }
  },
  created() {
    //if (this.mobileView) {
    //  this.$router.push('/')
    //}
    this.getEventSubscriptions()
  },
  methods: {
    async getEventSubscriptions () {
      this.eventSubscriptionsLoading = true
      try {
        const res = await eventSubscriptionService.getEventSubscriptions()
        this.eventSubscriptions = res.data
        this.eventSubscriptionsLoading = false
      } catch {
        this.showGenericErrorModal({ showing: true })
        this.eventSubscriptionsLoading = false
      }
    },
    showDeleteModal (sub) {
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'DELETE',
        confirmMessage: 'Are you sure you want to delete this event subscription?',
        confirmButtonMessage: 'SUBMIT',
        confirmButtonFunction: () => this.confirmDelete(sub.EventSubscriptionId)
      })
    },
    async confirmDelete(id) {
      try {
        await eventSubscriptionService.deleteEventSubscription(id)
        this.getEventSubscriptions()
        this.closeGenericConfirmModal()
      } catch {
        this.showGenericErrorModal({ showing: true })
      }
    },
    showEnableModal (sub) {
      if (sub.Enabled) {
        this.showGenericConfirmModal({
          showing: true,
          confirmTitle: 'STOP SUBSCRIPTION',
          confirmMessage: 'Are you sure you want to turn off this subscription?',
          confirmButtonMessage: 'SUBMIT',
          confirmButtonFunction: () => this.confirmEnable(sub.EventSubscriptionId)
      })
      } else {
        this.showGenericConfirmModal({
          showing: true,
          confirmTitle: 'START SUBSCRIPTION',
          confirmMessage: 'Are you sure you want to turn on this subscription?',
          confirmButtonMessage: 'SUBMIT',
          confirmButtonFunction: () => this.confirmEnable(sub.EventSubscriptionId)
        })
      }
    },
    async confirmEnable(id) {
      try {
        await eventSubscriptionService.enableEventSubscription(id)
        this.getEventSubscriptions()
        this.closeGenericConfirmModal()
      } catch {
        this.showGenericErrorModal({ showing: true })
      }
    },
    showTestModal (sub) {
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'SEND TEST',
        confirmMessage: 'This test will take your saved oauth url and perform an authorisation request based on the parameters provided.',
        confirmButtonMessage: 'TEST',
        confirmButtonFunction: () => this.confirmTest(sub.EventSubscriptionId)
      })
    },
    async confirmTest(id) {
      try {
        const res = await eventSubscriptionService.oauth(id)
        this.closeGenericConfirmModal()
        this.success = true
        this.infoModal = true
        this.infoTitle = 'SUBSCRIPTION TEST',
        this.infoMessage = res.data
      } catch (e) {
        this.success = false
        this.infoModal = true
        this.infoTitle = 'SUBSCRIPTION TEST',
        this.infoMessage = e
      }
    }
  }
}
</script>
