<template>
  <div class="transitionRuleContainer d-flex align-items-center justify-content-center">
    <div class="transitionRuleInner d-flex align-items-center justify-content-center">
      <FontAwesomeIcon icon="fa-link fa-solid" style="scale: 2"/>
    </div>
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'TransitionRule',
  components: {
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const statusStore = useStatus()
    const { showGenericErrorModal } = statusStore

    return {
      authData,
      showGenericErrorModal
    }
  }
}
</script>

<style lang="scss" scoped>
.transitionRuleContainer {
  height: 100px;
  width: 100px;
  transform: rotate(45deg);
  border: 1px solid var(--pr-color);
}
.transitionRuleInner {
  height: 75px;
  width: 75px;
  transform: rotate(-45deg);
}
</style>
