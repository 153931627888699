import { ref } from 'vue'
import { defineStore } from 'pinia'
import notificationService from '@/api/services/notification.service'

export const useNotification = defineStore('notificationStore', () => {
  const action = ref('')
  const liveNotificationsShown = ref(false)
  const notificationsLoading = ref(false)
  const liveNotifications = ref([])
  const notifications = ref([])
  const notificationFilterType = ref('All')
  const redirectToOneWay = ref(false)
  const redirectToVideoCV = ref(false)
  const redirectToTwoWay = ref(false)
  const redirectToShareAnalytics = ref(false)
  const redirectData = ref([])

  // set method for each state
  // reset sate
  const setAction = (payload) => {
    action.value = payload
    setTimeout(() => { action.value = '' }, 50000)
  }
  const setLiveNotifications = (payload) => {
    liveNotifications.value = payload
    setTimeout(() => { liveNotifications.value = [] }, 10000)
  }

  const getLiveNotifications = async ({ token, terminateCallOnLogout, notificationInterval }) => {
    const call = async () => {
      try {
        liveNotificationsShown.value = false
        const res = await notificationService.getAllUserNotifications(true)
        if (res.data == null) {
          liveNotifications.value = []
        } else {
          liveNotifications.value = res.data
        }
      } catch (err) {
        //?
      }
    }

    // for the initial call
    if (!terminateCallOnLogout) call()

    // for every future call
    if (!terminateCallOnLogout) {
      const myInterval = setInterval(async () => {
        call()
      }, 300000)
      return myInterval
    }

    if (terminateCallOnLogout) clearInterval(notificationInterval)
  }


  return {
    action,
    getLiveNotifications,
    liveNotificationsShown,
    liveNotifications,
    notifications,
    notificationFilterType,
    notificationsLoading,
    redirectToOneWay,
    redirectToVideoCV,
    redirectToTwoWay,
    redirectToShareAnalytics,
    redirectData,
    setAction,
    setLiveNotifications,
  }
})
