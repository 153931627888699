<template>
  <!--history-->
  <div style="position:relative;background: none" class="flex-grow-1 reviewModalRHTab">
    <ViewCandidateHistory
      v-if="showVideoCVTab"
      :candidate-id="candidate.CandidateId"
    />
    <ViewRoleHistory
      v-else
      :candidate-id="candidate.CandidateId"
    />
  </div>
</template>

<script>
import ViewCandidateHistory from '@/components/misc/History/CandidateHistory/candidateHistory.vue'
import ViewRoleHistory from '@/components/misc/History/viewCandidateRoleHistory.vue'

export default {
  name: 'History',
  components: { ViewCandidateHistory, ViewRoleHistory },
  props: {
    candidate: {
      type: Object,
      default: () => {}
    },
    showVideoCVTab: Boolean
  }
}
</script>

<style lang="scss" scoped>

</style>
