<template>
 <ul class="nav nav-tabs d-flex justify-content-around font-weight-bold">
    <li
      v-for="(stage, i) in reviewModalRoleWorkflow.OrderedRoleWorkflowStages"
      :key="i"
      class="nav-item text-center text-nowrap flex-grow-1"
      @click="reviewModalSelectedStage = stage"
    >
      <a
        class="nav-link primary-colour"
        :class="stage.RoleWorkflowStageId != reviewModalSelectedStage.RoleWorkflowStageId ? 'active' : 'white-text font-weight-light'"
      >
          <FontAwesomeIcon
            class="mr-1"
            :icon="getIconFromStageType(stage.StageType)"
          />
        <span
          :class="{'d-none' : stage.RoleWorkflowStageId != reviewModalSelectedStage.RoleWorkflowStageId}"
          class="d-sm-inline mr-2"
        > {{ stage.RoleWorkflowStageName }}</span>
      </a>
    </li>
  </ul>
</template>

<script>
import { useRoleCandidate } from '@/pinia'
import { storeToRefs } from 'pinia'
import GET_ICON_FROM_STAGE_TYPE_ENUM from '@/utils/getFAIconCodeFromWorkflowStageTypeEnum.js'

export default {
  name: 'StageTabs',
  setup () {
    const roleCandidateStore = useRoleCandidate()
    const { reviewModalRoleWorkflow, reviewModalSelectedStage } = storeToRefs(roleCandidateStore)

    return {
      reviewModalRoleWorkflow,
      reviewModalSelectedStage
    }
  },
  methods: {
    getIconFromStageType (stageType) {
      return GET_ICON_FROM_STAGE_TYPE_ENUM(stageType)
    }
  }
}
</script>
