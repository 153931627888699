<template>
  <div
    class="col-sm templateDiv"
    style="margin-bottom:52px !important;"
    :class="{ 'notMargTop' : mobileView }"
  >
    <div style="text-align:left;padding:2px;margin-top:0px;" class="h-full">
      <div class="grid grid-cols-3 w-1/1 mt-8 border-bottom">
        <div
          class="cursor-pointer text-lg grey-text text-center"
          :class="{ 'default': section == 'roles' }"
          @click="setSection('roles')"
        >
          <span v-if="mobileView"><i class="fa fa-suitcase" /></span>
          <span v-else>ROLES</span>
        </div>
        <div
          class="cursor-pointer text-lg grey-text text-center"
          :class="{ 'default': section == 'candidates' }"
          @click="setSection('candidates')"
        >
          <span v-if="mobileView"><i class="fa fa-users" /></span>
          <span v-else>CANDIDATES</span>
        </div>
        <div
          class="cursor-pointer text-lg grey-text text-center"
          :class="{ 'default': section == 'generic' }"
          @click="setSection('generic')"
        >
          <span v-if="mobileView"><i class="fa fa-cog" /></span>
          <span v-else>GENERIC</span>
        </div>
      </div>
      <div v-if="section === 'roles'">
        <roles />
      </div>
      <div v-if="section === 'candidates'">
        <candidates />
      </div>
      <div v-if="section === 'generic'">
        <generic />
      </div>
    </div>
  </div>
</template>

<script>
import { useMobile } from '@/composables/useMobile'

import candidates from './Tabs/candidates.vue'
import roles from './Tabs/roles.vue'
import generic from './Tabs/generic.vue'

export default {
  name: '',
  components: {
    candidates,
    roles,
    generic
  },
  setup () {
    const { mobileView } = useMobile()

    return {
      mobileView
    }
  },
  data () {
    return {
      section: 'roles',
      reportsLoaded: false
    }
  },
  created () {
    if (this.userData.rle == 'Inviter') {
      window.location.assign('/roles')
    }  
  },
  methods: {
    setSection (type) {
      this.section = type
    }
  }
}
</script>

<style>
  ul.nav-tabs.nav-justified {
    background-color: var(--bg-color) !important;
  }
</style>

<style lang="scss" scoped>
.md-tabs {
  position: relative;
    z-index: 1;
    padding: 0rem !important;
    margin-right: -1rem !important;
    margin-bottom: -20px;
    margin-left: -1rem !important;
    background-color: #999;
    border: 0;
    border-radius: 0rem !important;
    box-shadow: none !important;
}
.repTop {
  margin-top: 10px;
}
.repLeft {
  margin-left: -19px;
}
.notMargTop {
  margin-top: -20px;
}
</style>
