import axiosInstance from '../instance.js'
const api1 = new axiosInstance(1)

export default class meetNowService {
  static async getMeetNowSummary () {
    return await api1.get('/api/go/getmeetnowsummary')
  }

  static async getAllAvailableRooms () {
    return await api1.get('/api/go/getallavailablemeetnowrooms')
  }

  static async getAllAvailablePrivateRooms () {
    return await api1.get('/api/go/getallavailableprivaterooms')
  }

  static async searchCandidatesToTag (search) {
    return await api1.get(`/api/go/searchcandidatestotagmeetnow?search=${search}`)
  }

  static async getMeetNowRecordings (recordingFilter) {
    return await api1.get(`/api/go/getmeetnowrecordings?recordingFilter=${recordingFilter}`)
  }

  static async getMeetNowRecording (recordingId) {
    return await api1.get(`/api/go/getmeetnowrecording?recordingId=${recordingId}`)
  }

  static async updateMeetNowRecording (updateType, recordingId) {
    return await api1.post(`/api/go/updatemeetnowrecording?updateType=${updateType}&recordingId=${recordingId}`, {})
  }

  static async tagCandidateOrAssessor (formData) {
    return await api1.post('/api/go/tagmeetnowcandidateorassessor', formData)
  }

  static async createMeetingRoom (formData) {
    return await api1.post('/api/go/creategomeetingroom', formData)
  }

  static async updateDeleteGoMeetNowRoom (roomId, setDefault) {
    return await api1.post(`/api/go/updatedeletegomeetnowroom?roomId=${roomId}&setDefault=${setDefault}`, {})
  }

  static async scheduleNow (candidateId, roomId, meetingDate, meetingTime) {
    return await api1.post('/api/go/schedulenow?candidateId=' + candidateId + '&roomId=' + roomId + '&meetingDate=' + meetingDate + '&meetingTime=' + meetingTime, {})
  }

  static async instantMeet (candidateId, roomId) {
    return await api1.post('/api/go/schedulenow?candidateId=' + candidateId + '&roomId=' + roomId, {})
  }

  static async updateGoMeetNowRecordingName (roomId, roomName) {
    return await api1.post('/api/go/updategomeetnowrecordingname?roomId=' + roomId + '&roomName=' + roomName, {})
  }

  static async resetRoom (roomId) {
    return await api1.post('/api/go/resetroom?roomId=' + roomId, {})
  }

  static async changeThumbnail (formData) {
    return await api1.post('/api/go/changethumbnail', formData)
  }

  static async editRoomDetails (roomId, roomName, isPublic, brandId) {
    return await api1.post('/api/go/editroomdetails?roomId=' + roomId + '&roomName=' + roomName + '&isPublic=' + isPublic + '&brandId=' + brandId, {})
  }
}
