<template>
  <div class="mt-3 mt-md-4">
    <!-- <div v-if="clients.length > 0"> -->
    <b-table
      id="clients-table"
      class="text-left text-center"
      style="width: 100%; max-width: 100%;"
      head-variant="dark"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :items="clients"
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      :striped="true"
      hover
      small
      sort-icon-left
    >
      <template #cell(name)="data">
        <div class="d-flex align-items-center">
          <img
            style="height:50px;max-height:50px;object-fit:cover;max-width:100px;object-fit:contain;"
            class="mr-2"
            :src="data.item.Logo"
            :style="data.item.Logo.includes('shine_logo_white') || data.item.Logo.includes('shine-logo-white') ? 'background: #FF7e00; padding: 5px' : ''"
          >
          <div class="ml-2">
            {{ data.item.Name }}
          </div>
        </div>
      </template>
      <template #cell(AddedBy)="data">
        <div style="height:50px;max-height:50px;" class="d-flex align-items-center justify-content-center">
          {{ data.item.AddedBy }}
        </div>
      </template>
      <template #cell(DateAdded)="data">
        <div style="height:50px;max-height:50px;" class="d-flex align-items-center justify-content-center">
          {{ new Date(data.item.DateAdded).toLocaleDateString(['en-GB']) }}
        </div>
      </template>
      <template #cell(actions)="data">
        <div class="d-flex align-items-center justify-content-end pt-0" style="height:50px;max-height:50px;">
          <mdb-btn
            size="sm"
            class="btn my-2 my-md-1 mx-2"
            style="width:fit-content;padding: 0.5rem 1.1rem;"
            @click="viewClient(data.item.GoClientPortalClientId)"
          >
            VIEW
          </mdb-btn>
          <mdb-popover
            ref="popover"
            trigger="click"
            :options="{placement: 'left'}"
            style="cursor:pointer;"
            class="grey-text"
          >
            <div slot="body" class="text-nowrap">
              <h6
                class="d-flex"
                style="align-items:center"
                @click="editClient(data.item.GoClientPortalClientId)"
              >
                <a><i class="fa fa-edit" style="margin-right:7px;" /> Edit</a>
              </h6>
              <h6
                class="d-flex"
                style="align-items:center"
                @click="showConfirmDeleteClientModal(data.item.GoClientPortalClientId)"
              >
                <a><i class="fa fa-trash" style="margin-right:7px;" /> Delete</a>
              </h6>
              <CopyToClipboardPopoverButton
                :string-to-copy="`${CLIENT_PORTAL_URL}/#/signin?clientId=${data.item.GoClientPortalClientId}`"
                button-text="Copy Client Portal Link"
                @closePopover="() => $refs.popover.doClose()"
              />
            </div>
            <mdb-icon
              slot="reference"
              style="color:#555;font-size:1rem;"
              class="ellipsisButtonHover mr-2"
              icon="ellipsis-v"
            />
          </mdb-popover>
        </div>
      </template>
    </b-table>
    <div v-if="clients.length === 0 && !clientsLoading" class="my-4 primary-colour font-weight-bold">
      Add a client to create a Spotlight
    </div>
    <div class="d-flex align-items-center mb-5">
      <div v-if="clients.length > perPage" class="mr-4">
        <b-pagination
          v-show="clients.length > perPage"
          v-model="currentPage"
          class="ml-auto"
          :total-rows="rows"
          :per-page="perPage"
          first-number
          last-number
          aria-controls="clients-table"
          prev-text="<"
          next-text=">"
        />
      </div>
      <div class="d-flex flex-wrap align-items-center justify-content-end" :style="{'width': clients.length >= rows ? '100%' : '' }">
        <mdb-btn
          size="sm"
          class="btn"
          style="min-width:110px;width:fit-content;padding: 0.5rem 1.1rem;"
          @click="$emit('showAddEditModal', 0)"
        >
          <i class="fa fa-plus-circle" /> ADD
        </mdb-btn>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import clientPortalService from '@/api/services/clientPortal.service'
import { CLIENT_PORTAL_URL } from '@/common/config.js'

import CopyToClipboardPopoverButton from '@/components/misc/copyToClipboardPopoverButton.vue'

export default {
  name: 'ClientViewTable',
  components: { CopyToClipboardPopoverButton },
  props: {
    clients: {
      type: Array,
      default: () => []
    },
    clientsLoading: Boolean
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()

    return {
      authData,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal
    }
  },
  data () {
    return {
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      perPage: 6,
      windowWidth: window.innerWidth
    }
  },
  computed: {
    CLIENT_PORTAL_URL () { return CLIENT_PORTAL_URL },
    fields () {
      if (this.windowWidth > 991) {
        return [
          { key: 'Name', label: 'Client', sortable: true },
          { key: 'AddedBy', label: 'Created By' },
          { key: 'DateAdded', label: 'Date Added' },
          { key: 'Actions', label: '' }
        ]
      } else if (this.windowWidth > 767) {
        return [
          { key: 'Name', label: 'Client', sortable: true },
          // { key: 'AddedBy', label: 'Created By' },
          { key: 'DateAdded', label: 'Date Added' },
          { key: 'Actions', label: '' }
        ]
      } else {
        return [
          { key: 'Name', label: 'Client', sortable: true },
          // { key: 'AddedBy', label: 'Created By' },
          { key: 'Actions', label: '' }
        ]
      }
    },
    rows () {
      try {
        return this.clients.length
      } catch { return 0 }
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },
    editClient (clientId = 0) {
      this.$emit('showAddEditModal', clientId)
    },
    viewClient (clientId) {
      this.$router.push({ path: '/client_portal/spotlights', query: { clId: clientId } })
    },
    showConfirmDeleteClientModal (clientId) {
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'DELETE CLIENT',
        confirmMessage: 'Are you sure you want to delete this client? All client spotlights will be also be deleted.',
        confirmButtonMessage: 'DELETE',
        confirmButtonFunction: () => this.deleteClient(clientId)
      })
    },
    async deleteClient (clientId) {
      try {
        const res = await clientPortalService.deleteClient(clientId)
        if (res.data === false) {
          this.showGenericErrorModal({ showing: true })
          return
        }
        this.$emit('getAllClients')
        this.$emit('getRecentClients')
      } catch {
        this.showGenericErrorModal({ showing: true })
      }
      this.closeGenericConfirmModal()
    }
  }
}
</script>

<style lang="scss">
.pagination button[aria-checked=true] {
  background: #2F9E32 !important;
}
</style>
