<template>
  <!--comments-->
  <div
    class="flex-grow-1 reviewModalRHTab"
  >
    <div class="grey-text">
      <p
        style="padding:10px;font-size:small;"
        class="grey-text font-weight-bold"
      >
        <span
          style="cursor:pointer;"
          @click="showModal = true"
        >
          <i class="fa fa-plus-circle" />
          Add Comment
        </span>
      </p>
      <Note
        v-for="(note,index) in notes"
        :id="note.NotesId"
        :key="index"
        :author="note.AddedByName"
        :date="note.DateAdded"
        :email="note.AddedByEmail"
        :notes="note.QuestionNotes"
        :picture="note.ProfilePicture ?? ''"
        :user-language="userLanguage"
        :account-name="note.AccountName"
        @deleteModal="showDeleteCommentModal"
      />
    </div>
    <AddNoteModal
      v-bind="{ showModal, userLanguage, waitingUpdate }"
      :include-private-note-option="true"
      v-on="{ cancelAddComment, addEditComment }"
      @makePrivate="makePrivate = $event"
      @closeModal="showModal = false"
    />
  </div>
</template>

<script>
import { useStatus } from '@/pinia'
import { useMobile } from '@/composables/useMobile'
import roleV2Service from '@/api/services/roleV2.service'

import Note from '@/components/misc/Notes/note'
import AddNoteModal from '@/components/misc/Notes/addNoteModal'

export default {
  name: 'Comments',
  components: {
    Note,
    AddNoteModal
  },
  props: {
    candidateApplication: {
      type: Object,
      default: () => {}
    },
    currentQuestionId: {
      type: Number,
      default: () => 0
    },
    userLanguage: {
      type: String,
      default: ''
    },
    stageType: {
      type: Number,
      default: 0
    }
  },
  setup () {
    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()
    const { mobileView } = useMobile()

    return {
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal,
      mobileView
    }
  },
  data () {
    return {
      localCandidateApplication: {},
      showModal: false,
      waitingUpdate: false,
      comment: '',
      notes: [],
      makePrivate: false
    }
  },
  computed: {
    questionIdToUse () {
      if (this.stageType === 2) { return 0 }
      return this.currentQuestionId
    }
  },
  watch: {
    candidateApplication () {
      this.localCandidateApplication = { ...this.candidateApplication }
    }
  },
  created () {
    this.localCandidateApplication = { ...this.candidateApplication }
    this.getAllComments()
  },
  methods: {
    async addEditComment (comment) {
      this.comment = comment
      const JSONData = {
        CandidateId: this.candidateApplication.CandidateId,
        RoleId: this.candidateApplication.JobId,
        GoCandidateQuestionId: this.questionIdToUse,
        QuestionNotes: comment,
        IsPrivate: this.makePrivate
      }
      try {
        this.waitingUpdate = true
        const response = await roleV2Service.addRoleComment(JSONData)
        this.notes = response.data
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.$emit('updateCandidateApplication', { ...this.localCandidateApplication })
      this.waitingUpdate = false
      this.showModal = false
      this.comment = ''
    },
    cancelAddComment () {
      this.comment = ''
      this.waitingUpdate = false
      this.showModal = false
    },
    showDeleteCommentModal (commentId) {
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'DELETE COMMENT',
        confirmMessage: 'Are you sure you want to delete this comment?',
        confirmButtonMessage: 'DELETE',
        confirmButtonFunction: () => this.confirmDeleteComment(commentId)
      })
    },
    async confirmDeleteComment (commentId) {
      this.waitingUpdate = true
      try {
        const response = await roleV2Service.deleteComment(commentId)
        if (response.data === true) {
          this.getAllComments()
        }
        this.closeGenericConfirmModal()
      } catch (err) {
        this.closeGenericConfirmModal()
        this.showGenericErrorModal({ showing: true })
      }
      this.waitingUpdate = false
    },
    onlyUnique (value, index, self) {
      return self.indexOf(value) === index
    },
    async getAllComments () {
      this.waitingUpdate = true
      const JSONData = {
        CandidateId: this.candidateApplication.CandidateId,
        RoleId: this.candidateApplication.JobId
      }
      try {
        const res = await roleV2Service.getAllRoleNotes(JSONData)
        this.notes = res.data
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.waitingUpdate = false
    }
  }
}
</script>
