<template>
  <div>
    <div class="p-2 p-md-3 p-lg-4">
      <div>
        <b-row no-gutters class="pb-3 px-3">
          <div class="d-flex align-items-center">
            <mdb-icon icon="fa fa-video mr-2" size="sm" />
            <h3 class="text-left">
              Share Intro
            </h3>
          </div>
        </b-row>
        <b-row no-gutters class="px-3">
          <div
            v-if="sharingVideoIntro.length === 0 && !useTextInstead"
            class="rounded border-2 border-gray-400 p-4 px-5 d-flex flex-column align-items-center justify-content-center"
            style="border: 2px dotted rgb(192, 192, 192);font-size:0.9rem;font-weight:bold;cursor:pointer;height:90px"
            @click="showAddInsertVideoModal = true"
          >
            <mdb-icon
              v-if="!loading"
              icon="fas fa-file-video"
              size="md"
              class="grey-text"
            />
            <p v-if="!loading" class="grey-text">
              Add Video
            </p>
            <mdb-icon
              v-else
              icon="fa fa-pulse fa-spinner"
              size="lg"
            />
          </div>
        </b-row>
        <b-row
          no-gutters
          class="grey-text mt-2 pb-2"
          style="transform:translateX(-5px)"
          @click="useTextInstead = !useTextInstead"
        >
          <mdb-input
            v-model="useTextInstead"
            type="checkbox"
            label="Use a written share intro instead?"
            size="sm"
          />
        </b-row>
        <b-row
          v-if="useTextInstead"
          no-gutters
          style="height:90px; width:100%;max-width:600px;"
          class="ml-2"
          :style="{'margin-bottom' : useTextInstead && shareType !== 'one-way' ? '30px' : '0px'}"
        >
          <mdb-input
            v-model="sharingTextIntro"
            type="textarea"
            auto-complete="fna"
            :rows="4"
            size="sm"
            maxlength="500"
            aria-placeholder="Enter a brief share introduction"
            style="width:100%;"
          />
        </b-row>
        <b-row
          v-if="sharingVideoIntro.length !== 0 && !useTextInstead"
          no-gutters
          align-v="center"
          style="cursor: pointer;max-width:600px;height:100px;"
          class="flex-nowrap border-2 border-gray-400 bg-gray-100 m-3 p-1 text-bold text-black rounded text-center "
        >
          <b-col cols="auto" class="flex-grow-0">
            <img
              :src="sharingVideoIntro[0].ThumbnailUrl"
              class="m-auto"
              style="height:90px; border-radius: 2px; object-fit: cover"
            >
          </b-col>
          <b-col
            offset="1"
            cols="auto"
            class="p-1 flex-grow-1"
          >
            <b-row no-gutters class="font-extrabold text-center">
              {{ sharingVideoIntro[0].ShortDescription }}
            </b-row>
            <b-row no-gutters class="flex-nowrap overflow-hidden">
              <i class="fas fa-tags prefix" style="font-size: 1rem; display:inline;transform:translateY(3px);" />
              <div
                v-if="sharingVideoIntro[0].Tags && sharingVideoIntro[0].Tags.length > 15"
                class="ml-2"
                style="font-size: smaller;white-space: nowrap;"
              >
                {{ `${sharingVideoIntro[0].Tags.substring(0, 16)}...`|| 'no tags' }}
              </div>
              <div
                v-else
                class="ml-2"
                style="font-size: smaller;white-space: nowrap;"
              >
                {{ sharingVideoIntro[0].Tags !== null && sharingVideoIntro[0].Tags !== 'null' && sharingVideoIntro[0].Tags !== undefined && sharingVideoIntro[0].Tags !== [] ? sharingVideoIntro[0].Tags : 'no tags' }}
              </div>
            </b-row>
          </b-col>
          <b-col cols="auto" class="flex-grow-0">
            <i
              style="cursor:pointer; transform:scale(1.4)"
              class="grey-text fa fa-times colorOnHover mr-3"
              @click="removeIntroVid()"
            />
          </b-col>
        </b-row>
      </div>

      <div v-if="shareType === 'one-way'" class="px-3 py-2">
        <b-row
          no-gutters
          align-h="between"
          align-v="center"
          style="width:100%;"
          class="mt-4 mb-3"
        >
          <div class="d-flex align-items-center">
            <mdb-icon icon="fa fa-video mr-2" size="sm" />
            <h3 class="text-left">
              Choose Which Questions You Wish To Share
            </h3>
          </div>
        </b-row>
        <div style="height:130px;overflow-y:auto" class="mb-4 pb-1">
          <b-row
            v-if="!loadingQuestions"
            no-gutters
            style="height:fit-content;"
          >
            <div
              v-for="(q, i) in roleQuestionsWithSelectStatus"
              :key="i"
              class="d-flex align-items-center roleQuestions"
              style="height:60px;width:100%;border: 2px solid #333; border-bottom: none;"
              :style="{'background' : !roleQuestionsWithSelectStatus[i].addedToShare ? '#C0C0C0' : 'none' }"
            >
              <FontAwesomeIcon class="ml-2" icon="fa-solid fa-circle-question" />
              <div class="mr-auto ml-2 twoLinedEllipsis text-black" style="max-width:70%;font-size:smaller;text-align:left;">
                {{ q.Description }}
              </div>
              <mdb-switch
                v-model="roleQuestionsWithSelectStatus[i].addedToShare"
                style="transform:scale(0.9)"
                off-label=""
                on-label=""
                class="m-0"
                @click="toggleQuestionSelected(q.GoCandidateQuestionId)"
              />
            </div>
          </b-row>
          <div
            v-else
            style="height:130px;overflow-y:auto"
            class="mb-4 pb-1 d-flex align-items-center justify-content-center"
          >
            <div><mdb-icon icon="fa fa-pulse fa-spinner" size="lg" /></div>
          </div>
        </div>
      </div>
    </div>

    <InsertVideoModal
      @handleChosenVideo="handleChosenVideo"
    />
  </div>
</template>

<script>
import oneWayService from '@/api/services/oneWayQuestion.service'
import InsertVideoModal from '@/components/genericModals/InsertVideoModals/insertVideoModal.vue'
import { useRoles, useUserData, useLibrary } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'

export default {
  name: 'Information',
  components: {
    InsertVideoModal
  },
  props: {
    roleWorkflowStageId: String,
    shareType: String,
    candidatesAddedToShare: Array
  },
  data () {
    return {
      roleQuestions: [],
      chars: '0/500',
      useTextInstead: false,
      loading: false,
      selectedCandidateIndex: 0,
      show: true,
      videoModalPage: 'choose',
      loadingQuestions: true,
      roleQuestionsWithSelectStatus: []
    }
  },
  setup () {
    const rolesStore = useRoles()
    const {
      sharingVideoIntro,
      sharingTextIntro,
    } = storeToRefs(rolesStore)

    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const { mobileView } = useMobile()
    const libraryStore = useLibrary()
    const { showAddInsertVideoModal } = storeToRefs(libraryStore)
    
    return {
      sharingVideoIntro,
      sharingTextIntro,
      authData,
      userData,
      mobileView,
      showAddInsertVideoModal
    }
  },
  methods: {
    toggleQuestionSelected (qId) {
      this.roleQuestionsWithSelectStatus.map(q => q.GoCandidateQuestionId === qId ? { ...q, addedToShare: !q.addedToShare } : q)
    },
    async getQuestions () {
      this.loadingQuestions = true
      try {
        const res = await oneWayService.getCurrentStageInvitationQuestions(this.roleWorkflowStageId)
        this.roleQuestions = res.data.filter(q => q.IsPractiseQuestion === false)
        this.roleQuestionsWithSelectStatus = this.roleQuestions.map(q => { return { ...q, addedToShare: true } })
      } catch {}
      this.loadingQuestions = false
    },
    charCount (type, max) {
      this.chars = type.length + '/' + max
    },
    handleChosenVideo (vid) {
      this.sharingVideoIntro = [vid]
      this.showAddInsertVideoModal = false
      this.sharingTextIntro = ''
    },
    forceRerender () {
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    removeIntroVid () {
      this.sharingVideoIntro = []
    }
  },
  computed: {
    selectedQuestionIds () {
      return this.roleQuestionsWithSelectStatus.filter(q => (q.addedToShare)).map(q => q.GoCandidateQuestionId)
    },
    viewingCand () {
      return this.candidatesAddedToShare[this.selectedCandidateIndex]
    },
    informationPageIncomplete () {
      try {
        return (this.sharingVideoIntro.length === 0 && !this.useTextInstead) || (this.sharingTextIntro === '' && this.useTextInstead)
      } catch {
        return true
      }
    },
    shareIntroIncomplete () {
      // return (this.sharingVideoIntro.length === 0 && !this.useTextInstead) || (this.sharingTextIntro === '' && this.useTextInstead)
      return false
    }
  },
  watch: {
    selectedQuestionIds: {
      handler: function () {
        this.$emit('selectedQuestionIds', this.selectedQuestionIds)
      },
      deep: true
    },
    shareIntroIncomplete () {
      this.$emit('shareIntroIncomplete', this.shareIntroIncomplete)
    },
    selectedCandidateIndex () {
      this.forceRerender()
    },
    informationPageIncomplete () {
      this.$emit('infoPageComplete', this.informationPageIncomplete)
    }
  },
  mounted () {
    if (this.shareType == 'one-way') {
      this.getQuestions()
    }
    if (this.sharingTextIntro != '') this.useTextInstead = true
    this.$emit('infoPageComplete', this.informationPageIncomplete)
    this.$emit('shareIntroIncomplete', this.shareIntroIncomplete)
    this.$emit('candidateInfoIncomplete', this.candidateInfoIncomplete)
  }
}
</script>

<style lang="scss" scoped>
.roleQuestions:last-child {
  border-bottom: solid 2px #333 !important;
}
</style>
