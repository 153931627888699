<template>
  <div>
    <div class="p-2 p-md-3 p-lg-4">
      <b-row no-gutters>
        <h3>Candidate(s) to Share</h3>
      </b-row>
      <b-row no-gutters class="mt-2 mb-4">
        <div
          style="border:1px solid #e1e1e1; height:40vh;"
          :class="{ 'mobShareHeight': mobileView }"
          class="d-flex flex-wrap col-12 align-content-start"
        >
          <div
            v-for="cand in candidatesAddedToShare"
            :key="cand.CandidateId"
            class="p-1 m-1 m-md-2"
            style="border:1px solid #C0C0C0; border-radius:5px;cursor:pointer;width:fit-content;height:fit-content;"
          >
            <div class="d-flex align-items-center justify-content-between">
              <div style="height:100%;" class="d-flex align-items-center">
                <img
                  :src="cand.ProfilePicture"
                  style="height:25px;width:25px;border-radius:50%;object-fit:cover;"
                  class="d-inline mr-2"
                >
                <h5 class="text-left d-inline" style="font-size:14px;">
                  {{ cand.Name }}
                </h5>
              </div>
              <div style="width:30px;" class="flex-grow-0">
                <i
                  style="cursor:pointer;color:#C0C0C0;"
                  class="fa fa-times colorOnHover ml-2"
                  @click="removeFromCandidatesAddedToShare(cand)"
                />
              </div>
            </div>
          </div>
        </div>
        <div style="height:30px;width:100%;">
          <i
            style="cursor:pointer;color:black; transform:scale(1.4);float:right;margin-right: 13px;margin-top: -30px;"
            class="d-none d-md-block fas fa-user-plus"
            @click="openSearchCandidateModal()"
          />
          <i
            style="cursor:pointer;color:black;transform:scale(1.2) translateY(7px);float:right;margin-right: 13px;margin-top: -30px;"
            class="d-md-none fas fa-user-plus"
            @click="openSearchCandidateModal()"
          />
        </div>
      </b-row>
    </div>

    <!--add candidates modal-->
    <mdb-modal
      v-if="showSearchCandidateModal"
      id="showSearchCandidateModal"
      class="m-0 mobileModalOverlay"
      @close="showSearchCandidateModal = false"
    >
      <mdb-modal-header class="p-3">
        <mdb-modal-title>FIND CANDIDATES</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="pt-0">
        <div class="w-100">
          <ExpandableSearchBar
            class="my-3"
            :width="700"
            place-holder="Name or Email. 3 chars min."
            :searchTriggersAPICall="true"
            :force-always-focused="true"
            :disable-right-alignment="true"
            @search="doNewSearch"
          />
        </div>

        <!--candidates in search-->
        <p class="text-left mb-2 grey-text" style="font-size:15px;">
          {{ searchInputValue == '' ? 'All Candidates' : 'Results' }}
        </p>
        <div style="height:150px;overflow-y:scroll;border:solid #C0C0C0 1px;">
          <div v-if="searchedCandidates.length > 0">
            <div
              v-for="(cand, i) in searchedCandidates"
              :key="i"
              style="border-radius:5px;height:45px;border:2px #C0C0C0 solid;background:#ffffff;cursor:pointer;overflow-x:auto;overflow-y:hidden;"
              class="p-2 m-2 borderColorOnHover"
              @click="addToRemoveFromPreparing(cand)"
            >
              <div style="min-width: 100%;width: fit-content; overflow-x:auto;" class="d-flex align-items-center justify-content-between">
                <div style="height:100%;" class="d-flex align-items-center">
                  <img
                    :src="cand.ProfilePicture"
                    style="height:25px;width:25px;border-radius:50%;object-fit:cover;"
                    class="d-inline mr-2"
                  >
                  <h5 class="text-left font-weight-bold d-inline text-nowrap mr-4">
                    {{ cand.Name }}
                  </h5>
                </div>
                <b-col class="d-none d-lg-block">
                  <p class="text-sm text-right" style="color:black !important;">
                    {{ cand.EmailAddress }}
                  </p>
                </b-col>
              </div>
            </div>
          </div>
          <div v-else class="mt-3">
            <p class="grey-text text-center">
              No results
            </p>
          </div>
        </div>

        <!--preparing to add-->
        <div class="d-flex justify-between">
          <p class="mb-2 mt-3 grey-text">
            {{ preparingToAddCandidates.length > 0 ? 'To Add' : '' }}
          </p>
          <p class="mb-2 mt-3 grey-text font-weight-bold" style="font-size:13px;cursor:pointer;">
            {{ preparingToAddCandidates.length }} selected
          </p>
        </div>
        <div v-if="preparingToAddCandidates.length > 0" style="height:150px;border:solid #C0C0C0 1px;overflow-y:scroll;">
          <div
            v-for="(cand, i) in preparingToAddCandidates"
            :key="i"
            style="border-radius:5px;height:45px;border:2px #C0C0C0 solid;background:#ffffff;cursor:pointer;overflow-x:auto;overflow-y:hidden;"
            class="p-2 m-2 borderColorOnHover"
            @click="addToRemoveFromPreparing(cand)"
          >
            <div style="min-width: 100%;width: fit-content; overflow-x:auto;" class="d-flex align-items-center justify-content-between">
              <div style="height:100%;" class="d-flex align-items-center">
                <img
                  :src="cand.ProfilePicture"
                  style="height:25px;width:25px;border-radius:50%;object-fit:cover;"
                  class="d-inline mr-2"
                >
                <h5 class="text-left font-weight-bold d-inline text-nowrap mr-4">
                  {{ cand.Name }}
                </h5>
              </div>
              <b-col class="d-none d-lg-block">
                <p class="text-sm text-right" style="color:black !important;">
                  {{ cand.EmailAddress }}
                </p>
              </b-col>
              <div style="width:30px;" class="flex-grow-0">
                <i style="cursor:pointer; transform:scale(1.4)" class="grey-text fa fa-times colorOnHover" />
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end w-100 mt-3">
          <mdb-btn
            v-if="preparingToAddCandidates.length > 0"
            type="reset"
            class="btn btn-sm p-0 m-0"
            size="md"
            style="width:100px; height:30px;"
            @click="addSelectedCandidates()"
          >
            ADD SELECTED
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>
  </div>
</template>

<script>
import { useMobile } from '@/composables/useMobile'
import { useCandidate, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import ExpandableSearchBar from '@/components/UI/expandableSearch.vue'
import candidateV2Service from '@/api/services/candidateV2.service.js'

export default {
  name: '',
  components: { ExpandableSearchBar },
  setup () {
    const candidateStore = useCandidate()
    const { candidates, view, candidatesAddedToShare } = storeToRefs(candidateStore)
    const { mobileView } = useMobile()
    
    const statusStore = useStatus()
    const { showGenericErrorModal } = statusStore
    return {
      candidates,
      candidatesAddedToShare,
      view,
      mobileView,
      showGenericErrorModal
    }
  },
  data () {
    return {
      preparingToAddCandidates: [],
      searchedCandidates: [],
      searchInputValue: '',
      showSearchCandidateModal: false
    }
  },
  methods: {
    async doNewSearch (search) {
      if (search.length < 3) return
      const searchModel = {
        Search: search,
        Take: 50,
        Skip: 0
      }
      try {
        const res = await candidateV2Service.getCandidates(searchModel)
        res.data?.CandidateAccounts?.forEach(c => {
          let index = this.candidatesAddedToShare.findIndex(cand => cand.CandidateId == c.CandidateId)
          if (index == -1) {
            this.searchedCandidates.push(c)
          }
        })
      } catch (err) {
        this.showGenericErrorModal()
      }
    },
    addToRemoveFromPreparing (cand) {
      if (this.preparingToAddCandidates.filter(c => c.CandidateId === cand.CandidateId).length === 0) {
        this.preparingToAddCandidates.push(cand)
      } else {
        this.preparingToAddCandidates = this.preparingToAddCandidates.filter(c => c.CandidateId !== cand.CandidateId)
      }
    },
    addSelectedCandidates () {
      this.candidatesAddedToShare = [...this.candidatesAddedToShare, ...this.preparingToAddCandidates]
      this.preparingToAddCandidates = []
      this.searchedCandidatesCandidates = []
      this.showSearchCandidateModal = false
    },
    openSearchCandidateModal () {
      this.showSearchCandidateModal = true
    },
    removeFromCandidatesAddedToShare (cand) {
      this.candidatesAddedToShare = this.candidatesAddedToShare.filter(c => c.CandidateId !== cand.CandidateId)
    }
  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}
.mobShareHeight {
  height: 50vh !important;
}

@media (max-width:576px) {
  .mobileModalOverlay {
    margin-left: -3px !important;
    width: calc(100vw - 10px) !important;
    height: calc(100vh - 0px) !important;
  }
}
</style>
