<template>
  <div style="font-size:small;font-weight:bold;text-align:center;font-weight:normal;color:black;background:#fff;border-bottom-left-radius:5px;border-bottom-right-radius:5px;">
    <SlideFade>
      <div
        class="stageListGrid text-left"
        style="overflow:hidden;"
        :class="{'grey-text' : role.CloseDateIsPast}"
      >
        <div class="incomplete">
          <div
            class="text-black font-weight-bold h6 m-0 w-100 p-2 header"
            style="letter-spacing: 1px;"
          >
            INCOMPLETE
          </div>
          <StageListItem
            v-for="(item) in incompleteStages"
            :key="item.RoleWorkflowStageItemId"
            class="stageListItem"
            :stage-item="item"
            :role="role"
            :selected-stage="selectedStage"
          />
        </div>
        <div>
          <div
            class="text-black font-weight-bold h6 m-0 w-100 p-2 header"
            style="letter-spacing: 1px;"
          >
            COMPLETE
          </div>
          <StageListItem
            v-for="(item) in completeStages"
            :key="item.RoleWorkflowStageItemId"
            class="stageListItem"
            :stage-item="item"
            :role="role"
            :selected-stage="selectedStage"
          />
        </div>
      </div>
    </SlideFade>
  </div>
</template>

<script>

import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'
import StageListItem from './stageListItem.vue'

export default {
  name: 'StageList',
  components: {
    SlideFade, StageListItem
  },
  props: {
    role: {
      type: Object,
      default: () => {}
    },
    selectedStage: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const completeStages = computed(() => {
      if (props.selectedStage.OrderedRoleWorkflowStageItems) {
        return props.selectedStage?.OrderedRoleWorkflowStageItems?.filter(s => s.CompleteStatusType)
      } return []
    })

    const incompleteStages = computed(() => {
      if (props.selectedStage.OrderedRoleWorkflowStageItems) {
        return props.selectedStage?.OrderedRoleWorkflowStageItems?.filter(s => !s.CompleteStatusType)
      } return []
    })

    return {
      authData,
      completeStages,
      incompleteStages
    }
  }
}
</script>

<style scoped>
.stageListGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(30px, auto);
}
.header {
  background: #e0e0e0;
}
.stageListItem:nth-of-type(2n - 1) {
  background: #f7f7f7;
}
.stageListItem:nth-of-type(2n) {
  background: #f0f0f0;
}
.stageListItem:hover {
  background: #e8e8e8;
  transition: 0.2s ease;
}
.incomplete {
  border-right: rgb(218, 218, 218) solid 2px;
}
</style>
