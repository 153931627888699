<template>
  <div>

    <!-- HEADER TEXT -->
    <div class="md-form form-sm">
      <validation-provider
        mode="lazy"
        name="Header Text"
        rules="required"
      >
        <FontAwesomeIcon
          class="prefix"
          icon="fa-solid fa-input-text"
          style="margin-top: -5px;font-size: 1.3rem;" />
        <mdb-input
          v-model="newHeaderText"
          type="text"
          auto-complete="jbt"
          label="Header Text"
          style="margin-left:33px;"
          size="sm"
        />
      </validation-provider>
    </div>


    <!-- SIGN UP INFO TEXT -->
    <div class="md-form form-sm mt-5">
      <FontAwesomeIcon
        class="prefix"
        icon="fa-solid fa-input-text"
        style="margin-top: -5px;font-size: 1.3rem;" />
      <mdb-input
        v-model="newSignUpInfoText"
        type="textarea"
        class="dark-grey-text"
        style="margin-left:33px;"
        label="Sign up information text"
        :rows="3"
        size="sm"
        maxlength="100"
      />
      <div class="remainingChars grey-text ">
        {{ setChars(newSignUpInfoText, 100) }}
      </div>
    </div>


    <!-- COMPLETION TEXT -->
    <div class="md-form form-sm mt-5">
      <FontAwesomeIcon
        class="prefix"
        icon="fa-solid fa-input-text"
        style="margin-top: -5px;font-size: 1.3rem;" 
      />
      <mdb-input
        v-model="newCompletionText"
        type="textarea"
        class="dark-grey-text"
        style="margin-left:33px;"
        label="Registration Completion Text"
        :rows="4"
        size="sm"
        maxlength="250"
      />
      <div class="remainingChars grey-text ">
        {{ setChars(newCompletionText, 250) }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue-demi'
export default {
  name: 'SelfRegCustomFormText',
  props: {
    jobDetails: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {

    // WHEN THE VALUES ARE THE SAME AS DEFAULT WE JUST STORE NULL IN THE FIELDS

    // HEADER TEXT
    const defaultHeaderText = ref('REGISTER NOW')
    const newHeaderText = ref(props.jobDetails.CustomFormHeaderText ?? defaultHeaderText.value)
    watch(newHeaderText, () => {
      if (defaultHeaderText.value == newHeaderText.value || !newHeaderText.value) {
        emit('setHeaderText', null)
      } else { emit('setHeaderText', newHeaderText.value) }
    })



    // SIGN UP TEXT
    const defaultSignUpInfoText = ref('Fill in this form if you wish to register for this position.')
    const newSignUpInfoText = ref(props.jobDetails.CustomFormSignUpInfoText ?? defaultSignUpInfoText.value)
    watch(newSignUpInfoText, () => {
      if (defaultSignUpInfoText.value == newSignUpInfoText.value || !newSignUpInfoText.value) {
        emit('setSignUpInfoText', null)
      } else { emit('setSignUpInfoText', newSignUpInfoText.value) }
    })



    // COMPLETION TEXT
    const defaultCompletionText = ref('Thanks for registering.\nPlease check your email for an invitation that will allow you take the next step in this process.')
    const newCompletionText = ref(props.jobDetails.CustomFormSignUpCompletionText ?? defaultCompletionText.value)
    watch(newCompletionText, () => {
      if (defaultCompletionText.value == newCompletionText.value || !newCompletionText.value) {
        emit('setSignUpCompletionText', null)
      } else { emit('setSignUpCompletionText', newCompletionText.value) }
    })



    const setChars = (text, max) => {
      try {
        return `${text.length} / ${max}`
      } catch {
        return `0 / ${max}`
      }
    }

    return {
      defaultHeaderText,
      defaultSignUpInfoText,
      defaultCompletionText,
      newHeaderText,
      newSignUpInfoText,
      newCompletionText,
      setChars
    }
  }
}
</script>
