<template>
  <div>
    <!-- Mobile -->
    <MobileFilter
      v-if="mobileView || tabletView"
      v-bind="{ filters }"
      @changeFilter="changeFilter($event)"
      @toggleOnlyFollowed="viewingOnlyFollowedRoles = !viewingOnlyFollowedRoles"
    />

    <div
      v-else
      data-v-f55e8a72
      class="filterBar d-flex align-items-center p-2"
      style="font-size:14px;"
    >
      <div
        class="d-none d-lg-flex align-items-center"
        style="color:#555;margin-right: 18px;"
        title="Filter roles by your active roles, your closed (expired), your archived (deleted) roles and the roles you are currently following."
      >
        <i class="fas fa-sliders-h mt-2" style="margin-left:15px;" />
        <span style="margin-left:12px;font-weight:bold;" class="mt-1"> Filter </span>
      </div>

      <div
        v-for="(filter, index) in filters"
        :key="filter.id"
        class="d-flex align-items-center flex-wrap mt-1"
      >
        <div
          v-if="filterData[index].value == true"
          style="color:#555;"
          :title="filter.tooltip"
        >
          <mdb-input
            v-model="filterData[index].value"
            disabled
            type="checkbox"
            :label="filter.name"
            size="sm"
            :style="[{'font-weight' : filter.value ? 'bold' : 'normal'}]"
            :class="index === 0 ? 'filterLeftSmall' : 'filterLeft'"
          />
        </div>
        <div
          v-else
          style="color:#555;"
          :title="filter.tooltip"
          @click="changeFilter(filter.id)"
        >
          <mdb-input
            v-model="filterData[index].value"
            :disabled="loading === false"
            type="checkbox"
            :label="filter.name"
            size="sm"
            :style="[{'font-weight' : filter.value ? 'bold' : 'normal'}]"
            :class="index === 0 ? 'filterLeftSmall' : 'filterLeft'"
          />
        </div>
      </div>

      <div data-v-f55e8a72 class="ml-auto text-nowrap mt-1">
        <div
          style="color:#555;"
          :style="{'opacity': selectedRoleFilter === 'Legacy' ? '0.2':'1'}"
          :title="viewingOnlyFollowedRoles ? 'Currently only showing your followed roles' : 'Currently showing both followed and non-followed roles'"
        >
          <div class="followed-only-toggle d-flex align-items-center">
            <span id="followed-only-toggle-label">{{ titleText }}</span>
            <mdb-switch
              v-model="viewingOnlyFollowedRoles"
              :disabled="selectedRoleFilter === 'Legacy'"
              style="transform:scale(0.9)"
              off-label=""
              on-label=""
              class="m-0 mt-1"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMobile } from '@/composables/useMobile'
import MobileFilter from '@/components/misc/filter/MobileFilter'
import { useRoles } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'FilterBar',
  components: {
    MobileFilter
  },
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    loading: Boolean,
    selectedRoleFilter: String
  },
  setup () {
    const { mobileView, tabletView } = useMobile()

    const roleStore = useRoles()
    const { viewingOnlyFollowedRoles } = storeToRefs(roleStore)

    return {
      mobileView,
      tabletView,
      viewingOnlyFollowedRoles
    }
  },
  data () {
    return {
      filterData: this.filters,
      windowWidth: 0,
      windowHeight: 0,
      legacyFilterExists: false,
      filterId: 1
    }
  },
  computed: {
    titleText () {
      if (this.windowWidth < 1280) {
        return 'Followed Roles'
      } else {
        return 'Followed Roles Only'
      }
    }
  },
  watch: {
    viewingOnlyFollowedRoles: {
      handler: function () {
        this.$emit('reloadRoles')
      }
    }
  },
  created () {
    this.windowHeight = window.innerHeight
    this.windowWidth = window.innerWidth
    if (this.filterData.length === 5) {
      this.legacyFilterExists = true
    }
  },
  methods: {
    changeFilter (id) {
      this.filterId = id
      var filtersToChange = this.filters.filter(f => f.id !== id)
      filtersToChange.forEach(filter => {
        filter.value = false
      })
      this.filterData[id].value = !this.filterData[id].value
    }
  }
}
</script>

<style>
.filterBar {
  background:#e1e1e1;
  width:100%;
  border:solid 1px #c0c0c0;
  height: fit-content;
  border-radius:5px;
}

.followed-only-toggle {
  display: flex;
  float: right;
}

.filterLeft {
  margin-left:-30px !important;
}
.filterLeftSmall {
  margin-left:0px !important;
}
</style>

<style lang="css" scoped>
@media (max-width: 1600px) {
  .form-check {
    padding-left: 0.3rem !important;
  }
}

.noCursor {
  cursor: none;
}
</style>
