<template>
  <div class="px-3 px-md-5 pt-4 text-left" style="height:calc(100vh - 80px);overflow-y:hidden;">
    <div v-if="!isAdding" style="height:100%">
      <SlideFade>
        <AllQuestionSets
          :loading-question-set="loadingQuestionSet"
          :default-set="defaultSet"
          :question-set-list="questionSetList"
          @selectSet="selectedSet = $event"
          @isAdding="isAdding = $event"
        />
      </SlideFade>
    </div>
    <div v-if="isAdding" style="height:100%">
      <SlideFade>
        <CreateQuestionSets
          :selected-set="selectedSet"
          :question-set-list="questionSetList"
          @reloadSets="getQuestionSetList()"
          @updateQuestionSet="updateQuestionSet($event)"
          @isAdding="isAdding = $event"
        />
      </SlideFade>
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import questionSetService from '@/api/services/questionSet.service.js'

import CreateQuestionSets from './Pages/createQuestionSet.vue'
import AllQuestionSets from './Pages/allQuestionSets.vue'
import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'

export default {
  name: 'QuestionSetHome',
  components: {
    AllQuestionSets,
    CreateQuestionSets,
    SlideFade
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    return {
      authData
    }
  },
  data () {
    return {
      isAdding: false,
      questionLength: [
        { text: '00:30', value: 30, selected: true },
        { text: '00:45', value: 45 },
        { text: '01:00', value: 60 },
        { text: '01:15', value: 75 },
        { text: '01:30', value: 90 },
        { text: '01:45', value: 105 },
        { text: '02:00', value: 120 },
        { text: '02:15', value: 135 },
        { text: '02:30', value: 150 },
        { text: '02:45', value: 165 },
        { text: '03:00', value: 180 }
        //{ text: '03:15', value: 195 },
        //{ text: '03:30', value: 210 },
        //{ text: '03:45', value: 225 },
        //{ text: '04:00', value: 240 },
        //{ text: '04:15', value: 255 },
        //{ text: '04:30', value: 270 },
        //{ text: '04:45', value: 285 },
        //{ text: '05:00', value: 300 }
      ],
      loadingQuestionSet: true,
      defaultSet: [
        {
          QuestionSetId: 0,
          QuestionSetName: 'QUESTION SET NAME',
          Questions: []
        }
      ],
      selectedSet: [
        {
          QuestionSetId: 0,
          QuestionSetName: 'QUESTION SET NAME',
          Questions: []
        }
      ],
      questionSetList: []
    }
  },
  created () {
    this.getQuestionSetList()
    this.selectedSet = this.defaultSelectedSet
  },
  methods: {
    updateQuestionSet (updatedSet) {
      let index = -1
      this.questionSetList.forEach((q, i) => {
        if (q.QuestionSetId === updatedSet[0].QuestionSetId) { index = i }
      })
      if (index === -1) {
        this.questionSetList.push(updatedSet[0])
      } else {
        this.questionSetList[index] = updatedSet[0]
      }
      this.selectedSet = updatedSet
    },
    async getQuestionSetList () {
      this.loadingQuestionSet = true
      try {
        const res = await questionSetService.getQuestionSetList()
        this.questionSetList = this.convertResponse(res.data)
      } catch (err) {
        //?
      }
      setTimeout(() => { this.loadingQuestionSet = false }, 1000)
    },
    convertResponse (res) {
      try {
        // splits the questions by setId
        const setIds = []
        const setShells = []
        res.map(q => {
          if (!setIds.includes(q.QuestionSetId)) { setIds.push(q.QuestionSetId) }
        })
        setIds.map(id => {
          setShells.push({ QuestionSetId: id, QuestionSetName: '', Questions: [] })
        })
        res.map(q => {
          let index
          setShells.forEach((sh, i) => {
            if (sh.QuestionSetId === q.QuestionSetId) { index = i }
          })
          setShells[index] = { ...setShells[index], QuestionSetName: q.QuestionSetName, Questions: [...setShells[index].Questions, q] }
        })
        return setShells
      } catch (err) { 
        //?
      }
    }
  }
}
</script>
