import { ref, watch } from 'vue'
import { defineStore } from 'pinia'
import candidateService from '@/api/services/candidate.service'
import candidateV2Service from '@/api/services/candidateV2.service'
import { useStatus } from './useStatus'

export const useCandidate = defineStore('candidateStore', () => {
  // Generalise this?
  const statusStore = useStatus()

  const errorModal = ref({
    showing : false,
    messagg: '',
    title: ''
  })

  const defaultCandidate = {
    CandidateId: 0,
    FirstName: '',
    LastName: '',
    EmailAddress: '',
    ContactNumber: '',
    University: '',
    Degree: '',
    Username: '',
    ProfilePicture: null,
    Following: true,
    Hours: 0,
    WillingToRelocate: 0,
    WorkingPattern: 0
  }

  // can we asssign defaultCandidate here
  // check each time this is altered is {...}
  const candidateToEdit = ref({
    CandidateId: 0,
    FirstName: '',
    LastName: '',
    EmailAddress: '',
    ContactNumber: '',
    University: '',
    Degree: '',
    Username: '',
    ProfilePicture: null,
    Following: true,
    Hours: 0,
    WillingToRelocate: 0,
    WorkingPattern: 0
  })

  const addEditQuestionModalShowing = ref (false)
  const batchLoadingCandidates = ref (false)
  const editingCandidate = ref(false)
  const candidates = ref([])
  const candidateTableCount = ref(0)
  const candidateTablePerPage = ref(10)
  const candidateTableCurrentPage = ref(1)
  const candidateFiles = ref([])
  const candidateQuestions = ref([])
  const candidatesAddedToShare = ref([])
  const recentCandidates = ref([])
  const redirectAfterSave = ref(false)
  const selectedQuestion = ref([])

  const sharingWithPage = ref({
    standardShareWith: [],
    blindShareWith: [],
    emailSubject: '',
    emailContent: 'Hi,\r\n\r\nI’ve shared a candidate with you, please click on the thumbnail below. You will be redirected to our sharing website to view the candidate`s recording in more detail.'
  })

  const sharingVideoIntro = ref([])
  const sharingTextIntro = ref('')
  const videoToAddToQuestion = ref([])
  const view = ref('ViewAllCandidates')

  const importAndInviteModal = ref(false)
  const importAndInviteModalRoleId = ref(0)
  const importAndInviteModalRoleWorkflowStageId = ref(null)
  const hideRedoStageOptionOnImportModal = ref(true)

  // MEHTODS
  const addCandidateToRecentCandidates = async (candId) => {
    // add token
    try {
      const res = await candidateService.addCandidateToRecentCandidates(candId)
      updateRecentCandidates(res.data)
    } catch (err) { 
      //?
    }
  }

  const deleteCandidate = (id) => candidates.value = candidates.value.filter(c => c.CandidateId !== id)
  const resetAllCandidates = () => candidates.value = []
  const resetCandidate = () => candidateToEdit.value = { ...defaultCandidate }
  const setErrorModal = (payload) => errorModal.value = payload
  // const resetState // todo

  const resetShareModal = () => {
    candidatesAddedToShare.value = []
    sharingWithPage.value = {
      standardShareWith: [],
      blindShareWith: [],
      emailSubject: '',
      emailContent: 'Hi,\r\n\r\nI’ve shared a candidate with you, please click on the thumbnail below. You will be redirected to our sharing website to view the candidate`s recording in more detail.'
    }
    sharingVideoIntro.value = []
    sharingTextIntro.value = ''
    videoToAddToQuestion.value = []
    view.value = 'ViewAllCanddates'
  }

  const resetSingleShareModal = () => {
    candidatesAddedToShare.value = []
    sharingWithPage.value = {
      standardShareWith: [],
      blindShareWith: [],
      emailSubject: '',
      emailContent: 'Hi,\r\n\r\nI’ve shared a candidate with you, please click on the thumbnail below. You will be redirected to our sharing website to view the candidate`s recording in more detail.'
    }
    sharingVideoIntro.value = []
    sharingTextIntro.value = ''
  }

  // CHECK THESE METHODS THAT TAKE JUST PAYLOAD BUT REFERENCE >1 PARAM
  const updateRecentCandidates = (payload) => {
    recentCandidates.value = recentCandidates.value.filter(c => c.CandidateId !== payload.CandidateId)
    recentCandidates.value = [payload, ...recentCandidates.value]
    recentCandidates.value = recentCandidates.value.filter((c, i) => i < 10)
  }

  const addOrUpdateCandidateAfterSave = (payload) => {
    const index = candidates.value.findIndex(item => item.CandidateId === payload.CandidateId)
    if (index !== -1) {
      candidates.value.splice(index, 1, payload)
    } else {
      candidates.value.push(payload)
    }
  }

  const modCandidateListFollowing = (payload) => {
    const index = candidates.value.findIndex(item => item.CandidateId === payload.CandidateId)
    if (index !== -1) candidates.value.splice(index, 1, payload)
  }

  const updateCandidateFollowing = ({ candId, following }) => {
    const index = candidates.value.findIndex(item => item.CandidateId === candId)
    if (index !== -1) {
      const candidate = { ...candidates.value[index], Following: following }
      recentCandidates.value = recentCandidates.value.map(c => c.CandidateId === candId ? { ...c, Following: following } : { ...c })
      candidates.value.splice(index, 1, candidate)
      if (candidateToEdit.value.CandidateId === candId) candidateToEdit.value = candidate
    }
  }

  const updateIdCheckedValues = ({ candId, IDChecked, name }) => {
    if (candidates.value == null) { return }
    const index = candidates.value.findIndex(item => item.CandidateId === candId)
    if (index !== -1) {
      const candidate = { ...candidates.value[index], IDChecked: IDChecked, IDCheckedDate: new Date(), IDCheckedByName: name }
      recentCandidates.value = recentCandidates.value.map(c => c.CandidateId === candId ? { ...c, IDChecked: IDChecked } : { ...c })
      candidates.value.splice(index, 1, candidate)
      if (candidateToEdit.value.CandidateId === candId) candidateToEdit.value= candidate
    }
  }

  const setAllCandidates = (payload) => {
    const candidateWorkingPattern = [
      { text: 'All/Any', value: 0 },
      { text: 'On Site', value: 1 },
      { text: 'Remote', value: 2 },
      { text: 'Hybrid', value: 3 }
    ]
    const returnText = (thing) => {
      if (thing !== null && thing !== undefined && thing !== '') {
        return candidateWorkingPattern.filter(c => c.value === thing)[0].text
      } else { return '' }
    }
    if (candidates.value == null) candidates.value = []
    candidates.value = [...candidates.value.map(c => { return { ...c, WorkingPatternReadable: returnText(c.WorkingPattern) } }), ...payload]
  }

  const candidateTableRecentSearches = ref([])
  const handleRecentSearch = search => {
    if (search == "") return
    if (candidateTableRecentSearches.value.includes(search)) return
    candidateTableRecentSearches.value.splice(0, 0, search)
    if (candidateTableRecentSearches.value.length > 5) {
      candidateTableRecentSearches.value.pop()
    }
  }
  const candidateTableLoading = ref(false)

  const getCandidates = async (search = "") => {
    handleRecentSearch(search)
    const take = candidateTablePerPage.value
    const skip = (candidateTableCurrentPage.value - 1) * candidateTablePerPage.value

    const searchModel = {
      Search: search,
      Take: take,
      Skip: skip
    }
    candidateTableLoading.value = true
    try {
      const res = await candidateV2Service.getCandidates(searchModel)
      candidates.value = res.data.CandidateAccounts
      candidateTableCount.value = res.data.TotalCount
      candidateTableLoading.value = false
      return true
    } catch (err) {
      statusStore.setError({ status: true })
      candidateTableLoading.value = false
      return false
    }
  }

  ///// CANDIDATE HISTORY
  const filteredHistory = ref([])
  const allHistory = ref([])
  const blockedHistory = ref([])
  const selectedYear = ref(0)
  const selectedMonth = ref('00')
  const selectedHistoryType = ref('all')
  const fetchCandHistory = async (candidateId) => {
    try {
      allHistory.value = []
      blockedHistory.value = []
      filteredHistory.value = []
      const res = await candidateV2Service.getCandidateHistory(candidateId)
      allHistory.value = res.data
      createBlockedHistory()
    } catch (err) {
      //?
    }
  }
  const createBlockedHistory = () => {
    let blockedArray = []
    // takes the incoming data and transforms into an array where role based history items are blocked by applicantId and others are left the same
    allHistory.value.forEach(item => {
      if (item.ApplicantId) {
        let id = item.ApplicantId
      // if there is an applicantId we will want to either create a new array or push an item to an already existing array of the same applicant id
        const index = blockedArray.findIndex(c => c[id])
        if (index != -1) {
          blockedArray[index][id].push(item)
        } else {
           blockedArray.push({ [id]: [item], id })
        }
      } else {
        blockedArray = [...blockedArray, item ]
      }
    })
    blockedHistory.value = blockedArray
  }

  // CUSTOM FIELDS
  const customFieldList = ref([])
  const loadingCustomFieldList = ref(false)
  const getCustomFieldList = async () => {
    try {
      customFieldList.value = []
      loadingCustomFieldList.value = true
      const res = await candidateV2Service.getCustomFieldList()
      customFieldList.value = res.data
      loadingCustomFieldList.value = false
      return res.data
    } catch (err) {
      loadingCustomFieldList.value = false
    }
  }
  const customFieldModalShowing = ref(false)
  const openAddCustomFieldModal = () => {
    customFieldModalShowing.value = true
  }
  const defaultFormField = ref({ Type: '', ReadableType: '', Question: '', CustomFieldInformation: null })
  const currentCustomFormField = ref({ ...defaultFormField.value })
  watch(customFieldModalShowing, () => {
    currentCustomFormField.value = { ...defaultFormField.value }
  })
  const addCustomFormField = async () => {
    try {
      await candidateV2Service.addCustomFormField(currentCustomFormField.value)
      await getCustomFieldList()
      currentCustomFormField.value = { ...defaultFormField.value }
      customFieldModalShowing.value = false
    } catch (err) {
      //?
    }
  }
  const getCustomFieldValues = async (candAccId) => {
    try {
      const res = await candidateV2Service.getCustomFieldValues(candAccId)
      return res.data
    } catch (err) {
      //?
    }
  }
  const saveCustomFieldValues = async (JSON) => {
    try {
      await candidateV2Service.saveCustomFieldValues(JSON)
    } catch (err) {
      //?
    }
  }
  const editCustomFormField = async (JSON) => {
    try {
      await candidateV2Service.editCustomFormField(JSON)
    } catch (err) {
      //?
    }
  }
  const deleteCustomFormField = async (JSON) => {
    try {
      await candidateV2Service.deleteCustomFormField(JSON)
    } catch (err) {
      //?
    }
  }

  return {
    addOrUpdateCandidateAfterSave,
    addCandidateToRecentCandidates,
    addEditQuestionModalShowing,
    allHistory,
    batchLoadingCandidates,
    blockedHistory,
    candidates,
    candidateFiles,
    candidateQuestions,
    candidatesAddedToShare,
    candidateToEdit,
    candidateTableCount,
    candidateTablePerPage,
    candidateTableCurrentPage,
    candidateTableLoading,
    candidateTableRecentSearches,
    createBlockedHistory,
    getCandidates,
    deleteCandidate,
    errorModal,
    editingCandidate,
    fetchCandHistory,
    deleteCustomFormField,
    filteredHistory,
    importAndInviteModal,
    importAndInviteModalRoleId,
    importAndInviteModalRoleWorkflowStageId,
    hideRedoStageOptionOnImportModal,
    modCandidateListFollowing,
    recentCandidates,
    redirectAfterSave,
    resetAllCandidates,
    resetCandidate,
    resetShareModal,
    resetSingleShareModal,
    setErrorModal,
    selectedQuestion,
    setAllCandidates,
    selectedYear,
    selectedMonth,
    selectedHistoryType,
    sharingWithPage,
    sharingVideoIntro,
    sharingTextIntro,
    updateRecentCandidates,
    updateCandidateFollowing,
    updateIdCheckedValues,
    videoToAddToQuestion,
    view,
    getCustomFieldList,
    customFieldList,
    loadingCustomFieldList,
    addCustomFormField,
    customFieldModalShowing,
    openAddCustomFieldModal,
    currentCustomFormField,
    getCustomFieldValues,
    saveCustomFieldValues,
    editCustomFormField
  }
})
