<template>
  <div
    v-if="initialised"
    class="text-bold text-black rounded text-center border-2 border-gray-400 bg-gray-100 my-1 flex-nowrap px-2"
    style="transition: all 1s ease;width:100%;"
  >
    <div class="d-flex py-3" style="width:100%;">
      <HistoryItemDetails
        class="ml-2"
        :extraDetailText="getHistoryInfo(blockDefiningHistoryItem).extraDetailText"
        :historyTypeText="getHistoryInfo(blockDefiningHistoryItem).historyTypeText"
        :iconCode="getHistoryInfo(blockDefiningHistoryItem).iconCode"
        :dateAdded="blockDefiningHistoryItem.DateAdded"
      />
      <div class="flex-grow-0 mx-2 d-flex align-items-center ml-auto">
        <FontAwesomeIcon
          class="chevron cp"
          icon="fa-regular fa-circle-chevron-down"
          size="1x"
          :class="{ 'rotatedChevron' : !collapsed }"
          @click="changeCollapsing()"
        />
      </div>
    </div>
    <SlideFade>
      <div v-if="collapsed" class="slide-in">
        <SingleHistoryItem
          v-for="(historyItem, i) in innerBlockHistoryItems"
          :key="i"
          :historyItem="historyItem"
          no-gutters
          :style="{'--i': i}"
          class="slide-in my-2 p-3"
          @openReviewModal="$emit('openReviewModal', $event)"
        />
        <div
          v-if="!innerBlockHistoryItems.length"
          style="font-size: 0.8rem"
          class="my-4"
        >
          The candidate has not yet started this application
        </div>
      </div>
    </SlideFade>
  </div>
</template>

<script>
// import { useUserData } from '@/pinia'
// import { storeToRefs } from 'pinia'

import SingleHistoryItem from './singleHistoryItem.vue'
import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'
import { nextTick, onMounted, ref } from 'vue-demi'
import HistoryItemDetails from './historyItemDetails.vue'
import GET_HISTORY_INFO from '@/utils/historyInfoSwitchCase.js'

export default {
  name: 'BlockedHistory',
  components: {
    SlideFade, SingleHistoryItem, HistoryItemDetails
  },
  props: {
    historyBlock: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // COLLAPSING
    const collapsed = ref(false)
    const collapsing = ref(false)
    const initialised = ref(false)
    const changeCollapsing = () => {
      collapsing.value = true
      nextTick(() => {
        collapsed.value = !collapsed.value
      })
      setTimeout(() => {
        collapsing.value = false
      }, 1000)
    }

    onMounted(() => initialised.value = true)

    const getHistoryInfo = item => GET_HISTORY_INFO(item)

    // what is the key (currently only applicantId) that the block is grouped by
    const blockKeyValue = ref(Object.keys(props.historyBlock)[0])
    // block defining history item to be rendered on the top of the block e.g. invited to role
    const blockDefiningHistoryItem = ref(props.historyBlock[blockKeyValue.value].find(h => h.HistoryType == 2))
    // items that will be rendered in the dropdown
    const innerBlockHistoryItems = ref(props.historyBlock[blockKeyValue.value].filter(h => h.HistoryType != 2))

    return {
      collapsed, collapsing, initialised, blockKeyValue, blockDefiningHistoryItem, innerBlockHistoryItems, changeCollapsing, getHistoryInfo
    }
  }
}
</script>

<style lang="scss" scoped>
.chevron.rotatedChevron {
  transition: 0.5s ease-out;
  transform: rotate(180deg)  scale(1.2);
}
.chevron {
  transform: rotate(0deg) scale(1.2);
  transition: 0.5s ease-out;
}
.slide-in {
  &-enter-active {
    transition: opacity .5s linear, transform .5s cubic-bezier(.2,.5,.1,1);
    transition-delay: calc( 0.1s * var(--i) );
  }

  &-enter {
    opacity: 0;
  }

  &-enter { transform: translateX(30px); }
}
</style>
