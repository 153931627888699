import axiosInstance from '../instance.js'
const api2 = new axiosInstance(2)
const api1 = new axiosInstance(1)

export default class roleV2Service {
  //!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // workflow calls
  // get all workflow templates
  static async getWorkflowTemplates () {
    return await api2.get('/api/workflow_template')
  }

  // get single workflow template with stages
  static async getWorkflowWithStages (workflowId) {
    return await api2.get(`/api/workflow_template/${workflowId}`)
  }

  // get all workflow templates
  static async addStageToWorkflow (JSONForm) {
    return await api2.post('/api/workflow_template_stages/add', JSONForm)
  }

  // get all workflow templates
  static async addEditRoleSetup (JSONForm) {
    return await api2.post('/api/role/addedit', JSONForm)
  }

  // get roleworkflow stage item
  static async getRoleWorkflowStageItem (id) {
    return await api2.get(`/api/role_workflow_stage_item/${id}`)
  }
  // edit roleworkflow stage item name
  static async editRoleWorkflowStageItem (JSONData) {
    try {
      return await api2.post('/api/role_workflow_stage_item/edit', JSONData)
    } catch (err) { return err }
  }
  // edit roleworkflow stage name
  static async editRoleWorkflowStage (JSONData) {
    try {
      return await api2.post('/api/role_workflow_stage/edit', JSONData)
    } catch (err) { return err }
  }
  // edit roleworkflow stage item ordinals
  static async editRoleWorkflowStageItemOrdinals (ordinalGuidDictionary) {
    try {
      return await api2.post('/api/role_workflow_stage_item/ordinals', { GuidIntDictionary: ordinalGuidDictionary })
    } catch (err) { return err }
  }
  // add roleworkflow stage item
  static async addRoleWorkflowStageItem (JSONData) {
    try {
      return await api2.post('/api/role_workflow_stage_item/add', JSONData)
    } catch (err) { return err }
  }

  // get role - with job details
  static async getRole (id) {
    return await api2.get(`/api/role/${id}`)
  }

  // get get role
  static async getRoleWithStages (id) {
    return await api2.get(`/api/role/with_stages/${id}`)
  }

  // get recent roles
  static async getRecentRoles () {
    return await api2.get('/api/role/get/recent_roles')
  }

  // main call used for roles landing
  static async getRoles (filterGroup) {
    return await api2.post('/api/role/get/all_roles', filterGroup)
  }

  // get role no job details
  static async getRoleNoJobDetails (id) {
    return await api2.get(`/api/role/no_job_details/${id}`)
  }

  // get role no job details
  static async getRolePersistMasking (id) {
    return await api2.get(`/api/role/persist_masking/${id}`)
  }

  // add role to recent roles
  static async addRoleToRecentRoles (id) {
    return await api2.post(`/api/role/add_role_to_recent_roles/${id}`, {})
  }

  // add roleworkflow template to role
  static async addRoleWorkflow (JSONData) {
    return await api2.post('/api/role_workflow/add', JSONData)
  }

  // get roleworkflow
  static async getRoleWorkflow (roleWorkflowId) {
    return await api2.get(`/api/role_workflow/${roleWorkflowId}`)
  }

  // get roleworkflow without setup details
  static async getRoleWorkflowNoSetupDetails (roleWorkflowId) {
    return await api2.get(`/api/role_workflow/no_setup_details/${roleWorkflowId}`)
  }

  // get roleworkflow stage
  static async getRoleWorkflowStage (roleWorkflowStageId) {
    return await api2.get(`/api/role_workflow_stage/${roleWorkflowStageId}`)
  }

  // edit one way setup
  static async editRoleWorkflowOneWayStage (stage) {
    return await api2.put('/api/role_workflow_stage_setup/one_way', stage)
  }

  // call finds whether there are any interviews that have been started searhed by using the invites position
  static async checkOneWayStageHasBeenStarted (roleWorkflowStageId) {
    return await api2.get(`/api/candidate_invitation/check_started/${roleWorkflowStageId}`)
  }

  // edit two way setup
  static async editRoleWorkflowTwoWayStage (stage) {
    return await api2.put('/api/role_workflow_stage_setup/two_way', stage)
  }

  // add next step to one way setup
  static async addEditNextStepToOneWaySetup (nextStep) {
    return await api2.post('/api/role_workflow_stage_setup/add_edit_next_step', nextStep)
  }

  // delete next step from one way setup
  static async deleteNextStepFromOneWaySetup (nextStepId) {
    return await api2.delete(`/api/role_workflow_stage_setup/delete_next_step/${nextStepId}`)
  }

  // update next step ordinals
  static async editNextStepOrdinals (JSONData) {
    return await api2.post('/api/role_workflow_stage_setup/edit_next_step_ordinal', JSONData)
  }



  // ROLE CANDIDATES TABLE
  // main get candidates for table call
  static async getRoleCandidates (stageItemId, skip, take, search, filterByDate = null) {
    const JSONData = {
      SearchTerm: encodeURIComponent(search),
      FilterByDate: filterByDate
    }
    return await api2.post(`/api/role_candidates/stage_item/${stageItemId}?skip=${skip}&take=${take}`, JSONData)
  }

  // gets all non invited candidates in a certain stage item
  static async getUninvitedRoleCandidates (roleWorkflowId, skip, take, search) {
    const JSONData = {
      SearchTerm: encodeURIComponent(search)
    }
    return await api2.post(`/api/role_candidates/stage_item/uninvited/${roleWorkflowId}?skip=${skip}&take=${take}`, JSONData)
  }

  // initial call to invite candidates to a one way stage
  static async inviteCandidatesToOneWayStage (JSONData) {
    return await api2.post('/api/candidate_invitation/invite', JSONData)
  }

  // redostage
  static async redoCandidatesOneWayStage (JSONData) {
    return await api2.post('/api/candidate_invitation/redo', JSONData)
  }

  // jobtrain re-confirm
  static async confirmJobtrainRecompletion (invitationCode) {
    return await api2.post('/api/candidate_invitation/jobtrainrecompletion?invitationCode=' + invitationCode)
  }

  // uninvite from one way stage
  static async uninviteFromOneWayStage (JSONData) {
    return await api2.post('/api/candidate_invitation/uninvite', JSONData)
  }

  // resend invite email
  static async resendOneWayStageInviteEmail (JSONData) {
    return await api2.post('/api/candidate_invitation/resend_invite_email', JSONData)
  }

  // reset one way interview
  static async resetOneWayStageInterview (JSONData) {
    return await api2.post('/api/candidate_invitation/reset_interview', JSONData)
  }

  // unlock interview
  static async unlockOneWayStageInterview (JSONData) {
    return await api2.post('/api/candidate_invitation/unlock_one_way', JSONData)
  }

  // bulk invite
  static async bulkSetCandidateStatus (JSONData) {
    JSONData['SystemChange'] = false // as opposed to a user changing the status
    return await api2.post('/api/applicant_stage/bulk_change_stage_item', JSONData)
  }

  // set temp meeting date
  static async setTempMeetingDate (JSONData) {
    return await api2.post('/api/meeting/set_temp_meeting_date', JSONData)
  }

  // delete temp meeting date
  static async deleteTempMeetingDate (candidateId, roleWorkflowStageId) {
    return await api2.delete(`/api/meeting/delete_temp_meeting_date/${candidateId}/${roleWorkflowStageId}`)
  }

  // get hosts and assessors
  static async getHostAssessors(search) {
    const JSONData = {
      Search: search
    }
    return await api2.post('/api/user/get_host_and_assessors', JSONData)
  }

  

  // initial call to invite candidates to a stage
  static async inviteCandidatesToTwoWayStage (JSONData) {
    return await api2.post('/api/meeting/invite', JSONData)
  }

  // reset two way interview
  static async resetTwoWayStageInterview (JSONData) {
    return await api2.post('/api/meeting/reset_interview', JSONData)
  }

  // uninvite from two way stage
  static async uninviteFromTwoWayStage (JSONData) {
    return await api2.post('/api/meeting/uninvite', JSONData)
  }

  // resend two way email
  static async resendTwoWayStageInviteEmail (JSONData) {
    return await api2.post('/api/meeting/resend_invite_email', JSONData)
  }

  // reset two way room
  static async resetTwoWayRoom (meetingId) {
    return await api1.post(`/api/go/resetroom?roomId=${meetingId}`)
  }



  // uninvite from event
  static async uninviteFromEvent (JSONData) {
    return await api2.post('/api/event_invite/uninvite', JSONData)
  }
  // resends scheduler invite emails
  static async resendSchedulerInviteEmail (JSONData) {
    return await api2.post('/api/scheduler_invite/resend_scheduler_invite_email', JSONData)
  }
  // resends scheduler confirmatin emails
  static async resendSchedulerConfirmationEmail (JSONData) {
    return await api2.post('/api/scheduler_invite/resend_scheduler_confirmation_email', JSONData)
  }

  // reset scheduler invite
  static async resetSchedulerInvite (schedulerInviteId) {
    return await api2.post(`/api/scheduler_invite/reset_scheduler_invite/${schedulerInviteId}`)
  }

  // extend one way invite deadline
  static async extendOneWayInviteDeadline (payload) {
    return await api2.post(`/api/candidate_invitation/extend_deadline`, payload)
  }
  // extend scheduler invite deadline
  static async extendSchedulerInviteDeadline (payload) {
    return await api2.post(`/api/scheduler_invite/extend_deadline`, payload)
  }

  // get date filter applicatn counts
  static async getDateFilterCount (payload) {
    return await api2.post(`/api/applicant/date_filter_stage_item_counts`, payload)
  }


  // !!!!!!!!!!!!!!!!!
  // REVIEW MODAL CALLS

  static async getOneWayApplication (JSONData) {
    return api2.post('/api/review_modal/one_way', JSONData)
  }

  static async getTwoWayApplication (JSONData) {
    return api2.post('/api/review_modal/two_way', JSONData)
  }

  static async getEventApplication (JSONData) {
    return api2.post('/api/review_modal/event', JSONData)
  }

  static async getAllRoleNotes (JSONData) {
    return api2.post('/api/review_modal/get_role_notes', JSONData)
  }

  // add or edit a comment
  static async addRoleComment (JSONData) {
    return api2.post('/api/review_modal/add_role_comment', JSONData)
  }

  // delete a comment
  static async deleteComment (commentId) {
    return api2.delete(`/api/review_modal/delete_role_comment/${commentId}`)
  }

  // get any associated documents for ther candidate application e.g. CV
  static async getCandidateDocs (candidateId) {
    return api2.get(`/api/candidate_account/get_candidate_docs/${candidateId}`)
  }

  // get video url for candidate videos - one way
  static async getCandidateVideoStream (candidateVideoId) {
    return api2.get(`/api/review_modal/get_candidate_video_stream/${candidateVideoId}`)
  }

  // get video url for candidate videos - one way
  static async deleteOneWayQuestion (candidateVideoId, chooseAlternateQuestionSetQuestion) {
    return api2.delete(`/api/review_modal/delete_one_way_answer/${candidateVideoId}?chooseAlternateQuestionSetQuestion=${chooseAlternateQuestionSetQuestion}`)
  }

  // optimise video
  static async repairVideo (videoId) {
    return api2.put(`/api/review_modal/repair_video?videoId=${videoId}`)
  }

  // restore original video
  static async restoreVideo (videoId) {
    return api2.put(`/api/review_modal/restore_video?videoId=${videoId}`)
  }  

  // get role history
  static async getRoleHistory (candidateId, roleId) {
    return api2.get(`/api/review_modal/get_role_history?candidateId=${candidateId}&jobId=${roleId}`)
  }



  // ROLE CARD CALLS
  // populates the total count shown in the role card
  static async getApplicantCountInRole (roleId) {
    return await api2.get(`/api/applicant/role_count/${roleId}`)
  }

  static async getStageApplicantCounts (stageGuids) {
    return await api2.post('/api/applicant/stage_count', stageGuids)
  }

  static async getStageItemApplicantCounts (stageItemGuids) {
    return await api2.post('/api/applicant/stage_item_counts', stageItemGuids)
  }

  static async addInviteCandidate (JSONData) {
    return await api2.post('/api/alternative_stage_invites/add_then_invite_candidate', JSONData)
  }

  // delete a role - sets IsDeleted field and if permanant delete also sets RemoveThis flag
  static async deleteRole (JSONData) {
    return await api2.post(`/api/role/delete_role`, JSONData)
  }

  // applicant search
  static async searchApplicantWithinRole (roleId, search) {
    search = encodeURIComponent(search)
    return await api2.get(`/api/applicant/search/role/${roleId}?searchTerm=${search}`)
  }

  static async searchApplicantWithinStage (stageId, search, skip = 0, take = 10, completedStatusesOnly = false) {
    search = encodeURIComponent(search)
    return await api2.get(`/api/applicant/search/stage/${stageId}?searchTerm=${search}&skip=${skip}&take=${take}&completedStatusesOnly=${completedStatusesOnly}`)
  }

  static async followRole (id) {
    return api2.post(`/api/notification_subscription/follow_role/${id}`, {})
  }

  // unfollow role
  static async unFollowRole (id) {
    return api2.post(`/api/notification_subscription/unfollow_role/${id}`, {})
  }

  // get quick select role sharing candidates
  static async candidatesFromStageItemId (id) {
    return api2.get(`/api/applicant/get/stage_item/${id}`)
  }

  // LEGACY CANDIDATE DATA
  static async getLegacyCandidatesForRole(jobId, type, search) {
    if (search === '') { search = '-1' }
    return await api2.get(`/api/applicant/get/legacy_data/${jobId}?type=${type}&search=${search}`)
  }

  // LEGACY REVIEW MODAL 1W
  static async getLegacy1WCandidatesInterview(candidateInvitationId) {
    return await api2.get(`/api/review_modal/legacy_one_way/${candidateInvitationId}`)
  }

  // LEGACY REVIEW MODAL 2W
  static async getLegacy2WCandidatesInterview(meetingId) {
    return await api2.get(`/api/review_modal/legacy_two_way/${meetingId}`)
  }

  // MANUAL TRIGGER OF REMINDER EMAILS
  static async sendShineGoReminderEmailsManualTrigger(roleId) {
    return await api1.get(`/api/go/shinegoreminderemailsmanualtrigger?roleId=${roleId}`)
  }

  //CLONING

  // clone a role
  static async cloneRole (roleId, type, jobTitle, externalReferenceId, endDate, isEverGreen) {
    const JSONData = {
      JobTitle: jobTitle,
      ExternalReferenceId: externalReferenceId,
      Expires: endDate,
      IsEverGreen: isEverGreen
    }
    return await api2.post('/api/role/clone_role?roleId=' + roleId + '&type=' + type, JSONData)
  }

  // TRANSCRIPTIONS

  // check individual
  static async checkTranscription(candidateVideoId) {
    return await api2.get(`/api/review_modal/check_video_for_transcription/${candidateVideoId}`)
  }

  // individual
  static async getTranscription(candidateVideoId, transcribeAgain, emailPdf) {
    return await api2.get(`/api/review_modal/get_transcription_for_video/${candidateVideoId}?transcribeAgain=${transcribeAgain}&emailPdf=${emailPdf}`)
  }

  // check full
  static async checkTranscriptions(invitationCode, candidateId, roleId, roleWorkFlowStageId) {
    return await api2.get(`/api/review_modal/check_invite_for_transcription/${invitationCode}?candidateId=${candidateId}&roleId=${roleId}&roleWorkFlowStageId=${roleWorkFlowStageId}`)
  }

  // full
  static async getFullTranscription(invitationCode, transcribeAgain, emailPdf) {
    return await api2.get(`/api/review_modal/get_full_transcription_for_video/${invitationCode}?transcribeAgain=${transcribeAgain}&emailPdf=${emailPdf}`)
  }

}
