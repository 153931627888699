<template>
  <!--modal boilerplate-->
  <mdb-modal
    id=""
    :show="addScaleModalShowing"
    size="md"
    style="overflow-y:auto;"
    @close="$emit('addScaleModalShowing', false)"
  >
    <mdb-modal-header>
      <mdb-modal-title>CREATE AN ELEMENT</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body class="px-4">
      <div class="d-flex align-items-center mt-4" style="height:60px">
        <i class="mr-3 fas fa-signature" style="font-size: 1.3rem;" />
        <mdb-input
          v-model="elementName"
          type="text"
          auto-complete="jbt"
          label="Element Name"
          style="width:100%"
        />
      </div>
      <div class="md-form form-sm mb-5">
        <i class="fas fa-house-user prefix primary-colour-ni" style="margin-top: 4px;font-size: 1.3rem;" />
        <mdb-select
          v-model="scaleOptions"
          placeholder="Select"
          label="Point scale"
          style="margin-left:33px;"
          class="sm"
        />
      </div>
    </mdb-modal-body>
    <mdb-modal-footer>
      <mdb-btn
        :disabled="selectedScaleId == 0 || elementName == ''"
        size="sm"
        style="width:fit-content;"
        class="m-0 btn font-weight-bold text-nowrap"
        @click="addElement()"
      >
        ADD
      </mdb-btn>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import scoringService from '@/api/services/scoring.service.js'

export default {
  name: 'AddScaleModal',
  props: {
    addScaleModalShowing: Boolean
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()

    return {
      authData,
      showGenericErrorModal
    }
  },
  data () {
    return {
      scaleOptions: [],
      elementName: '',
      scales: []
    }
  },
  computed: {
    selectedScaleId () {
      try {
        return this.scaleOptions.filter(s => s.selected)[0].value
      } catch {
        return 0
      }
    }
  },
  watch: {
    addScaleModalShowing () {
      if (this.addScaleModalShowing) {
        this.getAllScales()
      } else {
        this.elementName = ''
      }
    }
  },
  methods: {
    async getAllScales () {
      this.loading = true
      try {
        const res = await scoringService.getAllScales()
        this.scales = res.data
        this.scaleOptions = res.data.map(s => { return { text: s.ScaleName, value: s.GoScoringScaleId } })
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    addElement () {
      const selectedElement = this.scales.filter(s => s.GoScoringScaleId === this.selectedScaleId)[0]
      this.$emit('addingElement', {
        ...selectedElement,
        TextArray: selectedElement.TextArray,
        ElementName: this.elementName
      })
    }
  }
}
</script>

