<template>
  <div class="px-3 px-md-4" style="height:calc(100% - 70px);width:100%; overflow-y:auto; overflow-x:hidden;scrollbar-color:var(--pr-color);scrollbar-width:10px;margin-bottom:30px;">
    <div class="font-weight-bold text-left my-3 d-flex align-items-center justify-content-between" style="height:70px;">
      <div
        v-if="!candidatesLoaded"
        class="d-flex align-items-center"
        style="margin-top: -20px !important;"
      >
        <h3 class="primary-colour float-left font-weight-bold">
          Loading
        </h3> <i class="fa fa-spin fa-spinner" style="float: left;margin-top: 0px;margin-left: 5px;" />
      </div>
      <div
        v-else
        class="d-flex align-items-center justify-content-between"
        style="width:100%;"
      >
        <div class="d-flex flex-column my-3">
          <div class="d-flex align-items-center mb-2">
            <div class="d-flex align-items-center">
              <h5 class="primary-colour font-weight-bolder h5 m-0">
                {{ selectedCandidateStatus }}
              </h5>
              <div class="d-flex flex-wrap ml-3 cp mobileFontSize">
                <div
                  title="Stage Setup"
                  class="mr-2"
                  @click="goToStageSetup()"
                >
                  <span
                    class="dark-grey-text small font-weight-bold"
                  >
                    STAGE: </span><span>{{ selectedWorkflowStage.RoleWorkflowStageName }}
                  </span>
                </div>
                <div
                  title="Role Setup"
                  class="cp"
                  @click="goToRoleSetup()"
                >
                  <span
                    class="dark-grey-text small font-weight-bold cp"
                  >
                    ROLE: </span>
                  <span>{{ role.JobTitle }}</span>
                </div>
              </div>
            </div>
            <i
              v-show="candidatesLoaded === false"
              class="fa fa-spin fa-spinner"
              style="margin-left: 5px;"
            />
          </div>
          <div style="height :25px;">
            <Treeselect
              v-model="value"
              class="primary-colour mr-auto"
              style="width: 200px;scale: 0.9; transform: translateX(-10px); position: relative; z-index: 5;"
              :multiple="false"
              :clearable="false"
              :max-height="350"
              :disable-branch-nodes="true"
              :show-count="true"
              :options="statusTreeSelectOptions"
              :clear-on-select="true"
              :close-on-select="true"
              @select="goToNewStageItem"
            />
          </div>
        </div>
        <ExpandableSearchBar
          placeHolder="3 characters minimum"
          :width="300"
          :max-widthpercentage="40"
          :search-triggers-a-p-i-call="true"
          @search="search = $event"
        />
        <div
          v-if="search.length > 2"
          style="font-size: 0.8rem"
          class="cp text-black ml-3 overflow-wrap"
          @click="search = ''"
        >
          CLEAR SEARCH
        </div>
      </div>
    </div>

    <!-- Load Day Header -->
    <DateFilterBar
      v-if="showDateFilterBar && candidatesLoaded"
      @changeDate="getRoleCandidatesLocal()"
    />

    <RolesTable
      v-if="candidatesLoaded"
      :candidates-loaded="candidatesLoaded"
      class="mtopone"
      @getRoleCandidates="getRoleCandidatesLocal"
      @setCurrentPage="setCurrentPage"
    />

    <mdb-btn
      v-show="candidatesLoaded"
      type="reset"
      class="float-left btn-outline m-0 up"
      size="sm"
      style="width:100px;"
      @click="$router.go(-1)"
    >
      BACK
    </mdb-btn>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { useUserData, useRoleCandidate, useWorkflow, useStatus, useEvent, useScheduler } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import { computed, onBeforeUnmount, ref } from 'vue'
import { useRoute } from 'vue-router/composables'
import roleV2Service from '@/api/services/roleV2.service'

import RolesTable from './RolesTable/rolesTable.vue'
import ExpandableSearchBar from '@/components/UI/expandableSearch'
import DateFilterBar from '@/views/roles/innerComponents/Pages/Candidates/DateFilterBar/filterBar.vue'

export default {
  name: 'RoleCandidatesContainer',
  components: {
    RolesTable,
    ExpandableSearchBar,
    Treeselect,
    DateFilterBar
  },
  setup () {
    const route = useRoute()

    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)
    const { rid } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    const roleCandidateStore = useRoleCandidate()
    const { candidate, candidates, candidateTableData, viewSchedule, viewType, selectedCandidates, getRoleCandidates, roleCandidateTableCount, getUninvitedRoleCandidates, perPage, currentPage, skip } = storeToRefs(roleCandidateStore)

    const { showGenericErrorModal } = useStatus()

    const workflowStore = useWorkflow()
    const { role, getRoleWithStages, selectedWorkflowStage, selectedWorkflowStageItem } = storeToRefs(workflowStore)

    const eventStore = useEvent()
    const { selectedDate } = storeToRefs(eventStore)

    const schedulerStore = useScheduler()
    const { getSchedulerSetup } = useScheduler()
    const { selectedSchedulerSetup } = storeToRefs(schedulerStore)

    const candidatesLoaded = ref(false)
    candidate.value = {}

    const now = new Date()
    selectedDate.value = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()))

    // search from URL
    const searchQuery = computed(() => {
      try {
        return route.query.search ?? ""
      } catch { return '' }
    })
    const search = ref('')
    search.value = decodeURI(searchQuery.value)

    const showDateFilterBar = computed(() => {
      if (search.value.length > 2) return false
      let stageItemStatus = selectedWorkflowStageItem.value.StageItemStatus
      if (selectedWorkflowStage.value.StageType == 2) {
        if (stageItemStatus === 3) return true
      } else if (selectedWorkflowStage.value.StageType == 3) {
        if (stageItemStatus === 3 || stageItemStatus >= 6) return true
      }
      return false
    })

    // PAGE ROWS AND PAGINATION
    const windowHeight = ref(window.innerHeight)
    currentPage.value = 1
    const onResize = () => windowHeight.value = window.innerHeight
    window.addEventListener('resize', onResize)
    onBeforeUnmount(() => window.removeEventListener('resize', onResize))
    perPage.value = userData.value.pge

    // TREE SELECT
    const value = ref(null)
    const statusTreeSelectOptions = ref([])
    const setStatusTreeNodeOptions = () => {
      statusTreeSelectOptions.value = role.value.RoleWorkflow.OrderedRoleWorkflowStages.map((stage, i) => {
        return {
          id: stage.RoleWorkflowStageId,
          label: stage.RoleWorkflowStageName,
          isDefaultExpanded: stage.RoleWorkflowStageId == route.params.roleWorkflowStageId,
          children: [
            (i == 0 && role.value.InterviewType !== 2) && {
              id: 0,
              label: 'Not Invited',
              parent: stage.RoleWorkflowStageId
            },
            ...stage.OrderedRoleWorkflowStageItems.map(stageItem => {
              return {
                id: stageItem.RoleWorkflowStageItemId,
              label: stageItem.RoleWorkflowStageItemName,
              parent: stage.RoleWorkflowStageId
            }
          })
        ]}
      })
    }


    return {
      rid,
      mobileView,
      role,
      candidate,
      candidates,
      candidateTableData,
      getRoleWithStages,
      selectedWorkflowStage,
      selectedWorkflowStageItem,
      showGenericErrorModal,
      getRoleCandidates,
      roleCandidateTableCount,
      perPage,
      statusTreeSelectOptions,
      setStatusTreeNodeOptions,
      currentPage,
      viewType,
      viewSchedule,
      search,
      selectedCandidates,
      candidatesLoaded,
      value,
      getUninvitedRoleCandidates,
      skip,
      searchQuery,
      getSchedulerSetup,
      selectedSchedulerSetup,
      selectedDate,
      showDateFilterBar
    }
  },
  computed: {
    selectedCandidateStatus () {
      return this.selectedWorkflowStageItem.RoleWorkflowStageItemName
    },
    notInvitedStageItem () {
      return {
        CompletedStageType: false,
        FixedStatusType: true,
        Ordinal: 1,
        ReadableStatus: 'Not Invited',
        RoleWorkflowStageItemName: 'Not Invited',
        RoleWorkflowStageItemId: 0,
        StageItemStatus: 1
      }
    }
  },
  watch: {
    async '$route.params.roleWorkflowStageItemId' () {
      this.currentPage = 1
      await this.fetchStageAndStageItemAndCandidates()
    },
    searchQuery () {
      if (!this.searchQuery) return
      this.search = decodeURI(this.searchQuery)
    },
    search () {
      this.currentPage = 1
      if (!this.search) {
        this.$router.replace({'search': null}).catch(() => {})
      }
      if (this.search.length > 2 || this.search == "") {
        this.getRoleCandidatesLocal()
      }
    },
    selectedDate () {
      if (this.selectedDate != null) this.getRoleCandidatesLocal()
    }
  },
  mounted () {
    this.doPageSetup()
  },
  methods: {
    goToRoleSetup () {
      this.$router.push(`/roles/add_edit/detail/${this.role.JobId}`).catch(() => {})
    },
    goToStageSetup () {
      const stageType = this.selectedWorkflowStage.StageType
      if (stageType == 1) {
        this.$router.push(`/roles/add_edit/one_way_stage_setup/${this.role.JobId}/${this.selectedWorkflowStage.RoleWorkflowStageId}?page=setting_up`).catch(() => {})
      } else if (stageType == 2) {
        this.$router.push(`/roles/add_edit/two_way_stage_setup/${this.role.JobId}/${this.selectedWorkflowStage.RoleWorkflowStageId}?page=meeting_name_and_type`).catch(() => {})
      } else if (stageType == 3) {
        this.$router.push(`/roles/add_edit/event_setup/${this.role.JobId}/${this.selectedWorkflowStage.RoleWorkflowStageId}?page=about`).catch(() => {})
      }
    },
    async doPageSetup () {
      await this.getRoleWithStages(Number(this.$route.params.jobId))
      await this.fetchStageAndStageItemAndCandidates()
    },
    async setCurrentPage (page) {
      this.selectedCandidates = []
      this.currentPage = page
      if (this.viewType > 0) {
        await this.viewSchedule(this.selectedWorkflowStageItem.RoleWorkflowStageItemId, this.viewType)
      } else {
        await this.getRoleCandidatesLocal()
      }
    },
    goToNewStageItem (event) {
      if (!event.parent) return
      this.$router.push({
        name: 'candidates',
        params: {
          jobId: this.$route.params.jobId,
          roleWorkflowStageItemId: event.id,
          roleWorkflowStageId: event.parent
        }
      }).catch(() => {})
    },
    async fetchStageAndStageItemAndCandidates () {
      await this.getCurrentRoleWorkflowStage()
      await this.getCurrentRoleWorkflowStageItem()
      this.candidateTableData = {
        RoleId: this.$route.params.jobId,
        RoleWorkflowStageId: this.$route.params.roleWorkflowStageId,
        RoleWorkflowId: this.role.RoleWorkflowId,
        RoleWorkflowStageItemId: this.selectedWorkflowStageItem.RoleWorkflowStageItemId,
        StageItemStatus: this.selectedWorkflowStageItem.StageItemStatus,
        StageType: this.selectedWorkflowStage.StageType
      }
      this.setStatusTreeNodeOptions()
      this.getRoleCandidatesLocal()
    },
    async getCurrentRoleWorkflowStage () {
      try {
        const res = await roleV2Service.getRoleWorkflowStage(this.$route.params.roleWorkflowStageId)
        this.selectedWorkflowStage = res.data
        if (this.selectedWorkflowStage.IsSelfSchedule) {
          const res = await this.getSchedulerSetup(this.selectedWorkflowStage.RoleWorkflowStageId)
          this.selectedSchedulerSetup = res
        }
      } catch (err) {
        this.showGenericErrorModal()
      }
    },
    async getCurrentRoleWorkflowStageItem () {
      if (this.$route.params.roleWorkflowStageItemId == undefined) return
      // SPECIAL NOT INVITED CASE
      if (this.$route.params.roleWorkflowStageItemId == "0" || this.$route.params.roleWorkflowStageItemId == 0) {
        this.selectedWorkflowStageItem = this.notInvitedStageItem
      } else {
        try {
          const res = await roleV2Service.getRoleWorkflowStageItem(this.$route.params.roleWorkflowStageItemId)
          this.selectedWorkflowStageItem = res.data
        } catch (err) {
          this.showGenericErrorModal()
        }
      }

      this.candidateTableData.RoleWorkflowStageItemId = this.selectedWorkflowStage.RoleWorkflowStageItemId
      this.candidateTableData.StageItemStatus = this.selectedWorkflowStageItem.StageItemStatus
    },
    addOrRemoveCandidateFromList (candidate) {
      if (this.candidates.length > 0) {
        const idx = this.candidates.indexOf(c => c.CandidateId === candidate.CandidateId)
        if (idx === -1) {
          this.candidates.push(candidate)
        } else {
          this.candidates.splice(idx, 1)
        }
      } else {
        this.candidates.push(this.candidate)
      }
    },
    async getRoleCandidatesLocal () {
      this.viewType = 0
      if (!this.$route.params.jobId) return
      this.candidatesLoaded = false
      this.candidates = []
      try {
        if (this.$route.params.roleWorkflowStageItemId === "0" || this.$route.params.roleWorkflowStageItemId === 0) {
          if (this.role.InterviewType != 2) { // self registration only
            await this.getUninvitedRoleCandidates(this.role.RoleWorkflowId, this.skip, this.perPage, this.search)
          }
        } else {
          var filteredDate = (this.showDateFilterBar && !this.search) ? this.selectedDate : null
          await this.getRoleCandidates(this.$route.params.roleWorkflowStageItemId, this.skip, this.perPage, this.search, this.selectedWorkflowStage.StageType, filteredDate)
        }
      } catch (err) {
        this.showGenericErrorModal()
      }
      this.candidatesLoaded = true
      this.buttonClick = false
    }
  }
}
</script>

<style>
.up {
  margin-top: -105px !important;
}
.vue-treeselect__input {
  color: var(--pr-color);
}
@media (max-width: 576px) {
  .mtopone {
    margin-top: 40px !important;
  }
  .mobileFontSize {
    font-size: small !important;
  }
}
</style>
