<template>
  <div style="height:100%;overflow-y:auto;" class="my-4 text-left">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <div style="font-size:1.3rem;" class="font-weight-bold mt-1">
        Add Point Scale
      </div>
      <mdb-btn
        size="sm"
        style="width:fit-content;font-size: 1rem; letter-spacing: 2px;"
        class="m-0 btn font-weight-bold"
        @click="testScale()"
      >
        TEST
      </mdb-btn>
    </div>
    <div style="font-size:0.9rem; font-wrap: balance" class="grey-text mt-3">
      By default, point scales are held as numeric values but you can also use 'Text Based Scoring'. <br/>
      Text based scales written entirely as integers will use the values you give, allowing you to create non-linear scales.
    </div>
    <div
      class="md-form form-sm mr-auto d-flex align-items-center p-0"
      style="margin-top: 40px;margin-bottom: 35px;"
      @click="toggleTextBasedScoring()"
    >
      <mdb-input
        v-model="makeTextBased"
        type="checkbox"
        class="p-0"
        label="Text Based Scoring"
        :disabled="disableTextBased"
        size="sm"
      />
      <Tooltip
        class="ml-2 mt-3"
        tooltip-direction="right"
        :tool-tip-summary="'For Text Based Scoring please choose a \'Number of Steps\' value between 2 and 5.'"
      />
    </div>
    <div class="d-flex align-items-center mt-4" style="height:60px">
      <i class="mr-3 fas fa-signature" style="font-size: 1.3rem;" />
      <mdb-input
        v-model="scaleName"
        type="text"
        auto-complete="jbt"
        label="Scale name **"
        size="sm"
        class="scoringScaleForm"
      />
    </div>
    <div
      v-if="!textValuesAreAllNumbers"
      class="d-flex align-items-center mt-4"
      style="height:60px">
      <i class="mr-3 fas fa-signature" style="font-size: 1.3rem;" />
      <mdb-input
        v-model="lowestScaleValue"
        type="number"
        auto-complete="jbt"
        label="Lowest Point Value"
        :min="0"
        :max="100"
        size="sm"
        class="scoringScaleForm"
      />
    </div>
    <div class="d-flex align-items-center mt-4" style="height:60px">
      <i class="mr-3 fas fa-signature" style="font-size: 1.3rem;" />
      <mdb-input
        v-model="numberOfSteps"
        type="number"
        auto-complete="jbt"
        label="Number Of Steps"
        :min="2"
        :max="numberOfStepsMax"
        size="sm"
        class="scoringScaleForm"
      />
    </div>
    <div
      v-if="!textValuesAreAllNumbers"
      class="d-flex align-items-center mt-4"
      style="height:60px">
      <i class="mr-3 fas fa-signature" style="font-size: 1.3rem;" />
      <mdb-input
        v-model="stepIncrement"
        type="number"
        auto-complete="jbt"
        label="Points Per Step"
        :min="1"
        :max="100"
        size="sm"
        class="scoringScaleForm"
      />
    </div>
    <div class="d-flex align-items-center justify-content-between mt-5 mb-3">
      <mdb-btn
        size="sm"
        style="width:fit-content;"
        class="m-0 btn btn-outline"
        @click="$router.back(-1)"
      >
        BACK
      </mdb-btn>
      <mdb-btn
        v-if="!saving"
        size="sm"
        style="width:fit-content;min-width:100px"
        :disabled="scaleName === ''"
        class="m-0"
        @click="save()"
      >
        SAVE
      </mdb-btn>
      <mdb-btn
        v-else
        size="sm"
        style="width:fit-content;min-width:100px"
        class="m-0"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
    <!-- TABLE -->
    <div v-if="makeTextBased" class="textScaleContainer font-weight-bold text-center mt-5 mb-5">
      <div class="d-flex align-items-center black-text textScaleTableHeader py-2">
        <div class="mr-3 textStepColumn">
          Points
        </div>
        <div class="mr-3 textWordColumn">
          Text Value
        </div>
      </div>
      <div
        v-for="(steps, i) in Number(numberOfSteps)"
        :key="steps"
        class="d-flex align-items-center textColumnRow pr-3 mb-2"
      >
        <div class="mr-3 mt-1 textStepColumn">
          <span  v-if="!textValuesAreAllNumbers">{{ (i * Number(stepIncrement)) + Number(lowestScaleValue) }}</span>
          <span v-else>{{textArray[i]}}</span>
        </div>
        <mdb-input
          v-model="textArray[i]"
          type="text"
          auto-complete="jbt"
          size="sm"
          class="scoringScaleTextForm textWordColumn"
        />
      </div>
    </div>

    <TestScoringModal
      :test-scoring-modal-showing="testScoringModalShowing"
      :lowest-scale-value="Number(lowestScaleValue)"
      :number-of-steps="Number(numberOfSteps)"
      :text-array="textArray"
      :numericTextScores="textValuesAreAllNumbers"
      :step-increment="Number(stepIncrement)"
      :make-text-based="makeTextBased"
      @testScoringModalShowing="testScoringModalShowing = $event"
    />
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import scoringService from '@/api/services/scoring.service.js'

import TestScoringModal from './testScoringScaleModal.vue'
import Tooltip from '@/components/misc/tooltip.vue'

export default {
  name: 'AddScale',
  components: {
    TestScoringModal,
    Tooltip
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()

    return {
      authData,
      showGenericErrorModal
    }
  },
  data () {
    return {
      scaleName: '',
      lowestScaleValue: 0,
      numberOfSteps: 2,
      stepIncrement: 1,
      makeTextBased: false,
      textArray: [],
      testScoringModalShowing: false,
      saving: false
    }
  },
  computed: {
    textValuesAreAllNumbers () {
      if (this.textArray.length == 0) return false
      let count = 0
      this.textArray.forEach(r => { 
        if (isNaN(r) || r.length == 0) count++
      })
      return count == 0
    },
    computedLowestScaleValue () {
      if (!this.textValuesAreAllNumbers) return Number(this.lowestScaleValue)
      let biggestValue = 0
      this.textArray.forEach(v => biggestValue = biggestValue < Number(v) ? Number(v) : biggestValue)
      return biggestValue
    },
    numberOfStepsMax () {
      return this.makeTextBased ? 5 : 11
    },
    textBasedArrayIsValid () {
      // must not contain undefineds and must be same length as the step count
      if (this.textArray.length !== Number(this.numberOfSteps)) { return false }
      if (this.textArray.filter(t => t === undefined || t === '').length > 0) { return false }
      return true
    },
    disableTextBased () {
      return this.numberOfSteps < 2 || this.numberOfSteps > 5
    }
  },
  watch: {
    numberOfSteps () {
      if (!this.makeTextBased) { return }
      if (this.numberOfSteps > 5) {
        this.numberOfSteps = 5
      } else if (this.numberOfSteps < 2) {
        this.numberOfSteps = 2
      }
    }
  },
  methods: {
    toggleTextBasedScoring () {
      if (this.disableTextBased === true) { return }
      this.makeTextBased = !this.makeTextBased
      this.textArray = []
    },
    testScale () {
      if (!this.textBasedArrayIsValid && this.makeTextBased) {
        this.mustCompleteTextBasedErrorModal()
      } else {
        this.testScoringModalShowing = true
      }
    },
    save () {
      if (!this.textBasedArrayIsValid && this.makeTextBased) {
        this.mustCompleteTextBasedErrorModal()
      } else {
        this.saveScore()
      }
    },
    mustCompleteTextBasedErrorModal () {
      this.showGenericErrorModal({
        showing: true,
        errorTitle: 'Incomplete Column Values',
        errorMessage: 'Please fill in all columns before continuing.'
      })
    },
    async saveScore () {
      this.saving = true
      try {
        const fData = new FormData()
        fData.append('scaleName', this.scaleName)
        fData.append('lowestScaleValue', Number(this.computedLowestScaleValue))
        fData.append('numberOfSteps', Number(this.numberOfSteps))
        fData.append('stepIncrement', Number(this.stepIncrement))
        fData.append('makeTextBased', this.makeTextBased)
        fData.append('textArray', JSON.stringify(this.textArray))
        await scoringService.saveScale(fData)
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.$router.push('/settings/scoring/scales/all')
      this.saving = false
    }
  }
}
</script>

<style lang="scss" scoped>
.scoringScaleForm {
  margin: 10px 0px 4px 0px !important;
  width:100%;
  max-width:600px;
}
.scoringScaleTextForm {
  margin: 10px 0px 4px 0px !important;
  width:100%;
  max-width:300px;
}
.textScaleContainer {
  width: 100%;
  max-width: 400px;
  border: #c0c0c0 solid 2px;
}
.textScaleTableHeader {
  // background: var(--op-color)
  font-size: 1.2rem;
}
.textStepColumn {
  width: 25%;
  max-width: 100px;
  border-right: #c0c0c0 solid 1px;
}
.textWordColumn {
  width: 75%;
  max-width: 300px;
}
.textColumnRow {
  max-height: 50px;
  border-top: #c0c0c0 solid 1px;
}
</style>
