<template>
  <div class="d-flex flex-column" style="height:100%;padding-bottom:60px;">
    <div class="font-weight-bold py-4 flex-grow-0">
      Question Sets
    </div>
    <div class="flex-grow-1 py-3 py-md-4 w-1/1 px-3 text-black rounded" style="border: rgb(225, 225, 225) 2px solid;overflow-x:hidden;">
      <div v-if="loadingQuestionSet">
        <h3 class="primary-colour text-left font-weight-bold">
          Loading<i style="margin-left: 5px !important;" class="fa fa-spin fa-spinner ml-3" />
        </h3>
      </div>
      <div
        v-if="!loadingQuestionSet && questionSetList.length == 0"
        style="font-size:0.8rem;"
        class="text-center"
      >
        Click 'ADD NOW' to create your first question set
      </div>
      <div v-if="!loadingQuestionSet && questionSetList.length > 0">
        <b-row
          v-for="set in questionSetList"
          :key="set.QuestionSetId"
          no-gutters
          style="width:100%;height:60px;"
          class="text-bold text-black rounded text-center border-2 border-gray-400  bg-gray-100 my-1 flex-nowrap"
        >
          <div style="width:10%; max-width: 40px;;position:relative;border-radius:5px" class="m-2 d-none d-sm-flex align-items-center justify-content-center insetShadowPrimary">
            <FontAwesomeIcon icon="fa-circle-question fa-solid" style="font-size:1rem;position:absolute; top:4px; left:12px;" />
            <FontAwesomeIcon icon="fa-circle-question fa-solid" style="font-size:1rem;position:absolute; top:18px; left:4px;" />
            <FontAwesomeIcon icon="fa-circle-question fa-solid" style="font-size:1rem;position:absolute; top:18px; left:20px;" />
          </div>
          <b-col class="align-self-center twoLinedEllipsis ml-2" style="text-align:left;font-size:smaller;">
            {{ set.QuestionSetName }}
          </b-col>
          <div class="d-flex align-self-center align-items-center justify-content-center font-extrabold mr-auto px-1" style="font-size:smaller;height:30px;border:solid 2px var(--pr-color);border-radius:15px;cursor:default;">
            <mdb-popover
              ref="popover"
              trigger="hover"
              :options="{placement: 'left'}"
            >
              <div slot="body">
                <div v-for="(q, i) in set.Questions" :key="i">
                  <span class="font-weight-bold text-black">{{ i+1 }}. </span><span>{{ q.Description }}</span>
                </div>
              </div>
              <div slot="reference" style="padding:10px;">
                <div class="d-block d-sm-none" style="font-size:smaller;">
                  {{ set.Questions.length }} Q{{ set.Questions.length > 1 ? '\'S' : '' }}
                </div>
                <div class="d-none d-sm-block" style="font-size:smaller;">
                  {{ set.Questions.length }} QUESTION{{ set.Questions.length > 1 ? 'S' : '' }}
                </div>
              </div>
            </mdb-popover>
          </div>
          <div class="flex-grow-0 mx-2 d-flex align-items-center">
            <mdb-btn class="px-2 py-1" @click="selectSet(set)">
              EDIT
            </mdb-btn>
          </div>
        </b-row>
      </div>
    </div>
    <div style="width:100%;min-height:65px;" class="d-flex justify-content-end align-items-center">
      <mdb-btn
        class="p-2"
        style="width:130px;"
        size="sm"
        @click="goToAdd()"
      >
        ADD NOW
      </mdb-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AllQuestionSets',
  components: {
  },
  props: {
    questionSetList: {
      type: Array,
      default: () => []
    },
    defaultSet: {
      type: Array,
      default: () => []
    },
    loadingQuestionSet: Boolean
  },
  methods: {
    goToAdd () {
      this.$emit('selectSet', this.defaultSet)
      this.$emit('isAdding', true)
    },
    selectSet (set) {
      this.$emit('selectSet', [set])
      this.$emit('isAdding', true)
    }
  }
}
</script>
