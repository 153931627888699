<template>
  <div v-if="loading">
    <mdb-icon icon="fa fa-pulse fa-spinner" />
  </div>
  <div
    v-else-if="!loading && elements.length > 0"
    class="mb-5"
    style="margin-top:-50px;"
  >
    <div
      v-if="view == 'profile'"
      class="text-sm"
      style="margin-top:100px;margin-bottom:50px;text-align: center;font-weight: bold;"
    >
      ADDITIONAL INFORMATION
    </div>
    <div
      :class="{'custom-fields-container': view == 'profile'}"
    >
      <div
        v-for="(form, i) in elements"
        :key="i"
        style="height: 60px"
        :style="form.Type == 'textarea' ? { height: '80px' } : { height: '60px' }"
        :class="{ 'span-2-columns mt-3 mb-4': form.Type == 'textarea' }"
      >
        <div
          class="md-form"
        >
          <i
            v-if="view == 'profile'"
            class="fas fa-user prefix"
            style="margin-top: 6px;font-size: 1.3rem;"
          />
          <div
            v-else
            style="float:left;"
          >
            <i
              class="fas fa-user prefix"
              style="margin-top: 6px;font-size: 1.3rem;"
            />
          </div>
          <mdb-input
            v-if="form.Type === 'text' || form.Type == 'textarea' || form.Type == 'number'"
            v-model="form.Value"
            :type="form.Type"
            :rows="4"
            :label="form.Question"
            style="margin-left:33px;"
            size="sm"
          />
          <mdb-select
            v-if="form.Type == 'dropdown'"
            v-model="form.Dropdown.value"
            :label="form.Question"
            style="margin-left:33px; font-size: 0.9rem !important"
            size="sm"
          />
          <div
            v-if="form.Type == 'bool'"
            class="d-flex align-items-center"
            style="transform: translateX(20px); height: 60px "
          >
            <mdb-switch
              v-model="form.Value"
              style="font-size: 0.9rem !important; width :70px;height: 60px "
              off-label=""
              on-label=""
              class="m-0"
              size="sm"
            />
            <div
              class="dark-grey-text font-small"
              style="transform: translateY(-7px)"
            >
              {{ form.Question }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useCandidate } from '@/pinia'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue-demi'
export default {
  name: 'CustomProfileFields',
  props: {
    view: {
      type: String,
      default: 'profile'
    }
  },
  setup (props, { emit }) {
    const candidateStore = useCandidate()
    const {
      getCustomFieldValues,
      saveCustomFieldValues
    } = useCandidate()
    const {
      candidateToEdit
    } = storeToRefs(candidateStore)


    const loading = ref(false)
    const elements = ref([])
    const getFormElements = async () => {
      const candidateAccountId = props.view == 'profile' ? candidateToEdit.value.CandidateAccountId : 0
      const values = await getCustomFieldValues(candidateAccountId)
      elements.value = values
      if (values.length > 0) {
        convertElementsToForms()
      }
      loading.value = false
    }

    getFormElements()

    const convertElementsToForms = () => {
      elements.value = [...elements.value.map(f => {
        if (f.Type == 'bool') { // category == 6 is the special case for declaration which we want to convert to a boolean
          f.Type = 'bool'
          f.Value = f.Value == 'false' || f.Value == '' ? false : true
          return f
        }
        else if (f.Type == 'string' || f.Type == 'textarea') {
          f.Value = f.Value != '' ? f.Value : ''
          return f
        }
        else if (f.Type == 'number') {
          f.Value = f.Value != '' ? f.Value : 0
          return f
        }
        else if (f.Type == 'dropdown') {
          let dropdown = (JSON.parse(f.CustomFieldInformation)).map((option) => {
            return { value: option, text: option, selected: f.Value ? f.Value == option : false }
          })
          if (!dropdown.find(s => s.selected)) dropdown = dropdown.map((s, index) => ({ ...s, selected: index == 0 }))
          f.Dropdown = ref(dropdown)
          f.Value = computed(() => f.Dropdown.value.find(f => f.selected)?.text || null)
          return f
        }
        return f
      })]
    }

    // have a computed values of the fields and their values and then have a watcher which automatically saves them after 1 second of inactivity
    const valuesToSave = ref([])
    watch(elements, () => {
      valuesToSave.value = elements.value.map(e =>
        ({ CustomFieldElementId: e.CustomFieldElementId, Value: e.Type != 'dropdown' ? `${e.Value}` : (e.Dropdown.value.find(s => s.selected)?.text || null) })
      )
    }, { deep: true, immediate: true })

    const debounceTimeout = ref(null)
    watch(valuesToSave, () => {
      if (debounceTimeout.value) {
        clearTimeout(debounceTimeout.value)
      }
      if (valuesToSave.value.length > 0) {
        const JSON = {
          CustomFormValues: valuesToSave.value,
          CandidateAccountId: props.view == 'profile' ? candidateToEdit.value.CandidateAccountId : 0
        }
        if (props.view == 'profile') {
          debounceTimeout.value = setTimeout(() => {
            saveCustomFieldValues(JSON)
          }, 1000)
        } else if (props.view == 'invite_and_add_modal') {
          emit('customValues', JSON)
        }
      }
    }, { deep: true })

    return {
      elements,
      loading
    }
  }
}
</script>

<style scoped>
.custom-fields-container {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 25px;
  row-gap: 10px;
  grid-auto-flow: dense;
}

@media (min-width: 992px) {
  .custom-fields-container {
    grid-template-columns: 1fr 1fr; /* Two columns above 992px */
  }
}

.span-2-columns {
  grid-column: span 2 !important;
}
</style>
