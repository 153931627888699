<template>
  <div class="flex flex-col px-8">
    <span class="font-bold text-left pt-4">
      Setup your authentication parameters
    </span>

    <div
      class="lg-form form-lg m-0 mt-3 mb-2 text-left"
      style="transform: translateX(-18px)"
      @click="eventSubscription.RequireAuthentication = !eventSubscription.RequireAuthentication"
    >
      <mdb-input
        v-model="eventSubscription.RequireAuthentication"
        type="checkbox"
        label="Require Authentication"
        size="sm"
      />
    </div>

    <div v-if="eventSubscription.RequireAuthentication">
      <div class="w-3/3 flex flex-col">
        <div
          v-if="eventSubscription.ConnectionType > 0"
          class="grey-text"
          style="text-align: left;margin-top: 20px;margin-bottom: -30px;font-size: 11px;margin-left: 26px;"
        >
          Connection Type
        </div>
        <div class="flex flex-row items-center" style="margin-bottom:-15px">
          <font-awesome-icon :icon="['fas', 'gears']" class="mr-2" />
          <mdb-select
            v-model="connectionType"
            placeholder="Connection Type"
            size="sm"
            class="w-full"
            @change="connectionTypeSelect($event)"
          />
        </div>
        <div
          v-if="eventSubscription.ConnectionMethod > 0"
          class="grey-text"
          style="text-align: left;margin-top: 20px;margin-bottom: -30px;font-size: 11px;margin-left: 26px;"
        >
          Connection Method
        </div>
        <div class="flex flex-row items-center" style="margin-bottom:-15px">
          <font-awesome-icon :icon="['fas', 'gears']" class="mr-2" />
          <mdb-select
            v-model="connectionMethod"
            placeholder="Connection Method"
            size="sm"
            class="w-full"
            @change="connectionMethodSelect($event)"
          />
        </div>
        <div
          v-if="eventSubscription.DeliveryMethod > 0"
          class="grey-text"
          style="text-align: left;margin-top: 20px;margin-bottom: -30px;font-size: 11px;margin-left: 26px;"
        >
          Delivery Method
        </div>
        <div class="flex flex-row items-center" style="margin-bottom:-15px">
          <font-awesome-icon :icon="['fas', 'gears']" class="mr-2" />
          <mdb-select
            v-model="deliveryMethod"
            placeholder="Delivery Method"
            size="sm"
            class="w-full"
            @change="deliveryMethodSelect($event)"
          />
        </div>
        <div v-if="eventSubscription.ConnectionType > 0">
          <div
            v-if="eventSubscription.ConnectionUrl != ''"
            class="grey-text"
            style="text-align: left;margin-top: 20px;margin-bottom: -30px;font-size: 11px;margin-left: 26px;"
          >
            Oauth Url
          </div>
          <div class="flex flex-row items-center" style="margin-bottom:-15px">
            <font-awesome-icon :icon="['fas', 'gears']" class="mr-2" />
            <mdb-input
              v-model="eventSubscription.ConnectionUrl"
              placeholder="Oauth Url"
              size="sm"
              class="w-full"
            />
          </div>
        </div>
        <!--REST OAUTH ONLY-->
        <div v-if="eventSubscription.ConnectionType === 1">
          <div
            v-if="eventSubscription.ClientId != ''"
            class="grey-text"
            style="text-align: left;margin-top: 20px;margin-bottom: -30px;font-size: 11px;margin-left: 26px;"
          >
            Client Id
          </div>
          <div class="flex flex-row items-center" style="margin-bottom:-15px">
            <font-awesome-icon :icon="['fas', 'gears']" class="mr-2" />
            <mdb-input
              v-model="eventSubscription.ClientId"
              placeholder="Client Id"
              size="sm"
              class="w-full"
            />
          </div>
          <div
            v-if="eventSubscription.ClientSecret != ''"
            class="grey-text"
            style="text-align: left;margin-top: 20px;margin-bottom: -30px;font-size: 11px;margin-left: 26px;"
          >
            Client Secret
          </div>
          <div class="flex flex-row items-center" style="margin-bottom:-15px">
            <font-awesome-icon :icon="['fas', 'gears']" class="mr-2" />
            <mdb-input
              v-model="eventSubscription.ClientSecret"
              placeholder="Client Secret"
              size="sm"
              class="w-full"
            />
          </div>
          <div
            v-if="eventSubscription.Scope != ''"
            class="grey-text"
            style="text-align: left;margin-top: 20px;margin-bottom: -30px;font-size: 11px;margin-left: 26px;"
          >
            Scope (Optional)
          </div>
          <div class="flex flex-row items-center" style="margin-bottom:-15px">
            <font-awesome-icon :icon="['fas', 'gears']" class="mr-2" />
            <mdb-input
              v-model="eventSubscription.Scope"
              placeholder="Scope"
              size="sm"
              class="w-full"
            />
          </div>
        </div>
        <div class="items-center">
          <mdb-btn
            v-if="eventSubscription.ConnectionType === 1 && eventSubscription.ConnectionMethod > 0 && eventSubscription.DeliveryMethod > 0 && eventSubscription.ClientSecretVariable != '' && eventSubscription.ClientSecret != '' && eventSubscription.ClientIdVariable != '' && eventSubscription.ClientId != '' && eventSubscription.ConnectionUrl!= ''"
            class="btn-sm ml-auto"
            style="width:150px;"
            @click="showTestConnectionModal(eventSubscription)"
          > 
            TEST CONNECTION
          </mdb-btn>
        </div>
      </div>
      <mdb-modal
        id="genericInfoModal"
        :show="infoModal"
        @close="infoModal = false"
      >
        <mdb-modal-header>
          <mdb-modal-title>{{ infoTitle.toUpperCase() }}</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body>
          <div
            style="word-break: break-all;max-height:400px;overflow-y:auto"
          >
            <div v-if="success">
              <p class="my-4" style="margin-top:5px !important;text-align:left;">
                Success
              </p>
              <p
                class="my-4 grey-text"
                style="margin-top:-10px !important;font-size:0.8rem;text-align:left;"
                v-html="infoMessage"
              />
            </div>
            <div v-else>
              <p class="my-4" style="margin-top:5px !important;text-align:left;">
                Failed
              </p>
              <p
                class="my-4 grey-text"
                style="margin-top:-10px !important;font-size:0.8rem;text-align:left;"
                v-html="infoMessage"
              />
            </div>
          </div>
        </mdb-modal-body>
        <mdb-modal-footer>
          <mdb-btn
            color="primary"
            size="sm"
            style="width:100px;"
            @click.native="infoModal = false"
          >
            CLOSE
          </mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>
    </div>
  </div> 
</template>

<script>
import { ref } from 'vue'
import { useEventSubscription, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import eventSubscriptionService from '@/api/services/eventsubscription.service'

export default {
  name: 'ConnectionForm',
  setup () {

    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()

    const eventSubscriptionStore = useEventSubscription()
    const { eventSubscription, connectionType, connectionMethod, deliveryMethod } = storeToRefs(eventSubscriptionStore)

    const infoModal = ref(false)
    const infoTitle = ref('')
    const infoMessage = ref('')
    const success = ref(false)

    return {
      eventSubscription,
      connectionType,
      connectionMethod,
      deliveryMethod,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal,
      infoModal,
      infoTitle,
      infoMessage,
      success
    }
  },
  created () {
    this.isValidated = false 
    if (this.eventSubscription.EventSubscriptionId > 0) {
      this.setOptions(this.eventSubscription)
    } else {
      this.$router.push('/settings/eventsubscriptions')
    }
  },
  methods: {
    showTestConnectionModal (sub) {
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'AUTHENTICATION PARAMETERS TEST',
        confirmMessage: 'This test will take your oauth url and perform an authorisation request based on the parameters provided.',
        confirmButtonMessage: 'TEST',
        confirmButtonFunction: () => this.testConnection(sub)
      })
    },
    async testConnection(sub) {
      try {
        const res = await eventSubscriptionService.testOauth(sub)
        this.closeGenericConfirmModal()
        this.success = true
        this.infoModal = true
        this.infoTitle = 'SUBSCRIPTION TEST',
        this.infoMessage = res.data
      } catch (e) {
        this.closeGenericConfirmModal()
        this.success = false
        this.infoModal = true
        this.infoTitle = 'SUBSCRIPTION TEST',
        this.infoMessage = e
      }
    },
    setOptions (sub) {
      if (sub.ConnectionType === 1) {
        this.connectionType = [
          { text: 'REST', value: 1, selected: true }
        ]
      }
      if (sub.ConnectionMethod === 1) {
        this.connectionMethod = [
          { text: 'OAUTH', value: 1, selected: true }
        ]
      }
      if (sub.DeliveryMethod === 1) {
        this.deliveryMethod = [
          { text: 'POST', value: 1, selected: true }
        ]
      }
    },
    connectionTypeSelect (value) {
      this.eventSubscription.ConnectionType = value

      if (this.$parent.currentPage === 'authentication') {
        if (this.eventSubscription.ConnectionType < 1) {
          this.isValidated = false 
        } else {
          this.isValidated = true
        }
      }

    },
    connectionMethodSelect (value) {
      this.eventSubscription.ConnectionMethod = value

      if (this.$parent.currentPage === 'authentication') {
        if (this.eventSubscription.ConnectionMethod < 1) {
          this.isValidated = false 
        } else {
          this.isValidated = true
        }
      }
    },
    deliveryMethodSelect (value) {
      this.eventSubscription.DeliveryMethod = value

      if (this.$parent.currentPage === 'authentication') {
        if (this.eventSubscription.DeliveryMethod < 1) {
          this.isValidated = false 
        } else {
          this.isValidated = true
        }
      }
    }
  }
}
</script>
<style>
  .md-form textarea.md-textarea {
    padding: 0;
    overflow-y: auto !important;
  }
</style>
