<template>
  <div
    style="height: 100%"
    class="d-flex h-100"
    :title="'Role Id: ' + role.JobId"
  >
    <div
      style="font-size:1rem;font-weight:bold;width:100%;"
      class="d-flex align-items-center py-2"
      :style="{'opacity': !role.IsComplete && role.CloseDateIsPast ? '1' : '1' }"
    >
      <div
        class="ml-2 d-flex align-items-center text-black cp mr-auto"
        :style="{'color': !role.CloseDateIsPast ? userData.lbl.SecondaryColour : userData.lbl.PrimaryColour}"
        style="height:100%; text-wrap: balance; font-size: 1.3rem"
        @click="editRole()"
      >
        <!-- :title="role.JobTitle + ' (' + role.JobId + ') created by ' + role.AddedBy.FirstName + ' ' + role.AddedBy.Surname" -->
        <!--loop 1-->
        {{ role.JobTitle }}
      </div>
      <div
        v-if="(role.Following && role.IsComplete) || (role.Following && !role.IsComplete)"
        title="Unfollow this role"
        style="border-radius:5px;background-color:#fff;"
        class="ml-auto d-flex align-items-center cp mr-1"
        @click="unFollowRole()"
      >
        <mdb-icon icon="star" style="transform:scale(0.7)" />
        <div style="font-size:0.6rem" class="mr-1 d-none d-sm-block d-md-none d-lg-block">
          Following
        </div>
      </div>
      <div
        v-if="(!role.Following && role.IsComplete) || (!role.Following && !role.IsComplete)"
        title="Follow this role"
        style="border-radius:5px;background-color:#fff;"
        class="ml-auto d-flex align-items-center cp mr-1"
        @click="followRole()"
      >
        <mdb-icon
          far
          icon="star"
          style="transform:scale(0.7)"
        />
        <div style="font-size:0.6rem;margin-top:1px;" class="mr-1 d-none d-sm-block d-md-none d-lg-block">
          Follow
        </div>
      </div>
      <FontAwesomeIcon
        v-if="userData.rle !== 'Inviter'"
        title="Role Setup"
        style="scale: 1.2"
        class="white-text mx-2 cp"
        icon="fa-gear fa-regular"
        @click="editRole()"
      />
      <TopPanelPopover
        style="z-index:99;"
        class="mr-1"
        :noShow="false"
        :role="role"
      />
    </div>
    <mdb-modal
      :show="cloneRoleModalShowing"
      style="height:100vh; width: 100vw; position:fixed; top: 0; left: 0; z-index:100000000;"
      @close="closeCloneRoleModalShowing()"
    >
      <mdb-modal-header>
        REPLICATE ROLE
      </mdb-modal-header>
      <mdb-modal-body class="px-xs-2 py-xs-1 px-sm-4 py-sm-4">
        <div class="grey-text text-left" style="font-size: 0.9rem;margin-top:0px;">
          Please edit the following values and then confirm you wish to replicate the role {{ role.JobTitle }}.<br>
        </div>
        <div class="md-form form-sm text-left" style="margin-top:30px !important;">
          <i class="fa fa-signature prefix" style="margin-top: 10px;font-size: 1rem;" />
          <mdb-input
            v-model="jobTitle"
            type="text"
            label="Job Title"
            auto-complete="fna"
            size="sm"
            style="margin-left:33px;font-size: 0.9rem;color:#888;"
          />
        </div>
        <div class="md-form form-sm text-left">
          <i class="far fa-question-circle prefix" style="margin-top: 10px;font-size: 1rem;" />
          <mdb-input
            v-model="externalReferenceId"
            type="text"
            label="External reference (optional)"
            auto-complete="fna"
            size="sm"
            style="margin-left:33px;font-size: 0.9rem;color:#888;"
          />
        </div>
        <div
          v-if="!isEvergreen"
          class="md-form form-sm"
        >
          <div style="font-size: 0.7rem;color:#888;transform:translate(30px, -10px)">
            Closing date
          </div>
          <i class="far fa-clock prefix" style="margin-top: 16px;font-size: 1rem;" />
          <input
            v-model="endDate"
            type="date"
            :min="minimumDate"
            onkeydown="return false"
            auto-complete="opd"
            label="Closing date"
            style="margin-left:33px;font-size: 0.9rem;color:#888;"
            size="sm"
          >
        </div>
        <div
          class="md-form form-sm m-0 mb-5"
          style="transform: translateX(-20px);margin-top:-10px !important;margin-bottom: 10px !important;"
          @click="isEvergreen = !isEvergreen"
        >
          <mdb-input
            v-model="isEvergreen"
            type="checkbox"
            label="Evergreen Role"
            size="sm"
          />
        </div>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          v-if="!spinningFlag"
          type="submit"
          :disabled="jobTitle === '' || (endDate === null && isEvergreen === false)"
          class="float-right"
          size="sm"
          style="width:120px;"
          @click="confirmCloneRole()"
        >
          REPLICATE
        </mdb-btn>
        <mdb-btn
          v-else
          size="sm"
          style="width:120px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>  
  </div>
</template>

<script>

import { useUserData, useStatus, useRoles, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import roleV2Service from '@/api/services/roleV2.service'
import TopPanelPopover from './topPanelPopover.vue'

export default {
  name: 'TopPanel',
  components: {
    TopPanelPopover
  },
  props: {
    role: Object
  },
  setup () {
    const userStore = useUserData()
    const { userData, authData } = storeToRefs(userStore)

    const { showGenericErrorModal, closeGenericConfirmModal, showGenericConfirmModal } = useStatus()

    const rolesStore = useRoles()
    const { roleSharingModalInitialData, cloneRole, roleSharingModalShowing, getRoleAndReplace } = storeToRefs(rolesStore)

    const workflowStore = useWorkflow()
    const { selectedWorkflowStage } = storeToRefs(workflowStore)

    const isHovering = ref(false)
    const hoverTimeout = ref(null)

    return {
      userData,
      showGenericErrorModal,
      closeGenericConfirmModal,
      showGenericConfirmModal,
      isHovering,
      hoverTimeout,
      authData,
      cloneRole,
      getRoleAndReplace,
      selectedWorkflowStage,
      roleSharingModalInitialData,
      roleSharingModalShowing
    }
  },
  data () {
    return {
      cloneRoleModalShowing: false,
      jobTitle: '',
      externalReferenceId: '',
      endDate: null,
      isEvergreen: false,
      spinningFlag: false,
      type: 1,
      minimumDate: '',
    }
  },
  watch: {
    isEvergreen () {
      if (this.isEvergreen) {
        this.endDate = '2999-12-01'
      } else {
        this.endDate = null
      }
    }
  },
  async mounted() {
    await this.$nextTick(() => {})
  },
  methods: {
    async confirmCloneRole () {
      this.spinningFlag = true
      try {
        if (this.role.RoleWorkflow.RoleWorkflowStages[0].RoleWorkflowStageName === 'One-Way') {
          this.type = 1
        } else if (this.role.RoleWorkflow.RoleWorkflowStages[0].RoleWorkflowStageName === 'Live Interview') {
          this.type = 2
        }
        await this.cloneRole(this.role.JobId, this.type, this.jobTitle, this.externalReferenceId, this.endDate, this.isEvergreen)
        window.location.reload()
      } catch (err) {
        this.showGenericErrorModal()
      }
      this.spinningFlag = false
      this.closeCloneRoleModalShowing()
    },
    closeCloneRoleModalShowing () {
      this.cloneRoleModalShowing = false
      this.jobTitle = ''
      this.externalReferenceId = ''
      this.endDate = null
      this.isEvergreen = false
      this.loading = false
    },
    prepareAndShowShareModal () {
      this.roleSharingModalInitialData = {
        roleId: this.role.JobId,
        shareType: 'one-way',
        roleWorkflowStageId: this.role.RoleWorkflow.RoleWorkflowStages[0].RoleWorkflowStageId,
        candToShare: []
      }
      this.roleSharingModalShowing = true
      this.selectedWorkflowStage = this.role.RoleWorkflow.RoleWorkflowStages[0]
    },
    editRole () {
      this.$router.push(`/roles/add_edit/detail/${this.role.JobId}`).catch(() => {})
    },
    async followRole () {
      try {
        const res = await roleV2Service.followRole(this.role.JobId)
        if (res.data == true) {
          this.$emit('setFollowing', true)
        }
      } catch {
        this.showGenericErrorModal()
      }
    },
    async unFollowRole () {
      try {
        const res = await roleV2Service.unFollowRole(this.role.JobId)
        if (res.data == true) {
          this.$emit('setFollowing', false)
        }
      } catch (err) {
        console.log(Object.assign({}, err))
        this.showGenericErrorModal({showing: true})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .marqueeStyle {
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    top: 10px;
  }
  .marquee span {
    display: inline-block;
    animation: marquee calc(var(--scroll-speed) * .5s) linear infinite;
  }
  .marquee2 span {
    display: inline-block;
    animation: marquee2 calc(var(--scroll-speed) * .5s) linear infinite;
  }
  @keyframes marquee {
    0% {
      transform: translateX(0%);
      opacity: 0;
      display: none;
    }
    19% {
      transform: translateX(0%);
      opacity: 1;
    }
    20% {
      display: inline-block;
      transform: translateX(0%);
      opacity: 1;
    }
    79% {
      transform: translateX(calc(var(--scroll-speed) * -7%));
      display: none;
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    81% {
      transform: translateX(0%);
      opacity: 0;
    }
    100% {
      opacity: 0;
      transform: translateX(0%);
    }
  }
  @keyframes marquee2 {
    0% {
      transform: translateX(0%);
      opacity: 1;
    }
    19% {
      transform: translateX(0%);
      display: none;
      opacity: 0;
    }
    20% {
      opacity: 0;
      transform: translateX(0%);
    }
    21% {
      transform: translateX(calc(var(--scroll-speed) * 7%));
      opacity: 0;
    }
    22% {
      opacity: 1;
      display: inline-block;
      transform: translateX(calc(var(--scroll-speed) * 7%));
    }
    80% {
      transform: translateX(0%);
      opacity: 1;
    }
    81% {
      transform: translateX(0%);
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }
