import { render, staticRenderFns } from "./spotlightViewLeftColumn.vue?vue&type=template&id=f5440a62&scoped=true"
import script from "./spotlightViewLeftColumn.vue?vue&type=script&lang=js"
export * from "./spotlightViewLeftColumn.vue?vue&type=script&lang=js"
import style0 from "./spotlightViewLeftColumn.vue?vue&type=style&index=0&id=f5440a62&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5440a62",
  null
  
)

export default component.exports