<template>
  <div class="ID-checked-badge-icon-container">
    <FontAwesomeIcon
      icon="fa-badge-check fa-solid"
      class="ID-checked-badge-icon"
      title="Verified"
      size="sm"
    />
  </div>
</template>

<script>

export default {
  name: 'IDCheckedBadge'
}
</script>

<style lang="css">
.ID-checked-badge-icon {
  color: green !important;
  font-size:1.2rem;
}
.ID-checked-badge-icon-container {
  background: #fff!important;
  padding: 13px;
  max-height: 10px!important;
  max-width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

@media (max-width: 576px) {
  .ID-checked-badge-icon-container {
    background: #fff!important;
    padding: 12px;
    max-height: 10px!important;
    max-width: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}

</style>
