import settings from '@/views/settings/settingsContainer.vue'

import mySettings from '@/views/settings/innerComponents/settingsTabs/mysettings/mySettings'
import accountSettings from '@/views/settings/innerComponents/settingsTabs/account'
import publicapi from '@/views/settings/innerComponents/settingsTabs/publicapi'
import branding from '@/views/settings/innerComponents/settingsTabs/branding'
import users from '@/views/settings/innerComponents/settingsTabs/users'
import stockText from '@/views/settings/innerComponents/settingsTabs/stocktext'
import questionSets from '@/views/settings/innerComponents/settingsTabs/questionSets/home'
import videoCV from '@/views/settings/innerComponents/settingsTabs/videoCVSettings'
import selfRegistration from '@/views/settings/innerComponents/settingsTabs/selfRegistration'
import quickShare from '@/views/settings/innerComponents/settingsTabs/quickShare'

import eventSubscriptionsIndex from '@/views/settings/innerComponents/settingsTabs/eventSubscriptions/index'
import eventSubscriptions from '@/views/settings/innerComponents/settingsTabs/eventSubscriptions/eventSubscriptions'
import eventSubscriptionsForm from '@/views/settings/innerComponents/settingsTabs/eventSubscriptions/form/addSubscriptionForm'
import scoring from '@/views/settings/innerComponents/settingsTabs/scoring/scoring'
import surveys from '@/views/settings/innerComponents/settingsTabs/surveys/surveys'
import workingPatternsIndex from '@/views/settings/innerComponents/settingsTabs/workingPatterns/index.vue'
import workingPatterns from '@/views/settings/innerComponents/settingsTabs/workingPatterns/workingPatterns.vue'
import workingPatternsForm from '@/views/settings/innerComponents/settingsTabs/workingPatterns/addWorkingPattern.vue'
import emailCustomisation from '@/views/settings/innerComponents/settingsTabs/emailCustomisation/emailCustomisation.vue'
import customCandidateFields from '@/views/settings/innerComponents/settingsTabs/customCandidateFields/customCandidateFields.vue'


const routes = [
  {
    path: '/settings',
    name: 'settings',
    component: settings,
    meta: { requiresLogin: true },
    children: [
      {
        path: 'mysettings',
        name: 'mysettings',
        component: mySettings
      },
      {
        path: 'account',
        name: 'my account',
        component: accountSettings
      },
      {
        path: 'publicapi',
        name: 'publicapi',
        component: publicapi
      },
      {
        path: 'branding',
        name: 'branding',
        component: branding
      },
      {
        path: 'users',
        name: 'users',
        component: users
      },
      {
        path: 'stocktext',
        name: 'stockText',
        component: stockText
      },
      {
        path: 'questionsets',
        name: 'questionSets',
        component: questionSets
      },
      {
        path: 'videocv',
        name: 'videoCV',
        component: videoCV
      },
      {
        path: 'selfregistration',
        name: 'selfRegistration',
        component: selfRegistration
      },
      {
        path: 'quickshare',
        name: 'quickShare',
        component: quickShare
      },
      {
        path: 'email_customisation',
        name: 'email_customisation',
        component: emailCustomisation
      },
      {
        path: 'custom_fields',
        name: 'custom_fields',
        component: customCandidateFields
      },
      {
        path: 'eventsubscriptions',
        component: eventSubscriptionsIndex,
        children: [
          {
            path: '',
            name: 'eventSubscriptions',
            component: eventSubscriptions,
          },
          {
            path: 'add',
            name: 'addSubscription',
            component: eventSubscriptionsForm,
          }
        ]
      },
      {
        path: 'scoring/:section/*',
        name: 'scoring',
        component: scoring
      },
      {
        path: 'surveys*',
        name: 'surveys',
        component: surveys
      },
      {
        path: 'workingpatterns',
        component: workingPatternsIndex,
        children: [
          {
            path: '',
            name: 'workingPatterns',
            component: workingPatterns,
          },
          {
            path: 'add',
            name: 'addWorkingPattern',
            component: workingPatternsForm,
          },
        ]
      }
    ]
  }
]
export default routes
