import { ref } from 'vue'
import { defineStore } from 'pinia'

const defaultEventSubscription = {
  EventSubscriptionId: 0,  
  Name: '',
  RequireAuthentication: true,
  OneWayCompletion: false,
  OneWayInviteExpiry: false,
  OneWayReset: false,
  OneWayAssessorScoring: false,
  OneWayModeratorScoring: false,
  OneWayStatusChange: false,
  SchedulerBooking: false,
  SchedulerInviteExpiry: false,
  SchedulerCancellation: false,
  SchedulerStatusChange: false,
  TwoWayCompletion: false,
  TwoWayAssessorScoring: false,
  TwoWayModeratorScoring: false,
  TwoWayStatusChange: false,
  TwoWayBooking: false,
  TwoWayInviteExpiry: false,
  TwoWayCancellation: false,
  ConnectionType: 0,
  ConnectionMethod: 0,
  ConnectionUrl: '',
  ClientId: '',
  ClientSecret: '',
  ServiceEndPoint: '',
  Scope: '',
  DateAdded: null,
  AccountId: 0,
  Enabled: false,
  PayloadType: 1,
  DeliveryMethod: 0,
  PayloadTypeData: 1
}

const oneWaySettings = ref([
  { text: 'On Completion', value: 1, selected: false },
  { text: 'On Invitation Expiry', value: 2, selected: false },
  { text: 'On Reset', value: 3, selected: false },
  { text: 'On Assessor Scoring', value: 4, selected: false },
  { text: 'On Moderator Scoring', value: 5, selected: false },
  { text: 'On Status Change', value: 6, selected: false }
])

const twoWaySettings = ref([
  { text: 'On Completion', value: 1, selected: false },
  //{ text: 'On Assessor Scoring', value: 2, selected: false },
  //{ text: 'On Moderator Scoring', value: 3, selected: false },
  { text: 'On Status Change', value: 4, selected: false },
  { text: 'On Booking - if Self-Schedule Enabled', value: 5, selected: false },
  { text: 'On Invitation Expiry - if Self-Schedule Enabled', value: 6, selected: false },
  { text: 'On Cancellation - if Self-Schedule Enabled', value: 7, selected: false }
])

const selfScheduleSettings = ref([
  { text: 'On Booking', value: 1, selected: false },
  { text: 'On Invitation Expiry', value: 2, selected: false },
  { text: 'On Cancellation', value: 3, selected: false },
  { text: 'On Status Change', value: 4, selected: false }
])

const connectionType = ref ([
  { text: 'Please select connection type', value: 0, selected: true },
  { text: 'REST', value: 1, selected: false }
])

const connectionMethod = ref ([
  { text: 'Please select connection method', value: 0, selected: true },
  { text: 'OAUTH', value: 1, selected: false }
])

const deliveryMethod = ref ([
  { text: 'Please select delivery method', value: 0, selected: true },
  { text: 'POST', value: 1, selected: false }
])

const payloadType = ref ([
  { text: 'Please select payload type', value: 0, selected: true },
  { text: 'JSON', value: 1, selected: false }
])

const payloadTypeData = ref ([
  { text: 'Shine', value: 1, selected: true },
  { text: 'Oleeo', value: 2, selected: false },
  { text: 'Jobtrain', value: 3, selected: false },
  { text: 'Other', value: 10, selected: false }
])

const isValidated = ref (false)

export const useEventSubscription = defineStore('eventSubscriptionStore', () => {

  const eventSubscription = ref(defaultEventSubscription)

  var plValue = eventSubscription.value.PayloadTypeData
  
  return {
    eventSubscription,
    oneWaySettings,
    twoWaySettings,
    selfScheduleSettings,
    connectionType,
    connectionMethod,
    payloadType,
    payloadTypeData,
    isValidated,
    defaultEventSubscription,
    deliveryMethod,
    plValue
  }
})
