<template>
  <div>
    <mdb-modal
      id="shareMeetNowModalShowing"
      :show="shareMeetNowModalShowing"
      @close="$emit('shareMeetNowModalShowing', false)"
    >
      <mdb-modal-header>
        <span class="font-weight-bold py-2 text-left">{{ selectedRecording.RecordingName.toUpperCase() }} - MEET NOW SHARE</span>
      </mdb-modal-header>
      <mdb-modal-body class="px-xs-2 py-xs-0 px-sm-4 py-sm-4 text-center" style="max-height:75%; overflow-y:auto">
        <div class="text-black text-left my-2" style="font-size:0.8rem">
          Almost there! Just tell use who we need to send your Meet Now recording to and we'll get it on the way. You can track if they click the link and watch the video in the Meet Now History.
        </div>
        <div class="d-flex align-items-center mt-4" style="height:40px">
          <FontAwesomeIcon icon="fa-solid fa-user" class="d-inline mr-3" />
          <mdb-input
            v-model="nameOfRecipient"
            type="text"
            label="Name of Recipient"
            size="sm"
            class="m-0"
            maxlength="40"
            style="height:40px;width:100%;"
          />
        </div>
        <div class="d-flex align-items-center mt-3" style="height:40px">
          <FontAwesomeIcon icon="fa-solid fa-envelope" class="d-inline mr-3" />
          <mdb-input
            v-model="emailOfRecipient"
            type="email"
            label="Email of Recipient"
            size="sm"
            class="m-0"
            maxlength="40"
            style="height:40px;width:100%;"
          />
        </div>
        <div class="d-flex align-items-center mt-3" style="height:40px">
          <FontAwesomeIcon icon="fa-solid fa-pen-line" class="d-inline mr-3" />
          <mdb-input
            v-model="subjectLine"
            type="text"
            label="Subject Line"
            size="sm"
            class="m-0"
            maxlength="40"
            style="height:40px;width:100%;"
          />
        </div>
        <div class="d-flex align-items-center mt-1" style="width:100%">
          <FontAwesomeIcon
            icon="fa-solid fa-pen-line"
            class="d-inline mr-3"
            style="transform:translateY(-40px)"
          />
          <mdb-input
            v-model="emailContent"
            type="textarea"
            size="sm"
            label="Email Content"
            :rows="4"
            style="width:100%"
          />
        </div>
      </mdb-modal-body>
      <mdb-modal-footer class="d-flex justify-content-end">
        <mdb-btn
          v-if="!shareInProcess"
          size="sm"
          :disabled="!allRequiredFieldsFilledIn"
          style="width:100px;align-self:start;"
          @click="shareIntro()"
        >
          SEND
        </mdb-btn>
        <mdb-btn
          v-else
          size="sm"
          style="width:100px;align-self:start;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import videoIntroService from '@/api/services/videointro.service'
import { ENVIRONMENT } from '@/common/config.js'

export default {
  name: 'ShareMeetNowModal',
  props: {
    selectedRecording: {
      type: Object,
      default: () => {}
    },
    shareMeetNowModalShowing: Boolean
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()

    return {
      authData,
      showGenericErrorModal
    }
  },
  data () {
    return {
      shareInProcess: false,
      nameOfRecipient: '',
      emailOfRecipient: '',
      subjectLine: '',
      emailContent: ''
    }
  },
  computed: {
    allRequiredFieldsFilledIn () {
      return this.nameOfRecipient !== '' && this.emailOfRecipient !== '' && this.subjectLine !== '' && this.emailContent !== ''
    }
  },
  watch: {
    shareIntroModalShowing () {
      if (this.shareIntroModalShowing === false) {
        this.nameOfRecipient = ''
        this.emailOfRecipient = ''
        this.subjectLine = ''
        this.emailContent = ''
      }
    }
  },
  methods: {
    async shareIntro () {
      this.shareInProcess = true
      const formData = new FormData()
      formData.append('nameOfRecipient', this.nameOfRecipient)
      formData.append('emailOfRecipient', this.emailOfRecipient)
      formData.append('subjectLine', this.subjectLine)
      formData.append('emailContent', this.emailContent)
      formData.append('goVideoIntroId', this.selectedRecording.GoVideoIntroId)
      try {
        await videoIntroService.shareIntro(formData)
        this.$emit('shareIntroModalShowing', false)
      } catch (err) {
        this.showGenericErrorModal({ showing: true, errorTitle: 'Error Sharing Intro', errorMessage: 'Something went wrong sharing your Shine Intro. Try refreshing and sharing again.' })
      }
      this.shareInProcess = false
    }
  }
}
</script>

<style lang="scss">
@media (max-width: 576px) {
  #shareIntroModalShowing .modal-dialog .modal-content {
    margin:5px !important;
    width:calc(100vw - 10px) !important;
    height: calc(100vh - 10px) !important;
  }
  #shareIntroModalShowing .modal-dialog {
    margin:0px !important;
  }
}
</style>
