import axiosInstance from '../instance.js'
const api1 = new axiosInstance(1)

export default class roleService {

  // add or edit a role
  static async addEditRole (role, step, workflowType, interviewType) {
    role.Question = []
    if (interviewType === null || interviewType === 'null' || interviewType === undefined) {
      return await api1.post('/api/go/addeditrole?step=' + step + '&workflowType=' + workflowType, role)
    } else {
      return await api1.post('/api/go/addeditrole?step=' + step + '&workflowType=' + workflowType + '&interviewType=' + interviewType, role)
    }
  }

  static async addRoleToRecentRoles (roleId) {
    return await api1.post('/api/go/addroletorecentroles?roleId=' + roleId, {})
  }

  // get a specific role for editing
  static async getRole (roleId) {
    return await api1.get('/api/go/getrole?roleId=' + roleId)
  }

  // filter roles
  static async getRoles (data) {
    return await api1.post('/api/go/getroles', data)
  }

  // delete a role
  static async deleteRole (roleId) {
    return await api1.delete('/api/go/deleterole?roleId=' + roleId)
  }

  // remove a role
  static async removeRole (roleId) {
    return await api1.delete('/api/go/removerole?roleId=' + roleId)
  }

  // fetch all the pool questions
  static async getQuestionPool () {
    return await api1.get('/api/go/getquestionpool')
  }

  // fetch all the pool questions
  static async getCandidateQuestionPool () {
    return await api1.get('/api/go/getcandidatequestionpool')
  }

  // add a pool question to the jobs list
  static async addPoolQuestionToJob (jobId, questionId, isPractiseQuestion) {
    return await api1.post(`/api/go/addpoolquestion?jobId=${jobId}&questionId=${questionId}&isPractiseQuestion=${isPractiseQuestion}`, {})
  }

  // add a question set to the jobs list
  static async addEditRoleQuestionSet (questionSetId, toDelete, roleId) {
    return await api1.post(`/api/go/addeditrolequestionset?questionSetId=${questionSetId}&toDelete=${toDelete}&roleId=${roleId}`, {})
  }

  // remove a question from the jobs list - but dont delete
  static async removeQuestionFromList (jobId, questionId) {
    return await api1.delete(`/api/jobs/removequestionfromlist?jobId=${jobId}&questionId=${questionId}`)
  }

  // test is a role has a practise question
  static async roleHasPractiseQuestion (jobId, questionId) {
    return await api1.get(`/api/go/rolehaspractisequestion?jobId=${jobId}&questionId=${questionId}`)
  }

  // add or edit an existing question
  static async addEditQuestion (roleId, videoUrl, questionId, description, timeLimit, isPractiseQuestion, isPool, tags, isScenario, noRatings, scoringTemplateId) {
    return await api1.post('/api/go/addeditquestion?roleId=' + roleId + '&videoUrl=' + videoUrl + '&tags=' + tags + '&isScenario=' + isScenario + '&questionId=' + questionId + '&description=' + encodeURIComponent(description) + '&timeLimit=' + timeLimit + '&isPractiseQuestion=' + isPractiseQuestion + '&isPool=' + isPool + '&noRatings=' + noRatings + '&scoringTemplateId=' + scoringTemplateId, {})
  }

  // update ordinals when questions get shifted around
  static async updateOrdinals (ids, roleId) {
    return await api1.post('/api/go/updaterolequestionordinals?roleId=' + roleId + '&ordinals=' + ids, {})
  }

  // delete a question
  static async deleteQuestion (roleId, qId) {
    return await api1.delete('/api/go/deletequestion?roleId=' + roleId + '&qId=' + qId)
  }

  // delete a pool question
  static async deletePoolQuestion (roleId, qId) {
    return await api1.delete('/api/go/deletepoolquestion?roleId=' + roleId + '&qId=' + qId)
  }

  // edit option
  static async editOptions (roleId, readingTime, retryCount, allowRetries, pressuredRetry, pressuredRetryTime) {
    return await api1.post('/api/go/editoptions?roleId=' + roleId + '&readingTime=' + readingTime + '&allowRetries=' + allowRetries + '&retryCount=' + retryCount + '&pressuredRetry=' + pressuredRetry + '&pressuredRetryTime=' + pressuredRetryTime, {})
  }

  // add or edit next steps
  static async addEditNextStep (roleId, nextStepsId, nextStepsContent) {
    return await api1.post('/api/go/addeditnextstep?jobId=' + roleId + '&nextStepsId=' + nextStepsId + '&nextStepsContent=' + encodeURIComponent(nextStepsContent), {})
  }

  // get next steps
  static async getNextSteps (roleId) {
    return await api1.get('/api/go/getnextsteps?roleId=' + roleId)
  }

  // delete next steps
  static async deleteNextSteps (rid, nid) {
    return await api1.delete('/api/go/deletenextsteps?rid=' + rid + '&nid=' + nid)
  }

  // load candidates from left hand column e.g. invited, pending, shortlisted etc
  static async getRoleCandidates (type, roleId) {
    return await api1.get('/api/go/getrolecandidates?type=' + type + '&roleId=' + roleId)
  }

  // invite a candidate to a role
  static async inviteCandidatesToRole (roleId, candidateIds,) {
    return await api1.post('/api/go/invitecandidatestorole?jobId=' + roleId, candidateIds)
  }

  // uninvite a candidate from a role
  static async uninviteCandidatesToRole (roleId, candidateIds) {
    return await api1.post('/api/go/uninvitecandidatestorole?jobId=' + roleId, candidateIds)
  }

  // get candidate interview data for reviewing
  static async getGoCandidateApplication (candidateId, roleId, inviteCode) {
    if (!inviteCode) {
      return await api1.get('/api/go/getgocandidateapplication?candidateId=' + candidateId + '&roleId=' + roleId)
    } else {
      return await api1.get('/api/go/getgocandidateapplication?candidateId=' + candidateId + '&roleId=' + roleId + '&inviteCode=' + inviteCode)
    }
  }

  // get role comments
  static async getAllRoleComments (candidateId, roleId, clientId) {
    return await api1.get(`/api/go/getallrolecomments?roleId=${roleId}&clientId=${clientId}&candidateId=${candidateId}`)
  }

  // add or edit a comment
  static async addEditComment (candidateId, roleId, questionId, comment) {
    return await api1.post('/api/go/addeditrolecomment?candidateId=' + candidateId + '&roleId=' + roleId + '&questionId=' + questionId + '&clientId=' + 0 + '&comment=' + encodeURIComponent(comment), null)
  }

  // delete a comment
  static async deleteComment (commentId) {
    return await api1.delete('/api/go/deleterolecomment?commentId=' + commentId)
  }

  // uninvite a candidate from a role
  static async bulkSetStatus (roleId, status, candidateIds) {
    return await api1.post(`/api/go/bulksetstatus?jobId=${roleId}&status=${status}`, candidateIds)
  }

  // set invitation status e.g. shortlisted, rejected etc...
  static async setStatus (candidateId, roleId, type) {
    return await api1.put('/api/go/setstatus?candidateId=' + candidateId + '&roleId=' + roleId + '&status=' + type, null)
  }

  // get invitation status e.g. shortlisted, rejected etc...
  static async getCandidateStatus (roleId, candidateId) {
    return await api1.get('/api/go/getcandidaterolestatus?candidateId=' + candidateId + '&roleId=' + roleId)
  }

  // set ratings (slider)
  static async setRating (candidateId, roleId, questionId, score) {
    return await api1.post('/api/go/setrating?jobId=' + roleId + '&candidateId=' + candidateId + '&questionId=' + questionId + '&ratingValue=' + score, null)
  }

  // resending interview email
  static async interviewEmail (candidateId, roleId, reset) {
    return await api1.post('/api/go/interviewemail?candidateId=' + candidateId + '&roleId=' + roleId + '&reset=' + reset, null)
  }

  // get any associated documents for ther candidate application e.g. CV
  static async getCandidateDocs (candidateId) {
    return await api1.get('/api/go/getcandidatedocs?candidateId=' + candidateId)
  }

  // delete an existing candidate video question/answer
  static async deleteCandidateQuestion (candidateVideoId, chooseAlternateQuestionSetQuestion = false) {
    return await api1.delete('/api/go/deletecandidatequestion?candidateVideoId=' + candidateVideoId + '&chooseAlternateQuestionSetQuestion=' + chooseAlternateQuestionSetQuestion)
  }

  // get video library for role creation or role editing - not used
  static async getLibrary (filterType) {
    return await api1.get('/api/go/getrolelibrary?filterType=' + filterType + '&index=0' + '&limit=9999')
  }

  // get hosts and assessors
  static async getHostAssessors () {
    return await api1.get('/api/go/gethostassessors')
  }

  // set temp meeting date
  static async setTempMeetingDate (candidateId, roleId, meetingDate, meetingTime, selectedHost, selectedAssessors) {
    return await api1.post('/api/go/settempmeetingdate?candidateId=' + candidateId + '&roleId=' + roleId + '&meetingDate=' + meetingDate + '&meetingTime=' + meetingTime + '&selectedHost=' + selectedHost, selectedAssessors)
  }

  // delete temp meeting date
  static async deleteTempMeetingDate (candidateId, roleId) {
    return await api1.delete('/api/go/deletetempmeetingdate?candidateId=' + candidateId + '&roleId=' + roleId)
  }

  // delete all temp meeting dates - not used
  static async deleteAllTempMeetingDates (roleId) {
    return await api1.delete('/api/go/deletealltempmeetingdates?roleId=' + roleId)
  }

  // follow role
  static async followRole (id) {
    return await api1.post('/api/go/followrole?id=' + id, {})
  }

  // unfollow role
  static async unFollowRole (id) {
    return await api1.post('/api/go/unfollowrole?id=' + id, {})
  }

  // get existing interview date to reset
  static async getCurrentInterviewDate (cid, rid) {
    return await api1.get('/api/go/getcurrentinterviewdate?cid=' + cid + '&rid=' + rid)
  }

  // set as legacy / not legacy
  static async setAsLegacy (rid) {
    return await api1.post('/api/go/setaslegacy?rid=' + rid, {})
  }

  // use getAddress.io
  static async postCodeSearch (postCode, accessToken) {
    return await api1.get(`https://api.getAddress.io/find/${postCode}?api-key=${accessToken}&expand=true`)
  }

  static async getAddressIOAT () {
    return await api1.get('/api/go/getaddressioat')
  }

  static async addInviteCandidate (formData) {
    return await api1.post('/api/go/addtheninvitecandidate', formData)
  }

  static async unlockInterview (inviteCode) {
    return await api1.post(`/api/go/unlockinterview?inviteCode=${inviteCode}`, {})
  }
}
