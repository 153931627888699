import { API_URL } from '@/common/config.js'

export default {
  uploadVideo: async function (type, file, data, returnId = false) {
    let formBody = []
    let fileGuid = null
    for (var property in data) {
      var encodedKey = encodeURIComponent(property)
      var encodedValue = encodeURIComponent(data[property])
      formBody.push(encodedKey + '=' + encodedValue)
    }
    formBody = formBody.join('&')
    await fetch(`${API_URL}/api/library/goinitialize`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: formBody
    }).then(async function (response) {
      return response.text().then(async function (text) {
        const rem1 = text.replace('"', '')
        const rem2 = rem1.replace('"', '')
        fileGuid = rem2
      })
    })

    const size = file.size
    var name = 'name.webm'
    if (file.type === 'video/mp4') {
      name = 'name.mp4'
    }

    const blockSize = 1048576 // 1024*1024; size of one chunk
    const blocks = []
    let offset = 0
    let index = 0
    let list = ''

    while (offset < size) {
      const start = offset
      const end = Math.min(offset + blockSize, size)

      blocks.push({
        name, index, start, end
      })

      list += index + ','
      offset = end
      index++
    }

    var putBlocks = []
    var fileCreated = null

    if (typeof (file.name) === 'string') {
      fileCreated = file
    } else {
      fileCreated = new File([file], 'name.webm')
      if (type === 1) {
        fileCreated = new File([file], 'name.mp4')
      }
    }

    blocks.forEach(element => {
      const blobData = fileCreated.slice(element.start, element.end)
      putBlocks.push({
        block: element,
        file: blobData,
        data: data,
        guid: fileGuid
      })
    })

    const tasks = putBlocks.map(getUpload)
    const results = await Promise.all(tasks)
    let fail = false

    results.forEach(element => {
      if (!element) {
        fail = true
      }
    })

    let commitResponse = false

    if (fail) {
      return false
    } else {
      const cData = {
        name: name,
        list: list,
        userEmail: data.userEmail,
        guid: fileGuid,
        videoType: data.videoType
      }
      let commitForm = []
      for (var p in cData) {
        var ek = encodeURIComponent(p)
        var ev = encodeURIComponent(cData[p])
        commitForm.push(ek + '=' + ev)
      }
      commitForm = commitForm.join('&')
      await fetch(`${API_URL}/api/library/gocommit`, {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: commitForm
      }).then(async response => {
        if (response.ok) {
          commitResponse = true
          // added so that video intros / candidate questions return an id
          if (returnId) {
            try {
              const text = await response.text()
              commitResponse = text
            } catch {
              //?
            }
          }
        } else {
          commitResponse = false
        }
      })
    }
    return commitResponse

    function getUpload (block) {
      return uploadBlock(block.block, block.file, block.data, block.guid, block.data.videoType)
    }

    async function uploadBlock (block, blob, data, guid, type) {
      var result = false
      const fData = new FormData()
      fData.append('userEmail', data.userEmail)
      fData.append('guid', guid)
      fData.append('name', block.name)
      fData.append('index', block.index)
      fData.append('file', blob)
      fData.append('videoType', type)
      await fetch(`${API_URL}/api/library/goupload`, {
        method: 'POST',
        body: fData
      }).then(async function (response) {
        if (response.ok) {
          result = true
        }
      })
      return result
    }
  }
}
