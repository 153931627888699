<template>
  <div class="d-flex flex-column">
    <SlideFade>
      <div class="md-form form-sm d-flex align-items-center m-0">
        <i class="fas fa-user prefix" style="margin-top:20px;font-size: 1.3rem;" />
        <label style="font-size:12px;">Sector</label>
        <mdb-select
          v-model="sectorOptions"
          placeholder="Select"
          style="margin-left:33px;width:100%;"
          size="sm"
        />
      </div>
    </SlideFade>
    <SlideFade>
      <div v-if="!hideJobType" class="md-form form-sm d-flex align-items-center m-0">
        <i class="fas fa-user prefix" style="margin-top:20px;font-size: 1.3rem;" />
        <label style="font-size:12px;">Job Type</label>
        <mdb-select
          v-model="jobTypeOptions"
          placeholder="Select"
          style="margin-left:33px;width:100%;"
          size="sm"
        />
      </div>
    </SlideFade>
  </div>
</template>

<script>
import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'

export default {
  name: 'SectorAndJobType',
  components: {
    SlideFade
  },
  props: {
    jobDetails: Object,
    roleId: Number
  },
  data () {
    return {
      sectorOptions: [
        { value: 'None', text: 'None' },
        { value: 'Administration, business and management', text: 'Administration, business and management' },
        { value: 'Alternative therapies', text: 'Alternative therapies' },
        { value: 'Animals, land and environment', text: 'Animals, land and environment' },
        { value: 'Computing and ICT', text: 'Computing and ICT' },
        { value: 'Construction and building', text: 'Construction and building' },
        { value: 'Design, arts and crafts', text: 'Design, arts and crafts' },
        { value: 'Education and training', text: 'Education and training' },
        { value: 'Engineering', text: 'Engineering' },
        { value: 'Facilities and property services', text: 'Facilities and property services' },
        { value: 'Financial services', text: 'Financial services' },
        { value: 'Garage services', text: 'Garage services' },
        { value: 'Hairdressing and beauty', text: 'Hairdressing and beauty' },
        { value: 'Healthcare', text: 'Healthcare' },
        { value: 'Heritage, culture and libraries', text: 'Heritage, culture and libraries' },
        { value: 'Hospitality, catering and tourism', text: 'Hospitality, catering and tourism' },
        { value: 'Languages', text: 'Languages' },
        { value: 'Legal and court services', text: 'Legal and court services' },
        { value: 'Manufacturing and production', text: 'Manufacturing and production' },
        { value: 'Performing arts and media', text: 'Performing arts and media' },
        { value: 'Print and publishing, marketing and advertising', text: 'Print and publishing, marketing and advertising' },
        { value: 'Retail and customer services', text: 'Retail and customer services' },
        { value: 'Science, mathematics and statistics', text: 'Science, mathematics and statistics' },
        { value: 'Security, uniformed and protective services', text: 'Security, uniformed and protective services' },
        { value: 'Social sciences and religion', text: 'Social sciences and religion' },
        { value: 'Social work and caring services', text: 'Social work and caring services' },
        { value: 'Sport and leisure', text: 'Sport and leisure' },
        { value: 'Transport, distribution and logistics', text: 'Transport, distribution and logistics' }
      ],
      originalOptions: [
        { value: 'None', text: 'None', selected: true },
        { value: 'Administration, business and management', text: 'Administration, business and management' },
        { value: 'Alternative therapies', text: 'Alternative therapies' },
        { value: 'Animals, land and environment', text: 'Animals, land and environment' },
        { value: 'Computing and ICT', text: 'Computing and ICT' },
        { value: 'Construction and building', text: 'Construction and building' },
        { value: 'Design, arts and crafts', text: 'Design, arts and crafts' },
        { value: 'Education and training', text: 'Education and training' },
        { value: 'Engineering', text: 'Engineering' },
        { value: 'Facilities and property services', text: 'Facilities and property services' },
        { value: 'Financial services', text: 'Financial services' },
        { value: 'Garage services', text: 'Garage services' },
        { value: 'Hairdressing and beauty', text: 'Hairdressing and beauty' },
        { value: 'Healthcare', text: 'Healthcare' },
        { value: 'Heritage, culture and libraries', text: 'Heritage, culture and libraries' },
        { value: 'Hospitality, catering and tourism', text: 'Hospitality, catering and tourism' },
        { value: 'Languages', text: 'Languages' },
        { value: 'Legal and court services', text: 'Legal and court services' },
        { value: 'Manufacturing and production', text: 'Manufacturing and production' },
        { value: 'Performing arts and media', text: 'Performing arts and media' },
        { value: 'Print and publishing, marketing and advertising', text: 'Print and publishing, marketing and advertising' },
        { value: 'Retail and customer services', text: 'Retail and customer services' },
        { value: 'Science, mathematics and statistics', text: 'Science, mathematics and statistics' },
        { value: 'Security, uniformed and protective services', text: 'Security, uniformed and protective services' },
        { value: 'Social sciences and religion', text: 'Social sciences and religion' },
        { value: 'Social work and caring services', text: 'Social work and caring services' },
        { value: 'Sport and leisure', text: 'Sport and leisure' },
        { value: 'Transport, distribution and logistics', text: 'Transport, distribution and logistics' }
      ],
      jobTypeOptions: [{ value: 'None', text: 'None', selected: true }]
    }
  },
  computed: {
    hideJobType () {
      try {
        return this.sectorOptions.filter(o => o.selected)[0].text === 'None'
      } catch { return false }
    },
    selectedSector () {
      try {
        return this.sectorOptions.filter(o => o.selected)[0].text
      } catch { return '' }
    },
    selectedJobType () {
      try {
        return this.jobTypeOptions.filter(o => o.selected)[0].text
      } catch { return '' }
    }
  },
  watch: {
    roleId: {
      immediate: true,
      handler: 'checkSectorDetails'
    },
    jobDetails: {
      deep: true,
      immediate: true,
      handler: 'checkSectorDetails'
    },
    selectedSector () {
      this.$emit('selectedSector', this.selectedSector)
    },
    selectedJobType () {
      this.$emit('selectedJobType', this.selectedJobType)
    },
    sectorOptions: {
      handler: function () {
        try {
          const selected = this.sectorOptions.filter(o => o.selected)[0].text
          if (selected === 'None') {
            this.jobTypeOptions = [{ value: 'None', text: 'None', selected: true }]
          } else if (selected === 'Administration, business and management') {
            this.jobTypeOptions = [
              { value: 'Administrative assistant', text: 'Administrative assistant' },
              { value: 'Business analyst', text: 'Business analyst' },
              { value: 'Business continuity specialist', text: 'Business continuity specialist' },
              { value: 'Business development manager', text: 'Business development manager' },
              { value: 'Car rental agent', text: 'Car rental agent' },
              { value: 'Charity fundraiser', text: 'Charity fundraiser' },
              { value: 'Civil service administrative officer', text: 'Civil service administrative officer' },
              { value: 'Civil service executive officer', text: 'Civil service executive officer' },
              { value: 'Company secretary', text: 'Company secretary' },
              { value: 'Compliance officer', text: 'Compliance officer' },
              { value: 'Diplomatic service officer', text: 'Diplomatic service officer' },
              { value: 'Economic development officer', text: 'Economic development officer' },
              { value: 'European Union official', text: 'European Union official' },
              { value: 'Health and safety adviser', text: 'Health and safety adviser' },
              { value: 'Health records clerk', text: 'Health records clerk' },
              { value: 'Health service manager', text: 'Health service manager' },
              { value: 'Human resources adviser', text: 'Human resources adviser' },
              { value: 'Legal secretary', text: 'Legal secretary' },
              { value: 'Local government administrative assistant', text: 'Local government administrative assistant' },
              { value: 'Local government officer', text: 'Local government officer' },
              { value: 'Local government revenues officer', text: 'Local government revenues officer' },
              { value: 'Management consultant', text: 'Management consultant' },
              { value: 'Medical secretary', text: 'Medical secretary' },
              { value: 'Member of Parliament (MP)', text: 'Member of Parliament (MP)' },
              { value: 'Operational researcher', text: 'Operational researcher' },
              { value: 'Personal assistant', text: 'Personal assistant' },
              { value: 'Project manager', text: 'Project manager' },
              { value: 'Purchasing manager', text: 'Purchasing manager' },
              { value: 'Receptionist', text: 'Receptionist' },
              { value: 'Recruitment consultant', text: 'Recruitment consultant' },
              { value: 'Registrar of births, deaths, marriages and civil partnerships', text: 'Registrar of births, deaths, marriages and civil partnerships' },
              { value: 'Scrum Master', text: 'Scrum Master' },
              { value: 'Secretary', text: 'Secretary' },
              { value: 'Trade union official', text: 'Trade union official' },
              { value: 'Trading standards officer', text: 'Trading standards officer' }
            ]
          } else if (selected === 'Alternative therapies') {
            this.jobTypeOptions = [
              { value: 'Acupuncturist', text: 'Acupuncturist' },
              { value: 'Homeopath', text: 'Homeopath' },
              { value: 'Hypnotherapist', text: 'Hypnotherapist' },
              { value: 'Naturopath', text: 'Naturopath' },
              { value: 'Osteopath', text: 'Osteopath' },
              { value: 'Reflexologist', text: 'Reflexologist' }
            ]
          } else if (selected === 'Animals, land and environment') {
            this.jobTypeOptions = [
              { value: 'Agricultural consultant', text: 'Agricultural consultant' },
              { value: 'Agricultural engineer', text: 'Agricultural engineer' },
              { value: 'Animal care worker', text: 'Animal care worker' },
              { value: 'Animal technician', text: 'Animal technician' },
              { value: 'Assistance dog trainer', text: 'Assistance dog trainer' },
              { value: 'Cemetery worker', text: 'Cemetery worker' },
              { value: 'Climate Scientist', text: 'Climate Scientist' },
              { value: 'Countryside officer', text: 'Countryside officer' },
              { value: 'Countryside ranger', text: 'Countryside ranger' },
              { value: 'Dog groomer', text: 'Dog groomer' },
              { value: 'Dog handler', text: 'Dog handler' },
              { value: 'Ecologist', text: 'Ecologist' },
              { value: 'Environmental consultant', text: 'Environmental consultant' },
              { value: 'Environmental health officer', text: 'Environmental health officer' },
              { value: 'Environmental manager', text: 'Environmental manager' },
              { value: 'Farm manager', text: 'Farm manager' },
              { value: 'Farm worker', text: 'Farm worker' },
              { value: 'Farmer', text: 'Farmer' },
              { value: 'Farrier', text: 'Farrier' },
              { value: 'Fence installer', text: 'Fence installer' },
              { value: 'Fish farm worker', text: 'Fish farm worker' },
              { value: 'Fishing vessel skipper', text: 'Fishing vessel skipper' },
              { value: 'Forest officer', text: 'Forest officer' },
              { value: 'Forest worker', text: 'Forest worker' },
              { value: 'Gamekeeper', text: 'Gamekeeper' },
              { value: 'Gardener', text: 'Gardener' },
              { value: 'Geoscientist', text: 'Geoscientist' },
              { value: 'Greenkeeper', text: 'Greenkeeper' },
              { value: 'Horse groom', text: 'Horse groom' },
              { value: 'Horse riding coach', text: 'Horse riding coach' },
              { value: 'Horticultural worker', text: 'Horticultural worker' },
              { value: 'Jockey', text: 'Jockey' },
              { value: 'Landscaper', text: 'Landscaper' },
              { value: 'Meteorologist', text: 'Meteorologist' },
              { value: 'Oceanographer', text: 'Oceanographer' },
              { value: 'Pest control technician', text: 'Pest control technician' },
              { value: 'Rural surveyor', text: 'Rural surveyor' },
              { value: 'SSPCA inspector', text: 'SSPCA inspector' },
              { value: 'Tree surgeon', text: 'Tree surgeon' },
              { value: 'Veterinary nurse', text: 'Veterinary nurse' },
              { value: 'Veterinary surgeon', text: 'Veterinary surgeon' },
              { value: 'Wind turbine technician', text: 'Wind turbine technician' },
              { value: 'Zookeeper', text: 'Zookeeper' }
            ]
          } else if (selected === 'Computing and ICT') {
            this.jobTypeOptions = [
              { value: 'App developer', text: 'App developer' },
              { value: 'AR/VR programmer', text: 'AR/VR programmer' },
              { value: 'Audio-visual technician', text: 'Audio-visual technician' },
              { value: 'Big data engineer', text: 'Big data engineer' },
              { value: 'CAD technician', text: 'CAD technician' },
              { value: 'Computer service and repair technician', text: 'Computer service and repair technician' },
              { value: 'Database administrator', text: 'Database administrator' },
              { value: 'DevSecOps developer', text: 'DevSecOps developer' },
              { value: 'Ethical hacker', text: 'Ethical hacker' },
              { value: 'Forensic computer analyst', text: 'Forensic computer analyst' },
              { value: 'Game designer', text: 'Game designer' },
              { value: 'Games developer', text: 'Games developer' },
              { value: 'Games tester', text: 'Games tester' },
              { value: 'Helpdesk professional', text: 'Helpdesk professional' },
              { value: 'Infrastructure engineer', text: 'Infrastructure engineer' },
              { value: 'IT project analyst', text: 'IT project analyst' },
              { value: 'IT support technician', text: 'IT support technician' },
              { value: 'IT trainer', text: 'IT trainer' },
              { value: 'Machine learning engineer', text: 'Machine learning engineer' },
              { value: 'Network manager', text: 'Network manager' },
              { value: 'Office equipment service technician', text: 'Office equipment service technician' },
              { value: 'Robotics engineer', text: 'Robotics engineer' },
              { value: 'Software developer', text: 'Software developer' },
              { value: 'Software engineer', text: 'Software engineer' },
              { value: 'Solution architect', text: 'Solution architect' },
              { value: 'Systems analyst', text: 'Systems analyst' },
              { value: 'Virtual reality designer', text: 'Virtual reality designer' },
              { value: 'Web developer', text: 'Web developer' },
              { value: 'Web editor', text: 'Web editor' }
            ]
          } else if (selected === 'Construction and building') {
            this.jobTypeOptions = [
              { value: 'Architect', text: 'Architect' },
              { value: 'Architectural technician', text: 'Architectural technician' },
              { value: 'Architectural technologist', text: 'Architectural technologist' },
              { value: 'Bricklayer', text: 'Bricklayer' },
              { value: 'Builders\' merchant', text: 'Builders\' merchant' },
              { value: 'Building standards officer (local authority)', text: 'Building standards officer (local authority)' },
              { value: 'Building surveyor', text: 'Building surveyor' },
              { value: 'Building technician', text: 'Building technician' },
              { value: 'Carpet fitter or floor layer', text: 'Carpet fitter or floor layer' },
              { value: 'Civil engineer', text: 'Civil engineer' },
              { value: 'Civil engineering technician', text: 'Civil engineering technician' },
              { value: 'Clerk of works', text: 'Clerk of works' },
              { value: 'Commercial energy assessor', text: 'Commercial energy assessor' },
              { value: 'Construction manager', text: 'Construction manager' },
              { value: 'Construction operative', text: 'Construction operative' },
              { value: 'Construction plant mechanic', text: 'Construction plant mechanic' },
              { value: 'Construction plant operator', text: 'Construction plant operator' },
              { value: 'Demolition operative', text: 'Demolition operative' },
              { value: 'Domestic energy assessor ', text: 'Domestic energy assessor ' },
              { value: 'Dry liner', text: 'Dry liner' },
              { value: 'Electricity distribution worker', text: 'Electricity distribution worker' },
              { value: 'General practice surveyor', text: 'General practice surveyor' },
              { value: 'Glazier', text: 'Glazier' },
              { value: 'Joiner', text: 'Joiner' },
              { value: 'Land surveyor', text: 'Land surveyor' },
              { value: 'Landscape architect', text: 'Landscape architect' },
              { value: 'Leakage operative', text: 'Leakage operative' },
              { value: 'Painter and decorator', text: 'Painter and decorator' },
              { value: 'Planning and development surveyor', text: 'Planning and development surveyor' },
              { value: 'Plasterer', text: 'Plasterer' },
              { value: 'Plumber', text: 'Plumber' },
              { value: 'Quantity surveyor', text: 'Quantity surveyor' },
              { value: 'Road worker', text: 'Road worker' },
              { value: 'Roofer', text: 'Roofer' },
              { value: 'Scaffolder', text: 'Scaffolder' },
              { value: 'Shopfitter', text: 'Shopfitter' },
              { value: 'Stonemason', text: 'Stonemason' },
              { value: 'Structural engineer', text: 'Structural engineer' },
              { value: 'Technical surveyor', text: 'Technical surveyor' },
              { value: 'Town planner', text: 'Town planner' },
              { value: 'Town planning support staff', text: 'Town planning support staff' },
              { value: 'Wall and floor tiler', text: 'Wall and floor tiler' },
              { value: 'Water treatment technician', text: 'Water treatment technician' },
              { value: 'Window fitter', text: 'Window fitter' }
            ]
          } else if (selected === 'Design, arts and crafts') {
            this.jobTypeOptions = [
              { value: 'Animator', text: 'Animator' },
              { value: 'Art gallery curator', text: 'Art gallery curator' },
              { value: 'Blacksmith', text: 'Blacksmith' },
              { value: 'Cabinet maker', text: 'Cabinet maker' },
              { value: 'Ceramics designer or maker', text: 'Ceramics designer or maker' },
              { value: 'Concept Artist', text: 'Concept Artist' },
              { value: 'Costume designer', text: 'Costume designer' },
              { value: 'Dressmaker', text: 'Dressmaker' },
              { value: 'Exhibition designer', text: 'Exhibition designer' },
              { value: 'Fashion designer', text: 'Fashion designer' },
              { value: 'Fine artist', text: 'Fine artist' },
              { value: 'French polisher', text: 'French polisher' },
              { value: 'Furniture designer', text: 'Furniture designer' },
              { value: 'Glassmaker', text: 'Glassmaker' },
              { value: 'Graphic designer', text: 'Graphic designer' },
              { value: 'Illustrator', text: 'Illustrator' },
              { value: 'Interior designer', text: 'Interior designer' },
              { value: 'Jewellery designer-maker', text: 'Jewellery designer-maker' },
              { value: 'Medical illustrator', text: 'Medical illustrator' },
              { value: 'Model maker', text: 'Model maker' },
              { value: 'Musical instrument maker or repairer', text: 'Musical instrument maker or repairer' },
              { value: 'Photographer', text: 'Photographer' },
              { value: 'Photographic stylist', text: 'Photographic stylist' },
              { value: 'Picture framer', text: 'Picture framer' },
              { value: 'Product designer', text: 'Product designer' },
              { value: 'Service Designer', text: 'Service Designer' },
              { value: 'Set designer', text: 'Set designer' },
              { value: 'Signwriter', text: 'Signwriter' },
              { value: 'Tattooist', text: 'Tattooist' },
              { value: 'Textile designer', text: 'Textile designer' },
              { value: 'User experience (UX) designer', text: 'User experience (UX) designer' }
            ]
          } else if (selected === 'Education and training') {
            this.jobTypeOptions = [
              { value: 'Careers adviser', text: 'Careers adviser' },
              { value: 'Classroom assistant', text: 'Classroom assistant' },
              { value: 'Community education coordinator', text: 'Community education coordinator' },
              { value: 'Early years teacher', text: 'Early years teacher' },
              { value: 'EFL teacher', text: 'EFL teacher' },
              { value: 'Further education lecturer', text: 'Further education lecturer' },
              { value: 'Higher education lecturer', text: 'Higher education lecturer' },
              { value: 'Learning support assistant', text: 'Learning support assistant' },
              { value: 'Learning technologist', text: 'Learning technologist' },
              { value: 'Literacies tutor', text: 'Literacies tutor' },
              { value: 'Nursery manager', text: 'Nursery manager' },
              { value: 'Teacher- Primary School', text: 'Teacher- Primary School' },
              { value: 'Teacher- Secondary School', text: 'Teacher- Secondary School' },
              { value: 'Teacher- Secondary School- Art and Design', text: 'Teacher- Secondary School- Art and Design' },
              { value: 'Teacher- Secondary School- Biology', text: 'Teacher- Secondary School- Biology' },
              { value: 'Teacher- Secondary School- Business Studies', text: 'Teacher- Secondary School- Business Studies' },
              { value: 'Teacher- Secondary School- Chemistry', text: 'Teacher- Secondary School- Chemistry' },
              { value: 'Teacher- Secondary school- Computing', text: 'Teacher- Secondary school- Computing' },
              { value: 'Teacher- Secondary school- Design and technology', text: 'Teacher- Secondary school- Design and technology' },
              { value: 'Teacher- Secondary School- Drama', text: 'Teacher- Secondary School- Drama' },
              { value: 'Teacher- Secondary School- English', text: 'Teacher- Secondary School- English' },
              { value: 'Teacher- Secondary School- Gaelic', text: 'Teacher- Secondary School- Gaelic' },
              { value: 'Teacher- Secondary School- Geography', text: 'Teacher- Secondary School- Geography' },
              { value: 'Teacher- Secondary School- History', text: 'Teacher- Secondary School- History' },
              { value: 'Teacher- Secondary School- Home Economics', text: 'Teacher- Secondary School- Home Economics' },
              { value: 'Teacher- Secondary School- Mathematics', text: 'Teacher- Secondary School- Mathematics' },
              { value: 'Teacher- Secondary School- Modern Languages', text: 'Teacher- Secondary School- Modern Languages' },
              { value: 'Teacher- Secondary School- Modern Studies', text: 'Teacher- Secondary School- Modern Studies' },
              { value: 'Teacher- Secondary School- Music', text: 'Teacher- Secondary School- Music' },
              { value: 'Teacher- Secondary School- Physical Education', text: 'Teacher- Secondary School- Physical Education' },
              { value: 'Teacher- Secondary School- Physics', text: 'Teacher- Secondary School- Physics' },
              { value: 'Teacher- Secondary School- Religious Education', text: 'Teacher- Secondary School- Religious Education' },
              { value: 'Training manager', text: 'Training manager' },
              { value: 'Training officer', text: 'Training officer' }
            ]
          } else if (selected === 'Engineering') {
            this.jobTypeOptions = [
              { value: 'Aerospace engineer', text: 'Aerospace engineer' },
              { value: 'Aircraft maintenance engineer', text: 'Aircraft maintenance engineer' },
              { value: 'Automotive engineer', text: 'Automotive engineer' },
              { value: 'Boat builder', text: 'Boat builder' },
              { value: 'Building services engineer', text: 'Building services engineer' },
              { value: 'Chemical engineer', text: 'Chemical engineer' },
              { value: 'Chemical engineering technician', text: 'Chemical engineering technician' },
              { value: 'CNC programmer', text: 'CNC programmer' },
              { value: 'Design engineer', text: 'Design engineer' },
              { value: 'Diver- specialist', text: 'Diver- specialist' },
              { value: 'Drilling engineer', text: 'Drilling engineer' },
              { value: 'Electrical engineer', text: 'Electrical engineer' },
              { value: 'Electrical engineering technician', text: 'Electrical engineering technician' },
              { value: 'Electrician', text: 'Electrician' },
              { value: 'Electronics engineer', text: 'Electronics engineer' },
              { value: 'Electronics engineering technician', text: 'Electronics engineering technician' },
              { value: 'Energy engineer', text: 'Energy engineer' },
              { value: 'Engineering craft machinist', text: 'Engineering craft machinist' },
              { value: 'Engineering maintenance technician', text: 'Engineering maintenance technician' },
              { value: 'Engineering operative', text: 'Engineering operative' },
              { value: 'Gas service technician', text: 'Gas service technician' },
              { value: 'Heating and ventilation engineer', text: 'Heating and ventilation engineer' },
              { value: 'Manufacturing systems engineer', text: 'Manufacturing systems engineer' },
              { value: 'Marine engineer', text: 'Marine engineer' },
              { value: 'Materials engineer', text: 'Materials engineer' },
              { value: 'Mechanical engineer', text: 'Mechanical engineer' },
              { value: 'Mechanical engineering technician', text: 'Mechanical engineering technician' },
              { value: 'Merchant navy engineering officer', text: 'Merchant navy engineering officer' },
              { value: 'Naval architect', text: 'Naval architect' },
              { value: 'Nuclear engineer', text: 'Nuclear engineer' },
              { value: 'Offshore drilling worker', text: 'Offshore drilling worker' },
              { value: 'Offshore roustabout', text: 'Offshore roustabout' },
              { value: 'Offshore service technician', text: 'Offshore service technician' },
              { value: 'Quarry engineer', text: 'Quarry engineer' },
              { value: 'Rail engineering technician', text: 'Rail engineering technician' },
              { value: 'Refrigeration and air conditioning engineer', text: 'Refrigeration and air conditioning engineer' },
              { value: 'ROV pilot technician', text: 'ROV pilot technician' },
              { value: 'Satellite systems technician', text: 'Satellite systems technician' },
              { value: 'Smart meter installer', text: 'Smart meter installer' },
              { value: 'Telecoms engineer', text: 'Telecoms engineer' },
              { value: 'Thermal insulation engineer', text: 'Thermal insulation engineer' },
              { value: 'Track maintenance operative', text: 'Track maintenance operative' },
              { value: 'Welder', text: 'Welder' }
            ]
          } else if (selected === 'Facilities and property services') {
            this.jobTypeOptions = [
              { value: 'Accommodation warden', text: 'Accommodation warden' },
              { value: 'Caretaker', text: 'Caretaker' },
              { value: 'Cleaner', text: 'Cleaner' },
              { value: 'Facilities manager', text: 'Facilities manager' },
              { value: 'Highways cleaner', text: 'Highways cleaner' },
              { value: 'Locksmith', text: 'Locksmith' },
              { value: 'Recycling operative', text: 'Recycling operative' },
              { value: 'Refuse collector', text: 'Refuse collector' },
              { value: 'Window cleaner', text: 'Window cleaner' }
            ]
          } else if (selected === 'Financial services') {
            this.jobTypeOptions = [
              { value: 'Accountant- Management', text: 'Accountant- Management' },
              { value: 'Accountant- Private practice ', text: 'Accountant- Private practice ' },
              { value: 'Accountant- Public finance', text: 'Accountant- Public finance' },
              { value: 'Accounting technician', text: 'Accounting technician' },
              { value: 'Accounts assistant', text: 'Accounts assistant' },
              { value: 'Actuary', text: 'Actuary' },
              { value: 'Bank manager', text: 'Bank manager' },
              { value: 'Banking customer service adviser', text: 'Banking customer service adviser' },
              { value: 'Bookkeeper', text: 'Bookkeeper' },
              { value: 'Credit manager', text: 'Credit manager' },
              { value: 'Economist', text: 'Economist' },
              { value: 'Finance analyst', text: 'Finance analyst' },
              { value: 'Financial adviser', text: 'Financial adviser' },
              { value: 'Insurance account manager', text: 'Insurance account manager' },
              { value: 'Insurance broker', text: 'Insurance broker' },
              { value: 'Insurance claims handler', text: 'Insurance claims handler' },
              { value: 'Insurance loss adjuster', text: 'Insurance loss adjuster' },
              { value: 'Insurance risk surveyor', text: 'Insurance risk surveyor' },
              { value: 'Insurance underwriter', text: 'Insurance underwriter' },
              { value: 'Investment analyst', text: 'Investment analyst' },
              { value: 'Investment banker', text: 'Investment banker' },
              { value: 'Payroll administrator', text: 'Payroll administrator' },
              { value: 'Stockbroker', text: 'Stockbroker' },
              { value: 'Tax inspector', text: 'Tax inspector' }
            ]
          } else if (selected === 'Garage services') {
            this.jobTypeOptions = [
              { value: 'Car valet', text: 'Car valet' },
              { value: 'Cycle mechanic', text: 'Cycle mechanic' },
              { value: 'Mechanic', text: 'Mechanic' },
              { value: 'Motor vehicle body repairer', text: 'Motor vehicle body repairer' },
              { value: 'Motor vehicle parts person', text: 'Motor vehicle parts person' },
              { value: 'Roadside technician', text: 'Roadside technician' },
              { value: 'Tyre or exhaust fitter', text: 'Tyre or exhaust fitter' },
              { value: 'Vehicle spray painter', text: 'Vehicle spray painter' }
            ]
          } else if (selected === 'Hairdressing and beauty') {
            this.jobTypeOptions = [
              { value: 'Aromatherapist', text: 'Aromatherapist' },
              { value: 'Beauty consultant', text: 'Beauty consultant' },
              { value: 'Beauty therapist', text: 'Beauty therapist' },
              { value: 'Body piercer', text: 'Body piercer' },
              { value: 'Fashion model', text: 'Fashion model' },
              { value: 'Hairdresser', text: 'Hairdresser' },
              { value: 'Make-up artist', text: 'Make-up artist' },
              { value: 'Nail technician', text: 'Nail technician' }
            ]
          } else if (selected === 'Healthcare') {
            this.jobTypeOptions = [
              { value: 'Ambulance care assistant', text: 'Ambulance care assistant' },
              { value: 'Ambulance paramedic', text: 'Ambulance paramedic' },
              { value: 'Ambulance technician', text: 'Ambulance technician' },
              { value: 'Anatomical pathology technician', text: 'Anatomical pathology technician' },
              { value: 'Art therapist', text: 'Art therapist' },
              { value: 'Audiologist', text: 'Audiologist' },
              { value: 'Chiropractor', text: 'Chiropractor' },
              { value: 'Clinical engineer', text: 'Clinical engineer' },
              { value: 'Clinical perfusionist', text: 'Clinical perfusionist' },
              { value: 'Clinical psychologist', text: 'Clinical psychologist' },
              { value: 'Dance movement psychotherapist', text: 'Dance movement psychotherapist' },
              { value: 'Dental hygienist', text: 'Dental hygienist' },
              { value: 'Dental nurse', text: 'Dental nurse' },
              { value: 'Dental technician', text: 'Dental technician' },
              { value: 'Dental therapist', text: 'Dental therapist' },
              { value: 'Dentist', text: 'Dentist' },
              { value: 'Dietitian', text: 'Dietitian' },
              { value: 'Dispatcher', text: 'Dispatcher' },
              { value: 'Dispensing optician', text: 'Dispensing optician' },
              { value: 'District nurse', text: 'District nurse' },
              { value: 'Doctor- GP', text: 'Doctor- GP' },
              { value: 'Dramatherapist', text: 'Dramatherapist' },
              { value: 'Emergency call handler', text: 'Emergency call handler' },
              { value: 'Emergency medical dispatcher', text: 'Emergency medical dispatcher' },
              { value: 'Ergonomist', text: 'Ergonomist' },
              { value: 'Forensic psychologist', text: 'Forensic psychologist' },
              { value: 'Health promotion specialist', text: 'Health promotion specialist' },
              { value: 'Health visitor', text: 'Health visitor' },
              { value: 'Healthcare assistant', text: 'Healthcare assistant' },
              { value: 'Hospital doctor', text: 'Hospital doctor' },
              { value: 'Medical physicist', text: 'Medical physicist' },
              { value: 'Midwife', text: 'Midwife' },
              { value: 'Music therapist', text: 'Music therapist' },
              { value: 'Nurse- Adult', text: 'Nurse- Adult' },
              { value: 'Nurse- Child', text: 'Nurse- Child' },
              { value: 'Nurse- Learning Disability', text: 'Nurse- Learning Disability' },
              { value: 'Nurse- Mental Health', text: 'Nurse- Mental Health' },
              { value: 'Occupational therapist', text: 'Occupational therapist' },
              { value: 'Occupational therapy support worker', text: 'Occupational therapy support worker' },
              { value: 'Offshore medic', text: 'Offshore medic' },
              { value: 'Operating department practitioner', text: 'Operating department practitioner' },
              { value: 'Optometrist', text: 'Optometrist' },
              { value: 'Orthoptist', text: 'Orthoptist' },
              { value: 'Pathologist', text: 'Pathologist' },
              { value: 'Pharmacist', text: 'Pharmacist' },
              { value: 'Pharmacy technician', text: 'Pharmacy technician' },
              { value: 'Phlebotomist', text: 'Phlebotomist' },
              { value: 'Physiotherapist', text: 'Physiotherapist' },
              { value: 'Physiotherapy assistant', text: 'Physiotherapy assistant' },
              { value: 'Play therapist', text: 'Play therapist' },
              { value: 'Podiatrist', text: 'Podiatrist' },
              { value: 'Practice nurse', text: 'Practice nurse' },
              { value: 'Prosthetist-orthotist', text: 'Prosthetist-orthotist' },
              { value: 'Radiographer', text: 'Radiographer' },
              { value: 'Speech and language therapist', text: 'Speech and language therapist' },
              { value: 'Sterile services technician', text: 'Sterile services technician' },
              { value: 'Surgeon', text: 'Surgeon' }
            ]
          } else if (selected === 'Heritage, culture and libraries') {
            this.jobTypeOptions = [
              { value: 'Archaeologist', text: 'Archaeologist' },
              { value: 'Archivist', text: 'Archivist' },
              { value: 'Conservator', text: 'Conservator' },
              { value: 'Librarian', text: 'Librarian' },
              { value: 'Library assistant', text: 'Library assistant' },
              { value: 'Museum assistant', text: 'Museum assistant' },
              { value: 'Museum curator', text: 'Museum curator' }
            ]
          } else if (selected === 'Hospitality, catering and tourism') {
            this.jobTypeOptions = [
              { text: 'Air cabin crew', value: 'Air cabin crew' },
              { text: 'Airline customer service agent', value: 'Airline customer service agent' },
              { text: 'Airport information assistant', value: 'Airport information assistant' },
              { text: 'Baker', value: 'Baker' },
              { text: 'Bar person', value: 'Bar person' },
              { text: 'Cake decorator', value: 'Cake decorator' },
              { text: 'Catering manager', value: 'Catering manager' },
              { text: 'Chef', value: 'Chef' },
              { text: 'Counter service assistant', value: 'Counter service assistant' },
              { text: 'Hotel manager', value: 'Hotel manager' },
              { text: 'Hotel porter', value: 'Hotel porter' },
              { text: 'Hotel receptionist', value: 'Hotel receptionist' },
              { text: 'Housekeeping assistant', value: 'Housekeeping assistant' },
              { text: 'Kitchen manager (head chef)', value: 'Kitchen manager (head chef)' },
              { text: 'Kitchen porter', value: 'Kitchen porter' },
              { text: 'Resort representative', value: 'Resort representative' },
              { text: 'Restaurant manager', value: 'Restaurant manager' },
              { text: 'Tour manager', value: 'Tour manager' },
              { text: 'Tourist guide', value: 'Tourist guide' },
              { text: 'Tourist information centre assistant', value: 'Tourist information centre assistant' },
              { text: 'Travel agent', value: 'Travel agent' }
            ]
          } else if (selected === 'Languages') {
            this.jobTypeOptions = [
              { value: 'Interpreter', text: 'Interpreter' },
              { value: 'Translator', text: 'Translator' }
            ]
          } else if (selected === 'Legal and court services') {
            this.jobTypeOptions = [
              { value: 'Administrative officer (courts)', text: 'Administrative officer (courts)' },
              { value: 'Advocate', text: 'Advocate' },
              { value: 'Advocate\'s clerk', text: 'Advocate\'s clerk' },
              { value: 'Court officer', text: 'Court officer' },
              { value: 'Judge or sheriff', text: 'Judge or sheriff' },
              { value: 'Paralegal', text: 'Paralegal' },
              { value: 'Patent attorney', text: 'Patent attorney' },
              { value: 'Procurator fiscal', text: 'Procurator fiscal' },
              { value: 'Reporter to the Children\'s Panel', text: 'Reporter to the Children\'s Panel' },
              { value: 'Sheriff officer', text: 'Sheriff officer' },
              { value: 'Solicitor', text: 'Solicitor' }
            ]
          } else if (selected === 'Manufacturing and production') {
            this.jobTypeOptions = [
              { text: 'Brewery worker', value: 'Brewery worker' },
              { text: 'Butcher', value: 'Butcher' },
              { text: 'Distillery manager', value: 'Distillery manager' },
              { text: 'Food packaging operative', value: 'Food packaging operative' },
              { text: 'Garment technologist', value: 'Garment technologist' },
              { text: 'Production manager (manufacturing)', value: 'Production manager (manufacturing)' },
              { text: 'Production worker (manufacturing)', value: 'Production worker (manufacturing)' },
              { text: 'Quality assurance officer', value: 'Quality assurance officer' },
              { text: 'Quality control technician', value: 'Quality control technician' },
              { text: 'Quality manager', value: 'Quality manager' },
              { text: 'Sewing machinist', value: 'Sewing machinist' },
              { text: 'Tailor', value: 'Tailor' },
              { text: 'Technical brewer', value: 'Technical brewer' },
              { text: 'Textile technologist', value: 'Textile technologist' },
              { text: 'Toolmaker', value: 'Toolmaker' }
            ]
          } else if (selected === 'Performing arts and media') {
            this.jobTypeOptions = [
              { text: 'Actor', value: 'Actor' },
              { text: 'Arts administrator', value: 'Arts administrator' },
              { text: 'Broadcast engineer', value: 'Broadcast engineer' },
              { text: 'Broadcast journalist', value: 'Broadcast journalist' },
              { text: 'Choreographer', value: 'Choreographer' },
              { text: 'Classical musician', value: 'Classical musician' },
              { text: 'Community arts worker', value: 'Community arts worker' },
              { text: 'Dance teacher', value: 'Dance teacher' },
              { text: 'Dancer', value: 'Dancer' },
              { text: 'DJ', value: 'DJ' },
              { text: 'Entertainer', value: 'Entertainer' },
              { text: 'Lighting technician', value: 'Lighting technician' },
              { text: 'Music promotions manager', value: 'Music promotions manager' },
              { text: 'Pop musician', value: 'Pop musician' },
              { text: 'Prop maker', value: 'Prop maker' },
              { text: 'Radio broadcast assistant', value: 'Radio broadcast assistant' },
              { text: 'Roadie', value: 'Roadie' },
              { text: 'Sound designer', value: 'Sound designer' },
              { text: 'Stage manager', value: 'Stage manager' },
              { text: 'Stagehand', value: 'Stagehand' },
              { text: 'Studio sound engineer', value: 'Studio sound engineer' },
              { text: 'Stunt performer', value: 'Stunt performer' },
              { text: 'TV or film assistant director', value: 'TV or film assistant director' },
              { text: 'TV or film camera operator', value: 'TV or film camera operator' },
              { text: 'TV or film director', value: 'TV or film director' },
              { text: 'TV or film producer', value: 'TV or film producer' },
              { text: 'TV or film production assistant', value: 'TV or film production assistant' },
              { text: 'TV or film sound technician', value: 'TV or film sound technician' },
              { text: 'TV presenter', value: 'TV presenter' },
              { text: 'TV production runner', value: 'TV production runner' },
              { text: 'VFX artist', value: 'VFX artist' },
              { text: 'Wardrobe assistant', value: 'Wardrobe assistant' }
            ]
          } else if (selected === 'Print and publishing, marketing and advertising') {
            this.jobTypeOptions = [
              { value: '3D Printing specialist', text: '3D Printing specialist' },
              { value: 'Advertising account executive', text: 'Advertising account executive' },
              { value: 'Advertising account planner', text: 'Advertising account planner' },
              { value: 'Advertising copywriter', text: 'Advertising copywriter' },
              { value: 'Commissioning editor', text: 'Commissioning editor' },
              { value: 'Conference and exhibition organiser', text: 'Conference and exhibition organiser' },
              { value: 'Copy editor', text: 'Copy editor' },
              { value: 'Digital strategist', text: 'Digital strategist' },
              { value: 'Events manager', text: 'Events manager' },
              { value: 'Image consultant', text: 'Image consultant' },
              { value: 'Indexer', text: 'Indexer' },
              { value: 'Literary agent', text: 'Literary agent' },
              { value: 'Machine printer', text: 'Machine printer' },
              { value: 'Magazine journalist', text: 'Magazine journalist' },
              { value: 'Market research data analyst', text: 'Market research data analyst' },
              { value: 'Market research executive', text: 'Market research executive' },
              { value: 'Market research interviewer', text: 'Market research interviewer' },
              { value: 'Marketing manager', text: 'Marketing manager' },
              { value: 'Media researcher', text: 'Media researcher' },
              { value: 'Medical sales representative', text: 'Medical sales representative' },
              { value: 'Newspaper journalist', text: 'Newspaper journalist' },
              { value: 'Newspaper or magazine editor', text: 'Newspaper or magazine editor' },
              { value: 'Print room operative', text: 'Print room operative' },
              { value: 'Public relations officer', text: 'Public relations officer' },
              { value: 'SEO specialist', text: 'SEO specialist' },
              { value: 'Sub-editor', text: 'Sub-editor' },
              { value: 'Technical author', text: 'Technical author' },
              { value: 'Video editor', text: 'Video editor' },
              { value: 'Writer', text: 'Writer' }
            ]
          } else if (selected === 'Retail and customer services') {
            this.jobTypeOptions = [
              { value: 'Antique dealer', text: 'Antique dealer' },
              { value: 'Bookseller', text: 'Bookseller' },
              { value: 'Call centre operator', text: 'Call centre operator' },
              { value: 'Car salesperson', text: 'Car salesperson' },
              { value: 'Customer service adviser', text: 'Customer service adviser' },
              { value: 'Estate agent', text: 'Estate agent' },
              { value: 'Florist', text: 'Florist' },
              { value: 'Online seller', text: 'Online seller' },
              { value: 'Retail buyer', text: 'Retail buyer' },
              { value: 'Retail jeweller', text: 'Retail jeweller' },
              { value: 'Retail manager', text: 'Retail manager' },
              { value: 'Sales assistant', text: 'Sales assistant' },
              { value: 'Sales manager', text: 'Sales manager' },
              { value: 'Sales representative', text: 'Sales representative' },
              { value: 'Train conductor', text: 'Train conductor' },
              { value: 'Train station staff', text: 'Train station staff' },
              { value: 'Visual merchandiser', text: 'Visual merchandiser' },
              { value: 'Wine merchant', text: 'Wine merchant' }
            ]
          } else if (selected === 'Science, mathematics and statistics') {
            this.jobTypeOptions = [
              { value: 'Astronaut', text: 'Astronaut' },
              { value: 'Astronomer', text: 'Astronomer' },
              { value: 'Biochemist', text: 'Biochemist' },
              { value: 'Biologist', text: 'Biologist' },
              { value: 'Biomedical scientist', text: 'Biomedical scientist' },
              { value: 'Biotechnologist', text: 'Biotechnologist' },
              { value: 'Botanist', text: 'Botanist' },
              { value: 'Cartographer', text: 'Cartographer' },
              { value: 'Chemical plant process operator', text: 'Chemical plant process operator' },
              { value: 'Chemist', text: 'Chemist' },
              { value: 'Data analyst', text: 'Data analyst' },
              { value: 'Data scientist', text: 'Data scientist' },
              { value: 'Food scientist or food technologist', text: 'Food scientist or food technologist' },
              { value: 'Forensic scientist', text: 'Forensic scientist' },
              { value: 'Geneticist', text: 'Geneticist' },
              { value: 'Geographical information systems (GIS) Officer', text: 'Geographical information systems (GIS) Officer' },
              { value: 'Laboratory technician', text: 'Laboratory technician' },
              { value: 'Marine biologist', text: 'Marine biologist' },
              { value: 'Microbiologist', text: 'Microbiologist' },
              { value: 'Neuroscientist', text: 'Neuroscientist' },
              { value: 'Pharmacologist', text: 'Pharmacologist' },
              { value: 'Physicist', text: 'Physicist' },
              { value: 'Statistician', text: 'Statistician' },
              { value: 'Zoologist', text: 'Zoologist' }
            ]
          } else if (selected === 'Security, uniformed and protective services') {
            this.jobTypeOptions = [
              { value: 'Army officer', text: 'Army officer' },
              { value: 'Army soldier', text: 'Army soldier' },
              { value: 'Border Force officer or assistant officer', text: 'Border Force officer or assistant officer' },
              { value: 'CCTV operator', text: 'CCTV operator' },
              { value: 'Civil enforcement officer', text: 'Civil enforcement officer' },
              { value: 'Coastguard', text: 'Coastguard' },
              { value: 'Criminal intelligence analyst', text: 'Criminal intelligence analyst' },
              { value: 'Customs officer', text: 'Customs officer' },
              { value: 'Firefighter', text: 'Firefighter' },
              { value: 'Immigration officer', text: 'Immigration officer' },
              { value: 'Merchant navy deck officer', text: 'Merchant navy deck officer' },
              { value: 'Merchant navy rating', text: 'Merchant navy rating' },
              { value: 'Police officer', text: 'Police officer' },
              { value: 'Prison governor', text: 'Prison governor' },
              { value: 'Prison officer', text: 'Prison officer' },
              { value: 'Private investigator', text: 'Private investigator' },
              { value: 'RAF airman or airwoman', text: 'RAF airman or airwoman' },
              { value: 'RAF officer', text: 'RAF officer' },
              { value: 'Royal Marines commando', text: 'Royal Marines commando' },
              { value: 'Royal Marines officer', text: 'Royal Marines officer' },
              { value: 'Royal Navy officer', text: 'Royal Navy officer' },
              { value: 'Royal Navy rating', text: 'Royal Navy rating' },
              { value: 'Scenes of crime officer', text: 'Scenes of crime officer' },
              { value: 'Security officer', text: 'Security officer' },
              { value: 'Store detective', text: 'Store detective' }
            ]
          } else if (selected === 'Social sciences and religion') {
            this.jobTypeOptions = [
              { value: 'Counselling psychologist', text: 'Counselling psychologist' },
              { value: 'Educational psychologist', text: 'Educational psychologist' },
              { value: 'Health psychologist', text: 'Health psychologist' },
              { value: 'Occupational psychologist', text: 'Occupational psychologist' },
              { value: 'Psychotherapist', text: 'Psychotherapist' },
              { value: 'Sport and exercise psychologist', text: 'Sport and exercise psychologist' }
            ]
          } else if (selected === 'Social work and caring services') {
            this.jobTypeOptions = [
              { text: 'Care home practitioner', value: 'Care home practitioner' },
              { text: 'Care service manager', value: 'Care service manager' },
              { text: 'Care support worker', value: 'Care support worker' },
              { text: 'Childminder', value: 'Childminder' },
              { text: 'Community development worker', value: 'Community development worker' },
              { text: 'Counsellor', value: 'Counsellor' },
              { text: 'Crematorium technician', value: 'Crematorium technician' },
              { text: 'Early years practitioner', value: 'Early years practitioner' },
              { text: 'Funeral director', value: 'Funeral director' },
              { text: 'Housing officer', value: 'Housing officer' },
              { text: 'Nanny', value: 'Nanny' },
              { text: 'Playworker', value: 'Playworker' },
              { text: 'Social worker', value: 'Social worker' },
              { text: 'Welfare rights officer', value: 'Welfare rights officer' }
            ]
          } else if (selected === 'Sport and leisure') {
            this.jobTypeOptions = [
              { value: 'Fitness instructor', text: 'Fitness instructor' },
              { value: 'Footballer', text: 'Footballer' },
              { value: 'Leisure centre manager', text: 'Leisure centre manager' },
              { value: 'Lifeguard', text: 'Lifeguard' },
              { value: 'Outdoor activities instructor', text: 'Outdoor activities instructor' },
              { value: 'Personal trainer', text: 'Personal trainer' },
              { value: 'Sport and exercise scientist', text: 'Sport and exercise scientist' },
              { value: 'Sports coach', text: 'Sports coach' },
              { value: 'Sports development officer', text: 'Sports development officer' },
              { value: 'Sports professional', text: 'Sports professional' },
              { value: 'Sports therapist', text: 'Sports therapist' },
              { value: 'Swimming teacher or coach', text: 'Swimming teacher or coach' },
              { value: 'Yoga teacher', text: 'Yoga teacher' }
            ]
          } else if (selected === 'Transport, distribution and logistics') {
            this.jobTypeOptions = [
              { text: 'Air traffic controller', value: 'Air traffic controller' },
              { text: 'Airport baggage handler', value: 'Airport baggage handler' },
              { text: 'Bus or coach driver', value: 'Bus or coach driver' },
              { text: 'Courier', value: 'Courier' },
              { text: 'Driving examiner', value: 'Driving examiner' },
              { text: 'Driving instructor', value: 'Driving instructor' },
              { text: 'Dynamic positioning operator', value: 'Dynamic positioning operator' },
              { text: 'Flight dispatcher', value: 'Flight dispatcher' },
              { text: 'Forklift truck operator', value: 'Forklift truck operator' },
              { text: 'Freight forwarder', value: 'Freight forwarder' },
              { text: 'Large goods vehicle driver', value: 'Large goods vehicle driver' },
              { text: 'Pilot- Airline', value: 'Pilot- Airline' },
              { text: 'Pilot- Helicopter', value: 'Pilot- Helicopter' },
              { text: 'Postal delivery worker', value: 'Postal delivery worker' },
              { text: 'Road transport manager', value: 'Road transport manager' },
              { text: 'Taxi driver', value: 'Taxi driver' },
              { text: 'Train driver', value: 'Train driver' },
              { text: 'Warehouse operative', value: 'Warehouse operative' }
            ]
          }
          if (this.jobDetails.JobType !== undefined && this.jobDetails.JobType !== '') {
            this.jobTypeOptions = this.jobTypeOptions.map(o => o.text === this.jobDetails.JobType ? { ...o, selected: true } : { ...o, selected: false })
          } else {
            this.jobTypeOptions = this.jobTypeOptions.map(o => o.text === 'None' ? { ...o, selected: true } : { ...o, selected: false })
          }
        } catch {
          this.jobTypeOptions = [{ value: 'None', text: 'None', selected: true }]
        }
      },
      immediate: true
    }
  },
  beforeUnmount () {
    this.jobTypeOptions = [{ value: 'None', text: 'None', selected: true }]
    this.sectorOptions = this.originalOptions
  },
  methods: {
    checkSectorDetails () {
      if (this.jobDetails.Sector !== undefined && this.jobDetails.Sector !== '' && this.jobDetails.Sector !== null && this.jobDetails.Sector !== 'None') {
        this.sectorOptions = this.sectorOptions.map(o => o.text === this.jobDetails.Sector ? { ...o, selected: true } : { ...o, selected: false })
      } else { this.sectorOptions = this.originalOptions }
      if (this.jobDetails.JobType !== undefined && this.jobDetails.JobType !== '' && this.jobDetails.JobType !== null && this.jobDetails.JobType !== 'None') {
        this.jobTypeOptions = this.jobTypeOptions.map(o => o.text === this.jobDetails.JobType ? { ...o, selected: true } : { ...o, selected: false })
      } else { this.sectorOptions = this.originalOptions }
    }
  }
}
</script>
