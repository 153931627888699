<template>
  <div class="px-3 px-md-4" style="height:calc(100% - 60px);width:100%; overflow-y:auto; overflow-x:hidden;scrollbar-color:var(--pr-color);scrollbar-width:10px;margin-bottom: 100px;">
    <div class="font-weight-bold text-left my-3 d-flex align-items-center justify-content-between" style="height:50px;">
      <div class="d-flex align-items-center">
        <h3 v-if="rolesLoaded === false" class="primary-colour float-left font-weight-bold">
          Loading <i class="fa fa-spin fa-spinner ml-2" />
        </h3>
        <h3 v-else class="primary-colour float-left font-weight-bold">
          {{ titleText }}
          <i class="fas fa-sync ml-2 cp" @click="reloadRoles()" />
        </h3>
      </div>
      <div class="d-flex align-items-center">
        <span
          v-if="search"
          style="font-size: 0.8rem"
          class="cp text-black ml-3"
          @click="search = ''"
        >CLEAR SEARCH</span>
      </div>
      <ExpandableSearchBar
        v-if="viewingCandidateData === false"
        :width="300"
        :max-widthpercentage="40"
        :searchTriggersAPICall="true"
        @search="search = $event"
      />
    </div>
    <FilterBar
      v-if="viewingCandidateData === false"
      v-show="!search || tabletView"
      :filters="filters"
      :loading="rolesLoaded"
      :selectedRoleFilter="selectedRoleFilter.name"
      :disabled="userData.rle === 'Inviter'"
      @reloadRoles="getRolesLocal()"
    />
    <div
      v-if="selectedRoleFilter.name !=='Legacy'"
      class="mt-3 mobRoleCard2Grid"
    >
      <div style="height:fit-content;width:100%;margin-bottom:50px;" class="roleCard2Grid">
        <RoleCard
          v-for="(role, i) in allRoles"
          :key="role.JobId"
          :roleFromProps="role"
          style="width:100%;margin-top:20px;margin-bottom:-15px;"
          class="mr-3 rolesCardWidth"
          :style="{'--i': i}"
        />
      </div>
    </div>
    <div
      v-else
    >
      <div style="height:fit-content;width:100%;margin-bottom:50px;">
        <div class="my-4 grey-text" style="text-align:left;margin-top:30px !important;">
          <LegacyRoles />
        </div>
      </div>
    </div>

    <div v-if="allRoles.length === 0 && rolesLoaded">
      <p
        v-if="selectedRoleFilter.name !=='Legacy'"
        class="my-4 grey-text"
        style="font-size:0.9rem;text-align:left;margin-right:10px;margin-top: -35px !important;"
      >
        No roles to display (if you've not yet created any roles, let's get started by clicking the 'add' button below)!
      </p>
      <p
        v-else
        class="my-4 grey-text"
        style="font-size:0.9rem;text-align:left;margin-right:10px;margin-top: -35px !important;"
      >
        No legacy roles exist for this account.
      </p>
    </div>
    <InfiniteScroll
      v-if="viewingCandidateData === false"
      :allDataFetched="allRolesFetched"
      :gettingData="rolesLoaded == false"
      @getMoreData="getRolesLocal"
    />
    <ImportAndInviteModal
      :job-id="selectedRoleId"
      :import-and-invite-modal="importAndInviteModal"
      @closeImportAndInviteModal="importAndInviteModal = false"
      @updateComponentContext="refreshRoles()"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import ExpandableSearchBar from '@/components/UI/expandableSearch.vue'
import RoleCard from '@/views/roles/innerComponents/RoleCard/roleCard2'
import LegacyRoles from '@/views/roles/innerComponents/LegacyRoles/legacyRoles.vue'
import FilterBar from './filter/FilterBar.vue'
import ImportAndInviteModal from '@/components/misc/importBulkCandidatesModal.vue'
import { useRoles, useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import InfiniteScroll from '@/components/misc/infiniteScroll.vue'
import { useMobile } from '@/composables/useMobile'

export default {
  name: 'RolesLanding',
  components: {
    ExpandableSearchBar,
    RoleCard,
    FilterBar,
    ImportAndInviteModal,
    InfiniteScroll,
    LegacyRoles
  },
  setup () {
    const roleStore = useRoles()
    const { allRoles, recentRoles, getRoles, viewingOnlyFollowedRoles, filters, allRolesFetched } = storeToRefs(roleStore)
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)
    const { mobileView, tabletView } = useMobile()

    const selectedRoleFilter = computed(() => filters.value.find(f => f.value))

    if (userData.value.rle === 'Inviter') {
      filters.value =  ref([
        {
          id: 0,
          name: 'Open',
          value: true,
          tooltip: 'Your active roles'
        },
      ])
    } else if (userData.value.leg) {
      filters.value =  ref([
        {
          id: 0,
          name: 'Open',
          value: true,
          tooltip: 'Your active roles'
        },
        {
          id: 1,
          name: 'Closed',
          value: false,
          tooltip: 'Your closed (expired) roles'
        },
        {
          id: 2,
          name: 'Archived',
          value: false,
          tooltip: 'Your archived (deleted) roles'
        },
        {
          id: 3,
          name: 'Incomplete',
          value: false,
          tooltip: 'Your incomplete roles'
        },
        {
          id: 4,
          name: 'Legacy',
          value: false,
          tooltip: 'Your legacy roles'
        }
      ])
    } else {
      filters.value =  ref([
        {
          id: 0,
          name: 'Open',
          value: true,
          tooltip: 'Your active roles'
        },
        {
          id: 1,
          name: 'Closed',
          value: false,
          tooltip: 'Your closed (expired) roles'
        },
        {
          id: 2,
          name: 'Archived',
          value: false,
          tooltip: 'Your archived (deleted) roles'
        },
        {
          id: 3,
          name: 'Incomplete',
          value: false,
          tooltip: 'Your incomplete roles'
        }
      ])
    }

    return {
      allRoles,
      getRoles,
      viewingOnlyFollowedRoles,
      recentRoles,
      filters,
      selectedRoleFilter,
      allRolesFetched,
      userData,
      mobileView,
      tabletView
    }
  },
  data () {
    return {
      rolesLoaded: true,
      search: '',
      listVisible: true,
      filtering: false,
      importAndInviteModal: false,
      selectedRoleId: 0,
      defaultRoleFilter: {
        id: 0,
        name: 'Open',
        value: true,
        tooltip: 'Your active roles'
      },
      viewingCandidateData: false
    }
  },
  computed: {
    titleText () {
      return this.search != '' ? 'Search Results' : this.viewingOnlyFollowedRoles ? 'Followed Roles Only' : 'All Roles'
    },
    searchQuery () {
      try {
        return this.$route.query.search
      } catch { return '' }
    }
  },
  watch: {
    async selectedRoleFilter () {
      this.search = ''
      this.allRolesFetched = false
      this.allRoles = []
      await this.getRolesLocal()
    },
    viewingOnlyFollowedRoles () {
      this.allRoles = []
      // triggers a new api call
      this.search = ''
    },
    async search () {
      this.allRoles = []
      this.allRolesFetched = false
      if (!this.search) {
        this.$router.push(this.$route.path).catch(() => {})
      } else {
        this.$router.push({ path: this.$route.path, query: { search: encodeURIComponent(this.search) } }).catch(() => {})
      }
      await this.getRolesLocal()
    },
    searchQuery () {
      if (!this.searchQuery) return
      this.search = decodeURI(this.searchQuery)
    }
  },
  async mounted () {
    this.allRoles = []
    if (this.searchQuery) {
      // triggers api call if existing query
      this.search = decodeURI(this.searchQuery)
      return
    } else {
      this.getRolesLocal()
    }
  },
  beforeDestroy () { this.allRoles = [] },
  methods: {
    async refreshRoles () {
      this.allRoles = []
      await this.getRolesLocal()
    },
    async reloadRoles () {
      this.allRolesFetched = false
      this.allRoles = []
      this.viewingCandidateData = false
      await this.getRolesLocal()
    },
    async getRolesLocal () {
      this.rolesLoaded = false
      await this.getRoles({
        Search: this.search,
        FollowedRolesOnly: this.viewingOnlyFollowedRoles,
        Filter: this.selectedRoleFilter,
        Skip: this.allRoles.length,
        Take: 8
      })
      if (this.selectedRoleFilter !== null) {
        if (this.selectedRoleFilter.name === 'Legacy') {
            this.allRolesFetched = true
        }
      }
      setTimeout(() => {
        this.rolesLoaded = true
      }, 500)
    }
  }
}
</script>

<style scoped>
.listViewSelected {
  transition: ease 0.5s;
}
</style>

<style lang="css" scoped>
.filterBar {
  background:#e1e1e1;
  width:100%;
  border:solid 1px #c0c0c0;
  height:50px;
  border-radius:5px;
  margin-bottom:20px;
}

.form-check-input[type="checkbox"] + label, label.btn input[type="checkbox"] + label {
  padding-left:20px !important;
}
.roleCardListView {
  max-width: 100% !important;
}

@media (max-width: 576px) {
  .mobRoleCard2Grid {
    margin-top:60px !important;
    margin-bottom:75px;
  }
}
@media (min-width: 577px) {
  .roleCard2Grid {
    width:fit-content;
    display: grid;
    justify-content: space-evenly;
    grid-gap: 35px;
    grid-template-columns: 1fr;
  }
}

@media (min-width: 1200px) {
  .roleCard2Grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1800px) {
  .roleCard2Grid {
    justify-content: start;
    align-items: start;
    align-self:start;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  }
}
.roleCardContainer {
  float:left;
  height: 610px;
  width:100%;
  padding:3px;
}
.roleCardContent {
  height:600px;
  overflow-y:auto;
  overflow-x:hidden;
  background:#fff;
}
</style>
