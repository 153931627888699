<template>
  <!--step 4: Reviewing options-->
  <div class="px-3 px-md-5" style="text-align:left;padding:2px;margin-top:50px;">
    <div class="h5 grey-text">
      Set the Masking Options
    </div>
    <!-- AUDIO VIDEO CAPTURE -->
    <div class="md-form form-sm d-flex align-items-center" style="margin-top:40px;position:relative">
      <FontAwesomeIcon
        icon="fa-regular fa-face-hand-peeking"
        class="mr-3 mt-4 prefix"
        style="font-size: 1.4rem;margin-top: -10px;"
      />
      <label style="margin-top:-5px;font-size:12px;"> Audio / Video Capture ( will be applied to any stage with video interviews )</label>
      <mdb-select
        v-model="videoRecordOptions"
        style="margin-left: 40px;width:calc(100% - 50px);display:inline"
        class="mr-4"
        size="sm"
      />
      <Tooltip
        style="display: inline"
        tooltip-direction="left"
        :bullet-point-array="videoRecordingExplanation"
      />
    </div>

    <!-- CANDIDATE MASKING OPTIONS -->
    <div class="mb-5 mt-4">
      <div class="d-flex align-items-center">
        <div class="h6 m-0 grey-text mr-3">
          Internal Application Review Masking
        </div>
        <Tooltip
          style="display: inline"
          tooltip-direction="left"
          toolTipSummary="These settings will apply while reviewing the candidates progress in each stage. <br /> They will also apply when sharing using the Quick Share functionality.  <br /> NOTE: the settings are specific to this role and do not apply to other areas of the application."
        />
      </div>
      <div
        class="md-form form-sm"
        style="margin-top: 10px;"
      >
        <div @click="maskGoCandidateName = !maskGoCandidateName">
          <mdb-input
            :value="maskGoCandidateName"
            type="checkbox"
            class="mb-3"
            label="Mask candidate name"
            size="sm"
          />
        </div>
        <div @click="maskCandidateProfilePicture = !maskCandidateProfilePicture">
          <mdb-input
            :value="maskCandidateProfilePicture"
            type="checkbox"
            class="mb-3"
            label="Mask candidate profile picture"
            size="sm"
          />
        </div>
        <div @click="maskCandidateDocuments = !maskCandidateDocuments">
          <mdb-input
            :value="maskCandidateDocuments"
            type="checkbox"
            class="mb-3"
            label="Mask candidate documents"
            size="sm"
          />
        </div>
        <div @click="toggleMaskCandidateVideo">
          <mdb-input
            v-if="selectedVideoRecordOption != null"
            :value="maskCandidateVideo"
            :disabled="selectedVideoRecordOption == 1"
            type="checkbox"
            class="mb-3"
            label="Mask video recordings - only play audio ( not applicable to all stages )"
            size="sm"
          />
        </div>
        <div
          v-if="showExemptAdminOption"
          @click="adminsExemptFromMasking = !adminsExemptFromMasking">
          <mdb-input
            :value="adminsExemptFromMasking"
            type="checkbox"
            class="mb-3 grey-text"
            label="Make Admins exempt from masking"
            size="sm"
          />
        </div>
      </div>
    </div>

    <!-- BOTTOM BUTTONS -->
    <div class="d-flex align-items-center justify-content-between">
      <mdb-btn
        class="btn btn-outline my-3"
        size="sm"
        style="width:110px;"
        @click="$router.push(`/roles/add_edit/detail/${role.JobId}`)"
      >
        CANCEL
      </mdb-btn>
      <mdb-btn
        v-show="!buttonClick"
        type="submit"
        class="my-3"
        size="sm"
        style="width:100px;"
        @click="updateLocalRoleAndNext()"
      >
        FINISH
      </mdb-btn>
      <mdb-btn
        v-show="buttonClick"
        class="my-3"
        size="sm"
        style="width:100px;cursor:default;"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import Tooltip from '@/components/misc/tooltip.vue'
import roleV2Service from '@/api/services/roleV2.service'
import { useUserData, useStatus, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router/composables'

export default {
  name: 'MaskingOptions',
  components: {
    Tooltip
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const workflowStore = useWorkflow()
    const { getRolePersistMasking } = useWorkflow()
    const { addRoleToRecentRoles, role, currentWorkflow } = storeToRefs(workflowStore)

    const { showGenericErrorModal } = useStatus()
    const route = useRoute()
    getRolePersistMasking(route.params.jobId)

    return {
      authData,
      showGenericErrorModal,
      addRoleToRecentRoles,
      role,
      currentWorkflow
    }
  },
  data () {
    return {
      buttonClick: false,
      videoRecordOptions: [
        { text: 'Full Audio & Video Recordings', value: 0, selected: true },
        { text: 'Audio Only', value: 1 },
        { text: 'N/A', value: null },
      ],
      localRole: {},
      maskCandidateVideo: false,
      maskCandidateDocuments: false,
      maskGoCandidateName: false,
      maskCandidateProfilePicture: false,
      adminsExemptFromMasking: false,
    }
  },
  computed: {
    selectedVideoRecordOption () {
      try {
        return this.videoRecordOptions.find(c => c.selected)?.value ?? undefined
      } catch { return undefined }
    },
    videoRecordingExplanation () {
      let option = 0
      try {
        option = this.videoRecordOptions.filter(c => c.selected)[0]?.value
      } catch { option = 0 }
      if (option === 0) {
        return [
          'In stages with video interviews, candidates will be recorded with full video & audio.'
        ]
      } else if (option === 1) {
        return [
          'In stages with video interviews, candidates will be recorded with audio only.',
        ]
      } else if (option === null) {
        return [
          'Pick this option if none of your interview stages will use video interviews.',
        ]
      } else return []
    },
    showExemptAdminOption () { return this.maskCandidateVideo || this.maskCandidateDocuments || this.maskGoCandidateName || this.maskCandidateProfilePicture }
  },
  watch: {
    role: {
      handler: function () {
        this.localRole = { ...this.role, Step: 4 }
        this.setMaskingOptionsFromRole()
      },
      deep: true,
      immediate: true
    },
    selectedVideoRecordOption () {
      if (this.selectedVideoRecordOption == 1) { // audio only
        this.maskCandidateVideo = true
      } else if (this.selectedVideoRecordOption == null)  { // doesnt apply (event stage types)
        this.maskCandidateVideo = false
        this.maskCandidateDocuments = false
      }
    }
  },
  methods: {
    toggleMaskCandidateVideo () {
      if (this.selectedVideoRecordOption != 1) {
        this.maskCandidateVideo = !this.maskCandidateVideo
      }
    },
    setMaskingOptionsFromRole () {
      this.videoRecordOptions = this.videoRecordOptions.map(v => v.value === this.localRole.AudioOnly ? { ...v, selected: true } : { ...v, selected: false })
      if (this.role.Step === 3) {
        this.videoRecordOptions = this.videoRecordOptions.map(v => v.value === 0 ? { ...v, selected: true } : { ...v, selected: false })
      }
      this.adminsExemptFromMasking = this.localRole.AdminsExemptFromMasking
      this.maskCandidateVideo = this.localRole.MaskCandidateVideo
      this.maskCandidateDocuments = this.localRole.MaskCandidateDocuments
      this.maskGoCandidateName = this.localRole.MaskGoCandidateName
      this.maskCandidateProfilePicture = this.localRole.MaskCandidateProfilePicture
      const templateContainsStagesWithVideo = this.currentWorkflow?.OrderedRoleWorkflowStages.filter(s => s.StageType === 1 || s.StageType == 2).length > 0
      if (templateContainsStagesWithVideo) {
        this.videoRecordOptions = this.videoRecordOptions.filter(v => v.value != null)
      }
    },
    async updateLocalRoleAndNext () {
      this.localRole.AudioOnly = typeof this.selectedVideoRecordOption === "number" ? this.selectedVideoRecordOption : null
      this.localRole.MaskCandidateVideo = this.maskCandidateVideo
      this.localRole.MaskCandidateDocuments = this.maskCandidateDocuments
      this.localRole.MaskGoCandidateName = this.maskGoCandidateName
      this.localRole.MaskCandidateProfilePicture = this.maskCandidateProfilePicture
      this.localRole.AdminsExemptFromMasking = this.adminsExemptFromMasking
      try {
        const res = await roleV2Service.addEditRoleSetup(this.localRole)
        this.role = res.data
        await this.addRoleToRecentRoles(this.role.JobId)
        this.$router.push(`/roles/add_edit/detail/${this.role.JobId}`).catch(() => { })
      } catch (err) {
        this.showGenericErrorModal()
      }
    }
  }
}
</script>

