<template>
  <div>
    <mdb-modal
      id="reBrandIntroModalShowing"
      :show="reBrandIntroModalShowing"
      @close="$emit('reBrandIntroModalShowing', false)"
    >
      <mdb-modal-header>
        <span class="font-weight-bold py-2 text-left">{{ selectedRecording.RecordingName.toUpperCase() }} - RE-BRAND</span>
      </mdb-modal-header>
      <mdb-modal-body class="px-4">
        <div class="text-black text-left pt-3" style="font-size:0.8rem">
          Re-branding will create a new copy of this Shine Video using the brand you select below. Please note the original Shine Intro video is not changed and the current brand will remain.
        </div>
        <div class="d-flex align-items-center mt-2 mb-5">
          <FontAwesomeIcon
            icon="fa-solid fa-video"
            class="d-inline mr-3"
            style="transform:translateY(20px)"
          />
          <mdb-select
            v-model="videoBrand"
            label="Video Brand"
            size="sm"
            class="d-inline m-0"
            style="height:50px;width:100%;"
          />
          <mdb-icon
            v-if="brandsLoading"
            icon="fa fa-pulse fa-spinner"
            class="ml-3 mt-4"
            style="transform:translateY(50px)"
          />
        </div>
        <div
          v-if="selectedBrandSameAsCurrent"
          class="text-black text-left mr-auto"
          style="font-size:0.7rem;max-width:50%"
        >
          ** This recording already uses this Branding!
        </div>
      </mdb-modal-body>
      <mdb-modal-footer class="d-flex justify-content-end">
        <mdb-btn
          v-if="!rebrandInProcess"
          size="sm"
          :disabled="selectedBrandSameAsCurrent"
          style="width:100px;align-self:start;"
          @click="reBrand()"
        >
          FINISH
        </mdb-btn>
        <mdb-btn
          v-else
          size="sm"
          style="width:100px;align-self:start;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { useVideoIntro, useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import videoIntroService from '@/api/services/videointro.service'
import accountService from '@/api/services/account.service'

export default {
  name: 'ReBrandIntroModal',
  components: { },
  props: {
    selectedRecording: {
      type: Object,
      default: () => {}
    },
    reBrandIntroModalShowing: Boolean
  },
  setup () {
    const userStore = useUserData()
    const { userData, authData } = storeToRefs(userStore)

    const { addOrUpdateIntro } = useVideoIntro()

    const { showGenericErrorModal } = useStatus()

    return {
      userData,
      authData,
      showGenericErrorModal,
      addOrUpdateIntro
    }
  },
  data () {
    return {
      rebrandInProcess: false,
      brandsLoading: true,
      videoBrand: [],
      counter: 0
    }
  },
  computed: {
    selectedBrandId () {
      if (this.videoBrand.length === 0) { return 0 }
      return this.videoBrand.filter(b => b.selected === true)[0].value
    },
    selectedBrandSameAsCurrent () {
      try {
        return this.selectedBrandId === this.selectedRecording.Branding.BrandingId
      } catch {
        return false
      }
    }
  },
  watch: {
    reBrandIntroModalShowing () {
      if (this.reBrandIntroModalShowing === true) {
        this.getBranding()
      } else {
        this.videoBrand = []
      }
    }
  },
  methods: {
    async getBranding () {
      this.brandsLoading = true
      try {
        const res = await accountService.getBranding()
        this.counter = 0
        this.videoBrand = res.data.map(br => {
          if (br.BrandingId !== this.selectedRecording.Branding.BrandingId && this.counter === 0) {
            this.counter++
            return { value: br.BrandingId, text: br.Name, selected: true }
          }
          return { value: br.BrandingId, text: br.Name, selected: false }
        })
      } catch (err) {
        //?
      }
      this.brandsLoading = false
    },
    async reBrand () {
      this.rebrandInProcess = true
      try {
        const res = await videoIntroService.reBrand(this.selectedBrandId, this.selectedRecording.GoVideoIntroId)
        this.addOrUpdateIntro(res.data)
        this.$emit('reBrandIntroModalShowing', false)
      } catch (err) {
        this.showGenericErrorModal({ showing: true, errorTitle: 'Error Re-Branding Intro', errorMessage: 'Something went wrong re-branding your Shine Intro. Please refresh and try again.' })
      }
      this.rebrandInProcess = false
    }
  }
}
</script>

<style lang="scss">
@media (max-width: 576px) {
  #reBrandIntroModalShowing .modal-dialog .modal-content {
    margin:5px !important;
    width:calc(100vw - 10px) !important;
    height: calc(100vh - 10px) !important;
  }
  #reBrandIntroModalShowing .modal-dialog {
    margin:0px !important;
  }
}
</style>
