<template>
  <div>
    <div
      v-if="!loading && allHistory.length === 0"
      class="font-weight-bold text-center mx-auto grey-text pt-2"
      style="font-size:small;width:100%;"
    >
      No candidate history results for this role
    </div>
    <b-table
      v-else-if="allHistory.length > 0"
      id="role-history-table"
      class="text-left dark-grey-text"
      head-variant="dark"
      :items="allHistory"
      :current-page="1"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-compare="candidateSort"
      :fields="fields"
      :striped="true"
    >
      <template #cell(HistoryType)="data">
        <div class="roleHistoryTableRow">
          <FontAwesomeIcon :icon="getHistoryInfo(data.item).iconCode" class="mx-2" />
          <div class="d-flex flex-column">
            <div class="font-weight-bold">
              {{ data.item.HistoryTypeDesc }}
            </div>
            <div>{{ getHistoryInfo(data.item).extraDetailText }}</div>
          </div>
        </div>
      </template>
      <template #cell(DateAdded)="data">
        <div class="roleHistoryTableRow">
          <div class="d-flex flex-column">
            <div>{{ new Date(data.item.DateAdded).toLocaleDateString() }}</div>
            <span class="font-weight-bold">{{ new Date(data.item.DateAdded).toLocaleTimeString().substring(0, 8) }}</span>
          </div>
        </div>
      </template>
      <template #cell(User)="data">
        <div class="roleHistoryTableRow">
          {{ getHistoryInfo(data.item).addedByName ?? 'System User' }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'
import roleV2Service from '@/api/services/roleV2.service'
// import HistoryItem from '@/components/misc/History/singleHistoryItem.vue'
import { computed, ref } from 'vue-demi'
import GET_HISTORY_INFO from '@/utils/historyInfoSwitchCase.js'

export default {
  name: 'RoleHistory',
  props: {
    candidateId: {
      type: Number,
      default: 0
    }
  },
  setup () {
    const workflowStore = useWorkflow()
    const { role } = storeToRefs(workflowStore)

    const fields = computed(() => {
      return [
        { key: 'HistoryType', label: '', tdClass: 'p-0 py-1', sortable: false, },
        { key: 'DateAdded', label: 'Date / Time', tdClass: 'sortIcon p-0 py-1', sortable: true, sortByFormatted: false, formatter: (value) => { return new Date(value).toLocaleDateString(['en-GB']) }},
        { key: 'User', label: 'User', tdClass: 'p-0 py-1'},
      ]
    })

    const getHistoryInfo = item => GET_HISTORY_INFO(item)

    const sortBy = ref('')
    const sortDesc = ref(false)
    return { role, fields, sortBy, sortDesc, getHistoryInfo }
  },
  data () {
    return {
      allHistory: [],
      loading: false
    }
  },
  mounted () {
    this.fetchRoleHistory()
  },
  methods: {
    async fetchRoleHistory () {
      this.loading = true
      try {
        const res = await roleV2Service.getRoleHistory(this.candidateId, this.role.JobId)
        this.allHistory = res.data
      } catch (err) {
        //?
      }
      this.loading = false
    },
    candidateSort (itemA, itemB, key) {
      if (key === 'DateAdded') {
        // Compare based on epoch time
        return Date.parse(itemA[key]) - Date.parse(itemB[key])
      } else {
        // Fallback to default sort
        return false
      }
    },
  }
}
</script>

<style scoped>
.roleHistoryTableRow {
  height: 60px !important;
  display: flex;
  align-items: center;
}
</style>
