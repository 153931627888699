<template>
  <div class="d-flex align-items-center pl-2">
    <i v-if="loadingSearch != role.JobId" class="fas fa-search cp question mr-2" />
    <i v-else class="fas fa-pulse fa-spinner cp question mr-2" />
    <input
      v-model="search"
      type="text"
      :disabled="disabled"
      class="input m-0 ml"
      :placeholder="placeHolder"
    >
    <i
      v-if="role.IsComplete && search"
      class="fas fa-times cp mx-2"
      @click="clearEntered()"
    />
  </div>
</template>

<script>
import { useUserData, useRoles } from '@/pinia'
import { storeToRefs } from 'pinia'
import { computed } from 'vue-demi'

export default {
  name: 'RoleCardSearchBar',
  props: {
    role: {
      type: Object,
      default: () => {}
    },
    loadingSearch: {
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)

    const roleStore = useRoles()
    const { searchingInRoleWithId } = storeToRefs(roleStore)

    const placeHolder = computed(() => {
      //if (props.role.MaskGoCandidateName) return 'Remove Name Masking to Search'
      if (!props.role.IsComplete) return 'Complete Setup To Search'
      return 'Search Invited Candidates (Min 3 Chars)'
    })

    const disabled = computed(() => !props.role.IsComplete) // || props.role.MaskGoCandidateName)

    return {
      userData,
      placeHolder,
      disabled,
      searchingInRoleWithId
    }
  },
  data () {
    return {
      search: '',
      focused: false,
      delayAPITimer: null
    }
  },
  watch: {
    search () {
      const debouncer = this.debounce()
      debouncer()
    },
    searchingInRoleWithId () {
      if (this.searchingInRoleWithId != this.role.JobId) {
        this.$emit('search', '')
      }
    }
  },
  beforeUnmount () {
    this.$emit('search', '')
    this.searchingInRoleWithId = 0
  },
  methods: {
    clearEntered () {
      this.search = ''
      this.$emit('search', '')
      this.searchingInRoleWithId = 0
    },
    processChange () {
      this.$emit('search', this.search.toLowerCase())
      this.searchingInRoleWithId = this.role.JobId
    },
    debounce () {
      const si = this
      return function () {
        clearTimeout(si.delayAPITimer)
        si.delayAPITimer = setTimeout(() => {
          si.processChange()
        }, 1000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
input[type=text]:focus:not([readonly]) {
  box-shadow: none;
  // border: 3px solid var(--pr-color);
}
.input {
  width: calc(100% - 40px);
  background: none;
  box-sizing: border-box;
  background: transparent;
  color: #555;
  outline: none;
  font-size: 14px;
  letter-spacing: 1px;
  // color: transparent;
  color: #555;
}
.paddingOnFocus {
  padding: 0 30px;
}


</style>
