<template>
  <div>
    <div class="tag-input" style="border: 0 !important;margin-left:30px;width:93.5%;">
      <div>
        <input
          v-model="inputTag"
          type="text"
          placeholder="+ Add Tag"
          class="tag-input__text bg-transparent"
          :style="{'font-size': `${inputFontSize}`}"
          style="width:90%;border: 0 !important;border-bottom:solid 1px #d1d1d1"
          @keydown.enter="addTag"
          @keydown.188="addTag"
        >
        <i
          class="fa fa-plus"
          style="float: right;margin-top: 22px;"
          @click="addTag"
        />
      </div>
    </div>
    <b-row class="py-3 px-3 pl-4">
      <b-col
        v-for="(tag, index) in videoTags"
        :key="tag"
        xs="auto"
        class="p-1 mr-4"
        style="max-width: fit-content;"
      >
        <b-row
          v-if="videoTags.length > 0"
          class="tag-input__tag rounded-full px-2"
          style="width: fit-content; flex-wrap: nowrap;"
        >
          <div
            class="text-gray-800 mr-2"
            style="cursor: pointer;"
            @click="removeTag(index)"
          >
            x
          </div>
          <p>{{ tag }}</p>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'VideoTagInput',
  props: {
    inputFontSize: {
      default: '0.8rem',
      type: String
    },
    videoTags: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      tags: null,
      inputTag: null
    }
  },
  methods: {
    emitTag (e) {
      e.preventDefault()
      this.$emit('addTag', e.target.value)
    },
    addTag: function (event) {
      event.preventDefault()
      this.$emit('addTag', this.inputTag)
      this.inputTag = null
    },
    removeTag: function (tagIndex) {
      this.$emit('removeTag', tagIndex)
      this.inputTag = null
    }
  }
}
</script>
<style scoped>
.tag-input {
  width: 100%;
  border: 1px solid #eee;
  font-size: 0.9em;
  height: 50px;
  box-sizing: border-box;
  padding: 0 10px;
}

.tag-input__tag {
  height: 30px;
  float: left;
  background-color: #eee;
  line-height: 30px;
  padding: 0 5px 0 5px;
  border-radius: 25px;
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 0.9em;
  line-height: 50px;
  background: none;
}
</style>
