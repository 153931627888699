<template>
  <div class="pt-4 px-3 px-md-5 text-black rounded text-left" style="overflow-y:auto;overflow-x:hidden">
    <div class="font-weight-bold py-4 flex-grow-0 primary-colour">
      Video CV Settings
    </div>
    <b-row
      no-gutters
      class="text-black mt-2 mb-3 font-small"
      align-v="center"
      align-h="between"
    >
      <b-col cols="auto" style="font-size:0.8rem;max-width:70%;margin-top:-20px;">
        Automatically add questions to new candidates Video CV?
      </b-col>
      <div style="width:60px;margin-top:-20px;">
        <mdb-switch
          v-model="addAutoMaticallyToNewCandsCv"
          style="transform:scale(0.9)"
          off-label=""
          on-label=""
          class="m-0 ml-auto"
        />
      </div>
    </b-row>
    <div v-if="addAutoMaticallyToNewCandsCv">
      <div
        v-if="questionSetList.length > 0"
        style="font-size:0.7rem;"
        class="mb-3"
      >
        Choose the question set you want to use
      </div>
      <div class="flex-grow-1 text-black rounded p-2" style="border: rgb(225, 225, 225) 2px solid;overflow-x:hidden;max-height:40vh;">
        <div v-if="loadingQuestionSet">
          <h3 class="primary-colour text-left font-weight-bold">
            Loading<i style="margin-left: 5px !important;" class="fa fa-spin fa-spinner ml-3" />
          </h3>
        </div>
        <div
          v-if="!loadingQuestionSet && questionSetList.length == 0"
          style="font-size:0.8rem;"
          class="text-center"
        >
          Go to the 'Question Sets' settings tab to add a question set
        </div>
        <div v-if="!loadingQuestionSet && questionSetList.length > 0">
          <b-row
            v-for="set in questionSetList"
            :key="set.QuestionSetId"
            no-gutters
            style="width:100%;height:60px;"
            :class="{'insetShadowPrimary' : selectedSetId === set.QuestionSetId}"
            class="cp text-bold text-black rounded text-center border-2 border-gray-400  bg-gray-100 my-1 flex-nowrap borderColorOnHover"
            @click="selectedSetId = set.QuestionSetId"
          >
            <div style="width:10%; max-width: 40px;position:relative;border-radius:5px" class="m-2 d-none d-sm-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon="fa-circle-question fa-solid" style="font-size:1rem;position:absolute; top:4px; left:12px;" />
              <FontAwesomeIcon icon="fa-circle-question fa-solid" style="font-size:1rem;position:absolute; top:18px; left:4px;" />
              <FontAwesomeIcon icon="fa-circle-question fa-solid" style="font-size:1rem;position:absolute; top:18px; left:20px;" />
            </div>
            <b-col class="align-self-center twoLinedEllipsis ml-2" style="text-align:left;font-size:smaller;">
              {{ set.QuestionSetName }}
            </b-col>
            <div class="d-flex align-self-center align-items-center justify-content-center font-extrabold mr-3 px-1" style="font-size:smaller;height:30px;border:solid 2px #333;border-radius:15px;cursor:default;">
              <mdb-popover
                ref="popover"
                trigger="hover"
                :options="{placement: 'left'}"
              >
                <div slot="body">
                  <div v-for="(q, i) in set.Questions" :key="i">
                    <span class="font-weight-bold text-black">{{ i+1 }}. </span><span>{{ q.Description }}</span>
                  </div>
                </div>
                <div slot="reference" style="padding:10px;">
                  <div class="d-block d-sm-none" style="font-size:smaller;">
                    {{ set.Questions.length }} Q{{ set.Questions.length > 1 ? '\'S' : '' }}
                  </div>
                  <div class="d-none d-sm-block" style="font-size:smaller;">
                    {{ set.Questions.length }} QUESTION{{ set.Questions.length > 1 ? 'S' : '' }}
                  </div>
                </div>
              </mdb-popover>
            </div>
          </b-row>
        </div>
      </div>
    </div>
    <b-row
      v-if="addAutoMaticallyToNewCandsCv && questionSetList.length > 0"
      no-gutters
      class="text-black mt-2 mb-3 font-small "
      align-v="center"
      align-h="between"
    >
      <b-col cols="auto" style="font-size:0.8rem;max-width:70%;">
        Automatically invite new candidates to complete their Video CV?
      </b-col>
      <div style="width:60px;">
        <mdb-switch
          v-model="autoMaticallyInviteNewCands"
          style="transform:scale(0.9)"
          off-label=""
          on-label=""
          class="m-0 ml-auto"
        />
      </div>
    </b-row>
    <b-row
      no-gutters
      class="text-black mt-2 mb-3 font-small "
      align-v="center"
    >
      <mdb-btn
        v-if="!loading"
        class="p-2 ml-auto"
        :disabled="!showSave"
        size="sm"
        style="width:100px;"
        @click="saveSettings()"
      >
        SAVE
      </mdb-btn>
      <mdb-btn
        v-else
        class="p-2 ml-auto"
        size="sm"
        style="width:100px;"
      >
        <i class="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </b-row>
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import questionSetService from '@/api/services/questionSet.service.js'
import userService from '@/api/services/user.service.js'

export default {
  name: 'VideoCvSettings',
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()

    return {
      authData,
      showGenericErrorModal
    }
  },
  data () {
    return {
      addAutoMaticallyToNewCandsCv: false,
      loadingQuestionSet: false,
      questionSetList: [],
      selectedSetId: 0,
      autoMaticallyInviteNewCands: false,
      loading: false
    }
  },
  computed: {
    showSave () {
      if (!this.addAutoMaticallyToNewCandsCv) {
        return true
      } else if (this.addAutoMaticallyToNewCandsCv && this.selectedSetId === 0) {
        return false
      } else {
        return true
      }
    }
  },
  watch: {
    addAutoMaticallyToNewCandsCv () {
      if (!this.addAutoMaticallyToNewCandsCv) {
        this.selectedSetId = 0
        this.autoMaticallyInviteNewCands = false
      }
    }
  },
  created () {
    this.getQuestionSetList()
    this.loadUserSettings()
  },
  methods: {
    async getQuestionSetList () {
      this.loadingQuestionSet = true
      try {
        const res = await questionSetService.getQuestionSetList()
        this.questionSetList = this.convertResponse(res.data)
      } catch (err) {
        //err
       }
      this.loadingQuestionSet = false
    },
    convertResponse (res) {
      try {
        // splits the questions by setId
        const setIds = []
        const setShells = []
        res.map(q => {
          if (!setIds.includes(q.QuestionSetId)) { setIds.push(q.QuestionSetId) }
        })
        setIds.map(id => {
          setShells.push({ QuestionSetId: id, QuestionSetName: '', Questions: [] })
        })
        res.map(q => {
          let index
          setShells.forEach((sh, i) => {
            if (sh.QuestionSetId === q.QuestionSetId) { index = i }
          })
          setShells[index] = { ...setShells[index], QuestionSetName: q.QuestionSetName, Questions: [...setShells[index].Questions, q] }
        })
        return setShells
      } catch (err) { 
        //?
      }
    },
    async saveSettings () {
      this.loading = true
      var formData = new FormData()
      formData.append('selectedSetId', this.selectedSetId)
      formData.append('autoMaticallyInviteNewCands', this.autoMaticallyInviteNewCands)
      formData.append('addAutoMaticallyToNewCandsCv', this.addAutoMaticallyToNewCandsCv)
      try {
        await questionSetService.saveVideoCVSettings(formData)
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      setTimeout(() => { this.loading = false }, 2000)
    },
    async loadUserSettings () {
      try {
        const res = await userService.getUserSettings()
        this.selectedSetId = res.data.QuestionSetIdToAutoAddToVideoCV
        this.autoMaticallyInviteNewCands = res.data.AutoInviteNewCandsToVideoCV
        this.addAutoMaticallyToNewCandsCv = res.data.AutoAddQuestionSetToNewCands
      } catch (err) {
        //?
      }
      setTimeout(() => {
        this.settingsLoaded = true
        this.$parent.settingsLoaded = true
      }, 3000)
    }
  }
}
</script>

<style lang="scss" scoped>
  @media (max-width: 576px) {
    .minus20 {
      margin-top: -20px;
    }
  }

</style>
