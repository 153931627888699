<template>
  <div
    class="min-h-full pt-md-4 px-3 px-md-5 w-1/1 text-black rounded"
    :class="mobileView ? 'mt-6 pt-2' : ''"
    style="height:calc(100vh - 100px);overflow-y:auto;margin-bottom:65px;overflow-x:hidden"
  >
    <div ref="scrollToDiv" style="height: 1px;">
      <div v-show="detLoaded === false" :class="{ 'marginTopSections': mobileView === false }">
        <h3 class="primary-colour text-left font-weight-bold" :class="{ 'mobileTopSections': mobileView }">
          Loading
          <i
            style="margin-left: 5px !important;"
            class="fa fa-spin fa-spinner ml-3"
            :class="{ 'mobileTopSectionSpinner': mobileView }"
          />
        </h3>
      </div>
      <div v-show="detLoaded === true">
        <div :class="{ 'marginTopSections': mobileView === false }">
          <h3 class="primary-colour text-left font-weight-bold" :class="{ 'mobileTopSections': mobileView }">
            Account
          </h3>
        </div>
        <div class="grey-text text-left mb-4 mx-0">
          <p
            v-show="errMsg"
            style="margin-top:20px;font-size:14px;"
            :class="{ 'extraMarginTop': mobileView }"
            class="grey-text"
          >
            Account details not available at the moment. Please try again later.
          </p>

          <div v-show="errMsg === false" style="text-align:left;padding:2px;margin-top:20px;overflow-y:auto;margin-bottom:100px;">
            <div
              class="h5 grey-text"
              style="margin-bottom:20px;"
            >
              Main Details
            </div>
            <div class="md-form form-sm">
              <i
                class="fas fa-tools prefix"
                style="margin-top:4px;font-size: 1.3rem;cursor:help;"
                title="Guid"
              />
              <mdb-input
                v-model="account.AccountGuid"
                type="text"
                disabled
                label="Account Guid"
                style="margin-left:33px;"
                size="sm"
              />
              <div style="float:right;margin-right: 20px;">
                <i
                  v-if="copiedA === false"
                  class="fas fa-copy prefix copyTop"
                  style="margin-top:10px;font-size: 0.8rem;cursor:pointer;"
                  title="Copy to clipboard!"
                  @click="copyToClipboard(1, account.AccountGuid)"
                />
                <i
                  v-else
                  class="fas fa-check prefix copyTop"
                  style="margin-top:10px;font-size: 0.8rem;cursor:none;"
                />
              </div>
            </div>
            <div class="md-form form-sm">
              <i
                class="fas fa-building prefix"
                style="margin-top:4px;cursor:help;"
                title="Account name"
              />
              <mdb-input
                v-model="account.Name"
                type="text"
                disabled
                auto-complete="company-name"
                label="Company Name"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form form-sm">
              <i
                class="fas fa-globe-africa prefix"
                style="margin-top: 4px;font-size: 1.3rem;cursor:help;"
                title="Country"
              />
              <mdb-select
                v-model="countries"
                placeholder="Country"
                disabled
                style="margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form form-sm">
              <i
                class="fas fa-envelope prefix"
                style="margin-top: 4px;font-size: 1.3rem;cursor:help;"
                title="Support email"
              />
              <mdb-input
                v-model="account.SupportEmailContact"
                type="email"
                disabled
                auto-complete="ename"
                label="Support Email Contact"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form form-sm">
              <i
                class="fas fa-phone prefix"
                style="margin-top: 4px;font-size: 1.3rem;cursor:help;"
                title="Support telephone"
              />
              <mdb-input
                v-model="account.SupportTelephone"
                type="text"
                disabled
                auto-complete="tname"
                label="Support Telephone"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
            <div
              v-if="account.AccountGroupMaster > 0"
              class="md-form form-sm"
            >
              <i
                class="fas fa-sitemap prefix"
                style="margin-top: 4px;font-size: 1.3rem;cursor:help;"
                title="Master account group"
              />
              <mdb-input
                v-model="account.AccountGroupName"
                type="text"
                auto-complete="magr"
                label="Master Account Group"
                disabled
                style="margin-left:33px;"
                size="sm"
              />
            </div>
            <div v-show="account.ApplicationId != null && account.ApplicationSecret != null && (userData.api || userData.rle === 'Super User')">
              <div :class="{ 'marginTopSections': mobileView === false }">
                <h3
                  style="margin-top:40px;margin-bottom:40px;"
                  class="primary-colour text-left font-weight-bold"
                  :class="{ 'mobileTopSections': mobileView }"
                >
                  Public API Settings
                </h3>
              </div>
              <div class="md-form form-sm">
                <i
                  class="fas fa-tools prefix"
                  style="margin-top:4px;font-size: 1.3rem;cursor:help;"
                  title="Application reference for bearer token"
                />
                <mdb-input
                  v-model="account.ApplicationId"
                  type="text"
                  disabled
                  label="Application Id (required for bearer token)"
                  style="margin-left:33px;"
                  size="sm"
                />
                <div style="float:right;margin-right: 20px;">
                  <i
                    v-if="copiedB === false"
                    class="fas fa-copy prefix copyTop"
                    style="margin-top:10px;font-size: 0.8rem;cursor:pointer;"
                    title="Copy to clipboard!"
                    @click="copyToClipboard(2, account.ApplicationId)"
                  />
                  <i
                    v-else
                    class="fas fa-check prefix copyTop"
                    style="margin-top:10px;font-size: 0.8rem;cursor:none;"
                  />
                </div>
              </div>
              <div class="md-form form-sm">
                <i
                  class="fas fa-tools prefix"
                  style="margin-top:4px;font-size: 1.3rem;cursor:help;"
                  title="Application secret for bearer token"
                />
                <mdb-input
                  v-model="account.ApplicationSecret"
                  type="text"
                  disabled
                  label="Application Secret (required for bearer token)"
                  style="margin-left:33px;"
                  size="sm"
                />
                <div style="float:right;margin-right: 20px;">
                  <i
                    v-if="copiedC === false"
                    class="fas fa-copy prefix copyTop"
                    style="margin-top:10px;font-size: 0.8rem;cursor:pointer;"
                    title="Copy to clipboard!"
                    @click="copyToClipboard(3, account.ApplicationSecret)"
                  />
                  <i
                    v-else
                    class="fas fa-check prefix copyTop"
                    style="margin-top:10px;font-size: 0.8rem;cursor:none;"
                  />
                </div>
              </div>            

              <div class="md-form form-sm">
                <i
                  class="fas fa-tools prefix"
                  style="margin-top:4px;font-size: 1.3rem;cursor:help;"
                  title="Application client reference"
                />
                <mdb-input
                  v-model="account.ClientId"
                  type="text"
                  disabled
                  label="Client Id (mandatory for all API calls)"
                  style="margin-left:33px;"
                  size="sm"
                />
                <div style="float:right;margin-right: 20px;">
                  <i
                    v-if="copiedD === false"
                    class="fas fa-copy prefix copyTop"
                    style="margin-top:10px;font-size: 0.8rem;cursor:pointer;"
                    title="Copy to clipboard!"
                    @click="copyToClipboard(4, account.ClientId)"
                  />
                  <i
                    v-else
                    class="fas fa-check prefix copyTop"
                    style="margin-top:10px;font-size: 0.8rem;cursor:none;"
                  />
                </div>
              </div>

              <div class="md-form form-sm">
                <i
                  class="fas fa-tools prefix"
                  style="margin-top:4px;font-size: 1.3rem;cursor:help;"
                  title="Application account reference"
                />
                <mdb-input
                  v-model="account.AccountId"
                  type="text"
                  disabled
                  label="Account Id (mandatory for all API calls)"
                  style="margin-left:33px;"
                  size="sm"
                />
                <div style="float:right;margin-right: 20px;">
                  <i
                    v-if="copiedE === false"
                    class="fas fa-copy prefix copyTop"
                    style="margin-top:10px;font-size: 0.8rem;cursor:pointer;"
                    title="Copy to clipboard!"
                    @click="copyToClipboard(5, account.AccountId)"
                  />
                  <i
                    v-else
                    class="fas fa-check prefix copyTop"
                    style="margin-top:10px;font-size: 0.8rem;cursor:none;"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="userData.job"
              class="h5 grey-text"
              style="margin-bottom:20px;"
            >
              Jobtrain Settings
            </div>
            <div v-if="userData.job" class="md-form form-sm">
              <i
                class="fas fa-tools prefix"
                style="margin-top:4px;font-size: 1.3rem;cursor:help;"
                title="Completion endpoint"
              />
              <mdb-input
                :value="jturl"
                type="text"
                disabled
                label="Completion endpoint"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import accountService from '@/api/services/account.service'

export default {
  name: '',
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    return {
      authData,
      userData,
      mobileView
    }
  },
  data () {
    return {
      account: [],
      detLoaded: false,
      errMsg: false,
      copiedA: false,
      copiedB: false,
      copiedC: false,
      copiedD: false,
      copiedE: false,
      jturl: '',
      pwd: '',
      buttonClick: false,
      countries: [
        { text: 'United Kingdom', value: 'GB', selected: true },
        { text: 'Ireland', value: 'IE' },
        { text: 'United States', value: 'US' },
        { text: 'Canada', value: 'CA' }
      ],
      mags: []
    }
  },
  created () {
    accountService.getGoAccount().then((response) => {
      this.account = response.data
      
      this.jturl = this.account.JobtrainUrl

      this.mags = this.mags.map(q => q.value === this.acc.AccountGroupMaster ? { ...q, selected: true } : { ...q, selected: false })
      if (this.account.Country === 'IE') {
        this.countries = [
          { text: 'United Kingdom', value: 'GB' },
          { text: 'Ireland', value: 'IE', selected: true },
          { text: 'United States', value: 'US' },
          { text: 'Canada', value: 'CA' }
        ]
      } else if (this.account.Country === 'US') {
        this.countries = [
          { text: 'United Kingdom', value: 'GB' },
          { text: 'Ireland', value: 'IE' },
          { text: 'United States', value: 'US', selected: true },
          { text: 'Canada', value: 'CA' }
        ]
      } else if (this.account.Country === 'CA') {
        this.countries = [
          { text: 'United Kingdom', value: 'GB' },
          { text: 'Ireland', value: 'IE' },
          { text: 'United States', value: 'US' },
          { text: 'Canada', value: 'CA', selected: true }
        ]
      } else {
        this.countries = [
          { text: 'United Kingdom', value: 'GB', selected: true },
          { text: 'Ireland', value: 'IE' },
          { text: 'United States', value: 'US' },
          { text: 'Canada', value: 'CA' }
        ]
      }
      this.detLoaded = true
    }).catch(() => {
      this.detLoaded = true
      this.errMsg = true
    })
  },
  methods: {
    copyToClipboard (type, value) {
      navigator.clipboard.writeText(value)
      if (type === 1) {
        this.copiedA = true
        setTimeout(() => { this.copiedA = false }, 1500)
      } else if (type === 2) {
        this.copiedB = true
        setTimeout(() => { this.copiedB = false }, 1500)
      } else if (type === 3) {
        this.copiedC = true
        setTimeout(() => { this.copiedC = false }, 1500)
      } else if (type === 4) {
        this.copiedD = true
        setTimeout(() => { this.copiedD = false }, 1500)
      } else {
        this.copiedE = true
        setTimeout(() => { this.copiedE = false }, 1500)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.extraMarginTop {
  margin-top:35px !important;
}
</style>
