<template>
  <div class="px-3 px-md-5" style="text-align:left;padding:2px;margin:50px 0px;width:100%;">
    <div class="h5 grey-text">
      Invitation Options
    </div>
    <validation-observer v-slot="{ }">
      <div class="md-form form-sm" style="margin-top:40px;">
        <validation-provider
          v-slot="{ errors }"
          mode="lazy"
          name="Invitation Method"
          rules="required"
        >
          <i class="fas fa-user prefix" style="margin-top: 6px;font-size: 1.3rem;" />
          <label style="margin-top:-35px;font-size:12px;">Please select invitation method</label>
          <mdb-select
            v-model="typeOfInvitationOptions"
            placeholder="Select"
            style="margin-left:33px;"
            size="sm"
          />
          <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
            Please select an invitation method
          </div>
        </validation-provider>
      </div>

      <!-- CUSTOM SELF REG FORM TEXT -->
      <SlideFade>
        <div v-if="selectedInvitationMethod == 2 || selectedInvitationMethod == 3" class="mt-5">
          <div class="d-flex align-items-center">
            <div style="font-size: 1rem" class="grey-text mb-3 font-weight-bold">
              Custom Self-Registration Form Text
            </div>
          </div>
          <SlideFade>
            <SelfRegCustomTextField
              class="pl-5" 
              :jobDetails="localRole.JobDetails"
              @setHeaderText="localRole.JobDetails.CustomFormHeaderText = $event"
              @setSignUpInfoText="localRole.JobDetails.CustomFormSignUpInfoText = $event"
              @setSignUpCompletionText="localRole.JobDetails.CustomFormSignUpCompletionText = $event"
            />
          </SlideFade>
        </div>
      </SlideFade>


      <!-- JOB DETAILS -->
      <SlideFade>
        <div v-if="selectedInvitationMethod == 2 || selectedInvitationMethod == 3" class="mt-5">
          <div style="font-size: 1rem" class="grey-text mb-5 font-weight-bold">
            Job Details
          </div>
          <div class="pl-5">
            <!--company overview-->
            <div class="d-flex align-items-center" style="">
              <i class="fas fa-border-none prefix mr-2" style="font-size: 1.3rem;" />
              <div class="grey-text" size="sm">
                Company overview
              </div>
            </div>
            <div class="ml-3">
              <div v-show="!companyOverviewText">
                <div
                  v-if="localRole.JobDetails.CompanyOverviewVideo === null"
                  class="md-form form-sm"
                  style=""
                >
                  <div
                    style="cursor:pointer;border: 2px dotted rgb(192, 192, 192);margin-top: 35px;padding: 20px;text-align: center;"
                    class="grey-text"
                    @click="addJobVideo(1)"
                  >
                    <i class="fas fa-video" style="font-size:1.5rem;" /><br><span style="font-size:small;">Add video</span>
                  </div>
                </div>
                <div
                  v-if="localRole.JobDetails.CompanyOverviewVideo !== null && Object.keys(localRole.JobDetails.CompanyOverviewVideo).length !== 0"
                  class="border-2 border-gray-400 bg-gray-100 mt-5 my-1 p-1 text-bold text-black rounded text-center"
                  style="width: 100%;"
                >
                  <b-row
                    no-gutters
                    align-h="center"
                    align-v="center"
                  >
                    <b-col cols="4" style="background: black;">
                      <img
                        :src="localRole.JobDetails.CompanyOverviewVideo.ThumbnailUrl || localRole.JobDetails.CompanyOverviewVideo.Thumb"
                        class="m-auto"
                        style="height:80px; border-radius: 2px; object-fit: contain"
                      >
                    </b-col>
                    <b-col offset="1" class="p-1">
                      <b-row no-gutters class="font-extrabold text-center">
                        {{ localRole.JobDetails.CompanyOverviewVideo.ShortDescription }}
                      </b-row>
                    </b-col>
                    <b-col cols="2">
                      <div style="margin-bottom:-5px" class="text-center">
                        <i
                          class=" fas fa-times prefix"
                          style="color: black; margin-top: 6px;font-size: 1.3rem; cursor: pointer;"
                          @click="removeCompanyOverviewVideo()"
                        />
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div
                class="md-form form-sm"
                style="margin-top: 25px;margin-bottom: 40px;"
                @click="toggleCompanyOverviewText()"
              >
                <mdb-input
                  v-model="companyOverviewText"
                  type="checkbox"
                  label="Use a written company overview instead?"
                  style="margin-left: -20px;margin-top: -10px;"
                  size="sm"
                />
              </div>
              <div
                v-show="companyOverviewText"
                class="md-form form-sm"
                style="margin-top:40px;"
              >
                <mdb-input
                  v-model="localRole.JobDetails.CompanyOverview"
                  type="textarea"
                  auto-complete="fna"
                  :rows="4"
                  size="sm"
                  maxlength="500"
                  aria-placeholder="Enter brief company information"
                />
                <div class="grey-text remainingChars">
                  {{ setChars(localRole.JobDetails.CompanyOverview, 500) }}
                </div>
              </div>
            </div>
            <!--job description-->
            <div class="d-flex align-items-center" style="">
              <i class="fas fa-border-none prefix mr-2" style="font-size: 1.3rem;" />
              <div class="grey-text" size="sm">
                Job description
              </div>
            </div>
            <div class="ml-3">
              <div v-show="!jobOverviewText">
                <div v-if="localRole.JobDetails.JobOverviewVideo === null" class="md-form form-sm">
                  <div
                    style="cursor:pointer;border: 2px dotted rgb(192, 192, 192);margin-top: 35px;padding: 20px;text-align: center;"
                    class="grey-text"
                    @click="addJobVideo(2)"
                  >
                    <i class="fas fa-video" style="font-size:1.5rem;" /><br><span style="font-size:small;">Add Video</span>
                  </div>
                </div>
                <div
                  v-if="localRole.JobDetails.JobOverviewVideo !== null"
                  class="border-2 border-gray-400 bg-gray-100 mt-5 my-1 p-1 text-bold text-black rounded text-center"
                  style="width: 100%;"
                >
                  <b-row
                    no-gutters
                    align-h="center"
                    align-v="center"
                  >
                    <b-col cols="4" style="background: black;">
                      <img
                        :src="localRole.JobDetails.JobOverviewVideo.ThumbnailUrl || localRole.JobDetails.JobOverviewVideo.Thumb"
                        class="m-auto"
                        style="height:80px; border-radius: 2px; object-fit: contain"
                      >
                    </b-col>
                    <b-col offset="1" class="p-1">
                      <b-row no-gutters class="font-extrabold text-center">
                        {{ localRole.JobDetails.JobOverviewVideo.ShortDescription }}
                      </b-row>
                    </b-col>
                    <b-col cols="2">
                      <div style="margin-bottom:-5px" class="text-center">
                        <i
                          class=" fas fa-times prefix"
                          style="color: black; margin-top: 6px;font-size: 1.3rem; cursor: pointer;"
                          @click="removeJobDescVideo()"
                        />
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div
                class="md-form form-sm"
                style="margin-top: 25px;margin-bottom: 40px;"
                @click="toggleJobOverviewText()"
              >
                <mdb-input
                  v-model="jobOverviewText"
                  type="checkbox"
                  label="Use a written job description instead?"
                  style="margin-left: -20px;margin-top: -10px;"
                  size="sm"
                />
              </div>
              <div
                v-show="jobOverviewText"
                class="md-form form-sm"
                style="margin-top:40px;"
              >
                <mdb-input
                  v-model="localRole.JobDetails.JobOverview"
                  type="textarea"
                  auto-complete="fna"
                  :rows="4"
                  size="sm"
                  maxlength="1000"
                  aria-placeholder="Enter brief job description"
                />
                <div class="grey-text remainingChars">
                  {{ setChars(localRole.JobDetails.JobOverview, 1000) }}
                </div>
              </div>
            </div>
            <div class="md-form form-sm mb-0" style="margin-top:40px;">
              <validation-provider mode="lazy" name="Salary">
                <i class="fas fa-border-none prefix float-left" style="margin-top: 6px;font-size: 1.3rem;" />
                <mdb-input
                  v-model="localRole.JobDetails.Salary"
                  type="text"
                  auto-complete="jbt"
                  label="Salary"
                  style="margin-left:33px;"
                  size="sm"
                />
              </validation-provider>
            </div>
            <div class="md-form form-sm" style="margin-top:40px;">
              <validation-provider mode="lazy" name="Working Pattern">
                <i class="fas fa-user prefix" style="margin-top: 6px;font-size: 1.3rem;" />
                <label style="margin-top:-35px;font-size:12px;">Working pattern</label>
                <mdb-select
                  v-model="workingPatternOptions"
                  placeholder="Select"
                  style="margin-left:33px;"
                  size="sm"
                />
              </validation-provider>
            </div>
            <div class="md-form form-sm">
              <i class="fas fa-border-none prefix float-left" style="margin-top: -5px;font-size: 1.3rem;" />
              <div
                class="grey-text"
                style="margin-left: 26px;"
                size="sm"
              >
                Benefits
              </div>
              <mdb-input
                v-model="localRole.JobDetails.Benefits"
                type="textarea"
                :rows="4"
                size="sm"
                maxlength="250"
              />
              <div class="grey-text remainingChars">
                {{ setChars(localRole.JobDetails.Benefits, 250) }}
              </div>
            </div>
            <div style="margin-bottom:20px;" class="mt-2">
              <PostCodeSearch
                :role-id="role.JobId"
                :remote-working="isRemoteWorking"
                :job-details="localRole.JobDetails"
                @postCode="localRole.JobDetails.PostCode = $event"
                @line_1="localRole.JobDetails.Line_1 = $event"
                @line_2="localRole.JobDetails.Line_2 = $event"
                @town_or_city="localRole.JobDetails.TownOrCity = $event"
                @county="localRole.JobDetails.County = $event"
                @country="localRole.JobDetails.Country = $event"
              />
            </div>
            <div
              class="md-form form-sm m-0 mb-5 p-0"
              style="margin-bottom:40px;"
              @click="toggleRemoteWorking()"
            >
              <mdb-input
                v-model="isRemoteWorking"
                type="checkbox"
                class="m-0 p-0"
                auto-complete="demo"
                label="Remote Working"
                size="sm"
              />
            </div>
            <SectorAndJobType
              :role-id="role.JobId"
              :job-details="localRole.JobDetails"
              @selectedJobType="localRole.JobDetails.JobType = $event"
              @selectedSector="localRole.JobDetails.Sector = $event"
            />
            <div class="md-form form-sm">
              <i class="fas fa-border-none prefix float-left" style="margin-top: -5px;font-size: 1.3rem;" />
              <div
                class="grey-text"
                style="margin-left: 26px;"
                size="sm"
              >
                Job requirements
              </div>
              <mdb-input
                v-model="localRole.JobDetails.Skills"
                type="textarea"
                :rows="4"
                size="sm"
                maxlength="250"
              />
              <div class="grey-text remainingChars">
                {{ setChars(localRole.JobDetails.Skills, 250) }}
              </div>
            </div>
          </div>
        </div>
      </SlideFade>
      <div class="d-flex align-items-center justify-content-between">
        <mdb-btn
          class="btn btn-outline"
          size="sm"
          style="width:110px;"
          @click="$router.push(`/roles/add_edit/detail/${role.JobId}`)"
        >
          CANCEL
        </mdb-btn>
        <mdb-btn
          v-show="!buttonClick"
          :disabled="!invitationMethodHasBeenSelected"
          size="sm"
          style="width:100px;"
          @click="updateRoleAndSave()"
        >
          NEXT
        </mdb-btn>
        <mdb-btn
          v-show="buttonClick"
          size="sm"
          style="width:100px;cursor:default;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </div>
    </validation-observer>

    <InsertVideoModal
      :defaultCategory="typeName === 'Job' ? 'Job' : 'Organisation'"
      @handleChosenVideo="handleChosenVideo"
      @handleRecordedVideo="handleRecordedVideo"
    />
  </div>
</template>

<script>
import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'
import PostCodeSearch from './postCodeSearch.vue'
import SectorAndJobType from './sectorAndJobList.vue'
import SelfRegCustomTextField from './selfRegCustomFormText.vue'
import libraryService from '@/api/services/library.service'
import roleV2Service from '@/api/services/roleV2.service'
// import roleService from '@/api/services/role.service'
import InsertVideoModal from '@/components/genericModals/InsertVideoModals/insertVideoModal.vue'
import { useUserData, useStatus, useWorkflow, useLibrary } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'

export default {
  name: 'InvitationOptions',
  components: { SlideFade, SectorAndJobType, PostCodeSearch, InsertVideoModal, SelfRegCustomTextField },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const workflowStore = useWorkflow()
    const { role, addRoleToRecentRoles, currentWorkflow } = storeToRefs(workflowStore)

    const { showGenericErrorModal } = useStatus()

    const { mobileView } = useMobile()

    const libraryStore = useLibrary()
    const { showAddInsertVideoModal } = storeToRefs(libraryStore)
    return {
      authData,
      userData,
      showGenericErrorModal,
      role,
      addRoleToRecentRoles,
      mobileView,
      showAddInsertVideoModal,
      currentWorkflow
    }
  },
  data () {
    return {
      buttonClick: false,
      minimumDate: '',
      localRole: {},
      cv_chars: '',
      typeName: 'Company',
      companyOverviewText: false,
      jobOverviewText: false,
      videoModalPage: 'choose',
      isRemoteWorking: false,
      workingPatternOptions: [
        { text: 'All/Any', value: '0', selected: true },
        { text: 'On Site', value: '1' },
        { text: 'Remote', value: '2' },
        { text: 'Hybrid', value: '3' }
      ],
      typeOfInvitationOptions: [
        { text: 'Invite through Shine Go', value: 1, selected: true },
        { text: 'Self-Registration', value: 2 },
        { text: 'Invite through Shine Go & Self-Registration', value: 3 }
      ]
    }
  },
  computed: {
    invitationMethodHasBeenSelected () {
      return this.typeOfInvitationOptions.filter(i => i.selected).length > 0
    },
    selectedInvitationMethod () {
      try {
        return this.typeOfInvitationOptions.filter(i => i.selected)[0].value
      } catch { return 0 }
    }
  },
  watch: {
    isRemoteWorking () {
      this.localRole.JobDetails.RemoteWorking = this.isRemoteWorking
    },
    workingPatternOptions () {
      try {
        this.localRole.JobDetails.WorkingPattern = this.workingPatternOptions.filter(o => o.selected)[0].value
      } catch {
        this.localRole.JobDetails.WorkingPattern = ''
      }
    },
    companyOverviewText () {
      if (this.companyOverviewText) {
        this.localRole.JobDetails.CompanyOverviewVideo = null
      } else { this.localRole.JobDetails.CompanyOverview = '' }
    },
    jobOverviewText () {
      if (this.jobOverviewText) {
        this.localRole.JobDetails.JobOverviewVideo = null
      } else { this.localRole.JobDetails.JobOverview = '' }
    }
  },
  async mounted () {
    await this.setLocalRoleAsJobWithJobDetails()
    this.setLocalDropDownsFromRole()
  },
  methods: {
    async setLocalRoleAsJobWithJobDetails () {
      try {
        const res = await roleV2Service.getRole(Number(this.$route.params.jobId))
        this.localRole = { ...res.data }
        this.role = { ...res.data }
      } catch (err) {
        //?
      }
    },
    async updateRoleAndSave () {
      this.localRole.InterviewType = this.selectedInvitationMethod
      this.buttonClick = true
      try {
        const res = await roleV2Service.addEditRoleSetup({ ...this.localRole, Step: 2 })
        this.role = res.data
        await this.addRoleToRecentRoles(res.data.JobId)
        this.$router.push(`/roles/add_edit/role_setup/${this.role.JobId}?page=branding_options`).catch(() => { })
      } catch {
        this.showGenericErrorModal()
      }
      this.buttonClick = false
    },
    toggleRemoteWorking () {
      this.isRemoteWorking = !this.isRemoteWorking
      if (this.isRemoteWorking) {
        this.localRole.JobDetails.PostCode = ''
        this.localRole.JobDetails.Line_1 = ''
        this.localRole.JobDetails.Line_2 = ''
        this.localRole.JobDetails.TownOrCity = ''
        this.localRole.JobDetails.County = ''
        this.localRole.JobDetails.Country = ''
      }
    },
    setChars (text, max) {
      try {
        return `${text.length} / ${max}`
      } catch {
        return `0 / ${max}`
      }
    },
    removeCompanyOverviewVideo () {
      this.localRole.JobDetails.CompanyOverviewVideo = null
      this.localRole.JobDetails.CompanyOverviewVideoId = null
      this.$forceUpdate()
    },
    removeJobDescVideo () {
      this.localRole.JobDetails.JobOverviewVideo = null
      this.localRole.JobDetails.JobOverviewVideoId = null
      this.$forceUpdate()
    },
    toggleCompanyOverviewText () {
      this.companyOverviewText = !this.companyOverviewText
    },
    toggleJobOverviewText () {
      this.jobOverviewText = !this.jobOverviewText
    },
    addJobVideo (type) {
      if (type === 1) {
        this.typeName = 'Company'
      } else if (type === 2) {
        this.typeName = 'Job'
      }
      this.showAddInsertVideoModal = true
    },
    setLocalDropDownsFromRole () {
      this.workingPatternOptions = this.workingPatternOptions.map(o => o.value === this.role.JobDetails?.WorkingPattern ? { ...o, selected: true } : { ...o, selected: false })
      if (this.localRole.JobDetails?.JobOverview) {
        this.jobOverviewText = true
      } else if (this.localRole.JobDetails?.JobOverviewVideo) {
        this.jobOverviewText = false
      }
      if (this.localRole.JobDetails?.CompanyOverview) {
        this.companyOverviewText = true
      } else if (this.localRole.JobDetails?.CompanyOverviewVideo) {
        this.companyOverviewText = false
      }
      this.isRemoteWorking = this.localRole.JobDetails?.RemoteWorking
      this.typeOfInvitationOptions = this.typeOfInvitationOptions.map(i => i.value === this.localRole.InterviewType ? { ...i, selected: true } : { ...i, selected: false })
      // if the first stage type is a two way then we dont allow anything but inviting through shine go
      if (this.currentWorkflow?.OrderedRoleWorkflowStages[0]?.StageType === 2) {
        this.typeOfInvitationOptions = this.typeOfInvitationOptions.filter(v => v.value == 1)
      }
    },
    async handleChosenVideo (video) {
      const newVid = Object.assign({}, video)
      if (this.typeName === 'Company') {
        this.localRole.JobDetails = { ...this.localRole.JobDetails, CompanyOverviewVideo: newVid, CompanyOverviewVideoId: newVid.UploadedVideoId }
        this.companyOverviewText = false
        this.showAddInsertVideoModal = false
        this.localRole.JobDetails.CompanyOverview = ''
        this.$forceUpdate()
      } else if (this.typeName === 'Job') {
        this.localRole.JobDetails = { ...this.localRole.JobDetails, JobOverviewVideo: newVid, JobOverviewVideoId: newVid.UploadedVideoId }
        this.jobOverviewText = false
        this.localRole.JobDetails.JobOverview = ''
        this.showAddInsertVideoModal = false
        this.$forceUpdate()
      }
    },
    async handleRecordedVideo () {
      try {
        const res = await libraryService.getMostRecentVid(this.userData.aid)
        if (this.typeName === 'Company') {
          this.localRole.JobDetails = { ...this.localRole.JobDetails, CompanyOverviewVideo: res.data, CompanyOverviewVideoId: res.data.UploadedVideoId }
          this.companyOverviewText = false
          this.localRole.JobDetails.CompanyOverview = ''
        } else if (this.typeName === 'Job') {
          this.localRole.JobDetails = { ...this.localRole.JobDetails, JobOverviewVideo: res.data, JobOverviewVideoId: res.data.UploadedVideoId }
          this.jobOverviewText = false
          this.localRole.JobDetails.JobOverview = ''
        }
      } catch (err) {
        //?
      }
      this.showAddInsertVideoModal = false
    },
    async handleVideoUpload () {
      try {
        const res = await libraryService.getMostRecentVid(this.userData.aid)
        if (this.typeName === 'Company') {
          this.localRole.JobDetails = { ...this.localRole.JobDetails, CompanyOverviewVideo: res.data, CompanyOverviewVideoId: res.data.UploadedVideoId }
          this.companyOverviewText = false
          this.localRole.JobDetails.CompanyOverview = ''
        } else if (this.typeName === 'Job') {
          this.localRole.JobDetails = { ...this.localRole.JobDetails, JobOverviewVideo: res.data, JobOverviewVideoId: res.data.UploadedVideoId }
          this.jobOverviewText = false
          this.localRole.JobDetails.JobOverview = ''
        }
      } catch (err) {
        //?
      }
      this.showAddInsertVideoModal = false
    }
  }
}
</script>
