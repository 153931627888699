<template>
  <div class="d-flex col outerContainerBoilerplate p-0">
    <LeftColumn />

    <div class="mainSectionBoilerPlate">
      <div class="mainSectionBoilerPlateInner">
        <SlideFade>
          <router-view />
        </SlideFade>

        <!-- fixed bottom buttons for mobile -->
        <div class="d-flex d-md-none align-items-center" style="position: fixed; bottom: 0px; left: 0px; justify-content: space-evenly;background: white;width: 100%;border-top: 1px solid rgb(161, 161, 161);z-index: 1001;;height: 55px;">
          <mdb-btn
            class="btn-outline m-0"
            size="sm"
            style="width:110px;border:solid 2px !important;background:none !important;padding: 0.36rem 1.1rem;"
            @click="$router.push('/roles').catch(() => {})"
          >
            <i class="fas fa-th" /> VIEW ALL
          </mdb-btn>
          <mdb-btn
            class="btn btn-outline"
            size="sm"
            style="width:110px;padding: 0.4rem 1.1rem;"
            @click="addNewRole()"
          >
            <i class="fas fa-plus-circle" /> ROLE
          </mdb-btn>
        </div>
      </div>
    </div>

    <!--sharing role candidates modal -->
    <ShareModal />

    <!-- Add new candidates to invite modal -->
    <AddingInvitingCandidateModal />
  </div>
</template>

<script>
import LeftColumn from './innerComponents/LeftColumn/leftColumnRoles.vue'
import ShareModal from './innerComponents/ShareModal/shareModal.vue'
import roleService from '@/api/services/role.service'
import AddingInvitingCandidateModal from './innerComponents/AddingInvitingNewCandidate/addingInvitingCandidateModal.vue'
// import RoleCandidatesContainer from './innerComponents/Pages/Candidates/roleCandidatesContainer.vue'
import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'

import { useUserData, useStatus, useWorkflow, useNotification } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'RolesV2Container',
  components: {
    ShareModal,
    AddingInvitingCandidateModal,
    LeftColumn,
    // RoleCandidatesContainer,
    SlideFade,
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const notificationStore = useNotification()
    const { redirectToOneWay, redirectToTwoWay, redirectData } = storeToRefs(notificationStore)
    
    const workflowStore = useWorkflow()
    const { resetRoleData } = storeToRefs(workflowStore)

    const { showGenericErrorModal } = useStatus()

    return {
      authData,
      redirectData,
      showGenericErrorModal,
      redirectToOneWay,
      redirectToTwoWay,
      resetRoleData
    }
  },
  methods: {
    addNewRole () {
      this.resetRoleData()
      this.$router.push('/roles/add_edit/role_setup/0?page=about_this_role').catch(() => { })
    }
  }
}
</script>

<style lang="css" scoped>
.leftColHeader {
  background:#c1c1c1;
  padding: 26px 20px;
  font-size: 1.2rem;
}
.leftColMainContent {
  overflow-y:auto;
  flex-grow:1;
}
.outerContainerBoilerplate {
  width:100vw;
  max-height: calc(100vh - 80px);
}
.leftColumnBoilerPlate {
  height: calc(100vh - 80px);
  width:330px;
  background:#e1e1e1;
  background-color:#e1e1e1;
}
.mainSectionBoilerPlate {
  flex-grow: 1;
  min-height: calc(100vh - 80px);
  overflow-y: auto;
}
.mainSectionBoilerPlateInner {
  height:fit-content;
}
.leftColBottomButtonPanel {
  height:fit-content;
  padding: 11px;
  background:#d1d1d1;
  border-top: 1px solid #a1a1a1;
}
@media screen and (max-width: 991px) {
  .outerContainerBoilerplate {
    width:100vw;
  }
  .leftColumnBoilerPlate {
    height: calc(100vh - 80px);
    width:230px;
  }
}
</style>
