<template>
  <div>
    <div class="min-h-full py-3 py-md-4 w-1/1 px-3 text-black rounded" style="background:rgb(225, 225, 225);min-height:400px;height:calc(100vh - 315px);overflow-y:auto;overflow-x:hidden">
      <div style="height:calc(100%);overflow-x:hidden;">
        <b-row
          v-for="file in candidateFiles"
          :key="file.FileId"
          no-gutters
          style="width:auto"
          class="text-bold text-black rounded text-center border-2 border-gray-400 py-2 bg-gray-100 px-3 px-sm-4 my-1 flex-nowrap"
        >
          <div class="align-self-center flex-grow-0" style="text-align: left;font-size: 1rem;font-weight: bold;">
            <i
              v-if="file.DocumentExtension == '.doc' || file.DocumentExtension == '.docx'"
              class="col-span-1 fas fa-2x text-left fa-file-word mr-3"
              style="font-size:1.2rem;"
            />
            <i
              v-if="file.DocumentExtension == '.pdf' "
              class="col-span-1 fas fa-2x text-left fa-file-pdf mr-3"
              style="font-size:1.2rem;"
            />
            <i
              v-if="file.DocumentExtension == '.jpg' || file.DocumentExtension == '.jpeg' || file.DocumentExtension == '.JPG' || file.DocumentExtension == '.png'"
              class="col-span-1 fas fa-2x text-left fa-file-image mr-3"
              style="font-size:1.2rem;"
            />
          </div>
          <b-col
            cols="2"
            sm="auto"
            class="align-self-center text-nowrap overflow-hidden"
            style="text-align:left;font-size:smaller;"
          >
            {{ file.DocumentName }}  |
          </b-col>
          <b-col
            cols="3"
            sm="auto"
            class="align-self-center font-extrabold ml-2"
          >
            {{ file.DocumentType }}
          </b-col>
          <b-col
            cols="auto"
            class="flex-grow-1"
            style=""
          />
          <b-col class="align-self-center text-right flex-grow-0" style="">
            <b-row
              no-gutters
              class="flex-nowrap"
              align-v="center"
            >
              <b-col
                class="d-none d-sm-block"
                sm="md"
                lg="6"
                style="align-self:center"
              >
                <mdb-btn
                  class="btn m-0"
                  size="sm"
                  style="padding: 0.5rem 1.1rem;"
                  @click="openCloseViewFileModal(file)"
                >
                  View
                </mdb-btn>
              </b-col>
              <b-col sm="md" lg="6">
                <mdb-popover
                  ref="popover"
                  trigger="click"
                  :options="{placement: 'bottom'}"
                  style="cursor:pointer;"
                  class="grey-text"
                >
                  <div slot="body">
                    <h6 class="d-flex" style="align-items:center">
                      <i class="fa fa-download grey-link " style="margin-right:7px;" /><a :href="file.DocUrl" class="grey-link"> Download</a>
                    </h6>
                    <h6
                      class="d-flex"
                      style="align-items:center"
                      @click="editFile(file)"
                    >
                      <i class="fa fa-file grey-link" style="margin-right:10px;" /><a class="grey-link"> Edit File</a>
                    </h6>
                    <h6
                      class="d-flex d-sm-none"
                      style="align-items:center"
                      @click="openCloseViewFileModal(file)"
                    >
                      <i class="fa fa-file grey-link" style="margin-right:10px;" /><a class="grey-link"> View File</a>
                    </h6>
                    <h6
                      class="d-flex"
                      style="align-items:center"
                      @click="openDeleteFileModal(file)"
                    >
                      <i class="fa fa-trash grey-link " style="margin-right:7px;" /><a class="grey-link"> Delete File</a>
                    </h6>
                  </div>
                  <mdb-icon
                    slot="reference"
                    style="transform: translateX(15px); color:#555;font-size:1rem;"
                    class="ellipsisButtonHover mr-2 ml-lg-2"
                    icon="ellipsis-v"
                  />
                </mdb-popover>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div
          v-if="!loadingFiles"
          slot="footer"
          class="rounded text-center border-2 border-gray-400 border-dashed p-3"
          style="font-size:1rem;font-weight:bold;padding:20px !important;cursor:pointer;"
          @click="openAddFileModal()"
        >
          Add File
        </div>
        <div
          v-else
          slot="footer"
          class="rounded text-center border-2 border-gray-400 border-dashed p-3"
          style="font-size:1rem;font-weight:bold;padding:20px !important;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </div>
        <div />
        <div ref="scrollToDiv" style="height: 1px;" />
      </div>
    </div>

    <!--adding/editing file modal-->
    <mdb-modal
      id="addFile"
      :show="addEditFileModal"
      style="transform:translateY(80px)"
      @close="addEditFileModal = false"
    >
      <mdb-modal-header>
        <b-row style="width: 90%; margin-left: 20px;" align-h="between">
          {{ editingFile ? 'Edit File' : 'Add File' }}
        </b-row>
      </mdb-modal-header>
      <mdb-modal-body class="px-xs-2 py-xs-0 px-sm-4 py-sm-4">
        <validation-observer>
          <form
            ref="form"
            @submit.prevent="confirmFileChange()"
            @reset.prevent="reset"
          >
            <div class="md-form form-sm text-left" style="margin-left:10px;margin-right:10px;margin-top:-15px;">
              <i class="fas fa-file prefix float-left" style="margin-top: 6px;font-size: 1.3rem;" />
              <mdb-input
                v-model="fileTitle"
                type="text"
                auto-complete="fna"
                placeholder="File Name"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
            <span v-if="!editingFile">
              <div class="md-form form-sm text-left" style="margin-left:10px;margin-right:10px;">
                <i class="fas fa-object-group prefix" style="margin-top: 6px;font-size: 1.3rem;" />
                <mdb-select
                  v-model="fileType"
                  :disabled="editingFile"
                  placeholder="Select File Type"
                  style="margin-left:33px;"
                  size="sm"
                />
              </div>
              <p style="font-size:small;margin-left:35px;transform:translateY(-15px)" class="text-left">{{ restrictCVUpload ? 'Please remove the current CV before uploading another' : '' }}</p>
              <div class="md-form form-sm text-left" style="margin-left: 10px; margin-right: 10px;">
                <i class="fas fa-upload prefix" />
                <div class="file-field md-form form-sm text-left" style="margin-left:25px">
                  <div
                    v-if="!editingFile"
                    class="btn btn-primary btn-sm float-right"
                    style="margin-right:0px"
                    @click="launchFilePicker"
                  >
                    <span>Choose file</span>
                    <input
                      ref="file"
                      v-uploader
                      :disabled="editingFile"
                      type="file"
                      style="display:none"
                      accept=".docx,.doc,.pdf"
                    >
                  </div>
                  <div class="file-path-wrapper" style="font-size:14px;color:#495057;">
                    <input
                      class="file-path validate"
                      :disabled="editingFile"
                      type="text"
                      placeholder="Choose your file to upload"
                      :value="fileName"
                      readonly
                    >
                  </div>
                  <p style="font-size:small;">{{ !fileIsValid ? 'Please upload a valid file type' : '' }}</p>
                </div>
              </div>
            </span>
          </form>
        </validation-observer>
      </mdb-modal-body>
      <mdb-modal-footer style="display:block;">
        <b-row
          v-show="!loading"
          no-gutters
          align-h="between"
          align-v="center"
        >
          <b-col>
            <mdb-btn
              :disabled="editingFile"
              type="reset"
              class="float-left btn-outline"
              size="sm"
              style="width:100px;"
              @click="resetForm()"
            >
              RESET
            </mdb-btn>
          </b-col>
          <mdb-btn
            v-if="!editingFile"
            type="submit"
            :disabled="(fileTitle === null || fileTitle === '') || restrictCVUpload || !fileIsValid || selectedFileTypeValue == null || file == {}"
            class="float-right"
            size="sm"
            style="width:100px;"
            @click="confirmFileChange()"
          >
            <span>SAVE</span>
          </mdb-btn>
          <mdb-btn
            v-else
            type="submit"
            :disabled="fileTitle === null || fileTitle === ''"
            class="float-right"
            size="sm"
            style="width:100px;"
            @click="confirmFileChange()"
          >
            <span>SAVE</span>
          </mdb-btn>
        </b-row>
        <b-row v-show="loading" align-h="between">
          <mdb-progress
            v-if="uploadStarted"
            indeterminate
            style="margin-right:10px;"
          />
          <b-col class="text-left">
            <mdb-btn
              v-show="uploadStarted"
              size="sm"
              style="width:100px;"
              class="mx-0"
            >
              <mdb-icon icon="fa fa-pulse fa-spinner" />
            </mdb-btn>
          </b-col>
          <b-col v-if="loading && uploadStarted" class="align-self-center">
            <div class="my-auto text-black text-right" style="font-size: small;">
              Uploading File
            </div>
          </b-col>
        </b-row>
      </mdb-modal-footer>
    </mdb-modal>

    <!--confirm delete modal-->
    <mdb-modal
      v-if="deleteFileModalShowing"
      id="deleteFileModal"
      @close="deleteFileModalShowing = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>CONFIRM DELETE</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          Are you sure you want to delete this file?
        </p>
      </mdb-modal-body>
      <mdb-modal-footer class="justify-content-between">
        <b-row
          align-h="between"
          align-v="center"
          style="width:100%"
        >
          <mdb-btn
            style="width:130px;"
            class="btn-outline p-2"
            @click="deleteFileModalShowing = false"
          >
            CANCEL
          </mdb-btn>
          <mdb-btn
            v-if="!deleting"
            style="width:130px;"
            class="p-2"
            @click="deleteFile()"
          >
            DELETE
          </mdb-btn>
          <mdb-btn
            v-else
            style="width:130px;"
            class="p-2"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </b-row>
      </mdb-modal-footer>
    </mdb-modal>

    <CandidateFileViewer
      :view-file-modal="viewFileModal"
      :candidate-first-name="candidateFileViewerInfo.FirstName"
      :candidate-surname="candidateFileViewerInfo.LastName"
      :candidate-profile-picture-url="candidateFileViewerInfo.ProfilePicture"
      :file="file"
      :candidate-id="candidateToEdit.CandidateId"
      :current-i-d-check-status="candidateToEdit.IDChecked"
      @closeViewFileModal="viewFileModal = false"
    />
  </div>
</template>

<script>
import { useUserData, useCandidate } from '@/pinia'
import { storeToRefs } from 'pinia'
import candidateService from '@/api/services/candidate.service'
import CandidateFileViewer from '@/components/misc/candidateViewFileModal.vue'

export default {
  name: 'Files',
  components: {
    CandidateFileViewer
  },
  directives: {
    uploader: {
      bind (el, binding, vnode) {
        el.addEventListener('change', e => {
          if (e.target.files[0] !== undefined) {
            vnode.context.file = e.target.files[0]
          }
        })
      }
    }
  },
  setup () {
    const candidateStore = useCandidate()
    const { candidateToEdit, view, errorModal, candidateFiles } = storeToRefs(candidateStore)

    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    return {
      candidateToEdit,
      view,
      errorModal,
      candidateFiles,
      authData
    }
  },
  data () {
    return {
      addEditFileModal: false,
      deleting: false,
      deleteFileModalShowing: false,
      editingFile: false,
      file: {},
      fileIsValid: true,
      fileToDelete: null,
      fileTitle: '',
      fileType: [
        { text: 'CV', value: 0 },
        { text: 'Identification', value: 1 },
        { text: 'Right To Work', value: 2 },
        { text: 'Presentation', value: 3 },
        { text: 'Covering Letter', value: 4 },
        { text: 'Video', value: 5 },
        //VCVX{ text: 'Video CV', value: 6 },
        { text: 'Other', value: 7 }
      ],
      fileViewType: '',
      loading: '',
      loadingFiles: false,
      selectedFileId: null,
      uploadStarted: false,
      viewFileModal: false,
      willResolveIfFailed: null
    }
  },
  computed: {
    candidateFileViewerInfo () {
      if (this.candidateToEdit === null) {
        return { FirstName: '', LastName: '', ImageUrl: '' }
      } return { FirstName: this.candidateToEdit.FirstName, LastName: this.candidateToEdit.LastName, ProfilePicture: this.candidateToEdit.ProfilePicture }
    },
    fileName () {
      if (this.file) { return this.file.name || this.file.FileName }
      return ''
    },
    selectedFileTypeValue () {
      try {
        return this.fileType.filter(f => f.selected === true)[0].value
      } catch {
        return null
      }
    },
    restrictCVUpload () {
      if (this.candidateFiles.filter(f => f.DocumentType === 'CV').length > 0 && this.selectedFileTypeValue === 0) {
        return true
      } else { return false }
    }
  },
  watch: {
    candidateFiles: function () {
      this.scrollToDiv()
    },
    candidateToEdit () {
      this.loadCandFiles()
    },
    file () {
      try {
        const fileName = this.file.name.toLowerCase()
        if (fileName.includes('.jpg') || fileName.includes('.jpeg') || fileName.includes('.doc') || fileName.includes('.docx') || fileName.includes('.pdf') || fileName.includes('.png') || this.file === {}) {
          this.fileIsValid = true
        } else {
          this.fileIsValid = false
        }
      } catch {
        this.fileIsValid = false
      }
    }
  },
  created () {
    this.loadCandFiles()
  },
  mounted () {
    this.scrollToDiv()
  },
  methods: {
    // no parameters are passed when adding a document, otherwise both should be stated
    async confirmFileChange () {
      this.uploadStarted = true
      this.loading = true
      var formData = new FormData()
      formData.append('candidateId', this.candidateToEdit.CandidateId)
      if (this.deleting === true) {
        formData.append('docname', this.selectedFileId)
      } else {
        formData.append('docname', this.fileTitle)
      }
      if (this.editingFile === true) {
        formData.append('fileId', this.selectedFileId)
      }
      formData.append('type', this.selectedFileTypeValue)
      formData.append('toDelete', this.deleting)
      if (!this.editingFile) {
        formData.append('file', this.file)
      }
      try {
        const res = await candidateService.addEditDeleteDocument(formData)
        this.candidateFiles = res.data.DocumentList
        this.$emit('addCandidateToRecentCandidates', this.candidateToEdit.CandidateId)
      } catch (err) {
        console.log(Object.assign({}, err))
        this.$emit('errorAlert', { err, msg: 'Sorry an error has occurred uploading your file!' })
      }
      this.resetForm()
      this.uploadStarted = false
      this.loading = false
      this.addEditFileModal = false
      this.deleting = false
      this.deleteFileModalShowing = false
    },
    editFile (file) {
      this.file = file
      this.selectedFileId = file.DocumentId
      this.fileTitle = file.DocumentName
      this.fileType = this.fileType.map(f => f.text === file.DocumentType ? { ...f, selected: true } : { ...f, selected: false })
      this.addEditFileModal = true
      this.editingFile = true
      this.deleting = false
      this.$refs.popover.map(p => p.doClose())
    },
    openDeleteFileModal (file) {
      this.deleteFileModalShowing = true
      this.fileToDelete = file
      this.$refs.popover.map(p => p.doClose())
    },
    deleteFile () {
      this.file = {}
      this.deleting = true
      this.editingFile = false
      this.selectedFileId = this.fileToDelete.DocumentId
      this.confirmFileChange()
    },
    launchFilePicker () {
      this.$refs.file.click()
    },
    async loadCandFiles () {
      if (!this.candidateFiles.length > 0) {
        this.loadingFiles = true
        try {
          const res = await candidateService.getCandidateDocs(this.candidateToEdit.CandidateId)
          this.candidateFiles = res.data.DocumentList
        // eslint-disable-next-line no-empty
        } catch { }
      }
      this.loadingFiles = false
    },
    openCloseViewFileModal (file = {}) {
      this.file = file
      this.viewFileModal = true
    },
    openAddFileModal () {
      this.editingFile = false
      this.deleting = false
      this.resetForm()
      this.addEditFileModal = true
    },
    resetForm () {
      this.fileTitle = ''
      this.selectedFileURL = null
      this.selectedFileId = null
      this.file = {}
      this.fileType = [
        { text: 'CV', value: 0 },
        { text: 'Identification', value: 1 },
        { text: 'Right To Work', value: 2 },
        { text: 'Presentation', value: 3 },
        { text: 'Covering Letter', value: 4 },
        { text: 'Video', value: 5 },
        //VCVX{ text: 'Video CV', value: 6 },
        { text: 'Other', value: 7 }
      ]
    },
    scrollToDiv () {
      try {
        setTimeout(() => {
          this.$refs.scrollToDiv.scrollIntoView({ block: 'end', inline: 'nearest', behaviour: 'smooth' })
        }, 500)
      } catch {}
    }
  }
}
</script>
