<template>
  <div style="height:100%;width:100%; overflow-y:auto; overflow-x:hidden;scrollbar-color:var(--pr-color);scrollbar-width:10px;padding-bottom:100px;">
    <div v-if="(searchedRecordings.length === 0 && recordings.length === 0 && !$parent.loadingRecordings) || noResults === true" style="float: left;color: #555;font-size: 0.8rem;">
      No Results
    </div>

    <div
      v-if="loadingRecordings"
      v-show="mobileView"
      class="ml-0 ml-md-3 ml-lg-5 mView"
    >
      <h3 class="primary-colour text-left font-weight-bold">
        Loading
        <i style="margin-left: 5px !important;" class="fa fa-spin fa-spinner ml-3" />
      </h3>
    </div>

    <!-- grid View -->
    <div v-show="!listView && !loadingRecordings">
      <div class="meetNowRecordingsGrid px-3" style="min-height:fit-content;">
        <div
          v-for="recording in searchedRecordings"
          :key="recording.RecordingId"
          style="border:#a1a1a1 1px solid; width: 100%;height: 320px; border-radius:5px;box-sizing:border-box;"
          class="mb-4 shadow1 justify-self-center"
        >
          <div style="position:relative">
            <a
              class="pin"
              style="left: 45.55%;top: 52px;font-size: 2rem;opacity: 0.7;"
              @click="watchRecording(recording.RecordingUrl, recording.RecordingName, recording.RecordingThumbnail, recording.RecordingId, recording.ArchiveId)"
            ><span class="fa fa-play" /></a>
          </div>
          <div style="height: 140px;width:100%;" class="d-flex align-items-center justify-content-center">
            <img :src="recording.RecordingThumbnail" style="height:100%; width:100%; object-fit:cover;">
          </div>
          <div style="height: 176px;background:#fff;width:100%;color:#333;border-bottom-right-radius:4px;border-bottom-left-radius:4px;" class="d-flex flex-column justify-content-around p-2">
            <div
              style="font-size:1rem;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;cursor:help;color:#333"
              class="mr-auto"
              :title="recording.RecordingName"
            >
              {{ recording.RecordingName }}
            </div>
            <div style="font-size:0.6rem;" class="ml-2">
              {{ recording.RoomName }}
              <i
                v-if="recording.IsPublic"
                style="margin-left:3px;"
                class="fa fa-bullhorn"
              />
              <i
                v-else
                style="margin-left:3px;"
                class="fa fa-lock"
              />
            </div>
            <div style="width:100%;border-bottom:#a1a1a1 1px solid" class="d-flex justify-content-between align-items-center mt-1 pb-2">
              <div style="font-size:0.8rem;" class="ml-2">
                <FontAwesomeIcon icon="fa-solid fa-calendar-day" class="primary-colour" />
                {{ new Date(recording.DateTime).toLocaleDateString([userLanguage]) }}
              </div>
              <div style="font-size:0.8rem;" class="ml-2">
                <FontAwesomeIcon icon="fa-solid fa-clock-desk" class="primary-colour" />
                {{ recording.Duration }}
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <div v-if="recording" class="mr-auto d-flex align-items-center">
                <!-- HOST -->
                <div
                  v-if="recording.Host != undefined && recording.Host != null"
                  class="mr-1 d-flex align-items-center"
                  style="width:fit-content;height:20px"
                >
                  <img
                    v-show="recording.Host.Name !== 'N/A'"
                    :title="`Host - ${recording.Host.Name}`"
                    :src="recording.Host.ProfilePicture"
                    style="height:20px;width:20px; border-radius:50%;"
                    :class="{ 'borderForNoProfilePic' : recording.Host.ProfilePicture.includes('candidate-silhouette')}"
                  >
                </div>
                <!-- ASSESSORS -->
                <div
                  v-if="recording.Assessors != undefined && recording.Assessors.length > 0"
                  class="mr-1 d-flex align-items-center"
                  style="width:fit-content;height:20px"
                >
                  <img
                    v-for="(assessor,i) in recording.Assessors"
                    :key="i"
                    :title="`Assessor - ${assessor.Name}`"
                    :src="assessor.ProfilePicture"
                    style="height:20px;width:20px; border-radius:50%;"
                    :style="`transform:translateX(-${i * 4}px)`"
                    :class="{ 'borderForNoProfilePic' : assessor.ProfilePicture.includes('candidate-silhouette')}"
                  >
                </div>
                <!-- CANDIDATE -->
                <div
                  v-if="recording.Candidate !== undefined && recording.Candidate !== null"
                  class="mr-1 d-flex align-items-center"
                  style="width:fit-content;height:20px"
                >
                  <img
                    v-show="recording.Candidate.Name !== 'N/A'"
                    :title="`Candidate - ${recording.Candidate.Name}`"
                    :src="recording.Candidate.ProfilePicture"
                    style="height:20px;width:20px; border-radius:50%;"
                    :class="{ 'borderForNoProfilePic' : recording.Candidate.ProfilePicture.includes('candidate-silhouette')}"
                  >
                </div>
                <!-- GUESTS -->
                <div
                  v-show="recording.Guests.length > 0"
                  class="mr-1 d-flex align-items-center justify-items-center"
                  style="width:fit-content;height:20px"
                >
                  <span
                    v-for="(guest,i) in recording.Guests"
                    :key="i"
                    :title="`Guest - ${i + 1}`"
                    :style="`transform:translateX(-${i * 5}px)`"
                    style="height:20px"
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-user"
                      style="height:10px;width:10px; background:#d3d3d3;border-radius:50%;border:solid 1px #333"
                      class="p-1"
                    />
                  </span>
                </div>
              </div>
              <mdb-popover
                ref="popover"
                trigger="click"
                :options="{placement: 'bottom'}"
                style="cursor:pointer;"
                class="grey-text"
              >
                <div slot="body">
                  <!-- edit and sharing -->
                  <h6
                    v-if="recording.AddedById == userData.uid || recording.IsPublic"
                    style="margin-left:-10px;margin-top:-5px;padding:5px 12px;"
                    class="text-nowrap"
                    @click="showEditRecordingModal(recording)"
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-pen-line"
                      class="ml-1 grey-link"
                      style="margin-right:7px;"
                    /><a class="grey-link">Change Recording Name</a>
                  </h6>
                  <!--<h6 @click="share(recording)" v-if="meetNowFilterType !== 'Archived Recordings'" style="margin-left:-10px;margin-top:-5px;padding:5px 12px;" class="text-nowrap"><FontAwesomeIcon icon="fa-solid fa-share-nodes" class="ml-1 grey-link " style="margin-right:7px;" /><a   class="grey-link">Share</a></h6>-->

                  <!-- tagging -->
                  <h6
                    v-if="meetNowFilterType !== 'Archived Recordings'"
                    style="padding:5px 12px;margin-left:-10px"
                    class="text-nowrap"
                    @click="openTaggingModal(recording.RecordingId, false)"
                  >
                    <FontAwesomeIcon
                      icon="fa-user-tag fa-solid"
                      class="ml-1 grey-link"
                      style="margin-right:10px;"
                    /><a class="grey-link" style="margin-left:-3px;">Tag an Assessor</a>
                  </h6>
                  <h6
                    v-if="meetNowFilterType !== 'Archived Recordings'"
                    style="padding:5px 12px;margin-left:-10px"
                    class="text-nowrap"
                    @click="openTaggingModal(recording.RecordingId, true)"
                  >
                    <FontAwesomeIcon
                      icon="fa-user-tag fa-solid"
                      class="ml-1 grey-link"
                      style="margin-right:10px;"
                    /><a class="grey-link" style="margin-left:-3px;">Tag a Candidate</a>
                  </h6>

                  <!-- logic for making public - matches backend. can only make public/ private if its your video -->
                  <h6
                    v-if="meetNowFilterType !== 'Archived Recordings' && !recording.IsPublic && (recording.AddedById == userData.uid || userData.iss)"
                    style="padding:5px 12px;margin-left:-10px"
                    class="text-nowrap"
                    @click="updateMeetNowRecording('public', recording.RecordingId)"
                  >
                    <FontAwesomeIcon
                      icon="fa-bullhorn fa-solid"
                      class="ml-1 grey-link"
                      style="margin-right:10px;"
                    /><a class="grey-link">Make Public</a>
                  </h6>
                  <h6
                    v-if="meetNowFilterType === 'My Recordings' && recording.IsPublic && (recording.AddedById == userData.uid || userData.iss)"
                    style="padding:5px 12px;margin-left:-10px"
                    class="text-nowrap"
                    @click="updateMeetNowRecording('public', recording.RecordingId, false)"
                  >
                    <FontAwesomeIcon
                      icon="fa-lock-keyhole fa-solid"
                      class="ml-1 grey-link"
                      style="margin-right:10px;"
                    /><a class="grey-link">Make Private</a>
                  </h6>
                  <h6
                    v-if="meetNowFilterType === 'Public Recordings' && recording.IsPublic && (recording.AddedById == userData.uid || userData.iss)"
                    style="padding:5px 12px;margin-left:-10px"
                    class="text-nowrap"
                    @click="updateMeetNowRecording('public', recording.RecordingId, true)"
                  >
                    <FontAwesomeIcon
                      icon="fa-lock-keyhole fa-solid"
                      class="ml-1 grey-link"
                      style="margin-right:10px;"
                    /><a class="grey-link">Make Private</a>
                  </h6>

                  <!-- logic for archiving - matches backend. can only archive/unarchive if its public or it is your video / superuser -->
                  <h6
                    v-if="meetNowFilterType !== 'Archived Recordings' && (recording.AddedById == userData.uid || recording.IsPublic)"
                    style="padding:5px 12px;margin-left:-10px"
                    class="text-nowrap"
                    @click="updateMeetNowRecording('archive', recording.RecordingId)"
                  >
                    <FontAwesomeIcon
                      icon="fa-box-archive fa-solid"
                      class="ml-1 grey-link"
                      style="margin-right:10px;"
                    /><a class="grey-link">Archive Recording</a>
                  </h6>
                  <h6
                    v-if="meetNowFilterType === 'Archived Recordings' && (recording.AddedById == userData.uid || recording.IsPublic)"
                    style="padding:5px 12px;margin-left:-10px"
                    class="text-nowrap"
                    @click="updateMeetNowRecording('archive', recording.RecordingId)"
                  >
                    <FontAwesomeIcon
                      icon="fa-box-archive fa-solid"
                      class="ml-1 grey-link"
                      style="margin-right:10px;"
                    /><a class="grey-link">Unarchive Recording</a>
                  </h6>

                  <!-- change thumb -->
                  <h6
                    v-if="recording.AddedById == userData.uid || userData.rle === 'Admin' || userData.rle === 'AccountGroupAdministrator' || userData.rle === 'Account Group Administrator' || userData.iss"
                    class="text-nowrap"
                    @click="watchRecording(recording.RecordingUrl, recording.RecordingName, recording.RecordingThumbnail, recording.RecordingId, recording.ArchiveId)"
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-image-polaroid"
                      class="ml-1 grey-link"
                      style="margin-right:10px;"
                    /><a class="grey-link">Change Thumbnail</a>
                  </h6>

                  <!-- logic for deleting - matches backend. can only delete if its public or it is your video/ superuser -->
                  <h6
                    v-if="recording.AddedById == userData.uid || recording.IsPublic || recording.UpdatedById == userData.uid || userData.rle === 'Admin' || userData.rle === 'AccountGroupAdministrator' || userData.rle === 'Account Group Administrator' || userData.iss"
                    style="padding:5px 12px;margin-left:-10px"
                    class="text-nowrap"
                    @click="showDeleteRecordingModal(recording.RecordingId)"
                  >
                    <FontAwesomeIcon
                      icon="fa-trash-can-xmark fa-solid"
                      class="ml-1 grey-link"
                      style="margin-right:10px;"
                    /><a class="grey-link">Delete Recording</a>
                  </h6>

                  <EmbedVideo
                    v-if="recording.RecordingUrl !== ''"
                    :src="recording.RecordingUrl"
                    :video-title="recording.RecordingName"
                    @closePopover="$refs.popover.map(p => p.doClose())"
                  />
                </div>
                <mdb-icon
                  slot="reference"
                  style="width: 20px; height:30px; color:#555;font-size:1rem;"
                  class="d-flex align-items-center justify-content-center ellipsisButtonHover"
                  icon="ellipsis-v"
                />
              </mdb-popover>
            </div>
            <div>
              <mdb-btn
                style="width: 80px;padding: 6px !important;text-align: center;min-width:fit-content"
                class="m-0"
                @click="watchRecording(recording.RecordingUrl, recording.RecordingName, recording.RecordingThumbnail, recording.RecordingId, recording.ArchiveId)"
              >
                WATCH
              </mdb-btn>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- list View -->
    <div v-show="listView && !loadingRecordings">
      <div class="d-flex flex-wrap justify-content-center justify-content-md-start" style="min-height:fit-content;width:100%;">
        <div
          v-for="recording in searchedRecordings"
          :key="recording.RecordingId"
          style="border:#a1a1a1 1px solid; height: 70px;max-height:fit-content; width:100%; border-radius:1px;box-sizing:border-box"
          class="mb-2 mx-2 mr-3 d-flex"
        >
          <div
            v-if="mobileView === false"
            style="position:relative"
            class="d-none d-sm-block d-md-none d-lg-block"
          >
            <a
              class="pin"
              style="left: 35px;top: 16px;font-size: 1.5rem;opacity: 0.7;"
              @click="watchRecording(recording.RecordingUrl, recording.RecordingName, recording.RecordingId)"
            ><span class="fa fa-play" /></a>
          </div>
          <div style="height:101%;width:fit-content;" class="d-none d-sm-flex d-md-none d-lg-flex align-items-center justify-content-center">
            <img :src="recording.RecordingThumbnail" style="height:100%; width:93px;">
          </div>
          <div class="mr-auto d-flex flex-column justify-content-center text-left ml-1 ml-md-2 pr-2 recordingNameWidth" style="height:100%;">
            <div
              v-if="recording.RecordingName.length < 10"
              class="d-flex align-items-center font-weight-bold titleSize"
              style="color:#333;font-size:1.2rem"
            >
              {{ recording.RecordingName }}
            </div>
            <div
              v-if="recording.RecordingName.length > 9 && recording.RecordingName.length < 28"
              class="d-flex align-items-center font-weight-bold titleSize"
              style="color:#333;font-size:1rem"
            >
              {{ recording.RecordingName }}
            </div>
            <div
              v-if="recording.RecordingName.length > 27"
              class="d-flex align-items-center font-weight-bold titleSize"
              style="color:#333;font-size:1rem"
            >
              {{ recording.RecordingName }}
            </div>
            <div style="font-size:0.6rem;">
              {{ recording.RoomName }}
              <i
                v-if="recording.IsPublic"
                style="margin-left:3px;"
                class="fa fa-bullhorn"
              />
              <i
                v-else
                style="margin-left:3px;"
                class="fa fa-lock"
              />
            </div>
          </div>
          <div class="align-items-center justify-content-between d-none d-sm-flex" style="max-width:20%; height:100%;">
            <div class="d-flex flex-wrap align-items-center" style="height:fit-content;">
              <!-- HOST -->
              <span
                v-if="recording.Host !== null && recording.Host != undefined"
                class="mr-1 d-flex align-items-center"
                style="width:fit-content;height:20px"
              >
                <img
                  v-show="recording.Host.Name !== 'N/A'"
                  :title="`Host - ${recording.Host.Name}`"
                  :src="recording.Host.ProfilePicture"
                  style="height:20px;width:20px; border-radius:50%;"
                  :class="{ 'borderForNoProfilePic' : recording.Host.ProfilePicture.includes('candidate-silhouette')}"
                >
              </span>
              <!-- ASSESSORS -->
              <span
                v-if="recording.Assessors != undefined && recording.Assessors.length > 0"
                class="mr-1 d-flex align-items-center"
                style="width:fit-content;height:20px"
              >
                <img
                  v-for="(assessor,i) in recording.Assessors"
                  :key="i"
                  :title="`Assessor - ${assessor.Name}`"
                  :src="assessor.ProfilePicture"
                  style="height:20px;width:20px; border-radius:50%;"
                  :style="`transform:translateX(-${i * 4}px)`"
                  :class="{ 'borderForNoProfilePic' : assessor.ProfilePicture.includes('candidate-silhouette')}"
                >
              </span>
              <!-- CANDIDATE -->
              <span
                v-if="recording.Candidate !== undefined && recording.Candidate !== null"
                class="mr-1 d-flex align-items-center"
                style="width:fit-content;height:20px"
              >
                <img
                  v-show="recording.Candidate.Name !== 'N/A'"
                  :title="`Candidate - ${recording.Candidate.Name}`"
                  :src="recording.Candidate.ProfilePicture"
                  style="height:20px;width:20px; border-radius:50%;"
                  :class="{ 'borderForNoProfilePic' : recording.Candidate.ProfilePicture.includes('candidate-silhouette')}"
                >
              </span>
              <!-- GUESTS -->
              <div class="mr-1 d-flex align-items-center justify-items-center" style="width:fit-content;height:20px">
                <span
                  v-for="(guest,i) in recording.Guests"
                  :key="i"
                  :title="`Guest ${i + 1}`"
                  :style="`transform:translateX(-${i * 5}px)`"
                  style="height:20px"
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-user"
                    style="height:10px;width:10px; background:#d3d3d3;border-radius:50%;border:solid 1px #333"
                    class="p-1"
                  />
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column justify-content-center align-items-start mx-md-1 mx-lg-3" style="width:fit-content;">
            <div style="font-size:0.8rem;" class="d-flex flex-nowrap">
              <FontAwesomeIcon icon="fa-solid fa-calendar-day" class="d-inline" />
              <div style="color:#333" class="ml-1 text-nowrap d-inline">
                {{ new Date(recording.DateTime).toLocaleDateString([userLanguage]) }}
              </div>
            </div>
            <div style="font-size:0.8rem;">
              <FontAwesomeIcon icon="fa-solid fa-clock-desk" class="d-inline" />
              <span style="color:#333" class="ml-1">{{ recording.Duration }}</span>
            </div>
          </div>
          <div class="d-none d-sm-flex d-md-none d-lg-flex align-items-center justify-content-center mx-1 mx-md-2 mx-lg-3">
            <mdb-btn
              style="width: 80px;padding: 6px !important;text-align: center;min-width:fit-content"
              class="m-0"
              @click="watchRecording(recording.RecordingUrl, recording.RecordingName)"
            >
              WATCH
            </mdb-btn>
          </div>
          <div class="d-flex align-items-center justify-content-center mr-2 mr-lg-3">
            <mdb-popover
              ref="popover"
              trigger="click"
              :options="{placement: 'bottom'}"
              style="cursor:pointer;"
              class="grey-text"
            >
              <div slot="body">
                <!--edit and share-->
                <h6
                  v-if="recording.AddedById == userData.uid || recording.IsPublic"
                  style="margin-left:-10px;margin-top:-5px;padding:5px 12px;"
                  class="text-nowrap"
                  @click="showEditRecordingModal(recording)"
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-pen-line"
                    class="ml-1 grey-link"
                    style="margin-right:7px;"
                  /><a class="grey-link">Change Recording Name</a>
                </h6>
                <!--<h6 @click="share(recording)" v-if="meetNowFilterType !== 'Archived Recordings'" style="margin-left:-10px;margin-top:-5px;padding:5px 12px;" class="text-nowrap"><FontAwesomeIcon icon="fa-solid fa-share-nodes" class="ml-1 grey-link " style="margin-right:7px;" /><a   class="grey-link">Share</a></h6>-->

                <!-- playback -->
                <h6
                  style="margin-left:-10px;padding:5px 12px;"
                  class="text-nowrap d-flex d-sm-none d-md-flex d-lg-none"
                  @click="watchRecording(recording.RecordingUrl, recording.RecordingName, recording.RecordingThumbnail, recording.RecordingId, recording.ArchiveId)"
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-eye"
                    class="ml-1 grey-link "
                    style="margin-right:7px;"
                  /><a class="grey-link">Watch</a>
                </h6>

                <!-- tagging -->
                <h6
                  v-if="meetNowFilterType !== 'Archived Recordings' && recording.Guests < 0"
                  style="padding:5px 12px;margin-left:-10px"
                  class="text-nowrap"
                  @click="openTaggingModal(recording.RecordingId, false)"
                >
                  <FontAwesomeIcon
                    icon="fa-user-tag fa-solid"
                    class="ml-1 grey-link"
                    style="margin-right:10px;"
                  /><a class="grey-link">Tag an Assessor</a>
                </h6>
                <h6
                  v-if="meetNowFilterType !== 'Archived Recordings' && recording.Guests > 0"
                  style="padding:5px 12px;margin-left:-10px"
                  class="text-nowrap"
                  @click="openTaggingModal(recording.RecordingId, true)"
                >
                  <FontAwesomeIcon
                    icon="fa-user-tag fa-solid"
                    class="ml-1 grey-link"
                    style="margin-right:10px;"
                  /><a class="grey-link">Tag a Candidate</a>
                </h6>

                <!-- logic for making public - matches backend. can only make public/ private if its your video -->
                <h6
                  v-if="meetNowFilterType !== 'Archived Recordings' && !recording.IsPublic && (recording.AddedById == userData.uid || userData.iss)"
                  style="padding:5px 12px;margin-left:-10px"
                  class="text-nowrap"
                  @click="updateMeetNowRecording('public', recording.RecordingId)"
                >
                  <FontAwesomeIcon
                    icon="fa-bullhorn fa-solid"
                    class="ml-1 grey-link"
                    style="margin-right:10px;"
                  /><a class="grey-link">Make Public</a>
                </h6>
                <h6
                  v-if="meetNowFilterType === 'My Recordings' && recording.IsPublic && (recording.AddedById == userData.uid || userData.iss)"
                  style="padding:5px 12px;margin-left:-10px"
                  class="text-nowrap"
                  @click="updateMeetNowRecording('public', recording.RecordingId, false)"
                >
                  <FontAwesomeIcon
                    icon="fa-lock-keyhole fa-solid"
                    class="ml-1 grey-link"
                    style="margin-right:10px;"
                  /><a class="grey-link">Make Private</a>
                </h6>
                <h6
                  v-if="meetNowFilterType === 'Public Recordings' && recording.IsPublic && (recording.AddedById == userData.uid || userData.iss)"
                  style="padding:5px 12px;margin-left:-10px"
                  class="text-nowrap"
                  @click="updateMeetNowRecording('public', recording.RecordingId, true)"
                >
                  <FontAwesomeIcon
                    icon="fa-lock-keyhole fa-solid"
                    class="ml-1 grey-link"
                    style="margin-right:10px;"
                  /><a class="grey-link">Make Private</a>
                </h6>

                <!-- logic for archiving - matches backend. can only archive/unarchive if its public or it is your video / superuser -->
                <h6
                  v-if="meetNowFilterType !== 'Archived Recordings' && (recording.AddedById == userData.uid || recording.IsPublic)"
                  style="padding:5px 12px;margin-left:-10px"
                  class="text-nowrap"
                  @click="updateMeetNowRecording('archive', recording.RecordingId)"
                >
                  <FontAwesomeIcon
                    icon="fa-box-archive fa-solid"
                    class="ml-1 grey-link"
                    style="margin-right:10px;"
                  /><a class="grey-link">Archive Recording</a>
                </h6>
                <h6
                  v-if="meetNowFilterType === 'Archived Recordings' && (recording.AddedById == userData.uid || recording.IsPublic)"
                  style="padding:5px 12px;margin-left:-10px"
                  class="text-nowrap"
                  @click="updateMeetNowRecording('archive', recording.RecordingId)"
                >
                  <FontAwesomeIcon
                    icon="fa-box-archive fa-solid"
                    class="ml-1 grey-link"
                    style="margin-right:10px;"
                  /><a class="grey-link">Unarchive Recording</a>
                </h6>

                <!-- logic for deleting - matches backend. can only delete if its public or it is your video/ superuser -->
                <h6
                  v-if="recording.AddedById == userData.uid || recording.IsPublic || recording.UpdatedById == userData.uid"
                  style="padding:5px 12px;margin-left:-10px"
                  class="text-nowrap"
                  @click="showDeleteRecordingModal(recording.RecordingId)"
                >
                  <FontAwesomeIcon
                    icon="fa-trash-can-xmark fa-solid"
                    class="ml-1 grey-link"
                    style="margin-right:10px;"
                  /><a class="grey-link">Delete Recording</a>
                </h6>

                <EmbedVideo
                  v-if="recording.RecordingUrl !== ''"
                  :src="recording.RecordingUrl"
                  :video-title="recording.RecordingName"
                  @closePopover="$refs.popover.map(p => p.doClose())"
                />
              </div>
              <mdb-icon
                slot="reference"
                style="width: 20px; height:30px; color:#555;font-size:1rem;"
                class="d-flex align-items-center justify-content-center ellipsisButtonHover"
                icon="ellipsis-v"
              />
            </mdb-popover>
          </div>
        </div>
      </div>
    </div>

    <PlayRecordingModal
      :recording-to-watch-u-r-l="recordingToWatchURL"
      :recording-to-watch-thumb="recordingToWatchThumb"
      :selected-recording-name="selectedRecordingName"
      :play-meet-now-recording-modal-showing="playMeetNowRecordingModalShowing"
      :guid="guid"
      :recording-id="recordingId"
      @playMeetNowRecordingModalShowing="playMeetNowRecordingModalShowing = $event"
    />

    <div v-if="selectedRecordingIdToTag != null && taggingUntaggingCandidateModalShowing">
      <TagUntaggingCandidateModal
        :recording="selectedRecordingToTag"
        :tagging-untagging-candidate-modal-showing="taggingUntaggingCandidateModalShowing"
        @taggingUntaggingCandidateModalShowing="taggingUntaggingCandidateModalShowing = $event; selectedRecordingIdToTag = null"
      />
    </div>

    <div v-if="selectedRecordingIdToTag != null && taggingUntaggingAssessorModalShowing">
      <TagUntaggingAssessorModal
        :recording="selectedRecordingToTag"
        :tagging-untagging-assessor-modal-showing="taggingUntaggingAssessorModalShowing"
        @taggingUntaggingAssessorModalShowing="taggingUntaggingAssessorModalShowing = $event; selectedRecordingIdToTag = null"
      />
    </div>

    <!--create Room modal-->
    <CreateRoomModal
      :create-room-modal-showing="createRoomModalShowing"
      @createRoomModalShowing="$emit('createRoomModalShowing')"
    />

    <!--sharing modal-->
    <ShareMeetNowModal
      :selected-recording="selectedRecording"
      :share-meet-now-modal-showing="shareMeetNowModalShowing"
      @shareMeetNowModalShowing="shareMeetNowModalShowing = $event"
    />

    <!--delete modal-->
    <mdb-modal
      id="deleteRecordingModal"
      :show="deleteRecordingModal"
      @close="closeDeleteRecordingModal()"
    >
      <mdb-modal-header>
        <mdb-modal-title>DELETE?</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          Are you sure you want to delete this recording?
        </p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          v-show="!spinningFlag"
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="deleteRecording()"
        >
          YES
        </mdb-btn>
        <mdb-btn
          v-show="spinningFlag"
          color="primary"
          size="sm"
          style="width:100px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

    <!--edit recording name modal-->
    <mdb-modal
      id="editRecordingNameModal"
      :show="editRecordingNameModal"
      @close="closeEditRecordingNameModal()"
    >
      <mdb-modal-header>
        <mdb-modal-title>CHANGE RECORDING NAME?</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body style="padding:25px 40px !important; height:fit-content !important; overflow-y:auto !important;">
        <div class="d-flex align-items-center" style="height:40px">
          <FontAwesomeIcon icon="fa-solid fa-pen-line" class="d-inline mr-3" />
          <mdb-input
            v-model="recordingName"
            type="text"
            label="Change Recording Name?"
            size="sm"
            class="m-0"
            maxlength="30"
            style="height:40px;width:100%;"
          />
        </div>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          v-show="!spinningFlag"
          color="primary"
          :disabled="recordingName === ''"
          size="sm"
          style="width:100px;"
          @click.native="editRecordingName()"
        >
          SAVE
        </mdb-btn>
        <mdb-btn
          v-show="spinningFlag"
          color="primary"
          size="sm"
          style="width:100px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

    <!--alert modal-->
    <mdb-modal
      id="alertModal"
      :show="alertModal"
      @close="alertModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>{{ alertModalTitle.toUpperCase() }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <p class="my-4" style="font-size:0.8rem;text-align:left;">
          {{ alertModalContent }}
        </p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="primary" @click.native="alertModal = false">
          OK
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { useMeetNow, useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import meetNowService from '@/api/services/meetnow.service'
import logoutService from '@/api/services/logout.service'
import { logout } from '@/methods/auth.js'
import { userLanguage } from '@/common/userLanguage'

import PlayRecordingModal from './playRecordingModal.vue'
import TagUntaggingCandidateModal from './taggingUntaggingCandidateModal.vue'
import TagUntaggingAssessorModal from './taggingUntaggingAssessorModal.vue'
import CreateRoomModal from '../Rooms/createRoomModal.vue'
import EmbedVideo from '@/components/misc/embedVideo.vue'
import ShareMeetNowModal from './shareMeetNowModal.vue'

export default {
  name: 'Recordings',
  components: {
    PlayRecordingModal,
    TagUntaggingCandidateModal,
    TagUntaggingAssessorModal,
    CreateRoomModal,
    EmbedVideo,
    ShareMeetNowModal
  },
  props: {
    createRoomModalShowing: Boolean,
    listView: Boolean,
    search: {
      type: String,
      default: ''
    },
    meetNowFromRedirect: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const meetNowStore = useMeetNow()
    const { addOrUpdateRecording, setRecordings } = useMeetNow()
    const {
      myMeetNow,
      publicMeetNow,
      meetNowFilterType,
      archivedMeetNow,
      refreshMeetNowSummary
    } = storeToRefs(meetNowStore)

    const { mobileView } = useMobile()

    return {
      authData,
      userData,
      myMeetNow,
      publicMeetNow,
      meetNowFilterType,
      archivedMeetNow,
      refreshMeetNowSummary,
      addOrUpdateRecording,
      setRecordings,
      mobileView
    }
  },
  data () {
    return {
      playMeetNowRecordingModalShowing: false,
      recordingToWatchURL: '',
      recordingToWatchThumb: '',
      selectedRecordingName: '',
      recordingId: 0,
      guid: '',
      loadingRecordings: true,
      taggingUntaggingAssessorModalShowing: false,
      taggingUntaggingCandidateModalShowing: false,
      selectedRecordingIdToTag: null,
      noResults: false,
      userLanguage,
      deleteRecordingModal: false,
      spinningFlag: false,
      recId: 0,
      editRecordingNameModal: false,
      recording: [],
      alertModal: false,
      alertModalTitle: '',
      alertModalContent: '',
      recordingName: '',
      selectedRecording: { RecordingName: '' },
      shareMeetNowModalShowing: false
    }
  },
  computed: {
    recordings () {
      if (this.meetNowFilterType === 'My Recordings') return this.myMeetNow
      if (this.meetNowFilterType === 'Public Recordings') return this.publicMeetNow
      return this.archivedMeetNow
    },
    searchedRecordings () {
      if (this.search === '' || this.loadingRecordings) {
        return this.recordings
      }
      try {
        return this.recordings.filter(r => r.RecordingName.toLowerCase().includes(this.search.toLowerCase()) || r.RoomName.toLowerCase().includes(this.search.toLowerCase()))
      } catch { return this.recordings }
    },
    recordingFilterForAPICAll () {
      if (this.meetNowFilterType === 'My Recordings') return 'Private'
      if (this.meetNowFilterType === 'Public Recordings') return 'Public'
      return 'Archived'
    },
    selectedRecordingToTag () {
      if (this.selectedRecordingIdToTag != null) {
        return this.recordings.filter(r => r.RecordingId === this.selectedRecordingIdToTag)[0]
      } return {}
    }
  },
  watch: {
    meetNowFilterType () {
      this.getMeetNowRecordings()
    },
    meetNowFromRedirect: {
      handler: function () {
        if (this.meetNowFromRedirect.length > 0) {
          const recording = this.meetNowFromRedirect[0]
          this.watchRecording(recording.RecordingUrl, recording.RecordingName, recording.RecordingThumbnail, recording.RecordingId, recording.ArchiveId)
        }
      },
      immediate: true
    }
  },
  created () {
    this.getMeetNowRecordings()
  },
  methods: {
    logout,
    watchRecording (url, name, thumb, id, guid) {
      this.selectedRecordingName = name
      this.recordingToWatchURL = url
      this.recordingToWatchThumb = thumb
      this.recordingId = id
      this.guid = guid
      this.playMeetNowRecordingModalShowing = true
      try {
        this.$refs.popover.map(p => p.doClose())
      } catch {}
    },
    async updateMeetNowRecording (updateType, recordingId, remove = false) {
      // logic for making public/private, archived/nonarchived or deleting
      try {
        const res = await meetNowService.updateMeetNowRecording(updateType, recordingId)
        if (res.data !== '' && res.data !== null) {
          if (updateType === 'delete' || updateType === 'archive' || (updateType === 'public' && remove === true)) {
            this.addOrUpdateRecording({ ...res.data, RemoveFromCurrentView: true })
          } else {
            this.addOrUpdateRecording(res.data)
          }
          this.refreshMeetNowSummary = true
        }
      } catch (err) {
        this.refreshMeetNowSummary = true
      }
      this.$refs.popover.map(p => p.doClose())
    },
    openTaggingModal (recordingId, isCandidate) {
      this.selectedRecordingIdToTag = recordingId
      if (isCandidate) {
        this.taggingUntaggingCandidateModalShowing = true
      } else { this.taggingUntaggingAssessorModalShowing = true }
      this.$refs.popover.map(p => p.doClose())
    },
    share (recording) {
      this.selectedRecording = recording
      this.shareMeetNowModalShowing = true
      this.$refs.popover.map(r => r.doClose())
    },
    async getMeetNowRecordings () {
      this.noResults = false
      this.loadingRecordings = true
      this.$parent.loadingRecordings = true
      try {
        const res = await meetNowService.getMeetNowRecordings(this.recordingFilterForAPICAll)
        if (res.data != null) {
          this.setRecordings({ rooms: res.data, roomType: this.recordingFilterForAPICAll })
        } else {
          throw Error('error fetching recordings')
        }
      } catch (err) {
        this.noResults = true
        if (err.response.data.Message === 'Authorization has been denied for this request.') {
          logoutService.logout().then(() => {
            this.logout().then(() => this.$router.push('/').catch(() => { }))
          }).catch(() => {
            this.logout().then(() => this.$router.push('/').catch(() => { }))
          })
        }
      }
      this.$nextTick(() => {
        this.loadingRecordings = false
        this.$parent.loadingRecordings = false
      })
    },
    showDeleteRecordingModal (recId) {
      this.deleteRecordingModal = true
      this.recId = recId
      this.$refs.popover.map(p => p.doClose())
    },
    closeDeleteRecordingModal () {
      this.deleteRecordingModal = false
      this.recdId = 0
    },
    closeEditRecordingNameModal () {
      this.recordingName = ''
      this.editRecordingNameModal = false
    },
    showEditRecordingModal (recording) {
      this.recordingName = recording.RecordingName
      this.recording = recording
      this.editRecordingNameModal = true
      this.$refs.popover.map(p => p.doClose())
    },
    editRecordingName () {
      this.editRecordingNameModal = false
      meetNowService.updateGoMeetNowRecordingName(this.recording.RecordingId, this.recordingName).then(() => {
        this.recording.RecordingName = this.recordingName
        this.recordingName = ''
      }).catch((error) => {
        this.recordingName = ''
        this.alertModalTitle = 'Error!'
        if (error.response.data != null) {
          if (error.response.data.Message != null) {
            this.alertModalContent = error.response.data.Message
          } else {
            this.alertModalContent = error.response.data
          }
        } else {
          this.alertModalContent = 'Sorry an error has occurred - request failed!'
        }
        this.alertModal = true
      })
    },
    async deleteRecording () {
      this.spinningFlag = true
      // logic for making public/private, archived/nonarchived or deleting
      try {
        await this.updateMeetNowRecording('delete', this.recId)
        this.recId = 0
        this.deleteRecordingModal = false
        this.spinningFlag = false
        this.getMeetNowRecordings()
        this.refreshMeetNowSummary = true
      } catch (err) {
        this.recId = 0
        this.deleteRecordingModal = false
        this.spinningFlag = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

@media (min-width: 576px) {
  .meetNowRecordingsGrid {
    display: grid;
    justify-content: space-evenly;
    grid-gap: 35px;
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1200px) {
  .meetNowRecordingsGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 1500px) {
  .meetNowRecordingsGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (min-width: 1700px) {
  .meetNowRecordingsGrid {
    grid-template-columns: repeat(auto-fill, 250px);
  }
}
.borderForNoProfilePic {
  border: solid 2px #333;
}
.recordingNameWidth {
  max-width:60%;
}
@media (min-width:576px) {
  .recordingNameWidth {
    max-width:45%;
  }
}
@media (max-width: 576px) {
  .wider {
    width:80% !important;
  }
  .titleSize {
    font-size: 1rem !important;
  }
}
</style>
