<template>
  <!--questions-->
  <div class="flex-grow-1 reviewModalRHTab">
    <div style="height:fit-content;">
      <div
        v-for="question in candidateApplication.Questions"
        :key="question.QuestionId"
        class="d-flex align-items-center text-left cp py-2"
        style="width:100%;min-height:60px; height: fit-content;border-bottom:1px solid #c0c0c0"
      >
        <div class="d-flex align-items-center text-left cp mr-auto flex-grow-1" @click="selectQuestion(question.QuestionId)">
          <div
            v-if="question.Video != null"
            class="p-3"
            style="font-size:larger;color:grey"
            :class="{ 'primary-colour' : question.QuestionId === currentQuestionId && currentVideo.VideoUrl != null }"
          >
            <i class="fa fa-play" />
          </div>
          <div
            v-if="question.Video != null"
            style="color:grey"
            :class="{ 'primary-colour' : question.QuestionId === currentQuestionId && currentVideo.VideoUrl != null }"
          >
            <span style="white-space: pre-line !important; ">{{ question.QuestionText }}</span>
            <span
              v-if="question.ConvertedVideo"
              style="white-space: pre-line !important;font-size:12px;vertical-align: super;color:#555;"
              title="Video optimised"
            > o</span>
          </div>
          <div
            v-if="question.RetryData.RetryCount > 0 && question.RetryData.LastRetried"
            style="width:80px;margin-left:10px;border: solid 2px;border-radius: 30px;height:25px;background-color: var(--bg-color);margin-right:10px;"
            :title="'Last retry was made on ' + new Date(question.RetryData.LastRetried).toLocaleDateString([userLanguage]) + ' at ' + new Date(question.RetryData.LastRetried).toLocaleTimeString([userLanguage]).substring(0, 5) + '.'"
          >
            <div style="text-align:center;font-size:small;color: var(--lk-color);padding:5px;margin-top:-4px;">
              {{ question.RetryData.RetryCount }} {{ question.RetryData.RetryCount == 1 ? 'retry' : 'retries' }}
            </div>
          </div>
          <div
            v-if="question.Video == null"
            class="p-3"
            style="font-size:larger;color:grey;"
            :class="{ 'primary-colour' : question.QuestionId === currentQuestionId }"
          >
            <i class="fa fa-times" />
          </div>
          <div
            v-if="question.Video == null"
            class="twoLinesText"
            style="color:grey;"
            :class="{ 'primary-colour' : question.QuestionId === currentQuestionId }"
          >
            <span style="white-space: pre-line !important; ">{{ question.QuestionText }}</span>
          </div>
        </div>
        <mdb-popover
          v-if="modalContext != 'CANDIDATE_HISTORY_READ_ONLY'"
          v-show="question.Video != null" 
          trigger="click"
          :options="{placement: 'left'}"
          style="top:4px;"
          class="grey-text mr-3"
        >
          <div
            v-if="(userData.mid === RNYID || userData.mid === RAFID)"
            slot="body"
            style="width:200px;"
          >
            <h6 v-show="question.Video != null && userData.rle !== 'User'" style="margin-left:-10px;margin-top:-5px;padding:10px;">
              <i class="fa fa-times ml-1 grey-link" style="margin-right:17px;margin-top:4px;" /><a class="grey-link" @click="showDeleteCandidateQuestionModal(question)"> Delete/Reset Answer</a>
            </h6>
            <!-- COMMENTED OUT AS EXISTING METHOD UPLOADED THE WRONG ENTITY - guess this never worked - to come back to at a later date -->
            <!-- <h6 v-show="question.Video == null" style="margin-left:-10px;margin-top:-5px;padding:10px;">
              <i class="fa fa-upload ml-1 grey-link" style="margin-right:7px;margin-top:4px;" /><a class="grey-link" @click="showManualUploadModal(question.QuestionId)"> Manual Upload</a>
            </h6> -->
            <EmbedVideo
              v-if="question.Video != null"
              :src="question.Video.VideoUrl"
              :video-title="question.QuestionText"
              @closePopover="$refs.popover.doClose()"
            />
            <h6 v-show="question.Video != null && userData.trn" style="margin-left:-10px;margin-top:-5px;padding:10px;">
              <i class="fa fa-comment-dots ml-1 grey-link" style="margin-right:12px;margin-top:4px;" />
              <a class="grey-link" @click="getTranscription(question)"> Transcription</a>
            </h6>
          </div>
          <div
            v-else
            slot="body"
            style="width:200px;"
          >
            <h6 v-show="question.Video != null" style="margin-left:-10px;margin-top:-5px;padding:10px;">
              <i class="fa fa-times ml-1 grey-link" style="margin-right:17px;margin-top:4px;" /><a class="grey-link" @click="showDeleteCandidateQuestionModal(question)"> Delete/Reset Answer</a>
            </h6>
            <EmbedVideo
              v-if="question.Video != null"
              :src="question.Video.VideoUrl"
              :video-title="question.QuestionText"
              @closePopover="$refs.popover.doClose()"
            />
            <h6 v-show="question.Video != null && userData.trn" style="margin-left:-10px;margin-top:-5px;padding:10px;">
              <i class="fa fa-comment-dots ml-1 grey-link" style="margin-right:12px;margin-top:4px;" />
              <a class="grey-link" @click="getTranscription(question)"> Transcription</a>
            </h6>
            <div v-if="question.ConvertedVideo">
              <h6 v-if="userData.iss || userData.rle === 'Admin'|| userData.rle === 'Account Group Administrator'" style="margin-left:-10px;margin-top:-5px;padding:10px;">
                <i class="fa fa-wrench ml-1 grey-link" style="margin-right:13px;margin-top:4px;" /><a class="grey-link" @click="showRestoreVideoModal(question)"> Restore Video</a>
              </h6>
            </div>
            <div v-else>
              <h6 v-if="userData.iss || userData.rle === 'Admin'|| userData.rle === 'Account Group Administrator'" style="margin-left:-10px;margin-top:-5px;padding:10px;">
                <i class="fa fa-wrench ml-1 grey-link" style="margin-right:13px;margin-top:4px;" /><a class="grey-link" @click="showRepairVideoModal(question)"> Optimise Video</a>
              </h6>
            </div>
          </div>
          <mdb-icon
            v-if="question.QuestionId === currentQuestionId"
            slot="reference"
            title="Actions"
            class="primary-colour ellipsisButtonHover"
            style="font-size:1rem;"
            icon="ellipsis-v"
          />
          <mdb-icon
            v-else
            slot="reference"
            class="opacity ellipsisButtonHover"
            style="font-size:1rem;"
            disabled
            icon="ellipsis-v"
          />
        </mdb-popover>
        <mdb-popover
          v-else
          trigger="click"
          :options="{placement: 'left'}"
          style="top:4px;"
          class="grey-text mr-3"
        >
          <div slot="body" style="width:200px;">
            <h6 v-show="question.Video != null" style="margin-left:-10px;margin-top:-5px;padding:10px;">
              <i class="fa fa-times ml-1 grey-link" style="margin-right:7px;margin-top:4px;" /><a class="grey-link" @click="showDeleteCandidateQuestionModal(question)"> Delete/Reset Answer</a>
            </h6>
            <!-- COMMENTED OUT AS EXISTING METHOD UPLOADED THE WRONG ENTITY - guess this never worked - to come back to at a later date -->
            <!-- <h6 v-show="question.Video == null" style="margin-left:-10px;margin-top:-5px;padding:10px;">
              <i class="fa fa-upload ml-1 grey-link" style="margin-right:7px;margin-top:4px;" /><a class="grey-link" @click="showManualUploadModal(question.QuestionId)"> Manual Upload</a>
            </h6> -->
            <EmbedVideo
              v-if="question.Video != null"
              :src="question.Video.VideoUrl"
              :video-title="question.QuestionText"
              @closePopover="$refs.popover.doClose()"
            />
          </div>
        </mdb-popover>
      </div>
      <div v-if="candidateApplication.CompleteStatusType == false" style="padding:15px;height:75px;">
        <mdb-btn
          type="submit"
          class="float-right"
          size="sm"
          style="width:130px;"
          @click="resendInvite()"
        >
          <span>RESEND INVITE</span>
        </mdb-btn>
      </div>
    </div>

    <!--manual upload modal-->
    <mdb-modal
      id="manualUploadModal"
      :show="manualUploadModal"
      style="z-index:10000;"
      @close="manualUploadModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>MANUAL UPLOAD</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeightForRoles': mobileView }">
        <div class="file-field md-form form-sm text-left" style="margin-left:25px">
          <i class="fas fa-upload" style="margin-top: 10px;margin-left: -25px;font-size: 1.3rem;cursor:pointer" />
          <div
            class="btn btn-primary btn-sm float-right"
            style="margin-right:0px;"
            @click="launchManualUploadPicker"
          >
            <span>Choose file</span>
            <input
              ref="file"
              v-uploader
              type="file"
              style="display:none"
              accept="audio/mp4,video/mp4"
            >
          </div>
          <div class="file-path-wrapper" style="font-size:14px;color:#495057;margin-top:-34px;">
            <input
              class="file-path validate"
              type="text"
              placeholder="Select video"
              :value="file.name"
              readonly
            >
          </div>
        </div>
      </mdb-modal-body>
      <mdb-modal-footer v-show="!uploadSuccess" style="display:block;height:fit-content;">
        <mdb-btn
          v-show="uploadStarted === false"
          type="reset"
          class="float-left btn-outline"
          size="sm"
          style="width:100px;"
          @click.native="cancelManualUpload()"
        >
          CANCEL
        </mdb-btn>
        <mdb-btn
          v-show="!uploadStarted && !uploadSuccess"
          :disabled="fileSelected === false"
          type="submit"
          class="float-right"
          size="sm"
          style="width:100px;"
          @click="manualUpload()"
        >
          <span>UPLOAD</span>
        </mdb-btn>
        <mdb-progress
          v-if="uploadStarted"
          indeterminate
          style="margin-right:10px;margin-top:10px;"
        />
        <div
          v-if="uploadStarted"
          style="width:100%;"
          class="d-flex justify-content-end"
        >
          <mdb-btn size="sm" style="width:100px;">
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </div>
      </mdb-modal-footer>
    </mdb-modal>

    <!--transcription modal-->
    <mdb-modal
      id="transcriptionModal"
      :show="transcriptionModal"
      style="z-index:10000;"
      @close="closeTranscriptionModal()"
    >
      <mdb-modal-header>
        <mdb-modal-title>VIDEO RESPONSE TRANSCRIPTION</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeightForRoles': mobileView }">
        <div
          v-if="!question.Transcribed"
          class="my-4 grey-text"
          style="font-size:0.8rem;text-align:left;"
        >
          There is no transcription of the selected candidate's repsonse. Once processed, an email of the transcription (PDF) will be sent to you.
          <br><br>
          Note, if you have already subittmed a transcription request in the past few minutes, it may still be processing, so check you emails or come back to this modal shortly.
          <br><br>
          Do you wish to continue?
        </div>
        <div
          v-else
          class="my-4 grey-text"
          style="font-size:0.8rem;text-align:left;"
        >
          <span style="font-weight: bold;">{{ question.QuestionText }}</span>
          <br>
          <br>
          <span v-html="question.Transcription" />
          <div
            style="border-top:solid 1px #c0c0c0;margin-top: 10px;"
            @click="toggleReTranscribe()"
          >
            <mdb-input
              v-model="reTranscribe"
              type="checkbox"
              auto-complete="demo"
              label="Re-transcribe (will re-transcribe audio if you don't like existing version)?"
              style="margin-left:-20px;margin-top:30px;"
              size="sm"
            />
          </div>
          <div
            @click="toggleEmailPdf()"
          >
            <mdb-input
              v-model="emailPdf"
              type="checkbox"
              auto-complete="demo"
              label="Email PDF version?"
              style="margin-left:-20px;margin-top:10px;"
              size="sm"
            />
          </div>    
        </div>
      </mdb-modal-body>
      <mdb-modal-footer
        v-if="question.Transcribed === false"
      >
        <mdb-btn
          v-if="!transcribing"
          type="submit"
          class="float-right"
          size="sm"
          style="width:100px;"
          @click="confirmGetTranscription()"
        >
          YES
        </mdb-btn>
        <mdb-btn
          v-else
          class="float-right"
          size="sm"
          style="width:100px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
      <mdb-modal-footer
        v-else
      >
        <mdb-btn
          v-if="!transcribing"
          type="submit"
          class="float-right"
          :disabled="reTranscribe == false && emailPdf === false"
          size="sm"
          style="width:100px;"
          @click="confirmEmailTranscription()"
        >
          SUBMIT
        </mdb-btn>
        <mdb-btn
          v-else
          class="float-right"
          size="sm"
          style="width:100px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { useUserData, useStatus, useRoleCandidate } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import roleV2Service from '@/api/services/roleV2.service'
import UploadFile from '@/uploadq'
import { userLanguage } from '@/common/userLanguage'
import { RNYID, RAFID, ENVIRONMENT } from '@/common/config.js'

import EmbedVideo from '@/components/misc/embedVideo.vue'
export default {
  name: 'Questions',
  components: { EmbedVideo },
  directives: {
    uploader: {
      bind (el, binding, vnode) {
        el.addEventListener('change', e => {
          if (e.target.files[0] !== undefined) {
            vnode.context.file = e.target.files[0]
          }
        })
      }
    }
  },
  props: {
    currentQuestionId: {
      type: Number,
      default: 0
    },
    candidateApplication: {
      type: Object,
      default: () => {}
    },
    currentVideo: {
      type: Object,
      default: () => {}
    },
    currentQuestion: {
      type: Object,
      default: () => {}
    },
    modalContext: {
      type: String,
      default: ''
    }
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()
    const { mobileView } = useMobile()

    const roleCandidateStore = useRoleCandidate()
    const { confirmResendOneWayInvite } = storeToRefs(roleCandidateStore)

    return {
      authData,
      userData,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal,
      mobileView,
      confirmResendOneWayInvite,
      userLanguage,
      RNYID,
      RAFID,
      ENVIRONMENT
    }
  },
  data () {
    return {
      qid: 0,
      uploadStarted: false,
      uploadSuccess: false,
      fileSelected: false,
      file: { name: '' },
      manualUploadModal: false,
      transcriptionModal: false,
      reTranscribe: false,
      transcribing: false,
      emailPdf: false,
      question: []
    }
  },
  watch: {
    file (val) {
      if (this.file.name !== '') {
        this.fileSelected = true
      }
    }
  },
  methods: {
    toggleReTranscribe () {
      if (this.reTranscribe === true) {
        this.reTranscribe = false
      } else {
        this.reTranscribe = true
      }
    },
    toggleEmailPdf () {
      if (this.emailPdf === true) {
        this.emailPdf = false
      } else {
        this.emailPdf = true
      }
    },
    createGuid: function () {
      function _p8 (s) {
        var p = (Math.random().toString(16) + '000000000').substr(2, 8)
        return s ? '-' + p.substr(0, 4) + '-' + p.substr(4, 4) : p
      }
      return _p8() + _p8(true) + _p8(true) + _p8()
    },
    resendInvite () {
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'RESEND INVITE EMAIL',
        confirmMessage: 'Are you sure you want to resend the candidate a copy of their invitation email? Note: this will not affect any recorded videos. Also note that invitations on an expired role will not be present. Likewise the invite email expiry date will be updated based on whether they have previously completed and any retry limits put in place at role level.',
        confirmButtonMessage: 'RESEND',
        confirmButtonFunction: () => this.confirmResendInvite()
      })
    },
    async confirmResendInvite () {
      var roleWorkflowStageId =  this.candidateApplication.RoleWorkflowStageId
      try {
        await this.confirmResendOneWayInvite({ CandidateId: this.candidateApplication.CandidateId }, roleWorkflowStageId)
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.closeGenericConfirmModal()
    },
    launchManualUploadPicker () {
      this.$refs.file.click()
    },
    async manualUpload () {
      const vData = {
        userEmail: this.userData.une,
        inviteCode: this.candidateApplication.InvitationCode,
        userQuestion: this.qid,
        selectedVideo: this.createGuid() + '.mp4'
      }
      this.uploadStarted = true
      const uploadSuccess = await UploadFile.uploadVideo(1, this.file, vData)
      this.uploadStarted = false
      this.uploadSuccess = uploadSuccess
      if (uploadSuccess) {
        this.$refs.file.value = null
        this.manualUploadModal = false
        this.fileSelected = false
        this.uploadSuccess = false
        this.$emit('reloadCandidateApplication', this.candidateApplication.CandidateId)
      } else { this.showGenericErrorModal({ showing: true }) }
    },
    cancelManualUpload () {
      this.$refs.file.value = null
      this.fileSelected = false
      this.file = { name: '' }
    },
    selectQuestion (questionId) {
      this.$emit('setQuestionId', questionId)
    },
    showDeleteCandidateQuestionModal (question) {
      let confirmButtonFunction
      const videoId = question.Video.CandidateVideoId
      if (question.GoCandidateQuestionSetId > 0 && question.IsFromRandomisedQuestionSet) {
        confirmButtonFunction = () => this.resettingQuestionSetQuestionOptionModal(videoId)
      } else {
        confirmButtonFunction = () => this.confirmCandidateDeleteQuestion(videoId)
      }
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'DELETE/RESET/ANSWER',
        confirmMessage: 'Are you sure you want to delete/reset this answer? If you proceed you will be able to resend the candidate\'s invite email again from the \'RESEND INVITE\' button if you scroll to the bottom of the questions list.',
        confirmButtonMessage: 'SUBMIT',
        confirmButtonFunction: confirmButtonFunction
      })
    },
    resettingQuestionSetQuestionOptionModal (videoId) {
      this.closeGenericConfirmModal()
      this.$nextTick(() => {
        this.showGenericConfirmModal({
          showing: true,
          confirmTitle: 'RANDOMISED QUESTION',
          confirmMessage: 'Do you wish for the candidate to answer a different randomised Question from this Question Set?',
          confirmButtonMessage: 'Yes',
          confirmButtonFunction: () => this.confirmCandidateDeleteQuestion(videoId, true),
          confirmButtonMessage2: 'No',
          confirmButtonFunction2: () => this.confirmCandidateDeleteQuestion(videoId, false)
        })
      }, 500)
    },
    showRepairVideoModal (question) {
      let confirmButtonFunction
      const videoId = question.Video.CandidateVideoId
      confirmButtonFunction = () => this.confirmRepairVideo(videoId)
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'OPTIMISE VIDEO',
        confirmMessage: 'If you are not happy with the quality of this video, we can attempt to optimise it. This may also reduce the file size to assist with any lag in load times.<br /><br />To continue, press submit, but please wait until the process is complete.',
        confirmButtonMessage: 'SUBMIT',
        confirmButtonFunction: confirmButtonFunction
      })
    },
    showRestoreVideoModal (question) {
      let confirmButtonFunction
      const videoId = question.Video.CandidateVideoId
      confirmButtonFunction = () => this.confirmRestoreVideo(videoId)
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'RESTORE VIDEO',
        confirmMessage: 'Are you sure you want to restore the original video?',
        confirmButtonMessage: 'RESTORE',
        confirmButtonFunction: confirmButtonFunction
      })
    },
    async confirmCandidateDeleteQuestion (videoId, chooseAlternateQuestionSetQuestion = false) {
      try {
        await roleV2Service.deleteOneWayQuestion(videoId, chooseAlternateQuestionSetQuestion)
        this.$emit('reloadCandidateApplication', this.candidateApplication.CandidateId)
        this.$emit('reviewModalContextNeedsUpdating')
        this.closeGenericConfirmModal()
      } catch (err) {
        this.closeGenericConfirmModal()
        this.showGenericErrorModal({ showing: true })
      }
    },
    async confirmRepairVideo (videoId) {
      try {
        await roleV2Service.repairVideo(videoId)
        this.$emit('reloadCandidateApplication', this.candidateApplication.CandidateId)
        this.$emit('reviewModalContextNeedsUpdating')
        this.closeGenericConfirmModal()
      } catch (err) {
        this.closeGenericConfirmModal()
        this.showGenericErrorModal({ showing: true, errorTitle: 'OPTIMISE VIDEO', errorMessage: 'Sorry, unable to repair/re-encode video. Please contact us if you require further assistance.' })
      }
    },
    async confirmRestoreVideo (videoId) {
      try {
        await roleV2Service.restoreVideo(videoId)
        this.$emit('reloadCandidateApplication', this.candidateApplication.CandidateId)
        this.$emit('reviewModalContextNeedsUpdating')
        this.closeGenericConfirmModal()
      } catch (err) {
        this.closeGenericConfirmModal()
        this.showGenericErrorModal({ showing: true, errorTitle: 'RESTORE VIDEO', errorMessage: 'Sorry, we\'ve been unable to restore the original video. Please contact us if you require further assistance.' })
      }
    },
    showManualUploadModal (questionId) {
      this.qid = questionId
      this.manualUploadModal = true
    },
    async getTranscription(question) {
      try {
        var res = await roleV2Service.checkTranscription(question.Video.CandidateVideoId)
        this.question = res.data
        this.transcriptionModal = true
      } catch (err) { 
        //?
      }
    },
    async confirmGetTranscription() {
      this.transcribing = true
      roleV2Service.getTranscription(this.question.Video.CandidateVideoId, this.reTranscribe, true)
      setTimeout(() => {
        this.transcribing = false
        this.transcriptionModal = false
        this.emailPdf = false
        this.reTranscribe = false
      }, 30000)
    },
    async confirmEmailTranscription() {
      this.transcribing = true
      roleV2Service.getTranscription(this.question.Video.CandidateVideoId, this.reTranscribe, this.emailPdf)
      setTimeout(() => {
        this.transcribing = false
        this.transcriptionModal = false
        this.emailPdf = false
        this.reTranscribe = false
      }, 30000)
    },
    closeTranscriptionModal () {
      this.transcribing = false
      this.transcriptionModal = false
      this.includeRaw = false
      this.reTranscribe = false
    }
  }
}
</script>

<style lang="scss" scoped>
.opacity {
  opacity: .8
}

.twoLinesText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: N;
  line-height: 18px;
  max-height: 36px;
}
</style>
