<template>
  <div
    style="width: 100%;"
    class="d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
      <FontAwesomeIcon
        :icon="iconCode" />
      <div
        class="mx-2 d-flex flex-column"
        style="font-size: clamp(0.7rem, 2vw, 0.9rem)">
        <div class="font-weight-bold">
          {{date}}
        </div>
        <div>{{monthAndYear}}</div>
      </div>
      <div
        class="mx-2 d-flex flex-column text-left"
        style="font-size: clamp(0.9rem, 2vw, 1.2rem)">
        <div class="font-weight-bold">
            {{historyTypeText}}
        </div>
        <div
          style="font-size: clamp(0.7rem, 2vw, 0.9rem)">
          {{extraDetailText}}
        </div>
      </div>
    </div>
    <slot class="ml-auto" name="button"></slot>
  </div>
</template>

<script>
import { returnDateWithSuffix, returnShortMonthAndYear } from '@/utils/dateFormatting.js'
import { ref } from 'vue-demi'

export default {
  name: 'HistoryItemDetails',
  setup (props) {
    const date = ref(returnDateWithSuffix(props.dateAdded))
    const monthAndYear = ref(returnShortMonthAndYear(props.dateAdded))

    return { date, monthAndYear }
  },
  props: {
    extraDetailText: {
      type: String,
      default: ''
    },
    historyTypeText: {
      type: String,
      default: ''
    },
    iconCode: {
      type: String,
      default: ''
    },
    dateAdded: {
      type: String,
      default: ''
    }
  }
}
</script>
