import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

export const useStatus = defineStore('statusStore', () => {
  const error = ref({ status: false, message: ''})
  const loading = ref({ status: false, message: ''})

  const genericErrorModal = ref({
    errorTitle: 'Error',
    errorMessage: 'Something went wrong.',
    showing: false
  })

  const genericConfirmModal = ref({
    showing: false,
    confirmTitle: '',
    confirmMessage: '',
    confirmButtonMessage: '',
    confirmButtonMessage2: '',
    confirmButtonFunction: () => { return 'emptyanon' },
    confirmButtonFunction2: () => { return 'emptyanon' }
  })

  // init + reset state

  const setError = ({ status, message }) => {
    error.value = { status, message }
    setTimeout(() => { error.value = { status: false, message: '' } }, 3000)
  }

  const setLoading = ({ isLoading, message}) =>  loading.value = { isLoading, message }

  // Been renamed from original
  const showGenericErrorModal = (payload) => {
    if (payload && payload.showing !== undefined) {
      genericErrorModal.value.showing = payload.showing
    } else {
      genericErrorModal.value.showing = true
    }
    if (payload && payload.errorTitle !== undefined) {
      genericErrorModal.value.errorTitle = payload.errorTitle
    } else {
      genericErrorModal.value.errorTitle = 'Error'
    }
    if (payload && payload.errorMessage !== undefined) {
      genericErrorModal.value.errorMessage = payload.errorMessage
    } else {
      genericErrorModal.value.errorMessage = 'Something went wrong.'
    }
  }

  const closeGenericErrorModal = () => {
    genericErrorModal.value = {
      errorTitle: 'Error',
      errorMessage: 'Something went wrong.',
      showing: false
    }
  }

  const showGenericConfirmModal = (payload) => {
    if (payload.showing !== undefined) {
      genericConfirmModal.value.showing = payload.showing
    } else {
      genericConfirmModal.value.showing = false
    }
    if (payload.confirmTitle !== undefined) {
      genericConfirmModal.value.confirmTitle = payload.confirmTitle
    } else {
      genericConfirmModal.value.confirmTitle = ''
    }
    if (payload.confirmMessage !== undefined) {
      genericConfirmModal.value.confirmMessage = payload.confirmMessage
    } else {
      genericConfirmModal.value.confirmMessage = ''
    }
    if (payload.confirmButtonMessage !== undefined) {
      genericConfirmModal.value.confirmButtonMessage = payload.confirmButtonMessage
    } else {
      genericConfirmModal.value.confirmButtonMessage = ''
    }
    if (payload.confirmButtonFunction !== undefined) {
      genericConfirmModal.value.confirmButtonFunction = payload.confirmButtonFunction
    } else {
      genericConfirmModal.value.confirmButtonFunction = () => { return 'emptyanon' }
    }
    if (payload.confirmButtonMessage2 !== undefined) {
      genericConfirmModal.value.confirmButtonMessage2 = payload.confirmButtonMessage2
    } else {
      genericConfirmModal.value.confirmButtonMessage2 = ''
    }
    if (payload.confirmButtonFunction2 !== undefined) {
      genericConfirmModal.value.confirmButtonFunction2 = payload.confirmButtonFunction2
    } else {
      genericConfirmModal.value.confirmButtonFunction2 = () => { return 'emptyanon' }
    }
  }

  const closeGenericConfirmModal = () => {
    genericConfirmModal.value = {
      showing: false,
      confirmTitle: '',
      confirmMessage: '',
      confirmButtonMessage: '',
      confirmButtonMessage2: '',
      confirmButtonFunction: () => { return 'emptyanon' },
      confirmButtonFunction2: () => { return 'emptyanon' }
    }
  }


  // SINGLE INSERT TEXT MODAL
  const insertTextGenericModal = ref({
    text: '',
    showing: false,
    modalTitle: 'INSERT TEXT'
  })
  const openInsertTextGenericModal = (modalTitle = 'INSERT TEXT', modalText = '') => {
    insertTextGenericModal.value.text = modalText
    insertTextGenericModal.value.showing = true
    insertTextGenericModal.value.modalTitle = modalTitle
  }
  const closeInsertTextGenericModal = () => {
    insertTextGenericModal.value.text = ''
    insertTextGenericModal.value.showing = false
    insertTextGenericModal.value.modalTitle = 'INSERT TEXT'
  }

  // DELETE
  const deleteCustomFormFieldModal = ref({
    id: 0,
    showing: false,
  })
  const openDeleteCustomFormFieldModal = (id = '') => {
    deleteCustomFormFieldModal.value.id = id
    deleteCustomFormFieldModal.value.showing = true
  }
  const closeDeleteCustomFormFieldModal = () => {
    //deleteCustomFormFieldModal.value.id = 0
    deleteCustomFormFieldModal.value.showing = false
  }

  // MULTIPLE TEXT BOXES MODAL
  const insertMultipleTextBoxGenericModal = ({
    showing: false,
    modalTitle: 'Insert Options'
  })
  const textValues = ref({})
  // each object in the array below needs to following structure  // { inputLabel: 'Stage Name', required: true, iconCode: 'fa-solid fa-users', textValuesKeyName: 'stageName' },
  const multipleTextBoxesInputArray = ref([])
  const insertMultipleTextBoxPreventConfirmation = computed(() => {
    let invalidCount = 0
    multipleTextBoxesInputArray.value.forEach(input => {
      if (input.required == true && !textValues.value[`${input.textValuesKeyName}`])
      invalidCount ++
  })
  return invalidCount > 0
})

  return {
    closeGenericConfirmModal,
    closeGenericErrorModal,
    error,
    genericConfirmModal,
    genericErrorModal,
    loading,
    setError,
    setLoading,
    showGenericConfirmModal,
    showGenericErrorModal,
    insertTextGenericModal,
    openInsertTextGenericModal,
    deleteCustomFormFieldModal,
    openDeleteCustomFormFieldModal,
    closeInsertTextGenericModal,
    closeDeleteCustomFormFieldModal,
    multipleTextBoxesInputArray,
    textValues,
    insertMultipleTextBoxPreventConfirmation,
    insertMultipleTextBoxGenericModal
  }
})
