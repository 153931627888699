<template>
  <div>
    <div class="d-flex justify-content-center border-center mt-3" style="justify-content: space-between !important;">
      <CircleNavigationItem
        v-for="(nav, i) in roleSetupNavigationItems"
        :key="i"
        :query-to-match="$route.query.page"
        :nav-item-count="roleSetupNavigationItems.length"
        :nav-item="nav"
        @clicked="handleButtonNavigation(nav.Step)"
      />
    </div>
    <SlideFade>
      <AboutThisRole v-if="queryMatches('about_this_role')" />

      <InvitationOptions v-else-if="queryMatches('invitation_options')" />

      <BrandingOptions v-else-if="queryMatches('branding_options')" />

      <MaskingOptions v-else-if="queryMatches('masking_options')" />
    </SlideFade>
  </div>
</template>

<script>
import AboutThisRole from './aboutThisRole.vue'
import BrandingOptions from './brandingOptions.vue'
import InvitationOptions from './InvitationOptions/invitationOptions.vue'
import MaskingOptions from './maskingOptions.vue'
import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'
import CircleNavigationItem from '@/components/misc/circleNavigationItems.vue'
import { useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router/composables'

export default {
  name: 'RoleSetupContainer',
  components: { AboutThisRole, BrandingOptions, InvitationOptions, MaskingOptions, SlideFade, CircleNavigationItem },
  setup () {

    const workflowStore = useWorkflow()
    const { getRoleWithStages } = useWorkflow()
    const { role } = storeToRefs(workflowStore)

    const route = useRoute()
    // for refresh
    if (Number(route.params.jobId) != role.value.JobId) getRoleWithStages(route.params.jobId)

    return { role }
  },
  data () {
    return {
      saveRequired: false,
      buttonClick: false
    }
  },
  methods: {
    queryMatches (query) {
      return this.$route.query.page === query
    },
    goToOptionsPage (query) {
      this.$router.push({ path: `/roles/add_edit/role_setup/${this.role.JobId}`, query: { page: query } }).catch(() => { })
    },
    handleButtonNavigation (page) {
      if (page === 1) {
        if (this.role.Step >= 0) {
          this.goToOptionsPage('about_this_role')
        }
      } else if (page === 2) {
        if (this.role.Step >= 1 && this.role.JobId) {
          this.goToOptionsPage('invitation_options')
        }
      } else if (page === 3) {
        if (this.role.Step >= 2) {
          this.goToOptionsPage('branding_options')
        }
      } else if (page === 4) {
        if (this.role.Step >= 3) {
          this.goToOptionsPage('masking_options')
        }
      }
    }
  },
  computed: {
    currentStep () {
      return this.role.Step
    },
    roleSetupNavigationItems () {
      return [
        { Step: 1, Query: 'about_this_role', Text: 'About This Role', ActualStep: this.currentStep },
        { Step: 2, Query: 'invitation_options', Text: 'Invitation Options', ActualStep: this.currentStep },
        { Step: 3, Query: 'branding_options', Text: 'Branding Options', ActualStep: this.currentStep },
        { Step: 4, Query: 'masking_options', Text: 'Masking Options', ActualStep: this.currentStep }
      ]
    }
  },
  watch: {
    '$route.query.page' (to) {
      if (to === 'about_this_role' || to === undefined) {
        this.$emit('setStep', 1)
      } else if (to === 'invitation_options') {
        this.$emit('setStep', 2)
      } else if (to === 'branding_options') {
        this.$emit('setStep', 3)
      } else if (to === 'masking_options') {
        this.$emit('setStep', 4)
      }
    }
  }
}
</script>
