<template>
  <div class="d-flex flex-column text-black rounded text-left" style="overflow-y:auto;overflow-x:hidden;padding:25px;">
    <div
      v-if="emailTypeDropdown.length > 0"
      class="d-flex align-items-center justify-content-between mb-3"
      :style="{ 'margin-bottom': selectedEmailTypeDropdown == 0 ? '300px !important': '0' }"
    >
      <div>
        <div class="font-weight-bold pt-4 flex-grow-0 primary-colour">
          Email Customisation
        </div>
        <div
          style="font-size: clamp(0.8rem, 2vw, 0.9rem)"
          class="dark-grey-text py-3 mr-2"
        >
          Select an email type from the dropdown to begin customisation. You can select editable text areas with a mouse click, and add dynamic text through the <span class="font-weight-bold">'MERGE TAGS'</span> option.</div>
      </div>
      <div
        class="grey-background px-3 d-flex justify-content-between"
        style="width: 600px; max-width: 50%; height: 80px; "
      >
        <!-- DROPDOWN -->
        <div
          class="md-form m-0 flex-1"
        >
          <!-- <i class="fas fa-envelope prefix" style="margin-top: 25px;font-size: 1.3rem;" /> -->
          <mdb-select
            v-model="emailTypeDropdown"
            auto-complete="pat"
            placeholder="None"
            label="Select Email to Customise"
            style="font-size: 0.9rem; height: fit-content "
            size="sm"
            search
          />
        </div>
        <!-- EXPORT BUTTON -->
        <mdb-btn
          class="btn px-4 py-2 my-auto ml-4"
          size="lg"
          :disabled="loadingSavedTemplate || selectedEmailTypeDropdown == 0 || !emailSubject"
          style="height: 40px"
          @click="exportHtml()"
        >
          <i v-if="loadingSavedTemplate" class="fa fa-spin fa-spinner" />
          <span v-else>SAVE</span>
        </mdb-btn>
      </div>
    </div>

    <div
      v-else
      class="d-flex align-items-center justify-content-between mb-3"
      :style="{ 'margin-bottom': selectedEmailTypeDropdown == 0 ? '300px !important': '0' }"
    >
      <div>
        <div class="font-weight-bold pt-4 flex-grow-0 primary-colour">
          Email Customisation
        </div>
        <div
          style="font-size: clamp(0.8rem, 2vw, 0.9rem)"
          class="dark-grey-text py-3 mr-2"
        >
          Sorry, there are no available templates to customise.
        </div>
      </div>
    </div>
    <div
      v-if="selectedEmailTypeDropdown"
      class="d-flex flex-column"
      style="margin-top: 10px;"
    >
      <div class="md-form form-sm text-left">
        <div
          style="position: relative; max-width: 100%"
          class="d-flex align-items-center gap-5"
        >
          <span
            class="dark-grey-text font-small"
            style="position: absolute; top: -21px;"
          >
            <i class="fas fa-envelope mr-2" style="font-size: 1rem;" />EMAIL SUBJECT
          </span>
          <EditableContent
            style="max-width: 80%; overflow: hidden"
            class="w-auto flex-grow-1" 
            :initial="emailSubject"
            @updateContent="emailSubject = $event"
          />
          <mdb-select
            v-model="subjectLineDropdown"
            placeholder="None"
            label="Email Subject Variables"
            class="flex-grow-1"
            style="font-size: 0.9rem; width: fit-content; height: 40px; margin-top: -40px; max-width: 200px; min-height: fit-content"
            size="sm"
          />
        </div>
      </div>
      <!-- <div class="d-flex align-items-center">
        <mdb-btn
          class="btn"
          size="md"
          style="height: 40px; width: 120px;"
          @click="preview('desktop')"
        >
          <span>DESKTOP</span>
        </mdb-btn>
        <mdb-btn
          class="btn"
          size="md"
          style="height: 40px; width: 120px;"
          @click="preview('mobile')"
        >
          <span>MOBILE</span>
        </mdb-btn>
        <mdb-btn
          v-if="showingPreview"
          class="btn"
          size="md"
          style="height: 40px; width: 120px;"
          @click="preview('hide')"
        >
          <span>HIDE</span>
        </mdb-btn>
      </div> -->
      <div class="fitWidth">
        <EmailEditor
          ref="emailEditor"
          :key="selectedEmailTypeDropdown"
          style="height: 50vh; border: 2px #c0c0c0 solid"
          :appearance="appearance"
          :min-height="minHeight"
          :locale="locale"
          :tools="tools"
          :options="options"
          v-on:load="editorLoaded" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useUserData, useCustomEmails } from '@/pinia'
import { storeToRefs } from 'pinia'
import { EmailEditor } from 'vue-email-editor';
import { computed, ref, watch } from 'vue-demi';
import EditableContent from './editableContent.vue'

export default {
  name: 'EmailCustomisation',
  components: {
    EmailEditor, EditableContent
  },
  setup () {
    const userStore = useUserData()
    const { userData, authData } = storeToRefs(userStore)
    const customEmailStore = useCustomEmails()
    const { saveCustomEmail, getCustomEmail, savedEmailTemplate, replaceFooterContentWithVariables, wrapButtonContentWithATag, subjectLineVariableWrapper, replaceSubjectLineVariablesWithValues, replaceValuesWithSubjectLineVariables } = useCustomEmails()
    const { selectedEmailTemplate, selectedEmailTypeDropdown, emailTypeDropdown, currentMergeTags } = storeToRefs(customEmailStore)

    emailTypeDropdown.value = emailTypeDropdown.value.map(s => ({...s, selected: false}))

    const minHeight = ref("620px");
    const locale = ref("en");
    const tools = ref({
      // disable image tool
      image: { enabled: false, },
      html: { enabled: false, },
      button: { enabled: false, },
      menu: { enabled: false, },
      heading: { enabled: false, },
      divider: { enabled: false, },
      columns: { enabled: false, },
      text: { enabled: false, },
    });
    const options = computed(() => {
      return {
        features: {
          undoRedo: true,
          audit: false,
          preview: true,
          textEditor: { cleanPaste: false, emojis: false },
        },
        mergeTagsConfig: {
          sort: false
        },
        mergeTags: { ...currentMergeTags.value }
      }
    })
    const appearance = ref({
      theme: "white",
      panels: {
        enabled: false,
        tools: {
          visible: false,
          dock: "right",
          collapsible: false,
        }
      },
    });

    const emailEditor = ref(null)
    const emailSubject = ref('')

    const selectedEmailTemplateLocal = ref([])
    watch(selectedEmailTemplate, () => selectedEmailTemplateLocal.value = [...selectedEmailTemplate.value])

    const editorLoaded = () => {
      var tempDesign = {
        counters: {
          u_row: 1,
          u_column: 1,
          u_content_text: 2,
        },
        body: {
          rows: [
            ...selectedEmailTemplateLocal.value
          ],
          values: {
            textColor: "#555",
            backgroundColor: "#fff",
            backgroundImage: {
              url: "",
              fullWidth: true,
              repeat: false,
              center: true,
              cover: false,
            },
            contentWidth: "700px",
            contentAlign: "center",
            fontFamily: {
              label: "Raleway",
              value: "Arial, Helvetica, sans-serif",
            },
            preheaderText: "",
            linkStyle: {
              body: true,
              linkColor: "red",
              linkHoverColor: "#0000ee",
              linkUnderline: true,
              linkHoverUnderline: true,
              inherit: true,
            },
            _meta: {
              htmlID: "u_body",
              htmlClassNames: "u_body",
            },
          },
        },
        schemaVersion: 6,
      }
      emailEditor.value?.editor.loadDesign(tempDesign);
    }

    const loadingSavedTemplate = ref(false)
    watch(selectedEmailTypeDropdown, async () => {
      emailSubject.value = ''
      loadingSavedTemplate.value = true
      editorLoaded()
      const res = await getCustomEmail({ EmailType: selectedEmailTypeDropdown.value })
      if (res.data) {
        emailSubject.value = replaceValuesWithSubjectLineVariables(res.data.Subject)
        selectedEmailTemplateLocal.value = savedEmailTemplate(res.data.Html)
        editorLoaded()
      }
      loadingSavedTemplate.value = false
      addSelectionListener()
      setSubjectLineDropdownOptions()
    })

    const exportHtml = () => {
      emailEditor.value.editor.exportHtml(async (data) => {
        var html = replaceFooterContentWithVariables(data.html)
        html = wrapButtonContentWithATag(html)
        const JSON = {
          EmailType: selectedEmailTypeDropdown.value,
          Html: html,
          Subject: replaceSubjectLineVariablesWithValues(emailSubject.value)
        }
        loadingSavedTemplate.value = true
        await saveCustomEmail(JSON)
        loadingSavedTemplate.value = false
      })
    }

    const showingPreview = ref(false)
    const preview = type => {
      if (type == 'hide') {
        emailEditor.value.editor.hidePreview()
        showingPreview.value = false
      } else {
        emailEditor.value.editor.showPreview(type)
        showingPreview.value = true
      }
    }


    // SUBJECT LINE
    const subjectLineDropdown = ref([])
    const setSubjectLineDropdownOptions = () => {
      subjectLineDropdown.value = Object.values(currentMergeTags.value).map(({ text, value }) => ({ text, value }))
    }
    const selectedDropdownVariableToAdd = computed(() => {
      try {
        return subjectLineDropdown.value.filter(s => s.selected)[0].text
      } catch { return null }
    })
    const selectedDropdownVariableToAddHTML = computed(() => subjectLineVariableWrapper(selectedDropdownVariableToAdd.value))

    const savedRange = ref(null)
    const saveSelection = () => {
      const selection = window.getSelection()
      if (selection.rangeCount > 0) {
        savedRange.value = selection.getRangeAt(0).cloneRange()
      }
    }
    const restoreSelection = () => {
      const selection = window.getSelection()
      if (savedRange.value) {
        selection.removeAllRanges()
        selection.addRange(savedRange.value)
      } else {
        setCursorToEnd()
      }
    }
    const setCursorToEnd = () => {
      const el = document.getElementById('editable')
      const range = document.createRange()
      const selection = window.getSelection()
      range.selectNodeContents(el)
      range.collapse(false)
      selection.removeAllRanges()
      selection.addRange(range)
    }
    const addSubjectLineVariable = () => {
      restoreSelection()
      const selection = window.getSelection()
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0)
        const commonAncestor = range.commonAncestorContainer

        if (commonAncestor.id === 'editable' || (commonAncestor.parentElement && commonAncestor.parentElement.id === 'editable')) {
          const tempDiv = document.createElement("div")
          tempDiv.innerHTML = selectedDropdownVariableToAddHTML.value
          const fragment = document.createDocumentFragment()
          let node
          let lastNode
          while ((node = tempDiv.firstChild)) {
            lastNode = fragment.appendChild(node)
          }
          range.deleteContents()
          range.insertNode(fragment)

          if (lastNode) {
            const newRange = document.createRange()
            newRange.setStartAfter(lastNode)
            newRange.setEndAfter(lastNode)
            selection.removeAllRanges()
            selection.addRange(newRange)
          }
        }
      }
    }
    const addSelectionListener = () => {
      const editableDiv = document.getElementById('editable')
      editableDiv.addEventListener('focus', saveSelection)
      editableDiv.addEventListener('input', saveSelection)
      editableDiv.addEventListener('click', saveSelection)
    }
    watch(selectedDropdownVariableToAdd, () => {
      addSubjectLineVariable()
    })


    return {
      userData,
      authData,
      editorLoaded,
      emailEditor,
      minHeight,
      locale,
      tools,
      options,
      appearance,
      emailTypeDropdown,
      selectedEmailTypeDropdown,
      emailSubject,
      loadingSavedTemplate,
      exportHtml,
      selectedDropdownVariableToAdd,
      subjectLineDropdown,
      selectedDropdownVariableToAddHTML,
      preview,
      showingPreview
    }
  }
}
</script>

<style>
.blockbuilder-preferences, .hygfTb {
  display: none !important;
  opacity: 0 !important;
}
</style>