<template>
  <mdb-modal
    id="createRoomModalShowing"
    :show="createRoomModalShowing"
    @close="$emit('createRoomModalShowing',false)"
  >
    <mdb-modal-header>
      <span class="font-weight-bold py-2">CREATE MEETING ROOM</span>
    </mdb-modal-header>
    <mdb-modal-body style="padding:25px 40px !important; height:fit-content !important; overflow-y:auto !important;">
      <div class="d-flex align-items-center" style="height:40px">
        <FontAwesomeIcon icon="fa-solid fa-pen-line" class="d-inline mr-3" />
        <mdb-input
          v-model="roomName"
          type="text"
          label="Name of the Room"
          size="sm"
          class="m-0"
          maxlength="30"
          style="height:40px;width:100%;"
        />
      </div>
      <div class="d-flex align-items-center">
        <FontAwesomeIcon
          icon="fa-solid fa-video"
          class="d-inline mr-3"
          style="transform:translateY(20px)"
        />
        <mdb-select
          v-model="roomTerminology"
          label="Room Terminology"
          size="sm"
          class="d-inline m-0"
          style="height:50px;width:100%;"
        />
      </div>
      <div class="d-flex align-items-center">
        <FontAwesomeIcon
          icon="fa-solid fa-video"
          class="d-inline mr-3"
          style="transform:translateY(20px)"
        />
        <mdb-select
          v-model="roomBrand"
          label="Room Brand"
          size="sm"
          class="d-inline m-0"
          style="height:50px;width:100%;"
        />
      </div>
      <div class="d-flex align-items-start mt-4" style="height:130px;">
        <FontAwesomeIcon
          icon="fa-solid fa-circle-question"
          class="d-inline mr-3"
          style="transform:translateY(25px)"
        />
        <mdb-input
          v-model="lobbySpecificInfo"
          style="width:100%;"
          label="Lobby Specific Information"
          type="textarea"
          auto-complete="sum"
          :rows="5"
          size="sm"
          maxlength="500"
          aria-label="Lobby Specific Info"
        />
      </div>
      <div class="d-flex align-items-center mt-3">
        <FontAwesomeIcon
          v-if="isPublic"
          icon="fa-solid fa-bullhorn"
          class="d-inline mr-3"
        />
        <FontAwesomeIcon
          v-if="!isPublic"
          icon="fa-solid fa-lock-keyhole"
          class="d-inline mr-3"
        />
        <mdb-switch
          v-model="isPublic"
          style="transform:scale(0.9)"
          off-label=""
          on-label=""
          class="m-0 mt-1 mr-2"
        />
        <div style="font-size:0.8rem;color:#757575">
          {{ !isPublic ? "Room is Private" : "Room is Public" }}
        </div>
      </div>
    </mdb-modal-body>
    <mdb-modal-footer class="justify-content-end">
      <mdb-btn
        v-show="!buttonModalClick"
        :disabled="disableCreateRoom"
        size="sm"
        style="width:100px;"
        @click.native="createRoom()"
      >
        CREATE
      </mdb-btn>
      <mdb-btn
        v-show="buttonModalClick"
        size="sm"
        style="width:100px;"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import { useUserData, useMeetNow } from '@/pinia'
import { storeToRefs } from 'pinia'
import accountService from '@/api/services/account.service'
import meetNowService from '@/api/services/meetnow.service'

export default {
  name: 'CreateRoomModal',
  props: {
    createRoomModalShowing: Boolean
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const meetNowStore = useMeetNow()
    const { addOrUpdateRoom } = useMeetNow()
    const { meetNowFilterType, refreshMeetNowSummary } = storeToRefs(meetNowStore)

    return {
      authData,
      userData,
      meetNowFilterType,
      addOrUpdateRoom,
      refreshMeetNowSummary
    }
  },
  data () {
    return {
      buttonModalClick: false,
      isPublic: false,
      brandsLoading: true,
      lobbySpecificInfo: '',
      roomBrand: [],
      roomName: '',
      roomTerminology: [
        { value: 'Online Meeting Room', text: 'Online Meeting Room', selected: true },
        { value: 'Video Interview', text: 'Video Interview', selected: false },
        { value: 'Online Assessment Center', text: 'Online Assessment Center', selected: false },
        { value: 'Video Conference Call', text: 'Video Conference Call', selected: false },
        { value: 'Board Room', text: 'Board Room', selected: false }
      ]
    }
  },
  computed: {
    disableCreateRoom () {
      return this.roomBrand.length === 0 || this.roomName === ''
    }
  },
  watch: {
    createRoomModalShowing () {
      if (this.createRoomModalShowing) {
        this.getBranding()
      }
    }
  },
  created () {
    this.getBranding()
  },
  methods: {
    async createRoom () {
      this.buttonModalClick = true
      const selectedbrandId = this.roomBrand.filter(br => br.selected)[0].value
      const roomTerminology = this.roomTerminology.filter(r => r.selected)[0].value
      const formData = new FormData()
      formData.append('roomTerminology', roomTerminology)
      formData.append('selectedbrandId', selectedbrandId)
      formData.append('roomName', this.roomName)
      formData.append('lobbySpecificInfo', this.lobbySpecificInfo)
      formData.append('isPublic', this.isPublic)
      try {
        const res = await meetNowService.createMeetingRoom(formData)
        this.$emit('createRoomModalShowing')
        this.addOrUpdateRoom(res.data)
        this.refreshMeetNowSummary = true
        this.roomName = ''
        this.lobbySpecificInfo = ''
        this.isPublic = false
        this.roomTerminology = [
          { value: 'Online Meeting Room', text: 'Online Meeting Room', selected: true },
          { value: 'Video Interview', text: 'Video Interview', selected: false },
          { value: 'Online Assessment Center', text: 'Online Assessment Center', selected: false },
          { value: 'Video Conference Call', text: 'Video Conference Call', selected: false },
          { value: 'Board Room', text: 'Board Room', selected: false }
        ]
      } catch (err) { 
        //?
      }
      this.buttonModalClick = false
    },
    async getBranding () {
      try {
        const res = await accountService.getBranding()
        this.roomBrand = res.data.map(br => br.Default === true ? { value: br.BrandingId, text: br.Name, selected: true } : { value: br.BrandingId, text: br.Name, selected: false })
      } catch (err) {
        //?
      }
      this.brandsLoading = false
    }
  }
}
</script>

<style>
.listViewSelected {
  transition: ease 0.5s;
}
.dropdown-content {
  background-color: #fff !important;
}
</style>

<style lang="css" scoped>
.meetNowContentHeight {
  height:calc(100vh - 250px)
}
@media (min-width: 768px) {
  .meetNowContentHeight {
    height:calc(100vh - 200px)
  }
}
</style>

<style lang="scss">
@media (max-width: 992px) {
  .moveCreateRoom {
    transform: translateX(15px)
  }
}
</style>
