<template>
  <!--comments-->
  <div class="flex-grow-1 reviewModalRHTab">
    <div class="grey-text">
      <p
        style="padding:10px;font-size:small;"
        class="grey-text font-weight-bold"
      >
        <span
          style="cursor:pointer;"
          @click="commentModal = true"
        >
          <i class="fa fa-plus-circle" />
          Add comment:
        </span>
      </p>
      <Note
        v-for="(note,index) in notes"
        :id="note.NotesId"
        :key="index"
        :author="note.AddedBy"
        :date="note.DateAdded"
        :email="note.AddedByEmail"
        :notes="note.QuestionNotes"
        :picture="note.ProfilePicture"
        :user-language="userLanguage"
        :account-name="note.AccountName"
        @deleteModal="showDeleteCommentModal"
      />
    </div>
    <!--comment modal-->
    <mdb-modal
      id="commentModal"
      :show="commentModal"
      style="z-index:10000;"
      @close="commentModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>ADD COMMENT</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeightForRoles': mobileView }">
        <div class="md-form form-sm text-left">
          <i class="fas fa-comment prefix float-left" style="margin-top: -5px;font-size: 1.3rem;" />
          <div
            class="grey-text"
            style="margin-left: 26px;margin-bottom: -20px;margin-top:-25px;"
            size="sm"
          >
            Add comment:
          </div>
          <mdb-input
            v-model="comment"
            type="textarea"
            :rows="7"
            size="sm"
            style="margin-bottom:-25px;"
          />
        </div>
      </mdb-modal-body>
      <mdb-modal-footer style="display:block;">
        <mdb-btn
          type="reset"
          class="float-left btn-outline"
          size="sm"
          style="width:100px;"
          @click="cancelAddComment()"
        >
          CANCEL
        </mdb-btn>
        <mdb-btn
          v-show="!waitingUpdate"
          type="submit"
          :disabled="(comment === null || comment === '')"
          class="float-right"
          size="sm"
          style="width:100px;"
          @click="addVCVSpotlightComment()"
        >
          <span>SAVE</span>
        </mdb-btn>
        <mdb-btn
          v-show="waitingUpdate"
          class="float-right"
          size="sm"
          style="width:100px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'

import clientPortalService from '@/api/services/clientPortal.service'
import Note from '@/components/misc/Notes/note'

export default {
  name: 'VCVComments',
  components: { Note },
  props: {
    candidateId: {
      type: Number,
      default: 0
    },
    currentQuestionId: {
      type: Number,
      default: 0
    },
    userLanguage: {
      type: String,
      default: 'en-GB'
    }
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()
    const { mobileView } = useMobile()

    return {
      authData,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal,
      mobileView
    }
  },
  data () {
    return {
      commentModal: false,
      waitingUpdate: false,
      comment: '',
      notes: []
    }
  },
  computed: {
    questionIdToUse () {
      return this.currentQuestionId
    }
  },
  watch: {
    currentQuestionId () {
      this.notes = []
      this.getVCVSpotlightComments()
    }
  },
  mounted () {
    this.getVCVSpotlightComments()
  },
  methods: {
    async addVCVSpotlightComment () {
      try {
        this.waitingUpdate = true
        var formData = new FormData()
        formData.append('ClientId', 0)
        formData.append('CandidateId', this.candidateId)
        formData.append('QuestionId', this.currentQuestionId)
        formData.append('comment', this.comment)
        await clientPortalService.addVCVSpotlightComment(formData)
        this.getVCVSpotlightComments()
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.waitingUpdate = false
      this.commentModal = false
      this.comment = ''
    },
    async getVCVSpotlightComments () {
      try {
        this.waitingUpdate = true
        const response = await clientPortalService.getVCVSpotlightComments(0, this.currentQuestionId, this.candidateId)
        this.notes = response.data
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.waitingUpdate = false
      this.commentModal = false
      this.comment = ''
    },
    cancelAddComment () {
      this.comment = ''
      this.waitingUpdate = false
      this.commentModal = false
    },
    showDeleteCommentModal (commentId) {
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'DELETE COMMENT',
        confirmMessage: 'Are you sure you want to delete this comment?',
        confirmButtonMessage: 'DELETE',
        confirmButtonFunction: () => this.confirmDeleteComment(commentId)
      })
    },
    async confirmDeleteComment (commentId) {
      this.waitingUpdate = true
      try {
        await clientPortalService.deleteVCVComment(commentId)
        this.getVCVSpotlightComments()
        this.closeGenericConfirmModal()
      } catch (err) {
        this.closeGenericConfirmModal()
        this.showGenericErrorModal({ showing: true })
      }
      this.waitingUpdate = false
    }
  }
}
</script>
