import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useVideoIntro = defineStore('videoIntroStore', () => {
  const videoIntroFilterType = ref('')
  const myIntros = ref([])
  const archivedIntros = ref([])

  // reset state
  // set variable

  const addOrUpdateIntro = (payload) => {
    let recordings = []
    if (videoIntroFilterType.value === 'My Shine Intros') {
      recordings = myIntros.value
    } else if (videoIntroFilterType.value === 'Archived Shine Intros') {
      // must be archived recordings
      recordings = archivedIntros.value
    }
    const index = recordings.findIndex(r => r.GoVideoIntroId === payload.GoVideoIntroId)
    if (index !== -1 && payload.RemoveFromCurrentView) {
      // if removing instance from current view
      recordings.splice(index, 1)
    } else if (index !== -1) {
      // replacing instance in current view
      recordings.splice(index, 1, payload)
    } else if (index === -1) {
      // adding
      recordings.unshift(payload)
    }
  }

  const switchArchived = (payload) => {
    if (!payload.Archived) {
      myIntros.value.splice(0, 0, payload)
      archivedIntros.value = archivedIntros.value.filter(i => i.GoVideoIntroId !== payload.GoVideoIntroId)
    } else {
      archivedIntros.value.splice(0, 0, payload)
      myIntros.value = myIntros.value.filter(i => i.GoVideoIntroId !== payload.GoVideoIntroId)
    }
  }


  return {
    addOrUpdateIntro,
    archivedIntros,
    myIntros,
    switchArchived,
    videoIntroFilterType
  }
})
