<template>
  <div class="px-3 px-md-5" style="text-align:left;padding:2px;margin-top:50px;">
    <div class="h5 grey-text">
      Set the branding options
    </div>
    <validation-observer v-slot="{ }">
      <div class="md-form form-sm" style="margin-top:40px;">
        <validation-provider
          v-slot="{ errors }"
          mode="lazy"
          name="Type Role"
          rules="required"
        >
          <i class="fas fa-user prefix" style="margin-top: 6px;font-size: 1.3rem;" />
          <label style="margin-top:-35px;font-size:12px;">Select the type of role</label>
          <mdb-select
            v-model="brandingOptions"
            placeholder="Type of role"
            style="margin-left:33px;"
            size="sm"
          />
          <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
            Please select the type of role
          </div>
        </validation-provider>
      </div>
      <div v-show="selectedBrandingType === 1">
        <div class="md-form form-sm" style="margin-top:40px;">
          <i class="fas fa-list prefix" style="margin-top: 6px;font-size: 1.3rem;" />
          <label style="margin-top:-35px;font-size:12px;">Select branding used with candidates</label>
          <mdb-select
            v-model="candidateBrandingOptions"
            placeholder="Branding used with the Candidates"
            style="margin-left:33px;"
            size="sm"
          />
        </div>
        <div class="md-form form-sm" style="margin-top:40px;">
          <i class="fas fa-list prefix" style="margin-top: 6px;font-size: 1.3rem;" />
          <label style="margin-top:-35px;font-size:12px;">Select branding used for sharing candidates and the self-registration site.</label>
          <mdb-select
            v-model="sharingBrandingOptions"
            placeholder="Branding Used for Sharing"
            style="margin-left:33px;"
            size="sm"
          />
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between">
        <mdb-btn
          class="btn btn-outline"
          size="sm"
          style="width:110px;"
          @click="$router.push(`/roles/add_edit/detail/${role.JobId}`)"
        >
          CANCEL
        </mdb-btn>
        <mdb-btn
          v-show="!buttonClick"
          type="submit"
          :disabled="selectedBrandingType === -1"
          size="sm"
          style="width:100px;"
          @click="updateLocalRoleAndNext()"
        >
          NEXT
        </mdb-btn>
        <mdb-btn
          v-show="buttonClick"
          size="sm"
          style="width:100px;cursor:default;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import accountService from '@/api/services/account.service'
import roleV2Service from '@/api/services/roleV2.service'
import { useUserData, useStatus, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'BrandingOptions',
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const workflowStore = useWorkflow()
    const { addRoleToRecentRoles, role } = storeToRefs(workflowStore)

    const { showGenericErrorModal } = useStatus()

    return {
      authData,
      showGenericErrorModal,
      addRoleToRecentRoles,
      role
    }
  },
  data () {
    return {
      brandingOptions: [
        { text: 'Current (internal)', value: 0 },
        { text: 'Other (external)', value: 1 }
      ],
      buttonClick: false,
      candidateBrandingOptions: [],
      sharingBrandingOptions: [],
      localRole: {}
    }
  },
  computed: {
    selectedBrandingType () {
      try {
        return this.brandingOptions.filter(i => i.selected)[0].value
      } catch { return -1 }
    }
  },
  watch: {
    selectedBrandingType () {
      if (this.selectedBrandingType === 0) {
        this.candidateBrandingOptions = this.candidateBrandingOptions.map(b => { return { ...b, selected: false } })
        this.sharingBrandingOptions = this.sharingBrandingOptions.map(b => { return { ...b, selected: false } })
      }
    },
    role: {
      handler: function () {
        this.localRole = { ...this.role, Step: 4, JobDetails: { ...this.role.JobDetails } }
        this.getBranding()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async getBranding () {
      try {
        const response = await accountService.getBranding()
        response.data.forEach((item) => {
          this.candidateBrandingOptions.push({
            text: item.Name,
            value: item.BrandingId
          })
          this.sharingBrandingOptions.push({
            text: item.Name,
            value: item.BrandingId
          })
        })
      } catch (err) {
        this.showGenericErrorModal()
      }
      this.setBrandingFromRole()
      this.candidateBrandingOptions = this.candidateBrandingOptions.map(o => o.value === this.role.CandidateBrandId ? { ...o, selected: true } : { ...o, selected: false })
      this.sharingBrandingOptions = this.sharingBrandingOptions.map(o => o.value === this.role.SharingBrandId ? { ...o, selected: true } : { ...o, selected: false })
    },
    async updateLocalRoleAndNext () {
      this.buttonClick = true
      try {
        this.localRole.CandidateBrandId = this.candidateBrandingOptions.filter(o => o.selected)[0].value
      } catch { this.localRole.CandidateBrandId = 0 }
      try {
        this.localRole.SharingBrandId = this.sharingBrandingOptions.filter(o => o.selected)[0].value
      } catch { this.localRole.SharingBrandId = 0 }
      try {
        const res = await roleV2Service.addEditRoleSetup({ ...this.localRole, Step: 3 })
        this.role = res.data
        await this.addRoleToRecentRoles(this.role.JobId)
        this.$router.push(`/roles/add_edit/role_setup/${this.role.JobId}?page=masking_options`).catch(() => { })
      } catch (err) {
        this.showGenericErrorModal()
      }
      this.buttonClick = false
    },
    setBrandingFromRole () {
      if (this.role.CandidateBrandId > 0 || this.role.SharingBrandId > 0) {
        this.brandingOptions = [
          { text: 'Current (internal)', value: 0 },
          { text: 'Other (external)', value: 1, selected: true }
        ]
      } else {
        this.brandingOptions = [
          { text: 'Current (internal)', value: 0, selected: true },
          { text: 'Other (external)', value: 1 }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
