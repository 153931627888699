<template>
  <div style="overflow-x:hidden;" :style="{'padding-top': mobileView ? '50px' : '0'}">
    <div
      class="d-flex align-items-center px-2 px-lg-4 mt-0 mainSectionBoilerPlateInnerTopPanel"
      style="top:0;background:#fff;justify-content:space-evenly;"
      :style="{'padding-top': mobileView ? '80px' : '0'}"
    >
      <div
        class="cursor-pointer text-lg grey-text text-center"
        style="width:50%;"
        :class="{ 'default': section == 'templates', 'notSelected': section != 'templates'}"
        @click="goTo('templates')"
      >
        <span v-if="mobileView">TEMPLATES</span>
        <span v-else>SCORING TEMPLATES</span>
      </div>
      <div
        class="cursor-pointer text-lg grey-text text-center"
        style="width:50%;"
        :class="{ 'default': section == 'scales', 'notSelected': section != 'scales'}"
        @click="goTo('scales')"
      >
        <span v-if="mobileView">SCALES</span>
        <span v-else>POINT SCALES</span>
      </div>
    </div>

    <div class="pt-2 pt-md-4 px-4 px-lg-5" style="height: calc(100vh - 205px); margin-top: 55px">
      <ScoringScales v-if="section === 'scales'" />
      <ScoringTemplates v-if="section === 'templates'" />
    </div>
  </div>
</template>

<script>
import { useMobile } from '@/composables/useMobile'

import ScoringTemplates from './scoringTemplates/scoringTemplatesContainer'
import ScoringScales from './scoringScales/scoringScalesContainer.vue'

export default {
  name: 'ScoringSettingsContainer',
  components: {
    ScoringTemplates,
    ScoringScales
  },
  setup () {
    const { mobileView } = useMobile()

    return {
      mobileView
    }
  },
  data () {
    return {
      section: 'templates'
    }
  },
  mounted () {
    this.setSection()
  },
  methods: {
    goTo(section) {
      this.section = section
      this.$router.push(`/settings/scoring/${section}/all`).catch(() => {})
    },
    setSection () {
      this.section = this.$route.params.section
      this.$router.push(`/settings/scoring/${this.section}/all`).catch(() => {})
    }
  }
}
</script>

<style lang="scss">
.scoring-settings-title-container {
  min-height: 80px;
  height: fit-content;
  font-size: 1rem;
}
.scoring-settings-content-container {
  height: calc(100vh - 317px);
  border: #c0c0c0 solid 2px;
  border-radius: 10px;
}
.notSelected {
  border-bottom: solid 1px #c0c0c0;
}
</style>
