<template>
  <div class="editStageItemDraggableRow">
    <div class="d-flex align-items-center col-7 col-md-8 p-0">
      <FontAwesomeIcon
        class="mx-3"
        style="cursor: grab"
        size="lg"
        icon="fa-solid fa-grip-dots"/>
      <mdb-input
        v-model="stageItemName"
        type="text"
        style="width:300px;"
        size="sm"
        class="m-0"
      />
      <!-- SAVE ICON -->
      <div
        style="width: 40px"
        class="ml-3 d-flex justify-content-center">
        <mdb-btn
          v-show="!savingName"
          @click="saveName()"
          :disabled="!stageItemName || !stageItemNameHasBeenEdited"
          class="p-1 px-2 btn-outline"
        >
          <i v-if="stageItemNameHasBeenEdited" class="fa fa-save " />
          <i v-else class="fa fa-pen" />
        </mdb-btn>
        <i v-show="savingName" class="fa fa-spinner fa-pulse " />
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-end col-5 col-md-4 p-0">
      <!-- STAGE TYPE BUBBLE -->
      <StageItemTypeRow
        class="m-auto"
        :text="stageItemType"
      />
      <!-- DELETE -->
      <FontAwesomeIcon 
        v-if="canDelete"
        @click="openConfirmDeleteStageItemModal"
        class="cp mr-3"
        style="scale:1.1"
        icon="fa-solid fa-trash-can"
      />
      <!-- TOOL TIP -->
      <Tooltip
        v-if="stageItemTooltipMessage"
        style="margin-top: 3px"
        class="mr-3"
        tooltipDirection="left"
        :toolTipSummary="stageItemTooltipMessage"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue-demi'
import StageItemTypeRow from '../stageItemTypeBubble.vue'
import Tooltip from '@/components/misc/tooltip.vue'
import roleV2Service from '@/api/services/roleV2.service'
import { useStatus, useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'EditStageItemDraggableRow',
  components: { StageItemTypeRow, Tooltip },
  props: {
    stageItem: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    const statusStore = useStatus()
    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = statusStore
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)

    const stageItemTooltipMessage = computed(() => {
      let text = ''
      switch (props.stageItem.StageItemStatus) {
        case 2:
          text = 'This is the initial status for this stage. Candidates will be automatically given this status as their fist step in this stage.';
          break;
        case 3:
          text = 'Candidates will be automatically given this status, once a date and time has been set for them to begin.';
        break;
        case 4:
          text = 'Candidates will be automatically given this status once they have begun this stage.';
        break;
        case 5:
          text = 'When a Candidates invite has expired they will be automatically given this status.';
        break;
        case 6:
          text = 'This is where a Candidate is placed once their participation in the stage is complete.';
        break;
        default:
          text = 'These statuses allow you to categorise completed applications more easily.';
          break;
      }
      return text;
    })
    const stageItemType = computed(() => {
      let text = ''
      switch (props.stageItem.StageItemStatus) {
        case 2: text = 'Start'; break;
        case 3: text = 'Scheduled'; break;
        case 4: text = 'Ongoing'; break;
        case 5: text = 'Expired'; break;
        case 6: text = 'Complete'; break;
        default:
          break;
      }
      return text;
    })
    const canDelete = computed(() => props.stageItem.StageItemStatus >= 7 && (userData.value.rle === 'Admin' || userData.value.iss))
    
    // saving the name 
    const stageItemName = ref(props.stageItem.RoleWorkflowStageItemName)
    const stageItemNameInitialValue = ref(props.stageItem.RoleWorkflowStageItemName)
    const stageItemNameHasBeenEdited = computed(() => stageItemNameInitialValue.value !== stageItemName.value)
    const savingName = ref(false)
    const saveName = async () => {
      savingName.value = true
      const JSONData = {
        RoleWorkflowStageItemName: stageItemName.value,
        RoleWorkflowStageItemId: props.stageItem.RoleWorkflowStageItemId
      }
      try {
        await roleV2Service.editRoleWorkflowStageItem(JSONData)
        stageItemNameInitialValue.value = stageItemName.value
        emit('refreshStatuses')
      } catch (err) {
        showGenericErrorModal({showing: true})
      }
      savingName.value = false
    }

    // deleting
    const deleteStageItem = async () => {
      try {
        // first we want to check the applicant count is zero
        const res = await roleV2Service.getStageItemApplicantCounts({ Guids: [props.stageItem.RoleWorkflowStageItemId] })
        const stageApplicantCount = res.data[props.stageItem.RoleWorkflowStageItemId]
        if (stageApplicantCount == 0) {
          const JSONData = {
            IsDeleted: true,
            RoleWorkflowStageItemName: props.stageItem.RoleWorkflowStageItemName,
            RoleWorkflowStageItemId: props.stageItem.RoleWorkflowStageItemId
          }
          await roleV2Service.editRoleWorkflowStageItem(JSONData)
          emit('refreshStatuses')
          closeGenericConfirmModal()
        } else {
          showGenericErrorModal({ showing: true, errorTitle: 'CANNOT DELETE', errorMessage: 'There are currently candidates with this status. To delete this status they must first be moved. ' })
        }
      } catch (err) {
        showGenericErrorModal({showing: true})
      }
    }

    const openConfirmDeleteStageItemModal = () => {
      showGenericConfirmModal({
        showing: true,
        confirmTitle: 'CONFIRM DELETE',
        confirmMessage: 'Are you sure you want to delete this Status?',
        confirmButtonMessage: 'CONFIRM',
        confirmButtonFunction: () => deleteStageItem()
      })
    }

    return { stageItemType, stageItemName, stageItemNameHasBeenEdited, saveName, savingName, stageItemTooltipMessage, canDelete, openConfirmDeleteStageItemModal }
  }
}
</script>

<style>
.editStageItemDraggableRow {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  background: #ebebeb;;
}
</style>