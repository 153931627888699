<template>
  <div class="px-3 px-md-4 py-2 py-md-3" style="width:100%;">
    <div class="d-flex align-items-center">
      <i class="mr-3 fas fa-user text-left" style="font-size: 1.3rem;" />
      <mdb-input
        v-model="FirstName"
        type="text"
        class="addingInviteCandidateForm"
        auto-complete="fna"
        label="First name"
        size="sm"
      />
    </div>
    <div class="d-flex align-items-center">
      <i class="mr-3 fas fa-user" style="font-size: 1.3rem;" />
      <mdb-input
        v-model="LastName"
        type="text"
        class="addingInviteCandidateForm"
        auto-complete="sna"
        label="Last name"
        size="sm"
      />
    </div>
    <validation-provider
      ref="email"
      v-slot="{ errors }"
      mode="lazy"
      name="Email Address"
      rules="email"
    >
      <div class="d-flex align-items-center">
        <i class="mr-3 fas fa-envelope" style="font-size: 1.3rem;" />
        <mdb-input
          v-model="EmailAddress"
          type="email"
          class="addingInviteCandidateForm"
          auto-complete="emai"
          label="Email address"
          size="sm"
          @change="validate($event)"
        />
      </div>
      <div v-if="errors[0]" style="font-size: 0.7rem;margin-top: -5px;margin-bottom: 0px;text-align: left;margin-left: 37px;">
        Please provide a valid email address
      </div>
    </validation-provider>

    <div class="d-flex align-items-center">
      <i class="mr-3 fas fa-mobile" style="font-size: 1.3rem;" />
      <mdb-input
        v-model="ContactNumber"
        type="text"
        class="addingInviteCandidateForm"
        auto-complete="tel"
        label="Mobile number (optional)"
        size="sm"
      />
    </div>
    <div class="d-flex align-items-center">
      <i class="mr-3 fas fa-map-marker-alt" style="font-size: 1.3rem;" />
      <mdb-input
        v-model="Location"
        type="text"
        class="addingInviteCandidateForm"
        auto-complete="loci"
        label="Location (optional)"
        size="sm"
      />
    </div>
    <div class="d-flex align-items-center">
      <i class="mr-3 fas fa-user" style="font-size: 1.3rem;" />
      <mdb-input
        v-model="ExternalCandidateReference"
        type="text"
        class="addingInviteCandidateForm"
        auto-complete="ref"
        label="External reference (optional)"
        size="sm"
      />
    </div>
  </div>
</template>

<script>
import { useRoles, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'CandidateInformationForm',
  setup () {
    const roleStore = useRoles()
    const { addingInvitingCandidateModalShowing, addInviteCandidateModalData } = storeToRefs(roleStore)
    const workflowStore = useWorkflow()
    const { role } = storeToRefs(workflowStore)
    return {
      addingInvitingCandidateModalShowing, addInviteCandidateModalData, role
    }
  },
  props: {
    resetForm: Boolean,
  },
  data () {
    return {
      errors: [],
      FirstName: '',
      LastName: '',
      EmailAddress: '',
      ContactNumber: '',
      Location: '',
      ExternalCandidateReference: '',
      emailValid: false
    }
  },
  computed: {
    formIsValid () {
      return this.FirstName !== '' && this.LastName !== '' && this.EmailAddress !== '' && this.emailValid
    }
  },
  watch: {
    emailValid () { this.updateForm() },
    FirstName () { this.updateForm() },
    LastName () { this.updateForm() },
    EmailAddress () { this.updateForm() },
    ContactNumber () { this.updateForm() },
    Location () { this.updateForm() },
    ExternalCandidateReference () { this.updateForm() },
    resetForm () {
      if (this.resetForm) {
        this.FirstName = ''
        this.LastName = ''
        this.EmailAddress = ''
        this.ContactNumber = ''
        this.Location = ''
        this.ExternalCandidateReference = ''
      }
    }
  },
  methods: {
    async validate () {
      const ref = this.$refs.email
      const res = await ref.validate()
      this.emailValid = res.valid
    },
    updateForm () {
      if (this.formIsValid === true) {
        this.$emit('formValid', this.formIsValid)
        if (this.formIsValid) {
          this.$emit('formData', {
            FirstName: this.FirstName,
            LastName: this.LastName,
            EmailAddress: this.EmailAddress,
            ContactNumber: this.ContactNumber,
            Location: this.Location,
            ExternalCandidateReference: this.ExternalCandidateReference,
            CandidateId: 0
          })
        }
      } else { this.$emit('formValid', false) }
    }
  }
}
</script>

<style lang="scss">
.addingInviteCandidateForm {
  margin: 10px 0px 4px 0px !important;
  width:100%
}
</style>
