<template>
  <div class="d-flex flex-column text-black rounded text-left" style="overflow-y:auto;overflow-x:hidden;padding:25px;">
    <div
      class="d-flex align-items-center justify-content-between mb-3"
      :style="{'margin-top': mobileView ? '30px' : '0px', }"
    >
      <div class="font-weight-bold flex-grow-0 primary-colour">
        Custom Profile Fields
      </div>
      <mdb-btn
        class="btn px-4 py-2 m-0"
        @click="customFieldModalShowing = true"
      >
        <span>ADD</span>
      </mdb-btn>
    </div>
    <div v-if="loadingCustomFieldList">
      <h3 class="text-left font-weight-bold">
        Loading
        <i class="fa fa-spin fa-spinner ml-3" />
      </h3>
    </div>
    <div v-else-if="!loadingCustomFieldList && customFieldList.length == 0">
      <h3 class="text-left grey-text">
        Click the 'ADD' button to begin creating custom data fields for your candidate profiles
      </h3>
    </div>
    <b-table
      v-else-if="!loadingCustomFieldList && customFieldList.length > 0"
      id="custom_candidate_fields_table"
      class="text-left"
      head-variant="dark"
      :items="customFieldList"
      :per-page="customFieldList.length"
      :current-page="1"
      :fields="fields"
      :striped="true"
      hover
      small
      sort-icon-left
      responsive="sm"
      :style="{'margin-top': mobileView ? '10px' : '0px', }"
    >
      <template #cell(question)="data">
        <div
          class="custom-field-data-row cp"
          @click="openTextEdit(data.item)"
        >
          <div style="float: left;margin-right: 5px;">
            <FontAwesomeIcon
              class="ml-2"
              icon="fa-solid fa-edit"
              title="Edit Description?"
            />
          </div>
          <div style="float: left;">
            {{ data.item.Question }}
          </div>
        </div>
        <div
          v-if="data.item.Type == 'dropdown'"
          style="margin-left:43px;font-size:11px;margin-top: -28px;margin-bottom: 10px;"
        >
          <span v-html='data.item.CustomFieldInformation.replaceAll(",","<br />- ").replaceAll("[","").replaceAll("]","").replaceAll("Please Select","").replaceAll("\"", "")' />
        </div>
      </template>
      <template #cell(type)="data">
        <div class="custom-field-data-row">
          {{ getUIElement(data.item.Type) }}
        </div>
      </template>
      <template #cell(included)="data">
        <div
          class="custom-field-data-row"
          :style="{'margin-left': mobileView ? '-10px !important' : '0px'}"
        >
          <mdb-switch
            :disabled="saving"
            :value="data.item.IsIncluded"
            off-label=""
            on-label=""
            class="m-0"
            size="sm"
            @input="e => toggleIncluded(e, data.item)"
          />
        </div>
      </template>
      <template #cell(CustomFieldElementId)="data">
        <div 
          v-if="data.item.HasData"
          class="custom-field-data-row"
          title="Cannot delete this field as candidate profile data is associated with it!"
          :style="{'margin-right': mobileView ? '10px' : '0px'}"
        >
          <i class="fa fa-trash" style="opacity: 0.3;cursor:help;" />
        </div>
        <div 
          v-else
          class="custom-field-data-row"
          title="Delete this field?"
          :style="{'margin-right': mobileView ? '10px' : '0px'}"
          @click="openDeleteModal(data.item)"
        >
          <i class="fa fa-trash" style="cursor:pointer;" />
        </div>
      </template>
    </b-table>
    <CustomFormFieldModal />
    <GenericInsertTextModal @text="updateQuestion" />
    <DeleteCustomFormFieldModal @id="id" />
  </div>
</template>

<script>
import { useUserData, useCandidate, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import { ref } from 'vue-demi';
import { useMobile } from '@/composables/useMobile'
import CustomFormFieldModal from './customFormFieldModal/customFormFieldModal.vue'
import GenericInsertTextModal from '@/components/genericModals/insertTextModal.vue'
import DeleteCustomFormFieldModal from '@/components/genericModals/deleteCustomFormFieldModal.vue'

export default {
  name: 'CustomFormFields',
  components: { CustomFormFieldModal, GenericInsertTextModal, DeleteCustomFormFieldModal },
  setup () {
    const userStore = useUserData()
    const { mobileView } = useMobile()
    const { userData, authData } = storeToRefs(userStore)
    const candidateStore = useCandidate()
    const {
      getCustomFieldList,
      editCustomFormField,
      deleteCustomFormField
    } = useCandidate()
    const {
      customFieldModalShowing,
      customFieldList,
      loadingCustomFieldList
    } = storeToRefs(candidateStore)

    getCustomFieldList()

    var fields = ref([
      { key: 'question', label: '   Description', tdClass: 'sortIcon p-0', thClass: 'background link-colour font-weight-bold pl-3' },
      { key: 'type', label: '   Type', tdClass: 'sortIcon p-0', thClass: 'background link-colour font-weight-bold pl-3' },
      { key: 'included', label: '   Include On Profile Page', tdClass: 'sortIcon p-0', thClass: 'background link-colour font-weight-bold pl-3' },
      { key: 'CustomFieldElementId', label: ' ', tdClass: 'sortIcon p-0', thClass: 'background link-colour font-weight-bold pl-3' }
    ])

    if (mobileView.value === true) {
      fields = ref([
        { key: 'question', label: '   Description', tdClass: 'sortIcon p-0', thClass: 'background link-colour font-weight-bold pl-3' },
        { key: 'included', label: '   Include', tdClass: 'sortIcon p-0', thClass: 'background link-colour font-weight-bold pl-3' },
        { key: 'CustomFieldElementId', label: ' ', tdClass: 'sortIcon p-0', thClass: 'background link-colour font-weight-bold pl-3' }
      ])
    }

    const saving = ref(false)
    const selectedFormElement = ref({})
    const editCustomFormFieldLocal = async () => {
      saving.value = true
      const JSON = { ...selectedFormElement.value }
      await editCustomFormField(JSON)
      await getCustomFieldList()
      saving.value = false
    }
    const deleteCustomFormFieldModalLocal = async () => {
      saving.value = true
      const JSON = { ...selectedFormElement.value }
      try {
        await deleteCustomFormField(JSON)
        await getCustomFieldList()
      } catch (err) {
        //?
      }
      saving.value = false
    }

    // TEXT EDITING
    const { openInsertTextGenericModal } = useStatus()
    const openTextEdit = (element) => {
      selectedFormElement.value = { ...element }
      openInsertTextGenericModal('Set A Field Description', element.Question)
    }
    const updateQuestion = (text) => {
      selectedFormElement.value.Question = text
      editCustomFormFieldLocal()
    }

    // DELETE
    const { openDeleteCustomFormFieldModal } = useStatus()
    const openDeleteModal = (element) => {
      selectedFormElement.value = { ...element }
      openDeleteCustomFormFieldModal(element.CustomFieldElementId)
    }
    const id = (id) => {
      selectedFormElement.value.CustomFieldElementId = id
      deleteCustomFormFieldModalLocal()
    }

    // IS INCLUDED EDITING
    const toggleIncluded = (newValue, element) => {
      selectedFormElement.value = { ...element, IsIncluded: newValue }
      editCustomFormFieldLocal()
    }

    const getUIElement = (type) => {
      switch (type.toLowerCase()) {
        case 'text':
          return 'Textbox';
        case 'textarea':
          return 'Text Area';
        case 'number':
          return 'Number';
        case 'bool':
          return 'Checkbox';
        case 'dropdown':
          return 'Dropdown Menu';
        default:
          return 'Unknown type';
      }
    }

    return {
      userData,
      authData,
      customFieldList,
      loadingCustomFieldList,
      fields,
      customFieldModalShowing,
      openTextEdit,
      updateQuestion,
      id,
      getUIElement,
      toggleIncluded,
      saving,
      openDeleteModal,
      mobileView
    }
  }
}
</script>

<style>
.custom-field-data-row {
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 15px;
}
</style>
