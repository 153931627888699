<template>
  <div class="flex flex-col px-8">
    <div
      v-if="eventSubscription.Name != ''"
      class="grey-text"
      style="text-align: left;margin-top: 20px;margin-bottom: -30px;font-size: 11px;margin-left: 26px;"
    >
      Subscription Friendly Name
    </div>
    <div class="flex flex-row items-center">
      <i class="fa fa-regular fa-star mr-2" />
      <mdb-input
        v-model="eventSubscription.Name"
        class="w-1/2"
        placeholder="Subscription Friendly Name"
      />
    </div>

    <span class="flex text-primary font-bold pb-4 mr-auto"> What events do you want to subscribe too? </span>

    <div class="grid grid-cols-2">
      <!-- ONE WAY -->
      <div class="text-left mb-4">
        <font-awesome-icon :icon="['far', 'mobile']" />
        <span class="text-left font-bold pt-6 pb-2"> 1-Way Interviews</span>
        <div class="flex flex-col text-left">
          <div
            v-for="(item, i) in oneWaySettings"
            :key="i"
            class="text-primary flex flex-row items-center p-0.5"
            @click="setOneWayIndex(item)"
          >
            <mdb-input
              v-if="item.selected === false"
              type="checkbox"
            />
            <div
              v-else
              style="margin-left:15px;margin-right:14px;padding:5px;margin-top:-2px;"
            >
              <i class="fa fa-check" />
            </div>
            <span> {{ item.text }}</span>
          </div>
        </div>
      </div>

      <!-- TWO WAY -->
      <div class="text-left mb-4">
        <font-awesome-icon :icon="['far', 'people']" />
        <span class="text-left font-bold pt-6 pb-2"> 2-Way Interviews</span>
        <div class="flex flex-col text-left">
          <div
            v-for="(item, i) in twoWaySettings"
            :key="i"
            class="text-primary flex flex-row items-center p-0.5"
            @click="setTwoWayIndex(item)"
          >
            <mdb-input
              v-if="item.selected === false"
              type="checkbox"
            />
            <div
              v-else
              style="margin-left:15px;margin-right:14px;padding:5px;margin-top:-2px;"
            >
              <i class="fa fa-check" />
            </div>
            <span> {{ item.text }}</span>
          </div>
        </div>
      </div>

      <!-- EVENT -->
      <div class="text-left mb-4">
        <font-awesome-icon :icon="['far', 'calendar']" />
        <span class="text-left font-bold pt-6 pb-2"> Self Schedule Event</span>
        <div class="flex flex-col text-left">
          <div
            v-for="(item, i) in selfScheduleSettings"
            :key="i"
            class="text-primary flex flex-row items-center p-0.5"
            @click="setSelfScheduleIndex(item)"
          >
            <mdb-input
              v-if="item.selected === false"
              type="checkbox"
            />
            <div
              v-else
              style="margin-left:15px;margin-right:14px;padding:5px;margin-top:-2px;"
            >
              <i class="fa fa-check" />
            </div>
            <span> {{ item.text }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue"
import { useEventSubscription } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'AddEventSubscription',
  setup () {

    const eventSubscriptionStore = useEventSubscription()
    const { isValidated, eventSubscription, oneWaySettings, twoWaySettings, selfScheduleSettings } = storeToRefs(eventSubscriptionStore)

    const navigationItems = ref([
      { Step: 1, Query: 'events', Text: 'Events', ActualStep: 0 },
      { Step: 2, Query: 'authentication', Text: 'Authentication', ActualStep: 1 },
      { Step: 3, Query: 'data', Text: 'Data', ActualStep: 2 }
    ])

    return {
      navigationItems,
      oneWaySettings,
      selfScheduleSettings,
      twoWaySettings,
      eventSubscription,
      isValidated
    }
  },
  created () {
    this.setOptions(this.eventSubscription)
    this.validateThis()
  },
  methods: {
    setOptions (sub) {
      this.oneWaySettings = [
        { text: 'On Completion', value: 1, selected: sub.OneWayCompletion },
        { text: 'On Invitation Expiry', value: 2, selected: sub.OneWayInviteExpiry },
        { text: 'On Reset', value: 3, selected: sub.OneWayReset },
        { text: 'On Assessor Scoring', value: 4, selected: sub.OneWayAssessorScoring },
        { text: 'On Moderator Scoring', value: 5, selected: sub.OneWayModeratorScoring },
        { text: 'On Status Change', value: 6, selected: sub.OneWayStatusChange }
      ],      
      this.twoWaySettings = [
        { text: 'On Completion', value: 1, selected: sub.TwoWayCompletion },
        // { text: 'On Assessor Scoring', value: 2, selected: sub.TwoWayAssessorScoring },
        // { text: 'On Moderator Scoring', value: 3, selected: sub.TwoWayModeratorScoring },
        { text: 'On Status Change', value: 4, selected: sub.TwoWayModeratorScoring },
        { text: 'On Booking - if Self-Schedule Enabled', value: 5, selected: sub.TwoWayBooking },
        { text: 'On Invitation Expiry - if Self-Schedule Enabled', value: 6, selected: sub.TwoWayInviteExpiry },
        { text: 'On Cancellation - if Self-Schedule Enabled', value: 7, selected: sub.TwoWayCancellation }
      ],
      this.selfScheduleSettings = [
        { text: 'On Booking', value: 1, selected: sub.SchedulerBooking },
        { text: 'On Invitation Expiry', value: 2, selected: sub.SchedulerInviteExpiry },
        { text: 'On Cancellation', value: 3, selected: sub.SchedulerCancellation },
        { text: 'On Status Change', value: 4, selected: sub.SchedulerStatusChange }
      ]
    },
    async setOneWayIndex (item) {
      if (item.selected) {
        item.selected = false
        if (item.value === 1) { this.eventSubscription.OneWayCompletion = false }
        if (item.value === 2) { this.eventSubscription.OneWayInviteExpiry = false }
        if (item.value === 3) { this.eventSubscription.OneWayReset = false }
        if (item.value === 4) { this.eventSubscription.OneWayAssessorScoring = false }
        if (item.value === 5) { this.eventSubscription.OneWayModeratorScoring = false }
        if (item.value === 6) { this.eventSubscription.OneWayStatusChange = false }
      } else {
        item.selected = true
        if (item.value === 1) { this.eventSubscription.OneWayCompletion = true }
        if (item.value === 2) { this.eventSubscription.OneWayInviteExpiry = true }
        if (item.value === 3) { this.eventSubscription.OneWayReset = true }
        if (item.value === 4) { this.eventSubscription.OneWayAssessorScoring = true }
        if (item.value === 5) { this.eventSubscription.OneWayModeratorScoring = true }
        if (item.value === 6) { this.eventSubscription.OneWayStatusChange = true }
      }
      this.validateThis()
    },
    async setTwoWayIndex (item) {
      if (item.selected) {
        item.selected = false
        if (item.value === 1) { this.eventSubscription.TwoWayCompletion = false }
        if (item.value === 2) { this.eventSubscription.TwoWayAssessorScoring =  false }
        if (item.value === 3) { this.eventSubscription.TwoWayModeratorScoring =  false }
        if (item.value === 4) { this.eventSubscription.TwoWayStatusChange =  false }
        if (item.value === 5) { this.eventSubscription.TwoWayBooking =  false }
        if (item.value === 6) { this.eventSubscription.TwoWayInviteExpiry =  false }
        if (item.value === 7) { this.eventSubscription.TwoWayCancellation =  false }
      } else {
        item.selected = true
        if (item.value === 1) { this.eventSubscription.TwoWayCompletion = true }
        if (item.value === 2) { this.eventSubscription.TwoWayAssessorScoring = true }
        if (item.value === 3) { this.eventSubscription.TwoWayModeratorScoring = true }
        if (item.value === 4) { this.eventSubscription.TwoWayStatusChange = true }
        if (item.value === 5) { this.eventSubscription.TwoWayBooking = true }
        if (item.value === 6) { this.eventSubscription.TwoWayInviteExpiry = true }
        if (item.value === 7) { this.eventSubscription.TwoWayCancellation = true }
      }
      this.validateThis()
    },
    async setSelfScheduleIndex (item) {
      if (item.selected) {
        item.selected = false
        if (item.value === 1) { this.eventSubscription.SchedulerBooking = false }
        if (item.value === 2) { this.eventSubscription.SchedulerInviteExpiry = false }
        if (item.value === 3) { this.eventSubscription.SchedulerCancellation = false }
        if (item.value === 4) { this.eventSubscription.SchedulerStatusChange = false }
      } else {
        item.selected = true
        if (item.value === 1) { this.eventSubscription.SchedulerBooking = true }
        if (item.value === 2) { this.eventSubscription.SchedulerInviteExpiry = true }
        if (item.value === 3) { this.eventSubscription.SchedulerCancellation = true }
        if (item.value === 4) { this.eventSubscription.SchedulerStatusChange = true }
      }
      this.validateThis()
    },
    validateThis () {
      if (this.$parent.currentPage === 'events') {
        if (this.eventSubscription.OneWayCompletion === true || this.eventSubscription.OneWayInviteExpiry === true || this.eventSubscription.OneWayReset === true || this.eventSubscription.OneWayAssessorScoring === true || this.eventSubscription.OneWayModeratorScoring === true || this.eventSubscription.OneWayStatusChange === true
          || this.eventSubscription.TwoWayCompletion === true || this.eventSubscription.TwoWayAssessorScoring === true || this.eventSubscription.TwoWayModeratorScoring === true || this.eventSubscription.TwoWayStatusChange === true || this.eventSubscription.TwoWayBooking === true || this.eventSubscription.TwoWayInviteExpiry === true || this.eventSubscription.TwoWayCancellation === true
          || this.eventSubscription.SchedulerBooking === true || this.eventSubscription.SchedulerInviteExpiry === true || this.eventSubscription.SchedulerCancellation === true || this.eventSubscription.SchedulerStatusChange === true) {
            this.isValidated = true 
        }
        if (this.eventSubscription.OneWayCompletion === false && this.eventSubscription.OneWayInviteExpiry === false && this.eventSubscription.OneWayReset === false && this.eventSubscription.OneWayAssessorScoring === false && this.eventSubscription.OneWayModeratorScoring === false && this.eventSubscription.OneWayStatusChange === false 
          && this.eventSubscription.TwoWayCompletion === false && this.eventSubscription.TwoWayAssessorScoring === false && this.eventSubscription.TwoWayModeratorScoring === false && this.eventSubscription.TwoWayStatusChange === false && this.eventSubscription.TwoWayBooking === false && this.eventSubscription.TwoWayInviteExpiry === false && this.eventSubscription.TwoWayCancellation === false
          && this.eventSubscription.SchedulerBooking === false && this.eventSubscription.SchedulerInviteExpiry === false && this.eventSubscription.SchedulerCancellation === false && this.eventSubscription.SchedulerStatusChange === false) {
            this.isValidated = false 
        }
      }
    }
  }
}
</script>

<style scoped>
div data-v-189f23e2 .form-check {
  align-items: center;
  flex: 1
}
</style>
