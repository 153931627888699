<template>
  <FloatingOptions
    class="mx-2"
    :buttons="editableCardOptionButtons"
    :showing="showSetupOptions"
    @rules="handleRuleSetup()"
    @statuses="handleStatusSetup()"
    @setup="$emit('stageSetup')"
  />
</template>

<script>
import FloatingOptions from '@/components/UI/floatingOptions.vue'
import { useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'
import { ref } from 'vue-demi'

export default {
  name: 'SetupCompleteOptions',
  components: {
    FloatingOptions
  },
  props: {
    stage: {
      type: Object,
      default: () => {}
    },
    showSetupOptions: {
      type: Boolean,
      default: false
    },
  },
  setup (props) {
    
    const workflowStore = useWorkflow()
    const { editStageItemModalShowing, editStageItemStage } = storeToRefs(workflowStore)

    const completeSetupNotClicked = ref(true)
    const editableCardOptionButtons = ref([
      // to be brought in later
      {
        buttonText: 'STAGE SETUP',
        outerButtonClass: 'text-nowrap',
        iconClass: 'fa fa-edit',
        actionEmitName: 'setup'
      },
      {
        buttonText: 'STATUSES',
        outerButtonClass: 'text-nowrap',
        iconClass: 'fa fa-edit',
        actionEmitName: 'statuses'
      },
      // {
      //   buttonText: 'STAGE RULES',
      //   outerButtonClass: 'text-nowrap',
      //   iconClass: 'fa fa-edit',
      //   actionEmitName: 'rules'
      // }
    ])
    const handleStatusSetup = () => {
      editStageItemStage.value = props.stage
      editStageItemModalShowing.value = true
    }
    const handleRuleSetup = () => { }

    return { completeSetupNotClicked, editableCardOptionButtons, handleStatusSetup, handleRuleSetup }
  }
}
</script>
