<template>
  <!--set meeting room date and time modal-->
  <mdb-modal
    id="extendInviteModal"
    :show="extendInviteDeadlineModalShowing"
    style="z-index:10000;"
    @close="extendInviteDeadlineModalShowing = false"
  >
    <mdb-modal-header>
      <mdb-modal-title>SET A NEW DEADLINE</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <div
        class="w-full flex flex-row"
        style="margin-top:-20px;"
      >
        <font-awesome-icon
          :icon="['far', 'calendar']"
          class="mr-2 text-primary"
          style="margin-top:38px;"
        />
        <mdb-input
          v-model="newDate"
          type="date"
          :min="minimumDeadlineDate"
          :max="maximumDeadlineDate"
          class="w-full"
          size="sm"
          placeholder="End Date"
        />
      </div>
    </mdb-modal-body>
    <mdb-modal-footer style="display:block;">
      <mdb-btn
        type="submit"
        class="float-right"
        size="sm"
        style="width:100px;"
        @click="extendDeadlineLocal"
      >
        <span v-if="!loading">SET</span>
        <span v-else><mdb-icon icon="fa fa-pulse fa-spinner" /></span>
      </mdb-btn>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>

import { useRoleCandidate, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'

export default {
  name: 'ExtendInviteDeadlineModal',
  setup (_, { emit }) {
    const roleCandidateStore = useRoleCandidate()
    const { extendDeadline } = useRoleCandidate()
    const { candidate, extendInviteDeadlineModalShowing } = storeToRefs(roleCandidateStore)

    const workflowStore = useWorkflow()
    const { role, selectedWorkflowStage } = storeToRefs(workflowStore)

    const loading = ref(false)

    const setDate = (date) => {
      if (!date) return
      var month = date.getMonth() + 1
      var day = date.getDate()
      var year = date.getFullYear()
      if (month < 10) {
        month = '0' + month.toString()
      }
      if (day < 10) {
        day = '0' + day.toString()
      }
      return year + '-' + month + '-' + day
    }
    const maximumDeadlineDate = computed(() => setDate(new Date(selectedWorkflowStage?.value.EndDate)))
    const minimumDeadlineDate = computed(() => setDate(new Date()))
    const newDate = ref(minimumDeadlineDate.value)

    const extendDeadlineLocal = async () => {
      await extendDeadline(newDate.value)
      extendInviteDeadlineModalShowing.value = false
      emit('refreshCandidates')
    }

    return {
      role,
      candidate,
      selectedWorkflowStage,
      loading,
      maximumDeadlineDate,
      minimumDeadlineDate,
      extendDeadline,
      extendInviteDeadlineModalShowing,
      newDate,
      extendDeadlineLocal
    }
  }
}
</script>
