<template>
  <mdb-popover
    ref="popover"
    trigger="click"
    :options="{placement: 'bottom'}"
    style="cursor:pointer;align-self:center;width:5px;"
    class="grey-text"
  >
    <div slot="body" style="width:fit-content !important">
      <h6
        v-if="question.AnswerUrl !== ''"
        class="d-flex mb-2"
        style="align-items:center"
      >
        <i class="fa fa-download ml-1 grey-link " style="margin-right:7px;" /><a
          :href="question.AnswerUrl"
          target="_blank"
          download
          class="grey-link mr-3 text-nowrap"
        >Link For Download</a>
      </h6>
      <h6
        v-if="question.AnswerUrl === ''"
        class="d-flex mb-2"
        style="align-items:center"
        @click="editQuestion(question)"
      >
        <i class="fas fa-edit ml-1 grey-link" style="margin-right:10px;" /><a class="grey-link mr-3">Edit</a>
      </h6>
      <h6
        v-if="question.AnswerUrl !== ''"
        class="d-flex mb-2"
        style="align-items:center"
        @click="openPlayVideoModal(question)"
      >
        <i class="fas fa-play ml-1 grey-link" style="margin-right:10px;" /><a class="grey-link mr-3">Play</a>
      </h6>
      <h6
        v-if="question.AnswerUrl !== ''"
        class="d-flex mb-2"
        style="align-items:center"
        @click="openResetQuestionModal(question)"
      >
        <i class="fa fa-undo ml-1 grey-link" style="margin-right:10px;" /><a class="grey-link mr-3">Reset</a>
      </h6>
      <h6
        v-if="question.AnswerUrl !== ''"
        class="d-flex mb-2"
        style="align-items:center"
        @click="openPlayVideoModalCapturing(question)"
      >
        <i class="fas fa-camera ml-1 grey-link" style="margin-right:10px;" /><a class="grey-link mr-3 text-nowrap">Capture Profile Pic</a>
      </h6>
      <h6
        class="d-flex mb-2"
        style="align-items:center"
        @click="openDeleteQuestionModal(question)"
      >
        <i class="fa fa-trash ml-1 grey-link " style="margin-right:7px;" />
        <a class="grey-link mr-3 text-nowrap">
          <span>Delete</span>
        </a>
      </h6>
      <EmbedVideo
        v-if="question.AnswerUrl !== ''"
        :src="question.AnswerUrl"
        :video-title="question.Description"
        @closePopover="tryClosePopover()"
      />
    </div>
    <mdb-icon
      slot="reference"
      style="width: 20px; height:30px; color:#555;font-size:1rem;"
      class="ellipsisButtonHover ml-2"
      icon="ellipsis-v"
    />
  </mdb-popover>
</template>

<script>
import EmbedVideo from '@/components/misc/embedVideo.vue'

export default {
  name: 'VideoCVPopover',
  components: {
    EmbedVideo
  },
  props: {
    question: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    tryClosePopover () {
      try {
        this.$refs.popover.map(p => p.doClose())
      // eslint-disable-next-line no-empty
      } catch {}
      try {
        this.$refs.popover.doClose()
      // eslint-disable-next-line no-empty
      } catch {}
    },
    openDeleteQuestionModal (question) {
      this.$emit('openDeleteQuestionModal', question)
    },
    openPlayVideoModal (question) {
      this.$emit('openPlayVideoModal', question)
    },
    openPlayVideoModalCapturing (question) {
      this.$emit('openPlayVideoModalCapturing', question)
    },
    openResetQuestionModal (question) {
      this.$emit('openResetQuestionModal', question)
    },
    editQuestion (question) {
      this.$emit('editQuestion', question)
    }
  }
}
</script>
