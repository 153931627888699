// LOCAL
// export const WEB_URL = 'http://localhost:8080'
// export const API_URL = 'http://localhost:49504'
// export const API_URLV2 = 'https://localhost:7068'
// export const MEET_URL = 'http://localhost:44308'
// export const SHARE_INTRO_URL = 'https://shinegointros.azurewebsites.net'
// export const CANDIDATE_URL = 'http://localhost:8080'
// export const SELF_REGISTRATION_URL = 'http://localhost:8080'
// export const PUBLIC_API_URL = 'https://localhost:7013/'
// export const CLIENT_PORTAL_URL = 'http://localhost:8081'
// export const SCHEDULER_URL = 'http://localhost:9000/#'
// export const ENVIRONMENT = 'LOCAL'

// UAT
// export const WEB_URL = 'https://shine-go.azurewebsites.net'
// export const API_URL = 'https://shine-api.azurewebsites.net'
// export const API_URLV2 = 'https://shine-core-api.azurewebsites.net'
// export const MEET_URL = 'https://shine-meet.azurewebsites.net'
// export const SHARE_INTRO_URL = 'https://shine-go-intros.azurewebsites.net'
// export const CANDIDATE_URL = 'https://shine-go-candidate.azurewebsites.net'
// export const SELF_REGISTRATION_URL = 'https://shine-go-jobs.azurewebsites.net'
// export const PUBLIC_API_URL = 'https://shine-public-api.azurewebsites.net/'
// export const CLIENT_PORTAL_URL = 'https://shine-go-client-portal.azurewebsites.net'
// export const SCHEDULER_URL = 'https://shine-go-scheduler.azurewebsites.net/#'
// export const ENVIRONMENT = 'UAT'

// PRODUCTION
export const WEB_URL = 'https://go.shineinterview.com'
export const API_URL = 'https://api.shineinterview.com'
export const API_URLV2 = 'https://coreapi.shineinterview.com'
export const MEET_URL = 'https://meet.shineinterview.com'
export const SHARE_INTRO_URL = 'https://hi.shineinterview.com'
export const CANDIDATE_URL = 'https://candidate2.shineinterview.com'
export const SELF_REGISTRATION_URL = 'https://jobs.shineinterview.com'
export const PUBLIC_API_URL = 'https://publicapi.shineinterview.com/'
export const CLIENT_PORTAL_URL = 'https://portal.shineinterview.com'
export const SCHEDULER_URL = 'https://scheduler2.shineinterview.com/#'
export const ENVIRONMENT = 'PRODUCTION'

// HARD-CODED RN & RAF ACCOUNT ID'S
export const RNYID = 2
export const RAFID = 3

//VERSION PRE-LOGIN HARD-CODED VALUE
export const VERSION = '. Version 2.8.0'

//BASE ENCODE
export const AUTHORIZATION_BASIC_ENCODE = 'Basic MDk5MTUzYzI2MjUxNDliYzhlY2IzZTg1ZTAzZjAwMjI6'

//BULK UPLOAD LIMIT
export const BULK_UPLOAD_LIMIT = 500
