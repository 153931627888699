<template>
  <mdb-modal
    id="commentModal"
    :show="showModal"
    style="z-index:10000;"
    @close="$emit('closeModal', input = '')"
  >
    <mdb-modal-header>
      <mdb-modal-title>ADD COMMENT</mdb-modal-title>
    </mdb-modal-header>

    <mdb-modal-body style="height: fit-content !important">
      <div class="md-form form-sm text-left">
        <i
          class="fas fa-edit prefix float-left"
          style="margin-top: -5px;font-size: 1.2rem;"
        />
        <div
          class="grey-text"
          style="margin-left: 26px;margin-bottom: -20px;margin-top:-25px;font-size: 0.9rem;"
          size="sm"
        >
          Your comment about this candidate:
        </div>

        <mdb-input
          v-model="input"
          class="mt-12 w-full ml-0"
          type="textarea"
          :rows="6"
          style="overflow-y:visible;width: 100% !important;"
        />

        <div
          v-if="includePrivateNoteOption"
           class="d-flex align-items-center ml-auto justify-content-end">
          <mdb-input
            id="foo"
            v-model="makePrivate"
            title="Prevent Other Users From Seeing This"
            type="checkbox"
            class="flex m-0 p-0"
          />
          <div
            style="font-size: 0.9rem; transform: translateY(12px)"
            class="ml-0 grey-text">
            Private Comment?
          </div>
        </div>
      </div>
    </mdb-modal-body>

    <mdb-modal-footer style="display:block;">
      <mdb-btn
        type="reset"
        class="float-left btn-outline"
        size="sm"
        style="width:100px;"
        @click="$emit('cancelAddComment'), input = ''"
      >
        CANCEL
      </mdb-btn>

      <mdb-btn
        v-if="!waitingUpdate"
        type="submit"
        :disabled="!input.length"
        class="float-right"
        size="sm"
        style="width:100px;"
        @click="$emit('addEditComment', input), input = ''"
      >
        <span>SAVE</span>
      </mdb-btn>

      <mdb-btn
        v-else
        class="float-right"
        size="sm"
        style="width:100px;"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    waitingUpdate: {
      type: Boolean,
      default: false
    },
    includePrivateNoteOption: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      input: '',
      makePrivate: false
    }
  },
  watch: {
    makePrivate () {
      this.$emit('makePrivate', this.makePrivate)
    },
    showModal () {
      if (this.showModal) {
        this.makePrivate = false
      }
    }
  }
}
</script>
