<template>
  <!--generic error modal -->
  <mdb-modal
    id="genericErrorModal"
    :show="showing"
    @close="close()"
  >
    <mdb-modal-header>
      <mdb-modal-title>{{ errorTitle.toUpperCase() }}</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
        {{ errorMessage }}
      </p>
    </mdb-modal-body>
    <mdb-modal-footer>
      <mdb-btn
        color="primary"
        size="sm"
        style="width:100px;"
        @click.native="close()"
      >
        CLOSE
      </mdb-btn>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import { useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'GenericErrorModal',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  setup () {
    const statusStore = useStatus()
    const { closeGenericErrorModal, closeGenericConfirmModal } = useStatus()
    const { genericErrorModal } = storeToRefs(statusStore)

    return {
      closeGenericErrorModal,
      closeGenericConfirmModal,
      genericErrorModal
    }
  },
  computed: {
    errorTitle () {
      if (this.genericErrorModal.errorTitle === '') {
        return 'Error'
      } else { return this.genericErrorModal.errorTitle }
    },
    errorMessage () {
      if (this.genericErrorModal.errorMessage === '') {
        return 'Sorry, something went wrong'
      } else { return this.genericErrorModal.errorMessage }
    },
    showing () {
      return this.genericErrorModal.showing
    }
  },
  watch: {
    showing () {
      if (this.showing) {
        this.closeGenericConfirmModal()
      }
    }
  },
  methods: {
    close () {
      this.closeGenericErrorModal()
    }
  }
}
</script>
