<template>
  <div>
    <mdb-modal
      id="roleSharingModalShowing"
      :show="roleSharingModalShowing"
      size="lg"
      style="overflow-y:auto;"
      @close="closeModal()"
    >
      <mdb-modal-header>
        <h5>SHARE</h5>
      </mdb-modal-header>
      <div style="width:100%;height:115px;">
        <!--hidden for mobile need to work out mobile based toggle between each element of sharing setup-->
        <div class="d-flex justify-content-center border-center" style="justify-content: space-between !important;position: relative;">
          <div class="mx-2 mx-md-4 mt-2 mt-md-4" style="text-align: -webkit-center;">
            <a
              :class="{ 'btn-blank-primary': sharingStep === 0, 'btn-blank-outline': sharingStep != 0 }"
              type="button"
              class="white-text d-flex justify-content-center align-items-center btn-floating btn-sm ripple-parent"
              @click="sharingStep = 0"
            >
              <i
                v-if="tickedStep > -1"
                style="cursor:pointer;"
                class="fas fa-check primary-colour"
              />
            </a>
            <span class="d-none d-sm-block" style="font-size:12px;color:black;text-decoration:underline;">Candidates</span>
            <i style="cursor:pointer;color:black;" class="fas fa-users d-sm-none" />
          </div>
          <div class="mx-2 mx-md-4 mt-2 mt-md-4" style="text-align: -webkit-center;">
            <a
              :class="{ 'btn-blank-primary': sharingStep === 1, 'btn-blank-outline': sharingStep != 1 }"
              type="button"
              class="white-text d-flex justify-content-center align-items-center btn-floating btn-sm ripple-parent"
              @click="tickedStep > -1 ? sharingStep = 1 : null"
            >
              <i
                v-if="tickedStep > 0"
                style="cursor:pointer;"
                class="fas fa-check primary-colour"
              />
            </a>
            <span class="d-none d-sm-block" style="font-size:12px;color:black;text-decoration:underline;">Share With</span>
            <i style="cursor:pointer;color:black;" class="fas fa-user-plus d-sm-none" />
          </div>
          <div class="mx-2 mx-md-4 mt-2 mt-md-4" style="text-align: -webkit-center;">
            <a
              :class="{ 'btn-blank-primary': sharingStep === 2, 'btn-blank-outline': sharingStep != 2 }"
              type="button"
              class="white-text d-flex justify-content-center align-items-center btn-floating btn-sm ripple-parent"
              @click="tickedStep > 0 ? sharingStep = 2 : null"
            >
              <i
                v-if="tickedStep > 1"
                style="cursor:pointer;"
                class="fas fa-check primary-colour"
              />
            </a>
            <span class="d-none d-sm-block" style="font-size:12px;color:black;text-decoration:underline;">Information</span>
            <i style="cursor:pointer;color:black;" class="fas fa-info-circle d-sm-none" />
          </div>
          <div class="mx-2 mx-md-4 mt-2 mt-md-4" style="text-align: -webkit-center;">
            <a
              :class="{ 'btn-blank-primary': sharingStep === 3, 'btn-blank-outline': sharingStep != 3 }"
              type="button"
              class="white-text d-flex justify-content-center align-items-center btn-floating btn-sm ripple-parent"
              @click="tickedStep > 1 ? sharingStep = 3 : null"
            >
              <i
                v-if="tickedStep > 2"
                style="cursor:pointer;"
                class="fas fa-check primary-colour"
              />
            </a>
            <span class="d-none d-sm-block" style="font-size:12px;color:black;text-decoration:underline;">Settings</span>
            <i style="cursor:pointer;color:black;" class="fas fa-sliders-h  d-sm-none" />
          </div>
        </div>
        <div style="background:#e1e1e1;height:1px;width:calc(100% - 80px);transform:translateY(-94px)" class="d-none d-sm-block mx-auto mt-5" />
        <div style="background:#e1e1e1;height:1px;width:calc(100% - 80px);transform:translateY(-100px)" class="d-sm-none mx-auto mt-5" />
      </div>
      <!--timeline line-->
      <div class="video-modal-body">
        <!--candidates page-->
        <div v-if="sharingStep == 0">
          <SlideFade>
            <CandidatesPage
              :role-id="roleId"
              :candidates-added-to-share="candidatesAddedToShare"
              @updateCandidatesAddedToShare="candidatesAddedToShare = $event"
              @sharingPageChange="sharingPageChange($event)"
            />
          </SlideFade>
        </div>
        <!--share with page-->
        <div v-if="sharingStep == 1">
          <SlideFade>
            <ShareWithPage />
          </SlideFade>
        </div>
        <!--information page-->
        <div v-if="sharingStep == 2">
          <SlideFade>
            <InformationPage
              :share-type="shareType"
              :roleWorkflowStageId="roleWorkflowStageId"
              :candidates-added-to-share="candidatesAddedToShare"
              @selectedQuestionIds="selectedQuestionIds = $event"
              @shareIntroIncomplete="shareIntroIncomplete = $event"
              @infoPageComplete="informationPageIncomplete = $event"
            />
          </SlideFade>
        </div>
        <!--settings page-->
        <div v-if="sharingStep == 3">
          <SlideFade>
            <SettingsPage
              :share-type="shareType"
              :candidates-added-to-share="candidatesAddedToShare"
              @settingsLoading="settingsLoading = $event"
              @allowedDomain="shareSettings.allowedDomain = $event"
              @shareName="shareSettings.shareName = $event"
              @enableTrackingNotifications="shareSettings.enableTrackingNotifications = $event"
              @enableDomainAuthSharing="shareSettings.enableDomainAuthSharing = $event"
              @includeCVInfo="shareSettings.includeCVInfo = $event"
              @includeBioInfo="shareSettings.includeBioInfo = $event"
              @includeOtherFiles="shareSettings.includeOtherFiles = $event"
              @includeEducationInfo="shareSettings.includeEducationInfo = $event"
              @includeSalaryInfo="shareSettings.includeSalaryInfo = $event"
              @includePersonalInfo="shareSettings.includePersonalInfo = $event"
              @includeContactInfo="shareSettings.includeContactInfo = $event"
              @includeLinkedIn="shareSettings.includeLinkedIn = $event"
              @requestFeedback="shareSettings.requestFeedback = $event"
              @overrideMasking="shareSettings.overrideMasking = $event"
            />
          </SlideFade>
        </div>
      </div>
      <!-- candidate footer -->
      <mdb-modal-footer v-if="sharingStep === 0" class="d-flex justify-content-end p-0 m-0">
        <b-row
          align-v="center"
          :class="{ 'nxtBtn': mobileView }"
          align-h="end"
          no-gutters
          style="background:#C0C0C0;width: 100%;height:60px;"
          class="m-0 p-0"
        >
          <mdb-btn
            type="reset"
            class="btn btn-sm p-0"
            size="md"
            :disabled="candidatesAddedToShare.length == 0"
            style="width:60px; height:40px;"
            @click="sharingPageChange(1)"
          >
            NEXT
          </mdb-btn>
        </b-row>
      </mdb-modal-footer>

      <!-- sharewith -->
      <mdb-modal-footer v-if="sharingStep === 1" class="d-flex justify-content-end p-0 m-0">
        <b-row
          align-v="center"
          :class="{ 'nxtBtn': mobileView }"
          align-h="end"
          no-gutters
          style="background:#C0C0C0;width: 100%;height:60px;"
          class="m-0 p-0"
        >
          <mdb-btn
            type="reset"
            class="btn btn-sm p-0"
            :disabled="sharingWithPage.emailSubject == '' || sharingWithPage.emailContent == '' || (sharingWithPage.standardShareWith.length == 0 && sharingWithPage.blindShareWith.length == 0)"
            size="md"
            style="width:60px; height:40px;"
            @click="sharingPageChange(2)"
          >
            NEXT
          </mdb-btn>
        </b-row>
      </mdb-modal-footer>

      <!-- informationfooter -->
      <mdb-modal-footer v-if="sharingStep === 2" class="d-flex justify-content-end p-0 m-0">
        <b-row
          align-v="center"
          :class="{ 'nxtBtn': mobileView }"
          align-h="between"
          no-gutters
          style="background:#C0C0C0;width: 100%;height:60px;"
          class="m-0 p-0"
        >
          <div
            v-if="shareIntroIncomplete && !candidateInfoIncomplete"
            class="text-black ml-3"
            style="font-size:0.7rem;max-width:70%;"
          >
            * Please add a share introduction
          </div>
          <div v-if="(!shareIntroIncomplete && !candidateInfoIncomplete)" />
          <mdb-btn
            type="reset"
            :disabled="informationPageIncomplete"
            class="btn btn-sm p-0 ml-auto"
            size="md"
            style="width:60px; height:40px;"
            @click="sharingPageChange(3)"
          >
            NEXT
          </mdb-btn>
        </b-row>
      </mdb-modal-footer>

      <mdb-modal-footer v-if="sharingStep === 3" class="d-flex justify-content-end p-0 m-0">
        <b-row
          v-if="!settingsLoading"
          align-v="center"
          :class="{ 'nxtBtn': mobileView }"
          align-h="end"
          no-gutters
          style="background:#C0C0C0;width: 100%;height:60px !important;"
          class="m-0 p-0"
        >
          <mdb-btn
            class="btn btn-sm p-0"
            size="md"
            style="width:80px; height:40px;"
            @click="showConfirmShareNowModal()"
          >
            SHARE NOW
          </mdb-btn>
        </b-row>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import CandidatesPage from './Pages/Candidates/candidates.vue'
import InformationPage from './Pages/Information/information.vue'
import SettingsPage from './Pages/settings.vue'
import ShareWithPage from './Pages/shareWith.vue'
import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'
import candidateV2Service from '@/api/services/candidateV2.service'
import { useRoles, useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'

export default {
  name: 'RoleShareModal',
  components: {
    CandidatesPage,
    InformationPage,
    SettingsPage,
    ShareWithPage,
    SlideFade
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const rolesStore = useRoles()
    const {
      roleSharingModalShowing,
      roleSharingModalInitialData,
      sharingWithPage,
      sharingVideoIntro,
      sharingTextIntro
    } = storeToRefs(rolesStore)

    const { mobileView } = useMobile()

    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()

    return {
      authData,
      sharingWithPage,
      sharingVideoIntro,
      sharingTextIntro,
      mobileView,
      userData,
      roleSharingModalShowing,
      roleSharingModalInitialData,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal
    }
  },
  data () {
    return {
      informationPageIncomplete: true,
      loading: { isLoading: false, message: '' },
      sharingStep: 0,
      tickedStep: -1,
      viewingCandIndex: 0,
      shareSettings: {
        shareName: '',
        enableTrackingNotifications: false,
        enableDomainAuthSharing: false,
        includeCVInfo: true,
        includeBioInfo: true,
        includeOtherFiles: true,
        includeSalaryInfo: true,
        includeEducationInfo: true,
        includeContactInfo: true,
        includePersonalInfo: true,
        includeLinkedIn: true,
        requestFeedback: false,
        overrideMasking: false,
        allowedDomain: ''
      },
      settingsLoading: false,
      shareIntroIncomplete: true,
      candidateInfoIncomplete: true,
      candidates: [],
      candidatesAddedToShare: [],
      selectedQuestionIds: []
    }
  },
  computed: {
    noOfCands () {
      return this.candidatesAddedToShare.length
    },
    roleId () {
      return this.roleSharingModalInitialData.roleId
    },
    candToShare () {
      return this.roleSharingModalInitialData.candToShare
    },
    shareType () {
      // one-way or two-way
      return this.roleSharingModalInitialData.shareType
    },
    roleWorkflowStageId () {
      return this.roleSharingModalInitialData.roleWorkflowStageId
    }
  },
  watch: {
    // will reset progress if all candidates are removed
    candidatesAddedToShare: function () {
      if (this.candidatesAddedToShare.length === 0) { this.sharingStep = 0; this.tickedStep = -1 }
    },
    // will reset progress if all information is removed
    sharingWithPage: function () {
      if (this.sharingWithPage.emailSubject === '' || this.sharingWithPage.emailContent === '' || this.sharingWithPage.standardShareWith.length === 0) { this.sharingStep = 1; this.tickedStep = 0 }
    },
    candToShare () {
      // sets up the initial candidate the first time the modal is opened but wont override if more have been added when modal closed
      if (this.candToShare && this.candidatesAddedToShare.length === 0) {
        this.candidatesAddedToShare = [this.candToShare]
      }
    },
    roleSharingModalShowing () {
      if (this.roleSharingModalShowing === false) { this.candidatesAddedToShare = [] }
    }
  },
  methods: {
    showConfirmShareNowModal () {
      const confirmMessage = `Are you sure you want to process sharing ${this.noOfCands > 1 ? 'these': 'this' } candidate${this.noOfCands > 1 ? 's': '' } with the selected recipient(s)?`
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'SHARE NOW',
        confirmMessage,
        confirmButtonMessage: 'CONFIRM',
        confirmButtonFunction: () => this.shareNow()
      })
    },
    closeModal () {
      this.resetShareModal()
      this.roleSharingModalShowing = false
    },
    resetShareModal () {
      this.roleSharingModalInitialData = {}
      Object.assign(this.$data, this.$options.data())
    },
    sharingPageChange (value) {
      this.sharingStep = value
      if (value > this.tickedStep) {
        this.tickedStep = value - 1
      }
    },
    async shareNow () {
      const salaryAndSummary = {}
      for (const i in this.candidatesAddedToShare) {
        const cand = this.candidatesAddedToShare[i]
        const id = cand.CandidateId
        salaryAndSummary[id] = [cand.SalaryExpectation, cand.CandidateSummary]
      }
      const JSONData = {
        SalaryAndSummary: JSON.stringify(salaryAndSummary),
        CandidateIds: JSON.stringify(this.candidatesAddedToShare.map(c => c.CandidateId)),
        EmailContent: this.sharingWithPage.emailContent,
        EmailSubject: this.sharingWithPage.emailSubject,
        ShareWithList: JSON.stringify(this.sharingWithPage.standardShareWith),
        BlindShareWithList: JSON.stringify(this.sharingWithPage.blindShareWith),
        IntroVideoFileName: this.sharingVideoIntro.length > 0 ? this.sharingVideoIntro[0].FileName : '',
        IntroText: this.sharingTextIntro,
        EnableDomainAuthSharing: this.shareSettings.enableDomainAuthSharing,
        EnableTrackingNotifications: this.shareSettings.enableTrackingNotifications,
        IncludeBioInfo: this.shareSettings.includeBioInfo,
        IncludeCV: this.shareSettings.includeCVInfo,
        IncludeLinkedIn: this.shareSettings.includeLinkedIn,
        IncludeOtherFiles: this.shareSettings.includeOtherFiles,
        IncludeEducationInfo: this.shareSettings.includeEducationInfo,
        IncludeContactInfo: this.shareSettings.includeContactInfo,
        IncludeSalaryInfo: this.shareSettings.includeSalaryInfo,
        IncludePersonalInfo: this.shareSettings.includePersonalInfo,
        AllowedDomain: this.shareSettings.allowedDomain,
        RequestFeedback: this.shareSettings.requestFeedback,
        OverrideRoleMaskingSettings: this.shareSettings.overrideMasking,
        ShareName: this.shareSettings.shareName,
        RoleId: this.roleId,
        RoleWorkflowStageId: this.roleWorkflowStageId,
        ShareType: this.shareType == 'one-way' ? 1 : this.shareType == 'two-way' ? 2 : -1,
        SelectedQuestionIds: JSON.stringify(this.selectedQuestionIds)
      }
      try {
        await candidateV2Service.shareCandidates(JSONData)
      } catch (err) {
        console.log(Object.assign({}, err))
        this.showGenericErrorModal({ showing: true })
      }
      this.closeGenericConfirmModal()
      this.loading = { isLoading: false, message: '' }
      this.roleSharingModalShowing = false
      this.resetShareModal()
      this.$nextTick(() => {
        this.tickedStep = -1
        this.sharingStep = 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .nxtBtn {
    height:65px !important;
    margin-top:0px !important;
  }
  .fullH {
    height: 100vh !important;
  }
</style>
