<template>
  <SlideFade>
    <MeetingConfiguration v-if="queryMatches('meeting_name_and_type') && loadingStage == false" />
  </SlideFade>
</template>

<script>
import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'
import MeetingConfiguration from './meetingConfiguration.vue'
import roleV2Service from '@/api/services/roleV2.service'
import { ref } from 'vue'
import { useUserData, useStatus, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'TwoWayWorkFlowContainer',
  components: { SlideFade, MeetingConfiguration },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const workflowStore = useWorkflow()
    const { selectedWorkflowStage, selectedWorkflowStageId, role } = storeToRefs(workflowStore)

    const { showGenericErrorModal } = useStatus()

    const loadingStage = ref(true)

    return {
      authData,
      showGenericErrorModal,
      selectedWorkflowStage,
      selectedWorkflowStageId,
      loadingStage,
      role
    }
  },
  async created () {
    await this.getRole()
    this.getRoleWorkflowStage(this.$route.params.roleWorkflowStageId)
  },
  methods: {
    async getRoleWorkflowStage (roleWorkflowStageId) {
      try {
        const res = await roleV2Service.getRoleWorkflowStage(roleWorkflowStageId)
        this.selectedWorkflowStage = res.data
        this.selectedWorkflowStageId = this.$route.params.roleWorkflowStageId
        this.$nextTick(() => {
          this.loadingStage = false
        })
      } catch {
        this.showGenericErrorModal()
      }
    },
    async getRole () {
      try {
        const res = await roleV2Service.getRole(this.$route.params.jobId)
        this.role = res.data
      } catch (err) {
        this.showGenericErrorModal()
      }
    },
    queryMatches (query) {
      return this.$route.query.page === query
    }
  }
}
</script>
