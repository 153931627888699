<template>
  <div style="width:100px;" class="ml-auto primary-colour d-flex justify-content-end align-items-center">
    <i class="fa fa-share-alt mr-2" /><span class="font-weight-bold mr-2"> SHARE</span>
    <mdb-popover
      ref="popover"
      trigger="click"
      :options="{placement: 'left'}"
      style="cursor:pointer;"
      class="grey-text"
    >
      <div slot="body">
        <h6
          style="margin-left:-10px;padding:12px;"
          class="text-nowrap"
          @click="prepareAndShowShareModal()"
        >
          <i class="fa fa-share ml-1 grey-link" style="margin-right:10px;" /><a class="grey-link">Share Candidate</a>
        </h6>
        <QuickSharePopoverButton
          v-if="RoleWorkflowStageType === 1 || RoleWorkflowStageType === 2"
          :role-id="RoleId"
          :RoleWorkflowStageId="RoleWorkflowStageId"
          :RoleWorkflowStageType="RoleWorkflowStageType"
          :candidate-id="CandidateId"
          @closePopover="$refs.popover.doClose()"
        />
      </div>
      <mdb-icon
        slot="reference"
        style="width: 20px; height:30px; color:#555;font-size:1rem;"
        class="d-flex align-items-center justify-content-center ellipsisButtonHover text-black"
        icon="ellipsis-v"
      />
    </mdb-popover>
  </div>
</template>

<script>
import QuickSharePopoverButton from '@/components/misc/quickSharePopoverButton.vue'

export default {
  name: 'SharePopover',
  components: {
    QuickSharePopoverButton
  },
  props: {
    CandidateId: {
      type: Number,
      default: 0
    },
    RoleId: { type: Number, default: null },
    RoleWorkflowStageId: { type: String, default: null },
    RoleWorkflowStageType: { type: Number, default: null }
  },
  data () {
    return {
    }
  },
  methods: {
    prepareAndShowShareModal () {
      this.$emit('openShareModal')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
