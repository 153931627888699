<template>
  <div class="flex flex-col px-8">
    <div class="flex justify-between pt-4">
      <CircleNavigationItem
        v-for="(nav, i) in navigationItems"
        :key="i"
        :query-to-match="$route.query.page"
        :nav-item-count="navigationItems.length"
        :nav-item="nav"
        @clicked="handleButtonNavigation(nav.Step)"
      />
    </div>

    <component :is="$route.query.page" />

    <div class="flex flex-row justify-between pt-4">
      <mdb-btn
        class="btn btn-outline btn-sm h-8 w-32 "
        @click="back()"
      >
        BACK
      </mdb-btn>

      <div v-if="currentPage === 'events'">
        <mdb-btn
          v-if="isValidated && eventSubscription.Name !== ''"
          class="btn-sm h-8 w-32"
          @click="saveEventSubscription(eventSubscription)"
        >
          NEXT
        </mdb-btn>
        <mdb-btn
          v-else
          class="btn-sm h-8 w-32"
          disabled
        >
          NEXT
        </mdb-btn>
      </div>

      <div
        v-if="currentPage === 'authentication' && eventSubscription.ConnectionType === 1 && eventSubscription.RequireAuthentication"
        style="margin-bottom:30px;"
      >
        <mdb-btn
          v-if="isValidated && eventSubscription.ConnectionType > 0 && eventSubscription.ConnectionMethod > 0 && eventSubscription.ConnectionUrl !== '' && eventSubscription.ClientId !== '' && eventSubscription.ClientSecret !== ''"
          class="btn-sm h-8 w-32"
          @click="saveEventSubscription(eventSubscription)"
        >
          NEXT
        </mdb-btn>
        <mdb-btn
          v-else
          class="btn-sm h-8 w-32"
          disabled
        >
          NEXT
        </mdb-btn>
      </div>
      <div
        v-if="currentPage === 'authentication' && eventSubscription.ConnectionType === 2 && eventSubscription.RequireAuthentication"
        style="margin-bottom:30px;"
      >
        <mdb-btn
          v-if="isValidated && eventSubscription.ConnectionType > 0 && eventSubscription.ConnectionMethod > 0 && eventSubscription.ConnectionUrl !== '' && eventSubscription.ServiceEndPointEnvelope !== ''"
          class="btn-sm h-8 w-32"
          @click="saveEventSubscription(eventSubscription)"
        >
          NEXT
        </mdb-btn>
        <mdb-btn
          v-else
          class="btn-sm h-8 w-32"
          disabled
        >
          NEXT
        </mdb-btn>
      </div>
      <div
        v-if="currentPage === 'authentication' && eventSubscription.RequireAuthentication === false"
        style="margin-bottom:30px;"
      >
        <mdb-btn
          class="btn-sm h-8 w-32"
          @click="saveEventSubscription(eventSubscription)"
        >
          NEXT
        </mdb-btn>
      </div>

      <div v-if="currentPage === 'dataForm'">
        <mdb-btn
          v-if="isValidated && eventSubscription.PayloadType > 0 && eventSubscription.ServiceEndPoint !== ''"
          class="btn-sm h-8 w-32"
          @click="saveEventSubscription(eventSubscription)"
        >
          FINISH
        </mdb-btn>
        <mdb-btn
          v-else
          class="btn-sm h-8 w-32"
          disabled
        >
          FINISH
        </mdb-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue"
import CircleNavigationItem from '@/components/misc/circleNavigationItems.vue'
import Events from '@/views/settings/innerComponents/settingsTabs/eventSubscriptions/form/events'
import Authentication from '@/views/settings/innerComponents/settingsTabs/eventSubscriptions/form/authentication'
import DataForm from '@/views/settings/innerComponents/settingsTabs/eventSubscriptions/form/data'
import eventSubscriptionService from '@/api/services/eventsubscription.service'

import { useRouter, useRoute } from 'vue-router/composables'
import { useEventSubscription, useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'AddEventSubscription',
  components: {
    CircleNavigationItem,
    Events,
    Authentication,
    DataForm
  },
  setup () {
    const router = useRouter()
    const route = useRoute()

    const eventSubscriptionStore = useEventSubscription()
    const { isValidated, oneWaySettings, twoWaySettings, selfScheduleSettings } = storeToRefs(eventSubscriptionStore)
    var { eventSubscription } = storeToRefs(eventSubscriptionStore)

    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const currentPage = computed(() => route.query.page)

    const navigationItems = ref([
      { Step: 1, Query: 'events', Text: 'Events', ActualStep: 0 },
      { Step: 2, Query: 'authentication', Text: 'Authentication', ActualStep: 1 },
      { Step: 3, Query: 'dataForm', Text: 'Data', ActualStep: 2 }
    ])

    const handleButtonNavigation = (step) => {
      if (step === 1) router.push('/settings/eventsubscriptions/add?page=events')
      if (step === 2) router.push('/settings/eventsubscriptions/add?page=authentication')
      if (step === 3) router.push('/settings/eventsubscriptions/add?page=dataForm')
    }

    return {
      authData,
      currentPage,
      navigationItems,
      oneWaySettings,
      selfScheduleSettings,
      twoWaySettings,
      handleButtonNavigation,
      eventSubscription,
      isValidated
    }
  },
  created () {
    this.setOptions(this.eventSubscription)
  },
  methods: {
    setOptions (sub) {
      if (sub.Complete) {
        this.navigationItems = ref([
          { Step: 1, Query: 'events', Text: 'Events', ActualStep: 3 },
          { Step: 2, Query: 'authentication', Text: 'Authentication', ActualStep: 3 },
          { Step: 3, Query: 'data', Text: 'Data', ActualStep: 3 }
        ])
      }
    },
    async saveEventSubscription (eventSubscription) {
      try {
        const res = await eventSubscriptionService.saveEventSubscription(eventSubscription)
        eventSubscription.EventSubscriptionId = res.data

        if (this.currentPage === 'events') {
          this.$router.push('/settings/eventsubscriptions/add?page=authentication')
          this.navigationItems = [
            { Step: 1, Query: 'events', Text: 'Events', ActualStep: 1 },
            { Step: 2, Query: 'authentication', Text: 'Authentication', ActualStep: 1 },
            { Step: 3, Query: 'dataForm', Text: 'Data', ActualStep: 2 }
          ]
        }
        else if (this.currentPage === 'authentication') {
          this.$router.push('/settings/eventsubscriptions/add?page=dataForm')
          this.navigationItems = [
            { Step: 1, Query: 'events', Text: 'Events', ActualStep: 1 },
            { Step: 2, Query: 'authentication', Text: 'Authentication', ActualStep: 2 },
            { Step: 3, Query: 'dataForm', Text: 'Data', ActualStep: 2 }
          ]
        }
        else if (this.currentPage === 'dataForm') {
          this.$router.push('/settings/eventsubscriptions')
          this.navigationItems = [
            { Step: 1, Query: 'events', Text: 'Events', ActualStep: 1 },
            { Step: 2, Query: 'authentication', Text: 'Authentication', ActualStep: 2 },
            { Step: 3, Query: 'dataForm', Text: 'Data', ActualStep: 3 }
          ]
        }
      } catch (err) {
        //?
      }
    },
    back() {
      if (this.currentPage=== 'events') {
        this.$router.push('/settings/eventsubscriptions')
        this.navigationItems = [
          { Step: 1, Query: 'events', Text: 'Events', ActualStep: 0 },
          { Step: 2, Query: 'authentication', Text: 'Authentication', ActualStep: 1 },
          { Step: 3, Query: 'dataForm', Text: 'Data', ActualStep: 2 }
        ]
      }
      else if (this.currentPage === 'authentication') {
        this.$router.push('/settings/eventsubscriptions/add?page=events')
        this.navigationItems = [
          { Step: 1, Query: 'events', Text: 'Events', ActualStep: 0 },
          { Step: 2, Query: 'authentication', Text: 'Authentication', ActualStep: 1 },
          { Step: 3, Query: 'dataForm', Text: 'Data', ActualStep: 2 }
        ]
      }
      else if (this.currentPage === 'dataForm') {
        this.$router.push('/settings/eventsubscriptions/add?page=authentication')
        this.navigationItems = [
          { Step: 1, Query: 'events', Text: 'Events', ActualStep: 1 },
          { Step: 2, Query: 'authentication', Text: 'Authentication', ActualStep: 1 },
          { Step: 3, Query: 'dataForm', Text: 'Data', ActualStep: 2 }
        ]
      }
    }
  }
}
</script>

<style scoped>
div data-v-189f23e2 .form-check {
  align-items: center;
  flex: 1
}
</style>
