<template>
  <div class="w-100" style="height:100%">
    <!--grid view-->
    <div
      v-if="listView === false"
      v-show="videoData.IsDeleted === false"
      class="text-left mx-0"
      style="padding:0px !important;width:100%;"
    >
      <div
        v-if="videoData.Processing"
        class="mapImage"
        style="width:100%;background:#333;border-top-left-radius:5px;border-top-right-radius:5px;"
      >
        <img
          class="containerThumb"
          :src="videoData.ThumbnailUrl"
          alt="Placeholder"
          style="border-top-left-radius:5px;border-top-right-radius:5px;width:100%;"
        >
        <a
          class="pin pin1"
          style="border-top-left-radius:5px;border-top-right-radius:5px;"
          @click="playVideo(videoData.FileName)"
        ><mdb-icon icon="fa fa-pulse fa-spinner" /></a>
      </div>
      <div
        v-else
        class="mapImage"
        style="width:100%;background:#333;border-top-left-radius:5px;border-top-right-radius:5px;"
      >
        <img
          class="containerThumb"
          :src="videoData.ThumbnailUrl"
          alt="Placeholder"
          style="border-top-left-radius:5px;border-top-right-radius:5px;width:100%;"
        >
        <a
          v-show="videoData.ThumbnailUrl !== 'https://app.shineinterview.com/framework/images/library-silhouette.png'"
          class="pin pin1"
          style="border-top-left-radius:5px;border-top-right-radius:5px;"
          @click="playVideo(videoData.FileName)"
        ><span class="fa fa-play" /></a>
      </div>
      <div style="height:auto;width:100%;transform:translateY(-5px)" class="p-2">
        <div class="text-xl font-bold d-flex align-items-center">
          <div
            style="font-size:1rem;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;cursor:help;color:#333"
            class="mr-auto"
            :title="videoData.VideoTitle"
          >
            {{ videoData.VideoTitle }}
          </div>
          <mdb-popover
            ref="popover"
            trigger="click"
            :options="{placement: 'left'}"
            style="cursor:pointer;font-size:1rem;"
            class="grey-text"
          >
            <div slot="body">
              <!--<h6 style="margin-left:-7px;margin-bottom:5px;"><i class="fa fa-download ml-1 grey-link" style="margin-right:7px;" /><a   @click="download(video.FileName)" download="video" class="grey-link"> Download</a></h6>-->
              <h6 style="margin-left:-7px;margin-bottom:5px;" @click="editModalShow()">
                <i class="fa fa-file ml-1 grey-link" style="margin-right:10px;" /><a class="grey-link"> Edit</a>
              </h6>
              <h6 style="margin-left:-7px;margin-bottom:5px;" @click="playVideo(video.FileName);editThumb()">
                <i class="fa fa-image ml-1 grey-link" style="margin-right:6px;" /><a class="grey-link"> Change Thumb</a>
              </h6>
              <h6 style="margin-left:-7px;margin-bottom:5px;" @click="showDeleteModal = true">
                <i class="fa fa-times ml-1 grey-link" style="margin-right:10px;" /><a class="grey-link"> Delete</a>
              </h6>
              <EmbedVideo
                v-if="videoData.FileName !== ''"
                :fetch-src="() => getVideoUrl(videoData.FileName)"
                :src-is-async="true"
                :src="videoData.FileName"
                :video-title="videoData.VideoTitle"
                @closePopover="$refs.popover.doClose()"
              />
            </div>
            <mdb-icon
              slot="reference"
              title="More actions"
              class="ellipsisButtonHover p-1"
              style="color:#555;font-size:1rem;"
              icon="ellipsis-v"
            />
          </mdb-popover>
        </div>
        <div style="font-size:10px;" class="grey-text">
          {{ videoData.VideoTypeDesc }}
        </div>
        <div class="grey-text small mt-2">
          <i class="fa fa-calendar mr-1 col-span-1" /> <span> {{ new Date(videoData.LastUpdatedDate).toLocaleDateString([userLanguage]) }} {{ new Date(videoData.LastUpdatedDate).toLocaleTimeString([userLanguage]).substring(0, 5) }}</span><br>
          <i class="fa fa-user mr-1 col-span-1" /> <span>{{ videoData.LastUpdatedBy }}</span>
        </div>
      </div>
    </div>

    <!--list view-->
    <div
      v-else
      class="text-left mx-0"
      style="padding:0px !important;width:100%;"
    >
      <div class="d-flex flex-wrap justify-content-md-start" style="min-height:fit-content;width:100%;height:79px;">
        <div style="border:#a1a1a1 1px solid; height: 80px; width:100%; border-radius:1px;box-sizing:border-box; position:relative;" class="d-flex align-items-center justify-content-center listViewMobileWidth">
          <div
            v-if="videoData.Processing"
            style="background:#333;"
            class="d-sm-flex d-lg-flex align-items-center justify-content-center"
          >
            <a
              class="pin pin2"
              style="border-top-left-radius:5px;border-top-right-radius:5px;"
              @click="playVideo(videoData.FileName)"
            ><mdb-icon icon="fa fa-pulse fa-spinner" /></a>
          </div>
          <div
            v-else
            style="background:#333;"
            class="d-sm-flex d-lg-flex align-items-center justify-content-center"
          >
            <img
              :src="videoData.ThumbnailUrl"
              style="margin-top: 3px;margin-bottom: 2px;height:73px; width:123px;object-fit:cover;background:#333"
              @click="playVideo(video.FileName)"
            >
            <a
              v-show="videoData.ThumbnailUrl != 'https://app.shineinterview.com/framework/images/library-silhouette.png'"
              class="pin pin2"
              style="border-top-left-radius:5px;border-top-right-radius:5px;"
              @click="playVideo(video.FileName)"
            ><span class="fa fa-play" /></a>
          </div>
          <div class="d-flex mx-auto font-weight-bold titleSize rolesTitleSize" style="width:100%;text-align:left;margin-left:10px !important;">
            <span v-if="videoData.VideoTitle < 10" style="color:#333;font-size:1.2rem;">{{ videoData.VideoTitle }}</span>
            <span v-if="videoData.VideoTitle > 9 && role.JobDetails.JobTitle < 28" style="color:#333;font-size:1.2rem;">{{ videoData.VideoTitle }}</span>
            <span v-if="videoData.VideoTitle > 27" style="color:#333;font-size:1rem;">{{ videoData.VideoTitle }}</span>
            <span v-else style="color:#333;font-size:1rem;">{{ videoData.VideoTitle }}</span>
          </div>
          <div class="webDisplay flex-column justify-content-center align-items-start mx-md-1 mx-lg-3" style="width:fit-content;">
            <div style="font-size:0.8rem;" class="d-flex flex-nowrap">
              <div style="color:#333" class="ml-1 text-nowrap d-inline">
                <i class="fa fa-calendar mr-1 col-span-1" /> <span> {{ new Date(videoData.LastUpdatedDate).toLocaleDateString([userLanguage]) }} {{ new Date(videoData.LastUpdatedDate).toLocaleTimeString([userLanguage]).substring(0, 5) }}</span><br>
                <i class="fa fa-user mr-1 col-span-1" /> <span>{{ videoData.LastUpdatedBy }}</span>
              </div>
            </div>
          </div>
          <div style="z-index:1000;" class="mx-1">
            <mdb-popover
              ref="popover"
              trigger="click"
              :options="{placement: 'left'}"
              style="cursor:pointer;"
              class="grey-text"
            >
              <div slot="body">
                <!--<h6 style="margin-left:-7px;margin-bottom:5px;"><i class="fa fa-download ml-1 grey-link" style="margin-right:7px;" /><a   @click="download(video.FileName)" download="video" class="grey-link"> Download</a></h6>-->
                <h6 style="margin-left:-7px;margin-bottom:5px;" @click="editModalShow()">
                  <i class="fa fa-file ml-1 grey-link" style="margin-right:10px;" /><a class="grey-link"> Edit</a>
                </h6>
                <h6 style="margin-left:-7px;margin-bottom:5px;" @click="playVideo(videoData.FileName);editThumb()">
                  <i class="fa fa-image ml-1 grey-link" style="margin-right:6px;" /><a class="grey-link"> Change Thumb</a>
                </h6>
                <h6 style="margin-left:-7px;margin-bottom:5px;" @click="showDeleteModal = true">
                  <i class="fa fa-times ml-1 grey-link" style="margin-right:10px;" /><a class="grey-link"> Delete</a>
                </h6>
                <h6 class="mobDisplay" style="margin-left:-7px;margin-bottom:5px;">
                  <i class="fa fa fa-calendar ml-1 grey-link" style="margin-right:10px;" /><a class="grey-link"> {{ new Date(videoData.LastUpdatedDate).toLocaleDateString([userLanguage]) }} {{ new Date(video.LastUpdatedDate).toLocaleTimeString([userLanguage]).substring(0, 5) }}</a>
                </h6>
                <h6 class="mobDisplay" style="margin-left:-7px;margin-bottom:5px;">
                  <i class="fa fa fa-user ml-1 grey-link" style="margin-right:10px;" /><a class="grey-link"> {{ videoData.LastUpdatedBy }}</a>
                </h6>
                <EmbedVideo
                  v-if="videoData.FileName !== ''"
                  :fetch-src="() => getVideoUrl(videoData.FileName)"
                  :src-is-async="true"
                  :src="videoData.FileName"
                  :video-title="videoData.VideoTitle"
                  @closePopover="$refs.popover.doClose()"
                />
              </div>
              <mdb-icon
                slot="reference"
                title="More actions"
                class="ellipsisButtonHover p-1 right5"
                style="color:#555;font-size:1rem;"
                icon="ellipsis-v"
              />
            </mdb-popover>
          </div>
        </div>
      </div>
    </div>

    <!--update modal-->
    <mdb-modal
      id="uploadVideo"
      :show="showEditModal"
      @close="showEditModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>EDIT VIDEO</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <validation-observer>
          <form
            ref="form"
            @submit.prevent="updateVideo()"
            @reset.prevent="reset"
          >
            <div class="md-form form-sm text-left">
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="Video Title"
                rules="required"
              >
                <i class="fas fa-video prefix float-left" style="margin-top: 6px;font-size: 1.3rem;" />
                <mdb-input
                  v-model="videoData.VideoTitle"
                  type="text"
                  auto-complete="fna"
                  label="Video Title"
                  style="margin-left:33px;"
                  size="sm"
                />
                <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                  Please provide a title
                </div>
              </validation-provider>
            </div>
            <div class="md-form form-sm text-left">
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="Video Catgegory"
                rules="required"
              >
                <i class="fas fa-object-group prefix" style="margin-top: 6px;font-size: 1.3rem;" />
                <mdb-select
                  v-model="videoCategories"
                  :placeholder="videoData.VideoTypeDesc"
                  style="margin-left:33px;"
                  size="sm"
                />
                <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                  Please select a category
                </div>
              </validation-provider>
            </div>
            <div class="md-form form-sm text-left" style="margin-left:10px;margin-right:10px;">
              <i class="fas fa-tags prefix" style="margin-top: 6px;font-size: 1.3rem;" />
              <videotaginput
                :video-tags="videoTags"
                @removeTag="removeTag"
                @addTag="addTag"
              />
            </div>
          </form>
        </validation-observer>
      </mdb-modal-body>
      <mdb-modal-footer style="display:block;">
        <mdb-btn
          type="reset"
          class="float-left btn-outline"
          size="sm"
          style="width:100px;"
          @click="cancelEditModal()"
        >
          CANCEL
        </mdb-btn>
        <mdb-btn
          v-if="!waitingUpdate"
          type="submit"
          :disabled="(videoData.VideoTitle === null || videoData.VideoTitle === '') || (videoData.VideoTypeDesc === null || videoData.VideoTypeDesc === '')"
          class="float-right"
          size="sm"
          style="width:100px;"
          @click="updateVideo();waitingUpdate = true;"
        >
          <span>SAVE</span>
        </mdb-btn>
        <mdb-btn
          v-if="waitingUpdate"
          class="float-right"
          size="sm"
          style="width:100px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

    <!--video playback modal-->
    <mdb-modal
      id="playModal"
      :show="playModal"
      @close="playModal = false, editingThumb = false"
    >
      <mdb-modal-header>
        <mdb-modal-title v-show="editingThumb === false">
          {{ videoData.VideoTitle.toUpperCase() }}
        </mdb-modal-title>
        <mdb-modal-title v-show="editingThumb === true">
          UPDATE THUMBNAIL
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <div
          v-show="editingThumb === false"
          class="small grey-text text-left"
          style="margin-left:7px;"
        >
          {{ videoData.VideoTypeDesc }} Video
        </div>
        <div
          v-show="editingThumb === true"
          class="small grey-text text-left"
          style="margin-left:7px;"
        >
          Please choose a new frame then press Capture button
        </div>
        <div id="videoContainer" class="mx-auto p-2">
          <div style="background:black;">
            <video
              v-if="videoData.IsiOS"
              id="videoLibraryPreview"
              :src="videoUrl"
              controls
              playsinline
              class="video-fluid z-depth-1"
              style="outline:none;margin: 0px auto;border-radius: 2px;height: 250px;-moz-transform: none !important;background:black;"
              crossorigin=""
              @click="videoIsTouched()"
            />
            <video
              v-else
              id="videoLibraryPreview"
              :src="videoUrl"
              :poster="videoData.ThumbnailUrl"
              controls
              playsinline
              class="video-fluid z-depth-1"
              style="outline:none;margin: 0px auto;border-radius: 2px;height: 250px;-moz-transform: none !important;background:black;"
              crossorigin=""
              @click="videoIsTouched()"
            />
          </div>
          <canvas
            id="canvas"
            crossorigin=""
            style="display:none;"
          />
        </div>
      </mdb-modal-body>
      <mdb-modal-footer style="display:block;height:80px !important;">
        <div v-if="confirmThumb == false">
          <mdb-btn
            v-show="editingThumb === true"
            color="primary"
            size="sm"
            style="width:100px;"
            @click="captureThumb(videoData.GuidReference)"
          >
            CAPTURE
          </mdb-btn>
          <mdb-btn
            v-show="editingThumb === false"
            color="primary"
            size="sm"
            style="width:100px;"
            @click="playModal = false"
          >
            CLOSE
          </mdb-btn>
        </div>
        <div v-if="confirmThumb == true">
          <mdb-btn
            class="btn-outline"
            color="primary"
            size="sm"
            style="width:100px;"
            @click="resetAppendThumb()"
          >
            <i class="fa fa-redo" /> RESET
          </mdb-btn>
          <mdb-btn
            v-show="!buttonClick"
            color="primary"
            size="sm"
            style="width:100px;"
            @click="confirmAppendThumb()"
          >
            <i class="fa fa-check" /> SAVE
          </mdb-btn>
          <mdb-btn
            v-show="buttonClick"
            class="float-right"
            size="sm"
            style="width:100px;"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </div>
      </mdb-modal-footer>
    </mdb-modal>

    <!--error modal-->
    <mdb-modal
      id="errorModal"
      :show="errorModal"
      @close="errorModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>{{ errorModalTitle.toUpperCase() }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          <span v-html="error" />
        </p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="errorModal = false"
        >
          OK
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

    <!--delete modal-->
    <mdb-modal
      id="showDeleteModal"
      :show="showDeleteModal"
      @close="showDeleteModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>DELETE?</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          Are you sure you want to delete this video?
        </p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          v-show="!buttonClick"
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="deleteVideo"
        >
          YES
        </mdb-btn>
        <mdb-btn
          v-show="buttonClick"
          class="float-right"
          size="sm"
          style="width:100px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { useLibrary, useUserData } from '@/pinia'
import { useMobile } from '@/composables/useMobile'
import { storeToRefs } from 'pinia'
import libraryService from '@/api/services/library.service'
import { userLanguage } from '@/common/userLanguage'

import videotaginput from '../components/videoTagInput'
import EmbedVideo from '@/components/misc/embedVideo.vue'

export default {
  name: 'VideoLibraryCard',
  components: {
    videotaginput, EmbedVideo
  },
  props: {
    video: {
      type: Object,
      default: () => {}
    },
    userToken: {
      type: String,
      default: ''
    },
    listView: Boolean
  },
  setup () {
    const userStore = useUserData()
    const { userData, authData } = storeToRefs(userStore)

    const libraryStore = useLibrary()
    const { libraryNumbers } = storeToRefs(libraryStore)

    const { mobileView } = useMobile()

    return {
      libraryNumbers,
      userData,
      authData,
      mobileView
    }
  },
  data () {
    return {
      userLanguage,
      playModal: false,
      errorModal: false,
      showEditModal: false,
      showDeleteModal: false,
      waitingDelete: false,
      waitingUpdate: false,
      videoCategories: [{ text: 'Organisation', value: 1 }, { text: 'Job', value: 2 }, { text: 'Shareable', value: 3 }, { text: 'Introduction', value: 4 }, { text: 'Outro', value: 5 }, { text: 'Question', value: 6 }, { text: 'Candidate', value: 7 }, { text: 'All', value: 0 }],
      videoUrl: '',
      error: '',
      errorModalTitle: '',
      editingThumb: false,
      canvasImg: null,
      file: '',
      confirmThumb: false,
      formData: null,
      videoTags: [],
      buttonClick: false,
      videoData: this.video
    }
  },
  beforeMount () {
    if (this.videoData.Tags) {
      this.videoTags = this.videoData.Tags.split(', ') // should be this videotags?
    }
  },
  methods: {
    formatDate (dt) {
      const date = new Date(dt)
      const formattedDate = Intl.DateTimeFormat('gb').format(date)
      return formattedDate
    },
    editModalShow () {
      this.showEditModal = true
      this.waitingUpdate = false
    },
    cancelEditModal () {
      this.showEditModal = false
    },
    editThumb () {
      this.playModal = true
      this.editingThumb = true
    },
    async captureThumb (guid) {
      var video = document.getElementById('videoLibraryPreview')
      video.pause()
      var canvas = document.getElementById('canvas')
      var vw = video.videoWidth
      var vh = video.videoHeight
      canvas.width = vw
      canvas.height = vh
      canvas.getContext('2d').drawImage(video, 0, 0, vw, vh)
      canvas.toBlob(function (blob) {
        if (blob != null) {
          var newImg = document.createElement('img')
          newImg.crossOrigin = 'anonymous'
          var url = URL.createObjectURL(blob)
          this.videoData.ThumbnailUrl = url
          newImg.onload = function () {
            URL.revokeObjectURL(url)
          }
          this.confirmThumb = true
          this.formData = new FormData()
          this.formData.append('file', blob)
          this.formData.append('videoId', guid)
        } else {
          this.playModal = false
          this.editingThumb = false
          this.errorModalTitle = 'Error!'
          this.errorModal = true
          this.error = 'Sorry unable to edit thumb on this video!'
        }
      }.bind(this))
    },
    confirmAppendThumb () {
      this.appendThumb(this.formData)
      this.confirmThumb = true
    },
    appendThumb (formData) {
      this.buttonClick = true
      libraryService.appendThumb(formData).then((response) => {
        this.videoData.ThumbnailUrl = response.data
        this.playModal = false
        this.confirmThumb = false
        this.buttonClick = false
      }).catch(() => {
        this.playModal = false
        this.confirmThumb = false
        this.errorModalTitle = 'Error!'
        this.errorModal = true
        this.error = 'Sorry an error has occurred!'
        this.buttonClick = false
      })
    },
    resetAppendThumb () {
      var video = document.getElementById('videoLibraryPreview')
      video.load()
      this.confirmThumb = false
    },
    async deleteVideo () {
      this.buttonClick = true
      await libraryService.deleteVideo(this.videoData.GuidReference).then((response) => {
        this.videoData.IsDeleted = response.data
        this.showDeleteModal = false
        this.$parent.visibleLibrary.forEach(f => {
          if (this.videoData.GuidReference === f.GuidReference) {
            f.IsDeleted = true
            this.videoData.IsDeleted = true
          }
        })
        this.buttonClick = false
        libraryService.getLibraryNumbers().then((response) => {
          this.libraryNumbers = response.data
        }).catch(() => {
          //?
        })
      }).catch((err) => {
        try {
          const errObj = Object.assign({}, err)
          this.showDeleteModal = false
          this.errorModalTitle = 'Cannot Delete!'
          this.errorModal = true
          this.error = errObj.response.data.Message
          return
        } catch {
          //?
         }
        this.showDeleteModal = false
        this.errorModalTitle = 'Error!'
        this.errorModal = true
        this.error = 'Sorry an error has occurred!'
        this.buttonClick = false
      })
    },
    updateVideo () {
      if (this.videoTags === null) {
        this.videoTags = 'null'
      }
      libraryService.updateVideo(this.videoData.GuidReference, this.videoData.VideoTitle, this.videoData.VideoType, this.videoTags).then((response) => {
        this.videoData = response.data
        //this.videoData.ThumbnailUrl = response.data.ThumbnailUrl
        this.showEditModal = false
      }).catch(() => {
        this.showEditModal = false
        this.errorModalTitle = 'Error!'
        this.errorModal = true
        this.error = 'Sorry an error has occurred!'
      })
      this.waitingUpdate = false
    },
    videoIsTouched () {
    },
    removeTag (e) {
      this.videoTags.splice(e, 1)
    },
    addTag: function (e) {
      if (this.videoTags != null) {
        if (!this.videoTags.includes(e)) {
          this.videoTags.push(e)
          e = ''
        } else {
          this.videoTags.push(e)
          e = ''
        }
      }
    },
    playVideo (fileName) {
      libraryService.getUrl(fileName).then((response) => {
        this.videoUrl = response.data
        this.playModal = true
      }).catch(() => {
        this.playModal = false
        this.errorModalTitle = 'Error!'
        this.errorModal = true
        this.error = 'Sorry an error has occurred!'
      })
    },
    async getVideoUrl (fileName) {
      const res = await libraryService.getUrl(fileName)
      return res.data
    }
  }
}
</script>

<style lang="scss">
.pin2 {
  left: 41px;
  top: 22px;
  font-size: 1.5rem;
  opacity: 0.7;
}
.popover {
  z-index: 1000 !important;
}
@media (max-width: 576px) {
  #playModal .modal-dialog .modal-content {
    margin:5px !important;
    width:calc(100vw - 10px) !important;
    height: calc(100vh - 10px) !important;
  }
  #playModal .modal-dialog {
    margin:0px !important;
  }
}
@media (min-width: 577px) and (max-width: 999px) {
  #playModal .modal-content {
    margin: 5px !important;
    width: 70vw !important;
    height: 85vh !important;
    margin: 1.75rem auto !important;
    margin-top: 5% !important;
  }
  #playModal .modal-dialog {
    max-width: none !important;
  }
  #videoLibraryPreview {
    height: 48vh !important;
  }
}
@media (min-width: 1000px) {
  #playModal .modal-content {
    margin: 5px !important;
    width: 60vw !important;
    height: 75vh !important;
    margin: 1.75rem auto !important;
    margin-top: 5% !important;
  }
  #playModal .modal-dialog {
    max-width: none !important;
  }
  #videoLibraryPreview {
    height: 48vh !important;
  }
}
</style>
