<template>
  <mdb-modal
    v-show="avatarCropperModalShowing"
    id="avatarCropperModal"
    @close="$emit('closeAvatarCroppingModal')"
  >
    <mdb-modal-header>
      <mdb-modal-title>AVATAR IMAGE</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <div
        v-if="input == null && image == ''"
        style="height:100%"
        class="d-flex align-items-center justify-content-center flex-column"
      >
        <i
          v-if="existingPic === ''"
          class="fa fa-user fa-8x my-4"
          style="color:darkgrey;"
        />
        <img
          v-else
          :src="existingPic"
          style="object-fit:cover;display:block;border-radius:50%;width: 200px;height: 200px;border: solid;padding: 6px;margin-bottom: 20px;"
        >
        <div class="grey-text" style="font-size:12px;">
          Click upload to select an image for your avatar. Once uploaded you can resize.
        </div>
      </div>
      <div
        v-if="input != null || image != ''"
        style="height:100%"
        class="d-flex align-items-center justify-content-center"
      >
        <Cropper
          ref="cropper"
          class="upload-example-cropper"
          :src="image"
          :stencil-component="$options.components.CircularStencil"
        />
      </div>
      <input
        ref="file"
        type="file"
        style="visibility:hidden"
        accept=".jpg, .jpeg, .png"
        @change="uploadImage($event)"
      >
    </mdb-modal-body>
    <mdb-modal-footer style="height:80px !important">
      <div class="d-flex align-items-center justify-content-between" style="width:100%;height:60px;">
        <mdb-btn
          class="btn-outline mr-auto"
          size="sm"
          style="width:100px;"
          @click="$refs.file.click()"
        >
          UPLOAD
        </mdb-btn>
        <mdb-btn
          v-if="!loadingCropImage"
          :disabled="input == null && image == ''"
          size="sm"
          style="width:100px;"
          @click="cropImage()"
        >
          CONFIRM
        </mdb-btn>
        <mdb-btn
          v-else
          size="sm"
          style="width:100px;"
        >
          <i class="fa fa-spin fa-spinner" />
        </mdb-btn>
      </div>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import CircularStencil from './circularStencil.vue'

export default {
  name: 'AvatarCropper',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CircularStencil,
    Cropper
  },
  props: {
    imageFromProps: {
      default: '',
      type: String
    },
    avatarCropperModalShowing: Boolean,
    loadingCropImage: Boolean,
    existingPic: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      image: '',
      input: null
    }
  },
  watch: {
    // will close the modal after upload
    loadingCropImage () {
      if (!this.loadingCropImage && (this.input !== null || this.image !== '')) { this.$emit('closeAvatarCroppingModal') }
    }
  },
  created () {
    if (this.imageFromProps !== '') {
      this.image = this.imageFromProps
    }
  },
  methods: {
    async cropImage () {
      this.$emit('setLoadingCropImage', true)
      const result = this.$refs.cropper.getResult()
      const reducedSizedCanvasURL = result.canvas.toDataURL('image/jpeg', 0.2)
      fetch(reducedSizedCanvasURL)
        .then(res => res.blob())
        .then(blob => this.$emit('handleCrop', blob))
    },
    uploadImage (event) {
      this.input = event.target
      if (this.input.files && this.input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.image = e.target.result
        }
        reader.readAsDataURL(this.input.files[0])
      }
    }
  }
}

</script>

<style lang="scss">
.upload-example-cropper {
  border: solid 1px #EEE;
  height: 350px;
  width: 100%;
}
@media (max-width: 576px) {
  #avatarCropperModal .modal-content {
    margin: -3px !important;
    width: calc(100vw - 10px) !important;
    height: calc(100vh - 65px) !important;
  }
}
</style>
