<template>
  <div class="p-4">
    <div class="flex flex-row items-center mb-3">
      <div class="primary-colour flex font-bold h5 m-0">
        Scheduler Slot Management
      </div>
      <mdb-btn
        size="sm"
        style="font-size: .9rem"
        class="ml-auto btn-outline flex items-center justify-content-center"
        @click="openSlotModal()"
      >
        <mdb-icon
          icon="fa fa-cog mr-2"
          size="sm"
        />
        Scheduler Settings
      </mdb-btn>
    </div>
    <div
      class="grid m-auto"
      style="min-width:97%;"
      :class="mobileView ? 'grid-cols-1' : 'grid-cols-2'"
    >
      <div>
        <mdb-container
          class="p-0 pr-3"
          style="max-width:100%;margin-bottom:20px;">
          <div class="flex flex-row space-x-4 shadow-sm">
            <div
              v-for="tab in tabs"
              :key="tab.id"
              style="width:100% !important; "
              class="w-1/2 rounded-t-lg p-2 cursor-pointer hover:bg-gray-200 border border-b-0 text-sm sm:text-base"
              :class="activeTab === tab.id ? 'background-colour' : ''"
              @click="activeTab = tab.id"
            >
              <span :class="activeTab === tab.id ? 'secondary-colour' : 'primary-colour'">
                {{ tab.text }}
              </span>
            </div>
          </div>
          <div
            style="height:calc(100vh - 302px);"
            class="border overflow-y-scroll shadow rounded"
          >
            <component
              :is="activeTab"
              @hideForm="$emit('hideForm')"
            />
          </div>
        </mdb-container>
        <mdb-btn
          size="sm"
          color="secondary"
          class="mr-auto m-0 btn btn-outline text-xs w-1/3 sm:w-1/5"
          style="width:110px;float:left;"
          @click="$router.go(-1)"
        >
          CANCEL
        </mdb-btn>
      </div>
      <div>
        <TimeslotSelect />
        <mdb-btn
          size="sm"
          color="white"
          class="ml-auto m-0 text-xs w-1/3 sm:w-1/5"
          style="width:110px;float:right;margin-top:20px !important;"
          @click="$router.push(`/roles/add_edit/detail/${$route.params.jobId}`)"
        >
          FINISH
        </mdb-btn>
      </div>
    </div>

    <!-- MODALS -->
    <mdb-modal
      :show="slotModal"
      size="lg"
      class="overflow-y-scroll"
      @close="slotModal = false"
    >
      <mdb-modal-header class="text-lg">
        Scheduler Settings
      </mdb-modal-header>
      <SlotManagement
        class="p-6"
        @hideModal="slotModal = false"
      />
    </mdb-modal>
    <InsertVideoModal
      :defaultCategory="'Introduction'"
      @handleChosenVideo="handleChosenVideo"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import { useScheduler, useLibrary, useEvent, useWorkflow } from '@/pinia';
import { storeToRefs } from 'pinia';
import { useMobile } from '@/composables/useMobile'
import { useRoute } from 'vue-router/composables'

import AddSlots from '@/views/roles/innerComponents/Pages/AddEditRole/SchedulerSetup/addSlots';
import AddWorkingDay from '@/views/roles/innerComponents/Pages/AddEditRole/SchedulerSetup/addWorkingDay';
import TimeslotSelect from '@/views/roles/innerComponents/Pages/AddEditRole/SchedulerSetup/timeslotSelect';
import SlotManagement from '@/views/roles/innerComponents/Pages/AddEditRole/SchedulerSetup/slotManagement.vue';
import InsertVideoModal from '@/components/genericModals/InsertVideoModals/insertVideoModal.vue'

export default {
  name: 'Slots',
    components: {
    AddSlots,
    AddWorkingDay,
    TimeslotSelect,
    SlotManagement,
    InsertVideoModal
},
  setup () {
    const { mobileView } = useMobile()

    const workflowStore = useWorkflow()
    const { selectedWorkflowStage } = storeToRefs(workflowStore)

    const schedulerStore = useScheduler()
    const { getSchedulerSetup } = useScheduler()
    const { selectedSchedulerSetup, selectedVideo, introTextForm, expiryDays } = storeToRefs(schedulerStore)

    const libraryStore = useLibrary()
    const { showAddInsertVideoModal } = storeToRefs(libraryStore)
    const { setSelectedEvent } = useEvent()

    const route = useRoute()

    const activeTab = ref('AddSlots')
    const slotModal = ref(false)

    const handleChosenVideo = (video) => {
      selectedVideo.value = video
      showAddInsertVideoModal.value = false
    }

    const load = async () => {
      const res = await getSchedulerSetup(route.params.roleWorkflowStageId)
      if (!res) slotModal.value = true

      selectedSchedulerSetup.value.MaximumAmendmentsAllowed = res.MaximumAmendmentsAllowed
      selectedSchedulerSetup.value.MinimumAmendmentsPeriod = res.MinimumAmendmentsPeriod
      selectedSchedulerSetup.value.DaysAheadBookingAllowed = res.DaysAheadBookingAllowed
      selectedSchedulerSetup.value.DaysAheadBookingBlocked = res.DaysAheadBookingBlocked
      selectedSchedulerSetup.value.InvitationExpiry = res.InvitationExpiry

      if (res.IntroText) {
        introTextForm.value = true
        selectedSchedulerSetup.value.IntroText = res.IntroText
      }

      if (res.UploadedVideoId) {
        selectedVideo.value = res.UploadedVideo
        selectedVideo.value.ThumbnailUrl = res.UploadedVideo.Thumb
      }

      expiryDays.value = expiryDays.value.map(x =>
        x.value === selectedSchedulerSetup.value.InvitationExpiry
        ? { ...x, selected: true }
        : { ...x, selected: false })

      if (selectedWorkflowStage.value.StageType === 3) await setSelectedEvent(route.params.roleWorkflowStageId)
    }

    load()

    const openSlotModal = () => {
      load()
      slotModal.value = true
    }

    const tabs = [
      {
        text: 'Add Slots',
        id: 'AddSlots'
      }
    ]

    // hide add from working day for now
    //const tabs = [
    //  {
    //    text: 'Add Slots',
    //    id: 'AddSlots'
    //  },
    //  {
    //    text: 'Add From Working Day',
    //    id: 'AddWorkingDay'
    //  }
    //]

    return {
      activeTab,
      mobileView,
      selectedSchedulerSetup,
      tabs,
      slotModal,
      selectedVideo,
      handleChosenVideo,
      openSlotModal
    }
  }
}
</script>
