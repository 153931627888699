<template>
  <div>
    <mdb-modal-body :style="blobRecording ? {'margin-bottom': '-40px'} : {'margin-bottom': '0px'}">
      <div class="small grey-text text-left" style="margin-left:7px;margin-bottom:15px;">
        Record yourself a new video and add it to your video library. The maximum recording time is 5 minutes.
      </div>
      <div id="videoContainer" class="mx-auto p-2">
        <video
          v-if="!stopButtonPressed"
          ref="video"
          height="380"
          width="640"
          type="video/mp4"
          muted
          autoplay
          playsinline
          class="video-fluid z-depth-1"
          style="outline:none;margin: 0px;border-radius: 2px;height: 250px;object-fit: cover"
        />
        <video
          v-if="stopButtonPressed"
          ref="videoRec"
          height="380"
          width="640"
          type="video/mp4"
          controls
          playsinline
          class="video-fluid z-depth-1"
          style="outline:none;margin: 0px;border-radius: 2px;height: 250px;object-fit: cover"
        />
        <b-row no-gutters class="justify-content-end">
          <span
            id="time"
            style="margin-top:10px;"
            class="text-right grey-text"
          >5:00</span>
        </b-row>
        <b-row align-h="center">
          <mdb-btn
            v-if="!recordStart && !blobRecording && !settingVideoUpForQuestion"
            size="sm"
            color="primary"
            :disabled="!stream"
            style="width:110px;"
            @click="startRecord"
          >
            <i class="fa fa-circle" /> RECORD
          </mdb-btn>
          <mdb-btn
            v-if="recordStart && !blobRecording"
            size="sm"
            color="primary"
            style="width:110px;"
            @click="stopRecord"
          >
            <i class="fa fa-square" /> STOP
          </mdb-btn>
        </b-row>
      </div>
      <div v-show="blobRecording && !uploadSuccess && !uploadStarted">
        <validation-observer>
          <form
            ref="form"
            @submit.prevent="upload()"
            @reset.prevent="reset"
          >
            <div class="md-form form-sm text-left" style="margin-left:10px;margin-right:10px;">
              <i class="fas fa-video prefix float-left" style="margin-top: 6px;font-size: 1.3rem;" />
              <mdb-input
                v-model="title"
                type="text"
                auto-complete="fna"
                placeholder="Video Title"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form form-sm text-left" style="margin-left:10px;margin-right:10px;">
              <i class="fas fa-object-group prefix" style="margin-top: 6px;font-size: 1.3rem;" />
              <mdb-select
                v-model="videoCategories"
                placeholder="Select Category"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form form-sm text-left" style="margin-left:10px;margin-right:10px;">
              <i class="fas fa-tags prefix" style="margin-top: 6px;font-size: 1.3rem;" />
              <VideoTagInput
                input-font-size="0.9rem"
                :video-tags="videoTags"
                @removeTag="removeTag"
                @addTag="addTag"
              />
            </div>
          </form>
        </validation-observer>
      </div>
    </mdb-modal-body>
    <!-- footer -->
    <mdb-modal-footer v-show="(!uploadSuccess || uploadStarted) && blobRecording" style="display:block;">
      <b-row v-show="blobRecording && !uploadSuccess && !uploadStarted && !settingVideoUpForQuestion" align-h="between">
        <mdb-btn
          v-if="blobRecording && !uploadSuccess && !uploadStarted && !settingVideoUpForQuestion"
          size="sm"
          class="btn-outline"
          style="width:110px;"
          @click="resetVideo()"
        >
          <i class="fa fa-redo" /> RESETx
        </mdb-btn>
        <mdb-btn
          size="sm"
          :disabled="(title === null || title === '') || videoCategories.filter(i => i.selected).length === 0"
          style="width:110px;"
          @click="upload()"
        >
          <i class="fa fa-upload" /> FINISH
        </mdb-btn>
      </b-row>

      <b-row v-show="loading.isLoading" align-h="between">
        <mdb-progress
          v-if="uploadStarted || settingVideoUpForQuestion"
          indeterminate
          style="margin-right:10px;margin-top:10px;"
        />
        <div class="d-flex align-items-center justify-content-between" style="width:100%">
          <div
            v-if="loading && loading.isLoading"
            class="my-auto text-black text-right"
            style="font-size: small;"
          >
            {{ loading.message }}
          </div>
          <mdb-btn
            v-show="uploadStarted || settingVideoUpForQuestion"
            size="sm"
            style="width:110px;"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </div>
      </b-row>
    </mdb-modal-footer>
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'

import VideoTagInput from '@/components/videoTagInput.vue'
import UploadFile from '@/upload'

export default {
  name: 'RecordVideoQuestionModal',
  components: {
    VideoTagInput
  },
  props: {
    setTextOrVideoQuestionModal: {
      type: Function,
      default: () => {}
    },
    defaultCategory: {
      type: String,
      default: ''
    }
  },
  setup () {
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)

    const statusStore = useStatus()
    const { loading } = storeToRefs(statusStore)

    return {
      userData,
      loading
    }
  },
  data () {
    return {
      blobRecording: null,
      recordChunks: [],
      recordStart: false,
      recordVideo: true,
      settingVideoUpForQuestion: false,
      stopButtonPressed: false,
      stream: null,
      time: 300,
      timer: null,
      title: null,
      uploadStarted: false,
      uploadSuccess: false,
      videoCategories: [{ text: 'Organisation', value: 1 }, { text: 'Job', value: 2 }, { text: 'Shareable', value: 3 }, { text: 'Introduction', value: 4 }, { text: 'Outro', value: 5 }, { text: 'Question', value: 6 }],
      videoTags: []
    }
  },
  mounted () {
    this.videoCategories = this.videoCategories.map(q => q.text === this.defaultCategory ? { ...q, selected: true } : { ...q, selected: false })
  },
  created () {
    if (this.recordVideo === true && this.stopButtonPressed === false) {
      this.turnOnCamera()
    } else {
      this.turnOffCamera()
    }
  },
  destroyed () { this.turnOffCamera() },
  methods: {
    async goToQuestionPage () {
      this.$emit('handleRecordedVideo')
    },
    addTag: function (e) {
      if (this.videoTags != null) {
        if (!this.videoTags.includes(e) && e !== '') {
          this.videoTags.push(e)
          e = ''
        } else {
          e = ''
        }
      }
    },
    pad (val) {
      return val > 9 ? val : '0' + val
    },
    recordButton () {
      this.stopButtonPressed = false
      this.title = ''
      this.videoTags = []
      this.videoCategories = [{ text: 'Organisation', value: 1 }, { text: 'Job', value: 2 }, { text: 'Shareable', value: 3 }, { text: 'Introduction', value: 4 }, { text: 'Outro', value: 5 }, { text: 'Question', value: 6, selected: true }, { text: 'All', value: 0 }]
      if (this.recordVideo === true) {
        this.recordVideo = false
        this.turnOffCamera()
      } else {
        this.recordVideo = true
      }
    },
    removeTag (e) {
      this.videoTags.splice(e, 1)
    },
    resetVideo () {
      this.uploadSuccess = false
      this.recordStart = false
      clearInterval(this.timer)
      this.time = 300
      try {
        var display = document.querySelector('#time')
        display.textContent = '5:00'
      } catch (e) {}
      this.stopButtonPressed = false
      this.title = ''
      this.videoTags = []
      this.stream = null
      this.videoCategories = [{ text: 'Organisation', value: 1 }, { text: 'Job', value: 2 }, { text: 'Shareable', value: 3 }, { text: 'Introduction', value: 4 }, { text: 'Outro', value: 5 }, { text: 'Question', value: 6, selected: true }, { text: 'All', value: 0 }]
      this.blobRecording = null
      this.turnOnCamera()
    },
    startRecord () {
      var display = document.querySelector('#time')
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time--
            var t = this.time / 60
            var minutes = parseInt(t)
            var seconds = Math.round((t - minutes) * 60)
            display.textContent = this.pad(minutes) + ':' + this.pad(seconds)
          } else {
            clearInterval(this.timer)
            this.stopRecord()
          }
        }, 1000)
      }
      this.stopButtonPressed = false
      const vm = this
      const record = new MediaRecorder(this.stream)
      this.recorder = record
      this.recorder.ondataavailable = function (recdata) {
        vm.recordChunks.push(recdata.data)
      }
      this.recorder.onstop = function () {
        this.stopButtonPressed = true
        const newBlob = new Blob(vm.recordChunks, { type: 'video/webm' })
        const video = vm.$refs.videoRec
        video.srcObject = null
        video.src = URL.createObjectURL(newBlob)
        video.load()
        vm.recordChunks = []
        vm.blobRecording = newBlob
        this.blobRecording = newBlob
      }
      this.recorder.start()
      this.recordStart = true
    },
    stopRecord () {
      clearInterval(this.timer)
      this.timer = null
      this.isRunning = false
      this.recorder.stop()
      this.recordStart = false
      this.stopButtonPressed = true
      this.turnOffCamera()
      if (this.recorder !== null) {
        if (this.recorder.stream != null) {
          this.recorder.stream.getTracks().forEach(track => track.stop())
        }
      }
    },
    async turnOnCamera () {
      try {
        const mstream = await navigator.mediaDevices.getUserMedia({
          video: {
            advanced: [
              { width: { exact: 4096 } },
              { width: { exact: 3840 } },
              { width: { exact: 2560 } },
              { width: { exact: 1920 } },
              { width: { exact: 1280 } },
              { width: { exact: 1024 } },
              { width: { exact: 900 } },
              { width: { exact: 800 } },
              { width: { exact: 640 } },
              { width: { exact: 320 } }
            ]
          },
          audio: true
        })
        this.stream = mstream
        this.$refs.video.srcObject = mstream
      } catch (e) {
        this.turnOffCamera()
      }
    },
    async turnOffCamera () {
      if (this.stream !== null) {
        this.stream.getTracks().forEach(track => track.stop())
        this.stream = null
        try {
          this.$refs.video.srcObject = null
        } catch {}
      }
    },
    async upload () {
      this.loading = { isLoading: true, message: 'Uploading video' }
      const data = this.blobRecording
      const cat = this.videoCategories.filter(i => i.selected)
      const vData = {
        userEmail: this.userData.une,
        videoType: cat[0].value,
        shortDescription: this.title,
        videoTitle: this.title,
        availableToAll: false,
        allowShare: false,
        selectedVideo: '',
        hasForm: false,
        videoText: '',
        videoTags: this.videoTags.join(', ')
      }
      this.uploadStarted = true
      const uploadSuccess = await UploadFile.uploadVideo(0, data, vData)
      this.uploadSuccess = uploadSuccess
      if (uploadSuccess) {
        this.goToQuestionPage()
        this.turnOffCamera()
      }
    }
  }
}
</script>
