<template>
  <div
    v-if="text"
    class="stageItemTypeBubble">
    {{text}}
  </div>
</template>

<script>
export default {
  name: 'StageItemTypeBubble',
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>
.stageItemTypeBubble {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: clamp(0.8rem, 2vw, 1.1rem);
  border: var(--pr-color) solid 3px;
  border-radius: 15px;
  padding: 2px 12px;
  color: var(--pr-color);
  height: 30px;
}
</style>