import axiosInstance from '../instance.js'
const api1 = new axiosInstance(1)

export default class scoringService {
  static async saveScale (data) {
    return await api1.post('/api/go/role_scoring/savescale', data)
  }

  static async getAllScales () {
    return await api1.get('/api/go/role_scoring/getallscales')
  }

  static async deleteScale (scaleId) {
    return await api1.delete(`/api/go/role_scoring/deletescale?scaleId=${scaleId}`)
  }

  static async saveTemplate (formData) {
    return await api1.post('/api/go/role_scoring/savetemplate', formData)
  }

  static async getTemplate (templateId, allowDeleted) {
    return await api1.get(`/api/go/role_scoring/gettemplate?templateId=${templateId}&allowDeleted=${allowDeleted}`)
  }

  static async getAllTemplates (includeDeleted = false) {
    return await api1.get(`/api/go/role_scoring/getalltemplates?includeDeleted=${includeDeleted}`)
  }

  static async deleteTemplate (templateId) {
    return await api1.delete(`/api/go/role_scoring/deletetemplate?templateId=${templateId}`)
  }

  static async makeTemplateDefault (templateId) {
    return await api1.post(`/api/go/role_scoring/maketemplatedefault?templateId=${templateId}`, {})
  }

  static async saveScore (formData) {
    return await api1.post('/api/go/role_scoring/savescore', formData)
  }

  static async deleteScore (formData) {
    return await api1.post('/api/go/role_scoring/deletescore', formData)
  }

  static async getQuestionScores (templateId, userWhoScoredId, candidateId, questionId, roleId) {
    return await api1.get(`/api/go/role_scoring/getrolescoringscores?templateId=${templateId}&userWhoScoredId=${userWhoScoredId}&candidateId=${candidateId}&questionId=${questionId}&roleId=${roleId}`)
  }

  static async getScoreOverview (roleId, candidateId) {
    return await api1.get(`/api/go/role_scoring/getscoreoverview?roleId=${roleId}&candidateId=${candidateId}`)
  }

  static async checkScalesHaveBeenAdded () {
    return await api1.get('/api/go/role_scoring/checkscaleshavebeenadded')
  }
}
