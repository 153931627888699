<template>
  <div>
    <div
      class="p-2 m-2 mt-0 d-flex flex-row align-items-center"
      style="border: solid 2px #e1e1e1;border-radius: 10px;max-width: 100%;"
    >
      <div class="d-flex flex-row align-items-center">
        <FontAwesomeIcon
          icon="fa-regular fa-circle-chevron-left"
          style="scale:1.4;"
          class="cp mr-3"
          @click="setView()"
        />
        <div
          v-if="!isRoleSummary"
          class="rounded-xl w-10 h-10 d-flex align-items-center justify-content-center mr-2"
          style="border: solid 1px #c0c0c0;"
        >
          <span class="font-weight-bold"> {{ number }} </span>
        </div>
      </div>
      <div
        v-if="!isRoleSummary"
        class="d-flex flex-column mr-2 align-self-center text-left"
        style="white-space:pre-wrap;max-width: 65%;"
      >
        <span class="text-left font-bold text-lg"> Overall Question Score </span>
        <span class="text-left flex-wrap"> {{ text }}</span>
      </div>
      <div
        v-else
        class="font-weight-bold"
        style="font-size:1.2rem;"
      >
        Overall Score
      </div>
      <div
        class="flex flex-wrap align-items-center justify-content-end ml-auto"
      >
        <div v-if="!(score == 0 && maxScore == 0)" class="flex-none border rounded-xl p-3  m-1 d-none d-lg-inline">
          <span v-if="!loading">
            <span v-if="templateId > 0">{{ score }} / {{ maxScore }}</span>
            <span v-else>n/a</span>
          </span>
          <div v-else>
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </div>
        </div>
        <div v-if="templateId > 0" class="border rounded-xl p-2 p-lg-3 m-1">
          <span v-if="!loading">
            <span>{{ percentage }}</span>
          </span>
          <div v-else>
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      default: 0
    },
    text: {
      type: String,
      default: ''
    },
    score: {
      type: Number,
      default: 0
    },
    maxScore: {
      type: Number,
      default: 1
    },
    loading: Boolean,
    isRoleSummary: {
      type: Boolean,
      default: false
    },
    templateId: {
      type: Number,
      default: 0
    }
  },

  computed: {
    percentage () {
      if (this.score === 0) {
        return `${0}%`
      } else {
        const p = (this.score / this.maxScore) * 100
        return `${p.toFixed(0)}%`
      }
    }
  },

  methods: {
    setView () {
      this.$parent.$parent._data.showDetail = false
    }
  }
}
</script>
