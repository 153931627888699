import axiosInstance from '../instance.js'
const api1 = new axiosInstance(1)

export default class clientPortalService {
  static async getAllClients () {
    return await api1.get('/api/go/clientportal/getallclients')
  }

  static async getClientPortalClient (clientId) {
    return await api1.get(`/api/go/clientportal/getclient?clientId=${clientId}`)
  }

  static async addEditClientPortalClient (formData) {
    return await api1.post('/api/go/clientportal/addeditclient', formData)
  }

  static async getRecentClients () {
    return await api1.get('/api/go/clientportal/getrecentclients')
  }

  static async addClientToRecentClients (clientId) {
    return await api1.post(`/api/go/clientportal/addclienttorecentclients?clientId=${clientId}`, {})
  }

  static async getAllSpotlights (clientId) {
    return await api1.get(`/api/go/clientportal/getallspotlights?clientId=${clientId}`)
  }

  static async getSpotlight (spotlightId) {
    return await api1.get(`/api/go/clientportal/getspotlight?spotlightId=${spotlightId}`)
  }

  static async addEditSpotlight (formData) {
    return await api1.post('/api/go/clientportal/addeditspotlight', formData)
  }

  static async getRecentSpotlights (clientId) {
    return await api1.get(`/api/go/clientportal/getrecentspotlights?clientId=${clientId}`)
  }

  static async addSpotlightToRecentSpotlights (spotlightId) {
    return await api1.post(`/api/go/clientportal/addspotlighttorecentspotlights?spotlightId=${spotlightId}`, {})
  }

  static async getCandidatesToAddToRoleSpotlight (spotlightId, roleId) {
    return await api1.get(`/api/go/clientportal/getcandidatestoaddtorolespotlight?spotlightId=${spotlightId}&roleId=${roleId}`)
  }

  static async getCandidatesToAddToVCVSpotlight (spotlightId, location, sector, salaryLowerBoundary, salaryUpperBoundary) {
    return await api1.get(`/api/go/clientportal/getcandidatestoaddtovcvspotlight?spotlightId=${spotlightId}&location=${location}&sector=${sector}&salaryLowerBoundary=${salaryLowerBoundary}&salaryUpperBoundary=${salaryUpperBoundary}`)
  }

  static async getSpotlightCandidates (spotlightId) {
    return await api1.get(`/api/go/clientportal/getspotlightcandidates?spotlightId=${spotlightId}`)
  }

  static async getClientPortalVCVReviewModalInformation (candidateId) {
    return await api1.get(`/api/go/clientportal/getvcvreviewmodalinformation?candidateId=${candidateId}`)
  }

  static async addCandidatesToRoleSpotlight (formData) {
    return await api1.post('/api/go/clientportal/addcandidatestorolespotlight', formData)
  }

  static async addCandidatesToVCVSpotlight (formData) {
    return await api1.post('/api/go/clientportal/addcandidatestovcvspotlight', formData)
  }

  static async updateSpotlightCandidateStatus (formData) {
    return await api1.post('/api/go/clientportal/updatespotlightcandidatestatus', formData)
  }

  // add or edit a comment
  static async addVCVSpotlightComment (formData) {
    return await api1.post('/api/go/clientportal/addvcvspotlightcomment', formData)
  }

  // delete videoCV spotlight comment
  static async deleteVCVComment (commentId) {
    return await api1.delete('/api/go/clientportal/deletevcvspotlightcomment?commentId=' + commentId)
  }

  // get all comments
  static async getVCVSpotlightComments (clientId, questionId, candidateId) {
    return await api1.get(`/api/go/clientportal/getvcvspotlightcomments?clientId=${clientId}&questionId=${questionId}&candidateId=${candidateId}`)
  }

  static async deleteClient (clientId) {
    return await api1.delete(`/api/go/clientportal/deleteclient?clientId=${clientId}`)
  }

  static async deleteSpotlight (spotlightId) {
    return await api1.delete(`/api/go/clientportal/deletespotlight?spotlightId=${spotlightId}`)
  }

  static async removeCandidateFromSpotlight (spotlightId, candidateId, roleId) {
    return await api1.post(`/api/go/clientportal/removecandidatefromspotlight?spotlightId=${spotlightId}&candidateId=${candidateId}&roleId=${roleId}`, {})
  }
}
