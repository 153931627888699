// Eventually delete this, and base the mobileView value off screensize as in MSC project
import { ref } from 'vue'
const mobileView = ref(false)
const tabletView = ref(false)
const windowHeight = ref(window.innerHeight)
const windowWidth = ref(window.innerWidth)

const checkIsMobile = () => {
  if (/mobile/i.test(navigator.userAgent)) {
    if ((/\b(iPad)\b/.test(navigator.userAgent) && /WebKit/.test(navigator.userAgent) && !window.MSStream) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints && navigator.maxTouchPoints === 5)) {
      mobileView.value = false
    } else {
      mobileView.value = true
    }
  } else {
    if (/(tablet|playbook)|(android(?!.*(mobi|opera mini)))/i.test(navigator.userAgent)) {
      mobileView.value = false
    } else {
      mobileView.value = false
    }
  }
  
  //ipad air
  if (windowHeight.value === 713 && windowWidth.value === 1180) {
    tabletView.value = true
  }
  //ipad air
  if (windowHeight.value === 1180 && windowWidth.value === 820) {
    tabletView.value = true
  }
  //ipad pro
  if (windowHeight.value === 1366 && windowWidth.value === 1024) {
    tabletView.value = true
  }
  //android tablet workaround
  if (windowHeight.value >= 820 && windowWidth.value === 800) {
    tabletView.value = true
  }
  if (windowHeight.value === 1152 && windowWidth.value === 864) {
    tabletView.value = true
  }
  if (windowHeight.value === 1280 && windowWidth.value === 600) {
    tabletView.value = true
  }
  if (windowHeight.value === 1280 && windowWidth.value === 720) {
    tabletView.value = true
  }
  if (windowHeight.value === 1280 && windowWidth.value === 768) {
    tabletView.value = true
  }
  if (windowHeight.value === 1280 && windowWidth.value === 800) {
    tabletView.value = true
  }
  if (windowHeight.value === 1280 && windowWidth.value === 960) {
    tabletView.value = true
  }
  if (windowHeight.value === 1280 && windowWidth.value === 1024) {
    tabletView.value = true
  }
  if (windowHeight.value === 1024 && windowWidth.value === 768) {
    tabletView.value = true
  }
  if (windowHeight.value > 600 && windowWidth.value > 600 && windowWidth.value <= 1440) {
    tabletView.value = true
  }
  //small laptops
  if (windowHeight.value === 1080 && windowWidth.value === 1920) {
    tabletView.value = false
  }
}

export function useMobile () {
  return {
    checkIsMobile,
    mobileView,
    tabletView,
    windowHeight,
    windowWidth
  }
}
