<template>
  <div class="px-3 px-md-5" style="text-align:left;padding:2px;margin-top:50px;">
    <!--step 7: retry options-->
    <div class="h5 grey-text">
      Preparation Time & Retries
    </div>
    <validation-observer v-slot="{}">
      <div class="md-form form-sm text-left">
        <div class="md-form form-sm d-flex align-items-center">
          <div @click="setReadingTime = !setReadingTime">
            <mdb-input
              v-model="setReadingTime"
              type="checkbox"
              label="Set preparation time?"
              style="margin-left:-25px;"
              size="sm"
            />
          </div>
          <div style="padding-top:20px;">
            <Tooltip
              class="mt-3"
              style="display: inline"
              tooltip-direction="left"
              :tool-tip-summary="`Having seen the question, ${!setReadingTime ? 'candidates will have unlimited time to prepare an answer.' : 'preparation time will be restricted'}`"
            />
          </div>
        </div>
      </div>
      <div
        v-show="setReadingTime === true && selectedReadingTimeType === 1"
        class="md-form form-sm text-left ml-4"
        style="margin-top:50px;"
      >
        <i class="fas fa-clock prefix float-left" style="margin-top: 6px;font-size: 1.3rem;" />
        <mdb-input
          v-model="readingTimeS"
          type="number"
          name="seconds"
          auto-complete="fna"
          label="Select number of seconds"
          style="margin-left:33px;"
          size="sm"
          :max="59"
          :min="5"
        />
      </div>
      <div
        v-show="setReadingTime === true && selectedReadingTimeType === 2"
        class="md-form form-sm text-left ml-4"
        style="margin-top:50px;"
      >
        <i class="fas fa-clock prefix float-left" style="margin-top: 6px;font-size: 1.3rem;" />
        <mdb-input
          v-model="readingTime"
          type="number"
          name="minutes"
          auto-complete="fna"
          label="Select number of minutes"
          style="margin-left:33px;"
          size="sm"
          :max="20"
          :min="1"
        />
      </div>
      <div v-show="setReadingTime === true" class="md-form form-sm text-left ml-4">
        <i class="fas fa-clock prefix float-left" style="margin-top: 6px;font-size: 1.3rem;" />
        <mdb-select
          v-model="readingTimeType"
          style="margin-left:33px;"
          label="Select seconds or minutes"
          size="sm"
        />
      </div>
      <div
        v-if="setReadingTime === true"
        class="md-form form-sm d-flex align-items-center ml-4"
        @click="pressureWatchVideoQuestion = !pressureWatchVideoQuestion"
      >
        <mdb-input
          :value="pressureWatchVideoQuestion"
          type="checkbox"
          label="Pressure Watch Video Questions"
          style="margin-left:-25px;"
          size="sm"
        />
        <Tooltip
          class="mt-3"
          style="display: inline"
          tooltip-direction="left"
          tool-tip-summary="For video questions, preparation time does not begin until the video has been watched fully. <br /> <br />
                Checking this setting will enforce candidates to begin watching the video question within 30 seconds. If they have not began the video within this time,
                they will be moved on to preparation time. <br /> <br />At this point they will still be able to see the question text."
        />
      </div>
      <div class="md-form form-sm text-left mb-5 mt-5">
        <div class="md-form form-sm d-flex align-items-center" @click="toggleDisablePlayback">
          <mdb-input
            v-model="disablePlayback"
            type="checkbox"
            label="Disable Playback?"
            :disabled="setRetries === false"
            style="margin-left:-25px;"
            size="sm"
          />
          <Tooltip
            class="mt-3"
            style="display: inline"
            tooltip-direction="left"
            :tool-tip-summary="`Having recorded an answer, ${!disablePlayback ?
              'candidates will be able to replay their response. Disabled if retries are not set.' :
              'candidates will not be able to see their response. Disabled if retries are not set.'}`"
          />
        </div>
      </div>
      <div class="md-form form-sm text-left mt-5" :class="{'mb-5': !setRetries }">
        <div class="md-form form-sm d-flex align-items-center" @click="toggleSetRetries">
          <mdb-input
            v-model="setRetries"
            type="checkbox"
            label="Allow retries?"
            style="margin-left:-25px;"
            size="sm"
          />
          <Tooltip
            class="mt-3"
            style="display: inline"
            tooltip-direction="left"
            :tool-tip-summary="`Allow candidates to record their answer again? Note: enabling this allows candidates to playback their answer.`"
          />
        </div>
      </div>
      <div
        v-show="setRetries === true"
        class="md-form form-sm text-left ml-4"
        style="margin-top:50px;"
      >
        <i class="fas fa-question prefix float-left" style="margin-top: 6px;font-size: 1.3rem;" />
        <mdb-select
          v-model="retryCount"
          auto-complete="fna"
          label="Number of retries"
          style="margin-left:33px;"
          size="sm"
        />
      </div>
      <div
        v-if="setRetries === true"
        class="md-form form-sm text-left ml-4"
        :class="{'mb-5': !setPressuredRetry }"
      >
        <div class="md-form form-sm d-flex align-items-center" @click="toggleSetPressureRetry()">
          <mdb-input
            v-model="setPressuredRetry"
            :disabled="selectedRetryCount === 0"
            type="checkbox"
            label="Pressured Retries?"
            style="margin-left:-25px;"
            size="sm"
          />
          <Tooltip
            class="mt-3"
            style="display: inline"
            tooltip-direction="left"
            tool-tip-summary="This is powerful tool for restricting the time candidates have to review their answers. Once a candidate has
                  finished recording, a time sensitive countdown will start, allowing them limited time to consider using any remaining retries. When the time has run out, the answer will be automatically accepted.
                  <br /> <br/><strong class='font-weight-bolder'>TIP: </strong> We reccommend using this setting in conjuntion with a preparation time otherwise the candidate will still have unlimited time per question."
          />
        </div>
      </div>
      <div
        v-show="setPressuredRetry === true && setRetries === true"
        class="md-form form-sm text-left mb-5"
        style="margin-top:50px;margin-left:60px;"
      >
        <i class="fas fa-question prefix float-left" style="margin-top: 6px;font-size: 1.3rem;" />
        <mdb-select
          v-model="pressuredRetryTime"
          auto-complete="fna"
          label="Time candidate has to review their answer before it is automatically submitted."
          style="margin-left:33px;"
          size="sm"
        />
      </div>
      <div class="d-flex align-items-center justify-content-between mt-3 mb-5">
        <mdb-btn
          class="btn btn-outline"
          size="sm"
          style="width:110px;"
          @click="$router.push(`/roles/add_edit/detail/${role.JobId}`)"
        >
          CANCEL
        </mdb-btn>
        <mdb-btn
          v-show="!buttonClick"
          :disabled="setReadingTime === true && computedReadingTime < 10"
          type="submit"
          size="sm"
          style="width:100px;"
          @click="updateLocalSetupAndNext()"
        >
          NEXT
        </mdb-btn>
        <mdb-btn
          v-show="buttonClick"
          size="sm"
          style="width:100px;cursor:default;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import Tooltip from '@/components/misc/tooltip.vue'
import roleV2Service from '@/api/services/roleV2.service'
import { useUserData, useStatus, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'ReadingAndRetries',
  components: {
    Tooltip
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const workflowStore = useWorkflow()
    const { role, selectedWorkflowStage } = storeToRefs(workflowStore)

    const { showGenericErrorModal } = useStatus()

    return {
      authData,
      showGenericErrorModal,
      role,
      selectedWorkflowStage
    }
  },
  data () {
    return {
      setReadingTime: false,
      setRetries: false,
      readingTime: 10,
      readingTimeS: 10,
      readingTimeType: [
        { text: 'Seconds', value: 1, selected: true },
        { text: 'Minutes', value: 2 }
      ],
      retryCount: [
        { text: 'No Limit', value: 999, selected: true },
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: '5', value: 5 },
        { text: '6', value: 6 },
        { text: '7', value: 7 },
        { text: '8', value: 8 },
        { text: '9', value: 9 },
        { text: '10', value: 10 }
      ],
      localSetup: {},
      setPressuredRetry: false,
      pressuredRetryTime: [
        { text: '15 Seconds', value: 15, selected: true },
        { text: '30 Seconds', value: 30 },
        { text: '45 Seconds', value: 45 },
        { text: '60 Seconds', value: 60 },
        { text: 'Question response time', value: 99 }
      ],
      disablePlayback: false,
      pressureWatchVideoQuestion: false,
      buttonClick: false
    }
  },
  computed: {
    computedReadingTime () {
      if (this.setReadingTime === false) { return 0 }
      if (this.selectedReadingTimeType === 2) {
        return this.readingTime * 60
      } else { return Number(this.readingTimeS) }
    },
    selectedRetryCount () {
      try {
        return this.retryCount.filter(r => r.selected)[0].value
      } catch {
        return 999
      }
    },
    selectedReadingTimeType () {
      return this.readingTimeType.filter(r => r.selected)[0].value
    },
    selectedPressuredRetryTime () {
      try {
        return this.pressuredRetryTime.filter(r => r.selected)[0].value
      } catch {
        return 15
      }
    }
  },
  watch: {
    setReadingTime () {
      if (this.setReadingTime === false) {
        this.readingTimeS = 0
        this.readingTime = 0
        this.pressureWatchVideoQuestion = false
      }
    },
    setRetries () {
      if (this.setRetries === false) {
        this.setPressuredRetry = false
      } else if (this.selectedWorkflowStage.AllowRetries === false) {
        this.retryCount = this.retryCount.map(r => r.value === 999 ? { ...r, selected: true } : { ...r, selected: false })
      }
    },
    selectedRetryCount () {
      if (this.selectedRetryCount === 0) {
        this.setPressuredRetry = false
      }
    }
  },
  created () {
    this.localSetup = { ...this.selectedWorkflowStage.OneWaySetup }
    this.initDataFromSetup()
    // the question page backend doesnt actually set the step so we just set it front end
  },
  methods: {
    toggleDisablePlayback () {
      if (this.setRetries) {
        this.disablePlayback = !this.disablePlayback
      }
    },
    toggleSetRetries () {
      this.setRetries = !this.setRetries
      if (!this.setRetries) {
        this.disablePlayback = false
      }
    },
    toggleSetPressureRetry () {
      if (this.selectedRetryCount !== 0) {
        this.setPressuredRetry = !this.setPressuredRetry
      }
    },
    async updateLocalSetupAndNext () {
      this.buttonClick = true
      this.localSetup.ReadingTime = this.computedReadingTime
      this.localSetup.RetryCount = this.selectedRetryCount
      this.localSetup.AllowRetries = this.setRetries
      this.localSetup.PressuredRetry = this.setPressuredRetry
      this.localSetup.PressuredRetryTime = this.setPressuredRetry === true ? this.selectedPressuredRetryTime : -1
      this.localSetup.DisablePlayback = this.disablePlayback
      this.localSetup.PressureWatchVideoQuestion = this.pressureWatchVideoQuestion
      try {
        const res = await roleV2Service.editRoleWorkflowOneWayStage({ ...this.localSetup, Step: 3 })
        this.selectedWorkflowStage = { ...this.selectedWorkflowStage, OneWaySetup: res.data, Step: res.data.Step }
        this.$nextTick(() => {
          this.$router.push(`/roles/add_edit/one_way_stage_setup/${this.role.JobId}/${this.$route.params.roleWorkflowStageId}?page=next_steps`).catch(() => { })
        })
      } catch (err) {
        this.showGenericConfirmModal()
      }
    },
    initDataFromSetup () {
      if (this.localSetup.ReadingTime > 0) {
        this.setReadingTime = true
        this.pressureWatchVideoQuestion = this.localSetup.PressureWatchVideoQuestion
      }
      if (Number(this.localSetup.ReadingTime) % 60 === 0) {
        this.readingTimeType = [
          { text: 'Seconds', value: 1 },
          { text: 'Minutes', value: 2, selected: true }
        ]
        this.readingTime = Number(this.localSetup.ReadingTime) / 60
      } else {
        this.readingTimeType = [
          { text: 'Seconds', value: 1, selected: true },
          { text: 'Minutes', value: 2 }
        ]
        this.readingTimeS = this.localSetup.ReadingTime
      }
      this.disablePlayback = this.localSetup.DisablePlayback
      if (this.localSetup.AllowRetries) {
        if (this.localSetup.RetryCount !== undefined) {
          this.setRetries = true
          this.retryCount = this.retryCount.map(r => r.value === this.localSetup.RetryCount ? { ...r, selected: true } : { ...r, selected: false })
        }
      }
      if (this.localSetup.PressuredRetry) {
        this.setPressuredRetry = true
        this.pressuredRetryTime = this.pressuredRetryTime.map(r => r.value === this.localSetup.PressuredRetryTime ? { ...r, selected: true } : { ...r, selected: false })
      }
    }
  }
}
</script>
