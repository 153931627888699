<template>
  <div class="d-flex col outerContainerBoilerplate pb-4 px-0">
    <div>
      <ClientViewLeftColumn
        v-if="$route.path === '/client_portal/clients'"
        :recent-clients="recentClients"
        :recent-clients-loading="recentClientsLoading"
        @addEditClientModalShowing="addEditClientModalShowing = $event"
      />

      <SpotlightViewLeftColumn
        v-else
        :recent-spotlights="recentSpotlights"
        :recent-spotlights-loading="recentSpotlightsLoading"
        @addEditSpotlightModalShowing="addEditSpotlightModalShowing = $event"
      />
    </div>

    <div class="mainSectionBoilerPlate">
      <div class="mainSectionBoilerPlateInner">
        <ClientViewMainSection
          v-if="$route.path === '/client_portal/clients'"
          :add-edit-client-modal-showing="addEditClientModalShowing"
          @addClientToRecentClients="addClientToRecentClients($event)"
          @addEditClientModalShowing="addEditClientModalShowing = $event"
          @getRecentClients="getRecentClients()"
        />

        <SpotlightListSection
          v-if="$route.path === '/client_portal/spotlights' && urlQueryClientId != 0"
          :add-edit-spotlight-modal-showing="addEditSpotlightModalShowing"
          @getRecentSpotlights="getRecentSpotlights()"
          @addEditSpotlightModalShowing="addEditSpotlightModalShowing = $event"
          @addSpotlightToRecentSpotlights="addSpotlightToRecentSpotlights($event)"
        />

        <SpotlightWorkflowSection
          v-if="$route.path === '/client_portal/spotlights' && urlQuerySpotlightId != 0"
          @getRecentSpotlights="getRecentSpotlights()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import clientPortalService from '@/api/services/clientPortal.service'

import ClientViewLeftColumn from './innerComponents/clientView/clientViewLeftColumn.vue'
import SpotlightViewLeftColumn from './innerComponents/spotlightView/spotlightViewLeftColumn.vue'
import SpotlightListSection from './innerComponents/spotlightView/spotlightListSection/spotlightListMainSection.vue'
import ClientViewMainSection from './innerComponents/clientView/clientViewMainSection.vue'
import SpotlightWorkflowSection from './innerComponents/spotlightView/spotlightWorkflowSection/spotlightWorkflowSection.vue'

export default {
  name: 'ClientPortalContainer',
  components: {
    ClientViewLeftColumn,
    ClientViewMainSection,
    SpotlightViewLeftColumn,
    SpotlightListSection,
    SpotlightWorkflowSection
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()
    const { mobileView } = useMobile()

    return {
      authData,
      showGenericErrorModal,
      mobileView
    }
  },
  data () {
    return {
      addEditClientModalShowing: false,
      recentClients: [],
      recentClientsLoading: true,
      addEditSpotlightModalShowing: false,
      recentSpotlights: [],
      recentSpotlightsLoading: false
    }
  },
  computed: {
    urlQueryClientId () {
      if (this.$route.query.clId === undefined) {
        return 0
      } else {
        return Number(this.$route.query.clId)
      }
    },
    urlQuerySpotlightId () {
      if (this.$route.query.slId === undefined) {
        return 0
      } else {
        return Number(this.$route.query.slId)
      }
    }
  },
  watch: {
    urlQueryClientId () {
      if (this.urlQueryClientId !== 0) {
        this.getRecentSpotlights()
      }
    }
  },
  created () {
    this.getRecentClients()
  },
  methods: {
    async getRecentClients () {
      this.recentClientsLoading = true
      try {
        const res = await clientPortalService.getRecentClients()
        this.recentClients = res.data
      } catch (err) {
        this.showGenericErrorModal({ showing: true})
      }
      this.recentClientsLoading = false
    },
    async addClientToRecentClients (clientId) {
      this.recentClientsLoading = true
      try {
        await clientPortalService.addClientToRecentClients(clientId)
        await this.getRecentClients()
      } catch (err) {
        this.showGenericErrorModal({ showing: true})
      }
      this.recentClientsLoading = false
    },
    async getRecentSpotlights () {
      this.recentSpotlightsLoading = true
      try {
        const res = await clientPortalService.getRecentSpotlights(this.urlQueryClientId, this.authData.access_token)
        this.recentSpotlights = res.data
      } catch (err) {
        this.showGenericErrorModal({ showing: true})
      }
      this.recentSpotlightsLoading = false
    },
    async addSpotlightToRecentSpotlights (spotlightId) {
      try {
        await clientPortalService.addSpotlightToRecentSpotlights(spotlightId)
      } catch (err) {
        this.showGenericErrorModal({ showing: true})
      }
    }
  }
}
</script>

<style lang="css" scoped>

.outerContainerBoilerplate {
  width:100vw;
  max-height: calc(100vh - 80px);
}
.mainSectionBoilerPlate {
  flex-grow: 1;
  min-height: calc(100vh - 80px);
  overflow-y: auto;
}
.mainSectionBoilerPlateInner {
  height:fit-content;
}
@media screen and (max-width: 991px) {
  .outerContainerBoilerplate {
    width:100vw;
  }
  .leftColumnBoilerPlate {
    height: calc(100vh - 80px);
    width:230px;
  }
}
</style>
