<template>
  <div>
    <!-- modal boilerplate -->

    <mdb-modal-body class="p-3 py-sm-4 px-sm-5">
      <!-- filter section -->
      <div class="grey-text" style="font-size: 0.9rem;">
        Choose a video from your video library
      </div>
      <validation-observer v-slot="{ reset }">
        <form
          ref="questionform"
          @submit.prevent="addEditQuestion()"
          @reset.prevent="reset"
        >
          <b-row
            no-gutters
            align-v="center"
            align-h="between"
          >
            <b-col class="md-form form-sm text-left m-0 align-content-center">
              <i class="fas fa-filter prefix" style="font-size: 1.3rem; display:inline;transform:translateY(26px);" />
              <mdb-select
                v-model="videoCategories"
                placeholder="Filter by category"
                style="margin-left:33px;"
                size="sm"
                @change="changeVidCategoryFilter"
              />
            </b-col>
            <b-col class="md-form form-sm text-left m-0">
              <validation-provider mode="lazy" name="TagFilter">
                <mdb-input
                  v-model="filterByTagString"
                  placeholder="Filter by tag"
                  style="margin-left:33px;"
                  size="sm"
                />
              </validation-provider>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
      <!-- while loading -->
      <b-row
        v-if="loading.isLoading"
        align-h="center"
        align-v="center"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </b-row>
      <!-- video list -->
      <SlideFade>
        <div
          v-if="filteredVideos.length > 0 && !loading.isLoading"
          style="max-height: 300px; overflow: auto;"
          class="p-2"
        >
          <div
            v-for="video in filteredVideos"
            :key="video.GuidReference"
            style="cursor: pointer;"
            class="border-2 border-gray-400 bg-gray-100 my-1 p-1 text-bold text-black rounded text-center borderColorOnHover"
            @click="selectedVideoFromList = video"
          >
            <b-row
              v-show="video.IsDeleted === false"
              no-gutters
              align-h="center"
              align-v="center"
              class="flex-nowrap"
            >
              <b-col cols="4">
                <img
                  :src="video.ThumbnailUrl"
                  class="m-auto"
                  style="height:70px; border-radius: 2px; object-fit: contain"
                >
              </b-col>
              <b-col
                offset="1"
                cols="6"
                class="p-1"
              >
                <b-row no-gutters class="font-extrabold text-center">
                  {{ video.ShortDescription }}
                </b-row>
                <b-row no-gutters class="flex-nowrap overflow-hidden">
                  <i class="fas fa-tags prefix" style="font-size: 1rem; display:inline;transform:translateY(3px);" />
                  <div
                    v-if="video.Tags && video.Tags.length > 15"
                    class="ml-2"
                    style="font-size: smaller;white-space: nowrap;"
                  >
                    {{ `${video.Tags.substring(0, 16)}...`|| 'no tags' }}
                  </div>
                  <div
                    v-else
                    class="ml-2"
                    style="font-size: smaller;white-space: nowrap;"
                  >
                    {{ video.Tags !== null && video.Tags !== 'null' && video.Tags !== undefined && video.Tags !== [] ? video.Tags : 'no tags' }}
                  </div>
                </b-row>
              </b-col>
              <b-col cols="1">
                <div style="margin-bottom:-5px" class="text-center">
                  <i
                    v-if="selectedVideoFromList && selectedVideoFromList.GuidReference == video.GuidReference && !loading.isLoading"
                    :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
                    class="col-span-1 fa fa-2x text-left fa-check mx-2"
                    style="font-size:1.2rem;cursor:pointer;"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </SlideFade>
      <!-- no results -->
      <b-row v-if="filteredVideos.length == 0 && !loading.isLoading" align-h="center">
        <div class="mr-4 grey-text" style="font-size:0.9rem;">
          No results
        </div>
      </b-row>
    </mdb-modal-body>
    <!-- FOOTER -->
    <mdb-modal-footer style="display:block;">
      <b-row align-h="end" align-v="center">
        <b-col>
          <mdb-btn
            v-if="!loading.isLoading"
            type="submit"
            :disabled="!selectedVideoFromList.GuidReference"
            class="float-right"
            size="sm"
            style="width:100px;"
            @click="setVideoToAddToList"
          >
            <span>INSERT</span>
          </mdb-btn>
          <mdb-btn
            v-else
            size="sm"
            style="width:100px;"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </b-col>
      </b-row>
    </mdb-modal-footer>
  </div>
</template>

<script>
import { useUserData, useStatus, useLibrary } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'

import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'

export default {
  name: 'ChooseVideoQuestionModal',
  components: {
    SlideFade
  },
  props: {
    defaultCategory: {
      type: String,
      default: 'Job'
    }
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const libraryStore = useLibrary()
    const { chooseVideoLibrary, getVideoQuestionLibrary } = storeToRefs(libraryStore)

    const statusStore = useStatus()
    const { loading, error } = storeToRefs(statusStore)

    const { mobileView } = useMobile()

    return {
      authData,
      loading,
      error,
      mobileView,
      chooseVideoLibrary,
      getVideoQuestionLibrary
    }
  },
  data () {
    return {
      categoryFilter: 6,
      filterByTagString: '',
      filteredVideos: [],
      selectedVideoFromList: {},
      videoCategories: [
        { text: 'Organisation', value: 1 },
        { text: 'Job', value: 2 },
        { text: 'Shareable', value: 3 },
        { text: 'Introduction', value: 4 },
        { text: 'Outro', value: 5 },
        { text: 'Question', value: 6 },
        { text: 'Candidate', value: 7 }
      ]
    }
  },
  computed: {
    currentlySelectedCategoryName () {
      return this.videoCategories.filter(c => c.selected === true)[0].text
    }
  },
  watch: {
    filterByTagString () {
      this.computeFilteredVideos()
    },
    async categoryFilter () {
      this.selectedVideoFromList = {}
      await this.getVideoQuestionLibrary(this.categoryFilter)
      this.computeFilteredVideos()
    },
    error () {
      if (this.error.message === 'Could not fetch video questions') this.filteredVideos = []
    }
  },
  created () {
    this.videoCategories = this.videoCategories.map(q => q.text === this.defaultCategory ? { ...q, selected: true } : { ...q, selected: false })
  },
  async mounted () {
    let filterType = this.videoCategories.filter(c => c.selected === true)[0].value
    await this.getVideoQuestionLibrary(filterType)
    this.computeFilteredVideos()
  },
  methods: {
    reset () {
      this.selectedVideoFromList = {}
      this.filterByTagString = {}
      this.chooseVideoLibrary = []
      this.filteredVideos = []
    },
    computeFilteredVideos () {
      if (this.filterByTagString === '' || this.chooseVideoLibrary.length === 0) { this.filteredVideos = this.chooseVideoLibrary; return }
      const newFilteredVids = []
      this.chooseVideoLibrary.forEach(element => {
        try {
          const tagArray = element.Tags.split(',')
          tagArray.forEach(e => {
            if (e.includes(this.filterByTagString) && !newFilteredVids.includes(element)) {
              newFilteredVids.push(element)
            }
          })
        } catch {}
      })
      this.filteredVideos = newFilteredVids
    },
    changeVidCategoryFilter (e) {
      this.categoryFilter = e
    },
    setVideoToAddToList () {
      this.$emit('handleChosenVideo', this.selectedVideoFromList)
    }
  }
}
</script>
