<template>
  <mdb-modal
    id="instantAndScheduledModalShowing"
    :v-if="instantAndScheduledModalShowing"
    :show="instantAndScheduledModalShowing"
    @close="exit(); $emit('instantAndScheduledModalShowing',false)"
  >
    <mdb-modal-header>
      <span class="font-weight-bold py-2">{{ meetingIsInstant ? 'INSTANT MEET' : 'SCHEDULE A MEETING' }}</span>
    </mdb-modal-header>
    <mdb-modal-body style="padding:25px 16px !important;">
      <div v-show="exists === true" style="text-align: left;font-size: 13px;padding-bottom: 20px;">
        <span v-show="err === 1">
          You have already scheduled a meeting close to your chosent time, it is advisable to amend the scheduled time to a later date (at least 2 hours from your chosen slot).
        </span>
        <span v-show="err === -1">
          Sorry, something went wrong!
        </span>
      </div>
      <div v-if="!loadingRooms">
        <div v-if="rooms.length > 0">
          <div class="file-field md-form form-sm text-left">
            <label style="margin-top:-35px;font-size:12px;">Choose Room</label>
            <i
              v-if="!loadingRooms"
              class="fas fa fa-video prefix"
              style="margin-top: 6px;font-size: 1.3rem;"
            />
            <i
              v-if="loadingRooms"
              class="fa fa-spinner fa-pulse"
              style="margin-top: 6px;font-size: 1.3rem;"
            />
            <mdb-select
              v-model="rooms"
              placeholder="Choose Room"
              style="margin-left:33px;"
              size="sm"
              @change="roomSelected"
            />
          </div>
          <div v-if="!meetingIsInstant">
            <div class="file-field md-form form-sm text-left">
              <i
                class="far fa-calendar prefix"
                style="margin-top: 6px;font-size: 1.3rem;"
                title="Select date"
              />
              <input
                v-model="meetingDate"
                type="date"
                :min="today"
                onkeydown="return false"
                auto-complete="mdt"
                style="margin-left:33px;font-size: 0.9rem;color:#888;"
                size="sm"
                @change="timeChange"
              >
            </div>
            <div class="file-field md-form form-sm text-left">
              <label v-show="timeErr" style="margin-top: 40px;font-size: 12px;margin-left: 35px;color: red;">YOU CANNOT CHOOSE A TIME LESS THAN THE CURRENT TIME!</label>
              <i
                class="far fa-clock prefix"
                style="margin-top: 6px;font-size: 1.3rem;"
                title="Select time"
              />
              <mdb-input
                v-model="meetingTime"
                type="time"
                onkeydown="return false"
                auto-complete="mtm"
                style="margin-left:33px;"
                size="sm"
                @change="timeChange"
              />
            </div>
          </div>
          <div
            v-if="meetingIsInstant"
            style="color:#757575;font-size:0.7rem;margin-top:0px !important;"
            class="text-left mt-5"
          >
            <div class="mb-2">
              By pressing Instant Meet the Candidate will be emailed and asked to enter your meeting room immediately.
            </div>
            <div>
              You will also be transferred directly into the meeting room. If you wish to schedule a meeting with this for a later time please choose to <span
                style="color:var(--pr-color);"
                class="underline cp"
                @click="$emit('setIsInstant', false)"
              >Schedule a Meeting</span> instead
            </div>
          </div>
          <div
            v-else
            style="color:#757575;font-size:0.7rem;"
            class="text-left mt-5"
          >
            <div class="mb-2">
              By pressing Schedule Meet, this candidate will be emailed and asked to enter your meeting room on the day and time specified.
            </div>
            <div>
              If you wish to schedule a meeting immediately please choose <span
                class="underline cp"
                style="color:var(--pr-color);"
                @click="$emit('setIsInstant', true)"
              >Instant Meeting </span> instead.
            </div>
          </div>
        </div>
        <div v-else>
          <div style="color:#757575;font-size:0.7rem;" class="text-left">
            <div>You have no private rooms available to set up this meeting. Please create a private room before continuing.</div>
          </div>
        </div>
      </div>
      <div v-else>
        <h3 class="primary-colour text-left font-weight-bold">
          Loading
          <i style="margin-left: 5px !important;" class="fa fa-spin fa-spinner ml-3" />
        </h3>
      </div>
    </mdb-modal-body>
    <mdb-modal-footer class="justify-content-end">
      <mdb-btn
        v-show="!buttonModalClick && rooms.length === 0"
        :disabled="loadingRooms"
        size="sm"
        style="width:145px;"
        @click.native="goToMeetNowSetUp()"
      >
        CREATE ROOM
      </mdb-btn>
      <mdb-btn
        v-show="!buttonModalClick && meetingIsInstant && rooms.length > 0"
        :disabled="loadingRooms || roomId < 1"
        size="sm"
        style="width:145px;"
        @click.native="instantMeet()"
      >
        INSTANT MEET
      </mdb-btn>
      <mdb-btn
        v-show="!buttonModalClick && !meetingIsInstant && rooms.length > 0"
        :disabled="meetingTime == null || meetingDate == null || loadingRooms || timeErr || roomId < 1"
        size="sm"
        style="width:145px;"
        @click.native="scheduledMeet()"
      >
        SCHEDULED MEET
      </mdb-btn>
      <mdb-btn
        v-show="buttonModalClick"
        size="sm"
        style="width:145px;"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import { useCandidate, useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import meetNowService from '@/api/services/meetnow.service'
import { MEET_URL } from '@/common/config.js'

export default {
  name: 'InstantAndScheduledMeetingModal',
  props: {
    instantAndScheduledModalShowing: Boolean,
    meetingIsInstant: Boolean
  },
  setup () {
    const candidateStore = useCandidate()
    const { candidateToEdit } = storeToRefs(candidateStore)

    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    return {
      authData,
      candidateToEdit
    }
  },
  data () {
    return {
      today: '',
      buttonModalClick: false,
      loadingRooms: false,
      rooms: [],
      myRooms: [],
      meetingTime: null,
      meetingDate: null,
      roomId: 0,
      exists: false,
      err: null,
      time: '',
      timeErr: false
    }
  },
  computed: {
    room: function () {
      var c = ''
      this.rooms.forEach(element => {
        if (element.selected === true) {
          c = element.value
        }
      })
      return c
    }
  },
  watch: {
    instantAndScheduledModalShowing () {
      if (this.instantAndScheduledModalShowing) { this.getAllAvailablePrivateRooms() }
    }
  },
  mounted () {
    var dtToday = new Date()
    var month = dtToday.getMonth() + 1
    var day = dtToday.getDate()
    var year = dtToday.getFullYear()
    if (month < 10) {
      month = '0' + month.toString()
    }
    if (day < 10) {
      day = '0' + day.toString()
    }
    this.today = year + '-' + month + '-' + day
    this.time = dtToday.getHours() + ':' + dtToday.getMinutes()
  },
  methods: {
    async getAllAvailablePrivateRooms () {
      this.loadingRooms = true
      try {
        const res = await meetNowService.getAllAvailablePrivateRooms()
        this.myRooms = res.data
        this.rooms = res.data.map(r => r.IsDefault ? { value: r.RoomId, text: r.RoomName, selected: true } : { value: r.RoomId, text: r.RoomName, selected: false })
        this.rooms.forEach(element => {
          if (element.selected === true) {
            this.roomId = element.value
          }
        })
      } catch (err) { 
        //?
      }
      this.loadingRooms = false
    },
    exit () {
      this.buttonModalClick = false
      this.roomId = 0
      this.meetingDate = null
      this.meetingTime = null
    },
    goToMeetNowSetUp () {
      window.location.href = '/meetnow'
    },
    instantMeet () {
      this.$emit('meetingIsInstant', true)
      this.buttonModalClick = true
      this.rooms.forEach(element => {
        if (element.selected === true) {
          this.roomId = element.value
        }
      })
      meetNowService.instantMeet(this.candidateToEdit.CandidateId, this.roomId).then((response) => {
        this.buttonModalClick = false
        if (response.data !== 0) {
          this.err = response.data
          this.exists = true
        } else {
          this.myRooms.forEach(element => {
            if (element.RoomId === this.roomId) {
              window.open(MEET_URL + '?inv=' + element.HostLink, '_blank')
            }
          })
          this.roomId = 0
          this.$emit('instantAndScheduledModalShowing', false)
          this.$emit('meetingIsInstant', false)
        }
      }).catch(() => {
        this.roomId = 0
        this.err = -1
        this.exists = true
        this.buttonModalClick = false
      })
    },
    scheduledMeet () {
      this.buttonModalClick = true
      this.rooms.forEach(element => {
        if (element.selected === true) {
          this.roomId = element.value
        }
      })
      meetNowService.scheduleNow(this.candidateToEdit.CandidateId, this.roomId, this.meetingDate, this.meetingTime).then((response) => {
        this.buttonModalClick = false
        if (response.data !== 0) {
          this.err = response.data
          this.exists = true
        } else {
          this.$emit('instantAndScheduledModalShowing', false)
          this.roomId = 0
          this.meetingDate = null
          this.meetingTime = null
        }
      }).catch(() => {
        this.roomId = 0
        this.err = -1
        this.exists = true
        this.buttonModalClick = false
      })
    },
    roomSelected () {
      this.rooms.forEach(element => {
        if (element.selected === true) {
          this.roomId = element.value
        }
      })
      this.timeChange()
    },
    timeChange () {
      this.timeErr = false
      var dtToday = new Date()
      var mtDate = new Date(this.meetingDate)
      if (mtDate <= dtToday) {
        var h = dtToday.getHours()
        var m = dtToday.getMinutes()
        if (m.toString().length === 1) {
          m = '0' + m.toString()
        }
        if (h === '0') { h = 24 }
        var currentTime = h + '.' + m
        if (this.meetingTime != null) {
          var time = this.meetingTime.split(':')
          var hour = time[0]
          if (hour === '00') { hour = 24 }
          var min = time[1]
          var inputTime = hour + '.' + min
          var totalTime = currentTime - inputTime
          if (totalTime > 0) {
            this.timeErr = true
          } else {
            this.timeErr = false
          }
        }
      }
    }
  }
}
</script>

<style>
.listViewSelected {
  transition: ease 0.5s;
}
</style>

<style lang="css" scoped>
.meetNowContentHeight {
  height:calc(100vh - 250px)
}
@media (min-width: 768px) {
  .meetNowContentHeight {
    height:calc(100vh - 200px)
  }
}
</style>

<style lang="scss">
@media (max-width: 992px) {
  .moveCreateRoom {
    transform: translateX(15px)
  }
}
@media (max-width: 576px) {
  #instantAndScheduledModalShowing .modal-dialog .modal-content {
    margin:5px !important;
    width:calc(100vw - 10px) !important;
    height: calc(100vh - 65px) !important;
  }
}
</style>
