import axiosInstance from '../instance.js'
const api1 = new axiosInstance(1)

export default class candidateService {
  static async getCandidates (page) {
     return await api1.get(`/api/go/getcandidates?page=${page}`)
  }

  static async filterCandidates (page, filter) {
    return await api1.get('/api/go/filtercandidates?page=' + page + '&filter=' + filter)
  }

  static async getCandidate (candidateId) {
    return await api1.get(`/api/go/getcandidate?candidateId=${candidateId}`)
  }

  static async getCandidateFromCandAccId (candidateAccId) {
    return await api1.get(`/api/go/getcandidatefromcandaccid?candidateAccId=${candidateAccId}`)
  }

  static async getRecentCandidates () {
    return await api1.get('/api/go/getrecentcandidates')
  }

  static async addCandidateToRecentCandidates (candId) {
    return await api1.post(`/api/go/addgocandidatetorecentcandidates?candId=${candId}`, {})
  }

  static async getCandidateDocs (candidateId) {
    return await api1.get(`/api/candidate/getcandidatedocs?candidateId=${candidateId}`)
  }

  static async addEditDeleteDocument (formData) {
    return await api1.post('/api/go/addeditdocument', formData)
  }

  static async addEditCandidate (formData) {
    return await api1.post('/api/go/addeditcandidate', formData)
  }

  static async addEditCandidateQuestion (formData) {
    return await api1.post('/api/go/addeditcandidatequestion', formData)
  }

  static async addPoolQuestionToList (candidateId, questionId) {
    return await api1.post(`/api/go/addpoolquestiontocandidatelist?candidateId=${candidateId}&questionId=${questionId}`, {})
  }

  static async archiveCandidate (candidateId) {
    return await api1.post('/api/go/archivecandidate?candidateId=' + candidateId)
  }

  static async deleteCandidate (candidateId) {
    return await api1.delete('/api/go/deletecandidate?candidateId=' + candidateId)
  }

  static async unDeleteCandidate (candidateId) {
    return await api1.delete('/api/go/undeletecandidate?candidateId=' + candidateId)
  }

  static async deleteCandidatePoolQuestion (questionId) {
    return await api1.delete('/api/go/deletecandidatepoolquestion?questionId=' + questionId)
  }

  static async getCandidateQuestions (candidateId) {
    return await api1.get(`/api/go/getcandidatequestions?candidateId=${candidateId}`)
  }

  static async getCandidateQuestionPool () {
    return await api1.get('/api/go/getcandidatequestionpool')
  }

  static async addEditCandidateQuestionSet (questionSetId, toDelete, candidateId) {
    return await api1.post(`/api/go/addeditcandidatequestionset?questionSetId=${questionSetId}&toDelete=${toDelete}&candidateId=${candidateId}`, {})
  }

  static async setCandidateTableDisplaySettings (formData) {
    return await api1.post('/api/go/setcandidatetabledisplaysettings', formData)
  }

  static async getCandidateTableDisplaySettings () {
    return await api1.get('/api/go/getcandidatetabledisplaysettings')
  }

  static async getCandidateHistory (candidateId) {
    return await api1.get(`/api/go/getcandidatehistory?candidateId=${candidateId}`)
  }

  static async inviteCandidateToVideoCV (candidateId) {
    return await api1.post(`/api/go/invitecandidatetovideocv?candidateId=${candidateId}`, {})
  }

  static async profilePicture (formData) {
    return await api1.post('/api/go/profilepicture', formData)
  }

  static async shareCandidates (formData) {
    return await api1.post('/api/go/sharegocandidates', formData)
  }

  static async quickShareCandidates (data) {
    return await api1.post('/api/go/quicksharegocandidate', data)
  }

  static async resetCandidateVideoCVAnswer (candidateId, questionListId) {
    return await api1.post(`/api/go/resetcandidatevideocvanswer?candidateId=${candidateId}&questionListId=${questionListId}`, {})
  }

  static async updateCandidateQuestionListOrdinals (ids) {
    return await api1.post('/api/go/updatecandidatequestionlistordinals?ids=' + ids, {})
  }

  static async followCandidate (id) {
    return await api1.post('/api/go/followcandidate?id=' + id, {})
  }

  static async updatePerPage (pge) {
    return await api1.post('/api/go/perpage?pge=' + pge, {})
  }

  static async unFollowCandidate (id) {
    return await api1.post('/api/go/unfollowcandidate?id=' + id, {})
  }

  static async changeIDCheckedStatus (isChecked, candidateId) {
    return await api1.post('/api/go/changeidcheckedstatus?isChecked=' + isChecked + '&candidateId=' + candidateId, {})
  }

  static async getCandidateIDCheckStatus (candidateId) {
    return await api1.get('/api/go/getcandidateidcheckedstatus?candidateId=' + candidateId)
  }

  static async addCandidateNote (data) {
    return await api1.post('/api/go/candidates/candidatenotes/addnote', data)
  }

  static async getCandidateNotes (id) {
    return await api1.get('/api/go/candidates/candidatenotes/getnotes?candidateId=' + id)
  }

  static async deleteCandidateNote (id) {
    return await api1.delete('/api/go/candidates/candidatenotes/deletenote?candidateNoteId=' + id)
  }

  //VOIDS (fire and forget)
  static importMassCsv (formData) {
    api1.post('/api/go/importmassbulk', formData)
  }

}
