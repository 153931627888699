<template>
  <div class="px-3 px-md-5" style="text-align:left;padding:2px;margin-top:50px;">
    <div class="h5 grey-text">
      Configure this One-Way Interview Stage
    </div>
    <div class="md-form form-sm" style="margin-top: 40px">
      <div style="font-size: 0.7rem;color:#888;transform:translate(30px, -10px);margin-bottom:-33px;">
        How many days following the date of invite does the candidate have to complete<br>their interview?
      </div>
      <i class="far fa-hourglass prefix" style="margin-top: 6px;font-size: 1.3rem;" />
      <mdb-select
        v-model="candidatelimit"
        placeholder="Select days"
        style="margin-left:33px;"
        size="sm"
      />
    </div>
    <div class="md-form form-sm" style="margin-top: 40px">
      <div style="font-size: 0.7rem;color:#888;transform:translate(30px, -10px);margin-bottom:-33px;">
        How many days following the date of a reset does the candidate have to complete<br>their interview again? Only affects completed or previously completed candidates.
      </div>
      <i class="far fa-hourglass prefix" style="margin-top: 6px;font-size: 1.3rem;" />
      <mdb-select
        v-model="retryDeadline"
        placeholder="Select days"
        style="margin-left:33px;"
        size="sm"
      />
    </div>

    <div
      v-if="!isEvergreen"
      class="md-form form-sm"
      style="margin-top: 40px"
    >
      <div style="font-size: 0.7rem;color:#888;transform:translate(30px, -10px)">
        Optionally, what time does the invitation close for the candidate compared to the role closing time of {{ time }}
      </div>
      <i class="far fa-hourglass prefix" style="margin-top: 6px;font-size: 1.3rem;" />
      <mdb-select
        v-model="closingTimes"
        style="margin-left:33px;margin-top: -40px;"
        placeholder="Select time"
        size="sm"
      />
    </div>

    <!--intro screen 1-->
    <div class="md-form form-sm d-flex align-items-center mt-5">
      <FontAwesomeIcon icon="fa-solid fa-id-card" style="font-size: 1.3rem;" />
      <div
        class="grey-text"
        style="margin-left: 8px;width:160px;"
        size="sm"
      >
        Request ID Check
      </div>
      <div @click="requestIDCheck = !requestIDCheck">
        <mdb-input
          v-model="requestIDCheck"
          type="checkbox"
          style="transform:translateY(-10px);width:40px;"
          size="sm"
        />
      </div>
      <Tooltip
        class="ml-4"
        style="display: inline"
        tooltip-direction="left"
        tool-tip-summary="If checked, candidates will have to upload a form of ID before commencing with the interview."
      />
    </div>
    <div class="md-form form-sm d-flex align-items-center mt-3">
      <FontAwesomeIcon icon="fa-solid fa-lock" style="font-size: 1.3rem;" />
      <div
        class="grey-text"
        style="margin-left: 14px;width:160px;"
        size="sm"
      >
        Locked Interview
      </div>
      <div @click="lockInterviewAfterFirstAttempt = !lockInterviewAfterFirstAttempt">
        <mdb-input
          v-model="lockInterviewAfterFirstAttempt"
          type="checkbox"
          style="transform:translateY(-10px);width:40px;"
          size="sm"
        />
      </div>
      <Tooltip
        class="ml-4"
        style="display: inline"
        tooltip-direction="left"
        tool-tip-summary="If checked, candidates will have one attempt to complete the interview. If the candidate closes the browser or refreshes the page, their interview will be locked. It can be manually unlocked from within the role."
      />
    </div>
    <div class="md-form form-sm d-flex align-items-center" style="">
      <i class="fas fa-border-none prefix float-left" style="font-size: 1.3rem;" />
      <div
        class="grey-text"
        style="margin-left: 32px;width:160px;"
        size="sm"
      >
        Introduction Screen 1
      </div>
      <div @click="introScreen1 = !introScreen1">
        <mdb-input
          v-model="introScreen1"
          type="checkbox"
          style="transform:translateY(-10px)"
          size="sm"
        />
      </div>
    </div>
    <div v-if="introScreen1" class="ml-5">
      <div class="md-form form-sm">
        <validation-provider mode="lazy" name="Heading">
          <i class="fa fa-signature prefix" style="margin-top: 6px;font-size: 1.3rem;" />
          <mdb-input
            v-model="localSetup.IntroScreen1Header"
            type="text"
            auto-complete="jbt"
            label="Heading"
            style="margin-left:33px;"
            size="sm"
          />
        </validation-provider>
      </div>
      <div v-show="!introScreen1Text">
        <div
          v-if="localSetup.IntroScreen1Video === null || localSetup.IntroScreen1Video === undefined"
          class="md-form form-sm"
          style="margin-top:40px;"
        >
          <div
            style="cursor:pointer;border: 2px dotted rgb(192, 192, 192);margin-top: 35px;padding: 20px;text-align: center;"
            class="grey-text"
            @click="addJobVideo(1)"
          >
            <i class="fas fa-video" style="font-size:1.5rem;" /><br><span style="font-size:small;">Add Video</span>
          </div>
        </div>
        <div
          v-if="!(localSetup.IntroScreen1Video === null || localSetup.IntroScreen1Video === undefined)"
          class="border-2 border-gray-400 bg-gray-100 mt-5 my-1 p-1 text-bold text-black rounded text-center"
          style="width: 100%;"
        >
          <b-row
            no-gutters
            align-h="center"
            align-v="center"
          >
            <b-col cols="4" style="background: black;">
              <img
                :src="localSetup.IntroScreen1Video.ThumbnailUrl || localSetup.IntroScreen1Video.Thumb"
                class="m-auto"
                style="height:80px; border-radius: 2px; object-fit: contain"
              >
            </b-col>
            <b-col offset="1" class="p-1">
              <b-row no-gutters class="font-extrabold text-center">
                {{ localSetup.IntroScreen1Video.ShortDescription }}
              </b-row>
            </b-col>
            <b-col cols="2">
              <div style="margin-bottom:-5px" class="text-center">
                <i
                  class=" fas fa-times prefix"
                  style="color: black; margin-top: 6px;font-size: 1.3rem; cursor: pointer;"
                  @click="localSetup.IntroScreen1Video = null"
                />
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div
        class="md-form form-sm"
        style="margin-top: 25px;margin-bottom: 40px;"
        @click="introScreen1Text = !introScreen1Text"
      >
        <mdb-input
          v-model="introScreen1Text"
          type="checkbox"
          label="Remove video and use a written introduction instead?"
          style="margin-left: -20px;margin-top: -10px;"
          size="sm"
        />
      </div>
      <div
        v-show="introScreen1Text"
        class="md-form form-sm"
        style="margin-top:40px;"
      >
        <mdb-input
          v-model="localSetup.IntroScreen1"
          type="textarea"
          auto-complete="fna"
          :rows="8"
          size="sm"
          maxlength="1000"
        />
        <div class="grey-text remainingChars">
          {{ setChars(localSetup.IntroScreen1, 1000) }}
        </div>
      </div>
    </div>
    <!--intro screen 2-->
    <div class="md-form form-sm d-flex align-items-center" style="">
      <i class="fas fa-border-none prefix float-left" style="font-size: 1.3rem;" />
      <div
        class="grey-text"
        style="margin-left: 32px;width:160px;"
        size="sm"
      >
        Introduction Screen 2
      </div>
      <div @click="introScreen2 = !introScreen2">
        <mdb-input
          v-model="introScreen2"
          type="checkbox"
          style="transform:translateY(-10px)"
          size="sm"
        />
      </div>
    </div>
    <div v-if="introScreen2" class="ml-5">
      <div class="md-form form-sm">
        <validation-provider mode="lazy" name="Heading">
          <i class="fa fa-signature prefix" style="margin-top: 6px;font-size: 1.3rem;" />
          <mdb-input
            v-model="localSetup.IntroScreen2Header"
            type="text"
            auto-complete="jbt"
            label="Heading"
            style="margin-left:33px;"
            size="sm"
          />
        </validation-provider>
      </div>
      <div v-show="!introScreen2Text">
        <div
          v-if="localSetup.IntroScreen2Video === null || localSetup.IntroScreen2Video === undefined"
          class="md-form form-sm"
          style="margin-top:40px;"
        >
          <div
            style="cursor:pointer;border: 2px dotted rgb(192, 192, 192);margin-top: 35px;padding: 20px;text-align: center;"
            class="grey-text"
            @click="addJobVideo(2)"
          >
            <i class="fas fa-video" style="font-size:1.5rem;" /><br><span style="font-size:small;">Add Video</span>
          </div>
        </div>
        <div
          v-if="localSetup.IntroScreen2Video !== null && localSetup.IntroScreen2Video !== undefined"
          class="border-2 border-gray-400 bg-gray-100 mt-5 my-1 p-1 text-bold text-black rounded text-center"
          style="width: 100%;"
        >
          <b-row
            no-gutters
            align-h="center"
            align-v="center"
          >
            <b-col cols="4" style="background: black;">
              <img
                :src="localSetup.IntroScreen2Video.ThumbnailUrl || localSetup.IntroScreen2Video.Thumb"
                class="m-auto"
                style="height:80px; border-radius: 2px; object-fit: contain"
              >
            </b-col>
            <b-col offset="1" class="p-1">
              <b-row no-gutters class="font-extrabold text-center">
                {{ localSetup.IntroScreen2Video.ShortDescription }}
              </b-row>
            </b-col>
            <b-col cols="2">
              <div style="margin-bottom:-5px" class="text-center">
                <i
                  class=" fas fa-times prefix"
                  style="color: black; margin-top: 6px;font-size: 1.3rem; cursor: pointer;"
                  @click="localSetup.IntroScreen2Video = null"
                />
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div
        class="md-form form-sm"
        style="margin-top: 25px;margin-bottom: 40px;"
        @click="introScreen2Text = !introScreen2Text"
      >
        <mdb-input
          v-model="introScreen2Text"
          type="checkbox"
          label="Remove video and use a written introduction instead?"
          style="margin-left: -20px;margin-top: -10px;"
          size="sm"
        />
      </div>
      <div
        v-show="introScreen2Text"
        class="md-form form-sm"
        style="margin-top:40px;"
      >
        <mdb-input
          v-model="localSetup.IntroScreen2"
          type="textarea"
          auto-complete="fna"
          :rows="8"
          size="sm"
          maxlength="1000"
          aria-placeholder="Enter brief job description"
        />
        <div class="grey-text remainingChars">
          {{ setChars(localSetup.IntroScreen2, 1000) }}
        </div>
      </div>
    </div>

    <!-- SCORING -->
    <div class="mb-5 mt-2">
      <div class="d-flex align-items-center" style="margin-top:2rem !important;">
        <FontAwesomeIcon
          icon="fa-solid fa-chart-simple-horizontal"
          style="scale:1.4"
          class="d-none d-md-block mr-2 mr-lg-4 ml-1"
        />
        <div style="font-size:0.9rem" class="dark-grey-text">
          Scoring Template
          <Tooltip
            class="ml-5"
            tooltip-direction="left"
            style="display: inline"
            tool-tip-summary="Based on your choice here, a user will give a candidate points per question or per stage, when reviewing the interview. <br /> <br /> If you choose per question, you will have the chance to add a template when adding the stage questions.<br /><br />Note: once invites are set you cannot change this. Likewise if you delete a scoring template that's set up here, scoring will no longer be available."
          />
        </div>
        <mdb-switch
          v-model="scoringTemplatePerRole"
          style="transform:scale(0.9)"
          :disabled="oneWayStageHasBeenStarted"
          :title="oneWayStageHasBeenStarted ? 'There are candidates with active invites in this stage - if you would like to change the template you must reset all interviews in this stage.' : 'Scoring Template'"
          off-label="Per Question"
          on-label="Per Stage"
          class="m-0 ml-auto mt-1 dark-grey-text"
        />
      </div>
      <!-- templateId === -1 is template per question, templateId === 0 is no scoring selected for scoring per role -->
      <div
        v-if="scoringTemplatePerRole"
        class="d-flex align-items-center"
        style="height: 50px;"
      >
        <mdb-select
          v-model="scoringTemplateOptions"
          :disabled="oneWayStageHasBeenStarted"
          placeholder="Choose Scoring Template"
          style="margin-left:44px;width: 100%"
          size="sm"
        />
      </div>
    </div>

    <!-- BUTTONS -->
    <div class="d-flex align-items-center justify-content-between mt-3 mb-5">
      <mdb-btn
        class="btn btn-outline"
        size="sm"
        style="width:110px;"
        @click="$router.push(`/roles/add_edit/detail/${role.JobId}`)"
      >
        CANCEL
      </mdb-btn>
      <mdb-btn
        v-show="!buttonClick"
        type="submit"
        class="m-0"
        size="sm"
        style="width:100px;"
        @click="updateLocalSetupAndSaveStage()"
      >
        NEXT
      </mdb-btn>
      <mdb-btn
        v-show="buttonClick"
        class="m-0"
        size="sm"
        style="width:100px;cursor:default;"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
    <InsertVideoModal
      :defaultCategory="typeName === 'Job' ? 'Job' : 'Organisation'"
      @handleChosenVideo="handleChosenVideo"
      @handleRecordedVideo="handleRecordedVideo"
    />
  </div>
</template>

<script>
import InsertVideoModal from '@/components/genericModals/InsertVideoModals/insertVideoModal.vue'
import libraryService from '@/api/services/library.service'
import Tooltip from '@/components/misc/tooltip.vue'
import scoringService from '@/api/services/scoring.service.js'
import roleV2Service from '@/api/services/roleV2.service'
import { useUserData, useStatus, useWorkflow, useLibrary } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'

export default {
  name: 'SettingUp',
  components: { InsertVideoModal, Tooltip },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const workflowStore = useWorkflow()
    const { role, selectedWorkflowStage, oneWayStageHasBeenStarted } = storeToRefs(workflowStore)

    const { showGenericErrorModal } = useStatus()
    const { mobileView } = useMobile()

    const libraryStore = useLibrary()
    const { showAddInsertVideoModal } = storeToRefs(libraryStore)

    return {
      authData,
      showGenericErrorModal,
      role,
      selectedWorkflowStage,
      oneWayStageHasBeenStarted,
      mobileView,
      showAddInsertVideoModal
    }
  },
  data () {
    return {
      localSetup: {},
      buttonClick: false,
      introScreen1: false,
      introScreen2: false,
      introScreen1Text: '',
      introScreen2Text: '',
      typeName: 'IntroScreen1Video',
      isEvergreen: false,
      requestIDCheck: false,
      lockInterviewAfterFirstAttempt: false,
      scoringTemplatePerRole: true,
      defaultScoringTemplateOptions: [{ text: 'No Scoring', value: 0, selected: true }],
      scoringTemplateOptions: [],
      time: "00:00AM",
      closingTimes: [{ text: '00:00AM', value: 0 }, 
      { text: '01:00AM', value: 1 }, 
      { text: '02:00AM', value: 2 }, 
      { text: '03:00AM', value: 3 }, 
      { text: '04:00AM', value: 4 }, 
      { text: '05:00AM', value: 5 }, 
      { text: '06:00AM', value: 6 }, 
      { text: '07:00AM', value: 7 }, 
      { text: '08:00AM', value: 8 }, 
      { text: '09:00AM', value: 9 }, 
      { text: '10:00AM', value: 10 }, 
      { text: '11:00AM', value: 11 }, 
      { text: '12:00PM', value: 12 }, 
      { text: '01:00PM', value: 13 }, 
      { text: '02:00PM', value: 14 }, 
      { text: '03:00PM', value: 15 }, 
      { text: '04:00PM', value: 16 }, 
      { text: '05:00PM', value: 17 }, 
      { text: '06:00PM', value: 18 }, 
      { text: '07:00PM', value: 19 }, 
      { text: '08:00PM', value: 20 }, 
      { text: '09:00PM', value: 21 }, 
      { text: '10:00PM', value: 22 }, 
      { text: '11:00PM', value: 23 }],
      candidatelimit: [
        { text: '1 day', value: 1 },
        { text: '2 days', value: 2 },
        { text: '3 days', value: 3 },
        { text: '5 days', value: 5 },
        { text: '7 days', value: 7 },
        { text: '10 days', value: 10 },
        { text: '14 days', value: 14 },
        { text: 'No limit (until role closing date)', value: -1, selected: true }
      ],
      retryDeadline: [
        { text: '1 day', value: 1 },
        { text: '2 days', value: 2 },
        { text: '3 days', value: 3 },
        { text: '5 days', value: 5 },
        { text: '7 days', value: 7 },
        { text: '10 days', value: 10 },
        { text: '14 days', value: 14 },
        { text: 'No limit (until role closing date)', value: -1, selected: true }
      ],
    }
  },
  computed: {
    // templateId === -1 is template per question, templateId === 0 is no scoring selected for scoring per role
    selectedTemplateId () {
      if (!this.scoringTemplatePerRole) { return -1 }
      try {
        return this.scoringTemplateOptions.filter(s => s.selected)[0].value
      } catch { return 0 }
    },
    selectedRetryLimit () {
      try {
        return this.retryDeadline.filter(s => s.selected)[0].value
      } catch { return -1 }
    },
    selectedCandidatelimit () {
      try {
        return this.candidatelimit.filter(s => s.selected)[0].value
      } catch { return -1 }
    },
    closingTime: function () {
      var c = ''
      this.closingTimes.forEach(element => {
        if (element.selected === true) {
          c = element.value
        }
      })
      return c
    },
  },
  watch: {
    introScreen1 () {
      if (!this.introScreen1) {
        this.localSetup.IntroScreen1Header = ''
        this.localSetup.IntroScreen1 = ''
        this.localSetup.IntroScreen1Video = null
        this.localSetup.IntroScreen1VideoId = null
      }
    },
    introScreen2 () {
      if (!this.introScreen2) {
        this.localSetup.IntroScreen2Header = ''
        this.localSetup.IntroScreen2 = ''
        this.localSetup.IntroScreen2Video = null
        this.localSetup.IntroScreen2VideoId = null
      }
    },
    introScreen1Text () {
      if (this.introScreen1Text === true) {
        this.localSetup.IntroScreen1Video = null
        this.localSetup.IntroScreen1VideoId = null
      }
    },
    introScreen2Text () {
      if (this.introScreen2Text === true) {
        this.localSetup.IntroScreen2Video = null
        this.localSetup.IntroScreen2VideoId = null
      }
    }
  },
  async mounted () {
    this.localSetup = { ...this.selectedWorkflowStage.OneWaySetup }
    await this.populateScoringTemplateOptions()
    this.initialiseDataFromSetup()
  },
  methods: {
    // templateId === -1 is template per question, templateId === 0 is no scoring selected for scoring per role
    async populateScoringTemplateOptions () {
      const includeDeleted = true
      let res
      try {
        res = await scoringService.getAllTemplates(includeDeleted)
      } catch (err) {
        this.showGenericErrorModal()
        return
      }
      // value when adding a question
      let options = res.data.map(t => { return { text: t.TemplateName, value: t.TemplateId, selected: t.IsDefault } })
      options = [...this.defaultScoringTemplateOptions, ...options]

      if (this.localSetup.TemplateId !== -1) {
        options = options.map(o => o.value === this.localSetup.TemplateId ? { ...o, selected: true } : { ...o, selected: false })
        this.scoringTemplatePerRole = true
      } else {
        this.scoringTemplatePerRole = false
      }
      // value when editing a question that has selected no template
      this.scoringTemplateOptions = options
    },
    async handleRecordedVideo () {
      try {
        const res = await libraryService.getMostRecentVid(this.userData.aid)
        this.addVideoToLocalSetup(res.data)
      } catch {
        this.showGenericErrorModal()
      }
      this.showAddInsertVideoModal = false
    },
    async updateLocalSetupAndSaveStage () {
      this.buttonClick = true
      this.localSetup.RequestIdcheck = this.requestIDCheck
      this.localSetup.TemplateId = this.scoringTemplatePerRole ? this.selectedTemplateId : -1
      this.localSetup.LockInterviewAfterFirstAttempt = this.lockInterviewAfterFirstAttempt
      this.localSetup.RetryLimit = this.selectedRetryLimit
      this.localSetup.CandidateLimit = this.selectedCandidatelimit

      if (this.closingTime > 0) {
      this.localSetup.RoleClosingTime = this.closingTime
      } else {
        this.localSetup.RoleClosingTime = 0
      }

      try {
        const res = await roleV2Service.editRoleWorkflowOneWayStage({ ...this.localSetup, Step: 1 })
        this.selectedWorkflowStage = { ...this.selectedWorkflowStage, OneWaySetup: res.data, Step: res.data.Step }
        this.$nextTick(() => {
          this.$router.push(`/roles/add_edit/one_way_stage_setup/${this.role.JobId}/${this.$route.params.roleWorkflowStageId}?page=questions`).catch(() => { })
        })
      } catch (err) {
        this.showGenericErrorModal()
      }
    },
    setChars (text, max) {
      try {
        return `${text.length} / ${max}`
      } catch {
        return `0 / ${max}`
      }
    },
    addJobVideo (type) {
      if (type === 1) {
        this.typeName = 'IntroScreen1Video'
      } else if (type === 2) {
        this.typeName = 'IntroScreen2Video'
      }
      this.showAddInsertVideoModal = true
    },
    initialiseDataFromSetup () {
      if (this.localSetup.IntroScreen1 || this.localSetup.IntroScreen1Header || this.localSetup.IntroScreen1Video) { this.introScreen1 = true }
      if (this.localSetup.IntroScreen2 || this.localSetup.IntroScreen2Header || this.localSetup.IntroScreen2Video) { this.introScreen2 = true }
      if (this.localSetup.IntroScreen1) { this.introScreen1Text = true }
      if (this.localSetup.IntroScreen2) { this.introScreen2Text = true }
      this.requestIDCheck = this.localSetup.RequestIdcheck
      this.lockInterviewAfterFirstAttempt = this.localSetup.LockInterviewAfterFirstAttempt
      this.candidatelimit = this.candidatelimit.map(c => c.value === this.localSetup.CandidateLimit ? { ...c, selected: true } : { ...c, selected: false })
      this.retryDeadline = this.retryDeadline.map(c => c.value === this.localSetup.RetryLimit ? { ...c, selected: true } : { ...c, selected: false })
      this.isEvergreen = this.role.IsEvergreen

      if (this.localSetup.RoleClosingTime > 0) {
        this.closingTimes = this.closingTimes.map(v => v.value === this.localSetup.RoleClosingTime ? { ...v, selected: true } : { ...v, selected: false })
      } else {
        this.closingTimes = this.closingTimes.map(v => v.value === this.role.RoleClosingTime ? { ...v, selected: true } : { ...v, selected: false })
      }

      if (this.role.RoleClosingTime == 0) { this.time = "00:00AM" }
      if (this.role.RoleClosingTime == 1) { this.time = "01:00AM" }
      if (this.role.RoleClosingTime == 2) { this.time = "02:00AM" }
      if (this.role.RoleClosingTime == 3) { this.time = "03:00AM" }
      if (this.role.RoleClosingTime == 4) { this.time = "04:00AM" }
      if (this.role.RoleClosingTime == 5) { this.time = "05:00AM" }
      if (this.role.RoleClosingTime == 6) { this.time = "06:00AM" }
      if (this.role.RoleClosingTime == 7) { this.time = "07:00AM" }
      if (this.role.RoleClosingTime == 8) { this.time = "08:00AM" }
      if (this.role.RoleClosingTime == 9) { this.time = "09:00AM" }
      if (this.role.RoleClosingTime == 10) { this.time = "10:00AM" }
      if (this.role.RoleClosingTime == 11) { this.time = "11:00AM" }
      if (this.role.RoleClosingTime == 12) { this.time = "12:00PM" }
      if (this.role.RoleClosingTime == 13) { this.time = "01:00PM" }
      if (this.role.RoleClosingTime == 14) { this.time = "02:00PM" }
      if (this.role.RoleClosingTime == 15) { this.time = "03:00PM" }
      if (this.role.RoleClosingTime == 16) { this.time = "04:00PM" }
      if (this.role.RoleClosingTime == 17) { this.time = "05:00PM" }
      if (this.role.RoleClosingTime == 18) { this.time = "06:00PM" }
      if (this.role.RoleClosingTime == 19) { this.time = "07:00PM" }
      if (this.role.RoleClosingTime == 20) { this.time = "08:00PM" }
      if (this.role.RoleClosingTime == 21) { this.time = "09:00PM" }
      if (this.role.RoleClosingTime == 22) { this.time = "10:00PM" }
      if (this.role.RoleClosingTime == 23) { this.time = "11:00PM" }

    },
    async handleChosenVideo (video) {
      const newVid = Object.assign({}, video)
      this.addVideoToLocalSetup(newVid)
    },
    addVideoToLocalSetup (video) {
      if (this.typeName === 'IntroScreen1Video') {
        this.localSetup = { ...this.localSetup, IntroScreen1Video: video, IntroScreen1VideoId: video.UploadedVideoId }
        this.introScreen1Text = false
        this.localSetup.IntroScreen1 = ''
        this.showAddInsertVideoModal = false
      } else if (this.typeName === 'IntroScreen2Video') {
        this.localSetup = { ...this.localSetup, IntroScreen2Video: video, IntroScreen2VideoId: video.UploadedVideoId }
        this.introScreen2Text = false
        this.localSetup.IntroScreen2 = ''
        this.showAddInsertVideoModal = false
      }
    }
  }
}
</script>
