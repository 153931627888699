import axiosInstance from '../instance.js'
const api1 = new axiosInstance(1)

export default class libraryService {
  // get library
  static async getLibrary (filterType, vid) {
    return await api1.get('/api/go/getlibrary?filterType=' + filterType + '&vid=' + vid)
  }

  // get video from accountId and videoURL
  static async getCandidateAnswerFromUrl (accountId, videoURL) {
    return await api1.get(`/api/go/getcandidateanswerfromurl?accountId=${accountId}&videoURL=${videoURL}`)
  }

  // get uploaded video from accountId and videoURL
  static async getVidFromURL (accountId, videoURL) {
    return await api1.get(`/api/go/getuploadedvideofromaccandurl?accountId=${accountId}&videoURL=${videoURL}`)
  }

  // get video from videoId
  static async getVidFromVidId (videoId) {
    return await api1.get(`/api/go/getvidfromvidid?videoId=${videoId}`)
  }

  // get video from videoId
  static async getVideoFromVideoId (videoId, accountId) {
    return await api1.get(`/api/go/getvideofromvideoid?videoId=${videoId}&accountId=${accountId}`)
  }

  // get video from accountID and videoURL
  static async getVideoCVVidFromURL (accountId, videoURL) {
    return await api1.get(`/api/go/getvideocvvidfromurl?accountId=${accountId}&videoURL=${videoURL}`)
  }

  // get library
  static async getMostRecentVid (accountId) {
    return await api1.get(`/api/go/getmostrecentvid?accountId=${accountId}`)
  }

  // delete video
  static async deleteVideo (guid) {
    return await api1.delete('/api/go/deletelibraryvideo?guid=' + guid)
  }

  // update video
  static async updateVideo (guid, videoTitle, videoType, videoTags) {
    if (videoTags.length === 0 || videoTags === '') {
      videoTags = 'null'
    }
    return await api1.post('/api/go/updatelibraryvideo?guid=' + guid + '&videoTitle=' + videoTitle + '&videoType=' + videoType + '&tags=' + videoTags, null)
  }

  // get url
  static async getUrl (fileName) {
    return await api1.get('/api/go/getlibraryurl?fileName=' + fileName)
  }

  // get library numbers
  static async getLibraryNumbers () {
    return await api1.get('/api/go/getlibrarynumbers')
  }

  // append thumb
  static async appendThumb (formData) {
    return await api1.post('/api/go/appendthumb', formData)
  }

  // still processing webm to mp4
  static async isStillProcessing (guid) {
    return await api1.get('/api/go/isstillprocessing?guid=' + guid)
  }

  // add outro video
  static async addOutroVideo (jobId, videoId) {
    return await api1.post('/api/go/addoutrovideo?jobId=' + jobId + '&videoId=' + videoId, null)
  }

  // delete outro video
  static async deleteOutroVideo (jobId) {
    return await api1.delete('/api/go/deleteoutrovideo?jobId=' + jobId)
  }

  // get outro video
  static async getOutroVideo (jobId) {
    return await api1.get('/api/go/getoutrovideo?jobId=' + jobId)
  }

  // brings in answers for one way review modal
  static async getCandidateVideoStream (videoId) {
    return await api1.get('/api/go/getcandidatevideostream?videoId=' + videoId)
  }
}
