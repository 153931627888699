<template>
  <mdb-modal-header style="background-color: var(--bg-color) !important;color:  var(--lk-color) !important;position:relative;" class="d-flex align-items-center p-0">
    <svg
      id="a"
      style="position:absolute; left: 0px; top: 0px;width:100%;height: 180px; overflow: hidden;"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 800 80"
    ><path :style="{'fill': lightenDarkenColor(userData.lbl.PrimaryColour, -30)}" d="M956,84V-7L-13-18V84" /><path :style="{'fill': lightenDarkenColor(userData.lbl.PrimaryColour, -30)}" d="M276.93,85.42c1.94,.24,3.87,.51,5.75,.75,118.78,15.72,262.33,1.93,386.32,14.83,105.46,10.96,184.63-5.2,287-17V-7L-13-18l.31,101.59c31.66-5.27,64.47-8.29,97.53-9.25,64.96-1.98,129.92,3.05,192.09,11.08Z" /><path :style="{'fill': lightenDarkenColor(userData.lbl.PrimaryColour, -15)}" d="M-13-18l.31,58.02c33.66-5.51,68.71-8.18,103.76-8.29,62.23-.24,123.02,6.8,181.19,16.32,1.82,.3,3.57,.6,5.39,.9,111.15,18.57,201.26,29.28,320.34,43.05,116.87-2.47,248.06,6.07,358-8V-7L-13-18Z" /><path class="e" d="M97.37-10.89c59.45,1.8,116.17,10.54,170.3,21.59,1.7,.33,3.33,.69,5.02,1.02,103.52,21.44,207.47,47.46,321.64,62.2,120.35,15.51,244.21,12.22,361.6-4.43l.06-76.49L-13-18l.31,14.24C23.03-9.63,60.32-11.99,97.37-10.89Z" /><path :style="{'fill': lightenDarkenColor(userData.lbl.PrimaryColour, 20)}" d="M956-7L-13-18h0c40,0,113,4,152,2,56.66,3.62,36,1,102,1,19,0,40-1,66,0,95.89,24.29,154.27,40.68,263.61,56.34,127.43,18.27,260.8,14.67,385.39,3.03V-7Z" /><path :style="{'fill': lightenDarkenColor(userData.lbl.PrimaryColour, 35)}" d="M468-7c88.27,27.16-25.67-1.36,78.76,15.32,126.28,20.18,261.11,18.21,386.18-1.59,7.75-1.23,15.38-2.52,23.07-3.86V-7H468Z" /></svg>
    <div style="z-index:1;height:100px;width:90%;" class="d-flex align-items-center px-2 px-lg-3 py-2">
      <div class="mr-3">
        <div
          v-if="!candidate.ProfilePicture || candidate.ProfilePicture.includes('candidate-silhouette') || candidateApplication.Job?.MaskCandidateProfilePicture || spotlightMasking === 2 || spotlightMasking === 3"
          style="border-radius:50%;min-width:50px;width:4vw;height:4vw;min-height:50px;border:solid 3px; cursor:pointer;"
          class="p-2 d-flex align-items-center justify-content-center"
        >
          <i class="fa fa-user fa-2x" />
        </div>
        <div
          v-else
          style="border-radius:50%; cursor:pointer;"
          class="p-1"
        >
          <img
            :src="candidate.ProfilePicture"
            style="object-fit:cover;display:block;border-radius:50%;min-width:50px;width:4vw;height:4vw;min-height:50px;border:solid 3px;"
          >
        </div>
      </div>
      <div class="mobileWatchT">
        <div class="d-flex align-items-center">
          <h5
            v-if="candidateApplication.Job?.MaskGoCandidateName || spotlightMasking === 2"
            class="mobileWatchF text-left"
            style="color:  var(--lk-color) !important;font-weight:bold;font-size:2rem;"
          >
            Candidate Id: {{ candidate.CandidateId }}
          </h5>
          <h5
            v-else
            class="mobileWatchF text-left"
            style="color:  var(--lk-color) !important;font-weight:bold;font-size:2rem;"
          >
            {{ candidate.Name }}
          </h5>
          <IDCheckedBadge
            v-if="candidate.IDChecked"
            class="ml-3"
          />
        </div>
        <div
          v-if="modalContext === 'ROLES' && candidateApplication.Following"
          :style="{ 'background': `${userData.lbl.SecondaryColour} !important` }"
          style="padding: 1px;font-size: 14px;width: 90px;border-radius: 5px;height: 23px;cursor:pointer;"
          @click="unFollowCandidate(candidate)"
        >
          <mdb-icon
            icon="star"
            :style="{ 'color': `${userData.lbl.PrimaryColour} !important` }"
            title="Unfollow candidate"
          />
          <span
            style="font-size:12px;margin-left:3px;"
            :style="{ 'color': `${userData.lbl.PrimaryColour} !important` }"
          >Following</span>
        </div>
        <div
          v-if="modalContext === 'ROLES' && !candidateApplication.Following"
          :style="{ 'background': `${userData.lbl.SecondaryColour} !important` }"
          style="padding: 1px;font-size: 14px;width: 90px;border-radius: 5px;height: 23px;cursor:pointer;"
          @click="followCandidate(candidate)"
        >
          <mdb-icon
            far
            icon="star"
            :style="{ 'color': `${userData.lbl.PrimaryColour} !important` }"
            title="Follow candidate"
          />
          <span style="font-size:12px;margin-left:3px;" :style="{ 'color': `${userData.lbl.PrimaryColour} !important` }">Follow</span>
        </div>
      </div>
      <div v-if="(modalContext === 'ROLES' || modalContext === 'CANDIDATE_HISTORY') && !hideStatusDropdown" class="ml-auto d-none d-lg-flex align-items-center">
        <span class="mr-3" style="background:none;color:  var(--lk-color) !important;font-weight:bold;font-size:1.2rem;">
          <span v-show="candidateApplication.StageItemStatus === 1"><i class="fa fa-star-half-alt" /> <u>{{ candidateApplication.RoleWorkflowStageItemName }}</u></span>
          <span v-show="candidateApplication.StageItemStatus === 4"><i class="fa fa-star-half-alt" /> <u>{{ candidateApplication.RoleWorkflowStageItemName }}</u></span>
        </span>
        <div
          v-if="candidateApplication.CompleteStatusType"
          class="d-flex align-items-center"
        >
          <i class="fa fa-star mr-2" />
          <mdb-select
            id="progressStatus"
            v-model="applicationStatus"
            class="primary-colour"
            size="sm"
            style="height:49px;border: none !important;outline:none !important;background:none;width:188px;"
            @change="changeStatusEvent($event)"
          />
        </div>
      </div>
    </div>
  </mdb-modal-header>
</template>

<script>
import { useUserData, useRoleCandidate, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import candidateV2Service from '@/api/services/candidateV2.service'
import IDCheckedBadge from '@/components/misc/IDCheckedBadge'
import roleV2Service from '@/api/services/roleV2.service'

export default {
  name: 'ReviewHeader',
  components: {
    IDCheckedBadge
  },
  props: {
    modalContext: {
      type: String,
      default: ''
    },
    candidateApplication: {
      type: Object,
      default: () => {}
    },
    candidate: {
      type: Object,
      default: () => {}
    },
    hideStatusDropdown: {
      type: Boolean,
      default: false
    },
    watchInterviewModal: Boolean,
    spotlightMasking: {
      type: Number,
      default: 0
    },
    waitingUpdate: Boolean
  },
  setup (props) {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()

    const roleCandidateStore = useRoleCandidate()
    const { reviewModalRoleWorkflow, reviewModalSelectedStage } = storeToRefs(roleCandidateStore)

    // NEEDS WORK - make sure the ability to change a status is only on the stage the applicant is currently in
    const applicationStatus = ref({})
    const setDropdownOptions = () => {
      const completedStageItems = reviewModalSelectedStage.value.OrderedRoleWorkflowStageItems.filter(i => i.CompleteStatusType)
      applicationStatus.value = completedStageItems.map(stageItem => {
        return { text: stageItem.RoleWorkflowStageItemName, value: stageItem.RoleWorkflowStageItemId, selected: stageItem.RoleWorkflowStageItemId == props.candidateApplication.CurrentStatus }
      })
    }
    setDropdownOptions()

    return {
      authData,
      userData,
      reviewModalRoleWorkflow,
      applicationStatus,
      setDropdownOptions,
      reviewModalSelectedStage,
      showGenericErrorModal
    }
  },
  data () {
    return {
      windowWidth: window.innerWidth
    }
  },
  watch: {
    candidateApplication: {
      handler: function () {
        this.setDropdownOptions()
      },
      deep: true
    }
  },
  methods: {
    async followCandidate (candidate) {
      try {
        const res = await candidateV2Service.followCandidate(candidate.CandidateId)
        if (res.data === true) {
          this.$emit('setFollowing', true)
        }
      } catch {
        this.showGenericErrorModal()
      }
    },
    async unFollowCandidate (candidate) {
      try {
        const res = await candidateV2Service.unFollowCandidate(candidate.CandidateId)
        if (res.data === true) {
          this.$emit('setFollowing', false)
        }
      } catch {
        this.showGenericErrorModal()
      }
    },
    lightenDarkenColor (color, amount) {
      return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
    },
    async changeStatusEvent (e) {
      if (e === this.candidateApplication.CurrentStatus) return // no status change
      const JSONData = {
        RoleWorkflowStageItemId: e,
        RoleId: this.candidateApplication.JobId,
        ApplicantIds: [this.candidate.ApplicantId]
      }
      this.$emit('needsUpdating')
      try {
        await roleV2Service.bulkSetCandidateStatus(JSONData)
      } catch (err) {
        this.showGenericErrorModal()
      }
    }
  }
}
</script>

<style>
  #progressStatus {
    display: flex;
    align-items: center;
    width:100px;
    justify-content: center;
  }
  #progressStatus .select-wrapper span.caret {
    color: white;
  }
  #progressStatus .select-dropdown input {
    font-size: 1.3rem;
      color: white;
      font-weight: bold;
      border:none !important;
      width:180px;
  }
  #progressStatus > .select-wrapper {
    margin: 0px !important;
    height: 30px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
<style lang="scss" scoped>
 .mobileWatchF {
    font-size:1.5rem !important;
  }
  .e {
    fill:var(--pr-color);
  }
</style>
