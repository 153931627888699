<template>
  <!--modal boilerplate-->
  <mdb-modal
    id=""
    :show="previewTemplateModalShowing"
    size="lg"
    style="overflow-y:auto;"
    @close="$emit('previewTemplateModalShowing', false)"
  >
    <mdb-modal-header>
      <mdb-modal-title class="font-weight-bold">
        TEMPLATE PREVIEW
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body style="height: fit-content !important;">
      <QuestionTemplate
        :hide-score-summary="true"
        :hide-comment-box="true"
        :template="template"
      />
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import QuestionTemplate from '@/components/misc/ReviewModalV2/reviewModalComponents/scoring/question/QuestionTemplate'

export default {
  name: 'PreviewTemplateModal',
  components: {
    QuestionTemplate
  },
  props: {
    previewTemplateModalShowing: Boolean,
    template: {
      type: Object,
      default: () => {}
    },
    modalTitle: {
      type: String,
      default: 'TEST'
    }
  }
}
</script>
