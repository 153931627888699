<template>
  <div
    class="flex flex-col shadow"
    :class="mobileView ? 'px-1' : 'px-0'"
    style="max-width:100%;height:calc(100vh - 262px);"
  >
    <div class="background-colour flex flex-row items-center rounded-t-lg">
      <span
        style="font-size: 1.1rem"
        class="flex items-center secondary-colour py-2 px-4 rounded-t-lg"
      > {{ dateString }} </span>
      <mdb-icon
        v-if="timeslotsLoading"
        icon="fa fa-pulse fa-spinner secondary-colour"
        style="font-size: 1.5rem;margin-left:-15px;"
      />
      <div
        style="scale: 1.3"
        class="ml-auto flex space-x-4 pr-4"
      >
        <i
          class="fa fa-caret-left flex secondary-colour cursor-pointer"
          @click="previousDay()"
        />
        <i
          class="fa fa-caret-right flex secondary-colour cursor-pointer"
          @click="nextDay()"
        />
      </div>
    </div>

    <div class="flex flex-col w-full overflow-y-scroll shadow-sm">
      <div v-if="!timeslotsLoading && !selectedDateIsPastEndDate">
        <EventStageLocationSelectItem
          v-for="(location) in locations"
          :key="location.GoEventLocationId"
          :name="location.GoEventLocationName"
          :location="location"
          :stageId="selectedEvent.RoleWorkflowStageId"
          :timeslots="eventStageLocationTimeslots[location.GoEventLocationId]"
          @refreshTimeslots="loadInitial()"
        />
      </div>
      <span
        v-if="selectedDateIsPastEndDate"
        class="mt-3 dark-grey-text font-weight-bold"
      >
        STAGE CLOSED</span>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useScheduler, useEvent, useWorkflow } from '@/pinia';
import { storeToRefs } from 'pinia';
import { useMobile } from '@/composables/useMobile'
import moment from 'moment';
import { useRoute } from 'vue-router/composables'

import EventStageLocationSelectItem from '@/views/roles/innerComponents/Pages/AddEditRole/SchedulerSetup/eventStageLocationSelectItem';

export default {
	name: "TimeslotSelect",
	components: { EventStageLocationSelectItem },
  setup () {
    const { mobileView } = useMobile()
    const eventStore = useEvent()
    const { selectedEvent } = storeToRefs(eventStore)

    const workflowStore = useWorkflow()
    const { getSelectedStage } = useWorkflow()
    const { selectedWorkflowStage } = storeToRefs(workflowStore)

    const route = useRoute()

    if (!selectedWorkflowStage.value.RoleWorkflowStageId) {
      getSelectedStage(route.params.roleWorkflowStageId)
    }

    const stageType = computed(() => selectedWorkflowStage.value.StageType)
    const schedulerStore = useScheduler()
    const { loadSchedulerTimeslots } = useScheduler()
    const { selectedDate, eventStageLocationTimeslots, timeslotsLoading } = storeToRefs(schedulerStore)

    const nextDay = () => {
      selectedDate.value = moment(selectedDate.value).add(1, 'd')
      loadSchedulerTimeslots(selectedDate.value, route.params.roleWorkflowStageId)
    }

    const previousDay = () => {
      selectedDate.value = moment(selectedDate.value).subtract(1, 'd')
      loadSchedulerTimeslots(selectedDate.value, route.params.roleWorkflowStageId)
    }

    selectedDate.value = new Date()
    loadSchedulerTimeslots(selectedDate.value, route.params.roleWorkflowStageId)
    const dateString = computed(() => moment(selectedDate.value).format("dddd Do MMMM YYYY"))
    const selectedDateIsPastEndDate = computed(() => new Date(selectedWorkflowStage?.value.EndDate) < new Date(selectedDate.value._d))

    const virtual = [
      {
        GoEventLocationId: -1,
        GoEventLocationName: 'Virtual Event'
      }
    ]
    const liveInterview = [
      {
        GoEventLocationId: -1,
        GoEventLocationName: selectedWorkflowStage.value.RoleWorkflowStageName == null ? "Live Interview" : selectedWorkflowStage.value.RoleWorkflowStageName
      }
    ]

    const locations = computed(() => {
      if (stageType.value === 2) {
        return liveInterview
      } else if (selectedWorkflowStage.value.StageType === 2) {
        return liveInterview
      } else {
        return selectedEvent?.value?.CustomUrl == null ? selectedEvent.value.GoEventLocations : virtual
      }
    })

    const loadInitial = () => {
      loadSchedulerTimeslots(selectedDate.value, route.params.roleWorkflowStageId)
    }
    loadInitial()

    return {
      dateString,
      mobileView,
      selectedEvent,
      selectedDate,
      nextDay,
      previousDay,
      timeslotsLoading,
      eventStageLocationTimeslots,
      locations,
      stageType,
      selectedDateIsPastEndDate,
      loadInitial
    }
  }
}
</script>
