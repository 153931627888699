<template>
  <div class="px-3 px-md-5" style="text-align:left;padding:2px;margin:50px 0px;width:100%;">
    <div class="h5 grey-text">
      About this role
    </div>
    <validation-observer v-slot="{ }">
      <div class="md-form form-sm" style="margin-top:40px;">
        <validation-provider
          v-slot="{ errors }"
          mode="lazy"
          name="Job Title"
          rules="required"
        >
          <i class="fa fa-signature prefix" style="margin-top: 6px;font-size: 1.3rem;" />
          <mdb-input
            v-model="localRole.JobTitle"
            type="text"
            auto-complete="jbt"
            label="Job Title"
            style="margin-left:33px;"
            size="sm"
          />
          <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
            Please provide a job title
          </div>
        </validation-provider>
      </div>
      <div class="md-form form-sm" style="margin-top:40px;">
        <validation-provider
          v-slot="{ errors }"
          mode="lazy"
          name="Opening Date"
          rules="required"
        >
          <div style="font-size: 0.7rem;color:#888;transform:translate(30px, -10px)">
            Opening date
          </div>
          <i class="far fa-clock prefix" style="margin-top: 6px;font-size: 1.3rem;" />
          <input
            v-model="localRole.OpenFrom"
            type="date"
            :min="minimumDate"
            onkeydown="return false"
            :disabled="role.JobId"
            auto-complete="opd"
            label="Opening date"
            style="margin-left:33px;font-size: 0.9rem;color:#888;"
            size="sm"
          >
          <div
            v-if="errors[0]"
            style="font-size: 0.7rem;margin-left: 33px;margin-bottom:0px;"
            class="mt-2"
          >
            Please provide an opening date
          </div>
        </validation-provider>
      </div>
      <div
        v-if="!isEvergreen"
        class="md-form form-sm"
        style="margin-top:40px;"
      >
        <validation-provider
          v-slot="{ errors }"
          mode="lazy"
          name="Closing Date"
          rules="required"
        >
          <div style="font-size: 0.7rem;color:#888;transform:translate(30px, -10px)">
            Closing date
          </div>
          <i class="far fa-clock prefix" style="margin-top: 6px;font-size: 1.3rem;" />
          <input
            v-model="localRole.Expires"
            type="date"
            :min="minimumDate"
            onkeydown="return false"
            auto-complete="opd"
            label="Closing date"
            style="margin-left:33px;font-size: 0.9rem;color:#888;"
            size="sm"
          >
          <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:10px;margin-bottom:0px;">
            Please provide a closing date
          </div>
        </validation-provider>
      </div>
      <div
        v-if="!isEvergreen"
        class="md-form form-sm"
        style="margin-top:40px;"
      >
        <div style="font-size: 0.7rem;color:#888;transform:translate(30px, -10px)">
          Optionally, what time do you want the role to close (default is midnight)?
        </div>
        <i class="far fa-hourglass prefix" style="margin-top: 6px;font-size: 1.3rem;" />
        <mdb-select
          v-model="closingTimes"
          style="margin-left:33px;margin-top: -40px;"
          placeholder="Select time"
          size="sm"
        />
      </div>
      <div
        class="md-form form-sm m-0 mb-5"
        style="transform: translateX(-18px)"
        @click="isEvergreen = !isEvergreen"
      >
        <mdb-input
          v-model="isEvergreen"
          type="checkbox"
          label="Evergreen Role"
          size="sm"
        />
      </div>
      <div class="md-form form-sm" style="margin-top:40px;">
        <div style="font-size: 0.7rem;color:#888;transform:translate(30px, -10px)">
          Allow shared video interviews to be viewed until
        </div>
        <i class="far fa-clock prefix" style="margin-top: 6px;font-size: 1.3rem;" />
        <input
          v-model="localRole.SharingEndDate"
          type="date"
          :min="minimumDate"
          onkeydown="return false"
          auto-complete="mls"
          label="Allow shared video interviews to be viewed until"
          style="margin-left:33px;font-size: 0.9rem;color:#888;"
          size="sm"
        >
      </div>
      <div class="md-form form-sm" style="margin-top:40px;">
        <i class="far fa-question-circle prefix" style="margin-top: 6px;font-size: 1.3rem;" />
        <mdb-input
          v-model="localRole.JobReferenceId"
          type="text"
          auto-complete="jid"
          label="External Reference Id (optional)"
          style="margin-left:33px;"
          size="sm"
        />
      </div>
    </validation-observer>
    <div class="d-flex align-items-center justify-content-between">
      <mdb-btn
        class="btn btn-outline"
        size="sm"
        style="width:110px;"
        @click="$router.go(-1)"
      >
        CANCEL
      </mdb-btn>
      <mdb-btn
        v-show="!buttonClick"
        :disabled="!formIsValid"
        class="my-3 ml-auto"
        size="sm"
        style="width:110px;"
        @click="updateRoleAndNext()"
      >
        NEXT
      </mdb-btn>
      <mdb-btn
        v-show="buttonClick"
        class="my-3 ml-auto"
        size="sm"
        style="width:110px;cursor:default;"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import roleV2Service from '@/api/services/roleV2.service'
import { useUserData, useStatus, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'AboutThisRole',
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const workflowStore = useWorkflow()
    const { role, addRoleToRecentRoles } = storeToRefs(workflowStore)

    const { showGenericErrorModal } = useStatus()

    return {
      role,
      addRoleToRecentRoles,
      showGenericErrorModal,
      authData
    }
  },
  data () {
    return {
      minimumDate: '',
      localRole: {},
      buttonClick: false,
      isEvergreen: false,
      closingTimes: [{ text: '00:00AM', value: 0 }, 
      { text: '01:00AM', value: 1 }, 
      { text: '02:00AM', value: 2 }, 
      { text: '03:00AM', value: 3 }, 
      { text: '04:00AM', value: 4 }, 
      { text: '05:00AM', value: 5 }, 
      { text: '06:00AM', value: 6 }, 
      { text: '07:00AM', value: 7 }, 
      { text: '08:00AM', value: 8 }, 
      { text: '09:00AM', value: 9 }, 
      { text: '10:00AM', value: 10 }, 
      { text: '11:00AM', value: 11 }, 
      { text: '12:00PM', value: 12 }, 
      { text: '01:00PM', value: 13 }, 
      { text: '02:00PM', value: 14 }, 
      { text: '03:00PM', value: 15 }, 
      { text: '04:00PM', value: 16 }, 
      { text: '05:00PM', value: 17 }, 
      { text: '06:00PM', value: 18 }, 
      { text: '07:00PM', value: 19 }, 
      { text: '08:00PM', value: 20 }, 
      { text: '09:00PM', value: 21 }, 
      { text: '10:00PM', value: 22 }, 
      { text: '11:00PM', value: 23 }]
    }
  },
  computed: {
    formIsValid () {
      return ((this.isEvergreen == false && this.localRole.Expires != 'Invalid date' && this.localRole.Expires != null) || this.isEvergreen) && this.localRole.JobTitle
    },
    closingTime: function () {
      var c = ''
      this.closingTimes.forEach(element => {
        if (element.selected === true) {
          c = element.value
        }
      })
      return c
    },
  },
  watch: {
    isEvergreen () {
      if (this.isEvergreen) {
        this.localRole.Expires = '2999-12-01'
      } else {
        this.localRole.Expires = null
      }
    },
    role: {
      handler: async function () {
        this.localRole = { ...this.role, Step: 4, JobDetails: { ...this.role.JobDetails } }
        await this.$nextTick(() => {})
        this.setLocalDropDownsFromRole()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    setMinimumDate (date) {
      var month = date.getMonth() + 1
      var day = date.getDate()
      var year = date.getFullYear()
      if (month < 10) {
        month = '0' + month.toString()
      }
      if (day < 10) {
        day = '0' + day.toString()
      }
      this.minimumDate = year + '-' + month + '-' + day
    },
    setLocalDropDownsFromRole () {
      this.isEvergreen = this.localRole.IsEvergreen
      var roleClosingTime = 0;
      if (this.localRole.RoleClosingTime > 0) {
        roleClosingTime = this.localRole.RoleClosingTime
      }
      this.closingTimes = this.closingTimes.map(v => v.value === roleClosingTime ? { ...v, selected: true } : { ...v, selected: false })

      if (!this.role.JobId) {
        this.setMinimumDate(new Date())
      } else { this.setMinimumDate(new Date(this.role.OpenFrom)) }
      try {
        this.localRole.OpenFrom = moment(String(this.role.OpenFrom)).format('YYYY-MM-DD')
      } catch {}
      try {
        this.localRole.Expires = moment(String(this.role.Expires)).format('YYYY-MM-DD')
      } catch {}
      try {
        this.localRole.SharingEndDate = moment(String(this.role.SharingEndDate)).format('YYYY-MM-DD')
      } catch { this.localRole.SharingEndDate = null }
    },
    async updateRoleAndNext () {
      this.buttonClick = true
      this.localRole.IsEvergreen = this.isEvergreen

      if (this.closingTime > 0) {
      this.localRole.RoleClosingTime = this.closingTime
      } else {
        this.localRole.RoleClosingTime = 0
      }

      try {
        const res = await roleV2Service.addEditRoleSetup({ ...this.localRole, Step: 1 })
        if (this.localRole.JobId == 0) { await roleV2Service.followRole(res.data.JobId) }
        this.role = res.data
        await this.addRoleToRecentRoles(this.role.JobId)
        this.$router.push(`/roles/add_edit/role_setup/${this.role.JobId}?page=invitation_options`).catch(() => { })
      } catch {
        this.showGenericErrorModal()
      }
      this.buttonClick = false
    }
  }
}
</script>
