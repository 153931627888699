<template>
  <!--VCV-->
  <div class="flex-grow-1 reviewModalRHTab">
    <div style="height:fit-content;">
      <div
        v-for="question in candidateApplication.Questions"
        :key="question.QuestionId"
        class="d-flex align-items-center text-left cp"
        style="width:100%;height:60px;border-bottom:1px solid #c0c0c0"
      >
        <div class="d-flex align-items-center text-left cp mr-auto flex-grow-1" @click="selectQuestion(question.QuestionId)">
          <div
            v-if="question.Video != null"
            class="p-3"
            style="font-size:larger;color:grey"
            :class="{ 'primary-colour' : question.QuestionId === currentQuestionId && currentVideo.VideoUrl != null }"
          >
            <i class="fa fa-play" />
          </div>
          <div
            v-if="question.Video != null"
            class="twoLinesText"
            style="color:grey"
            :class="{ 'primary-colour' : question.QuestionId === currentQuestionId && currentVideo.VideoUrl != null }"
          >
            <span>{{ question.QuestionText }}</span>
          </div>
          <div
            v-if="question.Video == null"
            class="p-3"
            style="font-size:larger;color:grey;"
            :class="{ 'primary-colour' : question.QuestionId === currentQuestionId }"
          >
            <i class="fa fa-times" />
          </div>
          <div
            v-if="question.Video == null"
            class="twoLinesText"
            style="color:grey;"
            :class="{ 'primary-colour' : question.QuestionId === currentQuestionId }"
          >
            <span>{{ question.QuestionText }}</span>
          </div>
        </div>
      </div>
      <div v-if="candidateApplication.Questions.length == 0">
        <p style="padding:10px;font-size:small;" class="grey-text">
          This candidate has no Video CV answers.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VCVQuestions',
  components: { },
  props: {
    currentQuestionId: {
      type: Number,
      default: 0
    },
    candidateApplication: {
      type: Object,
      default: () => {}
    },
    currentVideo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      qid: 0
    }
  },
  methods: {
    selectQuestion (questionId) {
      this.$emit('setQuestionId', questionId)
    }
  }
}
</script>

<style lang="scss" scoped>
.opacity {
  opacity: .8
}

.twoLinesText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: N;
  line-height: 18px;
  max-height: 36px;
}
</style>
