<template>
  <i
    v-if="loading"
    class="fa fa-spin fa-spinner"
    style="font-size:1.5rem;padding:10px;"
  />
  <div
    v-else
    class="flex flex-col px-2"
  >
    <div
      v-if="workingPattern.length > 0"
    >
      <div class="flex flex-row items-center">
        <font-awesome-icon
          :icon="['far', 'calendar-days']"
          class="mr-2 text-primary"
        />
        <mdb-select
          v-model="workingPatternDropdown"
          class="w-full"
          placeholder="Choose Working Pattern"
        />
      </div>

      <div class="flex flex-col text-primary p-2 rounded-sm">
        <div
          v-for="(slot,i, x) in selectedWorkingPattern?.GoEventTimeslotsDictionary"
          :key="i"
        >
          <div
            class="flex flex-row items-center space-x-3 w-full mb-2 py-1 px-2 rounded-lg"
            :class="x % 2 === 0 ? 'bg-gray-100' : 'bg-gray-50'"
          >
            <i class="fa fa-regular fa-clock" />
            <span> {{ i }} </span>

            <div class="ml-auto flex flex-row space-x-1">
              <span
                v-for="(time,y) in slot"
                :key="y"
                class="w-8 h-8 rounded-full border text-xs flex items-center justify-center font-bold"
              >
                {{ time.ApplicationUser?.FirstName[0] }}
                {{ time.ApplicationUser?.Surname[0] }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex flex-col"
        style="margin-top:-10px;"
      >
        <span class="text-primary text-left font-bold">
          Apply slots to the following 'from' and 'to' dates and/or specific days:
        </span>

        <span
          class="text-left text-xs text-primary"
          style="margin-top:20px;margin-left:20px;color:#555 !important;"
        >
          Start date
        </span>
        <div
          class="w-full flex flex-row"
          style="margin-top:-20px;"
        >
          <font-awesome-icon
            :icon="['far', 'calendar']"
            class="mr-2 text-primary"
            style="margin-top:38px;"
          />
          <mdb-input
            v-model="eventSlot.StartDate"
            type="date"
            class="w-full"
            placeholder="Start Date"
          />
        </div>

        <span
          class="text-left text-xs text-primary"
          style="margin-top:0px;margin-left:20px;color:#555 !important;"
        >
          End date
        </span>
        <div
          class="w-full flex flex-row"
          style="margin-top:-20px;"
        >
          <font-awesome-icon
            :icon="['far', 'calendar']"
            class="mr-2 text-primary"
            style="margin-top:38px;"
          />
          <mdb-input
            v-model="eventSlot.EndDate"
            type="date"
            class="w-full"
            placeholder="End Date"
          />
        </div>
        <DaySelect
          class="w-full"
          @toggleDay="daysOfWeek = $event"
        />
      </div>

      <div
        v-if="selectedEvent.GoEventLocations.length"
        class="text-left"
      >
        <span
          class="text-xs text-primary"
          style="margin-top:20px;margin-left:20px;color:#555 !important;"
        >
          Location
        </span>
        <div
          class="flex flex-row items-center"
          style="margin-top:-20px;"
        >
          <font-awesome-icon
            :icon="['fas', 'location-dot']"
            class="mr-2 text-primary"
          />
          <mdb-select
            v-model="locationsDropdown"
            class="w-full"
            placeholder="Please select"
          />
        </div>
      </div>

      <div class="flex pb-1">
        <mdb-btn
          size="sm"
          color="secondary"
          class="mr-auto m-0 btn btn-outline text-xs w-1/3 sm:w-1/5"
          style="width:110px;"
          @click="reset()"
        >
          CLEAR
        </mdb-btn>
        <mdb-btn
          :disabled="formInvalid"
          size="sm"
          color="white"
          class="ml-auto m-0 text-xs w-1/3 sm:w-1/5"
          style="width:110px;"
          @click="addEventSlot()"
        >
          CREATE
        </mdb-btn>
      </div>
    </div>
    <div v-else>
      There are no defined working patterns to select from.
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useEvent, useStatus } from '@/pinia'
import { useMobile } from '@/composables/useMobile'
import { storeToRefs } from 'pinia'
import eventService from '@/api/services/event.service'
import DaySelect from '@/views/roles/innerComponents/Pages/AddEditRole/SchedulerSetup/daySelect'

export default {
  name: 'AddWorkingDay',
  components: { DaySelect },
  setup () {
    const { mobileView } = useMobile()
    const eventStore = useEvent()
    const { selectedEvent, eventStageLocationTimeslots, selectedDate, timeslotsLoading, daySelected } = storeToRefs(eventStore)
    const { getWorkingPatterns, getSchedulerTimeslots, days} = useEvent()
    const { showGenericErrorModal } = useStatus()

    const locationsDropdown = ref([])
    const loading = ref(true)
    const key = ref(0)

    const workingPatternDropdown = ref()
    const workingPattern = ref()
    const selectedWorkingPatternIndex = computed(() => workingPatternDropdown?.value.find(x => x.selected)?.value)
    const selectedWorkingPattern = computed(() => workingPattern.value.find(x => x.GoEventWorkingPatternId === selectedWorkingPatternIndex.value))

    const daysOfWeek = ref([])

    const eventSlot = ref({
      StartDate: null,
      EndDate: null
    })

    const load = async () => {
      loading.value = true
      try {
        if (selectedEvent.value.GoEventLocations) mapLocations()
        await loadWorkingPatterns()
      } catch (error) {
        showGenericErrorModal({ showing: true })
      }
      loading.value = false
    }

    const loadWorkingPatterns = async () => {
      const res = await getWorkingPatterns()
      workingPattern.value = res
      workingPatternDropdown.value = res.map(c => ({ text: c.GoEventWorkingPatternName, selected: false, value: c.GoEventWorkingPatternId }))
    }

    const mapLocations = () => {
      locationsDropdown.value = selectedEvent.value.GoEventLocations.map(c => ({
        text: c.GoEventLocationName,
        selected: false,
        value: c.GoEventLocationId
      }))
      if (locationsDropdown.value.length === 1) {
        locationsDropdown.value[0].selected = true
      }
    }

    const selectedLocation = computed(() => locationsDropdown.value.filter(x => x.selected).map(y => y.value)[0])

    const addEventSlot = async () => {
      try {
        timeslotsLoading.value = true
        const obj = selectedWorkingPattern.value.GoEventTimeslotsDictionary
        for (const key in obj){
          const array = obj[key]
          let payload = {
            RoleWorkflowStageId: selectedEvent.value.RoleWorkflowStageId,
            StartTime: array[0].StartTime,
            EndTime: array[0].EndTime,
            StartDate: eventSlot.value.StartDate,
            EndDate: eventSlot.value.EndDate,
            NumberOfSlots: Number(array.length),
            ApplicationUsers: array.map(x => x.ApplicationUser?.Id ? x.ApplicationUser.Id : null),
            GoEventLocationId: selectedLocation.value,
            DaysOfWeek: daysOfWeek.value,
            GoEventWorkingPatternId: selectedWorkingPatternIndex.value
          }
          const res = await eventService.addEventTimeslot(payload)
          if (res.data === true) {
            reset()
            const res = await getSchedulerTimeslots(selectedDate.value)
            eventStageLocationTimeslots.value = res
          }
        }
      } catch (error) {
        showGenericErrorModal({ showing: true })
      } finally {
        timeslotsLoading.value = false
      }
    }

    const formInvalid = computed(() => {
      let invalid = null
      if (selectedEvent.value.CustomUrl && selectedEvent.value.GoEventLocations.length === 0) {
        invalid = (
          eventSlot.value.StartDate === null ||
          eventSlot.value.EndDate === null ||
          !selectedWorkingPattern.value
        )
      } else {
        invalid = (
          eventSlot.value.StartDate === null ||
          eventSlot.value.EndDate === null ||
          !selectedWorkingPattern.value ||
          !selectedLocation.value
        )
      }
      return invalid
    })

    const reset = () => {
      eventSlot.value.StartDate = null,
      eventSlot.value.EndDate = null,
      daysOfWeek.value = []
      daySelected.value = [false, false, false, false, false, false, false]
      workingPatternDropdown.value = workingPatternDropdown.value.map(c => ({ ...c, selected: false }))
      if (selectedEvent.value.GoEventLocations.length > 1) {
        locationsDropdown.value = locationsDropdown.value.map(x => ({ ...x, selected: false }))
      }
    }

    load()

    return {
      daysOfWeek,
      mobileView,
      workingPatternDropdown,
      workingPattern,
      selectedWorkingPattern,
      selectedWorkingPatternIndex,
      loading,
      eventSlot,
      addEventSlot,
      locationsDropdown,
      days,
      key,
      formInvalid,
      selectedEvent,
      reset
    }
  }
}
</script>
