<template>
  <div style="width:100%; flex-grow: 1; overflow: auto;" class="px-3 px-md-4">
    <div class="font-weight-bold text-left my-3 d-flex align-items-center justify-content-between" style="height:50px">
      <div class="d-flex align-items-center">
        <!-- <span class="d-none d-sm-inline mr-2">Spotlight</span> -->
        <span
          v-if="logo != '' && spotlightName != ''"
          class="d-flex align-items-center mr-2"
          style="height:50px"
        >
          <img
            style="height:50px;max-height:50px;object-fit:cover;max-width:100px;object-fit:contain;"
            class="mr-2"
            :src="logo"
            :style="logo.includes('shine_logo_white') || logo.includes('shine-logo-white') ? 'background: #FF7e00; padding: 5px' : ''"
          >
          <div>{{ spotlightName }}
            <span style="font-size:0.8rem" class="font-weight-light">{{ spotlight.AllowWorkflowChanges ? '' : ' (Workflow Disabled)' }}</span>
          </div>
          <Tooltip
            v-if="spotlight.ReasonsForDisabledWorkflow.length > 0"
            class="ml-3"
            tooltip-direction="right"
            :bullet-point-array="spotlight.ReasonsForDisabledWorkflow"
            :tool-tip-summary="`Your external client will NOT be able to change any candidates status. Reason${spotlight.ReasonsForDisabledWorkflow.length > 1 ? '\'s' : ''} for disabled workflow:`"
          />
        </span>
        <i
          v-if="spotlightLoading"
          style="margin-left: 5px !important;"
          class="fa fa-spin fa-spinner ml-3 d-none d-sm-block"
        />
      </div>
      <ExpandableSearchBar
        @search="search = $event"
      />
    </div>

    <SpotlightWorkflowDraggable
      style="height: calc(100vh - 280px); overflow: auto; min-width:1000px; max-width:100%;"
      class="mb-3 pb-3"
      :spotlight="spotlight"
      :candidates="candidates"
      :search="search"
      @getSpotlightCandidates="getSpotlightCandidates()"
    />

    <div class="d-flex flex-wrap align-items-center justify-content-between" style="width:100%;">
      <mdb-btn
        size="sm"
        class="btn btn-outline m-0 my-2 my-md-1 "
        style="min-width:110px;width:fit-content;padding: 0.5rem 1.1rem;"
        @click="$router.back(-1)"
      >
        BACK
      </mdb-btn>
      <mdb-btn
        size="sm"
        class="btn m-0 my-2 my-md-1"
        style="min-width:110px;width:fit-content;padding: 0.5rem 1.1rem;"
        @click="openAddCandidateModal()"
      >
        <i class="fa fa-plus-circle" /> ADD
      </mdb-btn>
    </div>

    <AddRoleCandModal
      :spotlight="spotlight"
      :add-role-candidates-modal-showing="addRoleCandidatesModalShowing"
      :existing-candidates="candidateIds"
      @closeAddCandidateModal="closeAddRoleCandidateModal()"
    />

    <AddVCVCandModal
      :spotlight="spotlight"
      :add-v-c-v-candidates-modal-showing="addVCVCandidatesModalShowing"
      @closeAddCandidateModal="closeAddVCVCandidateModal()"
    />
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import clientPortalService from '@/api/services/clientPortal.service'

import SpotlightWorkflowDraggable from './spotlightWorkflowDraggable.vue'
import ExpandableSearchBar from '@/components/UI/expandableSearch.vue'
import AddRoleCandModal from './addCandModals/roleCandidatesModal.vue'
import AddVCVCandModal from './addCandModals/VCVCandidatesModal.vue'
import Tooltip from '@/components/misc/tooltip.vue'

export default {
  name: 'SpotlightWorkflowSection',
  components: {
    SpotlightWorkflowDraggable,
    ExpandableSearchBar,
    AddRoleCandModal,
    AddVCVCandModal,
    Tooltip
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()

    return {
      authData,
      showGenericErrorModal
    }
  },
  data () {
    return {
      spotlight: { ReasonsForDisabledWorkflow: [] },
      spotlightId: 0,
      search: '',
      spotlightLoading: true,
      logo: '',
      spotlightName: '',
      candidates: [],
      addRoleCandidatesModalShowing: false,
      addVCVCandidatesModalShowing: false
    }
  },
  computed: {
    candidateIds () {
      return this.candidates.map(c => c.CandidateId)
    },
    urlQuerySpotlightId () {
      if (this.$route.query.slId === undefined) {
        return 0
      } else {
        return Number(this.$route.query.slId)
      }
    }
  },
  watch: {
    urlQuerySpotlightId () {
      this.loadNewSpotlight()
    }
  },
  mounted () {
    this.loadNewSpotlight()
  },
  beforeDestroy () {
    this.addVCVCandidatesModalShowing = false
    this.addRoleCandidatesModalShowing = false
  },
  methods: {
    async getSpotlight () {
      this.spotlightLoading = true
      try {
        const res = await clientPortalService.getSpotlight(this.urlQuerySpotlightId)
        this.spotlight = res.data
        this.spotlightName = res.data.Name
        this.logo = res.data.Logo
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.spotlightLoading = false
    },
    async closeAddRoleCandidateModal () {
      this.addRoleCandidatesModalShowing = false
      await this.getSpotlightCandidates()
      await this.addSpotlightToRecentSpotlights()
    },
    async closeAddVCVCandidateModal () {
      this.addVCVCandidatesModalShowing = false
      await this.getSpotlightCandidates()
      await this.addSpotlightToRecentSpotlights()
    },
    async getSpotlightCandidates () {
      this.candidates = []
      try {
        const res = await clientPortalService.getSpotlightCandidates(this.urlQuerySpotlightId)
        this.candidates = res.data
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.spotlightLoading = false
    },
    openAddCandidateModal () {
      if (this.spotlight.CandidateSource === 'VideoCV') {
        this.addVCVCandidatesModalShowing = true
      } else {
        this.addRoleCandidatesModalShowing = true
      }
    },
    async addSpotlightToRecentSpotlights () {
      if (this.urlQuerySpotlightId !== 0) {
        try {
          await clientPortalService.addSpotlightToRecentSpotlights(this.urlQuerySpotlightId)
          this.$emit('getRecentSpotlights')
        } catch (err) {
          this.showGenericErrorModal({ showing: true })
        }
      }
    },
    async loadNewSpotlight () {
      await this.getSpotlight()
      await this.getSpotlightCandidates()
      this.$emit('getRecentSpotlights')
    }
  }
}
</script>

<style scoped>
  .notSelected {
    border-bottom: solid 2px #c0c0c0;
  }
</style>
