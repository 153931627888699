<template>
  <div class="d-flex flex-column">
    <SlideFade>
      <div v-if="line_1 !== '' || showAddress" class="mt-3 mb-1">
        <div class="d-flex align-items-center mb-2" style="width:100%;">
          <i class="fas fa-border-none prefix float-left" style="margin-top: -5px;font-size: 1.3rem;" />
          <mdb-input
            v-model="line_1"
            type="text"
            :disabled="remoteWorking"
            auto-complete="jbt"
            label="Address Line 1"
            size="sm"
            class="ml-3 my-0"
            style="width: 100%;"
          />
        </div>
      </div>
    </SlideFade>
    <SlideFade>
      <div v-if="line_2 !== '' || showAddress" class="mb-1">
        <div class="d-flex align-items-center mb-2" style="width:100%;">
          <i class="fas fa-border-none prefix float-left" style="margin-top: -5px;font-size: 1.3rem;" />
          <mdb-input
            v-model="line_2"
            type="text"
            :disabled="remoteWorking"
            auto-complete="jbt"
            label="Address Line 2"
            size="sm"
            class="ml-3 my-0"
            style="width: 100%;"
          />
        </div>
      </div>
    </SlideFade>
    <SlideFade>
      <div v-if="town_or_city !== '' || showAddress">
        <div class="d-flex align-items-center mb-2" style="width:100%;">
          <i class="fas fa-border-none prefix float-left" style="margin-top: -5px;font-size: 1.3rem;" />
          <mdb-input
            v-model="town_or_city"
            type="text"
            :disabled="remoteWorking"
            auto-complete="jbt"
            label="Town / City"
            size="sm"
            class="ml-3 my-0"
            style="width: 100%;"
          />
        </div>
      </div>
    </SlideFade>
    <SlideFade>
      <div v-if="county !== '' || showAddress">
        <div class="d-flex align-items-center mb-2" style="width:100%;">
          <i class="fas fa-border-none prefix float-left" style="margin-top: -5px;font-size: 1.3rem;" />
          <mdb-input
            v-model="county"
            type="text"
            :disabled="remoteWorking"
            auto-complete="jbt"
            label="County"
            size="sm"
            class="ml-3 my-0"
            style="width: 100%;"
          />
        </div>
      </div>
    </SlideFade>
    <SlideFade>
      <div v-if="country !== '' || showAddress">
        <div class="d-flex align-items-center mb-2" style="width:100%;">
          <i class="fas fa-border-none prefix float-left" style="margin-top: -5px;font-size: 1.3rem;" />
          <mdb-input
            v-model="country"
            type="text"
            :disabled="remoteWorking"
            auto-complete="jbt"
            label="Country"
            size="sm"
            class="ml-3 my-0"
            style="width: 100%;"
          />
        </div>
      </div>
    </SlideFade>
    <div>
      <div class="d-flex align-items-center mb-2" style="width:100%;">
        <i class="fas fa-border-none prefix float-left" style="margin-top: -5px;font-size: 1.3rem;" />
        <mdb-input
          v-model="postCode"
          type="text"
          :disabled="remoteWorking"
          auto-complete="jbt"
          label="Postcode"
          size="sm"
          class="ml-3 my-0"
          style="width: 100%;"
        />
      </div>
      <!--
        getaddress.io "retired"
        <mdb-btn
          v-if="!loading"
          class="float-left m-0"
          :disabled="remoteWorking || postCode === ''"
          size="sm"
          style="min-width:130px;"
          @click="searchAPI()"
        >
          {{ dropDownOptions.length > 0 ? 'SEARCH AGAIN' : 'SEARCH POSTCODE' }}
        </mdb-btn>
        <mdb-btn
          v-else
          class="float-left m-0"
          size="sm"
          style="width:130px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      -->
    </div>
    <SlideFade>
      <mdb-select
        v-if="dropDownOptions.length > 0"
        v-model="dropDownOptions"
        :disabled="remoteWorking"
        placeholder="Results"
        class="p-0 m-0 mb-4"
        style="height:30px;"
        size="sm"
      />
    </SlideFade>
  </div>
</template>

<script>
import roleService from '@/api/services/role.service'
import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'PostCodeSearch',
  components: {
    SlideFade
  },
  props: {
    jobDetails: Object,
    roleId: Number,
    remoteWorking: Boolean
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    return { authData }
  },
  data () {
    return {
      postCode: '',
      line_1: '',
      line_2: '',
      town_or_city: '',
      county: '',
      country: '',
      loading: false,
      addressOptions: [],
      dropDownOptions: [],
      showAddress: false
    }
  },
  computed: {
    selectedAddress () {
      const selected = this.dropDownOptions.filter(o => o.selected)
      if (selected.length > 0) {
        return selected[0].value
      } else { return '' }
    }
  },
  watch: {
    roleId () {
      this.checkJobDetails()
    },
    remoteWorking () {
      if (this.remoteWorking) {
        this.postCode = ''
        this.line_1 = ''
        this.line_2 = ''
        this.town_or_city = ''
        this.county = ''
        this.country = ''
        this.addressOptions = []
        this.dropDownOptions = []
      }
    },
    line_1 () {
      this.$emit('line_1', this.line_1)
    },
    line_2 () {
      this.$emit('line_2', this.line_2)
    },
    town_or_city () {
      this.$emit('town_or_city', this.town_or_city)
    },
    country () {
      this.$emit('country', this.country)
    },
    county () {
      this.$emit('county', this.county)
    },
    postCode () {
      this.$emit('postCode', this.postCode)
    },
    selectedAddress () {
      if (this.selectedAddress !== '') {
        this.waitToUpdate = true
        const details = this.selectedAddress.split(', ')
        this.line_1 = details[0]
        this.line_2 = details[1]
        this.town_or_city = details[2]
        this.county = details[3]
        this.country = details[4]
      }
      this.waitToUpdate = false
    },
    addressOptions () {
      const options = []
      this.addressOptions.forEach(o => {
        const address = `${o.line_1}, ${o.line_2}, ${o.town_or_city}, ${o.county}, ${o.country}, ${this.postCode}`
        options.push({ text: address, value: address })
      })
      this.dropDownOptions = options
    }
  },
  mounted () {
    if (this.jobDetails.PostCode !== '') { this.showAddress = true }
    this.checkJobDetails()
  },
  beforeUnmount () {
    this.$emit('line_1', '')
    this.$emit('line_2', '')
    this.$emit('town_or_city', '')
    this.$emit('country', '')
    this.$emit('county', '')
    this.$emit('postCode', '')
    this.addressOptions = []
    this.dropDownOptions = []
  },
  methods: {
    async searchAPI () {
      this.loading = true
      try {
        const tokenRes = await roleService.getAddressIOAT(this.authData.access_token)
        const res = await roleService.postCodeSearch(this.postCode, tokenRes.data)
        this.addressOptions = res.data.addresses
      } catch (err) {
        //?
      }
      this.loading = false
    },
    notUndefinedOrEmpty (value) {
      return value !== undefined && value !== ''
    },
    checkJobDetails () {
      if (this.notUndefinedOrEmpty(this.jobDetails.PostCode)) {
        this.postCode = this.jobDetails.PostCode
      } else {
        this.postCode = ''
      }
      if (this.notUndefinedOrEmpty(this.jobDetails.Line_1)) {
        this.line_1 = this.jobDetails.Line_1
      } else { this.line_1 = '' }
      if (this.notUndefinedOrEmpty(this.jobDetails.Line_2)) {
        this.line_2 = this.jobDetails.Line_2
      } else { this.line_2 = '' }
      if (this.notUndefinedOrEmpty(this.jobDetails.TownOrCity)) {
        this.town_or_city = this.jobDetails.TownOrCity
      } else { this.town_or_city = '' }
      if (this.notUndefinedOrEmpty(this.jobDetails.County)) {
        this.county = this.jobDetails.County
      } else { this.county = '' }
      if (this.notUndefinedOrEmpty(this.jobDetails.Country)) {
        this.country = this.jobDetails.Country
      } else { this.country = '' }
      this.addressOptions = []
      this.dropDownOptions = []
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
