<template>
  <div
    class="min-h-full pt-2 pt-md-4 px-3 px-md-5 w-1/1 text-black rounded"
    style="height:calc(100vh - 250px);overflow-y:auto;margin-bottom:65px;overflow-x:hidden"
    :class="{ 'mob': mobileView }"
  >
    <div ref="scrollToDiv" style="height: 1px;">
      <div v-show="logsLoaded === true" class="grey-text text-left">
        <div style="margin-top:10px;" class="text-left grey-text">
          <div
            v-if="logs.length === 0"
            class="text-center grey-text"
            style="font-size:12px;margin-top:50px;"
          >
            No logs available.
          </div>
          <b-table
            v-if="logs.length > 0"
            id="logs-table"
            class="text-left tableTh"
            head-variant="dark"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :items="logs"
            :per-page="logsPerPage"
            :current-page="logsCurrentPage"
            :fields="logFields"
            :filter="filter"
            hover
            small
            sort-icon-left
            responsive="sm"
          >
            <template #cell(section)="data">
              <div style="padding-top:15px;margin-left:5px;" class="smFont">
                {{ data.item.Section }}
              </div>
            </template>
            <template #cell(success)="data">
              <div
                v-show="data.item.Success === 'Success'"
                style="margin-top:14px;margin-left:5px;margin-right:5px;"
                class="smFont"
              >
                <i class="fa fa-check" />
              </div>
              <div
                v-show="data.item.Success === 'Failed'"
                style="margin-top:14px;margin-left:5px;margin-right:5px;"
                class="smFont"
              >
                <i class="fa fa-times" />
              </div>
            </template>
            <template #cell(dateadded)="data">
              <div style="margin-top:14px;margin-left:5px;" class="smFont">
                {{ new Date(data.item.DateAdded).toLocaleDateString() }}
              </div>
            </template>
            <template #cell(email)="data">
              <div style="margin-top:14px;margin-left:5px;" class="smFont">
                {{ data.item.Email }}
              </div>
            </template>
            <template #cell(actions)="data">
              <div class="float-right" style="cursor:pointer;">
                <mdb-popover
                  trigger="click"
                  :options="{placement: 'bottom'}"
                  style="cursor:pointer;"
                  class="grey-text"
                >
                  <div slot="body" style="height:fit-content !important;">
                    <h5 style="min-height:70px;height:fit-content;padding:10px;" class="grey-link d-flex flex-column">
                      <strong>Description:</strong>{{ data.item.Description }}
                    </h5>
                  </div>
                  <mdb-icon
                    slot="reference"
                    title="More actions"
                    style="margin: 1rem;color:#555;font-size:1rem;"
                    icon="ellipsis-v"
                    class="ellipsisButtonHover"
                  />
                </mdb-popover>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-show="logs.length > 5"
            v-model="logsCurrentPage"
            class="float-right mt-4"
            :total-rows="logsRows"
            :per-page="logsPerPage"
            first-number
            last-number
            aria-controls="notifications-table"
            prev-text="<"
            next-text=">"
            style="margin-bottom:30px;"
          />
        </div>
      </div>
      <!--generic alert modal-->
      <mdb-modal
        id="alertModal"
        :show="alertModal"
        @close="alertModal = false"
      >
        <mdb-modal-header>
          <mdb-modal-title>{{ alertModalTitle.toUpperCase() }}</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body :class="{ 'modalHeight': mobileView }">
          <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
            <span v-html="alertModalContent" />
          </p>
        </mdb-modal-body>
        <mdb-modal-footer>
          <mdb-btn
            color="primary"
            size="sm"
            style="width:100px;"
            @click.native="alertModal = false"
          >
            OK
          </mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>
    </div>
  </div>
</template>

<script>
import { extend } from 'vee-validate'
import logoutService from '@/api/services/logout.service'
import adminService from '@/api/services/admin.service'
import { logout } from '@/methods/auth.js'
import { userLanguage } from '@/common/userLanguage'

import { mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModal, mdbBtn } from 'mdbvue'

extend('notification-type', {
  validate: types => { return types.length > 0 },
  lazy: true,
  message: 'Select notification type'
})

extend('notification-target-role', {
  validate: targetRoles => { return targetRoles.length > 0 },
  lazy: true,
  message: 'Select target role'
})

export default {
  name: 'Admin',
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbBtn
  },

  data () {
    return {
      logFields: [
        { key: 'Success', label: ' ?  ', tdClass: 'headerPadding col5 sortIcon', sortable: true },
        { key: 'Section', label: '  Section', tdClass: 'headerPadding col35 sortIcon', sortable: true },
        { key: 'DateAdded', label: '  Date', tdClass: 'headerPadding col25 sortIcon', sortable: true },
        { key: 'Email', label: '  User', tdClass: 'headerPadding col25 sortIcon', sortable: true },
        { key: 'Actions', label: '', tdClass: 'headerPadding col10', sortable: false }
      ],
      showLogging: false,
      sortBy: '',
      sortDesc: false,
      logsPerPage: 8,
      logsCurrentPage: 1,
      filter: null,
      buttonClick: false,
      userLanguage,
      filterOn: [],
      logs: [],
      logsLoaded: false,
      alertModal: false,
      alertModalTitle: '',
      alertModalContent: ''
    }
  },
  computed: {
    logsRows () {
      return this.logs.length
    },
    sortOptions () {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
    dateFormat: {
      get () {
        return this.$material.locale.dateFormat
      },
      set (val) {
        this.$material.locale.dateFormat = val
      }
    }
  },
  created () {
    this.$parent.logsLoading = true
    this.getLogs()
  },
  methods: {
    logout,
    getLogs () {
      adminService.getLogs().then((response) => {
        this.logs = response.data
        setTimeout(() => {
          this.$parent.logsLoading = false
          this.logsLoaded = true
        }, 3000)
      }).catch((error) => {
        setTimeout(() => {
          this.$parent.logsLoading = false
          this.logsLoaded = true
        }, 3000)
        this.alertModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
    },
    errorAlert (error) {
      try {
        if (error.response.data != null) {
          if (error.response.data.Message != null) {
            this.errorModal = true
            this.error = error.response.data.Message
            if (this.error === '') { this.error = 'Sorry, an error has occured!' }
          } else if (error.response.data.error_description != null) {
            this.errorModal = true
            this.error = error.response.data.error_description
            if (this.error === '') { this.error = 'Sorry, an error has occured!' }
          } else {
            this.errorModal = true
            this.error = error.response.data
            if (this.error === '') { this.error = 'Sorry, an error has occured!' }
          }
        } else {
          this.errorModal = true
          this.error = 'Sorry an error has occurred!'
        }
        if (error.response.data.Message === 'Authorization has been denied for this request.') {
          this.doLogOut()
        }
      } catch {
        this.errorModal = true
        this.error = 'Sorry an error has occurred!'
      }
    },
    doLogOut () {
      logoutService.logout().then(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      }).catch(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .mob {
    width: calc(100% + 30px) !important;
    margin-left: -15px !important;
    margin-top: 15px;
  }
  .mobileTopSections {
    margin-top: 0px !important;
  }
  @media (max-width: 639px) {
    .smFont {
      font-size: 11px !important;
    }
  }
</style>
