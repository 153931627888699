<template>
  <div class="px-4">
    <VueSlider
      ref="slider"
      v-model="scoreValue"
      label="Example range"
      :min="minimum"
      :max="maximum"
      :height="8"
      :lazy="true"
      :interval="stepIncrement"
      :dot-options="{ tooltip: 'hover' }"
      marks
      :data="makeTextBased ? textArray : undefined"
      @change="e => change(e)"
    />
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import 'vue-slider-component/theme/antd.css'

export default {
  name: 'ScoringSlider',
  components: {
    VueSlider
  },
  props: {
    score: {
      type: Number,
      default: -1
    },
    lowestScaleValue: {
      type: Number,
      default: 0
    },
    stepIncrement: {
      type: Number,
      default: 1
    },
    numberOfSteps: {
      type: Number,
      default: 10
    },
    makeTextBased: {
      type: Boolean,
      default: false
    },
    numericTextScores: {
      type: Boolean,
      default: false
    },
    textArray: {
      type: Array,
      default: () => []
    },
    goScoringScaleId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      scoreValue: this.makeTextBased ? this.textArray[0] : this.minimum
    }
  },
  computed: {
    maximum () {
      return this.lowestScaleValue + (this.stepIncrement * (this.numberOfSteps - 1))
    },
    minimum () {
      if (this.makeTextBased) {
        return 1
      } else {
        return this.lowestScaleValue
      }
    }
  },
  watch: {
    minimum () {
      this.scoreValue = this.minimum
    },
    score: {
      handler: function () {
        this.updateScoreValue(this.score)
      },
      immediate: true,
    }
  },
  methods: {
    async updateScoreValue (score) {
      if (score == this.scoreValue || score == -1) return
      if (score !== -1) {
        if (this.makeTextBased) {
          // this reverses the score so we can find at what index the text score is
          let scoreIndex = 0
          if (this.numericTextScores) {
            scoreIndex = this.textArray.findIndex(text => text == `${score}`)
          } else {
            scoreIndex = (score - this.lowestScaleValue) / this.stepIncrement
          }
          this.scoreValue = this.textArray[scoreIndex]
          try {
            this.$refs.slider.setValue(this.textArray[scoreIndex])
          } catch {}
        } else {
          this.scoreValue = score
          try {
            this.$refs.slider.setValue(score)
          } catch {}
        }
      }
    },
    change (scoreValue) {
      this.$emit('sliderScoreChange', scoreValue)
    }
  }
}
</script>
