const GET_HISTORY_INFO = historyItem => {
  let iconCode = ''
  let linkText = ''
  let extraDetailText = ''
  let addedByName = historyItem.AddedBy?.RoleWorkflowStageItemName
  let updatedByName = historyItem.UpdatedBy?.Name || ''
  let historyTypeText = historyItem.HistoryTypeDesc
  let jobTitle = historyItem.Job?.JobTitle

  const handleStageCompleted = () => {
    extraDetailText = historyItem.HistoryItemUpdateTypeDesc
    if (!extraDetailText) {
      if (historyItem.RoleWorkflowStageItem?.RoleWorkflowStageItemName) {
        extraDetailText = `Status: ${historyItem.RoleWorkflowStageItem?.RoleWorkflowStageItemName?.toUpperCase()}`
      }
    } else {
      if (updatedByName) {
        extraDetailText += ` by ${updatedByName} at ${new Date(historyItem.DateAdded).toLocaleTimeString().substring(0, 5)}`
      } else { extraDetailText += ` at ${new Date(historyItem.DateAdded).toLocaleTimeString().substring(0, 5)}` }
    }
    iconCode = 'fa-solid fa-circle-check'
    linkText = 'VIEW'
    if (historyItem.HistoryItemUpdateTypeDesc && historyItem.HistoryItemUpdateTypeDesc != 'STAGE REDONE') linkText = ''
  }

  switch (historyItem.HistoryType) {
    case 1: // VIDEO CV COMPLETED
      iconCode = 'fa-solid fa-play'
      break;
    case 2: // JOB APPLICATION
      iconCode = 'fa-solid fa-envelope'
      historyTypeText = `Application For Role`
      extraDetailText = `${jobTitle} ${historyItem.ExtraHistoryDetail == 'Self-Registration' ? '(Self-Registration)' : historyItem.ExtraHistoryDetail == 'Reapplication' ? '(REAPPLICATION)' : ''}`
      break;
    case 4: // TAGGED IN MEET NOW
      iconCode = 'fa-solid fa-video'
      linkText = 'VIEW'
      break;
    case 5: // INVITED TO A STAGE
      iconCode = 'fa-solid fa-envelope'
      historyTypeText = `Application For Role`
      extraDetailText = historyItem.ExtraHistoryDetail == 'Redo' ? 'Redo' : null
      break;
    case 6: // ONE WAY COMPLETED
      handleStageCompleted()
      break;
      case 7: // TWO WAY COMPLETED
      handleStageCompleted()
      break;
    case 9: // ONE WAY FULL RESET
      iconCode = 'fa-solid fa-circle-xmark'
      break;
    case 10: // TWO WAY FULL RESET
      iconCode = 'fa-solid fa-circle-xmark'
      break;
    case 11: // SCHEDULER BOOKED
      iconCode = 'fa-regular fa-calendar'
      extraDetailText = !addedByName ? 'Booked by Candidate' : `Booked by ${addedByName}`
      break;
    case 12: // SCHEDULER CANCELLED
      iconCode = 'fa-solid fa-circle-xmark'
      extraDetailText = !addedByName ? 'Cancelled by Candidate' : `Cancelled by ${addedByName}`
      break;
      case 13: // STAGE INVITATION RESENT
      iconCode = 'fa-solid fa-envelopes'
      break;
      case 14: // ONE WAY QUESTION RESET
      iconCode = 'fa-solid fa-rotate-right'
      extraDetailText = `"${historyItem.ExtraHistoryDetail}"`
      break;
      case 16: // SCORE SAVED
      iconCode = 'fa-solid fa-square-pen'
      break;
      case 17: // MODERATOR SCORE SAVED
      iconCode = 'fa-solid fa-square-pen'
      break;
      case 18: // STATUS UPDATED
      iconCode = 'fa-regular fa-circle-star'
      extraDetailText = `Updated to "${historyItem.RoleWorkflowStageItem.RoleWorkflowStageItemName}"`
      break;
      case 19: // HOST ENTERED ROOM
      iconCode = 'fa-solid fa-arrow-right-to-arc'
      break;
      case 20: // CANDIDATE ENTERED ROOM
      iconCode = 'fa-solid fa-arrow-right-to-arc'
      break;
      case 21: // ASSESSOR ENTERED ROOM
      iconCode = 'fa-solid fa-arrow-right-to-arc'
      break;
      case 22: // TWO WAY RECORDING STARTED
      iconCode = 'fa-solid fa-circle'
      break;
      case 23: // TWO WAY RECORDING ENDED
      iconCode = 'fa-regular fa-square'
      break;
      case 24: // ROLE SHARE CREATED
      iconCode = 'fa-solid fa-share-from-square'
      break;
      case 25: // SCHEDULER BOOKING RESCHEDULED
      iconCode = 'fa-regular fa-calendar'
      extraDetailText = !addedByName ? 'Rescheduled by Candidate' : `Rescheduled by ${addedByName}`
      break;
    case 26: // INVITE DEADLINE EXTENDED
      iconCode = 'fa-solid fa-arrow-right-to-line'
      break;
    default:
        break;
  }
  return { iconCode, extraDetailText, addedByName, updatedByName, linkText, historyTypeText }
}

export default GET_HISTORY_INFO
