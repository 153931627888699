<template>
  <div class="shadow1 roleCardBorder roleCardHeight mobSpacing">
    <div class="roleCard2Container">
      <TopPanel
        :style="{'background': !role.CloseDateIsPast ? userData.lbl.PrimaryColour : '#c0c0c0'}"
        :role="role"
        class="first-row"
        @setFollowing="role.Following = $event"
      />
      <div class="second-row">
        <div class="second-row-first-column">
          <DateAndInvitedPanel
            :total-invite-count="totalInviteCount"
            :getting-count="gettingCount"
            :role="role"
          />
          <Searchbar
            :loading-search="loadingRoleCardApplicantSearch"
            :role="role"
            @search="search = $event"
          />
        </div>
        <div class="second-row-second-column">
          <InviteOptionsPanel :role="role" />
        </div>
      </div>
    </div>
    <div class="rolecard-lower-sections">
      <div
        v-if="!role.IsComplete"
        class="d-flex align-items-center justify-content-center p-2 cp"
        @click="goToRole()"
      >
        <FontAwesomeIcon icon="fa-regular fa-gear" />
        <span class="ml-2 font-weight-bold">COMPLETE SETUP</span>
      </div>
      <Stages
        v-else-if="search.length < 3"
        :role="role"
        @totalCount="handleTotalCount"
        @selectedStage="selectStage"
      />
      <SearchbarResults
        v-else
        :key="role.JobId"
        :role="role"
        @researchApplicants="doSearch"
      />
    </div>
  </div>
</template>

<script>
import { useUserData, useRoles, useRoleCandidate, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'
import DateAndInvitedPanel from './dateAndInvitedPanel.vue'
import InviteOptionsPanel from './inviteOptionsPanel.vue'
import Stages from './stages/stagesContainer.vue'
import SearchbarResults from './searchbar/searchbarResults.vue'
import roleV2Service from '@/api/services/roleV2.service'
import { onMounted, ref } from 'vue'

import Searchbar from './searchbar/searchbar.vue'
import TopPanel from './topPanel/topPanel.vue'

export default {
  name: 'RoleCard2',
  components: {
    DateAndInvitedPanel,
    InviteOptionsPanel,
    Stages,
    SearchbarResults,
    Searchbar,
    TopPanel
  },
  props: {
    roleFromProps: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    const role = ref({})
    onMounted(() => {
      role.value = props.roleFromProps
    })

    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)

    const roleCandidateStore = useRoleCandidate()
    const { meetingDateModal } = storeToRefs(roleCandidateStore)

    const rolesStore = useRoles()
    const { searchApplicantWithinRole, loadingRoleCardApplicantSearch } = storeToRefs(rolesStore)

    const workflowStore = useWorkflow()
    const {
      role: piniaRole
    } = storeToRefs(workflowStore)

    const search = ref('')
    const selectedStage = ref({})

    const totalInviteCount = ref(0)
    const gettingCount = ref(true)

    const key = ref(0)

    return {
      search,
      userData,
      selectedStage,
      searchApplicantWithinRole,
      loadingRoleCardApplicantSearch,
      meetingDateModal,
      piniaRole,
      totalInviteCount,
      gettingCount,
      role,
      key
    }
  },
  watch: {
    search () {
      this.doSearch()
    }
  },
  methods: {
    async selectStage (stage) {
      // get the selected stages stageItem counts
      if (!stage.CountsFetched) {
        try {
          const arrayOfStageItemGuids = stage.OrderedRoleWorkflowStageItems.map(stageItem => stageItem.RoleWorkflowStageItemId)
          const res = await roleV2Service.getStageItemApplicantCounts({ Guids: arrayOfStageItemGuids })
          let stageToAddCounts = this.role?.RoleWorkflow?.OrderedRoleWorkflowStages.filter(s => s.RoleWorkflowStageId == stage.RoleWorkflowStageId)[0]
          stageToAddCounts.CountsFetched = true
          stageToAddCounts.OrderedRoleWorkflowStageItems = stageToAddCounts.OrderedRoleWorkflowStageItems.map(stageItem => { return {...stageItem, Count: res.data[stageItem.RoleWorkflowStageItemId]}})
          const indexOfStage = this.role?.RoleWorkflow?.OrderedRoleWorkflowStages.findIndex(s => s.RoleWorkflowStageId == stage.RoleWorkflowStageId)
          this.role.RoleWorkflow.OrderedRoleWorkflowStages[indexOfStage] = stageToAddCounts
          stage = stageToAddCounts
        } catch {}
      }
      this.selectedStage = stage
    },
    handleTotalCount (count) {
      this.totalInviteCount = count
      this.gettingCount = false
    },
    goToRole () {
      this.$router.push(`/roles/add_edit/detail/${this.role.JobId}`)
    },
    prepOpenInviteCandidateModal (data) {
      this.addInviteCandidateModalData = data
      this.addingInvitingCandidateModalShowing = true
    },
    initialiseSharingModal (args) {
      this.sharingModalInitialData = args
      this.sharingModalShowing = true
    },
    refreshRoles () {
      this.getRoleLocal()
    },
    async doSearch () {
      this.key++
      this.loadingRoleCardApplicantSearch = 0
      if (this.search.length >= 3) {
        // needed so that any Primary Action button methods from the stores will have this specific rolecards role as set in the store
        this.piniaRole = this.role
        await this.$nextTick(() => {})
        await this.searchApplicantWithinRole(this.role.JobId, this.search)
      }
    }
  }
}
</script>

<style scoped>
.roleCardHeight {
  height: fit-content;
}
.roleCardBorder {
  border: 1px solid #c1c1c1;
  border-radius:5px;
}
.roleCard2Container {
  width: 100%;
  display: grid;
  grid-template-rows: auto 80px auto;
  text-align:left;
  overflow-x:hidden;
}

.roleCard2Container > div {
  background-color: #ccc;
}

.first-row {
  grid-row: 1;
  grid-column: 1 / span 2;
}

.second-row {
  grid-row: 2;
  grid-column: 1 / span 2;
  display: grid;
  grid-template-columns: auto 80px;
  grid-template-rows: 1fr;
  /* border: 1px solid #c1c1c1; */
  border-bottom: none;
}
.second-row-first-column {
  grid-template-rows: 1fr 1fr;
}
.second-row-first-column > div {
  border: 1px solid #c1c1c1;
  border-bottom: none;
  height: 50%;
}
.second-row-second-column {
  border: 1px solid #c1c1c1;
  border-bottom: none;
}

.second-row > div {
  background-color: #fff; /* Adjust the background color of the inner rows as needed */
}

.rolecard-lower-sections {
  border-top: 2px solid #c1c1c1;
}

@media (max-width: 576px) {
  .mobSpacing{
    margin-bottom:15px !important;
  }
}
</style>
