<template>
  <div
    class="col-sm templateDiv"
    :class="mobileView ? 'pt-3' : 'pt-0'"
    style="height:calc(100vh - 80px);overflow-y:auto"
  >
    <div
      v-if="managePermissions"
      class="d-flex align-items-center px-4 mt-6 mainSectionBoilerPlateInnerTopPanel mb-2 topTabs"
    >
      <div
        class="cursor-pointer text-lg grey-text text-center"
        :style="{'width': mobileView ? '41%' : '48%'}"
        :class="{ 'default': section == 'users', 'notSelected': section != 'users', 'mobileSize': mobileView === true }"
        @click="setSection('users')"
      >
        <span v-if="mobileView === false">USERS</span>
        <span v-else><i
          class="fa fa-users"
          title="Users"
          style="font-size:large"
        /></span>
        <i
          v-if="usersLoading"
          style="margin-left: 5px !important;"
          class="fa fa-spin fa-spinner ml-2"
        />
      </div>
      <div
        class="cursor-pointer text-lg grey-text text-center"
        :style="{'width': mobileView ? '41%' : '48%'}"
        :class="{ 'default': section == 'groups', 'notSelected': section != 'groups', 'mobileSize': mobileView === true }"
        @click="setSection('groups')"
      >
        <span v-if="mobileView === false">GROUPS</span>
        <span v-else><i
          class="fa fa-list"
          title="Groups"
          style="font-size:large"
        /></span>
        <i
          v-if="groupsLoading"
          style="margin-left: 5px !important;"
          class="fa fa-spin fa-spinner ml-2"
        />
      </div>
    </div>

    <div
      v-if="managePermissions"
      class="pt-2 pt-md-4 px-3 px-md-5 "
    >
      <Users
        v-if="section === 'users'"
      />
      <Groups
        v-if="section === 'groups'"
      />
    </div>

    <div v-if="!managePermissions">
      <div
        class="font-weight-bold text-left my-3 d-flex align-items-center justify-content-between"
        style="height:50px"
      >
        <div class="d-flex align-items-center">
          <span v-if="accountsLoaded === false">
            Loading <i class="fa fa-spin fa-spinner" style="margin-top: 0px;" />
          </span>
          <span v-else>
            My Accounts
          </span>
        </div>
      </div>

      <div
        v-show="accountsLoaded"
        :class="{ 'vMobBrandingTop': mobileView }"
        style="width:100%;margin-top:-50px;"
      >
        <div
          class="grey-text text-left"
        >
          <ExpandableSearchBar
            v-if="accounts.length > 0"
            :width="250"
            :max-widthpercentage="50"
            @search="filter = $event"
          />
        </div>

        <div
          class="align-items-left"
          style="margin-bottom:30px;float:left;width:100%;"
          :style="{'margin-top': accounts.length > 0 ? '5px' : '11px'}"
        >
          <div
            :class="{'primary-colour': filterValue === -1 }"
            style="margin-top:5px;cursor: pointer;font-weight:bold;font-size:12px;color:#555;float:left;border:solid 1px #e1e1e1; border-radius:5px; padding:5px;min-width:55px;margin-right:5px;"
            @click="filterAccountsByGroup(-1)"
          >
            <i v-if="filterValue === -1" class="fa fa-check-circle" /> All
          </div>
          <div
            v-for="group in accountGroups"
            :key="group.AccountGroupId"
            :class="{'primary-colour': filterValue === group.AccountGroupId }"
            style="margin-top:5px;cursor: pointer;font-weight:bold;font-size:12px;color:#555;float:left;border:solid 1px #e1e1e1; border-radius:5px; padding:5px;min-width:55px;margin-right:5px;"
            @click="filterAccountsByGroup(group.AccountGroupId)"
          >
            <i v-if="filterValue === group.AccountGroupId" class="fa fa-check-circle" /> {{ group.GroupName }}
          </div>
        </div>
        <div
          v-if="accounts.length === 0"
          class="text-left grey-text"
          :class="{ 'm-a-left': mobileView }"
          style="width:100%;font-size: 14px;margin-top: 50px;"
        >
          You have no accounts assigned to you to switch!
        </div>

        <div v-show="accountsLoaded === true">
          <!--desktop-->
          <b-table
            v-if="accounts.length > 0 && mobileView === false"
            id="accounts-table"
            class="text-left"
            head-variant="dark"
            style="margin-top:10px;"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :items="accounts"
            :per-page="accountsPerPage"
            :current-page="accountsCurrentPage"
            :fields="accountsFields"
            :filter="filter"
            :striped="true"
            hover
            small
            sort-icon-left
            responsive="sm"
          >
            <template #cell(name)="data">
              <div style="padding-top:12px;margin-left:10px;" class="smFont">
                <span v-if="data.item.AccountId === userData.aid"><i class="fa fa-check" /></span> {{ data.item.Name }}
              </div>
            </template>
            <template #cell(lastaccessed)="data">
              <div
                v-if="data.item.LastAccessed !== null"
                style="padding-top:8px;margin-left:5px;"
                class="smFont"
              >
                {{ new Date(data.item.LastAccessed).toLocaleDateString([userLanguage]) }}
              </div>
              <div v-else>
                -
              </div>
            </template>
            <template #cell(actions)="data">
              <div v-if="data.item.AccountId !== userData.aid" class="float-right webDisplay">
                <button
                  title="Assign accounts to this group?"
                  type="button"
                  class="btn btn-primary btn-md"
                  style="padding: 0.5rem 0.6rem;margin:0.175rem;"
                  @click="showSwitchAccountModal(data.item)"
                >
                  <i class="fas fa-sync" /> SWITCH NOW
                </button>
              </div>
              <div v-if="data.item.AccountId === userData.aid" class="float-right webDisplay">
                <button
                  title="Assign accounts to this group?"
                  type="button"
                  class="btn btn-primary btn-md"
                  style="padding: 0.5rem 0.6rem;margin:0.175rem;opacity:0.5;"
                  disabled
                >
                  <i class="fas fa-sync" /> SWITCH NOW
                </button>
              </div>
            </template>
          </b-table>

          <!--mobile-->
          <b-table
            v-if="accounts.length > 0 && mobileView === true"
            id="accounts-table"
            style="margin-top:20px;"
            class="text-left tableTh"
            head-variant="dark"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :items="accounts"
            :per-page="accountsPerPage"
            :current-page="accountsCurrentPage"
            :fields="accountsMobileFields"
            :filter="filter"
            hover
            small
            sort-icon-left
            responsive="sm"
          >
            <template #cell(name)="data">
              <div style="padding-top:12px;margin-left:10px;" class="smFont">
                {{ data.item.Name }}
              </div>
            </template>
            <template #cell(lastaccessed)="data">
              <div
                v-if="data.item.LastAccessed !== null"
                style="padding-top:8px;margin-left:5px;"
              >
                {{ new Date(data.item.LastAccessed).toLocaleDateString([userLanguage]) }}
              </div>
              <div v-else>
                -
              </div>
            </template>
            <template #cell(actions)="data">
              <mdb-popover
                trigger="click"
                :options="{placement: 'bottom'}"
                style="cursor:pointer;float:right;"
                class="grey-text mobDisplay"
              >
                <div slot="body">
                  <h6 style="margin-left:-10px;margin-top:-5px;padding:10px;" @click="showSwitchAccountModal(data.item)">
                    <a class="ml-1 grey-link"><i class="fa fa-plus" /><span style="margin-left:5px;">Switch</span></a>
                  </h6>
                </div>
                <mdb-icon
                  slot="reference"
                  title="More actions"
                  style="color:#555;font-size:1rem;transform:translateY(2px);margin-top:3px;margin-left:8px;"
                  class="p-2 ellipsisButtonHover mr-2"
                  icon="ellipsis-v"
                />
              </mdb-popover>
            </template>
          </b-table>

          <b-pagination
            v-show="accounts.length > 5"
            v-model="accountsCurrentPage"
            class="float-right mt-4"
            :total-rows="accountsRows"
            :per-page="accountsPerPage"
            first-number
            last-number
            aria-controls="accounts-table"
            prev-text="<"
            next-text=">"
            style="margin-bottom:30px;"
          />
        </div>

        <!--error modal-->
        <mdb-modal
          id="errorModal"
          :show="errorModal"
          @close="errorModal = false"
        >
          <mdb-modal-header>
            <mdb-modal-title>{{ errorModalTitle.toUpperCase() }}</mdb-modal-title>
          </mdb-modal-header>
          <mdb-modal-body :class="{ 'modalHeight': mobileView }">
            <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
              <span v-html="error" />
            </p>
          </mdb-modal-body>
          <mdb-modal-footer>
            <mdb-btn
              color="primary"
              size="sm"
              style="width:100px;"
              @click.native="closeErrorModal"
            >
              CLOSE
            </mdb-btn>
          </mdb-modal-footer>
        </mdb-modal>

        <!--switch modal-->
        <mdb-modal
          id="switchModal"
          :show="switchModal"
          @close="switchModal = false"
        >
          <mdb-modal-header>
            <mdb-modal-title>SWITCH?</mdb-modal-title>
          </mdb-modal-header>
          <mdb-modal-body :class="{ 'modalHeight': mobileView }">
            <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
              Are you sure you want to switch to this account?
            </p>
          </mdb-modal-body>
          <mdb-modal-footer>
            <mdb-btn
              v-show="!buttonClick"
              color="primary"
              size="sm"
              style="width:100px;"
              @click.native="switchAccount"
            >
              SWITCH
            </mdb-btn>
            <mdb-btn
              v-show="buttonClick"
              class="float-right"
              size="sm"
              style="width:100px;"
            >
              <mdb-icon icon="fa fa-pulse fa-spinner" />
            </mdb-btn>
          </mdb-modal-footer>
        </mdb-modal>
      </div>
    </div>

    <!--left column bottom menu-->
    <div
      v-if="userData.iss || userData.rle === 'Account Group Administrator'"
    >
      <div
        v-if="mobileView === false && (leftColumnActive === false || leftColumnActive === undefined)"
        class="fixed bottom-0 botVidBut moveCreateRoom"
        style="background:#d1d1d1;padding:12px;border-top: 1px solid #a1a1a1;margin-bottom: -5px;"
      >
        <div class="row" style="margin-left:25px;">
          <div v-if="managePermissions" class="col-sm-12 text-center">
            <mdb-btn
              size="sm"
              class="btn btn-solid-outline-web"
              style="padding: 0.5rem 1.1rem;"
              @click="manageMyAccounts()"
            >
              MANAGE PERMISSONS
            </mdb-btn>
          </div>
          <div v-else class="col-sm-12 text-center">
            <mdb-btn
              size="sm"
              class="btn btn-solid-outline-web"
              style="padding: 0.5rem 1.1rem;"
              @click="manageMyPermissions()"
            >
              MANAGE PERMISSONS
            </mdb-btn>
          </div>
        </div>
      </div>

      <div class="fixed bottom-0 mobDisplay" style="background: white;width: 100%;border-top: 1px solid rgb(161, 161, 161);z-index: 1001;margin-left: -33px;height: 55px;">
        <div class="row" style="margin-top:5px;">
          <div class="col-sm-12 text-center">
            <button
              v-if="managePermissions"
              style="width: 170px;padding: 6px !important;text-align: center;"
              title="Add account"
              class="mdc-button btn-solid-outline-mob btn"
              @click="manageMyAccounts()"
            >
              <span class="secondary-colour">MANAGE PERMISSONS</span>
            </button>
            <button
              v-else
              style="width: 170px;padding: 6px !important;text-align: center;"
              title="Add account"
              class="mdc-button btn-solid-outline-mob btn"
              @click="manageMyPermissions()"
            >
              <span class="secondary-colour">MANAGE PERMISSONS</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import logoutService from '@/api/services/logout.service'
import userService from '@/api/services/user.service'
import adminService from '@/api/services/admin.service'
import { userLanguage } from '@/common/userLanguage'
import { useMobile } from '@/composables/useMobile'
import Users from './Tabs/users.vue'
import Groups from './Tabs/groups.vue'

import { mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModal, mdbBtn } from 'mdbvue'
import ExpandableSearchBar from '@/components/UI/expandableSearch.vue'

export default {
  name: 'MyAccounts',
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbBtn,
    ExpandableSearchBar,
    Users,
    Groups
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData, leftColumnActive, refreshState } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    return {
      authData,
      userData,
      leftColumnActive,
      mobileView,
      refreshState
    }
  },
  data () {
    return {
      accountsFields: [
        { key: 'Name', label: '  Account Name', tdClass: 'headerPaddingUsers col40 sortIcon', sortable: true },
        { key: 'LastAccessed', label: '  Last Accessed', tdClass: 'headerPaddingUsers col30 sortIcon', sortable: true },
        { key: 'Actions', label: '', tdClass: 'headerPaddingUsers col30', sortable: false }
      ],
      accountsMobileFields: [
        { key: 'Name', label: '  Name', tdClass: 'headerPaddingUsers col70 sortIcon', sortable: true },
        { key: 'LastAccessed', label: '  Accessed', tdClass: 'headerPaddingUsers col20 sortIcon', sortable: true },
        { key: 'Actions', label: '', tdClass: 'headerPaddingUsers col10', sortable: false }
      ],
      accountsLoaded: false,
      accountsPerPage: 8,
      accountsCurrentPage: 1,
      accounts: [],
      returnedAccounts: [],
      accountGroups: [],
      switchModal: false,
      sortBy: '',
      sortDesc: false,
      filter: null,
      buttonClick: false,
      userLanguage,
      filterOn: [],
      errorModal: false,
      errorModalTitle: '',
      error: '',
      filterValue: -1,
      accountId: null,
      managePermissions: false,
      section: 'users',
      myAccountsLoaded: true,
      accountsLoading: false,
      usersLoading: false,
      groupsLoading: false,
      currentSection: 'users'
    }
  },
  computed: {
    accountsRows () {
      return this.accounts.length
    },
    sortOptions () {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
  },
  updated () {
    if (this.refreshState) {
      if (!this.managePermissions) {
        this.getMyAccounts()
      } else {
        if (this.section === 'users') {
          this.usersLoading = true
          try {
            this.$children[1].users = []
            this.$children[1].getUsers()
          }
          catch(e) { }
          this.refreshState = false
        } else if (this.section === 'groups') {
          this.groupsLoading = true
          this.$children[1].$parent.$children[1].groups = []
          this.$children[0].$parent.$children[1].getAccountGroups()
          this.refreshState = false
        }
      }
    }
  },
  async created () {
    this.$parent.accountsLoading = true
    this.getMyAccounts()
  },
  methods: {
    manageMyPermissions () {
      this.managePermissions = true
    },
    getUsers () {
      userService.getUsersAndAccountGroups().then((response) => {
        this.addEditUserForm = false
        this.users = response.data
        setTimeout(() => { this.$parent.usersLoading = false, this.usersLoaded = true }, 3000)
        this.buttonClick = false
      }).catch((error) => {
        this.addEditUserForm = false
        setTimeout(() => { this.$parent.usersLoading = false, this.usersLoaded = true }, 3000)
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
        this.buttonClick = false
      })
    },
    manageMyAccounts () {
      this.$parent.accountsLoading = true
      this.getMyAccounts()
      this.managePermissions = false
    },
    setSection (type) {
      this.section = type
      if (this.section === 'users' && this.currentSection !== 'users') {
        this.accountsLoading = false
        this.usersLoading = true
        this.groupsLoading = false
        this.currentSection = 'users'
      }
      if (this.section === 'groups' && this.currentSection !== 'groups') {
        this.accountsLoading = false
        this.usersLoading = false
        this.groupsLoading = true
        this.currentSection = 'groups'
      }
    },
    filterAccountsByGroup (filterValue) {
      this.filterValue = filterValue
      if (filterValue === -1) {
        this.accounts = this.returnedAccounts.filter(a => a.AccountId !== 0)
      } else {
        this.accounts = this.returnedAccounts.filter(a => a.AccountGroupId === filterValue)
      }
    },
    getMyAccounts () {
      userService.getMyAccounts().then((response) => {
        this.returnedAccounts = response.data.AccountNames
        this.accounts = response.data.AccountNames
        this.accountGroups = response.data.AccountGroupNames
        setTimeout(() => {
          this.$parent.accountsLoading = false
          this.accountsLoaded = true
        }, 3000)
      }).catch((error) => {
        setTimeout(() => {
          this.$parent.accountsLoading = false
          this.accountsLoaded = true
        }, 3000)
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
    },
    switchAccount () {
      this.buttonClick = true
      adminService.switchAccount(this.accountId).then((response) => {
        if (response.data !== null) {
          this.userData = response.data

          //not ideal but dirty quick fix - TO DO
          try {
            this.$parent.$parent.$children[0].$children[5].getMyRecentAccounts()
          } catch {
            this.$parent.$children[1].getMyRecentAccounts()
          }
          //not ideal but dirty quick fix - TO DO

          this.getMyAccounts()
        }
        this.switchModal = false
        this.accountId = null
        this.buttonClick = false
      }).catch((error) => {
        this.switchModal = false
        this.accountId = null
        this.buttonClick = false
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred switching or refreshing accounts!')
      })
    },
    showSwitchAccountModal (data) {
      this.accountId = data.AccountId
      this.switchModal = true
    },
    errorAlert (error, err) {
      try {
        if (error.response.data != null) {
          if (error.response.data.Message != null) {
            this.errorModal = true
            this.error = error.response.data.Message
            if (this.error === '') { this.error = err }
          } else if (error.response.data.error_description != null) {
            this.errorModal = true
            this.error = error.response.data.error_description
            if (this.error === '') { this.error = err }
          } else {
            this.errorModal = true
            this.error = error.response.data
            if (this.error === '') { this.error = err }
          }
        } else {
          this.errorModal = true
          this.error = err
        }
        if (error.response.data.Message === 'Authorization has been denied for this request.') {
          this.logOut()
        }
      } catch {
        this.errorModal = true
        this.error = err
      }
    },
    logOut () {
      logoutService.logout().then(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      }).catch(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      })
    },
    closeErrorModal () {
      this.errorModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 992px) {
  .moveCreateRoom {
    transform: translateX(15px)
  }
}
</style>
