<template>
  <div>
    <!--view file modal-->
    <mdb-modal
      v-if="viewFileModal && fileViewType == 'WordOrPDF'"
      id="viewCandidateFileModal"
      size="lg"
      @close="closeViewFileModal()"
    >
      <mdb-modal-header>
        <mdb-modal-title class="ml-3" style="width:90%;">
          <b-row
            align-v="center"
            no-gutters
            style="width:90% !important;"
          >
            <b-col>
              <b-row no-gutters align-v="center">
                <img
                  v-if="candidateProfilePictureUrl != undefined"
                  style="height:25px;width:25px;border-radius:50%;"
                  :src="candidateProfilePictureUrl"
                >
                <mdb-icon
                  v-else
                  icon="fa fa-user"
                  size="lg"
                />
                <h4 class="ml-2 font-weight-bold" :style="{ 'color': `${userData.lbl.PrimaryColour} !important` }">
                  {{ candidateFirstName.toUpperCase() }}
                </h4>
                <h4 class="ml-2 font-weight-bold" :style="{ 'color': `${userData.lbl.PrimaryColour} !important` }">
                  {{ candidateSurname.toUpperCase() }}
                </h4>
              </b-row>
            </b-col>
            <b-col class="text-right">
              <b-row class="flex-nowrap justify-content-end" style="color:#313131">
                <span class="text-nowrap mr-2">{{ fileTitle }}</span> | <span class="font-weight-bold ml-2">{{ selectedFileType }}</span>
              </b-row>
            </b-col>
          </b-row>
        </mdb-modal-title>
      </mdb-modal-header>
      <div
        v-if="selectedFileType === 'Identity'"
        style="border: 2px solid #c0c0c0"
        class="m-3 p-3"
      >
        <div class="d-flex align-items-center">
          <FontAwesomeIcon
            icon="fa-solid fa-id-card"
            class="prefix"
            style="font-size: 1.3rem;"
          />
          <div class="grey-text mx-3" style="font-size:clamp(0.8rem, 2vw, 0.9rem)">
            Identity Status
          </div>
          <span
            class="d-flex align-items-center ml-auto"
            :class="{'grey-text': !currentIDCheckStatus }"
            @click="toggleIDCheck()"
          >
            <mdb-switch
              style="transform:scale(0.9)"
              :disabled="savingIDChecked == true"
              :value="currentIDCheckStatus"
              off-label=""
              on-label=""
              class="m-0 ml-auto"
            />
            <span class="ml-2" style="font-size:clamp(0.8rem, 2vw, 0.9rem)">{{ currentIDCheckStatus ? 'ID Verified' : 'Not Verified' }}</span>
          </span>
        </div>
      </div>

      <!--word/pdf doc-->
      <mdb-modal-body class="pt-0">
        <b-row>
          <div class="p-1 pl-3">
            <i
              v-if="refreshButtonShowing"
              class="fa fa-redo m-auto"
              style="cursor:pointer;color:#333"
              @click="refreshDOCURL()"
            />
            <i
              v-if="!refreshButtonShowing"
              class="fa fa-pulse fa-spinner mx-auto my-0"
              style="cursor:pointer;color:#333"
            />
          </div>
          <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
            <iframe
              v-if="selectedFileURL !== null"
              ref="iframe"
              :onload="loaded()"
              :src="`https://docs.google.com/viewer?url=${selectedFileURL}&embedded=true`"
              width="1366px"
              height="1623px"
              frameborder="0"
            />
          </div>
        </b-row>
      </mdb-modal-body>
    </mdb-modal>

    <!--img-->
    <mdb-modal
      v-if="viewFileModal && fileViewType == 'Image'"
      size="lg"
      style="z-index:11000 !important;"
      @close="closeViewFileModal()"
    >
      <mdb-modal-header>
        <mdb-modal-title style="width:95%;">
          <b-row
            align-v="center"
            no-gutters
            style="width:90%"
          >
            <b-col>
              <b-row no-gutters align-v="center">
                <img
                  v-if="candidateProfilePictureUrl != undefined"
                  style="height:25px;width:25px;border-radius:50%;"
                  :src="candidateProfilePictureUrl"
                >
                <mdb-icon
                  v-else
                  icon="fa fa-user"
                  size="lg"
                />
                <h4 class="ml-2 font-weight-bold" :style="{ 'color': `${userData.lbl.PrimaryColour} !important` }">
                  {{ candidateFirstName.toUpperCase() }}
                </h4>
                <h4 class="ml-2 font-weight-bold" :style="{ 'color': `${userData.lbl.PrimaryColour} !important` }">
                  {{ candidateSurname.toUpperCase() }}
                </h4>
              </b-row>
            </b-col>
            <b-col class="text-right">
              <b-row class="flex-nowrap justify-content-end" style="color:#313131">
                <span class="text-nowrap mr-2">{{ fileTitle }}</span> | <span class="font-weight-bold ml-2">{{ selectedFileType }}</span>
              </b-row>
            </b-col>
          </b-row>
        </mdb-modal-title>
      </mdb-modal-header>
      <div
        v-if="selectedFileType === 'Identity'"
        style="border: 2px solid #c0c0c0"
        class="m-3 p-3"
      >
        <div class="d-flex align-items-center">
          <FontAwesomeIcon
            icon="fa-solid fa-id-card"
            class="prefix"
            style="font-size: 1.3rem;"
          />
          <div class="grey-text mx-3" style="font-size:clamp(0.8rem, 2vw, 0.9rem)">
            Identity Status
          </div>
          <span
            class="d-flex align-items-center ml-auto"
            :class="{'grey-text': !currentIDCheckStatus }"
            @click="toggleIDCheck()"
          >
            <mdb-switch
              style="transform:scale(0.9)"
              :disabled="savingIDChecked == true"
              :value="currentIDCheckStatus"
              off-label=""
              on-label=""
              class="m-0 ml-auto"
            />
            <span class="ml-2" style="font-size:clamp(0.8rem, 2vw, 0.9rem)">{{ currentIDCheckStatus ? 'ID Verified' : 'Not Verified' }}</span>
          </span>
        </div>
      </div>
      <mdb-modal-body style="height:fit-content;overflow-y:scroll!important;">
        <img
          class="m-auto"
          style="height:auto;width:100%;min-width:200px;max-width:600px;"
          :src="selectedFileURL"
          frameborder="0"
        >
      </mdb-modal-body>
    </mdb-modal>
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import candidateService from '@/api/services/candidate.service'

export default {
  name: 'CandidateFileViewModal',
  props: {
    viewFileModal: Boolean,
    candidateFirstName: {
      type: String,
      default: ''
    },
    candidateSurname: {
      type: String,
      default: ''
    },
    candidateProfilePictureUrl: {
      type: String,
      default: ''
    },
    file: {
      type: Object,
      default: () => {}
    },
    candidateId: {
      type: Number,
      default: 0
    },
    currentIDCheckStatus: Boolean
  },
  setup () {
    const userStore = useUserData()
    const { userData, authData } = storeToRefs(userStore)
    const { updateCheckedIdValues } = useUserData()
    const { showGenericErrorModal } = useStatus()

    return {
      userData,
      authData,
      showGenericErrorModal,
      updateCheckedIdValues
    }
  },
  data () {
    return {
      willResolveIfFailed: null,
      selectedFileURL: null,
      refreshButtonShowing: false,
      savingIDChecked: false
    }
  },
  computed: {
    fileViewType () {
      return this.file.DocumentExtension === '.docx' || this.file.DocumentExtension === '.doc' || this.file.DocumentExtension === '.pdf' ? 'WordOrPDF' : 'Image'
    },
    fileTitle () {
      return this.file.DocumentName
    },
    selectedFileType () {
      return this.file.DocumentType
    }
  },
  watch: {
    viewFileModal () {
      if (this.viewFileModal === true) { this.openViewFileModal() }
    },
    currentIDCheckStatus () {
      this.saveIDChecked()
    }
  },
  methods: {
    closeViewFileModal () {
      this.$emit('closeViewFileModal')
    },
    refreshDOCURL () {
      this.refreshButtonShowing = false
      var URL = this.selectedFileURL
      this.selectedFileURL = null
      this.$nextTick(() => { this.selectedFileURL = URL }, 500)
      setTimeout(() => { this.refreshButtonShowing = true }, 1500)
    },
    openViewFileModal () {
      this.selectedFileURL = this.file.DocUrl
      this.refreshButtonShowing = false
      setTimeout(() => { this.refreshButtonShowing = true }, 1500)
    },
    loaded () {
      // logic here - loading issues due to cross-origin request. it won't allow you to access contentDocument and will throw a cross-origin error
      // however if frame is not loaded successfully then contentDocument will return a #document object. at this point refresh will loop over until loaded successfully
      if (this.$refs.iframe !== undefined) {
        try {
          this.willResolveIfFailed = this.$refs.iframe.contentDocument[0]
          this.refreshDOCURL()
        } catch {
          // successfully loaded
        }
      }
    },
    async saveIDChecked () {
      this.savingIDChecked = true
      try {
        await candidateService.changeIDCheckedStatus(this.currentIDCheckStatus, this.candidateId)
      } catch {
        this.showGenericErrorModal({ showing: true })
      }
      setTimeout(() => {
        this.savingIDChecked = false
      }, 1000)
    },
    toggleIDCheck () {
      this.updateCheckedIdValues({ IDChecked: !this.currentIDCheckStatus, candId: this.candidateId, name: `${this.userData.fne} ${this.userData.sne}` })
      this.$emit('changeIDCheckStatus', !this.currentIDCheckStatus)
    }
  }
}
</script>
