<template>
  <div
    class="min-h-full pt-2 pt-md-4 px-3 px-md-5 w-1/1 text-black rounded"
    style="height:calc(100vh - 250px);overflow-y:auto;margin-bottom:65px;overflow-x:hidden"
    :class="{ 'mob': mobileView }"
  >
    <div ref="scrollToDiv" style="height: 1px;">
      <div v-show="notificationsLoaded === true" class="grey-text text-left">
        <div style="margin-top:10px;" class="text-left grey-text">
          <div
            v-if="notifications.length === 0 && addNotificationsForm === false"
            class="text-center grey-text"
            style="font-size:14px;margin-top:50px;"
          >
            No notifications to display. Click <a
              class="primary-colour"
              style="font-weight:bold;"
              @click="addNewNotification()"
            ><em>here</em></a> to add a new notification.
          </div>
          <b-table
            id="notifications-table"
            class="text-left"
            head-variant="dark"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :items="notifications"
            :per-page="notificationsPerPage"
            :current-page="notificationsCurrentPage"
            :fields="notificationsFields"
            :filter="filter"
            small
            responsive="sm"
            thead-class="hidden_header"
          >
            <template #cell(notification)="data">
              <div style="margin-top:12px;">
                <div
                  v-if="data.item.inf == 3"
                  class="alert alert-primary smFont"
                  style="width:100%;float:left;display:flex;align-items:center;"
                >
                  <div style="float:left;width:95%">
                    {{ data.item.msg }}
                  </div>
                  <div style="float:right;width:5%">
                    <i
                      class="fa fa-times"
                      style="float:right;cursor:pointer;"
                      title="Delete this notification?"
                      @click="showDeleteNotificationModal(data.item.nid)"
                    />
                  </div>
                </div>
                <div
                  v-if="data.item.inf == 2"
                  class="alert alert-warning smFont"
                  style="width:100%;float:left;display:flex;align-items:center;"
                >
                  <div style="float:left;width:95%">
                    {{ data.item.msg }}
                  </div>
                  <div style="float:right;width:5%">
                    <i
                      class="fa fa-times"
                      style="float:right;cursor:pointer;"
                      title="Delete this notification?"
                      @click="showDeleteNotificationModal(data.item.nid)"
                    />
                  </div>
                </div>
                <div
                  v-if="data.item.inf == 1"
                  class="alert alert-danger smFont"
                  style="width:100%;float:left;display:flex;align-items:center;"
                >
                  <div style="float:left;width:95%">
                    {{ data.item.msg }}
                  </div>
                  <div style="float:right;width:5%">
                    <i
                      class="fa fa-times"
                      style="float:right;cursor:pointer;"
                      title="Delete this notification?"
                      @click="showDeleteNotificationModal(data.item.nid)"
                    />
                  </div>
                </div>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-show="notifications.length > 5"
            v-model="notificationsCurrentPage"
            class="float-right mt-4"
            :total-rows="notificationsRows"
            :per-page="notificationsPerPage"
            first-number
            last-number
            aria-controls="notifications-table"
            prev-text="<"
            next-text=">"
            style="margin-bottom:30px;"
          />
          <div md="2" offset-md="10">
            <button
              v-if="notifications.length > 0"
              style="float: left;margin-right: 0px;width: 110px;padding: 6px !important;text-align: center;margin-top:25px;"
              title="Add user"
              class="mdc-button btn"
              @click="addNewNotification()"
            >
              <span class="secondary-colour"><i class="fa fa-plus-circle" /> Add</span>
            </button>
          </div>
        </div>
        <!--add notifications form-->
        <div v-show="addNotificationsForm" style="text-align:left;padding:2px;margin-top:20px;">
          <div class="grey-text">
            Add Notification
          </div>
          <validation-observer v-slot="{ invalid }">
            <form @submit.prevent="addNotification()">
              <div class="md-form form-sm">
                <i class="fas fa-exclamation-triangle prefix primary-colour" style="margin-top: 6px;font-size: 1.2rem;" />
                <validation-provider
                  v-slot="{ errors }"
                  mode="lazy"
                  name="Type"
                  rules="required|notification-type"
                >
                  <mdb-select
                    v-model="types"
                    placeholder="Notification type"
                    style="margin-left:33px;"
                    size="sm"
                  />
                  <div style="font-size: 0.7rem;margin-left: 33px;margin-top:0px;margin-bottom:0px;">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>
              <div class="md-form form-sm">
                <i class="fas fa-exclamation-triangle prefix primary-colour" style="margin-top: 6px;font-size: 1.2rem;" />
                <mdb-select
                  v-model="subTypes"
                  placeholder="Notification sub-type"
                  style="margin-left:33px;"
                  size="sm"
                />
              </div>
              <div class="md-form form-sm">
                <i class="fas fa-users prefix primary-colour" style="margin-top: 6px;font-size: 1.2rem;" />
                <validation-provider
                  v-slot="{ errors }"
                  mode="lazy"
                  name="TargetRole"
                  rules="required|notification-target-role"
                >
                  <mdb-select
                    v-model="targetRoles"
                    placeholder="Target role"
                    style="margin-left:33px;"
                    size="sm"
                  />
                  <div style="font-size: 0.7rem;margin-left: 33px;margin-top:0px;margin-bottom:0px;">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>
              <div class="md-form form-sm">
                <i class="fas fa-edit prefix primary-colour" style="margin-top: 6px;font-size: 1.2rem;" />
                <validation-provider
                  v-slot="{ errors }"
                  mode="lazy"
                  name="Description"
                  rules="required"
                >
                  <mdb-input
                    v-model="notification.Description"
                    type="text"
                    auto-complete="description"
                    label="Description"
                    style="margin-left:33px;"
                    size="sm"
                  />
                  <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                    Please enter notification text
                  </div>
                </validation-provider>
              </div>
              <div class="md-form form-sm">
                <i class="fas fa-calendar prefix primary-colour" style="margin-top: 6px;font-size: 1.2rem;" />
                <mdb-input
                  v-model="notification.StartD"
                  type="text"
                  auto-complete="startd"
                  label="Optional start date (dd/mm/yyyy)"
                  style="margin-left:33px;"
                  size="sm"
                />
              </div>
              <div class="md-form form-sm">
                <i class="fas fa-clock prefix primary-colour" style="margin-top: 6px;font-size: 1.2rem;" />
                <mdb-input
                  v-model="notification.StartT"
                  type="text"
                  auto-complete="startt"
                  label="Optional start time (hh:mm)"
                  style="margin-left:33px;"
                  size="sm"
                />
              </div>
              <div class="md-form form-sm">
                <i class="fas fa-calendar prefix primary-colour" style="margin-top: 6px;font-size: 1.2rem;" />
                <mdb-input
                  v-model="notification.ExpiryD"
                  type="text"
                  auto-complete="endd"
                  label="Optional end date (dd/mm/yyyy)"
                  style="margin-left:33px;"
                  size="sm"
                />
              </div>
              <div class="md-form form-sm">
                <i class="fas fa-clock prefix primary-colour" style="margin-top: 6px;font-size: 1.2rem;" />
                <mdb-input
                  v-model="notification.ExpiryT"
                  type="text"
                  auto-complete="endt"
                  label="Optional end time (hh:mm)"
                  style="margin-left:33px;"
                  size="sm"
                />
              </div>
              <mdb-btn
                class="float-left btn-outline"
                size="sm"
                style="width:100px;"
                @click="addNotificationsForm = false"
              >
                CANCEL
              </mdb-btn>
              <mdb-btn
                v-show="!buttonClick"
                type="submit"
                :disabled="invalid"
                class="float-right"
                size="sm"
                style="width:100px;"
              >
                ADD
              </mdb-btn>
              <mdb-btn
                v-show="buttonClick"
                class="float-right"
                size="sm"
                style="width:100px;"
              >
                <mdb-icon icon="fa fa-pulse fa-spinner" />
              </mdb-btn>
            </form>
          </validation-observer>
        </div>
        <!--delete notifcation modal-->
        <mdb-modal
          id="deleteNotificationModal"
          :show="deleteNotificationModal"
          @close="deleteNotificationModal = false"
        >
          <mdb-modal-header>
            <mdb-modal-title>DELETE NOTIFICATION</mdb-modal-title>
          </mdb-modal-header>
          <mdb-modal-body :class="{ 'modalHeight': mobileView }">
            <p class="my-4" style="font-size:0.8rem;text-align:left;">
              Are you sure you want to delete this notification?
            </p>
          </mdb-modal-body>
          <mdb-modal-footer>
            <mdb-btn color="primary" @click.native="deleteNotifcation">
              YES
            </mdb-btn>
          </mdb-modal-footer>
        </mdb-modal>
      </div>
      <!--generic alert modal-->
      <mdb-modal
        id="alertModal"
        :show="alertModal"
        @close="alertModal = false"
      >
        <mdb-modal-header>
          <mdb-modal-title>{{ alertModalTitle.toUpperCase() }}</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body :class="{ 'modalHeight': mobileView }">
          <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
            <span v-html="alertModalContent" />
          </p>
        </mdb-modal-body>
        <mdb-modal-footer>
          <mdb-btn
            color="primary"
            size="sm"
            style="width:100px;"
            @click.native="alertModal = false"
          >
            OK
          </mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { extend } from 'vee-validate'
import logoutService from '@/api/services/logout.service'
import adminService from '@/api/services/admin.service'
import { useMobile } from '@/composables/useMobile'
import { logout } from '@/methods/auth.js'
import { userLanguage } from '@/common/userLanguage'

import { mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModal, mdbBtn } from 'mdbvue'

extend('notification-type', {
  validate: types => { return types.length > 0 },
  lazy: true,
  message: 'Select notification type'
})

extend('notification-target-role', {
  validate: targetRoles => { return targetRoles.length > 0 },
  lazy: true,
  message: 'Select target role'
})

export default {
  name: 'Admin',
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbBtn
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    return {
      authData,
      mobileView
    }
  },
  data () {
    return {
      notificationsFields: [
        { key: 'Notification', label: '  Notification', sortable: false }
      ],
      showNotifications: false,
      addNotificationsForm: false,
      deleteNotificationModal: false,
      notifications: [],
      notificationsLoaded: false,
      sortBy: '',
      sortDesc: false,
      notificationsPerPage: 5,
      notificationsCurrentPage: 1,
      filter: null,
      buttonClick: false,
      userLanguage,
      filterOn: [],
      alertModal: false,
      alertModalTitle: '',
      alertModalContent: '',
      notificationId: null,
      types: [
        { text: 'Alert', value: 1 },
        { text: 'Warning', value: 2 },
        { text: 'Info', value: 3 }
      ],
      subTypes: [
        { text: 'Sub type 1', value: 1 },
        { text: 'Sub type 2', value: 2 },
        { text: 'Sub type 3', value: 3 }
      ],
      targetRoles: [
        { text: 'All Users', value: 'All' },
        { text: 'User', value: 'User' },
        { text: 'Manager', value: 'Manager' },
        { text: 'Admin', value: 'Admin' }
      ],
      notification: {
        GoNotificationId: 0,
        Type: 0,
        SubType: 0,
        Description: '',
        Role: 1,
        StartD: '',
        StartT: '',
        ExpiryD: '',
        ExpiryT: '',
        IsDeleted: false,
        StartDate: null,
        ExpiryDate: null
      }
    }
  },
  computed: {
    notificationsRows () {
      return this.notifications.length
    },
    sortOptions () {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
    dateFormat: {
      get () {
        return this.$material.locale.dateFormat
      },
      set (val) {
        this.$material.locale.dateFormat = val
      }
    },
    type: function () {
      var t = ''
      this.types.forEach(element => {
        if (element.selected === true) {
          t = element.value
        }
      })
      return t
    },
    subType: function () {
      var s = ''
      this.subTypes.forEach(element => {
        if (element.selected === true) {
          s = element.value
        }
      })
      return s
    },
    targetRole: function () {
      var r = ''
      this.targetRoles.forEach(element => {
        if (element.selected === true) {
          r = element.value
        }
      })
      return r
    }
  },
  created () {
    this.$parent.notificationsLoading = true
    this.getNotifications()
  },
  methods: {
    logout,
    addNewNotification () {
      this.addNotificationsForm = true
    },
    addNotification () {
      this.buttonClick = true
      this.notification.Type = this.type
      this.notification.SubType = this.subType
      this.notification.Role = this.targetRole
      adminService.addNotification(this.notification).then(() => {
        this.addNotificationsForm = false
        this.getNotifications()
      }).catch((error) => {
        this.addNotificationsForm = false
        this.notification = null
        this.alertModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
    },
    showDeleteNotificationModal (id) {
      this.notificationId = id
      this.deleteNotificationModal = true
    },
    deleteNotifcation () {
      adminService.deleteNotification(this.notificationId).then(() => {
        this.deleteNotificationModal = false
        this.notificationId = null
        this.getNotifications()
      }).catch((error) => {
        this.deleteNotificationModal = false
        this.notificationId = null
        this.alertModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
    },
    getNotifications () {
      adminService.getNotifications().then((response) => {
        this.notifications = response.data
        setTimeout(() => {
          this.$parent.notificationsLoading = false
          this.notificationsLoaded = true
        }, 3000)
        this.buttonClick = false
      }).catch((error) => {
        setTimeout(() => {
          this.$parent.notificationsLoading = false
          this.notificationsLoaded = true
        }, 3000)
        this.alertModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
        this.buttonClick = false
      })
    },
    errorAlert (error) {
      try {
        if (error.response.data != null) {
          if (error.response.data.Message != null) {
            this.errorModal = true
            this.error = error.response.data.Message
            if (this.error === '') { this.error = 'Sorry, an error has occured!' }
          } else if (error.response.data.error_description != null) {
            this.errorModal = true
            this.error = error.response.data.error_description
            if (this.error === '') { this.error = 'Sorry, an error has occured!' }
          } else {
            this.errorModal = true
            this.error = error.response.data
            if (this.error === '') { this.error = 'Sorry, an error has occured!' }
          }
        } else {
          this.errorModal = true
          this.error = 'Sorry an error has occurred!'
        }
        if (error.response.data.Message === 'Authorization has been denied for this request.') {
          this.doLogOut()
        }
      } catch {
        this.errorModal = true
        this.error = 'Sorry an error has occurred!'
      }
    },
    doLogOut () {
      logoutService.logout().then(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      }).catch(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .mob {
    width: calc(100% + 30px) !important;
    margin-left: -15px !important;
    margin-top: 15px;
  }
  .mobileTopSections {
    margin-top: 0px !important;
  }
  @media (max-width: 639px) {
    .smFont {
      font-size: 11px !important;
    }
  }
</style>
