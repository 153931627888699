<template>
  <div style="height:100%;width:100%; overflow-y:auto; overflow-x:hidden;scrollbar-color:var(--pr-color);scrollbar-width:10px;">
    <div v-if="(searchedRecordings.length === 0 && recordings.length === 0) || noResults === true" style="float: left;color: #555;font-size: 0.8rem;">
      No Results
    </div>

    <div v-if="loadingRecordings" class="ml-0 ml-md-3 ml-lg-5 mView d-block d-sm-none">
      <h3 class="primary-colour text-left font-weight-bold">
        Loading
        <i style="margin-left: 5px !important;" class="fa fa-spin fa-spinner ml-3" />
      </h3>
    </div>

    <!-- grid View -->
    <div v-show="!listView && !loadingRecordings">
      <div class="shineIntrosGrid px-3" style="min-height:fit-content;">
        <div
          v-for="recording in searchedRecordings"
          :key="recording.RecordingID"
          style="border:#a1a1a1 1px solid; height: 320px; border-radius:5px;box-sizing:border-box;width:100%;"
          class="mb-4 shadow1 justify-self-center"
        >
          <div style="position:relative">
            <a
              class="pin"
              style="left: 45.55%;top: 52px;font-size: 2rem;opacity: 0.7;"
              @click="watchRecording(recording.VideoUrl, recording.RecordingName, recording)"
            ><span class="fa fa-play" /></a>
          </div>
          <div style="height: 140px;width:100%;" class="d-flex align-items-center justify-content-center">
            <img :src="recording.ThumbnailUrl" style="height:100%; width:100%; object-fit:cover;">
          </div>
          <div style="height: 176px;background:#fff;width:100%;color:#333;border-bottom-right-radius:4px;border-bottom-left-radius:4px;" class="d-flex flex-column justify-content-around p-2">
            <div
              v-if="recording.RecordingName.length < 20"
              style="text-overflow: ellipsis;overflow: hidden;cursor:help;color:#333;font-size:1rem;"
              class="mr-auto"
              :title="'My Shine Intro: ' + recording.RecordingName"
            >
              {{ recording.RecordingName }}
            </div>
            <div
              v-if="recording.RecordingName.length > 19 && recording.RecordingName.length < 28"
              style="text-overflow: ellipsis;overflow: hidden;cursor:help;color:#333;font-size:0.9rem;"
              class="mr-auto"
              :title="'My Shine Intro: ' + recording.RecordingName"
            >
              {{ recording.RecordingName }}
            </div>
            <div
              v-if="recording.RecordingName.length > 27"
              style="text-overflow: ellipsis;overflow: hidden;cursor:help;color:#333;font-size:0.75rem;"
              class="mr-auto"
              :title="'My Shine Intro: ' + recording.RecordingName"
            >
              {{ recording.RecordingName }}
            </div>
            <div style="width:100%;border-bottom:#a1a1a1 1px solid" class="d-flex justify-content-between align-items-center mt-1 pb-2">
              <div style="font-size:0.8rem;" class="ml-2">
                <FontAwesomeIcon icon="fa-solid fa-calendar-day" class="primary-colour" />
                {{ new Date(recording.DateAdded).toLocaleDateString([userLanguage]) }}
              </div>
              <div style="font-size:0.8rem;" class="ml-2">
                <FontAwesomeIcon icon="fa-solid fa-clock-desk" class="primary-colour" />
                {{ new Date(recording.DateAdded).toLocaleTimeString([userLanguage]).substring(0, 5) }}
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <div
                :label="recording.Branding.AccountName"
                class="p-1 d-flex aling-items-center justify-content-center"
                :style="{'background': `${recording.Branding.NavColour}`}"
                style="border-radius: 5px;height: 28px;"
              >
                <img :src="recording.Branding.Logo" style="max-height:35px;max-width:100px;object-fit-cover;">
              </div>
              <mdb-popover
                ref="popover"
                trigger="click"
                :options="{placement: 'bottom'}"
                style="cursor:pointer;"
                class="grey-text"
              >
                <div slot="body">
                  <h6 v-if="videoIntroFilterType !== 'Archived Shine Intros'" class="text-nowrap">
                    <div v-if="shareLinkCopied == 'false'" @click="copyShareLink(recording)">
                      <FontAwesomeIcon icon="fa-solid fa-link-simple" class="mr-1 grey-link " />
                      <a class="grey-link">Copy Share Link</a>
                    </div>
                    <div v-if="shareLinkCopied == 'preparing'">
                      <mdb-icon icon="fa fa-pulse fa-spinner" class="mr-1 grey-link " />
                      <a class="grey-link">Creating your Share Link</a>
                    </div>
                    <div v-if="shareLinkCopied == 'copied'">
                      <i class="fa fa-thumbs-up mr-1 grey-link " />
                      <a class="grey-link">Copied to Clipboard</a>
                    </div>
                  </h6>
                  <h6
                    v-if="videoIntroFilterType !== 'Archived Shine Intros'"
                    class="text-nowrap"
                    @click="openResetShareLink(recording)"
                  >
                    <FontAwesomeIcon icon="fa-arrow-rotate-left fa-solid" class="mr-1 grey-link" /><a class="grey-link">Reset Share Link</a>
                  </h6>
                  <h6
                    v-if="videoIntroFilterType !== 'Archived Shine Intros'"
                    class="text-nowrap"
                    @click="watchRecording(recording.VideoUrl, recording.RecordingName, recording)"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-image-polaroid" class="mr-1 grey-link" /><a class="grey-link">Change Thumbnail</a>
                  </h6>
                  <h6
                    v-if="videoIntroFilterType !== 'Archived Shine Intros'"
                    class="text-nowrap"
                    @click="reBrand(recording)"
                  >
                    <FontAwesomeIcon icon="fa-shuffle fa-solid" class="mr-1 grey-link" /><a class="grey-link">Re-Brand</a>
                  </h6>
                  <h6
                    v-if="videoIntroFilterType !== 'Archived Shine Intros'"
                    class="text-nowrap"
                    @click="viewHistory(recording)"
                  >
                    <FontAwesomeIcon icon="fa-rectangle-vertical-history fa-solid" class="mr-1 grey-link" /><a class="grey-link">View History</a>
                  </h6>
                  <h6
                    v-if="videoIntroFilterType !== 'Archived Shine Intros'"
                    class="text-nowrap"
                    @click="updateIntroRecording('archive', recording.GoVideoIntroId)"
                  >
                    <FontAwesomeIcon icon="fa-box-archive fa-solid" class="mr-1 grey-link" /><a class="grey-link">Archive Recording</a>
                  </h6>
                  <h6
                    v-if="videoIntroFilterType === 'Archived Shine Intros'"
                    class="text-nowrap"
                    @click="updateIntroRecording('archive', recording.GoVideoIntroId)"
                  >
                    <FontAwesomeIcon icon="fa-box-archive fa-solid" class="mr-1 grey-link" /><a class="grey-link">Un-Archive Recording</a>
                  </h6>
                  <h6 class="text-nowrap" @click="showDeleteRecordingModal(recording.GoVideoIntroId)">
                    <FontAwesomeIcon icon="fa-trash-can-xmark fa-solid" class="mr-1 grey-link" /><a class="grey-link">Delete Recording</a>
                  </h6>
                  <EmbedVideo
                    v-if="recording.VideoUrl !== ''"
                    :src="recording.VideoUrl"
                    :video-title="recording.RecordingName"
                    @closePopover="$refs.popover.map(p => p.doClose())"
                  />
                </div>
                <mdb-icon
                  slot="reference"
                  style="width: 20px; height:30px; color:#555;font-size:1rem;"
                  class="d-flex align-items-center justify-content-center ellipsisButtonHover"
                  icon="ellipsis-v"
                />
              </mdb-popover>
            </div>
            <div v-if="videoIntroFilterType !== 'Archived Shine Intros'" style="margin-top:10px;">
              <mdb-btn
                style="width: 80px;padding: 6px !important;text-align: center;min-width:fit-content"
                class="m-0"
                @click="share(recording)"
              >
                SHARE
              </mdb-btn>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="listView && !loadingRecordings">
      <div class="d-flex flex-wrap justify-content-center justify-content-md-start" style="min-height:fit-content;width:100%;">
        <div
          v-for="recording in searchedRecordings"
          :key="recording.RecordingID"
          style="border:#a1a1a1 1px solid; height: 70px;max-height:fit-content; width:100%; border-radius:1px;box-sizing:border-box"
          class="mb-2 mx-2 mr-3 d-flex"
        >
          <div style="position:relative" class="d-none d-sm-block d-md-none d-lg-block">
            <a
              class="pin"
              style="left: 45px;top: 16px;font-size: 1.5rem;opacity: 0.7;"
              @click="watchRecording(recording.VideoUrl, recording.RecordingName, recording)"
            ><span class="fa fa-play" /></a>
          </div>
          <div style="height:101%;width:fit-content;width:110px;background:#333;" class="d-none d-sm-flex d-md-none d-lg-flex align-items-center justify-content-center">
            <img :src="recording.ThumbnailUrl" style="height:100%; object-fit:cover;background:#333">
          </div>
          <div class="mr-auto d-flex flex-column justify-content-center text-left ml-1 ml-md-2 pr-2 recordingNameWidth" style="height:100%;">
            <div
              v-if="recording.RecordingName.length < 20"
              class="d-flex align-items-center mx-auto font-weight-bold"
              style="color:#333;font-size:1.1rem"
            >
              {{ recording.RecordingName }}
            </div>
            <div
              v-if="recording.RecordingName.length > 19 && recording.RecordingName.length < 28"
              class="d-flex align-items-center mx-auto font-weight-bold"
              style="color:#333;font-size:0.9rem"
            >
              {{ recording.RecordingName }}
            </div>
            <div
              v-if="recording.RecordingName.length > 27"
              class="d-flex align-items-center mx-auto font-weight-bold"
              style="color:#333;font-size:0.6rem"
            >
              {{ recording.RecordingName }}
            </div>
          </div>
          <div class="d-flex flex-column justify-content-center align-items-start mx-md-1 mx-lg-3" style="width:fit-content;">
            <div style="font-size:0.8rem;" class="d-flex flex-nowrap">
              <FontAwesomeIcon icon="fa-solid fa-calendar-day" class="d-inline" />
              <div style="color:#333" class="ml-1 text-nowrap d-inline">
                {{ new Date(recording.DateAdded).toLocaleDateString([userLanguage]) }}
              </div>
            </div>
            <div style="font-size:0.8rem;">
              <FontAwesomeIcon icon="fa-solid fa-clock-desk" class="d-inline" />
              <span style="color:#333" class="ml-1">
                {{ new Date(recording.DateAdded).toLocaleTimeString([userLanguage]).substring(0, 5) }}
              </span>
            </div>
          </div>
          <div class="align-items-center justify-content-center d-none d-sm-flex mx-1 mx-md-2 mx-lg-3">
            <div v-if="videoIntroFilterType !== 'Archived Shine Intros'">
              <mdb-btn
                style="width: 80px;padding: 6px !important;text-align: center;min-width:fit-content"
                class="m-0"
                @click="share(recording)"
              >
                SHARE
              </mdb-btn>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-center mr-2 mr-lg-3">
            <mdb-popover
              ref="popover"
              trigger="click"
              :options="{placement: 'bottom'}"
              style="cursor:pointer;"
              class="grey-text"
            >
              <div slot="body">
                <h6
                  v-if="videoIntroFilterType !== 'Archived Shine Intros'"
                  class="text-nowrap d-flex d-sm-none d-md-flex d-lg-none"
                  @click="watchRecording(recording.VideoUrl, recording.RecordingName, recording)"
                >
                  <FontAwesomeIcon icon="fa-play fa-solid" class="mr-1 grey-link" /><a class="grey-link">Play</a>
                </h6>
                <h6
                  v-if="videoIntroFilterType !== 'Archived Shine Intros'"
                  class="text-nowrap d-flex d-sm-none"
                  @click="share(recording)"
                >
                  <FontAwesomeIcon icon="fa-solid fa-share-nodes" class="mr-1 grey-link" /><a class="grey-link">Share</a>
                </h6>
                <h6 v-if="videoIntroFilterType !== 'Archived Shine Intros'" class="text-nowrap">
                  <div v-if="shareLinkCopied == 'false'" @click="copyShareLink(recording)">
                    <FontAwesomeIcon icon="fa-solid fa-link-simple" class="mr-1 grey-link " />
                    <a class="grey-link">Copy Share Link</a>
                  </div>
                  <div v-if="shareLinkCopied == 'preparing'">
                    <mdb-icon icon="fa fa-pulse fa-spinner" class="mr-1 grey-link " />
                    <a class="grey-link">Creating your Share Link</a>
                  </div>
                  <div v-if="shareLinkCopied == 'copied'">
                    <i class="fa fa-thumbs-up mr-1 grey-link " />
                    <a class="grey-link">Copied to Clipboard</a>
                  </div>
                </h6>
                <h6
                  v-if="videoIntroFilterType !== 'Archived Shine Intros'"
                  class="text-nowrap"
                  @click="openResetShareLink(recording)"
                >
                  <FontAwesomeIcon icon="fa-arrow-rotate-left fa-solid" class="mr-1 grey-link" /><a class="grey-link">Reset Share Link</a>
                </h6>
                <h6
                  v-if="videoIntroFilterType !== 'Archived Shine Intros'"
                  class="text-nowrap"
                  @click="watchRecording(recording.VideoUrl, recording.RecordingName, recording)"
                >
                  <FontAwesomeIcon icon="fa-image-polaroid fa-solid" class="mr-1 grey-link" /><a class="grey-link">Change Thumbnail</a>
                </h6>
                <h6
                  v-if="videoIntroFilterType !== 'Archived Shine Intros'"
                  class="text-nowrap"
                  @click="reBrand(recording)"
                >
                  <FontAwesomeIcon icon="fa-shuffle fa-solid" class="mr-1 grey-link" /><a class="grey-link">Re-Brand</a>
                </h6>
                <h6
                  v-if="videoIntroFilterType !== 'Archived Shine Intros'"
                  class="text-nowrap"
                  @click="viewHistory(recording)"
                >
                  <FontAwesomeIcon icon="fa-rectangle-vertical-history fa-solid" class="mr-1 grey-link" /><a class="grey-link">View History</a>
                </h6>
                <h6
                  v-if="videoIntroFilterType !== 'Archived Shine Intros'"
                  class="text-nowrap"
                  @click="updateIntroRecording('archive', recording.GoVideoIntroId)"
                >
                  <FontAwesomeIcon icon="fa-box-archive fa-solid" class="mr-1 grey-link" /><a class="grey-link">Archive Recording</a>
                </h6>
                <h6
                  v-if="videoIntroFilterType === 'Archived Shine Intros'"
                  class="text-nowrap"
                  @click="updateIntroRecording('archive', recording.GoVideoIntroId)"
                >
                  <FontAwesomeIcon icon="fa-box-archive fa-solid" class="mr-1 grey-link" /><a class="grey-link">Un-Archive Recording</a>
                </h6>
                <h6
                  v-if="videoIntroFilterType === 'Archived Shine Intros'"
                  class="text-nowrap"
                  @click="showDeleteRecordingModal(recording.GoVideoIntroId)"
                >
                  <FontAwesomeIcon icon="fa-trash-can-xmark fa-solid" class="mr-1 grey-link" /><a class="grey-link">Delete Recording</a>
                </h6>
                <EmbedVideo
                  v-if="recording.VideoUrl !== ''"
                  :src="recording.VideoUrl"
                  :video-title="recording.RecordingName"
                  @closePopover="$refs.popover.map(p => p.doClose())"
                />
              </div>
              <mdb-icon
                slot="reference"
                style="width: 20px; height:30px; color:#555;font-size:1rem;"
                class="d-flex align-items-center justify-content-center ellipsisButtonHover"
                icon="ellipsis-v"
              />
            </mdb-popover>
          </div>
        </div>
      </div>
    </div>

    <PlayRecordingModal
      :selected-recording="selectedRecording"
      :recording-to-watch-u-r-l="recordingToWatchURL"
      :selected-recording-name="selectedRecordingName"
      :play-video-intro-recording-modal-showing="playVideoIntroRecordingModalShowing"
      @playVideoIntroRecordingModalShowing="playVideoIntroRecordingModalShowing = $event"
    />

    <IntroHistoryModal
      :selected-recording="selectedRecording"
      :video-intro-history-modal-showing="videoIntroHistoryModalShowing"
      @videoIntroHistoryModalShowing="videoIntroHistoryModalShowing = $event"
    />

    <ReBrandingModal
      :selected-recording="selectedRecording"
      :re-brand-intro-modal-showing="reBrandIntroModalShowing"
      @reBrandIntroModalShowing="reBrandIntroModalShowing = $event"
    />

    <ShareIntroModal
      :selected-recording="selectedRecording"
      :share-intro-modal-showing="shareIntroModalShowing"
      @shareIntroModalShowing="shareIntroModalShowing = $event"
    />
  </div>
</template>

<script>
import { useVideoIntro, useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import videoIntroService from '@/api/services/videointro.service'
import { userLanguage } from '@/common/userLanguage'
import { SHARE_INTRO_URL } from '@/common/config.js'

import PlayRecordingModal from './playRecordingModal.vue'
import IntroHistoryModal from './introHistoryModal.vue'
import ReBrandingModal from './reBrandingModal.vue'
import ShareIntroModal from './shareIntroModal.vue'
import EmbedVideo from '@/components/misc/embedVideo.vue'

export default {
  name: 'Recordings',
  components: {
    PlayRecordingModal,
    IntroHistoryModal,
    ReBrandingModal,
    ShareIntroModal,
    EmbedVideo
  },
  props: {
    listView: Boolean,
    search: {
      type: String,
      default: ''
    }
  },
  setup () {
    const userStore = useUserData()
    const { userData, authData } = storeToRefs(userStore)

    const videoIntroStore = useVideoIntro()
    const { myIntros, archivedIntros, videoIntroFilterType } = storeToRefs(videoIntroStore)
    const { addOrUpdateIntro, switchArchived } = useVideoIntro()

    const { showGenericConfirmModal, showGenericErrorModal, closeGenericConfirmModal } = useStatus()

    return {
      userData,
      authData,
      addOrUpdateIntro,
      switchArchived,
      myIntros,
      archivedIntros,
      videoIntroFilterType,
      showGenericConfirmModal,
      showGenericErrorModal,
      closeGenericConfirmModal
    }
  },
  data () {
    return {
      playVideoIntroRecordingModalShowing: false,
      videoIntroHistoryModalShowing: false,
      reBrandIntroModalShowing: false,
      shareIntroModalShowing: false,
      recordingToWatchURL: '',
      selectedRecordingName: '',
      selectedRecording: { RecordingName: '' },
      loadingRecordings: false,
      userLanguage,
      deleteRecordingModal: false,
      spinningFlag: false,
      shareLinkCopied: 'false',
      newShareLinkHistoryId: 0,
      recId: 0
    }
  },
  computed: {
    recordings () {
      if (this.videoIntroFilterType === 'My Shine Intros') return this.myIntros
      if (this.videoIntroFilterType === 'Archived Shine Intros') return this.archivedIntros
      return []
    },
    searchedRecordings () {
      if (this.search === '' || this.loadingRecordings) { return this.recordings }
      try {
        return this.recordings.filter(r => r.RecordingName.toLowerCase().includes(this.search.toLowerCase()))
      } catch { return this.recordings }
    },
    noResults () {
      return this.search !== '' && this.recordings.length > 0 && this.searchedRecordings.length === 0
    }
  },
  methods: {
    openResetShareLink (recording) {
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'CONFIRM LINK RESET',
        confirmMessage: 'Are you sure you want to reset the link? Anyone currently with access to this link will no longer be able to view this Shine Intro.',
        confirmButtonMessage: 'CONFIRM',
        confirmButtonFunction: () => this.updateIntroRecording('resetLink', recording.GoVideoIntroId)
      })
      this.$refs.popover.map(r => r.doClose())
    },
    viewHistory (recording) {
      this.$refs.popover.map(r => r.doClose())
      this.selectedRecording = recording
      this.videoIntroHistoryModalShowing = true
    },
    reBrand (recording) {
      this.$refs.popover.map(r => r.doClose())
      this.selectedRecording = recording
      this.reBrandIntroModalShowing = true
    },
    checkPreparedHistoryId () {
      if (this.newShareLinkHistoryId === 0) {
        setTimeout(() => {
          this.checkPreparedHistoryId()
        }, 3000)
      }
      if (this.newShareLinkHistoryId !== 0) {
        this.shareLinkCopied = 'copied'
        setTimeout(() => {
          this.shareLinkCopied = 'false'
          this.$refs.popover.map(r => r.doClose())
        }, 3000)
      }
    },
    async copyShareLink (recording) {
      this.newShareLinkHistoryId = 0
      this.shareLinkCopied = 'preparing'
      setTimeout(() => {
        this.checkPreparedHistoryId()
      }, 3000)
      try {
        const res = await videoIntroService.createVideoIntroHistory('Copy', recording.GoVideoIntroId)
        this.newShareLinkHistoryId = res.data.Id
        if (this.newShareLinkHistoryId !== 0) {
          await navigator.clipboard.writeText(`${SHARE_INTRO_URL}/a/${res.data.MetaFile}`)
        }
      } catch (err) {
        this.newShareLinkHistoryId = -1
        this.shareLinkCopied = 'false'
        this.$refs.popover.map(r => r.doClose())
      }
    },
    watchRecording (url, name, recording) {
      this.selectedRecording = recording
      this.selectedRecordingName = name
      this.recordingToWatchURL = url
      this.$refs.popover.map(r => r.doClose())
      this.$nextTick(() => {
        this.playVideoIntroRecordingModalShowing = true
      })
    },
    async updateIntroRecording (updateType, recordingId) {
      // logic for making archived/nonarchived, deleting, or resetting link
      this.$refs.popover.map(r => r.doClose())
      try {
        const res = await videoIntroService.updateShineIntroRecording(updateType, recordingId)
        if (res.data !== '' && res.data !== null) {
          if (updateType === 'delete') {
            this.addOrUpdateIntro({...res.data, RemoveFromCurrentView: true})
          }
          if (updateType === 'archive') {
            this.switchArchived({ ...res.data})
          }
          if (updateType === 'resetLink') {
            this.addOrUpdateIntro(res.data)
          }
        }
        this.closeGenericConfirmModal()
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
    },
    share (recording) {
      this.selectedRecording = recording
      this.shareIntroModalShowing = true
      this.$refs.popover.map(r => r.doClose())
    },
    showDeleteRecordingModal (recId) {
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'CONFIRM TO DELETE',
        confirmMessage: 'Are you sure you want to delete this Shine Intro?',
        confirmButtonMessage: 'CONFIRM',
        confirmButtonFunction: () => this.updateIntroRecording('delete', recId)
      })
      this.$refs.popover.map(r => r.doClose())
    }
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 576px) {
  .shineIntrosGrid {
    display: grid;
    justify-content: space-evenly;
    grid-gap: 35px;
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1200px) {
  .shineIntrosGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 1500px) {
  .shineIntrosGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (min-width: 1700px) {
  .shineIntrosGrid {
    grid-template-columns: repeat(auto-fill, 250px);
  }
}
.borderForNoProfilePic {
  border: solid 2px #333;
}
.recordingNameWidth {
  max-width:60%;
}
@media (min-width:576px) {
  .recordingNameWidth {
    max-width:45%;
  }
}
@media (max-width: 576px) {
  .wider {
    width:80% !important;
  }
}
</style>
