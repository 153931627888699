<template>
  <div
    class="p-2 pb-4 m-2"
    style="border: solid 2px #e1e1e1;border-radius: 10px;"
  >
    <div class="d-flex flex-row">
      <span class="font-bold text-lg mb-3"> {{ text }} </span>
      <Tooltip
        class="ml-3"
        style="margin-top: 3px"
        tooltip-direction="left"
        :tool-tip-summary="description"
      />
      <div
        v-if="!hideScoreSummary"
        class="flex ml-auto"
        style="column-gap: 10px;"
      >
        <div class="border rounded-xl m-auto p-3">
          <span> {{ score }} / {{ maxScore }} </span>
        </div>
        <div class="border rounded-xl m-auto p-3">
          <span> {{ percentage }} </span>
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import Tooltip from '@/components/misc/tooltip.vue'

export default {
  components: {
    Tooltip
  },

  props: {
    score: {
      type: Number,
      default: 0
    },
    maxScore: {
      type: Number,
      default: 0
    },
    text: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    hideScoreSummary: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    percentage () {
      if (this.score === 0) {
        return `${0}%`
      } else {
        const p = (this.score / this.maxScore) * 100
        return `${p.toFixed(0)}%`
      }
    }
  }
}
</script>
