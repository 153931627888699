<template>
  <div class="bg-gray-100 border font-bold text-lg text-left flex flex-col py-2 cursor-pointer">
    <div
      class="flex flex-row items-center justify-between"
      @click="show = !show"
    >
      <span class="text-black px-4 py-2"> {{ name }} </span>
      <i
        class="ml-auto flex pr-3 text-black"
        :class="show ? 'fa fa-regular fa-caret-down' : 'fa fa-regular fa-caret-up'"
      />
    </div>
    <div
      v-show="show"
      class="mt-2"
    >
      <div v-if="slots.length > 0">
        <div
          v-for="(timeslot, i) in slots"
          :key="i"
          class="px-3 py-2 text-primary flex flex-row items-center space-x-2 hover:bg-gray-100"
          :class="i % 2 === 0 ? 'bg-whitex' : 'bg-gray-50'"
        >
          <i class="fa fa-regular fa-clock primary-colour" />
          <p
            style="font-size: 0.9rem"
            class="w-32 dark-grey-text text-nowrap"
          >
            {{ timeslot[0] }}
          </p>
          <div class="ml-auto flex items-center flex-wrap">
            <div
              v-for="(user,j) in timeslot[1]"
              :key="j"
              class="flex items-center ml-1"
              style="height:40px;"
            >
              <div v-if="!user.IsUnlimited">
                <div
                  v-if="j <= 5"
                  class="w-10 h-10 rounded-full border-2 d-flex align-items-center justify-content-center"
                >
                  <span
                    class="m-auto font-bold text-primary text-sm"
                    :title="'Assigned to ' + user?.ApplicationUser?.FirstName + ' ' + user?.ApplicationUser?.Surname"
                  >
                    {{ user?.ApplicationUser?.FirstName[0] }} {{ user?.ApplicationUser?.Surname[0] }}
                  </span>
                </div>
                <div
                  v-else-if="j == timeslot[1].length - 1"
                  class="px-1"
                >
                  + {{ timeslot[1].length - 6 }}
                </div>
              </div>
              <font-awesome-icon
                v-else
                title="Unlimited Slots"
                class="mx-2 text-primary"
                :icon="['fas', 'infinity']"
              />
            </div>
            <div style="margin-top:-40px; float:right;width:100%;">
              <font-awesome-icon
                :icon="['fas', 'trash-can-xmark']"
                class="ml-auto text-xl px-2"
                style="float:right;"
                @click="deleteSlot(timeslot)"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="h-full py-2"
        style="background: white !important; margin-bottom: -10px;"
      >
        <div style="font-size: smaller;color: #555;margin-left: 25px;">
          No available slots
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { groupBy } from '@/utils/groupBy'
import { ref, computed } from 'vue'
import { useStatus, useScheduler, useEvent} from '@/pinia'
import { storeToRefs } from 'pinia';

export default {
  name: 'LocationSelectItem',
  props: {
    location: {
      type: Object,
      default: () => {}
    },
    timeslots: {
      type: Array,
      default: () => []
    },
    stageId: { 
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()
    const { deleteTimeSlot, getSchedulerTimeslots, eventStageLocationTimeslots } = useScheduler()
    const eventStore = useEvent()
    const { selectedEvent } = storeToRefs(eventStore)

    const show = ref(true)
    const slots = computed(() =>
        props.timeslots.length === 0 ?
          Array.from(groupBy(eventStageLocationTimeslots[1], timeslot => timeslot.Time)).sort()
        : 
          Array.from(groupBy(props.timeslots, timeslot => timeslot.Time)).sort()
    )

    const checkIfActiveBookingsExist = timeslot => {
      let count = 0
      timeslot[1].forEach(slot => {
        if (slot.GoSchedulerBookings?.filter(booking => !booking.IsDeleted).length > 0) count ++
      })
      return count != 0
    }
    const confirmDeleteTimeslot = async (timeslot) => {
      // need to delete multiple ids as we may have multiple slots at a certain time
      const ids = timeslot[1].map(s => s.GoSchedulerTimeslotId)
      const JSONData = {
        TimeslotIds: ids
      }
      try {
        if ((await deleteTimeSlot(JSONData)) == true) emit('refreshTimeslots')
      } catch (err) {
        console.log(Object.assign({}, err))
        showGenericErrorModal({ showing: true })
      } finally {
        closeGenericConfirmModal()
      }

    }
    const deleteSlot = timeslot => {
      // we want to only allow deleting a slot if there is no current booking
      var activeBookingsExist = checkIfActiveBookingsExist(timeslot)
      if (activeBookingsExist) {
        showGenericErrorModal({ showing: true, errorTitle: 'ACTIVE BOOKINGS', errorMessage: 'You must cancel any active bookings before a slot may be deleted.'})
      } else {
        showGenericConfirmModal({
          showing: true,
          confirmTitle: 'DELETE TIMESLOT',
          confirmMessage: 'Are you sure you want to delete this timeslot?',
          confirmButtonMessage: 'DELETE',
          confirmButtonFunction: () => confirmDeleteTimeslot(timeslot)
        })
      }
    }

    return {
      show,
      slots,
      deleteSlot,
      getSchedulerTimeslots,
      eventStageLocationTimeslots,
      selectedEvent
    }
  }
}
</script>
