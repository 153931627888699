<template>
  <div style="height: 100%">
    <i
      v-if="loading"
      class="fa fa-spinner fa-spin"
    />
    <div
      v-else
      class="d-flex flex-column align-items-center justify-content-center font-weight-bold mx-auto"
      style="height: 100%; font-size: 1.1rem; width: fit-content; position: relative"
      @mouseleave="hovering = false"
    >
      <div
        class="stageItemContainer d-flex flex-column justify-content-center align-items-center px-2 py-1 text-center"
        :class="{'completeColours': stage.SetupComplete && ((stage.IsSelfSchedule && stage.SelfScheduleSetupComplete) || !stage.IsSelfSchedule )}"
        @mouseover="hovering = true"
      >
        <!-- SELF SCHEDULE SETUP -->
        <div
          v-if="stage.IsSelfSchedule && stage.SetupComplete"
          class="self-schedule-button cp"
          @click="goToStageSelfSchedulingSetup()"
        >
          <div
            v-if="!slotCount"
            style="color: var(--pr-color); font-size: 1rem"
            class="text-nowrap mt-3"
            @mouseover="hovering = false"
          >
            <FontAwesomeIcon
              title="Add Some Timeslots"
              style="scale: 1.5"
              class="mr-3 font-weight-bold"
              icon="fa-regular fa-diamond-exclamation"
            />
            <span class="">SET TIMESLOTS</span>
          </div>
          <FloatingOptions
            v-else
            style="transform: translateY(15px)"
            :buttons="setTimeSlotButtonOption"
            :showing="hovering"
            @set_timeslots="goToStageSelfSchedulingSetup()"
          />
        </div>

        <!-- STAGE TILE -->
        <FontAwesomeIcon
          class="m-1 mr-2"
          :icon="getIconFromStageType(stage.StageType)"
        />
        <span>{{ stage.RoleWorkflowStageName }}</span>
        <FontAwesomeIcon
          v-if="hovering"
          size="sm"
          title="Admin Stage Settings"
          class="cp mt-2 editRefAndName"
          icon="fa-solid fa-pen"
          @click="launchGenericInsertMultipleModal(stage)"
        />
      </div>
      <!-- GO TO SETUP OPTIONS -->
      <div class="setupOptions">
        <!-- IF THE STAGE IS NOT SETUP THEN THEY ONLY HAVE ONE OPTION -->
        <div
          v-if="!stage.SetupComplete"
          style="transform:translateY(-60px)"
          class="d-flex align-items-center cp text-nowrap justify-content-center"
          @click="goToStageSetup()"
        >
          <FontAwesomeIcon
            title="Requires Setup"
            style="scale: 1.6"
            class="mr-3 font-weight-bold"
            icon="fa-regular fa-diamond-exclamation"
          />
          <span style="font-size: 0.9rem">REQUIRES SETUP</span>
        </div>
        <!-- IF THE STAGE IS SETUP THEN THEY HAVE MULTIPLE OPTIONS -->
        <SetupCompleteOptions
          v-else
          style="transform:translateY(-25px)"
          :stage="stage"
          :showSetupOptions="hovering"
          @stageSetup="goToStageSetup()"
        />
      </div>
      <EditStageItemModal />

      <GenericInsertMultipleTextInputsModal
        @textValues="editStageValues"/>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { useWorkflow, useStatus, useScheduler } from '@/pinia'
import { storeToRefs } from 'pinia'
import GET_ICON_FROM_STAGE_TYPE_ENUM from '@/utils/getFAIconCodeFromWorkflowStageTypeEnum.js'
import roleV2Service from '@/api/services/roleV2.service'

import SetupCompleteOptions from './workflowBuilderSetupCompleteOptions.vue'
import EditStageItemModal from './EditStageItemModal/editStageItemModal.vue'
import GenericInsertMultipleTextInputsModal from '@/components/genericModals/insertMultipleTextBoxModal.vue'
import FloatingOptions from '@/components/UI/floatingOptions.vue'

export default {
  name: 'StageSetup',
  components: { SetupCompleteOptions, EditStageItemModal, GenericInsertMultipleTextInputsModal, FloatingOptions },
  props: {
    stage: {
      type: Object,
      default: () => {}
    },
    hideStageSetup: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const workflowStore = useWorkflow()
    const { role, currentWorkflow, selectedWorkflowStage } = storeToRefs(workflowStore)

    const statusStore = useStatus()
    const { insertMultipleTextBoxGenericModal, textValues, multipleTextBoxesInputArray } = storeToRefs(statusStore)
    const { showGenericErrorModal, openInsertTextGenericModal } = useStatus()

    const { getSchedulerTimeslotCount } = useScheduler()

    const hovering = ref(false)
    const loading = ref(false)
    const slotCount = ref(0)

    const load = async () => {
      if (props.stage.IsSelfSchedule && props.stage.SetupComplete) {
        loading.value = true
        slotCount.value = await getSchedulerTimeslotCount(props.stage.RoleWorkflowStageId)
        loading.value = false
      }
    }

    load()

    // switching between roles on left panel needs to reset this value
    const isSelfSchedule = computed(() => props.stage.IsSelfSchedule)
    watch(isSelfSchedule, () => {
      load()
    })

    const setTimeSlotButtonOption = ref([
      {
        buttonText: 'TIMESLOTS',
        outerButtonClass: 'text-nowrap',
        iconClass: 'fa fa-cogs',
        actionEmitName: 'set_timeslots'
      }
    ])

    return {
      slotCount,
      setTimeSlotButtonOption,
      role,
      hovering,
      showGenericErrorModal,
      openInsertTextGenericModal,
      currentWorkflow,
      loading,
      insertMultipleTextBoxGenericModal,
      textValues,
      multipleTextBoxesInputArray,
      selectedWorkflowStage
    }
  },
  methods: {
    getIconFromStageType (stageType) {
      return GET_ICON_FROM_STAGE_TYPE_ENUM(stageType)
    },
    removeStage () {
      this.$emit('removeStage')
    },
    goToStageSelfSchedulingSetup () {
      this.selectedWorkflowStage = this.stage
      this.$router.push({ name: 'scheduler_slots', params: { roleWorkflowStageId: this.stage.RoleWorkflowStageId } })
    },
    async getRoleWorkflowNoSetupDetails () {
      try {
        const res = await roleV2Service.getRoleWorkflowNoSetupDetails(this.role.RoleWorkflowId)
        if (res.data) {
          this.currentWorkflow = res.data
        } else { this.$emit('noWorkflow') }
      } catch (err) {
        this.showGenericErrorModal()
      }
    },
    launchGenericInsertMultipleModal (stage) {
      this.textValues = {
        stageName: stage.RoleWorkflowStageName,
        externalStageRef: stage.RoleWorkflowStageExternalReferenceId || undefined,
      }
      this.multipleTextBoxesInputArray = [
        { inputLabel: 'Stage Name', required: true, iconCode: 'fa-solid fa-pen', textValuesKeyName: 'stageName', maxLength: 75 },
        { inputLabel: 'External Stage Reference', required: false, iconCode: 'fa-solid fa-pen', textValuesKeyName: 'externalStageRef', maxLength: 75 },
      ]
      this.insertMultipleTextBoxGenericModal = {
        showing: true,
        modalTitle: 'ADMIN STAGE SETTINGS'
      }
    },
    async editStageValues () {
      try {
        const JSONData = {
          RoleWorkflowStageName: this.textValues.stageName,
          RoleWorkflowStageExternalReferenceId: this.textValues.externalStageRef,
          RoleWorkflowStageId: this.stage.RoleWorkflowStageId,
        }
        await roleV2Service.editRoleWorkflowStage(JSONData)
        await this.getRoleWorkflowNoSetupDetails()
      } catch (err) {
        console.log(Object.assign({}, err))
      }
    },
    goToStageSetup () {
      let modalMessage = ''
      if (this.hideStageSetup) {
        modalMessage = 'Please complete the setup of all previous stages before continuing with this stages setup.'
      } else if (this.role.Step < 4) {
        modalMessage = 'Please complete the initial role setup before continuing with your workflow configuration.'
      }
      if (modalMessage != '') {
        this.showGenericErrorModal({
          showing: true,
          errorMessage: modalMessage,
          errorTitle: 'Complete Setup'
        })
      } else {
        // no issues preventing going to setup
        this.$emit('goToStageSetup')
      }
    }
  }
}
</script>

<style scoped>
.stageItemContainer {
  border: solid var(--pr-color) 6px;
  border-radius: 20px;
  background: #fff;
  color: var(--pr-color);
  position: relative;
  min-width: fit-content;
  width: 150px;
  height: 200px;
  /* z-index: 4; */
}
.stageItemContainer.completeColours {
  background: var(--pr-color);
  color: #fff;
  font-weight: bold;
}
.setupOptions {
  width: 150px;
  cursor: pointer;
  position: absolute;
  bottom: -50px;
  left: 0;
  color: var(--pr-color);
}
.deleteIcon.showOptions, .stageRequiresSetupIcon.showOptions {
  opacity: 1;
  transition: all .5s, opacity .5s .1s;
  transform: translateX(0);
  z-index: 1;
}
.self-schedule-button {
  position: absolute;
  top: -60px;
}
.editRefAndName {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.editRefAndName:hover {
  scale: 1.2;
  transition: 0.3s ease;
}
@media (max-width: 576px) {
  .setupOptions {
    width: 150px;
    cursor: pointer;
    position: absolute;
    bottom: -63px;
    left: 0;
    color: var(--pr-color);
  }
}
</style>
