<template>
  <div>
    <mdb-modal
      id="createNewVideoIntroModalShowing"
      :show="createNewVideoIntroModalShowing"
      @close="$emit('createNewVideoIntroModalShowing', false)"
    >
      <mdb-modal-header>
        <b-row style="width: 90%; margin: 0px 30px;" align-h="between">
          <div
            class="modal-title-container m-auto m-sm-0"
            :class="{'modal-title-underlined' : videoModalTab == 'record'}"
            style="font-size:smaller;margin-left:-20px !important;"
          >
            <h6 :class="{'grey-text' : videoModalTab != 'record' }" @click="videoModalTab = 'record'">
              <span class="d-none d-sm-block">RECORD VIDEO</span>
              <span class="d-block d-sm-none" style="font-size:12px;">RECORD <i class="fa fa-video" /></span>
            </h6>
          </div>
          <div
            class="modal-title-container m-auto m-sm-0"
            :class="{'modal-title-underlined' : videoModalTab == 'upload'}"
            style="font-size:smaller;margin-left:-20px !important;"
          >
            <h6 :class="{'grey-text' : videoModalTab != 'upload' }" @click="videoModalTab = 'upload'">
              <span class="d-none d-sm-block">UPLOAD VIDEO</span>
              <span class="d-block d-sm-none" style="font-size:12px;">UPLOAD <i class="fa fa-video" /></span>
            </h6>
          </div>
        </b-row>
      </mdb-modal-header>

      <div v-if="videoModalTab == 'record' && page == 1">
        <SlideFade>
          <RecordIntroTab
            @uploadedVideoId="uploadedVideoId = $event"
            @handleRecordedVideo="handleRecordedQuestion"
            @next="page = 2"
          />
        </SlideFade>
      </div>
      <div v-if="videoModalTab == 'upload' && page == 1">
        <SlideFade>
          <UploadIntroTab
            @uploadedVideoId="uploadedVideoId = $event"
            @file="file = $event"
            @next="page = 2"
            @uploadVideo="handleVideoUpload"
          />
        </SlideFade>
      </div>

      <!-- video details -->
      <div v-if="page == 2" style="height:fit-content; min-height:200px">
        <SlideFade>
          <div v-if="showPageTwo">
            <mdb-modal-body class="px-xs-2 py-xs-0 px-sm-4 py-sm-4 text-center">
              <div class="d-flex align-items-center" style="height:40px">
                <FontAwesomeIcon icon="fa-solid fa-pen-line" class="d-inline mr-3" />
                <mdb-input
                  v-model="videoIntroName"
                  type="text"
                  label="Name of the Video Intro"
                  size="sm"
                  class="m-0"
                  maxlength="40"
                  style="height:40px;width:100%;"
                />
              </div>
              <div class="d-flex align-items-center mt-2">
                <FontAwesomeIcon
                  icon="fa-solid fa-video"
                  class="d-inline mr-3"
                  style="transform:translateY(20px)"
                />
                <mdb-select
                  v-model="videoBrand"
                  label="Video Brand"
                  size="sm"
                  class="d-inline m-0"
                  style="height:50px;width:100%;"
                />
                <mdb-icon
                  v-if="brandsLoading"
                  icon="fa fa-pulse fa-spinner"
                  class="ml-3 mt-4"
                  style="margin-top:40px !important;"
                />
              </div>
              <div class="d-flex align-items-center" style="margin-top:40px">
                <FontAwesomeIcon
                  v-if="shareContactDetails"
                  icon="fa-solid fa-bullhorn"
                  class="d-inline mr-3"
                />
                <FontAwesomeIcon
                  v-if="!shareContactDetails"
                  icon="fa-solid fa-lock-keyhole"
                  class="d-inline mr-3"
                />
                <mdb-switch
                  v-model="shareContactDetails"
                  style="transform:scale(0.9)"
                  off-label=""
                  on-label=""
                  class="m-0 mt-1 mr-2"
                />
                <div style="font-size:0.8rem;color:#757575">
                  {{ shareContactDetails ? "Share" : "Hide" }} My Contact Details
                </div>
              </div>
            </mdb-modal-body>
            <mdb-modal-footer class="d-flex justify-content-between">
              <mdb-btn size="sm" @click="page = 1">
                BACK
              </mdb-btn>
              <mdb-btn
                v-if="!loading"
                style="width:90px;"
                size="sm"
                :disabled="uploadedVideoId == 0 || videoBrand.length === 0 || videoIntroName === ''"
                @click="createIntro()"
              >
                FINISH
              </mdb-btn>
              <mdb-btn
                v-else
                size="sm"
                style="width:90px;"
              >
                <mdb-icon icon="fa fa-pulse fa-spinner" />
              </mdb-btn>
            </mdb-modal-footer>
          </div>
        </SlideFade>
      </div>
    </mdb-modal>
  </div>
</template>

<script>
import { useVideoIntro, useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import accountService from '@/api/services/account.service'
import videoIntroService from '@/api/services/videointro.service'

import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'
import RecordIntroTab from './recordIntroTab.vue'
import UploadIntroTab from './uploadIntroTab.vue'

export default {
  name: 'VideoIntroModal',
  components: {
    SlideFade,
    RecordIntroTab,
    UploadIntroTab
  },
  props: {
    createNewVideoIntroModalShowing: Boolean
  },
  setup () {
    const userStore = useUserData()
    const { userData, authData } = storeToRefs(userStore)

    const { addOrUpdateIntro, switchArchived } = useVideoIntro()

    const { showGenericErrorModal } = useStatus()

    return {
      userData,
      authData,
      addOrUpdateIntro,
      switchArchived,
      showGenericErrorModal
    }
  },
  data () {
    return {
      page: 1,
      file: [],
      videoModalTab: 'record',
      shareContactDetails: true,
      videoBrand: [],
      videoIntroName: '',
      showPageTwo: false,
      uploadedVideoId: 0,
      brandsLoading: false,
      loading: false
    }
  },
  watch: {
    page () {
      this.showPageTwo = false
      if (this.page === 2) {
        setTimeout(() => {
          this.showPageTwo = true
          this.getBranding()
        }, 200)
      }
    },
    createNewVideoIntroModalShowing () {
      if (this.createNewVideoIntroModalShowing) {
        this.page = 1
        this.videoBrand = []
        this.videoIntroName = ''
      }
    }
  },
  methods: {
    goBack () {
      this.page = 1
      this.uploadedVideoId = 0
    },
    async createIntro () {
      this.loading = true
      const formData = new FormData()
      const selectedBrandId = this.videoBrand.filter(br => br.selected)[0].value
      formData.append('uploadedVideoId', this.uploadedVideoId)
      formData.append('videoIntroName', this.videoIntroName)
      formData.append('selectedBrandId', selectedBrandId)
      formData.append('shareContactDetails', this.shareContactDetails)
      try {
        const res = await videoIntroService.createVideoIntro(formData)
        this.addOrUpdateIntro(res.data)
        this.$emit('createNewVideoIntroModalShowing', false)
      } catch (err) {
        this.showGenericErrorModal({ showing: true, errorTitle: 'Error Uploading File', errorMessage: 'Something went wrong uploading your Shine Intro. Try refreshing and uploading again.' })
      }
      this.loading = false
    },
    async getBranding () {
      this.brandsLoading = true
      try {
        const res = await accountService.getBranding()
        this.videoBrand = res.data.map(br => br.Default === true ? { value: br.BrandingId, text: br.Name, selected: true } : { value: br.BrandingId, text: br.Name, selected: false })
      } catch (err) {
        //?
      }
      this.brandsLoading = false
    }
  }
}
</script>

<style lang="scss">
@media (max-width: 576px) {
  #createNewVideoIntroModalShowing .modal-dialog .modal-content {
    margin:5px !important;
    width:calc(100vw - 10px) !important;
    height: calc(100vh - 10px) !important;
  }
  #createNewVideoIntroModalShowing .modal-dialog {
    margin:0px !important;
  }
}
</style>
