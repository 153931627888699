<template>
  <div
    class="text-primary mt-4"
    style="color:#555 !important;font-size:small;margin-bottom:10px;"
  >
    <div
      style="font-size: .9rem"
      class="text-left">
      <span class="font-weight-bold">Restrict Slot Availability to Selected Days </span>
    </div>
    <div
      :key="key"
      class="flex text-primary"
      style="margin-top:20px;"
      :class="mobileView ? 'flex-col' : 'flex-row'"
    >
      <div
        v-for="(day) in days"
        :key="day.value"
        class="flex flex-row items-center"
        style="margin-left:0px;font-size: small;"
        @click="toggleDay(day.value)"
      >
        <span
          style="font-size: 0.7rem"
          class="cursor-pointer"
        > {{ day.text }} </span>
        <mdb-input
          v-model="daySelected[day.value]"
          style="margin-left: 6px !important;margin-right: 0px !important;"
          type="checkbox"
          class="flex m-0 p-0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useMobile } from '@/composables/useMobile'
import { useScheduler } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  setup (props, { emit }) {

    const { mobileView } = useMobile()
    const schedulerStore = useScheduler()
    const { daySelected } = storeToRefs(schedulerStore)

    const days = [
      {
        text: 'MON',
        value: 1
      },
      {
        text: 'TUE',
        value: 2
      },
      {
        text: 'WED',
        value: 3
      },
      {
        text: 'THU',
        value: 4
      },
      {
        text: 'FRI',
        value: 5
      },
      {
        text: 'SAT',
        value: 6
      },
      {
        text: 'SUN',
        value: 0
      }
    ]

    const key = ref(0)
    const daysOfWeek = ref([1, 2, 3, 4, 5])

    const toggleDay = (i) => {
      daySelected.value[i] = !daySelected.value[i]
      getDays()
      emit('toggleDay', daysOfWeek.value)
      key.value = key.value + 1
    }

    const getDays = () => {
      daysOfWeek.value = []
      for (let index = 0; index < daySelected.value.length; index++) {
        const element = daySelected.value[index];
        if (element === true) daysOfWeek.value.push(index)
      }
    }

    return {
      days,
      daySelected,
      key,
      toggleDay,
      mobileView
    }
  }
}
</script>
