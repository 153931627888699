<template>
  <div>
    <mdb-modal
      id="sharingModalShowing"
      :show="sharingModalShowing"
      size="lg"
      @close="$emit('closeSharingModal')"
    >
      <mdb-modal-header>
        <h5>SHARE</h5>
      </mdb-modal-header>
      <div class="video-modal-body">
        <div style="width:100%;height:115px;">
          <!--hidden for mobile need to work out mobile based toggle between each element of sharing setup-->
          <div class="d-flex justify-content-center border-center" style="justify-content: space-between !important;position: relative;">
            <div class="mx-2 mx-md-4 mt-2 mt-md-4" style="text-align: -webkit-center;">
              <a
                :class="{ 'btn-blank-primary': sharingStep === 0, 'btn-blank-outline': sharingStep != 0 }"
                type="button"
                class="white-text d-flex justify-content-center align-items-center btn-floating btn-sm ripple-parent"
                @click="sharingStep = 0"
              >
                <i
                  v-if="tickedStep > -1"
                  style="cursor:pointer;"
                  class="fas fa-check primary-colour"
                />
              </a>
              <span class="d-none d-sm-block" style="font-size:12px;color:black;text-decoration:underline;">Candidates</span>
              <i style="cursor:pointer;color:black;" class="fas fa-users d-sm-none" />
            </div>
            <div class="mx-2 mx-md-4 mt-2 mt-md-4" style="text-align: -webkit-center;">
              <a
                :class="{ 'btn-blank-primary': sharingStep === 1, 'btn-blank-outline': sharingStep != 1 }"
                type="button"
                class="white-text d-flex justify-content-center align-items-center btn-floating btn-sm ripple-parent"
                @click="tickedStep > -1 ? sharingStep = 1 : null"
              >
                <i
                  v-if="tickedStep > 0"
                  style="cursor:pointer;"
                  class="fas fa-check primary-colour"
                />
              </a>
              <span class="d-none d-sm-block" style="font-size:12px;color:black;text-decoration:underline;">Share With</span>
              <i style="cursor:pointer;color:black;" class="fas fa-user-plus d-sm-none" />
            </div>
            <div class="mx-2 mx-md-4 mt-2 mt-md-4" style="text-align: -webkit-center;">
              <a
                :class="{ 'btn-blank-primary': sharingStep === 2, 'btn-blank-outline': sharingStep != 2 }"
                type="button"
                class="white-text d-flex justify-content-center align-items-center btn-floating btn-sm ripple-parent"
                @click="tickedStep > 0 ? sharingStep = 2 : null"
              >
                <i
                  v-if="tickedStep > 1"
                  style="cursor:pointer;"
                  class="fas fa-check primary-colour"
                />
              </a>
              <span class="d-none d-sm-block" style="font-size:12px;color:black;text-decoration:underline;">Information</span>
              <i style="cursor:pointer;color:black;" class="fas fa-info-circle d-sm-none" />
            </div>
            <div class="mx-2 mx-md-4 mt-2 mt-md-4" style="text-align: -webkit-center;">
              <a
                :class="{ 'btn-blank-primary': sharingStep === 3, 'btn-blank-outline': sharingStep != 3 }"
                type="button"
                class="white-text d-flex justify-content-center align-items-center btn-floating btn-sm ripple-parent"
                @click="tickedStep > 1 ? sharingStep = 3 : null"
              >
                <i
                  v-if="tickedStep > 2"
                  style="cursor:pointer;"
                  class="fas fa-check primary-colour"
                />
              </a>
              <span class="d-none d-sm-block" style="font-size:12px;color:black;text-decoration:underline;">Settings</span>
              <i style="cursor:pointer;color:black;" class="fas fa-sliders-h  d-sm-none" />
            </div>
          </div>

          <!--timeline line-->
          <div style="background:#e1e1e1;height:1px;width:calc(100% - 80px);transform:translateY(-94px)" class="d-none d-sm-block mx-auto mt-5" />
          <div style="background:#e1e1e1;height:1px;width:calc(100% - 80px);transform:translateY(-100px)" class="d-sm-none mx-auto mt-5" />
        </div>

        <!--candidates page-->
        <div v-if="sharingStep == 0">
          <SlideFade>
            <CandidatesPage
              @sharingPageChange="sharingPageChange($event)"
            />
          </SlideFade>
          <b-row
            align-v="center"
            :class="{ 'nxtBtn': mobileView }"
            align-h="end"
            no-gutters
            style="background:#C0C0C0;height:60px;margin-top:-25px;position: inherit;bottom: 0;width: 100%;"
          >
            <mdb-btn
              type="reset"
              class="btn btn-sm p-0"
              size="md"
              :disabled="candidatesAddedToShare.length == 0"
              style="width:80px; height:40px;"
              @click="sharingPageChange(1)"
            >
              NEXT
            </mdb-btn>
          </b-row>
        </div>

        <!--share with page-->
        <div v-if="sharingStep == 1">
          <SlideFade>
            <ShareWithPage />
          </SlideFade>
          <b-row
            align-v="center"
            :class="{ 'nxtBtn': mobileView }"
            align-h="between"
            no-gutters
            style="background:#C0C0C0;height:60px;margin-top:-25px;position: inherit;bottom: 0;width: 100%;"
          >
            <mdb-btn
              class="btn btn-sm p-0 btn-outline"
              size="md"
              style="float:left;width:80px; height:40px;"
              @click="sharingPageChange(0)"
            >
              BACK
            </mdb-btn>
            <mdb-btn
              type="reset"
              class="btn btn-sm p-0"
              :disabled="sharingWithPage.emailSubject == '' || sharingWithPage.emailContent == '' || (sharingWithPage.standardShareWith.length == 0 && sharingWithPage.blindShareWith.length == 0)"
              size="md"
              style="width:80px; height:40px;"
              @click="sharingPageChange(2)"
            >
              NEXT
            </mdb-btn>
          </b-row>
        </div>

        <!--information page-->
        <div v-if="sharingStep == 2">
          <SlideFade>
            <InformationPage
              :viewing-cand-index="viewingCandIndex"
              @candidateInfoIncomplete="candidateInfoIncomplete = $event"
              @shareIntroIncomplete="shareIntroIncomplete = $event"
              @infoPageComplete="informationPageIncomplete = $event"
            />
          </SlideFade>
          <b-row
            align-v="center"
            :class="{ 'nxtBtn': mobileView }"
            align-h="between"
            no-gutters
            style="background:#C0C0C0;height:60px;margin-top:-25px;position: inherit;bottom: 0;width: 100%;"
          >
            <mdb-btn
              class="btn btn-sm p-0 btn-outline"
              size="md"
              style="float:left;width:80px; height:40px;"
              @click="sharingPageChange(1)"
            >
              BACK
            </mdb-btn>
            <mdb-btn
              type="reset"
              :disabled="shareIntroIncomplete"
              class="btn btn-sm p-0"
              size="md"
              style="width:80px; height:40px;"
              @click="sharingPageChange(3)"
            >
              NEXT
            </mdb-btn>
          </b-row>
        </div>

        <!--settings page-->
        <div v-if="sharingStep == 3">
          <SlideFade>
            <SettingsPage
              @settingsLoading="settingsLoading = $event"
              @allowedDomain="shareSettings.allowedDomain = $event"
              @shareName="shareSettings.shareName = $event"
              @enableTrackingNotifications="shareSettings.enableTrackingNotifications = $event"
              @enableDomainAuthSharing="shareSettings.enableDomainAuthSharing = $event"
              @includeCVInfo="shareSettings.includeCVInfo = $event"
              @includeBioInfo="shareSettings.includeBioInfo = $event"
              @includeOtherFiles="shareSettings.includeOtherFiles = $event"
              @includeEducationInfo="shareSettings.includeEducationInfo = $event"
              @includeSalaryInfo="shareSettings.includeSalaryInfo = $event"
              @includeContactInfo="shareSettings.includeContactInfo = $event"
              @includePersonalInfo="shareSettings.includePersonalInfo = $event"
              @includeLinkedIn="shareSettings.includeLinkedIn = $event"
              @requestFeedback="shareSettings.requestFeedback = $event"
            />
          </SlideFade>
          <b-row
            v-show="settingsLoading === false"
            align-v="center"
            :class="{ 'nxtBtn': mobileView }"
            align-h="between"
            no-gutters
            style="background:#C0C0C0;height:60px;margin-top:-25px;position: inherit;bottom: 0;width: 100%;"
          >
            <mdb-btn
              class="btn btn-sm p-0 btn-outline"
              size="md"
              style="float:left;width:80px; height:40px;"
              @click="sharingPageChange(2)"
            >
              BACK
            </mdb-btn>
            <mdb-btn
              :disabled="settingsLoading"
              class="btn btn-sm p-0"
              size="md"
              style="width:80px; height:40px;"
              @click="showConfirmShareNowModal()"
            >
              SHARE NOW
            </mdb-btn>
          </b-row>
        </div>
      </div>
    </mdb-modal>
  </div>
</template>

<script>
import { useUserData, useCandidate, useStatus } from '@/pinia'
import { storeToRefs }  from 'pinia'
import { useMobile } from '@/composables/useMobile'
import candidateV2Service from '@/api/services/candidateV2.service'
import { ENVIRONMENT } from '@/common/config.js'

import CandidatesPage from './Pages/candidates.vue'
import InformationPage from './Pages/information.vue'
import SettingsPage from './Pages/settings.vue'
import ShareWithPage from './Pages/shareWith.vue'
import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'

export default {
  name: 'CandidateShareModal',
  components: {
    CandidatesPage,
    InformationPage,
    SettingsPage,
    ShareWithPage,
    SlideFade
  },
  props: {
    sharingModalShowing: Boolean
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const candidateStore = useCandidate()
    const { resetShareModal } = useCandidate()
    const {
      candidates,
      candidatesAddedToShare,
      sharingWithPage,
      redirectAfterSave,
      candidateToEdit,
      sharingVideoIntro,
      sharingTextIntro
    } = storeToRefs(candidateStore)

    const { showGenericErrorModal, closeGenericConfirmModal, showGenericConfirmModal } = useStatus()

    const { mobileView } = useMobile()

    return {
      authData,
      candidates,
      candidatesAddedToShare,
      sharingWithPage,
      redirectAfterSave,
      resetShareModal,
      candidateToEdit,
      sharingVideoIntro,
      sharingTextIntro,
      mobileView,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal
    }
  },
  data () {
    return {
      informationPageIncomplete: true,
      loading: { isLoading: false, message: '' },
      sharingStep: 0,
      tickedStep: -1,
      viewingCandIndex: 0,
      shareSettings: {
        shareName: '',
        enableTrackingNotifications: false,
        enableDomainAuthSharing: false,
        includeCVInfo: true,
        includeBioInfo: true,
        includeOtherFiles: true,
        includeEducationInfo: true,
        includeSalaryInfo: true,
        includeContactInfo: true,
        includePersonalInfo: true,
        includeLinkedIn: true,
        requestFeedback: false,
        allowedDomain: ''
      },
      settingsLoading: true,
      shareIntroIncomplete: true,
      candidateInfoIncomplete: true
    }
  },
  computed: {
    noOfCands () {
      return this.candidatesAddedToShare.length
    }
  },
  watch: {
    // will reset progress if all candidates are removed
    candidatesAddedToShare: function () {
      if (this.candidatesAddedToShare.length === 0) { this.sharingStep = 0; this.tickedStep = -1 }
    },
    // will reset progress if all information is removed
    sharingWithPage: function () {
      if (this.sharingWithPage.emailSubject === '' || this.sharingWithPage.emailContent === '' || this.sharingWithPage.standardShareWith.length === 0) { this.sharingStep = 1; this.tickedStep = 0 }
    }
  },
  mounted () {
    // handle data changed if redirect
    if (this.redirectAfterSave) {
      this.tickedStep = -1
      this.sharingStep = 0
      this.candidatesAddedToShare = this.candidatesAddedToShare.map(c => c.CandidateId === this.candidateToEdit.CandidateId ? { ...this.candidateToEdit } : { ...c })
      this.viewingCandIndex = this.candidatesAddedToShare.findIndex(c => c.CandidateId === this.candidateToEdit.CandidateId)
    }
    this.redirectAfterSave = false
  },
  methods: {
    sharingPageChange (value) {
      this.sharingStep = value
      if (value > this.tickedStep) {
        this.tickedStep = value - 1
      }
    },
    showConfirmShareNowModal () {
      const confirmMessage = `Are you sure you want to process sharing ${this.noOfCands > 1 ? 'these': 'this' } candidate${this.noOfCands > 1 ? 's': '' } with the selected recipient(s)?`
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'SHARE NOW',
        confirmMessage,
        confirmButtonMessage: 'CONFIRM',
        confirmButtonFunction: () => this.shareNow()
      })
    },
    async shareNow () {
      this.loading = { isLoading: true, message: 'Deleting candidate question' }
      const salaryAndSummary = {}
      for (const i in this.candidatesAddedToShare) {
        const cand = this.candidatesAddedToShare[i]
        const id = cand.CandidateId
        salaryAndSummary[id] = [cand.SalaryExpectation, cand.CandidateSummary]
      }
      const JSONData = {
        SalaryAndSummary: JSON.stringify(salaryAndSummary),
        CandidateIds: JSON.stringify(this.candidatesAddedToShare.map(c => c.CandidateId)),
        EmailContent: this.sharingWithPage.emailContent,
        EmailSubject: this.sharingWithPage.emailSubject,
        ShareWithList: JSON.stringify(this.sharingWithPage.standardShareWith),
        BlindShareWithList: JSON.stringify(this.sharingWithPage.blindShareWith),
        IntroVideoFileName: this.sharingVideoIntro.length > 0 ? this.sharingVideoIntro[0].FileName : '',
        IntroText: this.sharingTextIntro,
        EnableDomainAuthSharing: this.shareSettings.enableDomainAuthSharing,
        EnableTrackingNotifications: this.shareSettings.enableTrackingNotifications,
        IncludeBioInfo: this.shareSettings.includeBioInfo,
        IncludeCV: this.shareSettings.includeCVInfo,
        IncludeLinkedIn: this.shareSettings.includeLinkedIn,
        IncludeOtherFiles: this.shareSettings.includeOtherFiles,
        IncludeEducationInfo: this.shareSettings.includeEducationInfo,
        IncludeContactInfo: this.shareSettings.includeContactInfo,
        IncludeSalaryInfo: this.shareSettings.includeSalaryInfo,
        IncludePersonalInfo: this.shareSettings.includePersonalInfo,
        AllowedDomain: this.shareSettings.allowedDomain,
        RequestFeedback: this.shareSettings.requestFeedback,
        ShareName: this.shareSettings.shareName,
        ShareType: 0
      }
      try {
        await candidateV2Service.shareCandidates(JSONData)
        this.resetShareModal()
        this.$nextTick(() => {
          this.tickedStep = -1
          this.sharingStep = 0
        })
        this.$emit('closeSharingModal')
      } catch (err) {
        console.log(Object.assign({}, err))
        this.showGenericErrorModal({ showing: true })
      }
      this.closeGenericConfirmModal()
      this.loading = { isLoading: false, message: '' }
    }
  }
}
</script>

<style lang="scss" scoped>
  .nxtBtn {
    height:65px !important;
    margin-top:0px !important;
  }
  .fullH {
    height: 100vh !important;
  }
</style>
