<template>
  <div>
    <div
      class="min-h-full py-3 py-md-4 w-1/1 px-3 text-black rounded"
      style="background:rgb(225, 225, 225);min-height:400px;transition: all ease 0.5s ;height:calc(100vh - 315px);margin-bottom:65px;overflow-x:hidden;"
    >
      <Draggable
        v-model="convertedCandidateQuestions"
        style="cursor:move;transition: all ease 0.5s ;"
        :options="{disabled : mobileView}"
        @start="drag=true"
        @end="handleMove"
      >
        <div
          v-for="(q, i) in convertedCandidateQuestions"
          :key="i"
          no-gutters
          class=""
        >
          <QuestionSet
            v-if="q.QuestionSetId !== undefined"
            :is-video-c-v="true"
            :deleting-set-id="deletingSetId"
            :question-set="q"
            @deleteSet="deleteSet($event)"
            @openDeleteQuestionModal="openDeleteQuestionModal($event)"
            @openPlayVideoModalCapturing="openPlayVideoModal($event);capturing = true;"
            @openPlayVideoModal="openPlayVideoModal($event);"
            @openResetQuestionModal="openResetQuestionModal($event)"
            @editQuestion="editQuestion($event)"
          />

          <b-row
            v-else
            no-gutters
            style="width:100%;"
            class="text-bold text-black rounded text-center border-2 border-gray-400  bg-gray-100 px-3 py-2 px-md-4 my-1 flex-nowrap"
          >
            <b-col
              class="align-self-center mr-3"
              style="text-align: left;font-size: 1rem;font-weight: bold;width:50px;flex-grow:0;margin-top:10px;"
            >
              <div
                v-if="q.AnswerUrl == ''"
                class="navIcon"
                title="This question has been not answered yet"
              >
                <i class="col-span-1 fa fa-2x text-left fa-times" style="font-size:1.2rem;color:#E1E1E1" />
              </div>
              <div
                v-else
                class="navIcon"
                title="This question has been answered"
              >
                <i class="col-span-1 fa fa-2x text-left fa-check" style="font-size:1.2rem;" />
              </div>
            </b-col>
            <b-col class="align-self-center twoLinedEllipsis" style="text-align:left;font-size:smaller;">
              {{ q.Description }}
            </b-col>
            <b-col class="align-self-center font-extrabold" style="font-size:smaller;width:50px;">
              <div style="font-size:smaller;width:50px;" class="ml-auto">
                {{ readableTimeLimit(q.TimeLimit) }}
              </div> <div style="font-size:smaller;width:50px;" class="ml-auto">
                mm:ss
              </div>
            </b-col>
            <div class="flex-grow-0 mr-3 d-flex align-items-center">
              <div v-if="q.AnswerUrl !== ''">
                <mdb-btn
                  class="btn"
                  size="sm"
                  style="padding: 0.5rem 1.2rem;font-size:0.8rem !important; letter-spacing:1px"
                  @click="openPlayVideoModal(q)"
                >
                  PLAY
                </mdb-btn>
              </div>
              <div style="width: 10px;">
                <VCVPopover
                  :question="q"
                  @openDeleteQuestionModal="openDeleteQuestionModal($event)"
                  @openPlayVideoModalCapturing="openPlayVideoModal($event);capturing = true;"
                  @openPlayVideoModal="openPlayVideoModal($event);"
                  @openResetQuestionModal="openResetQuestionModal($event)"
                  @editQuestion="editQuestion($event)"
                />
              </div>
            </div>
          </b-row>
        </div>
      </Draggable>
      <div
        v-if="!loading.isLoading"
        slot="footer"
        class="rounded text-center border-2 border-gray-400 border-dashed p-2"
        style="font-size:1rem;font-weight:bold;padding:20px !important;cursor:pointer;"
        @click="openAddQuestionModal()"
      >
        Add Question
      </div>
      <div
        v-else
        slot="footer"
        class="rounded text-center border-2 border-gray-400 border-dashed p-3"
        style="font-size:1rem;font-weight:bold;padding:20px !important;"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </div>
      <div style="height: 65px;" />
      <div ref="scrollToDiv" style="height: 1px;" />

      <!--add/edit question modal-->
      <AddEditVideoModal
        :question="question"
        :editing-question="editingQuestion"
        @addCandidateToRecentCandidates="$emit('addCandidateToRecentCandidates', $event)"
        @closeAddEditQuestionModal="closeAddEditQuestionModal"
      />

      <!--view video modal-->
      <PlayVideoModal
        :play-video-modal-showing="playVideoModalShowing"
        :capturing="capturing"
        :question-to-play="questionToPlay[0]"
        @closePlayVideoModal="closePlayVideoModal"
      />

      <!--confirm delete modal-->
      <mdb-modal
        v-if="deleteQuestionModalShowing"
        id="deleteQuestionModal"
        @close="deleteQuestionModalShowing = false"
      >
        <mdb-modal-header>
          <mdb-modal-title>CONFIRM DELETE</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body>
          <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
            Are you sure you want to delete this question? <span class="font-weight-bold">{{ questionToDelete.AnswerUrl !== '' ? 'Note: This will also delete the candidates answer.' : '' }}</span>
          </p>
        </mdb-modal-body>
        <mdb-modal-footer class="justify-content-between">
          <b-row
            align-h="end"
            align-v="center"
            style="width:100%"
          >
            <mdb-btn
              v-if="!loading.isLoading"
              style="width:130px;font-size:0.7rem;"
              class="p-2"
              @click="deleteQuestion()"
            >
              DELETE
            </mdb-btn>
            <mdb-btn
              v-else
              style="width:130px;"
              class="p-2"
            >
              <mdb-icon icon="fa fa-pulse fa-spinner" />
            </mdb-btn>
          </b-row>
        </mdb-modal-footer>
      </mdb-modal>

      <!--confirm reset modal-->
      <mdb-modal
        v-if="resetQuestionModalShowing"
        id="resetQuestionModal"
        @close="resetQuestionModalShowing = false"
      >
        <mdb-modal-header>
          <mdb-modal-title>CONFIRM RESET</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body>
          <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
            Are you sure you want to reset the answer to this question?
          </p>
        </mdb-modal-body>
        <mdb-modal-footer class="justify-content-between">
          <b-row
            align-h="between"
            align-v="center"
            style="width:100%"
          >
            <mdb-btn
              v-if="!loading.isLoading"
              style="width:130px;font-size:0.7rem;"
              class="p-2 ml-auto"
              @click="resetQuestion()"
            >
              RESET
            </mdb-btn>
            <mdb-btn
              v-else
              style="width:130px;"
              class="p-2  ml-auto"
            >
              <mdb-icon icon="fa fa-pulse fa-spinner" />
            </mdb-btn>
          </b-row>
        </mdb-modal-footer>
      </mdb-modal>

      <!--confirm send to candidate modal-->
      <mdb-modal
        v-if="sendToCandidateModalShowing"
        id="sendToCandidateModal"
        @close="sendToCandidateModalShowing = false"
      >
        <mdb-modal-header>
          <mdb-modal-title>{{ unansweredQuestionsInListCount !== 0 ? 'CONFIRM SEND' : 'NOTHING TO SEND' }}</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body>
          <p
            v-if="unansweredQuestionsInListCount === 0"
            class="my-4 grey-text"
            style="font-size:0.8rem;text-align:left;"
          >
            All the questions in your list have already been answered!
          </p>
          <div v-else>
            <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
              Are you sure you want the candidate to record a response to the following questions? They will be sent an email inviting them to do so.
            </p>
            <ol class="mx-3 font-small grey-text" style="margin-bottom:20px;">
              <li
                v-for="(q,i) in unansweredQuestionsInList"
                :key="q.CandidateQuestionListId"
                class="text-left mt-2"
              >
                <span class="font-weight-bold mr-3" style="font-size:0.8rem;">{{ i+1 }} : {{ q.Description }}</span>
              </li>
            </ol>
          </div>
        </mdb-modal-body>
        <mdb-modal-footer class="justify-content-between">
          <b-row
            align-h="end"
            align-v="center"
            style="width:100%"
          >
            <mdb-btn
              v-if="confirmIsLoading === false && unansweredQuestionsInListCount !== 0"
              style="width:130px;"
              class="p-2"
              @click="sendCandidateInvite()"
            >
              SEND
            </mdb-btn>
            <mdb-btn
              v-if="confirmIsLoading"
              style="width:130px;"
              class="p-2"
            >
              <mdb-icon icon="fa fa-pulse fa-spinner" />
            </mdb-btn>
          </b-row>
        </mdb-modal-footer>
      </mdb-modal>
    </div>
    <div class="d-flex justify-content-between align-items-center" style="position:relative;height:50px;max-width:100%;background:#333;bottom:110px;color:white;border-bottom-left-radius:10px;border-bottom-right-radius:10px;">
      <mdb-btn
        v-if="candidateQuestions.length > 0 && allAnswered === false && cvRequestUpToDate"
        class="btn ml-3"
        disabled
        size="sm"
        style="padding: 0.5rem 1.1rem;"
      >
        REQUEST SENT
      </mdb-btn>
      <mdb-btn
        v-if="candidateQuestions.length > 0 && allAnswered === false && cvRequestUpToDate"
        class="btn mr-3"
        :disabled="unansweredQuestionsInListCount.length === 0"
        size="sm"
        style="padding: 0.5rem 1.1rem;"
        @click="sendToCandidateModalShowing = true"
      >
        RESEND REQUEST
      </mdb-btn>
      <mdb-btn
        v-if="candidateQuestions.length > 0 && !cvRequestUpToDate"
        class="btn mr-3 ml-auto"
        :disabled="unansweredQuestionsInListCount.length === 0"
        size="sm"
        style="padding: 0.5rem 1.1rem;"
        @click="sendToCandidateModalShowing = true"
      >
        SEND TO CANDIDATE
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { useStatus, useUserData, useCandidate } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import candidateService from '@/api/services/candidate.service.js'

import Draggable from 'vuedraggable'
import AddEditVideoModal from './videoCVModal'
import PlayVideoModal from './playVideoModal.vue'
import QuestionSet from '@/views/questionSets/questionSetComponent.vue'
import VCVPopover from './vcvPopoverButton.vue'

export default {
  name: 'VideoCV',
  components: {
    Draggable,
    AddEditVideoModal,
    PlayVideoModal,
    QuestionSet,
    VCVPopover
  },
  setup () {
    const candidateStore = useCandidate()
    const {
      candidateToEdit,
      view,
      editingCandidate,
      candidateQuestions,
      addEditQuestionModalShowing
    } = storeToRefs(candidateStore)

    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { closeGenericConfirmModal, showGenericErrorModal } = useStatus()
    const { mobileView } = useMobile()

    return {
      candidateToEdit,
      authData,
      view,
      editingCandidate,
      candidateQuestions,
      addEditQuestionModalShowing,
      closeGenericConfirmModal,
      showGenericErrorModal,
      mobileView
    }
  },
  data () {
    return {
      deletingSet: false,
      capturing: false,
      drag: false,
      deleteQuestionModalShowing: false,
      questionToDelete: null,
      questionToReset: null,
      editingQuestion: false,
      question: { Description: null, IsPool: false, NoRatings: false },
      loading: { isLoading: false, message: '' },
      playVideoModalShowing: false,
      questionToPlay: [],
      resetQuestionModalShowing: false,
      sendToCandidateModalShowing: false,
      confirmIsLoading: false,
      yA: false,
      nA: false,
      questionLength: [
        { text: '00:30', value: 30, selected: true },
        { text: '00:45', value: 45 },
        { text: '01:00', value: 60 },
        { text: '01:15', value: 75 },
        { text: '01:30', value: 90 },
        { text: '01:45', value: 105 },
        { text: '02:00', value: 120 },
        { text: '02:15', value: 135 },
        { text: '02:30', value: 150 },
        { text: '02:45', value: 165 },
        { text: '03:00', value: 180 }
        //{ text: '03:15', value: 195 },
        //{ text: '03:30', value: 210 },
        //{ text: '03:45', value: 225 },
        //{ text: '04:00', value: 240 },
        //{ text: '04:15', value: 255 },
        //{ text: '04:30', value: 270 },
        //{ text: '04:45', value: 285 },
        //{ text: '05:00', value: 300 }
      ],
      convertedCandidateQuestions: [],
      deletingSetId: 0
    }
  },
  computed: {
    cvRequestUpToDate () {
      return this.candidateQuestions.filter(q => q.CVRequestUpToDate === true).length === this.candidateQuestions.length
    },
    preventInvites () {
      return false
      // add logic to disable button if all questions are answered
      // return this.candidateQuestions.filter(q => q.AnswerUrl !== '').length === this.candidateQuestions
    },
    unansweredQuestionsInListCount () {
      return this.candidateQuestions.filter(q => q.AnswerUrl === '').length
    },
    unansweredQuestionsInList () {
      return this.candidateQuestions.filter(q => q.AnswerUrl === '')
    },
    allAnswered () {
      return this.candidateQuestions.filter(e => e.AnswerUrl === '').length === 0
    }
  },
  watch: {
    candidateQuestions: function () {
      this.scrollToDiv()
      try {
        // converts question list data and splits them by set or not
        const setIds = []
        const setShells = []
        this.candidateQuestions.map((q) => {
          if (q.QuestionSetId) {
            if (!setIds.includes(q.QuestionSetId)) {
              setIds.push(q.QuestionSetId)
            }
          } else {
            setIds.push({ NonQuestionSetQuestion: q })
          }
        })
        setIds.map(id => {
          if (typeof id === 'number') {
            setShells.push({ QuestionSetId: id, QuestionSetName: '', AddScenario: false, ScenarioText: '', RandomiseCount: 0, RandomiseQuestions: false, Questions: [] })
          } else {
            setShells.push(id.NonQuestionSetQuestion)
          }
        })
        this.candidateQuestions.map(q => {
          let index
          if (q.QuestionSetId) {
            setShells.forEach((sh, i) => {
              if (sh.QuestionSetId === q.QuestionSetId) { index = i }
            })
            setShells[index] = { ...setShells[index], QuestionSetName: q.QuestionSetName, AddScenario: q.AddScenario, ScenarioText: q.ScenarioText, RandomiseCount: q.RandomiseCount, RandomiseQuestions: q.RandomiseQuestions, Questions: [...setShells[index].Questions, q] }
          }
        })
        this.convertedCandidateQuestions = setShells
      } catch (err) { return [] }
    },
    candidateToEdit () {
      this.fetchCandidateQuestionList()
    }
  },
  mounted () {
    this.fetchCandidateQuestionList()
    this.scrollToDiv()
  },
  methods: {
    async deleteSet (questionSet) {
      this.deletingSetId = questionSet.QuestionSetId
      try {
        const res = await candidateService.addEditCandidateQuestionSet(questionSet.QuestionSetId, true, this.candidateToEdit.CandidateId)
        this.candidateQuestions = res.data
        this.closeGenericConfirmModal()

      } catch {
        this.showGenericErrorModal({ showing: true })
      }
      this.deletingSetId = 0
    },
    readableTimeLimit (time) {
      const result = this.questionLength.filter(q => q.value === time)[0]
      if (result !== undefined) { return result.text }
      return 'unknown'
    },
    closeAddEditQuestionModal () {
      this.addEditQuestionModalShowing = false
    },
    closePlayVideoModal () {
      this.playVideoModalShowing = false
      this.questionToPlay = []
      this.capturing = false
    },
    openDeleteQuestionModal (question) {
      this.deleteQuestionModalShowing = true
      this.questionToDelete = question
      this.$refs.popover.map(p => p.doClose())
    },
    openResetQuestionModal (question) {
      this.resetQuestionModalShowing = true
      this.questionToReset = question
      this.$refs.popover.map(p => p.doClose())
    },
    async deleteQuestion () {
      this.loading = { isLoading: true, message: 'Deleting candidate question' }
      var formData = new FormData()
      formData.append('questionId', this.questionToDelete.CandidateQuestionId)
      formData.append('candidateId', this.candidateToEdit.CandidateId)
      formData.append('toDelete', true)
      formData.append('description', 'dummy description')
      formData.append('videoUrl', '')
      formData.append('tags', '')
      formData.append('isPool', this.questionToDelete.IsPool)
      this.loading = { isLoading: false, message: '' }
      this.deleteQuestionModalShowing = false
      try {
        const res = await candidateService.addEditCandidateQuestion(formData)
        this.candidateQuestions = res.data
        this.$emit('addCandidateToRecentCandidates', this.candidateToEdit.CandidateId)
      // eslint-disable-next-line no-empty
      } catch (err) { }
    },
    editQuestion (question) {
      this.addEditQuestionModalShowing = true
      this.question = question
      this.editingQuestion = true
      this.$refs.popover.map(p => p.doClose())
    },
    async fetchCandidateQuestionList () {
      this.loading = { isLoading: true, message: 'Fetching questions' }
      try {
        const res = await candidateService.getCandidateQuestions(this.candidateToEdit.CandidateId)
        this.candidateQuestions = res.data
      // eslint-disable-next-line no-empty
      } catch { }
      this.loading = { isLoading: false, message: '' }
    },
    handleMove () {
      var ids = ''
      this.convertedCandidateQuestions.forEach(e => {
        if (e.QuestionSetId !== null && e.QuestionSetId !== undefined) {
          let setIds = ''
          e.Questions.forEach(q => { setIds += `${q.CandidateQuestionListId},` })
          ids += setIds
        } else {
          ids = ids + e.CandidateQuestionListId + ','
        }
      })
      this.updateOrdinals(ids)
    },
    openPlayVideoModal (question) {
      this.questionToPlay = [question]
      this.playVideoModalShowing = true
      this.$refs.popover.map(p => p.doClose())
    },
    openAddQuestionModal () {
      this.addEditQuestionModalShowing = true
      this.editingQuestion = false
      this.question = { Description: null, IsPool: false, NoRatings: false }
    },
    async resetQuestion () {
      this.loading = { isLoading: true, message: 'Resetting candidate question' }
      try {
        const res = await candidateService.resetCandidateVideoCVAnswer(this.questionToReset.CandidateId, this.questionToReset.CandidateQuestionListId)
        this.candidateQuestions = res.data
        this.$emit('addCandidateToRecentCandidates', this.candidateToEdit.CandidateId)
      // eslint-disable-next-line no-empty
      } catch (err) {}
      this.resetQuestionModalShowing = false
      this.loading = { isLoading: false, message: '' }
    },
    async sendCandidateInvite () {
      this.confirmIsLoading = true
      try {
        await candidateService.inviteCandidateToVideoCV(this.candidateToEdit.CandidateId)
        this.$emit('addCandidateToRecentCandidates', this.candidateToEdit.CandidateId)
        this.confirmIsLoading = false
        this.sendToCandidateModalShowing = false
        this.fetchCandidateQuestionList()
      } catch (err) {
        console.log(Object.assign({}, err))
        this.confirmIsLoading = false
        this.sendToCandidateModalShowing = false
      }
    },
    scrollToDiv () {
      try {
        setTimeout(() => {
          this.$refs.scrollToDiv.scrollIntoView({ block: 'end', inline: 'nearest', behaviour: 'smooth' })
        }, 500)
      // eslint-disable-next-line no-empty
      } catch {}
    },
    async updateOrdinals (ids) {
      try {
        await candidateService.updateCandidateQuestionListOrdinals(ids)
        this.$emit('addCandidateToRecentCandidates', this.candidateToEdit.CandidateId)
      } catch (err) {
        console.log(Object.assign({}, err))
      }
    }
  }
}
</script>

<style lang="scss">
.popover {
  width: fit-content !important;
}
</style>
