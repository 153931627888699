<template>
  <div
    class="d-flex justify-content-end"
    :class="{'ml-auto': !disableRightAlignment }"
    :style="{'max-width': `${maxWidthpercentage}%`}">
    <div
      class="box"
      :style="{'background': `26${userData.lbl.PrimaryColour}`}"
      style="max-width:100%;position:relative"
      @mouseenter="focusOnSearchBar()"
      @click="focusOnSearchBar()"
      @mouseleave="blur()"
    >
      <input
        ref="txt"
        v-model="search"
        :placeholder="focused ? placeHolder : ''"
        type="text"
        class="input"
        :class="{'paddingOnFocus' : focused, 'largeSearch pl-4 pl-md-5': largeSearch}"
        :style="focused ? `width:${width}px` : 'fillernonsense'"
        style="transition:all .2s ease;max-width:100%;"
        @click="focusOnSearchBar()"
      />
      <i
        ref="question"
        style="position:absolute"
        :class="{'largeI': largeSearch}"
        class="fas fa-search cp question"
        @click="focusOnSearchBar()"
      />
      <i
        :class="{'fade-in': focused, 'largeI': largeSearch}"
        class="fas fa-times cp clear fade"
        @click="clearEntered()"
      />
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'ExpandableSearchBar',
  props: {
    placeHolder: {
      default: '',
      type: String
    },
    width: {
      default: 300,
      type: Number
    },
    maxWidthpercentage: {
      default: 65,
      type: Number
    },
    searchTriggersAPICall: {
      default: false,
      type: Boolean
    },
    forceAlwaysFocused: {
      default: false,
      type: Boolean
    },
    largeSearch: {
      default: false,
      type: Boolean
    },
    disableRightAlignment: {
      default: false,
      type: Boolean
    },
    searchProp: {
      default: '',
      type: String
    }
  },
  setup () {
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)

    return {
      userData
    }
  },
  data () {
    return {
      search: '',
      focused: this.forceAlwaysFocused,
      delayAPITimer: null
    }
  },
  watch: {
    search () {
      if (!this.searchTriggersAPICall) {
        this.$emit('search', this.search?.toLowerCase() ?? '')
      } else {
        const debouncer = this.debounce()
        debouncer()
      }
    },
    searchProp () {
      if (this.searchProp != '') this.search = this.searchProp
    }
  },
  beforeUnmount () {
    this.$emit('search', '')
  },
  mounted () {
    if (this.forceAlwaysFocused) {
      this.focusOnSearchBar()
    }
  },
  methods: {
    clearEntered () {
      this.search = ''
      this.blur()
    },
    focusOnSearchBar () {
      this.$refs.txt.focus()
      this.focused = true
    },
    blur () {
      if (this.search === '') {
        if (!this.forceAlwaysFocused) {
          this.$refs.txt.blur()
          this.focused = false
        }
      }
    },
    processChange () {
      this.$emit('search', this.search?.toLowerCase())
    },
    debounce () {
      const si = this
      return function () {
        clearTimeout(si.delayAPITimer)
        si.delayAPITimer = setTimeout(() => {
          si.processChange()
        }, 1000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  position:relative;
}
input[type=text]:focus:not([readonly]) {
  box-shadow: none;
  border: 3px solid var(--pr-color);
}

.input {
  height: 40px;
  width: 40px;
  background: none;
  border: 3px solid var(--pr-color);
  border-radius: 50px;
  box-sizing: border-box;
  background: transparent;
  color: #555;
  outline: none;
  transition: .5s;
  transform-origin: right;
  font-size: 14px;
  letter-spacing: 1px;
  // color: transparent;
  color: #555;
}

.paddingOnFocus {
  padding: 0 30px;
}
input:focus {
  transform-origin: right;
  // border-radius: 20px;
}

i.question, i.clear {
  position: absolute;
  top: 32%;
  font-size: 15px;
  color: #555;
  transition: .2s;
}
i.question {
  left: 12px;
}
i.clear {
  right: 14px;
}

.fade {
  opacity: 0;
  transition: .8s ease;
}
.fade.fade-in {
  opacity: 1;
  transition: .8s ease;
}
.widthNoFocus {
  width: 40px;
}

@media (max-width: 768px) {
  input[type=text]:focus:not([readonly]) {
    border: 2px solid var(--pr-color);
  }
  .input {
    width: 30px;
    height: 30px;
    border: 2px solid var(--pr-color);
    font-size: 12px;
  }
  i.question, i.clear {
    top: 30%;
    font-size: 13px;
  }
  i.clear {
    right: 12px;
  }
  i.question {
    left: 9px;
  }
  .paddingOnFocus {
    padding: 0 25px 0 25px;
  }
}
// applies only to a larger search box
@media (min-width: 767px) {
  .largeSearch[type=text]:focus:not([readonly]) {
    box-shadow: none;
    border: 4px solid var(--pr-color);
  }
  .largeSearch {
    height: 50px;
    width: 50px;
    border: 4px solid var(--pr-color);
    font-size: 16px;
  }
  .largeI.question, .largeI.clear {
    position: absolute;
    top: 34%;
    font-size: 17px;
    color: #555;
    transition: .2s;
  }
  .largeI.question {
    left: 17px;
  }
  .largeI.clear {
    right: 17px;
  }
}
</style>
