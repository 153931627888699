import { ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useStatus } from './useStatus'
import { useRoles } from './useRoles'

import roleService from '@/api/services/role.service'
import libraryService from '@/api/services/library.service'
import roleV2Service from '@/api/services/roleV2.service'

const defaultRole = {
  JobId: 0,
  JobReferenceId: '',
  IsEvergreen: false,
  JobTitle: '',
  OpenFrom: new Date().toISOString().slice(0, 10),
  Expires: null,
  InterviewType: 0,
  SharingBrandId: null,
  CandidateBrandId: null,
  AudioOnly: 0,
  MaskCandidateName: 0,
  Step: 0
}

export const useWorkflow = defineStore('workflowStore', () => {
  const statusStore = useStatus()
  const { showGenericErrorModal } = useStatus()
  const rolesStore = useRoles()
  const { recentRoles, allRoles } = storeToRefs(rolesStore)

  const questionPool = ref([])
  const mainQuestionList = ref([])
  const videoToAddToList = ref({})
  const videoQuestionLibrary = ref([])
  const loading = ref(false)
  const addEditQuestionModal = ref(false)
  const roleCardViewCandidatesDropdownID = ref(0)
  const role = ref(defaultRole)
  const viewingOnlyFollowedRoles = ref(true)
  const selectedWorkflowStage = ref({})
  const selectedWorkflowStageId = ref('')
  const selectedWorkflowStageItem = ref({})
  const nextSteps = ref([])
  const currentWorkflow = ref(null)
  const oneWayStageHasBeenStarted = ref(false)

  const resetRoleData = () => {
    selectedWorkflowStage.value = {}
    selectedWorkflowStageId.value = 0
    role.value = defaultRole
    currentWorkflow.value = null
  }

  const setVideoQuestionLibrary = (payload) => {
    if (payload === '' || payload === null) {
      videoQuestionLibrary.value = []
      videoQuestionLibrary.value = null
    } else {
      videoQuestionLibrary.value = payload
    }
  }

  // todo - use this method - currently used in other places duplicated
  const getRoleWorkflowNoSetupDetails = async (roleWorkflowId) => {
    try {
      const res = await roleV2Service.getRoleWorkflowNoSetupDetails(roleWorkflowId)
      currentWorkflow.value = res.data
    } catch (err) {
      showGenericErrorModal()
    }
  }

  const getSelectedStage = async (stageId) => {
    try {
      const res = await roleV2Service.getRoleWorkflowStage(stageId)  
      selectedWorkflowStage.value = res.data
    } catch (err) {
      showGenericErrorModal()
    }
  }

  const getQuestionPool = async ({ isLegacy, token }) => {
    statusStore.setLoading({ isLoading: true, message: 'Fetching questions from pool' })
    try {
      let res
      if (isLegacy) {
        res = await roleService.getQuestionPool()
      } else {
        res = await roleService.getCandidateQuestionPool()
      }
      questionPool.value = res.data
      statusStore.setError({ status: false, message: 'Successfully fetched pool questions' })
    } catch (err) {
      statusStore.setError({ status: true, message: err.data || 'Could not fetch questions' })
    } finally {
      statusStore.setLoading({ isLoading: false, message: '' })
    }
  }

  const deleteQuestionFromPool = async ({ roleId, qId, isLegacy, token }) => {
    statusStore.setLoading({ isLoading: true, message: 'Deleting pool question' })
    try {
      const res = await roleService.deletePoolQuestion(roleId, qId)
      if (res.data === 'Successfully deleted') {
        getQuestionPool({ isLegacy, token })
      } else {
        statusStore.setError({ status: true, message: res.data })
      }
    } catch (err) {
      statusStore.setError({ status: true, message: err.data || 'Could not delete pool question.' })
    } finally {
      statusStore.setLoading({ isLoading: false, message: '' })
    }
  }

  const addPoolQuestionToJob = async ({ jobId, questionId, isPractiseQuestion, token }) => {
    statusStore.setLoading({ isLoading: true, message: 'Adding pool question to your job' })
    try {
      await roleService.addPoolQuestionToJob(jobId, questionId, isPractiseQuestion)
      statusStore.setError({ status: false, message: 'Successfully added question to the job' })
    } catch (err) {
      statusStore.setError({ status: true, message: err.data || 'Could not add this pool question to the job' })
    } finally {
      statusStore.setLoading({ isLoading: false, message: '' })
    }
  }

  const getVideoQuestionLibrary = async ({ token, filterType }) => {
    statusStore.setLoading({ isLoading: true, message: 'Fetching video library' })
    try {
      const res = await libraryService.getLibrary(filterType, -1)
      if (res.data == null || res.data === '') {
        videoQuestionLibrary.value = []
      } else {
        videoQuestionLibrary.value = res.data
      }
      statusStore.setError({ status: false, message: 'Successfully fetched video questions' })
    } catch (err) {
      videoQuestionLibrary.value = []
      statusStore.setError({ status: true, message: 'Could not fetch video questions' })
    } finally {
      statusStore.setLoading({ isLoading: false, message: '' })
    }
  }

  const addVideoQuestionToList = async({ roleId, videoUrl, questionId, description, timeLimit, isPractiseQuestion, isPool, tags, isScenario, noRatings, token }) => {
    statusStore.setLoading({ isLoading: true, message: 'Adding video question to list' })
    try {
      const res = await roleService.addEditQuestion(roleId, videoUrl, questionId, description, timeLimit, isPractiseQuestion, isPool, tags, isScenario, noRatings)
      mainQuestionList.value = res.data.Question
      statusStore.setError({ status: false, message: 'Successfully added video question to list' })
    } catch (err) {
      statusStore.setError({ status: true, message: 'Could not add video to question list' })
    } finally {
      statusStore.setLoading({ isLoading: false, message: '' })
    }
  }

  const addRoleToRecentRoles = async (roleId) => {
    const existingRoleIndex = recentRoles.value.findIndex(r => r.JobId === roleId)
    // if its already on recent roles just move it to the first index
    if (existingRoleIndex != -1) {
      var role = recentRoles.value[existingRoleIndex]
      recentRoles.value.splice(existingRoleIndex, 1)
      recentRoles.value.splice(0, 0, role)
      return
    }
    recentRoles.value = []
    statusStore.setLoading({ isLoading: true, message: 'Adding Role To Recent Roles' })
    try {
      const res = await roleV2Service.addRoleToRecentRoles(roleId)
      recentRoles.value = res.data
      return true
    } catch {
      statusStore.setError({ status: true })
      return false
    }
  }

  const getRole = async (roleId) => {
    role.value = {}
    statusStore.setLoading({ isLoading: true, message: 'Getting current role' })
    try {
      const res = await roleV2Service.getRoleNoJobDetails(roleId)
      role.value = res.data
      return true
    } catch (err) {
      statusStore.setError({ status: true })
      return false
    }
  }

  const getRolePersistMasking = async (roleId) => {
    role.value = {}
    statusStore.setLoading({ isLoading: true, message: 'Getting current role' })
    try {
      const res = await roleV2Service.getRolePersistMasking(roleId)
      role.value = res.data
      return true
    } catch (err) {
      statusStore.setError({ status: true })
      return false
    }
  }

  const getRoleWithStages = async (roleId) => {
    role.value = {}
    statusStore.setLoading({ isLoading: true, message: 'Getting current role' })
    try {
      const res = await roleV2Service.getRoleWithStages(roleId)
      role.value = res.data
      currentWorkflow.value = res.data.RoleWorkflow || {}
      return true
    } catch (err) {
      statusStore.setError({ status: true })
      return false
    }
  }

  const addEditRoleSetup = async (role) => {
    allRoles.value = []
    statusStore.setLoading({ isLoading: true, message: 'Getting all roles' })
    try {
      const res = await roleV2Service.addEditRoleSetup(role)
      if (res.data) {
        role.value = res.data
        return true
      }
      return false
    } catch (err) {
      statusStore.setError({ status: true, message: 'Something went wrong editing your role' })
      return false
    }
  }


  // EDIT STAGE ITEM MODAL
  const editStageItemModalShowing = ref(false)
  const editStageItemStage = ref({})

  return {
    addEditQuestionModal,
    addPoolQuestionToJob,
    addVideoQuestionToList,
    deleteQuestionFromPool,
    getRoleWorkflowNoSetupDetails,
    getQuestionPool,
    getVideoQuestionLibrary,
    loading,
    roleCardViewCandidatesDropdownID,
    setVideoQuestionLibrary,
    videoToAddToList,
    videoQuestionLibrary,
    mainQuestionList,
    questionPool,
    role,
    viewingOnlyFollowedRoles,
    selectedWorkflowStage,
    selectedWorkflowStageId,
    selectedWorkflowStageItem,
    resetRoleData,
    addRoleToRecentRoles,
    getRole,
    addEditRoleSetup,
    nextSteps,
    currentWorkflow,
    getRoleWithStages,
    getSelectedStage,
    oneWayStageHasBeenStarted,
    editStageItemModalShowing,
    editStageItemStage,
    getRolePersistMasking
  }
})
