<template>
  <h6
    style="margin-left:-10px;padding:5px 12px;"
    class="text-nowrap"
    @click="createAndCopyEmbedLink()"
  >
    <FontAwesomeIcon
      v-if="!alertCopied"
      icon="fa-solid fa-code"
      class="ml-1 grey-link"
      style="margin-right:10px;"
    />
    <i
      v-else
      class="fa fa-thumbs-up ml-1"
      style="margin-right:10px;"
    />
    <a v-if="!alertCopied" class="grey-link">Embed</a>
    <span v-else>Copied!</span>
  </h6>
</template>

<script>

export default {
  name: 'EmbedLink',
  components: {
  },
  props: {
    src: {
      type: String,
      default: ''
    },
    videoTitle: {
      type: String,
      default: 'Shine Interview Video'
    },
    srcIsAsync: {
      type: Boolean,
      default: false
    },
    fetchSrc: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      alertCopied: false,
      asyncSrc: ''
    }
  },
  computed: {
    htmlEmbedString () {
      return `
      <iframe
        width="560"
        height="315"
        src="${this.src}" title="${this.videoTitle}"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen>${this.videoTitle}</iframe>`
    },
    asyncHTMLEmbedString () {
      return `
      <iframe
        width="560"
        height="315"
        src="${this.asyncSrc}" title="${this.videoTitle}"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen>${this.videoTitle}</iframe>`
    }
  },
  watch: {
  },
  created () {
  },
  updated () {
  },
  methods: {
    async createAndCopyEmbedLink () {
      if (!this.srcIsAsync) {
        await navigator.clipboard.writeText(this.htmlEmbedString)
      } else {
        const res = await this.fetchSrc()
        this.asyncSrc = res
        await navigator.clipboard.writeText(this.asyncHTMLEmbedString)
      }
      this.alertCopied = true
      setTimeout(() => {
        this.alertCopied = false
        this.$emit('closePopover')
      }, 2000)
    }
  }
}
</script>
