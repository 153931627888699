import axiosInstance from '../instance.js'
const api2 = new axiosInstance(2)

export default class oneWayQuestionService {
  // get all invitation questions
  static async getCurrentStageInvitationQuestions (stageId) {
    return await api2.get(`/api/invitation_question/get_all_in_stage/${stageId}`)
  }

  // save ADD invitation question
  static async saveAddInvitationQuestion (JSONData) {
    return await api2.post('/api/invitation_question/add', JSONData)
  }

  // save EDIT invitation question
  static async saveEditInvitationQuestion (JSONData) {
    return await api2.put('/api/invitation_question/edit', JSONData)
  }

  // save EDIT invitation question
  static async deleteInvitationQuestion (JSONData) {
    return await api2.delete('/api/invitation_question/delete', { data: JSONData })
  }

  // save ADD question set to one way setup
  static async addQuestionSetToOneWaySetup (JSONData) {
    return await api2.post('/api/invitation_question/question_set/add', JSONData)
  }

  // DELETE question set from one way setup
  static async deleteQuestionSetFromOneWaySetup (JSONData) {
    return await api2.delete('/api/invitation_question/question_set/delete', { data: JSONData })
  }

  // edit ordinals in one way questions
  static async updateOneWaySetupOrdinals (JSONData) {
    return await api2.post('/api/invitation_question/ordinals', JSONData)
  }

  // add pool question to list
  static async addPoolQuestionToList (JSONData) {
    return await api2.post('/api/invitation_question/add_pool_question_to_list', JSONData)
  }

  // delete pool question question
  static async deleteQuestionPoolQuestion (goCandidateQuestionId) {
    return await api2.delete(`/api/go_candidate_question/delete_question_pool/${goCandidateQuestionId}`)
  }

  // get question pool
  static async getQuestionPool () {
    return await api2.get('/api/go_candidate_question/get_question_pool')
  }
}
