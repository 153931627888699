<template>
  <div class="d-flex align-items-center justify-content-center h-100 w-100" style="color:black;">
    <div
      v-if="role.InterviewType === 2"
      title="Self-Registration Link"
      :class="{'primary-colour': copiedSelfRegLink }"
      :style="{ 'opacity': !role.IsComplete ? '.6' : '1'}"
      class="d-flex flex-column justify-content-center align-items-center cp br20 p-2"
      @click="copySelfRegLinkClipBoard(`${SELF_REGISTRATION_URL}/#/${userData.fnm}/${role.JobId}`)"
    >
      <FontAwesomeIcon
        icon="fa-copy fa-regular"
        class="mb-1"
      />
      <span
        style="font-size:0.8rem"
        title="Copy public registration link"
      >{{ copiedSelfRegLink ? 'Copied' : 'Copy' }}</span>
    </div>
    <div v-else-if="role.InterviewType === 3">
      <div
        title="Self-Registration Link"
        :class="{'primary-colour': copiedSelfRegLink }"
        :style="{ 'opacity': !role.IsComplete ? '.6' : '1'}"
        class="d-flex flex-column justify-content-center align-items-center cp br20 p-2"
        @click="copySelfRegLinkClipBoard(`${SELF_REGISTRATION_URL}/#/${userData.fnm}/${role.JobId}`)"
      >
        <span
          style="font-size:0.8rem"
          title="Copy public registration link"
        >{{ copiedSelfRegLink ? 'Copied' : 'Copy' }}</span>
      </div>
      <div 
        class="d-flex flex-column justify-content-center align-items-center cp br20 p-2"
        style="font-size:0.6rem;margin-top:-10px;margin-bottom:-15px;"
      >
        /
      </div>
      <mdb-popover
        ref="popover"
        trigger="click"
        :options="{placement: 'bottom'}"
        style="cursor:pointer;margin-top: -10px !important;"
        class="d-flex flex-column justify-content-center align-items-center cp br20 p-2"
      >
        <div slot="body">
          <h6
            style="margin-left:-10px;margin-top:-5px;padding:12px;"
            class="text-nowrap"
            @click="setCandidates()"
          >
            <FontAwesomeIcon icon="fa-solid fa-user-group" class="mr-2" /><a class="grey-link">Invite From Candidates</a>
          </h6>
          <h6
            style="margin-left:-10px;padding:12px;"
            class="text-nowrap"
            @click="openAddInviteCandidateModal()"
          >
            <FontAwesomeIcon icon="fa-regular fa-user-plus" class="mr-2" /><a class="grey-link">Add New Candidate</a>
          </h6>
          <!-- || firstRoleStageType === 3 //scheduler -->
          <h6
            v-if="firstRoleStageType === 1"
            style="margin-left:-10px;margin-top:-5px;padding:12px;"
            class="text-nowrap"
            @click="importCandidates()"
          >
            <FontAwesomeIcon icon="fa-solid fa-users" class="mr-2" /><a class="grey-link">Import & Invite</a>
          </h6>
        </div>
        <span slot="reference">
          <span
            class="mb-auto text-black"
            style="font-size:0.8rem;transform:translateY(-6px)"
            title="Invite direct from here"
          >Invite</span>
        </span>
      </mdb-popover>
    </div>
    <div v-else-if="showFullInvitePanel">
      <mdb-popover
        ref="popover"
        trigger="click"
        :options="{placement: 'bottom'}"
        style="cursor:pointer;"
        class="grey-text"
      >
        <div slot="body">
          <h6
            style="margin-left:-10px;margin-top:-5px;padding:12px;"
            class="text-nowrap"
            @click="setCandidates()"
          >
            <FontAwesomeIcon icon="fa-solid fa-user-group" class="mr-2" /><a class="grey-link">Invite From Candidates</a>
          </h6>
          <h6
            style="margin-left:-10px;padding:12px;"
            class="text-nowrap"
            @click="openAddInviteCandidateModal()"
          >
            <FontAwesomeIcon icon="fa-regular fa-user-plus" class="mr-2" /><a class="grey-link">Add New Candidate</a>
          </h6>
          <!-- || firstRoleStageType === 3 //scheduler -->
          <h6
            v-if="firstRoleStageType === 1"
            style="margin-left:-10px;margin-top:-5px;padding:12px;"
            class="text-nowrap"
            @click="importCandidates()"
          >
            <FontAwesomeIcon icon="fa-solid fa-users" class="mr-2" /><a class="grey-link">Import & Invite</a>
          </h6>
        </div>
        <span slot="reference">
          <mdb-icon
            style="width: 20px; height:30px; font-size:1rem;margin-left: 5px;"
            class="d-flex align-items-center justify-content-center text-black"
            icon="envelope"
          />
          <span class="mb-auto text-black" style="font-size:0.8rem;transform:translateY(-6px)">Invite</span>
        </span>
      </mdb-popover>
    </div>
    <div
      v-else-if="userData.rle === 'Inviter'"
      class="d-flex flex-column align-items-center justify-content-center"
      style="background:#fff;cursor:pointer;"
      @click="openAddInviteCandidateModal()"
    >
      <i
        class="fa fa-envelope"
        style="font-size:18px;transform:translateY(6px);"
      /><br>
      <div class="mb-auto" style="font-size:0.8rem;transform:translateY(-6px)">
        Invite
      </div>
    </div>
    <div
      v-else
      class="d-flex flex-column align-items-center justify-content-center"
      style="opacity:0.4;background:#fff"
    >
      <i
        class="fa fa-envelope"
        style="font-size:18px;transform:translateY(6px);"
      /><br>
      <div class="mb-auto" style="font-size:0.8rem;transform:translateY(-6px)">
        Invite
      </div>
    </div>
  </div>
</template>

<script>
import { SELF_REGISTRATION_URL } from '@/common/config.js'

import { useRoles, useWorkflow, useRoleCandidate, useUserData, useCandidate } from '@/pinia'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
export default {
  name: 'InviteOptionsPanel',
  props: {
    role: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)

    const roleCandidateStore = useRoleCandidate()
    const { candidateTableData } = storeToRefs(roleCandidateStore)
    
    const rolesStore = useRoles()
    const {
      addInviteCandidateModalData,
      addingInvitingCandidateModalShowing,
    } = storeToRefs(rolesStore)

    const candidateStore = useCandidate()
    const {
      importAndInviteModal,
      importAndInviteModalRoleId,
      importAndInviteModalRoleWorkflowStageId,
      hideRedoStageOptionOnImportModal
    } = storeToRefs(candidateStore)

    const workflowStore = useWorkflow()
    const {
      role: piniaRole
    } = storeToRefs(workflowStore)

    const firstStageId = computed(() => props.role?.RoleWorkflow?.RoleWorkflowStages[0].RoleWorkflowStageId)
    const firstRoleStageType = computed(() => props.role?.RoleWorkflow?.RoleWorkflowStages[0].StageType)

    const importCandidates = () => {
      // only want the redo option to show if one way stage is first
      hideRedoStageOptionOnImportModal.value = firstRoleStageType.value != 1
      importAndInviteModalRoleId.value = props.role.JobId
      importAndInviteModalRoleWorkflowStageId.value = firstStageId
      importAndInviteModal.value = true
    }

    const copiedSelfRegLink = ref(false)

    return {
      importCandidates,
      candidateTableData,
      addInviteCandidateModalData,
      addingInvitingCandidateModalShowing,
      piniaRole,
      firstRoleStageType,
      copiedSelfRegLink,
      firstStageId,
      userData
    }
  },
  computed: {
    SELF_REGISTRATION_URL () {
      return SELF_REGISTRATION_URL
    },
    firstStage () {
      return this.role?.RoleWorkflow?.RoleWorkflowStages[0]
    },
    showFullInvitePanel () {
      return this.role.IsComplete && this.role.RoleWorkflow && this.userData.rle !== 'Inviter'
    }
  },
  methods: {
    async setCandidates () {
      this.$router.push(`/roles/candidates/${this.role.JobId}/${this.firstStageId}/0`).catch(() => {})
    },
    openAddInviteCandidateModal () {
      this.piniaRole = this.role
      this.addInviteCandidateModalData = this.firstStage
      this.addingInvitingCandidateModalShowing = true
    },
    copySelfRegLinkClipBoard (value) {
      if (!this.role.IsComplete) return
      navigator.clipboard.writeText(value)
      this.copiedSelfRegLink = true
      setTimeout(() => { this.copiedSelfRegLink = false }, 2000)
    },
  }
}
</script>
