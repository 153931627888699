<template>
  <div
    style="margin-top:20px;padding-bottom:20px;margin-bottom: 100px;"
    :style="{'pointer-events': loadingAssessors ? 'none' : 'auto'}"
  >
    <div
      v-if="candidates.length === 0 && candidatesLoaded === true"
      class="text-left grey-text small"
      style="margin-top:30px;margin-bottom:10px;"
    >
      No candidates found.
    </div>
    <div v-show="candidates.length > 0" md="10">
      <b-table
        id="candidates-table"
        ref="roleCandidatesTable"
        class="text-left grey-text"
        head-variant="dark"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :items="candidates"
        :per-page="perPage"
        :current-page="1"
        :fields="fields"
        :sort-compare="candidateSort"
        :striped="true"
        :pagination="true"
        hover
        small
        sort-icon-left
        responsive
        :selectable="!preventTableSelection"
        select-mode="multi"
        @row-selected="selectCandidate"
      >
        <template #cell(Selected)="{rowSelected}">
          <div v-if="rowSelected" class="tableRow mx-auto">
            <FontAwesomeIcon icon="fa-solid fa-check" class="ml-3 mr-2" />
          </div>
          <div v-else class="tableRow mx-auto">
            <FontAwesomeIcon icon="fa-regular fa-square" class="ml-3 mr-2" />
          </div>
        </template>
        <template #cell(name)="data">
          <CandidateInfo :data="data" />
        </template>
        <template #cell(DateInvited)="data">
          <div class="tableRow" style="cursor:pointer;">
            {{ new Date(data.item.DateInvited).toLocaleDateString([userLanguage]) }}
          </div>
        </template>
        <template #cell(DateExpired)="data">
          <div class="tableRow" style="cursor:pointer;">
            {{ role.IsEvergreen ? 'N/A' : new Date(data.item.DateExpired).toLocaleDateString([userLanguage]) }}
          </div>
        </template>
        <template #cell(InterviewDate)="data">
          <div
            v-if="stageType === 2"
            class="tableRow"
            style="cursor:pointer;"
          >
            {{ new Date(data.item.InterviewDate).toLocaleString(undefined, { day: 'numeric', month: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit'}) }}
          </div>
        </template>
        <template #cell(DateCompleted)="data">
          <div class="tableRow" style="cursor:pointer;">
            {{ new Date(data.item.DateCompleted).toLocaleDateString([userLanguage]) }}
          </div>
        </template>
        <template #cell(BookingDate)="data">
          <div class="tableRow" style="cursor:pointer;">
            {{ new Date(data.item.BookingDate).toLocaleString([userLanguage], { day: 'numeric', month: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit'}) }}
          </div>
        </template>
        <template #cell(BookedWithUser)="data">
          <div
            :title="!data.item.BookedWithUser?.Name && 'This slot is not associated with a specific user'"
            class="tableRow"
            style="cursor:pointer;"
          >
            {{ data.item.BookedWithUser?.Name ?? 'N/A' }}
          </div>
        </template>
        <template #cell(ExternalCandidateReference)="data">
          <div class="tableRow">
            {{ data.item.ExternalCandidateReference }}
          </div>
        </template>
        <template #cell(InvitationCode)="data">
          <div class="tableRow">
            {{ data.item.InvitationCode }}
          </div>
        </template>
        <template #cell(actions)="data">
          <div
            v-if="stageItemStatus !== 1"
            class="ml-auto d-flex justify-content-end tableRow align-items-center my-auto mobileAdjustment"
            style="cursor:pointer;margin-right:10px;"
            @click="togglePreventTableSelection(true)"
            @mouseleave="togglePreventTableSelection(false)"
          >
            <!-- PRIMARY ACTION BUTTON -->
            <ActionButton
              :candidate="data.item"
              :stage-item-status="candidateTableData.StageItemStatus"
              :stage-type="candidateTableData.StageType"
              :roleWorkflowStage="selectedWorkflowStage"
              @updateComponentContext="$emit('getRoleCandidates')"
              @initialiseReviewModal="initialiseReviewModal"
            />

            <PopoverButton
              :data="data"
              @getRoleCandidates="$emit('getRoleCandidates')"
              @initialiseReviewModal="initialiseReviewModal"
              @confirmUninviteSelected="confirmUninviteSelected"
            />
          </div>
        </template>
      </b-table>

      <div class="d-flex align-items-center justify-content-between mt-3 flex-wrap">
        <!-- BULK OPTION ELEMENTS -->
        <BulkInvite
          class="d-flex align-items-center mr-auto mb-3"
          style="width:fit-content;"
          :candidates-loaded="candidatesLoaded"
          @updateComponentContext="$emit('getRoleCandidates')"
          @selectAllRows="$refs.roleCandidatesTable.selectAllRows()"
        />
        <!-- PAGINATION -->
        <b-pagination
          v-if="roleCandidateTableCount >= perPage"
          :value="currentPage"
          :total-rows="roleCandidateTableCount"
          :per-page="perPage"
          first-number
          last-number
          prev-text="<"
          next-text=">"
          class="mb-3"
          @change="e => $emit('setCurrentPage', e)"
        />

        <!--pagination dropdown-->
        <div style="float:left;margin-top: -40px !important;">
          <select
            v-if="notChangingPagination"
            v-model="page"
            class="selectStyle selectClass"
            @change="perPageStorage(page)"
          >
            <option value="10" :selected="userData.pge === 10">
              10 per page
            </option>
            <option value="25" :selected="userData.pge === 25">
              25 per page
            </option>
            <option value="50" :selected="userData.pge === 55">
              50 per page
            </option>
            <option value="100" :selected="userData.pge === 100">
              100 per page
            </option>
          </select>
          <div
            v-else
            class="selectStyleElse"
          >
            <i class="fa fa-spin fa-spinner" style="margin-top:10px;margin-left: -5px;" /> loading page
          </div>
        </div>
        <!--pagination dropdown-->
      </div>
    </div>

    <MeetingRoomDateAndTimeModal
      @getRoleCandidates="$emit('getRoleCandidates')"
      @cancelMeetingModalAndRemoveCand="cancelMeetingModalAndRemoveCand($event)"
    />

    <ReviewModal
      style="position:fixed;"
      :watch-interview-modal="watchInterviewModal"
      :review-modal-data="reviewModalData"
      :role-id="role.JobId"
      @closeModal="closeInterviewModal()"
      @setCandidates="$emit('getRoleCandidates')"
    />

    <ExtendInviteDeadlineModal @refreshCandidates="$emit('getRoleCandidates')" />
  </div>
</template>

<script>
import { useNotification, useStatus, useWorkflow, useRoleCandidate, useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import roleV2Service from '@/api/services/roleV2.service'
import candidateService from '@/api/services/candidate.service'
import signUpService from '@/api/services/signup.service'
import { CANDIDATE_URL, MEET_URL } from '@/common/config.js'
import { userLanguage } from '@/common/userLanguage'
import { CONVERT_STATUS_NAME } from '@/utils/convertStatus.js'

import MeetingRoomDateAndTimeModal from '../meetingRoomDateAndTimeModal.vue'
import ReviewModal from '@/components/misc/ReviewModalV2/reviewModal.vue'
import BulkInvite from '../ColumnComponents/bulkMethods.vue'
import PopoverButton from '../ColumnComponents/PopoverButton/popoverButton.vue'
import CandidateInfo from '../ColumnComponents/candidateInfo.vue'
import ActionButton from '../ColumnComponents/ActionButton/actionButtonContainer.vue'
import getRoleTableFields from './returnRoleTableFieldsNEW'
import ExtendInviteDeadlineModal from '../extendInviteDeadlineModal.vue'

export default {
  name: 'RolesTable',
  components: {
    MeetingRoomDateAndTimeModal,
    ReviewModal,
    BulkInvite,
    PopoverButton,
    CandidateInfo,
    ActionButton,
    ExtendInviteDeadlineModal
  },
  props: {
    filter: {
      type: String,
      default: ''
    },
    candidatesLoaded: Boolean
  },
  setup () {
    const notificationStore = useNotification()
    const { redirectToOneWay, redirectToTwoWay, redirectData } = storeToRefs(notificationStore)

    const roleCandidateStore = useRoleCandidate()
    const { candidateTableData, reviewModalRoleWorkflow, candidate, candidates, selectedCandidates, removeCandidatesFromTable, updateCandidateInTable, roleCandidateTableCount, meetingDateModal, perPage, currentPage, reviewModalSelectedStage, resettingTwoWay } = storeToRefs(roleCandidateStore)

    const workflowStore = useWorkflow()
    const { role, addRoleToRecentRoles, selectedWorkflowStage } = storeToRefs(workflowStore)

    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()
    const { mobileView } = useMobile()

    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    return {
      authData,
      userData,
      redirectToOneWay,
      redirectToTwoWay,
      redirectData,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal,
      mobileView,
      candidateTableData,
      role,
      candidate,
      candidates,
      selectedCandidates,
      addRoleToRecentRoles,
      removeCandidatesFromTable,
      updateCandidateInTable,
      roleCandidateTableCount,
      meetingDateModal,
      perPage,
      currentPage,
      reviewModalRoleWorkflow,
      selectedWorkflowStage,
      reviewModalSelectedStage,
      resettingTwoWay
    }
  },
  data () {
    return {
      sortBy: '',
      sortDesc: false,
      meetingAssessors: [],
      meetingHosts: [],
      wIndex: 0,
      waitingUpdate: false,
      closeDatePast: false,
      linkCopied: false,
      userLanguage: userLanguage,
      reviewModalData: {},
      watchInterviewModal: false,
      loadingAssessors: false,
      preventTableSelection: false,
      notChangingPagination: true,
      page: this.userData.pge
    }
  },
  computed: {
    stageType () {
      return this.candidateTableData.StageType
    },
    stageItemStatus () {
      return this.candidateTableData.StageItemStatus
    },
    completedStatusType () {
      return this.stageItemStatus >= 6
    },
    roleId () {
      return this.role.JobId
    },
    CANDIDATE_URL () { return CANDIDATE_URL },
    MEET_URL () { return MEET_URL },
    fields () {
      return getRoleTableFields(this.stageItemStatus, this.stageType, this.completedStatusType, this.mobileView, this.userLanguage, this.selectedWorkflowStage.IsSelfSchedule)
    }
  },
  watch: {
    selectedCandidates () {
      if (this.selectedCandidates.length === 0) {
        this.$refs.roleCandidatesTable.clearSelected()
      }
    },
    candidates: {
      handler: function () {
        if (this.candidates.length !== 0 && (this.redirectToOneWay || this.redirectToTwoWay)) {
          this.handleRedirectToReviewModal()
        }
        if (this.candidates.length > 0 && this.$route.query.sm == 'true') {
          this.handleSetInterviewTimeForNewlyAddedCandidate()
        }
      }, immediate: true
    }
  },
  mounted () {
    this.selectedCandidates = []
  },
  methods: {
    perPageStorage (e) {
      this.notChangingPagination = false
      candidateService.updatePerPage(e).then((response) => {
        if (response.data === true) {
          signUpService.getUserDetails(this.authData.access_token).then(async (res) => {
            this.userData = res.data
            this.perPage = e
            this.$emit('getRoleCandidates')
            this.notChangingPagination = true
          })
        } else {
          this.notChangingPagination = true
        }
      })
    },
    togglePreventTableSelection (bool) {
      this.preventTableSelection = bool
      if (bool) { this.selectedCandidates = [] }
    },
    cancelMeetingModalAndRemoveCand (candId) {
      this.selectedCandidates = this.selectedCandidates.filter(id => id !== candId)
      var rowIndex = this.$refs.roleCandidatesTable.paginatedItems.findIndex(i => i.CandidateId === candId)
      this.$refs.roleCandidatesTable.unselectRow(rowIndex)
      this.meetingDateModal = false
      this.addRoleToRecentRoles(this.role.JobId)
    },
    initialiseReviewModal (candidate) {
      this.reviewModalRoleWorkflow = this.role.RoleWorkflow
      this.reviewModalSelectedStage = { ...this.selectedWorkflowStage }
      this.reviewModalData = { ...candidate }
      this.watchInterviewModal = true
      this.addRoleToRecentRoles(this.role.JobId)
    },
    closeInterviewModal () {
      this.watchInterviewModal = false
    },
    candidateSort (itemA, itemB, key) {
      if (key === 'DateInvited' || key === 'DateCompleted') {
        // Compare based on epoch time
        return Date.parse(itemA[key]) - Date.parse(itemB[key])
      } else {
        // Fallback to default sort
        return false
      }
    },
    selectCandidate (candidates) {
      // need to first determine which is the newly selected or deselected candidate
      if (candidates.length > this.selectedCandidates.length) {
        // SELECTED
        var newlySelectedCand = [...candidates].filter(c => !this.selectedCandidates.includes(c.CandidateId))[0]
        this.candidate = newlySelectedCand
        if (this.stageType === 2 && this.stageItemStatus === 1 && !this.selectedWorkflowStage.IsSelfSchedule) this.meetingDateModal = true
      } else {
        var updatedCandIds = candidates.map(c => c.CandidateId)
        var deselectedCandId = this.selectedCandidates.filter(c => !updatedCandIds.includes(c))[0]
        var cand = this.candidates.filter(c => c.CandidateId === deselectedCandId)[0]
        this.candidate = cand !== undefined ? cand : {}
        if (this.stageType === 2 && this.stageItemStatus === 1 && this.selectedCandidates.indexOf(this.candidate.CandidateId) !== -1 && !this.selectedWorkflowStage.IsSelfSchedule) {
          // reaches here if deselecting on two-way
          var idx1 = this.selectedCandidates.indexOf(this.candidate.CandidateId)
          if (idx1 !== -1) {
            roleV2Service.deleteTempMeetingDate(this.candidate.CandidateId, this.selectedWorkflowStage.RoleWorkflowStageId).then(() => {
              this.selectedCandidates.splice(idx1, 1)
            })
          }
          this.selectedCandidates = candidates.map(c => c.CandidateId)
          return
        } else {
          // deselecting on one-way
          this.selectedCandidates = candidates.map(c => c.CandidateId)
          return
        }
      }
      if (candidates.length === 0) {
        this.selectedCandidates = []
        return
      }
      this.selectedCandidates = candidates.map(c => c.CandidateId)
    },
    async confirmUninviteSelected (candidateId = 0) {
      try {
        const candidates = candidateId === 0 ? this.selectedCandidates : [candidateId]
        const JSONData = {
          RoleId: this.roleId,
          SelectedCandidates: candidates,
          RoleWorkflowStageId: this.candidateTableData.RoleWorkflowStageId
        }
        if (this.stageType == 1) {
          await roleV2Service.uninviteFromOneWayStage(JSONData)
        } else if (this.stageType == 2) {
          await roleV2Service.uninviteFromTwoWayStage(JSONData)
        } else if (this.stageType == 3) {
          await roleV2Service.uninviteFromEvent(JSONData)
        }
        this.$emit('getRoleCandidates')
        this.closeGenericConfirmModal()
        this.addRoleToRecentRoles(this.role.JobId)
      } catch (err) {
        this.closeGenericConfirmModal()
        this.showGenericErrorModal()
      }
      this.selectedCandidates = []
    },
    handleRedirectToReviewModal () {
      this.initialiseReviewModal(this.candidates[0])
      this.redirectToOneWay = false
      this.redirectToTwoWay = false
    },
    handleSetInterviewTimeForNewlyAddedCandidate () {
      setTimeout(async () => {
        this.$refs.roleCandidatesTable.selectRow(0)
        await this.$nextTick()
        this.candidate = this.candidates[0]
        this.resettingTwoWay = false
        this.meetingDateModal = true
        const query = Object.assign({}, this.$route.query);
        delete query.sm
        this.$router.replace({ query })
      }, 300)
    },
    convertStatusName (status) {
      return CONVERT_STATUS_NAME(status)
    }
  }
}
</script>

<style lang="scss" scoped>
.tableRow {
  height: 35px !important;
  display: flex;
  align-items: center !important;
}
@media (min-width: 577px) {
  .buttonHt {
    margin-top:10px !important;
  }
}
@media (max-width: 576px) {
  .mobilePlacement {
    margin-top: 19px !important;
  }
   .mobb {
     width: 35px !important;
   }
  .mb-width {
    width:60px !important;
    margin-left: 0px;
  }
  .mobileAdjustment {
    margin-top: 10px !important;
  }
}

.rolesTD div {
  min-height: 30px;
  display: flex;
  align-items: center;
  padding: 3px;
}
.rolesTD {
  min-height: 30px;
  display: flex;
  align-items: center;
}
.selectedColumnRolesTable {
  width: 30px !important ;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-grow: 0 !important;
}
</style>
