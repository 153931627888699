<template>
  <div class="d-flex justify-content-center align-items-center ml-2 iconBorder cp">
    <mdb-popover
      ref="popover"
      trigger="click"
      :options="{placement: 'bottom'}"
    >
      <div slot="body" style="width:fit-content !important">
        <div class="optionContainer">
          <mdb-switch
            v-model="name"
            disabled
            off-label=""
            on-label=""
            class="m-0 switch"
          /><h5 class="d-flex mr-2 switch-label">
            Name
          </h5><FontAwesomeIcon icon="fa-solid fa-lock" />
        </div>
        <div class="optionContainer">
          <mdb-switch
            v-model="email"
            off-label=""
            on-label=""
            class="m-0 switch"
          /><h5 class="d-flex mr-3 switch-label">
            Email Address
          </h5>
        </div>
        <div class="optionContainer">
          <mdb-switch
            v-model="contactNumber"
            off-label=""
            on-label=""
            class="m-0 switch"
          /><h5 class="d-flex mr-3 switch-label">
            Contact Number
          </h5>
        </div>
        <div class="optionContainer">
          <mdb-switch
            v-model="position"
            off-label=""
            on-label=""
            class="m-0 switch"
          /><h5 class="d-flex mr-3 switch-label">
            Job Title
          </h5>
        </div>
        <div class="optionContainer">
          <mdb-switch
            v-model="externalRefId"
            off-label=""
            on-label=""
            class="m-0 switch"
          /><h5 class="d-flex mr-3 switch-label">
            External Reference
          </h5>
        </div>
        <div class="optionContainer">
          <mdb-switch
            v-model="location"
            off-label=""
            on-label=""
            class="m-0 switch"
          /><h5 class="d-flex mr-3 switch-label">
            Location
          </h5>
        </div>
        <div class="optionContainer">
          <mdb-switch
            v-model="salary"
            off-label=""
            on-label=""
            class="m-0 switch"
          /><h5 class="d-flex mr-3 switch-label">
            Current Salary
          </h5>
        </div>
        <div class="optionContainer">
          <mdb-switch
            v-model="expectedSalary"
            off-label=""
            on-label=""
            class="m-0 switch"
          /><h5 class="d-flex mr-3 switch-label">
            Expected Salary
          </h5>
        </div>
        <div class="optionContainer">
          <mdb-switch
            v-model="workingPattern"
            off-label=""
            on-label=""
            class="m-0 switch"
          /><h5 class="d-flex mr-3 switch-label">
            Working Pattern
          </h5>
        </div>
        <div class="optionContainer">
          <mdb-switch
            v-model="dateAdded"
            off-label=""
            on-label=""
            class="m-0 switch"
          /><h5 class="d-flex mr-3 switch-label">
            Created
          </h5>
        </div>
        <div class="optionContainer">
          <mdb-switch
            v-model="actions"
            disabled
            off-label=""
            on-label=""
            class="m-0 switch"
          /><h5 class="d-flex mr-2 switch-label">
            Actions
          </h5><FontAwesomeIcon icon="fa-solid fa-lock" />
        </div>
        <div v-if="!loading" class="optionContainer">
          <mdb-btn
            size="sm"
            style="width: 110px;"
            class="btn mt-3 ml-3"
            @click="saveDisplaySettings()"
          >
            SAVE
          </mdb-btn>
        </div>
        <div v-else class="optionContainer">
          <mdb-btn
            style="width: 110px;"
            size="sm"
            class="btn mt-3 ml-3"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </div>
      </div>
      <FontAwesomeIcon
        slot="reference"
        ref="question"
        icon="fa-regular fa-square-sliders"
        class="settingIcon"
      />
    </mdb-popover>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'

import candidateService from '@/api/services/candidate.service'

export default {
  name: 'CandidateDisplaySetting',
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    return {
      authData
    }
  },
  data () {
    return {
      name: true,
      email: false,
      contactNumber: false,
      position: false,
      externalRefId: false,
      location: false,
      salary: false,
      expectedSalary: false,
      workingPattern: false,
      actions: true,
      dateAdded: false,
      loading: false
    }
  },
  mounted () {
    this.getDisplaySettings()
  },
  methods: {
    async saveDisplaySettings () {
      this.loading = true
      const formData = {
        ShowEmail: this.email,
        ShowContactNumber: this.contactNumber,
        ShowPosition: this.position,
        ShowExternalRefId: this.externalRefId,
        ShowLocation: this.location,
        ShowSalary: this.salary,
        ShowExpectedSalary: this.expectedSalary,
        ShowWorkingPattern: this.workingPattern,
        ShowDateAdded: this.dateAdded
      }
      try {
        await candidateService.setCandidateTableDisplaySettings(formData)
        this.$refs.popover.doClose()
        this.handleFields()
      } catch (err) {
        console.log(Object.assign({}, err))
      }
      this.loading = false
    },
    async getDisplaySettings () {
      this.loading = true
      try {
        const res = await candidateService.getCandidateTableDisplaySettings()
        this.email = res.data.ShowEmail
        this.contactNumber = res.data.ShowContactNumber
        this.position = res.data.ShowPosition
        this.externalRefId = res.data.ShowExternalRefId
        this.location = res.data.ShowLocation
        this.salary = res.data.ShowSalary
        this.expectedSalary = res.data.ShowExpectedSalary
        this.workingPattern = res.data.ShowWorkingPattern
        this.dateAdded = res.data.ShowDateAdded
        this.handleFields()
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    },
    handleFields () {
      const fields = []
      fields.push({ key: 'Name', label: '  Candidate    ', tdClass: ['viewAllCandsTD', 'text-nowrap', 'p-0', 'pt-2', 'pt-md-2', 'pt-lg-3', 'pr-4', 'm-0', 'pl-3'], thClass: ['pl-3', 'pl-3', 'm-5', 'viewAllCandsTH', 'mr-5', 'text-nowrap'], sortable: true })
      if (this.email) { fields.push({ key: 'EmailAddress', label: '  Email   ', thClass: ['pl-3', 'm-5', 'viewAllCandsTH', 'mr-5', 'text-nowrap'], tdClass: ['viewAllCandsTD', 'text-nowrap', 'p-0', 'pt-2', 'pt-md-2', 'pt-lg-3', 'pr-4', 'm-0'], sortable: true }) }
      if (this.contactNumber) { fields.push({ key: 'ContactNumber', label: '  Contact No.   ', thClass: ['pl-3', 'm-5', 'viewAllCandsTH', 'mr-5', 'text-nowrap'], tdClass: ['viewAllCandsTD', 'text-nowrap', 'p-0', 'pt-2', 'pt-md-2', 'pt-lg-3', 'pr-4', 'm-0'], sortable: true }) }
      if (this.position) { fields.push({ key: 'Position', label: '  Job Title   ', thClass: ['pl-3', 'm-5', 'viewAllCandsTH', 'mr-5', 'text-nowrap'], tdClass: ['viewAllCandsTD', 'text-nowrap', 'p-0', 'pt-2', 'pt-md-2', 'pt-lg-3', 'pr-4', 'm-0'], sortable: true }) }
      if (this.externalRefId) { fields.push({ key: 'ExternalCandidateReference', label: '  External Ref.   ', thClass: ['pl-3', 'm-5', 'viewAllCandsTH', 'mr-5', 'text-nowrap'], tdClass: ['viewAllCandsTD', 'text-nowrap', 'p-0', 'pt-2', 'pt-md-2', 'pt-lg-3', 'pr-4', 'm-0'], sortable: true }) }
      if (this.location) { fields.push({ key: 'Location', label: '  Location   ', thClass: ['pl-3', 'm-5', 'viewAllCandsTH', 'mr-5', 'text-nowrap'], tdClass: ['viewAllCandsTD', 'text-nowrap', 'p-0', 'pt-2', 'pt-md-2', 'pt-lg-3', 'pr-4', 'm-0'], sortable: true }) }
      if (this.salary) { fields.push({ key: 'Salary', label: '  Current Salary   ', thClass: ['pl-3', 'm-5', 'viewAllCandsTH', 'mr-5', 'text-nowrap'], tdClass: ['viewAllCandsTD', 'text-nowrap', 'p-0', 'pt-2', 'pt-md-2', 'pt-lg-3', 'pr-4', 'm-0'], sortable: true }) }
      if (this.expectedSalary) { fields.push({ key: 'SalaryExpectation', label: '  Expected Salary   ', thClass: ['pl-3', 'm-5', 'viewAllCandsTH', 'mr-5', 'text-nowrap'], tdClass: ['viewAllCandsTD', 'text-nowrap', 'p-0', 'pt-2', 'pt-md-2', 'pt-lg-3', 'pr-4', 'm-0'], sortable: true }) }
      if (this.workingPattern) { fields.push({ key: 'WorkingPatternReadable', label: '  Working Pattern   ', thClass: ['pl-3', 'm-5', 'viewAllCandsTH', 'mr-5', 'text-nowrap'], tdClass: ['viewAllCandsTD', 'text-nowrap', 'p-0', 'pt-2', 'pt-md-2', 'pt-lg-3', 'pr-4', 'm-0'], sortable: true }) }
      if (this.dateAdded) { fields.push({ key: 'DateAdded', label: '  Added   ', thClass: ['pl-3', 'm-5', 'viewAllCandsTH', 'mr-5', 'text-nowrap'], tdClass: ['viewAllCandsTD', 'text-nowrap', 'p-0', 'pt-2', 'pt-md-2', 'pt-lg-3', 'pr-4', 'm-0'], sortable: true }) }
      fields.push({ key: 'Actions', label: ' ', thClass: ['pl-3', 'actionTH', 'm-5', 'mr-5', 'text-nowrap'], tdClass: ['text-right', 'text-nowrap', 'p-0', 'pb-1', 'pt-1', 'pt-lg-2', 'm-0'], sortable: false })
      this.$emit('fields', fields)
    }
  }
}
</script>

<style lang="css" scoped>
.settingIcon {
  transform: translateY(1px)
}
.optionContainer {
  display: flex;
  align-items: center;
  justify-content: start ;
}
.switch-label {
  font-size:0.9rem;
}
.switch {
  transform:scale(0.7) translate(-3px, 3px)
}
.iconBorder {
  height: 40px;
  width: 40px;
  background: none;
  border: 3px solid var(--pr-color);
  font-size: 20px;
  border-radius: 50%;
}

@media (max-width: 768px) {
  .iconBorder {
    width: 30px;
    height: 30px;
    border: 2px solid var(--pr-color);
    font-size: 16px;
  }
}
</style>
