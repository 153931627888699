<template>
  <div style="height:100%;width:100%; overflow-y:auto; overflow-x:hidden;padding-bottom:100px;">
    <div v-if="(availableSearchedRooms.length === 0 && availableRooms.length === 0 && !$parent.loadingRooms) || noResults === true" style="float: left;color: #555;font-size: 0.8rem;">
      No Results
    </div>

    <div
      v-if="loadingRooms"
      v-show="mobileView"
      class="ml-0 ml-md-3 ml-lg-5 mView"
    >
      <h3 class="primary-colour text-left font-weight-bold">
        Loading
        <i style="margin-left: 5px !important;" class="fa fa-spin fa-spinner ml-3" />
      </h3>
    </div>

    <!-- grid View -->
    <div v-show="!listView && !loadingRooms">
      <div class="availableRoomsGrid px-3" style="min-height:fit-content;">
        <div
          v-for="room in availableSearchedRooms"
          :key="room.RoomID"
          style="border:#a1a1a1 1px solid; height: 300px; border-radius:5px;box-sizing:border-box;position:relative;width:100%;"
          class="mb-4 shadow1 justify-self-center"
        >
          <div style="height: 35%;width:100%;background:#e1e1e1;" class="d-flex align-items-center justify-content-center">
            <FontAwesomeIcon
              icon="fa-solid fa-user-group"
              size="4x"
              style="opacity:0.5; color:#555"
            />
            <FontAwesomeIcon
              icon="fa-solid fa-user-group"
              size="4x"
              style="opacity:0.5; color:#555"
            />
          </div>
          <!-- brand circle -->
          <div
            :style="{ 'background': `${room.LogoBg}`}"
            style="width:100px; height:100px;position:absolute;top:30px;left:calc(50% - 50px);border:#a1a1a1 solid 1px;border-radius:50%;"
            class="p-2 d-flex align-items-center justify-content-center"
          >
            <img :src="room.BrandingLogo" style="width:80%;object-fit:cover;margin-top:-5px;">
          </div>
          <div
            v-if="room.IsDefault == true"
            label="This is your Default Room"
            style="position:absolute; top:2px; left:2px; background: #fff; border:2px #555 solid; border-radius:50%;"
            class="d-flex align-items-center justify-content-center p-1"
          >
            <FontAwesomeIcon
              label="This is your Default Room"
              icon="fa-solid fa-hexagon-check"
              size="2x"
              style="color:#555;"
            />
          </div>

          <div style="height: 65%;background:#fff;width:100%;padding:30px 5px 5px 5px;color:#333;border-bottom-right-radius:4px;border-bottom-left-radius:4px;" class="d-flex flex-column justify-content-around">
            <div
              v-if="room.RoomName.length < 20"
              class="d-flex align-items-center mx-auto font-weight-bold"
              style="color:#333;font-size:1.4rem;margin-bottom:-20px;"
            >
              {{ room.RoomName }}
            </div>
            <div
              v-if="room.RoomName.length > 19 && room.RoomName.length < 28"
              class="d-flex align-items-center mx-auto font-weight-bold"
              style="color:#333;font-size:1.1rem;margin-bottom:-20px;"
            >
              {{ room.RoomName }}
            </div>
            <div
              v-if="room.RoomName.length > 27"
              class="d-flex align-items-center mx-auto font-weight-bold"
              style="color:#333;font-size:0.9rem;margin-bottom:-20px;"
            >
              {{ room.RoomName }}
            </div>

            <div style="width:100%;border-top: solid 1px #e1e1e1;padding-top: 5px;" class="d-flex justify-content-between align-items-center mt-1">
              <div
                v-if="mobileView === false"
                style="font-size:0.8rem;"
                class="ml-2"
              >
                <i v-if="room.IsPublic" class="fa fa-bullhorn" />
                <i v-else class="fa fa-lock" />
                {{ room.IsPublic ? 'Public' : 'Private' }}
              </div>
              <div
                v-else
                style="font-size:0.8rem;"
                class="ml-2"
              >
                <FontAwesomeIcon
                  v-if="room.IsPublic"
                  icon="fa-solid fa-bullhorn"
                  style="color:#555"
                />
                <FontAwesomeIcon
                  v-else
                  icon="fa fa-lock"
                  style="color:#555"
                />
                {{ room.IsPublic ? 'Public' : 'Private' }}
              </div>
              <div>
                <mdb-popover
                  ref="popover"
                  trigger="click"
                  :options="{placement: 'bottom'}"
                  style="cursor:pointer;"
                  class="grey-text"
                >
                  <div slot="body">
                    <h6
                      v-if="userData.uid == room.AddedById || userData.iss"
                      style="padding:5px 12px;margin-left:-10px"
                      class="text-nowrap"
                      @click="showEditRoomModal(room)"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-sync"
                        class="ml-1 grey-link"
                        style="margin-right:10px;"
                      />
                      <a class="grey-link">Edit Room</a>
                    </h6>
                    <h6
                      v-if="!room.IsDefault && userData.uid == room.AddedById"
                      style="margin-left:-10px;margin-top:-5px;padding:5px 12px;"
                      class="text-nowrap"
                      @click="setDefaultOrDeleteRoom(room.RoomId, true)"
                    >
                      <FontAwesomeIcon
                        v-if="!settingAsDefault"
                        icon="fa-solid fa-gears"
                        class="ml-1 grey-link "
                        style="margin-right:7px;"
                      />
                      <i
                        v-else
                        class="fa fa-spinner fa-spin ml-1 grey-link"
                        style="margin-right:7px;"
                      />
                      <a class="grey-link">Set As Default</a>
                    </h6>
                    <h6
                      v-if="room.GuestLink"
                      style="margin-left:-10px;padding:5px 12px;"
                      class="text-nowrap"
                      @click="copyGuestLink(room.GuestLink)"
                    >
                      <i
                        v-if="!guestLinkCopied"
                        class="fa fa-link ml-1 grey-link"
                        style="margin-right:10px;"
                      />
                      <i
                        v-else
                        class="fa fa-thumbs-up ml-1"
                        style="margin-right:10px;"
                      />
                      <a v-if="!guestLinkCopied" class="grey-link">Copy Guest Link</a>
                      <span v-else>Copied!</span>
                    </h6>
                    <h6
                      v-if="room.AssessorLink"
                      style="margin-left:-10px;padding:5px 12px;"
                      class="text-nowrap"
                      @click="copyAssessorLink(room.AssessorLink)"
                    >
                      <i
                        v-if="!assessorLinkCopied"
                        class="fa fa-link ml-1 grey-link"
                        style="margin-right:10px;"
                      />
                      <i
                        v-else
                        class="fa fa-thumbs-up ml-1"
                        style="margin-right:10px;"
                      />
                      <a v-if="!assessorLinkCopied" class="grey-link">Copy Assessor Link</a>
                      <span v-else>Copied!</span>
                    </h6>
                    <h6
                      v-if="userData.uid == room.AddedById || userData.iss || userData.rle === 'Admin' || userData.rle === 'AccountGroupAdministrator' ||userData.rle === 'Account Group Administrator'"
                      style="padding:5px 12px;margin-left:-10px"
                      class="text-nowrap"
                      @click="showDeleteRoomModal(room.RoomId)"
                    >
                      <FontAwesomeIcon
                        v-if="!deletingInProgress"
                        icon="fa-trash-can-xmark fa-solid"
                        class="ml-1 grey-link"
                        style="margin-right:10px;"
                      />
                      <i
                        v-else
                        class="fa fa-spinner fa-spin ml-1 grey-link"
                        style="margin-right:10px;"
                      />
                      <a class="grey-link">Delete Room</a>
                    </h6>
                    <h6
                      v-if="userData.uid == room.AddedById || userData.iss || userData.rle === 'Admin' || userData.rle === 'AccountGroupAdministrator' ||userData.rle === 'Account Group Administrator'"
                      style="padding:5px 12px;margin-left:-10px"
                      class="text-nowrap"
                      @click="showResetRoomModal(room.RoomId)"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-sync"
                        class="ml-1 grey-link"
                        style="margin-right:10px;"
                      />
                      <a class="grey-link">Reset Room</a>
                    </h6>
                  </div>
                  <mdb-icon
                    slot="reference"
                    style="width: 20px; height:30px; color:#555;font-size:1rem;"
                    class="d-flex align-items-center justify-content-center ellipsisButtonHover"
                    icon="ellipsis-v"
                  />
                </mdb-popover>
              </div>
            </div>
            <div>
              <mdb-btn
                style="width: 80px;padding: 10px !important;text-align: center;min-width:fit-content"
                class="m-0"
                @click="enterRoom(room.HostLink)"
              >
                ENTER ROOM
              </mdb-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- list View -->
    <div v-show="listView && !loadingRooms">
      <div class="d-flex flex-wrap justify-content-md-start" style="min-height:fit-content;width:100%;">
        <div
          v-for="room in availableSearchedRooms"
          :key="room.RoomID"
          style="border:#a1a1a1 1px solid; height: 80px; width:100%; border-radius:1px;box-sizing:border-box; position:relative;"
          class="mb-2 mx-2 mr-3 d-flex align-items-center justify-content-center"
        >
          <div style="height:101%;width:70px;background:var(--pr-color);border-top-left-radius:1px; border-bottom-left-radius:1px;color:#333" class="d-none d-sm-flex d-md-none d-lg-flex align-items-center justify-content-center px-3 py-2 ">
            <FontAwesomeIcon icon="fa-solid fa-user-group" size="2x" />
          </div>
          <div class="ml-2">
            <FontAwesomeIcon
              v-if="room.IsDefault == true"
              label="This is your Default Room"
              icon="fa-solid fa-hexagon-check ml-2"
              style="color:#c0c0c0;background:#fff;margin-right:5px;"
            />
          </div>
          <div
            v-if="room.RoomName.length < 20"
            class="mx-auto font-weight-bold titleSize"
            style="color:#333;font-size:1.4rem;width:100%;text-align:left;"
          >
            {{ room.RoomName }}
          </div>
          <div
            v-if="room.RoomName.length > 19 && room.RoomName.length < 28"
            class="d-flex mx-auto font-weight-bold titleSize"
            style="color:#333;font-size:1.1rem;width:100%;text-align:left;"
          >
            {{ room.RoomName }}
          </div>
          <div
            v-if="room.RoomName.length > 27"
            class="d-flex mx-auto font-weight-bold titleSize"
            style="color:#333;font-size:0.9rem;width:100%;text-align:left;"
          >
            {{ room.RoomName }}
          </div>
          <div class="d-flex flex-wrap align-items-center justify-content-center py-1" style="width:40%;">
            <div style="font-size:0.8rem;color:#555" class="d-flex align-items-center justify-content-center mr-1 mr-sm-2 mView">
              <i v-if="room.IsPublic" class="fa fa-bullhorn" />
              <i v-else class="fa fa-lock" />
              <span class="mView" style="margin-left:5px;">{{ room.IsPublic ? 'Public' : 'Private' }}</span>
            </div>
            <div class="d-flex align-items-center justify-content-center mx-md-2 mx-lg-3">
              <i v-if="room.IsPublic" class="fa fa-bullhorn lView" />
              <i v-else class="fa fa-lock lView" />
              <mdb-btn
                style="text-align: center;min-width:fit-content"
                class="m-0 px-2 py-1 px-md-3"
                @click="enterRoom(room.HostLink)"
              >
                ENTER <span class="mView">ROOM</span>
              </mdb-btn>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-center mr-md-2">
            <mdb-popover
              ref="popover"
              trigger="click"
              :delay-on-mouse-out="20"
              :options="{placement: 'bottom'}"
              style="cursor:pointer;"
              class="grey-text"
            >
              <div slot="body">
                <h6
                  v-if="userData.uid == room.AddedById || userData.iss"
                  style="padding:5px 12px;margin-left:-10px"
                  class="text-nowrap"
                  @click="showEditRoomModal(room)"
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-sync"
                    class="ml-1 grey-link"
                    style="margin-right:10px;"
                  />
                  <a class="grey-link">Edit Room</a>
                </h6>
                <h6
                  v-if="!room.IsDefault && userData.uid == room.AddedById"
                  style="margin-left:-10px;margin-top:-5px;padding:5px 12px;"
                  class="text-nowrap"
                  @click="setDefaultOrDeleteRoom(room.RoomId, true)"
                >
                  <FontAwesomeIcon
                    v-if="!settingAsDefault"
                    icon="fa-solid fa-gears"
                    class="ml-1 grey-link "
                    style="margin-right:7px;"
                  />
                  <i
                    v-else
                    class="fa fa-spinner fa-spin ml-1 grey-link"
                    style="margin-right:7px;"
                  />
                  <a class="grey-link">Set As Default</a>
                </h6>
                <h6
                  v-if="room.GuestLink"
                  style="margin-left:-10px;padding:5px 12px;"
                  class="text-nowrap"
                  @click="copyGuestLink(room.GuestLink)"
                >
                  <i
                    v-if="!guestLinkCopied"
                    class="fa fa-link ml-1 grey-link"
                    style="margin-right:10px;"
                  />
                  <i
                    v-else
                    class="fa fa-thumbs-up ml-1"
                    style="margin-right:10px;"
                  />
                  <a v-if="!guestLinkCopied" class="grey-link">Copy Guest Link</a>
                  <span v-else>Copied!</span>
                </h6>
                <h6
                  v-if="room.AssessorLink"
                  style="margin-left:-10px;padding:5px 12px;"
                  class="text-nowrap"
                  @click="copyAssessorLink(room.AssessorLink)"
                >
                  <i
                    v-if="!assessorLinkCopied"
                    class="fa fa-link ml-1 grey-link"
                    style="margin-right:10px;"
                  />
                  <i
                    v-else
                    class="fa fa-thumbs-up ml-1"
                    style="margin-right:10px;"
                  />
                  <a v-if="!assessorLinkCopied" class="grey-link">Copy Assessor Link</a>
                  <span v-else>Copied!</span>
                </h6>
                <h6
                  v-if="userData.uid == room.AddedById || userData.iss"
                  style="padding:5px 12px;margin-left:-10px"
                  class="text-nowrap"
                  @click="showDeleteRoomModal(room.RoomId)"
                >
                  <FontAwesomeIcon
                    v-if="!deletingInProgress"
                    icon="fa-trash-can-xmark fa-solid"
                    class="ml-1 grey-link"
                    style="margin-right:10px;"
                  />
                  <i
                    v-else
                    class="fa fa-spinner fa-spin ml-1 grey-link"
                    style="margin-right:7px;"
                  />
                  <a class="grey-link">Delete Room</a>
                </h6>
                <h6
                  v-if="userData.uid == room.AddedById || userData.iss"
                  style="padding:5px 12px;margin-left:-10px"
                  class="text-nowrap"
                  @click="resetRoomModal = true"
                >
                  <FontAwesomeIcon
                    icon="fa-sync fa-solid"
                    class="ml-1 grey-link"
                    style="margin-right:10px;"
                  />
                  <a class="grey-link">Reset Room</a>
                </h6>
              </div>
              <mdb-icon
                slot="reference"
                style="width: 20px; height:30px; color:#555;font-size:1rem;"
                class="d-flex align-items-center justify-content-center ellipsisButtonHover"
                icon="ellipsis-v"
              />
            </mdb-popover>
          </div>
        </div>
      </div>
    </div>

    <!--create room modal-->
    <CreateRoomModal
      :create-room-modal-showing="createRoomModalShowing"
      @createRoomModalShowing="$emit('createRoomModalShowing')"
    />

    <!--edit room modal-->
    <mdb-modal
      id="editRoomModal"
      :show="editRoomModal"
      @close="closeEditRoomModal()"
    >
      <mdb-modal-header>
        <mdb-modal-title>EDIT ROOM</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body style="padding:25px 40px !important; height:fit-content !important; overflow-y:auto !important;">
        <div class="d-flex align-items-center" style="height:40px">
          <FontAwesomeIcon icon="fa-solid fa-pen-line" class="d-inline mr-3" />
          <mdb-input
            v-model="editRoomName"
            type="text"
            label="Name of the Room"
            size="sm"
            class="m-0"
            maxlength="30"
            style="height:40px;width:100%;"
          />
        </div>
        <div class="d-flex align-items-center">
          <FontAwesomeIcon
            icon="fa-solid fa-video"
            class="d-inline mr-3"
            style="transform:translateY(20px)"
          />
          <mdb-select
            v-model="roomBrand"
            label="Room Brand"
            size="sm"
            class="d-inline m-0"
            style="height:50px;width:100%;"
          />
        </div>
        <div class="d-flex align-items-center mt-3" style="margin-top:35px !important;">
          <FontAwesomeIcon
            v-if="editRoomIsPublic"
            icon="fa-solid fa-bullhorn"
            class="d-inline mr-3"
          />
          <FontAwesomeIcon
            v-else
            icon="fa-solid fa-lock-keyhole"
            class="d-inline mr-3"
          />
          <mdb-switch
            v-model="editRoomIsPublic"
            style="transform:scale(0.9)"
            off-label=""
            on-label=""
            class="m-0 mt-1 mr-2"
          />
          <div style="font-size:0.8rem;color:#757575">
            {{ !editRoomIsPublic ? "Room is Private" : "Room is Public" }}
          </div>
        </div>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          v-show="!spinningFlag"
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="editRoomDetails()"
        >
          UPDATE
        </mdb-btn>
        <mdb-btn
          v-show="spinningFlag"
          color="primary"
          size="sm"
          style="width:100px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

    <!--delete modal-->
    <mdb-modal
      id="deleteRoomModal"
      :show="deleteRoomModal"
      @close="closeDeleteRoomModal()"
    >
      <mdb-modal-header>
        <mdb-modal-title>DELETE?</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          Are you sure you want to delete this room? Note: you can only delete rooms you have created, unless you have administrator priviliges!
        </p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          v-show="!spinningFlag"
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="setDefaultOrDeleteRoom(roomId, false)"
        >
          YES
        </mdb-btn>
        <mdb-btn
          v-show="spinningFlag"
          color="primary"
          size="sm"
          style="width:100px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

    <!--reset modal-->
    <mdb-modal
      id="resetRoomModal"
      :show="resetRoomModal"
      @close="closeResetRoomModal()"
    >
      <mdb-modal-header>
        <mdb-modal-title>RESET?</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          Are you sure you want to reset this room? Resetting the room can help if the assigned session has unexpectedly expired.
        </p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          v-show="!spinningFlag"
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="resetRoom(roomId)"
        >
          YES
        </mdb-btn>
        <mdb-btn
          v-show="spinningFlag"
          color="primary"
          size="sm"
          style="width:100px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { useUserData, useMeetNow, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import meetNowService from '@/api/services/meetnow.service'
import logoutService from '@/api/services/logout.service'
import accountService from '@/api/services/account.service'
import { MEET_URL } from '@/common/config.js'
import { logout } from '@/methods/auth.js'

import CreateRoomModal from './createRoomModal.vue'

export default {
  name: 'AvailableRooms',
  components: {
    CreateRoomModal
  },
  props: {
    meetNowFilterType: {
      type: String,
      default: ''
    },
    createRoomModalShowing: Boolean,
    listView: Boolean,
    search: {
      type: String,
      default: ''
    }
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const meetNowStore = useMeetNow()
    const { availableRooms, refreshMeetNowSummary } = storeToRefs(meetNowStore)

    const { closeGenericConfirmModal } = useStatus()
    const { mobileView } = useMobile()

    return {
      authData,
      userData,
      availableRooms,
      refreshMeetNowSummary,
      closeGenericConfirmModal,
      mobileView
    }
  },
  data () {
    return {
      assessorLinkCopied: false,
      guestLinkCopied: false,
      loadingRooms: true,
      deletingInProgress: false,
      settingAsDefault: false,
      noResults: false,
      deleteRoomModal: false,
      roomId: 0,
      spinningFlag: false,
      loggingOut: false,
      resetRoomModal: false,
      editRoomModal: false,
      editRoomName: '',
      editRoomIsPublic: false,
      editRoomId: 0,
      roomBrand: [],
      brandsLoading: false
    }
  },
  computed: {
    availableSearchedRooms () {
      if (this.search === '') { return this.availableRooms }
      return this.availableRooms.filter(r => r.RoomName.toLowerCase().includes(this.search.toLowerCase()))
    }
  },
  mounted () {
    this.getAllAvailableRooms()
  },
  methods: {
    logout,
    async showEditRoomModal (room) {
      this.brandsLoading = true
      this.editRoomName = room.RoomName
      this.editRoomIsPublic = room.IsPublic
      this.editRoomModal = true
      this.editRoomId = room.RoomId
      try {
        const res = await accountService.getBranding()
        this.roomBrand = res.data.map(br => br.Default === true ? { value: br.BrandingId, text: br.Name, selected: br.BrandingId === room.BrandingId } : { value: br.BrandingId, text: br.Name, selected: br.BrandingId === room.BrandingId })
        this.brandsLoading = false

      } catch (err) {
        this.brandsLoading = false
      }
   },
    closeEditRoomModal () {
      this.editRoomName = ''
      this.editRoomIsPublic = false
      this.editRoomModal = false
      this.editRoomId = 0
    },
    enterRoom (hostLink) {
      window.open(`${MEET_URL}?inv=${hostLink}`, '_blank').focus()
      this.$refs.popover.map(p => p.doClose())
    },
    async getAllAvailableRooms () {
      this.loadingRooms = true
      this.$parent.loadingRooms = true
      this.noResults = false
      try {
        const res = await meetNowService.getAllAvailableRooms()
        if (res.data === null) {
          throw Error('error fetching recordings')
        }
        this.availableRooms = res.data
        this.refreshMeetNowSummary = true
      } catch (err) {
        this.noResults = true
        if (err.response.data.Message === 'Authorization has been denied for this request.') {
          logoutService.logout().then(() => {
            this.logout().then(() => this.$router.push('/').catch(() => { }))
          }).catch(() => {
            this.logout().then(() => this.$router.push('/').catch(() => { }))
          })
        }
      }
      this.loadingRooms = false
      this.$parent.loadingRooms = false
    },
    async copyGuestLink (link) {
      try {
        await navigator.clipboard.writeText(`${MEET_URL}?inv=${link}`)
        this.guestLinkCopied = true
        setTimeout(() => {
          this.guestLinkCopied = false
          this.$refs.popover.map(p => p.doClose())
        }, 2000)
      } catch {
        //?
      }
    },
    async copyAssessorLink (link) {
      try {
        await navigator.clipboard.writeText(`${MEET_URL}?inv=${link}`)
        this.assessorLinkCopied = true
        setTimeout(() => {
          this.assessorLinkCopied = false
          this.$refs.popover.map(p => p.doClose())
        }, 2000)
      } catch {
        //?
      }
    },
    async setDefaultOrDeleteRoom (roomId, setDefault) {
      this.spinningFlag = true
      if (setDefault) { this.settingAsDefault = true }
      if (!setDefault) { this.deletingInProgress = true }
      try {
        const res = await meetNowService.updateDeleteGoMeetNowRoom(roomId, setDefault)
        this.availableRooms = res.data
        if (!setDefault) {
          this.refreshMeetNowSummary = true
        }
      } catch (err) {
        //?
      }
      this.closeGenericConfirmModal()
      this.settingAsDefault = false
      this.deletingInProgress = false
      this.roomId = 0
      this.deleteRoomModal = false
      this.spinningFlag = false
      this.$refs.popover.map(p => p.doClose())
    },
    showDeleteRoomModal (roomId) {
      this.$refs.popover.map(p => p.doClose())
      this.deleteRoomModal = true
      this.roomId = roomId
    },
    closeDeleteRoomModal () {
      this.deleteRoomModal = false
      this.roomId = 0
    },
    showResetRoomModal (roomId) {
      this.resetRoomModal = true
      this.roomId = roomId
    },
    closeResetRoomModal () {
      this.resetRoomModal = false
      this.roomId = 0
    },
    async resetRoom (roomId) {
      this.spinningFlag = true
      try {
        await meetNowService.resetRoom(roomId)
        this.spinningFlag = false
      } catch (err) {
        this.spinningFlag = false
      }
      this.resetRoomModal = false
    },
    async editRoomDetails () {
      this.spinningFlag = true
      try {
        const selectedbrandId = this.roomBrand.filter(br => br.selected)[0].value
        await meetNowService.editRoomDetails(this.editRoomId, this.editRoomName, this.editRoomIsPublic, selectedbrandId)
        this.spinningFlag = false
        this.getAllAvailableRooms()
        this.closeEditRoomModal()
      } catch (err) {
        this.spinningFlag = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 576px) {
  .availableRoomsGrid {
    display: grid;
    justify-content: space-evenly;
    grid-gap: 35px;
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1200px) {
  .availableRoomsGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 1500px) {
  .availableRoomsGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (min-width: 1700px) {
  .availableRoomsGrid {
    grid-template-columns: repeat(auto-fill, 250px);
  }
}

.lView {
  display:none;
}
@media (max-width: 576px) {
  .titleSize {
    font-size: 1rem !important;
  }
  .minWidthOnRoomName {
    width: 90%;
    min-width: none;
  }
  .wider {
    width:80% !important;
  }
  .mView {
    display:none !important;
  }
  .lView {
    display:block;
    margin-right:10px;
    color: #333;
  }
}
@media (min-width: 576px) {
  .minWidthOnRoomName {
    width: none;
    min-width: 63%;
  }
}
@media (min-width: 768px) {
  .minWidthOnRoomName {
    width: none;
    min-width: 50%;
  }
}
</style>
