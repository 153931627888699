import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useMeetNow = defineStore('meetNowStore', () => {
  const meetNowFilterType = ref('')
  const availableRooms = ref([])
  const myMeetNow = ref([])
  const publicMeetNow = ref([])
  const archivedMeetNow = ref([])
  const refreshMeetNowSummary = ref(false)

  const setRecordings = ({ rooms, roomType }) => {
    if (roomType === 'Private') {
      myMeetNow.value = rooms
    } else if (roomType === 'Public') {
      publicMeetNow.value = rooms
    } else if (roomType === 'Archived') {
      archivedMeetNow.value = rooms
    }
  }

  const addOrUpdateRecording = (payload) => {
    let rooms = []
    if (meetNowFilterType.value === 'My Recordings') {
      rooms = myMeetNow.value
    } else if (meetNowFilterType.value === 'Public Recordings') {
      rooms = publicMeetNow.value
    } else {
      // must be archived recordings
      rooms = archivedMeetNow.value
    }
    const index = rooms.findIndex(r => r.RecordingId === payload.RecordingId)

    if (index !== -1 && payload.RemoveFromCurrentView) {
      // if removing instance from current view
      rooms.splice(index, 1)
    } else if (index !== -1) {
      // replacing instance in current view
      rooms.splice(index, 1, payload)
    } else if (index === -1) {
      // adding
      rooms.push(payload)
    }
  }

  const addOrUpdateRoom = (payload) => {
    const index = availableRooms.value.findIndex(r => r.RoomId === payload.RoomId)
    if (index !== -1) {
      availableRooms.value.splice(index, 1, payload)
    } else {
      availableRooms.value.unshift(payload)
    }
  }

  // reset state
  // refresh meet now summ
  // set meet now filter type
  // set available rooms

  return {
    addOrUpdateRoom,
    addOrUpdateRecording,
    archivedMeetNow,
    availableRooms,
    meetNowFilterType,
    myMeetNow,
    publicMeetNow,
    refreshMeetNowSummary,
    setRecordings,
  }
})
