<template>
  <div
    ref="videoLibraryPage"
    class="col-sm templateDiv vLib"
    :class="mobileView ? 'pt-3' : 'pt-0'"
    style="height:calc(100vh - 80px);overflow-y:auto;margin-bottom: 100px;"
  >
    <div style="height:fit-content">
      <div>
        <div class="font-weight-bold text-left my-3 d-flex align-items-center justify-content-between" style="height:50px">
          <div class="d-flex align-items-center">
            <span v-if="libraryLoaded === false && visibleLibrary.length === 0">
              Loading <i class="fa fa-spin fa-spinner" style="margin-top: 0px;" />
            </span>
            <span v-else>
              {{ libraryTitle }}
            </span>
          </div>
          <ExpandableSearchBar
            :width="300"
            :max-widthpercentage="30"
            @search="currentSearch = $event"
          />

          <div class="ml-3 d-flex">
            <div
              style="cursor:pointer;border-radius:50%;height:40px;width:40px;"
              class="listViewSelected align-items-center justify-content-center mr-1 d-none d-md-flex"
              :style="{'background' : listView === false ? `${userData.lbl.PrimaryColour}66` : 'none'}"
            >
              <FontAwesomeIcon
                icon="fa-solid fa-grid"
                style="height:25px;width:25px;"
                @click="listView = false"
              />
            </div>
            <div
              style="cursor:pointer;border-radius:50%;height:30px;width:30px;"
              class="listViewSelected align-items-center justify-content-center mr-1 d-flex d-md-none"
              :style="{'background' : listView === false ? `${userData.lbl.PrimaryColour}66` : 'none'}"
            >
              <FontAwesomeIcon
                icon="fa-solid fa-grid"
                style="height:17px;width:17px;"
                @click="listView = false"
              />
            </div>
            <div
              style="cursor:pointer;border-radius:50%;height:40px;width:40px;"
              class="listViewSelected align-items-center justify-content-center mr-1 d-none d-md-flex"
              :style="{'background' : listView === true ? `${userData.lbl.PrimaryColour}66` : 'none'}"
            >
              <FontAwesomeIcon
                icon="fa-solid fa-list-ul"
                style="height:25px;width:25px;"
                @click="listView = true"
              />
            </div>
            <div
              style="cursor:pointer;border-radius:50%;height:30px;width:30px;"
              class="listViewSelected align-items-center justify-content-center mr-1 d-flex d-md-none"
              :style="{'background' : listView === true ? `${userData.lbl.PrimaryColour}66` : 'none'}"
            >
              <FontAwesomeIcon
                icon="fa-solid fa-list-ul"
                style="height:17px;width:17px;"
                @click="listView = true"
              />
            </div>
          </div>
        </div>
        <b-row
          no-gutters
          style="margin-top:0px;margin-bottom:30px;width80%;"
          class="width100"
        >
          <div
            v-if="visibleLibrary.length !== 0 || libraryLoaded"
            class="grey-text small text-left"
            :class="{ 'noVidsLeft': mobileView }"
            style=""
          >
            <span v-show="libraryTitle === 'Job Videos'">Videos assigned to a specific Shine Interview that is descriptive to the actual role.</span>
            <span v-show="libraryTitle === 'Organisation Videos'">Videos assigned to a specific Shine Interview that is descriptive to the organisation itself.</span>
            <span v-show="libraryTitle === 'Shareable Videos'">Videos available for sharing e.g. on your LinkedIn account.</span>
            <!--VCVX<span v-show="libraryTitle === 'Introduction Videos'">Videos introductions (specific to a role, not to be confused with Shine Intros).</span>-->
            <span v-show="libraryTitle === 'Introduction Videos'">Videos introductions (specific to a role).</span>
            <span v-show="libraryTitle === 'Outro Videos'">Videos assigned to a specific Shine Interview once the candidate has completed answering all of their questions e.g. explaining what happens next.</span>
            <span v-show="libraryTitle === 'Question Videos'">Video questions assigned to a specific Shine Interview.</span>
            <span v-show="libraryTitle === 'Candidate Videos'">Video questions assigned to be used for candidate screening.</span>
            <span v-show="libraryTitle === 'All Videos'">All available videos.</span>
          </div>
          <div
            v-else
            class="grey-text small text-left"
            :class="{ 'noVidsLeft': mobileView }"
          >
            <span><br></span>
          </div>
        </b-row>
        <b-row
          v-show="libraryLoaded && visibleLibrary.length === 0"
          no-gutters
          class="mt-3"
        >
          <div
            class="grey-text small text-left"
            style="font-weight:bold;"
            :class="{ 'noVidsLeft': mobileView }"
          >
            No videos available.
          </div>
        </b-row>
      </div>
      <div
        v-show="visibleLibrary.length > 0"
        style="height:calc(100vh - 240px);overflow-y:auto;overflow-x:hidden;"
        class="mt-3"
      >
        <div :class="{ 'videoCardGrid': listView === false }" style="min-height:fit-content;">
          <div
            v-for="video in visibleLibrary"
            v-show="video.IsDeleted === false"
            :key="video.GuidReference"
            :class="{ 'bordering': listView === false, 'listViewWidth': listView }"
            style="border-radius:5px;box-sizing:border-box;"
            class="mb-4 shadow1 justify-items-center"
          >
            <VideoLibraryCard
              v-show="video.IsDeleted === false"
              :list-view="listView"
              :user-token="authData.access_token"
              :video="video"
            />
          </div>
        </div>
      </div>

      <div class="fixed bottom-0 botVidBut" style="margin-bottom:-1px;background:#d1d1d1;padding:10px;border-top: 1px solid #a1a1a1;z-index:1;">
        <div class="row" style="margin-left:25px;">
          <div class="col-sm-12 text-center">
            <mdb-btn
              v-show="hasVideo"
              class="btn-outline"
              size="sm"
              style="width:110px;border:solid 2px !important;background:none !important;padding: 0.5rem 1.1rem;"
              @click="recordButton()"
            >
              <i class="fa fa-circle" /> RECORD
            </mdb-btn>
            <mdb-btn
              class="btn btn-solid-outline-web"
              size="sm"
              style="width:110px;padding: 0.5rem 1.1rem;"
              @click="uploadVideo = true"
            >
              <i class="fa fa-upload" /> UPLOAD
            </mdb-btn>
          </div>
        </div>
      </div>

      <div
        v-show="mobileView"
        class="fixed bottom-0"
        style="background:white;width:100%;border-top: 1px solid #a1a1a1;z-index:1049;margin-left:-30px;height:55px;"
      >
        <div class="row" style="margin-top:5px;">
          <div class="col-sm-12 text-center">
            <mdb-btn
              v-show="hasVideo"
              class="fl btn-outline"
              size="sm"
              style="width:110px;margin-left:20px;border:solid 2px !important;background:none !important;padding: 0.36rem 1.1rem;"
              @click="recordButton()"
            >
              <i class="fa fa-circle" /> RECORD
            </mdb-btn>
            <mdb-btn
              class="fr btn btn-solid-outline-mob"
              size="sm"
              style="width:110px;padding: 0.4rem 1.1rem;"
              @click="uploadVideo = true"
            >
              <i class="fa fa-upload" /> UPLOAD
            </mdb-btn>
          </div>
        </div>
      </div>

      <!--record video modal-->
      <mdb-modal
        id="recordVideo"
        :show="recordVideo"
        style="z-index:10000;"
        @close="recordButton(),resetVideo()"
      >
        <mdb-modal-header>
          <mdb-modal-title>RECORD VIDEO</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body :class="{ 'modalHeight': mobileView }">
          <div class="small grey-text text-left" style="margin-left:7px;margin-bottom:10px;">
            The maximum recording time is 5 minutes.
          </div>
          <div id="videoContainer" class="mx-auto p-2">
            <video
              v-if="!stopButtonPressed"
              ref="video"
              height="380"
              width="640"
              type="video/mp4"
              playsinline
              muted
              autoplay
              class="video-fluid z-depth-1"
              style="outline:none;margin: 0px;border-radius: 2px;height: 250px;object-fit: cover"
            />
            <video
              v-if="stopButtonPressed"
              ref="videoRec"
              height="380"
              width="640"
              type="video/mp4"
              playsinline
              controls
              class="video-fluid z-depth-1"
              style="outline:none;margin: 0px;border-radius: 2px;height: 250px;object-fit: cover"
            />
            <span
              id="time"
              style="float:right;margin-top:10px;"
              class="grey-text"
            >5:00</span>
          </div>
          <div v-show="blobRecording && !uploadSuccess && !uploadStarted">
            <validation-observer>
              <form
                ref="form"
                @submit.prevent="upload()"
                @reset.prevent="reset"
              >
                <div class="md-form form-sm text-left" style="margin-left:10px;margin-right:10px;">
                  <i class="fas fa-video prefix float-left" style="margin-top: 6px;font-size: 1.3rem;" />
                  <mdb-input
                    v-model="title"
                    type="text"
                    auto-complete="fna"
                    placeholder="Video Title"
                    style="margin-left:33px;"
                    size="sm"
                  />
                </div>
                <div class="md-form form-sm text-left" style="margin-left:10px;margin-right:10px;">
                  <i class="fas fa-object-group prefix" style="margin-top: 6px;font-size: 1.3rem;" />
                  <mdb-select
                    v-model="videoCategories"
                    placeholder="Select Category"
                    style="margin-left:33px;"
                    size="sm"
                  />
                </div>
                <div class="md-form form-sm text-left" style="margin-left:10px;margin-right:10px;">
                  <i class="fas fa-tags prefix" style="margin-top: 6px;font-size: 1.3rem;" />
                  <videotaginput
                    :video-tags="videoTags"
                    @removeTag="removeTag"
                    @addTag="addTag"
                  />
                </div>
              </form>
            </validation-observer>
          </div>
        </mdb-modal-body>
        <mdb-modal-footer v-show="!uploadSuccess" style="display:block;height:100px !important;">
          <mdb-btn
            v-if="!recordStart && !blobRecording"
            size="sm"
            color="primary"
            :disabled="!stream"
            style="width:110px;margin-top:15px;"
            @click="startRecord"
          >
            <i class="fa fa-circle" /> RECORD
          </mdb-btn>
          <mdb-btn
            v-if="recordStart && !blobRecording"
            size="sm"
            color="primary"
            style="width:110px;margin-top:15px;"
            @click="stopRecord"
          >
            <i class="fa fa-square" /> STOP
          </mdb-btn>
          <mdb-btn
            v-if="blobRecording && !uploadSuccess && !uploadStarted"
            size="sm"
            style="width:110px;margin-top:15px;"
            @click="resetVideo()"
          >
            <i class="fa fa-redo" /> RESET
          </mdb-btn>
          <mdb-btn
            v-if="blobRecording && !uploadSuccess && !uploadStarted"
            size="sm"
            :disabled="(title === null || title === '') || videoCategories.filter(i => i.selected).length === 0"
            style="width:110px;margin-top:15px;"
            @click="upload()"
          >
            <i class="fa fa-upload" /> UPLOAD
          </mdb-btn>
          <mdb-progress
            v-if="uploadStarted"
            indeterminate
            style="margin-right:10px;margin-top:10px;"
          />
          <mdb-btn
            v-show="uploadStarted"
            size="sm"
            style="width:110px;"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>

      <!--modal for video library upload-->
      <mdb-modal
        id="uploadVideo"
        :show="uploadVideo"
        style="z-index:10000;"
        @close="uploadButton()"
      >
        <mdb-modal-header>
          <mdb-modal-title>UPLOAD VIDEO</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body :class="{ 'modalHeight': mobileView }">
          <validation-observer>
            <form
              ref="form"
              @submit.prevent="fileUpload()"
              @reset.prevent="reset"
            >
              <div class="md-form form-sm text-left" style="margin-left:10px;margin-right:10px;margin-top:-15px;">
                <i class="fas fa-video prefix float-left" style="margin-top: 6px;font-size: 1.3rem;" />
                <mdb-input
                  v-model="title"
                  type="text"
                  auto-complete="fna"
                  placeholder="Video Name"
                  style="margin-left:33px;"
                  size="sm"
                />
              </div>
              <div class="md-form form-sm text-left" style="margin-left:10px;margin-right:10px;">
                <i class="fas fa-object-group prefix" style="margin-top: 6px;font-size: 1.3rem;" />
                <mdb-select
                  v-model="videoCategories"
                  placeholder="Select Video Category"
                  style="margin-left:33px;"
                  size="sm"
                />
              </div>
              <div class="md-form form-sm text-left" style="margin-left:10px;margin-right:10px;">
                <i class="fas fa-upload prefix float-left" style="margin-top: 6px;font-size: 1.3rem;" />
                <div class="file-field md-form form-sm text-left" style="margin-left:25px">
                  <div
                    class="btn btn-primary btn-sm float-right"
                    style="margin-right:0px"
                    @click="launchFilePicker"
                  >
                    <span>Choose file</span>
                    <input
                      ref="file"
                      v-uploader
                      type="file"
                      style="display:none"
                      accept="audio/mp4,video/mp4"
                    >
                  </div>
                  <div class="file-path-wrapper" style="font-size:14px;color:#495057;">
                    <input
                      class="file-path validate"
                      type="text"
                      placeholder="Choose your video to upload"
                      :value="file.name"
                      readonly
                    >
                  </div>
                </div>
              </div>
              <div class="md-form form-sm text-left" style="margin-left:10px;margin-right:10px;">
                <i class="fas fa-tags prefix" style="margin-top: 6px;font-size: 1.3rem;" />
                <videotaginput
                  :video-tags="videoTags"
                  @addTag="addTag"
                  @removeTag="removeTag"
                />
              </div>
            </form>
          </validation-observer>
        </mdb-modal-body>
        <mdb-modal-footer v-show="!uploadSuccess" style="display:block;height:100px !important;">
          <mdb-btn
            v-show="uploadStarted === false"
            type="reset"
            class="float-left btn-outline"
            size="sm"
            style="width:100px;margin-top:15px;"
            @click="uploadButton()"
          >
            CANCEL
          </mdb-btn>
          <mdb-btn
            v-show="!uploadStarted && !uploadSuccess"
            type="submit"
            :disabled="(title === null || title === '') || videoCategories.filter(i => i.selected).length === 0"
            class="float-right"
            size="sm"
            style="width:100px;margin-top:15px;"
            @click="fileUpload()"
          >
            <span>SAVE</span>
          </mdb-btn>
          <mdb-progress
            v-if="uploadStarted"
            indeterminate
            style="margin-right:10px;margin-top:10px;"
          />
          <mdb-btn
            v-show="uploadStarted"
            size="sm"
            style="width:100px;"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>
    </div>

    <!--error modal-->
    <mdb-modal
      id="errorModal"
      :show="errorModal"
      @close="errorModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>{{ errorModalTitle.toUpperCase() }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          <span v-html="error" />
        </p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="errorModal = false"
        >
          OK
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { useLibrary, useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import libraryService from '@/api/services/library.service'
import UploadFile from '../../upload'

import VideoLibraryCard from '../../components/videoLibraryCard.vue'
import videotaginput from '../../components/videoTagInput'
import ExpandableSearchBar from '@/components/UI/expandableSearch.vue'

export default {
  name: 'Videolibrary',
  directives: {
    uploader: {
      bind (el, binding, vnode) {
        el.addEventListener('change', e => {
          if (e.target.files[0] !== undefined) {
            vnode.context.file = e.target.files[0]
          }
        })
      }
    }
  },
  components: {
    VideoLibraryCard,
    videotaginput,
    ExpandableSearchBar
  },
  setup () {
    const userStore = useUserData()
    const { userData, authData, videoLibraryFilter } = storeToRefs(userStore)

    const libraryStore = useLibrary()
    const { libraryNumbers } = storeToRefs(libraryStore)
    const { mobileView } = useMobile()

    return {
      libraryNumbers,
      userData,
      authData,
      videoLibraryFilter,
      mobileView
    }
  },
  data () {
    return {
      windowWidth: window.innerWidth,
      libraryLoaded: false,
      libraryTitle: 'Organisation Videos',
      library: [],
      visibleLibrary: [],
      currentFilter: '',
      currentSearch: '',
      videoCategories: [{ text: 'Organisation', value: 1 }, { text: 'Job', value: 2 }, { text: 'Shareable', value: 3 }, { text: 'Introduction', value: 4 }, { text: 'Outro', value: 5 }, { text: 'Question', value: 6 }, { text: 'Candidate', value: 7 }],
      recordVideo: false,
      uploadVideo: false,
      settingsClicked: false,
      selectedVideoCategory: 'All Videos',
      hasVideo: false,
      recordedFile: null,
      stream: null,
      blobRecording: null,
      recordStart: false,
      record: null,
      recordChunks: [],
      uploadStarted: false,
      uploadSuccess: false,
      file: '',
      title: null,
      videoTags: [],
      stopButtonPressed: false,
      time: 300,
      showtime: '5:00',
      timer: null,
      listView: false,
      errorModalTitle: '!',
      errorModal: false,
      error: ''
    }
  },
  computed: {
    queryStringCategory () {
      return this.$route.query.mm
    }
  },
  watch: {
    videoLibraryFilter: {
      handler: function (newFilter) {
        this.libraryLoaded = false
        if (newFilter !== 'All' && newFilter !== undefined) {
          this.visibleLibrary = this.library.filter(i => i.VideoTypeDesc === newFilter || i.VideoTypeDesc === 'Video ' + newFilter).sort((vid1, vid2) => { return this.orderByDate(vid1, vid2) })
          this.libraryTitle = newFilter + ' Videos'
          setTimeout(() => { this.libraryLoaded = true }, 3000)
        } else {
          this.visibleLibrary = this.library // .sort((vid1, vid2) => { return this.orderAlphabetically(vid1, vid2) })
          this.libraryTitle = 'All Videos'
          setTimeout(() => { this.libraryLoaded = true }, 3000)
        }
      },
      immediate: true
    },
    queryStringCategory: {
      handler: function (newFilter) {
        this.libraryLoaded = false
        if (newFilter !== undefined) {
          this.visibleLibrary = this.library.filter(i => i.VideoTypeDesc === newFilter || i.VideoTypeDesc === 'Video ' + newFilter).sort((vid1, vid2) => { return this.orderByDate(vid1, vid2) })
          this.libraryTitle = newFilter + ' Videos'
          setTimeout(() => { this.libraryLoaded = true }, 3000)
        } else {
          this.visibleLibrary = this.library // .sort((vid1, vid2) => { return this.orderAlphabetically(vid1, vid2) })
          this.libraryTitle = 'All Videos'
          setTimeout(() => { this.libraryLoaded = true }, 3000)
        }
      },
      immediate: true
    },
    currentSearch () {
      this.searchVideos()
    },
    file (val) {
      this.$emit('file-chosen', val)
    }
  },
  created () {
    this.checkInitialVideoCategory()
    window.addEventListener('onscroll', this.infScrollTrigger)
    window.addEventListener('wheel', this.infScrollTrigger)
  },
  beforeDestroy () {
    window.removeEventListener('onscroll', this.infScrollTrigger)
    window.removeEventListener('wheel', this.infScrollTrigger)
  },
  updated () {
    if (this.recordVideo === true && this.stopButtonPressed === false) {
      this.turnOnCamera()
    } else {
      this.turnOffCamera()
    }
  },
  async mounted () {
    const availableDevices = await navigator.mediaDevices.enumerateDevices()
    if (availableDevices.length > 0) {
      const videoDevices = availableDevices.filter(i => i.kind === 'videoinput')
      if (videoDevices.length > 0) {
        this.hasVideo = true
      }
    }
  },
  methods: {
    checkInitialVideoCategory () {
      if (this.queryStringCategory === 'Organisation') { // organisation (mobile menu query)
        this.getLibrary(1, -1)
        this.libraryTitle = 'Organisation Videos'
        this.videoLibraryFilter = 'Organistion'
      } else if (this.queryStringCategory === 'Job') { // job (mobile menu query)
        this.getLibrary(2, -1)
        this.libraryTitle = 'Job Videos'
        this.videoLibraryFilter = 'Job'
      } else if (this.queryStringCategory === 'Shareable') { // shareable (mobile menu query)
        this.getLibrary(2, -1)
        this.libraryTitle = 'Shareable Videos'
        this.videoLibraryFilter = 'Sharable'
      } else if (this.queryStringCategory === 'Introduction') { // introduction (mobile menu query)
        this.getLibrary(2, -1)
        this.libraryTitle = 'Introduction Videos'
        this.videoLibraryFilter = 'Introduction'
      } else if (this.queryStringCategory === 'Outro') { // outro (mobile menu query)
        this.getLibrary(2, -1)
        this.libraryTitle = 'Outro Videos'
        this.videoLibraryFilter = 'Outro'
      } else if (this.queryStringCategory === 'Question') { // question (mobile menu query)
        this.getLibrary(2, -1)
        this.libraryTitle = 'Question Videos'
        this.videoLibraryFilter = 'Question'
      } else if (this.queryStringCategory === 'Candidate') { // candidate (mobile menu query)
        this.getLibrary(2, -1)
        this.libraryTitle = 'Candidate Videos'
        this.videoLibraryFilter = 'Candidate'
      } else {
        this.getLibrary(-1, -1) // all (load all by default)
        this.libraryTitle = 'All Videos'
        this.videoLibraryFilter = 'All'
      }
    },
    getLibrary (filterType, lastVideoId) {
      var lastId = -1
      if (lastVideoId) {
        lastId = lastVideoId
      }
      libraryService.getLibrary(filterType, lastId).then((response) => {
        var formattedLibrary = response.data
        formattedLibrary.forEach(function (i) {
          if (!i.Tags) {
            i.Tags = ''
          }
        })
        this.visibleLibrary = this.library
        if (this.library.length === 0) {
          this.library = formattedLibrary // .sort((vid1, vid2) => { return this.orderAlphabetically(vid1, vid2) })
          this.visibleLibrary = formattedLibrary // .sort((vid1, vid2) => { return this.orderAlphabetically(vid1, vid2) })
        } else {
          const oldLibrary = [...this.library]
          formattedLibrary.forEach(f => {
            var included = false
            oldLibrary.forEach(o => {
              if (o.GuidReference === f.GuidReference) {
                included = true
              }
            })
            if (!included) {
              oldLibrary.push(f)
            }
          })
          this.library = oldLibrary // .sort((vid1, vid2) => { return this.orderAlphabetically(vid1, vid2) })
          this.visibleLibrary = this.library // .sort((vid1, vid2) => { return this.orderAlphabetically(vid1, vid2) })
        }
        setTimeout(() => { this.libraryLoaded = true }, 3000)
        this.visibleLibrary.forEach(f => {
          if (f.Processing) {
            this.isStillProcessing()
          }
        })
        this.getLibraryNumbers()
      }).catch(() => {
        setTimeout(() => { this.libraryLoaded = true }, 3000)
      })
    },
    async getLibraryNumbers () {
      try {
        const res = await libraryService.getLibraryNumbers()
        this.libraryNumbers = res.data
      } catch (err) {
        //?
      }
    },
    isStillProcessing () {
      this.visibleLibrary.forEach(f => {
        if (f.Processing) {
          setTimeout(() => {
            libraryService.isStillProcessing(f.GuidReference).then((response) => {
              if (response.data) {
                f.Processing = false
                f.FileName = f.FileName.replace('.webm.', '.mp4')
              } else {
                this.isStillProcessing()
              }
            })
          }, 2500)
        }
      })
    },
    launchFilePicker () {
      this.$refs.file.click()
    },
    orderAlphabetically (object1, object2) {
      try {
        const obj1 = object1.VideoTitle.toUpperCase()
        const obj2 = object2.VideoTitle.toUpperCase()

        if (obj1 < obj2) {
          return -1
        }
        if (obj1 > obj2) {
          return 1
        }
        return 0
      } catch { return 0 }
    },
    orderByDate (a, b) {
      try {
        if (Date.parse(a.LastUpdatedDate) < Date.parse(b.LastUpdatedDate)) {
          return 1
        } else if (Date.parse(a.LastUpdatedDate) > Date.parse(b.LastUpdatedDate)) {
          return -1
        } else {
          return 0
        }
      } catch { return 0 }
    },
    searchVideos () {
      this.visibleLibrary = this.library.filter(i => i.VideoTitle.toLowerCase().indexOf(this.currentSearch.toLowerCase()) > -1 || i.Tags.includes(this.currentSearch)).sort((vid1, vid2) => { return this.orderAlphabetically(vid1, vid2) })
    },
    async infScrollTrigger () {
      const element = this.$refs.videoLibraryPage.getBoundingClientRect()

      if (element.offsetHeight >= element.scrollHeight) {
        return
      }

      const hitBottom = element.bottom < window.innerHeight + 50

      var lastVideoId = -1
      let lastVideoIndex = -1
      if (this.library.length > 0) {
        lastVideoIndex = this.library.length - 1
        lastVideoId = this.library[lastVideoIndex].LastId
        const total = this.library[lastVideoIndex].TotalCount
        if (hitBottom && this.library.length < total) {
          this.getLibrary(-1, lastVideoId)
          window.scrollTo(0, 0)
          setTimeout(resolve => resolve, 500)
        }
      }
    },
    resetVideo () {
      this.uploadSuccess = false
      this.recordStart = false
      clearInterval(this.timer)
      this.time = 300
      try {
        var display = document.querySelector('#time')
        display.textContent = '5:00'
      } catch (err) {
        //?
      }
      this.blobRecording = null
      this.stopButtonPressed = false
      this.title = ''
      this.videoTags = []
      this.videoCategories = [{ text: 'Organisation', value: 1 }, { text: 'Job', value: 2 }, { text: 'Shareable', value: 3 }, { text: 'Introduction', value: 4 }, { text: 'Outro', value: 5 }, { text: 'Question', value: 6 }, { text: 'Candidate', value: 7 }, { text: 'All', value: 0 }]
      this.turnOffCamera()
      this.stream = null
    },
    recordButton () {
      this.stopButtonPressed = false
      this.title = ''
      this.videoTags = []
      this.videoCategories = [{ text: 'Organisation', value: 1 }, { text: 'Job', value: 2 }, { text: 'Shareable', value: 3 }, { text: 'Introduction', value: 4 }, { text: 'Outro', value: 5 }, { text: 'Question', value: 6 }, { text: 'Candidate', value: 7 }, { text: 'All', value: 0 }]
      if (this.recordVideo === true) {
        this.recordVideo = false
        this.turnOffCamera()
      } else {
        this.recordVideo = true
      }
    },
    uploadButton () {
      this.uploadStarted = false
      this.uploadSuccess = false
      this.title = ''
      this.videoTags = null
      this.file = ''
      this.videoCategories = [{ text: 'Organisation', value: 1 }, { text: 'Job', value: 2 }, { text: 'Shareable', value: 3 }, { text: 'Introduction', value: 4 }, { text: 'Outro', value: 5 }, { text: 'Question', value: 6 }, { text: 'Candidate', value: 7 }, { text: 'All', value: 0 }]
      this.reset()
      if (this.uploadVideo === true) {
        this.uploadVideo = false
      } else {
        this.uploadVideo = true
      }
    },
    async turnOnCamera () {
      try {
        if (this.stream === null) {
          const mstream = await navigator.mediaDevices.getUserMedia({
            video: {
              advanced: [
                { width: { exact: 4096 } },
                { width: { exact: 3840 } },
                { width: { exact: 2560 } },
                { width: { exact: 1920 } },
                { width: { exact: 1280 } },
                { width: { exact: 1024 } },
                { width: { exact: 900 } },
                { width: { exact: 800 } },
                { width: { exact: 640 } },
                { width: { exact: 320 } }
              ]
            },
            audio: true
          })
          this.stream = mstream
          this.$refs.video.srcObject = mstream
          this.$emit('streamOn', this.stream)
        }
      } catch (err) {
        //?
      }
    },
    async turnOffCamera () {
      if (this.stream !== null) {
        this.stream.getTracks().forEach(track => track.stop())
      }
    },
    pad (val) {
      return val > 9 ? val : '0' + val
    },
    startRecord () {
      var display = document.querySelector('#time')
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time--
            var t = this.time / 60
            var minutes = parseInt(t)
            var seconds = Math.round((t - minutes) * 60)
            display.textContent = this.pad(minutes) + ':' + this.pad(seconds)
          } else {
            clearInterval(this.timer)
            this.stopRecord()
          }
        }, 1000)
      }
      this.stopButtonPressed = false
      const vm = this
      const record = new MediaRecorder(this.stream)
      this.recorder = record
      this.recorder.ondataavailable = function (recdata) {
        vm.recordChunks.push(recdata.data)
      }
      this.recorder.onstop = function () {
        this.stopButtonPressed = true
        const newBlob = new Blob(vm.recordChunks, { type: 'video/webm' })
        const video = vm.$refs.videoRec
        video.srcObject = null
        video.src = URL.createObjectURL(newBlob)
        video.load()
        vm.recordChunks = []
        vm.blobRecording = newBlob
        this.blobRecording = newBlob
      }
      this.recorder.start()
      this.recordStart = true
    },
    stopRecord () {
      clearInterval(this.timer)
      this.timer = null
      this.isRunning = false
      this.recorder.stop()
      this.recordStart = false
      this.stopButtonPressed = true
      this.turnOffCamera()
      if (this.recorder !== null) {
        if (this.recorder.stream != null) {
          this.recorder.stream.getTracks().forEach(track => track.stop())
        }
      }
    },
    async upload () {
      const data = this.blobRecording
      const cat = this.videoCategories.filter(i => i.selected)
      const vData = {
        userEmail: this.userData.une,
        videoType: cat[0].value,
        shortDescription: this.title,
        videoTitle: this.title,
        availableToAll: false,
        allowShare: false,
        selectedVideo: '',
        hasForm: false,
        videoText: '',
        videoTags: this.videoTags
      }
      this.uploadStarted = true
      const uploadSuccess = await UploadFile.uploadVideo(0, data, vData)
      this.uploadStarted = false
      this.uploadSuccess = false
      if (typeof uploadSuccess === 'boolean') {
        this.uploadSuccess = uploadSuccess
      }
      if (typeof uploadSuccess === 'number') {
        this.uploadSuccess = true
      }
      if (this.uploadSuccess) {
        this.resetVideo()
        this.recordButton()
        this.library = []
        this.getLibrary(-1, -1)
        this.turnOffCamera()
        this.libraryLoaded = false
      } else {
        this.errorModalTitle = 'Error!'
        this.errorModal = true
        this.error = 'We have detected a possible error processing your video, please check it has successfully uploaded!'
        this.resetVideo()
        this.recordButton()
        this.library = []
        this.getLibrary(-1, -1)
        this.turnOffCamera()
        this.libraryLoaded = false
      }
      this.$emit('recordSuccess')
    },
    async fileUpload () {
      const selected = this.videoCategories.filter(i => i.selected)
      const vData = {
        userEmail: this.userData.une,
        videoType: selected[0].value,
        shortDescription: this.title,
        videoTitle: this.title,
        availableToAll: false,
        allowShare: false,
        selectedVideo: '',
        hasForm: false,
        videoText: '',
        videoTags: this.videoTags
      }
      this.uploadStarted = true
      const uploadSuccess = await UploadFile.uploadVideo(1, this.file, vData)
      this.uploadStarted = false
      this.UploadFile = false
      this.uploadSuccess = uploadSuccess
      if (uploadSuccess) {
        this.uploadButton()
        this.library = []
        this.visibleLibrary = []
        this.getLibrary(-1, -1)
      }
      this.$emit('uploadSuccess')
    },
    removeTag (e) {
      this.videoTags.splice(e, 1)
    },
    addTag: function (e) {
      if (this.videoTags != null) {
        if (!this.videoTags.includes(e) && e !== '') {
          this.videoTags.push(e)
          e = ''
        } else {
          e = ''
        }
      }
    },
    reset () {
      this.$refs.form.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
  .listViewWidth {
    width:100% !important;
  }
  @media (min-width: 576px) {
    .videoCardGrid {
      display: grid;
      justify-content: space-evenly;
      grid-gap: 35px;
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (min-width: 1024px) {
    .videoCardGrid {
      display: grid;
      justify-content: space-evenly;
      grid-gap: 35px;
      grid-template-columns: 1fr 1fr;
      }
  }
  @media (min-width: 1300px) {
    .videoCardGrid {
      grid-template-columns: repeat(auto-fill, 270px);
      justify-content: start;
      }
  }
  @media (min-width: 1400px) {
    .videoCardGrid {
      grid-template-columns: repeat(auto-fill, 290px);
      justify-content: start;
      }
  }
  @media (min-width: 1500px) {
    .videoCardGrid {
      grid-template-columns: repeat(auto-fill, 240px);
    }
  }
  .bordering {
    border:#a1a1a1 1px solid;
    height: 290px;
  }
  .m-left {
    margin-left: -15px;
  }
  .loadingMargins {
    margin-top:50px !important;
    margin-left:50px !important;
  }
  .noVidsLeft {
    left:30px !important;
  }
  .mobileGridRight {
    margin-right: 25px;
    margin-bottom: 180px;
  }
  .mobileSearchTop {
    margin-top: 16px;
    margin-right: 0px !important;
  }
  video {
    border-radius: 25px;
    margin: 10px;
  }
  #videoContainer {
    border: 2px #000;
    border-radius: 10px;
  }
  .md-progress {
    width: auto;
    margin-left: 10px;
  }
  .mobileContainer {
    padding: 0px !important;
    margin-left: -40px !important;
    width: calc(100% - -30px) !important;
  }
  .mLeft {
    margin-left:10px !important;
  }
  .vMobTop {
    margin-top:30px !important;
  }
</style>
<style>
  .md-progress .indeterminate {
    background-color: var(--pr-color) !important;
  }
  @media (max-width: 576px) {
  .wider {
    width:80% !important;
  }
  .wider95 {
    width:95% !important;
  }
  .width100 {
    width: 100% !important;
  }
}
</style>
