<template>
  <div
    v-if="!loading"
    class="flex flex-col w-full mt-5"
  >
    <span class="text-left grey-text font-weight-bold mb-3"> Add Location</span>
    <div class="w-full grey-text ml-3">
      <div class="flex flex-row items-center locationForm">
        <font-awesome-icon
          :icon="['fas', 'location-dot']"
          class="mr-2 text-primary"
        />
        <mdb-input
          v-model="eventLocation.GoEventLocationName"
          class="w-full"
          size="sm"
          placeholder="Location Name"
        />
      </div>
      <div class="flex flex-row items-center locationForm">
        <font-awesome-icon
          :icon="['fas', 'location-dot']"
          class="mr-2 text-primary"
        />
        <mdb-input
          v-model="eventLocation.AddressLine1"
          size="sm"
          class="w-full"
          placeholder="Address Line 1"
        />
      </div>
      <div class="flex flex-row items-center locationForm">
        <font-awesome-icon
          :icon="['fas', 'location-dot']"
          class="mr-2 text-primary"
        />
        <mdb-input
          v-model="eventLocation.AddressLine2"
          class="w-full"
          size="sm"
          placeholder="Address Line 2"
        />
      </div>
      <div class="flex flex-row items-center locationForm">
        <font-awesome-icon
          :icon="['fas', 'location-dot']"
          class="mr-2 text-primary"
        />
        <mdb-input
          v-model="eventLocation.Town"
          class="w-full"
          size="sm"
          placeholder="Town"
        />
      </div>
      <div class="flex flex-row items-center locationForm">
        <font-awesome-icon
          :icon="['fas', 'location-dot']"
          class="mr-2 text-primary"
        />
        <mdb-input
          v-model="eventLocation.County"
          class="w-full"
          placeholder="County"
          size="sm"
        />
      </div>
      <div class="flex flex-row items-center locationForm">
        <font-awesome-icon
          :icon="['fas', 'location-dot']"
          class="mr-2 text-primary"
        />
        <mdb-input
          v-model="eventLocation.PostCode"
          class="w-full"
          placeholder="Postcode"
          size="sm"
        />
      </div>
      <div class="flex flex-row items-center locationForm">
        <font-awesome-icon
          :icon="['fas', 'location-dot']"
          class="mr-2 text-primary"
        />
        <mdb-select
          v-model="countriesDropdown"
          class="w-full"
          placeholder="Country"
          size="sm"
        />
      </div>
      <div class="flex flex-row items-center">
        <font-awesome-icon
          :icon="['fas', 'pen']"
          class="mr-2 text-primary"
        />
        <mdb-input
          v-model="eventLocation.GoEventLocationDetails"
          type="textarea"
          label="Location Details"
          :rows="5"
          class="w-full"
          size="sm"
        />
      </div>
    </div>
    <mdb-btn
      class="btn text-center flex ml-auto"
      :disabled="formInvalid"
      size="sm"
      style="width:80px;"
      @click="addEventLocation()"
    >
      ADD
    </mdb-btn>
  </div>
  <i
    v-else
    class="fa fa-spin fa-spinner"
    style="font-size:1.5rem;padding:10px;"
  />
</template>

<script>
import { ref, computed } from 'vue'
import { useEvent } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name:'AddLocationForm',
  setup () {
    const eventStore = useEvent()
    const { countries, eventLocation, countriesDropdown, selectedCountry } = storeToRefs(eventStore)
    const { getCountries, addEventLocation } = useEvent()

    const loading = ref(true)

    const formInvalid = computed(() => {
      const invalid = (
        !selectedCountry.value ||
        !eventLocation.value.GoEventLocationName
      )
      return invalid
    })

    const load = async () => {
      loading.value = true
      await getCountries()
      countriesDropdown.value = countries.value.map(c => ({ text: c.CountryName, selected: false, value: c.CountryId }))
      loading.value = false
    }

    load()

    return {
      addEventLocation,
      countries,
      countriesDropdown,
      eventLocation,
      formInvalid,
      getCountries,
      loading
    }
  }
}
</script>

<style scoped>
label[data-v-189f23e2].active {
  color: #999 !important;
}
.locationForm {
  height: 60px;
}
</style>
