<template>
  <div
    id="outer"
    class="col-sm overflow-auto"
    style="background:white !important;"
  >
    <div id="inner" style="text-align:center;">
      <h3 class="primary-colour" style="margin-bottom:40px;margin-top:-20px;">
        404 Not Found!
      </h3>
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'NotFound',
  setup () {
    const userStore = useUserData()
    const { landingPage } = storeToRefs(userStore)
    return {
      landingPage
    }
  },
  mounted () {
    this.$router.push(this.landingPage)
  }
}
</script>

<style lang="scss" scoped>
#inner {
  display: table;
  margin: 0 auto;
}
#outer {
  width:100%
}
</style>
