<template>
  <div
    class="d-flex"
  >
    <div
      class="d-flex align-items-center px-5 pb-5"
      style="overflow-x: auto;height: 350px; overflow-y: visible"
    >
      <div class="workflowBuilderTimelineEnds green">
        START
      </div>
      <div
        v-for="(obj, i) in workflowTimeline"
        :key="i"
        class="d-flex align-items-center"
        :style="{ 'height' : `calc(100% / ${currentStages.length})`}"
      >
        <FontAwesomeIcon
          v-if="obj.type == 'Arrow'"
          class="my-auto dark-grey-text mx-4"
          style="scale: 1.5"
          icon="fa-arrow-right fa-solid"
        />
        <StageSetup
          v-else-if="obj.type == 'StageSetup'"
          :stage="obj.content"
          :hide-stage-setup="!obj.previousStageIsSetup"
          @goToStageSetup="goToStageSetup(obj.content.RoleWorkflowStageId, obj.content.StageType)"
        />
        <TransitionRules
          v-else-if="obj.type == 'TransitionSetup'"
          :stage="obj.content"
          class="mx-3"
          @goToStageSetup="goToTransitionSetup(obj.content.RoleWorkflowStageId)"
        />
      </div>
      <div class="workflowBuilderTimelineEnds red">
        END
      </div>
    </div>
  </div>
</template>

<script>
import GET_ICON_FROM_STAGE_TYPE_ENUM from '@/utils/getFAIconCodeFromWorkflowStageTypeEnum.js'
import StageSetup from './WorkflowBuilderStageSetup/workflowBuilderStageSetup.vue'
import roleV2Service from '@/api/services/roleV2.service'
import { useStatus, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'
import TransitionRules from './transitionRule.vue'

export default {
  name: 'WorkflowBuilder',
  components: {
    StageSetup, TransitionRules
  },
  props: {
    roleWorkflowId: {
      type: String,
      default: ''
    }
  },
  setup () {
    const { showGenericErrorModal } = useStatus()

    const workflowStore = useWorkflow()
    const { selectedWorkflowStageId, currentWorkflow, role } = storeToRefs(workflowStore)

    return {
      currentWorkflow,
      showGenericErrorModal,
      selectedWorkflowStageId,
      role
    }
  },
  data () {
    return {
      workflowTemplates: []
    }
  },
  computed: {
    currentStages () {
      if (this.currentWorkflow?.OrderedRoleWorkflowStages) { return this.currentWorkflow.OrderedRoleWorkflowStages }
      return []
    },
    workflowTimeline () {
      // created an object of arrows, stage setups. adds transition rule box when more than one stage
      let timeline = [{ type: 'Arrow'}]
      const noOfStages = this.currentStages.length
      this.currentStages.forEach((stage, index) => {
        // we want users to setup the stages in order - job is set to complete when final stage is setup. also transition rules might be affected by previous stage setup
        let stageEntry
        if (index === 0) {
          stageEntry = [{ type: 'StageSetup', content: stage, previousStageIsSetup: true }, { type: 'Arrow'}]
        } else {
          const previousStageIsSetup = this.currentStages[index - 1].SetupComplete && this.currentStages[index - 1].SelfScheduleSetupComplete
          stageEntry = [{ type: 'StageSetup', content: stage, previousStageIsSetup: previousStageIsSetup }, { type: 'Arrow'}]
        }
        // no transition setup box before first or after last stage
        if (noOfStages != 0 && index < noOfStages - 1) {
          stageEntry.push({ type: 'TransitionSetup', content: stage }, { type: 'Arrow'})
        }
        timeline.push(...stageEntry)
      })
      return timeline
    }
  },
  mounted () {
    this.getRoleWorkflowNoSetupDetails()
  },
  methods: {
    async getRoleWorkflowNoSetupDetails () {
      try {
        const res = await roleV2Service.getRoleWorkflowNoSetupDetails(this.roleWorkflowId)
        if (res.data) {
          this.currentWorkflow = res.data
        } else { this.$emit('noWorkflow') }
      } catch (err) {
        this.showGenericErrorModal()
      }
    },
    getIconFromStageType (stageType) {
      return GET_ICON_FROM_STAGE_TYPE_ENUM(stageType)
    },
    async goToStageSetup (stageId, stageType) {
      let url = ''
      this.selectedWorkflowStageId = stageId
      this.$nextTick(() => {
        switch (stageType) {
          case 1:
            url = `/roles/add_edit/one_way_stage_setup/${this.role.JobId}/${stageId}?page=setting_up`
            break
          case 2:
            url = `/roles/add_edit/two_way_stage_setup/${this.role.JobId}/${stageId}?page=meeting_name_and_type`
            break
          case 3:
            url = `/roles/add_edit/event_setup/${this.role.JobId}/${stageId}?page=about`
            break
          default:
            break
        }
        this.$router.push(url)
      })
    }
  }
}
</script>

<style>
.workflowBuilderTimelineEnds {
  height: 80px !important;
  width: 80px !important;
  min-width: 80px !important;
  padding: 0 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 900;
  letter-spacing: 1px;
}
.workflowBuilderTimelineEnds.red {
  background: red;
}
.workflowBuilderTimelineEnds.green {
  background: green;
}
</style>

