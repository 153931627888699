<template>
  <!--modal boilerplate-->
  <mdb-modal
    id="addingInvitingCandidateModalShowing"
    :show="addingInvitingCandidateModalShowing"
    size="lg"
    style="overflow-y:auto;"
    @close="addingInvitingCandidateModalShowing = false"
  >
    <mdb-modal-header>
      <mdb-modal-title class="font-weight-bold p-2 ml-3">
        ADD NEW CANDIDATE
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body style="overflow-y:auto">
      <SlideFade>
        <div v-if="page == 1">
          <CandidateInformationForm
            :reset-form="resetForm"
            @formValid="formValid = $event"
            @formData="formData = $event"
          />
          <CustomProfileFields
            style="margin-top:-30px;margin-left:25px;margin-right: 25px;"
            view="invite_and_add_modal"
            @customValues="setCustomValues"
          />
          <div
            class="mt-3 text-left mx-2"
            style="font-size:0.9rem;"
          >
            <span v-if="addInviteCandidateModalData.StageType === 1">
              By pressing Confirm you will create a new Candidate record and invite the Candidate to the Role of <span class="font-weight-bold">{{ role.JobTitle }}</span>
            </span>
            <span v-if="addInviteCandidateModalData.StageType === 2">
              By pressing Confirm you will create a new Candidate record and be redirected to set a date and time for the interview.
            </span>
          </div>
        </div>
        <ConfirmDetails
          v-if="page == 2"
          :conflicting-values="conflictingValues"
          @validConflicts="validConflicts = $event"
          @firstName="formData.FirstName = $event"
          @lastName="formData.LastName = $event"
          @contactNumber="formData.ContactNumber = $event"
          @location="formData.Location = $event"
          @externalCandidateReference="formData.ExternalCandidateReference = $event"
        />
      </SlideFade>
    </mdb-modal-body>
    <mdb-modal-footer>
      <div v-if="page === 1" style="width:100%;">
        <div
          v-if="applicantExists"
          class="text-left mb-3"
          style="font-size:1rem;margin-left:5px;"
        >
          This candidate has already been invited to this role!
        </div>
        <div class="d-flex align-items-center justify-content-between" style="width:100%">
          <div v-if="!loading" style="width:100%">
            <mdb-btn
              v-if="addInviteCandidateModalData.StageType !== 2"
              class="btn btn-outline fl m-0"
              style="float:left;"
              size="sm"
              :disabled="!formValid"
              @click="addCandidate(true, true)"
            >
              CONFIRM AND INVITE ANOTHER
            </mdb-btn>
            <mdb-btn
              class="btn fl ml-auto text-nowrap m-0"
              size="sm"
              style="width:110px;float:right;"
              :disabled="!formValid"
              @click="addCandidate(true, false)"
            >
              CONFIRM
            </mdb-btn>
          </div>
          <div v-else style="width:100%">
            <mdb-btn
              v-if="addInviteCandidateModalData.StageType !== 2"
              class="btn btn-outline fl m-0"
              style="float:left;"
              size="sm"
              disabled
            >
              CONFIRM AND INVITE ANOTHER
            </mdb-btn>
            <mdb-btn
              class="btn fl ml-auto text-nowrap m-0"
              size="sm"
              style="width:110px;float:right;"
            >
              <mdb-icon icon="fa fa-pulse fa-spinner" />
            </mdb-btn>
          </div>
        </div>
      </div>
      <div v-if="page === 2" style="width:100%">
        <div v-if="!loading" style="width:100%">
          <mdb-btn
            class="btn fl text-nowrap"
            size="sm"
            style="width:110px;float:right;"
            :disabled="!validConflicts"
            @click="addCandidate(false, false)"
          >
            CONFIRM
          </mdb-btn>
          <mdb-btn
            v-if="addInviteCandidateModalData.StageType === 1"
            class="btn btn-outline fl"
            size="sm"
            style="float:left;"
            :disabled="!validConflicts"
            @click="addCandidate(false, true)"
          >
            CONFIRM AND INVITE ANOTHER
          </mdb-btn>
        </div>
        <div v-else style="width:100%">
          <mdb-btn
            v-if="addInviteCandidateModalData.StageType === 1"
            class="btn btn-outline fl"
            size="sm"
            style="float:left;"
            disabled
          >
            CONFIRM AND INVITE ANOTHER
          </mdb-btn>
          <mdb-btn
            class="btn fl text-nowrap"
            size="sm"
            style="width:110px;float:right;"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </div>
      </div>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'
import CandidateInformationForm from './pages/candidateInformationForm.vue'
import ConfirmDetails from './pages/confirmDetails.vue'
import roleV2Service from '@/api/services/roleV2.service'
import { useRoles, useWorkflow, useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import CustomProfileFields from '@/views/candidates/Tabs/customProfileFields.vue'

export default {
  name: 'AddingInvitingCandidateModal',
  components: {
    CandidateInformationForm,
    ConfirmDetails,
    SlideFade,
    CustomProfileFields
  },
  setup () {
    const { showGenericErrorModal } = useStatus()
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)
    const roleStore = useRoles()
    const { addingInvitingCandidateModalShowing, addInviteCandidateModalData } = storeToRefs(roleStore)
    const workflowStore = useWorkflow()
    const { role } = storeToRefs(workflowStore)
    return {
      addingInvitingCandidateModalShowing, addInviteCandidateModalData, role, authData, showGenericErrorModal
    }
  },
  data () {
    return {
      page: 1,
      formData: {
        FirstName: '',
        LastName: '',
        EmailAddress: '',
        ContactNumber: '',
        Location: '',
        ExternalCandidateReference: ''
      },
      candidateId: 0,
      formValid: false,
      conflictingValues: {},
      validConflicts: false,
      applicantExists: false,
      loading: false,
      resetForm: false,
      customValues: null
    }
  },
  watch: {
    addingInvitingCandidateModalShowing () {
      if (!this.addingInvitingCandidateModalShowing) {
        this.resetModal()
      }
    }
  },
  methods: {
    resetModal () {
      this.resetForm = true
      this.formValid = false
      this.validConflicts = false
      this.candidateId = 0
      this.page = 1
      this.applicantExists = false
      this.conflictingValues = {}
      this.loading = false
      this.$nextTick(() => { this.resetForm = false })
    },
    setCustomValues (values) {
      this.customValues = values
    },
    async addCandidate (checkConflicts, addAnotherCandidate) {
      this.applicantExists = false
      this.loading = true
      try {
        const JSONData = {
          FirstName: this.formData.FirstName,
          LastName: this.formData.LastName,
          EmailAddress: this.formData.EmailAddress,
          ContactNumber: this.formData.ContactNumber,
          Location: this.formData.Location,
          ExternalCandidateReference: this.formData.ExternalCandidateReference,
          JobId: this.role.JobId,
          CheckCandidateInfoConflictsFirst: checkConflicts,
          CandidateId: this.candidateId,
          RoleWorkflowStageId: this.addInviteCandidateModalData.RoleWorkflowStageId,
          CustomValues: this.customValues
        }
        const res = await roleV2Service.addInviteCandidate(JSONData)
        this.loading = false
        this.customValues = null
        if (res.data.ApplicantExists) {
          this.applicantExists = true
          return
        }
        if (res.data.ConflictsExist === true) {
          this.conflictingValues = res.data
          this.page = 2
          this.candidateId = res.data.CandidateId
        } else {
          if (addAnotherCandidate) {
            this.resetModal()
          } else {
            let path
            let query = { search: this.formData.EmailAddress }
            // if its a one way stage they have been invited at this point whereas a two way they will be redirected to invite them manually
            if (this.addInviteCandidateModalData.StageType === 1 || this.addInviteCandidateModalData.StageType === 3) {
              let invitedStageItemId = this.addInviteCandidateModalData.OrderedRoleWorkflowStageItems.filter(s => s.RoleWorkflowStageItemName == 'Invited')[0].RoleWorkflowStageItemId
              path = `/roles/candidates/${this.role.JobId}/${this.addInviteCandidateModalData.RoleWorkflowStageId}/${invitedStageItemId}`
              this.$router.push({ path })
          } else {
              path = `/roles/candidates/${this.role.JobId}/${this.addInviteCandidateModalData.RoleWorkflowStageId}/0`
              query.sm = true
              this.$router.push({ path, query })
            }
            this.addingInvitingCandidateModalShowing = false
          }
        }
      } catch (err) {
        this.showGenericErrorModal()
      }
      this.loading = false
    }
  }
}
</script>
