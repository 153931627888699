<template>
      <!-- video intro modal -->
    <mdb-modal
      v-if="showAddInsertVideoModal"
      id="showAddInsertVideoModal"
      class="sss"
      @close="showAddInsertVideoModal = false"
    >
      <mdb-modal-header>
        <b-row style="width: 90%; margin-left: 20px;" align-h="between">
          <div
            class="modal-title-container m-auto m-sm-0"
            :class="{'modal-title-underlined' : videoModalPage == 'choose'}"
            style="font-size:smaller;margin-left:-20px !important;"
          >
            <h6 :class="{'grey-text' : videoModalPage != 'choose' }" @click="videoModalPage = 'choose'">
              <span v-show="mobileView === false">CHOOSE VIDEO</span>
              <span v-show="mobileView" style="font-size:12px;">CHOOSE <i class="fa fa-video" /></span>
            </h6>
          </div>
          <div
            class="modal-title-container m-auto m-sm-0"
            :class="{'modal-title-underlined' : videoModalPage == 'record'}"
            style="font-size:smaller;margin-left:-20px !important;"
          >
            <h6 :class="{'grey-text' : videoModalPage != 'record' }" @click="videoModalPage = 'record'">
              <span v-show="mobileView === false">RECORD VIDEO</span>
              <span v-show="mobileView" style="font-size:12px;">RECORD <i class="fa fa-video" /></span>
            </h6>
          </div>
          <div
            class="modal-title-container m-auto m-sm-0"
            :class="{'modal-title-underlined' : videoModalPage == 'upload'}"
            style="font-size:smaller;margin-left:-20px !important;"
          >
            <h6 :class="{'grey-text' : videoModalPage != 'upload' }" @click="videoModalPage = 'upload'">
              <span v-show="mobileView === false">UPLOAD VIDEO</span>
              <span v-show="mobileView" style="font-size:12px;">UPLOAD <i class="fa fa-video" /></span>
            </h6>
          </div>
        </b-row>
      </mdb-modal-header>
      <div v-if="videoModalPage == 'choose'">
        <SlideFade>
          <ChooseVideoPage
            :defaultCategory="category"
            @handleChosenVideo="$emit('handleChosenVideo', $event)"
          />
        </SlideFade>
      </div>
      <div v-if="videoModalPage == 'record'">
        <SlideFade>
          <RecordVideoPage
            :defaultCategory="category"
            @handleRecordedVideo="handleRecordedVideo"
          />
        </SlideFade>
      </div>
      <div v-if="videoModalPage == 'upload'">
        <SlideFade>
          <UploadVideoPage
            :defaultCategory="category"
            @uploadVideo="handleUploadedVideo"
          />
        </SlideFade>
      </div>
    </mdb-modal>
</template>

<script>
import { ref } from 'vue-demi'
import { useMobile } from '@/composables/useMobile'
import { useLibrary } from '@/pinia'
import { storeToRefs } from 'pinia'

import ChooseVideoPage from '@/components/genericModals/InsertVideoModals/ChooseVideoPage.vue'
import RecordVideoPage from '@/components/genericModals/InsertVideoModals/RecordVideoPage.vue'
import UploadVideoPage from '@/components/genericModals/InsertVideoModals/UploadVideoPage.vue'
import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'

export default {
  name: 'InsertVideoModal',
  components: {
    ChooseVideoPage, RecordVideoPage, UploadVideoPage, SlideFade
  },
  props: {
    defaultCategory: {
      type: String,
      default: 'Job'
    }
  },
  setup (props) {

    const { mobileView } = useMobile()
    const videoModalPage = ref('choose')
    const libraryStore = useLibrary()
    const { showAddInsertVideoModal } = storeToRefs(libraryStore)

    const category = ref(props.defaultCategory)

    return {
      videoModalPage,
      mobileView,
      showAddInsertVideoModal,
      category
    }
  },
  methods: {
    handleUploadedVideo (category) {
      this.category = category
      this.videoModalPage = 'choose'
    },
    handleRecordedVideo (video) {
      this.$emit('handleRecordedVideo', video)
      this.videoModalPage = 'choose'
    }
  }
}
</script>
