<template>
  <div
    style="box-sizing: auto !important"
    class="d-flex flex-wrap justify-content-around"
  >
    <ul
      v-for="(component, index) in tabs"
      :key="index"
      class="nav nav-tabs d-flex flex-grow-1 justify-content-between"
      style="box-sizing: auto !important"
    >
      <li
        class="nav-item w-100"
        style="box-sizing: auto !important; "
        @click="setActiveComponent(component)"
      >
        <a
          style="box-sizing: auto !important;"
          :class="activeComponent == component.componentName ? 'active' : 'nonActiveCSTab'"
          class="nav-link csNavLink m-0"
        >
          {{ component.componentNameReadable }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
// for this to work put navigation tabs in this format where the componentName is the same name as the imported component in the parent file
// const navigationItems = [
//   { componentName: 'AddAPlan', componentNameReadable: 'Add A Plan' },
//   { componentName: 'ExistingPlans', componentNameReadable: 'Existing Plans' }
// ]
export default {
  name: 'CSNavigationTabs',
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    activeComponentProp: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {

    const activeComponent = ref(props.tabs[0].componentName)
    const setActiveComponent = component => {
      activeComponent.value = component.componentName
      emit('activeComponent', component.componentName)
    }

    const activeComponentPropLocal = computed(() => props.activeComponentProp)
    watch(activeComponentPropLocal, () => {
      const tab = props.tabs.filter(t => t.componentName == activeComponentPropLocal.value)[0]
      setActiveComponent(tab)
    })

    return {
      setActiveComponent,
      activeComponent
    }
  }
}
</script>

<style scoped>
.active {
  color: var(--lk-color) !important;
  background: var(--pr-color) !important;
  border: 2px solid var(--sc-color) !important;
}
.nonActiveCSTab {
  color: var(--sc-color) !important;
  border: 2px solid #f1f1f1 !important;
}
/* .active:hover {
  color: var(--sc-color) !important;
} */
.csNavLink {
  border-width: 2px !important;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: clamp(.8rem, 2vw, 1.1rem)
}
</style>
