import { render, staticRenderFns } from "./leftColumn.vue?vue&type=template&id=0ec5d592&scoped=true"
import script from "./leftColumn.vue?vue&type=script&lang=js"
export * from "./leftColumn.vue?vue&type=script&lang=js"
import style0 from "./leftColumn.vue?vue&type=style&index=0&id=0ec5d592&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ec5d592",
  null
  
)

export default component.exports