<template>
  <div
    :title="`Id: ` + data.item.CandidateId + `. Ref: ` + (data.item.ExternalCandidateReference ? data.item.ExternalCandidateReference : 'n/a')"
    style="cursor:pointer;margin-left:10px;"
    class="tableRow"
    :class="{ 'selected': selectedCandidates.indexOf(data.item.CandidateId) > -1 }"
  >
    <ProfilePicture
      :imgSrc="data.item.ProfilePicture"
      :dimension="36"
      :maskUser="role.MaskCandidateProfilePicture"
      style="cursor: pointer;"
    />
    <div v-if="role.MaskGoCandidateName && stageItemStatus != 1" class="ml-3 pt-1">
      {{ data.item.CandidateId }}
    </div>
    <div v-else class="ml-3 pt-1">
      {{ data.item.Name }}
    </div>
    <span
      v-if="stageItemStatus == 4 && data.item.OneWayIsLocked && stageType === 1"
      title="Locked"
    >
      <i class="fa fa-lock ml-2 grey-link" />
    </span>
  </div>
</template>

<script>
import { useUserData, useStatus, useRoleCandidate, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'
import ProfilePicture from '@/components/UI/profilePicture.vue'

export default {
  name: 'CandidateInfo',
  components: { ProfilePicture },
  props: {
    data: Object
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()

    const roleCandidateStore = useRoleCandidate()
    const { candidateTableData, selectedCandidates } = storeToRefs(roleCandidateStore)

    const workflowStore = useWorkflow()
    const { role } = storeToRefs(workflowStore)
    return {
      authData,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal,
      candidateTableData,
      role,
      selectedCandidates
    }
  },
  computed: {
    stageType () {
      return this.candidateTableData.StageType
    },
    stageItemStatus () {
      return this.candidateTableData.StageItemStatus
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>

.selected {
  color: var(--pr-color) !important;
  font-weight: bold;
}
</style>