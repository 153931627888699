<template>
  <!--add candidates modal-->
  <mdb-modal
    v-if="showSearchCandidateModal"
    id="showSearchCandidateModal"
    @close="$emit('showSearchCandidateModal', false)"
  >
    <mdb-modal-header class="p-3">
      <mdb-modal-title>FIND CANDIDATES</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body
      class="pt-0"
    >
      <div class="w-100">
        <ExpandableSearchBar
          class="my-3"
          :width="700"
          place-holder="Name or Email"
          :searchTriggersAPICall="true"
          :force-always-focused="true"
          :disable-right-alignment="true"
          @search="doNewSearch"
        />
      </div>

      <!--candidates in search-->
      <div class="d-flex align-items-center mb-2 " style="width: 100%">
        <p class="text-left grey-text" style="font-size:15px;">
          {{ searchInputValue == '' ? 'Candidates' : 'Results' }}
        </p>
        <mdb-icon
          v-if="loadingRoleCandidates"
          class="m-0 ml-3 p-0"
          icon="fa fa-pulse fa-spinner"
          style="margin-left:7px !important;"
        />
      </div>
      <div style="height:150px;overflow-y:scroll;border:solid #C0C0C0 1px;">
        <div v-if="searchedCandidates.length > 0">
          <div
            v-for="cand in searchedCandidates"
            :key="cand.CandidateId"
            style="border-radius:5px;height:45px;border:2px #C0C0C0 solid;background:#ffffff;cursor:pointer;overflow-x:hidden;overflow-y:hidden;"
            class="p-2 m-2 borderColorOnHover"
            @click="addToRemoveFromPreparing(cand)"
          >
            <div style="min-width: 100%;width: fit-content; overflow-x:hidden;" class="d-flex align-items-center justify-content-between">
              <div style="height:100%;" class="d-flex align-items-center">
                <img
                  :src="cand.ProfilePicture"
                  style="height:27px;width:27px;border-radius:50%;object-fit:cover;"
                  class="d-inline mr-2"
                >
                <h5 class="text-left font-weight-bold d-inline text-nowrap">
                  {{ cand.Name }}
                </h5>
              </div>
              <b-col class="d-none d-lg-block">
                <p class="text-sm text-left" style="margin-left:-5px;margin-top:0px;color:black !important;font-size:12px;">
                  {{ cand.EmailAddress }}
                </p>
              </b-col>
            </div>
          </div>
        </div>
        <div v-else class="mt-3">
          <p v-if="!loadingRoleCandidates" class="grey-text text-center">
            No results
          </p>
        </div>
      </div>

      <!--preparing to add-->
      <div class="d-flex justify-between">
        <p class="mb-2 mt-3 grey-text">
          {{ preparingToAddCandidates.length > 0 ? 'To Add' : '' }}
        </p>
        <p class="mb-2 mt-3 grey-text font-weight-bold" style="font-size:13px;cursor:pointer;">
          {{ preparingToAddCandidates.length }} selected
        </p>
      </div>
      <div v-if="preparingToAddCandidates.length > 0" style="height:150px;border:solid #C0C0C0 1px;overflow-y:scroll;">
        <div
          v-for="cand in preparingToAddCandidates"
          :key="cand.CandidateId"
          style="border-radius:5px;height:45px;border:2px #C0C0C0 solid;background:#ffffff;cursor:pointer;overflow-x:hidden;overflow-y:hidden;"
          class="p-2 m-2 borderColorOnHover"
          @click="addToRemoveFromPreparing(cand)"
        >
          <div style="min-width: 100%;width: fit-content; overflow-x:auto;" class="d-flex align-items-center justify-content-between">
            <div style="height:100%;" class="d-flex align-items-center">
              <img
                :src="cand.ProfilePicture"
                style="height:27px;width:27px;border-radius:50%;object-fit:cover;"
                class="d-inline mr-2"
              >
              <h5 class="text-left font-weight-bold d-inline text-nowrap mr-4">
                {{ cand.Name }}
              </h5>
            </div>
            <div style="width:30px;" class="flex-grow-0">
              <i style="cursor:pointer; transform:scale(1.4)" class="grey-text fa fa-times colorOnHover" />
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end w-100 mt-3">
        <mdb-btn
          v-if="preparingToAddCandidates.length > 0"
          type="reset"
          class="btn btn-sm p-0 m-0"
          size="md"
          style="width:100px; height:30px;"
          @click="addSelectedCandidates()"
        >
          ADD SELECTED
        </mdb-btn>
      </div>
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import { ref } from 'vue'
import ExpandableSearchBar from '@/components/UI/expandableSearch.vue'
import roleV2Service from '@/api/services/roleV2.service'
import { useWorkflow, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
 name: 'SearchCandidates',
 components: {
  ExpandableSearchBar
 },
 props: {
  showSearchCandidateModal: {
    type: Boolean,
    default :false
  },
  candidatesAddedToShare: {
    type: Array,
    default: () => []
  }
 },
 setup () {
  const workflowStore = useWorkflow()
  const {
    selectedWorkflowStage
  } = storeToRefs(workflowStore)

  const { showGenericErrorModal } = useStatus()

  const loadingRoleCandidates = ref(false)
  const preparingToAddCandidates = ref([])
  const searchedCandidates = ref([])
  const searchInputValue = ref('')

  return {
    loadingRoleCandidates,
    preparingToAddCandidates,
    searchInputValue,
    searchedCandidates,
    selectedWorkflowStage,
    showGenericErrorModal
  }
 },
 methods: {
  addSelectedCandidates () {
    this.$emit('updateCandidatesAddedToShare', [...this.candidatesAddedToShare, ...this.preparingToAddCandidates])
    this.preparingToAddCandidates = []
    this.$emit('showSearchCandidateModal', false)
  },
  addToRemoveFromPreparing (cand) {
    if (this.preparingToAddCandidates.filter(c => c.CandidateId === cand.CandidateId).length === 0) {
      this.preparingToAddCandidates.push(cand)
    } else {
      this.preparingToAddCandidates = this.preparingToAddCandidates.filter(c => c.CandidateId !== cand.CandidateId)
    }
  },
  async doNewSearch (search) {
    this.searchedCandidates = []
    this.searchInputValue = search
    if (search === "" || search === null) {
      return
    } else {
    try {
      this.loadingRoleCandidates = true
      const res = await roleV2Service.searchApplicantWithinStage(this.selectedWorkflowStage.RoleWorkflowStageId, this.searchInputValue, 0, 100, true)
      // transform data and remove any candidates already added
      res.data.forEach(cand => {
        cand = { ...cand, Status: cand.RoleWorkflowStageItemId, ...cand.CandidateAccount }
        const index = this.candidatesAddedToShare.findIndex(alreadyAddedCand => alreadyAddedCand.CandidateId == cand.CandidateId)
        if (index == -1) {
          this.searchedCandidates.push(cand)
        }
      })
    } catch {
      this.showGenericErrorModal({showing: true})
    }
  }
    this.loadingRoleCandidates = false
  }
 }
}
</script>

<style>

</style>