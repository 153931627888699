<template>
  <div>
    <!--generic confirm modal -->
    <mdb-modal
      id="genericConfirmModal"
      :show="showing"
      @close="close()"
    >
      <mdb-modal-header>
        <mdb-modal-title>{{ confirmTitle.toUpperCase() }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <p
          class="my-4 dark-grey-text"
          style="font-size:0.8rem;text-align:left;"
          v-html="confirmMessage"
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <div v-if="confirmButtonMessage2 != ''" class="d-flex align-items-center mr-auto">
          <mdb-btn
            v-if="!buttonConfirmed2"
            class="btn btn-outline"
            color="primary"
            size="sm"
            style="width:100px;"
            @click.native="confirm2()"
          >
            {{ confirmButtonMessage2.toUpperCase() }}
          </mdb-btn>
          <mdb-btn
            v-else
            color="primary"
            class="btn btn-outline"
            size="sm"
            style="width:100px;"
          >
            <i class="fa fa-spin fa-spinner" />
          </mdb-btn>
        </div>
        <div class="d-flex align-items-center">
          <mdb-btn
            v-if="!buttonConfirmed"
            color="primary"
            size="sm"
            style="width:100px;"
            @click.native="confirm()"
          >
            {{ confirmButtonMessage.toUpperCase() }}
          </mdb-btn>
          <mdb-btn
            v-else
            color="primary"
            size="sm"
            style="width:100px;"
          >
            <i class="fa fa-spin fa-spinner" />
          </mdb-btn>
        </div>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'GenericConfirmModal',
  setup () {
    const statusStore = useStatus()
    const { closeGenericConfirmModal } = useStatus()
    const { genericConfirmModal } = storeToRefs(statusStore)

    return {
      genericConfirmModal,
      closeGenericConfirmModal
    }
  },
  data () {
    return {
      buttonConfirmed: false,
      buttonConfirmed2: false
    }
  },
  computed: {
    confirmTitle () {
      if (this.genericConfirmModal.confirmTitle === '') {
        return 'Confirm'
      } else { return this.genericConfirmModal.confirmTitle }
    },
    confirmMessage () {
      if (this.genericConfirmModal.confirmMessage === '') {
        return 'Are you sure?'
      } else { return this.genericConfirmModal.confirmMessage }
    },
    confirmButtonMessage () {
      if (this.genericConfirmModal.confirmButtonMessage === '') {
        return 'CONTINUE'
      } else { return this.genericConfirmModal.confirmButtonMessage }
    },
    confirmButtonFunction () {
      return this.genericConfirmModal.confirmButtonFunction
    },
    confirmButtonMessage2 () {
      if (this.genericConfirmModal.confirmButtonMessage2 === '') {
        return ''
      } else { return this.genericConfirmModal.confirmButtonMessage2 }
    },
    confirmButtonFunction2 () {
      return this.genericConfirmModal.confirmButtonFunction2
    },
    showing () {
      return this.genericConfirmModal.showing
    }
  },
  watch: {
    showing () {
      if (this.showing) {
        this.buttonConfirmed = false
        this.buttonConfirmed2 = false
      }
    }
  },
  methods: {
    close () {
      this.buttonConfirmed = false
      this.buttonConfirmed2 = false
      this.closeGenericConfirmModal()
    },
    confirm () {
      this.buttonConfirmed = true
      this.confirmButtonFunction()
    },
    confirm2 () {
      this.buttonConfirmed2 = true
      this.confirmButtonFunction2()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
