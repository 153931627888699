<template>
  <div style="height:99%;">
    <SlideFade>
      <TemplateList v-if="$route.path == '/settings/scoring/templates/all'" />
      <AddEditTemplate v-else-if="$route.path == '/settings/scoring/templates/add_edit'" />
    </SlideFade>
  </div>
</template>

<script>
import TemplateList from './templateList.vue'
import AddEditTemplate from './addEditTemplate.vue'
import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'

export default {
  name: 'ScoringTemplatesContainer',
  components: {
    TemplateList,
    AddEditTemplate,
    SlideFade
  }
}
</script>
