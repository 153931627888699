<template>
  <!--modal boilerplate-->
  <mdb-modal
    id="addEditClientModalShowing"
    :show="addEditClientModalShowing"
    size="lg"
    style="overflow-y:auto;"
    @close="closeAddEditModal()"
  >
    <mdb-modal-header>
      <mdb-modal-title class="font-weight-bold p-2 ml-3">
        {{ clientId === 0 ? 'ADD NEW' : 'EDIT' }} CLIENT
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body style="overflow-y:auto" class="px-4 px-md-5">
      <validation-observer v-slot="{ }">
        <div class="d-flex align-items-center mt-3" style="height:60px">
          <i class="mr-3 fa fa-signature prefix" style="font-size: 1.3rem;" />
          <mdb-input
            v-model="clientName"
            type="text"
            auto-complete="jbt"
            label="Client Name"
            size="sm"
            class="addingClientForm"
          />
        </div>
        <div class="d-flex align-items-center" style="height:60px">
          <i class="mr-3 fa fa-image prefix" style="font-size: 1.3rem;" />
          <mdb-select
            v-model="logoOptions"
            placeholder="Select"
            size="sm"
            style="width:100%;"
            class="m-0 mx-3 addingClientForm"
          />
        </div>
        <MDBFileUpload
          v-if="!useOwnLogo"
          class="my-4"
          :files="file"
          height="100"
          max-size="10M"
          accept="image/png, image/jpeg"
          file-too-big-text="This file is too big! Please select a file smaller than 10MB"
          :text="{ able: { single: 'Drag and Drop a File Here or Click to Select From Your Device' }}"
          @change="handleChange"
        />

        <div class="d-flex align-items-center " style="height:60px">
          <i class="mr-3 fas fa-envelope" style="font-size: 1.3rem;" />
          <mdb-input
            v-model="clientAdminEmail"
            type="text"
            auto-complete="jbt"
            label="Client Admin Email"
            size="sm"
            class="addingClientForm"
          />
        </div>
        <div
          v-if="clientId === 0"
          class="d-flex align-items-center "
          style="height:60px"
        >
          <i class="mr-3 fa fa-signature prefix" style="font-size: 1.3rem;" />
          <mdb-input
            v-model="clientAdminFirstName"
            type="text"
            auto-complete="jbt"
            label="Client Admin First Name"
            size="sm"
            class="addingClientForm"
          />
        </div>
        <div
          v-if="clientId === 0"
          class="d-flex align-items-center "
          style="height:60px"
        >
          <i class="mr-3 fa fa-signature prefix" style="font-size: 1.3rem;" />
          <mdb-input
            v-model="clientAdminSurname"
            type="text"
            auto-complete="jbt"
            label="Client Admin Surname"
            size="sm"
            class="addingClientForm"
          />
        </div>
        <div
          class="md-form form-sm mr-auto d-flex align-items-center p-0"
          style="margin-top: 25px;margin-bottom: 40px;"
          @click="toggleRestrictUserEmailDomain()"
        >
          <mdb-input
            v-model="restrictEmailDomains"
            type="checkbox"
            class="p-0"
            :label="restrictedEmailDomainMessage"
            style="margin-top: -10px;"
            size="sm"
          />
        </div>
      </validation-observer>
    </mdb-modal-body>
    <mdb-modal-footer>
      <div class="d-flex align-items-center justify-content-between" style="width:100%">
        <mdb-btn
          v-if="!loading"
          class="btn fl ml-auto text-nowrap m-0"
          size="sm"
          :disabled="!formValid"
          @click="addEditClientPortalClient()"
        >
          SUBMIT
        </mdb-btn>
        <mdb-btn
          v-if="loading"
          class="btn fl ml-auto"
          size="sm"
          style="width:110px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </div>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'

import clientPortalService from '@/api/services/clientPortal.service'
import MDBFileUpload from 'mdb-vue-file-upload'

export default {
  name: 'AddEditClientModal',
  components: {
    MDBFileUpload
  },
  directives: {
    uploader: {
      bind (el, binding, vnode) {
        el.addEventListener('change', e => {
          if (e.target.files[0] !== undefined) {
            vnode.context.file = e.target.files[0]
          }
        })
      }
    }
  },
  props: {
    addEditClientModalShowing: Boolean,
    clientId: {
      type: Number,
      default: 0
    }
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()

    return {
      authData,
      userData,
      showGenericErrorModal
    }
  },
  data () {
    return {
      clientName: '',
      clientAdminEmail: '',
      clientAdminFirstName: '',
      clientAdminSurname: '',
      loading: false,
      logoOptions: [{ value: 0, text: 'Use Our Logo', selected: true }, { value: 1, text: 'Use Client Logo' }],
      restrictEmailDomains: false,
      brandId: 0,
      logo: '',
      file: []
    }
  },
  computed: {
    formValid () {
      if (this.useOwnLogo) {
        if (this.clientName !== '' && this.clientAdminEmail !== '') { return true }
        return false
      } else {
        if (this.file !== [] && this.clientName !== '' && this.clientAdminEmail !== '') { return true }
        return false
      }
    },
    restrictedEmailDomainMessage () {
      try {
        const domain = this.clientAdminEmail.split('@')[1]
        if (domain === undefined || domain === '') { return 'Restrict user emails to this domain?' }
        return `Restrict user emails to "@${domain}" ?`
      } catch { return 'Restrict user emails to this domain?' }
    },
    useOwnLogo () {
      const value = this.logoOptions.filter(o => o.selected)[0].value
      return value === 0
    }
  },
  watch: {
    addEditClientModalShowing () {
      if (this.addEditClientModalShowing === true && this.clientId !== 0) {
        this.logo = this.userData.lbl.Logo
        this.getClientPortalClient()
      }
    },
    useOwnLogo: {
      handler: function () {
        if (this.useOwnLogo === true) {
          this.file = []
          this.logo = this.userData.lbl.Logo
        }
      },
      immediate: true
    }
  },
  methods: {
    handleChange (f) {
      this.file = f
    },
    async closeAddEditModal () {
      this.resetModal()
      this.$emit('closeAddEditModal')
    },
    toggleRestrictUserEmailDomain () {
      this.restrictEmailDomains = !this.restrictEmailDomains
    },
    resetModal () {
      this.clientName = ''
      this.clientAdminEmail = ''
      this.clientAdminFirstName = ''
      this.clientAdminSurname = ''
      this.loading = false
      this.logoOptions = [{ value: 0, text: 'Use Our Logo', selected: true }, { value: 1, text: 'Use Client Logo' }]
      this.restrictEmailDomains = false
      this.brandId = 0
      this.$emit('setClientId', 0)
      this.logo = this.userData.lbl.Logo
      this.file = []
    },
    async addEditClientPortalClient () {
      this.loading = true
      try {
        var formData = new FormData()
        formData.append('ClientName', this.clientName)
        formData.append('ClientAdminSurname', this.clientAdminSurname)
        formData.append('ClientAdminFirstName', this.clientAdminFirstName)
        formData.append('ClientId', this.clientId)
        formData.append('ClientAdminEmail', this.clientAdminEmail)
        formData.append('UseOwnLogo', this.useOwnLogo)
        formData.append('RestrictEmailDomains', this.restrictEmailDomains)
        // if using own logo file is set to '' and this.logo is userdata.lbl.Logo (set in watcher)
        formData.append('Logo', this.logo)
        formData.append('file', this.file[0])
        const res = await clientPortalService.addEditClientPortalClient(formData)
        this.file = []
        this.closeAddEditModal()
        if (res.data === true) {
          this.$emit('addClientToRecentClients', this.clientId)
        } else {
          this.showGenericErrorModal({ showing: true })
        }
      } catch (err) {
        if (err.response.data.Message !== undefined) {
          this.showGenericErrorModal({ showing: true, errorMessage: err.response.data.Message })
        } else {
          this.showGenericErrorModal({ showing: true })
        }
      }
      this.loading = false
    },
    async getClientPortalClient () {
      this.loading = true
      try {
        const res = await clientPortalService.getClientPortalClient(this.clientId)
        this.clientName = res.data.Name
        this.restrictEmailDomains = res.data.RestrictUsersToDomain
        this.clientAdminEmail = res.data.ClientAdminEmail
        if (res.data.UseOwnLogo) {
          this.logoOptions = [{ value: 0, text: 'Use Our Logo', selected: true }, { value: 1, text: 'Use Client Logo' }]
        } else {
          this.logoOptions = [{ value: 0, text: 'Use Our Logo' }, { value: 1, text: 'Use Client Logo', selected: true }]
        }
        this.logo = res.data.Logo
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.addingClientForm {
  margin: 10px 0px 4px 0px !important;
  width:100%
}
</style>
