<template>
  <div>
    <div class="px-3 px-md-5 mt-3 mt-md-5">
      <div class="text-black rounded p-3 p-md-4" style="background:rgb(225, 225, 225);">
        <div
          class="text-left text-black font-bold my-2"
          style="margin-top:-5px;"
          :class="{ 'margTop20': mobileView === false }"
        >
          Fixed Next Step
        </div>
        <div :class="{ 'paddTwo': mobileView }" class="grid grid-cols-5 text-bold text-black rounded text-center border-2 border-gray-400  bg-gray-100 p-4 my-1">
          <div class="col-span-5" style="margin-bottom:-5px;text-align:left;font-size:smaller;">
            You will shortly receive an email confirming all uploads have completed. Upon receiving this, please feel free to close the app or web browser.
          </div>
        </div>
        <div class="text-left text-black font-bold my-6">
          Next Steps (optional)
        </div>
        <div>
          <draggable
            :list="localSetup.NextSteps"
            style="cursor:pointer;"
            :options="{disabled : mobileView}"
            @start="drag=true"
            @end="handleMove"
          >
            <div
              v-for="step in localSetup.NextSteps"
              :key="step.NextStepsId"
              :class="{ 'paddTwo': mobileView }"
              class="grid grid-cols-5 text-bold text-black rounded text-center border-2 border-gray-400  bg-gray-100 p-4 my-1"
            >
              <div class="col-span-4 align-self-center overflow-hidden" style="text-align:left;font-size:smaller;white-space:pre-line;">
                {{ step.NextStepsContent }}
              </div>
              <div class="col-span-1 text-right" style="margin-bottom:-5px;">
                <i
                  :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
                  class="col-span-1 fa fa-2x text-left fa-edit mx-2"
                  style="font-size:1.2rem;cursor:pointer;"
                  @click="editNextStep(step)"
                />
                <i
                  :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
                  class="col-span-1 fa fa-2x text-left fa-times mx-2"
                  style="font-size:1.2rem;cursor:pointer;"
                  @click="showConfirmDeleteNextStepModal(step)"
                />
              </div>
            </div>
          </draggable>
          <div
            slot="footer"
            :class="{ 'smallerFont': mobileView }"
            class="rounded text-center border-2 border-gray-400 border-dashed p-3 mt-4"
            style="font-size:1rem;font-weight:bold;padding:20px !important;cursor:pointer;"
            @click="openAddNextStepModal()"
          >
            Add Step
          </div>
        </div>
        <div class="text-left text-black font-bold my-6">
          Outro Video (optional)
        </div>
        <div>
          <div
            v-if="localSetup.OutroVideo"
            :class="{ 'paddTwo': mobileView }"
            class="grid grid-cols-5 text-bold text-black rounded text-center border-2 border-gray-400 bg-gray-100 p-2 my-1"
          >
            <div class="col-span-1 align-self-center text-nowrap overflow-hidden" style="text-align:left;font-size:smaller;">
              <img
                v-if="localSetup.OutroVideo.Thumb"
                :src="localSetup.OutroVideo.Thumb"
                style="height:75px;border:solid 1px #c0c0c0;border-radius:5px;object-fit:cover;"
              >
              <img
                v-if="localSetup.OutroVideo.ThumbnailUrl"
                :src="localSetup.OutroVideo.ThumbnailUrl"
                style="height:75px;border:solid 1px #c0c0c0;border-radius:5px;object-fit:cover;"
              >
            </div>
            <div class="col-span-3 align-self-center text-nowrap overflow-hidden" style="text-align:left;font-size:smaller;margin-left:15px;">
              {{ localSetup.OutroVideo.VideoTitle }}
            </div>
            <div class="col-span-1 align-self-center text-nowrap overflow-hidden text-right">
              <i
                :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
                class="col-span-1 fa fa-2x text-left fa-times mx-2"
                style="font-size:1.2rem;cursor:pointer;"
                @click="deleteOutroVideo()"
              />
            </div>
          </div>
          <div
            v-else
            slot="footer"
            :class="{ 'smallerFont': mobileView }"
            class="rounded text-center border-2 border-gray-400 border-dashed p-3 mt-4"
            style="font-size:1rem;font-weight:bold;padding:20px !important;cursor:pointer;"
            @click="showAddInsertVideoModal = true"
          >
            Add Outro Video
          </div>
        </div>
      </div>
      <div style="margin-bottom:68px;" class="d-flex align-items-center justify-content-between mt-3 mb-md-5">
        <mdb-btn
          class="btn btn-outline"
          size="sm"
          style="width:110px;"
          @click="$router.push(`/roles/add_edit/detail/${role.JobId}`)"
        >
          CANCEL
        </mdb-btn>
        <mdb-btn
          v-show="!buttonClick"
          type="submit"
          class="m-0"
          size="sm"
          style="width:100px;"
          @click="updateLocalSetupAndNext()"
        >
          FINISH
        </mdb-btn>
        <mdb-btn
          v-show="buttonClick"
          class="m-0"
          size="sm"
          style="width:100px;cursor:default;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </div>

      <mdb-modal
        id="addEditNextStep"
        :show="addEditNextStepModal"
        @close="cancelAddEditNextStepModal()"
      >
        <mdb-modal-header>
          <mdb-modal-title>ADD/EDIT NEXT STEP</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body :class="{ 'modalHeightForRoles': mobileView }">
          <div class="md-form form-sm text-left">
            <i class="fas fa-question prefix float-left" style="margin-top: -5px;font-size: 1.3rem;" />
            <div
              class="grey-text"
              style="margin-left: 26px;margin-bottom: -20px;"
              size="sm"
            >
              Next Step Description
            </div>
            <mdb-input
              v-model="nextStep.NextStepsContent"
              type="textarea"
              auto-complete="fna"
              :rows="4"
              size="sm"
              maxlength="500"
              style="margin-left:25px;width:calc(100% - 30px);"
              @keyup.native="charCount(nextStep.NextStepsContent, 500)"
            />
            <div class="grey-text remainingChars">
              {{ chars }}
            </div>
          </div>
        </mdb-modal-body>
        <mdb-modal-footer style="display:block;">
          <mdb-btn
            type="reset"
            class="float-left btn-outline"
            size="sm"
            style="width:100px;"
            @click="cancelAddEditNextStepModal()"
          >
            CANCEL
          </mdb-btn>
          <mdb-btn
            v-show="!waitingUpdate"
            type="submit"
            :disabled="!nextStep.NextStepsContent"
            class="float-right"
            size="sm"
            style="width:100px;"
            @click="addEditNextStep()"
          >
            <span>SAVE</span>
          </mdb-btn>
          <mdb-btn
            v-show="waitingUpdate"
            class="float-right"
            size="sm"
            style="width:100px;"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>

    <InsertVideoModal
      :defaultCategory="'Outro'"
      @handleChosenVideo="handleChosenVideo"
    />
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import roleV2Service from '@/api/services/roleV2.service'
import InsertVideoModal from '@/components/genericModals/InsertVideoModals/insertVideoModal.vue'

import { useUserData, useStatus, useWorkflow, useLibrary } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'

export default {
  name: 'NextSteps',
  components: {
    draggable,
    InsertVideoModal
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const workflowStore = useWorkflow()
    const { role, selectedWorkflowStage, nextSteps } = storeToRefs(workflowStore)

    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()

    const { mobileView } = useMobile()

    const libraryStore = useLibrary()
    const { showAddInsertVideoModal } = storeToRefs(libraryStore)

    return {
      authData,
      showGenericErrorModal,
      role,
      selectedWorkflowStage,
      mobileView,
      nextSteps,
      closeGenericConfirmModal,
      showGenericConfirmModal,
      showAddInsertVideoModal
    }
  },
  data () {
    return {
      localSetup: {},
      addEditNextStepModal: false,
      nextStep: {},
      chars: '0/500',
      waitingUpdate: false,
      buttonClick: false
    }
  },
  watch: {
    selectedWorkflowStage: {
      immediate: true,
      handler: 'prepareLocalSetup'
    }
  },
  mounted () {
    this.prepareLocalSetup()
  },
  methods: {
    async updateLocalSetupAndNext () {
      this.buttonClick = true
      try {
        const res = await roleV2Service.editRoleWorkflowOneWayStage(this.localSetup)
        this.selectedWorkflowStage = { ...this.selectedWorkflowStage, OneWaySetup: res.data, Step: res.data.Step }
        this.$nextTick(() => {
          this.$router.push(`/roles/add_edit/detail/${this.role.JobId}`).catch(() => { })
        })
      } catch (err) {
        this.showGenericErrorModal()
      }
    },
    async handleChosenVideo (video) {
      this.localSetup.OutroVideo = video
      this.localSetup.OutroVideoId = video.UploadedVideoId
      this.showAddInsertVideoModal = false
    },
    deleteOutroVideo () {
      this.localSetup.OutroVideo = null
      this.localSetup.OutroVideoId = null
      this.showAddInsertVideoModal = false
    },
    closeOutroVideoModal () {
      this.showAddInsertVideoModal = false
    },
    charCount (type, max) {
      this.maxCharacter = max
      this.chars = type.length + '/' + max
    },
    openAddNextStepModal () {
      this.nextStep = {
        NextStepsId: 0,
        NextStepsContent: '',
        Ordinal: this.localSetup.NextSteps.length + 1,
        RoleWorkflowStageId: this.localSetup.RoleWorkflowStageId
      }
      this.addEditNextStepModal = true
    },
    editNextStep (step) {
      this.nextStep = step
      this.addEditNextStepModal = true
    },
    async addEditNextStep () {
      try {
        const res = await roleV2Service.addEditNextStepToOneWaySetup(this.nextStep, this.authData.access_token)
        if (res.data) {
          this.setNextSteps(res.data)
        }
      } catch {
        this.showGenericErrorModal()
      }
      this.closeAddEditNextStepModal()
    },
    setNextSteps (data) {
      this.nextSteps = data
      this.localSetup = { ...this.localSetup, NextSteps: data }
    },
    cancelAddEditNextStepModal () {
      this.addEditNextStepModal = false
      this.nextStep = {
        NextStepsId: 0,
        NextStepsContent: '',
        Ordinal: this.localSetup.NextSteps.length + 1,
        RoleWorkflowStageId: this.localSetup.RoleWorkflowStageId
      }
    },
    showConfirmDeleteNextStepModal (nextStep) {
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'DELETE STEP',
        confirmMessage: 'Are you sure you want to delete this "Next Step" message?',
        confirmButtonMessage: 'DELETE',
        confirmButtonFunction: () => this.confirmDeleteNextStep(nextStep.NextStepsId)
      })
    },
    async confirmDeleteNextStep (nextStepId) {
      try {
        const res = await roleV2Service.deleteNextStepFromOneWaySetup(nextStepId, this.authData.access_token)
        if (res.data) {
          this.setNextSteps(res.data)
        }
      } catch {
        this.showGenericErrorModal()
      }
      this.closeGenericConfirmModal()
    },
    closeAddEditNextStepModal () {
      this.waitingUpdate = false
      this.nextStep = {}
      this.cancelAddEditNextStepModal()
      this.deleteNextStepModal = false
      this.chars = '0/500'
    },
    async handleMove () {
      var ids = []
      this.localSetup.NextSteps.forEach(element => {
        ids.push(element.NextStepsId)
      })
      const JSONData = {
        NextStepIds: ids,
        RoleWorkflowStageId: this.localSetup.RoleWorkflowStageId
      }
      try {
        const res = await roleV2Service.editNextStepOrdinals(JSONData, this.authData.access_token)
        if (res.data) {
          this.setNextSteps(res.data)
        }
      } catch (err) {
        this.showGenericErrorModal()
      }
    },
    sortNextStep (a, b) {
      if (a.Ordinal < b.Ordinal) {
        return -1
      } else if (a.Ordinal > b.Ordinal) {
        return 1
      }
      return 0
    },
    prepareLocalSetup () {
      if (this.selectedWorkflowStage) {
        const nextSteps = this.selectedWorkflowStage.OneWaySetup.NextSteps.sort(this.sortNextStep)
        this.localSetup = { ...this.selectedWorkflowStage.OneWaySetup, Step: 4, NextSteps: nextSteps }
      }
    }
  }
}
</script>
