<template>
  <mdb-modal
    id=""
    :show="testScoringModalShowing"
    size="lg"
    style="overflow-y:auto;"
    @close="$emit('testScoringModalShowing', false)"
  >
    <mdb-modal-header>
      <mdb-modal-title class="font-weight-bold">
        {{ modalTitle.toUpperCase() }} SCORING SCALE
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <ScoringSlider
        class="my-5"
        :lowest-scale-value="lowestScaleValue"
        :number-of-steps="numberOfSteps"
        :step-increment="stepIncrement"
        :text-array="textArray"
        :make-text-based="makeTextBased"
      />
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import ScoringSlider from '@/components/misc/Scoring/scoringSlider.vue'

export default {
  name: 'TestScoringModal',
  components: {
    ScoringSlider
  },
  props: {
    testScoringModalShowing: Boolean,
    lowestScaleValue: {
      type: Number,
      default: 0
    },
    stepIncrement: {
      type: Number,
      default: 0
    },
    numberOfSteps: {
      type: Number,
      default: 0
    },
    makeTextBased: Boolean,
    textArray: {
      type: Array,
      default: () => []
    },
    modalTitle: {
      type: String,
      default: 'TEST'
    }
  }
}
</script>
