<template>
  <button
    :key="count"
    :title="actionButtonTitle"
    type="button"
    class="btn btn-primary mobilePlacement btn-md m-0"
    style="padding: 0.4rem 0.6rem;"
    @click="actionButtonFunc()"
  >
    <span class="d-none d-xl-inline">{{ actionButtonText }}</span>
    <span class="d-inline d-xl-none"><i :class="actionButtonIcon" /></span>
  </button>
</template>

<script>
import { useRoleCandidate } from '@/pinia'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue-demi'

export default {
  name: 'OneWayStageActionButton',
  props: {
    candidate: {
      type: Object,
      default: () => {}
    },
    stageItemStatus: {
      type: Number,
      default: 0
    },
    roleWorkflowStage: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    const roleCandidateStore = useRoleCandidate()
    const { resendInviteOneWayInvite, unlockInterview, resetOneWay } = useRoleCandidate()
    const { candidate: piniaCandidate } = storeToRefs(roleCandidateStore)

    const showUnlockInterview = computed(() => props.candidate.OneWayIsLocked)
    const showResendInviteEmail = computed(() => props.stageItemStatus == 2)
    const showResetInterview = computed(() => props.stageItemStatus == 5)
    const showWatchInterview = computed(() => (!props.candidate.OneWayIsLocked && props.stageItemStatus == 4) || props.stageItemStatus >= 6)

    const unlockInterviewLocal = async () => {
      piniaCandidate.value = props.candidate
      await unlockInterview(props.candidate, props.roleWorkflowStage.RoleWorkflowStageId, () => emit('updateComponentContext'))
    }
    const resendInviteLocal = async () => {
      piniaCandidate.value = props.candidate
      await resendInviteOneWayInvite(props.candidate, props.roleWorkflowStage.RoleWorkflowStageId, () => emit('updateComponentContext'))
    }
    const resetInterviewLocal = async () => {
      piniaCandidate.value = props.candidate
      resetOneWay(props.candidate, props.roleWorkflowStage.RoleWorkflowStageId, () => emit('updateComponentContext'))
    }
    const watchInterview = () => {
      emit('initialiseReviewModal', props.candidate)
    }

    const actionButtonText = ref('')
    const actionButtonFunc = ref(() => {})
    const actionButtonIcon = ref('')
    const actionButtonTitle = ref('')

    if (showUnlockInterview.value) {
      actionButtonText.value = 'UNLOCK'
      actionButtonFunc.value = unlockInterviewLocal
      actionButtonIcon.value = 'fa fa-unlock'
      actionButtonTitle.value = 'Unlock the interview'
    } else if (showResendInviteEmail.value) {
      actionButtonText.value = 'RESEND EMAIL'
      actionButtonFunc.value = resendInviteLocal
      actionButtonIcon.value = 'fa fa-envelope'
      actionButtonTitle.value = 'Resend the invite email'
    } else if (showResetInterview.value) {
      actionButtonText.value = 'RESET INTERVIEW'
      actionButtonFunc.value = resetInterviewLocal
      actionButtonIcon.value = 'fa fa-sync'
      actionButtonTitle.value = 'Reset this stages interview'
    } else if (showWatchInterview.value) {
      actionButtonText.value = 'WATCH INTERVIEW'
      actionButtonFunc.value = watchInterview
      actionButtonIcon.value = 'fa fa-tv' // CHECK THIS
      actionButtonTitle.value = 'Review this stages interview'
    }

    const count = ref(0)
    const stageItemStatus = computed(() => props.stageItemStatus)
    watch(stageItemStatus, () => count.value++ )

    return {
      actionButtonText,
      actionButtonFunc,
      actionButtonIcon,
      actionButtonTitle,
      count
    }
  }
}
</script>

<style>

</style>
