<template>
  <div
    :style="{ 'border' : isAdded ? 'solid 3px green' : 'solid 3px grey'}"
    class="d-flex align-items-center justify-content-between p-2 text-grey"
    style="height:35px; width:fit-content;border-radius:20px"
    @click="toggleAdded()"
  >
    <div
      v-if="isAdded && !loadingQuickSelectCandidates"
      class="mr-2"
      style="color:green"
    >
      <FontAwesomeIcon icon="fa-solid fa-circle-check" />
    </div>
    <div
      v-if="!isAdded && !loadingQuickSelectCandidates"
      class="mr-2"
      style="color:grey"
    >
      <FontAwesomeIcon icon="fa-regular fa-circle-plus" />
    </div>
    <div
      v-if="loadingQuickSelectCandidates"
      class="mr-2 p-0"
      style="color:grey"
    >
      <mdb-icon class="m-0 p-0" icon="fa fa-pulse fa-spinner" />
    </div>
    <div style="font-size:0.8rem;" class="mr-2 grey-text font-weight-bold">
      {{ text }}
    </div>
    <div style="height:20px;min-width:20px;width:fit-content;border-radius:50%;background: grey; color:#fff;font-size:0.7rem;" class="d-flex align-items-center justify-content-center p-1">
      {{ count }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuickSelect',
  components: {
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    count: {
      type: Number,
      default: 0
    },
    loadingQuickSelectCandidates: Boolean
  },
  data () {
    return {
      isAdded: false
    }
  },
  methods: {
    toggleAdded () {
      if (!this.loadingQuickSelectCandidates) {
        this.isAdded = !this.isAdded
        this.$emit('toggleAdded', { type: this.text, isAdded: this.isAdded })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
