import userService from '@/api/services/user.service'
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'

var defaultLanding = null

const getDefaultLanding = async () => {
  const req = await userService.getDefaultLanding()
  return req.status === 200 ? req.data : ['Candidates']
}

const checkDefaultLanding = async () => {
  if (!defaultLanding || defaultLanding.length === 1) defaultLanding = await getDefaultLanding()
}

export const setDefaultLanding = async (index) => {
  const userStore = useUserData()
  const { landingPage } = storeToRefs(userStore)

  await checkDefaultLanding()

  var route = defaultLanding[index]
    ? `/${defaultLanding[index].toLowerCase().replace(/\s+/g, '')}`
    : 'settings/mysettings'
  if (route === '/clientportal') route = '/client_portal/clients'
  if (route === '/roles') route = '/roles'

  landingPage.value = route
}

export const mapDefaultLanding = async (id) => {
  await checkDefaultLanding()

  const options = defaultLanding.map((text, i) => i === id
    ? { text, value: text, selected: true }
    : { text, value: text, selected: false })

  return options
}
