<template>
  <div class="p-4 p-lg-5">
    <div style="text-align:left;padding:2px;">
      <div class="font-weight-bold">
        Candidates
      </div>
      <div class="h5 grey-text" style="margin-top:20px;">
        Import Candidates
      </div>
      <div class="grey-text small">
        When importing candidate data, please provide a correctly formatted CSV file (click <a
          href="https://go.shineinterview.com/bulk-upload-example.csv"
          target="_blank"
          class="primary-colour"
        ><strong>here</strong></a> to download an example) including first name, last name, email address (all other fields are optional). <br><br>
        Note: there is a limit of {{ BULK_UPLOAD_LIMIT }} records per CSV upload. <br><br><strong>Please ensure the data you are about to import is correct. You will not be able to undo this action.</strong>
        <br><br>
      </div>
      <validation-observer>
        <form>
          <div class="md-form form-sm" @click="launchCsvPicker">
            <i class="fas fa-upload prefix" style="margin-top: 6px;font-size: 1.3rem;cursor:pointer" />
            <input
              type="text"
              class="form-control"
              :placeholder="importText"
              :value="file.name"
              readonly
            >
            <input
              ref="file"
              v-uploader
              type="file"
              style="display:none"
              accept=".csv"
            >
          </div>
          <mdb-btn
            class="float-left btn-outline"
            size="sm"
            style="width:100px;"
            @click="cancel"
          >
            CANCEL
          </mdb-btn>
          <div v-if="!confirm">
            <mdb-btn
              v-show="!buttonClick"
              :disabled="file == ''"
              class="float-right"
              size="sm"
              style="width:110px;"
              @click="confirmImport()"
            >
              IMPORT
            </mdb-btn>
          </div>
          <div v-else>
            <mdb-btn
              v-show="!buttonClick"
              :disabled="file == ''"
              class="float-right"
              size="sm"
              style="width:110px;color:white !important;background-color: red !important;font-weight: bold;"
              @click="importMassCsv()"
            >
              CONFIRM
            </mdb-btn>
            <mdb-btn
              v-show="buttonClick"
              class="float-right"
              size="sm"
              style="width:110px;color:white !important;background-color: red !important;font-weight: bold;"
            >
              <mdb-icon icon="fa fa-pulse fa-spinner" />
            </mdb-btn>
          </div>
        </form>
      </validation-observer>
    </div>

    <mdb-modal
      id="genericInfoModal"
      :show="infoModal"
      @close="infoModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>PROCESSING</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <!--<div class="my-4 grey-text" style="margin-top:20px !important;font-size:0.8rem;text-align:left;">
          Your import is in progress. This may take a number of minutes depending on how many records are being uploaded, whether the candidate already exists and 
          whether or not you have requested them to complete a video CV as part of the process. It can take between 1 and 10 seconds to process each candidate record.
          <br><br>
          Note: you can track any failures from notifications and from email alerts.
        </div>-->
        <div class="my-4 grey-text" style="margin-top:20px !important;font-size:0.8rem;text-align:left;">
          Your import is in progress. This may take a number of minutes depending on how many records are being uploaded and whether the candidate already exists. It can take between 1 and 10 seconds to process each candidate record.
          <br><br>
          Note: you can track any failures from notifications and from email alerts.
        </div>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="closeModalAndReload()"
        >
          CLOSE
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { useCandidate, useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import candidateService from '@/api/services/candidate.service'
import { userLanguage } from '@/common/userLanguage'
import { BULK_UPLOAD_LIMIT } from '@/common/config.js'

export default {
  name: '',
  directives: {
    uploader: {
      bind (el, binding, vnode) {
        el.addEventListener('change', e => {
          if (e.target.files[0] !== undefined) {
            vnode.context.file = e.target.files[0]
          }
        })
      }
    }
  },
  setup () {
    const candidateStore = useCandidate()
    const { view, candidates } = storeToRefs(candidateStore)

    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)
    return {
      view,
      authData,
      candidates,
      BULK_UPLOAD_LIMIT
    }
  },
  data () {
    return {
      buttonClick: false,
      csvText: 'Select a new cv to upload',
      file: '',
      importText: 'Select a csv file to upload',
      userLanguage,
      infoModal: false,
      confirm: false
    }
  },
  created () {
    this.userLanguage = ((navigator.languages && navigator.languages[0]) || '').substr(0, 5)
  },
  methods: {
    confirmImport () {
      this.confirm = true
    },
    launchCsvPicker () {
      this.$refs.file.click()
    },
    importMassCsv () {
      this.buttonClick = true
      var formData = new FormData()
      formData.append('file', this.file)
      try {
        setTimeout(() => {
          candidateService.importMassCsv(formData)
          this.infoModal = true
          this.buttonClick = false
        }, 3000)
      } catch (error) {
        this.buttonClick = false
        this.file = ''
        this.$refs.file.value = null
        this.$emit('getCandidates', false, false)
        this.$emit('errorAlert', { error, msg: 'Sorry an error has occurred!' })
      }
    },
    importCandidates () {
      this.showCandidates = false
      this.file = ''
    },
    cancel () {
      this.view = 'ViewAllCandidates'
    },
    closeModalAndReload () {
      this.file = ''
      this.$refs.file.value = null
      this.$emit('getCandidates', false, false)
      this.view = 'ViewAllCandidates'
    }
  }
}
</script>
