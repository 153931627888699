<template>
  <div
    class="d-flex align-items-center justify-content-between p-1 pl-2 text-black cp"
    :disabled="userData.rle === 'Inviter'"
    @click="setCandidates()"
  >
    <div
      class="font-weight-bold dark-grey-text"
    >
      {{ stageItem.RoleWorkflowStageItemName }}
    </div>
    <div
      class="mr-2 font-weight-bold"
      style="border-radius:10px; padding: 2px 8px;width:50px;text-align:center;"
      :style="{'background': !role.IsComplete ? '#cecece' : userData.lbl.PrimaryColour, 'color': '#fff'}"
    >
      {{ stageItem.Count }}
    </div>
  </div>
</template>

<script>

import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router/composables'

export default {
  name: 'StageList',
  props: {
    stageItem: {
      type: Object,
      default: () => {}
    },
    role: {
      type: Object,
      default: () => {}
    },
    selectedStage: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    const router = useRouter()
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)
    const setCandidates = () => {
      if (userData.value.rle !== 'Inviter') {
        router.push(`/roles/candidates/${props.role.JobId}/${props.selectedStage.RoleWorkflowStageId}/${props.stageItem.RoleWorkflowStageItemId}`).catch(() => {})
      }
    }
    return {
      userData,
      setCandidates
    }
  }
}
</script>
