<template>
  <div class="col-sm templateDiv" style="margin-bottom:52px !important;">
    <div style="text-align:left;padding:2px;margin-top:0px;" class="h-full">
      <div class="grid grid-cols-3 w-1/1 mt-8 border-bottom">
        <div
          class="cursor-pointer text-lg grey-text text-center"
          :class="{ 'default': section == 'sum', 'mobileSize': mobileView === true }"
          @click="setSection('sum')"
        >
          <span>SUMMARY</span>
        </div>
        <div
          class="cursor-pointer text-lg grey-text text-center"
          :class="{ 'default': section == 'det', 'mobileSize': mobileView === true }"
          @click="setSection('det')"
        >
          <span>DETAILS</span>
        </div>
        <div
          class="cursor-pointer text-lg grey-text text-center"
          :class="{ 'default': section == 'int', 'mobileSize': mobileView === true }"
          @click="setSection('int')"
        >
          <span>INTEGRATIONS</span>
        </div>
      </div>
      <div v-if="section === 'sum'">
        <sum />
      </div>
      <div v-if="section === 'det'">
        <det />
      </div>
      <div v-if="section === 'int'">
        <int />
      </div>
    </div>
  </div>
</template>

<script>
import { useMobile } from '@/composables/useMobile'

import det from './Tabs/det.vue'
import sum from './Tabs/sum.vue'
import int from './Tabs/int.vue'

export default {
  name: '',
  components: {
    det,
    sum,
    int
  },
  setup () {
    const { mobileView } = useMobile()

    return {
      mobileView
    }
  },
  data () {
    return {
      section: 'sum',
      settingsLoaded: true
    }
  },
  methods: {
    setSection (type) {
      this.section = type
    }
  },
}
</script>

<style>
  ul.nav-tabs.nav-justified {
    background-color: var(--bg-color) !important;
  }
</style>

<style lang="scss" scoped>
.md-tabs {
  position: relative;
    z-index: 1;
    padding: 0rem !important;
    margin-right: -1rem !important;
    margin-bottom: -20px;
    margin-left: -1rem !important;
    background-color: #999;
    border: 0;
    border-radius: 0rem !important;
    box-shadow: none !important;
}
.repTop {
  margin-top: 10px;
}
.repLeft {
  margin-left: -19px;
}
</style>
