<template>
  <div class="col-12 col-xl-6 px-3">
    <div
      class="font-weight-bold"
      style="font-size: clamp(1.3rem, 2vw, 1.8rem);">
      {{tableTitle}}
    </div>
    <div class="d-flex align-items-center text-center column-headers">
      <div class="col-7 p-0">
        Display Label
      </div>
      <div class="col-5 p-0">
        Type
      </div>
    </div>
    <Container
      style="width: 100%; height: fit-content;"
      :animationDuration="200"
      lock-axis="y"
      group-name="1"
      orientation="vertical"
      :get-child-payload="getChildPayload"
      :auto-scroll-enabled="false"
      @drop="onDrop('itemsToRender', $event)">
      <Draggable
        class="mb-2"
        v-for="(item, i) in itemsToRender"
        :key="i"
        v-model="itemsToRender"
        style="width: 100%; overflow: visible"
      >
        <EditStageItemDraggableRow
          class="mr-5"
          @refreshStatuses="$emit('refreshStatuses')"
          :stageItem="item"/>
      </Draggable>
    </Container>
    <div class="d-flex align-items-center justify-content-end">
      <slot />
    </div>
  </div>
</template>

<script>
import { nextTick, ref, watch } from 'vue-demi'
import EditStageItemDraggableRow from './editStageItemDraggableRow.vue'
import { Draggable, Container } from 'vue-dndrop'
import roleV2Service from '@/api/services/roleV2.service'

export default {
  name: 'EditStageItemTable',
  components: { EditStageItemDraggableRow, Draggable, Container },
  setup (props, { emit }) {
    const itemsToRender = ref([])

    // we want to use the props value to loop through but as we want to change the ordinals we cant do this directly
    itemsToRender.value = [ ...props.initialStageItems ]
    watch(props, async () => {
      itemsToRender.value = []
      await nextTick(() => {})
      itemsToRender.value = [ ...props.initialStageItems ]
    }, { deep: true })

    // UPDATE ORDINAL METHODS
    const updateStageItemOrdinals = async () => {
      try {
        let ordinalIdDictionary = {}
        itemsToRender.value.map((a, index) => {
          ordinalIdDictionary[a.RoleWorkflowStageItemId] = index + props.minimumOrdinal
        })
        await roleV2Service.editRoleWorkflowStageItemOrdinals(ordinalIdDictionary)
        emit('refreshStatuses')
      } catch (err) {
        console.log(Object.assign({}, err))
      }
    }
    const getChildPayload = index => {
      return itemsToRender.value[index]
    }
    const applyDrag = (arr, dragResult) => {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr
      const result = [...arr]
      let itemToAdd = payload
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }
      return result
    }
    const onDrop = async (_, dropResult) => {
      const newStageItemArray = applyDrag(itemsToRender.value, dropResult)
      itemsToRender.value = []
      await nextTick(() => {})
      itemsToRender.value = newStageItemArray
      if (dropResult.addedIndex !== null) {
        updateStageItemOrdinals(dropResult)
      }
    }
    
    return { itemsToRender, getChildPayload, onDrop }
  },
  props: {
    tableTitle: {
      type: String,
      default: ''
    },
    initialStageItems: {
      type: Array,
      default: () => []
    },
    minimumOrdinal: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped>
.column-headers {
  color: #333 !important;
  font-weight: bold;
  font-size: clamp(1.1rem, 2vw, 1.2rem);
  margin: 10px 0;
  padding: 10px 0;
  border-bottom: #444 solid 2px;
}
</style>