<template>
  <div class="d-flex col outerContainerBoilerplate p-0" :style="{'margin-top' : mobileView ? '-30px' : '0px'}">
    <LeftSettingsColumn />

    <div class="mainSectionBoilerPlate">
      <div class="mainSectionBoilerPlateInner">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { useMobile } from '@/composables/useMobile'
import LeftSettingsColumn from './innerComponents/leftSettingsColumn.vue'

export default {
  name: 'Settings',
  components: {
    LeftSettingsColumn
  },
  setup () {
    const { mobileView } = useMobile()

    return {
      mobileView
    }
  }
}
</script>

<style>
/* Hide scrollbar for Chrome, Safari and Opera */
* ::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>

<style lang="css" scoped>
.leftColHeader {
  background:#c1c1c1;
  padding: 26px 20px;
  font-size: 1.2rem;
}
.leftColMainContent {
  overflow-y:auto;
  flex-grow:1;
}
.outerContainerBoilerplate {
  width:100vw;
  max-height: calc(100vh - 80px);
}
.leftColumnBoilerPlate {
  height: calc(100vh - 80px);
  width:330px;
  background:#e1e1e1;
  background-color:#e1e1e1;
}
.mainSectionBoilerPlate {
  flex-grow: 1;
  min-height: calc(100vh - 80px);
  overflow-y: auto;
}
.mainSectionBoilerPlateInner {
  height:fit-content;
}
.leftColBottomButtonPanel {
  height:fit-content;
  padding: 11px;
  background:#d1d1d1;
  border-top: 1px solid #a1a1a1;
}
@media screen and (max-width: 991px) {
  .outerContainerBoilerplate {
    width:100vw;
  }
  .leftColumnBoilerPlate {
    height: calc(100vh - 80px);
    width:230px;
  }
}
</style>
