<template>
  <div class="w100">
    <!-- CUSTOM TEMPLATES -->
    <div v-if="customWorkflowTemplates.length">
      <div
        style="font-size: 0.9rem"
        class="font-weight-bold mt-4 mb-2"
      >
        MY CUSTOM TEMPLATES
      </div>
      <InterviewTemplates
        :selectedWorkflowTemplate="selectedWorkflowTemplate"
        :workflowTemplates="customWorkflowTemplates" 
        @setSelectedTemplate="selectedWorkflowTemplate = $event"
      />
    </div>
    <!-- DEFAULT TEMPLATES -->
    <div
      v-if="customWorkflowTemplates.length"
      style="font-size: 0.9rem"
      class="font-weight-bold mt-4 mb-2"
    >
      STANDARD TEMPLATES
    </div>
    <InterviewTemplates
      :selectedWorkflowTemplate="selectedWorkflowTemplate"
      :workflowTemplates="defaultWorkflowTemplates" 
      @setSelectedTemplate="selectedWorkflowTemplate = $event"
    />
    <div class="w100 d-flex justify-content-end mt-3">
      <mdb-btn
        :disabled="!selectedWorkflowTemplate.GoWorkflowTemplateId"
        class="btn"
        size="sm"
        style="width:160px;"
        @click="saveWorkflowTemplateToRole()"
      >
        <span v-if="addingWorkflow"><i class="fa fa-pulse fa-spinner" /></span>
        <span v-else>SELECT WORKFLOW</span>
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import roleV2Service from '@/api/services/roleV2.service'
import { useStatus, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'
import InterviewTemplates from './interviewTemplates.vue'

export default {
  name: 'WorkflowTemplateContainer',
  components: { InterviewTemplates },
  setup () {
    const workflowStore = useWorkflow()
    const { getRole, role } = storeToRefs(workflowStore)

    const { showGenericErrorModal } = useStatus()

    return {
      showGenericErrorModal,
      getRole,
      role
    }
  },
  data () {
    return {
      defaultWorkflowTemplates: [],
      customWorkflowTemplates: [],
      addingWorkflow: false,
      selectedWorkflowTemplate: {}
    }
  },
  mounted () {
    this.getWorkflowTemplates()
  },
  methods: {
    async getWorkflowTemplates () {
      try {
        const res = await roleV2Service.getWorkflowTemplates()
        this.defaultWorkflowTemplates = [...res.data.filter(t => t.IsDefault)]
        this.customWorkflowTemplates = [...res.data.filter(t => !t.IsDefault)]
      } catch {
        this.showGenericErrorModal()
      }
    },
    async saveWorkflowTemplateToRole () {
      this.addingWorkflow = true
      // will be an api call at some point
      const JSONData = {
        GoWorkflowTemplateId: this.selectedWorkflowTemplate.GoWorkflowTemplateId,
        JobId: this.role.JobId,
        RoleWorkflowName: this.selectedWorkflowTemplate.GoWorkflowTemplateName // this doesnt actually get used but is required for the ViewModel
      }
      try {
        await roleV2Service.addRoleWorkflow(JSONData)
        this.getRole(this.role.JobId)
      } catch {
        this.showGenericErrorModal()
      }
      this.addingWorkflow = false
    }
  }
}
</script>

