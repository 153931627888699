<template>
  <h6 :disabled="linkCopied === true || creatingQuickShare === true" @click="quickShare()">
    <div v-if="linkCopied === false && creatingQuickShare === false" class="d-flex align-items-center">
      <i class="fa fa-link ml-1 grey-link" style="margin-right:10px;" />
      <a class="grey-link">Quick Share</a>
    </div>
    <div v-if="linkCopied === true && creatingQuickShare === false" class="d-flex align-items-center">
      <i class="fa fa-link ml-1 grey-link" style="margin-right:10px;" />
      <a class="grey-link">Share link copied</a>
    </div>
    <div v-if="creatingQuickShare === true" class="d-flex align-items-center">
      <i class="fa fa-spinner fa-pulse ml-1 grey-link" style="margin-right:10px;" />
      <a class="grey-link">Creating Your Link</a>
    </div>
  </h6>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import candidateV2Service from '@/api/services/candidateV2.service'

export default {
  name: 'QuickSharePopoverButton',
  props: {
    CandidateId: {
      type: Number,
      default: 0
    },
    RoleId: { type: Number, default: null },
    RoleWorkflowStageId: { type: String, default: null },
    RoleWorkflowStageType: { type: Number, default: null },
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    return {
      authData
    }
  },
  data () {
    return {
      linkCopied: false,
      creatingQuickShare: false
    }
  },
  methods: {
    async quickShare () {
      this.creatingQuickShare = true
      setTimeout(() => { this.creatingQuickShare = false }, 2000)
      let shareType = -1
      if (this.RoleWorkflowStageType === null) {
        shareType = 0 // Candidates
      } else if (this.RoleWorkflowStageType === 1) {
        shareType = 1 // One-way
      } else if (this.RoleWorkflowStageType === 2) {
        shareType = 2 // Two-way
      }
      var requestData = {
        CandidateId: this.CandidateId,
        ShareType: shareType,
        RoleId: this.RoleId,
        RoleWorkflowStageId: this.RoleWorkflowStageId
      }
      const response = await candidateV2Service.quickShareCandidates(requestData)
      this.linkCopied = true
      await navigator.clipboard.writeText(response.data)
      setTimeout(() => {
        this.$emit('closePopover')
        this.$nextTick(() => { this.linkCopied = false })
      }, 5000)
    }
  },
}
</script>

