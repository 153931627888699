export const returnDateWithSuffix = date => {
  const day = new Date(date).getDate()
  if (day === 1 || day === 21 || day === 31) {
    return `${day}st`
  } else if (day === 2 || day === 22) {
    return `${day}nd`
  } else if (day === 3 || day === 23) {
    return `${day}rd`
  }
  return `${day}th`
}

export const returnShortMonthAndYear = date => {
  const year = date.substring(0, 4)
  const month = new Date(date).toLocaleString('default', { month: 'short' })
  return `${month.toUpperCase()} ${year.substring(2, 4)}`
}