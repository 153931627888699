import { API_URL } from '@/common/config.js'

export default {

  uploadVideo: async function (type, file, data) {
    let formBody = []
    let fileGuid = null

    for (var property in data) {
      var encodedKey = encodeURIComponent(property)
      var encodedValue = encodeURIComponent(data[property])
      formBody.push(encodedKey + '=' + encodedValue)
    }
    formBody = formBody.join('&')

    await fetch(`${API_URL}/api/shortlistreview/initialize`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: formBody
    }).then(async function (response) {
      return response.text().then(async function (text) {
        const rem1 = text.replace('"', '')
        const rem2 = rem1.replace('"', '')
        fileGuid = rem2
      })
    })

    const size = file.size
    const blockSize = 524288
    const blocks = []
    let offset = 0
    let index = 0
    let list = ''
    const selectedVideo = data.selectedVideo

    while (offset < size) {
      const start = offset
      const end = Math.min(offset + blockSize, size)

      blocks.push({
        selectedVideo, index, start, end
      })

      list += index + ','
      offset = end
      index++
    }

    var putBlocks = []

    var fileCreated = null

    if (typeof (file.name) === 'string') {
      fileCreated = file
    } else {
      fileCreated = new File([file], data.selectedVideo)
      if (type === 1) {
        fileCreated = new File([file], data.selectedVideo)
      }
    }

    blocks.forEach(element => {
      const blobData = fileCreated.slice(element.start, element.end)
      putBlocks.push({
        block: element,
        file: blobData,
        data: data,
        guid: fileGuid
      })
    })

    const tasks = putBlocks.map(getUpload)
    const results = await Promise.all(tasks)
    let fail = false

    results.forEach(element => {
      if (!element) {
        fail = true
      }
    })

    var commitResponse = false

    if (fail) {
      return false
    } else {
      const cData = {
        name: data.selectedVideo,
        list: list,
        userEmail: data.userEmail,
        guid: fileGuid,
        inviteCode: data.inviteCode
      }

      let commitForm = []

      for (var p in cData) {
        var ek = encodeURIComponent(p)
        var ev = encodeURIComponent(cData[p])
        commitForm.push(ek + '=' + ev)
      }
      commitForm = commitForm.join('&')

      await fetch(`${API_URL}/api/shortlistreview/commit`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: commitForm
      }).then(async function (response) {
        if (response.ok) {
          commitResponse = true
        } else {
          commitResponse = false
        }
      })
    }

    return commitResponse

    function getUpload (block) {
      return uploadBlock(block.block, block.file, block.data, block.guid)
    }

    async function uploadBlock (block, blob, data, guid) {
      var result = false
      const fData = new FormData()
      fData.append('userEmail', data.userEmail)
      fData.append('inviteCode', data.inviteCode)
      fData.append('guid', guid)
      fData.append('name', data.selectedVideo)
      fData.append('index', block.index)
      fData.append('file', blob)

      await fetch(`${API_URL}/api/shortlistreview/upload`, {
        method: 'POST',
        body: fData
      }).then(async function (response) {
        if (response.ok) {
          result = true
        }
      })

      return result
    }
  }
}
