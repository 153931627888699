<template>
  <div
    class="px-3 px-md-5 mt-4 overFlowY"
    :class="mobileView ? 'mt-6 pt-8' : ''"
    style="min-height: fit-content;"
  >
    <div
      v-if="usersLoaded === false"
      style="height:70px;margin-top: -15px !important;"
      class="d-flex align-items-center"
    >
      <h3 class="primary-colour font-weight-bold mr-2">
        Loading
      </h3> <i class="fa fa-spin fa-spinner" />
    </div>

    <div v-show="addEditUserForm == false && usersLoaded" :class="{ 'vMobBrandingTop': mobileView }">
      <div
        v-if="users.length === 0"
        class="text-left grey-text"
        style="margin-top:25px;"
        :class="{ 'noUsersMob': mobileView }"
      >
        No users exist. Click <a
          class="primary-colour"
          style="font-weight:bold;"
          @click="newUser()"
        ><em>here</em></a> to add new user.
      </div>
      <div class="candidatesScrollContainer">
        <div class="candidatesScrollContent">
          <div v-show="users.length > 0" style="margin-top: -10px !important;">
            <div class="d-flex align-items-center justify-content-between mb-3 pt-2">
              <h3 class="primary-colour font-weight-bold">
                Users
              </h3>
              <ExpandableSearchBar
                :width="400"
                :max-widthpercentage="50"
                @search="filter = $event"
              />
            </div>

            <!--data table for desktop-->
            <div v-if="mobileView === false">
              <b-table
                id="users-table"
                class="text-left"
                head-variant="dark"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :items="users"
                :per-page="perPage"
                :current-page="currentPage"
                :fields="fields"
                :filter="filter"
                :striped="true"
                hover
                small
                sort-icon-left
                responsive="sm"
              >
                <template #cell(user)="data">
                  <div
                    style="margin-top:0px;margin-left:5px;min-height:40px;"
                    class="d-flex align-items-center"
                    :class="{ 'fSizeSm': mobileView }"
                    @click="showUserDetailsModal(data.item)"
                  >
                    <div style="height:40px;width:40px;border-radius:50%;display:flex;align-items:center;justify-content:center;margin-top:10px;" class="mr-2 d-none d-sm-block d-md-none d-lg-block">
                      <img
                        v-if="data.item.ProfilePicture != '' && data.item.ProfilePicture != null && !data.item.ProfilePicture.includes('silhouette')"
                        style="border:solid 1px #c0c0c0;height:36px;width:36px;border-radius:50%;object-fit:cover;"
                        :src="data.item.ProfilePicture"
                      >
                      <span
                        v-else
                        aria-hidden="true"
                        style="border:solid 1px #c0c0c0;padding: 9px;border-radius:50%;"
                      >
                        <i
                          class="fa fa-user grey-text"
                          aria-hidden="true"
                          style="padding-left: 0px;padding-top: 4px;font-size: 18px;"
                        />
                      </span>
                    </div>
                    <div
                      v-if="data.item.Verified === false"
                      class="mr-2 grey-text"
                      @click="showUserDetailsModal(data.item)"
                    >
                      <div class="webDisplay" style="margin-top:5px;">
                        {{ data.item.User }}
                      </div>
                      <div class="d-flex d-md-none align-items-center" style="transform:translateY(-6px)">
                        <div class="mr-1">
                          {{ data.item.User }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-else
                      class="mr-2"
                      @click="showUserDetailsModal(data.item)"
                    >
                      <div class="webDisplay" style="margin-top:5px;">
                        {{ data.item.User }}
                      </div>
                      <div class="d-flex d-md-none align-items-center" style="transform:translateY(-6px)">
                        <div class="mr-1">
                          {{ data.item.User }}
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template #cell(role)="data">
                  <div
                    v-if="data.item.Verified === false"
                    style="margin-top:12px;"
                    class="grey-text"
                  >
                    {{ data.item.Role }}
                  </div>
                  <div v-else style="margin-top:12px;">
                    {{ data.item.Role }}
                  </div>
                </template>
                <template #cell(datecreated)="data">
                  <div
                    v-if="data.item.Verified === false"
                    style="margin-top:12px;"
                    class="grey-text"
                  >
                    {{ new Date(data.item.DateCreated).toLocaleDateString([userLanguage]) }}
                  </div>
                  <div v-else style="margin-top:12px;">
                    {{ new Date(data.item.DateCreated).toLocaleDateString([userLanguage]) }}
                  </div>
                </template>
                <template #cell(actions)="data">
                  <div class="d-flex align-items-center justify-content-end" style="cursor:pointer;">
                    <button
                      v-show="!data.item.IsSuspended"
                      v-if="data.item.Role !== 'Super User'"
                      title="Reset this user?"
                      type="button"
                      class="btn btn-primary btn-md"
                      style="padding: 0.5rem 0.7rem;"
                      @click="showResetUserModal(data.item.Id)"
                    >
                      <span class="d-none d-lg-inline mr-2">RESET PASSWORD</span><mdb-icon icon="sync" />
                    </button>
                    <button
                      v-show="!data.item.IsSuspended"
                      v-if="data.item.Role === 'Super User'"
                      title="Reset this user?"
                      type="button"
                      class="btn btn-primary btn-md disabled"
                      style="cursor:default;padding: 0.5rem 0.7rem;"
                    >
                      <span class="d-none d-md-inline mr-lg-2">RESET PASSWORD</span><mdb-icon icon="sync" />
                    </button>
                    <button
                      v-show="data.item.IsSuspended"
                      title="User suspended, click to reset"
                      type="button"
                      class="btn btn-primary btn-md"
                      style="padding: 0.5rem 0.7rem;"
                      @click="showUnSupendUserModal(data.item.Id)"
                    >
                      <mdb-icon icon="unlock" />
                    </button>
                    <mdb-popover
                      trigger="click"
                      :options="{placement: 'bottom'}"
                      style="cursor:pointer;"
                      class="grey-text"
                    >
                      <div slot="body">
                        <h6 style="margin-left:-10px;margin-top:-5px;padding:10px;" @click="addEditUser(data.item)">
                          <a class="ml-1 grey-link"><i class="fa fa-pencil-alt" /> Edit</a>
                        </h6>
                        <h6
                          v-if="data.item.Role !== 'Super User'"
                          style="margin-left:-10px;padding:10px;"
                          @click="showDeleteUserModal(data.item.Id)"
                        >
                          <a class="ml-1 grey-link"><i class="fa fa-trash" /> Delete</a>
                        </h6>
                        <h6
                          v-if="data.item.Role === 'Super User' && userData.rle === 'Super User'"
                          style="padding-top:5px;padding-bottom:5px;"
                          @click="showConfirmToggleModal(data.item.Id)"
                        >
                          <a class="ml-1 grey-link"><i class="fa fa-exchange-alt" /> Toggle</a>
                        </h6>
                      </div>
                      <mdb-icon
                        slot="reference"
                        title="More actions"
                        style="color:#555;font-size:1rem;transform:translateY(2px)"
                        class="p-2 ellipsisButtonHover mr-2"
                        icon="ellipsis-v"
                      />
                    </mdb-popover>
                  </div>
                </template>
              </b-table>
            </div>

            <!--data table for mobile-->
            <div v-if="mobileView">
              <b-table
                id="users-table"
                class="text-left"
                head-variant="dark"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :items="users"
                :per-page="perPage"
                :current-page="currentPage"
                :fields="mobileFields"
                :filter="filter"
                hover
                small
                sort-icon-left
                responsive="sm"
              >
                <template #cell(user)="data">
                  <div
                    style="margin-top:12px;"
                    class="smFont"
                    title="View details?"
                    @click="showUserDetailsModal(data.item)"
                  >
                    <span v-show="data.item.Verified === false" class="grey-text">{{ data.item.User }}</span>
                    <span v-show="data.item.Verified" class="primary-colour">{{ data.item.User }}</span>
                  </div>
                </template>
                <template #cell(role)="data">
                  <div style="margin-top:12px;" class="smFont">
                    {{ data.item.Role }}
                  </div>
                </template>
                <template #cell(actions)="data">
                  <div class="float-right" style="cursor:pointer;">
                    <mdb-popover
                      trigger="click"
                      :options="{placement: 'bottom'}"
                      style="cursor:pointer;"
                      class="grey-text"
                    >
                      <div slot="body">
                        <h6
                          v-show="!data.item.IsSuspended"
                          v-if="data.item.Role !== 'Super User'"
                          @click="showResetUserModal(data.item.Id)"
                        >
                          <a class="ml-1 grey-link"><i class="fa fa-sync" /> Reset User?</a>
                        </h6>
                        <h6 v-show="data.item.IsSuspended" @click="showUnSupendUserModal(data.item.Id)">
                          <a class="ml-1 grey-link"><i class="fa fa-sync" /> Unsuspend User?</a>
                        </h6>
                        <h6 @click="addEditUser(data.item)">
                          <a class="ml-1 grey-link"><i class="fa fa-pencil-alt" /> Edit</a>
                        </h6>
                        <h6
                          v-if="data.item.Role !== 'Super User'"
                          style="padding-top:5px;padding-bottom:5px;"
                          @click="showDeleteUserModal(data.item.Id)"
                        >
                          <a class="ml-1 grey-link"><i class="fa fa-trash" /> Delete User?</a>
                        </h6>
                        <h6
                          v-if="data.item.Role === 'Super User' && userData.rle === 'Super User'"
                          style="padding-top:5px;padding-bottom:5px;"
                          @click="showConfirmToggleModal(data.item.Id)"
                        >
                          <a class="ml-1 grey-link"><i class="fa fa-exchange-alt" /> Toggle</a>
                        </h6>
                      </div>
                      <mdb-icon
                        slot="reference"
                        title="More actions"
                        style="margin: 0.5rem;color:#555;font-size:1rem;"
                        icon="ellipsis-v"
                        class="ellipsisButtonHover"
                      />
                    </mdb-popover>
                  </div>
                </template>
              </b-table>
            </div>

            <!--pagination dropdown-->
            <div v-if="mobileView === false" style="float:right;">
              <select
                v-if="notChangingPagination"
                v-model="page"
                class="selectStyle selectClass"
                @change="perPageStorage(page)"
              >
                <option value="10" :selected="userData.pge === 10">
                  10 per page
                </option>
                <option value="25" :selected="userData.pge === 25">
                  25 per page
                </option>
                <option value="50" :selected="userData.pge === 55">
                  50 per page
                </option>
                <option value="100" :selected="userData.pge === 100">
                  100 per page
                </option>
              </select>
              <div
                v-else
                class="selectStyleElse"
              >
                <i class="fa fa-spin fa-spinner" style="margin-top:10px;margin-left: -5px;" /> loading page
              </div>
            </div>
            <!--pagination dropdown-->
            <b-pagination
              v-show="users.length > 5"
              v-model="currentPage"
              class="float-right mt-4"
              :total-rows="rows"
              :per-page="perPage"
              first-number
              last-number
              aria-controls="users-table"
              prev-text="<"
              next-text=">"
            />           

            <div
              v-show="mobileView === false"
              md="2"
              offset-md="10"
            >
              <button
                style="float: left;margin-right: 0px;width: 110px;padding: 6px !important;text-align: center;margin-top:25px;"
                title="Add user"
                class="mdc-button btn"
                @click="addEditUser(null)"
              >
                <span class="secondary-colour"><i class="fa fa-plus-circle" /> Add</span>
              </button>
            </div>

            <div class="fixed bottom-0 mobDisplay" style="background: white;width: 100%;border-top: 1px solid rgb(161, 161, 161);z-index: 1001;margin-left: -30px;height: 55px;">
              <div class="row" style="margin-top:5px;">
                <div class="col-sm-12 text-center">
                  <button
                    style="width: 110px;padding: 6px !important;text-align: center;"
                    title="Add user"
                    class="mdc-button btn-solid-outline-mob btn"
                    @click="addEditUser(null)"
                  >
                    <span class="secondary-colour"><i class="fa fa-plus-circle" /> Add</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="mobileView === false" style="width:100%;height:30px;margin-bottom: 100px;" />
    </div>

    <!--add/edit user form-->
    <div
      v-show="addEditUserForm"
      style="text-align:left;padding:2px;overflow-y:auto;margin-bottom:100px;"
      :class="{ 'addTop': mobileView }"
    >
      <div class="h5 grey-text">
        <span v-show="edit">Edit User</span><span v-show="!edit">Add User</span>
      </div>
      <validation-observer v-slot="{ invalid, reset }">
        <form
          ref="form"
          @submit.prevent="saveAddEditUser()"
          @reset.prevent="reset"
        >
          <div
            class="flex-nowrap"
            style="cursor:pointer;margin-top:30px;margin-bottom:30px;"
            :class="{ 'ppPad': user.Id != null }"
            @click="editProfilePicture()"
          >
            <div style="height:95px;width:95px;border:2px #c0c0c0 solid; border-radius:50%;display:flex;align-items:center;justify-content:center;" class="pPic">
              <img
                style="height:85px;width:85px;border-radius:50%;"
                :src="user.ProfilePicture"
                :title="user.FirstName + ' ' + user.Surname"
              >
            </div>
          </div>
          <div class="md-form form-sm">
            <validation-provider
              v-slot="{ errors }"
              mode="lazy"
              name="First name"
              rules="required"
            >
              <i class="fas fa-user prefix" style="margin-top: 6px;font-size: 1.3rem;"><div style="color:red;margin-left:25px;margin-top:-30px;">*</div></i>
              <mdb-input
                v-model="user.FirstName"
                type="text"
                auto-complete="fname"
                label="First name"
                style="margin-left:45px;"
                size="sm"
              />
              <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                Please provide a first name
              </div>
            </validation-provider>
          </div>
          <div class="md-form form-sm">
            <validation-provider
              v-slot="{ errors }"
              mode="lazy"
              name="Last name"
              rules="required"
            >
              <i class="fas fa-user prefix" style="margin-top: 6px;font-size: 1.3rem;"><div style="color:red;margin-left:25px;margin-top:-30px;">*</div></i>
              <mdb-input
                v-model="user.Surname"
                type="text"
                auto-complete="sname"
                label="Surname"
                style="margin-left:45px;"
                size="sm"
              />
              <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                Please provide a surname
              </div>
            </validation-provider>
          </div>
          <div class="md-form form-sm">
            <validation-provider
              v-slot="{ errors }"
              mode="lazy"
              name="Email (username)"
              rules="required"
            >
              <i class="fas fa-envelope prefix" style="margin-top: 6px;font-size: 1.3rem;"><div style="color:red;margin-left:25px;margin-top:-30px;">*</div></i>
              <mdb-input
                v-model="user.UserName"
                type="email"
                auto-complete="sname"
                label="Email (username)"
                style="margin-left:45px;"
                size="sm"
              />
              <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                Please provide a valid email address
              </div>
            </validation-provider>
          </div>
          <div class="md-form form-sm">
            <i class="fas fa-user prefix" style="margin-top: 6px;font-size: 1.3rem;" />
            <mdb-input
              v-model="user.JobTitle"
              type="text"
              auto-complete="jname"
              label="Job title (optional)"
              style="margin-left:45px;"
              size="sm"
            />
          </div>
          <div class="md-form form-sm">
            <i class="fas fa-mobile prefix" style="margin-top: 6px;font-size: 1.3rem;" />
            <mdb-input
              v-model="user.PhoneNumber"
              type="text"
              auto-complete="pname"
              label="Phone number (optional)"
              style="margin-left:45px;"
              size="sm"
            />
          </div>
          <div class="md-form form-sm">
            <mdb-icon
              fab
              icon="linkedin"
              style="margin-top: 12px;font-size: 1.3rem;"
            />
            <mdb-input
              v-model="user.LinkedInUrl"
              type="text"
              auto-complete="iname"
              label="LinkedIn profile link (optional)"
              style="margin-top:-36px;margin-left:45px;"
              size="sm"
            />
          </div>
          <div class="md-form form-sm">
            <mdb-icon
              fab
              icon="twitter-square"
              style="margin-top: 12px;font-size: 1.3rem;"
            />
            <mdb-input
              v-model="user.TwitterUrl"
              type="text"
              auto-complete="iname"
              label="Twitter handle (optional)"
              style="margin-top:-36px;margin-left:45px;"
              size="sm"
            />
          </div>
          <div class="md-form form-sm">
            <mdb-icon
              fab
              icon="facebook-square"
              style="margin-top: 12px;font-size: 1.3rem;"
            />
            <mdb-input
              v-model="user.FacebookUrl"
              type="text"
              auto-complete="iname"
              label="Facebook link (optional)"
              style="margin-top:-36px;margin-left:45px;"
              size="sm"
            />
          </div>
          <div class="md-form form-sm">
            <mdb-icon icon="globe" style="margin-top: 12px;font-size: 1.3rem;" />
            <mdb-input
              v-model="user.CompanyWebUrl"
              type="text"
              auto-complete="iname"
              label="Company website (optional)"
              style="margin-top:-36px;margin-left:45px;"
              size="sm"
            />
          </div>
          <div class="md-form form-sm">
            <validation-provider
              v-slot="{ errors }"
              mode="lazy"
              name="Role"
              rules="required|user-role"
            >
              <i class="fas fa-users prefix" style="margin-top: 6px;font-size: 1.3rem;"><div style="color:red;margin-left:25px;margin-top:-30px;">*</div></i>
              <mdb-select
                v-model="userRoles"
                placeholder="Please choose a role"
                label="User role"
                style="margin-left:45px;"
                size="sm"
              />
              <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                Please choose a role
              </div>
            </validation-provider>
          </div>
          <div class="md-form form-sm">
            <i class="fas fa-house-user prefix primary-colour-ni" style="margin-top: 4px;font-size: 1.3rem;" />
            <mdb-select
              v-model="pages"
              placeholder="Select a page"
              label="Default landing page"
              style="margin-left:45px;"
              class="sm"
            />
          </div>
          <div
            class="md-form form-sm mr-auto d-flex align-items-center p-0"
            style="margin-top: 25px;margin-bottom: 40px;"
            @click="toggleScoringModerator()"
          >
            <mdb-input
              v-model="isScoreModerator"
              type="checkbox"
              class="p-0"
              label="Scoring Moderator"
              style="margin-left:10px;margin-top: -10px;"
              size="sm"
            />
          </div>
          <div class="md-form form-sm" style="margin-left: -15px;padding-left: 15px;color:red;">
            <div style="font-size:2rem;margin-bottom: -43px;">
              *
            </div><div style="margin-left:20px;font-size:14px;">
              required fields
            </div>
          </div>
          <mdb-btn
            type="reset"
            class="float-left btn-outline"
            size="sm"
            style="width:100px;"
            @click="cancelAddEditUserForm()"
          >
            CANCEL
          </mdb-btn>
          <mdb-btn
            v-show="!buttonClick"
            type="submit"
            :disabled="invalid || userRole.length < 1"
            class="float-right"
            size="sm"
            style="width:100px;"
          >
            <span v-show="edit">SAVE</span>
            <span v-show="!edit">ADD</span>
          </mdb-btn>
          <mdb-btn
            v-show="buttonClick"
            class="float-right"
            size="sm"
            style="width:100px;"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </form>
      </validation-observer>
    </div>

    <mdb-modal
      id="deleteModal"
      :show="deleteModal"
      @close="onDeleteModalHide"
    >
      <mdb-modal-header>
        <mdb-modal-title>DELETE?</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          Are you sure you want to delete this user?
        </p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          v-show="!buttonClick"
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="onDeleteConfirm"
        >
          YES
        </mdb-btn>
        <mdb-btn
          v-show="buttonClick"
          class="float-right"
          size="sm"
          style="width:100px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

    <mdb-modal
      id="alertModal"
      :show="alertModal"
      @close="alertModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>{{ alertModalTitle.toUpperCase() }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          {{ alertModalContent }}
        </p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="alertModal = false"
        >
          OK
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

    <mdb-modal
      id="confirmToggleModal"
      :show="confirmToggleModal"
      @close="confirmToggleModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>CONFIRM TOGGLE</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          Are you sure you want to toggle your account back to Shine Enterprise?
        </p>
      </mdb-modal-body>
      <mdb-modal-footer style="display:block;">
        <mdb-btn
          type="reset"
          class="float-left btn-outline"
          size="sm"
          style="width:100px;"
          @click="confirmToggleModal = false"
        >
          CANCEL
        </mdb-btn>
        <mdb-btn
          v-show="!buttonClick"
          class="float-right"
          size="sm"
          style="width:100px;"
          @click.native="backToShine()"
        >
          TOGGLE
        </mdb-btn>
        <mdb-btn
          v-show="buttonClick"
          class="float-right"
          size="sm"
          style="width:100px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

    <mdb-modal
      id="toggleModal"
      :show="toggleModal"
      @close="closeToggleModal()"
    >
      <mdb-modal-header>
        <mdb-modal-title>{{ toggleModalTitle.toUpperCase() }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          {{ toggleModalContent }}
        </p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="closeToggleModal()"
        >
          OK
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

    <mdb-modal
      id="resetConfirmModal"
      :show="resetModal"
      @close="resetModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>RESET?</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          Are you sure you want to reset this user?
        </p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          v-show="!buttonClick"
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="onResetConfirm"
        >
          YES
        </mdb-btn>
        <mdb-btn
          v-show="buttonClick"
          class="float-right"
          size="sm"
          style="width:100px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

    <mdb-modal
      id="unSuspendConfirmModal"
      :show="unSuspendModal"
      @close="unSuspendModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>UNSUSPEND?</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          User is currently locked out and suspended. Are you sure you want to reset their account?
        </p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          v-show="!buttonClick"
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="onUnSuspendConfirm"
        >
          YES
        </mdb-btn>
        <mdb-btn
          v-show="buttonClick"
          class="float-right"
          size="sm"
          style="width:100px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

    <mdb-modal
      id="errorModal"
      :show="errorModal"
      @close="errorModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>{{ errorModalTitle.toUpperCase() }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          <span v-html="error" />
        </p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="closeErrorModal"
        >
          CLOSE
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

    <mdb-modal
      id="detailsModal"
      :show="detailsModal"
      @close="detailsModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>{{ detailsModalTitle.toUpperCase() }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body style="text-align:left;" :class="{ 'modalHeight': mobileView }">
        <div class="my-4 grey-text">
          <div style="font-size:0.8rem;">
            <div class="row">
              <div class="col-md-3">
                <strong>Email:</strong>
              </div>
              <div class="col-md-4">
                {{ modalDetailsUserName }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <strong>Role:</strong>
              </div>
              <div class="col-md-4">
                {{ modalDetailsRole }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <strong>Account:</strong>
              </div>
              <div class="col-md-6">
                {{ modalDetailsAccountName }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <strong>Date Added:</strong>
              </div>
              <div class="col-md-6">
                {{ new Date(modalDetailsDateCreated).toLocaleDateString([userLanguage]) }}
              </div>
            </div>
          </div>
        </div>
      </mdb-modal-body>
    </mdb-modal>

    <mdb-modal
      id="showEditProfilePictureModal"
      :show="showEditProfilePictureModal"
      @close="showEditProfilePictureModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>EDIT PROFILE PICTURE</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          Upload a new profile picture for
          <span v-if="user.FirstName !== ''"> {{ user.FirstName }} {{ user.Surname }}?</span>
          <span v-else> new user?</span>
          Please upload an image with the same dimensions in height and width, keeping the overall file size under 1Mb.
        </p>
        <div class="md-form form-sm" @click="launchCsvPicker">
          <div style="float:right;margin-right:23px;">
            <i class="fas fa-upload prefix" style="margin-top: 6px;font-size: 1.3rem;cursor:pointer" />
          </div>
          <input
            type="text"
            class="form-control"
            :placeholder="csvText"
            :value="file.name"
            readonly
          >
          <input
            ref="file"
            v-uploader
            type="file"
            style="display:none"
            accept=".jpg, .jpeg, .png"
          >
        </div>
      </mdb-modal-body>
      <mdb-modal-footer style="display:block;">
        <mdb-btn
          type="reset"
          class="float-left btn-outline"
          size="sm"
          style="width:100px;"
          @click="showEditProfilePictureModal = false"
        >
          CANCEL
        </mdb-btn>
        <mdb-btn
          v-show="!buttonModalClick"
          :disabled="file === ''"
          class="float-right"
          size="sm"
          style="width:100px;"
          @click.native="uploadProfilePicture();buttonModalClick = true;"
        >
          UPLOAD
        </mdb-btn>
        <mdb-btn
          v-show="buttonModalClick"
          class="float-right"
          size="sm"
          style="width:100px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { extend } from 'vee-validate'
import { useMobile } from '@/composables/useMobile'
import { logout } from '@/methods/auth.js'
import { mapDefaultLanding } from '@/utils/defaultLanding.js'
import { userLanguage } from '@/common/userLanguage'
import userService from '@/api/services/user.service'
import logoutService from '@/api/services/logout.service'
import candidateService from '@/api/services/candidate.service'
import signUpService from '@/api/services/signup.service'

import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn } from 'mdbvue'
import ExpandableSearchBar from '@/components/UI/expandableSearch.vue'

extend('user-role', {
  validate: userRoles => { return userRoles.length > 0 },
  lazy: true,
  message: 'Please choose a user role'
})

export default {
  name: 'Users',
  directives: {
    uploader: {
      bind (el, binding, vnode) {
        el.addEventListener('change', e => {
          if (e.target.files[0] !== undefined) {
            vnode.context.file = e.target.files[0]
          }
        })
      }
    }
  },
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
    ExpandableSearchBar
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    return {
      authData,
      userData,
      mobileView
    }
  },
  data () {
    return {
      fields: [
        { key: 'User', label: '  User', tdClass: 'headerPaddingUsers col30 sortIcon', sortable: true },
        { key: 'Role', label: '  Role', tdClass: 'headerPaddingUsers col20 sortIcon', sortable: true },
        { key: 'DateCreated', label: '  Registered', tdClass: 'headerPaddingUsers col20 sortIcon', sortable: true },
        { key: 'Actions', label: '', tdClass: 'headerPaddingUsers col30', sortable: false }
      ],
      mobileFields: [
        { key: 'User', label: '  User', tdClass: 'col50 sortIcon', sortable: true },
        { key: 'Role', label: '  Role', tdClass: 'col25 sortIcon', sortable: true },
        { key: 'Actions', label: '', tdClass: 'col25', sortable: false }
      ],
      sortBy: '',
      sortDesc: false,
      resetModal: false,
      unSuspendModal: false,
      alertModal: false,
      deleteModal: false,
      detailsModal: false,
      errorModal: false,
      errorModalTitle: '',
      error: '',
      file: '',
      hasUserData: false,
      users: [],
      csvText: 'Select a new image to upload',
      usersLoaded: false,
      selectedUserId: null,
      alertModalTitle: '',
      alertModalContent: '',
      detailsModalTitle: '',
      modalDetailsUserName: '',
      modalDetailsRole: '',
      modalDetailsAccountName: '',
      modalDetailsSuperUser: 'No',
      modalDetailsDateCreated: '',
      toggleModal: false,
      toggleModalTitle: '',
      toggleModalContent: '',
      confirmToggleModal: false,
      perPage: this.userData.pge,
      buttonClick: false,
      buttonModalClick: false,
      currentPage: 1,
      errors: null,
      filter: null,
      filterOn: [],
      addEditUserForm: false,
      user: {
        ProfilePicture: 'https://go.shineinterview.com/img/candidate-silhouette.png'
      },
      edit: false,
      userLanguage,
      toggleId: '',
      userRoles: [],
      showEditProfilePictureModal: false,
      pages: [],
      isScoreModerator: false,
      notChangingPagination: true,
      page: this.userData.pge
    }
  },
  computed: {
    rows () {
      return this.users.length
    },
    sortOptions () {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
    userRole: function () {
      var c = ''
      this.userRoles.forEach(element => {
        if (element.selected === true) {
          c = element.value
        }
      })
      return c
    },
    selectedLandingPage () {
      return this.pages.filter(page => page.selected)[0].index
    },
    maxUsers: function () {
      return this.userData.nos
    },
  },
  watch: {
    file (val) {
      this.$emit('file-chosen', val)
    }
  },
  async created () {
    this.getUsers()
    this.getUserRoles(this.userData.rle)
    this.pages = await mapDefaultLanding(this.user.DefaultLanding)
    this.pages[0].selected = true
  },
  methods: {
    perPageStorage (e) {
      this.notChangingPagination = false
      candidateService.updatePerPage(e).then((response) => {
        if (response.data === true) {
          signUpService.getUserDetails(this.authData.access_token).then(async (res) => {
            this.userData = res.data
            this.perPage = e
            this.getUsers()
            this.notChangingPagination = true
          })
        } else {
          this.notChangingPagination = true
        }
      })
    },
    logout,
    toggleScoringModerator () {
      this.isScoreModerator = !this.isScoreModerator
    },
    getUserRoles (role) {
      if (this.userData.iss) {
        this.userRoles = [
          { text: 'Please choose a role', value: '', selected: true },
          { text: 'Inviter', value: 'Inviter' },
          { text: 'User', value: 'User' },
          { text: 'Manager', value: 'Manager' },
          { text: 'Admin', value: 'Admin' },
          { text: 'Account Group Administrator', value: 'Account Group Administrator' }
        ]
      } else if (this.userData.rle === 'Admin' || this.userData.rle === 'Account Group Administrator') {
        this.userRoles = [
          { text: 'Please choose a role', value: '', selected: true },
          { text: 'Inviter', value: 'Inviter' },
          { text: 'User', value: 'User' },
          { text: 'Manager', value: 'Manager' },
          { text: 'Admin', value: 'Admin' },
          { text: 'Account Group Administrator', value: 'Account Group Administrator' }
        ]
      } else {
        switch (role) {
          case 'User':
            this.userRoles = [
              { text: 'User', value: 'User', selected: true }
            ]
            break
          case 'Manager':
            this.userRoles = [
              { text: 'Please choose a role', value: '', selected: true },
              { text: 'User', value: 'User' },
              { text: 'Manager', value: 'Manager' }
            ]
            break
          case 'Admin':
            this.userRoles = [
              { text: 'Please choose a role', value: '', selected: true },
              { text: 'Inviter', value: 'Inviter' },
              { text: 'User', value: 'User' },
              { text: 'Manager', value: 'Manager' },
              { text: 'Admin', value: 'Admin' },
              { text: 'Account Group Administrator', value: 'Account Group Administrator' }
            ]
            break
          case 'AccountGroupAdministrator':
            this.userRoles = [
              { text: 'Please choose a role', value: '', selected: true },
              { text: 'Inviter', value: 'Inviter' },
              { text: 'User', value: 'User' },
              { text: 'Manager', value: 'Manager' },
              { text: 'Admin', value: 'Admin' },
              { text: 'Account Group Administrator', value: 'Account Group Administrator' }
            ]
            break
          case 'Super User':
            this.userRoles = [
              { text: 'Please choose a role', value: '', selected: true },
              { text: 'Inviter', value: 'Inviter' },
              { text: 'User', value: 'User' },
              { text: 'Manager', value: 'Manager' },
              { text: 'Admin', value: 'Admin' },
              { text: 'Account Group Administrator', value: 'Account Group Administrator' }
            ]
            break
        }
      }
      if (this.edit) {
        this.userRoles = this.userRoles.map(r => r.value === role ? { ...r, selected: true } : { ...r, selected: false })
      } else { this.userRoles = this.userRoles.map(r => { return { ...r, selected: false } }) }
    },
    uploadProfilePicture () {
      this.buttonModalClick = true
      var formData = new FormData()
      formData.append('userName', this.userData.une)
      formData.append('userId', this.user.Id)
      formData.append('file', this.file)
      try {
        userService.profilePicture(formData).then((response) => {
          this.user.ProfilePicture = response.data
          this.file = ''
          this.showEditProfilePictureModal = false
          this.$refs.file.value = null
          if (this.userData.une === this.user.UserName) {
            this.userData.pic = this.user.ProfilePicture
          }
          this.buttonModalClick = false
        }).catch((error) => {
          this.file = ''
          this.showEditProfilePictureModal = false
          this.$refs.file.value = null
          this.errorModalTitle = 'Error!'
          this.errorAlert(error, 'Sorry an error has occurred! Please ensure uploaded images are less than 1Mb in size.')
          this.showEditProfilePictureModal = false
          this.$refs.file.value = null
          this.buttonModalClick = false
        })
      } catch (error) {
        this.file = ''
        this.showEditProfilePictureModal = false
        this.$refs.file.value = null
        this.$emit('errorAlert', { error, msg: 'Sorry an error has occurred!' })
      }
    },
    editProfilePicture () {
      this.showEditProfilePictureModal = true
    },
    launchCsvPicker () {
      this.$refs.file.click()
    },
    newUser () {
      this.cancelAddEditUserForm()
      this.edit = false
      this.reset()
      this.user = {
        Id: 0,
        ProfilePicture: 'https://go.shineinterview.com/img/candidate-silhouette.png',
        TwitterUrl: '@',
        FacebookUrl: 'https://www.facebook.com/'
      }
      this.addEditUserForm = true
    },
    showConfirmToggleModal (id) {
      this.toggleId = id
      this.confirmToggleModal = true
    },
    backToShine () {
      this.buttonClick = true
      userService.backToShine(this.toggleId).then(() => {
        this.alertModal = true
        this.buttonClick = false
        this.confirmToggleModal = false
        this.alertModal = true
        this.alertModalTitle = 'Toggled'
        this.alertModalContent = 'Account moved back to Shine Enterprise. As such you will now be logged out of Shine Go.'
        setTimeout(() => { this.logOut() }, 5000)
      }).catch((error) => {
        this.buttonClick = false
        this.user = {
          Id: 0,
          ProfilePicture: 'https://go.shineinterview.com/img/candidate-silhouette.png'
        }
        this.toggleId = ''
        this.getUsers()
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
    },
    closeToggleModal () {
      this.logOut()
    },
    viewActionsClick () {
      this.$emit('details', this.user)
    },
    async addEditUser (user) {
      this.cancelAddEditUserForm()
      this.reset()
      this.user = {
        Id: 0,
        ProfilePicture: 'https://go.shineinterview.com/img/candidate-silhouette.png'
      }
      //if (this.maxUsers <= this.users.length && !user) {
        //this.errorModalTitle = 'Maximum Users Reached'
        //this.errorAlert({}, 'You have reached maximum amount of users allowed for you plan. To add further users please contact your account manager.')
        //return
      //}
      if (user !== null) {
        this.user = user
        this.isScoreModerator = user.IsScoreModerator
        this.edit = true
        this.getUserRoles(this.user.Role)
      } else {
        this.edit = false
        this.user = {
          ProfilePicture: 'https://go.shineinterview.com/img/candidate-silhouette.png',
          TwitterUrl: '@',
          FacebookUrl: 'https://www.facebook.com/'
        }
      }
      this.addEditUserForm = true
    },
    saveAddEditUser () {
      this.buttonClick = true
      if (this.edit === false) {
        this.user.Id = ''
      }
      this.user.Role = this.userRole
      var formData = new FormData()
      formData.append('Id', this.user.Id)
      formData.append('FirstName', this.user.FirstName)
      formData.append('Surname', this.user.Surname)
      formData.append('UserName', this.user.UserName)
      formData.append('PhoneNumber', this.user.PhoneNumber)
      formData.append('Role', this.user.Role)
      formData.append('ProfilePicture', this.user.ProfilePicture)
      formData.append('JobTitle', this.user.JobTitle)
      formData.append('LinkedInUrl', this.user.LinkedInUrl)
      formData.append('TwitterUrl', this.user.Twitter)
      formData.append('FacebookUrl', this.user.FacebookUrl)
      formData.append('ComnpanyWebUrl', this.user.CompanyWebUrl)
      formData.append('DefaultLanding', this.selectedLandingPage)
      formData.append('IsScoreModerator', this.isScoreModerator)

      userService.saveAddEditUser(formData).then((response) => {       
        if (response === undefined || response === null) {
          this.buttonClick = false
          this.errorModalTitle = 'Error!'
          this.errorAlert('Undefined', 'Sorry an error has occurred! Please check user is not already registered on Shine Go or Shine!')
        } else {
          this.getUsers()
          if (this.userData.une === this.user.UserName) {
            this.userData.fne = this.user.FirstName
            this.userData.sne = this.user.Surname
            this.userData.pic = this.user.ProfilePicture
            this.userData.une = this.user.UserName
            this.userData.jtl = this.user.JobTitle
            this.userData.phn = this.user.PhoneNumber
            this.userData.twt = this.user.TwitterUrl
            this.userData.lnk = this.user.LinkedInUrl
            this.userData.fbk = this.user.FacebookUrl
            this.userData.www = this.user.CompanyWebUrl
            this.userData.rl = this.user.Role
            this.userData.dlp = this.selectedLandingPage
            this.userData.scm = this.isScoreModerator
          }
          this.reset()
          this.buttonClick = false
        }
      }).catch((error) => {
        this.buttonClick = false
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred! Please check user is not already registered on Shine Go or Shine!')
      })
    },
    cancelAddEditUserForm () {
      this.pages[0].selected = true
      this.user = {
        Id: 0,
        ProfilePicture: 'https://go.shineinterview.com/img/candidate-silhouette.png'
      }
      this.getUserRoles(this.userData.rle)
      this.addEditUserForm = false
      this.edit = false
    },
    reset () {
      this.isScoreModerator = false
      this.edit = false
      this.$refs.form.reset()
    },
    showUserDetailsModal (user) {
      this.detailsModalTitle = user.FirstName + ' ' + user.Surname
      this.modalDetailsUserName = user.UserName
      this.modalDetailsRole = user.Role
      this.modalDetailsAccountName = user.AccountName
      this.modalDetailsDateCreated = user.DateCreated
      if (user.IsSuperUser) {
        this.modalDetailsSuperUser = 'Yes'
      }
      this.detailsModal = true
    },
    showDeleteUserModal (userId) {
      this.selectedUserId = userId
      this.deleteModal = true
    },
    onDeleteModalHide () {
      this.selectedUserId = null
      this.deleteModal = false
    },
    showResetUserModal (userId) {
      this.selectedUserId = userId
      this.resetModal = true
    },
    showUnSupendUserModal (userId) {
      this.selectedUserId = userId
      this.unSuspendModal = true
    },
    onResetModalHide () {
      this.selectedUserId = null
      this.resetModal = false
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getUsers () {
      userService.getUsers().then((response) => {
        this.addEditUserForm = false
        this.users = response.data
        setTimeout(() => { this.usersLoaded = true }, 3000)
        this.buttonClick = false
      }).catch((error) => {
        this.addEditUserForm = false
        setTimeout(() => { this.usersLoaded = true }, 3000)
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
        this.buttonClick = false
      })
    },
    onDeleteConfirm () {
      this.buttonClick = true
      userService.deleteUser(this.selectedUserId).then(() => {
        this.deleteModal = false
        this.userData.nos = ++this.userData.nos
        this.getUsers()
        this.buttonClick = false
      }).catch((error) => {
        this.deleteModal = false
        setTimeout(() => { this.usersLoaded = true }, 3000)
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
        this.buttonClick = false
        this.getUsers()
      })
    },
    onResetConfirm () {
      this.buttonClick = true
      userService.resetUser(this.selectedUserId).then(() => {
        this.resetModal = false
        this.getUsers()
        this.buttonClick = false
      }).catch((error) => {
        this.resetModal = false
        setTimeout(() => { this.usersLoaded = true }, 3000)
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
        this.buttonClick = false
      })
    },
    onUnSuspendConfirm () {
      this.buttonClick = true
      userService.unSuspendUser(this.selectedUserId).then(() => {
        this.unSuspendModal = false
        this.getUsers()
        this.buttonClick = false
      }).catch((error) => {
        this.unSuspendModal = false
        setTimeout(() => { this.usersLoaded = true }, 3000)
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
        this.buttonClick = false
      })
    },
    errorAlert (error, msg) {
      try {
        if (msg) {
          this.errorModal = true
          this.error = msg
          return
        }
        if (error.response.data != null) {
          if (error.response.data.Message != null) {
            this.errorModal = true
            this.error = error.response.data.Message
            if (this.error === '') { this.error = 'Sorry, an error has occured!' }
          } else if (error.response.data.error_description != null) {
            this.errorModal = true
            this.error = error.response.data.error_description
            if (this.error === '') { this.error = 'Sorry, an error has occured!' }
          } else {
            this.errorModal = true
            this.error = error.response.data
            if (this.error === '') { this.error = 'Sorry, an error has occured!' }
          }
        } else {
          this.errorModal = true
          this.error = 'Sorry an error has occurred!'
        }
        if (error.response.data.Message === 'Authorization has been denied for this request.') {
          this.logOut()
        }
      } catch {
        this.errorModal = true
        this.error = 'Sorry an error has occurred!'
      }
    },
    closeErrorModal () {
      this.errorModal = false
    },
    doLogOut () {
      logoutService.logout().then(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      }).catch(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      })
    }
  }
}
</script>

<style>
  .page-link {
    border: 1px solid #dee2e6 !important;
  }
  table.table thead th {
    outline: none;
    cursor: pointer;
  }
  .form-control:focus {
    border-color: #d0d0d0;
    box-shadow: none;
  }
  .ppPad {
    margin-bottom:40px !important;
  }
  .vMobTop {
    margin-top:30px !important;
  }
</style>

<style lang="scss" scoped>
  .noUsersMob {
    margin-top: 75px;
  }
  .usersLoadingMargins {
    margin-top: 40px !important;
    margin-left: 2px !important;
    margin-bottom: -13px;
  }
  .popover[x-placement^=left] {
     width: 150px !important;
  }
  @media (max-width: 576px) {
    .usersTop {
      margin-top: -25px;
    }
  }
  .addTop {
    margin-top: 60px !important;
  }
</style>
