<template>
  <!--modal boilerplate-->
  <mdb-modal
    id="addEditSpotlightModalShowing"
    :show="addEditSpotlightModalShowing"
    size="lg"
    style="overflow-y:auto;"
    @close="closeAddEditModal()"
  >
    <mdb-modal-header>
      <mdb-modal-title class="font-weight-bold p-2 ml-3">
        {{ spotlightId === 0 ? 'ADD NEW' : 'EDIT' }} SPOTLIGHT
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body style="overflow-y:auto">
      <div class="px-2 px-md-3" style="width:100%;">
        <div class="d-flex align-items-center" style="height:60px">
          <i class="mr-3 fas fa-user text-left" style="font-size: 1.3rem;" />
          <mdb-input
            v-model="spotlightName"
            type="text"
            class="addingSpotlightForm"
            auto-complete="fna"
            label="Spotlight Name"
            size="sm"
          />
        </div>
        <div
          v-if="!editingSpotlight"
          class="d-flex align-items-center"
          style="height:60px"
        >
          <i class="mr-3 fas fa-user" style="font-size: 1.3rem;" />
          <mdb-select
            v-model="clientOptions"
            placeholder="Client"
            :disabled="clientSourceDisabled"
            size="sm"
            style="width:100%;"
            class="m-0 mx-3 addingSpotlightForm"
          />
        </div>
        <div
          v-if="zeroClientsError"
          style="font-size: 0.7rem;"
          class="my-3"
        >
          You must add clients in the Client Portal area before continuing.
        </div>
        <div
          v-if="!editingSpotlight"
          class="d-flex align-items-center"
          style="height:60px"
        >
          <i class="mr-3 fas fa-envelope" style="font-size: 1.3rem;" />
          <mdb-select
            v-model="candidateSource"
            :disabled="candidateSourceDisabled"
            placeholder="Candidate Source"
            size="sm"
            style="width:100%;"
            class="m-0 mx-3 addingSpotlightForm"
          />
        </div>
        <div
          v-if="!hideRoleOptions && !editingSpotlight"
          class="d-flex align-items-center"
          style="height:60px"
        >
          <i class="mr-3 fas fa-mobile" style="font-size: 1.3rem;" />
          <mdb-select
            v-model="roleOptions"
            :disabled="roleSourceDisabled"
            placeholder="Role"
            size="sm"
            style="width:100%;"
            class="m-0 mx-3 addingSpotlightForm"
          />
        </div>
        <div
          class="md-form form-sm mr-auto d-flex align-items-center m-0"
          style="height:60px;"
          @click="toggleEvergreen()"
        >
          <mdb-input
            v-model="isEvergreen"
            type="checkbox"
            label="Make this Spotlight Evergreen?"
            class="p-0"
            style="margin-top: -10px;"
            size="sm"
          />
        </div>
        <div
          v-if="!isEvergreen"
          class="md-form form-sm m-0 mt-4 ml-5"
          style="height:60px;"
        >
          <validation-provider
            v-slot="{ errors }"
            mode="lazy"
            name="Closing Date"
            rules="required"
          >
            <div style="font-size: 0.7rem;color:#888;transform:translateY(-25px);" class="prefix">
              Closing date
            </div>
            <i class="far fa-clock prefix" style="margin-top: 6px;font-size: 1.3rem;" />
            <input
              v-model="expiryDate"
              type="date"
              :min="minimumDate"
              onkeydown="return false"
              auto-complete="opd"
              label="Closing date"
              style="margin-left:33px;font-size: 0.9rem;color:#888;"
              size="sm"
            >
            <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:10px;margin-bottom:0px;">
              Please provide a closing date
            </div>
          </validation-provider>
        </div>
        <!-- <div class="md-form form-sm mr-auto d-flex align-items-center m-0" style="height:50px;" @click="toggleRemindersEnabled()">
            <mdb-input v-model="remindersEnabled" type="checkbox" label="Reminders Enabled" class="p-0" style="margin-top: -10px;" size="sm" />
          </div> -->
        <div class="d-flex align-items-center" style="height:60px">
          <FontAwesomeIcon
            icon="fa-regular fa-face-hand-peeking"
            class="mr-3 mt-2"
            style="font-size: 1.4rem;"
          />
          <mdb-select
            v-model="maskingCandidateOptions"
            placeholder="Masking Options"
            size="sm"
            style="width:100%;"
            class="m-0 mx-3 mr-4 addingSpotlightForm"
          />
          <Tooltip
            v-if="selectedCandidateSource !== 'VideoCV' && selectedCandidateSource != ''"
            class="ml-3 mt-3"
            tooltip-direction="left"
            :bullet-point-array="maskingTooltipArray"
          />
        </div>
        <div
          class="md-form form-sm mr-auto d-flex align-items-center m-0"
          style="height:50px;"
          @click="toggleWorkflowEnabled()"
        >
          <mdb-input
            v-model="workflowEnabled"
            type="checkbox"
            label="Workflow Enabled"
            class="p-0"
            style="margin-top: -10px;"
            size="sm"
          />
        </div>
      </div>
    </mdb-modal-body>
    <mdb-modal-footer>
      <div class="d-flex align-items-center justify-content-between" style="width:100%">
        <mdb-btn
          v-if="!loading"
          class="btn fl ml-auto text-nowrap m-0"
          size="sm"
          :disabled="!formValid"
          @click="addEditSpotlight()"
        >
          SUBMIT
        </mdb-btn>
        <mdb-btn
          v-if="loading"
          class="btn fl ml-auto"
          size="sm"
          style="width:110px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </div>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import { useStatus, useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
// import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'
import clientPortalService from '@/api/services/clientPortal.service'
import roleService from '@/api/services/role.service'
import Tooltip from '@/components/misc/tooltip.vue'

export default {
  name: 'AddEditSpotlightModal',
  components: {
    Tooltip
  },
  props: {
    addEditSpotlightModalShowing: Boolean,
    spotlightId: {
      default: 0,
      type: Number
    },
    clientId: {
      default: 0,
      type: Number
    },
    // so this modal can be added anywhere in the site - used to pick candidate source - value of OneWay, TwoWay, VideoCV, MultipleRoles
    context: {
      default: '',
      type: String
    },
    roleId: {
      default: 0,
      type: Number
    }
  },

  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()

    return {
      showGenericErrorModal,
      authData
    }
  },

  data () {
    return {
      minimumDate: '',
      spotlightName: '',
      loading: false,
      loadingRoles: false,
      clientOptions: [],
      candidateSource: [{ text: 'One-Way', value: 'OneWay' }, { text: 'Live Interview', value: 'TwoWay' }, { text: 'Video CV', value: 'VideoCV' }, { text: 'Multiple Roles', value: 'MultipleRoles' }],
      roleOptions: [],
      isEvergreen: false,
      expiryDate: null,
      remindersEnabled: false,
      maskingEnabled: false,
      workflowEnabled: true,
      spotlightSpecificStatuses: false,
      candidateSourceDisabled: false,
      clientSourceDisabled: false,
      roleSourceDisabled: false,
      hideRoleOptions: true,
      allRoleResults: [],
      zeroClientsError: false,
      editingSpotlight: false,
      maskingCandidateOptions: [
        { text: 'Full Audio & Video Interviews', value: 0, selected: true },
        { text: 'Audio Only (some masking)', value: 3 },
        { text: 'Audio Only (strict masking)', value: 2 }
      ]
    }
  },
  computed: {
    formValid () {
      let invalidCount = 0
      if (!this.editingSpotlight) {
        if (this.zeroClientsError) { invalidCount += 1 }
        if (this.selectedClientId === 0) { invalidCount += 1 }
        if (this.selectedCandidateSource === '') { invalidCount += 1 }
        if ((this.selectedCandidateSource === 'OneWay' || this.selectedCandidateSource === 'TwoWay') && this.selectedRoleId === 0) { invalidCount += 1 }
      }
      if (this.spotlightName === '') { invalidCount += 1 }
      if (!this.isEvergreen && this.expiryDate == null) { invalidCount += 1 }
      return invalidCount === 0
    },
    maskingTooltipArray () {
      if (this.selectedCandidateSource === 'MultipleRoles') {
        return [
          'Each candidate will be added to a Spotlight from a particular role - this setting will be overridden on a per candidate basis, if that roles masking settings are more strict.'
        ]
      } else {
        return ['Any stricter masking settings on the Role will take priority over this setting.']
      }
    },
    selectedCandidateSource () {
      try {
        return this.candidateSource.filter(s => s.selected)[0].value
      } catch { return '' }
    },
    selectedRoleId () {
      try {
        return this.roleOptions.filter(s => s.selected)[0].value
      } catch { return 0 }
    },
    selectedClientId () {
      try {
        return this.clientOptions.filter(s => s.selected)[0].value
      } catch { return 0 }
    },
    spotlightSpecificStatusesTooltip () {
      return `<span class="font-weight-bold">UNCHECKED</span> A Candidates status in this Spotlight's workflow, is directly tied to the Candidates status in the Role workflow. <br/><br/>
        Changes made in a spotlight with this setting, will affect the Candidates application status in other spotlights with the same setting, as well as their Role workflow status. <br/><br/>
        <span class="font-weight-bold">CHECKED</span> Creates a status per Candidate application that is specific to this Spotlight. The Candidates current Role status will not be affected by status changes made in the Spotlight. <br/><br/>
        A Candidates application shared across multiple Spotlights, will have an independent status per Spotlight.`
    }
  },
  watch: {
    // here will either populate modal from existing spotlight to edit -- or will consider context and populate modal
    addEditSpotlightModalShowing () {
      if (this.addEditSpotlightModalShowing === true) {
        if (this.spotlightId === 0) {
          this.initializeNewSpotlightModal()
        } else {
          this.initializeEditingSpotlightModal()
        }
      }
    },
    // if a candidate source is selected we can choose whether to show a role option or not
    selectedCandidateSource () {
      if (!this.editingSpotlight) {
        if (this.selectedCandidateSource !== '') {
          this.processRoleSource()
        }
      }
    },
    selectedRoleId () {
      // set masking default off role only if we are adding the spotlight - not editing
      if (this.selectedRoleId !== 0 && this.spotlightId === 0) {
        this.maskingEnabled = this.allRoleResults.filter(r => r.JobId === this.selectedRoleId)[0].MaskCandidateName === 1
      }
    }
  },
  methods: {
    toggleEvergreen () {
      this.isEvergreen = !this.isEvergreen
      this.$nextTick(() => {
        if (this.isEvergreen) { this.expiryDate = null }
      })
    },
    toggleWorkflowEnabled () { this.workflowEnabled = !this.workflowEnabled },
    toggleMaskingEnabled () { this.maskingEnabled = !this.maskingEnabled },
    toggleRemindersEnabled () { this.remindersEnabled = !this.remindersEnabled },
    setMinimumDate (date) {
      var month = date.getMonth() + 1
      var day = date.getDate()
      var year = date.getFullYear()
      if (month < 10) {
        month = '0' + month.toString()
      }
      if (day < 10) {
        day = '0' + day.toString()
      }
      this.minimumDate = year + '-' + month + '-' + day
    },
    async closeAddEditModal () {
      this.resetModal()
      this.$emit('closeAddEditModal')
    },
    resetModal () {
      this.$emit('setSpotlightId', 0)
      this.minimumDate = ''
      this.spotlightName = ''
      this.loading = false
      this.loadingRoles = false
      this.clientOptions = []
      this.candidateSource = [{ text: 'One-Way', value: 'OneWay' }, { text: 'Two-Way', value: 'TwoWay' }, { text: 'Video CV', value: 'VideoCV' }, { text: 'Multiple Roles', value: 'MultipleRoles' }]
      this.roleOptions = []
      this.isEvergreen = false
      this.expiryDate = null
      this.remindersEnabled = false
      this.maskingEnabled = false
      this.workflowEnabled = true
      this.candidateSourceDisabled = false
      this.clientSourceDisabled = false
      this.roleSourceDisabled = false
      this.spotlightSpecificStatuses = false
      this.hideRoleOptions = true
      this.allRoleResults = []
      this.zeroClientsError = false
      this.editingSpotlight = false
    },
    async addEditSpotlight () {
      this.loading = true
      try {
        var formData = new FormData()
        formData.append('SpotlightId', this.spotlightId)
        formData.append('RoleId', this.selectedRoleId)
        formData.append('CandidateSource', this.selectedCandidateSource)
        formData.append('ClientId', this.selectedClientId)
        formData.append('ExpiryDate', this.expiryDate)
        formData.append('SpotlightName', this.spotlightName)
        formData.append('IsEvergreen', this.isEvergreen)
        formData.append('RemindersEnabled', this.remindersEnabled)
        formData.append('WorkflowEnabled', this.workflowEnabled)
        formData.append('SpotlightSpecificStatuses', this.spotlightSpecificStatuses)
        formData.append('AudioOnly', this.maskingCandidateOptions.filter(o => o.selected)[0].value)
        const res = await clientPortalService.addEditSpotlight(formData)
        this.$emit('addSpotlightToRecentSpotlights', res.data)
        this.isEvergreen ? this.$emit('setFilter', 'EVERGREEN') : this.$emit('setFilter', 'OPEN')
        this.addSpotlightToRecentSpotlights(res.data)
        this.closeAddEditModal()
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.loading = false
    },
    async getClientPortalSpotlight () {
      this.loading = true
      try {
        const res = await clientPortalService.getClientPortalSpotlight(this.spotlightId, this.authData.access_token)
        return res.data
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.loading = false
    },
    async initializeEditingSpotlightModal () {
      this.editingSpotlight = true
      this.setMinimumDate(new Date())
      try {
        const res = await clientPortalService.getSpotlight(this.spotlightId)
        this.candidateSource = this.candidateSource.map(c => c.value === res.data.CandidateSource ? { ...c, selected: true } : { ...c, selected: false })
        this.spotlightName = res.data.Name
        this.isEvergreen = res.data.IsEvergreen
        this.remindersEnabled = res.data.RemindersEnabled
        this.workflowEnabled = res.data.WorkflowEnabled
        this.maskingCandidateOptions = this.maskingCandidateOptions.map(o => o.value === res.data.AudioOnly ? { ...o, selected: true } : { ...o, selected: false })
        if (res.data.ExpiryDate) { this.expiryDate = res.data.ExpiryDate.substring(0, 10) }
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
    },
    async initializeNewSpotlightModal () {
      this.setMinimumDate(new Date())
      this.processClientSource()
      this.processCandidateSource()
    },
    processCandidateSource () {
      if (this.context !== '') {
        this.candidateSource = this.candidateSource.map(c => c.value === this.context ? { ...c, selected: true } : { ...c, selected: false })
        this.candidateSourceDisabled = true
      }
    },
    async processRoleSource () {
      if (this.selectedCandidateSource === 'VideoCV' || this.selectedCandidateSource === 'MultipleRoles') {
        this.hideRoleOptions = true
        this.roleOptions = []
      } else {
        this.hideRoleOptions = false
        this.loadingRoles = true
        try {
          var payload = {
            ViewAll: false,
            Recent: false,
            Filter: { Value: true, Name: 'NonArchived' }
          }
          // NonArchived Filter
          const res = await roleService.getRoles(payload)
          this.allRoleResults = res.data
          let allRoles
          if (this.selectedCandidateSource === 'OneWay') {
            allRoles = res.data.filter(j => j.WorkflowType === 1)
          } else {
            allRoles = res.data.filter(j => j.WorkflowType === 7)
          }
          let roleArray = []
          allRoles.map(r => {
            const choice = { text: r.JobDetails.JobTitle, value: r.JobId }
            roleArray.push(choice)
          })
          roleArray = roleArray.map(c => c.value === this.roleId ? { ...c, selected: true } : { ...c, selected: false })
          this.roleOptions = roleArray
          if (this.roleId !== 0) { this.roleSourceDisabled = true }
        } catch (err) {
          this.showGenericErrorModal({ showing: true })
        }
        this.loadingRoles = false
      }
    },
    // gets all clients and makes a select array from the options - will select a client and disable option if in clientId is present
    async processClientSource () {
      try {
        const res = await clientPortalService.getAllClients()
        const allClients = res.data
        if (res.data.length === 0) { this.zeroClientsError = true }
        let clientArray = []
        allClients.map(c => {
          const choice = { text: c.Name, value: c.GoClientPortalClientId }
          clientArray.push(choice)
        })
        clientArray = clientArray.map(c => c.value === this.clientId ? { ...c, selected: true } : { ...c, selected: false })
        this.clientOptions = clientArray
        if (this.clientId !== 0) { this.clientSourceDisabled = true }
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
    },
    async addSpotlightToRecentSpotlights (spotlightId) {
      try {
        await clientPortalService.addSpotlightToRecentSpotlights(spotlightId)
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.addingSpotlightForm {
  margin: 10px 0px 4px 0px !important;
  width:100%
}
</style>
