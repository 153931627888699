<template>
  <div class="px-3 px-md-5" style="text-align:left;padding:2px;margin-top:50px;">
    <div class="h5 grey-text">
      Configure this Live Interview Stage
    </div>
    <div class="md-form form-sm" style="margin-top:40px;">
      <validation-provider
        v-slot="{ errors }"
        mode="lazy"
        name="Meeting Room Name"
        rules="required"
      >
        <i class="fa fa-signature prefix" style="margin-top: 6px;font-size: 1.3rem;" />
        <mdb-input
          v-model="localSetup.MeetingName"
          type="text"
          auto-complete="jbt"
          label="Meeting Room Name"
          style="margin-left:33px;"
          size="sm"
        />
        <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
          Please provide a meeting room name
        </div>
      </validation-provider>
    </div>
    <div class="md-form form-sm" style="margin-top: 40px">
      <i class="far fa-question-circle prefix" style="margin-top: 6px;font-size: 1.3rem;" />
      <label style="margin-top:-35px;font-size:12px;">Meeting descriptor</label>
      <mdb-select
        v-model="meetingDescriptor"
        placeholder="Select descriptor"
        style="margin-left:33px;"
        size="sm"
      />
    </div>
    <div
      class="md-form form-sm"
      style="margin-top: 15px;margin-bottom: 40px;"
      @click="rateRoom = !rateRoom"
    >
      <i class="fas fa-border-none prefix" style="margin-top: 9px;font-size: 1.3rem;" />
      <mdb-input
        v-model="rateRoom"
        type="checkbox"
        label="Rate Room"
        style="margin-left:16px;"
        size="sm"
      />
    </div>
    <div class="d-flex align-items-center justify-content-between">
      <mdb-btn
        class="btn btn-outline"
        size="sm"
        style="width:110px;"
        @click="$router.push(`/roles/add_edit/detail/${role.JobId}`)"
      >
        CANCEL
      </mdb-btn>
      <mdb-btn
        v-show="!buttonClick"
        :disabled="localSetup.MeetingName === '' && localSetup.MeetingDescriptor === '' && selectedDescriptor === null"
        size="sm"
        style="width:100px;"
        @click="updateLocalSetupAndNext()"
      >
        {{ selectedWorkflowStage.IsSelfSchedule ? 'NEXT' : 'FINISH' }}
      </mdb-btn>
      <mdb-btn
        v-show="buttonClick"
        class=""
        size="sm"
        style="width:100px;cursor:default;"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import roleV2Service from '@/api/services/roleV2.service'
import { useUserData, useStatus, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'LiveInterviewConfiguration',
  components: {
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const workflowStore = useWorkflow()
    const { selectedWorkflowStage, selectedWorkflowStageId, role } = storeToRefs(workflowStore)

    const { showGenericErrorModal } = useStatus()

    return {
      authData,
      showGenericErrorModal,
      selectedWorkflowStage,
      selectedWorkflowStageId,
      role
    }
  },
  data () {
    return {
      localSetup: {},
      meetingDescriptor: [
        { text: 'Online Meeting Room', value: 'Online Meeting Room' },
        { text: 'Video Interview', value: 'Video Interview' },
        { text: 'Online Assessment Centre', value: 'Online Assessment Centre' },
        { text: 'Video Conference Call', value: 'Video Conference Call' },
        { text: 'Boardroom', value: 'Boardroom' }
      ],
      rateRoom: false,
      buttonClick: false
    }
  },
  computed: {
    selectedDescriptor () {
      try {
        return this.meetingDescriptor.filter(m => m.selected)[0].value
      } catch (err) {
        return null
      }
    }
  },
  watch: {
    role: {
      handler () {
        this.localSetup = { ...this.selectedWorkflowStage.TwoWaySetup, Step: 1 }
        this.initDataFromStageSetup()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    initDataFromStageSetup () {
      this.rateRoom = this.localSetup.RateRoom
      this.meetingDescriptor = this.meetingDescriptor.map(m => m.value === this.localSetup.MeetingDescriptor ? { ...m, selected: true } : { ...m, selected: false })
    },
    async updateLocalSetupAndNext () {
      this.buttonClick = true
      this.localSetup.MeetingDescriptor = this.selectedDescriptor
      this.localSetup.RateRoom = this.rateRoom
      this.localSetup.RoleWorkflowStageId = this.selectedWorkflowStageId
      try {
        const res = await roleV2Service.editRoleWorkflowTwoWayStage(this.localSetup)
        this.selectedWorkflowStage = { ...this.selectedWorkflowStage, TwoWaySetup: res.data }
        if (this.selectedWorkflowStage.IsSelfSchedule) {
          this.$router.push(`/roles/add_edit/scheduler_setup/${this.$route.params.jobId}/${this.selectedWorkflowStage.RoleWorkflowStageId}/slots`)
        } else {
          this.$router.push(`/roles/add_edit/detail/${this.$route.params.jobId}`).catch(() => { })
        }
      } catch (err) {
        console.log(Object.assign({}, err))
        this.showGenericErrorModal()
      }
      this.buttonClick = false
    }
  }
}
</script>
