<template>
  <div
    class="leftColumnBoilerPlate d-none d-md-block"
    :class="{'leftColumnBoilerPlate1' : currentPage === '/settings/email_customisation'}"
  >
    <div
      style="width:100%;height:100%;"
      class="d-flex flex-col"
      :class="{'tabletSettingsWidth' : currentPage === '/settings/email_customisation'}"
    >
      <b-row
        no-gutters
        class="p-2 py-3"
        :class="{'face' : currentPage === '/settings/email_customisation'}"
      >
        <b-col
          v-show="userData.pic === $parent.silhouetteUrl"
          cols="3"
          class="align-self-center "
          :class="{'showFace' : currentPage === '/settings/email_customisation'}"
        >
          <i class="fa fa-user-circle" style="vertical-align:text-bottom;font-size:2.2rem;color:#555;" />
        </b-col>
        <b-col
          v-show="userData.pic != $parent.silhouetteUrl"
          cols="3"
          class="align-self-center"
          :class="{'showFace' : currentPage === '/settings/email_customisation'}"
        >
          <img
            style="height:50px;width:50px;border-radius:50%;"
            class="ml-1 mr-2 ml-lg-3"
            :src="userData.pic"
          >
        </b-col>
        <b-col
          v-if="userData.fne"
          class="ml-3"
          :class="{'uname' : currentPage === '/settings/email_customisation'}"
          cols="8"
        >
          <b-row style="color:#555;font-weight:bold;width:100%;" class="text-nowrap flex-nowrap">
            {{ (userData.fne.length + userData.sne.length).length > 20 ? `${(userData.fne + ' ' + userData.sne).substring(0, 18) + '...'}` : `${userData.fne} ${userData.sne}` }}
          </b-row>
          <b-row style="color:#555;font-style:italic;width:100%;font-size:0.9rem;" class="text-nowrap flex-nowrap">
            {{ userData.ane.length > 20 ? `${userData.ane.substring(0, 18) + '...'}` : `${userData.ane}` }}
          </b-row>
        </b-col>
      </b-row>
      <button class="nav-btn" style="border:solid 1px #b0b0b0 !important;background:#b0b0b0 !important;color:#555 !important;font-size:1.1em;font-weight:bold;padding:-5px;margin:0px;width:100%;">
        <span
          class="nav-btn-text"
          :class="{'alt-nav-btn-text' : currentPage === '/settings/email_customisation'}"
          style="margin-top:-2px;margin-bottom:-3px;font-size:1.1rem;"
        >
          Settings
        </span>
      </button>
      <div style="text-align:center;background:#e1e1e1;overflow:auto;">
        <!--account details-->
        <button
          v-show="userData.rle === 'Admin' || userData.iss || userData.rle === 'Account Group Administrator'"
          class="mdc-button mdc-button--outlined btn-outline nav-btn-grey"
          @click="goToRoute('account')"
        >
          <div class="mdc-button__ripple" />
          <span
            class="nav-btn-text"
            :class="{'alt-nav-btn-text' : currentPage === '/settings/email_customisation'}"
          ><i class="fas fa-info-circle" style="margin-right:10px;" /><span class="mdc-button__label">Account Details</span></span>
          <mdb-icon
            v-show="$route.path == '/settings/account'"
            icon="chevron-right"
            style="float:right;margin-top:7px;font-size:1rem;"
          />
        </button>

        <!--branding-->
        <button
          v-show="userData.rle === 'Admin' || userData.iss || userData.rle === 'Account Group Administrator'"
          class="mdc-button mdc-button--outlined btn-outline nav-btn-grey"
          @click="goToRoute('branding')"
        >
          <div class="mdc-button__ripple" />
          <span
            class="nav-btn-text"
            :class="{'alt-nav-btn-text' : currentPage === '/settings/email_customisation'}"
          ><mdb-icon icon="list-ol" style="margin-right:5px;" /><span class="mdc-button__label">Branding</span></span>
          <mdb-icon
            v-show="$route.path == '/settings/branding'"
            icon="chevron-right"
            style="float:right;margin-top:7px;font-size:1rem;"
          />
        </button>

        <!-- Custom Profile Fields -->
        <button
          v-if="userData.nsc"
          class="mdc-button mdc-button--outlined btn-outline nav-btn-grey"
          @click="goToRoute('custom_fields')"
        >
          <div class="mdc-button__ripple" />
          <span
            class="nav-btn-text"
            :class="{'alt-nav-btn-text' : currentPage === '/custom_fields'}"
          ><FontAwesomeIcon icon="fa-solid fa-pen-field" style="margin-right:10px;" /><span class="mdc-button__label">Custom Profile Fields</span></span>
          <mdb-icon
            v-show="$route.path.includes('/settings/custom_fields')"
            icon="chevron-right"
            style="float:right;margin-top:7px;font-size:1rem;"
          />
        </button>

        <!--email customisation-->
        <button
          v-if="userData.iss"
          class="mdc-button mdc-button--outlined btn-outline nav-btn-grey noShow"
          @click="goToRoute('email_customisation')"
        >
          <div class="mdc-button__ripple" />
          <span
            class="nav-btn-text"
            :class="{'alt-nav-btn-text' : currentPage === '/settings/email_customisation'}"
          ><FontAwesomeIcon icon="fa-solid fa-envelope" style="margin-right:10px;" /><span class="mdc-button__label">Email Customisation</span></span>
          <mdb-icon
            v-show="$route.path == '/email_customisation'"
            icon="chevron-right"
            style="float:right;margin-top:7px;font-size:1rem;"
          />
        </button>

        <!--event subscriptions-->
        <button
          v-if="mobileView === false && (userData.rle === 'Admin' || userData.iss || userData.rle === 'Account Group Administrator') && userData.job === false"
          class="mdc-button mdc-button--outlined btn-outline nav-btn-grey"
          @click="goToRoute('eventsubscriptions')"
        >
          <div class="mdc-button__ripple" />
          <span
            class="nav-btn-text"
            :class="{'alt-nav-btn-text' : currentPage === '/settings/email_customisation'}"
          >
            <font-awesome-icon :icon="['fas', 'gears']" style="margin-right:10px;" />
            <span class="mdc-button__label">Event Subscriptions</span>
          </span>
          <mdb-icon
            v-show="$route.path.includes('/settings/eventsubscriptions')"
            icon="chevron-right"
            style="float:right;margin-top:7px;font-size:1rem;"
          />
        </button>

        <!--my settings-->
        <button
          class="mdc-button mdc-button--outlined btn-outline nav-btn-grey"
          @click="goToRoute('mysettings')"
        >
          <div class="mdc-button__ripple" />
          <span
            class="nav-btn-text"
            :class="{'alt-nav-btn-text' : currentPage === '/settings/email_customisation'}"
          ><mdb-icon icon="users" style="margin-right:10px;" /><span class="mdc-button__label">My Settings</span></span>
          <mdb-icon
            v-show="$route.path == '/settings/mysettings'"
            icon="chevron-right"
            style="float:right;margin-top:7px;font-size:1rem;"
          />
        </button>

        <!--public api-->
        <button
          v-if="userData.pub && (userData.rle === 'Admin' || userData.iss || userData.rle === 'Account Group Administrator')"
          class="mdc-button mdc-button--outlined btn-outline nav-btn-grey"
          @click="goToRoute('publicapi')"
        >
          <div class="mdc-button__ripple" />
          <span
            class="nav-btn-text"
            :class="{'alt-nav-btn-text' : currentPage === '/settings/email_customisation'}"
          ><mdb-icon icon="cog" style="margin-right:10px;" /><span class="mdc-button__label">Public API</span></span>
          <mdb-icon
            v-show="$route.path == '/settings/publicapi'"
            icon="chevron-right"
            style="float:right;margin-top:7px;font-size:1rem;"
          />
        </button>

        <!--question sets-->
        <div v-if="userData.rle !== 'Inviter'">
          <button
            v-if="(userData.nrl || userData.ncn) && (userData.rle === 'Admin' || userData.rle === 'Manager' || userData.iss || userData.rle === 'Account Group Administrator')"
            class="mdc-button mdc-button--outlined btn-outline nav-btn-grey"
            @click="goToRoute('questionsets')"
          >
            <div class="mdc-button__ripple" />
            <span
              class="nav-btn-text"
              :class="{'alt-nav-btn-text' : currentPage === '/settings/email_customisation'}"
            ><FontAwesomeIcon icon="fa-solid fa-list-ol" style="margin-right:10px;" /><span class="mdc-button__label">Question Sets</span></span>
            <mdb-icon
              v-show="$route.path == '/settings/questionsets'"
              icon="chevron-right"
              style="float:right;margin-top:7px;font-size:1rem;"
            />
          </button>

          <!--quick share-->
          <button
            v-if="userData.nrl || userData.ncn"
            class="mdc-button mdc-button--outlined btn-outline nav-btn-grey"
            @click="goToRoute('quickshare')"
          >
            <div class="mdc-button__ripple" />
            <span
              class="nav-btn-text"
              :class="{'alt-nav-btn-text' : currentPage === '/settings/email_customisation'}"
            ><FontAwesomeIcon icon="fa-solid fa-link" style="margin-right:10px;" /><span class="mdc-button__label">Quick Share</span></span>
            <mdb-icon
              v-show="$route.path == '/settings/quickshare'"
              icon="chevron-right"
              style="float:right;margin-top:7px;font-size:1rem;"
            />
          </button>

          <!--scoring-->
          <button
            v-if="userData.nsc && (userData.rle === 'Admin' || userData.iss || userData.rle === 'Account Group Administrator')"
            class="mdc-button mdc-button--outlined btn-outline nav-btn-grey"
            @click="goToRoute('scoring/templates/all')"
          >
            <div class="mdc-button__ripple" />
            <span
              class="nav-btn-text"
              :class="{'alt-nav-btn-text' : currentPage === '/settings/email_customisation'}"
            ><FontAwesomeIcon icon="fa-solid fa-chart-simple-horizontal" style="margin-right:10px;" /><span class="mdc-button__label">Scoring</span></span>
            <mdb-icon
              v-show="$route.path.includes('/settings/scoring')"
              icon="chevron-right"
              style="float:right;margin-top:7px;font-size:1rem;"
            />
          </button>

          <!--self registration-->
          <button
            v-if="userData.nrl"
            class="mdc-button mdc-button--outlined btn-outline nav-btn-grey"
            @click="goToRoute('selfregistration')"
          >
            <div class="mdc-button__ripple" />
            <span
              class="nav-btn-text"
              :class="{'alt-nav-btn-text' : currentPage === '/settings/email_customisation'}"
            ><FontAwesomeIcon icon="fa-solid fa-user" style="margin-right:10px;" /><span class="mdc-button__label">Self Registration</span></span>
            <mdb-icon
              v-show="$route.path == '/settings/selfregistration'"
              icon="chevron-right"
              style="float:right;margin-top:7px;font-size:1rem;"
            />
          </button>
        </div>

        <!--sms -->
        <!--
        <button
          v-if="userData.sms && userData.nrl && (userData.rle === 'Admin' || userData.iss || userData.rle === 'Account Group Administrator')"
          class="mdc-button mdc-button--outlined btn-outline nav-btn-grey"
          @click="goToRoute('stocktext')"
        >
          <div class="mdc-button__ripple" />
          <span
            class="nav-btn-text"
            :class="{'alt-nav-btn-text' : currentPage === '/settings/email_customisation'}"
          ><mdb-icon icon="pen" style="margin-right:10px;" /><span class="mdc-button__label">SMS Text</span></span>
          <mdb-icon
            v-show="$route.path == '/settings/stocktext'"
            icon="chevron-right"
            style="float:right;margin-top:7px;font-size:1rem;"
          />
        </button>
        -->

        <!--surveys-->
        <!--<button
          v-if="userData.iss && userData.nsv"
          class="mdc-button mdc-button--outlined btn-outline nav-btn-grey"
          @click="goToRoute('surveys/list')"
        >
          <div class="mdc-button__ripple" />
          <span class="nav-btn-text"><FontAwesomeIcon icon="fa-regular fa-square-poll-vertical" style="margin-right:10px;" /><span class="mdc-button__label">Surveys</span></span>
          <mdb-icon
            v-show="$route.path.includes('/settings/surveys')"
            icon="chevron-right"
            style="float:right;margin-top:7px;font-size:1rem;"
          />
        </button>-->

        <!--users-->
        <button
          v-show="(userData.rle === 'Manager' && userData.aid !== 934) || userData.rle === 'Admin' || userData.iss || userData.rle === 'Account Group Administrator'"
          class="mdc-button mdc-button--outlined btn-outline nav-btn-grey"
          @click="goToRoute('users')"
        >
          <div class="mdc-button__ripple" />
          <span
            class="nav-btn-text"
            :class="{'alt-nav-btn-text' : currentPage === '/settings/email_customisation'}"
          ><mdb-icon icon="user-friends" style="margin-right:10px;" /><span class="mdc-button__label">Users</span></span>
          <mdb-icon
            v-show="$route.path == '/settings/users'"
            icon="chevron-right"
            style="float:right;margin-top:7px;font-size:1rem;"
          />
        </button>

        <!--video cv-->
        <!--VCVX
        <button
          v-if="userData.ncn"
          class="mdc-button mdc-button--outlined btn-outline nav-btn-grey"
          @click="goToRoute('videocv')"
        >
          <div class="mdc-button__ripple" />
          <span class="nav-btn-text"><FontAwesomeIcon icon="fa-regular fa-circle-play" style="margin-right:10px;" /><span class="mdc-button__label">Video CVs</span></span>
          <mdb-icon
            v-show="$route.path == '/settings/videocv'"
            icon="chevron-right"
            style="float:right;margin-top:7px;font-size:1rem;"
          />
        </button>
        -->

        <!--
        WHY IS THIS NOT WORKING?
        <button
          v-if="userData.eve && (userData.rle === 'Admin' || userData.iss || userData.rle === 'Account Group Administrator')"
          class="mdc-button mdc-button--outlined btn-outline nav-btn-grey"
          @click="goToRoute('workingpatterns')"
        >
          <div class="mdc-button__ripple" />
          <span
            class="nav-btn-text"
            :class="{'alt-nav-btn-text' : currentPage === '/settings/email_customisation'}"
          >
            <i class="fas fa-calendar" style="margin-right:10px;" />
            <span class="mdc-button__label">Working Patterns</span>
          </span>
          <mdb-icon
            v-show="$route.path.includes('/settings/workingpatterns')"
            icon="chevron-right"
            style="float:right;margin-top:7px;font-size:1rem;"
          />
        </button>
        -->
      </div>
      <div
        class="d-flex flex-wrap align-items-center justify-center leftColBottomButtonPanel mt-auto"
        style="margin-bottom:-2px;"
        :class="{ 'notBarActive': userData.aut && userData.inf > 0, 'alt-leftColBottomButtonPanel' : currentPage === '/settings/email_customisation'}"
      >
        <div style="font-size:10px;font-weight:bold;" class="m-2">
          Shine Interview<br>Version {{ VERSION }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import { VERSION } from '@/common/config.js'
import { useRoute } from 'vue-router/composables'

export default {
  name: 'LeftSettingsColumn',
  setup () {
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)
    const { mobileView } = useMobile()
    const route = useRoute()

    const currentPage = route.fullPath

    return {
      userData,
      mobileView,
      currentPage
    }
  },
  computed: {
    VERSION () { return VERSION }
  },
  methods: {
    goToRoute (route) {
      this.$router.push(`/settings/${route}`).catch(() => { })
    }
  }
}
</script>

<style lang="scss" scoped>
.notBarActive {
  margin-bottom: 43px !important;
}
@media (max-width:1301px) {
  .noShow {
    display: none;
  }
}
@media (max-width:1479px) {
  .tabletSettingsWidth {
    width: 270px !important;
  }
  .leftColumnBoilerPlate1 {
    width:270px !important;
    min-width:270px !important;
  }
}
@media (max-width:1417px) {
  .tabletSettingsWidth {
    width: 230px !important;
  }
  .leftColumnBoilerPlate1 {
    width:230px !important;
    min-width:230px !important;
  }
  .alt-nav-btn-text {
    font-size: smaller !important;
  }
  .face {
    margin-left: -10px;
    margin-right: -20px;
    font-size: small;
  }
  .uname {
    margin-top:5px;
  }
}
@media (max-width:1379px) {
  .tabletSettingsWidth {
    width: 230px !important;
  }
  .leftColumnBoilerPlate1 {
    width:230px !important;
    min-width:230px !important;
  }
  .alt-nav-btn-text {
    font-size: 10px !important;
    margin-left: 70px !important;
  }
  .face {
    margin-left: 75px !important;
    margin-right: -20px;
    font-size: 10px;
  }
  .showFace {
    display: none;
  }
  .alt-leftColBottomButtonPanel {
    margin-left: 60px !important;
  }
}
</style>

