<template>
  <div 
    @click="isOneWayOrTwoCheck()"
  >
    <mdb-popover
      v-if="userData.rle !== 'Inviter'"
      ref="popover"
      trigger="click"
      :options="{placement: 'bottom'}"
      style="cursor:pointer;"
      class="grey-text"
    >
      <div slot="body">
        <h6
          style="margin-left:-10px;padding:12px;"
          class="text-nowrap"
          @click="editRole()"
        >
          <FontAwesomeIcon icon="fa-gear fa-regular" class="grey-link mx-1" /><a class="grey-link"> Role Setup ({{ role.JobId }})</a>
        </h6>

        <h6 style="border-top: 1px solid grey;height: 11px !important;margin-top: 10px !important;margin-bottom: 0px !important;" class="text-nowrap" />

        <div v-if="role.RoleWorkflow !== null">
          <h6
            v-if="(userData.iss || userData.rle === 'Admin') && isOneWay"
            style="margin-left:-10px;margin-top:-5px;padding:12px;"
            class="text-nowrap grey-link"
            @click="shineGoReminderEmailsManualTriggerModal(role)"
          >
            <i class="fa fa-envelope ml-1" style="margin-right:7px;" /> Send Reminder Emails
          </h6>

          <h6
            v-if="(isOneWay || isTwoWay)"
            style="margin-left:-10px;margin-top:-5px;padding:12px;"
            class="text-nowrap grey-link"
            @click="getRoleReportData(role)"
          >
            <i 
              v-if="!reportSpinner"
              class="fa fa-chart-line ml-1"
              style="margin-right:7px;" 
            />
            <i 
              v-else
              class="fa fa-pulse fa-spinner ml-1"
              style="margin-right:7px;" 
            /> Email CSV Extract
          </h6>

          <h6 
            v-if="(userData.iss || userData.rle === 'Admin') && isOneWay"
            style="border-top: 1px solid grey;height: 11px !important;margin-top: 10px !important;margin-bottom: 0px !important;"
            class="text-nowrap" 
          />
        </div>

        <h6
          v-if="(isOneWay || isTwoWay) && role.IsComplete && !noShow"
          style="margin-left:-10px;margin-top:-5px;padding:12px;"
          class="text-nowrap"
          @click="showCloneRoleModal()"
        >
          <i class="fa fa-copy ml-1 grey-link " style="margin-right:7px;" /><a class="grey-link"> Replicate Role</a>
        </h6>
        <h6 
          v-if="(isOneWay || isTwoWay) && role.IsComplete && !noShow"
          style="border-top: 1px solid grey;height: 11px !important;margin-top: 10px !important;margin-bottom: 0px !important;"
          class="text-nowrap" 
        />

        <h6
          v-if="!role.IsDeleted"
          style="margin-left:-10px;margin-top:-5px;padding:12px;"
          class="text-nowrap"
          @click="showDeleteModal(role)"
        >
          <i class="fa fa-archive ml-1 grey-link " style="margin-right:7px;" /><a class="grey-link"> Archive Role</a>
        </h6>
        <h6
          v-if="userData.iss || userData.rle === 'Admin'"
          style="margin-left:-10px;margin-top:-5px;padding:12px;color:red !important;"
          class="text-nowrap"
          @click="showPermanentlyRemoveModal(role)"
        >
          <i class="fa fa-trash ml-1" style="margin-right:7px;" /> Permanently Remove
        </h6>
      </div>
      <mdb-icon
        slot="reference"
        style="width: 20px; height:30px; font-size:1rem;"
        :style="{'color': !role.CloseDateIsPast ? userData.lbl.SecondaryColour : userData.lbl.PrimaryColour}"
        class="d-flex align-items-center justify-content-center ellipsisButtonHover text-black"
        icon="ellipsis-v"
      />
    </mdb-popover>
  </div>
</template>

<script>
import { useUserData, useStatus, useRoles, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'
import reportService from '@/api/services/report.service'

export default {
  name: 'TopPanelPopover',
  props: {
    role: {
      type: Object,
      default: () => {}
    },
    noShow: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)

    const statusStore = useStatus()
    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = statusStore

    const workflowStore = useWorkflow()
    const { selectedWorkflowStage } = storeToRefs(workflowStore)

    const rolesStore = useRoles()
    const { deleteRole, cloneRole, getRoleAndReplace, sendShineGoReminderEmailsManualTrigger } = storeToRefs(rolesStore)

    return {
      userData,
      showGenericErrorModal,
      deleteRole,
      cloneRole,
      sendShineGoReminderEmailsManualTrigger,
      showGenericConfirmModal,
      closeGenericConfirmModal,
      getRoleAndReplace,
      selectedWorkflowStage
    }
  },
  data () {
    return {
      isOneWay: false,
      isTwoWay: false,
      cloneRoleModalShowing: false,
      jobTitle: '',
      externalReferenceId: '',
      loading: false,
      reportSpinner: false
    }
  },
  methods: {
    isOneWayOrTwoCheck() {
      if (this.role.RoleWorkflow.RoleWorkflowStages[0].RoleWorkflowStageName === 'One-Way') {
        this.isOneWay = true
      } else if (this.role.RoleWorkflow.RoleWorkflowStages[0].RoleWorkflowStageName === 'Live Interview' && this.role.RoleWorkflow.RoleWorkflowStages[0].IsSelfSchedule == false) {
        this.isTwoWay = true
      } else if (this.role.RoleWorkflow.RoleWorkflowStages[0].RoleWorkflowStageName === 'Royal Navy Live Interview') {
        this.isTwoWay = true
      }
    },
    async getRoleReportData (role) {
      this.reportSpinner = true
      try {
        await reportService.getRoleReportData(role.JobId, false)
        this.reportSpinner = false
      } catch (err) {
        this.reportSpinner = false
      }
    },
    editRole () {
      this.$router.push(`/roles/add_edit/detail/${this.role.JobId}`).catch(() => {})
    },
    showCloneRoleModal () {
      this.$parent.cloneRoleModalShowing = true
    },
    showDeleteModal () {
      const permanentDelete = false
      var msg = 'Please confirm you wish to archive ' + this.role.JobTitle + '?'
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: `ARCHIVE ${this.role.JobTitle.toUpperCase()}?`,
        confirmMessage: msg,
        confirmButtonMessage: 'ARCHIVE',
        confirmButtonFunction: () => this.confirmDeleteRole(permanentDelete)
      })
      this.confirmedAfterInvitesSent = false
    },
    showPermanentlyRemoveModal () {
      const permanentDelete = true
      var msg = 'Please confirm you wish to remove ' + this.role.JobTitle + '? This action cannot be undone!'
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: `REMOVE ${this.role.JobTitle.toUpperCase()}?`,
        confirmMessage: msg,
        confirmButtonMessage: 'REMOVE',
        confirmButtonFunction: () => this.confirmDeleteRole(permanentDelete)
      })
      this.confirmedAfterInvitesSent = false
    },
    shineGoReminderEmailsManualTriggerModal () {
      var msg = 'Please confirm you wish send reminder emails to all incomplete candidates for the role ' + this.role.JobTitle + '? Note: this action will not be triggered if there are no candidate to remind! Likewise, no expired candidates will be sent reminder!'
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: `REMINDER EMAILS: ${this.role.JobTitle.toUpperCase()}?`,
        confirmMessage: msg,
        confirmButtonMessage: 'SEND',
        confirmButtonFunction: () => this.confirmSendShineGoReminderEmailsManualTrigger()
      })
      this.confirmedAfterInvitesSent = false
    },
    async confirmSendShineGoReminderEmailsManualTrigger () {
      this.spinningFlag = true
      try {
        await this.sendShineGoReminderEmailsManualTrigger(this.role.JobId)
        this.$router.push('/roles').catch(() => {})
      } catch (err) {
        this.showGenericErrorModal()
      }
      this.spinningFlag = false
      this.closeGenericConfirmModal()
    },
    async confirmDeleteRole (permanentlyDelete) {
      this.spinningFlag = true
      try {
        await this.deleteRole(this.role.JobId, permanentlyDelete)
        this.$router.push('/roles').catch(() => {})
      } catch (err) {
        this.showGenericErrorModal()
      }
      this.spinningFlag = false
      this.closeGenericConfirmModal()
    }
  }
}
</script>
