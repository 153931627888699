<template>
  <div>
    <!-- RESEND EMAIL -->
    <h6
      v-if="showResendInvite"
      @click="resendInviteLocal(data.item)"
    >
      <i class="fa fa-envelope ml-1 grey-link" style="margin-right:7px;" />
      <a class="grey-link"> Resend Invite Email</a>
    </h6>

    <!-- REDO STAGE -->
    <h6
      v-if="showRedoStage"
      @click="redoStage(data.item)"
    >
      <FontAwesomeIcon
        icon="fa-solid fa-arrows-repeat"
        class="grey-link ml-1"
        style="margin-right:7px;"
      />
      <a class="grey-link"> Advise To Redo Stage</a>
    </h6>

    <!-- EXTEND DEADLINE -->
    <h6
      v-if="showExtendInviteDeadline"
      @click="extendInviteDeadlineLocal(data.item)"
    >
      <FontAwesomeIcon
        icon="fa-solid fa-arrow-right-to-line"
        class="grey-link ml-1"
        style="margin-right:7px;"
      />
      <a class="grey-link">Extend Deadline</a>
    </h6>

    <!-- INVITATION CODE -->
    <CopyToClipboardPopoverButton
      v-for="(button,i) in stringsToCopy"
      :key="i"
      :string-to-copy="button.url"
      :button-text="button.text"
    />

    <!-- GO TO INTERVIEW (Super User)-->
    <h6
      v-if="userData.iss && (stageItemStatus === 1 || stageItemStatus === 2 || stageItemStatus === 4 || stageItemStatus === 9)"
      @click="goToInterview(data.item)"
    >
      <FontAwesomeIcon
        icon="fa-solid fa-video-camera"
        class="grey-link ml-1"
        style="margin-right:7px;"
      />
      <a class="grey-link">Access Interview</a>
    </h6>
    
    <QuickSharePopoverButton
      v-if="showQuickShare"
      :role-id="role.JobId"
      :RoleWorkflowStageType="stageType"
      :RoleWorkflowStageId="selectedWorkflowStage.RoleWorkflowStageId"
      :candidate-id="data.item.CandidateId"
      @closePopover="$refs.popover.doClose()"
    />

    <!-- SINGLE UNINVITE -->
    <h6
      v-if="showUninvite"
      @click="uninviteSingleAndDeleteInterview(data.item)"
    >
      <i class="fa fa-times ml-1 grey-link" style="margin-right:10px;" />
      <a class="grey-link"> Uninvite</a>
    </h6>

    <!-- WATCH INTERVIEW -->
    <h6
      v-if="stageItemStatus === 4 || stageItemStatus === 5 || stageItemStatus === 6 || stageItemStatus === 7 || stageItemStatus === 8"
      @click="$emit('initialiseReviewModal', data.item)"
    >
      <i class="fa fa-play ml-1 grey-link" style="margin-right:10px;" />
      <a class="grey-link"> Watch Interview</a>
    </h6>

    <!-- RESET -->
    <div v-if="showReset">
      <h6
        v-if="userData.rle !== 'User'"
        @click="resetInterviewLocal(data.item)"
      >
        <i class="fa fa-sync ml-1 grey-link" style="margin-right:7px;" />
        <a class="grey-link"> Reset Interview </a>
      </h6>
    </div>

    <!-- JOBTRAIN SUPER USER RE-COMPLETION -->
    <h6
      v-if="userData.iss && userData.job && data.item.ExternalCandidateReference !== '' && data.item.DateCompleted !== null"
      @click="jobtrainRecompletion(data.item)"
    >
      <i
        class="fa fa-cog grey-link ml-1"
        style="margin-right:7px;"
      />
      <a class="grey-link"> Jobtrain Recompletion</a>
    </h6>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useUserData, useStatus, useRoleCandidate, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'
import { RNYID, RAFID } from '@/common/config.js'

import QuickSharePopoverButton from '@/components/misc/quickSharePopoverButton.vue'
import CopyToClipboardPopoverButton from '@/components/misc/copyToClipboardPopoverButton.vue'
import roleV2Service from '@/api/services/roleV2.service'

import { CANDIDATE_URL } from '@/common/config.js'

export default {
  name: 'OneWayPopoverButton',
  components: {
    QuickSharePopoverButton,
    CopyToClipboardPopoverButton
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const statusStore = useStatus()
    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = statusStore

    const roleCandidateStore = useRoleCandidate()
    const { resetOneWay, candidateTableData, candidate, selectedCandidates, resendInviteOneWayInvite, extendInviteDeadlineModalShowing } = storeToRefs(roleCandidateStore)

    const workflowStore = useWorkflow()
    const { role, addRoleToRecentRoles, selectedWorkflowStage } = storeToRefs(workflowStore)

    const stageType = computed(() => candidateTableData?.value?.StageType)
    const stageItemStatus = computed(() => candidateTableData?.value?.StageItemStatus)

    // Return popover text and urls
    const stringsToCopy = computed(() => {
      if ((stageItemStatus.value === 2 || stageItemStatus.value == 4)) {
        return [
          { url: `${CANDIDATE_URL}?inv=${props.data.item.InvitationCode}`, text: `Copy Link (${props.data.item.InvitationCode})` }
        ]
      } else return []
    })

    const showResendInvite = computed(() => stageItemStatus.value == 4)
    const showQuickShare = computed(() => stageItemStatus.value >= 6)

    // DONT ALLOW RN USERS TO SEE THESE
    const isRNUser = computed(() => (userData.value.mid === RNYID || userData.value.mid === RAFID) && userData.value.rle == 'User')

    const showRedoStage = computed(() => stageItemStatus.value >= 6 && !isRNUser.value)
    const showReset = computed(() => stageItemStatus.value >= 4 && stageItemStatus.value != 5 && !isRNUser.value)
    const showUninvite = computed(() => !isRNUser.value && stageItemStatus.value < 6)
    const showExtendInviteDeadline = computed(() => !isRNUser.value && (stageItemStatus.value == 2 || stageItemStatus.value == 4 || stageItemStatus.value == 5) && !role.value.IsEvergreen)

    return {
      authData,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal,
      candidateTableData,
      role,
      candidate,
      selectedCandidates,
      CANDIDATE_URL,
      addRoleToRecentRoles,
      resendInviteOneWayInvite,
      resetOneWay,
      stageType,
      stageItemStatus,
      stringsToCopy,
      selectedWorkflowStage,
      userData,
      RNYID,
      RAFID,
      showResendInvite,
      showRedoStage,
      showQuickShare,
      showReset,
      showUninvite,
      showExtendInviteDeadline,
      extendInviteDeadlineModalShowing
    }
  },
  methods: {
    goToInterview (candidate) {
      window.open(CANDIDATE_URL + '?inv=' + candidate.InvitationCode, '_blank')
    },
    async resendInviteLocal (candidate) {
      this.candidate = candidate
      await this.resendInviteOneWayInvite(candidate)
    },
    extendInviteDeadlineLocal (candidate) {
      this.candidate = candidate
      this.extendInviteDeadlineModalShowing = true
    },
    resetInterviewLocal (candidate) {
      this.candidate = candidate
      this.resetOneWay(candidate, this.candidateTableData.RoleWorkflowStageId, () => this.$emit('getRoleCandidates'))
    },
    uninviteSingleAndDeleteInterview (candidate) {
      this.candidate = candidate
      this.selectedCandidates = []
      this.selectedCandidates.push(candidate.CandidateId)
      const message = `Are you sure you want to uninvite this candidate? <br /><br />
        <span class="font-weight-bold">Note: </span>
        <br /> <br /> Data associated with the candidates attempt will be deleted, including answers and any scoring points given.`
      const title = 'CONFIRM UNINVITE'
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: title,
        confirmMessage: message,
        confirmButtonMessage: 'UNINVITE',
        confirmButtonFunction: () => this.$emit('confirmUninviteSelected', candidate.CandidateId)
      })
    },
    redoStage (candidate) {
      this.candidate = candidate
      this.selectedCandidates = []
      this.selectedCandidates.push(candidate.CandidateId)
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'REDO THE STAGE',
        confirmMessage: `Do you want to ask the candidate to redo this stage? <br/> <br/>
        They will be sent a new invite code via email and given another opportunity to take the interview. <br/> <br/>
        Previous interview attempts will still be available to view from the candidates history.`,
        confirmButtonMessage: 'CONFIRM',
        confirmButtonFunction: () => this.confirmRedoStage(candidate.CandidateId)
      })
    },
    async confirmRedoStage (candidateId) {
      try {
        const JSONData = {
          RoleId: this.role.JobId,
          SelectedCandidates: [candidateId],
          RoleWorkflowStageId: this.candidateTableData.RoleWorkflowStageId
        }
        await roleV2Service.redoCandidatesOneWayStage(JSONData)
        this.$emit('getRoleCandidates')
        this.closeGenericConfirmModal()
        this.addRoleToRecentRoles(this.role.JobId)
      } catch (err) {
        this.closeGenericConfirmModal()
        this.showGenericErrorModal()
      }
    },
    jobtrainRecompletion (candidate) {
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'JOBTRAIN RECOMPLETION',
        confirmMessage: `Send integration completion trigger again?`,
        confirmButtonMessage: 'CONFIRM',
        confirmButtonFunction: () => this.confirmJobtrainRecompletion(candidate.InvitationCode)
      })
    },
    async confirmJobtrainRecompletion (invitationCode) {
      try {
        await roleV2Service.confirmJobtrainRecompletion(invitationCode)
        this.closeGenericConfirmModal()
      } catch (err) {
        this.closeGenericConfirmModal()
        this.showGenericErrorModal()
      }
    }
  }
}
</script>
