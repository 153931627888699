import axiosInstance from '../instance.js'
const api1 = new axiosInstance(1)
const api2 = new axiosInstance(2)

export default class userService {
  static async getUsers () {
    return await api1.get('/api/go/getusers')
  }

  static async createEditUserSettings (formData) {
    return await api1.post('/api/go/createeditusersettings', formData)
  }

  static async getUserSettings () {
    return await api1.get('/api/go/getusersettings')
  }

  static async getCandidateShareDetails (goShareId) {
    return await api1.get(`/api/go/getcandidatesharedetails?goShareId=${goShareId}`)
  }

  static async deleteUser (userId) {
    return await api1.delete('/api/go/deleteuser?userId=' + userId)
  }

  static async resetUser (userId) {
    return await api1.get('/api/go/resetuser?userId=' + userId)
  }

  static async saveAddEditUser (formData) {
    return await api1.post('/api/go/addedituser', formData)
  }

  static async backToShine (userId) {
    return await api1.get('/api/go/backtoshine?id=' + userId)
  }

  static async profilePicture (formData) {
    return await api1.post('/api/go/userprofilepicture', formData)
  }

  static async getDefaultLanding () {
    return await api1.get('/api/go/getdefaultlandingpages')
  }

  static async checkUserDetails (aid) {
    return await api1.get('/api/go/checkuserdetails?aid=' + aid)
  }

  // todo - test this? why is null passed in original
  static async unSuspendUser (userId) {
    return await api1.post('/api/go/unsuspenduser?userId=' + userId, null)
  }

  //API_URLV2 calls

  static async getUser (id) {
    return await api2.get(`/api/user/get_user/${id}`)
  }

  // get all users and their account groups
  static async getUsersAndAccountGroups () {
    return await api2.get('/api/user/groups/')
  }

  // get all available accounts to assign to a user
  static async getAvailableAccountsForUser (userId) {
    return await api2.get('/api/user/accounts/' + userId)
  }

  // assign account permissions
  static async assignAccountPermissions (userId, accounts) {
    return await api2.post('/api/user/assign/' + userId, accounts)
  }

  // get all account groups
  static async getAccountGroups () {
    return await api2.get('/api/user/accountgroups/')
  }

  // add/edit account group
  static async addEditAccountGroup (grp) {
    return await api2.post('/api/user/accountgroups/', grp)
  }

  // delete account group
  static async deleteAccountGroup (accountGroupMasterId) {
    return await api2.delete('/api/user/accountgroups/' + accountGroupMasterId)
  }

  // assign accounts
  static async assignAccountsToGroup (groupId, accounts) {
    return await api2.post('/api/user/accountgroups/assign/' + groupId, accounts)
  }

  // get all accounts for the group
  static async getAccountGroupsList (accountGroupId) {
    return await api2.get('/api/user/accountgroups/' + accountGroupId)
  }

  // get all available account groups to assign to a user
  static async getAvailableAccountGroupsForUser (userId) {
    return await api2.get('/api/user/usergroups/' + userId)
  }

  // assign account groups permissions
  static async assignAccountGroupsPermissions (userId, accountGroups) {
    return await api2.post('/api/user/usergroups/assign/' + userId, accountGroups)
  }

  // get my accounts list for switching
  static async getMyAccounts () {
    return await api2.get('/api/user/myaccounts/')
  }

  // get my accounts list for switching
  static async getMyRecentAccounts () {
    return await api2.get('/api/user/myaccounts/recent')
  }

  // set as master account
  static async setAsMasterAccount (userId, accountId) {
    return await api2.get('/api/user/myaccounts/setasmasteraccount?uid=' + userId + '&aid=' + accountId)
  }
}
