import axiosInstance from '../instance.js'
const api1 = new axiosInstance(1)

export default class surveyService {
  static async saveSurvey (data) {
    return await api1.post('/api/go/survey/savesurvey', data)
  }

  static async getAllSurveys (includeArchived) {
    return await api1.get(`/api/go/survey/getallsurveys?includeArchived=${includeArchived}`)
  }

  static async deleteSurvey (surveyId) {
    return await api1.delete(`/api/go/survey/deletesurvey?surveyId=${surveyId}`)
  }

  static async makeSurveyDefault (surveyId) {
    return await api1.post(`/api/go/survey/makesurveydefault?surveyId=${surveyId}`, {})
  }
}
