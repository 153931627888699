<template>
  <div>
    <div class="text-left">
      {{ question }}
    </div>
    <Slider
      class="mb-8"
      :lowest-scale-value="sliderValues.LowestScaleValue"
      :number-of-steps="sliderValues.NumberOfSteps"
      :step-increment="sliderValues.StepIncrement"
      :numericTextScores="sliderValues.NumericTextScores"
      :text-array="sliderValues.TextArray"
      :make-text-based="sliderValues.IsTextBased"
      :go-scoring-scale-id="sliderValues.GoScoringScaleId"
      :score="score"
      @sliderScoreChange="$emit('setSliderScore', { score: $event, elementId: sliderValues.GoScoringElementId })"
    />
    <div v-if="!hideCommentBox" class="mx-4 mt-4">
      <!-- <span style="font-size:0.8rem;">COMMENT</span> -->
      <mdb-input
        :value="comment"
        :placeholder="`Comment on '${question}'`"
        type="textarea"
        :rows="5"
        size="sm"
        maxlength="2000"
        style="width:100%;pointer-events: auto !important;"
        auto-complete="jbt"
        class="scoringScaleForm mb-5"
        @input="(value) => $emit('setSliderComment', { elementId: sliderValues.GoScoringElementId, comment: value })"
      />
    </div>
  </div>
</template>

<script>
import Slider from '@/components/misc/Scoring/scoringSlider'

export default {
  components: {
    Slider
  },

  props: {
    question: {
      type: String,
      default: ''
    },
    sliderValues: {
      type: Object,
      default: () => {}
    },
    score: {
      type: Number,
      default: 0
    },
    comment: {
      type: String,
      default: ''
    },
    hideCommentBox: {
      type: Boolean,
      default: true
    }
  }
}
</script>
