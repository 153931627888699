<template>
  <div class="shadow1 roleLeftTileGrid">
    <div
      v-if="role.CloseDateIsPast"
      class="title d-flex align-items-center font-weight-bold px-2 w-100 text-left"
      style="background-color: #ccc;"
    >
      <div class="mr-1 primary-colour">
        {{ role.JobTitle }}
      </div>
      <TopPanelPopover
        class="ml-auto"
        :noShow="true"
        :role="role"
      />
    </div>
    <div
      v-else
      class="title d-flex align-items-center font-weight-bold px-2 w-100 text-left"
      :class="{ 'lighter': !role.IsComplete }"
    >
      <div class="mr-1">
        {{ role.JobTitle }}
      </div>
      <TopPanelPopover
        class="ml-auto"
        :noShow="true"
        :role="role"
      />
    </div>
    <div
      class="tabletAdjustment viewButton d-flex align-items-center justify-content-center black-text font-weight-bold cp border-right-rolecard"
      @click="goToRoleCard()"
    >
      <FontAwesomeIcon
        style="scale: 1.2"
        class="mr-2 cp"
        icon="fa-magnifying-glass-arrow-right fa-regular"
      />
      <span>ROLECARD</span>
    </div>
    <div
      class="tabletAdjustment viewButton d-flex align-items-center justify-content-center black-text font-weight-bold cp"
      @click="goToRoleSetup()"
    >
      <span>ROLE SETUP</span>
      <FontAwesomeIcon
        style="scale: 1.2"
        class="ml-2 cp"
        icon="fa-gear fa-regular"
      />
    </div>
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import TopPanelPopover from '@/views/roles/innerComponents/RoleCard/topPanel/topPanelPopover.vue'

export default {
  name: 'LeftColumnRolesTile',
  components: {
    TopPanelPopover
  },
  props: {
    role: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const statusStore = useStatus()
    const { showGenericErrorModal } = statusStore

    return {
      authData,
      showGenericErrorModal
    }
  },
  methods: {
    goToRoleCard () {
      this.$router.push(`/roles?search=${encodeURIComponent(this.role.JobId)}`).catch(() => {})
    },
    goToRoleSetup () {
      this.$router.push(`/roles/add_edit/detail/${this.role.JobId}`).catch(() => {})
    }
  }
}
</script>

<style lang="css" scoped>
.roleLeftTileGrid {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr 1fr;
  border-radius: 5px;
  border: 2px solid #c0c0c0;
}
.roleLeftTileGrid > div {
  padding: 10px;
}
.viewButton {
  background: #fff;
  color: #555;
  font-size: 0.9rem;
}
.title {
  grid-column: 1 / span 2;
  background: var(--pr-color);
  color: #fff;
}
.title.lighter {
  background: var(--op-color);
}
.border-right-rolecard {
  border-right: solid 2px #c0c0c0;
}
</style>
