<template>
  <div class="px-3 px-md-4 py-2 py-md-3 text-left" style="width:100%;">
    <div>A Candidate with this email address already exists however we have noticed the following descrepancies between the data we hold and what you provided.</div>
    <div class="mt-3 mb-4">
      Please select the correct information for this person
    </div>
    <div class="text-center">
      <div class="row pb-2">
        <div class="col-3" />
        <div class="col-4">
          Current Information
        </div>
        <div class="col-4">
          New Information
        </div>
      </div>
      <div v-if="conflictingValues.NewFirstName" class="row pb-2 gap-1">
        <div class="col-3">
          First Name:
        </div>
        <div
          class="col-4 infoBubble"
          :class="{'selected': selectedFirstName === conflictingValues.OldFirstName}"
          @click="selectedFirstName = conflictingValues.OldFirstName"
        >
          {{ conflictingValues.OldFirstName }}
        </div>
        <div
          class="col-4 infoBubble"
          :class="{'selected': selectedFirstName === conflictingValues.NewFirstName}"
          @click="selectedFirstName = conflictingValues.NewFirstName"
        >
          {{ conflictingValues.NewFirstName }}
        </div>
      </div>
      <div v-if="conflictingValues.NewLastName" class="row pb-2 gap-1">
        <div class="col-3">
          Last Name:
        </div>
        <div
          class="col-4 infoBubble"
          :class="{'selected': selectedLastName === conflictingValues.OldLastName}"
          @click="selectedLastName = conflictingValues.OldLastName"
        >
          {{ conflictingValues.OldLastName }}
        </div>
        <div
          class="col-4 infoBubble"
          :class="{'selected': selectedLastName === conflictingValues.NewLastName}"
          @click="selectedLastName = conflictingValues.NewLastName"
        >
          {{ conflictingValues.NewLastName }}
        </div>
      </div>
      <div v-if="conflictingValues.NewContactNumber" class="row pb-2 gap-1">
        <div class="col-3">
          Contact Number:
        </div>
        <div
          class="col-4 infoBubble"
          :class="{'selected': selectedContactNumber === conflictingValues.OldContactNumber}"
          @click="selectedContactNumber = conflictingValues.OldContactNumber"
        >
          {{ conflictingValues.OldContactNumber }}
        </div>
        <div
          class="col-4 infoBubble"
          :class="{'selected': selectedContactNumber === conflictingValues.NewContactNumber}"
          @click="selectedContactNumber = conflictingValues.NewContactNumber"
        >
          {{ conflictingValues.NewContactNumber }}
        </div>
      </div>
      <div v-if="conflictingValues.NewLocation" class="row pb-2 gap-1">
        <div class="col-3">
          Location:
        </div>
        <div
          class="col-4 infoBubble"
          :class="{'selected': selectedLocation === conflictingValues.OldLocation}"
          @click="selectedLocation = conflictingValues.OldLocation"
        >
          {{ conflictingValues.OldLocation }}
        </div>
        <div
          class="col-4 infoBubble"
          :class="{'selected': selectedLocation === conflictingValues.NewLocation}"
          @click="selectedLocation = conflictingValues.NewLocation"
        >
          {{ conflictingValues.NewLocation }}
        </div>
      </div>
      <div v-if="conflictingValues.NewExternalCandidateReference" class="row pb-2 gap-1">
        <div class="col-3">
          External Candidate Reference ID:
        </div>
        <div
          class="col-4 infoBubble"
          :class="{'selected': selectedExternalId === conflictingValues.OldExternalCandidateReference}"
          @click="selectedExternalId = conflictingValues.OldExternalCandidateReference"
        >
          {{ conflictingValues.OldExternalCandidateReference }}
        </div>
        <div
          class="col-4 infoBubble"
          :class="{'selected': selectedExternalId === conflictingValues.NewExternalCandidateReference}"
          @click="selectedExternalId = conflictingValues.NewExternalCandidateReference"
        >
          {{ conflictingValues.NewExternalCandidateReference }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ConfirmDetails',
  props: {
    conflictingValues: Object
  },
  data () {
    return {
      selectedFirstName: '',
      selectedLastName: '',
      selectedContactNumber: '',
      selectedLocation: '',
      selectedExternalId: ''
    }
  },
  computed: {
    conflictCount () {
      let count = 0
      for (const [key, value] of Object.entries(this.conflictingValues)) {
        if (key.includes('New') && value != null) { count += 1 }
      }
      return count
    },
    selectedValuesCount () {
      let count = 0
      if (this.selectedFirstName !== '') { count += 1 }
      if (this.selectedLastName !== '') { count += 1 }
      if (this.selectedContactNumber !== '') { count += 1 }
      if (this.selectedLocation !== '') { count += 1 }
      if (this.selectedExternalId !== '') { count += 1 }
      return count
    },
    validConflicts () {
      return this.conflictCount === this.selectedValuesCount
    }
  },
  watch: {
    validConflicts () {
      this.$emit('validConflicts', this.validConflicts)
    },
    selectedFirstName () {
      this.$emit('firstName', this.selectedFirstName)
    },
    selectedLastName () {
      this.$emit('lastName', this.selectedLastName)
    },
    selectedContactNumber () {
      this.$emit('contactNumber', this.selectedContactNumber)
    },
    selectedLocation () {
      this.$emit('location', this.selectedLocation)
    },
    selectedExternalId () {
      this.$emit('externalCandidateReference', this.selectedExternalId)
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.infoBubble {
  border: 2px #c0c0c0 solid;
  border-radius: 5px;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.infoBubble:hover {
  border: 2px #535353 solid;
  transition: 0.5s ease;
}
.infoBubble.selected {
  border: 2px rgb(66, 142, 71) solid;
  transition: 0.5s ease;
}
</style>
