<template>
  <button class="help-card-cta" @click="articleCardOpened(helpTopic)">
    <div class="preview-card">
      <div class="preview-image-container">
        <img
          class="preview-image"
          :alt="getAltTag"
          :src="getPreviewImageUrl"
          :srcset="getIOSSrcSet"
        >
        <div class="play-overlay">
          <i v-show="helpTopic.ArticleType === &quot;Video&quot;" class="fa fa-play" />
          <i v-show="helpTopic.ArticleType === &quot;Document&quot;" class="fa fa-external-link-alt" />
        </div>
      </div>
      <div class="hidden-info-row">
        <p class="help-card-author">
          <i class="fa fa-user" />by: {{ helpTopic.UploaderName }}
        </p>
        <p v-if="helpTopic.ContentDuration !== '00:00:00'" class="video-duration">
          <i class="fa fa-clock" />{{ helpTopic.ContentDuration }}
        </p>
      </div>
      <div class="card-body">
        <div class="card-first-row">
          <div class="icon-container">
            <FontAwesomeIcon
              v-show="helpTopic.ArticleType === 'Document'"
              icon="fa-solid fa-file"
              size="3x"
            />
            <FontAwesomeIcon
              v-show="helpTopic.ArticleType === &quot;Video&quot;"
              icon="fa-solid fa-video"
              size="3x"
            />
          </div>
          <div class="card-title">
            <h2 class="help-card-title">
              {{ helpTopic.ArticleName }}
            </h2>
          </div>
          <div class="options" @click.stop>
            <mdb-popover
              ref="popover"
              trigger="click"
              :options="{placement: 'left'}"
              style="cursor:pointer;"
              class="grey-text"
            >
              <div slot="body">
                <h6 @click="showDeleteModal(helpTopic.Id, helpTopic.ArticleType)">
                  <i class="fa fa-times ml-1 grey-link" style="margin-right:10px;" /><a class="grey-link"> Delete</a>
                </h6>
              </div>
              <mdb-icon
                slot="reference"
                title="More actions"
                class="ellipsisButtonHover p-1 right5"
                style="color:#555;font-size:1rem;"
                icon="ellipsis-v"
              />
            </mdb-popover>
          </div>
        </div>
        <div class="card-second-row">
          <div class="tags">
            <i v-if="helpTopic.Tags.length > 0 && helpTopic.Tags[0] !== ''" class="fa fa-tag" />
            <div
              v-for="tag in helpTopic.Tags"
              :key="tag"
              class="tag-text"
            >
              <div class="tag">
                {{ tag }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </button>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'HelpTopic',
  props: {
    helpTopic: {
      type: Object,
      default: () => {}
    },
    userToken: {
      type: String,
      default: ''
    }
  },
  setup () {
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)

    return {
      userData
    }
  },
  data () {
    return {
      imageLookup: {
        '1:D:1': './GS_White.png',
        '1:L:1': './GS_Black.png',
        '1:D:2': './GS_White@2x.png',
        '1:L:2': './GS_Black@2x.png',
        '2:D:1': './GYK_White.png',
        '2:L:1': './GYK_Black.png',
        '2:D:2': './GYK_White@2x.png',
        '2:L:2': './GYK_Black@2x.png',
        '3:D:1': './UG_White.png',
        '3:L:1': './UG_Black.png',
        '3:D:2': './UG_White@2x.png',
        '3:L:2': './UG_Black@2x.png',
        '4:D:1': './Cand_White.png',
        '4:L:1': './Cand_Black.png',
        '4:D:2': './Cand_White@2x.png',
        '4:L:2': './Cand_Black@2x.png',
        '5:D:1': './Roles_White.png',
        '5:L:1': './Roles_Black.png',
        '5:D:2': './Roles_White@2x.png',
        '5:L:2': './Roles_Black@2x.png',
        '6:D:1': './Tech_White.png',
        '6:L:1': './Tech_Black.png',
        '6:D:2': './Tech_White@2x.png',
        '6:L:2': './Tech_Black@2x.png'
      }
    }
  },
  computed: {
    getTopic () {
      var topic = ''
      if (this.helpTopic.Category === 1) {
        topic = 'Getting Started'
      } else if (this.helpTopic.Category === 2) {
        topic = 'Did You Know?'
      } else if (this.helpTopic.Category === 3) {
        topic = 'User Guides'
      } else if (this.helpTopic.Category === 4) {
        topic = 'Candidates'
      } else if (this.helpTopic.Category === 5) {
        topic = 'Roles'
      } else if (this.helpTopic.Category === 6) {
        topic = 'Technical'
      }
      return topic
    },
    getAltTag () {
      return this.helpTopic.ArticleName + ' - ' + this.getTopic
    },
    getPreviewImageName () {
      var returnName = ''
      if (this.helpTopic.Category === 1) {
        returnName = returnName + 'Getting Started'
      } else if (this.helpTopic.Category === 2) {
        returnName = returnName + 'Did You Know'
      } else if (this.helpTopic.Category === 3) {
        returnName = returnName + 'User Guides'
      } else if (this.helpTopic.Category === 4) {
        returnName = returnName + 'Candidates'
      } else if (this.helpTopic.Category === 5) {
        returnName = returnName + 'Roles'
      } else if (this.helpTopic.Category === 6) {
        returnName = returnName + 'Technical'
      }
      return returnName
    },
    getPreviewImageUrl () {
      return this.getImageUri('1')
    },
    getIOSSrcSet () {
      var url = this.getImageUri('2')
      return url + ' 2x'
    }
  },
  methods: {
    articleCardOpened (helpTopic) {
      if (helpTopic.ArticleType === 'Video') {
        this.helpVideoModalOpen(helpTopic)
      } else {
        this.helpPdfModalOpen(helpTopic)
      }
    },
    helpVideoModalOpen (helpTopic) {
      this.$emit('toggleVideoModal', helpTopic)
    },
    showDeleteModal (helpArticleId, articleType) {
      var articleData = {
        id: helpArticleId,
        type: articleType
      }
      this.$emit('toggleDeleteModal', articleData)
    },
    helpPdfModalOpen (helpTopic) {
      this.$emit('togglePdfModal', helpTopic)
    },
    isColorTooDark (color) {
      var c = color.substring(1) // strip #
      var rgb = parseInt(c, 16) // convert rrggbb to decimal
      var r = (rgb >> 16) & 0xff // extract red
      var g = (rgb >> 8) & 0xff // extract green
      var b = (rgb >> 0) & 0xff // extract blue
      var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // ITU-R BT.709 standard
      if (luma < 125) {
        return true
      } else {
        return false
      }
    },
    getImageUri (density) {
      if (density !== '1' && density !== '2') {
        density = '1'
      }
      var images = require.context('@/assets/help/preview-images', false, /\.png$/)
      var color = this.userData.lbl.OpacityColour
      var tooDark = this.isColorTooDark(color)
      var colorKey = (tooDark) ? 'D' : 'L'
      // Category Id / Light Or Dark / Pixel Density
      var lookupKey = this.helpTopic.Category + ':' + colorKey + ':' + density
      return images(this.imageLookup[lookupKey])
    }
  }
}
</script>
