import { ref, computed } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useStatus } from '@/pinia'
import { useWorkflow } from './useWorkflow'

import moment from 'moment';

import eventService from '@/api/services/event.service'
import userService from '@/api/services/user.service'

export const useScheduler = defineStore('schedulerStore', () => {
  const { showGenericErrorModal } = useStatus()
  const workflowStore = useWorkflow()
  const { selectedWorkflowStage } = storeToRefs(workflowStore)

  const eventStageLocationTimeslots = ref([])
  const introVideoModal = ref(false)
  const selectedVideo = ref(null)
  const introTextForm = ref(false)
  const selectedDate = ref(null)

  // Event
  const expiryDays = ref([
    { text: 'No Expiry', value: null, selected: true },
    { text: 'Same Day', value: 1 },
    { text: '2 Days', value: 2 },
    { text: '3 Days', value: 3 },
    { text: '5 Days', value: 5 },
    { text: '7 Days', value: 7 },
    { text: '10 Days', value: 10 },
    { text: '14 Days', value: 14 },
    { text: '28 Days', value: 28 }
  ])

  const selectedExpiry = computed(() => expiryDays?.value?.find(x => x.selected)?.value)

  const selectedSchedulerSetup = ref({
    MaximumAmendmentsAllowed: null,
    MinimumAmendmentsPeriod: null,
    DaysAheadBookingAllowed: null,
    DaysAheadBookingBlocked: null,
    InvitationExpiry: null,
  })

  const getUsers = async () => {
    try {
      const res = await userService.getUsers()
      return res.data
    } catch {
      showGenericErrorModal({ showing: true })
    }
  }

  const getSchedulerSetup = async (id) => {
    try {
      const res = await eventService.getSchedulerSetup(id)
      return res.data
    } catch {
      showGenericErrorModal({ showing: true })
    }
  }

  const editSchedulerSetup = async (payload) => {
    try {
      const res = await eventService.editSchedulerSetup(payload)
      return res.data
    } catch (err) {
      showGenericErrorModal({ showing: true })
    }
  }

  const deleteTimeSlot = async (timeslotIdArray) => {
    try {
      const res = await eventService.deleteTimeSlot(timeslotIdArray)
      return res.data
    } catch (err) {
      showGenericErrorModal({ showing: true })
    }
  }

  // Timeslots
  const schedulerSlot = ref({
    StartTime: null,
    EndTime: null,
    NumberOfSlots: null,
    StartDate: null,
    EndDate: null,
    LinkSlotsToUsers: false
  })

  const resetSchedulerSlotForm = () => {
    schedulerSlot.value = {}
    schedulerSlot.value = { ...{
    StartTime: null,
    EndTime: null,
    NumberOfSlots: null,
    StartDate: null,
    EndDate: null,
    LinkSlotsToUsers: false }}
  }

  const timeslotsLoading = ref(true)
  const loadSchedulerTimeslots = async (date, id) => {
    timeslotsLoading.value = true
    const res = await getSchedulerTimeslots(date, id)
    eventStageLocationTimeslots.value = res
    timeslotsLoading.value = false
  }

  const getSchedulerTimeslots = async (date, id = null) => {
    var d = moment(date).format("YYYY-MM-DD")
    const roleWorkflowStageId = id === null
      ? selectedWorkflowStage.value.RoleWorkflowStageId
      : id
    try {
      const res = await eventService.getSchedulerTimeslots(roleWorkflowStageId, d)
      return res.data
    } catch (err) {
        showGenericErrorModal({ showing: true })
    }
  }

  const getSchedulerTimeslotCount = async (id) => {
    try {
      const res = await eventService.getSchedulerTimeslotCount(id)
      return res.data
    } catch (err) {
      showGenericErrorModal({ showing: true })
    }
  }

  const daySelected = ref([false, true, true, true, true, true, false])

  // Working Pattern
  const workingPattern = ref({ Name: '' })

  const getWorkingPatterns = async () => {
    try {
      const res = await eventService.getEventWorkingPatterns()
      return res.data
    } catch (err) {
      showGenericErrorModal({ showing: true })
    }
  }

  const days = [
    {
      text: 'Mon',
      value: 1
    },
    {
      text: 'Tues',
      value: 2
    },
    {
      text: 'Wed',
      value: 3
    },
    {
      text: 'Thurs',
      value: 4
    },
    {
      text: 'Fri',
      value: 5
    },
    {
      text: 'Sat',
      value: 6
    },
    {
      text: 'Sun',
      value: 0
    }
  ]

  return {
    days,
    daySelected,
    deleteTimeSlot,
    editSchedulerSetup,
    schedulerSlot,
    expiryDays,
    getSchedulerTimeslots,
    getSchedulerTimeslotCount,
    getSchedulerSetup,
    getUsers,
    introTextForm,
    introVideoModal,
    selectedDate,
    selectedExpiry,
    selectedVideo,
    selectedSchedulerSetup,
    eventStageLocationTimeslots,
    loadSchedulerTimeslots,
    timeslotsLoading,
    workingPattern,
    resetSchedulerSlotForm,
    getWorkingPatterns,
  }
})
