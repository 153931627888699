<template>
  <div
    id="editable"
    contenteditable="true"
    style="height: 45px; text-wrap: nowrap"
    ref="editable"
    @keyup="handleInput"
  />
</template>

<script>
import { computed, ref, watch } from 'vue';

export default {
  name: 'EditableContent',
  props: {
    initial: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const content = ref('')
    const editable = ref(null)

    const handleInput = () => {
      // content.value = editable.value.innerHTML
      emit('updateContent', editable.value.innerHTML)
    }
    const initialProp = computed(() => props.initial)
    watch(initialProp, (old, newValue) => {
      if (newValue == '' && old.length > 2) editable.value.innerHTML = props.initial
    }, { immediate: true })

    return { content, handleInput, editable }
  }
};
</script>

<style scoped>
#editable {
  border: 2px solid #ccc;
  padding: 10px;
  height: 40px;
  outline: none;
}
</style>
