<template>
  <div>
    <div class="w-full">
      <div
        class="flex flex-row items-center scheduleSettingsForm"
      >
        <font-awesome-icon
          :icon="['fas', 'layer-group']"
          class="mr-2 text-primary"
          style="cursor:help;"
        />
        <mdb-input
          v-model="selectedSchedulerSetup.DaysAheadBookingAllowed"
          class="w-full"
          :min="0"
          :max="50"
          type="number"
          size="sm"
          label="Days ahead booking allowed"
        />
        <Tooltip
          class="ml-3"
          tooltip-direction="left"
          tool-tip-summary="This optional setting creates a MAXIMUM time period, relative to the day of accessing the scheduler, that a candidate is allowed to book. <br /> <br /> For example, if 7 is chosen and a candidate accesses the scheduler on a Tuesday, only slots up to and including the following Tuesday, will be available to book."/>
      </div>

      <div
        class="flex flex-row items-center scheduleSettingsForm"
      >
        <font-awesome-icon
          :icon="['fas', 'layer-group']"
          class="mr-2 text-primary"
          style="cursor:help;"
        />
        <mdb-input
          v-model="selectedSchedulerSetup.DaysAheadBookingBlocked"
          class="w-full"
          :min="0"
          :max="50"
          size="sm"
          type="number"
          label="Days ahead booking blocked"
        />
        <Tooltip
          class="ml-3"
          tooltip-direction="left"
          tool-tip-summary="This optional setting creates a MINIMUM time period, relative to the day of accessing the scheduler, that a candidate is allowed to book. <br /> <br /> For example, if 3 is chosen and a candidate accesses the scheduler on a Tuesday, only slots from Friday onwards will be available for the candidate to book."/>
      </div>

      <div
        class="flex flex-row items-center scheduleSettingsForm"
      >
        <font-awesome-icon
          :icon="['fas', 'layer-group']"
          class="mr-2 text-primary"
          style="cursor:help;"
        />
        <mdb-input
          v-model="selectedSchedulerSetup.MaximumAmendmentsAllowed"
          class="w-full"
          :min="0"
          :max="50"
          size="sm"
          type="number"
          label="Maximum booking amendments allowed"
        />
        <Tooltip
          class="ml-3"
          tooltip-direction="left"
          tool-tip-summary="This optional setting allows you to restrict the number of times a candidate may amend their scheduled booking date."
        />
      </div>

      <div
        class="flex flex-row items-center scheduleSettingsForm"
      >
        <font-awesome-icon
          :icon="['fas', 'layer-group']"
          class="mr-2 text-primary"
          style="cursor:help;"
        />
        <mdb-input
          v-model="selectedSchedulerSetup.MinimumAmendmentsPeriod"
          class="w-full"
          :min="0"
          :max="50"
          size="sm"
          type="number"
          label="Minimum booking amendments period"
        />
        <Tooltip
          class="ml-3"
          tooltip-direction="left"
          tool-tip-summary="This optional setting allows you to prevent candidates from re-scheduling, when their existing booking, will take place within a certain number of days. <br /> <br /> For example, if 2 is chosen, candidates will only be able to re-schedule, when their existing booking is more than 2 days in the future."
        />
      </div>

      <div
        class="flex flex-row items-center scheduleSettingsForm"
      >
        <font-awesome-icon
          :icon="['far', 'calendar-xmark']"
          class="mr-2 text-primary"
          style="cursor:help;"
          title="This is how many days applicant has to actually book a time slot from receiving their invite"
        />
        <mdb-select
          v-model="expiryDays"
          size="sm"
          style="font-size:0.9rem"
          label="Invitation Expiry (days)"
          class="w-full"
        />
      </div>

      <!-- Video Intro -->
      <div
        class="text-left grey-text"
        style="margin-top:20px;margin-bottom:20px; font-size: .9rem"
      >
        Intro video or intro text
      </div>
      <div
        v-if="!introTextForm"
        class="flex flex-row items-center mb-4"
        label="Intro"
      >
        <font-awesome-icon
          :icon="['fa', 'video']"
          class="mr-2 text-primary"
          style="cursor:help;"
          title="Add an introductory video here"
        />
        <div
          v-if="!selectedVideo"
          class="rounded text-center border-gray-400 p-4 text-primary"
          style="font-size:1rem;font-weight:bold;cursor:pointer;width:100%;border:dashed 1px;"
          @click="showAddInsertVideoModal = true"
        >
          Add intro video
        </div>
        <div
          v-if="selectedVideo !== null"
          style="width:100%;border:dashed 1px;"
          class="grid grid-cols-5 text-bold text-black rounded text-center border-gray-400 bg-gray-100 p-4 my-1"
        >
          <div
            class="col-span-1 align-self-center text-nowrap overflow-hidden"
            style="text-align:left;font-size:smaller;"
          >
            <img
              :src="selectedVideo.ThumbnailUrl"
              style="height:75px;border:solid 1px #c0c0c0;border-radius:5px;object-fit:cover;"
            >
          </div>
          <div
            class="col-span-3 align-self-center text-nowrap overflow-hidden"
            style="text-align:left;font-size:smaller;margin-left:15px;"
          >
            {{ selectedVideo.VideoTitle }}
          </div>
          <div class="col-span-1 align-self-center text-nowrap overflow-hidden text-right">
            <i
              class="col-span-1 fa fa-2x text-left fa-times mx-2"
              style="font-size:1.2rem;cursor:pointer;"
              @click="selectedVideo = null"
            />
          </div>
        </div>
      </div>
      <div
        class="mr-auto flex mb-4"
        @click="toggleTextIntro()"
      >
        <mdb-input
          v-model="introTextForm"
          type="checkbox"
          class="grey-text"
          size="sm"
          style="font-size: 0.9rem"
          label="Use a written intro instead?"
        />
      </div>

      <!-- Text Intro -->
      <div
        v-if="introTextForm"
        class="flex flex-row items-center"
      >
        <font-awesome-icon
          :icon="['fas', 'pen']"
          class="mr-2 text-primary"
          style="margin-top:-105px;cursor:help;"
          title="Add introductory text here"
        />
        <mdb-input
          v-model="selectedSchedulerSetup.IntroText"
          type="textarea"
          label="Intro text"
          :rows="5"
          class="w-full"
        />
      </div>
    </div>

    <div class="flex flex-row">
      <mdb-btn
        class="btn text-center flex ml-auto"
        size="sm"
        @click="edit()"
      >
        FINISH
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { useScheduler, useLibrary, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'
import Tooltip from '@/components/misc/tooltip.vue'

export default {
  name: 'SlotManagementEventSetup',
  components: { Tooltip },
  setup (props, { emit }) {
    const workflowStore = useWorkflow()
    const { selectedWorkflowStage } = storeToRefs(workflowStore)

    const schedulerStore = useScheduler()
    const { editSchedulerSetup } = useScheduler()
    const {
      selectedSchedulerSetup,
      expiryDays,
      introTextForm,
      selectedVideo,
      selectedExpiry
    } = storeToRefs(schedulerStore)

    const libraryStore = useLibrary()
    const { showAddInsertVideoModal } = storeToRefs(libraryStore)

    const edit = async () => {
      let payload = {
        RoleWorkflowStageId: selectedWorkflowStage.value.RoleWorkflowStageId,
        MinimumAmendmentsPeriod: selectedSchedulerSetup.value.MinimumAmendmentsPeriod,
        MaximumAmendmentsAllowed: selectedSchedulerSetup.value.MaximumAmendmentsAllowed,
        DaysAheadBookingAllowed: selectedSchedulerSetup.value.DaysAheadBookingAllowed,
        DaysAheadBookingBlocked: selectedSchedulerSetup.value.DaysAheadBookingBlocked,
        InvitationExpiry: selectedExpiry.value,
        IntroText: selectedSchedulerSetup.value.IntroText,
        UploadedVideoId: selectedVideo.value?.UploadedVideoId,
      }
      await editSchedulerSetup(payload)
      emit('hideModal')
    }

    const toggleTextIntro = () => {
      introTextForm.value = !introTextForm.value
      if (!introTextForm.value) selectedSchedulerSetup.value.IntroText = null
      else selectedVideo.value = null
    }

    return {
      selectedSchedulerSetup,
      expiryDays,
      introTextForm,
      selectedVideo,
      toggleTextIntro,
      showAddInsertVideoModal,
      edit
    }
  }
}
</script>

<style scoped>
.scheduleSettingsForm {
  height: 65px !important;
  margin-top: 8px;
}
</style>
