<template>
  <div class="list">
    <div class="article-icon">
      <FontAwesomeIcon
        v-show="helpTopic.ArticleType === 'Document'"
        icon="fa-solid fa-file"
        size="3x"
      />
      <FontAwesomeIcon
        v-show="helpTopic.ArticleType === &quot;Video&quot;"
        icon="fa-solid fa-video"
        size="3x"
      />
    </div>
    <div class="article-information">
      <div class="title">
        {{ helpTopic.ArticleName }}
      </div>
      <div v-if="helpTopic.Tags.length > 0 && helpTopic.Tags[0] !== ''" class="tags">
        <i class="fa fa-tag" />
        <div
          v-for="tag in helpTopic.Tags"
          :key="tag"
          class="tag-text"
        >
          <div class="tag">
            {{ tag }}
          </div>
        </div>
      </div>
      <div v-if="helpTopic.Tags.length === 1 && helpTopic.Tags[0] === ''" class="no-tags" />
    </div>
    <div class="meta-information">
      <p class="help-card-author">
        <i class="fa fa-user" />{{ helpTopic.UploaderName }}
      </p>
      <div class="meta-date">
        <i class="fa fa-calendar" />{{ helpTopic.PrettyUploadedDate }}
      </div>
    </div>
    <div class="btn-container">
      <a
        v-show="helpTopic.ArticleType === 'Video'"
        class="btn help-list-cta"
        @click="helpVideoModalOpen(helpTopic)"
      >
        <div>Watch</div>
      </a>
      <a
        v-show="helpTopic.ArticleType === 'Document'"
        class="btn help-list-cta"
        target="_blank"
        @click="helpPdfModalOpen(helpTopic)"
      >
        <div>View</div>
      </a>
    </div>
    <div class="options">
      <mdb-popover
        ref="popover"
        trigger="click"
        :options="{placement: 'left'}"
        style="cursor:pointer;"
        class="grey-text"
      >
        <div slot="body">
          <h6 @click="showDeleteModal(helpTopic.Id, helpTopic.ArticleType)">
            <i class="fa fa-times ml-1 grey-link" style="margin-right:10px;" /><a class="grey-link"> Delete</a>
          </h6>
        </div>
        <mdb-icon
          slot="reference"
          title="More actions"
          class="ellipsisButtonHover p-1 right5"
          style="color:#555;font-size:1rem;"
          icon="ellipsis-v"
        />
      </mdb-popover>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpTopic',
  props: {
    helpTopic: {
      type: Object,
      default: () => {}
    },
    userToken: {
      type: String,
      default: ''
    }
  },
  methods: {
    helpVideoModalOpen (helpTopic) {
      this.$emit('toggleVideoModal', helpTopic)
    },
    showDeleteModal (helpArticleId, articleType) {
      var articleData = {
        id: helpArticleId,
        type: articleType
      }
      this.$emit('toggleDeleteModal', articleData)
    },
    helpPdfModalOpen (helpTopic) {
      this.$emit('togglePdfModal', helpTopic)
    }
  }
}
</script>
