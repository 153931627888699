import axiosInstance from '../instance.js'
const api2 = new axiosInstance(2)

export default class customEmailService {

  static async saveCustomEmail (data) {
    return await api2.post('api/settings/custom_email/save_custom_email', data)
  }

  static async getCustomEmail (data) {
    return await api2.post('api/settings/custom_email/get_custom_email', data)
  }
}
