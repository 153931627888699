<template>
  <div class="leftColumnBoilerPlate d-none d-md-block">
    <div style="width:100%;height:100%;" class="d-flex flex-col">
      <div class="font-bold text-left text-gray-600 leftColHeader">
        Recent Clients
        <i v-show="recentClientsLoading" class="fas fa-spin fa-sync" />
        <i v-show="!recentClientsLoading" class="fas fa-sync" />
      </div>
      <div class="leftColMainContent p-3">
        <div v-if="recentClients.length === 0 && recentClientsLoading === false">
          <p class="grey-text" style="margin-left:10px;font-size:0.8rem;text-align:left;margin-right:10px;">
            You have no recent clients, let's get started and add some clients by clicking the button below!
          </p>
        </div>
        <transition-group
          tag="ul"
          name="slide-in"
          :style="{ '--total': recentClients.length }"
          style="height:fit-content;padding-bottom:70px"
        >
          <li
            v-for="(client, i) in recentClients"
            :key="client.GoClientPortalClientId"
            :style="{'--i': i}"
            class="cp text-left text-gray-600 px-2 mb-2 pr-3 leftColumnThinTile"
            @click="goToClient(client.GoClientPortalClientId)"
          >
            <div
              style="height:40px;max-width:30%;border-radius:50%;display:flex;align-items:center;justify-content:center;"
              class="mr-2"
              @click="goToClient(client.GoClientPortalClientId)"
            >
              <img
                style="height:36px;width:100%;;border-radius:50%;object-fit:contain;"
                :src="client.Logo"
                :style="client.Logo.includes('shine_logo_white') || client.Logo.includes('shine-logo-white') ? 'background: #FF7e00; padding: 5px' : ''"
              >
            </div>
            <div
              class="text-center mr-auto"
              style="width:max-content;"
              @click="goToClient(client.GoClientPortalClientId)"
            >
              {{ client.Name }}
            </div>
          </li>
        </transition-group>
      </div>
      <div
        class="d-flex flex-wrap align-items-center justify-center leftColBottomButtonPanel"
        style="margin-bottom:5px;"
        :class="{ 'notBarActive': userData.aut && userData.inf > 0 }"
      >
        <mdb-btn
          size="sm"
          class="btn my-2 my-md-1 mx-2"
          style="min-width:110px;width:fit-content;padding: 0.5rem 1.1rem;"
          @click="addClient()"
        >
          <i class="fa fa-plus-circle" /> ADD
        </mdb-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'ClientViewLeftColumn',
  props: {
    recentClients: {
      type: Array,
      default: () => []
    },
    recentClientsLoading: Boolean
  },
  setup () {
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)

    return {
      userData
    }
  },
  methods: {
    addClient () {
      this.$emit('addEditClientModalShowing', true)
    },
    goToClient (clientId) {
      this.$router.push({ path: '/client_portal/spotlights', query: { clId: clientId } }).catch(() => {})
    }
  },
}
</script>

<style lang="scss" scoped>
.notBarActive {
    margin-bottom: 49px !important;
    background: #d1d1d1;
}
.slide-in {
  &-enter-active {
    transition: opacity .5s linear, transform .5s cubic-bezier(.2,.5,.1,1);
    transition-delay: calc( 0.1s * var(--i) );
  }

  &-enter {
    opacity: 0;
  }

  &-enter { transform: translateX(30px); }
}
</style>
