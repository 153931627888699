<template>
  <div
    class="px-3 px-md-5"
    :class="mobileView ? 'mt-6 pt-3' : ''"
    style="min-height: fit-content"
  >
    <div
      v-if="brandingLoaded === false"
      style="height:80px;"
      class="d-flex align-items-center"
    >
      <h3 class="primary-colour font-weight-bold mr-2">
        Loading
      </h3> <i class="fa fa-spin fa-spinner" />
    </div>

    <div :class="{ 'vMobBrandingTop': mobileView }">
      <!--branding table-->
      <div v-show="addBrandingForm === false && brandingLoaded">
        <div class="d-flex align-items-center justify-content-between mb-1" style="height:80px;">
          <h3 class="primary-colour font-weight-bold">
            Branding
          </h3>
          <div
            v-if="branding.length === 0"
            style="margin-top: 101px;width: 100%;margin-left: -65px;"
            class="text-left grey-text"
          >
            No branding to display. Click <a
              class="primary-colour"
              style="font-weight:bold;"
              @click="addBranding()"
            ><em>here</em></a> to add new branding.
          </div>
          <ExpandableSearchBar
            v-if="branding.length > 0"
            :width="400"
            :max-widthpercentage="50"
            @search="filter = $event"
          />
        </div>

        <b-table
          v-if="branding.length > 0"
          id="branding-table"
          class="text-left tableTh"
          head-variant="dark"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items="branding"
          :per-page="perPage"
          :current-page="currentPage"
          :fields="brandingFields"
          :filter="filter"
          hover
          small
          sort-icon-left
          responsive="sm"
        >
          <template #cell(name)="data">
            <div style="padding-top:12px;margin-left:5px;">
              <span v-show="data.item.Default"><mdb-icon
                                                 v-show="data.item.PrimaryColour === '#ffffff' || data.item.PrimaryColour === '#fff'"
                                                 icon="check-circle"
                                                 :style="{ color: data.item.SecondaryColour }"
                                                 style="font-size:1rem;cursor:pointer;"
                                                 title="Current default branding"
                                               />
                <mdb-icon
                  v-show="data.item.PrimaryColour != '#ffffff' && data.item.PrimaryColour != '#fff'"
                  icon="check-circle"
                  :style="{ color: data.item.PrimaryColour }"
                  style="font-size:1rem;cursor:pointer;"
                  title="Current default branding"
                />
              </span>
              {{ data.item.Name }}
            </div>
          </template>
          <template
            #cell(colours)="data"
            class="d-flex align-items-center"
            style="height:100%"
          >
            <div style="width: 100%;height:100% !important;" class="d-flex align-items-center justify-content-center">
              <i
                v-show="data.item.PrimaryColour === '#ffffff' || data.item.PrimaryColour === '#fff'"
                class="mr-2 mt-2  far fa-circle"
                :style="{ color: data.item.SecondaryColour }"
                style="font-size:1.3rem;cursor:pointer;"
                :title="'Primary colour: ' + data.item.PrimaryColour"
              />
              <i
                v-show="data.item.PrimaryColour != '#ffffff' && data.item.PrimaryColour != '#fff'"
                class="mr-2 mt-2  fa fa-circle"
                :style="{ color: data.item.PrimaryColour }"
                style="font-size:1.3rem;cursor:pointer;"
                :title="'Primary colour: ' + data.item.PrimaryColour"
              />
              <i
                v-show="data.item.SecondaryColour === '#ffffff' || data.item.SecondaryColour === '#fff'"
                class="mt-2 far fa-circle"
                :style="{ color: data.item.PrimaryColour }"
                style="font-size:1.3rem;cursor:pointer;"
                :title="'Secondary colour: ' + data.item.SecondaryColour"
              />
              <i
                v-show="data.item.SecondaryColour != '#ffffff' && data.item.SecondaryColour != '#fff'"
                class="mt-2 fa fa-circle"
                :style="{ color: data.item.SecondaryColour }"
                style="font-size:1.3rem;cursor:pointer;"
                :title="'Secondary colour: ' + data.item.SecondaryColour"
              />
            </div>
          </template>
          <template #cell(actions)="data">
            <div v-if="data.item.ShineGo != true" class="d-flex align-items-center justify-content-end">
              <button
                v-show="data.item.ShineGo != true"
                title="Edit this branding?"
                type="button"
                class="btn btn-primary btn-md"
                style="float:left;padding: 0.5rem 0.6rem;"
                @click="editBranding(data.item)"
              >
                <mdb-icon icon="pencil-alt" />
              </button>
              <mdb-popover
                trigger="click"
                :options="{placement: 'bottom'}"
                style="cursor:pointer;"
                class="grey-text"
              >
                <div slot="body">
                  <h6 style="margin-top:-5px;padding:10px;" @click="showSetAsDefaultModal(data.item.BrandingId, data.item.AccountId)">
                    <a class="ml-1 grey-link"><i class="fa fa-check-circle" /> Set As Default</a>
                  </h6>
                  <h6
                    v-show="data.item.ShineGo != true && data.item.Default == false"
                    style="padding:10px;"
                    @click="showDeleteBrandingModal(data.item.BrandingId)"
                  >
                    <a class="ml-1 grey-link"><i class="fa fa-trash" /> Delete Branding</a>
                  </h6>
                </div>
                <mdb-icon
                  slot="reference"
                  title="More actions"
                  class="ellipsisButtonHover"
                  style="color:#555;font-size:1rem;"
                  icon="ellipsis-v"
                />
              </mdb-popover>
            </div>
            <div v-if="data.item.ShineGo && (userData.iss || userData.rle === 'Admin' || userData.rle === 'Account Group Administrator')" class="d-flex align-items-center justify-content-end">
              <button
                v-show="data.item.ShineGo != true"
                title="Edit this branding?"
                type="button"
                class="btn btn-primary btn-md"
                style="float:left;padding: 0.5rem 0.6rem;"
                @click="editBranding(data.item)"
              >
                <mdb-icon icon="pencil-alt" />
              </button>
              <mdb-popover
                trigger="click"
                :options="{placement: 'bottom'}"
                style="cursor:pointer;"
                class="grey-text"
              >
                <div slot="body">
                  <h6 style="margin-top:-5px;padding:10px;" @click="showSetAsDefaultModal(data.item.BrandingId, data.item.AccountId)">
                    <a class="ml-1 grey-link"><i class="fa fa-check-circle" /> Set As Default</a>
                  </h6>
                  <h6
                    v-show="data.item.ShineGo != true && data.item.Default == false"
                    style="padding:10px;"
                    @click="showDeleteBrandingModal(data.item.BrandingId)"
                  >
                    <a class="ml-1 grey-link"><i class="fa fa-trash" /> Delete Branding</a>
                  </h6>
                </div>
                <mdb-icon
                  slot="reference"
                  title="More actions"
                  class="ellipsisButtonHover"
                  style="color:#555;font-size:1rem;"
                  icon="ellipsis-v"
                />
              </mdb-popover>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-if="branding.length > 5"
          v-model="currentPage"
          class="float-right mt-4"
          :total-rows="rows"
          :per-page="perPage"
          first-number
          last-number
          aria-controls="branding-table"
          prev-text="<"
          next-text=">"
          style="margin-bottom:30px;"
        />
        <div
          v-show="mobileView === false"
          md="2"
          offset-md="10"
        >
          <button
            v-if="branding.length > 0"
            style="float: left;margin-right: 0px;width: 110px;padding: 6px !important;text-align: center;margin-top:25px;"
            title="Add branding"
            class="mdc-button btn"
            @click="addBranding"
          >
            <span class="secondary-colour"><i class="fa fa-plus-circle" /> Add</span>
          </button>
        </div>
        <div class="fixed bottom-0 mobDisplay" style="background: white;width: 100%;border-top: 1px solid rgb(161, 161, 161);z-index: 1001;margin-left: -15px;height: 55px;">
          <div class="row" style="margin-top:5px;">
            <div class="col-sm-12 text-center">
              <button
                v-if="branding.length > 0"
                style="width: 110px;padding: 6px !important;text-align: center;"
                title="Add branding"
                class="mdc-button btn-solid-outline-mob btn"
                @click="addBranding"
              >
                <span class="secondary-colour"><i class="fa fa-plus-circle" /> Add</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--add/edit branding form-->
      <div v-show="addBrandingForm" style="text-align:left;padding:2px;margin-top:80px;min-height:fit-content;">
        <div class="h5 grey-text">
          <span v-show="edit">Edit Branding</span><span v-show="!edit">Add Branding</span>
        </div>
        <validation-observer v-slot="{ invalid }">
          <form @submit.prevent="addEditBranding()">
            <div class="md-form form-sm">
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="Branding Mame"
                rules="required"
              >
                <i class="fas fa-address-card prefix" style="margin-top: 6px;font-size: 1.3rem;" />
                <mdb-input
                  v-model="newBranding.Name"
                  type="text"
                  auto-complete="bname"
                  label="Branding Name"
                  style="margin-left:33px;"
                  size="sm"
                />
                <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                  Please give your branding a name
                </div>
              </validation-provider>
            </div>
            <div v-show="newBranding.Logo != ''" class="md-form form-sm">
              <i class="fas fa-image prefix" style="margin-top: 25px;font-size: 1.3rem;cursor:pointer" />
              <div style="margin-left:33px;font-size:0.75rem;margin-top:-10px;margin-bottom:10px;color:#757575;" class="active mr-5">
                Logo<br>
              </div>
              <img
                :src="newBranding.Logo"
                label="Logo"
                style="margin-left:33px;width:80px;background:#f1f1f1;padding:5px;border-radius:5px;"
              >
            </div>
            <div
              v-if="newBranding.Logo == ''"
              class="md-form form-sm"
              @click="launchLogoPicker"
            >
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="file"
                rules="required"
              >
                <i class="fas fa-upload prefix" style="margin-top: 6px;font-size: 1.3rem;cursor:pointer" />
                <input
                  type="text"
                  class="form-control"
                  :placeholder="logoText"
                  :value="file.name"
                  readonly
                >
                <input
                  ref="file"
                  v-uploader
                  type="file"
                  style="display:none"
                  accept=".jpg, .jpeg, .png"
                >
                <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                  You must a logo to upload with your branding
                </div>
              </validation-provider>
            </div>
            <div
              v-if="newBranding.Logo != ''"
              class="md-form form-sm"
              @click="launchLogoPicker"
            >
              <i class="fas fa-upload prefix" style="margin-top: 6px;font-size: 1.3rem;cursor:pointer" />
              <input
                type="text"
                class="form-control"
                :placeholder="logoText"
                :value="file.name"
                readonly
              >
              <input
                ref="file"
                v-uploader
                type="file"
                style="display:none"
                accept=".jpg, .jpeg, .png"
              >
            </div>
            <div class="md-form form-sm">
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="Primary colour"
                rules="required"
                class="d-flex align-items-center"
              >
                <i class="fas fa-palette prefix" style="margin-top: 25px;font-size: 1.3rem;" />
                <mdb-input
                  v-model="newBranding.PrimaryColour"
                  type="color"
                  auto-complete="bpcol"
                  label="Primary colour"
                  style="margin-left:33px;border-bottom:solid 1px #e0e0e0;width:fit-content;display:inline-block"
                  size="sm"
                />
                <span
                  v-if="newBranding.PrimaryColour != ''"
                  class="mx-auto font-weight-bold"
                  style="color:#555;float: left;margin-left: 10px !important;"
                >{{ newBranding.PrimaryColour }}</span>
                <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                  You must select your primary colour
                </div>
              </validation-provider>
            </div>
            <div class="md-form form-sm" style="margin-top:-20px;">
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="Secondary colour"
                rules="required"
                class="d-flex align-items-center"
              >
                <i class="fas fa-palette prefix" style="margin-top: 25px;font-size: 1.3rem;" />
                <mdb-input
                  v-model="newBranding.SecondaryColour"
                  type="color"
                  auto-complete="bscol"
                  label="Secondary colour"
                  style="margin-left:33px;border-bottom:solid 1px #e0e0e0;width:fit-content;display:inline-block"
                  size="sm"
                />
                <span
                  v-if="newBranding.SecondaryColour != ''"
                  class="mx-auto  font-weight-bold"
                  style="color:#555;margin-left: 10px !important;"
                >{{ newBranding.SecondaryColour }}</span>
                <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                  You must select your secondary colour
                </div>
              </validation-provider>
            </div>
            <div class="md-form form-sm" style="height:180px;">
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="Colour Scheme"
                rules="required|colour-scheme"
              >
                <label style="margin-top:-35px;font-size:12px;">Select a colour scheme</label>
                <i class="fas fa-file-image prefix" style="margin-top: 6px;font-size: 1.3rem;" />
                <mdb-select
                  v-model="colourSchemes"
                  placeholder="Colour scheme"
                  style="margin-left:33px;"
                  size="sm"
                />
                <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                  Please select a scheme
                </div>
              </validation-provider>
              <mdb-btn
                class="float-left btn-outline"
                size="sm"
                style="width:100px;"
                @click="addBrandingForm = false"
              >
                CANCEL
              </mdb-btn>
              <mdb-btn
                v-show="!buttonClick"
                type="submit"
                :disabled="invalid || colourScheme.length < 1"
                class="float-right"
                size="sm"
                style="width:100px;"
              >
                <span v-show="edit">SAVE</span><span v-show="!edit">ADD</span>
              </mdb-btn>
              <mdb-btn
                v-show="buttonClick"
                class="float-right"
                size="sm"
                style="width:100px;"
              >
                <mdb-icon icon="fa fa-pulse fa-spinner" />
              </mdb-btn>
            </div>
          </form>
        </validation-observer>
      </div>

      <!--set as default modal-->
      <mdb-modal
        id="setAsDefaultModal"
        :show="setAsDefaultModal"
        @close="setAsDefaultModal = false"
      >
        <mdb-modal-header>
          <mdb-modal-title>SET AS DEFAULT</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body :class="{ 'modalHeight': mobileView }">
          <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
            Are you sure you want to set this branding as the default scheme?
          </p>
        </mdb-modal-body>
        <mdb-modal-footer>
          <mdb-btn
            color="primary"
            size="sm"
            style="width:100px;"
            @click.native="setAsDefault"
          >
            YES
          </mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>

      <!--delete branding modal-->
      <mdb-modal
        id="deleteBrandingModal"
        :show="deleteBrandingModal"
        @close="deleteBrandingModal = false"
      >
        <mdb-modal-header>
          <mdb-modal-title>DELETE BRANDING</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body :class="{ 'modalHeight': mobileView }">
          <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
            Are you sure you want to delete this branding?
          </p>
        </mdb-modal-body>
        <mdb-modal-footer>
          <mdb-btn
            color="primary"
            size="sm"
            style="width:100px;"
            @click.native="deleteBranding"
          >
            YES
          </mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>

      <!--error modal-->
      <mdb-modal
        id="errorModal"
        :show="errorModal"
        @close="errorModal = false"
      >
        <mdb-modal-header>
          <mdb-modal-title>{{ errorModalTitle.toUpperCase() }}</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body :class="{ 'modalHeight': mobileView }">
          <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
            <span v-html="error" />
          </p>
        </mdb-modal-body>
        <mdb-modal-footer>
          <mdb-btn
            color="primary"
            size="sm"
            style="width:100px;"
            @click.native="errorModal = false"
          >
            OK
          </mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { extend } from 'vee-validate'
import accountService from '@/api/services/account.service'
import adminService from '@/api/services/admin.service'
import logoutService from '@/api/services/logout.service'
import { logout } from '@/methods/auth.js'
import { useMobile } from '@/composables/useMobile'

import { mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModal, mdbBtn } from 'mdbvue'
import ExpandableSearchBar from '@/components/UI/expandableSearch.vue'

extend('colour-scheme', {
  validate: colourScheme => { return colourScheme.length > 0 },
  lazy: true,
  message: 'Please choose a scheme'
})

export default {
  name: 'Branding',
  directives: {
    uploader: {
      bind (el, binding, vnode) {
        el.addEventListener('change', e => {
          if (e.target.files[0] !== undefined) {
            vnode.context.file = e.target.files[0]
          }
        })
      }
    }
  },
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbBtn,
    ExpandableSearchBar
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    return {
      authData,
      userData,
      mobileView
    }
  },
  data () {
    return {
      brandingFields: [
        { key: 'Name', label: '  Reference', tdClass: 'col45 sortIcon', sortable: true },
        { key: 'Colours', label: 'Scheme', thClass: 'text-center', tdClass: 'col30', sortable: false },
        { key: 'Actions', label: '', tdClass: 'col25', sortable: false }
      ],
      sortBy: '',
      sortDesc: false,
      perPage: 8,
      brandingLoaded: false,
      currentPage: 1,
      error: '',
      filter: null,
      buttonClick: false,
      filterOn: [],
      errorModal: false,
      errorModalTitle: '',
      errorModalContent: '',
      colourSchemes: [
        { text: 'Default', value: 1, selected: true },
        { text: 'Light', value: 2 },
        { text: 'Dark', value: 3 },
        { text: 'Primary Default', value: 4 },
        { text: 'Primary Light', value: 5 },
        { text: 'Primary Dark', value: 6 }
      ],
      branding: {
        AccountName: '',
        AltBgColour: '',
        AltLkColor: '',
        HeaderColour: '',
        LinkColour: '',
        BrandingId: 0,
        Logo: '',
        Name: '',
        PrimaryColour: '#fe7000',
        SecondaryColour: '#ffffff',
        Scheme: 1,
        Default: false
      },
      newBranding: {
        AccountId: 0,
        AccountName: '',
        BrandingId: 0,
        Logo: '',
        Name: '',
        PrimaryColour: '',
        SecondaryColour: '',
        Scheme: this.colourScheme,
        Default: false
      },
      deleteBrandingModal: false,
      setAsDefaultModal: false,
      addBrandingForm: false,
      file: '',
      edit: false,
      logoText: 'Select logo to upload',
      brandingId: 0,
      accountId: 0
    }
  },
  computed: {
    rows () {
      return this.branding.length
    },
    sortOptions () {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
    colourScheme: function () {
      var c = ''
      this.colourSchemes.forEach(element => {
        if (element.selected === true) {
          c = element.value
        }
      })
      return c
    },
    dateFormat: {
      get () {
        return this.$material.locale.dateFormat
      },
      set (val) {
        this.$material.locale.dateFormat = val
      }
    }
  },
  watch: {
    file (val) {
      this.$emit('file-chosen', val)
    }
  },
  created () {
    this.getBranding()
  },
  methods: {
    logout,
    getBranding () {
      accountService.getBranding().then((response) => {
        this.branding = response.data
        setTimeout(() => { this.brandingLoaded = true }, 3000)
        this.buttonClick = false
      }).catch((error) => {
        if (error.response.data.Message === 'Authorization has been denied for this request.') {
          this.doLogOut()
        }
        setTimeout(() => { this.brandingLoaded = true }, 3000)
        this.errorodalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
        this.buttonClick = false
      })
    },
    showDeleteBrandingModal (id) {
      this.brandingId = id
      this.deleteBrandingModal = true
    },
    deleteBranding () {
      accountService.deleteBranding(this.brandingId).then(() => {
        this.getBranding()
        this.brandingId = 0
        this.deleteBrandingModal = false
      }).catch((error) => {
        this.brandingId = 0
        this.deleteBrandingModal = false
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
    },
    showSetAsDefaultModal (bid, aid) {
      this.brandingId = bid
      this.accountId = aid
      this.setAsDefaultModal = true
    },
    setAsDefault () {
      accountService.setAsDefault(this.brandingId).then((response) => {
        this.branding = response.data
        this.brandingLoaded = true
        adminService.switchAccount(this.accountId).then((response) => {
          this.userData = response.data
          this.setAsDefaultModal = false
        })
      }).catch((error) => {
        this.brandingLoaded = true
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
    },
    addBranding () {
      this.addBrandingForm = true
      this.newBranding = {
        AccountId: 0,
        AccountName: '',
        BrandingId: 0,
        Logo: '',
        Name: '',
        PrimaryColour: '',
        SecondaryColour: '',
        Scheme: this.colourScheme,
        Default: false
      }
      this.newBranding.AccountId = this.userData.aid
      this.newBranding.PrimaryColour = this.userData.lbl.PrimaryColour
      this.newBranding.SecondaryColour = this.userData.lbl.SecondaryColour
      this.edit = false
    },
    editBranding (br) {
      this.addBrandingForm = true
      this.newBranding.AccountId = br.AccountId
      this.newBranding.AccountName = br.AccountName
      this.newBranding.BrandingId = br.BrandingId
      this.newBranding.Logo = br.Logo
      this.newBranding.Name = br.Name
      this.newBranding.PrimaryColour = br.PrimaryColour
      this.newBranding.SecondaryColour = br.SecondaryColour
      this.newBranding.Scheme = br.Scheme
      this.newBranding.Default = br.Default
      this.edit = true
      this.logoText = 'Select a new logo to upload using a 2:1 ratio (width x height)'
      if (br.Scheme === 'Default') {
        this.colourSchemes = [
          { text: 'Default', value: 1, selected: true },
          { text: 'Light', value: 2 },
          { text: 'Dark', value: 3 },
          { text: 'Primary Default', value: 4 },
          { text: 'Primary Light', value: 5 },
          { text: 'Primary Dark', value: 6 }
        ]
      }
      if (br.Scheme === 'Light') {
        this.colourSchemes = [
          { text: 'Default', value: 1 },
          { text: 'Light', value: 2, selected: true },
          { text: 'Dark', value: 3 },
          { text: 'Primary Default', value: 4 },
          { text: 'Primary Light', value: 5 },
          { text: 'Primary Dark', value: 6 }
        ]
      }
      if (br.Scheme === 'Dark') {
        this.colourSchemes = [
          { text: 'Default', value: 1 },
          { text: 'Light', value: 2 },
          { text: 'Dark', value: 3, selected: true },
          { text: 'Primary Default', value: 4 },
          { text: 'Primary Light', value: 5 },
          { text: 'Primary Dark', value: 6 }
        ]
      }
      if (br.Scheme === 'Primary Default') {
        this.colourSchemes = [
          { text: 'Default', value: 1 },
          { text: 'Light', value: 2 },
          { text: 'Dark', value: 3 },
          { text: 'Primary Default', value: 4, selected: true },
          { text: 'Primary Light', value: 5 },
          { text: 'Primary Dark', value: 6 }
        ]
      }
      if (br.Scheme === 'Primary Light') {
        this.colourSchemes = [
          { text: 'Default', value: 1 },
          { text: 'Light', value: 2 },
          { text: 'Dark', value: 3 },
          { text: 'Primary Default', value: 4 },
          { text: 'Primary Light', value: 5, selected: true },
          { text: 'Primary Dark', value: 6 }
        ]
      }
      if (br.Scheme === 'Primary Dark)') {
        this.colourSchemes = [
          { text: 'Default', value: 1 },
          { text: 'Light', value: 2 },
          { text: 'Dark', value: 3 },
          { text: 'Primary Default', value: 4 },
          { text: 'Primary Light', value: 5 },
          { text: 'Primary Dark', value: 6, selected: true }
        ]
      }
    },
    launchLogoPicker () {
      this.$refs.file.click()
    },
    addEditBranding () {
      this.buttonClick = true
      this.isClicked = true
      var formData = new FormData()
      formData.append('AccountName', this.newBranding.AccountName)
      if (this.edit) {
        formData.append('BrandingId', this.newBranding.BrandingId)
      }
      formData.append('AccountId', this.userData.aid)
      formData.append('Logo', this.newBranding.Logo)
      formData.append('Name', this.newBranding.Name)
      formData.append('PrimaryColour', this.newBranding.PrimaryColour)
      formData.append('SecondaryColour', this.newBranding.SecondaryColour)
      formData.append('Scheme', this.colourScheme)
      formData.append('Default', this.newBranding.Default)
      formData.append('file', this.file)
      accountService.addEditBranding(formData).then(() => {
        this.file = ''
        this.formData = {
          AccountId: this.userData.aid,
          AccountName: '',
          BrandingId: 0,
          Logo: '',
          Name: '',
          PrimaryColour: '',
          SecondaryColour: '',
          Scheme: 1
        }
        this.getBranding()
        this.addBrandingForm = false
        this.$refs.file.value = null
        adminService.switchAccount(this.newBranding.AccountId).then((response) => {
          this.userData = response.data
          this.setAsDefaultModal = false
        })
      }).catch((error) => {
        this.setAsDefaultModal = false
        this.$refs.file.value = null
        this.brandingLoaded = true
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
      this.buttonClick = false
    },
    errorAlert (error,) {
      try {
        if (error.response.data != null) {
          if (error.response.data.Message != null) {
            this.errorModal = true
            this.error = error.response.data.Message
            if (this.error === '') { this.error = 'Sorry, an error has occured!' }
          } else if (error.response.data.error_description != null) {
            this.errorModal = true
            this.error = error.response.data.error_description
            if (this.error === '') { this.error = 'Sorry, an error has occured!' }
          } else {
            this.errorModal = true
            this.error = error.response.data
            if (this.error === '') { this.error = 'Sorry, an error has occured!' }
          }
        } else {
          this.errorModal = true
          this.error = 'Sorry an error has occurred!'
        }
        if (error.response.data.Message === 'Authorization has been denied for this request.') {
          this.errorModal = true
          this.error = 'Authorization has been denied for this request [br].'
          this.doLogOut()
        }
      } catch {
        this.errorModal = true
        this.error = 'Sorry an error has occurred!'
      }
    },
    doLogOut () {
      logoutService.logout().then(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      }).catch(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      })
    }
  }
}
</script>

<style>
  .page-link {
    border: 1px solid #dee2e6 !important;
  }
  table.table thead th {
    outline: none;
    cursor: pointer;
  }
  .form-control:focus {
    border-color: #d0d0d0;
    box-shadow: none;
  }
  .hidden_header {
    display: none;
  }
  .table td, .table th {
    padding: .0rem;
    vertical-align: top;
    border-top: none;
  }
  table.table-sm th, table.table-sm td {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  input[type="color"].form-control.form-control-sm, .input-group-sm input[type="color"].form-control {
    border: none;
    width: 120px;
    height: 40px;
    margin-left: -7px;
    padding-top: 5px;
    margin-bottom: 0px;
  }
  .color {
    color:red;
  }
 .popover[x-placement^=left] {
     width: 150px;
  }
  .vMobBrandingTop {
    margin-top:-15px !important;
  }
</style>

<style lang="scss" scoped>
  .mobileTopSectionsBranding {
    margin-top:-10px !important;
  }
  .mobileTopSectionBrandingSpinner {
    margin-top:-9px !important;
  }
  .brandingLoadingMargins {
    margin-top: 40px !important;
    margin-left: 2px !important;
    margin-bottom: -13px;
  }
</style>
