<template>
  <div>
    <div
      v-if="userData.aut"
      class="d-none d-md-flex align-items-center"
      style="width: 100%;position: fixed;z-index:1004;height:80px;"
    >
      <div
        class="py-3 ml-3 cp"
        style="height:100%"
        @click="goToRoute('/candidates')"
      >
        <img :src="userData.lbl.Logo" style="height:100%;object-fit:cover">
      </div>
      <!--
      <div
        v-if="userData.mag && userData.noa > 1"
        class="alt-link-colour"
        style="margin-left:20px;font-weight: bold;font-size:smaller;"
      >
        Currently accessing: {{ userData.ane }}
      </div>
      -->
      <div class="ml-auto">
        <a
          v-if="userData.mag && userData.noa > 1"
          class=" ml-auto alt-link-colour"
          :class="{ 'topNavMarginRight': mobileView == true }"
          style="margin-right:50px;"
          size="sm"
        ><i class="fas fa-home" /> <u> {{ userData.ane }}</u></a>
        <a
          v-if="userData.mag || userData.noa > 1 || userData.rle === 'Super User'"
          class=" ml-auto alt-link-colour"
          :class="{ 'topNavMarginRight': mobileView == true }"
          style="margin-right:50px;"
          size="sm"
          @click="goToRoute('/myaccounts')"
        ><i class="fas fa-sync" /> <u>Switch Account</u></a>
        <a
          v-if="userData.rle === 'Super User'"
          class=" ml-auto alt-link-colour"
          :class="{ 'topNavMarginRight': mobileView == true }"
          style="margin-right:50px;"
          size="sm"
          @click="goToRoute('/admin')"
        ><mdb-icon icon="tools" /> <u>Admin</u></a>
        <a
          class="alt-link-colour"
          :class="{ 'topNavMarginRight': mobileView == true }"
          style="margin-right:40px;"
          size="sm"
          @click="$emit('logOut');loggingOut = true"
        >
          <span v-if="!loggingOut"><mdb-icon icon="sign-out-alt" /> <u>Logout</u></span>
          <span v-else><mdb-icon icon="spinner" /> <u>Logout</u></span>
        </a>
      </div>
    </div>
    <div
      v-if="userData.aut"
      class="row nav-colour outer-div fixed w-full top-0 z-50 bgColor"
      :class="{ 'height': mobileView == true, 'nav-colour': mobileView == true }"
      style="height:80px;border-bottom:solid 1px;z-index:100 !important;box-shadow: 0px 5px 8px -4px #333;"
    >
      <div class="mobDisplay bannerHeight" style="width:100%;height:89px;border-bottom:1px solid #c0c0c0;box-shadow: #888 0px 3px 6px 0px">
        <div v-mdb-resize:start="checkWidth">
          <mdb-side-nav-2
            v-model="show"
            :data="navigation"
            right
            :over="over"
            :sidenav-class="['nav-colour']"
            push
            @setOffset="setOffset"
          >
            <div slot="header" style="margin-top:85px;" />
            <mdb-navbar
              slot="nav"
              tag="div"
              position="top"
              color="nav-colour"
              :style="{ zIndex: '10000', cursor: 'pointer', boxShadow: 'none', padding: '.8rem 1rem !important' }"
            >
              <a @click="goToRoute('/candidates')"><img :src="userData.lbl.Logo" class="mobile-logo img-fluid"></a>
              <div class="cursor-pointer" @click="show = !show">
                <mdb-icon
                  v-if="!show"
                  icon="bars"
                  class="alt-link-colour"
                  style="position: fixed;right: 30px;top: 31px;font-size:1.5rem;"
                />
                <mdb-icon
                  v-else
                  icon="times"
                  class="alt-link-colour"
                  style="position: fixed;right: 30px;top: 31px;font-size:1.5rem;"
                />
              </div>
            </mdb-navbar>
            <div slot="content" style="margin-top:-5px;z-index:1002;padding-bottom:200px;">
              <ul class="list-unstyled">
                <li data-v-c7b25fd4="">
                  <a
                    data-v-c7b25fd4=""
                    class="mdbvue-sidenav__item alt-link-colour"
                    href="https://support.shineinterview.com/hc/en-us"
                    target="_blank"
                  >
                    <div class="text-center" style="min-width: 40px;"><i class="fas fa-question-circle" /></div> Help
                  </a>
                </li>
                <li v-if="userData.iss" data-v-c7b25fd4="">
                  <a
                    data-v-c7b25fd4=""
                    class="mdbvue-sidenav__item alt-link-colour"
                    @click="goToRoute('/admin')"
                  >
                    <div class="text-center" style="min-width: 40px;"><i class="fas fa-lock text-" /></div> Admin
                  </a>
                </li>
                <li v-if="userData.mag" data-v-c7b25fd4="">
                  <a
                    data-v-c7b25fd4=""
                    class="mdbvue-sidenav__item alt-link-colour"
                    @click="goToRoute('/myaccounts')"
                  >
                    <div class="text-center" style="min-width: 40px;"><i class="fas fa-sync text-" /></div> Switch Account
                  </a>
                </li>
                <li data-v-c7b25fd4="" style="padding-bottom:20px;margin-top:-5px;">
                  <a
                    data-v-c7b25fd4=""
                    target="_blank"
                    class="mdbvue-sidenav__item alt-link-colour"
                    @click="$emit('logOut')"
                  >
                    <div class="text-center" style="min-width: 40px;"><i class="fas fa-sign-out-alt text-" /></div> Logout
                  </a>
                </li>
                <li data-v-c7b25fd4="" style="color: rgb(85, 85, 85);background: rgb(192, 192, 192);position: fixed;bottom: 0px;width: 240px;margin-left:-8px;padding:10px;">
                  <div style="margin-top:1px;font-size:10px;font-weight:bold;">
                    Shine Interview<br>Version {{ VERSION }}
                  </div>
                </li>
              </ul>
            </div>
          </mdb-side-nav-2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import { VERSION } from '@/common/config.js'

import { mdbNavbar, mdbSideNav2, mdbIcon, mdbResize } from 'mdbvue'

export default {
  name: 'NavBar',
  components: {
    mdbNavbar,
    mdbSideNav2,
    mdbIcon
  },
  directives: {
    mdbResize
  },
  setup () {
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)
    const { mobileView } = useMobile()
    
    return {
      userData,
      mobileView
    }
  },
  data () {
    return {
      show: false,
      isActive: true,
      over: false,
      navigation: [],
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      loggingOut: false
    }
  },
  computed: {
    VERSION () { return VERSION }
  },
  watch: {
    $route: {
      handler () {
        this.show = false
      }
    }
  },
  methods: {
    removeAlert () {
      this.isActive = !this.isActive
      this.userData.inf = 0
    },
    checkWidth () {
      this.over = window.innerWidth < 900
      this.buildNav()
    },
    goToRoute (item) {
      if (item !== 'none') {
        if (this.$route.path !== item) {
          this.$router.push(item).catch(() => { })
        } else {
          this.$router.go(this.$router.currentRoute)
        }
      }
      this.show = false
    },
    setOffset (value) {
      if (!this.width) return
      this.$refs.layout.style.width = this.width + value + 'px'
      this.$refs.layout.style.paddingLeft = value + 'px'
    },
    buildNav () {
      this.navigation = []
      if (this.userData.ncn) {
        this.navigation.push({ name: 'Candidates', icon: 'user', href: '/#/candidates' })
      }
      if (this.userData.nrl) {
        this.navigation.push({ name: 'Roles', icon: 'suitcase', href: '/#/roles' })
      }
      if (this.userData.nvl) {
        this.navigation.push({
          name: 'Video Library',
          icon: 'photo-video',
          children: [
            {
              name: 'Organisation Videos',
              href: '/#/videolibrary?mm=Organisation'
            },
            {
              name: 'Job Videos',
              href: '/#/videolibrary?mm=Job'
            },
            {
              name: 'Shareable Videos',
              href: '/#/videolibrary?mm=Shareable'
            },
            {
              name: 'Outro Videos',
              href: '/#/videolibrary?mm=Outro'
            },
            {
              name: 'Question Videos',
              href: '/#/videolibrary?mm=Question'
            },
            {
              name: 'Candidate Videos',
              href: '/#/videolibrary?mm=Candidate'
            },
            {
              name: 'All Videos',
              href: '/#/videolibrary'
            }
          ]
        })
      }
      //if (this.userData.ncp) {
        //this.navigation.push({ name: 'Client Portal', icon: 'sun', href: '/#/client_portal/clients' })
      //}
      if (this.userData.nrp && (this.userData.rle === 'Admin' || this.userData.rle === 'Manager' || this.userData.iss || this.userData.rle === 'Account Group Administrator')) {
        this.navigation.push({ name: 'Reports', icon: 'chart-line', href: '/#/reports' })
      }
      if (this.userData.nmn) {
        this.navigation.push({ name: 'Meet Now', icon: 'video', href: '/#/meetnow' })
      }
      //VCVX
      //if (this.userData.nin) {
        //this.navigation.push({ name: 'Shine Intros', icon: 'tv', href: '/#/shineintros' })
      //}
      if (this.userData.nnt) {
        this.navigation.push({ name: 'Notifications', icon: 'bell', href: '/#/notifications' })
      }
      this.navigation.push({
        name: 'Settings',
        icon: 'cog'
      })
      if (this.userData.rle === 'Admin' || this.userData.iss || this.userData.rle === 'Account Group Administrator') {
        this.navigation.push({ name: '  Account', href: '/#/settings/account' })
      }
      if (this.userData.rle === 'Admin' || this.userData.iss || this.userData.rle === 'Account Group Administrator') {
        this.navigation.push({ name: '  Branding', href: '/#/settings/branding' })
      }
      if (this.userData.rle === 'Admin' || this.userData.iss || this.userData.rle === 'Account Group Administrator') {
        this.navigation.push({ name: '  Custom Profile Fields', href: '/#/settings/custom_fields' })
      }
      if (this.userData.rle === 'Admin' || this.userData.iss || this.userData.rle === 'Account Group Administrator') {
        this.navigation.push({ name: '  Event Subscriptions', href: '/#/settings/eventsubscriptions' })
      }

      this.navigation.push({ name: '  My Settings', href: '/#/settings/mysettings' })

      if (this.userData.pub && (this.userData.rle === 'Admin' || this.userData.iss || this.userData.rle === 'Account Group Administrator')) {
        this.navigation.push({ name: '  Public API', href: '/#/settings/publicapi' })
      }
      if ((this.userData.nrl || this.userData.ncn) && (this.userData.rle === 'Admin' || this.userData.rle === 'Manager' || this.userData.iss || this.userData.rle === 'Account Group Administrator')) {
        this.navigation.push({ name: '  Question Sets', href: '/#/settings/questionsets' })
      }
      if (this.userData.nrl || this.userData.ncn) {
        this.navigation.push({ name: '  Quick Share', href: '/#/settings/quickshare' })
      }
      if (this.userData.nsc) {
        this.navigation.push({ name: '  Scoring', href: '/#/settings/scoring/templates/all' })
      }
      if (this.userData.nrl) {
        this.navigation.push({ name: '  Self Registration', href: '/#/settings/selfregistration' })
      }
      //if (this.userData.nrl && (this.userData.rle === 'Admin' || this.userData.rle === 'Manager' || this.userData.iss || this.userData.rle === 'Account Group Administrator')) {
        //this.navigation.push({ name: '  SMS Text', href: '/#/settings/stocktext' })
      //}
      //if (this.userData.iss && this.userData.nsv) {
        //this.navigation.push({ name: '  Surveys', href: '/#/surveys/list' })
      //}
      if ((this.userData.rle === 'Manager' && this.userData.aid !== 934) || this.userData.rle === 'Admin' || this.userData.iss || this.userData.rle === 'Account Group Administrator') {
        this.navigation.push({ name: '  Users', href: '/#/settings/users' })
      }
      //VCVX
      //if (this.userData.ncn) {
        //this.navigation.push({ name: '  Video CV', href: '/#/settings/videocv' })
      //}
      //this.navigation.push({
        //name: 'Help',
        //icon: 'question-circle',
        //children: [
          //{
            //name: 'Getting Started',
            //href: '/#/help?f=1'
          //},
          //{
            //name: 'Did You Know',
            //href: '/#/help?f=2'
          //},
          //{
            //name: 'User Guides',
            //href: '/#/help?f=3'
          //},
          //{
            //name: 'Candidates',
            //href: '/#/help?f=4'
          //},
          //{
            //name: 'Roles',
            //href: '/#/help?f=5'
          //},
          //{
            //name: 'Technical',
            //href: '/#/help?f=6'
          //},
          //{
            //name: 'All Help Articles',
            //href: '/#/help?f=0'
          //}
        //]
      //})
    }
  }
}
</script>
