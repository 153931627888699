<template>
  <div style="position:relative;">
    <div class="d-flex align-items-center">
      <mdb-icon v-if="!loading && !listOpen" icon="search" />
      <i v-if="loading" class="fa fa-spinner fa-pulse" />
      <i
        v-if="listOpen && !loading"
        class="fa fa-times cp"
        @click="listOpen = false"
      />
      <Search
        class="ml-3"
        :searchTriggersAPICall="true"
        :place-holder="searchPlaceHolder"
        :overrideSearch="overrideSearch"
        @search="assignSearch"
      />
    </div>
    <div
      v-if="listOpen && !loading"
      class="typeAheadResults"
    >
      <ul>
        <li
          v-for="(result, i) in results"
          :key="i"
          @click="select(result)"
        >
          <!--<img
            v-if="result[searchResultsImageFieldName]"
            :src="result[searchResultsImageFieldName]"
            class="mr-2"
            style="max-width: 50px;"
          >-->
          <span>{{ result[searchResultsNameFieldName] }}</span>
        </li>
      </ul>
    </div>
    <div
      class="text-left d-flex align-item-center flex-wrap"
    >
      <span class="grey-text text-left text-nowrap mr-4">{{ selectedValueText }}</span>
      <div
        v-for="(result, index) in searchValuesSelected"
        :key="index"
        class="cp mr-4"
      >
        <!--<img
          v-if="result[searchResultsImageFieldName]"
          :src="result[searchResultsImageFieldName]"
          class="mr-2"
          style="max-width: 50px;"
        >-->
        <span>{{ result[searchResultsNameFieldName] }}</span>
        <FontAwesomeIcon
          class="ml-1"
          icon="fa-solid fa-times-circle"
          @click="removeSearch(result[searchResultsIdFieldName])"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue-demi'
import Search from './searchInput.vue'
import { useStatus } from '@/pinia'

export default {
  name: 'SearchWithDropdownSelect',
  components: { Search },
  props: {
    searchFunction: {
      type: Function,
      default: () => {}
    },
    searchResultsIdFieldName: {
      type: String,
      default: ''
    },
    searchResultsImageFieldName: {
      type: String,
      default: ''
    },
    searchResultsNameFieldName: {
      type: String,
      default: ''
    },
    selectedValueText: {
      type: String,
      default: ''
    },
    isSingleSelectDropdown: {
      type: Boolean,
      default: true
    },
    searchPlaceHolder: {
      type: String,
      default: ''
    },
    preSelected: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const { showGenericErrorModal } = useStatus()

    const listOpen = ref(false)
    const loading = ref(false)
    const results = ref([])
    const overrideSearch = ref(1)
    const searchValuesSelected = ref(props.preSelected)

    const search = ref('')
    const getSearch = async () => {
      loading.value = true
      listOpen.value = false
      results.value = []
      try {
        const res = await props.searchFunction(search.value)
        results.value = res.data.length > 0 ? res.data : [{ Id: -1, Name: 'No Results' }]
        listOpen.value = true
      } catch {
        showGenericErrorModal({ showing: true })
      }
      setTimeout(() => {
        loading.value = false
      }, 1000)
    }
    watch(search, () => {
      if (search.value && search.value.length > 2) {
        getSearch()
      }
    })

    const removeSearch = id => {
      searchValuesSelected.value = searchValuesSelected.value.filter(res => res[props.searchResultsIdFieldName] != id)
      emit('newSelect', searchValuesSelected.value)
      overrideSearch.value ++
    }

    const assignSearch = (searchTerm) => {
      search.value = searchTerm
    }

    const select = (value) => {
      listOpen.value = false
      search.value = ''
      if (value.Id == -1) return
      if (props.isSingleSelectDropdown) {
        searchValuesSelected.value = [value]
      } else {
        searchValuesSelected.value.push(value)
      }
      emit('newSelect', searchValuesSelected.value)
      overrideSearch.value ++
    }

    return {
      assignSearch,
      select,
      listOpen,
      loading,
      results,
      searchValuesSelected,
      removeSearch,
      overrideSearch
    }
  }
}
</script>

<style>
.typeAheadResults {
  display: flex;
  background: white;
  width: 100%;
  position: absolute;
  justify-content: center;
  z-index: 1;
}
.typeAheadResults ul {
  width: 100%;
  padding: 0px;
}
.typeAheadResults li {
  padding: 5px;
  border-bottom: 1px dotted #fff;
  display: flex;
  align-items: center;
}
.typeAheadResults li:hover {
  background-color: rgba(221, 221, 221, .5);
  /* background-color: red; */
  cursor: pointer;
}
.typeAheadResults img {
  width: 30px;
  border-radius: 50%;
}
.typeAheadResults li span {
  display: inline-block;
}
</style>
