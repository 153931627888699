<template>
  <div style="overflow-x:hidden;" :style="{'padding-top': mobileView ? '50px' : '0'}">
    <mdb-btn
      v-if="saved"
      size="sm"
      class="m-0 ml-auto btn btn-outline px-1"
      @click="goToListPage()"
    >
      BACK
    </mdb-btn>
    <mdb-btn
      v-else
      size="sm"
      class="m-0 ml-auto btn btn-outline px-1"
      @click="saveSurvey()"
    >
      POPULATE RN DATA
    </mdb-btn>
    MUST BE LOGGED IN TO THE RN ACCOUNT - LIVE OR UAT - FOR THIS TO WORK!!!
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import surveyService from '@/api/services/survey.service.js'

export default {
  name: 'TempName',
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()
    const { mobileView } = useMobile()

    return {
      authData,
      showGenericErrorModal,
      mobileView
    }
  },
  data () {
    return {
      section: 'add_edit_survey',
      saved: false,
      survey: {
        GoSurveyId: 0,
        BaseSurveyId: 0,
        Header: 'Royal Navy Pre-Recorded Interview Survey',
        SurveyCompletedText: 'Thanks for taking the time to answer our survey - your feedback is greatly appreciated.',
        SurveyDelivery: 'Email',
        SurveyDeadline: 30,
        ExpiredSurveyMessage: 'We would still welcome any feedback you may have regarding the Pre-Recorded Interview process. You can provide this by emailing rn.aib@gov.sscl.com, however please be advised this will not be anonymous.',
        OneSurveyEntryPerCandidate: true,
        FormBuilderElementList: [
          {
            GoSurveyFormElementId: 0,
            FormElementType: 'FourOptions',
            QuestionText: 'I received sufficient information about what to expect from the Pre-Recorded Interview.',
            IsMandatory: true,
            AdditionalComments: true
          },
          {
            GoSurveyFormElementId: 0,
            FormElementType: 'FourOptions',
            QuestionText: 'I understood what was expected of me during the Pre-Recorded Interview.',
            IsMandatory: true,
            AdditionalComments: true
          },
          {
            GoSurveyFormElementId: 0,
            FormElementType: 'FourOptions',
            QuestionText: 'I understood where I could seek support and guidance if I needed it throughout the Pre-Recorded Interview process.',
            IsMandatory: true,
            AdditionalComments: true
          },
          {
            GoSurveyFormElementId: 0,
            FormElementType: 'FourOptions',
            QuestionText: 'The Pre-Recorded Interview process was inclusive of my needs.',
            IsMandatory: true,
            AdditionalComments: true
          },
          {
            GoSurveyFormElementId: 0,
            FormElementType: 'TextArea',
            QuestionText: 'Describe your experience of the Pre-Recorded Interview',
            IsMandatory: false,
            AdditionalComments: false
          }
        ]
      }
    }
  },
  methods: {
    goToListPage () {
      this.$emit('setSection', { section: 'list' })
    },
    addStringifiedData () {
      const one = ['Strongly Disagree', 'Disagree', 'Agree', 'Strongly Agree']
      const thisone = JSON.stringify(one)
      this.survey.FormBuilderElementList = this.survey.FormBuilderElementList.map(e => e.FormElementType === 'FourOptions' ? { ...e, OptionList: thisone } : { ...e })
    },
    async saveSurvey () {
      // temporary to make sure option list is in the correct format (needs to be JSON within JSON so it remains a string when parsing)
      this.addStringifiedData()
      const formData = new FormData()
      formData.append('survey', JSON.stringify(this.survey))
      try {
        await surveyService.saveSurvey(formData)
      } catch {
        this.showGenericErrorModal({ showing: true })
      }
      this.saved = true
    }
  }
}
</script>
