<template>
  <div class="leftColumnBoilerPlate d-none d-md-block">
    <div style="width:100%;height:100%;" class="d-flex flex-col">
      <div class="font-bold text-left text-gray-600 leftColHeader">
        Recent Spotlights
        <i v-show="recentSpotlightsLoading" class="fas fa-spin fa-sync" />
        <i v-show="!recentSpotlightsLoading" class="fas fa-sync" />
      </div>
      <div class="leftColMainContent p-3">
        <div v-if="recentSpotlights.length === 0 && recentSpotlightsLoading === false">
          <p class="grey-text" style="margin-left:10px;font-size:0.8rem;text-align:left;margin-right:10px;">
            You have no recent spotlights, let's get started and add one by clicking the button below!
          </p>
        </div>
        <transition-group
          tag="ul"
          name="slide-in"
          :style="{ '--total': recentSpotlights.length }"
          style="height:fit-content;padding-bottom:70px"
        >
          <li
            v-for="(spotlight, i) in recentSpotlights"
            :key="spotlight.GoClientPortalSpotlightId"
            :style="{'--i': i}"
            class="cp text-left text-gray-600 px-2 mb-2 pr-3 leftColumnThinTile"
            @click="goToSpotlight(spotlight.GoClientPortalSpotlightId)"
          >
            <div
              style="height:40px;max-width:30%;border-radius:50%;display:flex;align-items:center;justify-content:center;"
              class="mr-2"
              @click="goToSpotlight(spotlight.GoClientPortalSpotlightId)"
            >
              <img style="height:36px;width:90%;;border-radius:50%;object-fit:contain;" :src="spotlight.Logo">
            </div>
            <div
              class="text-center mr-auto"
              style="width:max-content;max-height:100%;overflow-y:auto;"
              @click="goToSpotlight(spotlight.GoClientPortalSpotlightId)"
            >
              {{ spotlight.Name }}
            </div>
            <span v-if="spotlight.IsEvergreen" class="ml-2"><FontAwesomeIcon icon="fa-solid fa-leaf" /></span>
          </li>
        </transition-group>
      </div>
      <div class="d-flex flex-wrap align-items-center justify-center leftColBottomButtonPanel">
        <mdb-btn
          size="sm"
          class="btn my-2 my-md-1 mx-2"
          style="min-width:110px;width:fit-content;padding: 0.5rem 1.1rem;"
          @click="addSpotlight()"
        >
          <i class="fa fa-plus-circle" /> ADD
        </mdb-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import clientPortalService from '@/api/services/clientPortal.service'

export default {
  name: 'ClientViewLeftColumn',
  props: {
    recentSpotlights: {
      type: Array,
      default: () => []
    },
    recentSpotlightsLoading: Boolean
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()

    return {
      authData,
      showGenericErrorModal
    }
  },
  computed: {
    urlQueryClientId () {
      if (this.$route.query.clId === undefined) {
        return 0
      } else {
        return Number(this.$route.query.clId)
      }
    },
    urlQuerySpotlightId () {
      if (this.$route.query.slId === undefined) {
        return 0
      } else {
        return Number(this.$route.query.slId)
      }
    }
  },
  methods: {
    async addSpotlight () {
      if (this.urlQueryClientId === 0) {
        const res = await this.getSpotlight()
        this.$router.push({ path: '/client_portal/spotlights', query: { clId: res.data.ClientId } }).catch(() => {})
        this.$nextTick(() => {
          this.$emit('addEditSpotlightModalShowing', true)
        })
      } else {
        this.$emit('addEditSpotlightModalShowing', true)
      }
    },
    goToSpotlight (spotlightId) {
      this.$router.push({ path: '/client_portal/spotlights', query: { slId: spotlightId } }).catch(() => {})
    },
    async getSpotlight () {
      try {
        return await clientPortalService.getSpotlight(this.urlQuerySpotlightId)
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-in {
  &-enter-active {
    transition: opacity .5s linear, transform .5s cubic-bezier(.2,.5,.1,1);
    transition-delay: calc( 0.1s * var(--i) );
  }

  &-enter {
    opacity: 0;
  }

  &-enter { transform: translateX(30px); }
}
</style>
