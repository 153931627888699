<template>
  <div>
    <div v-if="isActive && userData.aut && userData.inf > 0">
      <transition name="fade">
        <div
          class="row justify-content-center"
          :class="[{'div-width': mobileView, 'mobNot': mobileView, 'alert alert-primary': userData.inf === 3, 'alert alert-warning': userData.inf === 2, 'alert alert-danger': userData.inf === 1}, mobileView ? 'mb-0 mt-2' : 'mb-0']"
          style="font-size: small;border-bottom:1px solid #c0c0c0;z-index:1;box-shadow: rgb(51 51 51) 0px 5px 8px -4px; min-width:100%; margin-right:-15px; margin-left:-15px;"
          :style="{'margin-top': mobileView ? '-12px' : '-1px'}"
        >
          <div
            class="text-center inline"
            :class="[{'mobile-notification': mobileView && userData.inf != 1, 'margTopNot' : mobileView}]"
          >
            {{ userData.msg }}
            <i
              v-if="userData.inf != 1"
              class="far fa-times-circle place-content-end"
              style="top: 93px;cursor:pointer;"
              @click="$emit('removeAlert')"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'

export default {
  name: 'NotificationBar',
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    return {
      userData,
      mobileView
    }
  },
  created () {
    setTimeout(() => {
      this.$emit('removeAlert')
    }, 20000)
  }
}
</script>

<style>
.mobNot {
  position: fixed !important;
  top: -8px !important;
  z-index: 1000 !important;
  height:89px;
}
.margTopNot {
  margin-top: 12px;
}
</style>
