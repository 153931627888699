<template>
  <div>
    <mdb-modal
      id="editStageItemModal"
      size="fluid"
      :show="editStageItemModalShowing"
      @close="closeModal()"
    >
      <mdb-modal-header>
        <div class="font-weight-bold">
          EDIT STAGE STATUSES
        </div>
      </mdb-modal-header>
      <mdb-modal-body>
        <div class="d-flex flex-wrap">
          <!-- INCOMPLETE SIDE -->
          <EditStageItemTable
            @refreshStatuses="refreshWorkflow"
            tableTitle="Incomplete"
            :minimumOrdinal="1"
            :initialStageItems="incompleteStageItems"/>
          <!-- COMPLETE SIDE -->
          <EditStageItemTable
            class="mt-3 mt-xl-0"
            @refreshStatuses="refreshWorkflow"
            tableTitle="Complete"
            :minimumOrdinal="minimumCompleteStageOrdinal"
            :initialStageItems="completeStageItems">
            <mdb-btn
              @click="openTextModal()"
              size="sm"
              class="m-0 my-2 btn p-2 px-3">
              <FontAwesomeIcon
                class="mr-2"
                icon="fa-regular fa-circle-plus" />
              ADD STATUS
            </mdb-btn>
          </EditStageItemTable>
        </div>
      </mdb-modal-body>
    </mdb-modal>

    <!-- GENERIC MODAL FOR ADDING THE TEXT OF THE NEW STAGE ITEM -->
    <GenericInsertTextModal
      @text="addNewStageItem"/>

  </div>
</template>

<script>
import { useUserData, useStatus, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'
import EditStageItemTable from './editStageItemTable.vue'
import { computed, nextTick, ref, watch } from 'vue-demi'
import GenericInsertTextModal from '@/components/genericModals/insertTextModal.vue'
import roleV2Service from '@/api/services/roleV2.service'

export default {
  name: 'EditStageItemModal',
  components: { EditStageItemTable, GenericInsertTextModal },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal, openInsertTextGenericModal } = useStatus()

    const workflowStore = useWorkflow()
    const { editStageItemModalShowing, editStageItemStage, currentWorkflow } = storeToRefs(workflowStore)

    const closeModal = () => {
      editStageItemModalShowing.value = false
      editStageItemStage.value = {}
    }

    const incompleteStageItems = ref([])
    const completeStageItems = ref([])
    // we have to do this as the stage prop is a computed object with nested properties so vues update cycle doesnt always recognise when the object has been given a new value
    const setCompleteAndIncompleteStatuses = () => {
      incompleteStageItems.value = [...editStageItemStage.value.OrderedRoleWorkflowStageItems.filter(i => !i.CompleteStatusType)]
      completeStageItems.value = [...editStageItemStage.value.OrderedRoleWorkflowStageItems.filter(i => i.CompleteStatusType)]
    }
    watch(editStageItemModalShowing, () => {
      if (editStageItemModalShowing.value == true) {
        setCompleteAndIncompleteStatuses()
      }
    })
    // we need to add a minimum ordinal incase we are changing the ordinals of the completed stage items and cant assume it will be one as a unfixed number of incomplete stage items
    const minimumCompleteStageOrdinal = computed(() => {
      let minimum = 100
      completeStageItems.value.map(a => {
        if (a.Ordinal < minimum) { minimum = a.Ordinal }
      })
      return minimum
    })

    // here we are updating the pinia value used to populate the UI of the workflow builder
    const refreshWorkflow = async () => {
      try {
        const res = await roleV2Service.getRoleWorkflowNoSetupDetails(currentWorkflow.value.RoleWorkflowId)
        currentWorkflow.value = { ...res.data }
        const newStageData = res.data.OrderedRoleWorkflowStages.find(s => s.RoleWorkflowStageId == editStageItemStage.value.RoleWorkflowStageId)
        editStageItemStage.value = { ...newStageData }
        await nextTick(() => {})
        setCompleteAndIncompleteStatuses()
      } catch (err) {
        this.showGenericErrorModal()
      }
    }

    const addNewStageItem = async (statusName) => {
      const JSONData = {
        Ordinal: minimumCompleteStageOrdinal.value + editStageItemStage.value.OrderedRoleWorkflowStageItems.length + 1,
        RoleWorkflowStageId: editStageItemStage.value.RoleWorkflowStageId,
        Name: statusName
      }

      try {
        await roleV2Service.addRoleWorkflowStageItem(JSONData)
        await refreshWorkflow()
      } catch (err) {
        showGenericErrorModal({showing: true})
      }
    }

    const openTextModal = () => {
      openInsertTextGenericModal('ADD A STAGE STATUS') 
    }

    return {
      authData,
      showGenericErrorModal,
      editStageItemModalShowing,
      editStageItemStage,
      closeModal,
      incompleteStageItems,
      completeStageItems,
      addNewStageItem,
      openTextModal,
      refreshWorkflow,
      minimumCompleteStageOrdinal
    }
  }
}
</script>
