<template>
  <div>
    <!--generic error modal -->
    <mdb-modal
      id="InsertMultipleTextBoxGenericModal"
      :show="showing"
      size="md"
      @close="close()"
    >
      <mdb-modal-header>
        <mdb-modal-title>{{ modalTitle.toUpperCase() }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <!-- INPUT ARRAYS -->
        <div
          v-for="(input, index) in multipleTextBoxesInputArray"
          :key="index"
          class="md-form form-sm"
          style="margin-bottom: 30px;"
        >
          <FontAwesomeIcon
            :icon="input.iconCode"
            class="prefix"
            style="margin-top: 5px;font-size: 1.3rem;" 
          />
          <mdb-input
            ref="input"
            :value="textValues[`${input.textValuesKeyName}`]"
            type="text"
            :label="input.inputLabel"
            :maxlength="input.maxLength"
            style="margin-left:40px;"
            size="sm"
            @input="(newValue) => setValue(newValue, input.textValuesKeyName)"
          />
        </div>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          color="primary"
          size="sm"
          style="width:100px;"
          class="btn-outline mr-auto"
          @click.native="close()"
        >
          CANCEL
        </mdb-btn>
        <mdb-btn
          :disabled="insertMultipleTextBoxPreventConfirmation"
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="confirm()"
        >
          CONFIRM
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'InsertMultipleTextBoxGenericModal',
  setup () {
    const statusStore = useStatus()
    const { insertMultipleTextBoxGenericModal, textValues, multipleTextBoxesInputArray, insertMultipleTextBoxPreventConfirmation } = storeToRefs(statusStore)

    return {
      multipleTextBoxesInputArray,
      textValues,
      insertMultipleTextBoxGenericModal,
      insertMultipleTextBoxPreventConfirmation
    }
  },
  computed: {
    modalTitle () {
      if (!this.insertMultipleTextBoxGenericModal.modalTitle) {
        return 'Insert Options'
      } else { return this.insertMultipleTextBoxGenericModal.modalTitle }
    },
    showing () {
      return this.insertMultipleTextBoxGenericModal.showing
    }
  },
  watch: {
    async showing () {
      if (this.showing) {
        await this.$nextTick(() => {})
        this.$refs.input[0].focus()
      }
    }
  },
  methods: {
    setValue (value, keyName) {
      this.textValues[`${keyName}`] = value 
    },
    closeInsertMultipleTextBoxGenericModal () {
      this.insertMultipleTextBoxGenericModal = {
        showing: false,
        modalTitle: 'Insert Options'
      }
    },
    close () {
      this.closeInsertMultipleTextBoxGenericModal()
      this.textValues = {}
    },
    confirm () {
      this.$emit('textValues', this.textValues)
      this.closeInsertMultipleTextBoxGenericModal()
      this.textValues = {}
    }
  }
}
</script>
