import { ref, computed } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useStatus } from '@/pinia'
import { useWorkflow } from './useWorkflow'
import moment from 'moment';

import eventService from '@/api/services/event.service'
import userService from '@/api/services/user.service'
import baseService from '@/api/services/base.service'

export const useEvent = defineStore('eventStore', () => {
  const { showGenericErrorModal, closeGenericConfirmModal } = useStatus()
  const workflowStore = useWorkflow()
  const { selectedWorkflowStage } = storeToRefs(workflowStore)

  const addEventLocationForm = ref(false)
  const events = ref([])
  const selectedDate = ref(null)

  const selectedEvent = ref({
    StartDate: null,
    EndDate: null,
    IntroText: null,
    UploadedVideoId: null,
    CustomUrl: null
  })

  const isEvergreen = computed(() => selectedEvent?.value?.EndDate === "2999-12-01")

  const resetEventForm = () => {
    selectedEvent.value.StartDate = null
    selectedEvent.value.EndDate = null
    selectedEvent.value.IntroText = null
    selectedEvent.value.UploadedVideoId = null
    selectedEvent.value.CustomUrl = null
  }

  const setSelectedEvent = async(id) => {
    try {
      selectedEvent.value = await getEventById(id)
      selectedEvent.value.StartDate = moment(selectedEvent?.value?.StartDate).format("YYYY-MM-DD")
      selectedEvent.value.EndDate = moment(selectedEvent?.value?.EndDate).format("YYYY-MM-DD")
      locations.value = await getEventLocations()
      mapEventLocations()
    } catch {
      showGenericErrorModal({ showing: true })
    }
  }

  const editEventSetup = async (payload) => {
    try {
      const res = await eventService.editEvent(payload)
      selectedWorkflowStage.value = res.data.RoleWorkflowStage
      return res.status
    } catch {
      showGenericErrorModal({ showing: true })
    }
  }

  const checkEventHasStarted = async (stageId) => {
    try {
      const res = await eventService.checkEventHasStarted(stageId)
      return res.data
    } catch {
      showGenericErrorModal({ showing: true })
    }
  }

  const getUsers = async () => {
    try {
      const res = await userService.getUsers()
      return res.data
    } catch {
      showGenericErrorModal({ showing: true })
    }
  }

  const getCountries = async () => {
    try {
      const res = await baseService.getCountries()
      countries.value = res.data
    } catch {
      showGenericErrorModal({ showing: true })
    }
  }

  const getEvents = async () => {
    try {
      const res = await eventService.getEvents()
      return res.data
    } catch {
      showGenericErrorModal({ showing: true })
    }
  }

  const getEventById = async (id) => {
    try {
      const res = await eventService.getEventById(id)
      return res.data
    } catch {
      showGenericErrorModal({ showing: true })
    }
  }

  // LOCATION
  const locations = ref([])
  const locationsDropdown = ref([])
  const countries = ref([])
  const countriesDropdown = ref([])
  const selectedCountry = computed(() => countriesDropdown.value.find(c => c.selected)?.value)
  const selectedLocation = computed(() => locationsDropdown.value.filter(x => x.selected).map(y => y.value))

  const eventLocation = ref({
    GoEventLocationName: null,
    AddressLine1: null,
    AddressLine2: null,
    Town: null,
    County: null,
    PostCode: null,
    Country: null,
    GoEventLocationDetails: null
  })

  const resetLocationForm = () => {
    eventLocation.value.GoEventLocationName = null
    eventLocation.value.AddressLine1 = null
    eventLocation.value.AddressLine2 = null
    eventLocation.value.Town = null
    eventLocation.value.County = null
    eventLocation.value.PostCode = null
    eventLocation.value.GoEventLocationDetails = null
  }

  const addEventLocation = async () => {
    let payload = {
      GoEventLocationName: eventLocation.value.GoEventLocationName,
      AddressLine1: eventLocation.value.AddressLine1,
      AddressLine2: eventLocation.value.AddressLine2,
      Town: eventLocation.value.Town,
      County: eventLocation.value.County,
      PostCode: eventLocation.value.PostCode,
      CountryId: selectedCountry.value,
      GoEventLocationDetails: eventLocation.value.GoEventLocationDetails
    }
    try {
      const res = await eventService.addEventLocation(payload)
      if (res.data === true) {
        resetLocationForm()
        locations.value = await getEventLocations()
        mapEventLocations()
        addEventLocationForm.value = false
      }
    } catch {
      showGenericErrorModal({ showing: true })
    }
  }

  const deleteEventLocation = async (id) => {
    try {
      const res = await eventService.deleteEventLocation(id)
      return res.data
    } catch {
      showGenericErrorModal({ showing: true })
    } finally {
      locations.value = await getEventLocations()
      closeGenericConfirmModal()
    }
  }

  const getEventLocations = async () => {
    try {
      const res = await eventService.getEventLocations()
      return res.data
    } catch {
      showGenericErrorModal({ showing: true })
    }
  }

  const checkLocation = (id) => {
    let eventLocationsIds = selectedEvent.value.GoEventLocations.map(x => x.GoEventLocationId)
    return eventLocationsIds.includes(id)
  }

  const mapEventLocations = () => {
    locationsDropdown.value = locations.value.map((c, index) => ({
      text: c.GoEventLocationName,
      selected: index == 0,
      value: c.GoEventLocationId
    }))
  }

  return {
    addEventLocation,
    addEventLocationForm,
    checkEventHasStarted,
    countries,
    countriesDropdown,
    deleteEventLocation,
    editEventSetup,
    events,
    eventLocation,
    getCountries,
    getEvents,
    getEventById,
    getEventLocations,
    getUsers,
    locations,
    mapEventLocations,
    resetEventForm,
    selectedCountry,
    selectedDate,
    selectedEvent,
    selectedLocation,
    setSelectedEvent,
    locationsDropdown,
    isEvergreen
  }
})
