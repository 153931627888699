<template>
  <div>
    <mdb-modal
      id="DeleteCustomFieldModal"
      :show="showing"
      @close="close()"
    >
      <mdb-modal-header>
        <mdb-modal-title>DELETE CUSTOM FIELD</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <div class="grey-text" style="margin-top:10px; margin-bottom:20px;font-size:smaller;">
          Are you sure you want to delete this custom field?
        </div>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          color="primary"
          size="sm"
          style="width:100px;"
          class="btn-outline mr-auto"
          @click.native="close()"
        >
          CANCEL
        </mdb-btn>
        <mdb-btn
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="confirm()"
        >
          DELETE
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import { ref } from 'vue-demi'

export default {
  name: 'DeleteCustomFormFieldModal',
  setup () {
    const statusStore = useStatus()
    const { deleteCustomFormFieldModal } = storeToRefs(statusStore)
    const { closeDeleteCustomFormFieldModal } = useStatus()

    const id = ref(deleteCustomFormFieldModal.value.id)

    return {
      closeDeleteCustomFormFieldModal,
      id,
      deleteCustomFormFieldModal
    }
  },
  computed: {
    showing () {
      return this.deleteCustomFormFieldModal.showing
    }
  },
  watch: {
    async showing () {
      if (this.showing) {
        this.id = this.deleteCustomFormFieldModal.id
      }
    }
  },
  methods: {
    close () {
      this.closeDeleteCustomFormFieldModal()
      this.id = 0
    },
    confirm () {
      this.$emit('id', this.id)
      this.closeDeleteCustomFormFieldModal()
      this.id = 0
    }
  }
}
</script>
