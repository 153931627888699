<template>
  <div
    class="min-h-full pt-2 pt-md-3 px-3 px-md-5 w-1/1 text-black rounded"
    style="height:calc(100vh - 190px);overflow-y:auto;margin-bottom:65px;overflow-x:hidden"
    :class="{ 'mob': mobileView }"
  >
    <div
      ref="scrollToDiv"
      style="height: 1px;"
    >
      <div
        v-show="masterAccountGroupsLoaded === true"
        class="grey-text text-left"
      >
        <div
          v-if="masterAccountGroups.length === 0"
          class="text-center grey-text"
          :class="{ 'm-a-left': mobileView }"
          style="font-size: 14px;margin-top: 50px;"
        >
          No master account groups to display. Click <a
            class="primary-colour"
            style="font-weight:bold;"
            @click="showAddEditMasterAccountGroup(null)"
          ><em>here</em></a> to add a new master account group.
        </div>
        <ExpandableSearchBar
          v-if="masterAccountGroups.length > 0"
          :width="250"
          :max-widthpercentage="50"
          @search="filter = $event"
        />
      </div>

      <div v-show="masterAccountGroupsLoaded === true">
        <!--desktop-->
        <b-table
          v-if="masterAccountGroups.length > 0 && mobileView === false"
          id="master-account-groups-table"
          class="text-left tableTh"
          head-variant="dark"
          style="margin-top:10px;"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items="masterAccountGroups"
          :per-page="masterAccountGroupsPerPage"
          :current-page="masterAccountGroupsCurrentPage"
          :fields="masterAccountGroupsFields"
          :filter="filter"
          hover
          small
          sort-icon-left
          responsive="sm"
        >
          <template #cell(accountgroupname)="data">
            <div style="padding-top:12px;margin-left:10px;" class="smFont">
              {{ data.item.AccountGroupName }}
            </div>
          </template>
          <template #cell(listedaccounts)="data">
            <div style="padding-top:12px;margin-left:5px;" class="smFont">
              {{ data.item.ListedAccounts }}
            </div>
          </template>
          <template #cell(type)="data">
            <div style="padding-top:8px;margin-left:5px;" class="smFont">
              <div v-if="data.item.Type === 'Standard'" style="background:purple;border-radius:15px;color:white;width:85px;padding:2px;">
                <div style="text-align:center">
                  {{ data.item.Type }}
                </div>
              </div>
              <div v-else style="background:green;border-radius:15px;color:white;width:85px;padding:2px;">
                <div style="text-align:center">
                  {{ data.item.Type }}
                </div>
              </div>
            </div>
          </template>
          <template #cell(actionsa)="data">
            <div class="float-right webDisplay">
              <button
                title="Assign accounts to this master account group?"
                type="button"
                class="btn btn-primary btn-md"
                style="padding: 0.5rem 0.6rem;margin:0.175rem;width:33px;"
                @click="showAddEditAssigningAccountsModal(data.item)"
              >
                <mdb-icon icon="plus" />
              </button>
              <button
                title="Edit this master account group?"
                type="button"
                class="btn btn-primary btn-md"
                style="padding: 0.5rem 0.6rem;margin:0.175rem;width:32px;"
                @click="showAddEditMasterAccountGroup(data.item)"
              >
                <mdb-icon icon="pencil-alt" />
              </button>
              <button
                v-if="data.item.ListedAccounts === 0 && data.item.GroupsAndUsersCount < 1"
                title="Delete this master account group?"
                type="button"
                class="btn btn-primary btn-md"
                style="padding: 0.5rem 0.6rem;margin:0.175rem;width:32px;"
                @click="showDeleteMasterAccountGroupModal(data.item)"
              >
                <mdb-icon icon="times" />
              </button>
              <button
                v-else
                type="button"
                class="btn btn-primary btn-md"
                style="padding: 0.5rem 0.6rem;margin:0.175rem;width:32px;"
                disabled
              >
                <mdb-icon icon="times" />
              </button>
            </div>
          </template>
        </b-table>

        <!--mobile-->
        <b-table
          v-if="masterAccountGroups.length > 0 && mobileView === true"
          id="master-account-groups-table"
          style="margin-top:20px;"
          class="text-left tableTh"
          head-variant="dark"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items="masterAccountGroups"
          :per-page="masterAccountGroupsPerPage"
          :current-page="masterAccountGroupsCurrentPage"
          :fields="masterAccountGroupsMobileAccountFields"
          :filter="filter"
          hover
          small
          sort-icon-left
          responsive="sm"
        >
          <template #cell(accountgroupname)="data">
            <div style="padding-top:12px;margin-left:10px;" class="smFont">
              {{ data.item.AccountGroupName }} ({{ data.item.ListedAccounts }})
            </div>
          </template>
          <template #cell(type)="data">
            <div style="padding-top:8px;margin-left:5px;">
              <div v-if="data.item.Type === 'Standard'" style="font-size:10px;background:purple;border-radius:15px;color:white;width:65px;padding:2px;">
                <div style="text-align:center">
                  {{ data.item.Type }}
                </div>
              </div>
              <div v-else style="font-size:10px;background:green;border-radius:15px;color:white;width:65px;padding:2px;">
                <div style="text-align:center">
                  {{ data.item.Type }}
                </div>
              </div>
            </div>
          </template>
          <template #cell(actionsa)="data">
            <mdb-popover
              trigger="click"
              :options="{placement: 'bottom'}"
              style="cursor:pointer;float:right;"
              class="grey-text mobDisplay"
            >
              <div slot="body">
                <h6 style="margin-left:-10px;margin-top:-5px;padding:10px;" @click="showAddEditAssigningAccountsModal(data.item)">
                  <a class="ml-1 grey-link"><i class="fa fa-plus" /><span style="margin-left:5px;">Assign Accounts</span></a>
                </h6>
                <h6 style="margin-left:-10px;margin-top:-5px;padding:10px;" @click="showAddEditMasterAccountGroup(data.item)">
                  <a class="ml-1 grey-link"><i class="fa fa-pencil-alt" /> Edit</a>
                </h6>
                <h6
                  v-if="data.item.GroupsAndUsersCount < 1"
                  style="margin-left:-10px;padding:10px;"
                  @click="showDeleteMasterAccountGroupModal(data.item)"
                >
                  <a class="ml-1 grey-link"><mdb-icon icon="times" /><span style="margin-left:7px;">Delete</span></a>
                </h6>
              </div>
              <mdb-icon
                slot="reference"
                title="More actions"
                style="color:#555;font-size:1rem;transform:translateY(2px);margin-top:3px;margin-left:8px;"
                class="p-2 ellipsisButtonHover mr-2"
                icon="ellipsis-v"
              />
            </mdb-popover>
          </template>
        </b-table>

        <b-pagination
          v-show="masterAccountGroups.length > 5"
          v-model="masterAccountGroupsCurrentPage"
          class="float-right mt-4"
          :total-rows="masterAccountGroupsRows"
          :per-page="masterAccountGroupsPerPage"
          first-number
          last-number
          aria-controls="account-groups-table"
          prev-text="<"
          next-text=">"
          style="margin-bottom:30px;"
        />

        <div
          v-show="mobileView === false"
          md="2"
          offset-md="10"
        >
          <button
            v-if="masterAccountGroups.length > 0"
            style="float: left;margin-right: 0px;width: 110px;padding: 6px !important;text-align: center;margin-top:25px;"
            title="Add account"
            class="mdc-button btn"
            @click="showAddEditMasterAccountGroup(null)"
          >
            <span class="secondary-colour"><i class="fa fa-plus-circle" /> Add</span>
          </button>
        </div>
        <div class="fixed bottom-0 mobDisplay" style="background: white;width: 100%;border-top: 1px solid rgb(161, 161, 161);z-index: 1001;margin-left: -33px;height: 55px;">
          <div class="row" style="margin-top:5px;">
            <div class="col-sm-12 text-center">
              <button
                v-if="masterAccountGroups.length > 0"
                style="width: 110px;padding: 6px !important;text-align: center;"
                title="Add account"
                class="mdc-button btn-solid-outline-mob btn"
                @click="showAddEditMasterAccountGroup(null)"
              >
                <span class="secondary-colour"><i class="fa fa-plus-circle" /> Add</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--asign accounts modal-->
      <mdb-modal
        id="assignModal"
        :show="assignModal"
        @close="assignModal = false"
      >
        <mdb-modal-header>
          <mdb-modal-title>ASSIGN ACCOUNTS?</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body :class="{ 'modalHeight': mobileView }">
          <div
            class="my-4 grey-text"
            style="font-size:0.8rem;text-align:left;"
          >
            Select which accounts are assigned to master group: <span style="font-weight: bold;">{{ selectedMasterGroup }}</span>?
            <div
              class="mobBoxHeight"
              style="margin-top:35px;padding:1px;height:200px;overflow-y: auto;border:solid 1px #c0c0c0;"
            >
              <div
                v-for="account in accounts"
                :key="account.AccountId"
                @click="setAccount(account.AccountId)"
              >
                <mdb-input
                  v-if="!account.Locked"
                  v-model="account.Checked"
                  type="checkbox"
                  style="margin-left:-10px;margin-top:10px;"
                  :style="account.Checked ? `font-weight:bold;` : 'font-weight:normal;'"
                  size="sm"
                  :label="account.Name"
                />
              </div>
            </div>
          </div>
        </mdb-modal-body>
        <mdb-modal-footer>
          <mdb-btn
            v-show="!buttonClick"
            color="primary"
            size="sm"
            style="width:100px;"
            @click.native="assignAccounts()"
          >
            ASSIGN
          </mdb-btn>
          <mdb-btn
            v-show="buttonClick"
            class="float-right"
            size="sm"
            style="width:100px;"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>

      <!--add/edit modal-->
      <mdb-modal
        id="addEditMasterAccountGroup"
        :show="showAddEditModal"
        @close="showAddEditModal = false"
      >
        <mdb-modal-header>
          <mdb-modal-title><span v-if="edit">EDIT MASTER ACCOUNT GROUP</span><span v-else>ADD NEW MASTER ACCOUNT GROUP</span></mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body :class="{ 'modalHeight': mobileView }">
          <validation-observer>
            <form
              ref="form"
              @submit.prevent="addEditMasterAccountGroup()"
              @reset.prevent="reset"
            >
              <div class="md-form form-sm text-left">
                <validation-provider
                  v-slot="{ errors }"
                  mode="lazy"
                  name="Master Account Group Name"
                  rules="required"
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-grid"
                    style="margin-top: 6px;font-size: 1.3rem;color:#c0c0c0;"
                  />
                  <mdb-input
                    v-model="mag.AccountGroupName"
                    type="text"
                    auto-complete="fna"
                    label="Master Account Group Name"
                    style="margin-left:33px;margin-top:-35px;"
                    size="sm"
                  />
                  <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                    Please provide a title
                  </div>
                </validation-provider>
              </div>

              <div class="md-form form-sm text-left">
                <validation-provider
                  v-slot="{ errors }"
                  mode="lazy"
                  name="Type"
                  rules="required"
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-grid"
                    style="margin-top: 6px;font-size: 1.3rem;color:#c0c0c0;"
                  />
                  <mdb-select
                    v-model="types"
                    style="margin-left:33px;margin-top:-60px;"
                    size="sm"
                  />
                  <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                    Please select a type
                  </div>
                </validation-provider>
              </div>
            </form>
          </validation-observer>
        </mdb-modal-body>
        <mdb-modal-footer style="display:block;">
          <mdb-btn
            type="reset"
            class="float-left btn-outline"
            size="sm"
            style="width:100px;"
            @click="showAddEditModal = false"
          >
            CANCEL
          </mdb-btn>
          <mdb-btn
            v-if="!buttonClick"
            type="submit"
            :disabled="mag.AccountGroupName === null || types.filter(i => i.selected).length === 0"
            class="float-right"
            size="sm"
            style="width:100px;"
            @click="saveAddEditMasterAccountGroup();buttonClick = true;"
          >
            <span>SAVE</span>
          </mdb-btn>
          <mdb-btn
            v-if="buttonClick"
            class="float-right"
            size="sm"
            style="width:100px;"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>

      <!--error modal-->
      <mdb-modal
        id="errorModal"
        :show="errorModal"
        @close="errorModal = false"
      >
        <mdb-modal-header>
          <mdb-modal-title>{{ errorModalTitle.toUpperCase() }}</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body :class="{ 'modalHeight': mobileView }">
          <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
            <span v-html="error" />
          </p>
        </mdb-modal-body>
        <mdb-modal-footer>
          <mdb-btn
            color="primary"
            size="sm"
            style="width:100px;"
            @click.native="closeErrorModal"
          >
            CLOSE
          </mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>

      <!--delete modal-->
      <mdb-modal
        id="deleteModal"
        :show="deleteModal"
        @close="deleteModal = false"
      >
        <mdb-modal-header>
          <mdb-modal-title>DELETE?</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body :class="{ 'modalHeight': mobileView }">
          <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
            Are you sure you want to delete this master account group?
          </p>
        </mdb-modal-body>
        <mdb-modal-footer>
          <mdb-btn
            v-show="!buttonClick"
            color="primary"
            size="sm"
            style="width:100px;"
            @click.native="deleteMasterAccountGroup"
          >
            YES
          </mdb-btn>
          <mdb-btn
            v-show="buttonClick"
            class="float-right"
            size="sm"
            style="width:100px;"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import logoutService from '@/api/services/logout.service'
import adminService from '@/api/services/admin.service'
import { userLanguage } from '@/common/userLanguage'
import { useMobile } from '@/composables/useMobile'

import { mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModal, mdbBtn } from 'mdbvue'
import ExpandableSearchBar from '@/components/UI/expandableSearch.vue'

export default {
  name: 'Admin',
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbBtn,
    ExpandableSearchBar
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData, leftColumnActive } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    return {
      authData,
      userData,
      leftColumnActive,
      mobileView
    }
  },
  data () {
    return {
      masterAccountGroupsFields: [
        { key: 'AccountGroupName', label: '  Master Account Group Name', tdClass: 'headerPaddingUsers col40 sortIcon', sortable: true },
        { key: 'ListedAccounts', label: '  Listed Accounts', tdClass: 'headerPaddingUsers col20 sortIcon', sortable: true },
        { key: 'Type', label: '  Type', tdClass: 'headerPaddingUsers col20 sortIcon', sortable: true },
        { key: 'ActionsA', label: '', tdClass: 'headerPaddingUsers col20', sortable: false }
      ],
      masterAccountGroupsMobileAccountFields: [
        { key: 'AccountGroupName', label: '  Name (Accounts)', tdClass: 'headerPaddingUsers col70 sortIcon', sortable: true },
        { key: 'Type', label: '  Type', tdClass: 'headerPaddingUsers col20 sortIcon', sortable: true },
        { key: 'ActionsA', label: '', tdClass: 'headerPaddingUsers col10', sortable: false }
      ],
      masterAccountGroupsLoaded: false,
      masterAccountGroupsPerPage: 8,
      masterAccountGroupsCurrentPage: 1,
      masterAccountGroups: [],
      showAddEditModal: false,
      deleteModal: false,
      assignModal: false,
      mag: {
        AccountGroupMasterId: 0,
        AccountGroupName: '',
        IsDeleted: false,
        ListedAccounts: 0,
        Type: ''
      },
      sortBy: '',
      sortDesc: false,
      filter: null,
      buttonClick: false,
      userLanguage,
      filterOn: [],
      errorModal: false,
      errorModalTitle: '',
      error: '',
      edit: false,
      types: [],
      accounts: [],
      selectedMasterGroup: ''
    }
  },
  computed: {
    masterAccountGroupsRows () {
      return this.masterAccountGroups.length
    },
    sortOptions () {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
    groupType: function () {
      var c = ''
      this.types.forEach(element => {
        if (element.selected === true) {
          c = element.value
        }
      })
      return c
    },
  },
  async created () {
    this.$parent.masterAccountGroupsLoading = true
    this.getMasterAccountGroups(-1)
  },
  methods: {
    assignAccounts () {
      this.buttonClick = true
      adminService.assignAccounts(this.mag.AccountGroupMasterId, this.accounts).then((response) => {
        this.getMasterAccountGroups(-1)
        this.assignModal = false
        this.buttonClick = false
      }).catch((error) => {
        this.assignModal = false
        this.buttonClick = false
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
    },
    setAccount (aid) {
      this.accounts.forEach(o => {
        if (o.AccountId === aid) {
          if (o.Checked === true) { o.Checked = false } else { o.Checked = true }
        }
      })
    },
    getMasterAccountGroups (accountId) {
      adminService.getMasterAccountGroups(accountId).then((response) => {
        this.masterAccountGroups = response.data
        setTimeout(() => {
          this.$parent.masterAccountGroupsLoading = false
          this.masterAccountGroupsLoaded = true
        }, 3000)
      }).catch((error) => {
        setTimeout(() => {
          this.$parent.masterAccountGroupsLoading = false
          this.masterAccountGroupsLoaded = true
        }, 3000)
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
    },
    errorAlert (error, err) {
      try {
        if (error.response.data != null) {
          if (error.response.data.Message != null) {
            this.errorModal = true
            this.error = error.response.data.Message
            if (this.error === '') { this.error = err }
          } else if (error.response.data.error_description != null) {
            this.errorModal = true
            this.error = error.response.data.error_description
            if (this.error === '') { this.error = err }
          } else {
            this.errorModal = true
            this.error = error.response.data
            if (this.error === '') { this.error = err }
          }
        } else {
          this.errorModal = true
          this.error = err
        }
        if (error.response.data.Message === 'Authorization has been denied for this request.') {
          this.logOut()
        }
      } catch {
        this.errorModal = true
        this.error = err
      }
    },
    logOut () {
      logoutService.logout().then(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      }).catch(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      })
    },
    showAddEditAssigningAccountsModal (masterGroup) {
      this.mag = masterGroup
      adminService.getAccountsList(this.mag.AccountGroupMasterId).then((response) => {
        this.selectedMasterGroup = masterGroup.AccountGroupName
        this.assignModal = true
        this.accounts = response.data
        this.accounts.forEach(o => {
          if (o.AccountGroupMaster === this.mag.AccountGroupMasterId) {
            o.Checked = true
          }
        })
        this.accounts.forEach(o => {
          if (o.AccountGroupMaster === this.mag.AccountGroupMasterId) {
            o.PreviouslySelected = true
          }
        })
        this.accounts.forEach(o => {
          if (o.AccountGroupMaster !== null) {
            if (o.AccountGroupMaster !== this.mag.AccountGroupMasterId) {
              o.Locked = true
            }
          }
        })
        this.getMasterAccountGroups(-1)
      }).catch((error) => {
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
        this.assignModal = false
        this.getMasterAccountGroups(-1)
      })
    },
    showAddEditMasterAccountGroup (data) {
      if (data !== null) {
        this.edit = true
        this.mag = data
        if (this.mag.Type === 'Standard') {
          this.types = [
            { text: 'Type', value: '' },
            { text: 'Standard', value: 'Standard', selected: true },
            { text: 'Reseller', value: 'Reseller' }
          ]
        } else if (this.mag.Type === 'Reseller') {
          this.types = [
            { text: 'Type', value: '' },
            { text: 'Standard', value: 'Standard' },
            { text: 'Reseller', value: 'Reseller', selected: true  }
          ]
        }
      } else {
        this.edit = false
        this.mag = {
          AccountGroupMasterId: 0,
          AccountGroupName: '',
          IsDeleted: false,
          ListedAccounts: 0,
          Type: ''
        }
        this.types = [
            { text: 'Type', value: '', selected: true },
            { text: 'Standard', value: 'Standard', selected: true },
            { text: 'Reseller', value: 'Reseller' }
          ]
      }
      this.showAddEditModal = true
    },
    saveAddEditMasterAccountGroup () {
      this.mag.Type = this.groupType
      this.buttonClick = true
      adminService.addEditMasterAccountGroup(this.mag).then(() => {
        this.buttonClick = false
        this.mag = {
          AccountGroupMasterId: 0,
          AccountGroupName: '',
          IsDeleted: false,
          ListedAccounts: 0,
          Type: ''
        },
        this.reset()
        this.showAddEditModal = false
        this.getMasterAccountGroups(-1)
      }).catch((error) => {
        this.buttonClick = false
        this.showAddEditModal = false
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
    },
    showDeleteMasterAccountGroupModal (item) {
      this.mag = item
      this.deleteModal = true
    },
    deleteMasterAccountGroup () {
      this.buttonClick = true
      adminService.deleteMasterAccountGroup(this.mag.AccountGroupMasterId).then(() => {
        this.buttonClick = false
        this.mag = {
          AccountGroupMasterId: 0,
          AccountGroupName: '',
          IsDeleted: false,
          ListedAccounts: 0,
          Type: ''
        },
        this.deleteModal = false
        this.getMasterAccountGroups(-1)
      }).catch((error) => {
        this.buttonClick = false
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
        this.deleteModal = false
      })
    },
    reset () {
      this.$refs.form.reset()
    },
    closeErrorModal () {
      this.errorModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .mob {
    width: calc(100% + 30px) !important;
    margin-left: -15px !important;
    margin-top: 15px;
  }
  .fiftyWide {
    width:55%;
  }
  .m-a-left {
    margin-left: 0px !important;
  }
  .italics {
    font-style:italic;
  }
  @media (max-width:576px) {
    .mobBoxHeight {
      height:calc(100vh - 350px) !important;
    }
  }
</style>
