<template>
  <div
    v-if="text != ''"
    class="cp"
    @click="$emit('clicked', text)">
    <span
      class="primary-colour text">{{text}}</span>
  </div>
</template>

<script>
  export default {
    name: 'ClickToPopluate',
    props: {
      text: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
.text {
  border-bottom: 2px solid var(--pr-color);
  transition: 0.2 ease;
}
.text:hover {
  font-weight: bold;
}
</style>