<template>
  <div style="width:100%; flex-grow: 1; overflow: auto;" class="px-3 px-md-4">
    <div class="font-weight-bold text-left my-3 d-flex align-items-center justify-content-between" style="height:50px">
      <div class="d-flex align-items-center">
        <span class="mr-2">Clients</span>
        <i
          v-if="clientsLoading"
          style="margin-left: 5px !important;"
          class="fa fa-spin fa-spinner ml-3"
        />
      </div>
      <ExpandableSearchBar
        @search="search = $event"
      />
    </div>
    <ClientsViewTable
      :clients-loading="clientsLoading"
      :clients="filteredClients"
      @getAllClients="getAllClients()"
      @getRecentClients="$emit('getRecentClients')"
      @showAddEditModal="showAddEditModal($event)"
    />

    <AddEditClientModal
      :add-edit-client-modal-showing="addEditClientModalShowing"
      :client-id="clientId"
      @closeAddEditModal="closeAddEditModal()"
      @setClientId="clientId = $event"
      @addClientToRecentClients="$emit('addClientToRecentClients', $event)"
    />
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import clientPortalService from '@/api/services/clientPortal.service'

import ClientsViewTable from './clientViewTable.vue'
import AddEditClientModal from './addEditClientModal.vue'
import ExpandableSearchBar from '@/components/UI/expandableSearch.vue'

export default {
  name: 'ClientViewMainSection',
  components: {
    ClientsViewTable,
    AddEditClientModal,
    ExpandableSearchBar
  },
  props: {
    addEditClientModalShowing: Boolean
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()

    return {
      authData,
      showGenericErrorModal
    }
  },
  data () {
    return {
      clients: [],
      clientId: 0,
      search: ''
    }
  },
  computed: {
    filteredClients () {
      if (this.search === '') { return this.clients }
      return this.clients.filter(c => c.Name.toLowerCase().includes(this.search.toLowerCase()))
    }
  },
  created () {
    this.getAllClients()
  },
  methods: {
    async getAllClients () {
      this.clientsLoading = true
      try {
        const res = await clientPortalService.getAllClients()
        this.clients = res.data
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.clientsLoading = false
    },
    showAddEditModal (clientId) {
      this.clientId = clientId
      this.$emit('addEditClientModalShowing', true)
    },
    closeAddEditModal () {
      this.clientId = 0
      this.$emit('addEditClientModalShowing', false)
      this.getAllClients()
    }
  },
}
</script>
