<template>
  <div>
    <mdb-modal
      id="playMeetNowRecordingModalShowing"
      :show="playMeetNowRecordingModalShowing"
      @close="$emit('playMeetNowRecordingModalShowing', false)"
    >
      <mdb-modal-header class="align-items-center">
        <span class="font-weight-bold py-2 text-left">{{ selectedRecordingName.toUpperCase() }}</span>
      </mdb-modal-header>
      <mdb-modal-body style="background:white;">
        <div class="mx-auto p-2">
          <div style="background:black;">
            <video
              id="meetNowVideoPreview"
              ref="meetNowVideoPreview"
              :src="recordingToWatchURL"
              :poster="recordingToWatchThumb"
              controls
              crossorigin=""
              controlsList="nodownload"
              playsinline
              type="video/mp4; codecs=avc1.42E01E, mp4a.40.2, H.265"
              class="video-fluid z-depth-1"
              style="outline:none;margin: 0px auto;border-radius: 2px;height: 250px;-moz-transform: none !important;background:black;"
            />
          </div>
          <canvas
            id="canvas"
            crossorigin=""
            style="display:none;"
          />
          <div v-if="!buttonClick" class="mt-3">
            <mdb-btn
              v-if="hasDragged === true"
              color="primary"
              size="sm"
              @click="captureThumb()"
            >
              <i class="fas fa-camera" style="margin-right:10px;" />
              <div class="d-inline text-nowrap">
                CHANGE VIDEO THUMBNAIL
              </div>
            </mdb-btn>
            <div
              v-else
              class="d-inline text-nowrap"
              style="font-size:0.8rem;"
            >
              To capture new thumbnail drag slider to the desired frame
            </div>
          </div>
          <div v-else class="mt-3">
            <i class="fa fa-spin fa-spinner" />
          </div>
        </div>
      </mdb-modal-body>
    </mdb-modal>
  </div>
</template>

<script>
import { useMeetNow, useUserData, useStatus } from '@/pinia'
import meetNowService from '@/api/services/meetnow.service'
import { storeToRefs } from 'pinia'

export default {
  name: 'PlayVideoModal',
  props: {
    selectedRecordingName: {
      type: String,
      default: ''
    },
    recordingToWatchURL: {
      type: String,
      default: ''
    },
    recordingToWatchThumb: {
      type: String,
      default: ''
    },
    playMeetNowRecordingModalShowing: Boolean,
    recordingId: {
      type: Number,
      default: 0
    },
    guid: {
      type: String,
      default: ''
    }
  },
  setup () {
    const { addOrUpdateRecording } = useMeetNow()

    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()

    return {
      addOrUpdateRecording,
      authData,
      userData,
      showGenericErrorModal
    }
  },
  data () {
    return {
      answerVideo: null,
      buttonClick: false,
      loading: false,
      hasDragged: false,
      showCapture: false
    }
  },
  watch: {
    playMeetNowRecordingModalShowing () {
      if (this.playMeetNowRecordingModalShowing === true) {
        this.hasDragged = false
        setTimeout(() => {
          this.$refs.meetNowVideoPreview.onseeked = () => { this.hasDragged = true }
        }, 500)
      }
    }
  },
  methods: {
    captureThumb () {
      this.buttonClick = true
      var video = document.getElementById('meetNowVideoPreview')
      video.pause()
      var canvas = document.getElementById('canvas')
      var vw = video.videoWidth
      var vh = video.videoHeight
      canvas.width = vw
      canvas.height = vh
      canvas.getContext('2d').drawImage(video, 0, 0, vw, vh)
      canvas.toBlob(async blob => {
        if (blob != null) {
          this.changeThumbnail(blob)
        }
      })
    },
    async changeThumbnail (blob) {
      var formData = new FormData()
      formData.append('UserName', this.userData.une)
      formData.append('Id', this.recordingId)
      formData.append('Guid', this.guid)
      formData.append('file', blob)
      try {
        const res = await meetNowService.changeThumbnail(formData)
        this.addOrUpdateRecording(res.data)
        this.$emit('playMeetNowRecordingModalShowing', false)
      } catch (error) {
        this.showGenericErrorModal({ showing: true, errorTitle: 'Error Updating Thumbnail', errorMessage: 'Something went wrong updating the thumbnail to your Shine Intro. Try refreshing and updating again.' })
      }
      this.buttonClick = false
      this.showCapture = false
    }
  }
}
</script>

<style lang="scss">
@media (max-width: 576px) {
  #playMeetNowRecordingModalShowing .modal-dialog .modal-content {
    margin:5px !important;
    width:calc(100vw - 10px) !important;
    height: calc(100vh - 65px) !important;
  }
}
@media (min-width: 577px) and (max-width: 999px) {
  #playMeetNowRecordingModalShowing .modal-content {
    margin: 5px !important;
    width: 70vw !important;
    height: 85vh !important;
    margin: 1.75rem auto !important;
    margin-top: 5% !important;
  }
  #playMeetNowRecordingModalShowing .modal-dialog {
    max-width: none !important;
  }
  #meetNowVideoPreview {
    height: 60vh !important;
  }
}
@media (min-width: 1000px) {
  #playMeetNowRecordingModalShowing .modal-content {
    margin: 5px !important;
    width: 60vw !important;
    height: 76vh !important;
    margin: 1.75rem auto !important;
    margin-top: 5% !important;
  }
  #playMeetNowRecordingModalShowing .modal-dialog {
    max-width: none !important;
  }
  #meetNowVideoPreview {
    height: 57vh !important;
  }
}
</style>
