<template>
  <div>
    <div class="p-2 p-md-3 p-lg-4">
      <b-row no-gutters class="mt-2 mb-4">
        <b-row no-gutters style="width:100%;">
          <h3>Add Candidates By Status</h3>
        </b-row>
        <b-row
          no-gutters
          style="height:fit-content; width:100%;"
          class="d-flex align-content-center justify-content-between justify-content-md-start mt-2 mb-2"
        >
          <div
            v-for="quickSelect in quickSelectCandidateOptions"
            :key="quickSelect.text"
            class="mb-2 mr-2"
            style="cursor:pointer;"
          >
            <QuickSelect
              :loading-quick-select-candidates="loadingQuickSelectCandidateCounts"
              :text="quickSelect.text"
              :count="quickSelect.count"
              @toggleAdded="quickAddRemoveCandidates($event)"
            />
          </div>
        </b-row>
        <b-row no-gutters style="width:100%;">
          <h3>Candidate(s) to Share</h3>
          <mdb-icon
            v-if="loadingQuickSelectCandidates"
            class="m-0 ml-3 p-0"
            icon="fa fa-pulse fa-spinner"
          />
        </b-row>
        <div
          style="border:1px solid #e1e1e1; height:33vh;"
          :class="{ 'mobShareHeight': mobileView }"
          class="d-flex flex-wrap col-12 align-content-start mt-2"
        >
          <div
            v-for="cand in candidatesAddedToShare"
            :key="cand.CandidateId"
          >
            <div v-if="cand.CandidateId > 0" style="margin-left:10px;margin-top:10px;margin-right: 0px;">
              <div style="border:1px solid #C0C0C0; border-radius:5px;cursor:pointer;width:fit-content;height:fit-content;">
                <div class="d-flex align-items-center justify-content-between">
                  <div style="height:100%;" class="d-flex align-items-center">
                    <img
                      v-if="cand.ProfilePicture != '' && cand.ProfilePicture != null && !role.MaskCandidateProfilePicture"
                      :src="cand.ProfilePicture"
                      style="height:25px;width:25px;border-radius:50%;object-fit:cover;"
                      class="d-inline mr-2"
                    >
                    <mdb-icon
                      v-else
                      icon="fa fa-user"
                      class="ml-1 mr-2"
                      size="md"
                    />
                    <h5 class="text-left d-inline" style="font-size:14px;">
                      {{ role.MaskGoCandidateName ? cand.CandidateId : cand.Name }}
                    </h5>
                  </div>
                  <div style="width:30px;" class="flex-grow-0">
                    <i
                      style="cursor:pointer;color:#C0C0C0;"
                      class="fa fa-times colorOnHover ml-2"
                      @click="removeFromCandidatesAddedToShare(cand)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="height:30px;width:100%;">
          <i
            style="cursor:pointer;color:black; transform:scale(1.4);float:right;margin-right: 13px;margin-top: -30px;"
            class="d-none d-md-block fas fa-user-plus"
            @click="openSearchCandidateModal()"
          />
          <i
            style="cursor:pointer;color:black;transform:scale(1.2) translateY(7px);float:right;margin-right: 13px;margin-top: -30px;"
            class="d-md-none fas fa-user-plus"
            @click="openSearchCandidateModal()"
          />
        </div>
      </b-row>
    </div>

    <FindCandidatesModal
      :candidatesAddedToShare="candidatesAddedToShare"
      :showSearchCandidateModal="showSearchCandidateModal"
      @showSearchCandidateModal="showSearchCandidateModal = $event"
      @updateCandidatesAddedToShare="$emit('updateCandidatesAddedToShare', $event)"
    />
  </div>
</template>

<script>
import roleV2Service from '@/api/services/roleV2.service'
import QuickSelect from '@/components/misc/quickSelect.vue'
import FindCandidatesModal from './findCandidatesModal.vue'

import { useWorkflow, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import { ref } from 'vue'

export default {
  name: 'Candidates',
  components: {
    QuickSelect, FindCandidatesModal
  },
  props: {
    candidatesAddedToShare: Array,
    roleId: Number
  },
  setup () {
    const workflowStore = useWorkflow()
    const {
      selectedWorkflowStage,
      role
    } = storeToRefs(workflowStore)
    const { mobileView } = useMobile()

    const { showGenericErrorModal } = useStatus()

    // counts for quick add by status
    const stageItemCounts = ref({})
    const stageItemsToInclude = ref([])
    const setInitialCounts = () => {
      stageItemsToInclude.value = selectedWorkflowStage.value.OrderedRoleWorkflowStageItems.filter(s => s.CompleteStatusType && s.StageItemStatus != 6)
      stageItemsToInclude.value.forEach(stageItem => {
        stageItemCounts.value[stageItem.RoleWorkflowStageItemId] = 0
      })
    }
    setInitialCounts()

    return {
      mobileView,
      selectedWorkflowStage,
      stageItemCounts,
      stageItemsToInclude,
      showGenericErrorModal,
      role
    }
  },
  data () {
    return {
      loadingQuickSelectCandidateCounts: true,
      loadingQuickSelectCandidates: false,
      preparingToAddCandidates: [],
      searchInputValue: '',
      showSearchCandidateModal: false,
      newCandidatesAddedToShare: []
    }
  },
  computed: {
    quickSelectCandidateOptions () {
      return this.stageItemsToInclude.map(stage => {
        return { text: stage.RoleWorkflowStageItemName, count: this.stageItemCounts[stage.RoleWorkflowStageItemId] }
      })
    }
  },
  mounted () {
    this.fetchStageItemCounts()
  },
  methods: {
    async quickAddRemoveCandidates (args) {
      this.loadingQuickSelectCandidates = true
      this.newCandidatesAddedToShare = [...this.candidatesAddedToShare]
      const stageItemIdToAdd = this.stageItemsToInclude.filter(s => s.RoleWorkflowStageItemName == args.type)[0].RoleWorkflowStageItemId
      if (args.isAdded) {
        try {
          const res = await roleV2Service.candidatesFromStageItemId(stageItemIdToAdd)
          res.data.forEach(cand => {
            cand = { ...cand, Status: stageItemIdToAdd, ...cand.CandidateAccount }
            const index = this.newCandidatesAddedToShare.findIndex(alreadyAddedCand => alreadyAddedCand.CandidateId == cand.CandidateId)
            if (index == -1) {
              this.newCandidatesAddedToShare.push(cand)
            }
          })
        } catch (err) {
          this.showGenericErrorModal({ showing: true })
        }
      } else {
        this.newCandidatesAddedToShare = this.newCandidatesAddedToShare.filter(c => c.Status != stageItemIdToAdd)
      }
      this.$emit('updateCandidatesAddedToShare', this.newCandidatesAddedToShare)
      this.loadingQuickSelectCandidates = false
    },
    openSearchCandidateModal () {
      this.showSearchCandidateModal = true
    },
    removeFromCandidatesAddedToShare (cand) {
      this.newCandidatesAddedToShare = this.candidatesAddedToShare.filter(c => c.CandidateId !== cand.CandidateId)
      this.$emit('updateCandidatesAddedToShare', this.newCandidatesAddedToShare)
    },
    async fetchStageItemCounts () {
      this.loadingQuickSelectCandidateCounts = true
      const listOfStageItemGuids = this.stageItemsToInclude.map(s => s.RoleWorkflowStageItemId)
      try {
        const res = await roleV2Service.getStageItemApplicantCounts({ Guids: listOfStageItemGuids })
        for (var [key, value] of Object.entries(this.stageItemCounts)) {
          this.stageItemCounts = { ...this.stageItemCounts, [key]: res.data[key] }
        }
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.loadingQuickSelectCandidateCounts = false
    }
  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}
.mobShareHeight {
  height: 43vh !important;
}
</style>
