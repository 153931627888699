<template>
  <div
    class="min-h-full pt-2 pt-md-4 px-3 px-md-5 w-1/1 text-black rounded"
    :class="mobileView ? 'mt-6 pt-2' : ''"
    style="height:calc(100vh - 150px);overflow-y:auto;margin-bottom:65px;overflow-x:hidden"
  >
    <div ref="scrollToDiv" style="height: 1px;">
      <div>
        <div :class="{ 'marginTopSections': mobileView === false }">
          <h3 class="primary-colour text-left font-weight-bold" :class="{ 'mobileTopSections': mobileView }">
            Public API <i
              style="cursor:pointer;"
              title="Open documentation in new window?"
              class="fa fa-info-circle"
              @click="openSwagger()"
            />
          </h3>
        </div>
        <div class="grey-text text-left mb-4 mx-0">
          <div style="height: 100%;margin-top:30px;">
            <iframe
              id="SwaggerFrame"
              style="width: 100%; height: 75vh; padding-bottom: 85px;"
              :src="swaggerUrl"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMobile } from '@/composables/useMobile'
import { PUBLIC_API_URL } from '@/common/config'

export default {
  name: '',
  setup () {
    const { mobileView } = useMobile()

    return {
      mobileView
    }
  },
  data () {
    return {
      swaggerUrl: PUBLIC_API_URL + 'swagger'
    }
  },
  methods: {
    openSwagger () {
      window.open(this.swaggerUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
.extraMarginTop {
  margin-top:35px !important;
}
</style>
