<template>
  <div style="height:100%;width:100%;">
    <div class="flex-grow-1 text-black rounded p-2" style="border: rgb(225, 225, 225) 2px solid;overflow-x:hidden;max-height:40vh;">
      <div v-if="loadingQuestionSet">
        <h3 class="primary-colour text-left font-weight-bold">
          Loading<i style="margin-left: 5px !important;" class="fa fa-spin fa-spinner ml-3" />
        </h3>
      </div>
      <div
        v-if="!loadingQuestionSet && questionSetList.length == 0"
        style="font-size:0.8rem;"
        class="text-center"
      >
        GO TO THE QUESTION SET SETTINGS TAB TO ADD A QUESTION SET
      </div>
      <div v-if="!loadingQuestionSet && questionSetList.length > 0">
        <b-row
          v-for="set in questionSetList"
          :key="set.QuestionSetId"
          no-gutters
          style="width:100%;height:60px;"
          :class="{'insetShadowPrimary' : selectedSetId === set.QuestionSetId }"
          class="cp text-bold text-black rounded text-center border-2 border-gray-400  bg-gray-100 my-1 flex-nowrap borderColorOnHover"
          @click="selectedSetId = set.QuestionSetId"
        >
          <div style="width: 10%; max-width: 40px; position: relative; border-radius: 5px" class="m-2 d-none d-sm-flex align-items-center justify-content-center">
            <FontAwesomeIcon icon="fa-circle-question fa-solid" style="font-size:1rem; position:absolute; top:4px; left:12px;" />
            <FontAwesomeIcon icon="fa-circle-question fa-solid" style="font-size:1rem; position:absolute; top:18px; left:4px;" />
            <FontAwesomeIcon icon="fa-circle-question fa-solid" style="font-size:1rem; position:absolute; top:18px; left:20px;" />
          </div>
          <b-col class="align-self-center twoLinedEllipsis ml-2" style="text-align:left;font-size:smaller;">
            {{ set.QuestionSetName }}
          </b-col>
          <div class="d-flex align-self-center align-items-center justify-content-center font-extrabold mr-3 px-1" style="font-size:smaller;height:30px;border:solid 2px #333;border-radius:15px;cursor:default;">
            <mdb-popover
              ref="popover"
              trigger="hover"
              :options="{placement: 'left'}"
            >
              <div slot="body">
                <div v-for="(q, i) in set.Questions" :key="i">
                  <span class="font-weight-bold text-black">{{ i+1 }}. </span><span>{{ q.Description }}</span>
                </div>
              </div>
              <div slot="reference" style="padding:10px;">
                <div class="d-block d-sm-none" style="font-size:smaller;">
                  {{ set.Questions.length }} Q{{ set.Questions.length > 1 ? '\'S' : '' }}
                </div>
                <div class="d-none d-sm-block" style="font-size:smaller;">
                  {{ set.Questions.length }} QUESTION{{ set.Questions.length > 1 ? 'S' : '' }}
                </div>
              </div>
            </mdb-popover>
          </div>
        </b-row>
      </div>
    </div>
    <b-row
      no-gutters
      class="text-black mt-2 mb-3 font-small "
      align-v="center"
    >
      <mdb-btn
        v-if="!loadingQuestionSet"
        class="p-2 ml-auto"
        :disabled="selectedSetId == 0"
        size="sm"
        style="width:100px;"
        @click="addQuestionSet()"
      >
        FINISH
      </mdb-btn>
      <mdb-btn
        v-else
        class="p-2 ml-auto"
        size="sm"
        style="width:100px;"
      >
        <i class="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </b-row>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import questionSetService from '@/api/services/questionSet.service.js'

export default {
  name: 'QuestionSetModalPage',
  props: {
    alreadyAddedQuestionSetIds: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    return {
      authData
    }
  },
  data () {
    return {
      loading: true,
      loadingQuestionSet: false,
      questionSetList: [],
      selectedSetId: 0
    }
  },
  created () {
    this.getQuestionSetList()
  },
  methods: {
    addQuestionSet () {
      this.loading = true
      this.$emit('addQuestionSet', this.selectedSetId)
    },
    async getQuestionSetList () {
      this.loadingQuestionSet = true
      try {
        const res = await questionSetService.getQuestionSetList()
        const filteredList = res.data.filter(q => !this.alreadyAddedQuestionSetIds.includes(q.QuestionSetId))
        this.questionSetList = this.convertResponse(filteredList)
      } catch (err) {
        //?
      }
      this.loadingQuestionSet = false
    },
    convertResponse (res) {
      try {
        // splits the questions by setId
        const setIds = []
        const setShells = []
        res.map(q => {
          if (!setIds.includes(q.QuestionSetId)) { setIds.push(q.QuestionSetId) }
        })
        setIds.map(id => {
          setShells.push({ QuestionSetId: id, QuestionSetName: '', Questions: [] })
        })
        res.map(q => {
          let index
          setShells.forEach((sh, i) => {
            if (sh.QuestionSetId === q.QuestionSetId) { index = i }
          })
          setShells[index] = { ...setShells[index], QuestionSetName: q.QuestionSetName, Questions: [...setShells[index].Questions, q] }
        })
        return setShells
      } catch (err) { 
        //?
      }
    }
  }
}
</script>
