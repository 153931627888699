<template>
  <mdb-popover
    v-if="userData.rle !== 'Inviter'"
    ref="popover"
    trigger="click"
    :options="{placement: 'left'}"
    style="cursor:pointer;"
    class="grey-text"
  >
    <div slot="body">
      <h6
        style="margin-left:-10px;padding:12px;"
        class="text-nowrap"
        @click="goToStageSetup()"
      >
        <FontAwesomeIcon icon="fa-gear fa-regular" class="grey-link mr-2"/><a class="grey-link"> Stage Setup</a>
      </h6>
      <h6
        v-if="role.IsComplete && !role.IsDeleted && stage.StageType != 3"
        class="text-nowrap cp"
        @click="prepareAndShowShareModal(stage)"
      >
        <i
          title="Share"
          class="fa fa-share-alt grey-link"
        />
        <a class="grey-link ml-2"> Share</a>
      </h6>
    </div>
    <mdb-icon
      slot="reference"
      style="font-size:1rem;"
      :style="{'color': !role.CloseDateIsPast ? userData.lbl.SecondaryColour : userData.lbl.PrimaryColour}"
      class="d-flex align-items-center justify-content-center ellipsisButtonHover text-black"
      icon="ellipsis-v"
    />
  </mdb-popover>
</template>

<script>
import { useRoles, useWorkflow, useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router/composables'

export default {
  name: 'StagePopover',
  props: {
    stage: {
      type: Object,
      default: () => {}
    },
    role: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {

    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)

    const router = useRouter()
    const goToStageSetup = () => {
      const stageType = props.stage.StageType
      if (stageType == 1) {
        router.push(`/roles/add_edit/one_way_stage_setup/${props.role.JobId}/${props.stage.RoleWorkflowStageId}?page=setting_up`).catch(() => {})
      } else if (stageType == 2) {
        router.push(`/roles/add_edit/two_way_stage_setup/${props.role.JobId}/${props.stage.RoleWorkflowStageId}?page=meeting_name_and_type`).catch(() => {})
      } else if (stageType == 3) {
        router.push(`/roles/add_edit/event_setup/${props.role.JobId}/${props.stage.RoleWorkflowStageId}?page=about`).catch(() => {})
      }
    }

    // SHARE MODAL LOGIC
    const workflowStore = useWorkflow()
    const { selectedWorkflowStage } = storeToRefs(workflowStore)
    const rolesStore = useRoles()
    const { roleSharingModalInitialData, roleSharingModalShowing } = storeToRefs(rolesStore)

    const prepareAndShowShareModal = () => {
      roleSharingModalInitialData.value = {
        roleId: props.role.JobId,
        shareType: props.stage.StageType == 1 ? 'one-way' : 'two-way',
        roleWorkflowStageId: props.stage.RoleWorkflowStageId,
        candToShare: null
      }
      selectedWorkflowStage.value = props.stage
      roleSharingModalShowing.value = true
    }


    return {
      goToStageSetup,
      prepareAndShowShareModal,
      userData
    }
  }
}
</script>
