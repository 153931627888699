<template>
  <div>
    <div class="w-full">
      <div class="md-form form-sm text-left m-0 p-0 mt-5">
        <div class="md-form form-sm d-flex align-items-center m-0 p-0">
          <font-awesome-icon
            :icon="['far', 'calendar-days']"
            class="mr-2 text-primary"
            style="margin-top:0px;cursor:help;"
            title="This is when the event will actually start"
          />
          <mdb-input
            v-model="startDate"
            disabled
            type="date"
            class="w-full"
            size="sm"
            label="Start date"
          />
        </div>
      </div>

      <div
        v-if="!isEvergreen"
        class="flex flex-row items-center"
      >
        <font-awesome-icon
          :icon="['far', 'calendar-days']"
          class="mr-2 text-primary"
          style="margin-top:-5px;cursor:help;"
          title="This is when the event ends"
        />
        <mdb-input
          v-model="endDate"
          disabled
          type="date"
          class="w-full"
          size="sm"
          placeholder="End date"
          label="End date"
        />
      </div>

      <div
        v-else-if="isEvergreen"
        class="flex mr-auto pb-2"
        style="transform: translateX(-18px);margin-bottom:10px;"
      >
        <mdb-input
          v-model="isEvergreen"
          type="checkbox"
          label="Evergreen Event"
          size="sm"
          disabled
        />
      </div>
    </div>

    <div class="flex flex-row">
      <mdb-btn
        class="btn btn-outline text-center flex mr-auto"
        size="sm"
        @click="$emit('cancel')"
      >
        CANCEL
      </mdb-btn>
      <mdb-btn
        :disabled="formInvalid"
        class="btn text-center flex ml-auto"
        size="sm"
        @click="$emit('next')"
      >
        NEXT
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue"
import { useEvent, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'
import moment from 'moment'

export default {
  name: 'AboutEventSetup',
  setup () {
    const eventStore = useEvent()
    const {
      selectedVideo,
      isEvergreen,
      selectedEvent
    } = storeToRefs(eventStore)

    const workflowStore = useWorkflow()
    const { role } = storeToRefs(workflowStore)

    const formInvalid = computed(() => {
      const invalid = (
        !role.value.OpenFrom ||
        !role.value.Expires
      )
      return invalid
    })

    // // NEEDS TO CHANGE AT SOME PPOINT
    // selectedEvent.value.StartDate = role.value.OpenFrom
    // selectedEvent.value.EndDate = role.value.Expires

    const startDate = ref(moment(role.value.OpenFrom).format("YYYY-MM-DD"))
    const endDate = ref(moment(role.value.Expires).format("YYYY-MM-DD"))

    return {
      startDate,
      endDate,
      selectedVideo,
      formInvalid,
      isEvergreen,
    }
  }
}
</script>
