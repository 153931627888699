import { ref, computed, watch } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useStatus } from './useStatus'
import { useUserData } from '@/pinia'
import roleV2Service from '@/api/services/roleV2.service'
import { useWorkflow } from './useWorkflow'
import moment from 'moment';

export const useRoleCandidate = defineStore('roleCandidateStore', () => {

  const workflowStore = useWorkflow()
  const { role, addRoleToRecentRoles, selectedWorkflowStage } = storeToRefs(workflowStore)

  const statusStore = useStatus()
  const { closeGenericConfirmModal, showGenericConfirmModal, showGenericErrorModal } = statusStore

  const userStore = useUserData()
  const { userData } = storeToRefs(userStore)

  var viewType = ref(0)

  // ROLE CANDIDATES TABLE
  const candidateTableData = ref({})
  const candidates = ref([])
  const candidate = ref({})
  const selectedCandidates = ref([])
  const selectedApplicantIds = computed(() => {
    let cands = candidates.value.filter(c => selectedCandidates.value.includes(c.CandidateId))
    return cands.map(a => a.ApplicantId)
  })
  const roleCandidateTableCount = ref(0)
  const currentPage = ref(1)
  const perPage = ref(userData.value.pge)
  const skip = computed(() => (currentPage.value - 1) * perPage.value)

  const getRoleCandidates = async (roleWorkflowStageItemId, skip, take, search = "", stageType, filterByDate = null) => {
    try {
      const res = await roleV2Service.getRoleCandidates(roleWorkflowStageItemId, skip, take, search, filterByDate)
      if (stageType === 1) {
        candidates.value = res.data.OneWayRoleCandidates
      } else if (stageType === 2) {
        candidates.value = res.data.TwoWayRoleCandidates
      } else if (stageType === 3) {
        candidates.value = res.data.EventRoleCandidates
      }
      roleCandidateTableCount.value = res.data.TotalCount
      return true
    } catch (err) {
      statusStore.setError({ status: true })
      return false
    }
  }

  const viewSchedule = async(roleWorkflowStageItemId, type) => {
    var dateVar = new Date('01-01-3000')
    if (type == 2) {
      dateVar = new Date('01-01-2099')
      viewType.value = 2
    } else if (type == 1) {
      viewType.value = 1
    }
    try {
      const res = await roleV2Service.getRoleCandidates(roleWorkflowStageItemId, skip.value, perPage.value, "", dateVar)
      candidates.value = res.data.TwoWayRoleCandidates
      if (res.data.TwoWayRoleCandidates == null) {
        candidates.value = res.data.EventRoleCandidates;
      }
      roleCandidateTableCount.value = res.data.TotalCount
      return true
    } catch (err) {
      statusStore.setError({ status: true })
      return false
    }
  }

  const getUninvitedRoleCandidates = async (roleWorkflowId, skip, take, search = "") => {
    try {
      const res = await roleV2Service.getUninvitedRoleCandidates(roleWorkflowId, skip, take, search)
      candidates.value = res.data.RoleCandidates
      roleCandidateTableCount.value = res.data.TotalCount
      return true
    } catch (err) {
      statusStore.setError({ status: true })
      return false
    }
  }

  const removeCandidatesFromTable = (candidateIdArray) => {
    candidates.value = candidates.value.filter(cand => !candidateIdArray.includes(cand.CandidateId))
  }

  const updateCandidateInTable = updatedCandidate => {
    const existingCandidateIndex = candidates.value.findIndex(c => c.CandidateId === updatedCandidate.CandidateId)
    if (existingCandidateIndex != -1) {
      candidates.value.splice(existingCandidateIndex, 1, updatedCandidate)
    }
  }

  // REVIEW MODAL
  const watchInterviewModal = ref(false)
  const reviewModalRoleWorkflow = ref({})
  const reviewModalSelectedStage = ref({})
  const reviewModalContext = ref('')

  // ONE WAY
  // UNLOCK INTERVIEW

  const confirmUnlockInterview = async (candidateId, roleWorkflowStageId, callBack = () => {}) => {
    const JSONData = {
      RoleId: role.value.JobId,
      CandidateId: candidateId,
      RoleWorkflowStageId: roleWorkflowStageId
    }
    try {
      await roleV2Service.unlockOneWayStageInterview(JSONData)
      closeGenericConfirmModal()
      addRoleToRecentRoles.value(role.value.JobId)
      callBack()
    } catch (err) {
      showGenericErrorModal()
      closeGenericConfirmModal()
    }
  }
  const unlockInterview = async (cand, roleWorkflowStageId, callBack = () => {}) => {
    candidate.value = cand
    showGenericConfirmModal({
      showing: true,
      confirmTitle: 'UNLOCK',
      confirmMessage: 'Are you sure you want to unlock this interview?',
      confirmButtonMessage: 'CONFIRM',
      confirmButtonFunction: async () => confirmUnlockInterview(cand.CandidateId, roleWorkflowStageId, callBack)
    })
  }

  // RESEND ONE WAY INVITE
  const confirmResendOneWayInvite = async (cand, roleWorkflowStageId, callBack = () => {}) => {
    const JSONData = {
      RoleId: role.value.JobId,
      CandidateId: cand.CandidateId,
      RoleWorkflowStageId: roleWorkflowStageId
    }
    try {
      await roleV2Service.resendOneWayStageInviteEmail(JSONData)
      await confirmUnlockInterview(cand.CandidateId, roleWorkflowStageId, callBack)
    } catch (err) {
      closeGenericConfirmModal()
      showGenericErrorModal()
    }
  }
  const resendInviteOneWayInvite = (cand, roleWorkflowStageId, callBack = () => {}) => {
    candidate.value = cand
    showGenericConfirmModal({
      showing: true,
      confirmTitle: 'RESEND INVITE EMAIL',
      confirmMessage: 'Are you sure you want to resend the candidate a copy of their invitation email? Note: this will not affect any recorded videos. Also note that invitations on an expired role will not be present.',
      confirmButtonMessage: 'RESEND',
      confirmButtonFunction: () => confirmResendOneWayInvite(cand, roleWorkflowStageId, callBack)
    })
  }

  // RESETTING ONE WAY
  const confirmResetInterview = async (roleWorkflowStageId, callback = () => {}) => {
    const JSONData = {
      RoleId: role.value.JobId,
      CandidateId: candidate.value.CandidateId,
      RoleWorkflowStageId: roleWorkflowStageId
    }
    try {
      await roleV2Service.resetOneWayStageInterview(JSONData)
      closeGenericConfirmModal()
      addRoleToRecentRoles.value(role.value.JobId)
      callback()
    } catch (err) {
      closeGenericConfirmModal()
      showGenericErrorModal()
    }
    selectedCandidates.value = []
  }
  const resetOneWay = (cand, roleWorkflowStageId, callBack = () => {}) => {
    candidate.value = cand
    showGenericConfirmModal({
      showing: true,
      confirmTitle: 'RESET INTERVIEW',
      confirmMessage: `Are you sure you want to reset this invitation? <br /><br />
      <span class="font-weight-bold">Note: </span>
      <br /><br />Recorded videos will be deleted.
      <br /><br />Invitations on an expired role will not be reset.
      <br /><br />Any scoring points given while reviewing will be reset.`,
      confirmButtonMessage: 'RESET',
      confirmButtonFunction: () => confirmResetInterview(roleWorkflowStageId, callBack)
    })
  }

  // BULK STATUS CHANGE
  const bulkSetCandidateStatus = async (newStageItemId, callback, applicantId= null) => {
    const JSONData = {
      RoleWorkflowStageItemId: newStageItemId,
      RoleId: role.value.JobId,
      ApplicantIds: applicantId ?  [applicantId] : selectedApplicantIds.value
    }
    try {
      await roleV2Service.bulkSetCandidateStatus(JSONData)
      addRoleToRecentRoles.value(role.value.JobId)
      callback()
    } catch (err) {
      closeGenericConfirmModal()
      showGenericErrorModal()
    }
  }

  // TWO WAY
  const meetingDateModal = ref(false)
  const resettingTwoWay = ref(false)

  // RESEND TWO WAY INVITE
  const confirmResendTwoWayInvite = async (cand, callBack = () => {}) => {
    const JSONData = {
      RoleId: role.value.JobId,
      CandidateId: cand.CandidateId,
      RoleWorkflowStageId: candidateTableData.value.RoleWorkflowStageId
    }
    try {
      await roleV2Service.resendTwoWayStageInviteEmail(JSONData)
      closeGenericConfirmModal()
    } catch (err) {
      closeGenericConfirmModal()
      showGenericErrorModal()
    }
  }
  const resendTwoWayConfirmationEmail = (cand, callBack = () => {}) => {
    candidate.value = cand
    showGenericConfirmModal({
      showing: true,
      confirmTitle: 'RESEND INVITE EMAIL',
      confirmMessage: 'Are you sure you want to resend the candidate a copy of their invitation email?',
      confirmButtonMessage: 'RESEND',
      confirmButtonFunction: () => confirmResendTwoWayInvite(cand, callBack)
    })
  }
  // RESET TWO WAY ROOM
  const confirmResetTwoWayRoom = async (cand) => {
    try {
      await roleV2Service.resetTwoWayRoom(cand.MeetingId)
      closeGenericConfirmModal()
    } catch (err) {
      closeGenericConfirmModal()
      showGenericErrorModal()
    }
  }
  const resetTwoWayRoom = (cand) => {
    candidate.value = cand
    showGenericConfirmModal({
      showing: true,
      confirmTitle: 'RESET ROOM',
      confirmMessage: 'Are you sure you want to reset the room?',
      confirmButtonMessage: 'RESET',
      confirmButtonFunction: () => confirmResetTwoWayRoom(cand)
    })
  }

  // RESEND SCHEDULER INVITE
  const confirmResendSchedulerInvite = async (cand, roleWorkflowStageId) => {
    const JSONData = {
      RoleId: role.value.JobId,
      CandidateId: cand.CandidateId,
      RoleWorkflowStageId: roleWorkflowStageId
    }
    try {
      await roleV2Service.resendSchedulerInviteEmail(JSONData)
      closeGenericConfirmModal()
    } catch (err) {
      closeGenericConfirmModal()
      showGenericErrorModal()
    }
  }
  const resendSchedulerInviteEmail = (cand, roleWorkflowStageId) => {
    candidate.value = cand
    showGenericConfirmModal({
      showing: true,
      confirmTitle: 'RESEND BOOKING INVITE EMAIL',
      confirmMessage: 'Are you sure you want to resend the candidate a copy of their booking invitation email?',
      confirmButtonMessage: 'RESEND',
      confirmButtonFunction: () => confirmResendSchedulerInvite(cand, roleWorkflowStageId)
    })
  }

  // RESET SCHEDULER INVITE
  const confirmResetSchedulerInvite = async (schedulerInviteId, callback) => {
    try {
      await roleV2Service.resetSchedulerInvite(schedulerInviteId)
      closeGenericConfirmModal()
      callback()
    } catch (err) {
      closeGenericConfirmModal()
      showGenericErrorModal()
    }
  }
  const resetSchedulerInvite = (schedulerInviteId, callBack = () => {}) => {
    showGenericConfirmModal({
      showing: true,
      confirmTitle: 'RESET SCHEDULED BOOKING',
      confirmMessage: 'Are you sure you want to reset this current timeslot? The existing booking will be cancelled and the candidate will be sent an email inviting them to reschedule.',
      confirmButtonMessage: 'RESET',
      confirmButtonFunction: () => confirmResetSchedulerInvite(schedulerInviteId, callBack)
    })
  }

  // RESEND SCHEDULER CONFIRMATION
  const confirmResendSchedulerConfirmation = async (cand, code) => {
    const JSONData = {
      RoleId: role.value.JobId,
      CandidateId: cand.CandidateId,
      RoleWorkflowStageId: candidateTableData.value.RoleWorkflowStageId,
      Code: code
    }
    try {
      await roleV2Service.resendSchedulerConfirmationEmail(JSONData)
      closeGenericConfirmModal()
    } catch (err) {
      closeGenericConfirmModal()
      showGenericErrorModal()
    }
  }
  const resendSchedulerConfirmationEmail = (cand, code = () => {}) => {
    candidate.value = cand
    showGenericConfirmModal({
      showing: true,
      confirmTitle: 'RESEND BOOKING CONFIRMATION EMAIL',
      confirmMessage: 'Are you sure you want to resend the candidate a copy of their booking confirmation email?',
      confirmButtonMessage: 'RESEND',
      confirmButtonFunction: () => confirmResendSchedulerConfirmation(cand, code)
    })
  }

  // EXTEND STAGE INVITE DEADLINE
  const extendInviteDeadlineModalShowing = ref(false)
  const extendDeadline = async (newDate) => {
    const JSONData = {
      NewExpiryDate: newDate,
      CandidateId: candidate.value.CandidateId,
      RoleWorkflowStageId: candidateTableData.value.RoleWorkflowStageId
    }
    try {
      if (selectedWorkflowStage.value.StageType == 1) {
        // ONE WAY
        return await roleV2Service.extendOneWayInviteDeadline(JSONData)
      } else if (selectedWorkflowStage.value.IsSelfSchedule) {
        // SCHEDULER
        return await roleV2Service.extendSchedulerInviteDeadline(JSONData)
      }
    } catch (err) {
      showGenericErrorModal()
    }
  }


  // date filter counts
  const dateFilterCounts = ref([])
  const getDateFilterCount = async (dateTime, stageId) => {
    const JSONData = {
      Date: dateTime,
      RoleWorkflowStageItemId: stageId,
      AccountId: role.value.AccountId,
    }
    try {
      const res = await roleV2Service.getDateFilterCount(JSONData)
      let momentDay = moment(dateTime).format('YYYY-MM-DD')
      dateFilterCounts.value.push({ day: momentDay, count: res.data })
    } catch (err) {
      //?
    }
  }

  watch(role, () => {
    dateFilterCounts.value = []
  }, { deep: true })

  return {
    candidateTableData,
    candidates,
    candidate,
    extendInviteDeadlineModalShowing,
    extendDeadline,
    selectedCandidates,
    resetOneWay,
    updateCandidateInTable,
    getRoleCandidates,
    roleCandidateTableCount,
    getUninvitedRoleCandidates,
    removeCandidatesFromTable,
    skip,
    perPage,
    viewType,
    currentPage,
    unlockInterview,
    meetingDateModal,
    resendInviteOneWayInvite,
    bulkSetCandidateStatus,
    selectedApplicantIds,
    watchInterviewModal,
    reviewModalRoleWorkflow,
    reviewModalContext,
    reviewModalSelectedStage,
    confirmResendOneWayInvite,
    resettingTwoWay,
    resendTwoWayConfirmationEmail,
    resendSchedulerInviteEmail,
    confirmResendSchedulerInvite,
    resetTwoWayRoom,
    resetSchedulerInvite,
    confirmResetSchedulerInvite,
    resendSchedulerConfirmationEmail,
    getDateFilterCount,
    dateFilterCounts,
    viewSchedule
  }
})
