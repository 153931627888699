<template>
  <transition
    :duration="300"
    name="slide-fade"
    mode="out-in"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-if="show" />
  </transition>
</template>

<script>
export default {
  name: 'SlideFade',
  data () {
    return {
      show: true
    }
  }
}
</script>

<!--add "scoped" attribute to limit CSS to this component only-->
<style scoped>
.slide-fade-enter-active {
  opacity: 1;
  transition: all .4s ease;
}
.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}
</style>
