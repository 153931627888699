<template>
  <div>
    <mdb-popover
      trigger="hover"
      :options="{placement: tooltipDirection}"
      style="cursor:pointer;width:fit-content; max-width:400px;"
      class="grey-text"
    >
      <div slot="body" style="height:fit-content !important;width:fit-content; max-width:400px;">
        <h5 style="height:fit-content;padding:10px;width:fit-content; max-width:400px;" class="grey-link d-flex flex-column">
          <strong
            v-if="bulletPointArray.length > 0 && toolTipSummary != ''"
            class="mb-2"
            v-html="toolTipSummary"
          >{{ toolTipSummary }}</strong>
          <div v-else-if="toolTipSummary != ''" v-html="toolTipSummary">
            {{ toolTipSummary }}
          </div>
          <div v-if="bulletPointArray.length > 0">
            <div
              v-for="(tip, i) in bulletPointArray"
              :key="i"
              class="mt-1"
              v-html="tip"
            >
              - {{ tip }}
            </div>
          </div>
        </h5>
      </div>
      <FontAwesomeIcon
        slot="reference"
        icon="fa-regular fa-circle-info"
        style="color:#555;font-size:1rem;"
        class="ellipsisButtonHover"
      />
      <mdb-icon />
    </mdb-popover>
  </div>
</template>

<script>

export default {
  name: 'ToolTip',
  props: {
    bulletPointArray: {
      type: Array,
      default: () => []
    },
    toolTipSummary: {
      type: String,
      default: ''
    },
    // can be one of either top, right, bottom, left
    tooltipDirection: {
      type: String,
      default: 'left'
    }
  },
  data () {
    return {
      showing: false
    }
  },
  created () {
    setInterval(() => { this.showing = !this.showing })
  },
  methods: {
    async createAndCopyEmbedLink () {
      if (!this.srcIsAsync) {
        await navigator.clipboard.writeText(this.htmlEmbedString)
      } else {
        const res = await this.fetchSrc()
        this.asyncSrc = res
        await navigator.clipboard.writeText(this.asyncHTMLEmbedString)
      }
      this.alertCopied = true
      setTimeout(() => {
        this.alertCopied = false
        this.$emit('closePopover')
      }, 2000)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
