<template>
  <div style="width:100%; flex-grow: 1; overflow: auto;" class="px-3 px-md-4">
    <div class="font-weight-bold text-left my-3 d-flex align-items-center justify-content-between" style="height:50px">
      <div class="d-flex align-items-center">
        <span
          v-if="logo != '' && clientName != ''"
          class="d-none d-sm-flex align-items-center mr-2"
          style="height:50px"
        >
          <!-- <img style="height:50px;max-height:50px;object-fit:cover;max-width:100px;object-fit:contain;" class="mx-2" :src="logo" /> -->
          <div>{{ clientName }} -</div>
        </span>
        <span class="mr-2">Spotlights</span>
        <i
          v-if="spotlightsLoading"
          style="margin-left: 5px !important;"
          class="fa fa-spin fa-spinner ml-3"
        />
      </div>
      <ExpandableSearchBar
        @search="search = $event"
      />
    </div>
    <div class="d-flex align-items-center mt-0 mb-3" style="background:#fff;z-index:300;justify-content:space-evenly;">
      <div
        class="d-flex align-items-center justify-content-center cursor-pointer text-lg grey-text text-center pb-2"
        style="width:50%;"
        :class="{ 'default': filter == 'OPEN', 'notSelected': filter != 'OPEN'}"
        @click="setFilter('OPEN')"
      >
        <span>OPEN</span>
        <span class="column-header-count font-weight-bold ml-3 d-none d-sm-flex align-items-center justify-content-center">{{ openSpotlights.length }}</span>
      </div>
      <div
        class="d-flex align-items-center justify-content-center cursor-pointer text-lg grey-text text-center pb-2"
        style="width:50%;"
        :class="{ 'default': filter == 'EVERGREEN', 'notSelected': filter != 'EVERGREEN'}"
        @click="setFilter('EVERGREEN')"
      >
        <FontAwesomeIcon icon="fa-solid fa-leaf" class="mr-3" />
        <span>EVERGREEN</span>
        <span class="column-header-count font-weight-bold ml-3 d-none d-sm-flex align-items-center justify-content-center">{{ evergreenSpotlights.length }}</span>
      </div>
      <div
        class="d-flex align-items-center justify-content-center cursor-pointer text-lg grey-text text-center pb-2"
        style="width:50%;"
        :class="{ 'default': filter == 'CLOSED', 'notSelected': filter != 'CLOSED'}"
        @click="setFilter('CLOSED')"
      >
        <span>CLOSED</span>
        <span class="column-header-count font-weight-bold ml-3 d-none d-sm-flex align-items-center justify-content-center">{{ closedSpotlights.length }}</span>
      </div>
    </div>

    <SpotlightsListTable
      :filter="filter"
      :spotlights="searchedSpotlights"
      :spotlights-loading="spotlightsLoading"
      :spotlight-total-length="spotlights.length"
      @getAllSpotlights="getAllSpotlights()"
      @getRecentSpotlights="$emit('getRecentSpotlights')"
      @showAddEditModal="showAddEditModal($event)"
    />

    <AddEditSpotlightModal
      :client-id="urlQueryClientId"
      :add-edit-spotlight-modal-showing="addEditSpotlightModalShowing"
      :spotlight-id="spotlightId"
      @closeAddEditModal="closeAddEditModal()"
      @setSpotlightId="spotlightId = $event"
      @addClientToRecentClients="$emit('addClientToRecentClients', $event)"
      @addSpotlightToRecentSpotlights="$emit('addSpotlightToRecentSpotlights', $event)"
      @setFilter="filter = $event"
    />
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import clientPortalService from '@/api/services/clientPortal.service'

import SpotlightsListTable from './spotlightsListTable.vue'
import AddEditSpotlightModal from './addEditSpotlightModal.vue'
import ExpandableSearchBar from '@/components/UI/expandableSearch.vue'

export default {
  name: 'SpotlightListMainSection',
  components: {
    SpotlightsListTable,
    AddEditSpotlightModal,
    ExpandableSearchBar
  },
  props: {
    addEditSpotlightModalShowing: Boolean
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()

    return {
      authData,
      showGenericErrorModal
    }
  },
  data () {
    return {
      spotlights: [],
      spotlightId: 0,
      search: '',
      spotlightsLoading: true,
      filter: 'OPEN',
      spotlightsLoaded: false,
      logo: '',
      clientName: ''
    }
  },
  computed: {
    searchedSpotlights () {
      if (this.search === '') { return this.filteredSpotlights }
      return this.filteredSpotlights.filter(c => c.Name.toLowerCase().includes(this.search.toLowerCase()))
    },
    evergreenSpotlights () { return this.spotlights.filter(c => c.IsEvergreen) },
    closedSpotlights () {
      return this.spotlights.filter(c => {
        if (!c.IsEvergreen) {
          return new Date(c.ExpiryDate).getTime() < new Date().getTime()
        }
      })
    },
    openSpotlights () {
      return this.spotlights.filter(c => {
        if (!c.IsEvergreen) {
          return new Date(c.ExpiryDate).getTime() > new Date().getTime()
        }
      })
    },
    filteredSpotlights () {
      if (this.filter === 'OPEN') {
        return this.openSpotlights
      } else if (this.filter === 'EVERGREEN') {
        return this.evergreenSpotlights
      } else if (this.filter === 'CLOSED') {
        return this.closedSpotlights
      }
      return []
    },
    urlQueryClientId () {
      if (this.$route.query.clId === undefined) {
        return 0
      } else {
        return Number(this.$route.query.clId)
      }
    },
    urlQuerySpotlightId () {
      if (this.$route.query.slId === undefined) {
        return 0
      } else {
        return Number(this.$route.query.slId)
      }
    }
  },
  mounted () {
    this.getAllSpotlights()
    this.getClientPortalClient()
  },
  methods: {
    setFilter (filter) {
      this.filter = filter
    },
    async getAllSpotlights () {
      this.spotlightsLoading = true
      try {
        const res = await clientPortalService.getAllSpotlights(this.urlQueryClientId)
        this.spotlights = res.data
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.spotlightsLoading = false
    },
    showAddEditModal (spotlightId) {
      this.spotlightId = spotlightId
      this.$emit('addEditSpotlightModalShowing', true)
    },
    closeAddEditModal () {
      this.spotlightId = 0
      this.$emit('addEditSpotlightModalShowing', false)
      this.getAllSpotlights()
    },
    async getClientPortalClient () {
      try {
        const res = await clientPortalService.getClientPortalClient(this.urlQueryClientId)
        this.clientName = res.data.Name
        this.logo = res.data.Logo
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
    }
  }
}
</script>

<style scoped>
  .notSelected {
    border-bottom: solid 1px #c0c0c0;
  }
</style>
