<template>
  <div>
    <div
      class="p-2 mb-2 mx-2 hover:bg-gray-100 cp"
      style="border: solid 2px #e1e1e1;border-radius: 10px;"
      @click="$emit('select', number)"
    >
      <div class="d-flex flex-row align-items-center">
        <div class="border rounded-xl w-10 h-10 p-2">
          <span class> {{ number }} </span>
        </div>

        <span class="mx-2 text-left whitespace-pre-wrap"> {{ question }}</span>

        <div
          class="ml-auto d-flex align-items-center flex-wrap justify-content-end"
          style=""
        >
          <div v-if="!(Score == 0 && MaxScore == 0)" class="border rounded-xl p-2 my-1">
            <span class="text-nowrap"> {{ Score }} / {{ MaxScore }} </span>
          </div>
          <div v-if="!(Score == 0 && MaxScore == 0)" class="border rounded-xl p-2 ml-2 my-1">
            <span> {{ percentage }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      default: 0
    },
    question: {
      type: String,
      default: ''
    },
    Score: {
      type: Number,
      default: 0
    },
    MaxScore: {
      type: Number,
      default: 1
    },
    questionId: {
      type: Number,
      default: 0
    }
  },

  computed: {
    percentage () {
      if (this.Score === 0) {
        return `${0}%`
      } else {
        const p = (this.Score / this.MaxScore) * 100
        return `${p.toFixed(0)}%`
      }
    }
  }
}
</script>
