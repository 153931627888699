<template>
  <div
    style="height:85vh"
    class="overflow-y-scroll mt-6"
  >
    <div class="flex justify-between">
      <CircleNavigationItem
        v-for="(nav, i) in navigationItems"
        :key="i"
        class="flex-row justify-between"
        :query-to-match="$route.query.page"
        :nav-item-count="navigationItems.length"
        :nav-item="nav"
        @clicked="handleButtonNavigation(nav.Query)"
      />
    </div>

    <!-- About / Location / Slot Management -->
    <div class="px-12">
      <component
        :is="queryMatches($route.query.page)"
        @next="next()"
        @addEvent="addEvent()"
        @cancel="cancel()"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue"
import { useEvent, useWorkflow, useRoles } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useRouter, useRoute } from 'vue-router/composables'

import AddLocation from '@/views/events/form/location/addLocation'
import About from '@/views/events/form/setup/about'
import Location from '@/views/events/form/setup/location'
import CircleNavigationItem from '@/components/misc/circleNavigationItems.vue'

export default {
  name: "EventDetail",
  components: {
    About,
    AddLocation,
    CircleNavigationItem,
    Location
  },
  setup() {
    const eventStore = useEvent()
    const { editEventSetup } = useEvent()
    const {
      selectedEvent,
      addEventLocationForm,
      introVideoModal,
      selectedVideo,
      introTextForm,
      locationsDropdown,
      selectedLocation
    } = storeToRefs(eventStore)

    const workflowStore = useWorkflow()
    const { getRoleWorkflowNoSetupDetails, getSelectedStage, getRoleWithStages } = useWorkflow()
    const { currentWorkflow, selectedWorkflowStage, role } = storeToRefs(workflowStore)

    const { getRecentRoles } = useRoles()

    const router = useRouter()
    const route = useRoute()

    const onlineEvent = ref(false)
    const loading = ref(false)

    const cancel = async () => router.push(`/roles/add_edit/detail/${route.params.jobId}`)
    const handleButtonNavigation = (page) => router.push({ query: { page } })

    const queryMatches = (query) => {
      if (route.query.page === query) return route.query.page
    }

    const next = async () => {
      const code = await addEvent()
      if (code === 200) {
        if (route.query.page === 'about') router.push({ query: { page: 'location' }})
        else if (route.query.page === 'location') {
          //await getRecentRoles()
          router.push(`/roles/add_edit/scheduler_setup/${route.params.jobId}/${route.params.roleWorkflowStageId}/slots`)
        }
      }
    }

    const addEvent = async () => {
      let payload = {
        RoleWorkflowStageId: selectedEvent.value.RoleWorkflowStageId,
        StartDate: role.value.OpenFrom,
        EndDate: role.value.Expires,
        GoEventLocationIds: selectedLocation.value,
        CustomUrl: selectedEvent.value.CustomUrl,
        Step: currentStep.value
      }
      return await editEventSetup(payload)
    }

    // Navigation
    const currentStep = computed(() => {
      if (route.query.page === 'about') return 1
      else if (route.query.page === 'location') return 2
      return null
    })

    const actualStep = computed(() => selectedWorkflowStage.value?.Step)

    const navigationItems = computed(() => {
      return [
        { Step: 1, Query: 'about', Text: 'About This Event', ActualStep: actualStep.value },
        { Step: 2, Query: 'location', Text: 'Location', ActualStep: actualStep.value }
      ]
    })

    const load = async () => {
      loading.value = true
      if (!role.value.JobId) {
        await getRoleWithStages(route.params.jobId)
      }
      await getRoleWorkflowNoSetupDetails(role.value.RoleWorkflowId)
      await getSelectedStage(route.params.roleWorkflowStageId)
      loading.value = false
    }

    if (!currentWorkflow.value || !selectedWorkflowStage.value.RoleWorkflowStageId) load()

    return {
      addEvent,
      addEventLocationForm,
      cancel,
      handleButtonNavigation,
      introTextForm,
      introVideoModal,
      locationsDropdown,
      navigationItems,
      next,
      selectedEvent,
      selectedVideo,
      onlineEvent,
      queryMatches,
      selectedWorkflowStage,
      actualStep
    }
  }
}
</script>

<style scoped>
label[data-v-189f23e2].active {
  color: #999 !important;
}
</style>
