<template>
  <div>
    <mdb-modal
      id="videoIntroHistoryModalShowing"
      :show="videoIntroHistoryModalShowing"
      @close="$emit('videoIntroHistoryModalShowing', false)"
    >
      <mdb-modal-header>
        <span class="font-weight-bold py-2 text-left">{{ selectedRecording.RecordingName.toUpperCase() }} - HISTORY</span>
      </mdb-modal-header>
      <mdb-modal-body style="height:400px;overflow-y:auto">
        <mdb-icon
          v-if="loading"
          icon="fa fa-pulse fa-spinner"
          class="mx-auto"
        />
        <div
          v-if="historyItems.length == 0 && !loading "
          class="mt-4"
          style="font-size:0.7rem;color:#686868"
        >
          There is no history for this recording yet
        </div>
        <table
          v-if="historyItems.length > 0 && !loading"
          class="table table-sm table-hover text-nowrap"
          style="color:#686868"
        >
          <thead>
            <tr class="" style="border: 3px solid #555555;box-sizing:border-box">
              <th scope="col">
                Date
              </th>
              <th scope="col">
                Time
              </th>
              <th class="d-none d-sm-block" scope="col">
                Sent To
              </th>
              <th scope="col">
                Watched
              </th>
            </tr>
          </thead>
          <tbody style="max-height:300px;overflow-y:auto;" class="mt-5">
            <tr
              v-for="item in historyItems"
              :key="item.GoVideoIntroHistoryId"
              style="border-bottom: 3px solid #e1e1e1"
            >
              <td v-if="item.Type == 'Reset'">
                <FontAwesomeIcon
                  label="Reset"
                  icon="fa-solid fa-arrow-rotate-left"
                  class="mr-2"
                /> {{ new Date(item.DateAdded).toLocaleDateString([userLanguage]) }}
              </td>
              <td v-if="item.Type == 'Copy'">
                <FontAwesomeIcon
                  label="Copy"
                  icon="fa-solid fa-link-simple"
                  class="mr-2"
                /> {{ new Date(item.DateAdded).toLocaleDateString([userLanguage]) }}
              </td>
              <td v-if="item.Type == 'Share'" class="d-flex align-items-center justify-content-center">
                <mdb-popover
                  trigger="click"
                  :options="{placement: 'bottom'}"
                  style="cursor:pointer;"
                  class="grey-text"
                >
                  <div slot="body">
                    <h5 class="text-nowrap">
                      {{ item.Email }}
                    </h5>
                  </div>
                  <mdb-icon
                    slot="reference"
                    class="ellipsisButtonHover mr-2 d-inline"
                    icon="fa-solid fa-envelope"
                  />
                </mdb-popover>
                {{ new Date(item.DateAdded).toLocaleDateString([userLanguage]) }}
              </td>
              <td>{{ new Date(item.DateAdded).toLocaleTimeString([userLanguage]).substring(0, 5) }}</td>
              <td class="d-none d-sm-block">
                {{ item.Email || '' }}
              </td>
              <td v-if="(item.Type == 'Share' || item.Type == 'Copy') && item.WatchCount > 0">
                <FontAwesomeIcon icon="fa-solid fa-circle-check" />
              </td>
              <td v-if="(item.Type == 'Share' || item.Type == 'Copy') && item.WatchCount == 0">
                <FontAwesomeIcon icon="fa-solid fa-circle-xmark" />
              </td><td /><td v-if="item.Type == 'Reset'" />
            </tr>
          </tbody>
        </table>
      </mdb-modal-body>
    </mdb-modal>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import videoIntroService from '@/api/services/videointro.service'
import { userLanguage } from '@/common/userLanguage'

export default {
  name: 'VideoIntroHistoryModal',
  components: { },
  props: {
    selectedRecording: {
      type: Object,
      default: () => {}
    },
    videoIntroHistoryModalShowing: Boolean
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    return {
      authData
    }
  },
  data () {
    return {
      historyItems: [],
      userLanguage,
      loading: true
    }
  },
  watch: {
    videoIntroHistoryModalShowing () {
      if (this.videoIntroHistoryModalShowing === true) {
        this.fetchIntroHistory()
      }
    }
  },
  methods: {
    async fetchIntroHistory () {
      try {
        const res = await videoIntroService.getVideoIntroHistory(this.selectedRecording.GoVideoIntroId)
        this.historyItems = res.data
      } catch (err) {
        //?
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
@media (max-width: 576px) {
  #videoIntroHistoryModalShowing .modal-dialog .modal-content {
    margin:5px !important;
    width:calc(100vw - 10px) !important;
    height: calc(100vh - 10px) !important;
  }
  #videoIntroHistoryModalShowing .modal-dialog {
    margin:0px !important;
  }
  .historyBorderColor {
    border: 1px solid #555555 !important;
  }
}
</style>
