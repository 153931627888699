import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useUserData = defineStore('userStore', () => {
  const authData = ref({})
  const userData = ref({
    aut: false,
    lbl: {
      HeaderColour: '#ff7e00',
      PrimaryColour: '#ff7e00',
      SecondaryColour: '#ffffff',
      LinkColour: '#ffffff',
      AltLkColour: '#ffffff',
      AltBgColour: '#ffffff',
      Logo: 'https://go.shineinterview.com/img/shine-logo-white.png',
      NavColour: '#ff7e00',
      OpacityColour: '#FFA200'
    }
  })

  const landingPage = ref('')
  const recordVideo = ref(false)
  const refreshState = ref(false)
  const videoLibraryFilter = ref('All')
  const helpArticleFilter = ref('')
  const leftColumnActive = ref(false) // does this ever change???
  const editRole = ref({}) // delete this?
  const rid = ref(0)
  const lockout = ref(null)

  const clearUserData = () => {
    userData.value = {
      aut: false,
      lbl: {
        HeaderColour: userData.value.lbl.HeaderColour,
        PrimaryColour: userData.value.lbl.PrimaryColour,
        SecondaryColour: userData.value.lbl.SecondaryColour,
        LinkColour: userData.value.lbl.LinkColour,
        AltLkColour: userData.value.lbl.AltLkColour,
        AltBgColour: userData.value.lbl.AltBgColour,
        Logo: userData.value.lbl.Logo,
        NavColour: userData.value.lbl.NavColour,
        OpacityColour: userData.value.lbl.OpacityColour
      }
    }
  }

  return {
    authData,
    clearUserData,
    editRole,
    helpArticleFilter,
    landingPage,
    leftColumnActive,
    lockout,
    recordVideo,
    rid,
    userData,
    videoLibraryFilter,
    refreshState
  }
}, {
  persist: true
})
