<template>
  <div
    class="col-sm templateDiv"
    style="margin-bottom:52px !important;"
    :class="{ 'notMargTop' : mobileView }"
  >
    <div style="text-align:left;padding:2px;margin-top:0px;" class="h-full admnMargTop">
      <div class="grid grid-cols-4 w-1/1 mt-8 border-bottom">
        <!--accounts-->
        <div
          class="cursor-pointer text-lg grey-text text-center"
          :class="{ 'default': section == 'acc', 'mobileSize': mobileView === true }"
          style="font-size: clamp(0.6rem, 1.2vw, 1.2rem);"
          @click="setSection('acc')"
        >
          <span v-if="mobileView === false">ACCOUNTS</span>
          <span v-else><i
            class="fa fa-users"
            title="Accounts"
            style="font-size:large"
          /></span>
          <i
            v-if="accountsLoading"
            style="margin-left: 5px !important;"
            class="fa fa-spin fa-spinner ml-3 mobSpinner"
          />
        </div>

        <!--master acount groups-->
        <div
          class="cursor-pointer text-lg grey-text text-center"
          :class="{ 'default': section == 'mag', 'mobileSize': mobileView === true }"
          style="font-size: clamp(0.6rem, 1.2vw, 1.2rem);"
          @click="setSection('mag')"
        >
          <span v-if="mobileView === false">MASTER ACCOUNT GROUPS</span>
          <span v-else><i
            class="fa fa-user-plus"
            title="Master Account Groups"
            style="font-size:large"
          /></span>
          <i
            v-if="masterAccountGroupsLoading"
            style="margin-left: 5px !important;"
            class="fa fa-spin fa-spinner ml-3 mobSpinner"
          />
        </div>

        <!--notifications-->
        <div
          class="cursor-pointer text-lg grey-text text-center"
          :class="{ 'default': section == 'not', 'mobileSize': mobileView === true }"
          style="font-size: clamp(0.6rem, 1.2vw, 1.2rem);"
          @click="setSection('not')"
        >
          <span v-if="mobileView === false">NOTIFICATIONS</span>
          <span v-else><i
            class="fa fa-bullhorn"
            title="Notifications"
            style="font-size:large"
          /></span>
          <i
            v-if="notificationsLoading"
            style="margin-left: 5px !important;"
            class="fa fa-spin fa-spinner ml-3 mobSpinner"
          />
        </div>

        <!--logs-->
        <div
          class="cursor-pointer text-lg grey-text text-center"
          :class="{ 'default': section == 'log', 'mobileSize': mobileView === true }"
          style="font-size: clamp(0.6rem, 1.2vw, 1.2rem);"
          @click="setSection('log')"
        >
          <span v-if="mobileView === false">LOGS</span>
          <span v-else><mdb-icon
            far
            icon="file-alt"
            title="Logs"
            style="font-size:large"
          /></span>
          <i
            v-if="logsLoading"
            style="margin-left: 5px !important;"
            class="fa fa-spin fa-spinner ml-3 mobSpinner"
          />
        </div>

        <!--toggle
          <div
            v-if="userData.iss === true"
            class="cursor-pointer text-lg grey-text text-center"
            :class="{ 'default': section == 'tog', 'mobileSize': mobileView === true }"
            @click="setSection('tog')"
          >
            <span v-if="mobileView === false">TOGGLE</span>
            <span v-else><mdb-icon
              icon="toggle-off"
              title="Toggle"
              style="font-size:large"
            /></span>
            <i
              v-if="toggleLoading"
              style="margin-left: 5px !important;"
              class="fa fa-spin fa-spinner ml-3"
              :class="{ 'mobileTopSectionSpinner': mobileView }"
            />
          </div>
        -->
      </div>
      <div class="scrollContainer">
        <div class="scrollContent">
          <div v-if="section === 'acc'">
            <acc />
          </div>
          <div v-if="section === 'mag'">
            <mag />
          </div>
          <div v-if="section === 'not'">
            <not />
          </div>
          <div v-if="section === 'log'">
            <log />
          </div>
          <!--
            <div v-if="section === 'tog'">
              <tog />
            </div>
          -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'

import adminService from '@/api/services/admin.service'
import logoutService from '@/api/services/logout.service'
import { logout } from '@/methods/auth.js'

import acc from './Tabs/acc.vue'
import mag from './Tabs/mag.vue'
import not from './Tabs/not.vue'
import log from './Tabs/log.vue'
//import tog from './Tabs/tog.vue'

export default {
  name: 'Admin',
  components: {
    acc,
    mag,
    not,
    log,
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    return {
      authData,
      userData,
      mobileView
    }
  },
  data () {
    return {
      section: 'acc',
      masterAccountGroupsLoading: false,
      accountsLoading: false,
      notificationsLoading: false,
      logsLoading: false,
      toggleLoading: false,
      currentSection: 'acc'
    }
  },
  async created () {
    this.checkAccess()
  },
  methods: {
    logout,
    setSection (type) {
      this.section = type

      if (this.section === 'log' && this.currentSection !== 'log') {
        this.masterAccountGroupsLoading = false
        this.accountsLoading = false
        this.notificationsLoading = false
        this.toggleLoading = false
        this.currentSection = 'log'
      }
      if (this.section === 'acc' && this.currentSection !== 'acc') {
        this.masterAccountGroupsLoading = false
        this.notificationsLoading = false
        this.logsLoading = false
        this.toggleLoading = false
        this.currentSection = 'acc'
      }
      if (this.section === 'mag' && this.currentSection !== 'mag') {
        this.accountsLoading = false
        this.notificationsLoading = false
        this.logsLoading = false
        this.toggleLoading = false
        this.currentSection = 'mag'
      }
      if (this.section === 'not' && this.currentSection !== 'not') {
        this.masterAccountGroupsLoading = false
        this.accountsLoading = false
        this.logsLoading = false
        this.toggleLoading = false
        this.currentSection = 'not'
      }
    },
    checkAccess () {
      adminService.checkAccess().then((response) => {
        if (response.data !== true) {
          this.dologOut()
        }
      }).catch(() => {
        this.dologOut()
      })
    },
    dologOut () {
      logoutService.logout().then(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      }).catch(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      })
    }
  }
}
</script>

<style>
  .page-link {
    border: 1px solid #dee2e6 !important;
  }
  table.table thead th {
    outline: none;
    cursor: pointer;
  }
  .form-control:focus {
    border-color: #d0d0d0;
    box-shadow: none;
  }
  .hidden_header {
    display: none;
  }
  .table td, .table th {
    padding: .0rem;
    vertical-align: top;
    border-top: none;
  }
  table.table-sm th, table.table-sm td {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  input[type="color"].form-control.form-control-sm, .input-group-sm input[type="color"].form-control {
    border: none;
    width: 120px;
    height: 40px;
    margin-left: -7px;
    padding-top: 5px;
    margin-bottom: 0px;
  }
</style>

<style lang="scss" scoped>
  .popover {
    width: 300px !important;
    background-color:#fff !important;
  }
  @media (max-width:576px) {
    .mobSpinner {
      font-size:16px;
    }
    .admnMargTop{
      margin-top: 20px !important;
    }
    .scrollContainer {
      float:left;
      // height: 445px;
      width:100%;
      padding:3px;
    }
    .scrollContent {
      // height:440px;
      overflow-y:auto;
      overflow-x:hidden;
      background:#fff;
    }
  }
  .notMargTop {
    margin-top: -20px;
  }
</style>
