<template>
  <div
    :key="key"
    class="tableContainer"
    :class="{'tHeightWithResults': searchResults.length > 0 }"
  >
    <table>
      <thead class="px-2">
        <tr class="table-row header-table-row px-2">
          <th
            v-for="(header, i) in headers"
            :key="header"
            class="columnItem header"
          >
            <div
              :class="{'pl-2': i == 0}"
              class="font-weight-bold  mr-1"
            >
              {{ header }}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(obj, i) in searchResults"
          :key="i"
          class="table-row results p-0 hoverResultsRow"
        >
          <td
            class="columnItem cp"
            :title="userData.rle !== 'Inviter' ? 'View Candidate' : obj.EmailAddress"
            style="max-width: 80px;"
            @click.prevent="viewCandidateInTable(obj)"
          >
            <div
              class="ml-2 hoverBold"
            >
              <ProfilePicture
                :imgSrc="obj.ProfilePicture"
                :dimension="30"
                :maskUser="role.MaskCandidateProfilePicture"
              />
              <div
                class="ml-2 text-wrap"
                style="font-size: 0.9em;padding: 2px;"
              >
                {{ obj.Name }}
              </div>
            </div>
          </td>
          <td
            class="columnItem"
            style="padding-left:10px;"
          >
            <div :title="obj.RoleWorkflowStageItem.RoleWorkflowStageName">
              <FontAwesomeIcon
                class="m-1 mr-2"
                style="scale: 1.4"
                :icon="getIconFromStageType(obj.RoleWorkflowStageItem.RoleWorkflowStageStageType)"
              />
            </div>
          </td>
          <td
            class="primary-colour font-weight-bold text-left columnItem"
            style="font-size: 0.7rem;padding:2px; overflow-wrap: wrap; max-width: 50px;"
          >
            <div class="text-wrap">
              {{ obj.RoleWorkflowStageItem.RoleWorkflowStageItemName.toUpperCase() }}
            </div>
          </td>
          <td
            class="primary-colour font-weight-bold text-left columnItem"
            style="font-size: 0.8rem; overflow-wrap: wrap; max-width: 100px;padding-top:10px;padding-bottom:10px;"
          >
            <ActionButton
              class="mr-2"
              :candidate="obj"
              :stageItemStatus="obj.RoleWorkflowStageItem.StageItemStatus"
              :roleWorkflowStage="obj.RoleWorkflowStage"
              :stageType="obj.RoleWorkflowStageItem.RoleWorkflowStageStageType"
              @resetting="viewCandidateInTable(obj)"
              @initialiseReviewModal="viewCandidateInTable(obj)"
              @updateComponentContext="$emit('researchApplicants')"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="searchResults.length == 0 && loadingRoleCardApplicantSearch != role.JobId"
      class="p-3 font-weight-bold"
    >
      NO RESULTS
    </div>
  </div>
</template>

<script>
import GET_ICON_FROM_STAGE_TYPE_ENUM from '@/utils/getFAIconCodeFromWorkflowStageTypeEnum.js'
import ProfilePicture from '@/components/UI/profilePicture.vue'
import { useRoles, useRoleCandidate, useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import ActionButton from '@/views/roles/innerComponents/Pages/Candidates/ColumnComponents/ActionButton/actionButtonContainer.vue'
import { nextTick, ref, watch } from 'vue-demi'

export default {
  name: 'SearchBarResults',
  components: {
    ProfilePicture, ActionButton
  },
  props: {
    role: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    const rolesStore = useRoles()
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)
    const { roleCardApplicantSearch, loadingRoleCardApplicantSearch } = storeToRefs(rolesStore)
    const roleCandidateStore = useRoleCandidate()
    const { meetingDateModal, resettingTwoWay } = storeToRefs(roleCandidateStore)
    const headers = [ 'CANDIDATE', 'STAGE', 'STATUS', 'ACTION']
    const key = ref(1)
    const searchResults = ref([])
    const oneway = ref([])
    const twoway = ref([])
    const event = ref([])

    watch(roleCardApplicantSearch, async () => {
      searchResults.value = []
      oneway.value = []
      twoway.value = []
      event.value = []
      await nextTick(() => {})
      if (roleCardApplicantSearch.value.OneWayCandidates?.length) {
        oneway.value = roleCardApplicantSearch.value.OneWayCandidates?.map(r => { return { ...r.LinkedApplicant, ...r.OneWayCandidate }})
      }
      if (roleCardApplicantSearch.value.TwoWayCandidates?.length) {
        twoway.value = roleCardApplicantSearch.value.TwoWayCandidates?.map(r => { return { ...r.LinkedApplicant, ...r.TwoWayCandidate }})
      }
      if (roleCardApplicantSearch.value.EventCandidates?.length) {
        event.value = roleCardApplicantSearch.value.EventCandidates?.map(r => { return { ...r.LinkedApplicant, ...r.EventCandidate }})
      }
      searchResults.value = [ ...oneway.value, ...twoway.value, ...event.value ]
      key.value++
    }, { deep: true, immediate: true })

    return {
      headers,
      roleCardApplicantSearch,
      loadingRoleCardApplicantSearch,
      resettingTwoWay,
      meetingDateModal,
      searchResults,
      key,
      userData
    }
  },
  methods: {
    getIconFromStageType (stageType) {
      return GET_ICON_FROM_STAGE_TYPE_ENUM(stageType)
    },
    async viewCandidateInTable (obj) {
      this.meetingDateModal = false
      this.resettingTwoWay = false
      await this.$nextTick(() => {})

      if (this.userData.rle !== 'Inviter') {
        this.$router.push(`/roles/candidates/${this.role.JobId}/${obj.RoleWorkflowStageItem.RoleWorkflowStageId}/${obj.RoleWorkflowStageItem.RoleWorkflowStageItemId}?search=${encodeURIComponent(obj.CandidateAccount.EmailAddress)}`).catch(() => {})
      }
    }
  }
}
</script>

<style scoped>
.tHeightWithResults {
  height: 252px !important;
  overflow-y: scroll;
}
.tableContainer {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  overflow: none;
}
.header-table-row {
  background: #e0e0e0;
}
table {
  width: 100%;
  text-align: left;
}
.table-row {
  width: 100%;
}
td {
  padding: 0;
}
.columnItem {
  height: 50px;
  padding: 0;
  border-bottom: 1px solid #c0c0c0;
}
.columnItem > div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}
.columnItem.right > div {
  justify-content: end;
}
.results:nth-of-type(2n - 1) {
  background: #f7f7f7;
}
.results:nth-of-type(2n) {
  background: #f0f0f0;
}
.hoverResultsRow:hover {
  background: #e8e8e8;
  transition: 0.2s ease;
}
.hoverBold:hover {
  font-weight: bold;
}

</style>
