<template>
  <!--modal boilerplate-->
  <mdb-modal
    id="addRoleCandidatesModalShowing"
    :show="addRoleCandidatesModalShowing"
    size="lg"
    style="overflow-y:auto;"
    @close="closeAddCandidateModal()"
  >
    <mdb-modal-header>
      <mdb-modal-title class="p-2">
        ADD CANDIDATE(s) TO <span v-if="spotlight.Name" class="font-weight-bold">{{ spotlight.Name.toUpperCase() }}</span>
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body style="overflow-y:auto" class="p-4">
      <!-- TIMELINE -->
      <div v-if="!hideTimeline" style="width: 100%; height: 115px">
        <div class="d-flex justify-content-center border-center" style="justify-content: space-between !important;position: relative;">
          <div class="mt-2 mt-md-4" style="text-align: -webkit-center">
            <a
              :class="{'btn-blank-primary': modalPage === 1, 'btn-blank-outline': modalPage != 1, }"
              type="button"
              style="border-radius: 50%"
              class="white-text d-flex justify-content-center align-items-center btn-floating btn-sm ripple-parent"
              @click="modalPage = 1"
            >
              <i
                v-if="tickedStep > 0 && modalPage === 1"
                style="cursor: pointer; color: white"
                class="fas fa-check"
              />
              <i
                v-if="tickedStep > 0 && modalPage !== 1"
                style="cursor: pointer"
                class="fas fa-check primary-colour"
              />
            </a>
            <span class="d-none d-sm-block" style="font-size: 12px;color: black;text-decoration: underline;">Role</span>
            <i style="cursor: pointer; color: black" class="fas fa-users d-sm-none" />
          </div>
          <div class="mt-2 mt-md-4" style="text-align: -webkit-center">
            <a
              :class="{ 'btn-blank-primary': modalPage === 2, 'btn-blank-outline': modalPage != 2, }"
              type="button"
              style="border-radius: 50%"
              class="white-text d-flex justify-content-center align-items-center btn-floating btn-sm ripple-parent"
              @click="tickedStep > 0 ? (modalPage = 2) : null"
            >
              <i
                v-if="tickedStep > 1 && modalPage === 2"
                style="cursor: pointer; color: white"
                class="fas fa-check"
              />
              <i
                v-if="tickedStep > 1 && modalPage !== 2"
                style="cursor: pointer"
                class="fas fa-check primary-colour"
              />
            </a>
            <span class="d-none d-sm-block" style="font-size: 12px;color: black;text-decoration: underline;">Candidates</span>
            <i style="cursor: pointer; color: black" class="fas fa-user-plus d-sm-none" />
          </div>
        </div>

        <!--timeline line-->
        <div style="background: #c0c0c0;height: 1px;width: calc(100% - 80px);transform: translateY(-94px);" class="d-none d-sm-block mx-auto mt-5" />
        <div style="background: #c0c0c0;height: 1px;width: calc(100% - 80px);transform: translateY(-100px);" class="d-sm-none mx-auto mt-5" />
      </div>

      <!-- SELECT ROLE - PAGE 1 FOR MULTIPLEROLES-->
      <div
        v-if="!hideTimeline && modalPage == 1"
        class="d-flex align-items-center my-3"
        style="height:60px"
      >
        <i class="mr-3 fas fa-mobile" style="font-size: 1.3rem;" />
        <mdb-select
          v-model="roleOptions"
          placeholder="Role"
          size="sm"
          style="width:100%;"
          class="m-0 mx-3 addingSpotlightForm"
        />
      </div>
      <!-- QUICKSELECT CANDIDATES -->
      <div v-if="(!hideTimeline && modalPage === 2) || hideTimeline">
        <b-row
          no-gutters
          style="height:fit-content; width:100%;"
          class="d-flex align-content-center justify-content-between justify-content-md-start mt-2 mb-2"
        >
          <div
            v-for="quickSelect in quickSelectCandidateOptions"
            :key="quickSelect.text"
            class="mb-2 mr-2"
            style="cursor:pointer;"
          >
            <QuickSelect
              :loading-quick-select-candidates="loadingQuickSelectCandidates"
              :text="quickSelect.text"
              :count="quickSelect.count"
              @toggleAdded="quickAddRemoveCandidates($event)"
            />
          </div>
        </b-row>
        <!-- ALL CANDIDATES IN ROLE -->
        <div class="d-flex align-items-center justify-content-between my-3">
          <p class="text-left grey-text font-weight-bold" style="font-size:15px;">
            {{ search == '' ? 'All Candidates' : 'Results' }}
          </p>
          <ExpandableSearchBar
            @search="search = $event"
          />
        </div>
        <div style="height:150px;overflow-y:scroll;border:solid #C0C0C0 1px;">
          <div v-if="searchedCandidatesNotYetAddedToSpotlight.length > 0">
            <div
              v-for="(cand, i) in searchedCandidatesNotYetAddedToSpotlight"
              :key="i"
              style="border-radius:5px;height:45px;border:2px #C0C0C0 solid;background:#ffffff;cursor:pointer;overflow-x:auto;overflow-y:hidden;"
              class="p-2 m-2 borderColorOnHover"
              @click="addToCandidatesInSpotlight(cand)"
            >
              <div style="min-width: 100%;width: fit-content; overflow-x:auto;" class="d-flex align-items-center justify-content-between">
                <div style="height:100%;" class="d-flex align-items-center">
                  <img
                    v-if="cand.ProfilePicture && audioOnly !== 3 && audioOnly != 2"
                    :src="cand.ProfilePicture"
                    style="height:25px;width:25px;border-radius:50%;object-fit:cover;"
                    class="d-inline mr-2"
                  >
                  <span
                    v-else
                    aria-hidden="true"
                    style="border:solid 1px #c0c0c0;height:25px;width:25px;border-radius:50%;object-fit:cover;"
                    class="mr-2 d-flex align-items-center justify-content-center"
                  >
                    <i
                      class="fa fa-user"
                      aria-hidden="true"
                      style="font-size: 15px;"
                    />
                  </span>
                  <h5 class="text-left font-weight-bold d-inline text-nowrap mr-4">
                    {{ cand.Name }}
                  </h5><span class="d-none d-sm-inline" style="font-size:0.8rem;">({{ CONVERT_STATUS_NAME(cand.Status) }})</span>
                </div>
                <b-col class="d-none d-lg-block">
                  <p class="text-sm text-right" style="color:black !important;">
                    {{ cand.EmailAddress }}
                  </p>
                </b-col>
              </div>
            </div>
          </div>
          <div v-else class="mt-3">
            <p class="grey-text text-center">
              No results
            </p>
          </div>
        </div>
        <!-- SELECTED CANDIDATES -->
        <p class="text-left my-3 grey-text font-weight-bold" style="font-size:15px;">
          Selected
        </p>
        <div style="border:1px solid #e1e1e1; height:140px;overflow:auto;" class="d-flex flex-wrap col-12 align-content-start mt-2 p-1">
          <div
            v-for="cand in candidatesAddedToSpotlight"
            :key="cand.CandidateId"
            class="p-1 ml-2 mr-1 mt-2"
            style="border:1px solid #C0C0C0; border-radius:5px;cursor:pointer;width:fit-content;height:fit-content;"
          >
            <div class="d-flex align-items-center justify-content-between">
              <div style="height:100%;" class="d-flex align-items-center">
                <img
                  v-if="cand.ProfilePicture != '' && cand.ProfilePicture != null && audioOnly !== 3 && audioOnly != 2"
                  :src="cand.ProfilePicture"
                  style="height:25px;width:25px;border-radius:50%;object-fit:cover;"
                  class="d-inline mr-2"
                >
                <mdb-icon
                  v-else
                  icon="fa fa-user"
                  class="ml-1 mr-2"
                  size="md"
                />
                <h5 class="text-left d-inline" style="font-size:14px;">
                  {{ cand.Name }}
                </h5>
              </div>
              <div style="width:30px;" class="flex-grow-0">
                <i
                  style="cursor:pointer;color:#C0C0C0;"
                  class="fa fa-times colorOnHover ml-2"
                  @click="removeFromCandidatesAddedToSpotlight(cand)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </mdb-modal-body>
    <mdb-modal-footer class="p-4 m-0">
      <div class="d-flex align-items-center justify-content-between m-0" style="width:100%">
        <mdb-btn
          v-if="modalPage == 1"
          class="btn fl ml-auto text-nowrap m-0"
          size="sm"
          :disabled="tickedStep < 1"
          @click="goToPage(2)"
        >
          NEXT
        </mdb-btn>
        <mdb-btn
          v-if="modalPage == 2 && !loading"
          class="btn fl ml-auto text-nowrap m-0"
          size="sm"
          :disabled="candidatesAddedToSpotlight.length === 0"
          @click="addCandidatesToRoleSpotlight()"
        >
          ADD
        </mdb-btn>
        <mdb-btn
          v-if="modalPage == 3 && loading"
          class="btn fl ml-auto"
          size="sm"
          style="width:110px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </div>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import clientPortalService from '@/api/services/clientPortal.service'
import { CONVERT_STATUS_NAME } from '@/utils/convertStatus.js'

import QuickSelect from '@/components/misc/quickSelect.vue'
import roleService from '@/api/services/role.service'
import ExpandableSearchBar from '@/components/UI/expandableSearch.vue'

export default {
  name: 'AddRoleCandidateModal',
  components: {
    QuickSelect,
    ExpandableSearchBar
  },
  props: {
    addRoleCandidatesModalShowing: Boolean,
    spotlight: {
      type: Object,
      default: () => {}
    },
    existingCandidates: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()

    return {
      authData,
      showGenericErrorModal
    }
  },
  data () {
    return {
      loadingQuickSelectCandidates: false,
      roleId: 0,
      modalPage: 1,
      tickedStep: 0,
      hideTimeline: false,
      loadingRoles: false,
      roleOptions: [],
      candidatesAddedToSpotlight: [],
      allRoleCandidates: [],
      search: '',
      searchedCandidates: [],
      loading: false
    }
  },
  computed: {
    awaitingReviewCands () { return this.allRoleCandidates.filter(c => c.Status === 'Pending' || c.Status === 'Awaiting Review') },
    reviewedCands () { return this.allRoleCandidates.filter(c => c.Status === 'Reviewed') },
    shortlistedCands () { return this.allRoleCandidates.filter(c => c.Status === 'Shortlisted') },
    acceptedCands () { return this.allRoleCandidates.filter(c => c.Status === 'Accepted' || c.Status.includes('Offered')) },
    rejectedCands () { return this.allRoleCandidates.filter(c => c.Status === 'Rejected') },
    selectedRoleId () {
      try {
        return this.roleOptions.filter(r => r.selected)[0].value
      } catch { return 0 }
    },
    candidatesNotYetAddedToSpotlight () {
      return this.allRoleCandidates.filter(c => {
        if (this.candidatesAddedToSpotlight.findIndex(ca => ca.CandidateId === c.CandidateId) === -1) {
          return c
        }
      })
    },
    searchedCandidatesNotYetAddedToSpotlight () {
      if (this.search === '') { return this.candidatesNotYetAddedToSpotlight }
      return this.candidatesNotYetAddedToSpotlight.filter(c => c.Name.toLowerCase().includes(this.search.toLowerCase()))
    },
    quickSelectCandidateOptions () {
      return [
        { text: 'Awaiting Review', count: this.awaitingReviewCands.length },
        { text: 'Reviewed', count: this.reviewedCands.length },
        { text: 'Shortlisted', count: this.shortlistedCands.length },
        { text: 'Accepted', count: this.acceptedCands.length },
        { text: 'Rejected', count: this.rejectedCands.length },
        { text: 'All', count: this.allRoleCandidates.length }
      ]
    },
    audioOnly () {
      try {
        return this.allRoles.filter(r => r.JobId === this.roleId)[0].AudioOnly
      } catch { return 0 }
    }
  },
  watch: {
    async addRoleCandidatesModalShowing () {
      if (this.addRoleCandidatesModalShowing) {
        if (this.spotlight.CandidateSource === 'MultipleRoles') {
          this.hideTimeline = false
          this.createRoleOptions()
        } else {
          this.roleId = this.spotlight.RoleId
          this.hideTimeline = true
          this.$nextTick(() => {
            this.modalPage = 2
          })
        }
      } else {
        this.resetModal()
      }
    },
    selectedRoleId () {
      if (this.selectedRoleId !== 0) {
        this.roleId = this.selectedRoleId
        this.tickedStep = 1
      }
    },
    modalPage () {
      if (this.modalPage === 2) {
        this.getCandidatesToAddToRoleSpotlight()
      }
    }
  },
  methods: {
    CONVERT_STATUS_NAME (status) {
      var val = CONVERT_STATUS_NAME(status)
      return val
    },
    quickAddRemoveCandidates (data) {
      if (data.isAdded === true) {
        // goes through each quick seletc category and finds all the cands in the category that are not yet already added then adds them
        if (data.type === 'All') {
          this.candidatesAddedToSpotlight = [...this.allRoleCandidates]
          return
        }
        let candsInQuickSelectCategoryNotYetAdded = []
        if (data.type === 'Awaiting Review') {
          candsInQuickSelectCategoryNotYetAdded = this.awaitingReviewCands.filter(c => {
            if (this.candidatesAddedToSpotlight.findIndex(ca => ca.CandidateId === c.CandidateId) === -1) {
              return c
            }
          })
        } else if (data.type === 'Shortlisted') {
          candsInQuickSelectCategoryNotYetAdded = this.shortlistedCands.filter(c => {
            if (this.candidatesAddedToSpotlight.findIndex(ca => ca.CandidateId === c.CandidateId) === -1) {
              return c
            }
          })
        } else if (data.type === 'Reviewed') {
          candsInQuickSelectCategoryNotYetAdded = this.reviewedCands.filter(c => {
            if (this.candidatesAddedToSpotlight.findIndex(ca => ca.CandidateId === c.CandidateId) === -1) {
              return c
            }
          })
        } else if (data.type === 'Accepted') {
          candsInQuickSelectCategoryNotYetAdded = this.acceptedCands.filter(c => {
            if (this.candidatesAddedToSpotlight.findIndex(ca => ca.CandidateId === c.CandidateId) === -1) {
              return c
            }
          })
        } else if (data.type === 'Rejected') {
          candsInQuickSelectCategoryNotYetAdded = this.rejectedCands.filter(c => {
            if (this.candidatesAddedToSpotlight.findIndex(ca => ca.CandidateId === c.CandidateId) === -1) {
              return c
            }
          })
        }
        this.candidatesAddedToSpotlight = this.candidatesAddedToSpotlight.concat(candsInQuickSelectCategoryNotYetAdded)
      }
    },
    closeAddCandidateModal () {
      this.$emit('closeAddCandidateModal')
    },
    // not via QuickSelect
    addToCandidatesInSpotlight (cand) {
      this.candidatesAddedToSpotlight.push(cand)
    },
    // not via QuickSelect
    removeFromCandidatesAddedToSpotlight (cand) {
      this.candidatesAddedToSpotlight = this.candidatesAddedToSpotlight.filter(c => c.CandidateId !== cand.CandidateId)
    },
    async addCandidatesToRoleSpotlight () {
      this.loading = true
      try {
        var formData = new FormData()
        formData.append('SpotlightId', this.spotlight.GoClientPortalSpotlightId)
        formData.append('RoleId', this.roleId)
        const addedCandsIds = JSON.stringify(this.candidatesAddedToSpotlight.map(c => c.CandidateId))
        formData.append('CandidateIds', addedCandsIds)
        const res = await clientPortalService.addCandidatesToRoleSpotlight(formData)
        if (res.data === false) {
          this.showGenericErrorModal({ showing: true })
        }
        this.resetModal()
        this.closeAddCandidateModal()
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.loading = false
    },
    async getCandidatesToAddToRoleSpotlight () {
      try {
        const res = await clientPortalService.getCandidatesToAddToRoleSpotlight(this.spotlight.GoClientPortalSpotlightId, this.roleId)
        this.allRoleCandidates = res.data
        this.allRoleCandidates.forEach(element => this.checkCandidateId(element))
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
    },
    async createRoleOptions () {
      this.loadingRoles = true
      try {
        var payload = {
          ViewAll: false,
          Recent: false,
          Filter: { Value: true, Name: 'NonArchived' }
        }
        // NonArchived Filter
        const res = await roleService.getRoles(payload)
        this.allRoles = res.data
        const roleArray = []
        this.allRoles.map(r => {
          const choice = { text: r.JobDetails.JobTitle, value: r.JobId }
          roleArray.push(choice)
        })
        this.roleOptions = roleArray
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.loadingRoles = false
    },
    resetModal () {
      this.loadingQuickSelectCandidates = false
      this.roleId = 0
      this.modalPage = 1
      this.tickedStep = 0
      this.hideTimeline = false
      this.loadingRoles = false
      this.roleOptions = []
      this.allRoleCandidates = []
      this.candidatesAddedToSpotlight = []
    },
    goToPage (pageNumber) {
      if (pageNumber === 2 && this.selectedRoleId !== 0) {
        this.tickedStep = 1
        this.modalPage = 2
      } else {
        this.modalPage = pageNumber
      }
    },
    checkCandidateId (candidate) {
      const deleteIndex = this.existingCandidates.findIndex(e => e === candidate.CandidateId)
      if (deleteIndex >= 0) this.allRoleCandidates.splice(deleteIndex, 1)
    }
  }
}
</script>
