<template>
  <div v-if="workingPatterns">
    <b-table
      id="working-pattern-table"
      class="text-left tableTh"
      head-variant="dark"
      :items="workingPatterns"
      :per-page="8"
      :current-page="currentPage"
      :filter="filter"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      hover
      small
      sort-icon-left
      responsive="sm"
    >
      <template #cell(name)="data">
        <div
          class="items-center flex"
          style="cursor:pointer;"
        >
          {{ data.item.GoEventWorkingPatternName }}
        </div>
      </template>
      <template #cell(period)="data">
        <div>
          {{ data.item.Period }}
        </div>
      </template>
      <template #cell(slots)="data">
        <div>
          {{ data.item.GoEventTimeslots.length }}
        </div>
      </template>
      <template #cell(actions)>
        <div>
          <button
            title="Edit working pattern"
            type="button"
            class="btn btn-primary btn-md"
          >
            <div>EDIT</div>
          </button>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useEvent } from '@/pinia'

export default {
  name: 'WorkingPatternTable',
  setup () {
    const { getWorkingPatterns } = useEvent()

    const workingPatterns = ref(null)
    const currentPage = ref(0)
    const filter = ref(null)
    const sortBy = ref('')
    const sortDesc = ref(false)

    const fields = [
      { key: 'name', label: '  Name', tdClass: 'sortIcon', sortable: true },
      { key: 'period', label: '  Period', },
      { key: 'slots', label: '  Slots', tdClass: 'sortIcon', sortable: true },
      { key: 'actions', label: '', },
    ]

    const load = async () => {
      workingPatterns.value = await getWorkingPatterns()
    }

    load()

    return {
      workingPatterns,
      currentPage,
      filter,
      fields,
      sortBy,
      sortDesc
    }
  }
}
</script>
