<template>
  <div style="overflow-y:auto;height:fit-content;margin-bottom:65px;overflow-x:hidden;">
    <validation-observer v-slot="{ invalid, reset }">
      <form
        ref="form"
        class="text-left"
        @submit.prevent="addEditCandidate()"
        @reset.prevent="reset"
      >
        <div class="form-group row">
          <div class="col-md-6">
            <div v-if="candidate.CandidateId > 0" class="md-form">
              <i class="fas fa-user prefix text-left" style="margin-top: 6px;font-size: 1.3rem;" />
              <mdb-input
                v-model="candidate.CandidateId"
                type="text"
                disabled
                auto-complete="cid"
                label="Candidate Id"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form">
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="First name"
                rules="required"
              >
                <i class="fas fa-user prefix text-left" style="margin-top: 6px;font-size: 1.3rem;" />
                <mdb-input
                  v-model="candidate.FirstName"
                  type="text"
                  auto-complete="fna"
                  label="First name"
                  style="margin-left:33px;"
                  size="sm"
                />
                <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                  Please provide a first name
                </div>
              </validation-provider>
            </div>
            <div class="md-form">
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="Last name"
                rules="required"
              >
                <i class="fas fa-user prefix" style="margin-top: 6px;font-size: 1.3rem;" />
                <mdb-input
                  v-model="candidate.LastName"
                  type="text"
                  auto-complete="sna"
                  label="Last name"
                  style="margin-left:33px;"
                  size="sm"
                />
                <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                  Please provide a last name
                </div>
              </validation-provider>
            </div>
            <div class="md-form">
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="Email Address"
                rules="required|email"
              >
                <i
                  class="fas fa-envelope prefix"
                  style="margin-top: 6px;font-size: 1.3rem;"
                  :class="{ 'grey-text': candidate.Role === 'Super Candidate' && candidateData.une !== candidate.CandidateName }"
                />
                <mdb-input
                  v-model="candidate.EmailAddress"
                  type="email"
                  auto-complete="emai"
                  label="Email address"
                  style="margin-left:33px;"
                  size="sm"
                  :class="{ 'disabled': candidate.Role === 'Super Candidate' && candidateData.une !== candidate.CandidateName }"
                />
                <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                  Please provide a valid email address
                </div>
              </validation-provider>
            </div>
            <div class="md-form">
              <i class="fas fa-mobile prefix" style="margin-top: 6px;font-size: 1.3rem;" />
              <mdb-input
                v-model="candidate.ContactNumber"
                type="text"
                auto-complete="tel"
                label="Mobile number (optional)"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form">
              <i class="fas fa-user-tie prefix" style="margin-top: 6px;font-size: 1.3rem;" />
              <mdb-input
                v-model="candidate.Position"
                type="text"
                auto-complete="posi"
                label="Job title / position (optional)"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form">
              <i class="fas fa-map-marker-alt prefix" style="margin-top: 6px;font-size: 1.3rem;" />
              <mdb-input
                v-model="candidate.Location"
                type="text"
                auto-complete="loci"
                label="Location (optional)"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form">
              <i class="fas fa-user prefix" style="margin-top: 6px;font-size: 1.3rem;" />
              <mdb-input
                v-model="candidate.ExternalCandidateReference"
                type="text"
                auto-complete="ref"
                label="External reference (optional)"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form">
              <i class="fas fa-user prefix" style="margin-top: 6px;font-size: 1.3rem;" />
              <mdb-input
                v-model="candidate.Salary"
                type="text"
                auto-complete="sal"
                label="Current salary (optional)"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form">
              <i class="fas fa-user prefix" style="margin-top: 6px;font-size: 1.3rem;" />
              <mdb-input
                v-model="candidate.SalaryExpectation"
                type="text"
                auto-complete="exp"
                label="Salary expectation (optional)"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form">
              <i class="fas fa-user prefix" style="margin-top: 6px;font-size: 1.3rem;" />
              <mdb-input
                v-model="candidate.PeriodOfNotice"
                type="text"
                auto-complete="per"
                label="Period of notice (optional)"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
          </div>
          <div class="col-md-6" style="margin-top:5px;">
            <div class="d-flex align-items-center mt-md-3 mb-5 mb-md-0" style="min-height:45px">
              <FontAwesomeIcon
                icon="fa-solid fa-id-card"
                class="prefix"
                style="font-size: 1.3rem;"
              />
              <div class="mx-3" style="font-size:clamp(0.9rem, 2vw, 1rem);color: #495057E6;">
                Identity Status
              </div>
              <Tooltip
                v-if="candidate.IDChecked"
                class="ml-3"
                tooltip-direction="left"
                :tool-tip-summary="`Verified by <strong>${candidate.IDCheckedByName}</strong> at ${formattedDate(candidate.IDCheckedDate)}`"
              />
              <span class="d-flex align-items-center ml-auto" :class="{'grey-text': !IDChecked }">
                <mdb-switch
                  v-model="IDChecked"
                  style="transform:scale(0.9)"
                  :disabled="savingIDChecked == true"
                  off-label=""
                  on-label=""
                  class="m-0 ml-auto"
                />
                <span class="ml-2" style="font-size:clamp(0.8rem, 2vw, 0.9rem)">{{ IDChecked ? 'ID Verified' : 'Not Verified' }}</span>
              </span>
            </div>
            <div class="md-form md-alt-form" style="margin-top: -5px;">
              <i class="fas fa-user prefix" style="margin-top: 6px;font-size: 1.3rem;" />
              <mdb-select
                v-model="candidateRelocate"
                auto-complete="rel"
                label="Willing To Relocate (optional)"
                style="margin-left:33px;font-size: 0.9rem;"
                size="sm"
              />
            </div>
            <div class="md-form">
              <i class="fas fa-user prefix" style="margin-top: 6px;font-size: 1.3rem;" />
              <mdb-select
                v-model="candidateWorkingPattern"
                auto-complete="pat"
                label="Working pattern (optional)"
                style="margin-left:33px;font-size: 0.9rem;"
                size="sm"
              />
            </div>
            <div class="md-form">
              <i class="fas fa-user prefix" style="margin-top: 6px;font-size: 1.3rem;" />
              <mdb-select
                v-model="candidateHours"
                auto-complete="hou"
                label="Hours (optional)"
                style="margin-left:33px;font-size: 0.9rem;"
                size="sm"
              />
            </div>
            <div class="md-form">
              <i class="fas fa-school prefix" style="margin-top: 6px;font-size: 1.3rem;" />
              <mdb-input
                v-model="candidate.University"
                type="text"
                auto-complete="uni"
                label="University (optional)"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form">
              <i class="fas fa-scroll prefix" style="margin-top: 6px;font-size: 1.3rem;" />
              <mdb-input
                v-model="candidate.Degree"
                type="text"
                auto-complete="deg"
                label="Degree (optional)"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form">
              <mdb-icon
                fab
                icon="linkedin primary-colour-ni"
                style="margin-top: 10px;font-size: 1.3rem;"
              />
              <mdb-input
                v-model="candidate.LinkedInUrl"
                type="text"
                auto-complete="deg"
                label="LinkedIn profile link (optional)"
                style="margin-top:-34px;margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form">
              <i class="fas fa-user prefix" style="margin-top: 6px;font-size: 1.3rem;" />
              <div style="margin-left: 32px;font-size: 14px;color: #757575;padding-top: 12px;">
                Summary (optional)
              </div>
              <mdb-input
                v-model="candidate.CandidateSummary"
                type="textarea"
                auto-complete="sum"
                :rows="8"
                size="sm"
                maxlength="500"
                aria-label="Summary (optional)"
                @keyup.native="charCount(candidate.CandidateSummary, 500)"
              />
              <div class="grey-text remainingChars" style="color: #757575;">
                {{ chars }}
              </div>
            </div>
          </div>
        </div>
        <CustomProfileFields />
        <div style="min-height:80px;">
          <mdb-btn
            type="reset"
            class="float-left btn-outline"
            size="sm"
            style="width:100px;"
            @click="cancelAddEditCandidateForm()"
          >
            CANCEL
          </mdb-btn>
          <mdb-btn
            v-if="showSuccessTick"
            class="float-right"
            size="sm"
            style="min-width:100px;"
          >
            <mdb-icon icon="fa fa-check" />
          </mdb-btn>
          <mdb-btn
            v-show="!buttonClick && !showSuccessTick"
            type="submit"
            :disabled="invalid || candidate.EmailAddress.length < 1"
            class="float-right"
            size="sm"
            style="min-width:100px;"
          >
            <span v-show="editingCandidate">SAVE {{ redirectAfterSave ? ' AND REDIRECT' : '' }}</span><span v-show="!editingCandidate">ADD</span>
          </mdb-btn>
          <mdb-btn
            v-show="buttonClick && !showSuccessTick"
            class="float-right"
            size="sm"
            style="width:100px;"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { useMobile } from '@/composables/useMobile'
import candidateService from '@/api/services/candidate.service'
import { ENVIRONMENT } from '@/common/config.js'
import Tooltip from '@/components/misc/tooltip.vue'
import CustomProfileFields from './customProfileFields.vue'

import { useUserData, useCandidate, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'Profile',
  components: {
    Tooltip, CustomProfileFields
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const candidateStore = useCandidate()
    const {
      candidates,
      candidateToEdit,
      editingCandidate,
      view,
      redirectAfterSave
    } = storeToRefs(candidateStore)

    const {
      resetCandidate,
      updateIdCheckedValues,
      addOrUpdateCandidateAfterSave
    } = useCandidate()

    const { showGenericErrorModal } = useStatus()
    const { mobileView } = useMobile()


    return {
      authData,
      userData,
      candidates,
      resetCandidate,
      candidateToEdit,
      editingCandidate,
      view,
      updateIdCheckedValues,
      redirectAfterSave,
      addOrUpdateCandidateAfterSave,
      showGenericErrorModal,
      mobileView
    }
  },
  data () {
    return {
      loaded: true,
      buttonClick: false,
      savingIDChecked: false,
      errors: null,
      showSuccessTick: false,
      candidateRelocate: [
        { text: 'Yes', value: 1 },
        { text: 'No', value: 2 },
        { text: 'Unknown', value: 3 }
      ],
      candidateWorkingPattern: [
        { text: 'All/Any', value: 0 },
        { text: 'On Site', value: 1 },
        { text: 'Remote', value: 2 },
        { text: 'Hybrid', value: 3 }
      ],
      candidateHours: [
        { text: 'All/Any', value: 0 },
        { text: 'Part Time', value: 1 },
        { text: 'Full Time', value: 2 }
      ],
      chars: '0/500',
      IDChecked: false
    }
  },
  computed: {
    candidateRel: function () {
      var c = ''
      this.candidateRelocate.forEach(element => {
        if (element.selected === true) {
          c = element.value
        }
      })
      return c
    },
    candidateWor: function () {
      var c = ''
      this.candidateWorkingPattern.forEach(element => {
        if (element.selected === true) {
          c = element.value
        }
      })
      return c
    },
    candidateHou: function () {
      var c = ''
      this.candidateHours.forEach(element => {
        if (element.selected === true) {
          c = element.value
        }
      })
      return c
    },
    candidate () {
      var can = this.candidateToEdit
      this.setCan(can)
      return this.candidateToEdit
    },
  },
  watch: {
    'candidate.IDChecked': {
      handler: function () {
        this.IDChecked = this.candidate.IDChecked
      },
      deep: true,
      immediate: true
    },
    IDChecked () {
      this.updateIdCheckedValues({ IDChecked: this.IDChecked, candId: this.candidate.CandidateId, name: `${this.userData.fne} ${this.userData.sne}` })
      this.saveIDChecked()
    }
  },
  destroyed () {
    this.redirectAfterSave = false
  },
  created () {
    this.loaded = true
  },
  methods: {
    formattedDate (date) {
      try {
        let mins = new Date(date).getMinutes()
        if (mins.toString().length === 1) mins = `0${mins}`
        return `${new Date(date).getHours()}:${mins} on ${new Date(date).toLocaleDateString(['en-GB'])}`
      } catch {
        return ''
      }
    },
    charCount (type, max) {
      this.maxCharacter = max
      this.chars = type.length + '/' + max
    },
    addEditCandidate () {
      if (this.buttonClick === false) {
        this.buttonClick = true
        this.loaded = true
        var formData = new FormData()
        formData.append('CandidateId', this.candidate.CandidateId)
        formData.append('FirstName', this.candidate.FirstName)
        formData.append('LastName', this.candidate.LastName)
        formData.append('EmailAddress', this.candidate.EmailAddress)
        formData.append('ContactNumber', this.candidate.ContactNumber)
        formData.append('Position', this.candidate.Position)
        formData.append('RefId', this.candidate.ExternalCandidateReference)
        formData.append('University', this.candidate.University)
        formData.append('Degree', this.candidate.Degree)
        formData.append('Username', this.userData.une)
        formData.append('WillingToRelocate', this.candidateRel)
        formData.append('WorkingPattern', this.candidateWor)
        formData.append('Hours', this.candidateHou)
        formData.append('Location', this.candidate.Location)
        formData.append('Salary', this.candidate.Salary)
        formData.append('SalaryExpectation', this.candidate.SalaryExpectation)
        formData.append('PeriodOfNotice', this.candidate.PeriodOfNotice)
        formData.append('CandidateSummary', this.candidate.CandidateSummary)
        formData.append('LinkedInUrl', this.candidate.LinkedInUrl)
        candidateService.addEditCandidate(formData).then((response) => {
          const newCandidate = this.candidate.CandidateId === 0
          // reponse is the new updated/added candidate. if new it is added to all candidates and if editing it replaces old candidate information in AllCandidates in vuex, then replaces the candidateToEdit
          this.candidateToEdit = {...response.data}
          this.editingCandidate = true
          this.addOrUpdateCandidateAfterSave(response.data, this.candidateToEdit.CandidateId)

          this.reset()
          this.buttonClick = false
          this.showSuccessTick = true
          setTimeout(() => { this.showSuccessTick = false }, 3000)
          // redirecting after save from share modal
          if (this.redirectAfterSave) {
            this.$emit('redirectAfterSaveHandler')
          } else {
            this.$emit('reloadCandidates')
          }
          if (newCandidate) {
            this.$emit('followCandidate', this.candidateToEdit)
          }
          this.$emit('addCandidateToRecentCandidates', this.candidate.CandidateId)
        }).catch((err) => {
          this.$emit('reloadCandidates')
          this.$emit('errorAlert', { err, msg: 'Sorry an error has occurred!' })
          this.buttonClick = false
        })
      }
    },
    cancelAddEditCandidateForm () {
      this.$parent.$parent.loaded = false
      this.resetCandidate()
      this.view = 'ViewAllCandidates'
    },
    reset () {
      this.$refs.form.reset()
    },
    setCan (can) {
      if (this.loaded === true) {
        if (can !== undefined) {
          if (can.CandidateId > 0) {
            if (can.Hours === 0) {
              this.candidateHours = [
                { text: 'All/Any', value: 0, selected: true },
                { text: 'Part Time', value: 1 },
                { text: 'Full Time', value: 2 }
              ]
            } else if (can.Hours === 1) {
              this.candidateHours = [
                { text: 'All/Any', value: 0 },
                { text: 'Part Time', value: 1, selected: true },
                { text: 'Full Time', value: 2 }
              ]
            } else if (can.Hours === 2) {
              this.candidateHours = [
                { text: 'All/Any', value: 0 },
                { text: 'Part Time', value: 1 },
                { text: 'Full Time', value: 2, selected: true }
              ]
            } else {
              this.candidateHours = [
                { text: 'All/Any', value: 0 },
                { text: 'Part Time', value: 1 },
                { text: 'Full Time', value: 2 }
              ]
            }
            if (can.WillingToRelocate === 1) {
              this.candidateRelocate = [
                { text: 'Yes', value: 1, selected: true },
                { text: 'No', value: 2 },
                { text: 'Unknown', value: 3 }
              ]
            } else if (can.WillingToRelocate === 2) {
              this.candidateRelocate = [
                { text: 'Yes', value: 1 },
                { text: 'No', value: 2, selected: true },
                { text: 'Unknown', value: 3 }
              ]
            } else if (can.WillingToRelocate === 3) {
              this.candidateRelocate = [
                { text: 'Yes', value: 1 },
                { text: 'No', value: 2 },
                { text: 'Unknown', value: 3, selected: true }
              ]
            } else {
              this.candidateRelocate = [
                { text: 'Yes', value: 1 },
                { text: 'No', value: 2 },
                { text: 'Unknown', value: 3 }
              ]
            }
            if (can.WorkingPattern === 0) {
              this.candidateWorkingPattern = [
                { text: 'All/Any', value: 0, selected: true },
                { text: 'On Site', value: 1 },
                { text: 'Remote', value: 2 },
                { text: 'Hybrid', value: 3 }
              ]
            } else if (can.WorkingPattern === 1) {
              this.candidateWorkingPattern = [
                { text: 'All/Any', value: 0 },
                { text: 'On Site', value: 1, selected: true },
                { text: 'Remote', value: 2 },
                { text: 'Hybrid', value: 3 }
              ]
            } else if (can.WorkingPattern === 2) {
              this.candidateWorkingPattern = [
                { text: 'All/Any', value: 0 },
                { text: 'On Site', value: 1 },
                { text: 'Remote', value: 2, selected: true },
                { text: 'Hybrid', value: 3 }
              ]
            } else if (can.WorkingPattern === 3) {
              this.candidateWorkingPattern = [
                { text: 'All/Any', value: 0 },
                { text: 'On Site', value: 1 },
                { text: 'Remote', value: 2 },
                { text: 'Hybrid', value: 3, selected: true }
              ]
            } else {
              this.candidateWorkingPattern = [
                { text: 'All/Any', value: 0 },
                { text: 'On Site', value: 1 },
                { text: 'Remote', value: 2 },
                { text: 'Hybrid', value: 3 }
              ]
            }
          }
        }
      }
    },
    async saveIDChecked () {
      this.savingIDChecked = true
      await candidateService.changeIDCheckedStatus(this.IDChecked, this.candidate.CandidateId)
      setTimeout(() => {
        this.savingIDChecked = false
      }, 1000)
    }
  }
}
</script>
