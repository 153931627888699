<template>
  <div>
    <mdb-modal
      v-if="questionToPlay != undefined"
      id="playVideo"
      :show="playVideoModalShowing"
      @close="$emit('closePlayVideoModal')"
    >
      <mdb-modal-header class="align-items-center">
        <div class="d-flex align-items-center ml-2">
          <img
            v-if="candidateToEdit !== null && candidateToEdit.ProfilePicture"
            style="height:40px;width:40px;border-radius:50%;object-fit:cover;"
            :src="candidateToEdit.ProfilePicture"
          >
          <mdb-icon
            v-else
            icon="fa fa-user"
            size="lg"
          />
          <h4 class="ml-2 font-weight-bold" :style="{ 'color': `${userData.lbl.PrimaryColour} !important` }">
            {{ candidateToEdit != null ? candidateToEdit.FirstName.toUpperCase() : '' }}
          </h4>
          <h4 class="ml-2 font-weight-bold" :style="{ 'color': `${userData.lbl.PrimaryColour} !important` }">
            {{ candidateToEdit != null ? candidateToEdit.LastName.toUpperCase() : '' }}
          </h4>
        </div>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <div id="videoContainer" class="mx-auto p-2">
          <i v-if="loading" class="fa fa-pulse fa-spinner" />
          <div v-else style="background:black;">
            <video
              v-if="answerVideo != null"
              id="candidateVideoPreview"
              ref="candidateVideoPreview"
              :src="answerVideo.VideoUrl"
              :poster="answerVideo.ThumbnailUrl"
              height="380"
              width="640"
              controls
              crossorigin=""
              playsinline
              autoplay
              controlsList="nodownload"
              type="video/mp4"
              class="video-fluid z-depth-1"
              style="outline:none;margin: 0px auto;border-radius: 2px;height: 250px;-moz-transform: none !important;background:black;"
            />
          </div>
          <canvas
            id="canvas"
            crossorigin=""
            style="display:none;"
          />
          <div v-show="noVideo" style="font-size:0.8rem;margin-top:30px;">
            Sorry, unable to load video!
          </div>
        </div>
      </mdb-modal-body>
      <mdb-modal-footer v-show="noVideo === false" style="display:block;height:80px !important;">
        <div v-if="!buttonClick">
          <mdb-btn
            v-if="hasDragged"
            color="primary"
            size="sm"
            @click="captureThumb(answerVideo.GuidReference)"
          >
            <i class="fas fa-camera" style="margin-right:10px;" />
            <div class="d-inline text-nowrap">
              Capture Profile Picture
            </div>
          </mdb-btn>
          <div
            v-else
            class="d-inline text-nowrap"
            style="font-size:0.8rem;"
          >
            To Capture Profile Picture Drag Slider To Desired Frame
          </div>
        </div>
        <div v-else>
          <mdb-btn color="primary" size="sm">
            <i class="fa fa-pulse fa-spinner p-0 m-0" />
          </mdb-btn>
        </div>
      </mdb-modal-footer>
    </mdb-modal>

    <!-- AvatarCropper -->
    <div v-if="avatarCropperModalShowing">
      <AvatarCropper
        :loading-crop-image="loadingCropImage"
        :image-from-props="image"
        :avatar-cropper-modal-showing="avatarCropperModalShowing"
        @setLoadingCropImage="loadingCropImage = $event"
        @handleCrop="handleCrop($event)"
        @closeAvatarCroppingModal="avatarCropperModalShowing = false"
      />
    </div>
  </div>
</template>

<script>
import { useUserData, useCandidate } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import libraryService from '@/api/services/library.service.js'
import candidateService from '@/api/services/candidate.service.js'

import AvatarCropper from '@/components/AvatarCropper/avatarCropper.vue'

export default {
  name: 'PlayVideoModal',
  components: {
    AvatarCropper
  },
  props: {
    questionToPlay: {
      type: Object,
      default: () => {}
    },
    capturing: Boolean,
    playVideoModalShowing: Boolean
  },
  setup () {
    const candidateStore = useCandidate()
    const { addCandidateToRecentCandidates, addOrUpdateCandidateAfterSave } = useCandidate()
    const { candidateToEdit, recentCandidates } = storeToRefs(candidateStore)

    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)
    const { mobileView } = useMobile()


    return {
      addCandidateToRecentCandidates,
      addOrUpdateCandidateAfterSave,
      candidateToEdit,
      recentCandidates,
      authData,
      userData,
      mobileView
    }
  },
  data () {
    return {
      answerVideo: null,
      buttonClick: false,
      hasDragged: false,
      loading: false,
      image: '',
      avatarCropperModalShowing: false,
      loadingCropImage: false,
      noVideo: false
    }
  },
  watch: {
    questionToPlay () {
      if (this.questionToPlay !== 'undefined') {
        this.fetchVideoToPlay()
      }
    },
    answerVideo () {
      if (this.answerVideo != null) {
        setTimeout(() => { this.$refs.candidateVideoPreview.onseeked = () => { this.hasDragged = true } }, 200)
      }
    },
    playVideoModalShowing () {
      if (this.playVideoModalShowing === true) {
        this.hasDragged = false
      }
    }
  },
  methods: {
    async fetchVideoToPlay () {
      this.noVideo = false
      this.loading = true
      if (this.questionToPlay !== undefined && this.questionToPlay.AnswerUrl !== '') {
        try {
          const res = await libraryService.getVideoCVVidFromURL(this.userData.aid, this.questionToPlay.AnswerUrl)
          this.answerVideo = res.data
          if (res.data === null) {
            this.noVideo = true
          }
        // eslint-disable-next-line no-empty
        } catch {}
      }
      this.loading = false
    },
    handleCrop (blob) {
      this.file = blob
      this.uploadProfilePicture()
    },
    captureThumb () {
      this.buttonClick = true
      var video = document.getElementById('candidateVideoPreview')
      video.pause()
      var canvas = document.getElementById('canvas')
      var vw = video.videoWidth
      var vh = video.videoHeight
      canvas.width = vw
      canvas.height = vh
      canvas.getContext('2d').drawImage(video, 0, 0, vw, vh)
      canvas.toBlob(async blob => {
        this.$emit('closePlayVideoModal')
        if (blob != null) {
          var url = URL.createObjectURL(blob)
          this.image = url
          this.avatarCropperModalShowing = true
        }
      })
      this.buttonClick = false
    },
    async uploadProfilePicture () {
      this.buttonModalClick = true
      var formData = new FormData()
      formData.append('UserName', this.userData.une)
      formData.append('CandidateId', this.candidateToEdit.CandidateId)
      formData.append('file', this.file)
      try {
        const res = await candidateService.profilePicture(formData)
        this.file = ''
        this.showEditProfilePictureModal = false

        // updates recent candidates and all candidates
        this.candidateToEdit.ProfilePicture = res.data
        this.addCandidateToRecentCandidates({ candId: this.candidateToEdit.CandidateId, token: this.authData.access_token })
        const updateCand = this.recentCandidates.filter(c => c.CandidateId === this.candidateToEdit.CandidateId)[0]
        this.addOrUpdateCandidateAfterSave(updateCand, this.candidateToEdit.CandidateId)
        this.buttonClick = false
      } catch (err) {
        console.log(Object.assign({}, err))
        this.errorModalTitle = 'Error!'
        this.errorAlert(err, 'Sorry an error has occurred!')
        this.showEditProfilePictureModal = false
        // this.$refs.file.value = null
        this.buttonClick = false
        this.file = ''
      }
      this.loadingCropImage = false
    },
    resetAppendThumb () {
      var video = document.getElementById('candidateVideoPreview')
      video.load()
    }
  }
}
</script>

<style lang="scss">
@media (max-width: 576px) {
  #playVideo .modal-dialog .modal-content {
    margin:5px !important;
    width:calc(100vw - 10px) !important;
    height: calc(100vh - 10px) !important;
  }
  #playVideo .modal-dialog {
    margin:0px !important;
  }
}
@media (min-width: 577px) and (max-width: 999px) {
  #playVideo .modal-content {
    margin: 5px !important;
    width: 70vw !important;
    min-height: 90vh !important;
    margin: 1.75rem auto !important;
    margin-top: 5% !important;
  }
  #playVideo .modal-dialog {
    max-width: none !important;
  }
  #candidateVideoPreview {
    height: 49vh !important;
  }
}
@media (min-width: 1000px) {
  #playVideo .modal-content {
    margin: 5px !important;
    width: 60vw !important;
    min-height: 76vh !important;
    margin: 1.75rem auto !important;
    margin-top: 5% !important;
  }
  #playVideo .modal-dialog {
    max-width: none !important;
  }
  #candidateVideoPreview {
    height: 49vh !important;
  }
}
</style>
