import axiosInstance from '../instance.js'
const api2 = new axiosInstance(2)

export default class scoringService {
  // static async saveScale (data) {
  //   return await api1.post('/api/scoring/role_scoring/savescale', data)
  // }

  // static async getAllScales () {
  //   return await api1.get('/api/scoring/role_scoring/getallscales')
  // }

  // static async deleteScale (scaleId) {
  //   return await api1.delete(`/api/scoring/role_scoring/deletescale?scaleId=${scaleId}`)
  // }

  // static async saveTemplate (formData) {
  //   return await api1.post('/api/scoring/role_scoring/savetemplate', formData)
  // }

  // static async getTemplate (templateId, allowDeleted) {
  //   return await api1.get(`/api/scoring/role_scoring/gettemplate?templateId=${templateId}&allowDeleted=${allowDeleted}`)
  // }

  // static async getAllTemplates (includeDeleted = false) {
  //   return await api1.get(`/api/scoring/role_scoring/getalltemplates?includeDeleted=${includeDeleted}`)
  // }

  // static async deleteTemplate (templateId) {
  //   return await api1.delete(`/api/scoring/role_scoring/deletetemplate?templateId=${templateId}`)
  // }

  // static async makeTemplateDefault (templateId) {
  //   return await api1.post(`/api/scoring/role_scoring/maketemplatedefault?templateId=${templateId}`, {})
  // }

  static async saveScore (JSONData) {
    return await api2.post('/api/scoring/save_score', JSONData)
  }

  static async deleteScore (JSONData) {
    return await api2.post('/api/scoring/delete_scores', JSONData)
  }

  static async getQuestionScores (JSONData) {
    return await api2.post('/api/scoring/get_scores', JSONData)
  }

  static async getScoreOverview (JSONData) {
    return await api2.post('/api/scoring/get_score_overview', JSONData)
  }

  // static async checkScalesHaveBeenAdded () {
  //   return await api2.get('/api/scoring/role_scoring/checkscaleshavebeenadded')
  // }
}
