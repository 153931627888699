<template>
  <div>
    <!-- Upload Video Modal Page  -->
    <mdb-modal-body class="px-xs-2 py-xs-0 px-sm-4 py-sm-4 text-center">
      <MDBFileUpload
        :files="file"
        height="200"
        max-size="10M"
        accept="video/mp4, video/webm"
        file-too-big-text="This file is too big! Please select a file smaller than 10MB"
        :text="{ able: { single: 'Drag and Drop a File Here or Click to Select From Your Device' }}"
        @change="handleChange"
      />

      <div class="text-black text-center mt-3" style="font-size:0.7rem">
        Max video length is 2 minutes
      </div>
    </mdb-modal-body>
    <mdb-modal-footer style="display:block;" class="d-flex justify-content-end">
      <mdb-btn
        v-show="!uploadSuccess && !uploadStarted"
        :disabled="file.length == 0"
        size="sm"
        style="width:100px;align-self:start;"
        @click="fileUpload()"
      >
        UPLOAD
      </mdb-btn>
      <mdb-progress
        v-if="uploadStarted"
        indeterminate
        style="margin-right:10px;"
      />
      <mdb-btn
        v-show="uploadStarted"
        size="sm"
        style="width:100px;align-self:start;"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
      <mdb-btn
        v-if="uploadSuccess"
        size="sm"
        class="ml-auto"
        :disabled="file.length == 0"
        @click="$emit('next')"
      >
        NEXT
      </mdb-btn>
    </mdb-modal-footer>
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'

import MDBFileUpload from 'mdb-vue-file-upload'
import UploadFile from '@/upload'

export default {
  name: 'UploadVideoQuestionModal',
  components: {
    MDBFileUpload
  },
  setup () {
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()

    return {
      userData,
      showGenericErrorModal,
    }
  },
  data () {
    return {
      file: [],
      uploadStarted: false,
      uploadSuccess: false,
      videoGuid: ''
    }
  },
  watch: {
    file () {
      this.$emit('file', this.file)
    }
  },
  methods: {
    handleChange (f) {
      this.file = f
    },
    async fileUpload () {
      const vData = {
        userEmail: this.userData.une,
        videoType: 10,
        shortDescription: `video intro - ${this.userData.une}`,
        videoTitle: `${this.file[0].name}`,
        availableToAll: false,
        allowShare: false,
        selectedVideo: '',
        hasForm: false,
        videoText: '',
        videoTags: ''
      }
      this.uploadStarted = true
      try {
        const res = await UploadFile.uploadVideo(1, this.file[0], vData, true)
        this.uploadSuccess = true
        this.$emit('uploadedVideoId', res)
      } catch (err) {
        this.showGenericErrorModal({ showing: true, errorTitle: 'Error Uploading File', errorMessage: 'Something went wrong uploading your Shine Intro. Try refreshing and uploading again.' })
      }
      this.uploadStarted = false
    }
  }
}
</script>

<style lang="scss">
.file-upload-wrapper .file-upload .message svg {
  margin: 0px auto !important;
}
.file-upload-wrapper .file-upload .message p {
  font-size: 0.9rem !important;
  margin-top: 15px !important;
}
</style>
