<template>
  <div style="height:100%">
    <div class="font-weight-bold pt-4 flex-grow-0 primary-colour text-left">
      Surveys
    </div>
    <div class="survey-title-container d-flex align-items-center justify-content-between">
      <div class="text-left dark-grey-text" style="font-size:clamp(0.8rem, 2vw, 0.9rem)">
        Add and Edit surveys - these can be added and displayed at the end of your One-Way and Live Interviews.
      </div>
      <mdb-btn
        size="sm"
        style="width:fit-content;"
        class="m-0 ml-2 text-nowrap"
        @click="addNew()"
      >
        <FontAwesomeIcon
          icon="fa-regular fa-circle-plus"
          class="d-block d-xl-none"
          style="scale: 1.4"
        />
        <span class="d-none d-xl-block text-center px-0">ADD A SURVEY</span>
      </mdb-btn>
    </div>
    <div class="survey-content-container">
      <div v-if="loading" class="mt-3">
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </div>
      <div v-else-if="!loading && surveys.length == 0" class="mt-3 font-weight-bold">
        NO RESULTS - click above to get started.
      </div>
      <div v-else style="width:100%;">
        <!-- SURVEY LIST -->
        <div
          v-for="(survey, i) in surveys"
          :key="i"
          class="d-flex align-items-center px-2 px-md-4 py-2 py-md-3 m-2 scaleListItem"
        >
          <FontAwesomeIcon
            icon="fa-regular fa-square-poll-vertical"
            size="2x"
            class="d-none d-md-block mr-4"
          />
          <FontAwesomeIcon
            v-if="survey.IsDefault"
            icon="fa-regular fa-check-double"
            title="This is your default survey"
            class="d-none d-md-block mr-4"
            style="scale:1.5"
          />
          <span class="font-weight-bold text-left" :class="{'default-survey-bold': survey.IsDefault }">{{ survey.Header.toUpperCase() }}</span>
          <mdb-btn
            v-if="survey.IsDefault === false"
            size="sm"
            style="width:50px"
            class="px-1 m-0 mr-3 ml-auto btn btn-outline"
            title="Make Default"
            @click="makeSurveyDefault(survey.GoSurveyId)"
          >
            <FontAwesomeIcon icon="fa-check-double fa-regular" style="scale:1.5" />
          </mdb-btn>
          <mdb-btn
            size="sm"
            style="width:50px"
            class="px-1 m-0"
            :class="{'ml-auto': survey.IsDefault}"
            disabled
            @click="viewSurvey(survey)"
          >
            <FontAwesomeIcon
              icon="fa-solid fa-eye"
              class="d-inline d-lg-none"
              style="scale: 1.4"
            />
            <span class="d-none d-lg-block text-nowrap">VIEW</span>
          </mdb-btn>
          <mdb-btn
            size="sm"
            style="width:50px"
            class="px-1 m-0 ml-3 btn btn-outline"
            @click="deleteSurvey(survey)"
          >
            <FontAwesomeIcon icon="fa-trash-can-xmark fa-solid" style="scale:1.5" />
          </mdb-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import surveyService from '@/api/services/survey.service.js'

export default {
  name: 'SurveyList',
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()

    return {
      authData,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal
    }
  },
  data () {
    return {
      loading: false,
      surveys: []
    }
  },
  created () {
    this.getAllSurveys()
  },
  methods: {
    addNew () {
      this.$emit('setSection', { section: 'add_edit', sId: '0' })
    },
    viewSurvey (survey) {
      this.$emit('setSection', { section: 'add_edit', sId: survey.GoSurveyId })
    },
    async getAllSurveys () {
      this.loading = true
      try {
        const includeArchived = false
        const res = await surveyService.getAllSurveys(includeArchived)
        if (res.data != null) {
          this.surveys = res.data
        }
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    async deleteSurvey (survey) {
      if (survey.IsDefault === true) {
        this.showGenericErrorModal({ showing: true, errorMessage: 'You cannot delete your default survey.', errorTitle: 'Cannot Delete' })
      } else {
        this.showGenericConfirmModal({
          showing: true,
          confirmTitle: 'DELETE',
          confirmMessage: 'Are you sure you want to delete this survey? <br /> <br /> Please note that if this survey has ever been answered by any candidates, it will be archived and while it will be removed it from this list, the existing data will not be deleted.',
          confirmButtonMessage: 'SUBMIT',
          confirmButtonFunction: () => this.confirmDeleteSurvey(survey.GoSurveyId)
        })
      }
    },
    async confirmDeleteSurvey (surveyId) {
      try {
        await surveyService.deleteSurvey(surveyId)
        this.getAllSurveys()
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.closeGenericConfirmModal()
    },
    async makeSurveyDefault (surveyId) {
      try {
        await surveyService.makeSurveyDefault(surveyId)
        this.getAllSurveys()
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.closeGenericConfirmModal()
    }
  }
}
</script>

<style lang="scss" scoped>
.survey-title-container {
  min-height: 80px;
  height: fit-content;
  font-size: 1rem;
}
.survey-content-container {
  height: calc(100vh - 300px);
  border: #c0c0c0 solid 2px;
  border-radius: 10px;
}
.scaleListItem {
  border: #c0c0c0 solid 1px;
  border-radius: 5px;
}
.default-survey-bold {
  border: var(--pr-color) 4px solid;
  border-radius: 5px;
  padding: 2px 6px;
  max-width: fit-content;
  margin-right: 10px;
}
</style>
