<template>
  <div>
    <div v-if="bulkTaskDropDownOptions.length > 0">
      <mdb-btn
        v-if="!(stageItemStatus == 1 && stageType == 2) && itemsOnThisPageCount !== selectedCandidates.length"
        size="sm"
        class="btn m-0"
        style="width:130px;padding: 9px 1.1rem;"
        @click="$emit('selectAllRows')"
      >
        <span class="d-none d-lg-inline">SELECT ALL</span>
        <span class="d-inline d-lg-none"><FontAwesomeIcon icon="fa-solid fa-check" /></span>
      </mdb-btn>
      <mdb-btn
        v-if="itemsOnThisPageCount === selectedCandidates.length"
        size="sm"
        class="btn btn-outline m-0"
        style="width:130px;padding: 9px 1.1rem;"
        @click="selectedCandidates = []"
      >
        <span class="d-none d-lg-inline">DESELECT ALL</span>
        <span class="d-inline d-lg-none"><FontAwesomeIcon icon="fa-regular fa-square" /></span>
      </mdb-btn>
    </div>
    <mdb-select
      v-if="selectedCandidates.length > 0 && bulkTaskDropDownOptions.length > 0"
      v-model="bulkTaskDropDownOptions"
      :disabled="selectedCandidates.length === 0 || !candidatesLoaded"
      placeholder="Select Bulk Action"
      size="sm"
      class="ml-3"
      style="width:fit-content;height:25px;margin-top:-65px;width:190px;"
    />
    <mdb-btn
      v-if="selectedBulkOption != '' && selectedCandidates.length > 0"
      size="sm"
      class="btn m-0 ml-3 text-nowrap"
      style="width:fit-content;padding: 9px 1.1rem;"
      @click="bulkAction()"
    >
      GO
    </mdb-btn>
  </div>
</template>

<script>
import { useStatus, useRoleCandidate, useWorkflow, useUserData, useRoles } from '@/pinia'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import roleV2Service from '@/api/services/roleV2.service'
import eventService from '@/api/services/event.service'
import { RNYID, RAFID } from '@/common/config.js'

export default {
  name: 'BulkInvites',
  props: {
    candidatesLoaded: Boolean,
  },
  setup () {
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)

    const statusStore = useStatus()
    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = statusStore

    const roleCandidateStore = useRoleCandidate()
    const { candidateTableData, candidate, selectedCandidates, candidates, removeCandidatesFromTable, updateCandidateInTable, getUninvitedRoleCandidates, skip, perPage, bulkSetCandidateStatus, selectedApplicantIds, roleCandidateTableCount } = storeToRefs(roleCandidateStore)

    const workflowStore = useWorkflow()
    const { role, addRoleToRecentRoles, selectedWorkflowStage } = storeToRefs(workflowStore)

    const bulkTaskDropDownOptions = ref([])

    const { getRecentRoles } = useRoles()

    const selectedBulkOption = computed(() => {
      try {
        const thing = bulkTaskDropDownOptions.value.filter(o => o.selected === true)[0].bulkTaskType
        return thing
      } catch { return '' }
    })

    const itemsOnThisPageCount = computed(() => {
      return roleCandidateTableCount.value <= perPage.value ? roleCandidateTableCount.value : perPage.value
    })

    return {
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal,
      candidateTableData,
      role,
      candidate,
      candidates,
      selectedCandidates,
      selectedBulkOption,
      bulkTaskDropDownOptions,
      addRoleToRecentRoles,
      removeCandidatesFromTable,
      updateCandidateInTable,
      getUninvitedRoleCandidates,
      skip,
      perPage,
      selectedWorkflowStage,
      bulkSetCandidateStatus,
      selectedApplicantIds,
      itemsOnThisPageCount,
      userData,
      getRecentRoles
    }
  },
  computed: {
    completedStageItems () {
      return this.selectedWorkflowStage.OrderedRoleWorkflowStageItems.filter(i => i.CompleteStatusType)
    },
    stageType () {
      return this.candidateTableData.StageType
    },
    stageItemStatus () {
      return this.candidateTableData.StageItemStatus
    }
  },
  watch: {
    stageItemStatus () {
      this.setBulkInviteOptions()
    },
    candidates: {
      handler: 'setBulkInviteOptions',
      immediate: true
    }
  },
  methods: {
    setBulkInviteOptions () {
      let options = []
      if (this.stageItemStatus === 1) {
        options.push({ bulkTaskType: 'Invite', value: 'Invite', text: 'Invite Selected', selected: true })
      } else if (this.stageItemStatus <= 5) {
        if ((this.userData.mid === RNYID || this.userData.mid === RAFID)) {
          if (this.userData.rle !== 'User') {
            options.push({ bulkTaskType: 'Uninvite', value: 'Uninvite', text: 'Uninvite Selected', selected: true })
          }
        }
        else {
          options.push({ bulkTaskType: 'Uninvite', value: 'Uninvite', text: 'Uninvite Selected', selected: true })
        }
      }
      if (this.stageItemStatus >= 6 || (this.userData.iss && this.stageItemStatus == 4)) {
        const statusesNotIncludingCurrent = this.completedStageItems.filter(f => f.StageItemStatus != this.stageItemStatus)
        statusesNotIncludingCurrent.map(s => {
          options = [...options, { bulkTaskType: 'BulkStatusChange', text: `Move to ${s.RoleWorkflowStageItemName}`, value: s.RoleWorkflowStageItemId }]
        })
      }
      if (this.role.CloseDateIsPast) {
        options = options.filter(o => o.value !== 'Invite' && o.value !== 'Uninvite')
      }
      if (this.stageItemStatus === 3 && this.stageType === 3 ) {
        // there are two default stage items with a stageItemStatus of 6 (completed) - attended and did not attend
        const attendedAndNotAttendedStatuses = this.selectedWorkflowStage.OrderedRoleWorkflowStageItems.filter(f => f.StageItemStatus === 6)
        attendedAndNotAttendedStatuses.map(st => {
          options = [...options, { bulkTaskType: 'BulkStatusChange', text: `Set as ${st.RoleWorkflowStageItemName}`, value: st.RoleWorkflowStageItemId } ]
        })
      }
      this.bulkTaskDropDownOptions = options
    },
    bulkAction () {
      if (this.selectedBulkOption === 'Uninvite') {
        this.uninviteSelected()
      } else if (this.selectedBulkOption === 'Invite') {
        this.inviteSelected()
      } else if (this.selectedBulkOption === 'BulkStatusChange') {
        this.bulkStatusChange()
      }
    },
    uninviteSelected () {
      const message = `Do you want to uninvite ${this.selectedCandidates.length} candidate${this.selectedCandidates.length === 1 ? '' : '\'s'} from this role?`
      const title = `CONFIRM UNINVITE${this.selectedCandidates.length === 1 ? '' : 'S'}`
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: title,
        confirmMessage: message,
        confirmButtonMessage: 'UNINVITE',
        confirmButtonFunction: () => this.confirmUninviteSelected()
      })
    },
    async confirmUninviteSelected () {
      try {
        const JSONData = {
          RoleId: this.role.JobId,
          SelectedCandidates: this.selectedCandidates,
          RoleWorkflowStageId: this.candidateTableData.RoleWorkflowStageId
        }
        if (this.stageType === 1) {
          await roleV2Service.uninviteFromOneWayStage(JSONData)
        } else if (this.stageType === 2) {
          await roleV2Service.uninviteFromTwoWayStage(JSONData)
        } else if (this.stageType === 3) {
          await roleV2Service.uninviteFromEvent(JSONData)
        }
        this.selectedCandidates = []
        this.$emit('updateComponentContext')
        this.closeGenericConfirmModal()
      } catch (err) {
        this.closeGenericConfirmModal()
        this.selectedCandidates = []
        this.showGenericErrorModal({ showing: true })
      }
    },
    inviteSelected () {
      const message = `Do you want to invite ${this.selectedCandidates.length} candidate${this.selectedCandidates.length === 1 ? '' : 's'} to this role?`
      const title = `CONFIRM INVITE${this.selectedCandidates.length === 1 ? '' : 'S'}`
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: title,
        confirmMessage: message,
        confirmButtonMessage: 'INVITE',
        confirmButtonFunction: () => this.confirmInviteSelected()
      })
    },
    async confirmInviteSelected () {
      try {
        const JSONData = {
          RoleId: this.role.JobId,
          SelectedCandidates: this.selectedCandidates,
          RoleWorkflowStageId: this.candidateTableData.RoleWorkflowStageId
        }
        if (this.stageType === 1) {
          await roleV2Service.inviteCandidatesToOneWayStage(JSONData)
        } else if (this.stageType === 2 && !this.selectedWorkflowStage.IsSelfSchedule) {
          await roleV2Service.inviteCandidatesToTwoWayStage(JSONData)
        } else if (this.selectedWorkflowStage.IsSelfSchedule) {
          await eventService.inviteCandidateToScheduler(JSONData)
        }
        this.selectedCandidates = []
        this.$emit('updateComponentContext')
        await this.getRecentRoles()
        this.closeGenericConfirmModal()
      } catch (err) {
        this.selectedCandidates = []
        this.closeGenericConfirmModal()
        this.showGenericErrorModal({ showing: true })
      }
    },
    async bulkStatusChange () {
      const newStageItemId = this.bulkTaskDropDownOptions.filter(o => o.selected === true)[0].value
      try {
        await this.bulkSetCandidateStatus(newStageItemId, () => this.$emit('updateComponentContext'))
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.selectedCandidates = []
      await this.getRecentRoles()
    }
  }
}
</script>
