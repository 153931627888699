<template>
  <div>
    <!-- Upload Video Modal Page  -->
    <mdb-modal-body class="px-xs-2 py-xs-0 px-sm-4 py-sm-4" style="margin-bottom: -50px;">
      <validation-observer>
        <form
          ref="form"
          @submit.prevent="fileUpload()"
          @reset.prevent="resetForm()"
        >
          <div class="md-form form-sm text-left" style="margin-left:10px;margin-right:10px;margin-top:-15px;">
            <i class="fas fa-video prefix float-left" style="margin-top: 6px;font-size: 1.3rem;" />
            <mdb-input
              v-model="videoTitle"
              type="text"
              auto-complete="fna"
              placeholder="Video Name"
              style="margin-left:33px;"
              size="sm"
            />
          </div>
          <div class="md-form form-sm text-left" style="margin-left:10px;margin-right:10px;">
            <i class="fas fa-object-group prefix" style="margin-top: 6px;font-size: 1.3rem;" />
            <mdb-select
              v-model="videoCategories"
              placeholder="Select Video Category"
              style="margin-left:33px;"
              size="sm"
            />
          </div>
          <div class="md-form form-sm text-left" style="margin-left: 10px; margin-right: 10px;">
            <i class="fas fa-upload prefix" />
            <div class="file-field md-form form-sm text-left" style="margin-left:25px">
              <div
                class="btn btn-primary btn-sm float-right"
                style="margin-right:0px"
                @click="launchFilePicker"
              >
                <span>Choose file</span>
                <input
                  ref="file"
                  v-uploader
                  type="file"
                  style="display:none"
                  accept="audio/mp4,video/mp4"
                >
              </div>
              <div class="file-path-wrapper" style="font-size:14px;color:#495057;">
                <input
                  class="file-path validate"
                  type="text"
                  placeholder="Choose your video to upload"
                  :value="file.name"
                  readonly
                >
              </div>
              <p style="font-size:small;">
                {{ !fileIsValid ? 'Please select an .mp4 file' : '' }}
              </p>
            </div>
          </div>
          <div class="md-form form-sm text-left" style="margin-left:10px;margin-right:10px; transform: translateY(-10px);">
            <i class="fas fa-tags prefix" style="margin-top: 6px;font-size: 1.3rem;" />
            <VideoTagInput
              input-font-size="0.9rem"
              :video-tags="videoTags"
              @addTag="addTag"
              @removeTag="removeTag"
            />
          </div>
        </form>
      </validation-observer>
    </mdb-modal-body>
    <mdb-modal-footer style="display:block;">
      <b-row
        v-if="!uploadStarted"
        no-gutters
        align-h="between"
        align-v="center"
      >
        <b-col>
          <mdb-btn
            type="reset"
            class="float-left btn-outline"
            size="sm"
            style="width:100px;"
            @click="resetForm()"
          >
            RESET
          </mdb-btn>
        </b-col>
        <mdb-btn
          type="submit"
          :disabled="(videoTitle === null || videoTitle === '') || !fileIsValid || videoCategories.filter(i => i.selected).length === 0 || !file"
          class="float-right"
          size="sm"
          style="width:100px;"
          @click="fileUpload()"
        >
          <span>SAVE</span>
        </mdb-btn>
      </b-row>
      <b-row v-else align-h="center">
        <mdb-progress
          v-if="uploadStarted"
          indeterminate
          style="margin-right:10px;"
        />
        <mdb-btn
          v-show="uploadStarted"
          size="sm"
          style="width:100px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </b-row>
    </mdb-modal-footer>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'

import UploadFile from '@/upload'
import VideoTagInput from '@/components/videoTagInput'

export default {
  name: 'UploadVideoModal',
  components: {
    VideoTagInput
  },
  directives: {
    uploader: {
      bind (el, binding, vnode) {
        el.addEventListener('change', e => {
          if (e.target.files[0] !== undefined) {
            vnode.context.file = e.target.files[0]
          }
        })
      }
    }
  },
  props: {
    defaultCategory: {
      type: String,
      default: ''
    }
  },
  setup () {
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)

    return {
      userData
    }
  },
  data () {
    return {
      addEditVideoQuestionModalPage: 1,
      file: '',
      fileIsValid: true,
      modalPage: 'upload',
      uploadStarted: false,
      uploadSuccess: false,
      videoCategories: [{ text: 'Organisation', value: 1 }, { text: 'Job', value: 2 }, { text: 'Shareable', value: 3 }, { text: 'Outro', value: 5 }, { text: 'Question', value: 6 }],
      videoTitle: '',
      videoTags: []
    }
  },
  watch: {
    file () {
      try {
        if (this.file.name.includes('.mp4')) {
          this.fileIsValid = true
        } else {
          this.fileIsValid = false
        }
      } catch {
        this.fileIsValid = false
      }
    }
  },
  mounted () {
    this.videoCategories = this.videoCategories.map(q => q.text === this.defaultCategory ? { ...q, selected: true } : { ...q, selected: false })
  },
  created () {
    this.resetForm()
  },
  methods: {
    addTag: function (e) {
      if (this.videoTags != null) {
        if (!this.videoTags.includes(e) && e !== '' && e !== null) {
          this.videoTags.push(e)
          e = ''
        } else {
          e = ''
        }
      }
    },
    async goToQuestionPage () {
      let vidType = this.videoCategories.filter(c => c.selected)[0].text
      this.$emit('uploadVideo', vidType)
    },
    removeTag (e) {
      this.videoTags.splice(e, 1)
    },
    async fileUpload () {
      let vidType
      try {
        vidType = this.videoCategories.filter(c => c.selected)[0].value
      } catch { vidType = 6 }
      const vData = {
        userEmail: this.userData.une,
        videoType: vidType,
        shortDescription: this.videoTitle,
        videoTitle: this.videoTitle,
        availableToAll: false,
        allowShare: false,
        selectedVideo: '',
        hasForm: false,
        videoText: '',
        videoTags: this.videoTags.join(', ')
      }
      this.uploadStarted = true
      const uploadSuccess = await UploadFile.uploadVideo(1, this.file, vData)
      this.uploadStarted = false
      this.UploadFile = false
      this.uploadSuccess = uploadSuccess
      if (uploadSuccess) {
        this.library = []
        this.goToQuestionPage()
      }
    },
    launchFilePicker () {
      this.$refs.file.click()
    },
    resetForm () {
      this.uploadStarted = false
      this.videoTitle = ''
      this.videoTags = []
      this.file = ''
      this.videoCategories = [{ text: 'Organisation', value: 1 }, { text: 'Job', value: 2 }, { text: 'Shareable', value: 3 }, { text: 'Introduction', value: 4 }, { text: 'Outro', value: 5 }, { text: 'Question', value: 6 }, { text: 'All', value: 0 }]
      if (this.uploadVideo === true) {
        this.uploadVideo = false
      } else {
        this.uploadVideo = true
      }
      try {
        this.$refs.file.value = null
      } catch {}
    }
  }
}
</script>
