<template>
  <div>
    <div
      v-if="isSupported"
      id="app"
      :style="[headerStyle,primaryStyle,secondaryStyle,backgroundStyle,linkStyle,altLinkStyle,altBackgroundStyle,navStyle,opacityStyle]"
      :class="{ 'bg': !userData.aut, 'noScroll' : !userData.aut && mobileView === false, 'nav-colour' : userData.aut }"
      style="max-width: 100% !important;padding-left: 0px;padding-right: 0;height:100vh;"
    >
      <div
        id="maindisplay"
        style="height:100vh"
        class="container-fluid overflow-y-auto"
        :class="{ 'bgWhite': userData.aut, 'fixed': userData.aut, 'noScrollX': mobileView, 'bottomFixedNav' : mobileView && page === '/videolibrary' }"
      >
        <NavBar @logOut="doLogOut()" />
        <div
          style="min-width:100%;height:100vh"
          :class="{ 'mTopNo': mobileView, 'mTopNoLog': !userData.aut}"
          :style="{'padding-top': mobileView ? '0px' : '80px'}"
        >
          <NotificationBar
            v-if="isActive && userData.msg"
            :style="[{'font-size': mobileView ? '8px' : '12px'}]"
            v-bind="{ isActive } "
            v-on="{ removeAlert }"
          />
          <div class="row">
            <!--left-->
            <left-column v-if="userData.aut" />
            <!--middle-->
            <router-view />
          </div>
        </div>
      </div>
      <GenericConfirmModal />
      <GenericErrorModal />
      <mdb-modal :show="errorModal" @close="errorModal = false">
        <mdb-modal-header>
          <mdb-modal-title>{{ errorTitle.toUpperCase() }}</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body :class="{ 'modalHeight': mobileView }">
          <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
            <span v-html="error" />
            <span v-if="error === 'You are already signed into another device, please log out of that device before continuing. Note: we do not allow the sharing of login details.'">
              <br><br>
              You can however click <a style="color:#555 !important;font-weight:bold;" @click="resetLogin()">here</a> to reset you login and allow access. This will be monitored.
            </span>
          </p>
        </mdb-modal-body>
      </mdb-modal>
      <mdb-modal id="orientationModal" class="usePortrait">
        <mdb-modal-body :class="{ 'modalHeight': mobileView }">
          <div class="ml-0 ml-md-3 ml-lg-4 p-2" style="margin-top:5%;">
            <img
              v-if="!userData.lbl.Logo.includes('shine-logo-white.png')"
              :src="userData.lbl.Logo"
              class="nav-colour"
              style="min-height: 65px;max-height: 65px;display: block;margin: 0px auto;padding: 10px;border-radius: 5px;"
            >
            <img
              v-else
              :src="WEB_URL + '/img/shinelogo.jpg'"
              style="min-height: 150px;max-height: 150px;display: block;margin: 0px auto;padding: 10px;border-radius: 5px;"
            >
          </div>
          <p class="my-4 grey-text" style="font-size:1rem;text-align:center;">
            If you are using a mobile device or tablet, please access this site in portrait mode! If you are using a laptop or other type of desktop computer, your browser window
            is too small for our platform to function correctly. Please maximise your browser where possible!
          </p>
        </mdb-modal-body>
      </mdb-modal>
      <mdb-modal
        v-if="tablet"
        id="tabletOrientationModal"
      >
        <mdb-modal-body :class="{ 'modalHeight': mobileView }">
          <div class="ml-0 ml-md-3 ml-lg-4 p-2" style="margin-top:5%;">
            <img
              v-if="!userData.lbl.Logo.includes('shine-logo-white.png')"
              :src="userData.lbl.Logo"
              class="nav-colour"
              style="min-height: 65px;max-height: 65px;display: block;margin: 0px auto;padding: 10px;border-radius: 5px;"
            >
            <img
              v-else
              :src="WEB_URL + '/img/shinelogo.jpg'"
              style="min-height: 150px;max-height: 150px;display: block;margin: 0px auto;padding: 10px;border-radius: 5px;"
            >
          </div>
          <p class="grey-text" style="font-size:1.2rem;text-align:center;padding-left: 30px;padding-right: 30px;padding-top:20px;">
            If you are using a mobile device or tablet, please access this site in portrait mode!
            <br><br>
            If you are using a laptop or other type of desktop computer, your browser window is too small (please maximise your browser where possible)!
          </p>
        </mdb-modal-body>
      </mdb-modal>
    </div>
    <div else>
      <div :class="{ 'marginTopSections': mobileView === false, 'canLoadingMargins': mobileView === false }" style="margin-left:30px;margin-top:15px;">
        <h3 class="primary-colour float-left" :class="{ 'mobileTopSections': mobileView }">
          Loading...
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import logoutService from '@/api/services/logout.service'
import userService from '@/api/services/user.service'
import { logout } from '@/methods/auth.js'
import { WEB_URL } from '@/common/config.js'
import { useUserData, useStatus, useNotification } from './pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'

import leftColumn from '@/components/leftColumn'
import GenericConfirmModal from '@/components/genericModals/confirmModal'
import GenericErrorModal from '@/components/genericModals/errorModal'
import NavBar from '@/components/UI/NavBar.vue'
import NotificationBar from './components/misc/NotificationBar'

export default {
  name: 'App',
  components: {
    leftColumn,
    GenericConfirmModal,
    GenericErrorModal,
    NavBar,
    NotificationBar
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData, landingPage, leftColumnActive, lockout } = storeToRefs(userStore)
    const { getLiveNotifications } = useNotification()
    const { closeGenericConfirmModal, closeGenericErrorModal } = useStatus()
    const { checkIsMobile, mobileView, tabletView } = useMobile()

    return {
      userStore,
      authData,
      userData,
      landingPage,
      getLiveNotifications,
      closeGenericConfirmModal,
      closeGenericErrorModal,
      leftColumnActive,
      lockout,
      checkIsMobile,
      mobileView,
      tabletView,
      WEB_URL
    }
  },
  data () {
    return {
      intervalId: '',
      windowHeight: 0,
      windowWidth: 0,
      show: false,
      showNavSettings: false,
      isActive: true,
      errorModal: false,
      error: '',
      errorTitle: 'Error',
      page: window.location.pathname,
      notificationsFired: false,
      notificationInterval: null,
      is401: false,
      isSupported: true,
      tablet: false,
    }
  },
  computed: {
    backgroundStyle () {
      return {
        '--bg-color': this.userData.lbl.PrimaryColour
      }
    },
    primaryStyle () {
      return {
        '--pr-color': this.userData.lbl.PrimaryColour
      }
    },
    secondaryStyle () {
      return {
        '--sc-color': this.userData.lbl.SecondaryColour
      }
    },
    headerStyle () {
      return {
        '--hd-color': this.userData.lbl.HeaderColour
      }
    },
    linkStyle () {
      return {
        '--lk-color': this.userData.lbl.LinkColour
      }
    },
    altLinkStyle () {
      return {
        '--al-color': this.userData.lbl.AltLkColour
      }
    },
    altBackgroundStyle () {
      return {
        '--ab-color': this.userData.lbl.AltBgColour
      }
    },
    navStyle () {
      return {
        '--nv-color': this.userData.lbl.NavColour
      }
    },
    opacityStyle () {
      return {
        '--op-color': this.userData.lbl.OpacityColour
      }
    },
    silhouetteUrl () {
      return 'https://go.shineinterview.com/img/candidate-silhouette.png'
    }
  },
  watch: {
    'userData.aut': {
      handler: async function (after, before) {
        // initiates ongoing notification calls upon login. the code for the notifications actually being shown is in the 'leftColumn' component
        if (after && before) {
          this.notificationsFired = false
          this.initiateNotifications()
        }
      },
      deep: true
    }
  },
  created () {
    this.checkIsMobile()
    if (this.tabletView && screen.orientation.type == 'portrait-primary') {
      this.tablet = true;
    } else {
      this.tablet = false;
    }
    window.addEventListener('load', this.checkStatus)
    this.windowHeight = window.innerHeight
    this.windowWidth = window.innerWidth
    // attempt to refresh to the page the user types in when not accounting for hashed urls e.g. base/roles should be base/#/roles
    try {
      if (window.location.pathname !== '/') {
        const pathToRedirectTo = window.location.pathname.split('/')[1]
        window.location = `/#/${pathToRedirectTo}`
      }
    } catch {
      window.location = `/#/${this.landingPage}`
    }
    this.page = window.location.pathname
  },
  mounted () {
    screen.orientation.addEventListener("change", (event) => {
      this.checkIsMobile()
      const type = event.target.type;
      if (this.tabletView && type == 'portrait-primary') {
        this.tablet = true;
      } else {
      this.tablet = false;
    }
    })
  },
  updated () {
    screen.orientation.addEventListener("change", (event) => {
      this.checkIsMobile()
      const type = event.target.type;
      if (this.tabletView && type == 'portrait-primary') {
        this.tablet = true;
      } else {
      this.tablet = false;
    }
    })
  },
  methods: {
    logout,
    async initiateNotifications () {
      if (this.userData.aut && this.authData.access_token && !this.notificationsFired) {
        this.notificationsFired = true
        const interval = await this.getLiveNotifications({ token: this.authData.access_token, terminateCallOnLogout: false, notificationInterval: null })
        this.notificationInterval = interval
      }
    },
    keyDown: function () {
      this.checkIsMobile()
    },
    removeAlert () {
      this.isActive = !this.isActive
      this.userData.inf = 0
    },
    async doLogOut () {
      this.lockout = null
      await this.getLiveNotifications({ token: null, terminateCallOnLogout: true, notificationInterval: this.notificationInterval })

      logoutService.logout().then(() => {
        window.clearInterval(this.intervalId)
        this.checkIsMobile()
        this.isActive = true
        this.show = false
        this.orientationModal = false
        this.isSupported = true
        this.logout().then(() => this.$router.push('/').catch(() => { })) // new

      }).catch(() => {
        window.clearInterval(this.intervalId)
        this.checkIsMobile()
        this.isActive = true
        this.show = false
        this.orientationModal = false
        this.isSupported = true
        this.logout().then(() => this.$router.push('/').catch(() => { })) // new
      })
      setTimeout(() => {
        this.closeGenericConfirmModal()
      }, 200)
    },
    async logOutCompletely () {
      window.clearInterval(this.intervalId)
      // stops the notification api call
      this.getLiveNotifications({ token: null, terminateCallOnLogout: true, notificationInterval: this.notificationInterval })
      // log out and clear all storage completely
      this.logout()
      this.checkIsMobile()
      if (window.location.pathname !== '/') {
        this.$router.replace('/#/')
      }
      window.location = '/#/'
      this.isActive = true
      this.show = false
      this.orientationModal = false
      this.closeGenericConfirmModal()
    },
    showSettings () {
      if (this.showNavSettings) {
        this.showNavSettings = false
      } else {
        this.showNavSettings = true
      }
    },
    checkUserDetails () {
      if (this.userData.aut) {
        userService.checkUserDetails(this.userData.aid).then((response) => {
          if (response.data === false) {
            this.doLogOut()
          }
        }).catch(() => {
          this.doLogOut()
        })
      }
    },
    checkStatus () {
      this.checkIsMobile()
      this.checkUserDetails()
    }
  }
}
</script>

<style>
#orientationModal .modal-dialog .modal-content {
  width:calc(100vw - 0px) !important;
  height: calc(100vh - 0px) !important;
  position: fixed;
  top: 0px;
  left: 0px;
}
#orientationModal .modal-header .close {
  display: none;
}

#tabletOrientationModal .modal-dialog .modal-content {
  width:calc(100vw - 0px) !important;
  height: calc(100vh - 0px) !important;
  position: fixed;
  top: 0px;
  left: 0px;
}
#tabletOrientationModal .modal-header .close {
  display: none;
}

.mTopNo {
  margin-top:0px !important;
}
.mTopNoLog {
  margin-top:5% !important;
}
.bgWhite {
  background-color:white;
}
.topNavMarginRight {
   margin-right: 20px !important;
   font-size:smaller;
}
.bg {
  background: var(--nv-color);
  background-image: linear-gradient(var(--nv-color), #fff) !important;
}
.altbg {
  background: #ff7e00 !important;
  background-image: linear-gradient(#ff7e00, #fff) !important;
}
.sidenav-primary[data-v-2e1b1b43]  {
  --active-color: var(--al-color) !important;
  --outline-color: var(--nv-color) !important;
}
.mdbvue-sidenav__item[data-v-070afb04] {
  border-radius: 2px;
  height:40px;
  margin-bottom:10px;
}
.mdbvue-sidenav[data-v-2e1b1b43] {
  color: var(--al-color) !important;
}
.height {
  height:10px !important;
  margin-bottom:80px;
}
#maindisplay::-webkit-scrollbar{
  width: 0px;
}
#maindisplay::-webkit-scrollbar-track{
  background: transparent;
}
#maindisplay::-webkit-scrollbar-thumb{
  background-color: lightgrey;
  border-radius: 0px;
}
.alert {
  position: relative;
  padding: .75rem 1.25rem;
  border-radius: 0rem;
  margin-top:-1px;
  margin-bottom: 0rem;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.mdbvue-sidenav__item[data-v-070afb04] {
  border-radius: 2px;
  height: 40px;
  /* margin-bottom: 10px; */
  color: var(--al-color) !important;
  font-size: 0.9rem;
}
.mdbvue-sidenav__item[data-v-070afb04]:focus, .mdbvue-sidenav__item[data-v-070afb04]:hover {
  color: var(--al-color) !important;
}
.div-width {
  width: 109% !important;
}
.mobile-notification {
  padding-right: 20px;
  text-align: left;
}
.noScrollX {
  top: 80px !important;
  overflow-x: hidden;
}
.bottomFixedNav {
  padding-bottom:50px;
}
.bgColor {
  background-color: #e5e7eb;
}

@media (max-width: 576px) {
  .candeskp {
    margin-top: -25px !important;
  }
  .vLib {
    margin-top: -25px !important;
  }
  .bannerHeight {
    height: 82px !important;
  }
}

@media (max-width: 800px) {
  .bannerHeight {
    height: 76px !important;
  }
}
</style>
