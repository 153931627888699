<template>
  <!--modal boilerplate-->
  <mdb-modal
    id="addVCVCandidatesModalShowing"
    :show="addVCVCandidatesModalShowing"
    size="lg"
    style="overflow-y:auto;"
    @close="closeAddCandidateModal()"
  >
    <mdb-modal-header>
      <mdb-modal-title class="p-2">
        ADD CANDIDATE(s) TO <span v-if="spotlight.Name" class="font-weight-bold">{{ spotlight.Name.toUpperCase() }}</span>
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body style="overflow-y:auto" class="p-4">
      <!-- <div class="d-flex align-items-center justify-content-center">
        <div class="d-flex align-items-center" style="height:60px">
            <i class="mr-3 fas fa-envelope" style="font-size: 1.3rem;"></i>
            <mdb-select v-model="sectorOptions" placeholder="Sector" size="sm" style="width:100%;" class="m-0 mx-3 addingSpotlightForm"/>
        </div>
        <div class="d-flex align-items-center" style="height:60px">
            <i class="mr-3 fas fa-envelope" style="font-size: 1.3rem;"></i>
            <mdb-select v-model="salaryOptions" placeholder="Salary" size="sm" style="width:100%;" class="m-0 mx-3 addingSpotlightForm"/>
        </div>
      </div> -->
      <!-- ALL CANDIDATES IN ROLE -->
      <div class="d-flex align-items-center justify-content-between my-3">
        <p class="text-left grey-text font-weight-bold" style="font-size:15px;">
          {{ search == '' ? 'All Candidates' : 'Results' }}
        </p>
        <ExpandableSearchBar
          @search="search = $event"
        />
      </div>
      <div style="height:150px;overflow-y:scroll;border:solid #C0C0C0 1px;">
        <div v-if="searchedCandidatesNotYetAddedToSpotlight.length > 0">
          <div
            v-for="(cand, i) in searchedCandidatesNotYetAddedToSpotlight"
            :key="i"
            style="border-radius:5px;height:45px;border:2px #C0C0C0 solid;background:#ffffff;cursor:pointer;overflow-x:auto;overflow-y:hidden;"
            class="p-2 m-2 borderColorOnHover"
            @click="addToCandidatesInSpotlight(cand)"
          >
            <div style="min-width: 100%;width: fit-content; overflow-x:auto;" class="d-flex align-items-center justify-content-between">
              <div style="height:100%;" class="d-flex align-items-center">
                <img
                  v-if="cand.ProfilePicture"
                  :src="cand.ProfilePicture"
                  style="height:25px;width:25px;border-radius:50%;object-fit:cover;"
                  class="d-inline mr-2"
                >
                <span
                  v-else
                  aria-hidden="true"
                  style="border:solid 1px #c0c0c0;height:25px;width:25px;border-radius:50%;object-fit:cover;"
                  class="mr-2 d-flex align-items-center justify-content-center"
                >
                  <i
                    class="fa fa-user"
                    aria-hidden="true"
                    style="font-size: 15px;"
                  />
                </span>
                <h5 class="text-left font-weight-bold d-inline text-nowrap mr-4">
                  {{ cand.Name }}
                </h5>
              </div>
              <b-col class="d-none d-lg-block">
                <p class="text-sm text-right" style="color:black !important;">
                  {{ cand.EmailAddress }}
                </p>
              </b-col>
            </div>
          </div>
        </div>
        <div v-else class="mt-3">
          <p class="grey-text text-center">
            No results
          </p>
        </div>
      </div>
      <!-- SELECTED CANDIDATES -->
      <p class="text-left my-3 grey-text font-weight-bold" style="font-size:15px;">
        Selected
      </p>
      <div style="border:1px solid #e1e1e1; height:140px;overflow:auto;" class="d-flex flex-wrap col-12 align-content-start mt-2 p-1">
        <div
          v-for="cand in candidatesAddedToSpotlight"
          :key="cand.CandidateId"
          class="p-1 ml-2 mr-1 mt-2"
          style="border:1px solid #C0C0C0; border-radius:5px;cursor:pointer;width:fit-content;height:fit-content;"
        >
          <div class="d-flex align-items-center justify-content-between">
            <div style="height:100%;" class="d-flex align-items-center">
              <img
                v-if="cand.ProfilePicture != '' && cand.ProfilePicture != null"
                :src="cand.ProfilePicture"
                style="height:25px;width:25px;border-radius:50%;object-fit:cover;"
                class="d-inline mr-2"
              >
              <mdb-icon
                v-else
                icon="fa fa-user"
                class="ml-1 mr-2"
                size="md"
              />
              <h5 class="text-left d-inline" style="font-size:14px;">
                {{ cand.Name }}
              </h5>
            </div>
            <div style="width:30px;" class="flex-grow-0">
              <i
                style="cursor:pointer;color:#C0C0C0;"
                class="fa fa-times colorOnHover ml-2"
                @click="removeFromCandidatesAddedToSpotlight(cand)"
              />
            </div>
          </div>
        </div>
      </div>
    </mdb-modal-body>
    <mdb-modal-footer class="p-4 m-0">
      <div class="d-flex align-items-center justify-content-between m-0" style="width:100%">
        <mdb-btn
          v-if="!loading"
          class="btn fl ml-auto text-nowrap m-0"
          size="sm"
          :disabled="candidatesAddedToSpotlight.length === 0"
          @click="addCandidatesToVCVSpotlight()"
        >
          ADD
        </mdb-btn>
        <mdb-btn
          v-if="loading"
          class="btn fl ml-auto"
          size="sm"
          style="width:110px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </div>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import clientPortalService from '@/api/services/clientPortal.service'
import ExpandableSearchBar from '@/components/UI/expandableSearch.vue'
import { CONVERT_STATUS_NAME } from '@/utils/convertStatus.js'

export default {
  name: 'AddVCVCandidateModal',
  components: {
    ExpandableSearchBar
  },
  props: {
    addVCVCandidatesModalShowing: Boolean,
    spotlight: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()

    return {
      authData,
      showGenericErrorModal
    }
  },
  data () {
    return {
      candidatesAddedToSpotlight: [],
      allRoleCandidates: [],
      search: '',
      searchedCandidates: [],
      loading: false,
      location: 'None',
      salaryOptions: [{ text: 'Only options', value: '' }],
      sectorOptions: [
        { value: 'None', text: 'None' },
        { value: 'Administration, business and management', text: 'Administration, business and management' },
        { value: 'Alternative therapies', text: 'Alternative therapies' },
        { value: 'Animals, land and environment', text: 'Animals, land and environment' },
        { value: 'Computing and ICT', text: 'Computing and ICT' },
        { value: 'Construction and building', text: 'Construction and building' },
        { value: 'Design, arts and crafts', text: 'Design, arts and crafts' },
        { value: 'Education and training', text: 'Education and training' },
        { value: 'Engineering', text: 'Engineering' },
        { value: 'Facilities and property services', text: 'Facilities and property services' },
        { value: 'Financial services', text: 'Financial services' },
        { value: 'Garage services', text: 'Garage services' },
        { value: 'Hairdressing and beauty', text: 'Hairdressing and beauty' },
        { value: 'Healthcare', text: 'Healthcare' },
        { value: 'Heritage, culture and libraries', text: 'Heritage, culture and libraries' },
        { value: 'Hospitality, catering and tourism', text: 'Hospitality, catering and tourism' },
        { value: 'Languages', text: 'Languages' },
        { value: 'Legal and court services', text: 'Legal and court services' },
        { value: 'Manufacturing and production', text: 'Manufacturing and production' },
        { value: 'Performing arts and media', text: 'Performing arts and media' },
        { value: 'Print and publishing, marketing and advertising', text: 'Print and publishing, marketing and advertising' },
        { value: 'Retail and customer services', text: 'Retail and customer services' },
        { value: 'Science, mathematics and statistics', text: 'Science, mathematics and statistics' },
        { value: 'Security, uniformed and protective services', text: 'Security, uniformed and protective services' },
        { value: 'Social sciences and religion', text: 'Social sciences and religion' },
        { value: 'Social work and caring services', text: 'Social work and caring services' },
        { value: 'Sport and leisure', text: 'Sport and leisure' },
        { value: 'Transport, distribution and logistics', text: 'Transport, distribution and logistics' }
      ]
    }
  },
  computed: {
    awaitingReviewCands () { return this.allRoleCandidates.filter(c => c.Status === 'Pending' || c.Status === 'Awaiting Review') },
    reviewedCands () { return this.allRoleCandidates.filter(c => c.Status === 'Reviewed') },
    shortlistedCands () { return this.allRoleCandidates.filter(c => c.Status === 'Shortlisted') },
    acceptedCands () { return this.allRoleCandidates.filter(c => c.Status === 'Accepted' || c.Status.includes('Offered')) },
    rejectedCands () { return this.allRoleCandidates.filter(c => c.Status === 'Rejected') },
    candidatesNotYetAddedToSpotlight () {
      return this.allRoleCandidates.filter(c => {
        if (this.candidatesAddedToSpotlight.findIndex(ca => ca.CandidateId === c.CandidateId) === -1) {
          return c
        }
      })
    },
    searchedCandidatesNotYetAddedToSpotlight () {
      if (this.search === '') { return this.candidatesNotYetAddedToSpotlight }
      return this.candidatesNotYetAddedToSpotlight.filter(c => c.Name.toLowerCase().includes(this.search.toLowerCase()))
    },
    selectedSector () {
      try {
        return this.sectorOptions.filter(o => o.selected)[0].value
      } catch { return 'None' }
    }
  },
  watch: {
    async addVCVCandidatesModalShowing () {
      if (this.addVCVCandidatesModalShowing) {
        this.getCandidatesToAddToVCVSpotlight()
      } else {
        this.resetModal()
      }
    }
  },
  methods: {
    CONVERT_STATUS_NAME (status) {
      return CONVERT_STATUS_NAME(status)
    },
    resetModal () {
      this.allRoleCandidates = []
      this.candidatesAddedToSpotlight = []
    },
    closeAddCandidateModal () {
      this.$emit('closeAddCandidateModal')
    },
    // not via QuickSelect
    addToCandidatesInSpotlight (cand) {
      this.candidatesAddedToSpotlight.push(cand)
    },
    // not via QuickSelect
    removeFromCandidatesAddedToSpotlight (cand) {
      this.candidatesAddedToSpotlight = this.candidatesAddedToSpotlight.filter(c => c.CandidateId !== cand.CandidateId)
    },
    async addCandidatesToVCVSpotlight () {
      this.loading = true
      try {
        var formData = new FormData()
        formData.append('SpotlightId', this.spotlight.GoClientPortalSpotlightId)
        const addedCandsIds = JSON.stringify(this.candidatesAddedToSpotlight.map(c => c.CandidateId))
        formData.append('CandidateIds', addedCandsIds)
        const res = await clientPortalService.addCandidatesToVCVSpotlight(formData)
        if (res.data === false) {
          this.showGenericErrorModal({ showing: true })
        }
        this.resetModal()
        this.closeAddCandidateModal()
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.loading = false
    },
    async getCandidatesToAddToVCVSpotlight () {
      try {
        const res = await clientPortalService.getCandidatesToAddToVCVSpotlight(this.spotlight.GoClientPortalSpotlightId, this.location, this.selectedSector, 0, 0)
        this.allRoleCandidates = res.data
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
    }
  }
}
</script>
