<template>
  <div>
    <mdb-modal
      id="customFormFieldModal"
      size="lg"
      :show="customFieldModalShowing"
      @close="customFieldModalShowing = false"
    >
      <mdb-modal-header>
        <mdb-modal-title class="text-xl font-weight-bold">
          Add A Custom Field (candidate profiles only)
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <!-- FORM QUESTION -->
        <div class="customFormStepWrapper mb-3">
          <div class="customFormStepWrapperTitle">
            STEP 1: Add your description
          </div>
          <mdb-input
            v-model="currentCustomFormField.Question"
            placeholder="Field Description"
            type="text"
            class="w-100 mx-auto mt-3"
            iconCode="fa-pen-line"
          />
        </div>

        <div class="customFormStepWrapper">
          <div class="customFormStepWrapperTitle">
            STEP 2: Preview Custom Field
          </div>
          <!-- NAVIGATION TABS -->
          <NavigationTabs
            class="mb-3"
            :tabs="navigationItems"
            @activeComponent="activeComponent = $event"
          />
          <component :is="activeComponent" />
        </div>
      </mdb-modal-body>
      <mdb-modal-footer>
        <button
          :disabled="!customFormValid"
          class="py-2 m-0 btn btn-primary rounded-full font-bold ml-auto mobButtonAddDefinition buttonRadius"
          style="width:140px;font-weight: bold;"
          @click="addCustomFormField()"
        >
          ADD
        </button>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { useCandidate } from '@/pinia'
import { storeToRefs } from 'pinia'
import TextInputDesign from './textInputDesign.vue'
import CheckboxDesign from './checkboxDesign.vue'
import DropdownMenuDesign from './dropdownMenuDesign.vue'
import { computed, ref, watch } from 'vue';
import NavigationTabs from '@/components/UI/navigationTabs.vue'

export default {
  name:'CustomFormFieldModal',
  components: { TextInputDesign, CheckboxDesign, DropdownMenuDesign, NavigationTabs
  },
  setup () {
    const candidateStore = useCandidate()
    const {
      addCustomFormField,
    } = useCandidate()
    const {
      customFieldModalShowing,
      currentCustomFormField
    } = storeToRefs(candidateStore)

    const navigationItems = [
      { componentName: 'TextInputDesign', componentNameReadable: 'Text Input' },
      { componentName: 'CheckboxDesign', componentNameReadable: 'Checkbox' },
      { componentName: 'DropdownMenuDesign', componentNameReadable: 'Dropdown Menu' }
    ]
    const activeComponent = ref('TextInputDesign')
    watch(activeComponent, () => {
      currentCustomFormField.value.Type = ''
      currentCustomFormField.value.ReadableType = ''
      currentCustomFormField.value.CustomFieldInformation = null
    })
    watch(customFieldModalShowing, () => {
      activeComponent.value = 'TextInputDesign'
    })

    const customFormValid = computed(() => {
      if (activeComponent.value == 'DropdownMenuDesign') {
        return currentCustomFormField.value.Type != '' && currentCustomFormField.value.Question != '' &&  currentCustomFormField.value.CustomFieldInformation?.length > 1
      } else {
        return currentCustomFormField.value.Type != '' && currentCustomFormField.value.Question != ''
      }
    })

    return {
      customFieldModalShowing,
      activeComponent,
      navigationItems,
      addCustomFormField,
      customFormValid,
      currentCustomFormField
    }
  }
}
</script>

<style>
.customFormStepWrapper {
  background: #e5e5e5;
  padding: 10px 10px;
}
.customFormStepWrapperTitle  {
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
}
</style>
