<template>
  <div
    class="px-4 px-lg-4 overFlowY"
    :class="mobileView ? 'mt-2' : 'mt-3 pt-1'"
    style="height:calc(100vh - 100px);overflow-y:auto;margin-bottom:100px;"
  >
    <div style="height:fit-content;">
      <div class="candidatesScrollContainer">
        <div class="p-2 p-lg-3"> 
          <div class="d-flex align-items-center justify-content-between text-left">
            <div class="mr-3">
              <h3
                v-if="candidateTableLoading"
                class="primary-colour font-weight-bold"
                style="margin-bottom:0px;margin-top:-5px !important;"
              >
                Loading
                <i class="fa fa-spin fa-spinner" style="margin-top: 0px;margin-left: 5px;" />
              </h3>
              <h3
                v-else
                style="margin-bottom:0px;margin-top:-5px !important;"
                class="primary-colour font-weight-bold"
              >
                Candidate Search
              </h3>
            </div>
          </div>
          <div
            v-if="mobileView"
            style="width: 100%"
            class="d-flex align-items-center justify-content-start mr-auto mt-4"
          >
            <ExpandableSearchBar
              style="max-width:85% !important;"
              :width="800"
              place-holder="Name, Email or External Candidate Reference"
              :max-width-percentage="85"
              :search-prop="search"
              :searchTriggersAPICall="true"
              :force-always-focused="true"
              :large-search="true"
              :disable-right-alignment="true"
              @search="doNewSearch"
            />
            <CandidateTableDisplaySettings
              @fields="fields = $event"
            />
          </div>
          <div
            v-else
            style="width: 100%"
            class="d-flex align-items-center justify-content-start mr-auto mt-4"
          >
            <ExpandableSearchBar
              :width="700"
              place-holder="Name, Email or External Candidate Reference"
              :max-width-percentage="65"
              :search-prop="search"
              :searchTriggersAPICall="true"
              :force-always-focused="true"
              :large-search="true"
              :disable-right-alignment="true"
              class="tabletSearchBarWidth"
              @search="doNewSearch"
            />
            <CandidateTableDisplaySettings
              @fields="fields = $event"
            />
          </div>
          <div
            v-if="candidateTableRecentSearches.length > 0"
            class="d-flex align-items-center justify-content-start mb-2 my-3 mr-2"
          >
            <FontAwesomeIcon
              icon="fa-solid fa-clock-rotate-left"
              class="mr-3"
              title="Recent Searches"
            />
            <ClickToPopulate
              v-for="(search, i) in candidateTableRecentSearches"
              :key="i"
              class="mr-5"
              :text="search"
              @clicked="doNewSearch(search)"
            />
          </div>

          <b-table
            v-if="candidates.length > 0"
            id="candidates-table"
            class="text-left mt-4"
            head-variant="dark"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :items="candidates"
            :per-page="candidateTablePerPage"
            :current-page="1"
            :fields="fields"
            responsive
            :striped="true"
            hover
            small
            sort-icon-left
            auto-width
          >
            <template #cell(name)="data">
              <div
                style="height:28px !important;"
                class="d-flex align-items-center"
                :class="{ 'fSizeSm': mobileView }"
              >
                <div style="width:40px;border-radius:50%;display:flex;align-items:center;justify-content:center;" class="mr-2 d-sm-block d-md-none d-lg-block picTransform">
                  <img
                    style="border:solid 1px #c0c0c0;height:36px;width:36px;border-radius:50%;object-fit:cover;cursor:pointer"
                    :src="data.item.ProfilePicture"
                    :title="`Id: ` + data.item.CandidateId + `. Ref: ` + (data.item.ExternalCandidateReference ? data.item.ExternalCandidateReference : 'n/a')"
                  >
                </div>
                <div class="mr-2" style="transform:translateY(-8px)">
                  <div class="d-none d-lg-inline nameTransform">
                    {{ data.item.Name }}
                  </div>
                  <div class="d-flex d-lg-none align-items-center nameTransform">
                    <div class="mr-1">
                      {{ data.item.Name }}
                    </div>
                    <mdb-icon
                      v-if="data.item.Following"
                      icon="star"
                      title="Following"
                      style="color:var(--pr-color);transform:scale(0.8)"
                      @click="unFollowCandidate(data.item)"
                    />
                    <mdb-icon
                      v-else
                      far
                      icon="star"
                      title="Not Following"
                      style="color:var(--pr-color);transform:scale(0.8)"
                      @click="followCandidate(data.item)"
                    />
                  </div>
                </div>
                <div style="transform:translateY(-8px)" class="d-none d-lg-flex">
                  <div
                    v-if="data.item.Following"
                    style="color:var(--pr-color);border: 1px var(--pr-color) solid; border-radius:5px;background-color:#fff;"
                    class="p-1 ml-2 d-flex align-items-center cp"
                    @click="unFollowCandidate(data.item)"
                  >
                    <mdb-icon
                      icon="star"
                      title="Following"
                      style="transform:scale(0.8)"
                    />
                  </div>
                  <div
                    v-else
                    style="color:var(--pr-color);border: 1px var(--pr-color) solid; border-radius:5px;background-color:#fff;"
                    class="p-1 ml-2 d-flex align-items-center cp"
                    @click="followCandidate(data.item)"
                  >
                    <mdb-icon
                      far
                      icon="star"
                      title="Not Following"
                      style="transform:scale(0.8)"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template #cell(dateadded)="data">
              <div
                style="height:100%"
                class="d-flex align-items-center"
                :class="{ 'fSizeSm': mobileView }"
              >
                {{ new Date(data.item.DateAdded).toLocaleDateString([userLanguage]) }}
              </div>
            </template>
            <template #cell(actions)="data">
              <div class="d-flex align-items-center justify-content-end" style="cursor:pointer;transform:translateY(3px);min-width:100px !important;margin-top:-2px;margin-bottom:4px;">
                <button
                  style=""
                  title="View/Edit Candidate?"
                  type="button"
                  class="btn btn-primary btn-md py-1 py-lg-2 px-2 px-lg-3 m-0 d-none d-md-block"
                  @click="editCandidate(data.item)"
                >
                  <div>VIEW</div>
                </button>
                <button
                  style=""
                  title="View/Edit Candidate?"
                  type="button"
                  class="btn btn-primary btn-md py-2 px-2 px-lg-3 m-0 d-block d-md-none"
                  @click="editCandidate(data.item)"
                >
                  <FontAwesomeIcon icon="fa-solid fa-user-gear" />
                </button>
                <mdb-popover
                  ref="popover"
                  trigger="click"
                  :options="{placement: 'left'}"
                  style="cursor:pointer;align-self:center;"
                  class="grey-text"
                >
                  <div slot="body">
                    <h6
                      class="d-flex mb-2"
                      style="align-items:center"
                      @click="showDeleteCandidateModal(data.item.CandidateId)"
                    >
                      <i class="fa fa-trash ml-1 grey-link " style="margin-right:7px;" /><a class="grey-link mr-3 text-nowrap">Delete</a>
                    </h6>
                  </div>
                  <mdb-icon
                    slot="reference"
                    style="color:#555;font-size:1rem;"
                    class="ellipsisButtonHover mx-2"
                    icon="ellipsis-v"
                  />
                </mdb-popover>
              </div>
            </template>
          </b-table>
          <h3
            v-if="candidates.length == 0 && !candidateTableLoading && search"
            class="primary-colour font-weight-bold mt-4"
            style="float:left;"
          >
            No Results
          </h3>
          <div
            v-if="mobileView === false && candidates.length > 0"
            md="2"
            offset-md="10"
            style="margin-top:-10px;"
            class="d-flex justify-content-between flex-wrap"
          >
            <mdb-btn
              style="margin-right: 0px;width: 110px;padding: 6px !important;text-align: center;margin-top:15px;min-width:fit-content;height: 38px;"
              class="mdc-button btn btn-solid-outline-web"
              @click="$emit('openShareModal')"
            >
              <span class="secondary-colour text-nowrap"><i class="fa fa-share-alt" /> SHARE</span>
            </mdb-btn>
            <div style="margin-top:-10px;">
              <div style="float:left;">
                <b-pagination
                  v-show="candidateTableCount >= candidates.length"
                  v-model="candidateTableCurrentPage"
                  class="float-right mt-4"
                  :total-rows="candidateTableCount"
                  :per-page="candidateTablePerPage"
                  first-number
                  last-number
                  aria-controls="candidates-table"
                  prev-text="<"
                  next-text=">"
                />
              </div>

              <!--pagination dropdown-->
              <div style="float:left;">
                <select
                  v-if="notChangingPagination"
                  v-model="page"
                  class="selectStyle selectClass"
                  @change="perPageStorage(page)"
                >
                  <option value="10" :selected="userData.pge === 10">
                    10 per page
                  </option>
                  <option value="25" :selected="userData.pge === 25">
                    25 per page
                  </option>
                  <option value="50" :selected="userData.pge === 55">
                    50 per page
                  </option>
                  <option value="100" :selected="userData.pge === 100">
                    100 per page
                  </option>
                </select>
                <div
                  v-else
                  class="selectStyleElse"
                >
                  <i class="fa fa-spin fa-spinner" style="margin-top:10px;margin-left: -5px;" /> loading page
                </div>
              </div>
              <!--pagination dropdown-->
            </div>
          </div>
          <div
            v-if="mobileView"
            md="2"
            offset-md="10"
            class="d-flex justify-content-between flex-wrap"
            style="float:right;margin-top:-20px;"
          >
            <b-pagination
              v-show="candidateTableCount > candidates.length"
              v-model="candidateTableCurrentPage"
              class="float-right mt-4"
              :total-rows="candidateTableCount"
              :per-page="candidateTablePerPage"
              first-number
              last-number
              aria-controls="candidates-table"
              prev-text="<"
              next-text=">"
            />
          </div>
          <div class="fixed bottom-0 mobDisplay" style="background: white;width: 100%;border-top: 1px solid rgb(161, 161, 161);z-index: 1001;bottom: 0px;left: 0px;height: 55px;">
            <div class="row" style="margin-top:5px;">
              <div class="col-sm-12 text-center">
                <mdb-popover
                  v-model="popover6"
                  trigger="click"
                  :options="{placement: 'bottom'}"
                >
                  <div slot="body">
                    <h6 style="margin-left:-10px;margin-top:-5px;padding:10px;" @click="newCandidate();popover6 == !popover6">
                      <a class="ml-1 grey-link"><i class="fa fa-user" /> New</a>
                    </h6>
                    <h6 style="margin-left:-10px;padding:10px;" @click="importCandidates()">
                      <a class="ml-1 grey-link"><i class="fa fa-users" /> Import</a>
                    </h6>
                  </div>
                  <mdb-btn
                    style="width: 110px;padding: 6px !important;text-align: center;min-width:fit-content"
                    class="fl mdc-button btn-outline btn"
                    @click="$emit('openShareModal')"
                  >
                    <span class="primary-colour text-nowrap"><i class="fa fa-share-alt" /> Share</span>
                  </mdb-btn>
                  <mdb-btn
                    slot="reference"
                    style="width: 110px;padding: 6px !important;text-align: center;"
                    title="Add candidate"
                    class="fr mdc-button btn-solid-outline-mob btn"
                  >
                    <div class="secondary-colour d-flex align-items-center justify-content-center">
                      <i class="fa fa-plus-circle mr-1" /> Add
                    </div>
                  </mdb-btn>
                </mdb-popover>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMobile } from '@/composables/useMobile'
import candidateService from '@/api/services/candidate.service'
import signUpService from '@/api/services/signup.service'
import ExpandableSearchBar from '@/components/UI/expandableSearch.vue'
import { userLanguage } from '@/common/userLanguage'
import CandidateTableDisplaySettings from '../ViewAllCandidates/candidateDisplaySetting.vue'
import { useUserData, useCandidate, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import ClickToPopulate from './recentSearch.vue'

export default {
  name: 'ViewAllCandidates',
  components: {
    ExpandableSearchBar, CandidateTableDisplaySettings, ClickToPopulate
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()

    const candidateStore = useCandidate()
    const {
      candidates,
      recentCandidates,
      candidateToEdit,
      editingCandidate,
      view,
      candidateFiles,
      getCandidates,
      candidateTableCount,
      candidateTablePerPage,
      candidateTableCurrentPage,
      candidateTableLoading,
      candidateTableRecentSearches
    } = storeToRefs(candidateStore)

    const {
      updateCandidateFollowing,
      deleteCandidate,
      resetCandidate,
    } = useCandidate()

    const { mobileView } = useMobile()

    return {
      authData,
      userData,
      candidates,
      updateCandidateFollowing,
      deleteCandidate,
      recentCandidates,
      resetCandidate,
      candidateToEdit,
      editingCandidate,
      view,
      candidateFiles,
      mobileView,
      getCandidates,
      candidateTableCount,
      candidateTablePerPage,
      candidateTableCurrentPage,
      candidateTableLoading,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal,
      candidateTableRecentSearches
    }
  },
  data () {
    return {
      fields: [
        { key: 'Name', label: '  Candidate', tdClass: 'headerPadding col55 sortIcon', sortable: true },
        { key: 'DateAdded', label: '  Created', tdClass: 'headerPadding col25 sortIcon', sortable: true },
        { key: 'CandidateId', label: '', sortable: true },
        { key: 'Actions', label: '', tdClass: 'headerPadding col20', sortable: false }
      ],
      sortBy: '',
      search: null,
      sortDesc: false,
      userLanguage,
      popover6: false,
      popover7: false,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      notChangingPagination: true,
      page: this.userData.pge
    }
  },
  computed: {
    doViewAllSearch () {
      return true
    }
  },
  watch: {
    candidateTableCurrentPage () {
      this.getCandidates(this.search)
    },
    doViewAllSearch () {
      if (this.doViewAllSearch) this.doNewSearch('')
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.setPerPage()
    this.doNewSearch('')
  },
  beforeDestroy () {
    this.candidates = []
    this.search = null
    window.removeEventListener('resize', this.onResize)
  },
  created () {
    this.setPerPage()
    this.doNewSearch('')
  },
  methods: {
    perPageStorage (e) {
      this.notChangingPagination = false
      candidateService.updatePerPage(e).then((response) => {
        if (response.data === true) {
          signUpService.getUserDetails(this.authData.access_token).then(async (res) => {
            this.userData = res.data
            this.candidateTablePerPage = e
            this.getCandidates(this.search)
            this.notChangingPagination = true
          })
        } else {
          this.notChangingPagination = true
        }
      })
    },
    doNewSearch (search, forceSearch = false) {
      if (!forceSearch) {
        if (search == this.search && !this.doViewAllSearch || !search && !this.doViewAllSearch) return
      }
      this.candidateTableCurrentPage = 1
      this.candidateTableCount = 0;
      this.search = search
      this.getCandidates(search)
      this.$router.push('/candidates').catch(() => {})
    },
    setPerPage () {
      this.candidateTablePerPage = this.userData.pge
    },
    onResize () {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
      this.setPerPage()
    },
    followCandidate (candidate) {
      candidateService.followCandidate(candidate.CandidateId).then((response) => {
        if (response.data === true) {
          this.updateCandidateFollowing({ candId: candidate.CandidateId, following: true })
        }
      })
    },
    unFollowCandidate (candidate) {
      candidateService.unFollowCandidate(candidate.CandidateId).then((response) => {
        if (response.data === true) {
          this.updateCandidateFollowing({ candId: candidate.CandidateId, following: false })
        }
      })
    },
    editCandidate (candidate) {
      this.candidateToEdit =  { ...candidate }
      this.editingCandidate = true
      this.view = 'Profile'
      this.candidateFiles = []
    },
    importCandidates () {
      this.view = 'ImportCandidates'
    },
    async onDeleteConfirm (candidateId) {
      try {
        const res = await candidateService.deleteCandidate(candidateId)
        if (res == undefined) throw new Error
        let search = this.search
        this.search = null
        this.candidates = []
        this.doNewSearch('', true)
        this.doNewSearch(search)
        this.reloadRecentCandidates()
        this.closeGenericConfirmModal()
      } catch (err) {
        this.closeGenericConfirmModal()
        this.showGenericErrorModal({ showing: true, errorMessage: 'This candidate has active applications therefore cannot be deleted.', errorTitle: 'CANNOT DELETE' })
      }
    },
    async reloadRecentCandidates () {
      try {
        const res = await candidateService.getRecentCandidates()
        this.recentCandidates = res.data
      } catch (err) {
        //?
      }
    },
    newCandidate () {
      this.editingCandidate = false
      this.resetCandidate()
      this.view = 'Profile'
    },
    showDeleteCandidateModal (candidateId) {
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'DELETE CANDIDATE',
        confirmMessage: 'Are you sure you want to delete this candidate? The platform will not delete any candidate that is already invited to a role, meeting or scheduled event.',
        confirmButtonMessage: 'CONFIRM',
        confirmButtonFunction: () => this.onDeleteConfirm(candidateId)
      })
    },
    sortOptions () {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.margins {
  margin-top:4px;margin-bottom:6px;
}
.fSizeSm {
  font-size:smaller;
}
.margins-loading {
  margin-left: 0px;
  margin-top: 17px;
}
.canPadding {
  margin-top:10px;
}
@media (max-width :768px) {
  .picTransform {
    transform:translateY(-0px);
  }
  .nameTransform {
    transform:translateY(6px);
  }
}
@media (min-width: 769px) and (max-width: 996px) {
  .nameTransform {
    transform:translateY(10px);
  }
}
@media (min-width :996px) {
  .picTransform {
    transform:translateY(-4px);
  }
  .nameTransform {
    transform:translateY(-6px);
  }
}

@media (max-width: 576px) {
  .mobileFontSize {
    font-size: 14px;
  }
}
</style>

<style lang="css">
th.viewAllCandsTH div {
  margin-right:30px !important;
  min-width: 60px !important;
}
th.actionTH div {
  width: 50px !important;
}
th.viewAllCandsTD {
  min-height: 30px;
}
</style>
