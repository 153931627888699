<template>
  <div
    v-if="!userData.aut"
    class="m-auto"
    style="color:#ff7e00;overflow-x: hidden;margin-top: 10px;"
    :style="{'transform': !mobileView ? 'translateY(-90px) scale(0.95)' : 'scale(0.95)'}"
  >
    <!--login button and strap line-->
    <img
      :src="userData.lbl.Logo"
      class="m-auto"
      alt="Shine Go"
      style="height:60px;object-fit:cover;display: block;margin-bottom:12px !important;"
      :class="{ 'margTop': mobileView }"
    >
    <div class="container-fluid" style="height:430px;transform: scale(0.955);background-color: #fff;background-clip: border-box;border: 1px solid rgba(0,0,0,.125);border-radius: .25rem;box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);">
      <div
        class="col-12"
        :class="{ 'threeNinetyWidth': mobileView }"
      >
        <div class="container-right-half">
          <div style="margin-left:0px; margin-right:0px;width:100%;padding:0px;margin-top:20px;">
            <div v-show="!resetSuccess" class="text-left primary-colour">
              <validation-observer v-slot="{ invalid:invalid }">
                <form>
                  <div class="h5" style="margin-top: 10px;padding-bottom: 10px;text-align: center;font-weight: bold;">
                    <span v-show="nmError">{{ $parent.$parent.error }}</span>
                    <span v-show="!nmError">Set your password</span>
                  </div>
                  <div class="md-form">
                    <i class="fas fa-lock prefix" style="margin-top:10px;font-size:1rem;color:gray;" />
                    <validation-provider
                      v-slot="{ errors }"
                      mode="lazy"
                      name="Password"
                    >
                      <mdb-input
                        id="input-password"
                        v-model="password"
                        :type="visibility"
                        auto-complete="new-password"
                        label="Create a new password"
                        style="margin-left:33px;"
                        size="sm"
                        @input="badPassword = false"
                      />
                      <a
                        v-if="visibility == 'password'"
                        style="position:absolute;right:0;top:10px;z-index:1000;"
                        @click="showPassword()"
                      ><i class="fas fa-eye grey-text" title="Show password." /></a>
                      <a
                        v-if="visibility == 'text'"
                        style="position:absolute;right:0px;top:10px;z-index:1000;"
                        @click="hidePassword()"
                      ><i class="fas fa-eye-slash grey-text" title="Hide password." /></a>
                      <span style="position:absolute;right:-230px;top:10px;width:275px;cursor:pointer;">
                        <mdb-popover
                          trigger="click"
                          :options="{placement: 'left'}"
                          style="cursor:pointer;"
                          class="grey-text"
                        >
                          <div slot="body" style="padding: 5px;">Your password must be 12-64 characters long, contain letters and numbers. You may use special characters, but your password must NOT contain spaces or emojis. Please use either a combination of numbers and/or upper case and lower case letters or a complex password combining three random words e.g. applenemobiro. Passwords that contain words like `qwerty` or `password` or `111` or `123435` will be rejected.</div>
                          <span slot="reference"><i class="fas fa-info-circle" /></span>
                        </mdb-popover>
                      </span>
                      <div v-if="errors[0] || badPassword === true" style="font-size: 0.7rem;margin-left: 33px;margin-top:-20px;margin-bottom:0px;">
                        Your password is too weak or must be at least 12 characters long. 
                      </div>
                    </validation-provider>
                  </div>
                  <div class="md-form">
                    <i class="fas fa-lock prefix" style="margin-top:10px;font-size:1rem;color:gray;" />
                    <validation-provider
                      v-slot="{ errors }"
                      mode="lazy"
                      name="Confirm"
                      rules="required|confirm:@Password"
                    >
                      <mdb-input
                        v-model="confirmation"
                        :type="visibility2"
                        auto-complete="new-password"
                        label="Confirm your new password"
                        style="margin-left:33px;"
                        size="sm "
                      />
                      <a
                        v-if="visibility2 == 'password'"
                        style="position:absolute;right:0;top:10px;z-index:1000;"
                        @click="showPassword2()"
                      ><i class="fas fa-eye grey-text" title="Show password." /></a>
                      <a
                        v-if="visibility2 == 'text'"
                        style="position:absolute;right:0px;top:10px;z-index:1000;"
                        @click="hidePassword2()"
                      ><i class="fas fa-eye-slash grey-text" title="Hide password." /></a>
                      <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-20px;margin-bottom:0px;">
                        Passwords must match!
                      </div>
                    </validation-provider>
                  </div>
                  <div style="width:100%;float:left;font-size:small;text-align: left;margin-top: 5px;margin-left:30px;margin-bottom: 0px;text-decoration: underline;cursor: pointer;z-index: 1000;" @click="toggleAgree()">
                    <mdb-input
                      v-model="agree"
                      type="checkbox"
                      style="margin-left:-20px;width:40px;"
                      size="sm"
                    />
                    <div style="float:left;font-size:small;text-align: left;margin-top: -28px;margin-left:30px;cursor: pointer;z-index: 1000;margin-bottom: 20px;">
                      I have read the  
                      <a
                        class="primary-colour"
                        style="text-decoration: underline;"
                        @click="privacyLink()"
                      >privacy</a> policy.
                    </div>
                  </div>
                  <!-- set button-->
                  <div style="position: fixed; bottom: 10px; float:right;right:15px;">
                    <div>
                      <mdb-btn
                        v-show="!buttonClick"
                        :disabled="invalid || agree === false || badPassword === true"
                        class="float-right"
                        style="width:110px;bottom: 0px !important;right: -5px !important;margin-top: -5px;"
                        size="sm"
                        :class="{ 'mobButtonHeight': mobileView }"
                        @click="doSetPassword"
                      >
                        SET
                      </mdb-btn>
                      <mdb-btn
                        v-show="buttonClick"
                        class="float-right"
                        style="width:110px;bottom: 0px !important;right: -5px !important;margin-top: -5px;"
                        size="sm"
                        :class="{ 'mobButtonHeight': mobileView }"
                      >
                        <mdb-icon icon="fa fa-pulse fa-spinner" />
                      </mdb-btn>
                    </div>
                  </div>
                </form>
              </validation-observer>
            </div>
            <div v-show="resetSuccess" class="card-body text-left primary-colour">
              <form>
                <div class="h5" style="margin-top: 10px;padding-bottom: 10px;">
                  <span v-show="nmError">{{ $parent.$parent.error }}</span>
                  <span v-show="!nmError">Set your password</span>
                </div>
                <div class="grey-text" style="width:300px;font-size:smaller;color:#9e9e9e;">
                  <div style="width:500px">
                    Your password has successfully been set.<a class="ml-1 primary-colour" @click="autoLogin">Click here</a> to login now.
                  </div><br>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--error modal-->
    <mdb-modal :show="errorModal" @close="errorModal = false">
      <mdb-modal-header>
        <mdb-modal-title>ERROR</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          <span v-html="error" />
        </p>
      </mdb-modal-body>
    </mdb-modal>

    <!--footer-->
    <mdb-row>
      <mdb-col style="font-size:x-small;margin-bottom:20px;margin-top:0px;color:#b1b1b1;">
        powered by <a
          href="http://www.shineinterview.com"
          target="_blank"
          style="color:#b1b1b1 !important;"
        >www.shineinterview.com</a> &copy;{{ new Date().getFullYear() }} {{ VERSION }}
      </mdb-col>
    </mdb-row>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { extend } from 'vee-validate'
import { useMobile } from '@/composables/useMobile'
import { logout } from '@/methods/auth.js'
import { VERSION } from '@/common/config.js'
import signUpService from '@/api/services/signup.service'
import { setDefaultLanding } from '@/utils/defaultLanding.js'

import { mdbBtn } from 'mdbvue'

extend('confirm', {
  params: ['target'],
  lazy: true,
  validate (value, { target }) {
    return value === target
  },
  message: 'Password confirmation does not match!'
})

export default {
  name: 'SetPassword',
  components: {
    mdbBtn
  },
  setup () {
    const userStore = useUserData()
    const { landingPage, userData, authData } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    return {
      landingPage,
      userData,
      authData,
      mobileView
    }
  },
  data () {
    return {
      password: '',
      confirmation: '',
      error: '',
      buttonClick: false,
      errorModal: false,
      visibility: 'password',
      visibility2: 'password',
      login: true,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      resetSuccess: false,
      nmError: false,
      agree: false,
      badPassword: false
    }
  },
  computed: {
    VERSION () { return VERSION }
  },
  created () {
    if (this.userData.aut) {
      this.logout().then(() => this.$router.push('/setPassword?userId=' + this.$route.query.userId + '&code=' + this.$route.query.code).catch(() => { }))
    }
    this.windowHeight = window.innerHeight
  },
  methods: {
    privacyLink () {
      window.open('https://www.shineinterview.com/privacy-policy/', '_blank')     
    },
    toggleAgree () {
      if (this.agree === false) { this.agree = true } else { this.agree = false }
    },
    logout,
    doSetPassword: async function () {
      if (this.password.includes('password') || this.password.includes('qwerty') || this.password.includes('12345') || this.password.includes('111') || this.password.length < 12) {
        this.badPassword = true
        this.agree = false
      } else {
        this.badPassword = false
        this.buttonClick = true
        var data = {
          UserId: this.$route.query.userId,
          ResetToken: this.$route.query.code,
          NewPassword: this.password
        }     
        signUpService.setPassword(data).then(() => {
          this.resetSuccess = true
          this.buttonClick = false
        }).catch((err) => {
          this.buttonClick = false
          this.errorAlert(err, 'Sorry, failed to set your new password! Please check your password is 12-64 characters long, contains letters and numbers, and does not contain spaces, special characters, or emojis. Please use either a combination of numbers and/or upper case and lower case letters or a complex password combining three random words e.g. applenemobiro. Passwords that contain words like `qwerty` or `password` or `111` or `123435` will be rejected.')
          this.error = 'Sorry, failed to set your new password! Please check your password is 12-64 characters long, contains letters and numbers, and does not contain spaces, special characters, or emojis. Please use either a combination of numbers and/or upper case and lower case letters or a complex password combining three random words e.g. applenemobiro. Passwords that contain words like `qwerty` or `password` or `111` or `123435` will be rejected.'
          this.$parent.error = 'Sorry, failed to set your new password! Please check your password is 12-64 characters long, contains letters and numbers, and does not contain spaces, special characters, or emojis. Please use either a combination of numbers and/or upper case and lower case letters or a complex password combining three random words e.g. applenemobiro. Passwords that contain words like `qwerty` or `password` or `111` or `123435` will be rejected.'
        })
      }
    },
    autoLogin: async function () {
      try {
        const user = await signUpService.getUserFromId(this.$route.query.userId)
        const login = await signUpService.login(user.data, this.password)
        this.authData = login.data
        await this.getUserDetails(this.authData.access_token)
      } catch(error) {
          this.errorAlert(error, 'Sorry an error has occurred!')
      }
    },
    async getUserDetails (token) {
      signUpService.getUserDetails(token).then(async (res) => {
        this.userData = res.data
        await setDefaultLanding(res.data.dlp)
        this.$router.push(this.landingPage).catch((e) => e)
      }).catch((error) => {
        this.buttonClick = false
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
    },
    errorAlert (error, err) {
      try {
        if (error.response.data != null) {
          if (error.response.data.Message != null) {
            if (this.mobileView === false) { this.$parent.errorModal = true } else { this.nmError = true }
            this.$parent.error = error.response.data.Message
            if (this.$parent.error === '') { this.$parent.error = err }
          } else if (error.response.data.error_description != null) {
            if (this.mobileView === false) { this.$parent.errorModal = true } else { this.nmError = true }
            this.$parent.error = error.response.data.error_description
            if (this.$parent.error === '') { this.$parent.error = err }
          } else {
            if (this.mobileView === false) { this.$parent.errorModal = true } else { this.nmError = true }
            this.$parent.error = error.response.data
            if (this.$parent.error === '') { this.$parent.error = err }
          }
        } else {
          if (this.mobileView === false) { this.$parent.errorModal = true } else { this.nmError = true }
          this.$parent.error = err
        }
      } catch {
        if (this.mobileView === false) { this.$parent.errorModal = true } else { this.nmError = true }
        this.$parent.error = err
      }
    },
    register () {
      this.$router.push('/signup').catch(() => { })
    },
    signIn () {
      this.$router.push('/')
    },
    showPassword () {
      this.visibility = 'text'
    },
    showPassword2 () {
      this.visibility2 = 'text'
    },
    hidePassword () {
      this.visibility = 'password'
    },
    hidePassword2 () {
      this.visibility2 = 'password'
    }
  }
}
</script>

<style>
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }
  .close {
    color:#c0c0c0 !important;
  }
</style>
