<!-- eslint-disable no-tabs -->
<template>
  <div
    class="p-2 mb-2 mx-2"
    style="border: solid 2px #e1e1e1;border-radius: 10px;"
  >
    <div class="d-flex justify-content-between">
      <div
        style="min-height:40px;min-width:40px;border-radius:50%;display:flex;align-items:start;justify-content:center;"
        class="mr-2 d-none d-sm-block d-md-none d-lg-block"
      >
        <img
          v-if="picture.length"
          style="border:solid 1px #c0c0c0;height:36px;width:36px;border-radius:50%;object-fit:cover;"
          :src="picture"
        >
        <mdb-icon
          v-else
          icon="fa fa-user fa-2x"
          size="lg"
        />
      </div>

      <div
        class="grey-text mr-auto align-self-start text-left pt-2"
        style="font-size:small;white-space: pre-wrap;"
      >
        <div v-html="notes" />
      </div>

      <div
        class="align-self-start pt-1 pr-1"
        style="font-size:small;"
      >
        <i
          v-if="userData.une === email"
          class="fa fa-times grey-text"
          style="cursor:pointer;"
          @click="$emit('deleteModal', id)"
        />
      </div>
    </div>

    <div
      class="d-flex align-items-center justify-content-end mt-2 font-weight-bold"
      style="font-size:x-small;"
    >
      <div class="mr-2">
        {{ author }}
      </div>

      <div v-if="accountName !== ''" class="ml-1 mr-2">
        ( {{ accountName }} )
      </div>

      <div class="grey-text">
        {{ formattedDate }}
      </div>
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  props: {
    author: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    id: {
      type: Number,
      default: 1
    },
    notes: {
      type: String,
      default: ''
    },
    picture: {
      type: String,
      default: ''
    },
    accountName: {
      type: String,
      default: ''
    },
    userLanguage: {
      type: String,
      default: 'en-GB'
    }
  },
  setup () {
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)

    return {
      userData
    }
  },
  computed: {
    formattedDate () {
      let mins = new Date(this.date).getMinutes()
      if (mins.toString().length === 1) mins = `0${mins}`
      return `${new Date(this.date).toLocaleDateString([this.userLanguage])} ${new Date(this.date).getHours()}:${mins}`
    }
  }
}
</script>
