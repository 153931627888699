<template>
  <!-- todo - branding colours -->
  <div
    style="position: relative"
    class="border-2 border-gray-600 rounded-lg flex flex-col font-bold cursor-pointer px-1 pt-1 "
    :class="{'bg-gray-300': isWeekendDay && !datesMatch, 'bg-secondary-colour': !isWeekendDay && !datesMatch, 'bg-primary-colour' : datesMatch, 'hover:bg-gray-200' : !datesMatch }"
    @click="changeDate()"
  >
    <div class="calendarLine first" />
    <div class="calendarLine second" />
    <div class="calendarLine third" />
    <span
      style=""
      class="uppercase"
      :class="datesMatch ? 'dark-grey-text' : 'text-primary-colour'"
    >
      {{ weekday }}
    </span>
    <div class="flex flex-col dark-grey-text font-weight-bold">
      <span
        style="letter-spacing: 2px; font-weight: 900"
        class="text-3xl"
      > {{ date }} </span>
      <span class="uppercase"> {{ month }} {{ year }} </span>
    </div>
    <span
      class="countPill"
      :title="`${count} booking${count == 1 ? '' : 's'}`"
      :class="{'bg-secondary-colour': datesMatch, 'bg-primary-colour white-text' : !datesMatch }"
    >
      <mdb-icon
        v-if="count == -1"
        icon="fa fa-pulse fa-spinner"
      />
      <span v-else>
        {{ count }}
      </span>
    </span>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useEvent } from '@/pinia/useEvent'
import { storeToRefs } from 'pinia'
import moment from 'moment';

export default {
  name: 'DayCard',
  props: {
    day: {
      type: Date,
      default: new Date()
    },
    count: {
      type: Number,
      default: 1
    }
  },
  setup (props) {
    const eventStore = useEvent()
    const { selectedDate } = storeToRefs(eventStore)

    const changeDate = () => {
      selectedDate.value = props.day
    }

    const weekday = computed(() => moment(props.day).utc().format('ddd'))
    const date = computed(() => moment(props.day).utc().format('DD'))
    const month = computed(() => moment(props.day).utc().format('MMM'))
    const year = computed(() => moment(props.day).utc().format('YY'))

    const options = { day: 'numeric', month: 'numeric', year: 'numeric' }
    const datesMatch = computed(() => ( props.day.toLocaleString('en-GB', options) === selectedDate?.value?.toLocaleString('en-GB', options)) )

    const isWeekendDay = computed(() => (props.day.getDay() === 0 || props.day.getDay() === 6))

    return {
      datesMatch,
      weekday,
      month,
      year,
      selectedDate,
      changeDate,
      isWeekendDay,
      date
    }
  }
}
</script>

<style scoped>

.calendarLine {
  position: absolute;
  top: -5px;
  height: 10px;
  width: 4px;
  z-index: 2;
  background: #555;
}
.calendarLine.first {
  left: calc(25% - 2px);
}
.calendarLine.second {
  left: calc(50% - 2px);
}
.calendarLine.third {
  left: calc(75% - 2px);
}
.countPill {
  width: 80%;
  margin: 0 auto 5px auto;
  border-radius: 10px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
