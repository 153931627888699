<template>
  <div>
    <div class="p-2 p-md-3 p-lg-4">
      <b-row no-gutters class="pb-3 px-3">
        <div class="d-flex align-items-center">
          <mdb-icon icon="fa fa-video mr-2" size="sm" />
          <h3 class="text-left">
            Share Intro
          </h3>
        </div>
      </b-row>
      <b-row no-gutters class="px-3">
        <div
          v-if="sharingVideoIntro.length === 0 && !useTextInstead"
          class="rounded border-2 border-gray-400 p-4 px-5 d-flex flex-column align-items-center justify-content-center"
          style="border: 2px dotted rgb(192, 192, 192);font-size:0.9rem;font-weight:bold;cursor:pointer;height:90px"
          @click="showAddInsertVideoModal = true"
        >
          <mdb-icon
            v-if="!loading"
            icon="fas fa-file-video"
            size="md"
            class="grey-text"
          />
          <p v-if="!loading" class="grey-text">
            Add Video
          </p>
          <mdb-icon
            v-else
            icon="fa fa-pulse fa-spinner"
            size="lg"
          />
        </div>
      </b-row>

      <b-row
        no-gutters
        class="grey-text mt-2"
        style="transform:translateX(-5px)"
        @click="useTextInstead = !useTextInstead"
      >
        <mdb-input
          v-model="useTextInstead"
          type="checkbox"
          label="Use a written share intro instead?"
          size="sm"
        />
      </b-row>

      <b-row
        v-if="useTextInstead"
        no-gutters
        style="height:90px; width:100%;max-width:600px;"
        class="ml-2"
      >
        <mdb-input
          v-model="sharingTextIntro"
          type="textarea"
          auto-complete="fna"
          :rows="4"
          size="sm"
          maxlength="500"
          aria-placeholder="Enter a brief share introduction"
          style="width:100%;"
        />
      </b-row>

      <b-row
        v-if="sharingVideoIntro.length !== 0 && !useTextInstead"
        no-gutters
        align-v="center"
        style="cursor: pointer;max-width:600px;"
        class="flex-nowrap border-2 border-gray-400 bg-gray-100 m-3 p-1 text-bold text-black rounded text-center"
      >
        <b-col cols="auto" class="flex-grow-0">
          <img
            :src="sharingVideoIntro[0].ThumbnailUrl"
            class="m-auto"
            style="height:70px; border-radius: 2px; object-fit: contain"
          >
        </b-col>
        <b-col
          offset="1"
          cols="auto"
          class="p-1 flex-grow-1"
        >
          <b-row no-gutters class="font-extrabold text-center">
            {{ sharingVideoIntro[0].ShortDescription }}
          </b-row>
          <b-row no-gutters class="flex-nowrap overflow-hidden">
            <i class="fas fa-tags prefix" style="font-size: 1rem; display:inline;transform:translateY(3px);" />
            <div
              v-if="sharingVideoIntro[0].Tags && sharingVideoIntro[0].Tags.length > 15"
              class="ml-2"
              style="font-size: smaller;white-space: nowrap;"
            >
              {{ `${sharingVideoIntro[0].Tags.substring(0, 16)}...`|| 'no tags' }}
            </div>
            <div
              v-else
              class="ml-2"
              style="font-size: smaller;white-space: nowrap;"
            >
              {{ sharingVideoIntro[0].Tags !== null && sharingVideoIntro[0].Tags !== 'null' && sharingVideoIntro[0].Tags !== undefined && sharingVideoIntro[0].Tags !== [] ? sharingVideoIntro[0].Tags : 'no tags' }}
            </div>
          </b-row>
        </b-col>
        <b-col cols="auto" class="flex-grow-0">
          <i
            style="cursor:pointer; transform:scale(1.4)"
            class="grey-text fa fa-times colorOnHover mr-3"
            @click="removeIntroVid()"
          />
        </b-col>
      </b-row>

      <div class="p-3">
        <b-row
          no-gutters
          align-h="between"
          align-v="center"
          style="width:100%;"
          class="mt-4"
        >
          <div class="d-flex align-items-center">
            <mdb-icon icon="fa fa-info-circle mr-2" size="sm" />
            <h3 class="text-left">
              Candidate Information
            </h3>
          </div>
          <h3 class="grey-text">
            {{ selectedCandidateIndex + 1 }} of {{ candidatesAddedToShare.length }} candidates
          </h3>
        </b-row>
        <b-row no-gutters>
          <SlideFade>
            <b-col
              v-if="show"
              cols="12"
              md="auto"
              class="flex-grow-0 mr-0 mr-sm-4 mt-4 mt-lg-0"
              align-self="center"
            >
              <div class="d-flex align-items-center justify-content-center">
                <mdb-icon
                  icon="fas fa-chevron-left fa-2x"
                  class="mr-2"
                  size="lg"
                  style="cursor:pointer;width:20px;"
                  @click.native="decViewingCand()"
                />
                <div style="height:125px;width:125px;border-radius:50%;display:flex;align-items:center;justify-content:center;" :style="{'border': `2px ${userData.lbl.PrimaryColour} solid`}">
                  <img
                    v-if="viewingCand.ProfilePicture != '' && viewingCand.ProfilePicture != null"
                    style="height:110px;width:110px;border-radius:50%;object-fit:cover;"
                    :src="viewingCand.ProfilePicture"
                  >
                  <mdb-icon
                    v-else
                    icon="fa fa-user fa-2x"
                    size="lg"
                  />
                </div>
                <mdb-icon
                  icon="fas fa-chevron-right fa-2x"
                  class="ml-2"
                  size="lg"
                  style="cursor:pointer;width:20px;"
                  @click.native="incViewingCand()"
                />
              </div>
              <div class="font-weight-bold" />
              <div class="mt-2 font-weight-bold" :style="{'color': `${userData.lbl.PrimaryColour}`}">
                {{ viewingCand.FirstName }} {{ viewingCand.LastName }}
              </div>
            </b-col>
          </SlideFade>
          <b-col
            cols="12"
            md="auto"
            style="flex-grow:1;"
          >
            <div class="md-form">
              <mdb-input
                v-model="viewingCand.SalaryExpectation"
                type="text"
                auto-complete="exp"
                label="Salary Expectation"
                size="sm"
              />
            </div>
            <div class="md-form grey-text" style="margin-bottom:3rem;">
              <mdb-input
                v-model="viewingCand.CandidateSummary"
                type="textarea"
                auto-complete="sum"
                :rows="4"
                size="sm"
                maxlength="500"
                label="Summary (for this share)"
                aria-label="Summary (optional)"
                @keyup.native="charCount(viewingCand.CandidateSummary, 500)"
              />
              <div class="grey-text remainingChars" style="color: #757575;">
                {{ chars }}
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row
          class="webDisplay"
          align-v="center"
          :class="{ 'nxtBtn': mobileView }"
          align-h="between"
          no-gutters
          style="margin-top:-20px;position: inherit;bottom: 0;width: 100%;margin-left:-20px;"
        >
          <div
            v-if="candidateInfoIncomplete && shareIntroIncomplete"
            class="text-black ml-3"
            style="font-size:0.7rem;max-width:70%;"
          >
            * Please add a share introduction and complete information for all candidates
          </div>
          <!--<div class="text-black ml-3" style="font-size:0.7rem;max-width:70%;" v-if="candidateInfoIncomplete && !shareIntroIncomplete">* Please complete information for all candidates</div>-->
          <div
            v-if="shareIntroIncomplete && !candidateInfoIncomplete"
            class="text-black ml-3"
            style="font-size:0.7rem;max-width:70%;"
          >
            * Please add a share introduction
          </div>
        </b-row>
      </div>
    </div>
    <InsertVideoModal
      :defaultCategory="'Candidate'"
      @handleChosenVideo="handleChosenVideo"
    />
  </div>
</template>

<script>
import { useCandidate, useUserData, useLibrary } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'

import InsertVideoModal from '@/components/genericModals/InsertVideoModals/insertVideoModal.vue'
import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'

export default {
  name: '',
  components: {
    SlideFade,
    InsertVideoModal
  },
  props: {
    viewingCandIndex: {
      type: Number,
      default: 0
    }
  },
  setup () {
    const candidateStore = useCandidate()
    const { candidates, candidatesAddedToShare, sharingTextIntro, sharingVideoIntro } = storeToRefs(candidateStore)

    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    const libraryStore = useLibrary()
    const { showAddInsertVideoModal } = storeToRefs(libraryStore)
    return {
      candidates,
      candidatesAddedToShare,
      sharingTextIntro,
      sharingVideoIntro,
      authData,
      userData,
      mobileView,
      showAddInsertVideoModal
    }
  },
  data () {
    return {
      chars: '0/500',
      useTextInstead: false,
      loading: false,
      selectedCandidateIndex: 0,
      show: true,
      videoModalPage: 'choose'
    }
  },
  computed: {
    viewingCand () {
      return this.candidatesAddedToShare[this.selectedCandidateIndex]
    },
    informationPageIncomplete () {
      return false
      // try {
      // return this.candidatesAddedToShare.filter(c => c.CandidateSummary === '' || c.CandidateSummary === undefined || c.SalaryExpectation === '').length !== 0 || ((this.sharingVideoIntro.length === 0 && !this.useTextInstead) || (this.sharingTextIntro === '' && this.useTextInstead))
      // } catch {
      //   return true
      // }
    },
    candidateInfoIncomplete () {
      return this.candidatesAddedToShare.filter(c => c.CandidateSummary === '' || c.CandidateSummary === undefined || c.SalaryExpectation === '').length !== 0
    },
    shareIntroIncomplete () {
      return (this.sharingVideoIntro.length === 0 && !this.useTextInstead) || (this.sharingTextIntro === '' && this.useTextInstead)
    }
  },
  watch: {
    shareIntroIncomplete () {
      this.$emit('shareIntroIncomplete', this.shareIntroIncomplete)
    },
    candidateInfoIncomplete () {
      this.$emit('candidateInfoIncomplete', this.candidateInfoIncomplete)
    },
    selectedCandidateIndex () {
      this.forceRerender()
    },
    informationPageIncomplete () {
      this.$emit('infoPageComplete', this.informationPageIncomplete)
    }
  },
  mounted () {
    this.selectedCandidateIndex = this.viewingCandIndex
    this.$emit('infoPageComplete', this.informationPageIncomplete)
    this.$emit('shareIntroIncomplete', this.shareIntroIncomplete)
    this.$emit('candidateInfoIncomplete', this.candidateInfoIncomplete)
  },
  methods: {
    charCount (type, max) {
      this.chars = type.length + '/' + max
    },
    decViewingCand () {
      if (this.selectedCandidateIndex === 0) {
        this.selectedCandidateIndex = this.candidatesAddedToShare.length - 1
      } else { this.selectedCandidateIndex = this.selectedCandidateIndex - 1 }
      this.show = true
    },
    handleChosenVideo (vid) {
      this.sharingVideoIntro = [vid]
      this.showAddInsertVideoModal = false
      this.sharingTextIntro = ''
    },
    incViewingCand () {
      if (this.selectedCandidateIndex === this.candidatesAddedToShare.length - 1) {
        this.selectedCandidateIndex = 0
      } else { this.selectedCandidateIndex = this.selectedCandidateIndex + 1 }
      this.show = true
    },
    forceRerender () {
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    openAddVideoModal () {
      // do stuff
    },
    removeIntroVid () {
      this.sharingVideoIntro = []
    }
  }
}
</script>
