<template>
  <div class="px-4">
    <div
      style="height:80px;"
      class="d-flex align-items-center"
    >
      <h3 class="primary-colour font-weight-bold mr-2">
        Working Patterns
      </h3>
    </div>

    <WorkingPatternTable />
    <button
      class="btn btn-primary btn-md flex ml-auto"
      @click="$router.push('/settings/workingpatterns/add')"
    >
      ADD
    </button>
  </div>
</template>

<script>
import WorkingPatternTable from '@/views/settings/innerComponents/settingsTabs/workingPatterns/table.vue';

export default {
  name:'WorkingPatterns',
  components: {
    WorkingPatternTable
  }
}
</script>
