<template>
  <!--candidate information modal-->
  <mdb-modal
    id="candidateInformationModal"
    :show="candidateInformationModal"
    style="z-index:10000;"
    @close="$emit('candidateInformationModal', false)"
  >
    <mdb-modal-header>
      <mdb-modal-title>CANDIDATE INFORMATION</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body :class="{ 'modalHeightForRoles': mobileView }">
      <div class="my-4 grey-text" style="font-size:0.8rem;text-align:left;margin-top:0px !important;">
        <span v-if="spotlightMasking == 0 && !candidateApplication.Job?.MaskGoCandidateName">
          <span style="font-weight:bold;">Candidate:</span> {{ candidate.FirstName }} {{ candidate.LastName }}<br>
        </span>
        <span style="font-weight:bold;">Role:</span> {{ candidateApplication.JobTitle }}<br>
        <span style="font-weight:bold;">Date Invited:</span> {{ new Date(candidateApplication.DateInvited).toLocaleDateString([userLanguage]) }} at {{ new Date(candidateApplication.DateInvited).toLocaleTimeString([userLanguage]).substring(0, 5) }}<br>
        <span style="font-weight:bold;">Invited By:</span> {{ candidateApplication.InvitedBy }}<br>
        <span v-show="candidateApplication.DateCompleted != null">
          <span style="font-weight:bold;">Date Completed:</span> {{ new Date(candidateApplication.DateCompleted).toLocaleDateString([userLanguage]) }} at {{ new Date(candidateApplication.DateCompleted).toLocaleTimeString([userLanguage]).substring(0, 5) }}<br>
        </span>
        <span style="font-weight:bold;">Invitation Code:</span> {{ candidateApplication.InvitationCode }}<br>
        <span style="font-weight:bold;">Invitation Code Entered Count:</span> {{ candidateApplication.InviteCodeEnteredCount }}<br>
        <span v-show="candidateApplication.ResetDate != null">
          <span style="font-weight:bold;">Reset Date:</span> {{ new Date(candidateApplication.ResetDate).toLocaleDateString([userLanguage]) }} at {{ new Date(candidateApplication.ResetDate).toLocaleTimeString([userLanguage]).substring(0, 5) }}<br>
          <span style="font-weight:bold;">Reset Count:</span> {{ candidateApplication.ResetCount }}<br>
          <span style="font-weight:bold;">Last Reset By:</span> {{ candidateApplication.ResetBy }}<br>
        </span>
        <div v-show="currentVideo.VideoUrl !== null && workflowType !== 7">
          <br><span style="font-weight:bold;">Questions:</span><br><br>
          <div class="mQuestionsScrollContainer" style="max-height:248px;margin-bottom:30px;">
            <div class="grey-text mQuestionsScrollContent">
              <div v-for="q in candidateApplication.Questions" :key="q.QuestionId">
                <span style="font-weight:bold;">Question: </span>{{ q.Ordinal }}<br>{{ q.QuestionText }}<br>
                <span v-if="q.Video && q.Video.Uploaded !== '0001-01-01T00:00:00'">
                  <span style="font-weight:bold;">Uploaded:</span> {{ new Date(q.Video?.Uploaded).toLocaleDateString([userLanguage]) }} at {{ new Date(q.Video?.Uploaded).toLocaleTimeString([userLanguage]).substring(0, 5) }}<br>
                </span>
                <span v-else>
                  <span style="font-weight:bold;">Uploaded:</span> N/A<br>
                </span>
                <span style="font-weight:bold;">Device: </span>{{ q.Video?.UserAgent }}<br><br>
              </div>
            </div>
            <br>
          </div>
        </div>
      </div>
    </mdb-modal-body>
    <mdb-modal-footer style="display:block;">
      <mdb-btn
        color="primary"
        size="sm"
        @click.native="$emit('candidateInformationModal', false)"
      >
        Ok
      </mdb-btn>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import { useMobile } from '@/composables/useMobile'

export default {
  name: 'CandidateInformationModal',
  props: {
    currentVideo: {
      type: Object,
      default: () => {}
    },
    userLanguage: {
      type: String,
      default: 'en-GB'
    },
    candidateInformationModal: Boolean,
    candidateApplication: {
      type: Object,
      default: () => {}
    },
    candidate: {
      type: Object,
      default: () => {}
    },
    workflowType: {
      type: Number,
      default: 0
    },
    spotlightMasking: {
      type: Number,
      default: 0
    }
  },
  setup () {
    const { mobileView } = useMobile()

    return {
      mobileView
    }
  }
}
</script>

<style lang="scss" scoped>
.mQuestionsScrollContainer {
  float:left;
  height: 120px;
  width:100%;
  padding:0px;
}
.mQuestionsScrollContent {
  overflow-y:auto;
  overflow-x:hidden;
  height: 150px !important;
}
@media (max-width: 639px) {
  .mQuestionsScrollContainer {
    float:left;
    height: 290px;
    width:100%;
    padding:0px;
  }
  .mQuestionsScrollContent {
    overflow-y:auto;
    overflow-x:hidden;
    height: 250px !important;
  }
}
</style>
