import axiosInstance from '../instance.js'
const api1 = new axiosInstance(1)

export default class accountService {
  // get account
  static async getGoAccount () {
    return await api1.get('/api/go/getgoaccount')
  }

  // get branding
  static async getBranding () {
    return await api1.get('/api/go/getbranding')
  }

  // delete branding
  static async deleteBranding (brandingId) {
    return await api1.delete('/api/go/deletebranding?brandingId=' + brandingId)
  }

  // add or edit branding
  static async addEditBranding (formData) {
    return await api1.post('/api/go/addeditbranding', formData)
  }

  // set as default
  static async setAsDefault (brandingId) {
    return await api1.get('/api/go/setasdefault?brandingId=' + brandingId)
  }

  // get billing plans
  static async getBillingPlans () {
    return await api1.get('/api/go/getbillingplans')
  }

  // get account billing data
  static async getAccountBillingData () {
    return await api1.get('/api/go/getaccountbillingdata')
  }

  // get billing plan
  static async getBillingPlan () {
    return await api1.get('/api/go/getbillingplan')
  }

  // edit billing details
  static async editBillingDetails (aid, toe, bd) {
    return await api1.post('/api/go/editbillingdetails?aid=' + aid + '&toe=' + toe, bd)
  }

  // get invoices
  static async getInvoices () {
    return await api1.get('/api/go/getinvoices')
  }

  // dowmload invoice
  static async downloadInvoice (iid, aid, typ) {
    if (typ === 'Credit Note') {
      return await api1.get('/api/go/downloadinvoice?iid=' + iid + '&aid=' + aid + '&typ=2')
    } else {
      return await api1.get('/api/go/downloadinvoice?iid=' + iid + '&aid=' + aid + '&typ=1')
    }
  }

  // update seats
  static async updateSeats (subscriptionId, seats, aid, cde, npt) {
    return await api1.post('/api/go/updateseats?subscriptionId=' + subscriptionId + '&seats=' + seats + '&aid=' + aid + '&cde=' + cde + '&npt=' + npt, null)
  }

  // get stocktext
  static async getStockText () {
    return await api1.get('/api/go/getstocktext')
  }

  // save stocktext
  static async saveStockText (stockText) {
    return await api1.post('/api/go/savestocktext', stockText)
  }

  // send test email
  static async sendTestEmail () {
    return await api1.post('/api/go/sendtestemail', null)
  }

  // get api vlaue (application secret)
  static async getApiValue (data) {
    return await api1.post('/api/go/getapivalue', data)
  }
}
