<template>
  <div style="overflow-x: hidden">
    <div
      class="p-2 mb-2 mx-2 hover:bg-gray-100"
      :class="show ? 'bg-gray-100' : ''"
      style="border: solid 2px #e1e1e1;border-radius: 10px;"
    >
      <div class="d-flex align-items-center" style="height:100%">
        <div
          style="border-radius:50%;display:flex;height:100%;"
          class="mr-2 d-none d-sm-flex align-items-center justify-content-between my-2"
        >
          <FontAwesomeIcon
            v-if="roleTemplateId === -1"
            class="upDownChevron cp m-2"
            icon="fa-regular fa-circle-chevron-down"
            :class="{ 'rotatedChevron' : !show }"
            @click="show = !show"
          />

          <!-- profile ProfilePicture -->
          <div
            v-if="ProfilePicture === '' || ProfilePicture === undefined || ProfilePicture.includes('candidate-silhouette')"
            style="border-radius:50%;width:50px;height:50px;border:solid 3px; cursor:pointer;"
            class="d-flex align-items-center justify-content-center"
          >
            <i class="fa fa-user" style="scale:1.5" />
          </div>
          <div v-else style="border-radius:50%; cursor:pointer;width:51px;height:51px;border:solid 3px;">
            <img :src="ProfilePicture" style="object-fit:cover;border-radius:50%;width:100%;height:100%">
          </div>
        </div>

        <div
          class="flex-column mr-auto align-self-start text-left mt-2 my-auto"
          style="font-size:small;white-space: pre-wrap;"
        >
          <!-- name -->
          <div class="text-uppercase font-bold">
            {{ Name }} Scoring
          </div>
          <!-- date
          <div class="font-italic grey-text" style="font-size:small;white-space: pre-wrap;">
            {{ date }}
          </div> -->

          <div
            v-if="IsModerator"
            class="text-uppercase font-bold"
            style="font-size:small;white-space: pre-wrap;"
          >
            <i class="fa fa-star" /> Moderator Score
          </div>
          <div v-if="TemplateName !== '' && roleTemplateId != -1">
            {{ TemplateName }}
          </div>
        </div>

        <div class="d-flex flex-row">
          <!-- Score -->
          <div
            v-if="!((Score == 0 && MaxScore == 0) || !userHasStartedScoring)"
            class="d-flex flex-wrap align-items-center justify-items-end"
          >
            <div class="border rounded-xl p-2 ml-auto mr-2 my-1 d-none d-xl-inline">
              <span> {{ Score }} / {{ MaxScore }} </span>
            </div>
            <div class="border rounded-xl p-2 ml-auto mr-2 my-1">
              <span> {{ percentage }}</span>
            </div>
          </div>
          <button
            type="button"
            class="btn btn-primary btn-sm rounded-lg py-0 m-auto text-nowrap"
            style="min-width:65px;font-size: 0.8em; height:30px;"
            @click="viewButtonFunction()"
          >
            {{ show ? 'HIDE' : 'VIEW' }}
          </button>
        </div>
      </div>
    </div>

    <transition-group
      tag="ul"
      name="slide-in"
      :style="{ '--total': QuestionOverview.length }"
    >
      <li
        v-for="(question, index) in QuestionOverview"
        v-show="show"
        :key="index"
        :style="{'--i': index } "
      >
        <ScoringChild
          :question="question.Description"
          :question-id="question.QuestionId"
          :number="returnOrdinal(question.QuestionId)"
          :max-score="question.MaxScore"
          :score="question.Score"
          @select="$emit('questionSelect', { number: $event, questionId: question.QuestionId, index, UserId })"
        />
      </li>
    </transition-group>
  </div>
</template>

<script>
import ScoringChild from '@/components/misc/ReviewModalV2/reviewModalComponents/scoring/ScoringItemChild'

export default {
  components: {
    ScoringChild
  },
  props: {
    Name: {
      type: String,
      default: ''
    },
    IsModerator: {
      type: Boolean,
      default: false
    },
    ProfilePicture: {
      type: String,
      default: ''
    },
    Score: {
      type: Number,
      default: 0
    },
    MaxScore: {
      type: Number,
      default: 0
    },
    QuestionOverview: {
      type: Array,
      default: () => []
    },
    UserId: {
      type: String,
      default: ''
    },
    selected: {
      type: Number,
      default: 0
    },
    roleTemplateId: {
      type: Number,
      default: 0
    },
    questions: {
      default: () => [],
      type: Array
    },
    userHasStartedScoring: Boolean,
    TemplateId: {
      type: Number,
      default: 0
    },
    TemplateName: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      show: false
    }
  },

  computed: {
    // this is used to give the correct ordinals accounting for when randomisation and question sets are used
    questionIndexesWithIds () {
      const idsAndIndexes = []
      this.questions.map((q, i) => {
        const questionId = q.QuestionId
        idsAndIndexes.push({ value: questionId, ordinal: i + 1 })
      })
      return idsAndIndexes
    },
    percentage () {
      if (this.Score === 0) {
        return `${0}%`
      } else {
        const p = (this.Score / this.MaxScore) * 100
        return `${p.toFixed(0)}%`
      }
    },

    isSelected () {
      return this.scoringItemId === this.selected
    }
  },

  created () {
    if (this.isSelected) {
      this.show = true
    }
  },

  methods: {
    returnOrdinal (questionId) {
      const ordinal = this.questionIndexesWithIds.filter(q => q.value === questionId)[0].ordinal
      return ordinal
    },

    viewButtonFunction () {
      if (this.roleTemplateId === -1) {
        this.show = !this.show
      } else {
        this.$emit('viewRoleTemplate', { userId: this.UserId, templateId: this.TemplateId })
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.slide-in {
  &-enter-active {
    transition: opacity .5s linear, transform .5s cubic-bezier(.2,.5,.1,1);
    transition-delay: calc( 0.1s * var(--i) );
  }

  &-enter {
    opacity: 0;
  }

  &-enter { transform: translateX(30px); }
}
</style>
