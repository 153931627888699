import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useStatus, } from './useStatus'

import roleV2Service from '@/api/services/roleV2.service'

export const useRoles = defineStore('rolesStore', () => {

  const statusStore = useStatus()
  const allRoles = ref([])
  const allRolesFetched = ref(false)
  const recentRoles = ref([])
  const viewingOnlyFollowedRoles = ref(false)
  const loadingRecentRoles = ref(false)

  const filters =  ref([
    {
      id: 0,
      name: 'Open',
      value: true,
      tooltip: 'Your active roles'
    },
    {
      id: 1,
      name: 'Closed',
      value: false,
      tooltip: 'Your closed (expired) roles'
    },
    {
      id: 2,
      name: 'Archived',
      value: false,
      tooltip: 'Your archived (deleted) roles'
    },
    {
      id: 3,
      name: 'Incomplete',
      value: false,
      tooltip: 'Your incomplete roles'
    }
  ])

  const getRecentRoles = async () => {
    recentRoles.value = []
    loadingRecentRoles.value = true
    try {
      const res = await roleV2Service.getRecentRoles()
      recentRoles.value = res.data
      loadingRecentRoles.value = false
      return true
    } catch (err) {
      console.log(Object.assign({}, err))
      statusStore.setError({ status: true, message: 'Something went wrong fetching your recent roles' })
      loadingRecentRoles.value = false
      return false
    }
  }

  const getRoles = async (filterGroup) => {
    statusStore.setLoading({ isLoading: true, message: 'Getting all roles' })
    if (allRoles.value.length == 0) { // first call
      allRolesFetched.value = false
    }
    try {
      const res = await roleV2Service.getRoles(filterGroup)
      if (res.data.length < filterGroup.Take) { // means we have all the data
        allRolesFetched.value = true
      }
      allRoles.value = [...allRoles.value, ...res.data]
      return true
    } catch (err) {
      console.log(Object.assign({}, err))
      statusStore.setError({ status: true, message: 'Something went wrong fetching your roles' })
      return false
    }
  }

  const getRoleAndReplace = async roleId => {
    statusStore.setLoading({ isLoading: true, message: 'Getting role' })
    try {
      const res = await roleV2Service.getRoleWithStages(roleId)
      // replace in allRoles
      const existingAllRolesIndex = allRoles.value.findIndex(r => r.JobId === res.data.JobId)
      if (existingAllRolesIndex != -1) {
        allRoles.value.splice(existingAllRolesIndex, 1, res.data)
      }
      // replace in recent roles
      const existingRecentRoleIndex = recentRoles.value.findIndex(r => r.JobId === res.data.JobId)
      if (existingRecentRoleIndex != -1) {
        recentRoles.value.splice(existingRecentRoleIndex, 1, res.data)
      }
      return true
    } catch (err) {
      statusStore.setError({ status: true })
      return false
    }
  }

  const deleteRoleFromStore = roleId => {
    recentRoles.value = recentRoles.value.filter(r => r.JobId != roleId)
    allRoles.value = allRoles.value.filter(r => r.JobId != roleId)
  }

  // ROLE CARD DATA AND METHODS
  const sharingModalInitialData = ref({ roleId: 0, shareType: '' })
  const sharingModalShowing = ref(false)
  const addInviteCandidateModalData = ref({ JobDetails: { JobTitle: '' } })
  const addingInvitingCandidateModalShowing = ref(false)

  const deleteRole = async (roleId, permanentDelete) => {
    try {
      let JSONData = {
        JobId: roleId,
        IsDeleted: true,
        RemoveThis: permanentDelete
      }
      const res = await roleV2Service.deleteRole(JSONData)
      if (res.data) {
        deleteRoleFromStore(roleId)
        return true
      } else { throw 'Something went wrong.' }
    } catch (err) {
      statusStore.setError({ status: true })
      return false
    }
  }

  const cloneRole = async (roleId, type, jobTitle, externalReferenceId, endDate, isEvergreen) => {
    try {
      const res = await roleV2Service.cloneRole(roleId, type, jobTitle, externalReferenceId, endDate, isEvergreen)
      if (res.data) {
        await this.getRecentRoles()
        return true
      } else { throw 'Something went wrong.' }
    } catch (err) {
      statusStore.setError({ status: true })
      return false
    }
  }

  const sendShineGoReminderEmailsManualTrigger = async (roleId) => {
    try {
      const res = await roleV2Service.sendShineGoReminderEmailsManualTrigger(roleId)
      if (res.data) {
        return true
      } else { throw 'Something went wrong.' }
    } catch (err) {
      statusStore.setError({ status: true })
      return false
    }
  }

  const roleCardApplicantSearch = ref([])
  const loadingRoleCardApplicantSearch = ref(0)
  const searchingInRoleWithId = ref(0)
  const searchApplicantWithinRole = async (jobId, search) => {
    loadingRoleCardApplicantSearch.value = jobId
    roleCardApplicantSearch.value = []
    try {
      const res = await roleV2Service.searchApplicantWithinRole(jobId, search)
      roleCardApplicantSearch.value = res.data
      loadingRoleCardApplicantSearch.value = 0
      return true
    } catch (err) {
      statusStore.setError({ status: true })
      loadingRoleCardApplicantSearch.value = 0
      return false
    }
  }


  ////
  /// ROLE SHARE MODAL
  ////
  const roleSharingModalShowing = ref(false)
  const roleSharingModalInitialData = ref({})
  const sharingWithPage = ref({ emailSubject: '', emailContent: '', standardShareWith: [], blindShareWith: [] })
  const redirectAfterSave = ref(false)
  const sharingVideoIntro = ref([])
  const sharingTextIntro = ref('')

  return {
    allRoles,
    recentRoles,
    getRecentRoles,
    getRoles,
    loadingRecentRoles,
    viewingOnlyFollowedRoles,
    sharingModalInitialData,
    sharingModalShowing,
    addInviteCandidateModalData,
    addingInvitingCandidateModalShowing,
    deleteRoleFromStore,
    getRoleAndReplace,
    filters,
    deleteRole,
    cloneRole,
    sendShineGoReminderEmailsManualTrigger,
    searchApplicantWithinRole,
    roleCardApplicantSearch,
    loadingRoleCardApplicantSearch,
    allRolesFetched,
    roleSharingModalInitialData,
    roleSharingModalShowing,
    sharingWithPage,
    redirectAfterSave,
    sharingVideoIntro,
    sharingTextIntro,
    searchingInRoleWithId
  }
})
