<template>
  <div style="height:99%;">
    <SlideFade>
      <ScaleList v-if="$route.path == '/settings/scoring/scales/all'" />
      <AddScale v-if="$route.path == '/settings/scoring/scales/new'" />
    </SlideFade>
  </div>
</template>

<script>
import ScaleList from './scaleList.vue'
import AddScale from './addScoringScale.vue'
import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'

export default {
  name: 'ScoringScalesContainer',
  components: {
    ScaleList,
    AddScale,
    SlideFade
  }
}
</script>
