<template>
  <div class="min-h-full pt-2 pt-md-4 px-3 px-md-5 w-1/1 text-black rounded" style="height:calc(100vh - 250px);overflow-y:auto;margin-bottom:65px;overflow-x:hidden">
    <div ref="scrollToDiv" style="height: 1px;">
      <div v-show="detLoaded === false" :class="{ 'marginTopSections': mobileView === false }">
        <h3 class="primary-colour text-left font-weight-bold" :class="{ 'mobileTopSections': mobileView }">
          Loading
          <i
            style="margin-left: 5px !important;"
            class="fa fa-spin fa-spinner ml-3"
            :class="{ 'mobileTopSectionSpinner': mobileView }"
          />
        </h3>
      </div>
      <div>
        <div v-show="detLoaded === true" class="grey-text text-left mb-4 mx-0">
          <p
            style="margin-top:20px;font-size:14px;"
            :class="{ 'extraMarginTop': mobileView }"
            class="grey-text"
          >
            Account details not available at the moment. Please try again later.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMobile } from '@/composables/useMobile'

export default {
  name: '',
  setup () {
    const { mobileView } = useMobile()

    return {
      mobileView
    }
  },
  data () {
    return {
      detLoaded: false
    }
  },
  created () {
    setTimeout(() => { this.detLoaded = true }, 3000)
  },
}
</script>

<style lang="scss" scoped>
.extraMarginTop {
  margin-top:35px !important;
}
</style>
