import axiosInstance from '../instance.js'
const api2 = new axiosInstance(2)

export default class eventService {

  // EVENT SETUP
  static async getEventById(id) {
    return await api2.get(`/api/event_stage_setup/get/${id}`)
  }

  static async editEvent(event) {
    return await api2.put('/api/event_stage_setup/edit', event)
  }

  static async addEventLocation(location) {
    return await api2.post('/api/event_stage_setup/location/add', location)
  }

  static async getEventLocations() {
    return await api2.get('/api/event_stage_setup/location/get_user_locations')
  }

  static async deleteEventLocation(id) {
    return await api2.delete(`/api/event_stage_setup/location/delete/${id}`)
  }

  // used to restrict certain settings
  static async checkEventHasStarted(stageId) {
    return await api2.get(`/api/event_invite/check_event_stage_has_started?roleWorkflowStageId=${stageId}`)
  }

  // Invite / Booking (/admin)
  static async inviteCandidateToScheduler(payload) {
    return await api2.post('/api/scheduler_invite/invite', payload)
  }

  static async cancelBooking(id) {
    return await api2.post('/api/scheduler_invite/cancel_booking?id=' + id)
  }

  // ?
  // static async rescheduleBooking (id) {
  //   return await api2.post('/api/GoEventsInvite/reschedule?id=' + id)
  // }




  // SCHEDULER SETUP
  static async getSchedulerSetup(id) {
    return await api2.get(`/api/scheduler_setup/get/${id}`)
  }

  static async editSchedulerSetup(payload) {
    return await api2.put('/api/scheduler_setup/edit', payload)
  }



  // Timeslots (/timeslot)
  static async addEventTimeslot(slot) {
    return await api2.post('/api/scheduler_setup/timeslot/add', slot)

  }
  static async deleteTimeSlot(timeslotIds) {
    return await api2.post('/api/scheduler_setup/timeslot/delete', timeslotIds)
  }

  static async getSchedulerTimeslotCount(roleWorkflowStageId) {
    return await api2.get(`/api/scheduler_setup/timeslot/get/${roleWorkflowStageId}`)
  }

  static async getSchedulerTimeslots(roleWorkflowStageId, date) {
    return await api2.get(`/api/scheduler_setup/timeslot/get/date?roleWorkflowStageId=${roleWorkflowStageId}&date=${date}`)
  }

  // Working Pattern (/working_pattern)
  static async addEventWorkingPattern(payload) {
    return await api2.post('/api/scheduler_setup/working_pattern/add', payload)
  }

  static async getEventWorkingPatterns() {
    return await api2.get('/api/scheduler_setup/working_pattern/get')
  }
}
