export default (stageType) => {
  let fontAwesomeIconCode = ''
  switch (stageType) {
    // ONE WAY
    case 1:
      fontAwesomeIconCode = 'fa-solid fa-mobile-screen-button'
      break
    // TWO WAY
    case 2:
      fontAwesomeIconCode = 'fa-solid fa-users'
      break
    // SELF SCHEDULE
    case 3:
      fontAwesomeIconCode = 'fa-regular fa-calendar'
      break
    // SCREENING
    case 4:
      fontAwesomeIconCode = 'fa-regular fa-calendar'
      break
    // ASSESSMENT CENTER
    case 5:
      fontAwesomeIconCode = 'fa-regular fa-calendar'
      break
    default:
      break
  }
  return fontAwesomeIconCode
}
