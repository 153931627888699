<template>
  <div style="height:100%">
    <div class="scoring-settings-title-container d-flex align-items-center justify-content-between">
      <div class="font-weight-bold text-left" style="font-size:clamp(0.8rem, 2vw, 1rem)">
        A Scoring Template is a pre-defined set of Elements or Competences.
        <Tooltip
          style="display: inline"
          tooltip-direction="left"
          :bullet-point-array="explanationOfTerms"
        />
      </div>
      <mdb-btn
        size="sm"
        style="width:fit-content;"
        class="m-0 ml-2 text-nowrap"
        :disabled="!scalesHaveBeenAdded"
        @click="addNew()"
      >
        <FontAwesomeIcon
          icon="fa-regular fa-circle-plus"
          class="d-block d-lg-none"
          style="scale: 1.4"
        />
        <span class="d-none d-lg-block">ADD A TEMPLATE</span>
      </mdb-btn>
    </div>
    <div class="scoring-settings-content-container">
      <div v-if="loading" class="mt-3">
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </div>
      <div v-else-if="!loading && !scalesHaveBeenAdded" class="mt-3 font-weight-bold">
        BEGIN BY ADDING A POINTS SCALE ON THE TOP RIGHT HAND TAB
      </div>
      <div v-else-if="!loading && templates.length == 0 && scalesHaveBeenAdded" class="mt-3 font-weight-bold">
        NO RESULTS
      </div>
      <div v-else style="width:100%;">
        <div
          v-for="(template, i) in templates"
          :key="i"
          class="d-flex align-items-center px-2 px-md-4 py-2 py-md-3 m-2 scaleListItem"
        >
          <FontAwesomeIcon
            icon="fa-solid fa-chart-simple-horizontal"
            size="2x"
            class="d-none d-md-block mr-4"
          />
          <FontAwesomeIcon
            v-if="template.IsDefault"
            icon="fa-regular fa-check-double"
            title="This is your default template"
            class="d-none d-md-block mr-4"
            style="scale:1.5"
          />
          <span class="font-weight-bold text-left" :class="{'default-template-bold': template.IsDefault }">{{ template.TemplateName.toUpperCase() }}</span>
          <mdb-btn
            v-if="template.IsDefault === false"
            size="sm"
            style="width:50px"
            class="px-1 m-0 mr-3 ml-auto btn btn-outline"
            title="Make Default"
            @click="makeTemplateDefault(template.TemplateId)"
          >
            <FontAwesomeIcon icon="fa-check-double fa-regular" style="scale:1.5" />
          </mdb-btn>
          <mdb-btn
            size="sm"
            style="width:50px"
            class="px-1 m-0"
            :class="{'ml-auto': template.IsDefault}"
            :disabled="!scalesHaveBeenAdded"
            @click="viewTemplate(template)"
          >
            <FontAwesomeIcon
              icon="fa-solid fa-eye"
              class="d-inline d-lg-none"
              style="scale: 1.4"
            />
            <span class="d-none d-lg-block text-nowrap">VIEW</span>
          </mdb-btn>
          <mdb-btn
            size="sm"
            style="width:50px"
            class="px-1 m-0 ml-3 btn btn-outline"
            @click="deleteTemplate(template)"
          >
            <FontAwesomeIcon icon="fa-trash-can-xmark fa-solid" style="scale:1.5" />
          </mdb-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import scoringService from '@/api/services/scoring.service.js'
import Tooltip from '@/components/misc/tooltip.vue'

export default {
  name: 'TemplateList',
  components: {
    Tooltip
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()

    return {
      authData,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal
    }
  },
  data () {
    return {
      loading: false,
      templates: [],
      testScoringModalShowing: false,
      scalesHaveBeenAdded: true
    }
  },
  created () {
    this.getAllTemplates()
    this.checkScalesHaveBeenAdded()
  },
  computed: {
    explanationOfTerms () {
      return [
        'Elements are used to give a concise score in a particular domain, using a single Point Scale.',
        'Competences in contrast are useful to give more granularity to your scoring, by grouping Elements together. For example, you could group "Body Language" and "Eye Contact" Elements, in a Competence titled "Confidence".',
        'Both Elements and Competences are built using Point Scales. These are generic building blocks, giving either numerical or text based scores, and can be used throughout your templates.'
      ]
    }
  },
  methods: {
    addNew () {
      this.$router.push({ path: '/settings/scoring/templates/add_edit', query: { tempId: '0' } }).catch(() => {})
    },
    viewTemplate (template) {
      this.$router.push({ path: '/settings/scoring/templates/add_edit', query: { tempId: template.TemplateId } }).catch(() => {})
    },
    async getAllTemplates () {
      this.loading = true
      try {
        const res = await scoringService.getAllTemplates()
        if (res.data != null) {
          this.templates = res.data
        } else {
          this.showGenericErrorModal({ showing: true })
        }
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    async checkScalesHaveBeenAdded () {
      try {
        const res = await scoringService.checkScalesHaveBeenAdded()
        this.scalesHaveBeenAdded = res.data
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
    },
    async deleteTemplate (template) {
      if (template.IsDefault === true) {
        this.showGenericErrorModal({ showing: true, errorMessage: 'You cannot delete your default template', errorTitle: 'Cannot Delete' })
      } else {
        this.showGenericConfirmModal({
          showing: true,
          confirmTitle: 'DELETE',
          confirmMessage: 'Are you sure you want to delete this template? <br /> <br /> Please note that if points have been given using this template, that template will not be affected and deleting it, will only remove it from this list.',
          confirmButtonMessage: 'SUBMIT',
          confirmButtonFunction: () => this.confirmDeleteTemplate(template.TemplateId)
        })
      }
    },
    async confirmDeleteTemplate (templateId) {
      try {
        await scoringService.deleteTemplate(templateId)
        this.getAllTemplates()
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.closeGenericConfirmModal()
    },
    async makeTemplateDefault (templateId) {
      try {
        await scoringService.makeTemplateDefault(templateId)
        this.getAllTemplates()
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.closeGenericConfirmModal()
    }
  }
}
</script>

<style lang="scss" scoped>
.scaleListItem {
  border: #c0c0c0 solid 1px;
  border-radius: 5px;
}
.default-template-bold {
  border: var(--pr-color) 4px solid;
  border-radius: 5px;
  padding: 2px 6px;
}
</style>
