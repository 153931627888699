<template>
  <div
    class="col-12"
    :class="{ 'threeNinetyWidth': mobileView }"
  >
    <div class="container-right-half">
      <div style="margin-left:0px; margin-right:0px;width:100%;padding:0px;margin-top: 20px;margin-bottom: 30px;">
        <div class="text-left primary-colour">
          <validation-observer v-slot="{ invalid:invalid }">
            <form>
              <div class="h5" style="margin-top: 10px;padding-bottom: 10px;text-align: center;font-weight: bold;">
                <span v-show="nmError">{{ $parent.$parent.error }}</span>
                <span v-show="!nmError">Forgotten your password</span>
              </div>
              <div class="md-form">
                <div style="margin-top:25px;">
                  <validation-provider
                    mode="lazy"
                    name="Email"
                    rules="required"
                  >
                    <i class="fas fa-user prefix" style="left:0;font-size:1rem;color:gray;margin-top:10px;" />
                    <mdb-input
                      v-model="userName"
                      type="email"
                      label="Email for password reset"
                      style="margin-left:33px;"
                      size="sm"
                    />
                  </validation-provider>
                </div>
              </div>
              <div style="width:100%;float:left;font-size:small;text-align: left;margin-top: 5px;margin-left:30px;margin-bottom: 0px;text-decoration: underline;cursor: pointer;z-index: 1000;" @click="toggleAgree()">
                <mdb-input
                  v-model="agree"
                  type="checkbox"
                  style="margin-left:-20px;width:40px;"
                  size="sm"
                />
                <div style="float:left;font-size:small;text-align: left;margin-top: -28px;margin-left:30px;cursor: pointer;z-index: 1000;margin-bottom: 0px;">
                  I have read the  
                  <a
                    class="primary-colour"
                    style="text-decoration: underline;"
                    @click="privacyLink()"
                  >privacy</a> policy.
                </div>
              </div>
              <!-- reset button-->
              <div style="position: fixed; bottom: 50px; float:right;right:20px;">
                <div class="grey-text text-right" style="margin-top: -20px !important;">
                  <span style="font-size:x-small;">Already know your login details?<a class="ml-1 primary-colour" @click="signIn">Click here!</a></span><br>
                </div>
                <div v-if="lockout === null" style="margin-top:10px;">
                  <mdb-btn
                    v-show="!buttonClick"
                    :disabled="invalid || agree === false"
                    class="float-right"
                    style="width:110px;bottom: 0px !important;right: -5px !important;"
                    size="sm"
                    :class="{ 'mobButtonHeight': mobileView }"
                    @click="doForgottenPassword"
                  >
                    RESET
                  </mdb-btn>
                  <mdb-btn
                    v-show="buttonClick"
                    class="float-right"
                    style="width:110px;bottom: 0px !important;right: -5px !important;"
                    size="sm"
                    :class="{ 'mobButtonHeight': mobileView }"
                  >
                    <mdb-icon icon="fa fa-pulse fa-spinner" />
                  </mdb-btn>
                </div>
                <div v-else style="margin-top:20px;">
                  <mdb-btn
                    disabled
                    class="float-right"
                    style="width:110px;bottom: 0px !important;right: -5px !important;"
                    size="sm"
                    :class="{ 'mobButtonHeight': mobileView }"
                  >
                    RESET
                  </mdb-btn>
                </div>
              </div>
            </form>
          </validation-observer>
        </div>
      </div>
    </div>
    <mdb-modal :show="errorModal" @close="errorModal = false">
      <mdb-modal-header>
        <mdb-modal-title>ERROR</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          <span v-html="error" />
        </p>
      </mdb-modal-body>
    </mdb-modal>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import signUpService from '@/api/services/signup.service'

import { mdbBtn } from 'mdbvue'

export default {
  name: 'ForgottenPassword',
  components: {
    mdbBtn
  },
  setup () {
    const userStore = useUserData()
    const { authData, lockout } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    return {
      authData,
      lockout,
      mobileView
    }
  },
  data () {
    return {
      userName: '',
      error: '',
      msg: '',
      buttonClick: false,
      errorModal: false,
      successModal: false,
      login: true,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      resetSuccess: false,
      nmError: false,
      counter: 0,
      agree:false
    }
  },
  created () {
    this.windowHeight = window.innerHeight
    var now = new Date()
    var minutes = now.getMinutes()
    if (this.lockout != null) {
      if (this.lockout < minutes) {
        this.lock = null
        this.counter = 0
      }
    }
  },
  methods: {
    privacyLink () {
      window.open('https://www.shineinterview.com/privacy-policy/', '_blank')     
    },
    toggleAgree () {
      if (this.agree === false) { this.agree = true } else { this.agree = false }
    },
    doForgottenPassword: async function () {
      this.buttonClick = true
      signUpService.forgottenPassword(this.userName).then((res) => {
        this.userName = null
        this.resetSuccess = true
        this.buttonClick = false
        if (this.mobileView === false) { this.$parent.$parent.errorModal = true } else { this.nmError = true }
        this.$parent.$parent.errorTitle = 'Forgotten your password'
        this.$parent.$parent.error = res.data
        this.agree = false
      }).catch((error) => {
        this.agree = false
        this.counter++
        this.userName = null
        this.buttonClick = false
        if (this.counter > 2) {
          if (this.mobileView === false) { this.$parent.$parent.errorModal = true } else { this.nmError = true }
          this.$parent.$parent.errorTitle = 'Error'
          this.$parent.$parent.error = 'Sorry, too many forgotten password attempts have been made, please try again in 5 minutes (contact us if you believe this is an error).'
          var twoMinutesLater = new Date()
          this.lockout = twoMinutesLater.getMinutes() + 5
        } else {
          this.errorAlert(error, 'Sorry an error has occurred!')
        }
      })
    },
    errorAlert (error, msg) {
      try {
        if (error.response.data != null) {
          if (error.response.data.Message != null) {
            if (this.mobileView === false) { this.$parent.$parent.errorModal = true } else { this.nmError = true }
            this.$parent.$parent.error = error.response.data.Message
            if (this.$parent.$parent.error === '') { this.error = 'Sorry, an error has occured!' }
          } else if (error.response.data.error_description != null) {
            if (this.mobileView === false) { this.$parent.$parent.errorModal = true } else { this.nmError = true }
            this.error = error.response.data.error_description
            if (this.$parent.$parent.error === '') { this.$parent.$parent.error = 'Sorry, an error has occured!' }
          } else {
            if (this.mobileView === false) { this.$parent.$parent.errorModal = true } else { this.nmError = true }
            this.$parent.$parent.error = error.response.data
            if (this.$parent.$parent.error === '') { this.$parent.$parent.error = 'Sorry, an error has occured!' }
          }
        } else {
          if (this.mobileView === false) { this.$parent.$parent.errorModal = true } else { this.nmError = true }
          this.$parent.$parent.error = msg
        }
      } catch {
        if (this.mobileView === false) { this.$parent.$parent.errorModal = true } else { this.nmError = true }
        this.$parent.$parent.error = 'Sorry an error has occurred!'
      }
    },
    register () {
      this.$router.push('/signup').catch(() => { })
    },
    signIn () {
      this.$emit('setPassword')
    }
  }
}
</script>

<style>
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }
  .close {
    color:#c0c0c0 !important;
  }
</style>
