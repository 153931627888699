<template>
  <div style="height:100%;">
    <div class="scoring-settings-title-container d-flex align-items-center justify-content-between">
      <div class="font-weight-bold text-left" style="font-size:clamp(0.8rem, 2vw, 1rem)">
        A Point Scale defines how points are given - for example from 1 to 5
      </div>
      <mdb-btn
        size="sm"
        style="width:fit-content;"
        class="m-0 ml-2 text-nowrap"
        @click="addNew()"
      >
        <FontAwesomeIcon
          icon="fa-regular fa-circle-plus"
          class="d-block d-lg-none"
          style="scale: 1.4"
        />
        <span class="d-none d-lg-block">ADD A SCALE</span>
      </mdb-btn>
    </div>
    <div class="scoring-settings-content-container" style="overflow-y: auto;">
      <div v-if="loading" class="mt-3">
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </div>
      <div v-else-if="!loading && scales.length == 0" class="mt-3 font-weight-bold">
        NO RESULTS
      </div>
      <div v-else style="width:100%;">
        <div
          v-for="(scale, i) in scales"
          :key="i"
          class="d-flex align-items-center px-2 px-md-4 py-2 py-md-3 m-2 scaleListItem"
        >
          <FontAwesomeIcon
            icon="fa-solid fa-chart-simple-horizontal"
            size="2x"
            class="d-none d-md-block mr-4"
          />
          <span class="font-weight-bold text-left">{{ scale.ScaleName.toUpperCase() }}</span>
          <mdb-btn
            size="sm"
            style="width:110px"
            class="px-1 m-0 ml-auto"
            @click="viewScale(scale)"
          >
            <FontAwesomeIcon
              icon="fa-solid fa-eye"
              class="d-inline d-lg-none"
              style="scale: 1.4"
            />
            <span class="d-none d-lg-block text-nowrap">VIEW</span>
          </mdb-btn>
          <mdb-btn
            size="sm"
            style="width:50px"
            class="px-1 m-0 ml-3 btn btn-outline"
            @click="deleteScale(scale.GoScoringScaleId)"
          >
            <FontAwesomeIcon icon="fa-trash-can-xmark fa-solid" style="scale:1.5" />
          </mdb-btn>
        </div>
      </div>
    </div>
    <TestScoringModal
      :test-scoring-modal-showing="testScoringModalShowing"
      :lowest-scale-value="selectedScale.LowestScaleValue"
      :number-of-steps="selectedScale.NumberOfSteps"
      :text-array="selectedScale.TextArray"
      :step-increment="selectedScale.StepIncrement"
      :make-text-based="selectedScale.IsTextBased"
      :modal-title="selectedScale.ScaleName"
      @testScoringModalShowing="testScoringModalShowing = $event"
    />
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import scoringService from '@/api/services/scoring.service.js'

import TestScoringModal from './testScoringScaleModal.vue'

export default {
  name: 'ScaleList',
  components: {
    TestScoringModal
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()

    return {
      authData,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal
    }
  },
  data () {
    return {
      loading: false,
      scales: [],
      selectedScale: { LowestScaleValue: 0, NumberOfSteps: 0, TextArray: [], StepIncrement: 0, IsTextBased: false },
      testScoringModalShowing: false
    }
  },
  created () {
    this.getAllScales()
  },
  methods: {
    addNew () {
      this.$router.push('/settings/scoring/scales/new').catch(() => {})
    },
    viewScale (scale) {
      this.selectedScale = scale
      this.testScoringModalShowing = true
    },
    async getAllScales () {
      this.loading = true
      try {
        const res = await scoringService.getAllScales()
        this.scales = res.data
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    async deleteScale (scaleId) {
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'DELETE',
        confirmMessage: 'Are you sure you want to delete this scale?',
        confirmButtonMessage: 'SUBMIT',
        confirmButtonFunction: () => this.confirmDeleteScale(scaleId)
      })
    },
    async confirmDeleteScale (scaleId) {
      try {
        await scoringService.deleteScale(scaleId)
        this.getAllScales()
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.closeGenericConfirmModal()
    }
  }
}
</script>

<style lang="scss" scoped>
.scaleListItem {
  border: #c0c0c0 solid 1px;
  border-radius: 5px;
}
</style>
