<template>
  <div class="leftColumnBoilerPlate d-none d-md-block">
    <div style="width:100%;height:100%;" class="d-flex flex-col">
      <div class="font-bold text-left text-gray-600 leftColHeader">
        Help Topics
      </div>
      <div class="leftColMainContent p-3">
        <!-- Help Topics -->
        <transition
          :key="'helpTopics'"
          name="fade"
          mode="out-in"
          class="leftColFullWidth mb-5"
        >
          <div>
            <div
              v-for="category in helpArticleCategories"
              :key="category.value"
              :class="{'selected' : category.value === currentFilter}"
              class="leftColumnThinTile mt-2 px-3 cp"
              style="position:relative;"
              @click="setHelpArticleFilter(category.value)"
            >
              <div class="text-gray-600" style="margin-right:auto">
                <i class="fa fa-tv p-1" /> {{ category.text }}
              </div>
              <div class="d-flex align-items-center justify-content-center left-column-count">
                <div class="font-weight-bold" style="color:#fff; letter-spacing:1px;">
                  {{ category.count }}
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="d-flex flex-wrap align-items-center justify-center leftColBottomButtonPanel" :class="{ 'notBarActive': userData.aut && userData.inf > 0 }">
        <a
          class="btn-outline my-2 my-md-1 mx-2 support-button"
          :href="'https://shineinterview.zendesk.com/hc/en-us'"
          target="_blank"
        ><i class="fa fa-plus-circle" /> SUPPORT</a>
        <mdb-btn
          v-if="userData.rle === &quot;Super User&quot;"
          size="sm"
          class="btn btn-solid-outline-web my-2 my-md-1 mx-2"
          style="min-width:110px;width:fit-content;padding: 0.5rem 1.1rem;"
          @click="$emit('toggleModal')"
        >
          <i class="fa fa-plus-circle" /> ADD
        </mdb-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: '',
  props: {
    showUploadModal: Boolean,
    helpArticleSummary: {
      type: Object,
      default: () => {}
    },
    currentFilter: {
      type: Number,
      default: 0
    }
  },
  setup () {
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)

    return {
      userData
    }
  },
  computed: {
    helpArticleCategories () {
      return [
        { text: 'All', value: 0, count: this.helpArticleSummary.allCount },
        { text: 'Getting Started', value: 1, count: this.helpArticleSummary.gettingStartedCount },
        { text: 'Did You Know?', value: 2, count: this.helpArticleSummary.didYouKnowCount },
        { text: 'User Guides', value: 3, count: this.helpArticleSummary.userGuidesCount },
        { text: 'Candidates', value: 4, count: this.helpArticleSummary.candidatesCount },
        { text: 'Roles', value: 5, count: this.helpArticleSummary.rolesCount },
        { text: 'Technical', value: 6, count: this.helpArticleSummary.technicalCount }
      ]
    }
  },
  methods: {
    setHelpArticleFilter (value) {
      this.$emit('updateHelpArticleFilter', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.notBarActive {
    margin-bottom: 46px !important;
    background: #d1d1d1;
}
</style>
