<template>
  <div
    class="mobileBackgroundImg"
    :style="'background: url(/bg.png);background-repeat: no-repeat;background-size: cover;'"
    style="width: 100vw;height: 100vh;position: absolute;top: 0px;"
  >
    <div
      class="d-flex justify-content-center flex-col m-0 mobileFormDiv"
      style="min-height: 100dvh; height: fit-content; overflow-y: auto;position: absolute;right:5%;width:40%;"
    >
      <div
        class="primary-colour"
        style="font-size:0.8rem;border:solid 1px;border-radius: 10px;background: #fff"
      >
        <div class="align-items-center justify-content-center text-center" style="width:100%;margin-top:15px;">
          <img
            :src="'/logo.png'"
            style="width:200px;display:block;margin:auto;margin-top: -50px;margin-bottom: -40px;"
          >
          <div
            v-if="!registered"
            style="font-size:1.2rem;color:grey;margin-top: 0px;margin-bottom: 0px;"
            class="mobileTitleSize"
          >
            CREATE YOUR ACCOUNT
          </div>
          <div
            v-else
            style="font-size:1.2rem;font-weight:bold;color:grey;margin-top: 0px;margin-bottom: 0px;"
            class="mobileTitleSize"
          >
            ACCOUNT CREATED
          </div>
          <div>
            <form
              v-if="!registered"
              autocomplete="off"
            >
              <div style="text-align: left;font-size: 0.8rem;padding:20px;width: 100%;min-height: 335px;">
                <!--name-->
                <div style="width:100%;float:left;margin-top:-10px;">
                  <div style="float:left;margin-top:10px;width:20px;">
                    <i class="fa fa-shield-alt sm" style="font-size:1rem;color:gray;" />
                  </div>
                  <div style="float:left;margin-top:-25px;width:calc(100% - 20px);">
                    <mdb-input
                      v-model="newCompany.Name"
                      type="text"
                      placeholder="Enter your company name"
                      class="mobileInputStyling positionRelative"
                      style="margin-left:15px;margin-right:10px;background:#f1f1f1;padding-left: 5px;"
                      size="sm"
                      @input="validateThis(0)"
                    />
                  </div>
                  <div class="positionRelative marginRight0" style="float:right;margin-top:-53px;font-size:1rem !important;margin-right:15px;">
                    <i
                      v-show="newCompanyName === 1"
                      class="fas fa-check-circle"
                      style="color:green;"
                    />
                    <i
                      v-show="newCompanyName === 2"
                      class="fas fa-times-circle"
                      style="color:red;"
                    />
                  </div>
                </div>
                <!--forename-->
                <div style="width:100%;float:left;margin-top:-10px;">
                  <div style="float:left;margin-top:10px;width:20px;">
                    <i class="far fa-user sm" style="font-size:1rem;color:gray;" />
                  </div>
                  <div style="float:left;margin-top:-25px;width:calc(100% - 20px);">
                    <mdb-input
                      v-model="newCompany.Forename"
                      type="text"
                      placeholder="Your forename"
                      class="mobileInputStyling positionRelative"
                      style="margin-left:15px;margin-right:10px;background:#f1f1f1;padding-left: 5px;"
                      size="sm"
                      @input="validateThis(1)"
                    />
                  </div>
                  <div class="positionRelative marginRight0" style="float:right;margin-top:-53px;font-size:1rem !important;margin-right:15px;">
                    <i
                      v-show="newCompanyForename === 1"
                      class="fas fa-check-circle"
                      style="color:green;"
                    />
                    <i
                      v-show="newCompanyForename === 2"
                      class="fas fa-times-circle"
                      style="color:red;"
                    />
                  </div>
                </div>
                <!--surname-->
                <div style="width:100%;float:left;margin-top:-10px;">
                  <div style="float:left;margin-top:10px;width:20px;">
                    <i class="far fa-user sm" style="font-size:1rem;color:gray;" />
                  </div>
                  <div style="float:left;margin-top:-25px;width:calc(100% - 20px);">
                    <mdb-input
                      v-model="newCompany.Surname"
                      type="text"
                      placeholder="Your last name"
                      class="mobileInputStyling positionRelative"
                      style="margin-left:15px;margin-right:10px;background:#f1f1f1;padding-left: 5px;"
                      size="sm"
                      @input="validateThis(2)"
                    />
                  </div>
                  <div class="positionRelative marginRight0" style="float:right;margin-top:-53px;font-size:1rem !important;margin-right:15px;">
                    <i
                      v-show="newCompanySurname === 1"
                      class="fas fa-check-circle"
                      style="color:green;"
                    />
                    <i
                      v-show="newCompanySurname === 2"
                      class="fas fa-times-circle"
                      style="color:red;"
                    />
                  </div>
                </div>
                <!--email-->
                <div style="width:100%;float:left;margin-top:-10px;">
                  <div style="float:left;margin-top:10px;width:20px;">
                    <i class="far fa-envelope sm" style="font-size:1rem;color:gray;" />
                  </div>
                  <div style="float:left;margin-top:-25px;width:calc(100% - 20px);">
                    <mdb-input
                      v-model="newCompany.Email"
                      type="email"
                      placeholder="Business email address"
                      class="mobileInputStyling positionRelative"
                      style="margin-left:15px;margin-right:10px;background:#f1f1f1;padding-left: 5px;"
                      size="sm"
                      autocomplete="new-email"
                      @input="validateThis(3)"
                    />
                  </div>
                  <div class="positionRelative marginRight0" style="float:right;margin-top:-53px;font-size:1rem !important;margin-right:15px;">
                    <i
                      v-show="newCompanyEmail === 1"
                      class="fas fa-check-circle"
                      style="color:green;"
                    />
                    <i
                      v-show="newCompanyEmail === 2"
                      class="fas fa-times-circle"
                      style="color:red;"
                    />
                  </div>
                </div>
                <!--password-->
                <div style="width:100%;float:left;margin-top:-10px;">
                  <div style="float:left;margin-top:11px;width:20px;">
                    <i class="fa fa-lock sm" style="font-size:1rem;color:gray;" />
                  </div>
                  <div style="float:left;margin-top:-25px;width:calc(100% - 20px);">
                    <mdb-input
                      v-model="newCompany.Password"
                      title="Your password must be 12-64 characters long, contain letters and numbers. You may use special characters, but your password must NOT contain spaces or emojis. Please use either a combination of upper case and lower case letters or a complex password combining three random words e.g. applenemobiro."
                      :type="visibility"
                      placeholder="Password (min. 12 chars)"
                      class="mobileInputStyling positionRelative"
                      style="margin-left:15px;margin-right:10px;background:#f1f1f1;padding-left: 5px;"
                      size="sm"
                      autocomplete="new-password"
                      @input="validateThis(4)"
                    />
                    <span
                      v-if="newCompanyPassword != 1"
                      style="position: relative;right: -246px;top: -51px;width: 275px;cursor: pointer;float: right;"
                    >
                      <mdb-popover
                        trigger="click"
                        :options="{placement: 'left'}"
                        style="cursor:pointer;"
                        class="grey-text"
                      >
                        <span slot="body">Your password must be 12-64 characters long, contain letters and numbers. You may use special characters, but your password must NOT contain spaces or emojis. Please use either a combination of upper case and lower case letters or a complex password combining three random words e.g. applenemobiro.</span>
                        <span slot="reference"><i class="fas fa-info-circle" /></span>
                      </mdb-popover>
                    </span>
                    <a
                      v-if="visibility == 'password'"
                      class="eyeRight"
                      style="position:absolute;right:0;margin-top:-50px;margin-right: 10px;"
                      @click="showPassword()"
                    ><i class="fas fa-eye grey-text" /></a>
                    <a
                      v-if="visibility == 'text'"
                      class="eyeRight"
                      style="position:absolute;right:0;margin-top:-50px;margin-right: 10px;"
                      @click="hidePassword()"
                    ><i class="fas fa-eye-slash grey-text" /></a>
                  </div>
                  <div class="positionRelative marginRight0" style="float:right;margin-top:-53px;font-size:1rem !important;margin-right:15px;">
                    <i
                      v-show="newCompanyPassword === 1"
                      class="fas fa-check-circle"
                      style="color:green;"
                    />
                  </div>
                </div>  
                <div style="width:100%;float:left;font-size:small;text-align: left;margin-top: 0px;margin-left:35px;text-decoration: underline;cursor: pointer;z-index: 1000;" @click="toggleAgree()">
                  <mdb-input
                    v-model="agree"
                    type="checkbox"
                    style="margin-left:-20px;width:40px;"
                    size="sm"
                  />
                  <div style="float:left;font-size:small;text-align: left;margin-top: -28px;margin-left:30px;cursor: pointer;z-index: 1000;">
                    I have read the
                    <a
                      class="primary-colour"
                      style="text-decoration: underline;"
                      @click="privacyLink()"
                    >privacy</a> policy.
                  </div>
                </div>

                <div class="mobButtonMargin" style="float: right;margin-bottom: 20px;margin-top:0px;margin-right:5px;">
                  <!--register button-->
                  <div>
                    <mdb-btn
                      v-show="!buttonClick"
                      :disabled="invalid || !agree"
                      class="btn btn-md rounded-full w-28"
                      style="color:white !important;width:145px;"
                      size="sm"
                      @click="finish()"
                    >
                      CREATE ACCOUNT
                    </mdb-btn>
                    <mdb-btn
                      v-show="buttonClick"
                      class="btn btn-md rounded-full w-28"
                      style="color:white !important;width:145px;"
                      size="sm"
                    >
                      <mdb-icon icon="fa fa-pulse fa-spinner" />
                    </mdb-btn>
                  </div>
                </div>              
              </div>
            </form>
            <div
              v-else
              class="finishMobHeight"
              style="text-align: center;font-size: 0.8rem;padding:20px;width: 100%;margin-top: 0px;min-height:351px;color:grey;"
            >
              Thank you for registering your account with Shine.
              <br><br>
              We have sent you a confirmation email, and will be in touch shortly to arrange an onboarding session, but in the meantime why not click on the login button below to get started?
              <br>
              <!--auto login button-->
              <mdb-btn
                v-show="!loginClick"
                class="btn btn-md rounded-full w-28"
                style="margin-top:20px;color:white !important;float:inherit !important;"
                size="sm"
                @click="autoLogin()"
              >
                LOGIN
              </mdb-btn>
              <mdb-btn
                v-show="loginClick"
                class="btn btn-md rounded-full w-28"
                style="margin-top:20px;color:white !important;float:inherit !important;"
                size="sm"
              >
                <mdb-icon icon="fa fa-pulse fa-spinner" />
              </mdb-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import signUpService from '@/api/services/signup.service'

export default {
  name: 'SignUp',
  setup () {

    const { mobileView } = useMobile()
    const userStore = useUserData()
    const { userData, authData } = storeToRefs(userStore)

    return {
      userData,
      authData,
      mobileView
    }
  },
  data () {
    return {
      buttonClick: false,
      loginClick: false,
      agree: false,
      newCompany: {
        Name: '',
        Forename: '',
        Surname: '',
        Email: '',
        Password: '',
        Oset: 0,
        Tzone: ''
      },
      newCompanyName: 0,
      newCompanyForename: 0,
      newCompanySurname: 0,
      newCompanyEmail: 0,
      newCompanyPassword: 0,
      invalid: true,
      visibility: 'password',
      registered: false
    }
  },
  created () {
    if (this.userData.aut) {
      this.$router.push('/')
    }
  },
  methods: {
    privacyLink () {
      window.open('https://www.shineinterview.com/privacy-policy/', '_blank')      
    },
    toggleAgree () {
      if (this.agree === false) { this.agree = true } else { this.agree = false }
    },
    hidePassword() {
      this.visibility = 'password'
    },
    showPassword() {
      this.visibility = 'text'
    },
    validateThis (field) {
      //check name
      if (field === 0) {
        this.validateCompanyName()
      }
      //check forename
      if (field === 1) {
        this.validateCompanyName()
        this.validateCompanyForename()
      }
      //check surname
      if (field === 2) {
        this.validateCompanySurname()
        this.reValidateThis(1)
      }
      //check email
      if (field === 3) {
        this.validateCompanyEmail()
        this.reValidateThis(2)
      }
      //check password
      if (field === 4) {
        this.validateCompanyPassword()
        this.reValidateThis(3)
      }
    },
    reValidateThis (field) {
      if (field === 1) {
        this.validateCompanyName()
        this.validateCompanyForename()
        this.validateCompanySurname()
      }
      if (field === 2) {
        this.validateCompanyName()
        this.validateCompanyForename()
        this.validateCompanySurname()
        this.validateCompanyEmail()
      }
      if (field === 3) {
        this.validateCompanyName()
        this.validateCompanyForename()
        this.validateCompanySurname()
        this.validateCompanyEmail()
        this.validateCompanyPassword()
      }
      this.formIsValid()
    },
    validateCompanyName () {
      if (this.newCompany.Name != '') {
        this.newCompanyName = 1
      } else {
        this.newCompanyName = 2
      }
    },
    validateCompanyForename () {
      if (this.newCompany.Forename != '') {
        this.newCompanyForename = 1
      } else {
        this.newCompanyForename = 2
      }
    },
    validateCompanySurname () {
      if (this.newCompany.Surname != '') {
        this.newCompanySurname = 1
      } else {
        this.newCompanySurname = 2
      }
    },
    validateCompanyEmail () {
      if (
        this.newCompany.Email.toLocaleLowerCase().includes('yahoo') ||
        this.newCompany.Email.toLocaleLowerCase().includes('gmail') ||
        this.newCompany.Email.toLocaleLowerCase().includes('hotmail') ||
        this.newCompany.Email.toLocaleLowerCase().includes('outlook') ||
        this.newCompany.Email.toLocaleLowerCase().includes('mailinator')
      ) {
        this.newCompanyEmail = 2
      } else {
        this.newCompanyEmail = 1
      }
    },
    validateCompanyPassword () {
      if (this.newCompany.Password != '' && this.newCompany.Password.length > 11) {
        this.newCompanyPassword = 1
      } else {
        this.newCompanyPassword = 2
      }
      if (this.newCompany.Password.includes('password') || 
            this.newCompany.Password.includes('qwerty') || 
              this.newCompany.Password.includes('123456789') || 
                this.newCompany.Password.includes('123') || 
                  this.newCompany.Password.includes('111') || 
                    this.newCompany.Password.includes(this.newCompany.Name) ||
                      this.newCompany.Password.includes(this.newCompany.Forename) ||
                      this.newCompany.Password.includes(this.newCompany.Surname) ||
                        this.newCompany.Password.includes(this.newCompany.Email)
      ) {
        this.newCompanyPassword = 2
      }  
    },
    formIsValid () {
      if (this.newCompanyName === 1
      && this.newCompanyForename === 1
      && this.newCompanySurname === 1
      && this.newCompanyEmail === 1
      && this.newCompanyPassword === 1) {
        this.invalid = false
      }
    },
    finish() {
      this.buttonClick = true
      var dt = new Date(Date.now())
      this.newCompany.Oset = dt.getTimezoneOffset()
      this.newCompany.Tzone = Intl.DateTimeFormat().resolvedOptions().timeZone
      signUpService.registerNewAccount(this.newCompany).then((res) => {
        this.newCompany = res.data
        this.registered = true
      }).catch((error) => {
        this.errorAlert(error, 'Sorry, your details could not be created as your email, or the company name you are wanting to register already exists. Please contact us if you feel this is an error.')
        this.newCompany = {
          Name: '',
          Forename: '',
          Surname: '',
          Email: '',
          Password: '',
          Oset: 0,
          Tzone: ''
        }
        this.newCompanyName = 0
        this.newCompanyForename = 0
        this.newCompanySurname = 0
        this.newCompanyEmail = 0
        this.newCompanyPassword = 0
        this.invalid = true
        this.visibility = 'password'
        this.registered = false
        this.buttonClick = false
        this.agree = false
      })
    },
    autoLogin: async function () {
      this.nmError = false
      this.loginClick = true
      signUpService.login(this.newCompany.Email, this.newCompany.Password).then(async (res) => {
        this.newCompany = {
          Name: '',
          Forename: '',
          Surname: '',
          Email: '',
          Password: '',
          Oset: 0,
          Tzone: ''
        }
        this.newCompanyName = 0
        this.newCompanyForename = 0
        this.newCompanySurname = 0
        this.newCompanyEmail = 0
        this.newCompanyPassword = 0
        this.invalid = true
        this.visibility = 'password'
        this.registered = true
        this.agree = false
        this.authData = res.data
        this.leftColumnActive = false
        await this.getUserDetails(res.data.access_token)
      }).catch((error) => {
        this.loginClick = false
        this.errorAlert(error, 'Sorry it would appear we are having issues logging you in. Please contact us if you believe this is an error.')
      })
    },
    async getUserDetails (token) {
      signUpService.getUserDetails(token).then(async (res) => {
        this.userData = res.data
        this.leftColumnActive = false
        this.$router.push('/candidates')
      }).catch((error) => {
        this.loginClick = false
        this.errorAlert(error, 'Sorry it would appear we are having issues logging you in. Please contact us if you believe this is an error.')
      })
    },
    errorAlert (error, msg) {
      try {
        if (error.response.data != null) {
          if (error.response.data.Message != null) {
            this.$parent.$parent.errorModal = true
            this.$parent.$parent.error = error.response.data.Message
          } else if (error.response.data.error_description != null) {
            this.$parent.$parent.errorModal = true
            this.$parent.$parent.error = error.response.data.error_description
          } else {
            this.$parent.$parent.errorModal = true
            this.$parent.$parent.error = error.response.data
          }
        } else {
          this.$parent.$parent.errorModal = true
          this.$parent.$parent.error = msg
        }
      } catch {
        this.$parent.$parent.errorModal = true
        this.$parent.$parent.error = 'Sorry an error has occurred!'
      }
    }
  }
}
</script>

<style scoped>
  .positionRelative {
    position: relative;
  }
  @media (max-width: 576px) {
  .mobileFormDiv {
    padding: 20px !important;
    width: 100% !important;
    right: 0% !important;
    background: #ff7e00 !important;
  }
  .mobileBackgroundImg {
    height: 100vh;
  }
  .mobileTitleSize {
    font-size: 1rem !important;
  }
  .eyeRight {
    margin-top: -47px !important;
    margin-right: 30px !important;
  }
  .finishMobHeight{
    min-height: 352 px !important
  }
}
@media screen and (min-width:320px) and (max-width:960px) {
  .mobileFormDiv {
    padding: 20px !important;
    width: 100% !important;
    right: 0% !important;
    background: #ff7e00 !important;
  }
  .mobileBackgroundImg {
    height: 100vh;
  }
  .mobileTitleSize {
    font-size: 1rem !important;
  }
  .eyeRight {
    margin-top: -50px !important;
    margin-right: 30px !important;
  }
  .finishMobHeight{
    min-height: 352 px !important
  }
}
</style>
