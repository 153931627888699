<template>
  <div class="mt-3 mt-md-4">
    <!-- <div v-if="spotlights.length > 0"> -->
    <b-table
      id="spotlights-table"
      class="text-left text-center"
      style="width: 100%; max-width: 100%;"
      head-variant="dark"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :items="spotlights"
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      :striped="true"
      hover
      small
      sort-icon-left
    >
      <template #cell(name)="data">
        <div class="d-flex align-items-center">
          <img
            style="height:50px;max-height:50px;object-fit:cover;"
            class="mr-2"
            :src="data.item.Logo"
            :style="data.item.Logo.includes('shine_logo_white') || data.item.Logo.includes('shine-logo-white') ? 'background: #FF7e00; padding: 5px' : ''"
          >
          <div class="ml-2">
            {{ data.item.Name }}
          </div>
        </div>
      </template>
      <template #cell(CandidateSource)="data">
        <div style="height:50px;max-height:50px;" class="d-flex align-items-center justify-content-center">
          {{ convertCandidateSource(data.item.CandidateSource) }}
        </div>
      </template>
      <template #cell(DateAdded)="data">
        <div style="height:50px;max-height:50px;" class="d-flex align-items-center justify-content-center">
          {{ new Date(data.item.DateAdded).toLocaleDateString(['en-GB']) }}
        </div>
      </template>
      <template #cell(actions)="data">
        <div class="d-flex align-items-center justify-content-end pt-0" style="height:50px;max-height:50px;">
          <mdb-btn
            size="sm"
            class="btn my-2 my-md-1 mx-2"
            style="width:fit-content;padding: 0.5rem 1.1rem;"
            @click="viewSpotlight(data.item.GoClientPortalSpotlightId)"
          >
            VIEW
          </mdb-btn>
          <mdb-popover
            ref="popover"
            trigger="click"
            :options="{placement: 'left'}"
            style="cursor:pointer;"
            class="grey-text"
          >
            <div slot="body" class="text-nowrap">
              <h6
                class="d-flex"
                style="align-items:center"
                @click="editSpotlight(data.item.GoClientPortalSpotlightId)"
              >
                <a><i class="fa fa-edit" style="margin-right:7px;" /> Edit</a>
              </h6>
              <h6
                class="d-flex"
                style="align-items:center"
                @click="showConfirmDeleteSpotlightModal(data.item.GoClientPortalSpotlightId)"
              >
                <a><i class="fa fa-trash" style="margin-right:7px;" /> Delete</a>
              </h6>
              <CopyToClipboardPopoverButton
                :string-to-copy="CLIENT_PORTAL_URL + '/#/signin?clientId=' + $route.query.clId"
                button-text="Copy Spotlight link?"
                @closePopover="$refs.popover.doClose()"
              />
            </div>
            <mdb-icon
              slot="reference"
              style="color:#555;font-size:1rem;"
              class="ellipsisButtonHover mr-2"
              icon="ellipsis-v"
            />
          </mdb-popover>
        </div>
      </template>
    </b-table>
    <div v-if="spotlights.length === 0 && spotlightTotalLength === 0 && !spotlightsLoading" class="my-4 primary-colour font-weight-bold">
      Add a spotlight to begin sharing candidates
    </div>
    <div class="d-flex align-items-center mb-5">
      <div v-if="spotlights.length > perPage" class="mr-4">
        <b-pagination
          v-show="spotlights.length > perPage"
          v-model="currentPage"
          class="ml-auto"
          :total-rows="rows"
          :per-page="perPage"
          first-number
          last-number
          aria-controls="spotlights-table"
          prev-text="<"
          next-text=">"
        />
      </div>
      <div class="d-flex flex-wrap align-items-center justify-content-between" :style="{'width': spotlights.length >= rows ? '100%' : '' }">
        <mdb-btn
          size="sm"
          class="btn btn-outline m-0 my-2 my-md-1 mr-auto"
          style="min-width:110px;width:fit-content;padding: 0.5rem 1.1rem;"
          @click="$router.back(-1)"
        >
          BACK
        </mdb-btn>
        <mdb-btn
          size="sm"
          class="btn m-0 my-2 my-md-1 ml-4"
          style="min-width:110px;width:fit-content;padding: 0.5rem 1.1rem;"
          @click="$emit('showAddEditModal', 0)"
        >
          <i class="fa fa-plus-circle" /> ADD
        </mdb-btn>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import clientPortalService from '@/api/services/clientPortal.service'
import { CLIENT_PORTAL_URL } from '@/common/config.js'

import CopyToClipboardPopoverButton from '@/components/misc/copyToClipboardPopoverButton.vue'

export default {
  name: 'SpotlightListTable',
  components: {
    CopyToClipboardPopoverButton
  },
  props: {
    spotlights: {
      type: Array,
      default: () => []
    },
    spotlightTotalLength: {
      type: Number,
      default: 0
    },
    filter: {
      type: String,
      default: ''
    },
    spotlightsLoading: Boolean
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()

    return {
      authData,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal
    }
  },
  data () {
    return {
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      perPage: 8,
      windowWidth: window.innerWidth
    }
  },
  computed: {
    CLIENT_PORTAL_URL () { return CLIENT_PORTAL_URL },
    fields () {
      if (this.windowWidth > 578) {
        return [
          { key: 'Name', label: 'Spotlight', sortable: true },
          { key: 'CandidateSource', label: 'Candidate Source' },
          { key: 'DateAdded', label: 'Date Added' },
          { key: 'Actions', label: '' }
        ]
      } else {
        return [
          { key: 'Name', label: 'Spotlight', sortable: true },
          { key: 'CandidateSource', label: 'Type' },
          { key: 'Actions', label: '' }
        ]
      }
    },
    rows () {
      try {
        return this.spotlights.length
      } catch { return 0 }
    }
  },
  watch: {
    filter () {
      this.currentPage = 1
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    showConfirmDeleteSpotlightModal (spotlightId) {
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'DELETE SPOTLIGHT',
        confirmMessage: 'Are you sure you want to delete this Spotlight?',
        confirmButtonMessage: 'DELETE',
        confirmButtonFunction: () => this.deleteSpotlight(spotlightId)
      })
    },
    async deleteSpotlight (spotlightId) {
      try {
        const res = await clientPortalService.deleteSpotlight(spotlightId)
        if (res.data === false) {
          this.showGenericErrorModal({ showing: true})
          return
        }
        this.$emit('getAllSpotlights')
        this.$emit('getRecentSpotlights')
      } catch (err) {
        this.showGenericErrorModal({ showing: true})
      }
      this.closeGenericConfirmModal()
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },
    editSpotlight (spotlightId = 0) {
      this.$emit('showAddEditModal', spotlightId)
    },
    viewSpotlight (spotlightId) {
      this.$router.push({ path: '/client_portal/spotlights', query: { slId: spotlightId } })
    },
    convertCandidateSource (source) {
      if (source === 'OneWay') { return 'One Way' }
      if (source === 'TwoWay') { return 'Two Way' }
      if (source === 'VideoCV') { return 'Video CV' }
      if (source === 'MultipleRoles') { return 'Multiple Roles' }
    }
  }
}
</script>

<style lang="scss">
.pagination button[aria-checked=true] {
  background: #2F9E32 !important;
}
</style>
