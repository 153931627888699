<template>
  <div style="width:100%; height:100%;">
    <div style="height:fit-content">
      <div>
        <div class="font-weight-bold text-left mt-0 mb-3 mt-md-3 d-flex align-items-center justify-content-between" style="height:50px">
          <div class="d-flex align-items-center">
            <span v-if="page.helpLoaded === false">
              <h3 class="primary-colour text-left font-weight-bold">Loading
                <i style="margin-left: 5px !important;" class="fa fa-spin fa-spinner ml-3" />
              </h3>
            </span>
            <span v-else>
              Help - {{ page.helpTitle }}
            </span>
          </div>
          <ExpandableSearchBar
            :width="200"
            :max-widthpercentage="50"
            @search="currentSearch = $event"
          />

          <div class="ml-3 d-flex">
            <div
              style="cursor:pointer;border-radius:50%;height:40px;width:40px;"
              class="listViewSelected align-items-center justify-content-center mr-1 d-none d-md-flex"
              :style="{'background' : page.listView === false ? `${userData.lbl.PrimaryColour}66` : 'none'}"
            >
              <FontAwesomeIcon
                icon="fa-solid fa-grid"
                style="height:25px;width:25px;"
                @click="page.listView = false"
              />
            </div>
            <div
              style="cursor:pointer;border-radius:50%;height:30px;width:30px;"
              class="listViewSelected align-items-center justify-content-center mr-1 d-flex d-md-none"
              :style="{'background' : page.listView === false ? `${userData.lbl.PrimaryColour}66` : 'none'}"
            >
              <FontAwesomeIcon
                icon="fa-solid fa-grid"
                style="height:17px;width:17px;"
                @click="page.listView = false"
              />
            </div>
            <div
              style="cursor:pointer;border-radius:50%;height:40px;width:40px;"
              class="listViewSelected align-items-center justify-content-center mr-1 d-none d-md-flex"
              :style="{'background' : page.listView === true ? `${userData.lbl.PrimaryColour}66` : 'none'}"
            >
              <FontAwesomeIcon
                icon="fa-solid fa-list-ul"
                style="height:25px;width:25px;"
                @click="page.listView = true"
              />
            </div>
            <div
              style="cursor:pointer;border-radius:50%;height:30px;width:30px;"
              class="listViewSelected align-items-center justify-content-center mr-1 d-flex d-md-none"
              :style="{'background' : page.listView === true ? `${userData.lbl.PrimaryColour}66` : 'none'}"
            >
              <FontAwesomeIcon
                icon="fa-solid fa-list-ul"
                style="height:17px;width:17px;"
                @click="page.listView = true"
              />
            </div>
          </div>
        </div>
        <b-row
          v-show="page.helpLoaded && page.visibleHelpTopics.length === 0"
          no-gutters
          class="mt-3"
        >
          <div
            class="grey-text small text-left"
            style="font-weight:bold;"
            :class="{ 'noVidsLeft': mobileView }"
          >
            No help topics available.
          </div>
        </b-row>
      </div>

      <!-- Help Card Section -->
      <div v-show="page.visibleHelpTopics.length > 0" class="mt-3">
        <div
          :class="{ 'helpArticleGrid': page.listView === false, 'helpArticleList': page.listView === true }"
          :style="{'margin-bottom' : mobileView ? '80px !important' : 'none'}"
          style="min-height:fit-content;"
          class="mb-5"
        >
          <div
            v-for="helpTopic in page.visibleHelpTopics"
            :key="helpTopic.GuidReference"
            class="mb-4 shadow1 justify-items-center helpArticle"
          >
            <HelpCard
              v-if="page.listView === false"
              :user-token="authData.access_token"
              :help-topic="helpTopic"
              @toggleVideoModal="toggleVideoModal($event)"
              @togglePdfModal="togglePdfModal($event)"
              @toggleDeleteModal="toggleDeleteModal($event)"
            />
            <HelpList
              v-if="page.listView === true"
              :user-token="authData.access_token"
              :help-topic="helpTopic"
              @toggleVideoModal="toggleVideoModal($event)"
              @togglePdfModal="togglePdfModal($event)"
              @toggleDeleteModal="toggleDeleteModal($event)"
            />
          </div>
        </div>
      </div>

      <!-- MOBILE Buttons -->
      <div
        v-show="mobileView"
        class="fixed bottom-0"
        style="background:white;width:100%;border-top: 1px solid #a1a1a1;z-index:1049;margin-left:-25px;height:55px;"
      >
        <div class="row" style="margin-top:5px;">
          <div class="col-sm-12 text-center">
            <a
              class="btn-outline my-2 my-md-1 mx-2 support-button"
              :href="'https://shineinterview.zendesk.com/hc/en-us'"
              target="_blank"
            ><i class="fa fa-plus-circle" /> SUPPORT</a>
            <mdb-btn
              v-if="userData.rle === &quot;Super User&quot;"
              class="btn btn-solid-outline-web"
              size="sm"
              style="width:110px;padding: 0.5rem 1.1rem;"
              @click="$emit('toggleModal')"
            >
              ADD
            </mdb-btn>
          </div>
        </div>
      </div>

      <!--modal for help topic upload-->
      <mdb-modal
        id="help-article-upload-modal"
        :show="showUploadModal"
        @close="$emit('toggleModal')"
      >
        <mdb-modal-header>
          <mdb-modal-title id="help-modal-header">
            ADD HELP ARTICLE
          </mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body :class="{ 'modalHeight': mobileView }">
          <div>
            <p id="help-modal-sub-header">
              Add a new help article to the help library
            </p>
          </div>
          <validation-observer>
            <form ref="form" @submit.prevent="fileUpload()">
              <div class="md-form form-sm text-left form-style">
                <i class="fas fa-file prefix float-left icon-style" />
                <mdb-input
                  v-model="article.articleName"
                  type="text"
                  auto-complete="fna"
                  placeholder="Article Name"
                  style="margin-left:33px;"
                  size="sm"
                />
              </div>
              <div class="md-form form-sm text-left form-style">
                <i class="fas fa-object-group prefix icon-style" />
                <mdb-select
                  v-model="helpCategories"
                  placeholder="Category"
                  style="margin-left:33px;"
                  size="sm"
                />
              </div>
              <div id="upload-document-title" class="md-form form-sm text-left form-style">
                <i class="fas fa-upload prefix icon-style" />
                <div id="upload-text">
                  Choose your file to upload
                </div>
              </div>
              <MDBFileUpload
                :files="file"
                height="200"
                accept="video/mp4, video/webm, application/pdf"
                :text="{ able: { single: 'Drag and Drop a File Here or Click to Select From Your Device' }}"
                @change="handleChange"
              />
              <div class="video-tags md-form form-sm text-left form-style">
                <i class="fas fa-tags prefix icon-style" />
                <videotaginput
                  :video-tags="article.articleTags"
                  @addTag="addTag"
                  @removeTag="removeTag"
                />
              </div>
              <div class="md-form form-sm text-left form-style email-notification">
                <mdb-switch
                  id="notify-user-toggle"
                  v-model="article.notifyUsers"
                  off-label="Notify users of this new content by email"
                  on-label=""
                  class="m-0"
                />
              </div>
            </form>
          </validation-observer>
        </mdb-modal-body>
        <mdb-modal-footer v-show="!article.completedUpload" style="display:block">
          <mdb-btn
            v-show="article.uploadStarted === false"
            type="reset"
            class="float-left btn-outline"
            size="sm"
            style="width:100px;"
            @click="$emit('toggleModal')"
          >
            CANCEL
          </mdb-btn>
          <mdb-btn
            v-show="!article.uploadStarted && !article.completedUpload"
            type="submit"
            :disabled="(article.articleName === null || article.articleName === '')"
            class="float-right"
            size="sm"
            style="width:100px;"
            @click="fileUpload()"
          >
            <span>SAVE</span>
          </mdb-btn>
          <mdb-progress
            v-if="article.uploadStarted"
            indeterminate
            style="margin-right:10px;"
          />
          <mdb-btn
            v-show="article.uploadStarted"
            size="sm"
            style="width:100px;"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>

      <!--video playback modal-->
      <mdb-modal
        id="play-modal"
        :show="video.playModal"
        @close="video.playModal = false"
      >
        <mdb-modal-header class="align-items-center">
          <span class="font-weight-bold py-2 text-left">{{ video.videoTitle }} ({{ topic }})</span>
        </mdb-modal-header>
        <mdb-modal-body :class="{ 'modalHeight': mobileView }">
          <div class="mx-auto p-2">
            <div style="background:black;">
              <video
                v-if="video.IsiOS"
                id="help-video"
                style="margin:auto;"
                :src="video.videoSource"
                controls
                playsinline
                class="video-fluid z-depth-1 help-video"
                crossorigin=""
                @click="videoIsTouched()"
              />
              <video
                v-else
                id="help-video"
                style="margin:auto;"
                :src="video.videoSource"
                :poster="video.ThumbnailUrl"
                controls
                playsinline
                class="video-fluid z-depth-1 help-video"
                crossorigin=""
                @click="videoIsTouched()"
              />
            </div>
          </div>
        </mdb-modal-body>
      </mdb-modal>

      <!--PDF View modal-->
      <mdb-modal
        id="pdf-modal"
        :show="pdf.viewPdfModal"
        @close="pdf.viewPdfModal = false"
      >
        <mdb-modal-header class="align-items-center">
          <span class="font-weight-bold py-2 text-left">{{ pdf.pdfTitle }} ({{ topic }})</span>
        </mdb-modal-header>
        <mdb-modal-body :class="{ 'modalHeight': mobileView }" class="pdf-modal-body">
          <div class="mx-auto p-2 pdf-body-content-container">
            <div style="background:black;">
              <iframe
                v-if="pdf.pdfSource !== null"
                ref="iframe"
                :onload="loadedPdf()"
                :src="`https://docs.google.com/viewer?url=${pdf.pdfSource}&embedded=true`"
                width="100%"
                height="100%"
                frameborder="0"
              />
            </div>
          </div>
        </mdb-modal-body>
      </mdb-modal>

      <!--Delete modal-->
      <mdb-modal
        id="delete-modal"
        :show="articleDelete.showDeleteModal"
        @close="toggleDeleteModal()"
      >
        <mdb-modal-header>
          <mdb-modal-title>DELETE?</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body :class="{ 'modalHeight': mobileView }">
          <p
            v-if="articleDelete.helpArticleType === 'Video'"
            class="my-4 grey-text"
            style="font-size:0.8rem;text-align:left;"
          >
            Are you sure you want to delete this video?
          </p>
          <p
            v-if="articleDelete.helpArticleType === 'Document'"
            class="my-4 grey-text"
            style="font-size:0.8rem;text-align:left;"
          >
            Are you sure you want to delete this document?
          </p>
        </mdb-modal-body>
        <mdb-modal-footer>
          <mdb-btn
            v-show="!articleDelete.buttonClick"
            color="primary"
            size="sm"
            style="width:100px;"
            @click.native="deleteArticle()"
          >
            YES
          </mdb-btn>
          <mdb-btn
            v-show="articleDelete.buttonClick"
            class="float-right"
            size="sm"
            style="width:100px;"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>
    </div>
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import helpService from '@/api/services/help.service'

import ExpandableSearchBar from '@/components/UI/expandableSearch.vue'
import HelpCard from '@/views/help/helpCard.vue'
import HelpList from '@/views/help/helpList.vue'
import videotaginput from '@/components/videoTagInput'
import MDBFileUpload from 'mdb-vue-file-upload'
import UploadFile from '@/upload'

export default {
  name: 'Help',
  components: {
    MDBFileUpload,
    ExpandableSearchBar,
    HelpCard,
    HelpList,
    videotaginput
  },
  props: {
    showUploadModal: Boolean,
    helpCategories: {
      type: Array,
      default: () => []
    },
    currentFilter: {
      type: Number,
      default: 0
    }
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()
    const { mobileView } = useMobile()

    return {
      authData,
      userData,
      showGenericErrorModal,
      mobileView
    }
  },
  data () {
    return {
      article: {
        articleName: '',
        articleTags: [],
        UploadFile: false,
        file: '',
        completedUpload: false,
        uploadStarted: false,
        notifyUsers: false
      },
      file: [],
      page: {
        helpTitle: 'All Help Articles',
        helpLoaded: false,
        allHelpTopics: [],
        visibleHelpTopics: [],
        listView: false,
        addHelpArticle: false,
        uploadTopic: false
      },
      video: {
        playModal: false,
        videoTitle: '',
        videoSource: '',
        videoThumbnail: ''
      },
      pdf: {
        viewPdfModal: false,
        pdfTitle: '',
        pdfSource: ''
      },
      articleDelete: {
        helpArticleId: null,
        showDeleteModal: false,
        buttonClick: false,
        helpArticleType: null
      },
      currentSearch: '',
      topic: ''
    }
  },
  watch: {
    currentSearch () {
      this.filterHelpArticles(this.currentSearch)
    },
    file () {
      this.$emit('file', this.file)
    },
    currentFilter () {
      this.filterHelpArticles()
    }
  },
  mounted () {
    this.getHelpArticles()
  },
  created () {
    this.$emit('resetCategories')
  },
  methods: {
    loadedPdf () {
      setTimeout(() => {
        if (this.$refs.iframe !== undefined) {
          try {
            this.willResolveIfFailed = this.$refs.iframe.contentDocument[0]
            this.refreshDOCURL()
          } catch {
            // successfully loaded
          }
        }
      }, 500)
    },
    refreshDOCURL () {
      var URL = this.pdf.pdfSource
      this.pdf.pdfSource = null
      this.$nextTick(() => { this.pdf.pdfSource = URL }, 500)
    },
    getTopic (helpTopic) {
      if (helpTopic.Category === 1) {
        this.topic = 'Getting Started'
      } else if (helpTopic.Category === 2) {
        this.topic = 'Did You Know?'
      } else if (helpTopic.Category === 3) {
        this.topic = 'User Guides'
      } else if (helpTopic.Category === 4) {
        this.topic = 'Candidates'
      } else if (helpTopic.Category === 5) {
        this.topic = 'Roles'
      } else if (helpTopic.Category === 6) {
        this.topic = 'Technical'
      }
    },
    toggleVideoModal (helpTopic) {
      this.getTopic(helpTopic)
      this.video.videoTitle = helpTopic.ArticleName
      this.video.videoThumbnail = helpTopic.ThumbnailLocation
      this.video.videoSource = helpTopic.ContentLocation
      this.video.playModal = true
    },
    togglePdfModal (helpTopic) {
      this.getTopic(helpTopic)
      this.pdf.pdfTitle = helpTopic.ArticleName
      this.pdf.pdfSource = helpTopic.ContentLocation
      this.pdf.viewPdfModal = true
    },
    toggleDeleteModal (helpArticleData) {
      if (this.articleDelete.showDeleteModal) { // Opened
        this.articleDelete.helpArticleId = null
        this.articleDelete.helpArticleType = null
        this.articleDelete.showDeleteModal = false
      } else { // Closed
        this.articleDelete.helpArticleId = helpArticleData.id
        this.articleDelete.helpArticleType = helpArticleData.type
        this.articleDelete.showDeleteModal = true
      }
    },
    async deleteArticle () {
      await helpService.deleteArticle(this.articleDelete.helpArticleId).then(res => {
        if (res) {
          this.toggleDeleteModal()
          this.getHelpArticles()
        } else {
          this.toggleDeleteModal()
        }
      }, err => {
        if (err.response.status === 401) {
          this.showGenericErrorModal({ showing: true, errorTitle: 'Error Deleting Article', errorMessage: 'You are currently logged out. Please try refreshing the page and deleting again.' })
        } else {
          this.showGenericErrorModal({ showing: true, errorTitle: 'Error Deleting Article', errorMessage: 'Something went wrong when trying to delete this help article. Please try refreshing the page and deleting again.' })
        }
      })
      this.$emit('getHelpArticleSummary')
    },
    async getHelpArticles () {
      await helpService.getHelpArticles().then((response) => {
        this.page.allHelpTopics = response.data
        this.filterHelpArticles()
      })
    },
    orderAlphabetically (object1, object2) {
      try {
        const obj1 = object1.VideoTitle.toUpperCase()
        const obj2 = object2.VideoTitle.toUpperCase()

        if (obj1 < obj2) {
          return -1
        }
        if (obj1 > obj2) {
          return 1
        }
        return 0
      } catch { return 0 }
    },
    filterHelpArticles (searchTerm = null) {
      this.page.helpLoaded = false
      var visibleFilter = ''
      if (this.currentFilter !== 0) {
        visibleFilter = this.page.allHelpTopics.filter(i => i.Category === this.currentFilter)
        visibleFilter = searchTerm !== null ? visibleFilter.filter(i => i.ArticleName.toLowerCase().indexOf(this.currentSearch.toLowerCase()) > -1 || i.Tags.includes(this.currentSearch)) : visibleFilter
        this.page.visibleHelpTopics = visibleFilter.sort((vid1, vid2) => { return this.orderAlphabetically(vid1, vid2) })
        if (this.helpCategories.length > 0 && this.currentFilter !== null) {
          this.page.helpTitle = this.helpCategories.filter(i => i.value === this.currentFilter)[0].text
        }
        setTimeout(() => { this.page.helpLoaded = true }, 500)
      } else { // All
        this.page.helpTitle = 'All Help Articles'
        visibleFilter = this.page.allHelpTopics.sort((vid1, vid2) => { return this.orderAlphabetically(vid1, vid2) })
        visibleFilter = searchTerm !== null ? visibleFilter.filter(i => i.ArticleName.toLowerCase().indexOf(this.currentSearch.toLowerCase()) > -1 || i.Tags.includes(this.currentSearch)) : visibleFilter
        this.page.visibleHelpTopics = visibleFilter.sort((vid1, vid2) => { return this.orderAlphabetically(vid1, vid2) })
        setTimeout(() => { this.page.helpLoaded = true }, 500)
      }
    },
    handleChange (f) {
      this.file = f
    },
    async fileUpload () {
      var selectedCategory = this.helpCategories.filter(i => i.selected)
      const isVideo = (this.file[0].name.includes('.mp4') || this.file[0].name.includes('.webm'))
      var videoUploadId = null
      var fileReference = null
      // Upload the video
      if (isVideo) {
        const uploadVideoModel = {
          videoTitle: this.article.articleName,
          videoType: 101,
          category: selectedCategory[0].value,
          videoTags: this.article.articleTags,
          uploader: this.userData.une,
          userEmail: this.userData.une,
          shortDescription: '',
          availableToAll: false,
          allowShare: false,
          selectedVideo: '',
          hasForm: false,
          videoText: ''
        }
        this.article.uploadStarted = true
        videoUploadId = await UploadFile.uploadVideo(101, this.file[0], uploadVideoModel, true)
      } else {
        var fData = new FormData()
        fData.append('file', this.file[0])
        fileReference = await helpService.uploadHelpFileToBlob(fData)
      }
      // Upload to our own table - get the videoId if there is one so we can make a reference.
      const uploadModel = {
        articleName: this.article.articleName,
        category: selectedCategory[0].value,
        tags: this.article.articleTags,
        uploader: this.userData.une,
        videoId: videoUploadId,
        fileExtension: this.file[0].name.substring(this.file[0].name.lastIndexOf('.') + 1, this.file[0].name.length),
        fileReference: fileReference?.data,
        notifyByEmail: this.article.notifyUsers
      }
      await helpService.uploadHelpArticle(uploadModel)
      this.$emit('toggleModal')
      this.$emit('getHelpArticleSummary')
      this.resetForm()
      this.getHelpArticles()
    },
    resetForm () {
      this.article = {
        articleName: '',
        articleTags: [],
        UploadFile: false,
        file: [],
        completedUpload: false,
        uploadStarted: false,
        notifyUsers: false
      }
      this.file = []
      this.$emit('resetCategories')
      this.$refs.form.reset()
    },
    removeTag: function (e) {
      this.article.articleTags.splice(e, 1)
    },
    addTag: function (e) {
      if (!this.article.articleTags.includes(e) && e !== null && e !== '') {
        this.article.articleTags.push(e)
      }
    },
    launchFilePicker () {
      this.$refs.file.click()
    }
  }
}
</script>

<style lang="scss">
$primary-color: #495057;

@mixin grey-sub-header {
  color: $primary-color!important;  // Important is required here as a previous style already has !important
  @include sub-header;
}

@mixin sub-header {
  text-align: left;
  margin-bottom:1em;
  font-size: 14px;
}

@mixin mx-tag {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  overflow: hidden;
  font-style: italic;
  i {
      margin-right:10px;
      font-size: 14px;
    }
  .tag-text {
    line-height: 14px;
    display: flex;
    color:$primary-color!important;
    white-space: nowrap;
    font-size: 12px;
    &:not(:last-child) {
      margin-right: 2px;
      &:after {
        content: ", ";
      }
    }
  }
}

.icon-style {
  margin-top: 6px;
  font-size: 1.3rem;
}

.form-style {
  margin:auto, 10px;
}

.all-title {
  margin-bottom: 10px;
}
.no-all-title {
  height:30px;
}

.support-button {
  font-size: 12px;
  min-width:110px;
  width:fit-content;
  padding: 0.5rem 1.1rem;
}

#play-modal {
  display: flex;
  .modal-dialog {
    max-width: unset;
    .modal-header {
      .modal-title {
        color: $primary-color!important;;
      }
      .close {
        float:none;
        margin:0;
        padding:0;
      }
    }
    .modal-content {
      display: flex;
      max-width:800px;
      .modalHeight {
        overflow-y: scroll;
      }
      .modal-body {
        padding:0;
      }
    }
  }
}

#pdf-modal {
  .modal-dialog {
    width: 97%;
    max-width: 97%;
    height: 100%;
    max-height: 100%;
    display: flex;

    @media (min-width: 576px) {
      width: 90%;
      max-width: 90%;
      height: 95%;
      max-height: 95%;
    }
    @media (min-width: 1200px) {
      width: 80%;
      max-width: 80%;
      height: 90%;
      max-height: 90%;
    }

    .pdf-modal-body{
      display: flex;
      padding: 0;

      .pdf-body-content-container {
        flex: 1;
        display: flex;
        padding: 0 !important;

        div {
          flex: 1;
        }
      }
    }
  }
}

.help-video{
  background:black;
  transform: none !important;
}

.helpArticleList {
  .helpArticle {
    .list {
      display: flex;
      border: solid 1px black;
      border-radius: 15px;
      overflow:hidden;

      .article-icon {
        display: none;
        background: var(--bg-color) !important;
        color: #fff;
        justify-content: center;
        font-size: 12px;
        align-items: center;
        border-right: solid 1px black;
      }

      .article-information {
        flex: 1 0 0%;
        display: flex;
        flex-direction: column;
        text-align: left;
        margin: 5px 0 5px 30px;

        .title { //Same as the card - mixin this
          color:$primary-color!important;
          font-size: 16px;
          line-height: 20px;
          font-weight: 700;
          margin: 5px 0;
        }

        .tags {
          justify-content: left;
          flex-wrap: nowrap;
          margin-top:5px;
          @include mx-tag();
          display:none;
        }

        .no-tags {
          display: none;
          min-height: 20px;
          margin-bottom:5px;
          margin-top:5px;
        }
      }

      .meta-information {
        flex: 1 0 0%;
        display: none;
        flex-direction: column;
        margin: 5px 30px;
        align-items: center;
        justify-content: left;
        color:$primary-color;
        padding:8px 0;
        font-size: 12px;

        .help-card-author {
          flex: 1;
          color:$primary-color!important;
        }
        .meta-date {
          flex: 1;
        }
        i {
          margin-right: 10px;
          color: var(--bg-color) !important;
        }
      }

      .btn-container {
        flex: 1 1 0%;
        align-items: center;
        display: flex;
        justify-content: right;
        margin-right: 0;

        .btn {
          &.help-list-cta {
            div {
              text-transform: uppercase!important;
            }
          }
        }
      }

      .options{
        margin: auto 10px auto auto;
      }
    }
  }
}

.helpArticleGrid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

  .helpArticle {
    flex-direction: column;
    display: flex;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;

    .preview-card {
      flex: 1;
      position: relative;

      &:hover {
        cursor:pointer;

        .hidden-info-row {
          transform: translate(0, -100%);
          transition: transform 300ms;
        }

        .preview-image-container {
          .play-overlay {
            display:flex;
            transition: opacity 300ms 100ms;
            background-color: 000;
            opacity: 0.4;

            i {
              color:#fff!important;
              font-size: 40px;
              margin:auto;
            }
          }
        }
      }

      .preview-image-container{
        position: relative;
        .preview-image {
          background: var(--op-color) !important
        }

        .play-overlay {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          display: none;
          opacity: 0;
        }
      }

      .hidden-info-row {
        padding: 5px 20px;
        background: rgba(255,255,255,.5);
        position: absolute;
        transform: translate(0, 0%);
        width: 100%;
        text-align: left;
        z-index: 1;
        display: flex;
        align-items: center;

        .help-card-author {
          font-size: 12px;
          line-height: 22px;
          color:$primary-color!important;
          flex: 1;
          i {
            margin-right: 10px;
            font-size: 14px;
            line-height: 24px;
            color: var(--bg-color) !important;
          }
        }

        .video-duration {
          font-size: 12px;
          line-height: 22px;
          color: #495057 !important;
          i {
            margin-right: 10px;
            font-size: 14px;
            line-height: 24px;
            color: var(--bg-color) !important;
          }
        }
      }

      .card-body {
        background: white;
        position: relative;
        z-index: 2;

        .card-first-row {
          display: flex;
          justify-content: space-between;

          .icon-container {
              font-size: 8px;
          }

          .card-title {
            margin-left: 10px;
            flex: 1;
            text-align: left;
                margin-bottom: 0;

            .help-card-title {
              line-height: 24px;
              font-size: 18px;
              text-align: left;
              flex: 1;
              color:$primary-color!important;
              font-weight: 700;
            }
          }
          .options {
            line-height: 24px;
            height: 24px;
          }
        }
        .card-second-row {
          .tags {
            display: flex;
            margin: 15px 0 5px 0;
            @include mx-tag();

            .fa.fa-tag {
                margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

#help-article-upload-modal{
  .modal-dialog {
    max-width:800px;

    .modal-body {
      padding: 1rem 2rem;

      div p#help-modal-sub-header {
        @include grey-sub-header;
      }

      #upload-document-title {
        @include sub-header;

        #upload-text {
          color: $primary-color;
        }

        i {
          margin-right:5px;
        }
      }

      #upload-text {
        position: relative;
        left: 2.3em;
        top: 15px;
      }

      .video-tags {
        margin-bottom: 0;
      }

      .email-notification {
        margin-top:1.5rem;
        #notify-user-toggle {
          min-height: 21px;
        }
      }

      .file-upload-wrapper {
        margin: 2em 2em 0 2em;
        color: $primary-color;

        @media (min-width: 576px) {
          margin: 2em 4em 0 4em;
        }

        .file-upload {
          margin:1rem;
          text-align: center;
          &:hover {
            cursor: pointer;
          }
        }
      }

      p#help-modal-sub-header {
        color: $primary-color;
      }

      .tag-input {
        i {
          cursor: pointer;
        }
      }
    }
  }
}

@media (min-width: 576px) {
    .helpArticleList {
      .helpArticle {
        .list {
          .article-information {
            .tags {
              max-width: 380px;
              display:flex;
            }
            .no-tags {
              display: block;;
            }
          }
          .btn-container {
            margin-right:15px;
          }
        }
      }
    }
    .helpArticleGrid {
      .helpArticle {
        width: 49%;
        margin-right: 2%;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
  @media (min-width : 768px) {
    .helpArticleList {
      .helpArticle {
        .list {
          .meta-information {
            display: flex;
          }

          .article-information {
            flex: 1 0 15%;

            .title {
              font-size:18px;
              line-height: 22px;
            }

            .tags {
              max-width: 560px;
              .tag-text {
                line-height: 16px;
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .helpArticleList {
      .helpArticle {
        .list {
          .article-icon {
            width:80px;
            font-size:13px;
            display: flex;
          }
          .article-information {
            flex: 1 0 10%;
            .title{
              font-size:20px;
              line-height: 24px;
            }
            .tags {
              max-width: none;
               .tag-text {
                line-height: 20px;
                font-size: 12px;
              }
            }
          }
          .meta-information {
            font-size: 14px;
          }
        }
      }
    }
    .helpArticleGrid {
      .helpArticle {
        width: 32%;
        margin-right: 2%;
        &:nth-child(2n) {
          margin-right: 2%;
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .helpArticleList {
      .helpArticle {
        .list {
          .article-information {
            flex: 1 0 25%;
          }
        }
      }
    }
  }

  @media (min-width: 1320px) {
    .helpArticleGrid {
      .helpArticle {
        width: 23.5%;
        margin-right: 2%;
        &:nth-child(3n) {
          margin-right: 2%;
        }
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .helpArticleList {
      .helpArticle {
        .list {
          .article-information {
            flex: 1 0 25%;
          }
        }
      }
    }
  }

  @media (max-width : 768px) {
    .helpArticleList {
      .helpArticle {
        .list {
          .btn-container {
            .btn.help-list-cta {
              padding: 0.5rem 1rem;
              min-width: 80px;
            }
          }
        }
      }
    }
  }
</style>
