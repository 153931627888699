import axiosInstance from '../instance.js'
const api1 = new axiosInstance(1)

export default class helpService {
  static async uploadHelpArticle (formData) {
    return await api1.post('/api/go/help/gouploaddocument', formData)
  }

  static async getHelpArticles () {
    return await api1.get('/api/go/help/getall')
  }

  static async uploadHelpFileToBlob (formData) {
    return await api1.post('/api/go/help/gofileblobupload', formData)
  }

  static async deleteArticle (articleId) {
    return await api1.delete('/api/go/help/deletearticle?articleId=' + articleId)
  }

  static async getHelpArticleSummary () {
    return await api1.get('/api/go/help/getsummary')
  }
}
