<template>
  <div class="workflowTemplateGrid">
    <div
      v-for="(workflowTemplate) in workflowTemplates"
      :key="workflowTemplate.GoWorkflowTemplateId"
      :class="{'selected': selectedWorkflowTemplate.GoWorkflowTemplateId === workflowTemplate.GoWorkflowTemplateId, 'borderOnHover': cannotSelectTemplateMessage(workflowTemplate) == ''}"
      class="d-flex align-items-center text-center workflowTemplate br20 cp"
      style="position: relative; width: 100%; height: 100%"
      @click="setSelectedTemplate(workflowTemplate)"
    >
      <div
        style="text-wrap: balance;"
        class="mx-auto"
      >
        <div class="d-flex flex-column p-3">
          <div class="d-flex align-items-center justify-content-center mb-3 flex-wrap">
            <FontAwesomeIcon
              v-for="(stage, i) in workflowTemplate.GoWorkflowTemplateStages"
              :key="i"
              class="m-1 mr-2 fa-2x"
              :icon="getIconFromStageType(stage.StageType)"
            />
          </div>
          <span class="font-weight-bold">{{ workflowTemplate.GoWorkflowTemplateName }}</span>
        </div>
        <div
          v-if="cannotSelectTemplateMessage(workflowTemplate) != ''"
          class="cannotSelectTemplateMessage d-flex align-items-center justify-content-center p-3 br20"
        >
          {{ cannotSelectTemplateMessage(workflowTemplate) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GET_ICON_FROM_STAGE_TYPE_ENUM from '@/utils/getFAIconCodeFromWorkflowStageTypeEnum.js'
import { useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'InterviewTemplate',
  props: {
    workflowTemplates: {
      type: Array,
      default: () => []
    },
    selectedWorkflowTemplate: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    const workflowStore = useWorkflow()
    const { role } = storeToRefs(workflowStore)
    return { role }
  },
  methods: {
    getIconFromStageType (stageType) {
      return GET_ICON_FROM_STAGE_TYPE_ENUM(stageType)
    },
    setSelectedTemplate (template) {
      if (this.cannotSelectTemplateMessage(template) == '') {
        this.$emit('setSelectedTemplate', template)
      }
    },
    cannotSelectTemplateMessage (template) {
      let message = ''
      const firstStageStageType = template.GoWorkflowTemplateStages[0].StageType
      // role must be complete to select workflow restriction
      if (this.role.Step != 4) {
        message = 'You must complete the role setup before selecting this template.'
        return message
      // cannot have self registration on a two way or event
      } else if (firstStageStageType == 2 && (this.role.InterviewType == 2 || this.role.InterviewType == 3)) {
        message = 'This template does not currently support Self-Registration.'
        return message
      } else if (this.role.AudioOnly == null) {
        const templateContainsStagesWithVideo = template.GoWorkflowTemplateStages.filter(s => s.StageType === 1 || s.StageType == 2).length > 0
        if (templateContainsStagesWithVideo) {
          message = 'Please choose a different Audio / Video Capture setting under the "Masking Options" tab.'
        }
      }
      return message
    }
  }
}
</script>

<style>
.workflowTemplateGrid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
  grid-auto-rows: 150px;
  grid-gap: 10px;
}
.workflowTemplate {
  border: 3px solid;
  border-color: #c9c9c9;
  width: 100%;
}
.workflowTemplate:hover, .workflowTemplate.selected {
  scale: 1.003;
}
.borderOnHover:hover, .workflowTemplate.selected {
  border: var(--pr-color) 3px solid;
  box-shadow: 5px 5px 10px -5px var(--pr-color);
  transition: box-shadow 0.2s;
}

.cannotSelectTemplateMessage {
  opacity: 0;
  backdrop-filter: blur(8px);
  position: absolute;
  height: 100%;
  width: 100%;
  font-weight: bold;
  left: 0;
  top: 0;
  overflow: hidden;
  font-size: clamp(0.9rem, 2vw, 1.1rem);
  transition: opacity 0.3 ease;
}
.cannotSelectTemplateMessage:hover {
  opacity: 1;
  transition: opacity 0.4 ease;
}
</style>
