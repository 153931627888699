<template>
  <div
    :class="{'grey-text' : role.CloseDateIsPast}"
    class="d-flex align-items-center justify-content-between font-weight-bold"
  >
    <span
      v-if="!role.IsEvergreen"
    >
      <i class="fa fa-calendar-times mx-2 primary-colour" />
      <span class="opacity-colour textSize">{{ role.CloseDateIsPast ? 'Closed' : 'Closes' }} {{ new Date(role.Expires).toLocaleDateString() }} {{ new Date(role.Expires).toLocaleTimeString([], {timeStyle: 'short', hour12: true}).toUpperCase() }}</span>
    </span>
    <span v-else class="ml-2">
      <FontAwesomeIcon icon="fa-solid fa-leaf" class="mr-2" />
      <span v-if="role.CloseDateIsPast" class="opacity-colour textSize">CLOSED</span>
      <span v-else class="opacity-colour textSize">EVERGREEN</span>
    </span>
    <div v-if="!gettingCount" class="mr-2">
      <i class="fa fa-user mx-2 primary-colour" />
      <span class="opacity-colour textSize">{{ totalInviteCount }} <span class="dnone">Invited</span></span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DateAndInvitedPanel',
  props: {
    role: Object,
    totalInviteCount: Number,
    gettingCount: Boolean
  }
}
</script>
<style scoped>
@media (max-width: 576px) {
  .dnone {
    display:none;
  }
}
.textSize {
  font-size: clamp(0.65rem, 2.4vw, 0.75rem);
}
</style>
