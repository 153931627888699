import axiosInstance from '../instance.js'
const api1 = new axiosInstance(1, true)

export default class signUpService {
  // login
  static async login (user, pass, rememberMe) {
    return await api1.post('/oauth2/token?rm=' + rememberMe, '&grant_type=password&username=' + encodeURIComponent(user) + '&password=' + pass, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }})
  }

  // get user from id
  static async getUserFromId (id) {
    return await api1.get('/api/go/getuserfromid?id=' + id, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
  }

  // forgotten passsord
  static async forgottenPassword (email) {
    return await api1.post('/api/go/forgottenpassword?email=' + encodeURIComponent(email) + '&s=go', null, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
  }

  // set password
  static async setPassword (data) {
    return await api1.post('/api/go/setPassword', data, { headers: { 'Content-Type': 'application/json' } })
  }

  // suspend acount
  static async suspendAccount (email, guid) {
    return await api1.post('/api/go/suspendAccount?email=' + encodeURIComponent(email) + '&guid=' + guid, null, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
  }

  // reset acount
  static async resetAccount (email, guid) {
    return await api1.post('/api/go/resetaccount?email=' + encodeURIComponent(email) + '&guid=' + guid, null, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
  }

  // get user details
  static async getUserDetails (accesstoken) {
    return await api1.post('/api/go/getuserdetails', null, { headers: { Authorization: 'Bearer ' + accesstoken } })
  }

  // check email address exists before doing anything else
  static async checkEmailAddressExists (tempAccountData) {
    return await api1.post('/api/go/checkemailaddressexists', tempAccountData, { headers: { 'Content-Type': 'application/json' } })
  }

  // create new user and account step where chargebee kicks in (set as disabled, enabled on success of chargebee)
  static async createNewUserAndAccount (tempAccountData) {
    return await api1.post('/api/go/createnewuserandaccount', tempAccountData, { headers: { 'Content-Type': 'application/json' } })
  }

  // chargebee is successful so create the new account and user
  static async updateTempAccount (accountId, error) {
    return await api1.post('/api/go/updatetempaccount?accountId=' + accountId + '&error=' + error, { headers: { 'Content-Type': 'application/json' } })
  }

  // chargebee is successful so confirm new account and send out welcome email
  static async confirmNewAccountAndUser (id, hostedPageId) {
    return await api1.post('/api/go/confirmnewaccountanduser?id=' + id + '&hostedPageId=' + hostedPageId, { headers: { 'Content-Type': 'application/json' } })
  }

  // register new club
  static async registerNewAccount (newAccount) {
    return await api1.post('/api/go/registernewaccount', newAccount, null, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
  }
}
