import axiosInstance from '../instance.js'
const api1 = new axiosInstance(1)
const api2 = new axiosInstance(2)

export default class adminService {
  // get all accounts
  static async getAccounts (type) {
    return await api1.get('/api/go/getaccounts?type=' + type)
  }

  // toggle back to shine
  static async toggleShine () {
    return await api1.get('/api/go/toggleShine')
  }

  // get all notifications for the current account
  static async getNotifications () {
    return await api1.get('/api/go/getnotifications')
  }

  // create new notification alert
  static async addNotification (notification) {
    return await api1.post('/api/go/addnotification', notification)
  }

  // delete a notification
  static async deleteNotification (notificationId) {
    return await api1.delete('/api/go/deletenotification?notificationId=' + notificationId)
  }

  // get logs
  static async getLogs () {
    return await api1.get('/api/go/getlogs')
  }

  // add/edit account
  static async addEditAccount (acc) {
    return await api1.post('/api/go/addeditaccount', acc)
  }

  // add/edit account
  static async createViewAllJobsPageOnSelfRegistrationSetting (bool) {
    return await api1.post(`/api/go/createviewalljobspageonselfregistrationsetting?create=${bool}`, {})
  }

  // check access
  static async checkAccess () {
    return await api1.get('/api/go/checkaccess')
  }

  //API_URLV2 calls

  // get all master account groups
  static async getMasterAccountGroups (accountId) {
    return await api2.get('/api/accounts/master/' + accountId)
  }

  // add/edit master account group
  static async addEditMasterAccountGroup (mag) {
    return await api2.post('/api/accounts/master/', mag)
  }

  // delete master account group
  static async deleteMasterAccountGroup (accountGroupMasterId) {
    return await api2.delete('/api/accounts/master/' + accountGroupMasterId)
  }

  // get all master account groups
  static async getAccountsList () {
    return await api2.get('/api/accounts/all/')
  }

  // assign accounts
  static async assignAccounts (accountGroupMasterId, accounts) {
    return await api2.post('/api/accounts/assign/' + accountGroupMasterId, accounts)
  }

  // switch account
  static async switchAccount (accountId) {
    return await api2.get('/api/user/switchaccount/' + accountId)
  }
}
