<template>
  <div
    class="flex flex-col px-4"
    style="min-height: 100% !important"
  >
    <div
      style="height:80px;"
      class="flex-col items-center text-left mt-4"
    >
      <h3 class="primary-colour font-weight-bold">
        Add Working Pattern - Day
      </h3>
      <span class="font-weight-bold text-primary text-sm">
        Create time slots and optionally profile user to those slots for a single day.
      </span>
    </div>
    <div v-if="loading">
      <i class="fa fa-spinner fa-pulse" />
    </div>

    <div v-else>
      <div class="px-2 text-left text-xs text-primary flex flex-row items-center w-full">
        <font-awesome-icon
          :icon="['far', 'calendar']"
          class="mr-2 text-primary text-lg"
        />
        <mdb-input
          id="workingpatternname"
          v-model="workingPattern.Name"
          label="Name"
          class="m-0 w-1/3"
          type="text"
          size="sm"
        />
      </div>

      <div class="grid grid-cols-2 space-x-2">
        <div
          class="flex flex-col px-4 border p-2"
          style="min-height: 100% !important"
        >
          <div class="text-left py-2 font-bold">
            ADD SLOTS
          </div>

          <span class="text-left text-xs text-primary"> Start Time </span>
          <mdb-input
            id="startTime"
            v-model="eventSlot.StartTime"
            class="m-0"
            label="Start Time"
            type="time"
            onkeydown="return false"
            auto-complete="mtm"
            size="sm"
          />

          <span class="text-left text-xs text-primary"> End Time </span>
          <mdb-input
            id="endTime"
            v-model="eventSlot.EndTime"
            class="m-0"
            type="time"
            onkeydown="return false"
            auto-complete="mtm"
            size="sm"
          />

          <div class="flex flex-row items-center">
            <font-awesome-icon
              :icon="['fas', 'layer-group']"
              class="mr-2 text-primary"
            />
            <mdb-input
              v-model="eventSlot.NumberOfSlots"
              class="w-full"
              :min="1"
              :max="50"
              type="number"
              label="0"
              @change="addRemoveSlot($event)"
            />
          </div>

          <div class="flex flex-col">
            <div
              class="flex flex-row items-center mb-2"
              @click="eventSlot.LinkSlotsToUsers = !eventSlot.LinkSlotsToUsers"
            >
              <font-awesome-icon
                :icon="['far', 'circle-plus']"
                class="mr-2 text-primary"
              />
              <mdb-input
                v-model="eventSlot.LinkSlotsToUsers"
                type="checkbox"
                class="flex m-0 p-0"
              />
              <span class="text-primary text-xs"> Link slots to Users </span> <br>
            </div>

            <!-- This can be lots of slots? -->
            <div
              v-if="eventSlot.LinkSlotsToUsers"
              class="flex flex-col ml-auto w-5/6"
            >
              <div v-if="!loading">
                <div
                  v-for="(slot, index) in Number(eventSlot.NumberOfSlots)"
                  :key="index"
                  class="flex flex-row w-full items-center"
                >
                  <span class="text-primary text-xs w-1/5 font-bold"> SLOT {{ index + 1 }} </span>
                  <mdb-select
                    :key="key"
                    v-model="users[index]"
                    class="w-4/5"
                    placeholder="Select User"
                  />
                </div>
              </div>
              <mdb-icon v-else icon="fa fa-pulse fa-spinner" />
            </div>
          </div>

          <div class="items-center py-4">
            <mdb-btn
              :disabled="slotInvalid"
              size="sm"
              color="white"
              class="ml-auto m-0 text-xs w-1/3 sm:w-1/5"
              @click="addSlot()"
            >
              ADD SLOT
            </mdb-btn>
          </div>
        </div>

        <div class="border p-2">
          <div class="text-left py-2 font-bold">
            SLOTS
          </div>

          <b-table
            id="workingpatterntable"
            class="text-left table"
            :items="workingPatternSlots"
            :fields="fields"
            head-variant="dark"
            sort-icon-left
          >
            <template #cell(time)="data">
              <div
                class="flex items-center"
                style="cursor:pointer;"
              >
                {{ data.item.StartTime }} - {{ data.item.EndTime }}
              </div>
            </template>
            <template #cell(slots)="data">
              <div class="flex items-center">
                <div
                  v-for="(user, j) in data.item.ApplicationUsers"
                  :key="j"
                  class="flex-row"
                >
                  <!-- todo centre text properly? -->
                  <div class="w-10 h-10 rounded-full border-2 flex items-center">
                    <span class="m-auto font-bold text-primary">
                      {{ user?.FirstName[0] }} {{ user?.Surname[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
            <template #cell(action)="data">
              <FontAwesomeIcon
                icon="fa-trash-can-xmark fa-solid"
                class="items-center text-lg cursor-pointer"
                @click="deleteWorkingPatternSlot(data.index)"
              />
            </template>
          </b-table>
        </div>
      </div>

      <div class="flex pb-1 mt-8">
        <mdb-btn
          size="sm"
          color="secondary"
          class="mr-auto m-0 btn btn-outline text-xs w-1/3 sm:w-1/5"
          @click="$router.push('/settings/workingpatterns')"
        >
          CANCEL
        </mdb-btn>
        <mdb-btn
          :disabled="formInvalid"
          size="sm"
          color="white"
          class="ml-auto m-0 text-xs w-1/3 sm:w-1/5"
          @click="addWorkingPattern()"
        >
          CREATE
        </mdb-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useEvent, useStatus, useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import eventService from '@/api/services/event.service'
import moment from 'moment';

export default {
  name:'AddWorkingPattern',
  setup () {
    const { mobileView } = useMobile()
    const eventStore = useEvent()
    const { eventSlot, workingPattern } = storeToRefs(eventStore)
    const { getUsers, resetEventSlotForm, getWorkingPatterns } = useEvent()
    const userStore = useUserData()
    const { showGenericErrorModal } = useStatus()

    const users = ref([])
    const key = ref(0)
    const loading = ref(false)
    const locationsDropdown = ref([])
    const workingPatternSlots = ref([])

    const fields = [
      { key: 'time', label: '  Time', tdClass: 'sortIcon', sortable: true },
      { key: 'slots', label: '  Slots', tdClass: 'sortIcon', sortable: true },
      { key: 'action', label: '' },
    ]

    // todo - duplicate code => addSlots
    let usersDefault = null
    let dropdown = []
    const load = async () => {
      loading.value = true
      try {
        let res = await getUsers()
        usersDefault = [...res]
        await addRemoveSlot(1)
      } catch (error) {
        showGenericErrorModal({ showing: true })
      }
      loading.value = false
    }

    const addSlot = () => {
      eventSlot.value.ApplicationUsers = users.value.map(user => user.filter(u => u.selected)[0])
      workingPatternSlots.value.push(JSON.parse(JSON.stringify(eventSlot.value)))
      eventSlot.value.StartTime = null
      eventSlot.value.EndTime = null
      eventSlot.value.NumberOfSlots = null
      if (eventSlot.value.LinkSlotsToUsers) {
        users.value = []
        addRemoveSlot(1)
      }
      eventSlot.value.LinkSlotsToUsers = false
    }

    const deleteWorkingPatternSlot = (i) => {
      workingPatternSlots.value.splice(i,1)
    }

    // todo - fix this when user selects then adds more slots
    const addRemoveSlot = async (event) => {
      loading.value = true
      let index = Number(event - 1)
      dropdown = usersDefault.map(c => ({
        text: c.User,
        selected: false,
        value: c.Id,
        FirstName: c.FirstName,
        Surname: c.Surname
      }))
      users.value[index] = dropdown
      key.value = key.value + 1
      loading.value = false
    }


    const addWorkingPattern = async () => {
      // Timeslots
      workingPatternSlots.value.map(x => {
        x.StartDate = moment("2999-12-1")._d,
        x.EndDate = moment("2999-12-1")._d,
        x.RoleWorkflowStageId = null
      })

      const ids = workingPatternSlots.value.map(x => x.ApplicationUsers.map(y => y?.value))
      workingPatternSlots.value.map((x,i) => x.ApplicationUsers = ids[i])

      let payload = {
        GoEventWorkingPatternName: workingPattern.value.Name,
        AccountId: userStore.userData.aid,
        GoEventTimeslots: workingPatternSlots.value
      }

      try {
        loading.value = true
        const res = await eventService.addEventWorkingPattern(payload)
        if (res.data === true) reset()
      } catch (error) {
        showGenericErrorModal({ showing: true })
      } finally {
        loading.value = false
      }
    }

    const formInvalid = computed(() => {
      const invalid = (
        !workingPattern.value.Name ||
        !workingPatternSlots.value.length
      )
      return invalid
    })

    const slotInvalid = computed(() => {
      const invalid = (
        eventSlot.value.StartTime === null ||
        eventSlot.value.EndTime === null
      )
      return invalid
    })

    const reset = () => {
      workingPattern.value.Name = ''
      workingPatternSlots.value = []
      resetEventSlotForm()
    }

    load()
    getWorkingPatterns()

    return {
      addWorkingPattern,
      addSlot,
      eventSlot,
      mobileView,
      users,
      addRemoveSlot,
      key,
      loading,
      locationsDropdown,
      formInvalid,
      workingPattern,
      workingPatternSlots,
      slotInvalid,
      fields,
      deleteWorkingPatternSlot
    }
  }
}
</script>

<!-- TODO -->
<style scoped>
table td {
  display: flex !important;
  align-items: center !important;
  vertical-align: middle !important;
}

b-table > tbody > tr > td {
  display: flex !important;
  align-items: center !important;
  vertical-align: middle !important;
}
</style>
