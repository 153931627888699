<template>
  <div
    style="width:100%;"
    class="d-flex align-items-center text-bold text-black rounded text-center border-2 border-gray-400  bg-gray-100 p-2 px-3 my-1 flex-nowrap"
  >
    <HistoryItemDetails
      :extraDetailText="getHistoryInfo(historyItem).extraDetailText"
      :historyTypeText="getHistoryInfo(historyItem).historyTypeText"
      :iconCode="getHistoryInfo(historyItem).iconCode"
      :dateAdded="historyItem.DateAdded"
    >
      <template #button>
        <mdb-btn
          v-if="getHistoryInfo(historyItem).linkText && !hideActionButtons"
          size="sm"
          color="primary"
          @click="button"
        >
          {{ getHistoryInfo(historyItem).linkText }}
        </mdb-btn>
      </template>
    </HistoryItemDetails>
  </div>
</template>

<script>
import HistoryItemDetails from './historyItemDetails.vue'
import GET_HISTORY_INFO from '@/utils/historyInfoSwitchCase.js'

export default {
  name: 'SingleHistoryItem',
  components: {
    HistoryItemDetails
  },
  props: {
    historyItem: {
      type: Object,
      default: () => {}
    },
    hideActionButtons: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const getHistoryInfo = item => GET_HISTORY_INFO(item)

    const button = () => {
      switch (props.historyItem.HistoryType) {
        case 4:
          // meet now
          emit('prepMeetNowModal', props.historyItem.RelevantHistoryTypeId)
          break;
        case 6:
          emit('openReviewModal', props.historyItem)
          // one way completed
          break;
        case 7:
          emit('openReviewModal', props.historyItem)
          // two way completed
          break;
      }
    }
    return { getHistoryInfo, button }
  }
}
</script>
