<template>
  <!--watch interview modal-->
  <mdb-modal
    id="watchInterviewModal"
    class="p-0 lightReviewModalBG"
    :show="watchInterviewModal"
    :size="stageType == 3 ? 'lg' : 'fluid'"
    @close="$emit('closeModal')"
  >
    <div
      v-if="waitingUpdate"
      style="height:60vh"
      class="d-flex align-items-center flex-column justify-content-center mx-auto"
    >
      <div class="mb-2">
        PREPARING APPLICATION . . .
      </div>
      <mdb-icon style="font-size:2rem;" icon="fa fa-pulse fa-spinner" />
    </div>
    <div v-else>
      <ReviewHeader
        :watch-interview-modal="watchInterviewModal"
        :hide-status-dropdown="applicantIsInStageWithIndex != viewingStageWithIndex"
        :candidate="candidate"
        :modal-context="modalContext"
        :spotlight-masking="spotlightMasking"
        :candidate-application="candidateApplication"
        :waiting-update="waitingUpdate"
        @close="$emit('closeModal')"
        @setFollowing="setFollowing"
        @needsUpdating="reviewModalContextNeedsUpdating = true"
        @setStatus="setCandidateApplicationStatus($event)"
      />

      <mdb-modal-body class="pt-0 lightReviewModalBG">
        <div class="pt-3">
          <!-- we dont render this is there is an invite code - they are being viewed from the candidate history -->
          <StageTabs
            v-if="!inviteCode" 
            class="pb-3"
          />
        </div>
        <div
          class="d-flex row m-0 reviewModalBodyHeight lightReviewModalBG"
          style="overflow-y:auto;width:100%;height:fit-content"
        >
          <div
            v-if="stageType != 3"
            style="min-height:400px;"
            class="d-flex col-12 col-lg-6 pl-0 pr-0 pr-lg-2"
          >
            <LHS
              :user-language="userLanguage"
              :stage-type="stageType"
              :show-video-c-v-tab="showVideoCVTab"
              :current-question-id="currentQuestionId"
              :current-video="currentVideo"
              :modal-context="modalContext"
              :spotlight-masking="spotlightMasking"
              :candidate="candidate"
              :current-question="currentQuestion"
              :candidate-application="candidateApplication"
              @setStatus="setCandidateApplicationStatus($event)"
              @setVideoStream="setVideoStream($event)"
              @setQuestionId="currentQuestionId = $event"
            />
          </div>
          <!--RHS-->
          <div
            style="min-height:400px;text-wrap:no-wrap;"
            class="d-flex flex-column col-12 pl-0 pr-0"
            :class="{ 'col-lg-12': stageType == 3, 'col-12 pl-0 pr-0 col-lg-6 mt-3 pl-lg-2 mt-lg-0': stageType != 3 }"
          >
            <!--RHS buttons-->
            <div style="width:100%;height:40px;" class="d-flex align-items-center justify-content-between flex-grow-0 mb-3">
              <mdb-btn
                class="mr-1 ml-0 tabTypeButtons"
                color="primary"
                size="sm"
                :class="{'opac' : tabType !== 'comments', 'selected' : tabType == 'comments'}"
                @click.native="showOptions(1);"
              >
                <i class="fa fa-comment" /><span class="d-none d-sm-inline"> COMMENTS</span>
              </mdb-btn>
              <mdb-btn
                v-show="currentVideo.VideoUrl !== null && stageType === 1 && !showVideoCVTab && modalContext != 'CANDIDATE_HISTORY_READ_ONLY'"
                class="mx-1 tabTypeButtons"
                color="primary"
                size="sm"
                :class="{'opac' : tabType !== 'scoring', 'selected' : tabType == 'scoring'}"
                @click.native="showOptions(2)"
              >
                <i class="fa fa-star-half-alt" /><span class="d-none d-sm-inline"> SCORING</span>
              </mdb-btn>
              <mdb-btn
                v-show="currentVideo.VideoUrl === null && stageType === 1 && !showVideoCVTab && modalContext != 'CANDIDATE_HISTORY_READ_ONLY'"
                class="mx-1 tabTypeButtons"
                color="secondary"
                size="sm"
                disabled
                :class="{'opac' : tabType !== 'scoring', 'selected' : tabType == 'scoring'}"
              >
                <i class="fa fa-star-half-alt" /><span class="d-none d-sm-inline"> SCORING</span>
              </mdb-btn>
              <mdb-btn
                v-if="!(stageType !== 1 || showVideoCVTab)"
                class="mx-1 tabTypeButtons"
                color="primary"
                size="sm"
                :class="{'opac' : tabType !== 'questions', 'selected' : tabType == 'questions'}"
                @click.native="showOptions(4);"
              >
                <i class="fa fa-question-circle" /><span class="d-none d-sm-inline"> QUESTIONS</span>
              </mdb-btn>
              <!--VCVX
              <mdb-btn
                v-if="showVideoCVTab"
                class="mx-1 tabTypeButtons"
                color="primary"
                size="sm"
                :class="{'opac' : tabType !== 'videocv', 'selected' : tabType == 'videocv'}"
                @click.native="showOptions(6);"
              >
                <i class="fa fa-question-circle" /><span class="d-none d-sm-inline"> VIDEO CV</span>
              </mdb-btn>-->
              <mdb-btn
                v-if="!candidateApplication.Job?.MaskCandidateDocuments && spotlightMasking !== 2"
                class="mx-1 tabTypeButtons"
                color="primary"
                size="sm"
                :class="{'opac' : tabType !== 'documents', 'selected' : tabType == 'documents'}"
                @click.native="showOptions(3)"
              >
                <i class="fa fa-file-alt" /><span class="d-none d-sm-inline"> DOCUMENTS</span>
              </mdb-btn>
              <mdb-btn
                class="ml-1 mr-0 tabTypeButtons"
                color="primary"
                size="sm"
                :class="{'opac' : tabType !== 'history', 'selected' : tabType == 'history'}"
                @click.native="showOptions(5)"
              >
                <i class="fa fa-clock" /><span class="d-none d-sm-inline"> HISTORY</span>
              </mdb-btn>
            </div>
            <!-- <SlideFade> -->
            <Comments
              v-if="tabType === 'comments' && !showVideoCVTab"
              :current-question-id="currentQuestionId"
              :stage-type="stageType"
              :candidate-application="candidateApplication"
              :user-language="userLanguage"
              @updateCandidateApplication="updateCandidateApplication($event)"
            />
            <VCVComments
              v-else-if="tabType === 'comments' && showVideoCVTab"
              :current-question-id="currentQuestionId"
              :candidate-id="reviewModalData.CandidateId"
              :spotlight-id="spotlightId"
              :user-language="userLanguage"
            />
            <Scoring
              v-else-if="tabType === 'scoring'"
              :candidate-application="candidateApplication"
              :current-video="currentVideo"
              :current-question-id="currentQuestionId"
              :stage-type="stageType"
              @updateCandidateApplication="updateCandidateApplication($event)"
              @setQuestionId="currentQuestionId = $event"
            />
            <Questions
              v-else-if="tabType === 'questions'"
              :current-video="currentVideo"
              :current-question-id="currentQuestionId"
              :candidate-application="candidateApplication"
              :modal-context="modalContext"
              :current-question="currentQuestion"
              @reviewModalContextNeedsUpdating="reviewModalContextNeedsUpdating = true"
              @setQuestionId="currentQuestionId = $event"
              @reloadCandidateApplication="getGoCandidateApplicationForReviewModal()"
              @updateCandidateApplication="updateCandidateApplication($event)"
            />
            <VCVQuestions
              v-else-if="tabType === 'videocv'"
              :current-video="currentVideo"
              :current-question-id="currentQuestionId"
              :candidate-application="candidateApplication"
              @setQuestionId="currentQuestionId = $event"
              @reloadCandidateApplication="getGoCandidateApplicationForReviewModal()"
              @updateCandidateApplication="updateCandidateApplication($event)"
            />
            <Documents
              v-else-if="tabType === 'documents'"
              :candidate="candidate"
              :candidate-application="candidateApplication"
              :current-question-id="currentQuestionId"
              @changeIDCheckStatus="changeIDCheckStatus($event)"
            />
            <History
              v-else-if="tabType === 'history'"
              :candidate="candidate"
              :show-video-c-v-tab="showVideoCVTab"
            />
            <!-- </SlideFade> -->
          </div>
        </div>
      </mdb-modal-body>
    </div>
  </mdb-modal>
</template>

<script>
import { useUserData, useStatus, useRoleCandidate } from '@/pinia'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useMobile } from '@/composables/useMobile'
import roleService from '@/api/services/role.service'
import roleV2Service from '@/api/services/roleV2.service'
import clientPortalService from '@/api/services/clientPortal.service'
import { userLanguage } from '@/common/userLanguage'

import ReviewHeader from './reviewModalComponents/header.vue'
import Comments from './reviewModalComponents/comments.vue'
import VCVComments from './reviewModalComponents/VCVComments.vue'
import Questions from './reviewModalComponents/questions.vue'
import VCVQuestions from './reviewModalComponents/VCVQuestions.vue'
import Scoring from './reviewModalComponents/scoring.vue'
import Documents from './reviewModalComponents/documents.vue'
import History from './reviewModalComponents/history.vue'
import LHS from './reviewModalComponents/LHS.vue'
import StageTabs from './reviewModalComponents/stageTabs.vue'

export default {
  name: 'ReviewModalV2',
  components: {
    ReviewHeader,
    Comments,
    Scoring,
    Documents,
    History,
    Questions,
    VCVQuestions,
    VCVComments,
    LHS,
    StageTabs
  },
  props: {
    reviewModalData: {
      type: Object,
      default: () => {}
    },
    roleId: {
      type: Number,
      default: 0
    },
    watchInterviewModal: Boolean,
    modalContext: {
      default: 'ROLES',
      type: String
    },
    showVideoCVTab: {
      default: false,
      type: Boolean
    },
    spotlightMasking: {
      default: 0,
      type: Number
    },
    inviteCode: {
      default: '',
      type: String
    }
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()
    const { mobileView } = useMobile()

    const roleCandidateStore = useRoleCandidate()
    const { reviewModalRoleWorkflow, reviewModalSelectedStage } = storeToRefs(roleCandidateStore)

    const stageType = computed(() => reviewModalSelectedStage.value.StageType)

    return {
      authData,
      userData,
      showGenericErrorModal,
      mobileView,
      reviewModalRoleWorkflow,
      reviewModalSelectedStage,
      stageType
    }
  },
  data () {
    return {
      waitingUpdate: true,
      currentVideo: { VideoUrl: null },
      candidate: {},
      currentQuestion: { Video: null },
      candidateDocs: [],
      currentQuestionId: 0,
      no: 5,
      tabType: 'questions',
      userLanguage,
      candidateApplicationStages: {},
      reviewModalContextNeedsUpdating: false
    }
  },
  computed: {
    applicantIsInStageWithIndex () {
      return this.reviewModalRoleWorkflow.OrderedRoleWorkflowStages.findIndex(stage => stage.RoleWorkflowStageId === this.candidateApplication.ApplicantsCurrentWorkflowStageId)
    },
    viewingStageWithIndex () {
      return this.reviewModalRoleWorkflow.OrderedRoleWorkflowStages.findIndex(stage => stage.RoleWorkflowStageId === this.selectedStageId)
    },
    selectedStageId () {
      return this.reviewModalSelectedStage.RoleWorkflowStageId
    },
    candidateApplication () {
      return this.candidateApplicationStages[this.selectedStageId]
    },
    noDocs () { return this.candidateDocs.length === 0 },
    overallScoringValue () { return this.currentQuestion.OverallRating },
    scoringValue () { return this.currentQuestion.Rating },
    spotlightId () {
      if (this.candidate.SpotlightId) {
        return this.candidate.SpotlightId
      } return 0
    }
  },
  watch: {
    async selectedStageId () {
      if (!this.candidateApplicationStages[this.selectedStageId]) return
      if (this.candidateApplicationStages[this.selectedStageId].NotYetLoaded === true) {
        // only gets the stage again if we havent already got it
        await this.getStageData()
      }
      this.currentQuestionId = this.candidateApplicationStages[this.selectedStageId].Questions[0].QuestionId
    },
    'currentVideo.VideoUrl' () {
      if (this.currentVideo.VideoUrl === null) {
        this.showScoring = false
      }
    },
    currentQuestionId () {
      this.updateCurrentQuestion()
    },
    currentQuestion () {
      if (this.reviewModalSelectedStage.StageType === 1) {
        try {
          if (this.currentQuestion.Video !== null && this.currentQuestion.Video !== undefined) {
            this.currentVideo = { ...this.currentQuestion.Video }
          } else { this.currentVideo = { VideoUrl: null } }
        } catch { this.currentVideo = { VideoUrl: null } }
      }
    },
    watchInterviewModal: {
      handler: 'handleWatcherInterviewModalWatcher',
      immediate: true
    }
  },
  methods: {
    async handleWatcherInterviewModalWatcher () {
      if (this.watchInterviewModal) {
        this.reviewModalContextNeedsUpdating = false
        this.candidate = this.reviewModalData
        // CREATES THE INITIAL MODEL TO POPULATE
        this.reviewModalRoleWorkflow.OrderedRoleWorkflowStages.forEach((stage) => {
          this.candidateApplicationStages[`${stage.RoleWorkflowStageId}`] = { Questions: [], NotYetLoaded: true }
        })
        await this.$nextTick(() => {})
        this.getStageData()
      } else {
        if (this.reviewModalContextNeedsUpdating == true) {
          this.$emit('setCandidates')
        }
        this.waitingUpdate = true
      }
    },
    setFollowing (bool) {
      this.reviewModalContextNeedsUpdating = true
      for (const [key, value] of Object.entries(this.candidateApplicationStages)) {
        value.Following = bool
      }
    },
    setCandidateApplicationStatus (newStageItemId) {
      const newStageItem = this.reviewModalSelectedStage.OrderedRoleWorkflowStageItems.find(i => i.RoleWorkflowStageItemId == newStageItemId)
      this.candidateApplicationStages[this.selectedStageId].CurrentStatus = newStageItem.RoleWorkflowStageItemId
      this.candidateApplicationStages[this.selectedStageId].CompleteStatusType = newStageItem.CompleteStatusType
      this.candidateApplicationStages[this.selectedStageId].StageItemStatus = newStageItem.StageItemStatus
      this.candidateApplicationStages[this.selectedStageId].RoleWorkflowStageItemName = newStageItem.RoleWorkflowStageItemName
      this.reviewModalContextNeedsUpdating = true
    },
    async getStageData () {
      await this.getGoCandidateApplicationForReviewModal()
      if (this.reviewModalSelectedStage.StageType === 1) {
        this.showOptions(4)
      } else if (this.reviewModalSelectedStage.StageType == 2) {
        this.showOptions(1)
      } else if (this.reviewModalSelectedStage.StageType == 3) {
        this.showOptions(1)
      }
    },
    changeIDCheckStatus (status) {
      this.candidate = { ...this.candidate, IDChecked: status }
    },
    updateCandidateApplication (application) {
      this.candidateApplicationStages[this.selectedStageId] = { ...application }
      this.updateCurrentQuestion()
      // these lines will trigger the watcher on currentQuestionId which will in turn update the currently selected question from the new application object
      this.$forceUpdate()
    },
    async getVCVApplication () {
      try {
        const response = await clientPortalService.getClientPortalVCVReviewModalInformation(this.candidate.CandidateId)
        this.candidateApplication = response.data
        try {
          this.currentQuestionId = this.candidateApplication.Questions[0].QuestionId
        } catch (err) {
          //?
        }
        this.tabType = 'videocv'
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.waitingUpdate = false
    },
    async getOneWayApplication () {
      const JSONData = {
        RoleWorkflowStageId: this.selectedStageId,
        CandidateId: this.candidate.CandidateId,
        RoleId: this.roleId,
        InvitationCode: this.inviteCode || null
      }
      try {
        const response = await roleV2Service.getOneWayApplication(JSONData)
         this.candidateApplicationStages = { ...this.candidateApplicationStages, [this.selectedStageId]: { ...response.data }}
        try {
          this.currentQuestionId = response.data.Questions[0].QuestionId
        } catch {
          //?
        }
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
    },
    async getTwoWayApplication () {
      const JSONData = {
        RoleWorkflowStageId: this.selectedStageId,
        CandidateId: this.candidate.CandidateId,
        RoleId: this.roleId,
        InvitationCode: this.inviteCode || null
      }
      try {
        const response = await roleV2Service.getTwoWayApplication(JSONData)
         this.candidateApplicationStages = { ...this.candidateApplicationStages, [this.selectedStageId]: { ...response.data }}
        try {
          this.tabType = 'comments'
          if (response.data.DefaultVideo !== null) {
            this.currentVideo = response.data.DefaultVideo
          } else { this.currentVideo = { VideoUrl: null } }
        } catch {
          //?
        }
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
    },
    async getEventApplication () {
      const JSONData = {
        RoleWorkflowStageId: this.selectedStageId,
        CandidateId: this.candidate.CandidateId,
        RoleId: this.roleId
      }
      try {
        const response = await roleV2Service.getEventApplication(JSONData)
         this.candidateApplicationStages = { ...this.candidateApplicationStages, [this.selectedStageId]: { ...response.data }}
        try {
          this.tabType = 'comments'
        } catch {
          //?
        }
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
    },
    async getGoCandidateApplicationForReviewModal (item) {
      this.currentQuestionId = 0
      this.currentVideo = {}
      this.currentQuestion = {}
      if (this.showVideoCVTab) {
        this.tabType = 'videocv'
        await this.getVCVApplication()
      } else if (this.reviewModalSelectedStage.StageType === 1) {
        await this.getOneWayApplication()
      } else if (this.reviewModalSelectedStage.StageType === 2) {
        await this.getTwoWayApplication()
      } else if (this.reviewModalSelectedStage.StageType === 3) {
        await this.getEventApplication()
      }
      this.waitingUpdate = false
    },
    setVideoStream (videoStreamObject) {
      this.candidateApplicationStages[this.selectedStageId].Questions = this.candidateApplicationStages[this.selectedStageId].Questions.map(q => { return q.QuestionId === this.currentQuestionId ? { ...q, Video: videoStreamObject } : q })
      this.currentVideo = videoStreamObject
      this.currentQuestion = { ...this.currentQuestion, Video: videoStreamObject }
    },
    async showOptions (type) {
      if (type === 1) { // comments
        this.tabType = 'comments'
      } else if (type === 2) { // scoring
        this.tabType = 'scoring'
      } else if (type === 3) { // documents
        try {
          const response = await roleService.getCandidateDocs(this.candidate.CandidateId)
          this.candidateDocs = response.data.DocumentList
        } catch (err) {
          this.showGenericErrorModal({ showing: true })
        }
        this.tabType = 'documents'
      } else if (type === 4) { // questions
        this.tabType = 'questions'
      } else if (type === 5) { // history
        this.tabType = 'history'
      } else if (type === 6) {
        this.tabType = 'videocv'
      }
    },
    updateCurrentQuestion () {
      if (this.currentQuestionId !== 0) {
        this.currentQuestion = { ...this.candidateApplicationStages[this.selectedStageId].Questions.filter(q => q.QuestionId === this.currentQuestionId)[0] }
      } else { this.currentQuestion = { Video: null } }
    }
  }
}
</script>
<style>
.reviewModalRHTab {
  border:solid 2px #c0c0c0;
  height:0px;
  overflow:auto;
  background: #fff;
}
.lightReviewModalBG {
  background: #e5e5e5;
}
#watchInterviewModal > div > div > div > .modal-header .close {
  transform: translateX(-22px);
  color: #fff !important;
  margin-top: -20px;
  font-size: 40px;
}
.videoTitleBanner {
  border-bottom: 1px solid rgb(192, 192, 192);
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: -8px;
  background-color: var(--bg-color) !important;
  color: var(--lk-color) !important;
}
@media (min-width: 992px) {
  .reviewModalBodyHeight {
    max-height:calc(100vh - 180px)
  }
}
@media (max-width: 576px) {
  .mobb {
     width: 35px !important;
   }
  .mobileWatchP {
    float:left;
    margin-top:-10px;
    margin-bottom:-10px;
    margin-left:-15px !important  ;
    border-radius:50%;
    cursor:pointer;
    position:relative;
  }
  .mobileWatchF {
    font-size:1.5rem !important;
  }
  .mobileWatchS {
    font-size:0.8rem !important;
  }
}
</style>
<style lang="scss" scoped>
#video-container video {
    width: auto;
    max-width: 100%;
    height: 300px;
    margin: auto;
}
.pag {
  display: inline-block;
}
.pag a {
  color: black;
  float: left;
  padding: 4px 8px;
  font-size: 10px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  margin: 0 4px;
  width:30px;
  text-align: center;
}
.pag a.active {
  background-color: var(--bg-color);
  color: var(--lk-color);
  border: 1px solid var(--bg-color);
}
.pag a:hover:not(.active) {background-color: #ddd;}

.mobButt {
  margin-top: -35px;
  margin-left: -8px;
}
.btnSz {
  font-size: 1rem !important;
}
.xTop {
  margin-top: 3px !important;
}
.fWidth {
  width:100% !important;
}
.md-form textarea.md-textarea {
  overflow-y: hidden;
  border-radius: 10px;
  border: solid 1px #e1e1e1;
  padding: 10px;
}
.range {
  width: 100%;
}
.vue-slider-process {
  background-color: var(--pr-color) !important;
  transition: background-color 0.3s;
  @at-root .vue-slider:hover & {
    background-color: var(--pr-color) !important;
  }
}
.opac {
  opacity: 0.5;
  height: 32px;
  font-size: 9px !important;
}
.vue-slider-process {
  background-color: var(--pr-color) !important;
  transition: background-color 0.3s;
  @at-root .vue-slider:hover & {
    background-color: var(--pr-color) !important;
  }
}
.ratingsM {
  margin-left: 7px !important;
}
.marg50 {
  margin-bottom: 50px !important;
}
.range-wrap {
  position: relative;
  margin: 0 auto 3rem;
}
.outerDiv {
    display: inline-flex;
    height: auto;
    padding: 5px;
    min-height: 50px;
}
.innerDiv {
    margin: auto 0px;
}
.outerDiv1 {
    display: inline-flex;
    height: auto;
}
.innerDiv1 {
    margin: auto 0px;
}
#qOuterDiv {
    display: grid;
    height: auto;
    padding: 5px;
    min-height: 50px;
}
#qInnerDiv {
    margin: auto 0px;
}
.tabTypeButtons {
  height: 40px;
  padding:0px !important;
  width:auto;
  flex-grow: 1;
  transition: ease 0.2s;
}
.tabTypeButtons.selected {
  height: 44px;
  transition: ease 0.2s;
}
.questionsScrollContainer {
  float:left;
  height: 40vh;
  width:100%;
  padding:0px;
}
.questionsScrollContent {
  overflow-y:auto;
  overflow-x:hidden;
  height:39vh !important;
}

*::-webkit-scrollbar {
  width: 0px;
}
.alertBtn {
  background: red !important;
  text-transform: uppercase !important;
  font-weight: bold;
}
@media (max-width: 576px) {
  .mobileQText {
    inline-size: 95%;
    overflow-wrap: break-word;
    text-align: center;
    margin-top: -9px;
  }
  .questionH {
    height: fit-content !important;
  }
  .mobileButTop {
    margin-top: 30px !important;
  }
  .autoH {
    height: auto !important;
  }
  .caretMargTop {
    margin-top: -5px !important;
  }
  .mobWidthPad {
    padding: 8px;
  }
}
@media (min-width:769px) and (max-width:1023px) {
  .tWidth {
    width: 100% !important;
  }
}

</style>
