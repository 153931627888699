<template>
  <div class="d-flex col outerContainerBoilerplate p-0" :class="mobileView? 'mt-2' : ''">
    <div v-if="leftColumnActive === false || leftColumnActive === undefined">
      <LeftHelpColumn
        :show-upload-modal="showUploadModal"
        :current-filter="currentFilter"
        :help-article-summary="helpArticleSummary"
        @toggleModal="toggleUploadModal()"
        @updateHelpArticleFilter="currentFilter = $event"
      />
    </div>

    <div class="mainSectionBoilerPlate px-4">
      <div class="mainSectionBoilerPlateInner">
        <Help
          :show-upload-modal="showUploadModal"
          :help-categories="helpCategories"
          :current-filter="currentFilter"
          @toggleModal="toggleUploadModal()"
          @resetCategories="resetCategories()"
          @getHelpArticleSummary="getHelpArticleSummary()"
          @updateHelpArticleFilter="currentFilter = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import helpService from '@/api/services/help.service'

import LeftHelpColumn from '../../views/help/innerComponents/leftColumn.vue'
import Help from '../../views/help/innerComponents/help.vue'

export default {
  name: 'HelpContainer',
  components: {
    LeftHelpColumn,
    Help
  },
  setup () {
    const userStore = useUserData()
    const { authData, leftColumnActive } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    return {
      authData,
      leftColumnActive,
      mobileView
    }
  },
  data () {
    return {
      showUploadModal: false,
      currentFilter: 0,
      helpCategories: [],
      helpArticleSummary: {
        allCount: 0,
        gettingStartedCount: 0,
        didYouKnowCount: 0,
        userGuidesCount: 0,
        candidatesCount: 0,
        rolesCount: 0,
        technicalCount: 0
      }
    }
  },
  watch: {
    '$route.query.f': {
      handler: function (value) {
        if (value !== undefined) this.currentFilter = Number(value)
      },
      immediate: true
    }
  },
  created () {
    this.getHelpArticleSummary()
    if (this.$route.query.f !== null && this.$route.query.f !== undefined && this.$route.query.f !== '0') {
      this.currentFilter = parseInt(this.$route.query.f)
    }
  },
  methods: {
    async getHelpArticleSummary () {
      try {
        const res = await helpService.getHelpArticleSummary()
        this.helpArticleSummary.allCount = res.data.AllCount
        this.helpArticleSummary.gettingStartedCount = res.data.GettingStartedCount
        this.helpArticleSummary.didYouKnowCount = res.data.DidYouKnowCount
        this.helpArticleSummary.userGuidesCount = res.data.UserGuidesCount
        this.helpArticleSummary.candidatesCount = res.data.CandidatesCount
        this.helpArticleSummary.rolesCount = res.data.RolesCount
        this.helpArticleSummary.technicalCount = res.data.TechnicalCount
      } catch (err) { 
        //?
      }
    },
    toggleUploadModal () {
      this.showUploadModal = !this.showUploadModal
    },
    resetCategories () {
      this.helpCategories = this.getCategories()
    },
    getCategories () {
      return [{ text: 'Getting Started', value: 1 },
        { text: 'Did You Know?', value: 2 },
        { text: 'User Guides', value: 3 },
        { text: 'Candidates', value: 4 },
        { text: 'Roles', value: 5 },
        { text: 'Technical', value: 6 }]
    }
  }
}
</script>

<style lang="css" scoped>
.leftColHeader {
  background:#c1c1c1;
  padding: 26px 20px;
  font-size: 1.2rem;
}
.leftColMainContent {
  overflow-y:auto;
  flex-grow:1;
}
.outerContainerBoilerplate {
  width:100vw;
  max-height: calc(100vh - 80px);
}
.leftColumnBoilerPlate {
  height: calc(100vh - 80px);
  width:330px;
  background:#e1e1e1;
  background-color:#e1e1e1;
}
.mainSectionBoilerPlate {
  flex-grow: 1;
  min-height: calc(100vh - 80px);
  overflow-y: auto;
}
.mainSectionBoilerPlateInner {
  height:fit-content;
}
.leftColBottomButtonPanel {
  height:fit-content;
  padding: 11px;
  background:#d1d1d1;
  border-top: 1px solid #a1a1a1;
}
@media screen and (max-width: 991px) {
  .outerContainerBoilerplate {
    width:100vw;
  }
  .leftColumnBoilerPlate {
    height: calc(100vh - 80px);
    width:230px;
  }
}
</style>
