import { createPinia, PiniaVuePlugin } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
// Pinia
Vue.use(PiniaVuePlugin)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

import 'bootstrap-css-only/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'mdbvue/lib/mdbvue.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import VueCardFormat from 'vue-credit-card-validation'
import Vue from 'vue'
import App from './App.vue'
import router from '../src/router'
import Verte from 'verte'
import 'verte/dist/verte.css'
import * as rules from 'vee-validate/dist/rules'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import '../mdb/mdbvue/scss/mdb-pro.scss'
import '../src/common/styles.css'
import LoadScript from 'vue-plugin-load-script'

import * as mdbvue from 'mdbvue'
import { PaginationPlugin, BTable, BCol, BFormGroup, BInputGroup, BButton, BInputGroupAppend, BFormInput, VBToggle, SidebarPlugin, BRow, BDropdown, BDropdownDivider, BDropdownItemButton } from 'bootstrap-vue'
import VueFilterDateFormat from 'vue-filter-date-format'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

// version 6 font awesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import FontAwesomeLibrary from '@/utils/fontawesomeImports.js'

library.add(FontAwesomeLibrary)
// avatar
Vue.use(Cropper)

Vue.use(LoadScript)

// TIME PICKER
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
Vue.component('VueTimepicker', VueTimepicker)

// font awesome
Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)

Vue.use(mdbvue.Notify)
Vue.use(VueCardFormat)

var MDBFileUpload = require('mdb-vue-file-upload')
Vue.use(MDBFileUpload)

for (const component in mdbvue) {
  Vue.component(component, mdbvue[component])
}

import VueObserveVisibility from "vue-observe-visibility";
Vue.use(VueObserveVisibility);

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('Verte', Verte)
Vue.use(PaginationPlugin)
Vue.use(SidebarPlugin)
Vue.component('BTable', BTable)
Vue.component('BCol', BCol)
Vue.component('BRow', BRow)
Vue.component('BFormGroup', BFormGroup)
Vue.component('BFormInput', BFormInput)
Vue.component('BInputGroup', BInputGroup)
Vue.component('BButton', BButton)
Vue.component('BInputGroupAppend', BInputGroupAppend)
Vue.component('BToggle', VBToggle)
Vue.component('BDropdown', BDropdown)
Vue.component('BDropdownDivider', BDropdownDivider)
Vue.component('BDropdownItemButton', BDropdownItemButton)

Vue.use(VueFilterDateFormat)

Vue.config.productionTip = false
new Vue({
  pinia,
  router,
  render: h => h(App)
}).$mount('#app')
