import { computed, ref, watch } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useUserData } from './useUserData'

import customEmailService from '@/api/services/customEmail.service'

import moment from 'moment'

export const useCustomEmails = defineStore('customEmailStore', () => {
  const userDataStore = useUserData()
  const { userData } = storeToRefs(userDataStore)

  // used in the email template dropwdown
  const emailTypeDropdown = ref([])

  if (userData.value.one === true && userData.value.liv === true && userData.value.eve === true) {
    emailTypeDropdown.value = ref([
      { text: 'One-Way Invite', value: 1  },
      { text: 'Live Interview Invite', value: 2  },
      { text: 'Scheduler Invite', value: 3  }
    ])
  }

  if (userData.value.one === true && userData.value.liv === false && userData.value.eve === true) {
    emailTypeDropdown.value = ref([
      { text: 'One-Way Invite', value: 1  },
      { text: 'Scheduler Invite', value: 3  }
    ])
  }

  if (userData.value.one === true && userData.value.liv === true && userData.value.eve === false) {
    emailTypeDropdown.value = ref([
      { text: 'One-Way Invite', value: 1  },
      { text: 'Live Interview Invite', value: 2  },
    ])
  }

  if (userData.value.one === true && userData.value.liv === false && userData.value.eve === false) {
    emailTypeDropdown.value = ref([
      { text: 'One-Way Invite', value: 1  }
    ])
  }

  if (userData.value.one === false && userData.value.liv === true && userData.value.eve === true) {
    emailTypeDropdown.value = ref([
      { text: 'Live Interview Invite', value: 2  },
      { text: 'Scheduler Invite', value: 3  }
    ])
  }

  if (userData.value.one === false && userData.value.liv === false && userData.value.eve === true) {
    emailTypeDropdown.value = ref([
      { text: 'Scheduler Invite', value: 3  }
    ])
  }

  if (userData.value.one === false && userData.value.liv === true && userData.value.eve === false) {
    emailTypeDropdown.value = ref([
      { text: 'Live Interview Invite', value: 2  }
    ])
  }

  // which email template the user is editing
  const selectedEmailTypeDropdown = computed(() => {
    try {
      return emailTypeDropdown.value.filter(s => s.selected)[0].value
    } catch { return 0 }
  })

  // this is a base default row formatting required for the email package
  const setTextForDefault = (text, id, editable) => {
    return {
      cells: [1],
      columns: [
        {
          contents: [
            {
              type: "text",
              values: {
                containerPadding: "10px",
                textAlign: "left",
                lineHeight: "140%",
                linkStyle: {
                  inherit: true,
                  linkColor: "#0000ee",
                  linkHoverColor: "#0000ee",
                  linkUnderline: true,
                  linkHoverUnderline: true,
                },
                _meta: {
                  htmlID: `u_content_text_${id}`,
                  htmlClassNames: `u_content_text_${id}`,
                },
                selectable: id == 'button' ? true : editable,
                draggable: false,
                duplicatable: editable,
                deletable: editable,
                hideable: editable,
                text: text
              }
            },
          ],
          values: {
            _meta: {
              htmlID: `u_column_${id}`,
              htmlClassNames: `u_column_${id}`,
            },
            border: {},
            padding: "0px",
            backgroundColor: "",
          },
        },
      ],
      values: {
        displayCondition: null,
        columns: false,
        backgroundColor: "",
        columnsBackgroundColor: "",
        backgroundImage: {
          url: "",
          fullWidth: true,
          repeat: false,
          center: true,
          cover: false,
        },
        padding: "0px",
        hideDesktop: false,
        _meta: {
          htmlID: `u_row_${id}`,
          htmlClassNames: `u_row_${id}`,
        },
        selectable: id == 'button' ? true : editable,
        draggable: false,
        duplicatable: editable,
        deletable: editable,
        hideable: editable,
      }
    }
  }

  // gives us a default bold styling
  const customText = (text) => {
    return `
      <strong style="font-weight:bold !important;>${text}</strong>
    `
  }

  const setHeader = ref(
    setTextForDefault(`
      <div style="background:${userData.value.lbl.PrimaryColour};padding:10px;-moz-border-radius: 10px;-webkit-border-radius: 10px;border-radius: 10px; border: solid 1px #ff7e00;" align="center">
        <img src="${userData.value.lbl.Logo}" alt='${userData.value.ane}' style="width:115px !important;margin:auto;" />
      </div>
      `,
      'header',
      false
    )
  )

  const setFooter = computed(() => {
    return setTextForDefault(`
      <div style="font-size:11px;">
        ${footerTechnicalSupportDetails.value}
      </div>
      `,
      'footer',
      false
    )
  })

  const footerTechnicalSupportDetails = computed(() => {
    switch (selectedEmailTypeDropdown.value) {
      case 1:
        return `<div>
                  <span>
                    By clicking the above link to join the video interview you consent to participate in a video interview, and to Shine using your data as part of this recruitment
                    process, and confirm that you agree with Shine’s full terms which can be viewed at
                    <a href="https://www.shineinterview.com/privacy-policy" target="_blank" style="text-decoration:none; color:#555;">
                      <u>https://www.shineinterview.com/privacy-policy</u>. Powered by <a href="http://www.shineinterview.com" target="_blank" style="text-decoration:none; color:#555;">
          <u>www.shineinterview.com</u></a> &copy; <span class="to_add_date_and_account_name">${`${moment().format('Do MMM YYYY hh:mm')}`} ${userData.value.ane}</span>
                    </a>.
                  </span>
                </div>`
      case 2:
        return `<div>
                    By clicking the above link to join the video interview you consent to participate in a video interview, and to Shine using your data as part of this recruitment
                    process, and confirm that you agree with Shine’s full terms which can be viewed at
                    <a href="https://www.shineinterview.com/privacy-policy" target="_blank" style="text-decoration:none; color:#555;">
                      <u>https://www.shineinterview.com/privacy-policy</u>. Powered by <a href="http://www.shineinterview.com" target="_blank" style="text-decoration:none; color:#555;">
          <u>www.shineinterview.com</u></a> &copy; <span class="to_add_date_and_account_name">${`${moment().format('Do MMM YYYY hh:mm')}`} ${userData.value.ane}</span>
                    </a>.
                  </span>
                </div>`
      case 3:
        return `<div>By clicking the above link you consent to Shine using your data as part of this recruitment process, and confirm that you agree with Shine’s full terms
          which can be viewed at <a href="https://www.shineinterview.com/privacy-policy" target="_blank" style="text-decoration:none; color:#555;"><u>https://www.shineinterview.com/privacy-policy</u></a>.           Powered by <a href="http://www.shineinterview.com" target="_blank" style="text-decoration:none; color:#555;">
          <u>www.shineinterview.com</u></a> &copy; <span class="to_add_date_and_account_name">${`${moment().format('Do MMM YYYY hh:mm')}`} ${userData.value.ane}</span></div>`
      default:
        return ''
    }
  })


  // we need to replce the created html content with the variable names we will replace backend
  const replaceFooterContentWithVariables = (htmlString) => {
    var replacementContent = "{{current_time}} {{accName_and_jobId}}"
    var parser = new DOMParser()
    var doc = parser.parseFromString(htmlString, 'text/html')
    var element = doc.querySelector('.to_add_date_and_account_name');
    if (element) {
        element.innerHTML = replacementContent;
    }
    return doc.documentElement.outerHTML;
}

  // EDITABLE TEXT DEFAULTS
  const editableTextWrapper = (string) => {
    return `<div
        class="shine_go_editable_text"
        style="font-family: 'Raleway', Arial, Helvetica, sans-serif; font-weight: normal; color:#555;font-size:14px;">
        ${string}
        </div>
    `
  }
  const oneWayInviteDefaultText = ref(
    setTextForDefault(
      editableTextWrapper(`
        <div>Hello ${customText('{{first_name}}')},</div>
        <br/>
        <div>Congratulations, you've been selected to complete a video interview for the position of ${customText('{{role_name}}')}</div>.
        <br/>
        <div>Your personal invite code is <strong style="font-weight:bold;">${customText('{{invite_code}}')}</strong>. You can record your responses on your computer, smartphone or tablet. Simply copy your invite code from this email, and then click on the button below to get started.</div>
      `),
      'one_way_invite',
      true
    )
  )
  const twoWayInviteDefaultText = ref(
    setTextForDefault(
      editableTextWrapper(`
        <div>Hello ${customText('{{first_name}}')},</div>
          <br />
          <div>You have been invited to participate as {{two_way_meeting_attendee_type}} in an online meeting room (${customText('{{two_way_meeting_name}}')}), for the position of ${customText('{{role_name}}')}</div>
          <br />
          <div>This will take place on ${customText('{{date}}')} at ${customText('{{time}}')}</div>
          <br />
        <div>Your personal invite code is  <strong style="font-weight:bold;">${customText('{{invite_code}}')}</strong>. When you are ready to join please click the button below. </div>
      `),
      'two_way_invite',
      true
    )
  )
  const schedulerInviteDefaultText = ref(
    setTextForDefault(
      editableTextWrapper(`
        <div>Hello ${customText('{{first_name}}')},</div>
          <br />
        <div>You have been invited to book a time slot with ${customText('{{account_name}}')}, relating to the role ${customText('{{role_name}}')}.</div>
          <br />
        <div>Please note you have until ${customText('{{date}}')} to book your slot.</div>
          <br />
        <div>Click the button below to get started. </.div>
      `),
      'scheduler_invite',
      true
    )
  )

  // BUTTON DEFAULTS
  const buttonWrapper = (string) => {
    return `<div
              style="text-decoration: none; min-width: 30px; width: fit-content; color: #fff !important; background: ${userData.value.lbl.PrimaryColour} !important; border: none; border-radius: 10px; padding: 8px 21px; font-weight: bold; font-size: 1rem"
              class="shine_go_button_text shine_go_required">
              ${string}
            </div>`
  }

  const button = ref(setTextForDefault(buttonWrapper('GET STARTED'), 'button', false ))

  const wrapButtonContentWithATag = (htmlString) => {
    var parser = new DOMParser()
    var doc = parser.parseFromString(htmlString, 'text/html')
    var element = doc.querySelector('.shine_go_button_text')
    if (element) {
        var innerContent = element.innerHTML
        var aTag = `<a href="{{button_url}}" target="_blank" style="text-decoration: none; min-width: 30px; color: #fff !important; background: ${userData.value.lbl.PrimaryColour} !important; border: none; border-radius: 10px; padding: 8px 21px; font-weight: bold; font-size: 1rem;">${innerContent}</a>`
        element.innerHTML = aTag
    }
    return doc.documentElement.outerHTML
  }


  // based on the selected email template we construct a whole email for the email package
  const selectedEmailTemplate = computed(() => {
    switch (selectedEmailTypeDropdown.value) {
      case 1:
        return [ setHeader.value, oneWayInviteDefaultText.value, button.value, setFooter.value ]
      case 2:
        return [ setHeader.value, twoWayInviteDefaultText.value, button.value, setFooter.value ]
      case 3:
        return [ setHeader.value, schedulerInviteDefaultText.value, button.value, setFooter.value ]
      default:
        return []
    }
  })



  // PARSING OUT SAVED TEMPLATES
  // this parses out the edited text from the saved html and converts it back to a format suitable for the email editor
  const getEditableTextAsString = (htmlString, className = "shine_go_editable_text") => {
    let parser = new DOMParser()
    let doc = parser.parseFromString(htmlString, 'text/html')
    let targetDivs = doc.querySelectorAll(`.${className}`)

    if (targetDivs.length === 0) {
      return null;
    }

    let resultString = ''

    targetDivs.forEach(targetDiv => {
      let childNodes = targetDiv.childNodes
      let childContent = ''

      childNodes.forEach(node => {
        childContent += node.outerHTML || node.textContent;
      })

      resultString += editableTextWrapper(childContent)
    })

    return resultString;
  }

  const getButtonTextFromHtmlString = (htmlString, className = "shine_go_button_text") => {
    let parser = new DOMParser()
    let doc = parser.parseFromString(htmlString, 'text/html')
    let targetElement = doc.querySelector(`.${className}`)
    if (!targetElement) { return null }
    return targetElement.textContent
  }

  // if there is an existing html saved we need to parse it and pass it back to the editor
  const savedTemplate = ref(null)
  const savedButton = ref(null)
  const savedEmailTemplate = (html) => {
    savedButton.value = setTextForDefault(buttonWrapper(getButtonTextFromHtmlString(html)), 'button', false )
    switch (selectedEmailTypeDropdown.value) {
      case 1:
        savedTemplate.value = setTextForDefault(getEditableTextAsString(html), 'one_way_invite', true)
        return [ setHeader.value, savedTemplate.value, savedButton.value, setFooter.value ]
      case 2:
        savedTemplate.value = setTextForDefault(getEditableTextAsString(html), 'two_way_invite', true)
        return [ setHeader.value, savedTemplate.value, savedButton.value, setFooter.value ]
      case 3:
        savedTemplate.value = setTextForDefault(getEditableTextAsString(html), 'scheduler_invite', true)
        return [ setHeader.value, savedTemplate.value, savedButton.value, setFooter.value ]
      default:
        return []
    }
  }


  // MERGE TAG LOGIC
  // this is the formatting we pass to the editor with our predefined variabkle placeholders - we will replace these strings backend
  const mergeTags = ref({
    first_name: { name: "First Name", text: "First Name", value: "{{first_name}}", sample: "John" },
    last_name: { name: "Second Name", text: "Second Name", value: "{{second_name}}", sample: "Smith" },
    role_name: { name: "Role Name", text: "Role Name", value: "{{role_name}}", sample: "Software Engineer" },
    invite_code: { name: "Invite Code", text: "Invite Code", value: "{{invite_code}}", sample: "XYZ" },
    two_way_meeting_attendee_type: { name: "Meeting Attendee Type", text: "Meeting Attendee Type", value: "{{two_way_meeting_attendee_type}}", sample: "a candidate" },
    two_way_meeting_name: { name: "Meeting Name", text: "Meeting Name", value: "{{two_way_meeting_name}}", sample: "Company Meeting Room" },
    date: { name: "Date", text: "Date", value: "{{date}}", sample: moment().format('Do MMMM') },
    time: { name: "Time", text: "Time", value: "{{time}}", sample: moment().format('HH:mm') },
    account_name: { name:  userData.value.ane, text:  userData.value.ane, value: "{{account_name}}", sample: userData.value.ane }
  })

  const currentMergeTags = ref({})
  const setMergeTagsAndButtonText = () => {
    switch (selectedEmailTypeDropdown.value) {
      case 1:
        currentMergeTags.value = { account_name: mergeTags.value.account_name, first_name: mergeTags.value.first_name, last_name: mergeTags.value.last_name, role_name: mergeTags.value.role_name, invite_code: mergeTags.value.invite_code }
        button.value = setTextForDefault(buttonWrapper('GET STARTED'), 'button', false )
        break;
      case 2:
        button.value = setTextForDefault(buttonWrapper('GET STARTED'), 'button', false )
        currentMergeTags.value = { account_name: mergeTags.value.account_name, first_name: mergeTags.value.first_name, last_name: mergeTags.value.last_name, role_name: mergeTags.value.role_name, invite_code: mergeTags.value.invite_code, two_way_meeting_attendee_type: mergeTags.value.two_way_meeting_attendee_type, two_way_meeting_name: mergeTags.value.two_way_meeting_name, date: mergeTags.value.date, time: mergeTags.value.time }
        break;
      case 3:
        button.value = setTextForDefault(buttonWrapper('BOOK NOW'), 'button', false )
        currentMergeTags.value = { account_name: mergeTags.value.account_name, first_name: mergeTags.value.first_name, last_name: mergeTags.value.last_name, role_name: mergeTags.value.role_name, date: mergeTags.value.date }
        break;
      default:
        currentMergeTags.value = {}
    }
  }
  watch(selectedEmailTypeDropdown, () => {
    setMergeTagsAndButtonText()
  }, { immediate: true })


  // SUBJECT LINE
  const subjectLineVariableWrapper = (string) => {
    return `<div contenteditable="false" class="subject-line-variable" style="cursor: pointer; border: 1.8px lightblue dashed; padding: 3px 5px; width: fit-content; display: inline; border-radius: 5px; ">${string}</div>`
  }
  const replaceSubjectLineVariablesWithValues = htmlString => {
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = htmlString
    const variableDivs = tempDiv.querySelectorAll('.subject-line-variable')
    variableDivs.forEach(div => {
      const innerText = div.innerText
      const matchingTag = Object.values(mergeTags.value).find(tag => tag.text === innerText)

      if (matchingTag) {
        const newTextNode = document.createTextNode(matchingTag.value)
        div.parentNode.replaceChild(newTextNode, div)
      }
    })
    return tempDiv.innerHTML
  }
  const replaceValuesWithSubjectLineVariables = htmlString => {
    Object.values(mergeTags.value).forEach(tag => {
      const regex = new RegExp(tag.value, 'g')
      htmlString = htmlString.replace(regex, subjectLineVariableWrapper(tag.text))
    })
    return htmlString
  }



  // API CALL
  const saveCustomEmail = async (JSON) => {
    try {
      return await customEmailService.saveCustomEmail(JSON)
    } catch (err) {
      console.log(Object.assign({}, err))
    }
  }
  const getCustomEmail = async (JSON) => {
    try {
      return await customEmailService.getCustomEmail(JSON)
    } catch (err) {
      console.log(Object.assign({}, err))
    }
  }

  return {
    setHeader,
    setFooter,
    oneWayInviteDefaultText,
    button,
    emailTypeDropdown,
    selectedEmailTemplate,
    selectedEmailTypeDropdown,
    currentMergeTags,
    saveCustomEmail,
    getCustomEmail,
    savedEmailTemplate,
    replaceFooterContentWithVariables,
    wrapButtonContentWithATag,
    subjectLineVariableWrapper,
    replaceSubjectLineVariablesWithValues,
    replaceValuesWithSubjectLineVariables
  }

})
