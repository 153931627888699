import axiosInstance from '../instance.js'
const api2 = new axiosInstance(2)

export default class candidateV2Service {
  static async followCandidate (candidateId) {
    return api2.post(`/api/notification_subscription/follow_candidate/${candidateId}`)
  }

  // unfollow candidate
  static async unFollowCandidate (candidateId) {
    return api2.post(`/api/notification_subscription/unfollow_candidate/${candidateId}`)
  }

  // get candidates
  static async getCandidates (SearchModel) {
    return api2.post('/api/candidate_account/get_all/search', SearchModel)
  }

  static async shareCandidates (JSONData) {
    return await api2.post('/api/sharing/share', JSONData)
  }

  static async quickShareCandidates (data) {
    return await api2.post('/api/sharing/quick_share', data)
  }

  // CANDIDATE PROFILE AREA
  // get candidate history
  static async getCandidateHistory (candidateId) {
    return await api2.get(`/api/candidate_account/get_candidate_history?candidateId=${candidateId}`)
  }

  // CUSTOM FORM FIELDS
  static async getCustomFieldList () {
    return await api2.get('/api/custom_candidate_fields/list_custom_field_elements')
  }
  static async addCustomFormField (JSON) {
    return await api2.post('/api/custom_candidate_fields/add_custom_field_element', JSON)
  }
  static async editCustomFormField (JSON) {
    return await api2.post('/api/custom_candidate_fields/edit_custom_field_element', JSON)
  }
  static async deleteCustomFormField (JSON) {
    return await api2.delete('/api/custom_candidate_fields/delete_custom_field_element?id=' + JSON.CustomFieldElementId)
  }
  static async getCustomFieldValues (candAccId) {
    return await api2.get(`/api/custom_candidate_fields/list_custom_field_values?candidateAccountId=${candAccId}`)
  }
  static async saveCustomFieldValues (JSON) {
    return await api2.post('/api/custom_candidate_fields/save_custom_field_values', JSON)
  }
}
