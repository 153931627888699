<template>
  <div
  >
    <h6
      v-if="showResendConfirmationEmail"
      @click="resendTwoWayConfirmationEmailLocal(data.item)"
      :title="isSelfSchedule ? 'Resend this candidate the timeslot booking confirmation email' : 'Resend this candidate their meeting invite email'"
    >
      <i class="fa fa-envelope ml-1 grey-link" style="margin-right:7px;" />
      <a class="grey-link"> {{ !isSelfSchedule ? 'Resend Invite Email' : 'Resend Confirmation Email' }} </a>
    </h6>

    <h6 v-if="mobileView">
      <i class="fa fa-calendar ml-1 grey-link" style="margin-right:7px;" />
      <a class="grey-link">Date invited: {{ new Date(data.item.DateInvited).toLocaleDateString([userLanguage]) }}</a>
    </h6>
    <h6 v-if="mobileView && stageItemStatus >= 6">
      <i class="fa fa-calendar ml-1 grey-link" style="margin-right:7px;" />
      <a class="grey-link">Date completed: {{ new Date(data.item.DateCompleted).toLocaleDateString([userLanguage]) }}</a>
    </h6>
    <h6 v-if="mobileView">
      <i class="fa fa-calendar ml-1 grey-link" style="margin-right:7px;" />
      <a class="grey-link">Date of interview: {{ new Date(data.item.InterviewDate).toLocaleString(undefined, { day: 'numeric', month: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit'}) }}</a>
    </h6>

    <CopyToClipboardPopoverButton
      v-for="(button,i) in stringToCopy"
      :key="i"
      :string-to-copy="button.url"
      :button-text="button.text"
    />

    <!-- EXTEND DEADLINE -->
    <h6
      v-if="showExtendInviteDeadline"
      @click="extendInviteDeadlineLocal(data.item)"
    >
      <FontAwesomeIcon
        icon="fa-solid fa-arrow-right-to-line"
        class="grey-link ml-1"
        style="margin-right:7px;"
      />
      <a class="grey-link">Extend Deadline</a>
    </h6>

    <!--  -->
    <QuickSharePopoverButton
      v-if="showQuickShare"
      :role-id="role.JobId"
      :RoleWorkflowStageType="stageType"
      :RoleWorkflowStageId="selectedWorkflowStage.RoleWorkflowStageId"
      :candidate-id="data.item.CandidateId"
      @closePopover="$refs.popover.doClose()"
    />


    <!-- UNINVITE -->
    <h6
      v-if="showUninvite"
      @click="uninviteSingleAndDeleteInterview(data.item)">
      <i class="fa fa-times ml-1 grey-link" style="margin-right:10px;" />
      <a class="grey-link"> Uninvite</a>
    </h6>


    <!-- RESET FULL INTERVIEW -->
    <!-- both of these actually do the same this but the wording is different depending if completed or not -->
    <h6
      v-if="showRescheduleThroughAdmin || showResetThroughAdmin"
      @click="resetInterviewLocal(data.item)"
    >
      <i class="fa fa-sync ml-1 grey-link" style="margin-right:7px;" />
      <a class="grey-link"> {{ showRescheduleThroughAdmin ? 'Reschedule' : 'Reset' }} </a>
    </h6>


    <!-- OPEN THE SCHEDULER -->
    <h6
      v-if="showRescheduleThroughScheduler"
      @click="openScheduler"
    >
      <i class="fa fa-calendar ml-1 grey-link" style="margin-right:7px;" />
      <a class="grey-link"> Open Scheduler </a>
    </h6>


    <!-- RESET ROOM - taken from shine -->
    <h6
      v-if="stageType === 2 && (stageItemStatus === 3 || stageItemStatus === 5) && (userData.iss || userData.rle == 'Admin')"
      @click="resetTwoWayRoomLocal(data.item)"
    >
      <i class="fa fa-sync ml-1 grey-link" style="margin-right:7px;" />
      <a class="grey-link"> Reset Room</a>
    </h6>


    <!-- RESET SCHEDULER INVITE -->
    <h6
      v-if="showResetSchedulerInvite"
      @click="resetSchedulerInviteLocal(data.item)"
      title="Delete the booking and send new scheduler invite"
    >
      <i class="fa fa-sync ml-1 grey-link" style="margin-right:7px;" />
      <a class="grey-link"> Reset Scheduler Invite</a>
    </h6>


  </div>

</template>

<script>
import { computed } from 'vue'
import { useUserData, useStatus, useRoleCandidate, useWorkflow, useScheduler } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import { RNYID, RAFID } from '@/common/config.js'

import QuickSharePopoverButton from '@/components/misc/quickSharePopoverButton.vue'
import CopyToClipboardPopoverButton from '@/components/misc/copyToClipboardPopoverButton.vue'
import roleV2Service from '@/api/services/roleV2.service'

import { SCHEDULER_URL, MEET_URL } from '@/common/config.js'
import moment from 'moment'

export default {
  name: 'TwoWayPopoverButton',
  components: {
    QuickSharePopoverButton,
    CopyToClipboardPopoverButton
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    const { mobileView } = useMobile()

    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const statusStore = useStatus()
    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = statusStore

    const roleCandidateStore = useRoleCandidate()
    const { candidateTableData, candidate, selectedCandidates, removeCandidatesFromTable, meetingDateModal, resettingTwoWay, resendTwoWayConfirmationEmail, resetTwoWayRoom, confirmResetSchedulerInvite, resendSchedulerConfirmationEmail, extendInviteDeadlineModalShowing } = storeToRefs(roleCandidateStore)

    const workflowStore = useWorkflow()
    const { role, addRoleToRecentRoles, selectedWorkflowStage } = storeToRefs(workflowStore)

    const schedulerStore = useScheduler()
    const { selectedSchedulerSetup } = storeToRefs(schedulerStore)

    const stageType = computed(() => candidateTableData?.value?.StageType)
    const stageItemStatus = computed(() => candidateTableData?.value?.StageItemStatus)

    const isSelfSchedule = computed(() => selectedWorkflowStage.value.IsSelfSchedule)
    const meetingIsToday = computed(() => moment(props.data.item.BookingDate).isSame(moment(), 'day') || moment(props.data.item.InterviewDate).isSame(moment(), 'day'))
    const meetingIsInPast = computed(() => moment().isAfter(moment(props.data.item.BookingDate), 'minute') || moment().isAfter(moment(props.data.item.InterviewDate), 'minute'))
    const isRNUser = computed(() => (userData.value.mid === RNYID || userData.value.mid === RAFID) && userData.value.rle == 'User')

    // Return popover text and urls
    const stringToCopy = computed(() => {
      var linksToCopy = []
      // ASSESSOR LINKS
      if (stageItemStatus.value == 3 && (!meetingIsInPast.value || meetingIsToday.value)) {
        linksToCopy.push(...[
          { url: `${MEET_URL}?inv=${props.data.item.HostLink}`, text: 'Copy Host Link' },
          { url: `${MEET_URL}?inv=${props.data.item.CandLink}`, text: 'Copy Candidate Link' }
        ])
        if (props.data.item.AssrLink) {
          linksToCopy.push({ url: `${MEET_URL}?inv=${props.data.item.AssrLink}`, text: 'Copy Assessor Link' })
        }
      }
      // SCHEDULER LINK
      if (isSelfSchedule.value && (stageItemStatus.value == 2 || stageItemStatus.value == 3) && !isRNUser.value) {
        linksToCopy.push({ url: `${SCHEDULER_URL}/${selectedSchedulerSetup.value.ScheduleUrl}?id=${props.data.item.SchedulerInvitationCode}&gu=${userData.value.uid}`, text: 'Copy Scheduler Link' })
      }
      return linksToCopy
    })

    const showUninvite = computed(() => !isRNUser.value && stageItemStatus.value < 6)
    // RN RESTRICTED - full interview reset
    const showResetThroughAdmin = computed(() => stageItemStatus.value >= 6 && !isRNUser.value)
    const showRescheduleThroughAdmin = computed(() => !isSelfSchedule.value && meetingIsToday.value && stageItemStatus.value == 3 && !isRNUser.value)
    const showRescheduleThroughScheduler = computed(() => isSelfSchedule.value && stageItemStatus.value == 3 && (meetingIsToday.value || meetingIsInPast.value) && !isRNUser.value)
    const showQuickShare = computed(() => stageItemStatus.value >= 6)

    const showResendConfirmationEmail = computed(() => ((meetingIsToday.value || (!meetingIsToday.value && !meetingIsInPast.value)) && stageItemStatus.value != 2))
    const showResetSchedulerInvite = computed(() => isSelfSchedule.value && (meetingIsToday.value || (!meetingIsToday.value && !meetingIsInPast.value)) && stageItemStatus.value == 3)
    const showExtendInviteDeadline = computed(() => !isRNUser.value && stageItemStatus.value == 2 && !role.value.IsEvergreen)

    return {
      authData,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal,
      candidateTableData,
      role,
      candidate,
      selectedCandidates,
      mobileView,
      SCHEDULER_URL,
      MEET_URL,
      addRoleToRecentRoles,
      removeCandidatesFromTable,
      resendTwoWayConfirmationEmail,
      stageType,
      stageItemStatus,
      stringToCopy,
      meetingDateModal,
      resettingTwoWay,
      selectedWorkflowStage,
      userData,
      resetTwoWayRoom,
      RNYID,
      RAFID,
      selectedSchedulerSetup,
      showUninvite,
      showResetThroughAdmin,
      showRescheduleThroughAdmin,
      showRescheduleThroughScheduler,
      showQuickShare,
      showResendConfirmationEmail,
      isSelfSchedule,
      confirmResetSchedulerInvite,
      showResetSchedulerInvite,
      resendSchedulerConfirmationEmail,
      showExtendInviteDeadline,
      extendInviteDeadlineModalShowing
    }
  },
  methods: {
    extendInviteDeadlineLocal (candidate) {
      this.candidate = candidate
      this.extendInviteDeadlineModalShowing = true
    },
    async resendTwoWayConfirmationEmailLocal (candidate) {
      this.candidate = candidate
      if (!this.isSelfSchedule) {
        await this.resendTwoWayConfirmationEmail(candidate)
      } else {
        await this.resendSchedulerConfirmationEmail(candidate, this.data.item.SchedulerInvitationCode, () => this.$emit('updateComponentContext'))
      }
    },
    async resetTwoWayRoomLocal (candidate) {
      this.candidate = candidate
      this.resetTwoWayRoom(candidate)
    },
    async resetInterviewLocal (candidate) {
      this.candidate = candidate
      if (!this.isSelfSchedule) {
        this.candidate = candidate
        this.meetingDateModal = true
        this.resettingTwoWay = true
      } else {
        // if it is self schedule we dont need to use the date time modal but we do need to additionally reset the scheduler invite
        const JSONData = {
          CandidateId: this.candidate.CandidateId,
          RoleWorkflowStageId: this.selectedWorkflowStage.RoleWorkflowStageId,
          MeetingDate: null,
          MeetingTime: null,
          SelectedHost: null,
          SelectedAssessors: null,
          RoleId: this.role.JobId
        }
        this.showGenericConfirmModal({
          showing: true,
          confirmTitle: 'RESET',
          confirmMessage:`Are you sure you want to reset this candidate? <br /><br />
            <span class="font-weight-bold">Note: </span>
            <br /> <br /> Data associated with the candidates interview attempt will be deleted. They will be invited to book a new slot.` ,
          confirmButtonMessage: 'RESET',
          confirmButtonFunction: async () => {
            await roleV2Service.resetTwoWayStageInterview(JSONData)
            await this.confirmResetSchedulerInvite(this.candidate.SchedulerInvitationCode, () => this.$emit('getRoleCandidates'))
          }
        })
      }
    },
    async resetSchedulerInviteLocal (candidate) {
      await this.resetSchedulerInvite(candidate.SchedulerInvitationCode, () => this.$emit('getRoleCandidates'))
    },
    async uninviteSingleAndDeleteInterview (candidate) {
      this.candidate = candidate
      this.selectedCandidates = []
      this.selectedCandidates.push(candidate.CandidateId)
      const message = `Are you sure you want to uninvite this candidate? <br /><br />
        <span class="font-weight-bold">Note: </span>
        <br /> <br /> Data associated with the candidates interview attempt will be deleted.`
      const title = 'CONFIRM UNINVITE'
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: title,
        confirmMessage: message,
        confirmButtonMessage: 'UNINVITE',
        confirmButtonFunction: async () => this.$emit('confirmUninviteSelected', candidate.CandidateId)
      })
    },
    openScheduler () {
      window.open(SCHEDULER_URL + '/' + this.selectedSchedulerSetup.ScheduleUrl + '?id=' + this.data.item.SchedulerInvitationCode + '&gu=' + this.userData.uid)
    }
  }
}
</script>
