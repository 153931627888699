<template>
  <div class="flex flex-col">
    <!-- LOCATION TYPE TOGGLE -->
    <div class="d-flex align-items-center mt-5">
      <font-awesome-icon
        :icon="['fas', 'location-dot']"
        class="mr-3 primary-colour"
        style="cursor:help;scale: 1.3"
      />
      <div class="mr-3 grey-text" style="font-size:clamp(0.9rem, 2vw, 1rem);">
        Location Type
      </div>
      <span class="d-flex align-items-center grey-text mt-1">
        <mdb-switch
          v-model="onlineEvent"
          :disabled="preventEditing"
          style="transform:scale(0.9)"
          off-label=""
          on-label=""
          class="m-0 ml-auto"
        />
        <span class="ml-2" style="font-size:clamp(0.8rem, 2vw, 0.9rem)">{{ onlineEvent ? 'Online' : 'Physical' }}</span>
      </span>
    </div>
    <span
      v-if="preventEditing"
      class="text-left dark-grey-text font-weight-bold text-sm my-3"
    >
      As timeslots have already been created for this stage you are prevented from editing the Location Type.
    </span>
    <div
      v-if="!onlineEvent"
      class="flex flex-col items-center w-full"
    >
      <div class="flex flex-row items-center w-full">
        <mdb-select
          v-model="locationsDropdown"
          multiple
          select-all
          class="w-2/3"
          placeholder="Location"
          :disabled="!locationsDropdown.length"
        />
        <mdb-btn
          class="btn text-center flex ml-auto"
          size="sm"
          @click="addEventLocationForm = !addEventLocationForm"
        >
          ADD LOCATION
        </mdb-btn>
      </div>

      <div
        v-if="locationsDropdown.length > 0"
        class="w-full my-3"
      >
        <LocationTable />
      </div>
    </div>

    <div
      v-else
      class="w-full"
    >
      <div
        class="flex flex-row items-center"
        style="padding-top:10px;"
      >
        <mdb-input
          v-model="selectedEvent.CustomUrl"
          class="w-full"
          placeholder="Event Url"
          label="Event Url"
          style="padding-top:10px;"
        />
      </div>
    </div>

    <AddLocation v-if="addEventLocationForm && onlineEvent === false" />

    <div class="flex flex-row mt-4">
      <mdb-btn
        class="btn btn-outline text-center flex mr-auto"
        size="sm"
        @click="$emit('cancel')"
      >
        CANCEL
      </mdb-btn>
      <mdb-btn
        :disabled="formInvalid"
        class="btn text-center flex ml-auto"
        size="sm"
        @click="$emit('next')"
      >
        NEXT
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue"
import { useEvent, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'

import AddLocation from '@/views/events/form/location/addLocation.vue'
import LocationTable from '@/views/events/form/location/locationTable.vue'

export default {
  name: 'LocationEventSetup',
  components: {
    AddLocation,
    LocationTable
  },
  setup () {
    const eventStore = useEvent()
    const {
      addEventLocationForm,
      locationsDropdown,
      selectedLocation,
      selectedEvent,
    } = storeToRefs(eventStore)
    const workflowStore = useWorkflow()
    const { selectedWorkflowStage } = storeToRefs(workflowStore)

    const formInvalid = computed(() => (!selectedLocation.value.length && !onlineEvent.value))
    const onlineEvent = ref(false)
    if (selectedEvent.value.CustomUrl) onlineEvent.value = true

    const toggleOnlineEvent = () => {
      if (onlineEvent.value) {
        // toggling to online event
        if (!selectedEvent.value.CustomUrl) selectedEvent.value.CustomUrl = 'https://meet.shineinterview.com'
        locationsDropdown.value = locationsDropdown.value.map(x => ({ ...x, selected: false }))
      } else {
        // toggling to physical
        selectedEvent.value.CustomUrl = null
      }
    }

    watch(onlineEvent, () => {
      toggleOnlineEvent()
    })

    const preventEditing = ref(selectedWorkflowStage.value.SelfScheduleSetupComplete)

    return {
      addEventLocationForm,
      onlineEvent,
      locationsDropdown,
      selectedLocation,
      formInvalid,
      selectedEvent,
      toggleOnlineEvent,
      preventEditing
    }
  }
}
</script>
