import axiosInstance from '../instance.js'
const api1 = new axiosInstance(1)
const api2 = new axiosInstance(2)

export default class notificationService {
  static async getAllUserNotifications (justNewNotifications) {
    return await api1.get(`/api/go/getallusernotifications?justNewNotifications=${justNewNotifications}`)
  }

  static async dimissNotifications (notificationIds) {
    return await api1.post(`/api/go/dismissnotifications`, notificationIds, {})
  }

  static async getNotificationSettings () {
    return await api1.get('/api/go/getnotificationsettings')
  }

  static async createEditNotificationSettings (formData) {
    return await api1.post('/api/go/createeditnotificationsettings', formData)
  }

  static async getMeetingCode (meetingId) {
    return await api1.get(`/api/go/getmeetingcodeforredirect?meetingId=${meetingId}`)
  }

  static async constructRedirectToViewInterviewURL (jobId, candidateId) {
    return await api2.get(`/api/notification_center/construct_redirect_to_interview_url?jobId=${jobId}&candidateId=${candidateId}`)
  }
}
