<template>
  <div>
    <mdb-icon
      v-if="loading"
      icon="fa fa-pulse fa-spinner"
      style="font-size:1.5rem"
    />
    <div
      v-else
      class="flex flex-col px-3 mt-3"
      style="min-height: 100% !important;position: relative"
    >
      <span
        class="text-left text-xs text-primary"
        style="margin-left:20px;color:#555 !important;"
      >
        Start time
      </span>
      <div class="flex flex-row items-center mt-2">
        <font-awesome-icon
          :icon="['far', 'calendar']"
          class="mr-2 text-primary"
        />
        <VueTimepicker
          v-model="schedulerSlot.StartTime"
          close-on-complete
          :minute-interval="5"
        />
      </div>
      <span
        class="text-left text-xs text-primary"
        style="margin-top:20px;margin-left:20px;color:#555 !important;"
      >
        End time
      </span>
      <div
        class="flex flex-row items-center mt-2"
        style="width:100%;"
      >
        <font-awesome-icon
          :icon="['far', 'calendar']"
          class="mr-2 text-primary"
        />
        <VueTimepicker
          v-model="schedulerSlot.EndTime"
          close-on-complete
          :minute-interval="5"
        />
      </div>
      <span
        class="text-left text-xs text-primary"
        style="margin-top:20px;margin-left:20px;color:#555 !important;"
      >
        Number of slots ( set to 0 for unlimited slots )
      </span>
      <div
        class="flex flex-row items-center"
        style="margin-top:-20px;"
      >
        <font-awesome-icon
          :icon="['fas', 'layer-group']"
          class="mr-2 text-primary"
        />
        <mdb-input
          v-model="schedulerSlot.NumberOfSlots"
          class="w-full"
          :min="0"
          :max="50"
          type="number"
          placeholder="0"
          @change="addRemoveSlot($event)"
        />
      </div>
      <div class="flex flex-col m-0">
        <div
          v-if="schedulerSlot.NumberOfSlots > 0"
          class="flex flex-row items-center cursor-pointer ml-4"
          @click="toggleLinkSlotsToUsers()"
        >
          <mdb-input
            v-model="schedulerSlot.LinkSlotsToUsers"
            type="checkbox"
            class="flex m-0 p-0"
            :disabled="!schedulerSlot.NumberOfSlots"
          />
          <span
            class="text-primary text-xs"
            style="color: #555 !important;"
          > Link slots to users?
          </span>
          <br>
        </div>
        <!-- This can be lots of slots? -->
        <div
          v-if="schedulerSlot.LinkSlotsToUsers"
          class="flex ml-5"
        >
          <div
            v-if="!users?.length"
            class="m-auto text-center"
          >
            No users
          </div>
          <div v-else-if="!loading && users?.length">
            <div
              v-for="(slot, index) in Number(schedulerSlot.NumberOfSlots)"
              :key="index"
              style="height: 60px;"
              class="flex items-center"
            >
              <span class="text-primary text-xs font-bold mr-3">
                SLOT {{ index + 1 }}
              </span>
              <mdb-select
                :key="key"
                v-model="users[index]"
                size="sm"
                style="min-width: 300px;"
                placeholder="Select User"
              />
            </div>
          </div>
          <mdb-icon
            v-else-if="loading"
            icon="fa fa-pulse fa-spinner"
          />
        </div>
        <!-- Day select, could this be a multi select? -->
        <div
          class="flex flex-col"
          style="margin-top:30px;"
        >
          <span
            style="font-size: 0.9rem"
            class="dark-grey-text text-left font-bold ">
            Slot Availability
          </span>
          <span
            class="text-left text-xs text-primary"
            style="margin-top:20px;margin-left:20px;color:#555 !important;"
          >
            Start date
          </span>
          <div
            class="w-full flex flex-row"
            style="margin-top:-20px;"
          >
            <font-awesome-icon
              :icon="['far', 'calendar']"
              class="mr-2 text-primary"
              style="margin-top:38px;"
            />
            <mdb-input
              v-model="schedulerSlot.StartDate"
              type="date"
              :min="minimumSlotDate"
              :max="maximumSlotDate"
              class="w-full"
              size="sm"
              placeholder="Start Date"
            />
          </div>
          <span
            class="text-left text-xs text-primary"
            style="margin-top:0px;margin-left:20px;color:#555 !important;"
          >
            End date
          </span>
          <div
            class="w-full flex flex-row"
            style="margin-top:-20px;"
          >
            <font-awesome-icon
              :icon="['far', 'calendar']"
              class="mr-2 text-primary"
              style="margin-top:38px;"
            />
            <mdb-input
              v-model="schedulerSlot.EndDate"
              type="date"
              :min="minimumSlotDate"
              :max="maximumSlotDate"
              class="w-full"
              size="sm"
              placeholder="End Date"
            />
          </div>
          <DaySelect
            class="w-full my-2 mb-5"
            @toggleDay="daysOfWeek = $event"
          />
        </div>
        <div
          v-if="selectedEvent?.CustomUrl === null && stageType == 3"
          class="text-left"
        >
          <span
            class="text-primary font-weight-bold"
            style="margin-top:20px;color:#555 !important; font-size: 0.9rem"
          >
            Location
          </span>
          <div
            class="flex flex-row items-center"
            style="margin-top:-20px;"
          >
            <font-awesome-icon
              :icon="['fas', 'location-dot']"
              class="mr-2 text-primary"
            />
            <mdb-select
              v-model="locationsDropdown"
              class="w-full"
              size="sm"
              placeholder="Please select"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!loading"
      class="flex py-4 px-4"
      style="position: sticky; bottom: 0px; left:10px; width: 100%; background: #fff;z-index: 3;border-top: 1px solid #c1c1c1"
    >
      <mdb-btn
        size="sm"
        color="secondary"
        class="mr-auto m-0 btn btn-outline text-xs w-1/3 sm:w-1/5"
        style="width:110px;"
        @click="reset()"
      >
        RESET
      </mdb-btn>
      <mdb-btn
        :disabled="formInvalid"
        size="sm"
        color="white"
        class="ml-auto m-0 text-xs w-1/3 sm:w-1/5"
        style="width:110px;"
        @click="addSchedulerSlot()"
      >
        CREATE
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, nextTick } from 'vue'
import { useScheduler, useStatus, useWorkflow, useEvent } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import eventService from '@/api/services/event.service'

import DaySelect from '@/views/roles/innerComponents/Pages/AddEditRole/SchedulerSetup/daySelect'
import { useRoute } from 'vue-router/composables'

import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

export default {
  name:'AddSlot',
  components: {
    DaySelect, VueTimepicker
  },
  setup () {
    const { mobileView } = useMobile()
    const workflowStore = useWorkflow()
    const { selectedWorkflowStage } = storeToRefs(workflowStore)

    const eventStore = useEvent()
    const { getEventLocations, setSelectedEvent } = useEvent()
    const { selectedEvent, locationsDropdown: locationsDropdownLocal } = storeToRefs(eventStore)

    const schedulerStore = useScheduler()
    const { schedulerSlot, selectedSchedulerSetup, selectedDate, eventStageLocationTimeslots, timeslotsLoading, daySelected } = storeToRefs(schedulerStore)
    const { getUsers, getSchedulerTimeslots, resetSchedulerSlotForm } = useScheduler()
    const { showGenericErrorModal } = useStatus()

    resetSchedulerSlotForm()

    const users = ref([])
    const key = ref(0)

    const loading = ref(false)
    const locationsDropdown = ref([])
    watch(locationsDropdownLocal, () => { locationsDropdown.value = locationsDropdownLocal.value }, { immediate: true, deep: true })
    const daysOfWeek = ref([1,2,3,4,5])
    const stageType = computed(() => selectedWorkflowStage.value.StageType)

    const route = useRoute()

    const toggleLinkSlotsToUsers = () => {
      if (!schedulerSlot.value.NumberOfSlots) return
      schedulerSlot.value.LinkSlotsToUsers = !schedulerSlot.value.LinkSlotsToUsers
    }

    // todo - duplicate code => addWorkingPattern
    let usersDefault = null
    let dropdown = []
    const load = async () => {
      loading.value = true
      try {
        if (selectedWorkflowStage.value.StageType === 3) await setSelectedEvent(route.params.roleWorkflowStageId)
        let res = await getUsers()
        if (res.length) {
          usersDefault = [...res]
          await addRemoveSlot(1)
        }
      } catch (error) {
        showGenericErrorModal({ showing: true })
      }
      loading.value = false
    }

    const mapLocations = () => {
      if (stageType.value === 3) {
        locationsDropdown.value = selectedEvent?.value?.GoEventLocations?.map((c, index) => ({
          text: c.GoEventLocationName,
          selected: index == 0,
          value: c.GoEventLocationId
        }))
      }
    }

    // todo - fix this when user selects then adds more slots
    const addRemoveSlot = async (event) => {
      if (event === '0') schedulerSlot.value.LinkSlotsToUsers = false
      if (!usersDefault) return

      loading.value = true
      let index = Number(event - 1)
      dropdown = usersDefault.map(c => ({
        text: c.User,
        selected: false,
        value: c.Id
      }))
      users.value[index] = dropdown
      key.value = key.value + 1
      loading.value = false
    }

    const selectedLocation = computed(() => locationsDropdown.value.filter(x => x.selected).map(y => y.value)[0])

    const findAssignedUserDuplicates = arry => arry.filter((item, index) => arry.indexOf(item) !== index)

    const addSchedulerSlot = async () => {
      let ApplicationUsers = users.value.map(user => user.filter(u => u.selected).map(x => x.value)[0] ?? null) // todo - make this computed? wasn't working
      if ((findAssignedUserDuplicates(ApplicationUsers).length != 0 || ApplicationUsers.length != Number(schedulerSlot.value.NumberOfSlots)) && schedulerSlot.value.LinkSlotsToUsers) {
        // this check is satisfied if either the same user has been applied to multiple slots or there is a slot that hasnt been assigned a user
        showGenericErrorModal({ showing: true, errorMessage: 'Please assign a different user for each slot' }); return
      } else if (schedulerSlot.value.EndDate < schedulerSlot.value.StartDate) {
        showGenericErrorModal({ showing: true, errorMessage: 'Please ensure your start date is before the end date.' }); return
      }
      let payload = {
        RoleWorkflowStageId: route.params.roleWorkflowStageId,
        StartTime: `${schedulerSlot.value.StartTime.HH}:${schedulerSlot.value.StartTime.mm}`,
        EndTime: `${schedulerSlot.value.EndTime.HH}:${schedulerSlot.value.EndTime.mm}`,
        StartDate: schedulerSlot.value.StartDate,
        EndDate: schedulerSlot.value.EndDate,
        NumberOfSlots: Number(schedulerSlot.value.NumberOfSlots),
        ApplicationUsers,
        GoEventLocationId: selectedLocation.value,
        DaysOfWeek: daysOfWeek.value,
        IsUnlimited: (schedulerSlot.value.NumberOfSlots === null || schedulerSlot.value.NumberOfSlots === '0')
      }

      try {
        timeslotsLoading.value = true
        const res = await eventService.addEventTimeslot(payload)
        if (res.data === true) {
          const res = await getSchedulerTimeslots(selectedDate.value)
          eventStageLocationTimeslots.value = res
        }
        reset()
      } catch (error) {
        showGenericErrorModal({ showing: true })
      } finally {
        setInterval(() => {
          timeslotsLoading.value = false
        }, 1500)
      }
    }

    load()

    const formInvalid = computed(() => {
      let invalid = null
      if ((selectedEvent?.value?.CustomUrl && selectedEvent?.value?.GoEventLocations.length === 0) || stageType.value == 2) {
        invalid = (
          !schedulerSlot.value.StartTime  ||
          !schedulerSlot.value.EndTime ||
          !schedulerSlot.value.StartDate ||
          !schedulerSlot.value.EndDate
        )
      } else {
        invalid = (
          !schedulerSlot.value.StartTime ||
          !schedulerSlot.value.EndTime ||
          !schedulerSlot.value.StartDate ||
          !schedulerSlot.value.EndDate ||
          !selectedLocation.value
        )
      }
      return invalid
    })

    const reset = async () => {
      loading.value = true
      resetSchedulerSlotForm()
      if (stageType.value === 3) {
        await getEventLocations()
        // selectedEvent.value?.GoEventLocations = res.data
        mapLocations()
        if (selectedEvent?.value.GoEventLocations?.length > 1) {
          locationsDropdown.value = locationsDropdown.value.map(x => ({ ...x, selected: false }))
        }
      }
      users.value = []
      daysOfWeek.value = [1,2,3,4,5]
      daySelected.value = [false, true, true, true, true, true, false]
      await nextTick(() => {})
      loading.value = false
    }

    const setDate = (date) => {
      if (date.getFullYear() === 1970) {
        date = new Date()
      }
      if (!date) return
      var month = date.getMonth() + 1
      var day = date.getDate()
      var year = date.getFullYear()
      if (month < 10) {
        month = '0' + month.toString()
      }
      if (day < 10) {
        day = '0' + day.toString()
      }
      return year + '-' + month + '-' + day
    }
    const maximumSlotDate = computed(() => {
      if (selectedWorkflowStage?.value.IsEvergreen) {
        return setDate(new Date('2099-01-01'))
      } else {
        return setDate(new Date(selectedWorkflowStage?.value.EndDate))
      }
    })
    const minimumSlotDate = computed(() => {
      var date = new Date()
      date.setFullYear(date.getFullYear()-1);
      return setDate(date)
    })

    return {
      daysOfWeek,
      schedulerSlot,
      mobileView,
      addSchedulerSlot,
      users,
      addRemoveSlot,
      key,
      loading,
      locationsDropdown,
      selectedLocation,
      formInvalid,
      maximumSlotDate,
      minimumSlotDate,
      selectedSchedulerSetup,
      setSelectedEvent,
      reset,
      toggleLinkSlotsToUsers,
      selectedWorkflowStage,
      selectedEvent,
      stageType,
      locationsDropdownLocal
    }
  }
}
</script>

<style>
.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: #c0c0c0 !important;
}
.vue__time-picker {
  color: #777 !important;
  width: 100% !important;
  font-size: .95rem !important;
}
.vue__time-picker .clear-btn {
  margin-top: 5px;
}
.vue__time-picker input.display-time {
  outline: none;
  width: 100% !important;
  border-bottom: 1px solid #d2d2d2 !important;
  border-top:none !important;
  border-left:none !important;
  border-right:none !important;
}
</style>
