<template>
  <div
    v-if="userData.aut && landingPage !== ''"
    class="left-container leftColFullWidth d-none d-md-block"
    :class="{ 'hideLeftNavigation': mobileView, 'shrink': ($route.path == '/setPassword' || $route.path == '/candidates' || $route.path == '/reports' || $route.path == '/' || $route.path.startsWith('/roles') || $route.path.startsWith('/settings') || $route.path == '/admin' || $route.path == '/help' || $route.path.startsWith('/client_portal') || $route.path.startsWith('/events') ) }"
  >
    <div v-show="mobileView === false" style="width:100%;">
      <div id="main-nav" style="float:left;width:100%;">
        <div style="width:70px;background:#313131;float:left;background-color:#313131;height:100%;height:100vh;" class="z-40 fixed">
          <div style="padding:22px 0px 0px 0px;">
            <div
              v-if="userData.ncn"
              class="navIcon"
              title="Candidates"
            >
              <a class="left-nav-colour" @click="goToRoute('/candidates')"><mdb-icon icon="users" :class="{ 'navSelected': ($route.path == '/candidates' || $route.path == '/') }" /></a>
            </div>
            <div
              v-if="userData.nrl"
              class="navIcon"
              title="Roles"
            >
              <a class="left-nav-colour" @click="goToRoute('/roles')"><mdb-icon icon="suitcase" :class="{ 'navSelected': $route.path.startsWith('/roles') }" /></a>
            </div>
            <div
              v-if="userData.nvl"
              class="navIcon"
              title="Video library"
            >
              <a class="left-nav-colour" @click="goToRoute('/videolibrary')"><FontAwesomeIcon icon="fa-regular fa-photo-film" :class="{ 'navSelected': $route.path == '/videolibrary' }" /></a>
            </div>
            <!--<div
              v-if="userData.ncp"
              class="navIcon"
              title="Client Portal"
            >
              <a class="left-nav-colour" @click="goToRoute('/client_portal/clients')"><FontAwesomeIcon icon="fa-solid fa-lamp-desk" :class="{ 'navSelected': $route.path.startsWith('/client_portal') }" /></a>
            </div>-->
            <div
              v-if="userData.nrp && (userData.rle === 'Manager' || userData.rle === 'Admin' || userData.iss || userData.rle === 'Account Group Administrator') "
              class="navIcon"
              title="Reports"
            >
              <a class="left-nav-colour" @click="goToRoute('/reports')"><mdb-icon icon="chart-line" :class="{ 'navSelected': $route.path == '/reports' }" /></a>
            </div>
            <div
              v-if="userData.nmn"
              class="navIcon"
              title="Meet Now"
            >
              <a class="left-nav-colour" @click="goToRoute('/meetnow')"><mdb-icon icon="video" :class="{ 'navSelected': $route.path == '/meetnow' }" /></a>
            </div>
            <!--VCVX
            <div
              v-if="userData.nin"
              class="navIcon"
              title="Shine Intros"
            >
              <a class="left-nav-colour" @click="goToRoute('/shineintros')"><mdb-icon icon="tv" :class="{ 'navSelected': $route.path == '/shineintros' }" /></a>
            </div>-->
            <div
              v-if="userData.nnt"
              class="navIcon"
              title="Notifications"
            >
              <a class="left-nav-colour" @click="goToRoute('/notifications')"><mdb-icon icon="bell" :class="{ 'navSelected': $route.path == '/notifications' }" /></a>
            </div>
            <div class="navIcon" title="Settings">
              <a class="left-nav-colour" @click="goToRoute('/settings')"><mdb-icon icon="cog" :class="{ 'navSelected': $route.path.startsWith('/settings') }" /></a>
            </div>
          </div>
          <div id="right-nav-footer" style="width:69px;background-color:#000;">
            <div style="padding: 16px 0px 0px 0px;height: 67px;">
              <div style="font-size:1.5rem;text-align:center;">
                <a class="secondary-colour" @click="help()"><mdb-icon icon="question-circle" /></a>
              </div>
            </div>
          </div>
        </div>

        <!-- Video Library -->
        <transition
          v-if="mobileView === false"
          :key="'videos'"
          name="fade"
          mode="out-in"
          style="overflow:hidden"
          class="leftColFullWidth"
        >
          <div v-if="$route.path === '/videolibrary'" style="margin-left:70px;">
            <div class="border-bottom font-bold text-left text-gray-600" style="background:#c1c1c1;padding:26px 20px;font-size:1.2rem;">
              Video Library
            </div>
            <div style="height:calc(100vh - 200px);overflow:auto;margin-top:10px;">
              <div style="height:calc(100vh - 200px);overflow:auto;margin-top:10px;">
                <div style="width:100%;height:fit-content;" class="mb-3">
                  <div
                    v-for="cats in videoCategories"
                    :key="cats.value"
                    :class="{'selected' : cats.text === videoLibraryFilter}"
                    class="leftColumnThinTile mt-2 mx-2 mx-lg-3 px-3 cp"
                    style="position:relative;"
                    @click="setVideoFilter(cats.text)"
                  >
                    <div class="text-gray-600" style="margin-right:auto">
                      <i class="fa fa-tv p-1" /> {{ cats.text }}
                    </div>
                    <div class="d-flex align-items-center justify-content-center left-column-count">
                      <div class="font-weight-bold" style="color:#fff; letter-spacing:1px;">
                        <span v-if="cats.value === 0"> {{ libraryNumbers.All }}</span>
                        <span v-if="cats.value === 1"> {{ libraryNumbers.Organisation }}</span>
                        <span v-if="cats.value === 2"> {{ libraryNumbers.Job }}</span>
                        <span v-if="cats.value === 3"> {{ libraryNumbers.Shareable }}</span>
                        <span v-if="cats.value === 4"> {{ libraryNumbers.Introduction }}</span>
                        <span v-if="cats.value === 5"> {{ libraryNumbers.Outro }}</span>
                        <span v-if="cats.value === 6"> {{ libraryNumbers.Question }}</span>
                        <span v-if="cats.value === 7"> {{ libraryNumbers.Candidate }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <!--notifications-->
        <transition
          v-if="mobileView === false"
          :key="'notifications'"
          name="fade"
          mode="out-in"
          style="overflow:hidden"
          class="leftColFullWidth"
        >
          <div v-if="$route.path === '/notifications'" style="margin-left:70px;">
            <div
              class="border-bottom font-bold text-left text-gray-600"
              style="background:#c1c1c1;padding:26px 20px;font-size:1.2rem;"
              @click="getNotifications()"
            >
              Notifications
              <i v-show="spinner" class="fas fa-spin fa-sync" />
              <i v-show="!spinner" class="fas fa-sync" />
            </div>
            <!--notification icons-->
            <div style="height:calc(100vh - 150px);overflow:auto;margin-top:10px;">
              <div style="width:100%;height:fit-content;" class="mb-5">
                <div
                  v-for="cats in notificationCategories"
                  v-show="cats.text !== 'Introduction'"
                  :key="cats.value"
                  :class="{'selected' : cats.filterType === notificationFilterType}"
                  class="leftColumnThinTile mt-2 mx-2 mx-lg-3 px-3 cp"
                  style="position:relative;"
                  @click="notificationFilterType = cats.filterType"
                >
                  <div class="text-gray-600" style="margin-right:auto">
                    <i class="fa fa-bell p-1" /> {{ cats.text }}
                  </div>
                  <div class="d-flex align-items-center justify-content-center left-column-count">
                    <div class="font-weight-bold" style="color:#fff; letter-spacing:1px;">
                      {{ cats.count }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <!--Meet now-->
        <transition
          v-if="mobileView === false"
          :key="'meetnow'"
          name="fade"
          mode="out-in"
          class="leftColFullWidth mb-5"
        >
          <div v-if="$route.path === '/meetnow'" style="margin-left:70px;">
            <div class="border-bottom font-bold text-left text-gray-600" style="background:#c1c1c1;padding:26px 20px;font-size:1.2rem;">
              Meet Now
            </div>
            <!--meet now icons-->
            <div style="height:calc(100vh - 200px);overflow:auto;margin-top:10px;">
              <div style="width:100%;height:fit-content;" class="mb-3">
                <div
                  v-for="cats in meetNowCategories"
                  v-show="cats.text !== 'Introduction'"
                  :key="cats.value"
                  :class="{'selected' : cats.filterType === meetNowFilterType}"
                  class="leftColumnThinTile mt-2 mx-2 mx-lg-3 px-3 cp"
                  style="position:relative;"
                  @click="meetNowFilterType = cats.filterType"
                >
                  <div class="text-gray-600" style="margin-right:auto">
                    <i class="fa fa-video p-1" /> {{ cats.text }}
                  </div>
                  <div class="d-flex align-items-center justify-content-center left-column-count">
                    <div class="font-weight-bold" style="color:#fff; letter-spacing:1px;">
                      {{ cats.count }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <!-- Video Intros -->
        <transition
          v-if="mobileView === false"
          :key="'videoIntros'"
          name="fade"
          mode="out-in"
          class="leftColFullWidth mb-5"
        >
          <div v-if="$route.path === '/shineintros'" style="margin-left:70px;">
            <div class="border-bottom font-bold text-left text-gray-600" style="background:#c1c1c1;padding:26px 20px;font-size:1.2rem;">
              Shine Intros
            </div>
            <!--video intro icons-->
            <div style="height:calc(100vh - 200px);overflow:auto;margin-top:10px;">
              <div style="width:100%;height:fit-content;" class="mb-3">
                <div
                  v-for="cats in videoIntroCategories"
                  v-show="cats.text !== 'Introduction'"
                  :key="cats.value"
                  :class="{'selected' : cats.filterType === videoIntroFilterType}"
                  class="leftColumnThinTile mt-2 mx-2 mx-lg-3 px-3 cp"
                  style="position:relative;"
                  @click="videoIntroFilterType = cats.filterType"
                >
                  <div class="text-gray-600" style="margin-right:auto">
                    <i class="fa fa-tv p-1" /> {{ cats.text }}
                  </div>
                  <div class="d-flex align-items-center justify-content-center left-column-count">
                    <div class="font-weight-bold" style="color:#fff; letter-spacing:1px;">
                      {{ cats.count }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <!-- Candidates -->
        <transition
          v-if="mobileView === false"
          :key="'candidates'"
          class="text-left text-gray-600 leftColFullWidth"
        >
          <div
            v-if="($route.path === '/candidates' || $route.path === '/')"
            class="leftColWidth"
            style="margin-left:70px;background: #e1e1e1;min-height:100vh;position:fixed;"
          >
            <div
              class="border-bottom font-bold text-left text-gray-600 mb-2"
              style="background:#c1c1c1;padding:26px 20px;font-size:1.1rem;"
              @click="getRecentCandidates()"
            >
              Recent Candidates
              <i v-show="spinner" class="fas fa-spin fa-sync" />
              <i v-show="!spinner" class="fas fa-sync" />
            </div>
            <div v-if="candidatesLoaded === true">
              <div v-show="recentCandidates.length === 0">
                <p class="grey-text" style="margin-left:10px;font-size:0.8rem;text-align:left;margin-right:10px;">
                  You have no recent candidates, let's get started and add some candidates by clicking the 'add' button below!
                </p>
              </div>
            </div>
            <div v-if="recentCandidates.length > 0" style="height:calc(100vh - 190px);overflow:auto;margin-top:20px;">
              <transition-group
                tag="ul"
                name="slide-in"
                :style="{ '--total': recentCandidates.length }"
                style="height:fit-content;padding-bottom:70px"
              >
                <li
                  v-for="(cand,i) in recentCandidates"
                  v-show="showRecentCandidateItems && i < 10"
                  :key="cand.CandidateId"
                  :style="{'--i': i}"
                  class="cp text-left text-gray-600 px-2 mb-2 pr-3 mx-2 mx-lg-3 leftColumnThinTile"
                  @click.self="goToRecentCandidate(cand)"
                >
                  <div
                    style="height:40px;width:40px;border-radius:50%;display:flex;align-items:center;justify-content:center;"
                    class="mr-2"
                    @click="goToRecentCandidate(cand)"
                  >
                    <img
                      v-if="cand.ProfilePicture != '' && cand.ProfilePicture != null"
                      style="height:36px;width:36px;border-radius:50%;object-fit:cover;"
                      :src="cand.ProfilePicture"
                      :style="{'border' : cand.ProfilePicture.includes('silhouette') ? '1px #c0c0c0 solid' : 'none'}"
                    >
                    <div
                      v-else
                      style="border:1px #c0c0c0 solid;height:36px;width:36px;border-radius:50%;"
                      class="p-1 d-flex align-items-center justify-content-center"
                    >
                      <mdb-icon icon="fa fa-user fa-2x" size="lg" />
                    </div>
                  </div>
                  <div
                    class="text-center mr-auto"
                    style="width:max-content;"
                    :style="{'color': `${userData.lbl.PrimaryColour}`}"
                    @click="goToRecentCandidate(cand)"
                  >
                    {{ cand.FirstName }} {{ cand.LastName }}
                  </div>
                  <span
                    v-if="cand.Following"
                    class="primary-colour"
                    @click="unFollowCandidate(cand)"
                  >
                    <mdb-icon icon="star" title="Unfollow candidate" />
                  </span>
                  <span
                    v-else
                    class="primary-colour"
                    @click="followCandidate(cand)"
                  >
                    <mdb-icon
                      far
                      icon="star"
                      title="Follow candidate"
                    />
                  </span>
                </li>
              </transition-group>
            </div>
          </div>
        </transition>

        <!-- My Accounts -->
        <transition
          v-if="mobileView === false"
          :key="'accounts'"
          class="text-left text-gray-600 leftColFullWidth"
        >
          <div
            v-if="($route.path === '/myaccounts')"
            class="leftColWidth"
            style="margin-left:70px;background: #e1e1e1;min-height:100vh;position:fixed;"
          >
            <div
              class="border-bottom font-bold text-left text-gray-600 mb-2"
              style="background:#c1c1c1;padding:26px 20px;font-size:1.1rem;"
              @click="getMyRecentAccounts()"
            >
              Recent Accounts
            </div>
            <div v-show="recentAccountsLoaded">
              <div v-if="recentAccounts.length === 0">
                <p class="grey-text" style="margin-top:30px;margin-left:20px;font-size:0.8rem;text-align:left;margin-right:10px;">
                  You have no recent accounts!
                </p>
              </div>
            </div>
            <div v-if="recentAccounts.length > 0" style="height:calc(100vh - 190px);overflow:auto;margin-top:20px;">
              <transition-group
                tag="ul"
                name="slide-in"
                :style="{ '--total': recentAccounts.length }"
                style="height:fit-content;padding-bottom:70px"
              >
                <li
                  v-for="(acc,i) in recentAccounts"
                  :key="acc.AccountId"
                  :style="{'--i': i}"
                  class="cp text-left text-gray-600 px-2 mb-2 pr-3 mx-2 mx-lg-3 leftColumnThinTile"
                  style="background:white;cursor:pointer;"
                  title="Switch account?"
                  @click.self="switchAccount(acc)"
                >
                  <div
                    v-if="acc.GroupName !== '-'"
                    style="width:100%;float:left"
                  >
                    <div class="nav-btn-text" style="float:left;font-size:0.8rem;margin-top:0px;">
                      <div style="font-weight:bold;">
                        {{ acc.Name }}
                      </div>
                      <div style="font-size:0.7rem;margin-top:-4px;">
                        {{ acc.GroupName }}
                      </div>
                    </div>
                    <i
                      v-if="acc.AccountId !== userData.aid"
                      class="fas fa-sync primary-colour"
                      title="Switch Account?"
                      style="float:right;font-size:1rem;margin-top:8px;"
                      @click="showSwitchAccountModal(acc)"
                    />
                    <i
                      v-else
                      class="fas fa-sync"
                      title="Switch Account?"
                      style="float:right;font-size:1rem;margin-top:8px;color:#e1e1e1;"
                    />
                  </div>
                  <div
                    v-else
                    style="width:100%;float:left"
                  >
                    <div class="nav-btn-text" style="float:left;font-size:0.8rem;margin-top:2px;">
                      <div style="font-weight:bold;">
                        {{ acc.Name }}
                      </div>
                    </div>
                    <i
                      v-if="acc.AccountId !== userData.aid"
                      class="fas fa-sync primary-colour"
                      title="Switch Account?"
                      style="float:right;font-size:1rem;margin-top:2px;"
                      @click="showSwitchAccountModal(acc)"
                    />
                    <i
                      v-else
                      class="fas fa-sync"
                      title="Switch Account?"
                      style="float:right;font-size:1rem;margin-top:8px;color:#e1e1e1;"
                    />
                  </div>
                </li>
              </transition-group>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <!--switch modal-->
    <mdb-modal
      id="switchModal"
      :show="switchModal"
      @close="switchModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>SWITCH?</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          Are you sure you want to switch to this account?
        </p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          v-show="!buttonClick"
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="switchAccount"
        >
          SWITCH
        </mdb-btn>
        <mdb-btn
          v-show="buttonClick"
          class="float-right"
          size="sm"
          style="width:100px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import candidateService from '@/api/services/candidate.service'
import libraryService from '@/api/services/library.service'
import meetNowService from '@/api/services/meetnow.service'
import notificationService from '@/api/services/notification.service'
import userService from '@/api/services/user.service'
import adminService from '@/api/services/admin.service'

import { useCandidate, useUserData, useMeetNow, useNotification, useLibrary, useVideoIntro, useStatus } from '@/pinia'
import { useMobile } from '@/composables/useMobile'
import { storeToRefs } from 'pinia'

export default {
  name: 'LeftColumn',
  setup () {
    // Candidate
    const candidateStore = useCandidate()
    const { updateCandidateFollowing } = useCandidate()
    const {
      candidateToEdit,
      recentCandidates,
      editingCandidate,
      view,
      candidateFiles
    } = storeToRefs(candidateStore)

    // User data
    const userStore = useUserData()
    const { authData, userData, landingPage, videoLibraryFilter, leftColumnActive, refreshState } = storeToRefs(userStore)

    // Meet now
    const meetNowStore = useMeetNow()
    const {
      meetNowFilterType,
      availableRooms,
      myMeetNow,
      publicMeetNow,
      archivedMeetNow,
      refreshMeetNowSummary
    } = storeToRefs(meetNowStore)

    // Notifications
    const notificationStore = useNotification()
    const {
      notificationFilterType,
      notifications,
      liveNotifications,
      liveNotificationsShown,
      redirectToOneWay,
      redirectToVideoCV,
      redirectToTwoWay,
      redirectToShareAnalytics,
      notificationsLoading
    } = storeToRefs(notificationStore)

    // Library
    const libraryStore = useLibrary()
    const { libraryNumbers } = storeToRefs(libraryStore)

    // Video Intro
    const videoIntroStore = useVideoIntro()
    const { videoIntroFilterType, myIntros, archivedIntros } = storeToRefs(videoIntroStore)

    const { mobileView } = useMobile()
    const { showGenericErrorModal } = useStatus()

    return {
      candidateToEdit,
      recentCandidates,
      authData,
      userData,
      landingPage,
      editingCandidate,
      view,
      candidateFiles,
      updateCandidateFollowing,
      meetNowFilterType,
      availableRooms,
      myMeetNow,
      publicMeetNow,
      archivedMeetNow,
      refreshMeetNowSummary,
      notificationFilterType,
      notifications,
      liveNotifications,
      liveNotificationsShown,
      redirectToOneWay,
      redirectToVideoCV,
      redirectToTwoWay,
      redirectToShareAnalytics,
      notificationsLoading,
      libraryNumbers,
      videoLibraryFilter,
      leftColumnActive,
      videoIntroFilterType,
      myIntros,
      archivedIntros,
      mobileView,
      showGenericErrorModal,
      refreshState
    }
  },
  data () {
    return {
      showRecentCandidateItems: false,
      isFirefox: false,
      windowHeight: 0,
      windowWidth: 0,
      videoCategories: [{ text: 'All', value: 0 }, { text: 'Candidate', value: 7 }, { text: 'Introduction', value: 4 }, { text: 'Job', value: 2 }, { text: 'Organisation', value: 1 }, { text: 'Outro', value: 5 }, { text: 'Question', value: 6 }, { text: 'Shareable', value: 3 }],
      spinner: false,
      candidatesLoaded: false,
      availableRoomsCount: 0,
      myMeetNowCount: 0,
      publicMeetNowCount: 0,
      archivedMeetNowCount: 0,
      recentAccountsLoaded: false,
      recentAccounts: [],
      switchModal: false,
      buttonClick: false
    }
  },
  computed: {
    meetNowCategories () {
      return [
        { text: 'Available Rooms', filterType: 'Available Rooms', count: this.availableRoomsCount },
        { text: 'My Recordings', filterType: 'My Recordings', count: this.myMeetNowCount },
        { text: 'Public Recordings', filterType: 'Public Recordings', count: this.publicMeetNowCount },
        { text: 'Archived Recordings', filterType: 'Archived Recordings', count: this.archivedMeetNowCount }
      ]
    },
    videoIntroCategories () {
      return [
        { text: 'My Shine Intros', filterType: 'My Shine Intros', count: this.myIntros.length },
        { text: 'Archived Shine Intros', filterType: 'Archived Shine Intros', count: this.archivedIntros.length }
      ]
    },
    notificationCategories () {
      return [
        { text: 'All Notifications', filterType: 'All', count: this.notifications.length },
        { text: 'Completions', filterType: 'Completion', count: this.notificationCompletionGroupLength },
        { text: 'Sharing', filterType: 'Candidate Sharing', count: this.notificationSharingGroupLength },
        { text: 'Live Interviews', filterType: 'Live Interview', count: this.notificationLiveInterviewGroupLength },
        { text: 'Self Registration', filterType: 'Self Registration', count: this.notificationSelfRegistrationGroupLength },
        { text: 'Bulk Uploads', filterType: 'Bulk Uploads', count: this.notificationBulkUploadsGroupLength }
      ]
    },
    notificationCompletionGroupLength () {
      try {
        return this.notifications.filter(n => n.NotificationGroup === 'Completion').length
      } catch { return 0 }
    },
    notificationSharingGroupLength () {
      try {
        return this.notifications.filter(n => n.NotificationGroup === 'Candidate Sharing').length
      } catch { return 0 }
    },
    notificationLiveInterviewGroupLength () {
      try {
        return this.notifications.filter(n => n.NotificationGroup === 'Live Interview').length
      } catch { return 0 }
    },
    notificationSelfRegistrationGroupLength () {
      try {
        return this.notifications.filter(n => n.NotificationGroup === 'Self Registration').length
      } catch { return 0 }
    },
    notificationBulkUploadsGroupLength () {
      try {
        return this.notifications.filter(n => n.NotificationGroup === 'Bulk Uploads').length
      } catch { return 0 }
    }
  },
  watch: {
    $route: {
      handler (to) {
        if (to.name === 'candidates' && !to.path.includes('roles')) {
          this.candidates()
        } else if (to.name === 'videolibrary') {
          this.videolibrary()
        } else if (to.name === 'notifications') {
          this.getNotifications()
        } else if (to.name === 'meetnow') {
          this.getMeetNowSummary()
        } else if (to.name === 'settings' && to.path === '/settings') {
          this.leftColumnActive = false
          this.$router.push('/settings/mysettings').catch(() => {})
        } else if (to.name === 'clientPortal') {
          this.leftColumnActive = false
        } else if (to.name === 'myaccounts') {
          this.getMyRecentAccounts()
        }
      },
      immediate: true
    },
    liveNotifications () {
      this.throwNotifications()
    },
    redirectToVideoCV () {
      if (this.redirectToVideoCV) {
        this.candidates()
      }
    },
    redirectToTwoWay () {},
    redirectToShareAnalytics () {},
    refreshMeetNowSummary () {
      if (this.refreshMeetNowSummary) {
        this.getMeetNowSummary()
        this.refreshMeetNowSummary = false
      }
    }
  },
  created () {
    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      this.isFirefox = true
    }
    this.windowHeight = window.innerHeight
    this.windowWidth = window.innerWidth
    if (this.$route.path === '/videolibrary') {
      this.getLibraryNumbers()
    }
  },
  async mounted () {
    window.addEventListener(
      'orientationchange',
      () => this.goToRoute(this.$route.path)
    )
  },
  updated () {
    if (this.userData.aut === false) {
      this.$router.push('/').catch(() => {})
    }
  },
  methods: {
    help () {
      window.open('https://support.shineinterview.com/hc/en-us', '_blank')
    },
    switchAccount () {
      this.buttonClick = true
      adminService.switchAccount(this.accountId).then((response) => {
        if (response.data !== null) {
          this.userData = response.data
          this.getMyRecentAccounts()
          this.refreshState = true
        }
        this.switchModal = false
        this.accountId = null
        this.buttonClick = false
      }).catch((error) => {
        this.switchModal = false
        this.accountId = null
        this.buttonClick = false
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
    },
    showSwitchAccountModal (data) {
      this.accountId = data.AccountId
      this.switchModal = true
    },
    getMyRecentAccounts () {
      this.recentAccountsLoaded = false
      userService.getMyRecentAccounts().then((response) => {
        this.recentAccounts = response.data
        this.recentAccountsLoaded = true
      }).catch(() => {
        this.recentAccountsLoaded = true
        this.showGenericErrorModal({ showing: true })
      })
    },
    followCandidate (candidate) {
      candidateService.followCandidate(candidate.CandidateId).then((response) => {
        if (response.data === true) {
          this.updateCandidateFollowing({ candId: candidate.CandidateId, following: true })
        }
      })
    },
    unFollowCandidate (candidate) {
      candidateService.unFollowCandidate(candidate.CandidateId).then((response) => {
        if (response.data === true) {
          this.updateCandidateFollowing({ candId: candidate.CandidateId, following: false })
        }
      })
    },
    goToRecentCandidate (candidate) {
      this.candidateToEdit = { ...candidate }
      this.editingCandidate = true
      this.view = 'Profile'
      this.candidateFiles = []
    },
    async getMeetNowSummary () {
      try {
        const res = await meetNowService.getMeetNowSummary()
        this.availableRoomsCount = res.data.AvailableRooms
        this.myMeetNowCount = res.data.MyRecordings
        this.publicMeetNowCount = res.data.PublicRecordings
        this.archivedMeetNowCount = res.data.ArchivedRecordings
      } catch (err) { 
        //?
      }
    },
    getRecentCandidates () {
      this.showRecentCandidateItems = false
      this.candidatesLoaded = false
      this.spinner = true
      candidateService.getRecentCandidates().then((response) => {
        this.recentCandidates = response.data
        this.candidatesLoaded = true
        this.spinner = false
        setTimeout(() => {
          this.showRecentCandidateItems = true
        }, 500)
      }).catch(() => {
        this.candidatesLoaded = true
        this.showGenericErrorModal({ showing: true })
        this.$router.push('/').catch(() => {})
      })
    },
    async getNotifications () {
      this.notificationsLoading = true
      this.spinner = true
      setTimeout(() => {
        this.spinner = false
      }, 3000)
      try {
        const res = await notificationService.getAllUserNotifications(false)
        this.notifications = res.data
      } catch (err) {
        //?
      }
      this.notificationsLoading = false
    },
    setVideoFilter (value) {
      this.videoLibraryFilter = value
    },
    candidates () {
      this.getRecentCandidates()
      this.view = 'ViewAllCandidates'
      this.editingCandidate = false
      this.candidateToEdit = []
    },
    async videolibrary () {
      this.leftColumnActive = false
      try {
        const res = await libraryService.getLibraryNumbers()
        this.libraryNumbers = res.data
      } catch (err) {
        //?
      }
    },
    account () {
      this.goToRoute('/account')
    },
    async getLibraryNumbers () {
      try {
        const res = await libraryService.getLibraryNumbers()
        this.libraryNumbers = res.data
      } catch (err) {
        //?
      }
    },
    throwNotifications () {
      if (this.liveNotifications.length !== 0 && !this.liveNotificationsShown) {
        this.liveNotificationsShown = true
        this.liveNotifications.map((n) => {
          this.$notify.info({ message: n.NotificationText, position: 'bottom right', timeOut: 5000, closeButton: false, color: this.userData.lbl.PrimaryColour })
        })
      }
    },
    goToRoute (item) {
      if (item !== 'none') {
        if (this.$route.path !== item) {
          this.$router.push(item).catch(() => { })
        } else {
          this.$router.go(this.$router.currentRoute)
        }
      }
    }
  }
}
</script>

<style>
/* notification */
#toastr-container {
  text-align: left !important;
}
/* .md-toast::before {
  content: '' !important;
  width: 20px !important;
  height: 100% !important;
} */
.md-toast {
  text-align: right !important;
  margin-left: auto !important;
  padding: 5px 10px 5px 55px !important;
  font-size:1rem !important;
  width: fit-content !important;
}
.md-toast.md-toast-info {
  background-image: url('../assets/bell.png') !important;
  /* background-image: none !important; */
}
/* notification */

.b-sidebar:not(.b-sidebar-right) {
  right: auto;
  top: 80px;
  left: 70px;
  width: 230px;
  z-index: 900;
  box-shadow: none !important;
  background-color: transparent !important;
}
.b-sidebar > .b-sidebar-header {
  display: none;
}
.b-sidebar {
  display: block;
}

</style>

<style lang="scss" scoped>
// transition for recent candidate sidebar
.slide-in {
  &-enter-active {
    transition: opacity .5s linear, transform .5s cubic-bezier(.2,.5,.1,1);
    transition-delay: calc( 0.1s * var(--i) );
  }

  &-enter {
    opacity: 0;
  }

  &-enter { transform: translateX(30px); }
}

.flex-parent {
  display: flex;
}
.long-and-truncated {
  flex: 1;
  &, & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.fade-enter-active {
  transition: opacity .75s 0.1s;
}
.fade-leave-active {
  display: none;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.shrink {
  width:70px !important;
}
.hideLeftNavigation {
  width:0px !important;
  background: none !important;
  display:none !important;
}
// animation on notification selected

.box-shadow-fade {
  position: relative;
}
.box-shadow-fade:before {
  transition: opacity 0.3s linear;
  content: '';
  border-radius: 50%;
  top: 0;
  position:absolute;
  bottom: 0;
  left: 0;
  height:100%;
  width:100%;
  right: 0;
  box-shadow: 0 0 5px 3px var(--pr-color);
  opacity: 0;
}
.box-shadow-fade.is-selected::before {
  opacity: 1 !important;
}
</style>
