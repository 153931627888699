<template>
  <div class="groups">
    <div class="group">
      <!-- AWAITING REVIEW -->
      <div class="client-portal-group-header border-right-clientportal">
        <span>{{ convertStatusName(userData.pen) }}</span>
        <span class="column-header-count">{{ filteredAwaitingReviewCands.length }}</span>
      </div>
      <Container
        class="pl-2 client-portal-drag-container border-right-clientportal-container"
        group-name="1"
        :get-child-payload="getChildPayload1"
        @drop="onDrop('awaitingReviewCands', $event)"
      >
        <Draggable v-for="item in filteredAwaitingReviewCands" :key="item.id">
          <DraggableCandidateCard
            :candidate="item"
            @changeStatusFromPopup="changeStatusFromPopup($event)"
            @removeCandidateFromSpotlight="showConfirmRemoveCandidateModal($event)"
            @openInterviewModal="reviewModalData = $event; watchInterviewModal = true"
          />
        </Draggable>
      </Container>
    </div>
    <div class="group">
      <!-- AWAITING REVIEWED -->
      <div class="client-portal-group-header border-right-clientportal">
        <span>{{ convertStatusName(userData.rev) }}</span>
        <span class="column-header-count">{{ filteredReviewedCands.length }}</span>
      </div>
      <Container
        class="client-portal-drag-container border-right-clientportal-container"
        group-name="1"
        :get-child-payload="getChildPayload2"
        @drop="onDrop('reviewedCands', $event)"
      >
        <Draggable v-for="item in filteredReviewedCands" :key="item.id">
          <DraggableCandidateCard
            :candidate="item"
            @changeStatusFromPopup="changeStatusFromPopup($event)"
            @removeCandidateFromSpotlight="showConfirmRemoveCandidateModal($event)"
            @openInterviewModal="reviewModalData = $event; watchInterviewModal = true"
          />
        </Draggable>
      </Container>
    </div>
    <div class="group">
      <!-- SHORTLISTED -->
      <div class="client-portal-group-header border-right-clientportal">
        <span>{{ convertStatusName(userData.sht) }}</span>
        <span class="column-header-count">{{ filteredShortlistedCands.length }}</span>
      </div>
      <Container
        class="client-portal-drag-container border-right-clientportal-container"
        group-name="1"
        :get-child-payload="getChildPayload3"
        @drop="onDrop('shortlistedCands', $event)"
      >
        <Draggable v-for="item in filteredShortlistedCands" :key="item.id">
          <DraggableCandidateCard
            :candidate="item"
            @changeStatusFromPopup="changeStatusFromPopup($event)"
            @removeCandidateFromSpotlight="showConfirmRemoveCandidateModal($event)"
            @openInterviewModal="reviewModalData = $event; watchInterviewModal = true"
          />
        </Draggable>
      </Container>
    </div>
    <div class="group">
      <!-- REJECTED -->
      <div class="client-portal-group-header border-right-clientportal">
        <span>{{ convertStatusName(userData.rej) }}</span>
        <span class="column-header-count">{{ filteredRejectedCands.length }}</span>
      </div>
      <Container
        class="client-portal-drag-container border-right-clientportal-container"
        group-name="1"
        :get-child-payload="getChildPayload4"
        @drop="onDrop('rejectedCands', $event)"
      >
        <Draggable v-for="item in filteredRejectedCands" :key="item.id">
          <DraggableCandidateCard
            :candidate="item"
            @changeStatusFromPopup="changeStatusFromPopup($event)"
            @removeCandidateFromSpotlight="showConfirmRemoveCandidateModal($event)"
            @openInterviewModal="reviewModalData = $event; watchInterviewModal = true"
          />
        </Draggable>
      </Container>
    </div>
    <div class="group">
      <!-- OFFERED -->
      <div class="client-portal-group-header">
        <span>{{ convertStatusName(userData.off) }}</span>
        <span class="column-header-count">{{ filteredOfferedCands.length }}</span>
      </div>
      <Container
        class="pr-2 client-portal-drag-container"
        group-name="1"
        :get-child-payload="getChildPayload5"
        @drop="onDrop('offeredCands', $event)"
      >
        <Draggable v-for="item in filteredOfferedCands" :key="item.id">
          <DraggableCandidateCard
            :candidate="item"
            @changeStatusFromPopup="changeStatusFromPopup($event)"
            @removeCandidateFromSpotlight="showConfirmRemoveCandidateModal($event)"
            @openInterviewModal="reviewModalData = $event; watchInterviewModal = true"
          />
        </Draggable>
      </Container>
    </div>

    <!-- <ReviewModal
      style="position:fixed"
      :watch-interview-modal="watchInterviewModal"
      :show-video-c-v-tab="reviewModalData.showVideoCVTab"
      :workflow-type="reviewModalData.workflowType"
      :review-modal-data="reviewModalData.candidate"
      modal-context="CLIENT_PORTAL"
      :role-id="reviewModalData.roleId"
      :spotlight-masking="spotlight.AudioOnly"
      @closeModal="closeInterviewModal()"
    /> -->
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import { CONVERT_STATUS_NAME } from '@/utils/convertStatus.js'
import clientPortalService from '@/api/services/clientPortal.service'

import { Container, Draggable } from 'vue-dndrop'
import DraggableCandidateCard from './candidateDraggableCard.vue'
// import ReviewModal from '@/components/misc/ReviewModalV2/reviewModal.vue'

export default {
  name: 'SpotlightWorkflowDraggable',
  components: {
    Container,
    Draggable,
    DraggableCandidateCard,
    // ReviewModal
  },
  props: {
    search: {
      type: String,
      default: ''
    },
    candidates: {
      type: Array,
      default: () => []
    },
    spotlight: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()

    return {
      authData,
      userData,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal
    }
  },
  data () {
    return {
      awaitingReviewCands: [],
      reviewedCands: [],
      shortlistedCands: [],
      rejectedCands: [],
      offeredCands: [],
      reviewModalData: { showVideoCVTab: false, workflowType: 0, candidate: {}, roleId: 0 },
      watchInterviewModal: false
    }
  },
  computed: {
    filteredAwaitingReviewCands () {
      if (this.search === '' || this.awaitingReviewCands.length === 0) {
        return this.awaitingReviewCands
      } else {
        return this.awaitingReviewCands.filter(i => i.Name.toLowerCase().includes(this.search.toLowerCase()))
      }
    },
    filteredReviewedCands () {
      if (this.search === '' || this.reviewedCands.length === 0) {
        return this.reviewedCands
      } else {
        return this.reviewedCands.filter(i => i.Name.toLowerCase().includes(this.search.toLowerCase()))
      }
    },
    filteredShortlistedCands () {
      if (this.search === '' || this.shortlistedCands.length === 0) {
        return this.shortlistedCands
      } else {
        return this.shortlistedCands.filter(i => i.Name.toLowerCase().includes(this.search.toLowerCase()))
      }
    },
    filteredRejectedCands () {
      if (this.search === '' || this.rejectedCands.length === 0) {
        return this.rejectedCands
      } else {
        return this.rejectedCands.filter(i => i.Name.toLowerCase().includes(this.search.toLowerCase()))
      }
    },
    filteredOfferedCands () {
      if (this.search === '' || this.offeredCands.length === 0) {
        return this.offeredCands
      } else {
        return this.offeredCands.filter(i => i.Name.toLowerCase().includes(this.search.toLowerCase()))
      }
    }
  },
  watch: {
    candidates () {
      const candidates = [...this.candidates]
      this.awaitingReviewCands = candidates.filter(s => s.Status === 'Pending')
      this.rejectedCands = candidates.filter(s => s.Status === 'Rejected')
      this.reviewedCands = candidates.filter(s => s.Status === 'Reviewed')
      this.offeredCands = candidates.filter(s => s.Status === 'Offered Position')
      this.shortlistedCands = candidates.filter(s => s.Status === 'Shortlisted')
    }
  },
  methods: {
    convertStatusName (status) {
      return CONVERT_STATUS_NAME(status)
    },
    showConfirmRemoveCandidateModal (CandidateId) {
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'REMOVE CANDIDATE',
        confirmMessage: 'Are you sure you want to remove this candidate?',
        confirmButtonMessage: 'REMOVE',
        confirmButtonFunction: () => this.removeCandidateFromSpotlight(CandidateId)
      })
    },
    async removeCandidateFromSpotlight ({ candidateId, roleId }) {
      try {
        const res = await clientPortalService.removeCandidateFromSpotlight(this.spotlight.GoClientPortalSpotlightId, candidateId, roleId)
        if (res.data === false) {
          this.showGenericErrorModal({ showing: true })
          return
        }
        this.$emit('getSpotlightCandidates')
      } catch {
        this.showGenericErrorModal({ showing: true })
      }
      this.closeGenericConfirmModal()
    },
    applyDrag (arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr
      const result = [...arr]
      let itemToAdd = payload
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }
      return result
    },
    onDrop (collection, dropResult) {
      // this.search = ''
      if (dropResult.addedIndex !== null) {
        this.updateStatusBackend(collection, dropResult.payload)
      }
      this[collection] = this.applyDrag(this[collection], dropResult)
    },
    async updateStatusBackend (status, candidate) {
      let newStatus = ''
      if (status === 'awaitingReviewCands') {
        newStatus = 'Pending'
      } else if (status === 'reviewedCands') {
        newStatus = 'Reviewed'
      } else if (status === 'shortlistedCands') {
        newStatus = 'Shortlisted'
      } else if (status === 'rejectedCands') {
        newStatus = 'Rejected'
      } else if (status === 'offeredCands') { newStatus = 'Offered Position' }
      var formData = new FormData()
      formData.append('NewStatus', newStatus)
      formData.append('CandidateId', candidate.CandidateId)
      formData.append('RoleId', candidate.RoleId)
      formData.append('SpotlightId', this.spotlight.GoClientPortalSpotlightId)
      try {
        await clientPortalService.updateSpotlightCandidateStatus(formData)
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
    },
    async changeStatusFromPopup ({ status, candidate }) {
      var formData = new FormData()
      formData.append('NewStatus', status)
      formData.append('CandidateId', candidate.CandidateId)
      formData.append('RoleId', candidate.RoleId)
      formData.append('SpotlightId', this.spotlight.GoClientPortalSpotlightId)
      try {
        await clientPortalService.updateSpotlightCandidateStatus(formData)
        this.$emit('getSpotlightCandidates')
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
    },
    getChildPayload1 (index) {
      return this.awaitingReviewCands[index]
    },
    getChildPayload2 (index) {
      return this.reviewedCands[index]
    },
    getChildPayload3 (index) {
      return this.shortlistedCands[index]
    },
    getChildPayload4 (index) {
      return this.rejectedCands[index]
    },
    getChildPayload5 (index) {
      return this.offeredCands[index]
    },
    closeInterviewModal () {
      this.watchInterviewModal = false
    }
  }
}
</script>

<style>
.column-header-count {
  border: var(--pr-color) 4px solid;
  border-radius: 50%;
  height:35px;
  width: 35px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dark-grey);
}
</style>

<style lang="css" scoped>
.groups {
  display: flex;
  justify-content: stretch;
  border: 2px solid var(--gray);
  padding-bottom: 10px;
  width: 100%;
}
.client-portal-drag-container {
  padding: 10px 10px;
  min-height: 10000px;
}
.border-right-clientportal-container {
  border-right: solid 1px var(--gray);
}
.group {
  width: 20% !important;
  min-width: 20% !important;
  height: fit-content;
}
.client-portal-group-header {
  position: sticky;
  top: 0px;
  z-index: 10;
  width: 100%;
  background: rgb(210, 210, 210);
  height: 80px;
  overflow: hidden;
  font-weight: bold;
  border-bottom: 2px solid var(--gray);
  font-size: clamp(0.8rem, 2.2vw, 1.2rem);
  display: flex;
  align-items: center;
  text-align: left;
  padding: 0 10px;
  justify-content: space-between;
}
.border-right-clientportal {
  border-right: 2px solid var(--gray);
}
</style>
