<template>
  <div
    v-if="loading"
    style="height:80px;margin-left: 30px;"
    class="d-flex align-items-center"
  >
    <h3 class="primary-colour font-weight-bold mr-2">
      Loading
    </h3>
    <i class="fa fa-spin fa-spinner" />
  </div>
  <EventDetail v-else />
</template>

<script>
import { ref } from 'vue'
import { useEvent } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router/composables'
import EventDetail from '@/views/events/form/setup/eventDetail.vue';

export default {
  components: {
    EventDetail
  },
  setup () {
    const route = useRoute()
    const eventStore = useEvent()
    const { setSelectedEvent } = useEvent()
    const { selectedEvent } = storeToRefs(eventStore)

    const loading = ref(true)

    const load = async () => {
      loading.value = true
      await setSelectedEvent(route.params.roleWorkflowStageId)
      loading.value = false
    }

    load()

    return {
      selectedEvent,
      loading
    }
  }
}
</script>
