<template>
  <div class="d-flex flex-column " style="width:100%;text-align:left;">
    <!--banner-->
    <div class="p-2 lhsTopBanner">
      <div
        class="ml-2 mr-auto"
        style="width: calc(100% - 70px)"
      >
        <span title="Role Job Title" class="text-balance">{{ candidateApplication.JobTitle }}</span>
        <span
          v-if="candidate.Position && userData.ane.includes('Royal Navy')"
          title="Candidate Job Title"
          style="font-size:0.9rem;"
          class="font-weight-bold"
        > - {{ candidate.Position }}</span>
        <div
          v-if="candidateApplication.CompleteStatusType"
          class="mobileWatchS"
          style="text-align:left;font-size: 0.7rem !important;"
        >
          Completed on: {{ new Date(candidateApplication.DateCompleted).toLocaleDateString([userLanguage]) }} {{ new Date(candidateApplication.DateCompleted).toLocaleTimeString([userLanguage]).substring(0, 5) }}
        </div>
        <div
          v-else
          class="mobileWatchS"
          style="text-align:left;font-size: 0.7rem !important;"
        >
          In progress
        </div>
      </div>
      <mdb-popover
        ref="popover"
        trigger="click"
        :options="{placement: 'left'}"
        style="cursor:pointer;"
        class="grey-text mx-2 my-3 ml-auto"
      >
        <div slot="body">
          <h6
            style="margin-left:-10px;padding:12px;"
            class="text-nowrap"
            @click="candidateInformationModal = true"
          >
            <i class="fa fa-question-circle ml-1 grey-link" style="margin-right:10px;" /><a class="grey-link"> Candidate information</a>
          </h6>
          <EmbedVideo
            v-if="currentVideo.VideoUrl !== null && stageType === 2"
            :src="currentVideo.VideoUrl"
            :video-title="`${candidate.FirstName} ${candidate.LastName} - Two-Way Interview`"
            @closePopover="$refs.popover.doClose()"
          />
          <div
            v-if="(modalContext === 'ROLES' || modalContext === 'CANDIDATE_HISTORY')"
            class="d-sm-none"
          >
            <LHSPopoverStatusChange
              :candidate-application="candidateApplication"
              @setStatus="$emit('setStatus', $event)"
            />
          </div>
          <h6
            v-if="!mobileView && userData.trn && (stageType == 1 || (userData.iss && stageType == 2))"
            style="margin-left:-10px;padding:12px;"
            class="text-nowrap"
            @click="getFullTranscription()"
          >
            <i class="fa fa-comment-dots ml-1 grey-link" style="margin-right:10px;" /><a class="grey-link"> Full Transcription</a>
          </h6>
        </div>
        <mdb-icon
          slot="reference"
          style="width: 20px; height:30px; font-size:1rem;"
          class="secondary-colour d-flex align-items-center justify-content-center ellipsisButtonHover"
          icon="ellipsis-v"
        />
      </mdb-popover>
    </div>

    <!--video-->
    <div
      class="px-3 d-flex align-items-center justify-content-center"
      style="background:#000;max-height:350px;width:100%;"
      :style="{'min-height' : stageType === 2 ? 'none' : '300px', 'max-height' : stageType === 2 ? 'none' : '300px', 'flex-grow' : stageType === 2 ? '1' : '0'}"
    >
      <i
        v-if="currentVideo.VideoUrl == null && !loadingStream"
        class="fa fa-video-slash"
        style="font-size: 5rem;color: #e1e1e1;opacity:0.5;"
      />
      <i
        v-if="currentVideo.VideoUrl == null && loadingStream"
        class="fa fa-spinner fa-pulse"
        style="font-size: 5rem;color: #e1e1e1;opacity:0.5;"
      />
      <audio
        v-if="(candidateApplication.Job?.MaskCandidateVideo || spotlightMasking == 2 || spotlightMasking == 3) && currentVideo.VideoUrl != null"
        style="height:300px;"
        :src="currentVideo.VideoUrl"
        autoplay
        controls
        playsinline
        controlsList="nodownload"
        class="audioOnly"
      />
      <video
        v-if="!candidateApplication.Job?.MaskCandidateVideo && currentVideo.VideoUrl != null"
        v-show="spotlightMasking === 0"
        id="video"
        style="height:300px;"
        :src="currentVideo.VideoUrl"
        :poster="currentVideo.ThumbnailUrl"
        autoplay
        controls
        playsinline
        controlsList="nodownload"
        preload="none"
        type="video/mp4"
        @play="onPlay"
      />
    </div>
    <div
      v-if="loadingStream "
      style="text-align: center;color: white;z-index: 1000001;position: absolute;top: 195px;width: 100%;font-weight:bold;font-size:1.3rem;"
    >
      <i class="fa fa-spin fa-spinner" /> LOADING VIDEO 
    </div>
    <div
      v-if="stageType == 2 && !showVideoCVTab"
      style="cursor:pointer;width:100%;border: solid 2px #c0c0c0;border-top: none;"
      class="primary-colour d-flex justify-content-end align-items-center py-3 pr-2"
    >
      <ShareWithPopover
        :role-id="candidateApplication.JobId"
        :RoleWorkflowStageType="stageType"
        :RoleWorkflowStageId="candidateApplication.RoleWorkflowStageId"
        :candidate-id="candidate.CandidateId"
        @openShareModal="prepareAndShowShareModal()"
      />
    </div>
    <!-- numbered buttons -->
    <div v-if="stageType === 1" style="border: solid 2px #c0c0c0;border-top: none;background: #fff">
      <div class="p-3 d-flex align-items-center" style="border-bottom: #c0c0c0 1px solid">
        <div
          id="numberedButtonsContainer"
          style="width:100%;overflow: auto;"
          :style="{'width:calc(100% - 120px);border-right: #c0c0c0 1px solid' : !showVideoCVTab}"
        >
          <div style="width:fit-content;" class="d-flex align-items-center pr-2">
            <div
              v-for="(question, i) in candidateApplication.Questions"
              :key="i"
              class="mr-2 px-2 py-1 cp d-flex justify-content-center font-weight-bold"
              :class="{'selectedQuestionBox' : question.QuestionId === currentQuestionId }"
              style="border-radius: 5px;border:solid 3px var(--pr-color);width:30px"
              @click="setQuestion(question.QuestionId)"
            >
              {{ i + 1 }}
            </div>
          </div>
        </div>
        <ShareWithPopover
          v-if="!showVideoCVTab && (stageType == 1 || stageType == 2)"
          :role-id="candidateApplication.JobId"
          :RoleWorkflowStageType="stageType"
          :RoleWorkflowStageId="candidateApplication.RoleWorkflowStageId"
          :candidate-id="candidate.CandidateId"
          @openShareModal="prepareAndShowShareModal()"
        />
      </div>
      <!-- carousel -->
      <div class="d-flex align-items-center px-2" style="height:fit-content; min-height:80px;">
        <div class="pl-2">
          <i
            class="fa fa-caret-left"
            :class="{ 'primary-colour cp' : prevQuestionId !== null }"
            style="font-size:2rem;color:grey"
            @click="previousQuestion(prevQuestionId);"
          />
        </div>
        <div
          v-if="currentQuestion.Video != null"
          class="mobileQText qPad mx-auto questionText"
          style="white-space: pre-line !important;"
          :class="{ 'primary-colour' : currentQuestion.QuestionId === currentQuestionId && currentVideo.VideoUrl != null, 'prevClicked' : prevClicked, 'nextClicked': nextClicked }"
        >
          <div class="font-weight-bold text-center p-3 mx-4">
            {{ currentQuestion.QuestionText }}
          </div>
        </div>
        <div
          v-else
          class="mobileQText qPad mx-auto questionText"
          :class="{ 'primary-colour' : currentQuestion.QuestionId === currentQuestionId && currentVideo.VideoUrl != null, 'prevClicked' : prevClicked, 'nextClicked': nextClicked }"
        >
          <div class="font-weight-bold text-center p-3 mx-4">
            {{ currentQuestion.QuestionText }}
          </div>
        </div>
        <div class="pr-2">
          <i
            class="fa fa-caret-right"
            :class="{ 'primary-colour cp' : nextQuestionId !== null }"
            style="font-size:2rem;color:grey;"
            @click="nextQuestion(nextQuestionId);"
          />
        </div>
      </div>
    </div>

    <CandidateInformationModal
      :user-language="userLanguage"
      :current-video="currentVideo"
      :candidate="candidate"
      :spotlight-masking="spotlightMasking"
      :workflow-type="stageType"
      :candidate-application="candidateApplication"
      :candidate-information-modal="candidateInformationModal"
      @candidateInformationModal="candidateInformationModal = $event"
    />

    <!--transcription modal-->
    <mdb-modal
      id="transcriptionModal"
      :show="transcriptionModal"
      style="z-index:10000;"
      @close="closeTranscriptionModal()"
    >
      <mdb-modal-header>
        <mdb-modal-title>VIDEO RESPONSE TRANSCRIPTION</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeightForRoles': mobileView }">
        <div
          v-if="!candidateApp.Transcribed"
          class="my-4 grey-text"
          style="font-size:0.8rem;text-align:left;"
        >
          The transcription of the selected candidate's entire interview will be processed and emailed to you. Do you wish to continue?        
        </div>
        <div
          v-else
          class="my-4 grey-text"
          style="font-size:0.8rem;text-align:left;"
        >
          <span v-html="candidateApp.Transcription" />      
          <div
            style="border-top:solid 1px #c0c0c0;margin-top: 10px;"
            @click="toggleEmailPdf()"
          >
            <mdb-input
              v-model="emailPdf"
              type="checkbox"
              auto-complete="demo"
              label="Email PDF version?"
              style="margin-left:-20px;margin-top:30px;"
              size="sm"
            />
          </div>
        </div>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          v-if="!transcribing"
          type="submit"
          class="float-right"
          :disabled="emailPdf === false"
          size="sm"
          style="width:100px;"
          @click="confirmGetFullTranscription()"
        >
          YES
        </mdb-btn>
        <mdb-btn
          v-else
          class="float-right"
          size="sm"
          style="width:100px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { useUserData, useStatus, useRoles, useRoleCandidate } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import { CONVERT_STATUS_NAME } from '@/utils/convertStatus.js'
import roleV2Service from '@/api/services/roleV2.service'

import CandidateInformationModal from './candidateInformationModal.vue'
import ShareWithPopover from './shareWithPopover.vue'
import EmbedVideo from '@/components/misc/embedVideo.vue'
import LHSPopoverStatusChange from './LHSPopoverStatusChanges.vue'

export default {
  name: 'LHS',
  components: {
    CandidateInformationModal,
    ShareWithPopover,
    EmbedVideo,
    LHSPopoverStatusChange
  },
  props: {
    candidateApplication: {
      type: Object,
      default: () => {}
    },
    currentVideo: {
      type: Object,
      default: () => {}
    },
    candidate: {
      type: Object,
      default: () => {}
    },
    currentQuestion: {
      type: Object,
      default: () => {}
    },
    currentQuestionId: {
      type: Number,
      default: 0
    },
    stageType: {
      type: Number,
      default: 0
    },
    userLanguage: {
      type: String,
      default: ''
    },
    modalContext: {
      type: String,
      default: ''
    },
    showVideoCVTab: Boolean,
    spotlightMasking: {
      type: Number,
      default: 0
    }
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()
    const { mobileView } = useMobile()
    const rolesStore = useRoles()
    const {
      roleSharingModalShowing,
      roleSharingModalInitialData
    } = storeToRefs(rolesStore)

    const roleCandidateStore = useRoleCandidate()
    const { candidateTableData } = storeToRefs(roleCandidateStore)

    return {
      authData,
      userData,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal,
      mobileView,
      roleSharingModalShowing,
      roleSharingModalInitialData,
      candidateTableData
    }
  },
  data () {
    return {
      nextClicked: false,
      prevClicked: false,
      candidateInformationModal: false,
      transcriptionModal: false,
      reTranscribe: false,
      includeRaw: false,
      transcribing: false,
      emailPdf: false,
      candidateApp: this.candidateApplication,
      loadingStream: true
    }
  },
  computed: {
    nextQuestionId () {
      const nextQidx = this.candidateApplication.Questions.findIndex(q => q.QuestionId === this.currentQuestionId)
      try {
        return this.candidateApplication.Questions[nextQidx + 1].QuestionId
      } catch { return null }
    },
    prevQuestionId () {
      const prevQidx = this.candidateApplication.Questions.findIndex(q => q.QuestionId === this.currentQuestionId)
      try {
        return this.candidateApplication.Questions[prevQidx - 1].QuestionId
      } catch { return null }
    },
    tq () {
      return this.candidateApplication.Questions.length
    },
    no () {
      try {
        return this.candidateApplication.Questions.findIndex(q => q.QuestionId === this.currentQuestionId)
      } catch (err) { console.log(Object.assign({}, err), 'error in no computed value - LHS'); return 0 }
    }
  },
  watch: {
    currentQuestionId (to, from) {
      const toIndex = this.candidateApplication.Questions.findIndex(q => q.QuestionId === to)
      const fromIndex = this.candidateApplication.Questions.findIndex(q => q.QuestionId === from)
      if (toIndex < 4) {
        document.getElementById('numberedButtonsContainer').scrollLeft = 0
      } else if (toIndex > this.candidateApplication.Questions.length - 3) {
        document.getElementById('numberedButtonsContainer').scrollLeft = 9999
      } else {
        document.getElementById('numberedButtonsContainer').scrollLeft += toIndex > fromIndex ? 39 : -39
      }
    },
    currentVideo: {     
      handler: function () {
        if (this.currentVideo.VideoUrl === null || this.currentVideo.VideoUrl === undefined) {
          this.getVideoStream()
        } else {
          this.loadingStream = true;
        }
      },
      immediate: true
    }
  },
  methods: {
    onPlay() {
      this.loadingStream = false;
    },
    toggleEmailPdf () {
      if (this.emailPdf === true) {
        this.emailPdf = false
      } else {
        this.emailPdf = true
      }
    },
    toggleReTranscribe () {
      if (this.reTranscribe === true) {
        this.reTranscribe = false
      } else {
        this.reTranscribe = true
      }
    },
    toggleIncludeRaw () {
      if (this.includeRaw === true) {
        this.includeRaw = false
      } else {
        this.includeRaw = true
      }
    },
    convertStatusName (status) {
      return CONVERT_STATUS_NAME(status)
    },
    prepareAndShowShareModal () {
      const shareType = this.stageType == 1 ? 'one-way' : this.stageType == 2 ? 'two-way' : ''
      this.roleSharingModalInitialData = {
        roleId: this.candidateApplication.JobId,
        shareType,
        roleWorkflowStageId: this.candidateApplication.RoleWorkflowStageId,
        candToShare: { ...this.candidate, Status: this.candidateTableData.RoleWorkflowStageItemId }
      }
      this.roleSharingModalShowing = true
    },
    setStatus (type) {
      this.$emit('setStatus', type)
    },
    nextQuestion () {
      this.loadingStream = true
      if (this.nextQuestionId != null) {
        this.nextClicked = true
        setTimeout(() => { this.nextClicked = false }, 200)
        this.$emit('setQuestionId', this.nextQuestionId)
      }
    },
    previousQuestion () {
      this.loadingStream = true
      if (this.prevQuestionId != null) {
        this.prevClicked = true
        setTimeout(() => { this.prevClicked = false }, 200)
        this.$emit('setQuestionId', this.prevQuestionId)
      }
    },
    setQuestion (questionId) {
      this.loadingStream = true
      this.$emit('setQuestionId', questionId)
    },
    async getVideoStream () {
      this.loadingStream = true
      try {
        var videoId = this.currentVideo.CandidateVideoId
        if (videoId !== undefined) {
          const response = await roleV2Service.getCandidateVideoStream(videoId)
          this.$emit('setVideoStream', response.data)
        } else {
          this.loadingStream = false
        }
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      setTimeout(() => {
        this.loadingStream = false
      }, 500)
    },
    async getFullTranscription() {
      this.emailPdf = false
      try {
        var res = await roleV2Service.checkTranscriptions(this.candidateApplication.InvitationCode, this.candidateApplication.CandidateId, this.candidateApplication.JobId, this.candidateApplication.RoleWorkflowStageId)
        this.candidateApp = res.data
        this.transcriptionModal = true
        if (!this.candidateApp.Transcribed) {
          this.emailPdf = true
        }
      } catch (err) { 
        //?
      }
    },
    async confirmGetFullTranscription() {
      this.transcribing = true
      roleV2Service.getFullTranscription(this.candidateApplication.InvitationCode, false, true)
      setTimeout(() => {
        this.transcribing = false
        this.transcriptionModal = false
        this.includeRaw = false
        this.reTranscribe = false
        this.emailPdf = false
      }, 30000)
    },
    closeTranscriptionModal () {
      this.transcribing = false
      this.transcriptionModal = false
      this.includeRaw = false
      this.reTranscribe = false
      this.emailPdf = false
    }
  }
}
</script>

<style lang="scss" scoped>
  video[poster] {
    width:480px !important;
    object-fit: cover;
  }
  .lhsTopBanner {
    font-weight:bold;
    background:var(--pr-color);
    color: var(--lk-color) !important;
    min-height:56px;
    height:fit-content;
    display: grid;
    grid-template-columns: auto 50px;
    align-items: center;

  }
  .audioOnly {
    height:58% !important;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  * ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  * {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .selectedQuestionBox {
    background: var(--pr-color);
    color: #fff;
  }
  .questionText {
    transform: translateX(0);
    transition: ease 0.1s;
    opacity: 1;
  }
  .questionText.prevClicked {
    transition: transform ease 0.1s;
    opacity: 0;
    transform: translateX(-20px);
  }
  .questionText.nextClicked {
    opacity: 0;
    transition: transform ease 0.1s;
    transform: translateX(20px);
  }
 .mobileWatchF {
    font-size:1.5rem !important;
  }
  .mobileWatchS {
    font-size:0.8rem !important;
  }
</style>
