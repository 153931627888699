// version 6 font awesome
import {
  faListOl,
  faRepeat,
  faLock,
  faSync,
  faCode,
  faListUl,
  faGrid,
  faUsers,
  faUserGroup,
  faCamera,
  faLink,
  faPen,
  faTrashCanXmark,
  faGears,
  faFile,
  faVideo,
  faShareFromSquare,
  faCalendarDay,
  faClockDesk,
  faLampDesk,
  faUser,
  faLeaf,
  faSquarePen,
  faBoxArchive,
  faShareNodes,
  faUserTag,
  faBullhorn,
  faEye,
  faPenLine,
  faCircleQuestion,
  faLockKeyhole,
  faCalendarClock,
  faHexagonCheck,
  faRotate,
  faArrowRotateLeft,
  faEnvelope,
  faEnvelopes,
  faLinkSimple,
  faCheck,
  faCircleCheck,
  faCircleXmark,
  faPlay,
  faRectangleVerticalHistory,
  faImagePolaroid,
  faShuffle,
  faUserGear,
  faFilterList,
  faChartSimpleHorizontal,
  faIdCard,
  faBadgeCheck,
  faArrowsRepeat,
  faLocationDot,
  faLayerGroup,
  faSuitcase as faSolidSuitcase,
  faMobileScreenButton,
  faArrowRight,
  faClockRotateLeft,
  faGripDots,
  faInfinity,
  faX,
  faRotateRight,
  faCircle,
  faArrowRightToArc,
  faArrowRightToLine,
  faSignature,
  faInputText,
  faTimesCircle,
  faPenField,
  faEdit
} from '@fortawesome/pro-solid-svg-icons'

import {
  faEllipsisVertical,
  faCircleInfo,
  faSuitcase,
  faCirclePlus,
  faCircleChevronRight,
  faCircleChevronLeft,
  faCircleChevronDown,
  faCircleChevronUp,
  faCircleEnvelope,
  faCirclePlay,
  faCopy,
  faTimes,
  faUserPlus,
  faSquareSliders,
  faPhotoFilm,
  faFaceHandPeeking,
  faCheckDouble,
  faSquarePollVertical,
  faSquare,
  faCalendar,
  faDiamondExclamation,
  faGear,
  faCalendarXmark,
  faCalendarDays,
  faMagnifyingGlassArrowRight,
  faStar,
  faCircleStar,
  faPeople,
  faMobile,
  faMobileScreenButton as faMobileScreenButtonRegular,
  faBarsProgress,
}
from '@fortawesome/pro-regular-svg-icons'

// font awesome
export default [
  faClockRotateLeft,
  faRotateRight,
  faSignature,
  faEdit,
  faTimes,
  faTimesCircle,
  faBarsProgress,
  faPeople,
  faMobile,
  faCircleStar,
  faShareFromSquare,
  faMagnifyingGlassArrowRight,
  faArrowRight,
  faSolidSuitcase,
  faMobileScreenButton,
  faCalendar,
  faDiamondExclamation,
  faGear,
  faArrowsRepeat,
  faSquare,
  faSquarePollVertical,
  faBadgeCheck,
  faIdCard,
  faCircleChevronLeft,
  faArrowRightToLine,
  faCheckDouble,
  faChartSimpleHorizontal,
  faFaceHandPeeking,
  faPhotoFilm,
  faInputText,
  faEllipsisVertical,
  faCircleInfo,
  faSync,
  faSuitcase,
  faRepeat,
  faLock,
  faCode,
  faListOl,
  faCircleChevronRight,
  faCirclePlay,
  faCircleChevronDown,
  faCircleChevronUp,
  faCircleEnvelope,
  faCopy,
  faLeaf,
  faSquareSliders,
  faLampDesk,
  faCirclePlus,
  faListUl,
  faGrid,
  faUsers,
  faUserPlus,
  faFile,
  faUserGroup,
  faCamera,
  faFilterList,
  faPen,
  faLink,
  faTrashCanXmark,
  faGears,
  faVideo,
  faCalendarDay,
  faClockDesk,
  faUser,
  faBoxArchive,
  faShareNodes,
  faUserTag,
  faBullhorn,
  faEye,
  faPenLine,
  faCircleQuestion,
  faLockKeyhole,
  faCalendarClock,
  faHexagonCheck,
  faRotate,
  faArrowRotateLeft,
  faEnvelope,
  faEnvelopes,
  faLinkSimple,
  faCheck,
  faCircleCheck,
  faCircleXmark,
  faPlay,
  faGripDots,
  faRectangleVerticalHistory,
  faImagePolaroid,
  faShuffle,
  faUserGear,
  faCalendarXmark,
  faCalendarDays,
  faLocationDot,
  faLayerGroup,
  faStar,
  faMobileScreenButtonRegular,
  faSquarePen,
  faInfinity,
  faX,
  faCircle,
  faArrowRightToArc,
  faPenField
]
