<template>
  <div id="main">
    <div
      v-if="stageTemplateId === 0"
      class="mt-3 font-weight-bold"
      style="font-size:0.9rem"
    >
      SCORING HAS BEEN DISABLED FOR THIS ROLE
    </div>
    <div v-else>
      <div v-if="!showDetail" class="mt-2">
        <span
          v-if="!loading && !userHasStartedScoringWithThisTemplate"
          style="cursor:pointer;"
          @click="addScoring()"
        >
          <p style="font-size:small;" class="grey-text font-weight-bold mb-2">
            <span style="cursor:pointer;"><i class="fa fa-plus-circle mr-2" />Add Score</span>
          </p>
        </span>
        <span v-if="!loading">
          <ScoringItem
            v-for="({ Name, IsModerator, Score, MaxScore, scoringItemId, QuestionOverview, UserId, ProfilePicture, TemplateId, TemplateName }, index) in scoring"
            :key="index"
            v-bind="{ Name, IsModerator, Score, MaxScore, scoringItemId, QuestionOverview, UserId, ProfilePicture, TemplateId, TemplateName }"
            :selected="selectedScoringItem"
            :role-template-id="stageTemplateId"
            :user-has-started-scoring="userHasStartedScoring"
            :questions="questions"
            @questionSelect="questionSelect($event)"
            @viewRoleTemplate="viewRoleTemplate($event)"
          />
        </span>
        <div v-else class="mt-3">
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </div>
      </div>
      <!-- QuestionDetail -->
      <div v-else>
        <ScoringDetail
          style="width:100%"
          :scoring-item-user-id="scoringItemUserId"
          :selected-question="selectedQuestion"
          :candidate-application="candidateApplication"
          :role-template-id="stageTemplateId"
          :template-id="templateId"
          @showDetail="showDetail = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import scoringService from '@/api/services/scoringV2.service.js'

import ScoringItem from '@/components/misc/ReviewModalV2/reviewModalComponents/scoring/ScoringItem'
import ScoringDetail from '@/components/misc/ReviewModalV2/reviewModalComponents/scoring/ScoringDetail'

export default {
  components: {
    ScoringItem,
    ScoringDetail
  },

  props: {
    questions: {
      type: Array,
      default: () => []
    },
    currentQuestionId: {
      type: Number,
      default:0
    },
    candidateApplication: {
      type: Object,
      default: () => {}
    }
  },

  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()

    return {
      authData,
      userData,
      showGenericErrorModal
    }
  },

  data () {
    return {
      selectedScoringItem: null,
      selectedNumber: null,
      showDetail: false,
      scoring: [{ Name: '', Score: 0, MaxScore: 10, QuestionOverview: [] }],
      loading: false,
      scoringItemUserId: '',
      templateId: 0
    }
  },

  computed: {
    stageTemplateId () {
      // - 1 means scoring per question, 0 = no scoring, > 0 is scoring per stage
      return this.candidateApplication.GoScoringTemplateId
    },
    selectedQuestion () {
      return this.questions.filter(q => q.QuestionId === this.currentQuestionId)[0]
    },
    userHasStartedScoring () {
      return this.scoring.filter(s => s.UserId === this.userData.uid).length > 0
    },
    userHasStartedScoringWithThisTemplate () {
      if (this.stageTemplateId === -1) {
        return this.scoring.filter(s => s.UserId === this.userData.uid).length > 0
      } else {
        return this.scoring.filter(s => s.UserId === this.userData.uid && s.TemplateId > 0 && s.TemplateId === this.stageTemplateId).length > 0
      }
    }

  },

  watch: {
    showDetail: {
      handler: function () {
        if (this.showDetail === false) {
          this.getScoreOverview()
        }
      },
      immediate: true
    }
  },

  methods: {
    questionSelect ({ number, questionId, index, UserId }) {
      this.selectedNumber = number
      this.selectedScoringItem = index
      this.scoringItemUserId = UserId
      this.showDetail = true
      this.$emit('setQuestionId', questionId)
    },
    viewRoleTemplate ({ userId, templateId }) {
      this.scoringItemUserId = userId
      this.templateId = templateId
      this.showDetail = true
    },
    addScoring () {
      let userScore = { Name: 'Your', Score: 0, MaxScore: 0, UserId: this.userData.uid, TemplateId: this.stageTemplateId, TemplateName: '', ProfilePicture: this.userData.pic }
      this.templateId = this.stageTemplateId
      if (this.stageTemplateId === -1) {
        const QuestionOverview = []
        this.questions.forEach(q => {
          if (q.GoScoringTemplateId !== 0) {
            QuestionOverview.push({ QuestionId: q.QuestionId, Description: q.QuestionText, MaxScore: 0, Score: 0 })
          }
        })
        userScore = { ...userScore, QuestionOverview }
      }
      this.scoring.splice(0, 0, userScore)
    },
    async getScoreOverview () {
      this.loading = true
      const JSONData = {
        RoleWorkflowStageId: this.candidateApplication.RoleWorkflowStageId,
        CandidateId: this.candidateApplication.CandidateId,
        InviteCode: this.candidateApplication.InvitationCode
      }
      try {
        const res = await scoringService.getScoreOverview(JSONData)
        this.scoring = [...res.data]
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  }
}
</script>
