<template>
  <div>
    <img
      v-if="imgSrc && !maskUser"
      :style="`height: ${dimension}px; width: ${dimension}px;`"
      style="border:solid 1px #c0c0c0;border-radius:50%;object-fit:cover;"
      :src="imgSrc"
    >
    <div
      v-else
      class="d-flex align-items-center justify-content-center"
      :style="`height: ${dimension}px; width: ${dimension}px; padding: ${Math.floor(dimension / 5)}`"
      style="border:solid 1px #c0c0c0;border-radius:50%;"
    >
      <i
        class="fa fa-user grey-text"
        aria-hidden="true"
        :style="`font-size: ${Math.floor(dimension / 2)}px !important; padding: ${Math.floor(dimension / 5)}`"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProfilePicture',
  props: {
    imgSrc: {
      type: String,
      default: ''
    },
    maskUser: {
      type: Boolean,
      defalut: false
    },
    dimension: { // used as width + height px
      type: Number,
      default: 50
    }
  }
}
</script>

<style lang="scss" scoped>

</style>