
<template>
  <div
    v-if="role.JobId"
    class="px-3 px-md-4 text-left h100"
  >
    <!-- JOB TITLE -->
    <div style="height:100px; width: 100%;" class="d-flex align-items-center h5 m-0">
      <div class="shadowBorder p-3 flex-grow-1 mr-3">
        <FontAwesomeIcon icon="fa-solid fa-suitcase" class="mr-2" />
        <span class="font-weight-bold">{{ role.JobTitle }}</span>
        <span style="float:right;font-size:medium;margin-top:3px;">Role Id: {{ role.JobId }}</span>
      </div>
      <!-- QUICK SET CLOSING DATE -->
      <div class="shadowBorder ">
        <div v-if="!role.IsEvergreen" class="d-flex align-items-center">
          <div class="d-flex flex-column px-4 py-2">
            <div
              :style="`border-bottom: 2px solid ${userData.lbl.PrimaryColour}`"
              class="d-flex align-items-center mb-1 pb-1"
            >
              <div
                style="font-size: 0.9rem;cursor: pointer;"
                class="primary-colour font-weight-bold"
                :title="`Actual closing date is ` + new Date(role.Expires).toLocaleDateString() + ` at ` + new Date(role.Expires).toLocaleTimeString([], {timeStyle: 'short', hour12: true}).toUpperCase()"
              >
                {{ !role.CloseDateIsPast ? 'CLOSES' : 'CLOSED' }}
              </div>
              <!-- <i class="far fa-edit ml-2 dark-grey-text" style="font-size: .8rem;" /> -->
            </div>
            <input
              type="date"
              :min="minimumDate"
              onkeydown="return false"
              :value="closingDate"
              auto-complete="opd"
              label="Closing date"
              style="font-size: 0.8rem;"
              size="sm"
              class="primary-colour cp"
              @change="saveNewClosingDate"
            >
          </div>
        </div>
        <!-- EVERGREEN -->
        <div v-else-if="!role.CloseDateIsPast" class="p-3 d-flex align-items-center">
          <mdb-btn
            class="m-0 word-wrap"
            size="sm"
            style="max-width: 130px"
            @click="saveNewClosingDate"
          >
            CLOSE ROLE
          </mdb-btn>
        </div>
        <!-- EVERGREEN -->
        <div v-else class="p-3 d-flex align-items-center">
          <mdb-btn
            class="btn-outline m-0 word-wrap"
            size="sm"
            style="max-width: 130px"
            @click="saveNewClosingDate"
          >
            SET AS OPEN
          </mdb-btn>
        </div>
      </div>
    </div>
    <!-- ROLE SETUP -->
    <div class="d-flex align-items-center justify-content-between shadowBorder p-3 flex-wrap flex-sm-nowrap" style="font-size:0.8rem;">
      <div v-if="!mobileView" class="h5 dark-grey-text m-0 font-weight-bold">
        Role Setup
      </div>
      <div
        v-for="(option, i) in roleSetupOptions"
        :key="i"
        class="d-flex align-items-center roleSetupOptionBorder mr-2 text-center py-1 px-2 cp my-1"
        :class="option.showCheckIcon ? 'checked' : 'not-checked'"
        @click="goToOptions(option.query, option.minimumStep)"
      >
        <FontAwesomeIcon
          v-if="option.showCheckIcon"
          icon="fa-solid fa-circle-check"
          class="primary-colour"
          style="font-size:1.6rem;"
        />
        <i
          v-else
          class="far fa-times-circle grey-text"
          style="font-size:1.6rem;"
        />
        <span
          style="font-size:clamp(0.7rem, 1.5vw, 1.1rem)"
          class="font-weight-bold ml-1"
          :class="option.showCheckIcon ? 'primary-colour' : 'text-black'"
        >{{ option.text }}</span>
      </div>
    </div>
    <!-- SELF REGISTRATION -->
    <!-- this is hidden if registartion type is through shinego only -->
    <div v-if="(role.InterviewType == 2 || role.InterviewType == 3) && roleWorkflowId && role.IsComplete" class="mt-4">
      <div class="d-flex align-items-center shadowBorder p-3 flex-wrap flex-sm-nowrap" style="font-size:0.8rem;">
        <div class="h5 dark-grey-text m-0 font-weight-bold mr-3">
          Invite
        </div>
        <div class="font-weight-bold text-white background-colour h5 m-0 p-2 px-3 br20">
          Self Registration
        </div>
        <div
          style="border: solid #555 2px"
          class="d-flex align-items-center cp br20 ml-3 p-2"
          @click="copySelfRegLinkClipBoard(`${SELF_REGISTRATION_URL}/#/${userData.fnm}/${role.JobId}`)"
        >
          <FontAwesomeIcon icon="fa-copy fa-regular" class="ml-1 mr-2 black-text" />
          <div style="font-size:clamp(0.7rem, 2vw, 0.8rem);max-width:80%;" class="mr-auto black-text textEllipsisOneLine">
            {{ SELF_REGISTRATION_URL }}/#/{{ userData.fnm }}/{{ role.JobId }}
          </div>
          <FontAwesomeIcon icon="fa-file fa-solid" class="mx-1" />
          <span class="mx-1" style="font-size:0.8rem">{{ copiedSelfRegLink ? 'COPIED' : 'COPY' }}</span>
        </div>
      </div>
    </div>
    <!-- WORKFLOW SECTION -->
    <div class="shadowBorder mt-4 p-3">
      <div class="h5 dark-grey-text m-0 font-weight-bold mr-3 mb-3">
        Interview Stage Design
      </div>
      <WorkflowTemplateContainer
        v-if="!roleWorkflowId"
      />
      <WorkflowBuilder
        v-else
        :restricted="true"
        :role-workflow-id="roleWorkflowId"
      />
    </div>
    <div class="w-100 mt-4 d-flex justify-content-between mb-3">
      <mdb-btn
        class="m-0"
        size="sm"
        style="width:100px;"
        @click="finishThis()"
      >
        FINISH
      </mdb-btn>
      <mdb-btn
        class="btn-outline m-0 ml-auto"
        size="sm"
        style="width:100px;"
        @click="$router.go(-1)"
      >
        BACK
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { SELF_REGISTRATION_URL } from '@/common/config.js'
import WorkflowTemplateContainer from '@/views/roles/innerComponents/Pages/AddEditRole/RoleDetail/WorkflowTemplate/workFlowTemplateContainer.vue'
import WorkflowBuilder from '@/views/roles/innerComponents/Pages/AddEditRole/RoleDetail//WorkflowBuilder2/workflowBuilder.vue'
import moment from 'moment'
import { useUserData, useRoles, useStatus, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router/composables'
import { watch, computed } from 'vue-demi'
import { useMobile } from '@/composables/useMobile'

export default {
  name: 'RoleDetail',
  components: {
    WorkflowTemplateContainer,
    WorkflowBuilder
  },
  setup () {
    const route = useRoute()
    const userStore = useUserData()
    const workflowStore = useWorkflow()
    const roleStore = useRoles()

    const { userData } = storeToRefs(userStore)
    const { getRole, addEditRoleSetup } = useWorkflow()
    const { role } = storeToRefs(workflowStore)
    const { getRecentRoles } = storeToRefs(roleStore)
    const { showGenericErrorModal } = useStatus()
    const { mobileView } = useMobile()

    getRole(route.params.jobId)

    const roleId = computed(() => route.params.jobId)

    watch(roleId, () => {
      getRole(roleId.value)
    })

    return {
      userData,
      showGenericErrorModal,
      role,
      getRecentRoles,
      addEditRoleSetup,
      mobileView
    }
  },
  data () {
    return {
      copiedSelfRegLink: false,
      minimumDate: '',
      closingDate: ''
    }
  },
  computed: {
    openFromDate () {
      return this.role.OpenFrom
    },
    SELF_REGISTRATION_URL () {
      return SELF_REGISTRATION_URL
    },
    roleWorkflowId () {
      return this.role.RoleWorkflowId
    },
    roleSetupOptions () {
      return [
        { text: 'About This Role', query: 'about_this_role', minimumStep: 0, showCheckIcon: this.role.Step > 0 },
        { text: 'Invitation Options', query: 'invitation_options', minimumStep: 1, showCheckIcon: this.role.Step > 1 },
        { text: 'Branding Options', query: 'branding_options', minimumStep: 2, showCheckIcon: this.role.Step > 2 },
        { text: 'Masking Options', query: 'masking_options', minimumStep: 3, showCheckIcon: this.role.Step > 3 }
      ]
    }
  },
  watch: {
    'role.OpenFrom': {
      deep: true,
      immediate: true,
      handler: 'setClosingDateFormField'
    }
  },
  methods: {
    async saveNewClosingDate (event) {
      let dateYesterday = new Date()
      dateYesterday.setDate(dateYesterday.getDate() - 1)
      let maxDate = new Date(8640000000000)
      const date = !this.role.IsEvergreen ? event.target.value : this.role.CloseDateIsPast ? maxDate : dateYesterday
      if (await this.addEditRoleSetup({ ...this.role, Expires: date, Step: 1 })) {
        this.closingDate = moment(String(this.role.Expires)).format('YYYY-MM-DD')
        if (this.role.IsEvergreen) { this.role.CloseDateIsPast = !this.role.CloseDateIsPast }
      }
    },
    setMinimumDate (date) {
      var month = date.getMonth() + 1
      var day = date.getDate()
      var year = date.getFullYear()
      if (month < 10) {
        month = '0' + month.toString()
      }
      if (day < 10) {
        day = '0' + day.toString()
      }
      this.minimumDate = year + '-' + month + '-' + day
    },
    copySelfRegLinkClipBoard (value) {
      navigator.clipboard.writeText(value)
      this.copiedSelfRegLink = true
      setTimeout(() => { this.copiedSelfRegLink = false }, 2000)
    },
    goToOptions (route, minimumStep) {
      if (this.role.Step >= minimumStep) {
        this.$router.push({ path: `/roles/add_edit/role_setup/${this.role.JobId}`, query: { page: route } }).catch(() => { })
      }
    },
    setClosingDateFormField () {
      this.setMinimumDate(new Date(this.role.OpenFrom))
      this.closingDate = moment(String(this.role.Expires)).format('YYYY-MM-DD')
    },
    async finishThis() {
      await this.getRecentRoles()
      this.$router.push('/roles')
    }
  }
}
</script>

<style lang="scss" scoped>

.shadowBorder {
  border-radius: 5px;
  border: 1px solid var(--gr-color);
  box-shadow: 3px 3px 15px -5px grey;
}
.roleSetupOptionBorder {
  border: solid 2px;
  border-radius: 20px;
}
.roleSetupOptionBorder.checked {
  border-color: var(--por-color);
}
.roleSetupOptionBorder.not-checked {
  border-color: var(--gr-color);
}
</style>
