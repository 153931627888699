import RolesV2 from '@/views/roles/rolesContainer.vue'
import CandidatesTable from '@/views/roles/innerComponents/Pages/Candidates/roleCandidatesContainer.vue'
import RoleDetail from '@/views/roles/innerComponents/Pages/AddEditRole/RoleDetail/roleDetail.vue'
import RoleLanding from '@/views/roles/innerComponents/Pages/RolesLanding/rolesLanding'
import RoleSetup from '@/views/roles/innerComponents/Pages/AddEditRole/RoleSetup/roleSetupContainer.vue'
import OneWayStageSetup from '@/views/roles/innerComponents/Pages/AddEditRole/StageSetup/OneWaySetup/oneWayStageSetupContainer.vue'
import TwoWayStageSetup from '@/views/roles/innerComponents/Pages/AddEditRole/StageSetup/TwoWaySetup/twoWayStageSetupContainer.vue'
import EventSetupContainer from '@/views/roles/innerComponents/Pages/AddEditRole/StageSetup/EventSetup/eventSetupContainer.vue'
import Slots from '@/views/roles/innerComponents/Pages/AddEditRole/SchedulerSetup/slots.vue'

const routes = [
  {
    path: '/roles',
    component: RolesV2,
    meta: { requiresLogin: true },
    children: [
      {
        path: '',
        name: 'landing',
        component: RoleLanding
      },
      {
        path: 'candidates/:jobId/:roleWorkflowStageId/:roleWorkflowStageItemId',
        name: 'candidates',
        component: CandidatesTable
      },
      {
        path: 'add_edit/detail/:jobId',
        name: 'detail',
        component: RoleDetail
      },
      {
        path: 'add_edit/role_setup/:jobId',
        name: 'role_setup',
        component: RoleSetup
      },
      {
        path: 'add_edit/two_way_stage_setup/:jobId/:roleWorkflowStageId',
        name: 'two_way',
        component: TwoWayStageSetup
      },
      {
        path: 'add_edit/one_way_stage_setup/:jobId/:roleWorkflowStageId',
        name: 'one_way',
        component: OneWayStageSetup
      },
      {
        path: 'add_edit/event_setup/:jobId/:roleWorkflowStageId',
        name: 'event',
        component: EventSetupContainer
      },
      {
        path: 'add_edit/scheduler_setup/:jobId/:roleWorkflowStageId/slots',
        name: 'scheduler_slots',
        component: Slots
      }
    ]
  }
]
export default routes
