import { storeToRefs } from 'pinia'
import { useUserData } from "../pinia/useUserData";
import { useStatus } from "../pinia/useStatus";

export const logout = async () => {
  const userDataStore = useUserData()
  const { authData, leftColumnActive } = storeToRefs(userDataStore)
  const { closeGenericConfirmModal, closeGenericErrorModal } = useStatus()

  closeGenericConfirmModal()
  closeGenericErrorModal()
  location.reload()
  userDataStore.clearUserData()
  authData.value = {}
  leftColumnActive.value = false
}
