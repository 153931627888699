<template>
  <div class="min-h-full pt-2 pt-md-4 px-3 px-md-5 w-1/1 text-black rounded" style="height:calc(100vh - 190px);overflow-y:auto;margin-bottom:65px;overflow-x:hidden">
    <div ref="scrollToDiv" style="height: 1px;">
      <div v-show="candidatesLoaded === false" :class="{ 'marginTopSections': mobileView === false }">
        <h3 class="primary-colour text-left font-weight-bold" :class="{ 'mobileTopSections': mobileView }">
          Loading
          <i
            style="margin-left: 5px !important;"
            class="fa fa-spin fa-spinner ml-3"
            :class="{ 'mobileTopSectionSpinner': mobileView }"
          />
        </h3>
      </div>
      <div v-show="candidatesLoaded === true" class="grey-text text-left mb-4 mx-0">
        <div clas="d-flex">
          <p class="font-weight-bold" style="margin-bottom:20px;">
            Candidates Summary
          </p>
          <p class="grey-text" style="font-size:12px;">
            Download candidate summary data by clicking the button below
          </p>
          <mdb-btn
            v-show="!spinningFlag"
            class="fWidth"
            color="primary"
            size="sm"
            style="width:333px;margin-top:20px;margin-bottom:20px;margin-left:0px;padding: 0.36rem 1.1rem;"
            @click.native="downloadCandidateSummary()"
          >
            DOWNLOAD SUMMARY <i class="fa fa-download" />
          </mdb-btn>
          <mdb-btn
            v-show="spinningFlag"
            class="fWidth"
            color="primary"
            size="sm"
            style="width:333px;margin-top:20px;margin-bottom:20px;margin-left:0px;padding: 0.36rem 1.1rem;"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import reportService from '@/api/services/report.service'

export default {
  name: '',
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    return {
      mobileView,
      authData
    }
  },
  data () {
    return {
      candidatesLoaded: false,
      spinningFlag: false
    }
  },
  created () {
    setTimeout(() => { this.candidatesLoaded = true }, 3000)
  },
  methods: {
    async downloadCandidateSummary () {
      this.spinningFlag = true
      try {
        const res = await reportService.downloadCandidateSummary()
        this.spinningFlag = false
        if (res.data) {
          window.location.assign(res.data)
        }
      } catch (err) {
        this.spinningFlag = false
        console.log(Object.assign({}, err))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.extraMarginTop {
  margin-top:35px !important;
}
@media (max-width: 639px) {
  .fWidth {
    width:100% !important;
  }
}
</style>
