<template>
  <div
    class="col-sm mx-0 mx-lg-3 vLib"
    :class="mobileView ? 'pt-3' : 'pt-0'"
    style="overflow-y:hidden;width:100%;"
  >
    <div class="font-weight-bold text-left my-3 d-flex align-items-center justify-content-between" style="height:50px">
      <div class="d-flex align-items-center">
        <span class="d-block d-sm-none">
          <span v-if="videoIntroFilterType === 'My Shine Intros'">My Intros</span>
          <span v-if="videoIntroFilterType === 'Archived Shine Intros'">Archived Intros</span>
        </span>
        <span class="d-none d-sm-block">
          {{ videoIntroFilterType }}
        </span>
        <i
          v-if="loadingRecordings"
          style="margin-left: 5px !important;"
          class="fa fa-spin fa-spinner ml-3 d-none d-sm-block"
        />
      </div>
      <ExpandableSearchBar
        :width="200"
        :max-widthpercentage="50"
        @search="search = $event"
      />

      <div class="ml-3 d-flex">
        <div
          style="cursor:pointer;border-radius:50%;height:40px;width:40px;"
          class="listViewSelected align-items-center justify-content-center mr-1 d-none d-md-flex"
          :style="{'background' : listView === false ? `${userData.lbl.PrimaryColour}66` : 'none'}"
        >
          <FontAwesomeIcon
            icon="fa-solid fa-grid"
            style="height:25px;width:25px;"
            @click="listView = false"
          />
        </div>
        <div
          style="cursor:pointer;border-radius:50%;height:30px;width:30px;"
          class="listViewSelected align-items-center justify-content-center mr-1 d-flex d-md-none"
          :style="{'background' : listView === false ? `${userData.lbl.PrimaryColour}66` : 'none'}"
        >
          <FontAwesomeIcon
            icon="fa-solid fa-grid"
            style="height:17px;width:17px;"
            @click="listView = false"
          />
        </div>
        <div
          style="cursor:pointer;border-radius:50%;height:40px;width:40px;"
          class="listViewSelected align-items-center justify-content-center mr-1 d-none d-md-flex"
          :style="{'background' : listView === true ? `${userData.lbl.PrimaryColour}66` : 'none'}"
        >
          <FontAwesomeIcon
            icon="fa-solid fa-list-ul"
            style="height:25px;width:25px;"
            @click="listView = true"
          />
        </div>
        <div
          style="cursor:pointer;border-radius:50%;height:30px;width:30px;"
          class="listViewSelected align-items-center justify-content-center mr-1 d-flex d-md-none"
          :style="{'background' : listView === true ? `${userData.lbl.PrimaryColour}66` : 'none'}"
        >
          <FontAwesomeIcon
            icon="fa-solid fa-list-ul"
            style="height:17px;width:17px;"
            @click="listView = true"
          />
        </div>
      </div>
    </div>

    <div class="videoIntrosContentHeight">
      <Recordings
        :video-intro-filter-type="videoIntroFilterType"
        :list-view="listView"
        :search="search"
      />
    </div>

    <div>
      <CreateNewIntroModal
        :create-new-video-intro-modal-showing="createIntroModalShowing"
        @createNewVideoIntroModalShowing="createIntroModalShowing = $event"
      />
    </div>

    <div
      v-show="mobileView"
      class="fixed bottom-0"
      style="background:white;width:100%;border-top: 1px solid #a1a1a1;z-index:1049;margin-left:-15px;height:55px;"
    >
      <div class="d-flex align-items-center justify-content-between" style="height:100%;">
        <div
          v-if="videoIntroFilterType === 'My Shine Intros'"
          class="underline ml-3"
          style="font-size:1rem;cursor:pointer;"
          @click="videoIntroFilterType = 'Archived Shine Intros'"
        >
          <i class="fa fa-archive" title="Archived Shine Intros" />
        </div>
        <div
          v-if="videoIntroFilterType === 'Archived Shine Intros'"
          class="underline ml-3"
          style="font-size:1rem;cursor:pointer;"
          @click="videoIntroFilterType = 'My Shine Intros'"
        >
          <i class="fa fa-bullhorn" title="My Shine Intros" />
        </div>
        <mdb-btn
          class="btn btn-solid-outline-mob mr-3"
          size="sm"
          style="width:110px;"
          @click="createNew()"
        >
          <i class="fa fa-plus-circle" /> ADD
        </mdb-btn>
      </div>
    </div>

    <!--left column bottom menu-->
    <div
      v-if="mobileView === false && (leftColumnActive === false || leftColumnActive === undefined)"
      class="fixed bottom-0 botVidBut moveCreateRoom"
      style="background:#d1d1d1;padding:12px;border-top: 1px solid #a1a1a1;margin-bottom: -5px;"
    >
      <div class="row" style="margin-left:25px;">
        <div class="col-sm-12 text-center">
          <mdb-btn
            size="sm"
            class="btn btn-solid-outline-web"
            style="width:110px;padding: 0.5rem 1.1rem;"
            @click="createNew()"
          >
            <i class="fa fa-plus-circle" /> ADD
          </mdb-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVideoIntro, useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import videoIntroService from '@/api/services/videointro.service'

import Recordings from './PageAndModals/recordings.vue'
import CreateNewIntroModal from './PageAndModals/CreateIntroModal/videoIntroModal.vue'
import ExpandableSearchBar from '@/components/UI/expandableSearch.vue'

export default {
  name: 'ShineIntros',
  components: {
    Recordings,
    CreateNewIntroModal,
    ExpandableSearchBar
  },
  setup () {
    const userStore = useUserData()
    const { userData, authData, leftColumnActive } = storeToRefs(userStore)

    const videoIntroStore = useVideoIntro()
    const { videoIntroFilterType, myIntros, archivedIntros } = storeToRefs(videoIntroStore)

    const { showGenericErrorModal } = useStatus()
    const { mobileView } = useMobile()

    return {
      userData,
      authData,
      leftColumnActive,
      videoIntroFilterType,
      myIntros,
      archivedIntros,
      showGenericErrorModal,
      mobileView
    }
  },
  data () {
    return {
      allSelected: false,
      createIntroModalShowing: false,
      listView: false,
      search: '',
      loadingRecordings: true
    }
  },
  computed: {
    filterOptions () {
      if (this.videoIntroFilterType === 'My Shine Intros') {
        return '<i class="fa fa-bullhorn" title="My Shine Intros"></i>'
      } else if (this.videoIntroFilterType === 'Archived Shine Intros') {
        return '<i class="fa fa-archive" title="Archived Shine Intros"></i>'
      } else { return '' }
    }
  },
  mounted () {
    this.videoIntroFilterType = 'My Shine Intros'
    this.getAllShineIntros()
  },
  methods: {
    createNew () {
      this.createIntroModalShowing = true
    },
    async getAllShineIntros () {
      this.loadingRecordings = true
      try {
        const res = await videoIntroService.getAllShineIntros()
        this.myIntros = res.data.MyIntros
        this.archivedIntros = res.data.ArchivedIntros
      } catch (err) {
        this.showGenericErrorModal({ showing: true, errorTitle: 'Error Fetching Intros', errorMessage: 'Something went wrong fetching your Shine Intros.' })
      }
      this.loadingRecordings = false
    }
  }
}
</script>

<style>
.listViewSelected {
  transition: ease 0.5s;
}
</style>

<style lang="css" scoped>
.videoIntrosContentHeight {
  height:calc(100vh - 220px)
}
@media (min-width: 768px) {
  .videoIntrosContentHeight {
    height:calc(100vh - 200px)
  }
}
</style>

<style lang="scss">
@media (max-width: 992px) {
  .moveCreateRoom {
    transform: translateX(15px)
  }
}
</style>
