<template>
  <div>
    <div class="p-2 p-md-3 p-lg-4">
      <b-row class="mb-3 p-2">
        <b-col cols="12" lg="6">
          <div class="d-flex w-100 justify-content-between align-items-center">
            <h3 style="font-size:15px;" class="text-left mb-2">
              Share With
            </h3>
            <p
              class="grey-text mb-2"
              style="font-size:13px;cursor:pointer;"
              @click="clearStandardSharingWith()"
            >
              CLEAR ALL
            </p>
          </div>
          <div style="border:1px solid #e1e1e1; height:100px;position:relative;overflow-y:scroll" class="p-1 p-lg-2 d-flex flex-wrap">
            <!-- standardShareWith -->
            <div
              v-for="email in sharingWithPage.standardShareWith"
              :key="email"
              class="px-1 m-1 m-md-2"
              style="outline:none;border:1px solid #C0C0C0; border-radius:5px;cursor:pointer;width:fit-content;height:fit-content;padding:3px;"
            >
              <div class="d-flex align-items-center justify-content-between">
                <p style="color:black !important;font-size:14px;">
                  {{ email }}
                </p>
                <i
                  style="cursor:pointer;color:#C0C0C0;"
                  class="fa fa-times colorOnHover ml-2"
                  @click="removeFromStandardShare(email)"
                />
              </div>
            </div>

            <div ref="scrollToDiv1" style="height: 50px;" />
          </div>
          <div style="position:absolute;right:25px;bottom:10px;height:20px;width:20px;">
            <i
              style="cursor:pointer;color:black; transform:scale(1.4);"
              class="fas fa-user-plus"
              @click="openStandardShareWithModal()"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          lg="6"
          class="mt-3 mt-lg-0"
        >
          <div class="d-flex w-100 justify-content-between align-items-center">
            <h3 style="font-size:15px;" class="text-left mb-2">
              Blind Share With (Bcc)
            </h3>
            <p
              class="grey-text mb-2"
              style="font-size:13px;cursor:pointer;"
              @click="clearBlindSharingWith()"
            >
              CLEAR ALL
            </p>
          </div>
          <div style="outline:none;border:1px solid #e1e1e1; height:100px;position:relative;overflow-y:scroll" class="p-1 p-lg-2 d-flex flex-wrap">
            <!-- standardShareWith -->
            <div
              v-for="email in sharingWithPage.blindShareWith"
              :key="email"
              class="px-1 m-1 m-md-2"
              style="border:1px solid #C0C0C0; border-radius:5px;cursor:pointer;width:fit-content;height:fit-content"
            >
              <div class="d-flex align-items-center justify-content-between">
                <p style="color:black !important;font-size:14px;">
                  {{ email }}
                </p>
                <i
                  style="cursor:pointer;color:#C0C0C0;"
                  class="fa fa-times colorOnHover ml-2"
                  @click="removeFromBlindShare(email)"
                />
              </div>
            </div>

            <div ref="scrollToDiv2" style="height: 50px;" />
          </div>
          <div style="position:absolute;right:25px;bottom:10px;height:20px;width:20px;">
            <i
              style="cursor:pointer;color:black; transform:scale(1.4);"
              class="fas fa-user-plus"
              @click="openBlindShareWithModal()"
            />
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-4 p-2">
        <b-col cols="12">
          <div class="d-flex w-100 justify-content-between align-items-center mb-1">
            <h3 style="font-size:15px;" class="text-left">
              Email Subject
            </h3>
            <p
              class="grey-text"
              style="font-size:13px;cursor:pointer;"
              @click="clearEmailSubject()"
            >
              CLEAR
            </p>
          </div>
          <input
            v-model="emailSubject"
            type="text"
            auto-complete="ref"
            style="outline:none;border:1px solid #e1e1e1;width:100%;color:black;"
            class="p-1"
            size="sm"
          >
        </b-col>
        <b-col>
          <div class="d-flex w-100 justify-content-between align-items-center mt-3 mb-1">
            <h3 style="font-size:15px;" class="text-left">
              Email Content
            </h3>
            <p
              class="grey-text"
              style="font-size:13px;cursor:pointer;"
              @click="clearEmailContent()"
            >
              CLEAR
            </p>
          </div>
          <textarea
            v-if="candidatesAddedToShare.length > 1"
            v-model="emailContentAlt"
            rows="5"
            type="textarea"
            auto-complete="ref"
            style="resize:none;outline:none;height:20vh;border:1px solid #e1e1e1;width:100%;color:black;"
            class="p-1"
            size="sm"
          />
          <textarea
            v-else
            v-model="emailContent"
            rows="5"
            type="textarea"
            auto-complete="ref"
            style="resize:none;outline:none;height:20vh;border:1px solid #e1e1e1;width:100%;color:black;"
            class="p-1"
            size="sm"
          />
        </b-col>
      </b-row>
    </div>

    <!--add email modal-->
    <mdb-modal
      v-if="showAddEmailModal"
      id="showAddEmailModal"
      class="mobileModalOverlay"
      @close="showAddEmailModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>Add {{ isStandardShare ? '' : 'BLIND SHARE' }} CONTACT</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <b-row no-gutters>
          <p class="text-left mb-2 grey-text" style="transform:translateY(20px)">
            Enter  {{ isStandardShare ? '' : 'Blind Share' }} Contact
          </p>
          <mdb-input
            ref="enterContact"
            v-model="emailToAdd"
            type="email"
            auto-complete="tel"
            style="width:100%;outline:none;"
            size="sm"
            label="email@domain.com"
            @keydown.enter.native="addEmail()"
          />
          <div class="d-flex align-items-center justify-content-end" style="width:100%;">
            <div class="mr-auto fadeAdded d-flex align-items-center" :class="{'added': addedHelpTextShowing }">
              <FontAwesomeIcon icon="fa-check fa-solid" size="2x" /><span class="ml-2" style="font-size:0.7rem">ADDED</span>
            </div>
            <mdb-btn
              type="reset"
              class="btn btn-sm p-0 btn-outline"
              size="md"
              :disabled="emailToAdd === '' || !checkValidEmail(emailToAdd)"
              style="width:60px; height:40px;"
              @click="addEmail()"
            >
              ADD
            </mdb-btn>
            <mdb-btn
              type="reset"
              class="btn btn-sm p-0 px-2 mr-3"
              size="md"
              :disabled="emailToAdd === '' || !checkValidEmail(emailToAdd)"
              style="height:40px;"
              @click="addEmail();showAddEmailModal = false;"
            >
              ADD AND CLOSE
            </mdb-btn>
          </div>
        </b-row>
      </mdb-modal-body>
    </mdb-modal>
  </div>
</template>

<script>
import { useCandidate } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: '',
  setup () {
    const candidateStore = useCandidate()
    const { candidatesAddedToShare, sharingWithPage } = storeToRefs(candidateStore)

    return {
      candidatesAddedToShare,
      sharingWithPage
    }
  },
  data () {
    return {
      emailToAdd: '',
      isStandardShare: false,
      showAddEmailModal: false,
      addedHelpTextShowing: false,
      emailContentAlt: 'Hi,\r\n\r\nI’ve shared some candidates with you, please click on the thumbnails below. You will be redirected to our sharing website to view the candidates recordings in more detail.'
    }
  },
  computed: {
    emailContent: {
      get () {
        return this.sharingWithPage.emailContent
      },
      set (value) {
        this.sharingWithPage = { ...this.sharingWithPage, emailContent: value }
      }
    },
    emailSubject: {
      get () {
        return this.sharingWithPage.emailSubject
      },
      set (value) {
        this.sharingWithPage = { ...this.sharingWithPage, emailSubject: value }
      }
    }
  },
  watch: {
    showAddEmailModal () {
      if (this.showAddEmailModal === true) {
        setTimeout(() => { this.$refs.enterContact.focus() }, 200)
      }
      if (this.candidatesAddedToShare.length > 1) {
        this.sharingWithPage = { ...this.sharingWithPage, emailContent: this.emailContentAlt }
      }
    }
  },
  methods: {
    checkValidEmail (email) {
      return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
    },
    addEmail: function () {
      if (this.emailToAdd === '' || !this.checkValidEmail(this.emailToAdd)) { return }
      this.addedHelpTextShowing = true
      if (this.isStandardShare) {
        if (this.sharingWithPage.standardShareWith.includes(this.emailToAdd)) { return }
        this.sharingWithPage = { ...this.sharingWithPage, standardShareWith: [...this.sharingWithPage.standardShareWith, this.emailToAdd] }
        setTimeout(() => {
          this.$refs.scrollToDiv1.scrollIntoView({ block: 'end', inline: 'nearest', behaviour: 'smooth' })
        }, 500)
      } else {
        if (this.sharingWithPage.blindShareWith.includes(this.emailToAdd)) { return }
        this.sharingWithPage = { ...this.sharingWithPage, blindShareWith: [...this.sharingWithPage.blindShareWith, this.emailToAdd] }
        setTimeout(() => {
          this.$refs.scrollToDiv2.scrollIntoView({ block: 'end', inline: 'nearest', behaviour: 'smooth' })
        }, 500)
      }
      setTimeout(() => { this.addedHelpTextShowing = false }, 2500)
      this.emailToAdd = ''
    },
    clearStandardSharingWith () {
      this.sharingWithPage = { ...this.sharingWithPage, standardShareWith: [] }
    },
    clearBlindSharingWith () {
      this.sharingWithPage = { ...this.sharingWithPage, blindShareWith: [] }
    },
    clearEmailContent () {
      this.sharingWithPage = { ...this.sharingWithPage, emailContent: '' }
    },
    clearEmailSubject () {
      this.sharingWithPage = { ...this.sharingWithPage, emailSubject: '' }
    },
    openBlindShareWithModal () {
      this.isStandardShare = false
      this.showAddEmailModal = true
    },
    openStandardShareWithModal () {
      this.isStandardShare = true
      this.showAddEmailModal = true
    },
    removeFromBlindShare (email) {
      this.sharingWithPage = { ...this.sharingWithPage, blindShareWith: this.sharingWithPage.blindShareWith.filter(e => e !== email) }
    },
    removeFromStandardShare (email) {
      this.sharingWithPage = { ...this.sharingWithPage, standardShareWith: this.sharingWithPage.standardShareWith.filter(e => e !== email) }
    }
  }
}
</script>

<style lang="css">

@media (min-width:992px) {
  .modal-dialog.modal-fluid {
    padding: 0px 50px !important;
  }
}
</style>

<style lang="scss" scoped>
.fadeAdded {
  transition: all ease .5s linear;
  transform: translateX(10px);
  opacity: 0;
}
.fadeAdded.added {
  transition: all ease .5s linear;
  transform: translateX(0px);
  opacity: 1;
}

@media (max-width:576px) {
  .mobileModalOverlay {
    margin-left: -3px !important;
    width: calc(100vw - 10px) !important;
    height: calc(100vh - 0px) !important;
  }
}
</style>
