import axiosInstance from '../instance.js'
const api1 = new axiosInstance(1)

export default class questionSetService {
  static async getQuestionSetList () {
    return await api1.get('/api/go/getquestionsetlist')
  }

  static async saveVideoCVSettings (formData) {
    return await api1.post('/api/go/savevideocvsettings', formData)
  }

  static async editQuestionSetScenarioAndRandomisation (formData) {
    return await api1.post('/api/go/editquestionsetscenarioandrandomisation', formData)
  }

  static async addEditQuestionSetQuestion (formData) {
    return await api1.post('/api/go/addeditquestionsetquestion', formData)
  }

  static async deleteQuestionSet (questionSetId) {
    return await api1.delete('/api/go/deletequestionset?questionSetId=' + questionSetId)
  }

  static async deleteCandidatePoolQuestion (questionId) {
    return await api1.delete('/api/go/deletecandidatepoolquestion?questionId=' + questionId)
  }

  // is this used?
  static async getCandidateQuestions (candidateId) {
    return await api1.get(`/api/go/getcandidatequestions?candidateId=${candidateId}`)
  }

  static async getCandidateQuestionPool () {
    return await api1.get('/api/go/getcandidatequestionpool')
  }

  // is this used?
  static async updateCandidateQuestionListOrdinals (ids) {
    return await api1.post('/api/go/updatecandidatequestionlistordinals?ids=' + ids, {})
  }

  static async saveQuestionSetName (questionSetId, setName) {
    return await api1.post('/api/go/savequestionsetname?setName=' + setName + '&questionSetId=' + questionSetId, {})
  }
}
