<template>
  <div style="max-height:100% ;overflow-y:auto;" class="text-left">
    <div class="d-flex align-items-center justify-content-between mb-4 mt-3">
      <div style="font-size:0.9rem;" class="grey-text">
        {{ templateIdQuery === 0 ? 'Create' : 'Edit' }} your template by adding Competences and Elements built from Point Scales
      </div>
      <mdb-btn
        :disabled="template.TemplateName === ''"
        size="sm"
        style="width:fit-content;font-size: clamp(0.8rem, 2vw, 1rem); letter-spacing: 2px;"
        class="m-0 btn font-weight-bold text-nowrap"
        @click="saveTemplate()"
      >
        <span v-if="saving && !loading"><i class="fa-check fa" /></span>
        <span v-else-if="loading"><i class="fa-pulse fa-spinner fa" /></span>
        <span v-else>SAVE</span>
      </mdb-btn>
    </div>
    <div class="d-flex align-items-center mt-3 mb-2" style="height:60px">
      <i class="mr-3 fas fa-signature" style="font-size: 1.3rem;" />
      <mdb-input
        v-model="template.TemplateName"
        type="text"
        auto-complete="jbt"
        class="template-name-input"
        label="Template Name"
      />
      <mdb-btn
        :disabled="template.ElementArray.length == 0"
        size="sm"
        style="width:fit-content;"
        class="m-0 ml-auto btn btn-outline font-weight-bold text-nowrap"
        @click="previewTemplate()"
      >
        <span><FontAwesomeIcon icon="fa-solid fa-eye" class="d-block d-sm-none" /></span>
        <span class="d-none d-sm-block">PREVIEW</span>
      </mdb-btn>
    </div>
    <div class="add-template-content-container">
      <div v-if="loading" class="mt-3 d-flex jsutifjustify-content-center">
        <mdb-icon class="mx-auto my-3" icon="fa fa-pulse fa-spinner" />
      </div>
      <div
        v-else
        style="width:100%;"
        class="px-2"
      >
        <Draggable
          :list="template.ElementArray"
          style="cursor:move;"
          @start="drag=true"
          @end="handleMove"
        >
          <div v-for="(elem, topLevelIndex) in template.ElementArray" :key="topLevelIndex">
            <!-- ELEMENTS WITHIN A COMPETENCY -->
            <div v-if="elem.CompetencyName != ''">
              <div class="d-flex align-items-center mt-3 p-3 competencyContainerBorder">
                <div class="font-weight-bold">
                  {{ elem.CompetencyName.toUpperCase() }}
                </div>
                <mdb-btn
                  size="sm"
                  class="m-0 ml-auto btn btn-outline px-1"
                  style="width:50px"
                  @click="removeElement(topLevelIndex)"
                >
                  <FontAwesomeIcon icon="fa-trash-can-xmark fa-solid" style="scale:1.4;" />
                </mdb-btn>
              </div>
              <div class="ml-0 ml-sm-5">
                <Draggable
                  :list="elem.Elements"
                  style="cursor:move;"
                  @start="drag=true"
                  @end="handleMove"
                >
                  <div
                    v-for="(innerElem, innerIndex) in elem.Elements"
                    :key="innerIndex"
                    class="mt-2 ml-auto p-2 elementContainerBorder elementWithComp"
                  >
                    <div style="width: 100%;" class="d-flex align-items-center">
                      <FontAwesomeIcon icon="fa-solid fa-chart-simple-horizontal" class="mr-2" />
                      <span>{{ innerElem.ElementName }}</span>
                      <mdb-btn
                        size="sm"
                        class="m-0 ml-auto btn btn-outline px-1"
                        style="width:50px"
                        @click="removeNestedElement(topLevelIndex, innerIndex)"
                      >
                        <FontAwesomeIcon icon="fa-trash-can-xmark fa-solid" style="scale:1.4;" />
                      </mdb-btn>
                    </div>
                  </div>
                </Draggable>
                <div
                  slot="footer"
                  class="rounded text-center border-2 border-gray-400 border-dashed p-2 mt-2 mb-4 elementWithComp ml-auto"
                  style="font-size:1rem;font-weight:bold;cursor:pointer;"
                  @click="openAddScaleModal(elem.CompetencyName, elem.CompetencyDescription)"
                >
                  <FontAwesomeIcon icon="fa-regular fa-circle-plus" class="mr-3" />
                  <span>ADD</span>
                </div>
              </div>
            </div>
            <!-- SINGLE ELEMENTS -->
            <div v-else class="my-3 elementContainerBorder p-2 d-flex align-items-center">
              <FontAwesomeIcon icon="fa-solid fa-chart-simple-horizontal" class="mr-2" />
              <span>{{ elem.Elements[0].ElementName }}</span>
              <mdb-btn
                size="sm"
                class="m-0 ml-auto btn btn-outline px-1"
                style="width:50px"
                @click="removeElement(topLevelIndex)"
              >
                <FontAwesomeIcon icon="fa-trash-can-xmark fa-solid" style="scale:1.4;" />
              </mdb-btn>
            </div>
          </div>
        </Draggable>
        <div
          v-if="!loading"
          slot="footer"
          class="rounded text-center border-2 border-gray-400 border-dashed p-2 my-3"
          style="font-size:1rem;font-weight:bold;padding:15px !important;cursor:pointer;"
        >
          <mdb-popover
            ref="popover"
            trigger="click"
            :options="{placement: 'left'}"
            style="cursor:pointer;align-self:center;width:5px;"
            class="grey-text"
          >
            <div slot="body" style="width:fit-content !important">
              <h6
                class="d-flex mb-2"
                style="align-items:center"
                @click="openAddCompetencyModal(clearValues = true)"
              >
                <a class="grey-link mr-3">COMPETENCY</a>
              </h6>
              <h6
                class="d-flex mb-2"
                style="align-items:center"
                @click="openAddScaleModal('', '')"
              >
                <a class="grey-link mr-3 text-nowrap">ELEMENT</a>
              </h6>
            </div>
            <div
              slot="reference"
              class="d-flex align-items-center justify-content-center mx-auto dark-grey-text font-weight-bold"
              style="width:fit-content;font-size:1.3rem;"
            >
              <FontAwesomeIcon icon="fa-regular fa-circle-plus" class="mr-3" />
              <span>ADD</span>
            </div>
          </mdb-popover>
        </div>
      </div>
    </div>

    <PreviewTemplateModal
      :preview-template-modal-showing="previewTemplateModalShowing"
      :template="template"
      @previewTemplateModalShowing="previewTemplateModalShowing = $event"
    />

    <AddScaleModal
      :add-scale-modal-showing="addScaleModalShowing"
      :competency-name="selectedCompetencyName"
      :competency-description="selectedCompetencyDescription"
      @addScaleModalShowing="addScaleModalShowing = $event"
      @addingElement="addScaleElementToList($event)"
    />

    <AddCompetencyModal
      :add-competency-modal-showing="addCompetencyModalShowing"
      @addCompetencyModalShowing="addCompetencyModalShowing = $event"
      @addingCompetence="addCompetenceToList($event)"
    />
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import scoringService from '@/api/services/scoring.service.js'

import Draggable from 'vuedraggable'
import PreviewTemplateModal from './previewTemplateModal.vue'
import AddScaleModal from './addScaleModal.vue'
import AddCompetencyModal from './addCompetencyModal.vue'

export default {
  name: 'AddEditTemplate',
  components: {
    PreviewTemplateModal,
    Draggable,
    AddScaleModal,
    AddCompetencyModal
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()

    return {
      authData,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal
    }
  },
  data () {
    return {
      drag: false,
      previewTemplateModalShowing: false,
      template: {
        ElementArray: [],
        TemplateName: '',
        TemplateId: 0,
        BaseTemplateId: 0
      },
      loading: false,
      saving: false,
      refactoredElementArray: [],
      addScaleModalShowing: false,
      addCompetencyModalShowing: false,
      selectedCompetencyDescription: '',
      selectedCompetencyName: ''
    }
  },
  computed: {
    templateIdQuery () {
      try {
        return Number(this.$route.query.tempId)
      } catch { return 0 }
    },
    elementArray () {
      return this.template.ElementArray
    }
  },
  watch: {
    templateIdQuery: {
      handler: function () {
        if (this.templateIdQuery !== 0) {
          this.getTemplate()
        }
      },
      immediate: true
    }
  },
  methods: {
    removeNestedElement (topLevelIndex, innerIndex) {
      this.template.ElementArray[topLevelIndex].Elements.splice(innerIndex, 1)
    },
    removeElement (topLevelIndex) {
      this.template.ElementArray.splice(topLevelIndex, 1)
    },
    openAddCompetencyModal (clearValues = false) {
      if (clearValues) {
        this.selectedCompetencyDescription = ''
        this.selectedCompetencyName = ''
      }
      this.addCompetencyModalShowing = true
    },
    openAddScaleModal (name, details) {
      this.selectedCompetencyDescription = details
      this.selectedCompetencyName = name
      this.addScaleModalShowing = true
    },
    addScaleElementToList ($event) {
      // add single element
      if (this.selectedCompetencyName === '') {
        this.template.ElementArray.push({ CompetencyName: '', CompetencyDescription: '', Elements: [{ ...$event }] })
      }
      // add element to competency
      const index = this.template.ElementArray.findIndex(e => e.CompetencyName === this.selectedCompetencyName)
      // new competency
      if (index === -1) {
        this.template.ElementArray.push({ CompetencyName: this.selectedCompetencyName, CompetencyDescription: this.selectedCompetencyDescription, Elements: [{ ...$event }] })
      }
      // existing competency
      this.template.ElementArray[index].Elements.push({ ...$event })
      this.addScaleModalShowing = false
      this.selectedCompetencyDescription = ''
      this.selectedCompetencyName = ''
    },
    addCompetenceToList ($event) {
      // check whether the competency added shares a name with existing - if so dont add
      if (this.template.ElementArray.filter(e => e.CompetencyName === $event.CompetencyName).length === 0) {
        this.template.ElementArray.push($event)
      }
      this.selectedCompetencyName = $event.CompetencyName
      this.selectedCompetencyDescription = $event.CompetencyDescription
      this.addCompetencyModalShowing = false
    },
    handleMove () {
    },
    previewTemplate () {
      this.previewTemplateModalShowing = true
    },
    async getTemplate () {
      this.loading = true
      const allowDeleted = false
      try {
        const res = await scoringService.getTemplate(this.templateIdQuery, allowDeleted)
        this.template = { ...res.data }
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    async confirmSaveTemplate () {
      this.loading = true
      this.saving = true
      const fData = new FormData()
      fData.append('template', JSON.stringify(this.template))
      fData.append('templateId', this.template.TemplateId)
      fData.append('templateName', this.template.TemplateName)
      try {
        await scoringService.saveTemplate(fData)
        // this.$router.push({ path: '/settings/scoring/templates/add_edit', query: { tempId: res.data } }).catch(() => {})
        this.$router.push('/settings/scoring/templates/all').catch(() => {})
      } catch (err) {
        this.saving = false
        this.showGenericErrorModal({ showing: true })
      }
      this.closeGenericConfirmModal()
      setTimeout(() => {
        this.loading = false
      }, 1000)
      setTimeout(() => {
        this.saving = false
      }, 3000)
    },
    showConfirmSaveTemplateModal () {
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'SAVE TEMPLATE',
        confirmMessage: 'Please note that if points have been given using this template, that template will not be affected by any changes. A new template with your updated template design will be created.',
        confirmButtonMessage: 'SAVE',
        confirmButtonFunction: () => this.confirmSaveTemplate()
      })
    },
    saveTemplate () {
      if (this.template.TemplateId === 0) {
        this.confirmSaveTemplate()
      } else {
        this.showConfirmSaveTemplateModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.scoringScaleForm {
  margin: 10px 0px 4px 0px !important;
  width:100%;
  max-width:600px;
}
.scoringScaleTextForm {
  margin: 10px 0px 4px 0px !important;
  width:100%;
  max-width:300px;
}
.textScaleContainer {
  width: 100%;
  max-width: 400px;
  border: #c0c0c0 solid 2px;
}
.textScaleTableHeader {
  background: var(--op-color)
}
.textStepColumn {
  width: 25%;
  max-width: 100px;
  border-right: #c0c0c0 solid 1px;
}
.textWordColumn {
  width: 75%;
  max-width: 300px;
}
.textColumnRow {
  max-height: 50px;
  border-top: #c0c0c0 solid 1px;
}
.competencyContainerBorder {
  border: var(--pr-color) solid 3px;
  border-radius: 5px;
}
.elementContainerBorder {
  border: #c0c0c0 solid 2px;
  border-radius: 5px;
}
.elementWithComp {
  width: 90%;
  min-width: 300px;
}
.add-template-content-container {
  min-height: calc(100vh - 385px);
  border: #c0c0c0 solid 2px;
  border-radius: 10px;
}
.template-name-input {
  width: 300px;
  max-width: 60%;
}
</style>
