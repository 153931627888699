<template>
  <div>
    <mdb-modal-body>
      <div class="small grey-text text-left" style="margin-left:7px;margin-bottom:15px;">
        Record a new video to start creating your Shine Intro. The maximum recording time is 2 minutes.
      </div>
      <div id="videoContainer" class="mx-auto p-2">
        <video
          v-if="!stopButtonPressed"
          ref="video"
          height="380"
          width="640"
          type="video/mp4"
          playsinline
          muted
          autoplay
          class="video-fluid z-depth-1"
          style="outline:none;margin: 0px;border-radius: 2px;height: 250px;object-fit: cover"
        />
        <video
          v-if="stopButtonPressed"
          ref="videoRec"
          height="380"
          width="640"
          type="video/mp4"
          playsinline
          controls
          class="video-fluid z-depth-1"
          style="outline:none;margin: 0px;border-radius: 2px;height: 250px;object-fit: cover"
        />
        <b-row no-gutters class="justify-content-end">
          <div
            id="time"
            style="margin-top:10px;"
            class="text-right grey-text"
          >
            {{ timeRemaining }}
          </div>
        </b-row>
        <b-row align-h="center">
          <mdb-btn
            v-if="!recordStart && !blobRecording"
            size="sm"
            color="primary"
            :disabled="!stream"
            style="width:110px;"
            @click="startRecord"
          >
            <i class="fa fa-circle" /> RECORD
          </mdb-btn>
          <mdb-btn
            v-if="recordStart && !blobRecording"
            size="sm"
            color="primary"
            style="width:110px;"
            @click="stopRecord"
          >
            <i class="fa fa-square" /> STOP
          </mdb-btn>
        </b-row>
      </div>
    </mdb-modal-body>
    <!-- footer -->
    <mdb-modal-footer v-if="blobRecording != null" style="display:block;">
      <b-row v-show="blobRecording && !uploadSuccess && !uploadStarted" align-h="between">
        <mdb-btn
          size="sm"
          style="width:110px;"
          class="btn-outline"
          @click="resetVideo()"
        >
          <i class="fa fa-redo" /> RESET
        </mdb-btn>
        <mdb-btn
          v-if="!uploadSuccess"
          size="sm"
          :disabled="blobRecording == null"
          style="width:110px;"
          @click="fileUpload()"
        >
          <i class="fa fa-upload" /> UPLOAD
        </mdb-btn>
      </b-row>
      <b-row v-if="uploadStarted">
        <mdb-progress indeterminate style="margin-right:10px;" />
        <mdb-btn
          size="sm"
          class="ml-auto"
          style="width:100px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </b-row>
      <div style="width:100%;" class="d-flex align-content-center justify-items-end">
        <mdb-btn
          v-if="uploadSuccess && !uploadStarted"
          size="sm"
          class="ml-auto"
          style="width:110px;"
          @click="$emit('next')"
        >
          NEXT
        </mdb-btn>
      </div>
    </mdb-modal-footer>
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'

import UploadFile from '@/upload'

export default {
  name: 'RecordVideoQuestionModal',
  setup () {
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()

    return {
      userData,
      showGenericErrorModal
    }
  },
  data () {
    return {
      blobRecording: null,
      recordChunks: [],
      recordStart: false,
      recordVideo: true,
      stopButtonPressed: false,
      stream: null,
      time: 120,
      timer: null,
      title: null,
      uploadStarted: false,
      uploadSuccess: false
    }
  },
  computed: {
    timeRemaining () {
      const mins = Math.floor(this.time / 60)
      let secs = this.time % 60
      secs = secs < 10 ? '0' + secs : secs
      return `${mins}:${secs}`
    }
  },
  created () {
    if (this.recordVideo === true && this.stopButtonPressed === false) {
      this.turnOnCamera()
    } else {
      this.turnOffCamera()
    }
  },
  destroyed () { this.turnOffCamera() },
  methods: {
    pad (val) {
      return val > 9 ? val : '0' + val
    },
    recordButton () {
      this.stopButtonPressed = false
      this.title = ''
      this.videoTags = []
      if (this.recordVideo === true) {
        this.recordVideo = false
        this.turnOffCamera()
      } else {
        this.recordVideo = true
      }
    },
    resetVideo () {
      this.uploadSuccess = false
      this.recordStart = false
      clearInterval(this.timer)
      this.time = 120
      this.stopButtonPressed = false
      this.stream = null
      this.blobRecording = null
      this.turnOnCamera()
    },
    startRecord () {
      this.stopButtonPressed = false
      const vm = this
      this.timer = setInterval(() => {
        this.time--
        if (this.time === 0) {
          this.stopRecord()
          clearInterval(this.timer)
        }
      }, 1000)
      const record = new MediaRecorder(this.stream)
      this.recorder = record
      this.recorder.ondataavailable = function (recdata) {
        vm.recordChunks.push(recdata.data)
      }
      this.recorder.onstop = function () {
        this.stopButtonPressed = true
        const newBlob = new Blob(vm.recordChunks, { type: 'video/webm' })
        const video = vm.$refs.videoRec
        video.srcObject = null
        video.src = URL.createObjectURL(newBlob)
        video.load()
        vm.recordChunks = []
        vm.blobRecording = newBlob
        this.blobRecording = newBlob
      }
      this.recorder.start()
      this.recordStart = true
    },
    stopRecord () {
      clearInterval(this.timer)
      this.timer = null
      this.isRunning = false
      this.recorder.stop()
      this.recordStart = false
      this.stopButtonPressed = true
      this.turnOffCamera()
      if (this.recorder !== null) {
        if (this.recorder.stream != null) {
          this.recorder.stream.getTracks().forEach(track => track.stop())
        }
      }
    },
    async turnOnCamera () {
      try {
        const mstream = await navigator.mediaDevices.getUserMedia({
          video: {
            advanced: [
              { width: { exact: 4096 } },
              { width: { exact: 3840 } },
              { width: { exact: 2560 } },
              { width: { exact: 1920 } },
              { width: { exact: 1280 } },
              { width: { exact: 1024 } },
              { width: { exact: 900 } },
              { width: { exact: 800 } },
              { width: { exact: 640 } },
              { width: { exact: 320 } }
            ]
          },
          audio: true
        })
        this.stream = mstream
        this.$refs.video.srcObject = mstream
      } catch (e) {
        this.turnOffCamera()
      }
    },
    async turnOffCamera () {
      if (this.stream !== null) {
        this.stream.getTracks().forEach(track => track.stop())
        this.stream = null
        try {
          this.$refs.video.srcObject = null
        } catch {
          //catch
        }
      }
    },
    async fileUpload () {
      const vData = {
        userEmail: this.userData.une,
        videoType: 10,
        shortDescription: `video intro - ${this.userData.une}`,
        videoTitle: 'twst',
        availableToAll: false,
        allowShare: false,
        selectedVideo: '',
        hasForm: false,
        videoText: '',
        videoTags: ''
      }
      this.uploadStarted = true
      try {
        const res = await UploadFile.uploadVideo(1, this.blobRecording, vData, true)
        this.uploadSuccess = true
        this.$emit('uploadedVideoId', res)
      } catch (err) {
        this.showGenericErrorModal({ showing: true, errorTitle: 'Error Uploading File', errorMessage: 'Something went wrong uploading your Shine Intro. Try refreshing and uploading again.' })
      }
      this.uploadStarted = false
    }
  }
}
</script>

