<template>
  <div class="d-flex align-items-center">
    <OneWayStageActionButton
      v-if="stageType == 1"
      :candidate="candidate"
      :stageItemStatus="stageItemStatus"
      :roleWorkflowStage="roleWorkflowStage"
      @updateComponentContext="$emit('updateComponentContext')"
      @initialiseReviewModal="$emit('initialiseReviewModal', $event)"
    />
    <TwoWayStageActionButton
      v-else-if="stageType == 2"
      :candidate="candidate"
      :stageItemStatus="stageItemStatus"
      :roleWorkflowStage="roleWorkflowStage"
      @updateComponentContext="$emit('updateComponentContext')"
      @initialiseReviewModal="$emit('initialiseReviewModal', $event)"
      @resetting="$emit('resetting')"
    />
    <EventStageActionButton
      v-else-if="stageType == 3"
      :candidate="candidate"
      :stageItemStatus="stageItemStatus"
      :roleWorkflowStage="roleWorkflowStage"
      @updateComponentContext="$emit('updateComponentContext')"
      @initialiseReviewModal="$emit('initialiseReviewModal', $event)"
    />
  </div>
</template>

<script>
import OneWayStageActionButton from './oneWayStageActionButton.vue'
import TwoWayStageActionButton from './twoWayStageActionButton.vue'
import EventStageActionButton from './eventStageActionButton.vue'

export default {
  name: 'ActionButton',
  components: { OneWayStageActionButton, TwoWayStageActionButton, EventStageActionButton },
  props: {
    candidate: {
      type: Object,
      default: () => {}
    },
    stageItemStatus: {
      type: Number,
      default: 0
    },
    stageType: {
      type: Number,
      default: 0
    },
    roleWorkflowStage: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

