<template>
  <div>
    <mdb-modal
      id="addQuestion"
      :show="addEditQuestionModalShowing"
      style="height:100vh; width: 100vw; position:fixed; top: 0; left: 0; z-index:100000000"
      @close="closeAddEditQuestionModal()"
    >
      <!--header for add or edit modal-->
      <mdb-modal-header v-if="modalPage == 1" class="p-2 p-sm-4">
        <b-row
          style="width: 90%; margin-left: 20px;"
          align-h="between"
          class=""
        >
          <div
            style="font-size: smaller;"
            class="modal-title-container m-auto m-sm-0"
            :class="{'modal-title-underlined' : addEditQuestionModalPage == 'AddEdit'}"
          >
            <h6 :class="{'grey-text' : addEditQuestionModalPage != 'AddEdit' }" @click="setAddEditQuestionModalPage('AddEdit')">
              <span v-if="mobileView === false">ADD/EDIT QUESTION</span>
              <span v-else>ADD/EDIT</span>
            </h6>
          </div>
          <div
            style="font-size: smaller;"
            class="modal-title-container m-auto m-sm-0 text-center"
            :class="{'modal-title-underlined' : addEditQuestionModalPage == 'QuestionPool'}"
          >
            <h6 :class="{'grey-text' : addEditQuestionModalPage != 'QuestionPool'}" @click="setAddEditQuestionModalPage('QuestionPool')">
              <span v-if="mobileView === false">QUESTION POOL</span>
              <span v-else>POOL</span>
            </h6>
          </div>
          <div
            style="font-size: smaller;"
            class="modal-title-container m-auto m-sm-0 text-center"
            :class="{'modal-title-underlined' : addEditQuestionModalPage == 'QuestionSet'}"
          >
            <h6 :class="{'grey-text' : addEditQuestionModalPage != 'QuestionSet'}" @click="setAddEditQuestionModalPage('QuestionSet')">
              <span v-if="mobileView === false">QUESTION SET</span>
              <span v-else>SETS</span>
            </h6>
          </div>
        </b-row>
      </mdb-modal-header>

      <!--add/edit question -->
      <div v-if="modalPage == 1 && addEditQuestionModalPage == 'AddEdit'" style="min-height:400px;margin-top:30px;">
        <mdb-modal-body :class="{ 'smFont': mobileView }" class="px-xs-2 py-xs-1 px-sm-4 py-sm-4">
          <div class="grey-text text-left" style="font-size: 0.9rem;">
            Add a new question for this candidate
          </div>
          <validation-observer v-slot="{ reset }">
            <form
              ref="questionform"
              @submit.prevent="addEditQuestion()"
              @reset.prevent="reset"
            >
              <div class="md-form form-sm text-left">
                <validation-provider
                  v-slot="{ errors }"
                  mode="lazy"
                  name="QuestionType"
                  rules="required|time-limit"
                >
                  <i
                    v-if="textOrVideoQuestion == 'Video'"
                    class="fas fa-play prefix"
                    style="margin-top: 6px;font-size: 1.3rem;"
                  />
                  <i
                    v-else
                    class="fas fa-bars prefix"
                    style="margin-top: 6px;font-size: 1.3rem;"
                  />
                  <mdb-select
                    v-model="questionTypeDropDownOptions"
                    placeholder="Question Type"
                    style="margin-left:33px;"
                    size="sm"
                  />
                  <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                    Please select question type
                  </div>
                </validation-provider>
              </div>
              <div class="md-form form-sm text-left">
                <i class="fas fa-question prefix float-left" style="margin-top: -5px;font-size: 1.3rem;" />
                <mdb-input
                  v-model="questionData.Description"
                  type="textarea"
                  placeholder="Question?"
                  auto-complete="fna"
                  :rows="4"
                  size="sm"
                  maxlength="550"
                  style="margin-left:25px;width:calc(100% - 30px);"
                  @keyup.native="charCount(questionData.Description, 550)"
                />
                <div class="grey-text remainingChars">
                  {{ chars }}
                </div>
              </div>
              <div v-if="textOrVideoQuestion == 'Video'" style="height: fit-content">
                <LeaveFadeExit>
                  <b-row
                    v-if="videoToAddToQuestion.length != 0"
                    key="pic"
                    no-gutters
                    align-h="between"
                    align-v="center"
                    class="my-2"
                  >
                    <SlideFade>
                      <div class="border-2 border-gray-400 bg-gray-100 my-1 p-1 text-bold text-black rounded text-center" style="width: 100%;">
                        <b-row
                          no-gutters
                          align-h="center"
                          align-v="center"
                        >
                          <b-col cols="4" style="background: black;">
                            <img
                              :src="videoToAddToQuestion[0].ThumbnailUrl"
                              class="m-auto"
                              style="height:80px; border-radius: 2px; object-fit: contain"
                            >
                          </b-col>
                          <b-col offset="1" class="p-1">
                            <b-row no-gutters class="font-extrabold text-center">
                              {{ videoToAddToQuestion[0].ShortDescription }}
                            </b-row>
                            <b-row
                              no-gutters
                              class="text-center"
                              style="font-size:smaller;"
                            >
                              {{ videoToAddToQuestion[0].VideoTypeDesc }}
                            </b-row>
                          </b-col>
                          <b-col cols="2">
                            <div style="margin-bottom:-5px" class="text-center">
                              <i
                                class=" fas fa-times prefix"
                                style="color: black; margin-top: 6px;font-size: 1.3rem; cursor: pointer;"
                                @click="setVideoToAddToQuestion([])"
                              />
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </SlideFade>
                  </b-row>
                  <b-row
                    v-else
                    key="box"
                    no-gutters
                    align-h="between"
                  >
                    <b-col class="p-0" cols="auto">
                      <i class="fas fa-video prefix" style="margin-top: 6px;font-size: 1.3rem;" />
                    </b-col>
                    <b-col v-if="loading.isLoading && loading.message === 'Preparing video'" class="align-self-center pl-3">
                      <div class="grey-text" style="font-size: 0.9rem;">
                        Preparing Video
                      </div>
                    </b-col>
                    <b-col class="align-self-center pl-3">
                      <div class="grey-text" style="font-size: 0.9rem;text-align:left;">
                        No video selected
                      </div>
                    </b-col>
                    <i
                      class="mr-2 fas fa-plus prefix justify-self-end"
                      style="margin-top: 6px; cursor: pointer;"
                      @click="modalPage=2"
                    />
                  </b-row>
                </LeaveFadeExit>
              </div>
              <div class="md-form form-sm text-left">
                <validation-provider
                  v-slot="{ errors }"
                  mode="lazy"
                  name="Invitation Method"
                  rules="required|time-limit"
                >
                  <i class="fas fa-clock prefix" style="margin-top: 6px;font-size: 1.3rem;" />
                  <mdb-select
                    v-model="questionLength"
                    placeholder="Time to answer this Question"
                    style="margin-left:33px;"
                    size="sm"
                  />
                  <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                    Please select time to answer question
                  </div>
                </validation-provider>
              </div>
              <div class="md-form form-sm text-left">
                <div class="md-form form-sm" @click="questionData.IsPool = !questionData.IsPool">
                  <i class="fas fa-plus-square prefix" style="margin-top: 9px;font-size: 1.3rem;" />
                  <mdb-input
                    v-model="questionData.IsPool"
                    type="checkbox"
                    label="Add to the Question Pool"
                    style="margin-left:15px;"
                    size="sm"
                  />
                </div>
              </div>
            </form>
          </validation-observer>
        </mdb-modal-body>
        <mdb-modal-footer v-if="videoToAddToList" style="display:block;">
          <b-row align-h="between" align-v="center">
            <div>
              <mdb-btn
                type="reset"
                class="btn-outline"
                size="sm"
                style="width:100px;"
                @click="closeAddEditQuestionModal()"
              >
                CANCEL
              </mdb-btn>
            </div>
            <mdb-btn
              v-if="!loading.isLoading"
              type="submit"
              :disabled="(questionData.Description === null || questionData.Description === '') || textOrVideoQuestion == 'Video' && videoToAddToQuestion.length == 0"
              class="float-right"
              size="sm"
              style="width:100px;"
              @click="addEditQuestion()"
            >
              <span>SAVE</span>
            </mdb-btn>
            <mdb-btn
              v-else
              size="sm"
              style="width:100px;"
            >
              <mdb-icon icon="fa fa-pulse fa-spinner" />
            </mdb-btn>
          </b-row>
        </mdb-modal-footer>
      </div>

      <!--question pool page-->
      <div v-if="modalPage == 1 && addEditQuestionModalPage == 'QuestionPool'" style="min-height:400px;margin-top:30px;">
        <mdb-modal-body class="px-2 py-1 px-sm-4 py-sm-4 text-black" :class="{ 'modalHeightForRoles': mobileView }">
          <b-row
            v-if="loading.message == 'Fetching questions from pool'"
            align-h="center"
            align-v="center"
          >
            <h3 class="primary-colour text-left font-weight-bold" :class="{ 'mobileTopSections': mobileView }">
              Loading
              <i
                style="margin-left: 5px !important;"
                class="fa fa-spin fa-spinner ml-3"
                :class="{ 'mobileTopSectionSpinner': mobileView }"
              />
            </h3>
          </b-row>
          <b-row
            v-if="filteredQuestionPool.length == 0 && !loading.isLoading"
            align-h="center"
            align-v="center"
          >
            <div class="text-center p-3 text-gray" style="font-size:0.8rem;">
              Either you have no questions in your question pool or all questions are already added for this candidate.
            </div>
          </b-row>
          <div v-if="filteredQuestionPool">
            <SlideFade>
              <div
                v-if="filteredQuestionPool.length > 0 && loading.message != 'Fetching questions from pool'"
                style="max-height: 243px; overflow: auto;"
                class="p-2"
              >
                <div
                  v-for="q in filteredQuestionPool"
                  :key="q.GoCandidateQuestionId"
                  :class="{ 'paddTwo': mobileView }"
                  style="cursor: pointer;"
                  class="border-2 border-gray-400  bg-gray-100 p-2 p-sm-3 my-1 text-bold text-black rounded text-center borderColorOnHover"
                  @click="selectedQuestionFromPool = [q]"
                >
                  <b-row
                    no-gutters
                    align-h="center"
                    align-v="center"
                    class="flex-nowrap"
                  >
                    <b-col cols="1" class="mx-2">
                      <div v-if="questionData.VideoUrl" class="text-center">
                        <i
                          :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
                          class="fa fa-2x text-left fa-video"
                          style="font-size:1.2rem;"
                        />
                      </div>
                      <div v-else class="text-center">
                        <i
                          :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
                          class="fa fa-2x text-left fa-question-circle"
                          style="font-size:1.2rem;"
                        />
                      </div>
                    </b-col>
                    <b-col class="align-self-center twoLinedEllipsis" style="text-align:left;font-size:smaller;">
                      {{ q.Description }}
                    </b-col>
                    <b-col cols="2">
                      <div style="margin-bottom:-5px" class="text-center">
                        <i
                          v-if="selectedQuestionFromPool.length > 0 && selectedQuestionFromPool[0].GoCandidateQuestionId == questionData.GoCandidateQuestionId && !loading.isLoading && loading.message != 'Successfully added question to the list'"
                          :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
                          class="col-span-1 fa fa-2x text-left fa-check mx-2"
                          style="font-size:1.2rem;cursor:pointer;"
                        />
                        <i
                          v-if="selectedQuestionFromPool.length > 0 && selectedQuestionFromPool[0].GoCandidateQuestionId == questionData.GoCandidateQuestionId && (loading.isLoading || loading.message == 'Successfully added question to the list')"
                          :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
                          class="fa fa-pulse fa-spinner"
                        />
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </SlideFade>
          </div>
        </mdb-modal-body>
        <mdb-modal-footer v-if="selectedQuestionFromPool.length > 0" style="display:block;">
          <b-row
            align-h="between"
            align-v="center"
            no-gutters
          >
            <mdb-btn
              v-if="confirmingDeletePoolQuestion == false"
              type="reset"
              :disabled="(loading.isLoading || loading.message == 'Successfully added question to the job')"
              size="sm"
              style="width:100px;"
              @click="confirmingDeletePoolQuestion = true"
            >
              DELETE
            </mdb-btn>
            <div v-else class="smFont">
              Confirm Delete?
            </div>
            <div v-if="confirmingDeletePoolQuestion">
              <mdb-btn
                style="transform:scale(0.90);"
                type="reset"
                size="sm"
                @click="confirmingDeletePoolQuestion = false"
              >
                <i
                  :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
                  :disabled="loading.isLoading"
                  class="fa fa-1x text-left fa-times"
                  style="font-size:1rem;"
                />
              </mdb-btn>
              <mdb-btn
                style="transform:scale(0.85);"
                type="reset"
                size="sm"
                class="btn-outline"
                @click="confirmDeletePoolQuestion()"
              >
                <i
                  :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
                  :disabled="loading.isLoading"
                  class="fa fa-1x text-left fa-check"
                  style="font-size:1rem;"
                />
              </mdb-btn>
            </div>
            <div v-if="!confirmingDeletePoolQuestion">
              <mdb-btn
                type="reset"
                :disabled="(loading.isLoading || loading.message == 'Successfully added question to the job')"
                size="sm"
                style="width:100px;"
                @click="addPoolQuestionToList()"
              >
                ADD
              </mdb-btn>
            </div>
          </b-row>
        </mdb-modal-footer>
      </div>

      <!--question set page-->
      <div v-if="modalPage == 1 && addEditQuestionModalPage == 'QuestionSet'" style="min-height:400px;margin-top:30px;">
        <mdb-modal-body class="px-2 py-1 px-sm-4 py-sm-4 text-black" :class="{ 'modalHeightForRoles': mobileView }">
          <QuestionSetModalPage
            @addQuestionSet="addQuestionSet($event)"
          />
        </mdb-modal-body>
      </div>

      <!--add video question modal header and body-->
      <div v-if="modalPage == 2">
        <mdb-modal-header>
          <b-row style="width: 90%; margin-left: 20px;" align-h="between">
            <div
              class="modal-title-container m-auto m-sm-0"
              :class="{'modal-title-underlined' : videoModalPage == 'choose'}"
              style="font-size:smaller;margin-left:-20px !important;"
            >
              <h6 :class="{'grey-text' : videoModalPage != 'choose' }" @click="videoModalPage = 'choose'">
                <span v-show="mobileView === false">CHOOSE VIDEO</span>
                <span v-show="mobileView"><i class="fa fa-video" /></span>
              </h6>
            </div>
            <div
              class="modal-title-container m-auto m-sm-0"
              :class="{'modal-title-underlined' : videoModalPage == 'record'}"
              style="font-size:smaller;margin-left:-20px !important;"
            >
              <h6 :class="{'grey-text' : videoModalPage != 'record' }" @click="videoModalPage = 'record'">
                <span v-show="mobileView === false">RECORD VIDEO</span>
                <span v-show="mobileView" style="font-size:12px;">RECORD <i class="fa fa-video" /></span>
              </h6>
            </div>
            <div
              class="modal-title-container m-auto m-sm-0"
              :class="{'modal-title-underlined' : videoModalPage == 'upload'}"
              style="font-size:smaller;margin-left:-20px !important;"
            >
              <h6 :class="{'grey-text' : videoModalPage != 'upload' }" @click="videoModalPage = 'upload'">
                <span v-show="mobileView === false">UPLOAD VIDEO</span>
                <span v-show="mobileView" style="font-size:12px;">UPLOAD <i class="fa fa-video" /></span>
              </h6>
            </div>
          </b-row>
        </mdb-modal-header>

        <div v-if="videoModalPage == 'choose'">
          <SlideFade>
            <ChooseVideoPage
              :default-category="'Candidate'"
              :set-text-or-video-question-modal="setTextOrVideoQuestionModal"
              @goBack="goBack()"
              @handleChosenVideo="handleChosenVideo($event)"
            />
          </SlideFade>
        </div>
        <div v-if="videoModalPage == 'record'">
          <SlideFade>
            <RecordVideoPage
              :set-text-or-video-question-modal="setTextOrVideoQuestionModal"
              :default-category="'Candidate'"
              @handleRecordedVideo="handleRecordedQuestion($event)"
            />
          </SlideFade>
        </div>
        <div v-if="videoModalPage == 'upload'">
          <SlideFade>
            <UploadVideoPage
              :default-category="'Candidate'"
              @uploadVideo="handleVideoUpload($event)"
            />
          </SlideFade>
        </div>
      </div>
    </mdb-modal>

    <!--cannot delete modal-->
    <mdb-modal
      v-if="failedToDeletePoolQuestion"
      id="failedToDeletePoolQuestionModal"
      @close="failedToDeletePoolQuestion = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>FAILED</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          Failed to delete - to delete a pool question it cannot be present on another users Video CV question list
        </p>
      </mdb-modal-body>
      <mdb-modal-footer class="justify-content-between">
        <b-row
          align-h="between"
          align-v="center"
          style="width:100%"
        >
          <mdb-btn
            style="width:130px;"
            class="p-2"
            @click="failedToDeletePoolQuestion = false"
          >
            CLOSE
          </mdb-btn>
        </b-row>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { useUserData, useCandidate, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'

import libraryService from '@/api/services/library.service'
import candidateService from '@/api/services/candidate.service'
import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'
import LeaveFadeExit from '@/components/UI/TransitionComponents/leaveFadeExit.vue'

import ChooseVideoPage from '@/components/genericModals/InsertVideoModals/ChooseVideoPage.vue'
import RecordVideoPage from '@/components/genericModals/InsertVideoModals/RecordVideoPage.vue'
import UploadVideoPage from '@/components/genericModals/InsertVideoModals/UploadVideoPage.vue'
import QuestionSetModalPage from '@/views/questionSets/questionSetModalPage'

export default {
  name: 'VideoCVModal',
  components: {
    SlideFade,
    LeaveFadeExit,
    ChooseVideoPage,
    RecordVideoPage,
    UploadVideoPage,
    QuestionSetModalPage
  },
  props: {
    question: {
      type: Object,
      default: () => {}
    },
    editingQuestion: Boolean
  },
  setup () {
    const candidateStore = useCandidate()
    const { addCandidateToRecentCandidates } = useCandidate()
    const {
      candidateToEdit,
      view,
      editingCandidate,
      candidateQuestions,
      addEditQuestionModalShowing,
      selectedQuestion,
      videoToAddToQuestion
    } = storeToRefs(candidateStore)

    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()
    const { mobileView } = useMobile()

    return {
      candidateToEdit,
      view,
      editingCandidate,
      candidateQuestions,
      addEditQuestionModalShowing,
      selectedQuestion,
      videoToAddToQuestion,
      authData,
      userData,
      showGenericErrorModal,
      addCandidateToRecentCandidates,
      mobileView
    }
  },
  data () {
    return {
      addEditQuestionModalPage: 'AddEdit',
      chars: '0/550',
      confirmingDeletePoolQuestion: false,
      failedToDeletePoolQuestion: false,
      filteredQuestionPool: [],
      modalPage: 1,
      questionTypeDropDownOptions: [
        { text: 'Text', value: 0, selected: true },
        { text: 'Video', value: 1 }
      ],
      questionPool: [],
      loading: { isLoading: false, message: '' },
      selectedVideo: [],
      selectedQuestionFromPool: [],
      videoModalPage: 'choose',
      videoToAddToList: [],
      questionData: this.question,
      questionLength: [
        { text: '00:30', value: 30, selected: true },
        { text: '00:45', value: 45 },
        { text: '01:00', value: 60 },
        { text: '01:15', value: 75 },
        { text: '01:30', value: 90 },
        { text: '01:45', value: 105 },
        { text: '02:00', value: 120 },
        { text: '02:15', value: 135 },
        { text: '02:30', value: 150 },
        { text: '02:45', value: 165 },
        { text: '03:00', value: 180 }
        //{ text: '03:15', value: 195 },
        //{ text: '03:30', value: 210 },
        //{ text: '03:45', value: 225 },
        //{ text: '04:00', value: 240 },
        //{ text: '04:15', value: 255 },
        //{ text: '04:30', value: 270 },
        //{ text: '04:45', value: 285 },
        //{ text: '05:00', value: 300 }
      ]
    }
  },
  computed: {
    textOrVideoQuestion () {
      var selected = this.questionTypeDropDownOptions.filter(o => o.selected)[0]
      if (selected.value === 0) {
        return 'Text'
      }
      return 'Video'
    }
  },
  watch: {
    addEditQuestionModalShowing () {
      if (this.addEditQuestionModalShowing) {
        if (this.editingQuestion) {
          this.populateModal()
        }
      } else {
        this.videoToAddToQuestion = []
        this.questionTypeDropDownOptions = [{ text: 'Text', value: 0, selected: true }, { text: 'Video', value: 1 }]
      }
    },
    modalPage: function () { this.loading = { isLoading: false, message: '' } },
    addEditQuestionModalPage: function () {
      if (this.addEditQuestionModalPage === 'QuestionPool') {
        this.fetchPoolQuestions()
      }
    },
    questionPool () {
      const currentQuestionIds = []
      this.candidateQuestions.forEach(q => currentQuestionIds.push(q.CandidateQuestionId))
      if (this.questionPool.length > 0) {
        this.filteredQuestionPool = this.questionPool.filter(item => !currentQuestionIds.includes(item.GoCandidateQuestionId))
      }
    }
  },
  methods: {
    convertQuestionListsToIncludeSets (questionList) {
      try {
        // splits the questions by setId
        const setIds = []
        const setShells = []
        questionList.map(q => {
          if (q.QuestionSetId) {
            if (!setIds.includes(q.QuestionSetId)) { setIds.push(q.QuestionSetId) }
          }
        })
        setIds.map(id => {
          setShells.push({ QuestionSetId: id, QuestionSetName: '', Questions: [] })
        })
        questionList.map(q => {
          let index
          if (q.QuestionSetId) {
            setShells.forEach((sh, i) => {
              if (sh.QuestionSetId === q.QuestionSetId) { index = i }
            })
            setShells[index] = { ...setShells[index], QuestionSetName: q.QuestionSetName, Questions: [...setShells[index].Questions, q] }
          } else {
            setShells.push(q)
          }
        })
        return setShells
      } catch (err) { 
        //?
      }
    },
    async addQuestionSet (questionSetId) {
      try {
        const res = await candidateService.addEditCandidateQuestionSet(questionSetId, false, this.candidateToEdit.CandidateId)
        this.candidateQuestions = res.data
      } catch {
        this.showGenericErrorModal({ showing: true })
      }
      this.closeAddEditQuestionModal()
      this.addEditQuestionModalPage = 'AddEdit'
    },
    addEditQuestion () {
      this.saveQuestion()
    },
    async addPoolQuestionToList () {
      this.loading = { isLoading: true, message: 'Adding pool question' }
      try {
        const res = await candidateService.addPoolQuestionToList(this.candidateToEdit.CandidateId, this.selectedQuestionFromPool[0].GoCandidateQuestionId)
        this.candidateQuestions = res.data
        this.$emit('addCandidateToRecentCandidates', this.candidateToEdit.CandidateId)
      } catch (err) {
        console.log(Object.assign({}, err))
      }
      this.loading = { isLoading: false, message: 'Successfully added question to the list' }
      this.closeAddEditQuestionModal()
      this.addEditQuestionModalPage = 'AddEdit'
    },
    charCount (type, max) {
      this.chars = type.length + '/' + max
    },
    closeAddEditQuestionModal () {
      this.modalPage = 1
      this.addEditQuestionModalPage = 'AddEdit'
      this.$emit('closeAddEditQuestionModal')

      this.questionData.Description = null
      this.questionData.IsPool = false
      this.questionData.NoRatings = false
    },
    async confirmDeletePoolQuestion () {
      // this.selectedQuestionFromPool
      this.loading = { isLoading: true, message: 'Deleting question from pool' }
      try {
        const res = await candidateService.deleteCandidatePoolQuestion(this.selectedQuestionFromPool[0].GoCandidateQuestionId)
        if (res.data !== 'Successfully deleted') {
          this.failedToDeletePoolQuestion = true
          this.confirmingDeletePoolQuestion = false
        } else {
          this.fetchPoolQuestions()
        }
      // eslint-disable-next-line no-empty
      } catch {}
      this.selectedQuestionFromPool = []
      this.loading = { isLoading: false, message: '' }
    },
    goBack () {
      this.modalPage = 1
    },
    async fetchPoolQuestions () {
      this.loading = { isLoading: true, message: 'Fetching questions from pool' }
      try {
        const res = await candidateService.getCandidateQuestionPool()
        this.questionPool = res.data
      // eslint-disable-next-line no-empty
      } catch {}
      this.selectedQuestionFromPool = []
      this.loading = { isLoading: false, message: '' }
    },
    async handleChosenVideo (video) {
      this.videoToAddToQuestion = [video]
      this.modalPage = 1
    },
    async handleRecordedQuestion (vidId) {
      // reason for this logic - some types of videos will return an id and others will just return a boolean
      if (typeof vidId === 'boolean') {
        try {
          const res = await libraryService.getMostRecentVid(this.userData.aid)
          this.videoToAddToQuestion = [res.data]
        } catch (err) { 
          //?
        }
      } else {
        try {
          const res = await libraryService.getVidFromVidId(vidId)
          this.videoToAddToQuestion = [res.data]
        } catch (err) { 
          //?
        }
      }
      this.modalPage = 1
    },
    async handleVideoUpload (vidId) {
      // reason for this logic - some types of videos will return an id and others will just return a boolean
      if (typeof vidId === 'boolean') {
        try {
          const res = await libraryService.getMostRecentVid(this.userData.aid)
          this.videoToAddToQuestion = [res.data]
        } catch (err) { 
          //?
        }
      } else {
        try {
          const res = await libraryService.getVidFromVidId(vidId)
          this.videoToAddToQuestion = [res.data]
        } catch (err) { 
          //?
        }
      }
      this.modalPage = 1
    },
    async populateModal () {
      if (this.questionData.VideoUrl !== '') {
        this.questionTypeDropDownOptions = [{ text: 'Text', value: 0, selected: false }, { text: 'Video', value: 1, selected: true }]
        try {
          const res = await libraryService.getVidFromURL(this.userData.aid, this.questionData.VideoUrl)
          if (!res.data.IsDeleted) {
            this.videoToAddToQuestion = [res.data]
          } else { this.videoToAddToQuestion = [] }
        } catch (err) { 
          //?
        }
      } else {
        this.videoToAddToQuestion = []
        this.questionTypeDropDownOptions = [{ text: 'Text', value: 0, selected: true }, { text: 'Video', value: 1, selected: false }]
      }
      this.questionLength = this.questionLength.map(q => this.questionData.TimeLimit === q.value ? { ...q, selected: true } : { ...q, selected: false })
      if (this.questionData.Description != null) {
        this.chars = (this.questionData.Description.length) + '/550'
      }
    },
    async saveQuestion () {
      this.loading = { isLoading: true, message: 'Saving new candidate question' }
      var formData = new FormData()
      // set as 0 so backend knows it is adding question
      if (this.editingQuestion) {
        formData.append('questionId', this.questionData.CandidateQuestionId)
      } else {
        formData.append('questionId', 0)
      }
      formData.append('candidateId', this.candidateToEdit.CandidateId)
      formData.append('description', this.questionData.Description)
      if (this.textOrVideoQuestion === 'Video') {
        const urlRes = await libraryService.getUrl(this.videoToAddToQuestion[0].FileName)
        formData.append('videoUrl', urlRes.data)
        formData.append('tags', this.videoToAddToQuestion[0].Tags)
      } else {
        formData.append('videoUrl', '')
        formData.append('tags', '')
      }
      formData.append('toDelete', false)
      const timeLimit = this.questionLength.filter(q => q.selected)[0].value
      formData.append('timeLimit', timeLimit)
      formData.append('isPool', this.questionData.IsPool)
      try {
        const res = await candidateService.addEditCandidateQuestion(formData)
        this.candidateQuestions = res.data
        this.addCandidateToRecentCandidates()
        this.$parent.questionsAllAnswered()
      } catch (err) {
        //?
      }
      this.loading = { isLoading: false, message: '' }
      this.closeAddEditQuestionModal()
    },
    setAddEditQuestionModalPage (value) {
      this.addEditQuestionModalPage = value
      if (value === 'QuestionPool') {
        // pool
      }
    },
    setTextOrVideoQuestionModal (value) {
      this.textOrVideoQuestion = value
    },
    setVideoToAddToQuestion (value) {
      this.videoToAddToQuestion = value
    }
  }
}
</script>
