<template>
  <!--set meeting room date and time modal-->
  <mdb-modal
    id="meetingDateModal"
    :show="meetingDateModal"
    style="z-index:10000;"
    @close="$emit('cancelMeetingModalAndRemoveCand', candidate.CandidateId)"
  >
    <mdb-modal-header>
      <mdb-modal-title>SET INTERVIEW DATE AND TIME</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body
      class="px-4"
      :class="{ 'mo\dalHeightForRoles': mobileView }"
    >
      <div class="file-field md-form form-sm text-left">
        <p
          v-if="!resettingTwoWay"
          class="grey-text"
          style="font-size:12px;"
        >
          Select date and time for this interview / online meeting along with a host and option additional attendees / assessors.
        </p>
        <p
          v-else
          class="grey-text"
          style="font-size:12px;"
        >
          Select a new date and time for this interview.
        </p>
      </div>
      <div class="file-field md-form form-sm text-left">
        <i
          class="far fa-calendar prefix"
          style="margin-top: 6px;font-size: 1.3rem;"
          title="Select date"
        />
        <input
          v-model="meetingDate"
          type="date"
          :min="today"
          onkeydown="return false"
          auto-complete="mdt"
          style="margin-left:33px;font-size: 0.9rem;color:#888;"
          size="sm"
          @change="timeChange"
        >
      </div>
      <div class="file-field md-form form-sm text-left">
        <label v-show="timeErr" style="margin-top: -25px;font-size: 12px;margin-left: 35px;color: red;">YOU CANNOT CHOOSE A TIME LESS THAN THE CURRENT TIME!</label>
        <i
          class="far fa-clock prefix"
          style="margin-top: 10px;font-size: 1.3rem;"
          title="Select time"
        />
        <VueTimepicker
          v-model="meetingTime"
          style="padding-left:33px; padding-right: 15px;width: 90%"
          close-on-complete
          :minute-interval="5"
        />
      </div>
      <div v-if="!loadingCurrentUser">
        <!-- HOST SEARCH -->
        <SearchWithDropdownSelect
          class="mt-5"
          :searchFunction="getHostAssessors"
          searchResultsIdFieldName="Id"
          searchResultsImageFieldName="ProfilePicture"
          searchResultsNameFieldName="Name"
          selectedValueText="Selected Host: "
          :preSelected="selectedHost"
          searchPlaceHolder="Search For A Host"
          @newSelect="selectedHost = $event"
        />
        <!-- HOST SEARCH -->
        <SearchWithDropdownSelect
          class="mt-5 mb-4"
          :searchFunction="getHostAssessors"
          :isSingleSelectDropdown="false"
          searchResultsIdFieldName="Id"
          searchResultsImageFieldName="ProfilePicture"
          searchResultsNameFieldName="Name"
          selectedValueText="Selected Assessors (optional): "
          searchPlaceHolder="Search For An Assessor (optional)"
          @newSelect="selectedAssessors = $event"
        />
      </div>
    </mdb-modal-body>
    <mdb-modal-footer style="display:block;">
      <mdb-btn
        type="reset"
        class="float-left btn-outline"
        size="sm"
        style="width:100px;"
        @click.native="$emit('cancelMeetingModalAndRemoveCand', candidate.CandidateId)"
      >
        CANCEL
      </mdb-btn>
      <mdb-btn
        :disabled="disabled"
        type="submit"
        class="float-right"
        size="sm"
        style="width:100px;"
        @click.native="setTempMeetingDate()"
      >
        <span v-if="!loading">SET</span>
        <span v-else><mdb-icon icon="fa fa-pulse fa-spinner" /></span>
      </mdb-btn>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>

import { useUserData, useStatus, useRoleCandidate, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import roleV2Service from '@/api/services/roleV2.service'
import userService from '@/api/services/user.service'
import { ref } from 'vue'
import SearchWithDropdownSelect from '@/components/UI/searchWithDropdownSelect.vue'

export default {
  name: 'MeetingRoomAndDateModal',
  components: { SearchWithDropdownSelect },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()
    const { mobileView } = useMobile()

    const roleCandidateStore = useRoleCandidate()
    const { candidate, meetingDateModal, resettingTwoWay } = storeToRefs(roleCandidateStore)

    const workflowStore = useWorkflow()
    const { role, selectedWorkflowStage } = storeToRefs(workflowStore)

    const loading = ref(false)
    return {
      authData,
      showGenericErrorModal,
      mobileView,
      role,
      candidate,
      meetingDateModal,
      selectedWorkflowStage,
      resettingTwoWay,
      loading,
      userData
    }
  },
  data () {
    return {
      today: '',
      timeErr: false,
      meetingDate: '',
      meetingTime: '',
      selectedAssessors: [],
      selectedHost: [],
      loadingAssessors: false,
      loadingCurrentUser: true,
    }
  },
  computed: {
    disabled () {
      return (!this.selectedHost.length && !this.resettingTwoWay) || this.meetingTime === '' || this.meetingDate === '' || this.timeErr || this.loading
    }
  },
  watch: {
    meetingDateModal () {
      if (!this.meetingDateModal) {
        this.resettingTwoWay = false
      } else {
        this.getCurrentUser()
      }
    }
  },
  created () {
    var dtToday = new Date()
    var month = dtToday.getMonth() + 1
    var day = dtToday.getDate()
    var year = dtToday.getFullYear()
    if (month < 10) {
      month = '0' + month.toString()
    }
    if (day < 10) {
      day = '0' + day.toString()
    }
    this.today = year + '-' + month + '-' + day
  },
  methods: {
    async getHostAssessors (search) {
      return await roleV2Service.getHostAssessors(search)
    },
    async getCurrentUser () {
      try {
        this.loadingCurrentUser = true
        const res = await userService.getUser(this.userData.uid)
        this.selectedHost = [res.data]
        this.loadingCurrentUser = false
      } catch {}
    },
    timeChange () {
      this.timeErr = false
      var dtToday = new Date()
      var mtDate = new Date(this.meetingDate)
      if (mtDate <= dtToday) {
        var h = dtToday.getHours()
        var m = dtToday.getMinutes()
        if (m.toString().length === 1) {
          m = '0' + m.toString()
        }
        if (h === '0') { h = 24 }
        var currentTime = h + '.' + m
        if (this.meetingTime != null) {
          var time = this.meetingTime.split(':')
          var hour = time[0]
          if (hour === '00') { hour = 24 }
          var min = time[1]
          var inputTime = hour + '.' + min
          var totalTime = currentTime - inputTime
          if (totalTime > 0) {
            this.timeErr = true
          } else {
            this.timeErr = false
          }
        }
      }
    },
    async setTempMeetingDate () {
      const assessorIds = this.selectedAssessors.map(u => u.UserName)
      var date = new Date(this.meetingDate + ' ' + this.meetingTime)
      if (isNaN(date)) {
        //?
      } else {
        this.loading = true
        try {
          const JSONData = {
            CandidateId: this.candidate.CandidateId,
            RoleWorkflowStageId: this.selectedWorkflowStage.RoleWorkflowStageId,
            MeetingDate: this.meetingDate || null,
            MeetingTime: this.meetingTime || null,
            SelectedHost: this.selectedHost[0].UserName,
            SelectedAssessors: assessorIds,
            RoleId: this.role.JobId
          }
          if (this.resettingTwoWay) {
            await roleV2Service.resetTwoWayStageInterview(JSONData)
            this.$emit('getRoleCandidates')
          } else {
            roleV2Service.setTempMeetingDate(JSONData).then(() => {
            }).catch(() => {
            })
          }
        } catch (err) {
          this.showGenericErrorModal({ showing: true })
        }
        this.meetingTime = ''
        this.meetingDate = ''
        this.resettingTwoWay = false
        this.meetingDateModal = false
        this.loading = false
      }
    }
  }
}
</script>
