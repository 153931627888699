<template>
  <div class="w-100">
    <ul class="nav nav-tabs d-flex justify-content-around font-weight-bold navBottomBorder">
      <li
        v-for="(stage, i) in stageList"
        :key="i"
        style="position: relative"
        class="nav-item text-center text-nowrap flex-grow-1"
        @click="selectStage(stage)"
      >
        <a
          class="nav-link primary-colour d-flex align-items-center justify-content-center"
          :class="stage.RoleWorkflowStageId != selectedStage.RoleWorkflowStageId ? 'active' : 'white-text font-weight-light'"
        >
          <div class="mx-auto d-flex align-items-center justify-content-center">
            <FontAwesomeIcon
              class="mr-2"
              :icon="getIconFromStageType(stage.StageType)"
            />
            <span
              style="text-wrap: balance;white-space: break-spaces;"
              :class="{'d-none' : stage.RoleWorkflowStageId != selectedStage.RoleWorkflowStageId}"
              class="d-sm-inline mr-2"
            > {{ stage.RoleWorkflowStageName }}</span>
            <div
              title="Total Applicants in this Stage"
              :class="{'notSelected': stage.RoleWorkflowStageId != selectedStage.RoleWorkflowStageId}"
              style="width:50px;"
              class="stageCount mr-auto ml-2"
            >
              <span v-if="!loading">{{ stageCounts[stage.RoleWorkflowStageId] }}</span>
              <span v-else> <mdb-icon icon="fa fa-pulse fa-spinner" /></span>
            </div>
          </div>
          <StagePopover
            style="transform:translateX(8px)"
            :role="role"
            :stage="stage"
          />
        </a>
        <!-- style="position: absolute; right: 10px; top: calc(50% - 9px)" /> -->
      </li>
    </ul>
    <SlideFade>
      <div
        :ref="role.JobId"
        style="overflow:hidden;"
        :class="{'grey-text' : role.CloseDateIsPast}"
      >
        <StageList
          :role="role"
          :selected-stage="selectedStage"
        />
      </div>
    </SlideFade>
  </div>
</template>

<script>

import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import StageList from './stageList.vue'
import { ref, computed, onMounted, watch } from 'vue'
import SlideFade from '@/components/UI/TransitionComponents/slideFade'
import GET_ICON_FROM_STAGE_TYPE_ENUM from '@/utils/getFAIconCodeFromWorkflowStageTypeEnum.js'
import roleV2Service from '@/api/services/roleV2.service'
import StagePopover from './stagePopover.vue'

export default {
  name: 'StageContainer',
  components: { StageList, SlideFade, StagePopover },
  props: {
    role: Object
  },
  setup (props, {emit}) {

    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)


    const stageList = computed(() => {
      try {
        return props.role.RoleWorkflow?.OrderedRoleWorkflowStages
      } catch { return [] }
    })

    const selectedStage = ref({})
    const selectStage = stage => {
      selectedStage.value = stage
      emit('selectedStage', stage)
    }

    onMounted(() => {
      selectStage(stageList.value[0]),
      { immediate: true }
    })

    const following = computed(() => props.role.Following)
    watch(following, () => {
      selectStage(stageList.value[0])
    })

    // getting overall stage counts
    const stageCounts = ref({})
    const loading = ref(false)
    const getStageApplicantCounts = async () => {
      try {
        loading.value = true
        const arrayOfStageGuids = stageList.value.map(stage => stage.RoleWorkflowStageId)
        const res = await roleV2Service.getStageApplicantCounts({ Guids: arrayOfStageGuids })
        stageCounts.value = res.data
        let totalCount = Object.values(stageCounts.value).reduce((a, b) => a + b, 0)
        emit('totalCount', totalCount)
      } catch (err) {
        console.log(Object.assign({}, err))
      }
      loading.value = false
    }
    if (props.role.IsComplete) {
      getStageApplicantCounts()
    }


    return {
      userData,
      selectStage,
      selectedStage,
      stageList,
      stageCounts,
      loading
    }
  },
  methods: {
    getIconFromStageType (stageType) {
      return GET_ICON_FROM_STAGE_TYPE_ENUM(stageType)
    }
  }
}
</script>

<style>
.navBottomBorder {
  border-bottom: solid 3px #c0c0c0;
}

.stageCount {
  width: fit-content;
  height: 50%;
  margin: auto 0;
  border: solid 2px var(--pr-color);
  color: var(--pr-color);
  font-weight: bold;
  font-size: 0.9rem;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 10px;
}
.stageCount.notSelected {
  border: solid 2px var(--pr-color);
  background: var(--pr-color);
  color: #fff;
}
</style>
