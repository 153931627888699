<template>
  <h6
    style="margin-left:-10px;padding:5px 12px;"
    class="text-nowrap"
    @click="copyToClipBoard()"
  >
    <i
      v-if="!alertCopied"
      class="fa fa-link ml-1 grey-link"
      style="margin-right:10px;"
    />
    <i
      v-else
      class="fa fa-thumbs-up ml-1"
      style="margin-right:10px;"
    />
    <a v-if="!alertCopied" class="grey-link">{{ buttonText }}</a>
    <span v-else>Copied!</span>
  </h6>
</template>

<script>
export default {
  name: 'CopyToClipboardPopoverButton',
  props: {
    stringToCopy: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      alertCopied: false
    }
  },
  methods: {
    async copyToClipBoard () {
      await navigator.clipboard.writeText(this.stringToCopy)
      this.alertCopied = true
      setTimeout(() => {
        this.alertCopied = false
        this.$emit('closePopover')
      }, 2000)
    }
  }
}
</script>
