import axiosInstance from '../instance.js'
const api2 = new axiosInstance(2)

export default class eventSubscriptionService {
  static async saveEventSubscription (eventSubscription) {
    return await api2.post('/api/eventsubscription/subscription', eventSubscription)
  }

  static async getEventSubscriptions () {
    return await api2.get('/api/eventsubscription/subscription')
  }

  static async deleteEventSubscription (eventSubscriptionId) {
    return await api2.delete('/api/eventsubscription/subscription?eventSubscriptionId=' + eventSubscriptionId)
  }

  static async enableEventSubscription (eventSubscriptionId) {
    return await api2.put('/api/eventsubscription/subscription?eventSubscriptionId=' + eventSubscriptionId)
  }

  //this call is for a completed subscription saved in the dB
  static async oauth (eventSubscriptionId) {
    return await api2.post('/api/eventsubscription/oauth?eventSubscriptionId=' + eventSubscriptionId)
  }

  //testing event subscription connection
  static async testOauth (sub) {
    const subscription = {
      ConnectionUrl: sub.ConnectionUrl,
      ClientId: sub.ClientId,
      ClientSecret: sub.ClientSecret
    }
    return await api2.post('/api/eventsubscription/testOauth', subscription)
  }
}
