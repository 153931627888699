<template>
  <div class="d-flex flex-column" style="height:100%;">
    <div v-if="selectedSet[0].QuestionSetId !== 0" class="font-weight-bold flex-grow-0 d-flex align-items-center">
      NAME:
      <mdb-input
        v-model="setName"
        :disabled="editing == false"
        type="text"
        style="margin-left:33px;width:300px;max-width:50%;"
        size="sm"
      />
      <mdb-btn
        v-if="editing == false && !newSet & !editingName"
        class="p-1 px-1 ml-3 btn-outline"
        style="width:25px;"
        @click="editing = true"
      >
        <i class="fa fa-edit " />
      </mdb-btn>
      <mdb-btn
        v-if="editing && !editingName"
        class="p-1 px-1 ml-3"
        style="width:25px;"
        @click="saveSetName()"
      >
        <i class="fa fa-save" />
      </mdb-btn>
      <mdb-icon
        v-if="editingName"
        icon="fa fa-pulse fa-spinner"
        class="p-0 px-1 ml-3"
      />
    </div>
    <div
      class="flex-grow-1 py-3 py-md-4 w-1/1 px-3 text-black rounded"
      :style="{'margin-top': selectedSet[0].QuestionSetId !== 0 ? '' : '30px'}"
      style="background:rgb(225, 225, 225);overflow-x:hidden;"
    >
      <Draggable
        :list="selectedSet[0].Questions"
        style="cursor:move;"
        @start="drag=true"
        @end="handleMove"
      >
        <b-row
          v-for="question in selectedSet[0].Questions"
          :key="question.CandidateQuestionListId"
          no-gutters
          style="width:100%;"
          class="text-bold text-black rounded text-center border-2 border-gray-400  bg-gray-100 px-3 py-2 px-md-4 my-1 flex-nowrap"
        >
          <div class="d-flex align-self-center align-items-center mr-3" style="text-align: left;font-size: 1rem;font-weight: bold;flex-grow:0;">
            <i
              v-if="question.VideoUrl != '' && question.VideoUrl != undefined"
              class="col-span-1 fa fa-2x text-left fa-video"
              style="font-size:1.2rem;"
            />
            <i
              v-else
              class="col-span-1 fa fa-2x text-left fa-question"
              style="font-size:1.2rem;"
            />
          </div>
          <b-col class="align-self-center twoLinedEllipsis" style="text-align:left;font-size:smaller;">
            {{ question.Description }}
          </b-col>
          <div class="align-self-center font-extrabold" style="font-size:smaller;width:50px;">
            <div style="font-size:smaller;width:50px;" class="ml-auto">
              {{ readableTimeLimit(question.TimeLimit) }}
            </div> <div style="font-size:smaller;width:50px;" class="ml-auto">
              mm:ss
            </div>
          </div>
          <div class="flex-grow-0 mr-3 d-flex align-items-center">
            <div style="width: 10px;">
              <mdb-popover
                ref="popover"
                trigger="click"
                :options="{placement: 'bottom'}"
                style="cursor:pointer;align-self:center;width:5px;"
                class="grey-text"
              >
                <div slot="body" style="width:fit-content !important">
                  <h6
                    class="d-flex mb-2"
                    style="align-items:center"
                    @click="editQuestion(question)"
                  >
                    <i class="fas fa-edit ml-1 grey-link" style="margin-right:10px;" /><a class="grey-link mr-3">Edit</a>
                  </h6>
                  <h6
                    class="d-flex mb-2"
                    style="align-items:center"
                    @click="openDeleteQuestionModal(question)"
                  >
                    <i class="fa fa-trash ml-1 grey-link " style="margin-right:7px;" /><a class="grey-link mr-3 text-nowrap">Delete Question</a>
                  </h6>
                </div>
                <mdb-icon
                  slot="reference"
                  style="width: 20px; height:30px; color:#555;font-size:1rem;"
                  class="ellipsisButtonHover ml-2"
                  icon="ellipsis-v"
                />
              </mdb-popover>
            </div>
          </div>
        </b-row>
      </Draggable>
      <div
        v-if="!loading"
        slot="footer"
        class="rounded text-center border-2 border-gray-400 border-dashed p-2"
        style="font-size:1rem;font-weight:bold;padding:20px !important;cursor:pointer;"
        @click="openAddQuestionModal()"
      >
        Add Question
      </div>
      <div
        v-else
        slot="footer"
        class="rounded text-center border-2 border-gray-400 border-dashed p-3"
        style="font-size:1rem;font-weight:bold;padding:20px !important;"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-end text-black" style="height:65px; width:100%;background:#999">
      <span v-if="!newSet" class="mr-3">Total Answer Time: <span class="font-weight-bold">{{ totalAnswerTime }} <span style="font-size:0.7rem;">mm:ss</span></span></span>
    </div>
    <div style="width:100%;height:65px;" class="d-flex justify-content-end align-items-center">
      <mdb-btn
        v-if="!newSet"
        class="p-2 btn-outline ml-0"
        size="sm"
        style="width:130px;"
        @click="openDeleteSetModal()"
      >
        DELETE SET
      </mdb-btn>
      <mdb-btn
        class="p-2 ml-auto mr-0"
        style="width:130px;"
        size="sm"
        @click="$emit('isAdding', false)"
      >
        FINISH
      </mdb-btn>
    </div>

    <!--add/edit question modal-->
    <AddEditQuestionModal
      :selected-set="selectedSet"
      :question-set-list="questionSetList"
      :question-modal-showing="questionModalShowing"
      :question="question"
      :editing-question="editingQuestion"
      @resetDefaultQuestion="resetDefaultQuestion()"
      @setQuestion="question = $event"
      @updateQuestionSet="updateQuestionSet($event)"
      @closeAddEditQuestionModal="questionModalShowing = false"
      @editingQuestion="editingQuestion = $event"
    />
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import questionSetService from '@/api/services/questionSet.service.js'

import Draggable from 'vuedraggable'
import AddEditQuestionModal from '../AddEditQuestionModal/questionModal.vue'

export default {
  name: 'CreateQuestionSets',
  components: {
    Draggable,
    AddEditQuestionModal
  },
  props: {
    questionSetList: {
      type: Array,
      default: () => []
    },
    selectedSet: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()

    return {
      authData,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal
    }
  },
  data () {
    return {
      questionLength: [
        { text: '00:30', value: 30, selected: true },
        { text: '00:45', value: 45 },
        { text: '01:00', value: 60 },
        { text: '01:15', value: 75 },
        { text: '01:30', value: 90 },
        { text: '01:45', value: 105 },
        { text: '02:00', value: 120 },
        { text: '02:15', value: 135 },
        { text: '02:30', value: 150 },
        { text: '02:45', value: 165 },
        { text: '03:00', value: 180 }
        //{ text: '03:15', value: 195 },
        //{ text: '03:30', value: 210 },
        //{ text: '03:45', value: 225 },
        //{ text: '04:00', value: 240 },
        //{ text: '04:15', value: 255 },
        //{ text: '04:30', value: 270 },
        //{ text: '04:45', value: 285 },
        //{ text: '05:00', value: 300 }
      ],
      questionSetQuestions: [],
      drag: false,
      loading: false,
      questionModalShowing: false,
      editingQuestion: false,
      defaultQuestion: { CandidateQuestionId: 0, CandidateQuestionListId: 0, Description: '', VideoUrl: '', IsPractiseQuestion: false, Tags: '', IsPool: false, TimeLimit: 30, QuestionSetId: 0, QuestionSetName: 'QUESTION SET NAME' },
      question: {},
      setName: '',
      editing: false,
      editingName: false,
      confirmDeleteModalShowing: false,
      deletingQuestion: false
    }
  },
  computed: {
    newSet () {
      return this.selectedSet[0].QuestionSetId === 0
    },
    totalAnswerTime () {
      let total = 0
      this.selectedSet[0].Questions.forEach(q => {
        total += q.TimeLimit
      })
      const minutes = Math.floor(total / 60)
      let seconds = total % 60
      if (seconds === 0) { seconds = '00' }
      return `${minutes}:${seconds}`
    }
  },
  watch: {
    questionModalShowing () {
      if (this.questionModalShowing && !this.editingQuestion) {
        this.resetDefaultQuestion()
      }
    },
    editingQuestion () {
      // if (!this.editingQuestion) { this.resetDefaultQuestion() }
    }
  },
  mounted () {
    this.setName = this.selectedSet[0].QuestionSetName
  },
  methods: {
    resetDefaultQuestion () {
      const questionSetId = this.question.QuestionSetId
      const questionSetName = this.question.QuestionSetName
      this.question = { ...this.defaultQuestion, QuestionSetId: questionSetId, QuestionSetName: questionSetName }
    },
    async deleteQuestionSet () {
      try {
        await questionSetService.deleteQuestionSet(this.selectedSet[0].QuestionSetId)
        this.$emit('reloadSets')
        this.closeGenericConfirmModal()
        this.$emit('isAdding', false)
      } catch {
        this.showGenericErrorModal({ showing: true })
      }
    },
    openDeleteQuestionModal (question) {
      this.question = question
      this.deletingQ = true
      let message = 'Are you sure you want to remove this question from the question set?'
      if (this.selectedSet[0].Questions.length === 1) {
        message += '\nNOTE: Deleting the last question in a set will also delete the set.'
      }
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'CONFIRM DELETE QUESTION',
        confirmMessage: message,
        confirmButtonMessage: 'CONFIRM',
        confirmButtonFunction: () => this.deleteQuestion()
      })
    },
    openDeleteSetModal () {
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'CONFIRM DELETE SET',
        confirmMessage: 'Are you sure you want to delete this Question Set?',
        confirmButtonMessage: 'CONFIRM',
        confirmButtonFunction: () => this.deleteQuestionSet()
      })
    },
    async deleteQuestion () {
      var formData = new FormData()
      // set as 0 so backend knows it is adding question
      formData.append('questionId', this.question.CandidateQuestionId)
      formData.append('description', 'todelete')
      formData.append('videoUrl', '')
      formData.append('tags', '')
      formData.append('toDelete', true)
      formData.append('timeLimit', 0)
      formData.append('isPool', false)
      formData.append('questionSetId', this.selectedSet[0].QuestionSetId)
      formData.append('questionListId', this.question.CandidateQuestionListId)
      formData.append('questionSetName', '')
      try {
        const res = await questionSetService.addEditQuestionSetQuestion(formData)
        if (res.data.length === 0) {
          this.deleteQuestionSet()
        } else {
          const convertedResponse = this.convertResponse(res.data)
          this.$emit('updateQuestionSet', convertedResponse)
          this.closeGenericConfirmModal()
        }
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
    },
    async saveSetName () {
      this.editingName = true
      this.editing = false
      try {
        const res = await questionSetService.saveQuestionSetName(this.selectedSet[0].QuestionSetId, this.setName)
        this.$emit('updateQuestionSet', this.convertResponse(res.data))
      } catch (err) { 
        //?
       }
      this.editingName = false
    },
    convertResponse (res) {
      const converted = [{
        QuestionSetId: res[0].QuestionSetId,
        QuestionSetName: res[0].QuestionSetName,
        Questions: [...res]
      }]
      return converted
    },
    updateQuestionSet (set) {
      this.$emit('updateQuestionSet', set)
    },
    saveQuestionSet () {
      this.$emit('isAdding', true)
    },
    editQuestion (question) {
      this.questionModalShowing = true
      this.editingQuestion = true
      // to add the current set info if set is already create
      this.question = question
    },
    handleMove () {
      var ids = ''
      this.questionSetQuestions.forEach(e => {
        ids = ids + e.CandidateQuestionListId + ','
      })
      // this.updateOrdinals(ids)
    },
    openAddQuestionModal () {
      this.questionIsTextOrVideo = 1
      this.questionModalShowing = true
      this.editingQuestion = false
      // to add the current set info if set is already create
      this.resetDefaultQuestion()
      if (this.selectedSet.length > 0) {
        this.question.QuestionSetId = this.selectedSet[0].QuestionSetId
        this.question.QuestionSetName = this.selectedSet[0].QuestionSetName
      }
    },
    readableTimeLimit (time) {
      return this.questionLength.filter(q => q.value === time)[0].text
    }
  }
}
</script>
