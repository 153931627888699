<template>
  <b-table
    class="text-left dark-grey-text"
    :items="locations"
    :fields="locationFields"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    head-variant="dark"
    :striped="true"
    responsive
    hover
    small
    sort-icon-left
    auto-width
  >
    <template #cell(name)="data">
      <div style="margin-left:5px;" class="smFont">
        {{ data.item.GoEventLocationName }}
      </div>
    </template>
    <template #cell(PostCode)="data">
      <div style="margin-left:5px;" class="smFont">
        {{ data.item.PostCode }}
      </div>
    </template>
    <template #cell(Town)="data">
      <div style="margin-left:5px;" class="smFont">
        {{ data.item.Town }}
      </div>
    </template>
    <!-- Todo edit -->
    <template #cell(ActionsA)="data">
      <!-- <button
        title="Edit this lcoation?"
        type="button"
        class="btn btn-primary btn-md"
        style="padding: 0.5rem 0.6rem;margin:0.175rem;"
      >
        <mdb-icon icon="pencil-alt" />
      </button> -->
      <span
        title="Delete this location?"
        @click="deleteLocation(data.item.GoEventLocationId)"
      >
        <FontAwesomeIcon
          icon="fa-trash-can-xmark fa-solid"
          class="ml-1 grey-link"
          style="margin-right:10px;float: right;"
        />
      </span>
    </template>
  </b-table>
</template>

<script>
import { ref } from "vue"
import { useStatus, useEvent } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name:'LocationTable',
  setup () {
    const { showGenericConfirmModal } = useStatus()
    const { deleteEventLocation } = useEvent()

    const eventStore = useEvent()
    const { locations } = storeToRefs(eventStore)

    const sortBy = ref('')
    const sortDesc = ref(false)

    const locationFields = [
      { key: 'Name', label: ' Name', tdClass: ' col40 sortIcon', sortable: true },
      { key: 'PostCode', label: '  Post Code', tdClass: ' col30 sortIcon', sortable: true },
      { key: 'Town', label: '  Town', tdClass: ' col20 sortIcon', sortable: true },
      { key: 'ActionsA', label: '', tdClass: 'col10' }
    ]

    const deleteLocation = (id) => {
      showGenericConfirmModal({
        showing: true,
        confirmTitle: 'DELETE LOCATION',
        confirmMessage: 'Are you sure you want to delete this location?',
        confirmButtonMessage: 'DELETE',
        confirmButtonFunction: () => deleteEventLocation(id)
      })
    }

    return {
      deleteLocation,
      locationFields,
      sortBy,
      sortDesc,
      locations
    }
  }
}
</script>
