<template>
  <div>
    <h6>
      <!-- EXPIRED -->
      <i
        v-if="candidateApplication.StageItemStatus === 5"
        class="fa fa-no-entry"
        style="margin-right:10px;margin-left:3px;"
      /> 
      <i
        v-else-if="candidateApplication.CompleteStatusType"
        class="fa fa-star"
        style="margin-right:10px;margin-left:3px;"
      /> 
      <i
        v-else
        class="fa fa-star-half-alt"
        style="margin-right:10px;margin-left:3px;"
      /> 
      {{ candidateApplication.RoleWorkflowStageItemName }}
    </h6>
    
    <div v-if="candidateApplication.CompleteStatusType">
      <h6
        v-for="(stageItem, i) in statusesNotIncludingCurrent"
        :key="i"
        @click="setStatus(stageItem.RoleWorkflowStageItemId)"
      >
        <i
          class="fa fa-question-circle ml-1 grey-link"
          style="margin-right:10px;"
        />
        <a class="grey-link">
          Set As {{ stageItem.RoleWorkflowStageItemName }}?
        </a>
      </h6>
    </div>
  </div>          
</template>

<script>
import { useRoleCandidate } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'LHSPopoverStatusChange',
  props: {
    candidateApplication: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    const roleCandidateStore = useRoleCandidate()
    const { reviewModalSelectedStage } = storeToRefs(roleCandidateStore)
    return {
      reviewModalSelectedStage
    }
  },
  computed: {
    statusesNotIncludingCurrent () {
      return this.reviewModalSelectedStage.OrderedRoleWorkflowStageItems.filter(i => i.CompleteStatusType && i.RoleWorkflowStageItemId != this.candidateApplication.CurrentStatus)
    }
  },
  methods: {
    setStatus (type) {
      this.$emit('setStatus', type)
    }
  }
}
</script>
