<template>
  <div
    v-if="initialised"
    class="text-bold text-black rounded text-center border-2 border-gray-400 bg-gray-100 my-1 flex-nowrap px-2"
    style="transition: all 1s ease;width:100%;"
  >
    <b-row no-gutters style="width:100%;height:60px;">
      <div style="width:10%; max-width: 40px;position:relative;border-radius:5px" class="m-2 d-none d-sm-flex align-items-center justify-content-center">
        <FontAwesomeIcon icon="fa-circle-question fa-solid" style="font-size:1rem;position:absolute; top:4px; left:12px;" />
        <FontAwesomeIcon icon="fa-circle-question fa-solid" style="font-size:1rem;position:absolute; top:18px; left:4px;" />
        <FontAwesomeIcon icon="fa-circle-question fa-solid" style="font-size:1rem;position:absolute; top:18px; left:20px;" />
      </div>
      <b-col
        :title="questionSet.QuestionSetName"
        class="align-self-center twoLinedEllipsis ml-2"
        style="text-align:left;font-size:smaller;width:fit-content;"
      >
        {{ questionSet.QuestionSetName }}
      </b-col>
      <div class="d-flex align-self-center align-items-center justify-content-center font-extrabold mr-auto px-1" style="font-size:smaller;height:30px;border:solid 2px var(--pr-color);border-radius:15px;cursor:default;">
        <mdb-popover
          ref="popover"
          trigger="hover"
          :options="{placement: 'left'}"
        >
          <div slot="body">
            <div v-for="(q, i) in questionSet.Questions" :key="i">
              <span class="font-weight-bold text-black">{{ i+1 }}. </span><span>{{ q.Description }}</span>
            </div>
          </div>
          <div
            v-if="!randomiseQuestions && noOfRandomisedQuestions != null"
            slot="reference"
            style="padding:10px;"
          >
            <div class="d-block d-sm-none" style="font-size:smaller;">
              {{ questionSet.Questions.length }} Q{{ questionSet.Questions.length > 1 ? '\'S' : '' }}
            </div>
            <div class="d-none d-sm-block" style="font-size:smaller;">
              {{ questionSet.Questions.length }} QUESTION{{ questionSet.Questions.length > 1 ? 'S' : '' }}
            </div>
          </div>
          <div
            v-else
            slot="reference"
            style="padding:10px;"
          >
            <div class="d-block d-sm-none" style="font-size:smaller;">
              {{ noOfRandomisedQuestions }} Q{{ noOfRandomisedQuestions > 1 ? '\'S' : '' }}
            </div>
            <div class="d-none d-sm-block" style="font-size:smaller;">
              {{ noOfRandomisedQuestions }} QUESTION{{ noOfRandomisedQuestions > 1 ? 'S' : '' }}
            </div>
          </div>
        </mdb-popover>
      </div>
      <div v-if="!invitesHaveBeenSent" class="flex-grow-0 ml-3 mr-2 d-flex align-items-center">
        <FontAwesomeIcon
          v-if="deletingSetId !== questionSet.QuestionSetId"
          class="cp"
          icon="fa-solid fa-trash-can-xmark"
          size="1x"
          @click="openDeleteSetModal()"
        />
        <i v-else class="fa fa-pulse fa-spinner" />
      </div>
      <div class="flex-grow-0 mx-2 d-flex align-items-center">
        <FontAwesomeIcon
          class="chevron cp"
          icon="fa-regular fa-circle-chevron-down"
          size="1x"
          :class="{ 'rotatedChevron' : !collapsed }"
          @click="changeCollapsing()"
        />
      </div>
    </b-row>
    <SlideFade>
      <div v-if="collapsed" class="slide-in">
        <b-row
          v-for="(question, i) in questionSet.Questions"
          :key="i"
          no-gutters
          style="width:100%;height:70px;"
          :style="{'--i': i}"
          class="slide-in text-bold text-black rounded text-center border-2 border-gray-400  bg-gray-100 px-2 py-2 px-md-4 my-1 flex-nowrap"
        >
          <b-col
            v-if="isVideoCV"
            class="align-self-center mr-3"
            style="text-align: left;font-size: 1rem;font-weight: bold;width:50px;flex-grow:0;margin-top:10px;"
          >
            <div
              v-if="question.AnswerUrl == ''"
              class="navIcon"
              title="This question has been not answered yet"
            >
              <i class="col-span-1 fa fa-2x text-left fa-times" style="font-size:1.2rem;color:#E1E1E1" />
            </div>
            <div
              v-else
              class="navIcon"
              title="This question has been answered"
            >
              <i class="col-span-1 fa fa-2x text-left fa-check" style="font-size:1.2rem;" />
            </div>
          </b-col>
          <div
            v-else
            class="align-self-center mr-1"
            style="text-align: left;font-size: 1rem;font-weight: bold;"
          >
            <i
              v-show="question.IsVideoQuestion == false"
              class="col-span-1 fa fa-2x text-left fa-question-circle"
              style="font-size:1.2rem;"
            />
            <i
              v-show="question.IsVideoQuestion"
              class="col-span-1 fa fa-2x text-left fa-video"
              style="font-size:1.2rem;"
            />
          </div>
          <b-col class="align-self-center twoLinedEllipsis" style="text-align:left;font-size:smaller;">
            {{ question.Description }}
          </b-col>
          <div class="d-flex flex-column align-self-center font-extrabold ml-auto" style="font-size:smaller;width:50px;">
            <div style="font-size:smaller;width:50px;" class="ml-auto">
              {{ readableTimeLimit(question.TimeLimit) }}
            </div>
            <div style="font-size:smaller;width:50px;" class="ml-auto">
              mm:ss
            </div>
          </div>
          <div v-if="isVideoCV && question.AnswerUrl !== ''" class="align-self-center justify-self-end">
            <mdb-btn
              class="btn"
              size="sm"
              style="padding: 0.5rem 1.2rem;font-size:0.8rem !important; letter-spacing:1px"
              @click="$emit('openPlayVideoModal', question)"
            >
              PLAY
            </mdb-btn>
          </div>
          <VCVPopover
            v-if="isVideoCV"
            class="mr-4 mr-md-3"
            :question="question"
            @openDeleteQuestionModal="$emit('openDeleteQuestionModal', $event)"
            @openPlayVideoModalCapturing="$emit('openPlayVideoModalCapturing', $event)"
            @openPlayVideoModal="$emit('openPlayVideoModal', $event);"
            @openResetQuestionModal="$emit('openResetQuestionModal', $event)"
            @editQuestion="$emit('editQuestion', $event)"
          />
        </b-row>
        <b-row v-if="!isVideoCV" no-gutters>
          <b-col cols="12" md="9">
            <div class="d-flex align-items-center my-1">
              <div style="font-size:0.8rem;max-width:70%;">
                Add Scenario  <span class="d-none d-sm-inline">Text</span>
              </div>
              <div style="width:60px;">
                <mdb-switch
                  v-model="addScenarioText"
                  style="transform:scale(0.9) translateY(3px)"
                  :disabled="invitesHaveBeenSent"
                  off-label=""
                  on-label=""
                  class="m-0"
                />
              </div>
              <mdb-input
                v-if="addScenarioText"
                v-model="scenarioText"
                :disabled="invitesHaveBeenSent"
                type="text"
                class="ml-3 p-0 m-0"
                style="transform:translateY(-25px);height:0px;min-width:130px;"
                size="sm"
              />
            </div>
          </b-col>
          <b-col
            v-if="questionSet.Questions.length > 1"
            cols="12"
            sm="9"
          >
            <div class="d-flex align-items-center mb-2">
              <div cols="auto" style="font-size:0.8rem;max-width:70%;">
                Randomise <span class="d-none d-sm-inline">Questions</span>
              </div>
              <div style="width:60px;">
                <mdb-switch
                  v-model="randomiseQuestions"
                  :disabled="invitesHaveBeenSent"
                  style="transform:scale(0.9) translateY(3px)"
                  off-label=""
                  on-label=""
                  class="m-0"
                />
              </div>
              <mdb-select
                v-if="randomiseQuestions"
                v-model="randomiseOptions"
                :disabled="invitesHaveBeenSent"
                placeholder="Number of Questions"
                class="ml-3 p-0 m-0"
                style="transform:translateY(-48px);height:0px;"
                size="sm"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-end mb-1"
          >
            <mdb-btn
              v-if="!savingQuestionSet"
              :disabled="!validSetSettings || invitesHaveBeenSent"
              class="p-1 py-2 mr-0 ml-auto"
              size="sm"
              style="width:60px;"
              @click="saveQuestionSet()"
            >
              SAVE
            </mdb-btn>
            <mdb-btn
              v-else
              class="p-1 py-2 mr-0 ml-auto"
              size="sm"
              style="width:60px;"
            >
              <i class="fa fa-pulse fa-spinner" />
            </mdb-btn>
          </b-col>
        </b-row>
      </div>
    </SlideFade>
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import questionSetService from '@/api/services/questionSet.service.js'

import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'
import VCVPopover from '@/views/candidates/Tabs/VideoCV/vcvPopoverButton.vue'

export default {
  name: 'QuestionSet',
  components: {
    SlideFade,
    VCVPopover
  },
  props: {
    invitesHaveBeenSent: {
      type: Boolean,
      default: false
    },
    questionSet: {
      type: Object,
      default: () => {}
    },
    isVideoCV: Boolean,
    deletingSetId: {
      type: Number,
      default: 0
    }
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()

    return {
      authData,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal
    }
  },
  data () {
    return {
      confirmedAfterInvitesSent: true,
      savingQuestionSet: false,
      addScenarioText: false,
      scenarioText: '',
      randomiseQuestions: false,
      collapsed: false,
      collapsing: false,
      questionLength: [
        { text: '00:30', value: 30, selected: true },
        { text: '00:45', value: 45 },
        { text: '01:00', value: 60 },
        { text: '01:15', value: 75 },
        { text: '01:30', value: 90 },
        { text: '01:45', value: 105 },
        { text: '02:00', value: 120 },
        { text: '02:15', value: 135 },
        { text: '02:30', value: 150 },
        { text: '02:45', value: 165 },
        { text: '03:00', value: 180 }
        //{ text: '03:15', value: 195 },
        //{ text: '03:30', value: 210 },
        //{ text: '03:45', value: 225 },
        //{ text: '04:00', value: 240 },
        //{ text: '04:15', value: 255 },
        //{ text: '04:30', value: 270 },
        //{ text: '04:45', value: 285 },
        //{ text: '05:00', value: 300 }
      ],
      randomiseOptions: [{ selected: true, value: 0, text: 'Choose' }],
      initialised: false
    }
  },
  computed: {
    validSetSettings () {
      const selectedRandomise = this.randomiseOptions.filter(q => q.selected)[0].value || 0
      if (this.randomiseQuestions && selectedRandomise === 0) {
        return false
      } else if (this.addScenarioText && this.scenarioText === '') {
        return false
      } else {
        return true
      }
    },
    noOfRandomisedQuestions () {
      try {
        return this.randomiseOptions.filter(o => o.selected)[0].value
      } catch { return null }
    }
  },
  watch: {
    'questionSet.Questions': {
      handler: function () {
        try {
          if (this.questionSet.Questions !== undefined || this.questionSet.Questions.length !== 0) { this.initComponentFromProps() }
        } catch {
          //?
        }
      },
      immediate: true
    },
    addScenarioText () {
      if (!this.addScenarioText) {
        this.scenarioText = ''
      }
    },
    randomiseQuestions () {
      if (!this.randomiseQuestions) {
        this.randomiseOptions = this.randomiseOptions.map(o => o.value === 0 ? { ...o, selected: true } : { ...o, selected: false })
      }
    }
  },
  mounted () {
    this.initialised = true
  },
  methods: {
    showConfirmActionModalIfInvitesSent (func) {
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'CONFIRM CHANGE',
        confirmMessage: 'Please be aware changing questions could affect existing invitations. If you continue, we strongly advise resetting all existing invites, as some of your settings maybe not be saved.',
        confirmButtonMessage: 'CONFIRM',
        confirmButtonFunction: () => func()
      })
    },
    async saveQuestionSet () {
      if (this.invitesHaveBeenSent && !this.confirmedAfterInvitesSent) {
        this.showConfirmActionModalIfInvitesSent(this.saveQuestionSet)
        this.confirmedAfterInvitesSent = true
        return
      }
      this.savingQuestionSet = true
      var formData = new FormData()
      formData.append('questionSetId', this.questionSet.QuestionSetId)
      formData.append('addScenario', this.addScenarioText)
      formData.append('scenarioText', this.scenarioText)
      formData.append('randomiseQuestions', this.randomiseQuestions)
      const randomiseCount = this.randomiseOptions.filter(o => o.selected)[0].value
      formData.append('randomiseCount', randomiseCount)
      try {
        await questionSetService.editQuestionSetScenarioAndRandomisation(formData)
        this.$emit('reloadQuestionSetSource')
        this.closeGenericConfirmModal()
      } catch (err) {
        this.showGenericErrorModal({ showing: true, errorMessage: 'Error saving your question set settings', errorTitle: 'Error' })
      }
      setTimeout(() => { this.savingQuestionSet = false }, 1000)
      this.confirmedAfterInvitesSent = false
    },
    async deleteSet () {
      this.$emit('deleteSet', this.questionSet)
    },
    openDeleteSetModal (question) {
      this.question = question
      this.deletingQ = true
      if (this.isVideoCV) {
        this.showGenericConfirmModal({
          showing: true,
          confirmTitle: 'CONFIRM DELETE SET',
          confirmMessage: 'Are you sure you want to remove this question set from the candidates Video CV?',
          confirmButtonMessage: 'CONFIRM',
          confirmButtonFunction: () => this.deleteSet()
        })
      } else {
        let message = 'Are you sure you want to remove this question set from the role?'
        if (this.invitesHaveBeenSent) {
          message += ' Please be aware changing questions will affect existing invitations. If you continue, we strongly advise resetting all existing invites, as some of your settings maybe not be saved.'
        }
        this.showGenericConfirmModal({
          showing: true,
          confirmTitle: 'CONFIRM DELETE SET',
          confirmMessage: message,
          confirmButtonMessage: 'CONFIRM',
          confirmButtonFunction: () => this.deleteSet()
        })
      }
    },
    changeCollapsing () {
      this.collapsing = true
      this.$nextTick(() => {
        this.collapsed = !this.collapsed
      })
      setTimeout(() => {
        this.collapsing = false
      }, 1000)
    },
    readableTimeLimit (time) {
      const result = this.questionLength.filter(q => q.value === time)[0]
      if (result !== undefined) { return result.text }
      return 'unknown'
    },
    initComponentFromProps () {
      try {
        if (this.questionSet.Questions.length > 1) {
          let options = [{ value: 0, text: 'Choose', selected: true }]
          this.questionSet.Questions.map((q, i) => {
            options[i + 1] = { value: i + 1, text: i + 1 }
          })
          options.pop()
          if (this.questionSet.RandomiseCount !== 0) {
            options = options.map(o => o.value === this.questionSet.RandomiseCount ? { ...o, selected: true } : { ...o, selected: false })
          }
          this.randomiseOptions = options
        }
        this.addScenarioText = this.questionSet.AddScenario
        this.scenarioText = this.questionSet.ScenarioText
        this.randomiseQuestions = this.questionSet.RandomiseQuestions
        if (this.questionSet.Questions.length === 1) {
          this.randomiseQuestions = false
        }
        if (this.invitesHaveBeenSent) {
          this.confirmedAfterInvitesSent = false
        }
      } catch (err) { 
        //?
      }
    }
  }
}
</script>

<style lang="scss">
.chevron.rotatedChevron {
  transition: 0.5s ease-out;
  transform: rotate(180deg)  scale(1.2);
}
.chevron {
  transform: rotate(0deg) scale(1.2);
  transition: 0.5s ease-out;
}
.slide-in {
  &-enter-active {
    transition: opacity .5s linear, transform .5s cubic-bezier(.2,.5,.1,1);
    transition-delay: calc( 0.1s * var(--i) );
  }

  &-enter {
    opacity: 0;
  }

  &-enter { transform: translateX(30px); }
}
</style>
