<template>
  <div class="p-4 p-lg-5" style="height:fit-content;overflow-y:scroll;">
    <b-row
      no-gutters
      class="p-0 p-md-3"
      style="margin-top:-30px;"
    >
      <div class="d-flex align-items-center">
        <mdb-icon icon="fa fa-cog mr-2" size="sm" />
        <h3 class="text-left">
          Share Settings
        </h3>
      </div>
    </b-row>
    <div class="px-2 px-md-4" style="font-size:0.9rem;margin-left:-20px;margin-top:-30px;">
      <div>
        <mdb-input
          v-model="shareName"
          type="text"
          auto-complete="exp"
          label="Share name"
          size="sm"
          style="min-width:250px;width:50%;"
          class="ml-3"
        />
      </div>
      <div class="d-flex align-items-center">
        <mdb-switch
          v-model="includePersonalInfo"
          off-label=""
          on-label=""
        />
        <h6 class="text-left font-sm" :class="{'dark-grey-text': !includePersonalInfo }">
          Include Personal Information?
        </h6>
      </div>
      <div class="ml-3 mb-2" :class="{'grey-text' : !includePersonalInfo }">
        <div class="d-flex align-items-center">
          <mdb-switch
            v-model="includeContactInfo"
            :disabled="!includePersonalInfo"
            off-label=""
            on-label=""
          />
          <h5
            class="text-left"
            style="font-size:0.8rem;"
            :class="{'dark-grey-text': !includeContactInfo }"
          >
            Include Contact Details?
          </h5>
        </div>
        <div class="d-flex align-items-center">
          <mdb-switch
            v-model="includeEducationInfo"
            :disabled="!includePersonalInfo"
            off-label=""
            on-label=""
          />
          <h5
            class="text-left"
            style="font-size:0.8rem;"
            :class="{'dark-grey-text': !includeEducationInfo }"
          >
            Include Education Information?
          </h5>
        </div>
        <div class="d-flex align-items-center">
          <mdb-switch
            v-model="includeSalaryInfo"
            :disabled="!includePersonalInfo"
            off-label=""
            on-label=""
          />
          <h5
            class="text-left"
            style="font-size:0.8rem;"
            :class="{'dark-grey-text': !includeSalaryInfo }"
          >
            Include Salary Information?
          </h5>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <mdb-switch
          v-model="includeBioInfo"
          off-label=""
          on-label=""
        />
        <h6 class="text-left font-sm" :class="{'dark-grey-text': !includeBioInfo }">
          Include Bio Information?
        </h6>
      </div>
      <div class="d-flex align-items-center">
        <mdb-switch
          v-model="includeCVInfo"
          off-label=""
          on-label=""
        />
        <h6 class="text-left font-sm" :class="{'dark-grey-text': !includeCVInfo }">
          Include CV?
        </h6>
      </div>
      <div class="d-flex align-items-center">
        <mdb-switch
          v-model="includeOtherFiles"
          off-label=""
          on-label=""
        />
        <h6 class="text-left font-sm" :class="{'dark-grey-text': !includeOtherFiles }">
          Include Other Files?
        </h6>
      </div>
      <div class="d-flex align-items-center">
        <mdb-switch
          v-model="includeLinkedIn"
          off-label=""
          on-label=""
        />
        <h6 class="text-left font-sm" :class="{'dark-grey-text': !includeLinkedIn }">
          Include LinkedIn?
        </h6>
      </div>
      <div class="d-flex align-items-center">
        <mdb-switch
          v-model="requestFeedback"
          off-label=""
          on-label=""
        />
        <h6 class="text-left font-sm" :class="{'dark-grey-text': !requestFeedback }">
          Request Feedback?
        </h6>
      </div>

      <div
        style="margin-left:10px !important;height:fit-content;"
        no-gutters
        class="md-form form-sm m-0 mt-3 mb-2 d-flex"
        @click="enableTrackingNotifications = !enableTrackingNotifications"
      >
        <i class="fas fa-user prefix mt-2" style="font-size: 1rem;" />
        <mdb-input
          v-model="enableTrackingNotifications"
          type="checkbox"
          label="Tracking notifications?"
          size="sm"
          class="ml-2"
        />
      </div>
      <div
        style="margin-left:10px !important;height:fit-content;"
        no-gutters
        class="md-form form-sm m-0 mb-2 d-flex mb-5"
        @click="enableDomainAuthSharing = !enableDomainAuthSharing"
      >
        <i class="fas fa-user prefix mt-2 mr-3" style="font-size: 1rem;" />
        <mdb-input
          v-model="enableDomainAuthSharing"
          type="checkbox"
          label="Domain authentication?"
          size="sm"
          class="ml-2"
        />
      </div>
      <div
        v-show="enableDomainAuthSharing"
        class="md-form"
        style="margin-left:10px !important;transform:translateY(15px);margin-top:-40px;"
      >
        <mdb-input
          v-model="allowedDomain"
          type="text"
          auto-complete="exp"
          label="Allowed domain e.g. http://www.youralloweddomain.com"
          size="sm"
          class="w-100 mb-5"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useUserData, useCandidate } from '@/pinia'
import { storeToRefs } from 'pinia'
import userService from '@/api/services/user.service'

export default {
  name: '',
  setup () {
    const candidateStore = useCandidate()
    const { candidatesAddedToShare } = storeToRefs(candidateStore)

    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    return {
      authData,
      candidatesAddedToShare
    }
  },
  data () {
    return {
      allowedDomain: '',
      shareName: '',
      enableTrackingNotifications: false,
      enableDomainAuthSharing: false,
      includeCVInfo: true,
      includeBioInfo: true,
      includeOtherFiles: true,
      includeContactInfo: true,
      includeSalaryInfo: true,
      includeEducationInfo: true,
      contactInfoInitial: true,
      educationInfoInitial: true,
      salaryInfoInitial: true,
      includeLinkedIn: true,
      includePersonalInfo: true,
      requestFeedback: false
    }
  },
  watch: {
    allowedDomain () {
      this.$emit('allowedDomain', this.allowedDomain)
    },
    enableTrackingNotifications () {
      this.$emit('enableTrackingNotifications', this.enableTrackingNotifications)
    },
    enableDomainAuthSharing () {
      if (this.enableDomainAuthSharing === false) { this.allowedDomain = '' }
      this.$emit('enableDomainAuthSharing', this.enableDomainAuthSharing)
    },
    includeCVInfo () {
      this.$emit('includeCVInfo', this.includeCVInfo)
    },
    includeBioInfo () {
      this.$emit('includeBioInfo', this.includeBioInfo)
    },
    includePersonalInfo () {
      if (this.includePersonalInfo === true) {
        this.includeContactInfo = this.contactInfoInitial
        this.includeSalaryInfo = this.salaryInfoInitial
        this.includeEducationInfo = this.educationInfoInitial
      } else {
        this.includeContactInfo = false
        this.includeSalaryInfo = false
        this.includeEducationInfo = false
      }
      this.$emit('includePersonalInfo', this.includePersonalInfo)
    },
    includeOtherFiles () {
      this.$emit('includeOtherFiles', this.includeOtherFiles)
    },
    includeEducationInfo () {
      this.$emit('includeEducationInfo', this.includeEducationInfo)
    },
    includeContactInfo () {
      this.$emit('includeContactInfo', this.includeContactInfo)
    },
    includeLinkedIn () {
      this.$emit('includeLinkedIn', this.includeLinkedIn)
    },
    includeSalaryInfo () {
      this.$emit('includeSalaryInfo', this.includeSalaryInfo)
    },
    requestFeedback () {
      this.$emit('requestFeedback', this.requestFeedback)
    },
    shareName () {
      this.$emit('shareName', this.shareName)
    }
  },
  mounted () {
    this.$emit('settingsLoading', true)
  },
  created () {
    this.loadUserSettings()
  },
  methods: {
    async loadUserSettings () {
      try {
        const res = await userService.getUserSettings()
        res.data.SharingEnableDomainAuthSharing === false || res.data.SharingEnableDomainAuthSharing === undefined ? this.enableDomainAuthSharing = false : this.enableDomainAuthSharing = true
        res.data.SharingEnableTrackingNotifications === false || res.data.SharingEnableTrackingNotifications === undefined ? this.enableTrackingNotifications = false : this.enableTrackingNotifications = true
        res.data.SharingIncludeBioInfo === true || res.data.SharingIncludeBioInfo === undefined ? this.includeBioInfo = true : this.includeBioInfo = false
        res.data.SharingIncludeCV === true || res.data.SharingIncludeCV === undefined ? this.includeCVInfo = true : this.includeCVInfo = false
        res.data.SharingIncludeOtherFiles === true || res.data.SharingIncludeOtherFiles === undefined ? this.includeOtherFiles = true : this.includeOtherFiles = false
        res.data.SharingIncludeContactInfo === true || res.data.SharingIncludeContactInfo === undefined ? this.includeContactInfo = true : this.includeContactInfo = false
        res.data.SharingIncludeContactInfo === true || res.data.SharingIncludeContactInfo === undefined ? this.contactInfoInitial = true : this.contactInfoInitial = false
        res.data.SharingIncludeSalaryInfo === true || res.data.SharingIncludeSalaryInfo === undefined ? this.includeSalaryInfo = true : this.includeSalaryInfo = false
        res.data.SharingIncludeSalaryInfo === true || res.data.SharingIncludeSalaryInfo === undefined ? this.salaryInfoInitial = true : this.salaryInfoInitial = false
        res.data.SharingIncludeEducationInfo === true || res.data.SharingIncludeEducationInfo === undefined ? this.includeEducationInfo = true : this.includeEducationInfo = false
        res.data.SharingIncludeEducationInfo === true || res.data.SharingIncludeEducationInfo === undefined ? this.educationInfoInitial = true : this.educationInfoInitial = false
        res.data.SharingIncludeLinkedIn === true || res.data.SharingIncludeLinkedIn === undefined ? this.includeLinkedIn = true : this.includeLinkedIn = false
        res.data.SharingIncludePersonalInfo === true || res.data.SharingIncludePersonalInfo === undefined ? this.includePersonalInfo = true : this.includePersonalInfo = false
        res.data.SharingRequestFeedback === false || res.data.SharingRequestFeedback === undefined ? this.requestFeedback = false : this.requestFeedback = true
        this.allowedDomain = ''
        this.$emit('settingsLoading', false)
      } catch (err) {
        this.$emit('settingsLoading', false)
      }
    }
  }
}
</script>

<style lang="css">
label.custom-control-input {
  background: var(--pr-color) !important
}
</style>
