<template>
  <!--scoring-->
  <div class="flex-grow-1 reviewModalRHTab">
      <ImprovedScoring
        :questions="candidateApplication.Questions"
        :current-question-id="currentQuestionId"
        :candidate-application="candidateApplication"
        @setQuestionId="$emit('setQuestionId', $event)"
      />
  </div>
</template>

<script>
import ImprovedScoring from '@/components/misc/ReviewModalV2/reviewModalComponents/scoring/ImprovedScoring'

export default {
  name: 'Scoring',

  components: {
    ImprovedScoring
  },

  props: {
    candidateApplication: {
      type: Object,
      default: () => {}
    },
    currentQuestionId: {
      type: Number,
      default: 1
    },
    currentVideo: {
      type: Object,
      default: () => {}
    },
    stageType: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss">
.vue-slider-process {
  background-color: var(--pr-color) !important;
  transition: background-color 0.3s;
  @at-root .vue-slider:hover & {
    background-color: var(--pr-color) !important;
  }
}
.vue-slider-process {
  background-color: var(--pr-color) !important;
  transition: background-color 0.3s;
  @at-root .vue-slider:hover & {
    background-color: var(--pr-color) !important;
  }
}
.reviewModalScoringTabrange-wrap {
  position: relative;
  margin: 0 auto 3rem;
}
.reviewModalScoringTabrange {
  width: 100%;
}
.vue-slider-dot-handle {
  border: 2px solid var(--pr-color) !important
}
.vue-slider:hover .vue-slider-dot-handle {
  border-color: var(--pr-color) !important;
}
</style>
