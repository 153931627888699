
<template>
  <div
    v-if="!allDataFetched"
    ref="sentinel"
    v-observe-visibility="handleInfinityScroll"
    class="sentinel"
  >
    <mdb-icon icon="fa fa-pulse fa-spinner m-2 mt-5" size="2x" />
  </div>
</template>

<script>

export default {
  name: 'InfiniteScroll',
  props: {
    gettingData: Boolean,
    allDataFetched: Boolean
  },
  methods: {
    handleInfinityScroll (isVisible) {
      if (!this.gettingData && !this.allDataFetched) {
        this.$emit('getMoreData')
      }
    }
  }
}
</script>
