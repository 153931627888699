import axiosInstance from '../instance.js'
const api1 = new axiosInstance(1)

export default class videoIntroService {
  static async createVideoIntro (formData) {
    return await api1.post('/api/go/createvideointro', formData)
  }

  static async createVideoIntroHistory (historyType, goVideoIntroId) {
    return await api1.post(`/api/go/createvideointrohistory?historyType=${historyType}&goVideoIntroId=${goVideoIntroId}`, {})
  }

  static async getAllShineIntros () {
    return await api1.get('/api/go/getallshineintros')
  }

  static async getVideoIntroHistory (goVideoIntroId) {
    return await api1.get(`/api/go/getvideointrohistory?goVideoIntroId=${goVideoIntroId}`)
  }

  // brandId / video Intro Id
  static async reBrand (brId, viId) {
    return await api1.post(`/api/go/rebrandvideointro?brId=${brId}&viId=${viId}`, {})
  }

  static async shareIntro (formData) {
    return await api1.post('/api/go/sharevideointro', formData)
  }

  static async updateShineIntroRecording (updateType, recordingId) {
    return await api1.post( `/api/go/updateshineintrorecording?updateType=${updateType}&recordingId=${recordingId}`, {})
  }

  static async changeVideoIntroThumbnail (formData) {
    return await api1.post('/api/go/changevideointrothumbnail', formData)
  }
}
