<template>
  <div class="w-100 d-flex align-items-center justify-content-center dark-grey-text">
    <div class="d-flex align-items-center my-2">
      <FontAwesomeIcon
        icon="fa-select"
        class="prefix"
        style="font-size: 1.3rem;"
      />
      <mdb-switch
        v-model="isSwitched"
        off-label=""
        :on-label="currentCustomFormField.Question"
        class="m-0 ml-auto"
      />
    </div>
  </div>
</template>

<script>
import { useCandidate } from '@/pinia'
import { onMounted, ref } from 'vue-demi';
import { storeToRefs } from 'pinia'

export default {
  name: 'CheckBoxDesign',
  setup () {
    const candidateStore = useCandidate()
    const { currentCustomFormField } = storeToRefs(candidateStore)

    onMounted(() => {
      currentCustomFormField.value.Type = 'bool'
      currentCustomFormField.value.ReadableType = 'Checkbox'
    })

    const isSwitched = ref(true)

    return {
      currentCustomFormField,
      isSwitched
    }
  }
}
</script>
