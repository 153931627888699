<template>
  <div style="overflow-x:hidden;" :style="{'padding-top': mobileView ? '50px' : '0'}">
    <div class="pt-2 pt-md-4 px-4 px-lg-5" style="height: calc(100vh - 80px);">
      <SurveyList
        v-if="section === 'list'"
        @setSection="setSection($event.section, $event.sId) "
      />
      <AddEditSurvey
        v-else-if="section === 'add_edit'"
        @setSection="setSection($event.section) "
      />
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'

import AddEditSurvey from './AddEditSurvey/addEditContainer.vue'
import SurveyList from './SurveyList/surveyList.vue'

export default {
  name: 'SurveyContainer',
  components: {
    SurveyList,
    AddEditSurvey
  },
  setup () {
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    return {
      userData,
      mobileView
    }
  },
  data () {
    return {
      section: 'list'
    }
  },
  computed: {
    routePath () {
      return this.$route.path
    }
  },
  watch: {
    routePath () {
      if (this.routePath === '/settings/surveys/list') {
        this.section = 'list'
      } else if (this.routePath === '/settings/surveys/add_edit') {
        this.section = 'add_edit'
      }
    }
  },
  mounted () {
    if (!this.userData.iss) { this.$router.push('/candidates') }
    this.setSection('list')
  },
  methods: {
    setSection (section, sId = null) {
      this.section = section
      if (section === 'list') {
        this.$router.push('/settings/surveys/list').catch(() => {})
      } else if (section === 'add_edit') {
        this.$router.push({ path: '/settings/surveys/add_edit', query: { sId: sId } }).catch(() => {})
      }
    }
  }
}
</script>

<style lang="scss">
.scoring-settings-title-container {
  min-height: 80px;
  height: fit-content;
  font-size: 1rem;
}
.scoring-settings-content-container {
  height: calc(100vh - 317px);
  border: #c0c0c0 solid 2px;
  border-radius: 10px;
}
.notSelected {
  border-bottom: solid 1px #c0c0c0;
}
</style>
