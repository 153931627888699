<template>
  <div>
    <!--generic error modal -->
    <mdb-modal
      id="InsertTextGenericModal"
      :show="showing"
      @close="close()"
    >
      <mdb-modal-header>
        <mdb-modal-title>{{ modalTitle.toUpperCase() }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <div class="grey-text" style="margin-top:10px; margin-bottom:20px;font-size:smaller;">
          Edit description of your custom field.
        </div>
        <mdb-input
          ref="input"
          v-model="text"
          type="text"
          style="width:100%;padding-bottom: 20px;"
          size="sm"
          class="m-0"
        />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          color="primary"
          size="sm"
          style="width:100px;"
          class="btn-outline mr-auto"
          @click.native="close()"
        >
          CANCEL
        </mdb-btn>
        <mdb-btn
          :disabled="!text"
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="confirm()"
        >
          CONFIRM
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import { ref } from 'vue-demi'

export default {
  name: 'InsertTextGenericModal',
  setup () {
    const statusStore = useStatus()
    const { insertTextGenericModal } = storeToRefs(statusStore)
    const { closeInsertTextGenericModal } = useStatus()

    const text = ref(insertTextGenericModal.value.text)

    return {
      closeInsertTextGenericModal,
      text,
      insertTextGenericModal
    }
  },
  computed: {
    modalTitle () {
      if (!this.insertTextGenericModal.modalTitle) {
        return 'Insert'
      } else { return this.insertTextGenericModal.modalTitle }
    },
    showing () {
      return this.insertTextGenericModal.showing
    }
  },
  watch: {
    async showing () {
      if (this.showing) {
        await this.$nextTick(() => {})
        this.$refs.input.focus()
        this.text = this.insertTextGenericModal.text
      }
    }
  },
  methods: {
    close () {
      this.closeInsertTextGenericModal()
      this.text = ''
    },
    confirm () {
      this.$emit('text', this.text)
      this.closeInsertTextGenericModal()
      this.text = ''
    }
  }
}
</script>
