<template>
  <div style="width: 100%">
    <div
      v-if="!loading && itemsToRender.length === 0"
      class="font-weight-bold text-center mx-auto grey-text"
      style="font-size:small;width:100%;"
    >
      NO RESULTS
    </div>
    <div v-if="loading">
      <mdb-icon icon="fa fa-pulse fa-spinner" />
    </div>
    <FontAwesomeIcon
      v-if="itemsToRender.length > 0"
      icon="fa-solid fa-refresh"
      class="cp"
      @click="refresh"
    />
    <div
      v-for="(historyItem, i) in itemsToRender"
      :key="i"
      class="mb-3"
    >
      <!-- if it doesnt have a candidate history id we can assume it is refactored data -->
      <BlockedHistory
        v-if="!historyItem.GoCandidateHistoryId"
        :historyBlock="historyItem"
        @openReviewModal="openReviewModal"
      />
      <SingleHistoryItem
        v-else
        :hideActionButtons="hideActionButtons"
        :historyItem="historyItem"
        @prepMeetNowModal="prepMeetNowModal"
      />
    </div>
    <div ref="scrollToDiv" style="height: 1px;" />

    <!-- MEET NOW MODAL -->
    <MeetNowRecordingModal
      :recording-to-watch-u-r-l="meetnowRecording.RecordingUrl"
      :recording-to-watch-thumb="meetnowRecording.RecordingThumbnail"
      :selected-recording-name="meetnowRecording.RecordingName"
      :play-meet-now-recording-modal-showing="playMeetNowRecordingModalShowing"
      :guid="meetnowRecording.guid"
      :recording-id="meetnowRecording.RecordingId"
      @playMeetNowRecordingModalShowing="playMeetNowRecordingModalShowing = $event"
    />
    <ReviewModal
      :watch-interview-modal="watchInterviewModal"
      :review-modal-data="candidateToEdit"
      :role-id="role.JobId"
      :inviteCode="invitationCode"
      :modalContext="modalContext"
      @closeModal="watchInterviewModal = false"
    />
  </div>
</template>

<script>
// import candidateV2Service from '@/api/services/candidateV2.service'
import BlockedHistory from './blockedHistory.vue'
import SingleHistoryItem from './singleHistoryItem.vue'
import { useUserData, useCandidate, useStatus, useWorkflow, useRoleCandidate } from '@/pinia'
import { storeToRefs } from 'pinia'
import { nextTick, ref } from 'vue-demi'
import MeetNowRecordingModal from '@/views/meetnow/Pages/Recordings/playRecordingModal.vue'
import meetNowService from '@/api/services/meetnow.service'
import ReviewModal from '@/components/misc/ReviewModalV2/reviewModal.vue'

export default {
  name: 'CandidateHistory',
  components: {
    BlockedHistory, SingleHistoryItem, MeetNowRecordingModal, ReviewModal
  },
  props: {
    hideActionButtons: {
      type: Boolean,
      default: false
    },
    candidateId: {
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const { showGenericErrorModal } = useStatus()

    const candidateStore = useCandidate()
    const { fetchCandHistory, createBlockedHistory } = useCandidate()
    const { candidateToEdit, filteredHistory, allHistory, blockedHistory, selectedYear, selectedMonth, selectedHistoryType } = storeToRefs(candidateStore)

    const userStore = useUserData()
    const { userData, authData } = storeToRefs(userStore)

    const loading = ref(false)

    const workflowStore = useWorkflow()
    const { getRoleWithStages, role } = storeToRefs(workflowStore)

    const roleCandidateStore = useRoleCandidate()
    const { reviewModalRoleWorkflow, candidate, reviewModalSelectedStage } = storeToRefs(roleCandidateStore)

    const refresh = () => fetchCandHistory(props.candidateId)

    // MEET NOW LINK
    const playMeetNowRecordingModalShowing = ref(false)
    const meetnowRecording = ref({ RecordingUrl: '', RecordingThumbnail: '', RecordingName: '', guid: '', RecordingId: 0 })
    const prepMeetNowModal = async recordingId => {
      try {
        const res = await meetNowService.getMeetNowRecording(recordingId)
        if (res.data == null) {
          showGenericErrorModal({ showing: true, errorTitle: 'DELETED', errorMessage: 'This recording has been deleted' })
          return
        }
        meetnowRecording.value = res.data
        playMeetNowRecordingModalShowing.value = true
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
    }

    // REVIEW MODAL
    const modalContext = ref('')
    const invitationCode = ref('')
    const watchInterviewModal = ref(false)
    const openReviewModal = async historyItem => {
      try {
        role.value = historyItem.Job
        candidate.value = candidateToEdit.value
        candidate.value.ApplicantId = historyItem.ApplicantId
        invitationCode.value = historyItem.InvitationCode
        await getRoleWithStages.value(historyItem.JobId)
        await nextTick()
        reviewModalRoleWorkflow.value = role.value.RoleWorkflow
        reviewModalSelectedStage.value = role.value.RoleWorkflow.OrderedRoleWorkflowStages.filter(s => s.RoleWorkflowStageId == historyItem.RoleWorkflowStageId)[0]
        // we only want to be able to edit existing applications
        modalContext.value = historyItem.HistoryItemUpdateTypeDesc ? 'CANDIDATE_HISTORY_READ_ONLY' : 'CANDIDATE_HISTORY'
        watchInterviewModal.value = true
      } catch (err) {
        //?
      }
    }

    return {
      candidateToEdit,
      userData,
      authData,
      blockedHistory,
      filteredHistory,
      allHistory,
      loading,
      createBlockedHistory,
      fetchCandHistory,
      selectedYear,
      selectedMonth,
      selectedHistoryType,
      openReviewModal,
      prepMeetNowModal,
      meetnowRecording,
      playMeetNowRecordingModalShowing,
      modalContext,
      watchInterviewModal,
      role,
      invitationCode,
      refresh
    }
  },
  computed: {
    // // logic here is that if a filter is applied we simply return a list with that filter applied - no filter we return a blocked history version
    itemsToRender () {      
       if (this.selectedYear === 0 && this.selectedMonth === '00' && this.selectedHistoryType === 'all') {
         return this.blockedHistory
       } else {
        return this.filteredHistory 
      }
    },
  },
  watch: {
    candidateId () {
      this.fetchCandHistory()
    },
    refreshHistory () {
      if (this.refreshHistory) { this.fetchCandHistory() }
    },
    selectedYear () { this.calculateFilteredHistoryItems() },
    selectedMonth () { this.calculateFilteredHistoryItems() },
    selectedHistoryType () { this.calculateFilteredHistoryItems() }
  },
  async mounted () {
    await this.fetchCandHistory(this.candidateId)
  },
  methods: {
    redirectToRole (roleId) {
      this.$router.push(`/roles/add_edit/detail/${roleId}`)
    },
    handleHistoryItemDate (date) {
      const day = new Date(date).getDate()
      if (day === 1 || day === 21 || day === 31) {
        return `${day}st`
      } else if (day === 2 || day === 22) {
        return `${day}nd`
      } else if (day === 3 || day === 23) {
        return `${day}rd`
      }
      return `${day}th`
    },
    handleToggleText (date) {
      const year = date.substring(0, 4)
      const month = new Date(date).toLocaleString('default', { month: 'short' })
      return `${month.toUpperCase()} ${year}`
    },
    calculateFilteredHistoryItems () {
      this.filteredHistory = this.allHistory.filter((item) => {
        if (this.checkSelectedYear(item) && this.checkSelectedMonth(item) && this.checkSelectedHistoryType(item)) {
          return item
        }
      })
    },
    checkSelectedYear (item) {
      return this.selectedYear === 0 || item.DateAdded.substring(0, 4) === String(this.selectedYear)
    },
    checkSelectedMonth (item) {
      return this.selectedMonth === '00' || item.DateAdded.substring(5, 7) === this.selectedMonth
    },
    checkSelectedHistoryType (item) {
      return this.selectedHistoryType === 'all' || item.HistoryTypeEnumDesc === this.selectedHistoryType
    }
  }
}
</script>
