<template>
  <div class="card-outer">
    <div class="d-flex align-items-center flex-wrap">
      <img
        v-if="candidate.ProfilePicture && candidate.AudioOnly != 2 && candidate.AudioOnly != 3"
        :src="candidate.ProfilePicture"
        style="height:40px;width:40px;min-height:40px;min-width:40px;border-radius:50%;object-fit:cover;"
        class="d-inline mr-2"
      >
      <span
        v-else
        aria-hidden="true"
        style="border:solid 1px #c0c0c0;height:40px;width:40px;min-height:40px;min-width:40px;border-radius:50%;object-fit:cover;"
        class="mr-2 d-flex align-items-center justify-content-center"
      >
        <i
          class="fa fa-user"
          aria-hidden="true"
          style="font-size: 15px;"
        />
      </span>
      <mdb-popover
        ref="popover"
        trigger="click"
        :options="{placement: 'left'}"
        style="cursor:pointer;"
        class="grey-text ml-auto"
      >
        <div slot="body" class="text-nowrap">
          <h6
            class="d-flex"
            style="align-items:center"
            @click="removeCandidateFromSpotlight()"
          >
            <a><i class="fa fa-trash" style="margin-right:7px;" /> Remove From Spotlight</a>
          </h6>
          <h6
            class="d-flex d-sm-none"
            style="align-items:center"
            @click="prepInterviewModal()"
          >
            <a><i class="fa fa-play" style="margin-right:7px;" /> Review</a>
          </h6>
          <h6
            v-if="candidate.Status != 'Pending'"
            class="d-flex d-sm-none"
            style="align-items:center"
            @click="changeStatusFromPopup('Pending')"
          >
            <a><i class="fa fa-star" style="margin-right:7px;" /> Set {{ convertStatusName(userData.pen) }}</a>
          </h6>
          <h6
            v-if="candidate.Status != 'Reviewed'"
            class="d-flex d-sm-none"
            style="align-items:center"
            @click="changeStatusFromPopup('Reviewed')"
          >
            <a><i class="fa fa-star" style="margin-right:7px;" /> Set {{ convertStatusName(userData.rev) }}</a>
          </h6>
          <h6
            v-if="candidate.Status != 'Shortlisted'"
            class="d-flex d-sm-none"
            style="align-items:center"
            @click="changeStatusFromPopup('Shortlisted')"
          >
            <a><i class="fa fa-star" style="margin-right:7px;" /> Set {{ convertStatusName(userData.sht) }}</a>
          </h6>
          <h6
            v-if="candidate.Status != 'Rejected'"
            class="d-flex d-sm-none"
            style="align-items:center"
            @click="changeStatusFromPopup('Rejected')"
          >
            <a><i class="fa fa-star" style="margin-right:7px;" /> Set {{ convertStatusName(userData.rej) }}</a>
          </h6>
          <h6
            v-if="candidate.Status != 'Offered Position'"
            class="d-flex d-sm-none"
            style="align-items:center"
            @click="changeStatusFromPopup('Offered Position')"
          >
            <a><i class="fa fa-star" style="margin-right:7px;" /> Set {{ convertStatusName(userData.off) }}</a>
          </h6>
        </div>
        <mdb-icon
          slot="reference"
          style="color:#555;font-size:1rem;"
          class="ellipsisButtonHover"
          icon="ellipsis-v"
        />
      </mdb-popover>
      <h5
        v-if="candidate.AudioOnly == 2"
        class="font-weight-bold d-inline text-center mt-3"
        title="This Roles masking settings mean this candidates name and profile picture have been hidden."
        style="max-height:40px;width:100%"
      >
        Candidate Id: {{ candidate.CandidateId }}
      </h5>
      <h5
        v-else
        class="font-weight-bold d-inline text-center mt-3"
        style="max-height:40px;width:100%"
      >
        {{ candidate.Name }}
      </h5>
    </div>
    <div class="d-flex align-items-center mt-3">
      <div class="mr-2" style="font-size:0.9rem;">
        {{ candidate.RoleName }}
      </div>
      <mdb-btn
        style="width:40px; height:40px;min-height:40px;min-width:40px;border-radius:50%;"
        class="d-none d-sm-flex align-items-center justify-content-center p-0 ml-auto"
        color="primary"
        size="sm"
        @click="prepInterviewModal()"
      >
        <i class="fa fa-play" @click="prepInterviewModal()" />
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { CONVERT_STATUS_NAME } from '@/utils/convertStatus.js'

export default {
  name: 'ClientPortalDraggableCandidateCard',
  props: {
    candidate: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)

    return {
      userData
    }
  },
  data () {
    return {
      watchInterviewModal: false
    }
  },
  methods: {
    convertStatusName (status) {
      return CONVERT_STATUS_NAME(status)
    },
    changeStatusFromPopup (status) {
      this.$emit('changeStatusFromPopup', { status, candidate: this.candidate })
    },
    prepInterviewModal () {
      this.$emit('openInterviewModal', { workflowType: this.candidate.WorkflowType, candidate: this.candidate, roleId: this.candidate.RoleId, showVideoCVTab: this.candidate.RoleId === 0 })
    },
    removeCandidateFromSpotlight () {
      this.$emit('removeCandidateFromSpotlight', { candidateId: this.candidate.CandidateId, roleId: this.candidate.RoleId })
    }
  }
}
</script>

<style lang="scss" scoped>
.card-outer {
  background: rgb(232, 232, 232);
  border: 2px solid var(--gray);
  padding: 10px;
  margin: 10px 10px 10px 10px;
  min-height: 100px;
  border-radius: 5px;
  cursor: pointer;
}
</style>
