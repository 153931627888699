<template>
  <div
    v-if="!viewLegacyCandidatesData || $parent.viewingCandidateData == false"
    style="width:100% !important;"
  >
    <div
      v-if="allRoles.length === 0 && allRoles === true"
      class="text-left grey-text"
      style="margin-top:30px;margin-bottom:10px;"
    >
      No roles found.
    </div>
    <div v-show="allRoles.length > 0" md="10">
      <!--desktop-->
      <b-table
        v-if="!mobileView"
        id="roles-table"
        ref="roleCandidatesTable"
        class="text-left grey-text"
        head-variant="dark"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :items="allRoles"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fields"
        :striped="true"
        :pagination="true"
        hover
        small
        sort-icon-left
        responsive
      >
        <template #cell(JobTitle)="data">
          <div class="tableRow">
            <div class="d-flex flex-column" style="margin-left:5px;">
              {{ data.item.JobTitle }}
            </div>
          </div>
        </template>
        <template #cell(Step)="data">
          <div class="tableRow">
            {{ data.item.Step }}
          </div>
        </template>
        <template #cell(Expires)="data">
          <div class="tableRow">
            <div class="d-flex flex-column">
              {{ new Date(data.item.Expires).toLocaleString(undefined, { day: 'numeric', month: 'numeric', year: 'numeric'}) }}
            </div>
          </div>
        </template>
        <template #cell(actions)="data">
          <div
            v-if="data.item.Step > 0"
            class="ml-auto justify-content-end tableRow"
            style="cursor:pointer;margin-right:10px;"
          >
            <mdb-btn
              type="submit"
              class="float-right"
              size="sm"
              style="width:100px;"
              @click="view(data.item.JobId, data.item.InterviewType, '-1')"
            >
              VIEW
            </mdb-btn>
          </div>
        </template>
      </b-table>

      <!--mobile-->
      <b-table
        v-if="mobileView"
        id="roles-table"
        ref="roleCandidatesTable"
        class="text-left grey-text"
        head-variant="dark"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :items="allRoles"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="mobfields"
        :striped="true"
        :pagination="true"
        hover
        small
        sort-icon-left
        responsive
      >
        <template #cell(JobTitle)="data">
          <div class="mobTableRow">
            <div class="d-flex flex-column" style="margin-left:5px;font-size:smaller;">
              {{ data.item.JobTitle }}<br>Expired: {{ new Date(data.item.Expires).toLocaleString(undefined, { day: 'numeric', month: 'numeric', year: 'numeric'}) }}  ({{ data.item.Step }})
            </div>
          </div>
        </template>
        <template #cell(actions)="data">
          <div
            v-if="data.item.Step > 0"
            class="ml-auto justify-content-end tableRow"
            style="cursor:pointer;margin-right:10px;margin-top:10px;"
          >
            <mdb-btn
              type="submit"
              class="float-right"
              size="sm"
              @click="view(data.item.JobId, data.item.InterviewType, '-1')"
            >
              <i class="fa fa-search" />
            </mdb-btn>
          </div>
        </template>
      </b-table>

      <div class="d-flex align-items-center justify-content-between mt-3 flex-wrap">
        <b-pagination
          v-show="allRoles.length > 0"
          v-model="currentPage"
          class="float-right mt-4"
          :total-rows="rows"
          :per-page="perPage"
          first-number
          last-number
          aria-controls="notifications-table"
          prev-text="<"
          next-text=">"
          style="margin-bottom:30px;"
        />
      </div>
    </div>    
  </div>

  <!-- candidates -->

  <div
    v-else
    style="width:100% !important;"
  >
    <div style="margin-top:-75px;margin-bottom:20px;">
      <div
        v-if="candidateSearch !== '-1'"
        class="d-flex align-items-center"
        style="margin-left: 100px;padding-top: 10px;margin-bottom: -29px;"
      >
        <span
          style="font-size: 0.8rem;font-weight:bold;"
          class="cp text-black ml-3"
          @click="candidateSearch = '-1'"
        >CLEAR SEARCH</span>
      </div>
      <ExpandableSearchBar
        :width="300"
        :max-widthpercentage="40"
        :searchTriggersAPICall="true"
        @search="candidateSearch = $event"
      />
    </div>
    <div
      v-if="legacyCandidates.length === 0 && legacyCandidatesLoaded === true"
      class="text-left grey-text"
      style="margin-top:30px;margin-bottom:10px;"
    >
      No candidates found.
    </div>
    <div v-show="legacyCandidates.length > 0" md="10">
      <!--desktop-->
      <b-table
        v-if="!mobileView"
        id="roles-table"
        ref="roleCandidatesTable"
        class="text-left grey-text"
        head-variant="dark"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :items="legacyCandidates"
        :per-page="legacyCandidatesPerPage"
        :current-page="legacyCandidatesCurrentPage"
        :fields="legacyCandidatesFields"
        :striped="true"
        :pagination="true"
        hover
        small
        sort-icon-left
        responsive
      >
        <template #cell(Name)="data">
          <div class="tableRow">
            <div class="d-flex flex-column" style="margin-left:5px;">
              {{ data.item.Name }}
            </div>
          </div>
        </template>
        <template v-if="type === 1" #cell(EmailAddress)="data">
          <div class="tableRow">
            {{ data.item.EmailAddress }}
          </div>
        </template>
        <template #cell(Status)="data">
          <div class="tableRow">
            {{ data.item.Status }}
          </div>
        </template>
        <template v-if="type === 2" #cell(DateScheduled)="data">
          <div class="tableRow">
            {{ new Date(data.item.DateScheduled).toLocaleString(undefined, { day: 'numeric', month: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit'}) }}
          </div>
        </template>
        <template #cell(DateCompleted)="data">
          <div class="tableRow">
            <div
              v-if="data.item.Status == 'Pending'"
              class="d-flex flex-column"
            >
              {{ new Date(data.item.DateCompleted).toLocaleString(undefined, { day: 'numeric', month: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit'}) }}
            </div>
            <div
              v-if="type === 2 && data.item.Status != 'Pending'"
              class="d-flex flex-column"
            >
              No Show
            </div>
          </div>
        </template>
        <template #cell(actions)="data">
          <div
            v-if="data.item.Status == 'Pending'"
            class="ml-auto justify-content-end tableRow"
            style="cursor:pointer;margin-right:10px;"
          >
            <mdb-btn
              type="submit"
              class="float-right"
              size="sm"
              style="width:150px;"
              @click="watch(data.item)"
            >
              WATCH INTERVIEW
            </mdb-btn>
          </div>
          <div
            v-if="data.item.ProgressStatus == 1"
            class="ml-auto justify-content-end tableRow"
            style="cursor:pointer;margin-right:10px;"
          >
            <mdb-btn
              type="submit"
              class="float-right"
              size="sm"
              style="width:150px;opacity: 0.5;"
              @click="watch(data.item)"
            >
              WATCH INTERVIEW
            </mdb-btn>
          </div>
        </template>
      </b-table>

      <!--mobile-->
      <b-table
        v-if="mobileView"
        id="roles-table"
        ref="roleCandidatesTable"
        class="text-left grey-text"
        head-variant="dark"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :items="legacyCandidates"
        :per-page="legacyCandidatesPerPage"
        :current-page="legacyCandidatesCurrentPage"
        :fields="mobLegacyCandidatesFields"
        :striped="true"
        :pagination="true"
        hover
        small
        sort-icon-left
        responsive
      >
        <template #cell(Name)="data">
          <div class="mobTableRow">
            <div class="d-flex flex-column" style="margin-left:5px;font-size:smaller;">
              {{ data.item.Name }}<br>Status: {{ data.item.Status }}
              <div v-if="reviewData.DateCompleted != null">
                Completed: {{ new Date(reviewData.DateCompleted).toLocaleDateString([userLanguage]) }}
              </div>
              <div v-else>
                Completed: N/A
              </div>
            </div>
          </div>
        </template>
        <template #cell(actions)="data">
          <div
            v-if="data.item.Status == 'Pending'"
            class="ml-auto justify-content-end tableRow"
            style="cursor:pointer;margin-right:10px;margin-top:10px;"
          >
            <mdb-btn
              type="submit"
              class="float-right"
              size="sm"
              @click="watch(data.item)"
            >
              <i class="fa fa-tv" />
            </mdb-btn>
          </div>
        </template>
      </b-table>

      <div class="d-flex align-items-center justify-content-between mt-3 flex-wrap">
        <b-pagination
          v-show="legacyCandidates.length > 0"
          v-model="legacyCandidatesCurrentPage"
          class="float-right mt-4"
          :total-rows="legacyCandidatesRows"
          :per-page="legacyCandidatesPerPage"
          first-number
          last-number
          aria-controls="notifications-table"
          prev-text="<"
          next-text=">"
          style="margin-bottom:30px;"
        />
        <mdb-btn
          type="submit"
          class="float-right"
          size="sm"
          style="width:100px;margin-top:-5px;"
          @click="toggleLegacyCandidatesData"
        >
          BACK
        </mdb-btn>
      </div>
    </div>    

    <!--video playback modal-->
    <mdb-modal
      id="playModal"
      :show="playModal"
      @close="playModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>
          Candidate: {{ candidateName }} {{ windowHeight }} {{ type }}
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <div
          v-if="getInterviewLoading"
          style="height:60vh"
          class="d-flex align-items-center flex-column justify-content-center mx-auto"
        >
          <div class="mb-2">
            PREPARING APPLICATION . . .
          </div>
          <mdb-icon style="font-size:2rem;" icon="fa fa-pulse fa-spinner" />
        </div>
        <div v-else>
          <div style="margin-left: 10px;margin-bottom: 30px;padding: 1px;margin-top: 0px;">
            <div v-if="type === 1" style="float:left;color:#222 !important;">
              Role: {{ reviewData.JobTitle }}
              <Tooltip
                v-if="reviewData.DateCompleted != null" 
                style="margin-top:0px !important;float:right;"
                :name="reviewData.CandidateId"
                class="ml-2 mt-3"
                tooltip-direction="right"
                :tool-tip-summary="'Candidate Id: ' + reviewData.CandidateId + 
                  '<br>Candidate: ' + candidateName + 
                  '<br>Role: ' + reviewData.JobTitle + 
                  '<br>Invited On: ' + new Date(reviewData.DateInvited).toLocaleDateString([userLanguage]) + 
                  '<br>Invited By: ' + reviewData.InvitedBy +
                  '<br>Invite Code: ' + reviewData.InvitationCode +
                  '<br>Completed On: ' + new Date(reviewData.DateCompleted).toLocaleDateString([userLanguage])"
              />
              <Tooltip
                v-else
                style="margin-top:0px !important;float:right;"
                :name="reviewData.CandidateId"
                class="ml-2 mt-3"
                tooltip-direction="right"
                :tool-tip-summary="'Candidate Id: ' + reviewData.CandidateId + 
                  '<br>Candidate: ' + candidateName + 
                  '<br>Role: ' + reviewData.JobTitle + 
                  '<br>Invited On: ' + new Date(reviewData.DateInvited).toLocaleDateString([userLanguage]) + 
                  '<br>Invited By: ' + reviewData.InvitedBy +
                  '<br>Invite Code: ' + reviewData.InvitationCode +
                  '<br>Completed On: N/A'"
              />
            </div>
            <div v-else style="float:left;color:#222 !important;">
              Role: {{ reviewData.JobTitle }}
              <Tooltip
                style="margin-top:0px !important;float:right;"
                :name="reviewData.CandidateId"
                class="ml-2 mt-3"
                tooltip-direction="right"
                :tool-tip-summary="'Candidate Id: ' + reviewData.CandidateId + 
                  '<br>Candidate: ' + candidateName + 
                  '<br>Role: ' + reviewData.JobTitle + 
                  '<br>Invited On: ' + new Date(reviewData.DateInvited).toLocaleDateString([userLanguage]) + 
                  '<br>Invite Code: ' + reviewData.InvitationCode +
                  '<br>Scheduled Date: ' + reviewData.UserAgent +
                  '<br>Scheduled Time: ' + reviewData.TokBoxStatus"
              />
            </div>
            <div v-if="!mobileView">
              <div v-if="reviewData.DateCompleted != null" style="float:right;margin-right: 10px;color:#222 !important;">
                Completed: {{ new Date(reviewData.DateCompleted).toLocaleDateString([userLanguage]) }}
              </div>
              <div v-else style="float:right;margin-right: 10px;color:#222 !important;">
                Completed: N/A
              </div>
            </div>
          </div>
          <div id="videoContainer" class="mx-auto p-2">
            <div style="background:black;">
              <div v-if="type === 1">
                <video
                  v-if="mobileView"
                  id="videoReview"
                  :src="video.VideoUrl"
                  controls
                  playsinline
                  autoplay
                  class="video-fluid z-depth-1"
                  style="outline:none;margin: 0px auto;border-radius: 2px;height: 350px;-moz-transform: none !important;background:black;"
                  crossorigin=""
                  :poster="video.ThumbnailUrl"
                />
                <video
                  v-else
                  id="videoReview"
                  :src="video.VideoUrl"
                  controls
                  playsinline
                  autoplay
                  class="video-fluid z-depth-1 v-height"
                  style="outline:none;margin: 0px auto;border-radius: 2px;height: 350px;-moz-transform: none !important;background:black;"
                  crossorigin=""
                  :poster="video.ThumbnailUrl"
                />
              </div>
              <div v-else>
                <video
                  v-if="mobileView"
                  id="videoReview"
                  :src="video.VideoUrl"
                  controls
                  playsinline
                  autoplay
                  class="video-fluid z-depth-1"
                  style="outline:none;margin: 0px auto;border-radius: 2px;height: calc(100vh - 150px);-moz-transform: none !important;background:black;"
                  crossorigin=""
                  :poster="video.ThumbnailUrl"
                />
                <video
                  v-else
                  id="videoReview"
                  :src="video.VideoUrl"
                  controls
                  playsinline
                  autoplay
                  class="video-fluid z-depth-1"
                  style="outline:none;margin: 0px auto;border-radius: 2px;height: calc(100vh - 400px);-moz-transform: none !important;background:black;"
                  crossorigin=""
                  :poster="video.ThumbnailUrl"
                />
              </div>
            </div>
          
            <div 
              v-if="type === 1"
              class="q-height"
              style="margin-left:10px;overflow-y: auto;font-size: 0.9rem !important;margin-right: 10px;margin-top: 10px;"
            >
              <div
                v-for="question in reviewData.Questions"
                :key="question.QuestionId"
                class="d-flex align-items-center text-left cp"
                style="width:calc(100% - 10px);border-bottom:1px solid #c0c0c0;margin-bottom: 15px;margin-top:10px;"
              >
                <div class="d-flex align-items-center text-left cp mr-auto flex-grow-1" @click="selectQuestion(question)">
                  <div
                    v-if="question.Video != null"
                    class="p-3"
                    style="font-size:larger;color:grey;margin-left:-15px;padding-bottom:15px;margin-top: -15px;"
                    :class="{ 'primary-colour' : question.QuestionId === currentQuestionId && video.VideoUrl != '' }"
                  >
                    <i class="fa fa-play" />
                  </div>
                  <div
                    v-if="question.Video != null"
                    class="twoLinesText"
                    style="color:grey;padding-bottom:15px;"
                    :class="{ 'primary-colour' : question.QuestionId === currentQuestionId && video.VideoUrl != '' }"
                  >
                    <span>{{ question.QuestionText }}</span>
                  </div>
                  <div
                    v-if="question.Video == null"
                    class="p-3"
                    style="font-size:larger;color:grey;margin-left:-15px;padding-bottom:15px;margin-top: -15px;"
                    :class="{ 'primary-colour' : question.QuestionId === currentQuestionId }"
                  >
                    <i class="fa fa-times" />
                  </div>
                  <div
                    v-if="question.Video == null"
                    class="twoLinesText"
                    style="color:grey;margin-top: -15px;"
                    :class="{ 'primary-colour' : question.QuestionId === currentQuestionId }"
                  >
                    <span>{{ question.QuestionText }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mdb-modal-body>
    </mdb-modal>

    <div class="primary-colour" style="width:0%;margin:auto;">
      <InfiniteScroll
        v-if="legacyLoading"
      />
    </div>
  </div>
</template>

<script>
import { useUserData, useRoles } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import { userLanguage } from '@/common/userLanguage'
import roleV2Service from '@/api/services/roleV2.service'
import ExpandableSearchBar from '@/components/UI/expandableSearch.vue'
import InfiniteScroll from '@/components/misc/infiniteScroll.vue'
import Tooltip from '@/components/misc/tooltip.vue'

export default {
  name: 'RoleCard2',
  components: {
    ExpandableSearchBar,
    InfiniteScroll,
    Tooltip
  },
  setup () {
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)
    const roleStore = useRoles()
    const { allRoles } = storeToRefs(roleStore)
    const { mobileView } = useMobile()

    return {
      userData,
      allRoles,
      mobileView,
      userLanguage
    }
  },
  data () {
    return {
      fields: [
        { key: 'JobTitle', label: '  Role', tdClass: 'headerPaddingUsers col50 sortIcon', sortable: true },
        { key: 'Step', label: '  Candidates', tdClass: 'headerPaddingUsers col0 sortIcon', sortable: true },
        { key: 'Expires', label: '  Closed', tdClass: 'headerPaddingUsers col20 sortIcon', sortable: true },
        { key: 'actions', label: '', tdClass: 'headerPaddingUsers col10' },
      ],
      mobfields: [
        { key: 'JobTitle', label: '  Role', tdClass: 'headerPaddingUsers col40 sortIcon', sortable: true },
        { key: 'actions', label: '', tdClass: 'headerPaddingUsers col20' },
      ],
      legacyCandidatesFields: [
        { key: 'Name', label: '  Candidate', tdClass: 'headerPaddingUsers col30 sortIcon', sortable: true },
        { key: 'EmailAddress', label: '  Email', tdClass: 'headerPaddingUsers col30 sortIcon', sortable: true },
        { key: 'Status', label: '  Status', tdClass: 'headerPaddingUsers col20 sortIcon', sortable: true },
        { key: 'DateCompleted', label: '  Completed', tdClass: 'headerPaddingUsers col20 sortIcon', sortable: true },
        { key: 'actions', label: '', tdClass: 'headerPaddingUsers col40' },
      ],
      mobLegacyCandidatesFields: [
        { key: 'Name', label: '  Candidate', tdClass: 'headerPaddingUsers col80 sortIcon', sortable: true },
        { key: 'actions', label: '', tdClass: 'headerPaddingUsers col20' },
      ],
      sortBy: '',
      sortDesc: false,
      filter: null,
      buttonClick: false,
      perPage: 9,
      currentPage: 1,
      legacyCandidates :[],
      legacyCandidatesCurrentPage: 1,
      legacyCandidatesPerPage: 9,
      viewLegacyCandidatesData: false,
      legacyCandidatesLoaded: false,
      legacyLoading: false,
      playModal: false,
      candidateName: '',
      reviewData: [],
      currentQuestionId: 0,
      video: [ { VideoUrl: '', ThumbnailUrl: ''}],
      getInterviewLoading: false,
      candidateSearch: '-1',
      jobId: 0,
      type: 0,
      windowHeight: window.innerHeight
    }
  },
  computed: {
    rows () {
      return this.allRoles.length
    },
    legacyCandidatesRows () {
      return this.legacyCandidates.length
    },    
    sortOptions () {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
    searchQuery () {
      try {
        return this.$route.query.candidateSearch
      } catch { return '' }
    }
  },
  watch: {
    async candidateSearch () {
      this.view(this.jobId, this.type, encodeURIComponent(this.candidateSearch)) 
    },
    searchQuery () {
      if (!this.searchQuery) return
      this.candidateSearch = decodeURI(this.searchQuery)
    }
  },
  async mounted () {
    if (this.searchQuery) {
      this.candidateSearch = decodeURI(this.searchQuery)
      return
    }
  },
  methods: {
    view (jobId, type, candidateSearch) {

      if (type === 2) {
        this.legacyCandidatesFields = [
          { key: 'Name', label: '  Candidate', tdClass: 'headerPaddingUsers col30 sortIcon', sortable: true },
          { key: 'Status', label: '  Status', tdClass: 'headerPaddingUsers col20 sortIcon', sortable: true },
          { key: 'DateScheduled', label: '  Scheduled', tdClass: 'headerPaddingUsers col20 sortIcon', sortable: true },
          { key: 'DateCompleted', label: '  Completed', tdClass: 'headerPaddingUsers col20 sortIcon', sortable: true },
          { key: 'actions', label: '', tdClass: 'headerPaddingUsers col40' },
        ]
      }

      this.legacyCandidatesLoaded = false
      this.legacyCandidates = []
      this.legacyLoading = true
      this.jobId = jobId
      this.type = type
      this.viewLegacyCandidatesData = true
      this.$parent.rolesLoaded = false
      this.$parent.allRolesFetched = false
      this.$parent.allDataFetched = false
      this.$parent.viewingCandidateData = true
      try {
        roleV2Service.getLegacyCandidatesForRole(jobId, type, candidateSearch).then((response) => {
        this.legacyCandidates = response.data
        this.legacyCandidatesLoaded = true
        this.$parent.rolesLoaded = true
        this.$parent.allRolesFetched = true
        this.legacyLoading = false
      })
      } catch (err) {
        this.legacyCandidatesLoaded = true
        this.$parent.rolesLoaded = true
        this.$parent.allRolesFetched = true
        this.showGenericErrorModal({ showing: true })
        this.legacyLoading = false
      }
    },
    watch (item) {
      try {
        if (this.type === 1) {
          this.playModal = true
          this.getInterviewLoading = true
          this.candidateName = item.Name
          roleV2Service.getLegacy1WCandidatesInterview(item.CandidateInvitationId).then((response) => {
            this.reviewData = response.data
            if (this.reviewData.Questions[0].Video) {
              this.video = this.reviewData.Questions[0].Video
            }
            this.currentQuestionId = this.reviewData.Questions[0].QuestionId
            this.getInterviewLoading = false
          })
        } else {
          this.playModal = true
          this.getInterviewLoading = true
          this.candidateName = item.Name
          roleV2Service.getLegacy2WCandidatesInterview(item.MeetingId).then((response) => {
            this.reviewData = response.data
            if (this.reviewData.DefaultVideo) {
              this.video.VideoUrl = this.reviewData.DefaultVideo.VideoUrl
              this.video.ThumbnailUrl = this.reviewData.Thumb
            }
            this.getInterviewLoading = false
          })
        }
      } catch (err) {
        this.reviewData = []
        this.playModal = false
        this.getInterviewLoading = false
        this.video = []
        this.currentQuestionId = 0
        this.showGenericErrorModal({ showing: true })
      }
    },
    toggleLegacyCandidatesData() {
      this.legacyCandidatesLoaded = false
      this.viewLegacyCandidatesData = false
      this.legacyCandidates = []
      this.$parent.viewingCandidateData = false
    },
    selectQuestion(question) {
      this.video = []
      this.currentQuestionId = question.QuestionId
      this.video = this.reviewData.Questions[question.Ordinal - 1].Video
    }
  }
}
</script>

<style scoped>
.tableRow {
  height: 35px !important;
  display: flex;
  align-items: center;
}
.mobTableRow {
  height: 55px !important;
  display: flex;
  align-items: center;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.v-height {
  height: 350px;
}
.q-height {
  height: 200px;
}

@media (min-width:640px) and (max-height: 900px) {
  .v-height {
    height: 325px !important;
  }
  .q-height {
    height: 180px;
  }
}

@media (min-width:640px) and (max-height: 850px) {
  .v-height {
    height: 325px !important;
  }
  .q-height {
    height: 100px;
  }
}

@media (min-width:640px) and (max-height: 750px) {
  .v-height {
    height: 300px !important;
  }
  .q-height {
    height: 100px;
  }
}

@media (min-width:640px) and (max-height: 700px) {
  .v-height {
    height: 250px !important;
  }
  .q-height {
    height: 60px;
  }
}

@media (min-width:640px) and (max-height: 600px) {
  .v-height {
    height: 200px !important;
  }
  .q-height {
    height: 50px;
  }
}

@media (min-width:640px) and (max-height: 500px) {
  .v-height {
    height: 150px !important;
  }
  .q-height {
    height: 50px;
  }
}

</style>
