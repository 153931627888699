<template>
  <div class="d-flex flex-column pt-4 px-3 px-md-5 text-black rounded text-left" style="overflow-y:auto;overflow-x:hidden">
    <div class="font-weight-bold py-4 flex-grow-0 primary-colour">
      Self Registration Settings
    </div>
    <b-row
      no-gutters
      class="text-black mt-2 mb-3 font-small "
      align-v="center"
      align-h="between"
    >
      <b-col cols="auto" style="font-size:0.8rem;max-width:70%;">
        When Self-Registration is used, create a 'View All Jobs' page
      </b-col>
      <div style="width:60px;">
        <mdb-switch
          v-model="createViewAllJobsPageOnSelfRegistration"
          style="transform:scale(0.9)"
          off-label=""
          on-label=""
          class="m-0 ml-auto"
        />
      </div>
    </b-row>

    <SlideFade>
      <div v-if="createViewAllJobsPageOnSelfRegistration">
        <div>
          <div style="font-size:0.8rem;" class="font-weight-bold mb-3">
            Your 'View All Jobs' page can found here
          </div>
          <div
            style="border:var(--pr-color) 2px solid; border-radius: 5px; max-width: 100%;width: fit-content;"
            class="d-flex align-items-center p-1 cp"
            @click="copySelfRegLinkClipBoard(`${SELF_REGISTRATION_URL}/#/${userData.fnm}`)"
          >
            <FontAwesomeIcon icon="fa-copy fa-regular" class="ml-1 mr-2 black-text" />
            <div style="font-size:0.8rem;max-width:80%;overflow:hidden" class="mr-auto black-text">
              {{ SELF_REGISTRATION_URL }}/#/{{ userData.fnm }}
            </div>
            <FontAwesomeIcon icon="fa-file fa-solid" class="mx-1" />
            <span class="mx-1" style="font-size:0.8rem">{{ copiedSelfRegLink ? 'COPIED' : 'COPY' }}</span>
          </div>
        </div>
      </div>
    </SlideFade>
    <b-row
      no-gutters
      class="text-black mt-2 mb-3 font-small "
      align-v="center"
    >
      <mdb-btn
        v-if="!loading"
        class="p-2 m-0 ml-auto mt-3"
        size="sm"
        :disabled="hideSave"
        style="width:100px;"
        @click="saveSettings()"
      >
        SAVE
      </mdb-btn>
      <mdb-btn
        v-else
        class="p-2 m-0 ml-auto mt-3"
        size="sm"
        style="width:100px;"
      >
        <i class="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </b-row>
  </div>
</template>

<script>
import { useStatus, useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import adminService from '@/api/services/admin.service'
import { SELF_REGISTRATION_URL } from '@/common/config.js'

import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'

export default {
  name: 'SelfRegistrationSettings',
  components: {
    SlideFade
  },
  setup () {

    const userStore = useUserData()
    const { userData, authData } = storeToRefs(userStore)
    const { showGenericErrorModal } = useStatus

    return {
      showGenericErrorModal,
      userData,
      authData
    }
  },
  data () {
    return {
      createViewAllJobsPageOnSelfRegistration: false,
      loading: false,
      copiedSelfRegLink: false,
      account: {}
    }
  },
  computed: {
    SELF_REGISTRATION_URL () {
      return SELF_REGISTRATION_URL
    },
    hideSave () {
      return Object.keys(this.account).length === 0
    }
  },
  created () {
    this.getAccounts()
  },
  methods: {
    copySelfRegLinkClipBoard (value) {
      navigator.clipboard.writeText(value)
      this.copiedSelfRegLink = true
      setTimeout(() => { this.copiedSelfRegLink = false }, 2000)
    },
    async saveSettings () {
      this.loading = true
      try {
        await adminService.createViewAllJobsPageOnSelfRegistrationSetting(this.createViewAllJobsPageOnSelfRegistration)
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      setTimeout(() => { this.loading = false }, 2000)
    },
    async getAccounts () {
      this.loading = true
      try {
        const res = await adminService.getAccounts(2)
        this.account = res.data.filter(a => a.AccountId === this.userData.aid)[0]
        this.createViewAllJobsPageOnSelfRegistration = this.account.CreateViewAllJobsPageOnSelfRegistration
      } catch (err) {
        //?
      }
      this.loading = false
    }
  }
}
</script>
