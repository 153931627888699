<template>
  <div style="height:100%;width:100%;">
    <div
      v-if="userData.rle !== 'Inviter'"
      v-show="settingsLoaded"
      style="text-align:left"
      class="h-full"
    >
      <div class="d-flex align-items-center px-4 mt-6 mainSectionBoilerPlateInnerTopPanel mb-3 topTabs">
        <div
          class="cursor-pointer text-lg grey-text text-center"
          style="width:50%;"
          :class="{ 'default': section == 'profile', 'notSelected': section != 'profile', 'mobileSize': mobileView === true }"
          @click="setSection('profile')"
        >
          <span v-if="mobileView === false">MY PROFILE</span>
          <span v-else><i
            class="fa fa-user"
            title="My profile"
            style="font-size:large"
          /></span>
        </div>
        <div
          class="cursor-pointer text-lg grey-text text-center"
          style="width:50%;"
          :class="{ 'default': section == 'notifications', 'notSelected': section != 'notifications', 'mobileSize': mobileView === true }"
          @click="setSection('notifications')"
        >
          <span v-if="mobileView === false">NOTIFICATIONS</span>
          <span v-else><i
            class="fa fa-bell"
            title="Notifications"
            style="font-size:large"
          /></span>
        </div>
      </div>
      <div class="pt-2 pt-md-4 px-3 px-md-5 ">
        <MyProfile v-if="section === 'profile'" />
        <NotificationSettings v-if="section === 'notifications'" />
        <SiteSettings v-if="section === 'site'" />
      </div>
    </div>
    <div
      v-else
      v-show="settingsLoaded"
      style="text-align:left"
      class="h-full"
    >
      <div class="d-flex align-items-center px-4 mt-6 mainSectionBoilerPlateInnerTopPanel mb-3 topTabs">
        <div
          class="cursor-pointer text-lg grey-text text-center"
          style="width:100%;"
          :class="{ 'default': section == 'profile', 'notSelected': section != 'profile', 'mobileSize': mobileView === true }"
          @click="setSection('profile')"
        >
          <span v-if="mobileView === false">MY PROFILE</span>
          <span v-else><i
            class="fa fa-user"
            title="My profile"
            style="font-size:large"
          /></span>
        </div>
      </div>
      <div class="pt-2 pt-md-4 px-3 px-md-5 ">
        <MyProfile v-if="section === 'profile'" />
        <NotificationSettings v-if="section === 'notifications'" />
        <SiteSettings v-if="section === 'site'" />
      </div>
    </div>
  </div>
</template>

<script>
import { useMobile } from '@/composables/useMobile'
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import MyProfile from './Tabs/myprofile.vue'
import SiteSettings from './Tabs/sitesettings.vue'
import NotificationSettings from './Tabs/notificationsettings.vue'

export default {
  name: '',
  components: {
    MyProfile,
    SiteSettings,
    NotificationSettings
  },
  setup () {
    const userStore = useUserData()
    const { mobileView } = useMobile()
    const { userData } = storeToRefs(userStore)
    return {
      mobileView,
      userData
    }
  },
  data () {
    return {
      section: 'profile',
      settingsLoaded: true
    }
  },
  methods: {
    setSection (type) {
      this.section = type
    }
  }
}
</script>

<style>
  ul.nav-tabs.nav-justified {
    background-color: var(--bg-color) !important;
  }
</style>

<style lang="scss" scoped>
  .topTabs {
    top:-25px;
    background:#fff;
    z-index:99;
    justify-content:space-evenly;
    height:108px;
  }
  .notSelected {
    border-bottom: solid 1px #c0c0c0;
  }
  .divContainer {
    float:left;
    height: 100%;
    width:100%;
    padding:3px;
  }
  .divContent {
    height:90%;
    overflow-y:auto;
    overflow-x:hidden;
    background:#fff;
  }
  .md-tabs {
    position: relative;
    z-index: 1;
    padding: 0rem !important;
    margin-right: -1rem !important;
    margin-bottom: -20px;
    margin-left: -1rem !important;
    background-color: #999;
    border: 0;
    border-radius: 0rem !important;
    box-shadow: none !important;
  }
  .repTop {
    margin-top: 10px;
  }
  .repLeft {
    margin-left: -19px;
  }
  @media (max-width: 576px) {
    .minus20 {
      margin-top: -20px;
    }
    .topTabs {
      top:26px;
      background:#fff;
      z-index:99;
      justify-content:space-evenly;
      height:48px;
  }
  }
</style>
