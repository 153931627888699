<template>
  <div
    class="mt-4"
    :class="mobileView ? 'mt-6 pt-8' : ''"
    style="min-height: fit-content;margin-top:85px !important;"
  >
    <div v-show="groupsLoaded" :class="{ 'vMobBrandingTop': mobileView }">
      <div
        class="grey-text text-left"
      >
        <div
          v-if="groups.length === 0"
          class="text-center grey-text"
          :class="{ 'm-a-left': mobileView }"
          style="font-size: 14px;margin-top: 50px;"
        >
          No groups to display. Click <a
            class="primary-colour"
            style="font-weight:bold;"
            @click="showAddEditAccountGroup(null)"
          ><em>here</em></a> to add a new group.
        </div>
        <ExpandableSearchBar
          v-if="groups.length > 0"
          :width="250"
          :max-widthpercentage="50"
          @search="filter = $event"
        />
      </div>

      <div v-show="groupsLoaded === true">
        <!--desktop-->
        <b-table
          v-if="groups.length > 0 && mobileView === false"
          id="groups-table"
          class="text-left"
          head-variant="dark"
          style="margin-top:10px;"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items="groups"
          :per-page="groupsPerPage"
          :current-page="groupsCurrentPage"
          :fields="groupsFields"
          :filter="filter"
          :striped="true"
          hover
          small
          sort-icon-left
          responsive="sm"
        >
          <template #cell(groupname)="data">
            <div style="padding-top:12px;margin-left:10px;" class="smFont">
              {{ data.item.GroupName }}
            </div>
          </template>
          <template #cell(listedaccounts)="data">
            <div style="padding-top:12px;margin-left:5px;" class="smFont">
              {{ data.item.ListedAccounts }}
            </div>
          </template>
          <template #cell(datecreated)="data">
            <div style="padding-top:8px;margin-left:5px;" class="smFont">
              {{ new Date(data.item.DateCreated).toLocaleDateString([userLanguage]) }}
            </div>
          </template>
          <template #cell(actions)="data">
            <div class="float-right webDisplay">
              <button
                title="Assign accounts to this group?"
                type="button"
                class="btn btn-primary btn-md"
                style="padding: 0.5rem 0.6rem;margin:0.175rem;width:33px;"
                @click="showAddEditAssigningAccountsModal(data.item)"
              >
                <mdb-icon icon="plus" />
              </button>
              <button
                title="Edit this account group?"
                type="button"
                class="btn btn-primary btn-md"
                style="padding: 0.5rem 0.6rem;margin:0.175rem;width:32px;"
                @click="showAddEditAccountGroup(data.item)"
              >
                <mdb-icon icon="pencil-alt" />
              </button>
              <button
                v-if="data.item.IsLocked"
                title="Account group assigned to users or other accounts, so cannot be deleted!"
                type="button"
                class="btn btn-primary btn-md"
                style="padding: 0.5rem 0.6rem;margin:0.175rem;width:32px;opacity: 0.5;"
              >
                <mdb-icon icon="times" />
              </button>
              <button
                v-else
                title="Delete this account group?"
                type="button"
                class="btn btn-primary btn-md"
                style="padding: 0.5rem 0.6rem;margin:0.175rem;width:32px;"
                @click="showDeleteAccountGroupModal(data.item)"
              >
                <mdb-icon icon="times" />
              </button>
            </div>
          </template>
        </b-table>

        <!--mobile-->
        <b-table
          v-if="groups.length > 0 && mobileView === true"
          id="master-account-groups-table"
          style="margin-top:20px;"
          class="text-left tableTh"
          head-variant="dark"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items="groups"
          :per-page="groupsPerPage"
          :current-page="groupsCurrentPage"
          :fields="groupsMobileFields"
          :filter="filter"
          hover
          small
          sort-icon-left
          responsive="sm"
        >
          <template #cell(groupname)="data">
            <div style="padding-top:8px;margin-left:10px;" class="smFont">
              {{ data.item.GroupName }} ({{ data.item.ListedAccounts }})
            </div>
          </template>
          <template #cell(datecreated)="data">
            <div style="padding-top:8px;margin-left:5px;" class="smFont">
              {{ new Date(data.item.DateCreated).toLocaleDateString([userLanguage]) }}
            </div>
          </template>
          <template #cell(actions)="data">
            <mdb-popover
              trigger="click"
              :options="{placement: 'bottom'}"
              style="cursor:pointer;float:right;"
              class="grey-text mobDisplay"
            >
              <div slot="body">
                <h6 style="margin-left:-10px;margin-top:-5px;padding:10px;" @click="showAddEditAssigningAccountsModal(data.item)">
                  <a class="ml-1 grey-link"><i class="fa fa-plus" /><span style="margin-left:5px;">Assign Accounts</span></a>
                </h6>
                <h6 style="margin-left:-10px;margin-top:-5px;padding:10px;" @click="showAddEditAccountGroup(data.item)">
                  <a class="ml-1 grey-link"><i class="fa fa-pencil-alt" /> Edit</a>
                </h6>
                <h6
                  v-if="!account.IsLocked"
                  style="margin-left:-10px;padding:10px;"
                  @click="showDeleteAccountGroupModal(data.item)"
                >
                  <a class="ml-1 grey-link"><mdb-icon icon="times" /><span style="margin-left:7px;">Delete</span></a>
                </h6>
              </div>
              <mdb-icon
                slot="reference"
                title="More actions"
                style="color:#555;font-size:1rem;transform:translateY(2px);margin-top:3px;margin-left:8px;"
                class="p-2 ellipsisButtonHover mr-2"
                icon="ellipsis-v"
              />
            </mdb-popover>
          </template>
        </b-table>

        <b-pagination
          v-show="groups.length > 5"
          v-model="groupsCurrentPage"
          class="float-right mt-4"
          :total-rows="groupsRows"
          :per-page="groupsPerPage"
          first-number
          last-number
          aria-controls="groups-table"
          prev-text="<"
          next-text=">"
          style="margin-bottom:30px;"
        />

        <div
          v-show="mobileView === false"
          md="2"
          offset-md="10"
        >
          <button
            v-if="groups.length > 0"
            style="float: left;margin-right: 0px;width: 110px;padding: 6px !important;text-align: center;margin-top:25px;"
            title="Add account"
            class="mdc-button btn"
            @click="showAddEditAccountGroup(null)"
          >
            <span class="secondary-colour"><i class="fa fa-plus-circle" /> Add</span>
          </button>
        </div>
        <div class="fixed bottom-0 mobDisplay" style="background: white;width: 100%;border-top: 1px solid rgb(161, 161, 161);z-index: 1001;margin-left: -33px;height: 55px;">
          <div class="row" style="margin-top:5px;">
            <div class="col-sm-12 text-center">
              <button
                v-if="groups.length > 0"
                style="width: 110px;padding: 6px !important;text-align: center;"
                title="Add account"
                class="mdc-button btn-solid-outline-mob btn"
                @click="showAddEditAccountGroup(null)"
              >
                <span class="secondary-colour"><i class="fa fa-plus-circle" /> Add</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--asign accounts modal-->
      <mdb-modal
        id="assignModal"
        :show="assignModal"
        @close="assignModal = false"
      >
        <mdb-modal-header>
          <mdb-modal-title>ASSIGN ACCOUNTS?</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body :class="{ 'modalHeight': mobileView }">
          <div
            class="my-4 grey-text"
            style="font-size:0.8rem;text-align:left;"
          >
            Select which accounts are assigned to group: <span style="font-weight: bold;">{{ selectedGroup }}</span>?
            <div
              class="mobBoxHeight"
              style="margin-top:35px;padding:1px;height:200px;overflow-y: auto;border:solid 1px #c0c0c0;"
            >
              <div
                v-for="account in accounts"
                :key="account.AccountId"
                @click="setAccount(account.AccountId)"
              >
                <div
                  v-if="account.Locked"
                  style="margin-left:13px;margin-top:10px;opacity:0.5;"
                  :style="account.Checked ? `font-weight:bold;` : 'font-weight:normal;'"
                  size="sm"
                >
                  <i
                    class="fa fa-lock"
                    style="margin-right:20px;cursor:help;"
                    title="Cannot remove account from group as users are already assigned to this group!"
                  />{{ account.Name }}
                </div>
                <mdb-input
                  v-else
                  v-model="account.Checked"
                  type="checkbox"
                  style="margin-left:-10px;margin-top:10px;"
                  :style="account.Checked ? `font-weight:bold;` : 'font-weight:normal;'"
                  size="sm"
                  :label="account.Name"
                />
              </div>
            </div>
          </div>
        </mdb-modal-body>
        <mdb-modal-footer>
          <mdb-btn
            v-show="!buttonClick"
            color="primary"
            size="sm"
            style="width:100px;"
            @click.native="assignAccountsToGroup()"
          >
            ASSIGN
          </mdb-btn>
          <mdb-btn
            v-show="buttonClick"
            class="float-right"
            size="sm"
            style="width:100px;"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>

      <!--add/edit modal-->
      <mdb-modal
        id="addEditMasterGroup"
        :show="showAddEditModal"
        @close="showAddEditModal = false"
      >
        <mdb-modal-header>
          <mdb-modal-title><span v-if="edit">EDIT GROUP</span><span v-else>ADD NEW GROUP</span></mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body :class="{ 'modalHeight': mobileView }">
          <validation-observer>
            <form
              ref="form"
              @submit.prevent="addEditGroup()"
              @reset.prevent="reset"
            >
              <div class="md-form form-sm text-left">
                <validation-provider
                  v-slot="{ errors }"
                  mode="lazy"
                  name="Group Name"
                  rules="required"
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-grid"
                    style="margin-top: 6px;font-size: 1.3rem;color:#c0c0c0;"
                  />
                  <mdb-input
                    v-model="grp.GroupName"
                    type="text"
                    auto-complete="fna"
                    label="Group Name"
                    style="margin-left:33px;margin-top:-35px;"
                    size="sm"
                  />
                  <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                    Please provide a title
                  </div>
                </validation-provider>
              </div>
            </form>
          </validation-observer>
        </mdb-modal-body>
        <mdb-modal-footer style="display:block;">
          <mdb-btn
            type="reset"
            class="float-left btn-outline"
            size="sm"
            style="width:100px;"
            @click="showAddEditModal = false"
          >
            CANCEL
          </mdb-btn>
          <mdb-btn
            v-if="!buttonClick"
            type="submit"
            :disabled="grp.GroupName === null"
            class="float-right"
            size="sm"
            style="width:100px;"
            @click="saveAddEditAccountGroup();buttonClick = true;"
          >
            <span>SAVE</span>
          </mdb-btn>
          <mdb-btn
            v-if="buttonClick"
            class="float-right"
            size="sm"
            style="width:100px;"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>

      <!--error modal-->
      <mdb-modal
        id="errorModal"
        :show="errorModal"
        @close="errorModal = false"
      >
        <mdb-modal-header>
          <mdb-modal-title>{{ errorModalTitle.toUpperCase() }}</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body :class="{ 'modalHeight': mobileView }">
          <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
            <span v-html="error" />
          </p>
        </mdb-modal-body>
        <mdb-modal-footer>
          <mdb-btn
            color="primary"
            size="sm"
            style="width:100px;"
            @click.native="closeErrorModal"
          >
            CLOSE
          </mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>

      <!--delete modal-->
      <mdb-modal
        id="deleteModal"
        :show="deleteModal"
        @close="deleteModal = false"
      >
        <mdb-modal-header>
          <mdb-modal-title>DELETE?</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body :class="{ 'modalHeight': mobileView }">
          <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
            Are you sure you want to delete this group?
          </p>
        </mdb-modal-body>
        <mdb-modal-footer>
          <mdb-btn
            v-show="!buttonClick"
            color="primary"
            size="sm"
            style="width:100px;"
            @click.native="deleteAccountGroup"
          >
            YES
          </mdb-btn>
          <mdb-btn
            v-show="buttonClick"
            class="float-right"
            size="sm"
            style="width:100px;"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import logoutService from '@/api/services/logout.service'
import userService from '@/api/services/user.service'
import { userLanguage } from '@/common/userLanguage'
import { useMobile } from '@/composables/useMobile'

import { mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModal, mdbBtn } from 'mdbvue'
import ExpandableSearchBar from '@/components/UI/expandableSearch.vue'

export default {
  name: 'Admin',
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbBtn,
    ExpandableSearchBar
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData, leftColumnActive } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    return {
      authData,
      userData,
      leftColumnActive,
      mobileView
    }
  },
  data () {
    return {
      groupsFields: [
        { key: 'GroupName', label: '  Group Name', tdClass: 'headerPaddingUsers col40 sortIcon', sortable: true },
        { key: 'ListedAccounts', label: '  Accounts', tdClass: 'headerPaddingUsers col20 sortIcon', sortable: true },
        { key: 'DateCreated', label: '  Created', tdClass: 'headerPaddingUsers col20 sortIcon', sortable: true },
        { key: 'Actions', label: '', tdClass: 'headerPaddingUsers col20', sortable: false }
      ],
      groupsMobileFields: [
        { key: 'GroupName', label: '  Name (Accounts)', tdClass: 'headerPaddingUsers col70 sortIcon', sortable: true },
        { key: 'DateCreated', label: '  Created', tdClass: 'headerPaddingUsers col20 sortIcon', sortable: true },
        { key: 'Actions', label: '', tdClass: 'headerPaddingUsers col10', sortable: false }
      ],
      groupsLoaded: false,
      groupsPerPage: 8,
      groupsCurrentPage: 1,
      groups: [],
      showAddEditModal: false,
      deleteModal: false,
      assignModal: false,
      grp: {
        GroupId: 0,
        GroupName: '',
        IsDeleted: false,
        ListedAccounts: 0,
        Type: ''
      },
      sortBy: '',
      sortDesc: false,
      filter: null,
      buttonClick: false,
      userLanguage,
      filterOn: [],
      errorModal: false,
      errorModalTitle: '',
      error: '',
      edit: false,
      types: [],
      accounts: [],
      selectedGroup: ''
    }
  },
  computed: {
    groupsRows () {
      return this.groups.length
    },
    sortOptions () {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
  },
  async created () {
    this.$parent.groupsLoading = true
    this.getAccountGroups()
  },
  methods: {
    assignAccountsToGroup () {
      this.buttonClick = true
      userService.assignAccountsToGroup(this.grp.AccountGroupId, this.accounts).then((response) => {
        this.getAccountGroups()
        this.assignModal = false
        this.buttonClick = false
      }).catch((error) => {
        this.assignModal = false
        this.buttonClick = false
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
    },
    setAccount (aid) {
      this.accounts.forEach(o => {
        if (o.AccountId === aid) {
          if (o.Checked === true) { o.Checked = false } else { o.Checked = true }
        }
      })
    },
    getAccountGroups () {
      userService.getAccountGroups(this.authData.access_token).then((response) => {
        this.groups = response.data
        setTimeout(() => {
          this.$parent.groupsLoading = false
          this.groupsLoaded = true
        }, 3000)
      }).catch((error) => {
        setTimeout(() => {
          this.$parent.groupsLoading = false
          this.groupsLoaded = true
        }, 3000)
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
    },
    errorAlert (error, err) {
      try {
        if (error.response.data != null) {
          if (error.response.data.Message != null) {
            this.errorModal = true
            this.error = error.response.data.Message
            if (this.error === '') { this.error = err }
          } else if (error.response.data.error_description != null) {
            this.errorModal = true
            this.error = error.response.data.error_description
            if (this.error === '') { this.error = err }
          } else {
            this.errorModal = true
            this.error = error.response.data
            if (this.error === '') { this.error = err }
          }
        } else {
          this.errorModal = true
          this.error = err
        }
        if (error.response.data.Message === 'Authorization has been denied for this request.') {
          this.logOut()
        }
      } catch {
        this.errorModal = true
        this.error = err
      }
    },
    logOut () {
      logoutService.logout().then(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      }).catch(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      })
    },
    showAddEditAssigningAccountsModal (group) {
      this.grp = group
      userService.getAccountGroupsList(this.grp.AccountGroupId).then((response) => {
        this.selectedGroup = group.GroupName
        this.assignModal = true
        this.accounts = response.data
      }).catch((error) => {
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
        this.assignModal = false
      })
    },
    showAddEditAccountGroup (data) {
      if (data !== null) {
        this.edit = true
        this.grp = data
      } else {
        this.edit = false
        this.grp = {
          GroupId: 0,
          GroupName: '',
          IsDeleted: false,
          ListedAccounts: 0,
          Type: ''
        }
      }
      this.showAddEditModal = true
    },
    saveAddEditAccountGroup () {
      this.buttonClick = true
      userService.addEditAccountGroup(this.grp).then(() => {
        this.buttonClick = false
        this.grp = {
          GroupId: 0,
          GroupName: '',
          IsDeleted: false,
          ListedAccounts: 0,
          Type: ''
        },
        this.reset()
        this.showAddEditModal = false
        this.getAccountGroups()
      }).catch((error) => {
        this.buttonClick = false
        this.showAddEditModal = false
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
    },
    showDeleteAccountGroupModal (item) {
      this.grp = item
      this.deleteModal = true
    },
    deleteAccountGroup () {
      this.buttonClick = true
      userService.deleteAccountGroup(this.grp.AccountGroupId).then(() => {
        this.buttonClick = false
        this.mag = {
          GroupId: 0,
          GroupName: '',
          IsDeleted: false,
          ListedAccounts: 0,
          Type: ''
        },
        this.deleteModal = false
        this.getAccountGroups()
      }).catch((error) => {
        this.buttonClick = false
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
        this.deleteModal = false
      })
    },
    reset () {
      this.$refs.form.reset()
    },
    closeErrorModal () {
      this.errorModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .mob {
    width: calc(100% + 30px) !important;
    margin-left: -15px !important;
    margin-top: 15px;
  }
  .fiftyWide {
    width:55%;
  }
  .m-a-left {
    margin-left: 0px !important;
  }
  .italics {
    font-style:italic;
  }
  @media (max-width:576px) {
    .mobBoxHeight {
      height:calc(100vh - 350px) !important;
    }
  }
</style>
