<template>
  <mdb-popover
    v-show="!role.CloseDateIsPast || stageItemStatus === 5"
    ref="popover"
    trigger="click"
    dismissable
    :options="{placement: 'left'}"
    style="cursor:pointer;"
    class="grey-text"
  >
    <div
      slot="body"
    >
      <OneWayPopoverButton
        v-if="stageType == 1"
        :data="data"
        @getRoleCandidates="$emit('getRoleCandidates')"
        @confirmUninviteSelected="$emit('confirmUninviteSelected', $event)"
        @initialiseReviewModal="$emit('initialiseReviewModal', $event)"
      />

      <TwoWayPopoverButton
        v-else-if="stageType == 2"
        :data="data"
        @getRoleCandidates="$emit('getRoleCandidates')"
        @confirmUninviteSelected="$emit('confirmUninviteSelected', $event)"
      />

      <EventPopoverButton
        v-else-if="stageType == 3"
        :data="data"
        @getRoleCandidates="$emit('getRoleCandidates')"
        @confirmUninviteSelected="$emit('confirmUninviteSelected', $event)"
      />
    </div>
    <mdb-icon
      slot="reference"
      :class="{'grey-text' : role.CloseDateIsPast}"
      title="More actions"
      style="width: 20px;color:#555;font-size:1rem;"
      class="ellipsisButtonHover mx-2 mobilePlacement"
      icon="ellipsis-v"
    />
  </mdb-popover>
</template>

<script>
import { computed } from 'vue'
import { useWorkflow, useRoleCandidate } from '@/pinia'
import { storeToRefs } from 'pinia'
import OneWayPopoverButton from './oneWayPopoverButton.vue'
import TwoWayPopoverButton from './twoWayPopoverButton.vue'
import EventPopoverButton from './eventPopoverButton.vue'

export default {
  name: 'PopoverButton',
  components: {
    OneWayPopoverButton, EventPopoverButton, TwoWayPopoverButton
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    const roleCandidateStore = useRoleCandidate()
    const { candidateTableData } = storeToRefs(roleCandidateStore)

    const workflowStore = useWorkflow()
    const { role } = storeToRefs(workflowStore)

    const stageType = computed(() => candidateTableData?.value?.StageType)
    const stageItemStatus = computed(() => candidateTableData?.value?.StageItemStatus)

    return {
      role,
      stageType,
      stageItemStatus
    }
  }
}
</script>
