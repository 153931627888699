<template>
  <div
    class="mt-4 overFlowY"
    :class="mobileView ? 'mt-6 pt-8' : ''"
    style="min-height: fit-content;margin-top:80px !important;"
  >
    <div v-show="usersLoaded" :class="{ 'vMobBrandingTop': mobileView }">
      <div class="candidatesScrollContainer">
        <div class="candidatesScrollContent">
          <div v-show="users.length > 0">
            <div class="d-flex align-items-center justify-content-between mb-3 pt-2">
              <ExpandableSearchBar
                :width="400"
                :max-widthpercentage="50"
                @search="filter = $event"
              />
            </div>

            <!--data table for desktop-->
            <div v-if="mobileView === false">
              <b-table
                id="users-table"
                class="text-left"
                head-variant="dark"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :items="users"
                :per-page="perPage"
                :current-page="currentPage"
                :fields="fields"
                :filter="filter"
                :striped="true"
                hover
                small
                sort-icon-left
                responsive="sm"
              >
                <template #cell(firstname)="data">
                  <div
                    style="margin-top:0px;margin-left:5px;"
                    class="d-flex align-items-center"
                    :class="{ 'fSizeSm': mobileView }"
                  >
                    <div style="height:40px;width:40px;border-radius:50%;display:flex;align-items:center;justify-content:center;margin-top:10px;" class="mr-2 d-none d-sm-block d-md-none d-lg-block">
                      <img
                        v-if="data.item.ProfilePicture != '' && data.item.ProfilePicture != null"
                        style="border:solid 1px #c0c0c0;height:36px;width:36px;border-radius:50%;object-fit:cover;"
                        :src="data.item.ProfilePicture"
                      >
                      <span
                        v-else
                        aria-hidden="true"
                        style="border:solid 1px #c0c0c0;padding: 9px;border-radius:50%;"
                      >
                        <i
                          class="fa fa-user grey-text"
                          aria-hidden="true"
                          style="padding-left: 0px;padding-top: 4px;font-size: 18px;"
                        />
                      </span>
                    </div>
                    <div class="mr-2">
                      <div class="webDisplay smFont" style="margin-top:0px;">
                        {{ data.item.FirstName }} {{ data.item.Surname }}
                      </div>
                      <div class="d-flex d-md-none align-items-center" style="transform:translateY(-6px)">
                        <div class="mr-1 smFont">
                          {{ data.item.FirstName }} {{ data.item.Surname }}
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template #cell(role)="data">
                  <div
                    v-if="data.item.Verified === false"
                    style="margin-top:12px;"
                    class="grey-text smFont"
                  >
                    {{ data.item.Role }}
                  </div>
                  <div
                    v-else
                    style="margin-top:12px;"
                    class="smFont"
                  >
                    {{ data.item.Role }}
                  </div>
                </template>
                <template #cell(accountname)="data">
                  <div
                    v-if="data.item.Verified === false"
                    style="margin-top:12px;"
                    class="grey-text smFont"
                  >
                    {{ data.item.AccountName }}
                  </div>
                  <div
                    v-else
                    class="smFont"
                    style="margin-top:12px;"
                  >
                    {{ data.item.AccountName }}
                  </div>
                </template>
                <template #cell(linkedaccounts)="data">
                  <div
                    class="smFont"
                    style="margin-top:12px;"
                  >
                    {{ data.item.LinkedAccounts }}
                  </div>
                </template>
                <template #cell(actions)="data">
                  <div class="d-flex align-items-center justify-content-end" style="cursor:pointer;">
                    <button
                      title="Edit user permissions?"
                      type="button"
                      class="btn btn-primary btn-md"
                      style="padding: 0.5rem 0.7rem;"
                      @click="editAccountPermissions(data.item)"
                    >
                      <span class="d-none d-lg-inline">PERMISSIONS</span>
                    </button>
                  </div>
                </template>
              </b-table>
            </div>

            <!--data table for mobile-->
            <div v-if="mobileView">
              <b-table
                id="users-table"
                class="text-left"
                head-variant="dark"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :items="users"
                :per-page="perPage"
                :current-page="currentPage"
                :fields="mobileFields"
                :filter="filter"
                hover
                small
                sort-icon-left
                responsive="sm"
              >
                <template #cell(firstname)="data">
                  <div
                    style="margin-top:12px;"
                    class="smFont"
                    :title="data.item.Role"
                  >
                    <span class="primary-colour">{{ data.item.FirstName }} {{ data.item.Surname }}</span>
                  </div>
                </template>
                <template #cell(linkedaccounts)="data">
                  <div style="margin-top:12px;" class="smFont">
                    {{ data.item.LinkedAccounts }}
                  </div>
                </template>
                <template #cell(actions)="data">
                  <div class="float-right" style="cursor:pointer;">
                    <mdb-popover
                      trigger="click"
                      :options="{placement: 'bottom'}"
                      style="cursor:pointer;"
                      class="grey-text"
                    >
                      <div slot="body">
                        <h6
                          @click="editAccountPermissions(data.item)"
                        >
                          <a class="ml-1 grey-link"><i class="fa fa-edit" /> Edit Permissions?</a>
                        </h6>
                      </div>
                      <mdb-icon
                        slot="reference"
                        title="More actions"
                        style="margin: 0.5rem;color:#555;font-size:1rem;"
                        icon="ellipsis-v"
                        class="ellipsisButtonHover"
                      />
                    </mdb-popover>
                  </div>
                </template>
              </b-table>
            </div>

            <b-pagination
              v-show="users.length > 5"
              v-model="currentPage"
              class="float-right mt-4"
              :total-rows="rows"
              :per-page="perPage"
              first-number
              last-number
              aria-controls="users-table"
              prev-text="<"
              next-text=">"
            />
          </div>
        </div>
      </div>
    </div>

    <mdb-modal
      id="permissionsModal"
      :show="permissionsModal"
      @close="permissionsModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>EDIT PERMISSIONS FOR <span style="text-transform: uppercase;">{{ selectedUser.FirstName }} {{ selectedUser.Surname }}</span></mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <b-row style="width: 90%; margin-left: 20px;justify-content: space-around;">
          <div
            style="font-size: smaller;width:50%;"
            class="modal-title-container text-center"
            :class="{'modal-title-underlined' : modalPage == 'ACCOUNTS'}"
          >
            <h6 :class="{'grey-text' : modalPage != 'ACCOUNTS' }" @click="setModalPage('ACCOUNTS')">
              <span><i class="fa fa-list" /> ACCOUNTS</span>
            </h6>
          </div>
          <div
            style="font-size: smaller;width:50%;"
            class="modal-title-container text-center"
            :class="{'modal-title-underlined' : modalPage == 'GROUPS'}"
          >
            <h6 :class="{'grey-text' : modalPage != 'GROUPS'}" @click="setModalPage('GROUPS')">
              <span><i class="far fa-list-alt" /> GROUPS</span>
            </h6>
          </div>
        </b-row>
        <!-- accounts-->
        <div v-if="modalPage == 'ACCOUNTS'" style="min-height:400px;margin-top:30px;">
          <mdb-modal-body class="px-xs-2 py-xs-1 px-sm-4 py-sm-4">
            <div class="grey-text text-left" style="font-size: 0.9rem;margin-top:-20px;">
              <div
                class="mobBoxHeight"
                style="padding:1px;height:200px;overflow-y: auto;"
              >
                <div
                  v-for="account in accounts"
                  :key="account.AccountId"
                >
                  <div
                    v-if="account.AccountId === userData.aid && selectedUser.Id == userData.uid"
                    style="margin-left:38px;margin-top:10px;opacity:0.5;"
                    :style="account.Checked ? `font-weight:bold;` : 'font-weight:normal;'"
                    size="sm"
                  >
                    <i
                      class="fa fa-lock"
                      style="margin-right:20px;cursor:help;"
                      title="This is the account you are currently logged into, so it cannot be removed!"
                    />{{ account.Name }}
                  </div>
                  <div
                    v-else-if="account.Name == selectedUser.AccountName"
                    style="margin-left:38px;margin-top:10px;opacity:0.5;"
                    :style="account.Checked ? `font-weight:bold;` : 'font-weight:normal;'"
                    size="sm"
                  >
                    <i
                      class="fa fa-lock"
                      style="margin-right:20px;cursor:help;"
                      title="Users current account!"
                    />{{ account.Name }} 
                  </div>
                  <div
                    v-else-if="account.AccountGrouped && account.Checked"
                    style="margin-left:38px;margin-top:10px;opacity:0.5;"
                    :style="account.Checked ? `font-weight:bold;` : 'font-weight:normal;'"
                    size="sm"
                  >
                    <i
                      class="fa fa-th"
                      style="margin-right:20px;cursor:help;"
                      title="Account assigned from a group and cannot be individually removed!"
                    />
                    {{ account.Name }}
                  </div>
                  <div v-else>
                    <div style="float:left;cursor:pointer;width:30px;">
                      <i
                        class="fa fa-sync"
                        style="margin-right:20px;"
                        title="Make this the user's master account?"
                        @click="setAsMasterAccount(account)"
                      />
                    </div>
                    <div
                      style="padding-left:21px;"
                      @click="setAccount(account)"
                    >
                      <mdb-input
                        v-model="account.Checked"
                        type="checkbox"
                        style="margin-left:-10px;margin-top:10px;"
                        :style="account.Checked ? `font-weight:bold;` : 'font-weight:normal;'"
                        size="sm"
                        :label="account.Name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mdb-modal-body>
          <mdb-modal-footer style="display:block;">
            <b-row align-h="between" align-v="center">
              <div>
                <mdb-btn
                  type="reset"
                  class="btn-outline"
                  size="sm"
                  style="width:100px;"
                  @click="permissionsModal = false"
                >
                  CANCEL
                </mdb-btn>
              </div>
              <mdb-btn
                v-if="!buttonClick"
                type="submit"
                class="float-right"
                size="sm"
                style="width:100px;"
                @click="assignAccountPermissions()"
              >
                <span>DONE</span>
              </mdb-btn>
              <mdb-btn
                v-else
                size="sm"
                style="width:100px;"
              >
                <mdb-icon icon="fa fa-pulse fa-spinner" />
              </mdb-btn>
            </b-row>
          </mdb-modal-footer>
        </div>
        <!-- groups-->
        <div v-if="modalPage == 'GROUPS'" style="min-height:400px;margin-top:30px;">
          <mdb-modal-body class="px-xs-2 py-xs-1 px-sm-4 py-sm-4">
            <div class="grey-text text-left" style="font-size: 0.9rem;margin-top:-20px;">
              <div
                class="mobBoxHeight"
                style="padding:1px;height:200px;overflow-y: auto;"
              >
                <div
                  v-for="accountGroup in accountGroups"
                  :key="accountGroup.AccountGroupId"
                  @click="setAccountGroup(accountGroup.AccountGroupId)"
                >
                  <mdb-input
                    v-model="accountGroup.Checked"
                    type="checkbox"
                    style="margin-left:-10px;margin-top:10px;"
                    :style="accountGroup.Checked ? `font-weight:bold;` : 'font-weight:normal;'"
                    size="sm"
                    :label="accountGroup.GroupName"
                  />
                </div>
              </div>
            </div>
          </mdb-modal-body>
          <mdb-modal-footer style="display:block;">
            <b-row align-h="between" align-v="center">
              <div>
                <mdb-btn
                  type="reset"
                  class="btn-outline"
                  size="sm"
                  style="width:100px;"
                  @click="permissionsModal = false"
                >
                  CANCEL
                </mdb-btn>
              </div>
              <mdb-btn
                v-if="!buttonClick"
                type="submit"
                class="float-right"
                size="sm"
                style="width:100px;"
                @click="assignAccountGroupsPermissions()"
              >
                <span>DONE</span>
              </mdb-btn>
              <mdb-btn
                v-else
                size="sm"
                style="width:100px;"
              >
                <mdb-icon icon="fa fa-pulse fa-spinner" />
              </mdb-btn>
            </b-row>
          </mdb-modal-footer>
        </div>
      </mdb-modal-body>
    </mdb-modal>

    <mdb-modal
      id="errorModal"
      :show="errorModal"
      @close="errorModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>{{ errorModalTitle.toUpperCase() }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          <span v-html="error" />
        </p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="closeErrorModal"
        >
          CLOSE
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import { userLanguage } from '@/common/userLanguage'
import userService from '@/api/services/user.service'

import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn } from 'mdbvue'
import ExpandableSearchBar from '@/components/UI/expandableSearch.vue'

export default {
  name: 'Users',
  directives: {
    uploader: {
      bind (el, binding, vnode) {
        el.addEventListener('change', e => {
          if (e.target.files[0] !== undefined) {
            vnode.context.file = e.target.files[0]
          }
        })
      }
    }
  },
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
    ExpandableSearchBar
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    return {
      authData,
      userData,
      mobileView
    }
  },
  data () {
    return {
      fields: [
        { key: 'FirstName', label: '  User', tdClass: 'headerPaddingUsers col30 sortIcon', sortable: true },
        { key: 'Role', label: '  Role', tdClass: 'headerPaddingUsers col20 sortIcon', sortable: true },
        { key: 'AccountName', label: '  Account', tdClass: 'headerPaddingUsers col30 sortIcon', sortable: true },
        { key: 'LinkedAccounts', label: '  Accounts', tdClass: 'headerPaddingUsers col10 sortIcon', sortable: true },
        { key: 'Actions', label: '', tdClass: 'headerPaddingUsers col10', sortable: false }
      ],
      mobileFields: [
        { key: 'FirstName', label: '  User', tdClass: 'col50 sortIcon', sortable: true },
        { key: 'LinkedAccounts', label: '  Accounts', tdClass: 'col25 sortIcon', sortable: true },
        { key: 'Actions', label: '', tdClass: 'col25', sortable: false }
      ],
      sortBy: '',
      sortDesc: false,
      permissionsModal: false,
      errorModal: false,
      errorModalTitle: '',
      error: '',
      users: [],
      usersLoaded: false,
      selectedUserId: null,
      alertModalTitle: '',
      alertModalContent: '',
      perPage: 8,
      buttonClick: false,
      buttonModalClick: false,
      currentPage: 1,
      errors: null,
      filter: null,
      filterOn: [],
      selectedUser: [{ FirstName: '', Surname: '' }],
      user: {
        ProfilePicture: 'https://go.shineinterview.com/img/candidate-silhouette.png'
      },
      userLanguage,
      pages: [],
      modalPage: 'ACCOUNTS',
      accounts: [],
      accountGroups: []
    }
  },
  computed: {
    rows () {
      return this.users.length
    },
    sortOptions () {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  },
  async created () {
    this.$parent.usersLoading = true
    this.getUsers()
  },
  methods: {
    setAccount (a) {
       if (a.AccountGrouped && a.Checked) {
        //cannot toggle
      } else {
        this.accounts.forEach(o => {
          if (o.AccountId === a.AccountId) {
            if (o.Checked === true) { o.Checked = false } else { o.Checked = true }
          }
        })
      }
    },
    setAccountGroup (gid) {
      this.accountGroups.forEach(o => {
        if (o.AccountGroupId === gid) {
          if (o.Checked === true) { o.Checked = false } else { o.Checked = true }
        }
      })
    },
    setModalPage (tab) {
        this.modalPage = tab
    },
    editAccountPermissions (user) {
      this.modalPage = 'ACCOUNTS'
      this.selectedUser = user
      this.permissionsModal = true
      userService.getAvailableAccountsForUser(user.Id).then((response) => {
        this.accounts = response.data
        this.editGroupPermissions()
      }).catch((error) => {
        this.permissionsModal = false
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
    },
    editGroupPermissions () {
      this.permissionsModal = true
      userService.getAvailableAccountGroupsForUser(this.selectedUser.Id).then((response) => {
        this.accountGroups = response.data
      }).catch((error) => {
        this.permissionsModal = false
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
    },
    assignAccountPermissions () {
      userService.assignAccountPermissions(this.selectedUser.Id, this.accounts).then((response) => {

        //not ideal but dirty quick fix - TO DO
        try {
          this.$parent.$parent.$children[1].getMyRecentAccounts()
        } catch {
          this.$parent.$parent.$children[5].getMyRecentAccounts()
        }
        //not ideal but dirty quick fix - TO DO

        this.getUsers()
        this.permissionsModal = false
        this.buttonClick = false
        this.selectedUser = []
      }).catch((err) => {
        this.selectedUser = []
        this.permissionsModal = false
        this.buttonClick = false
        this.errorModalTitle = 'Error!'
        this.errorAlert(err, 'Sorry an error has occurred!')
      })
    },
    setAsMasterAccount (account) {
      userService.setAsMasterAccount(this.selectedUser.Id, account.AccountId).then((response) => {
        this.selectedUser.AccountName = account.Name
        this.editAccountPermissions(this.selectedUser)
      }).catch((error) => {
        this.permissionsModal = false
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
    },
    assignAccountGroupsPermissions () {
      userService.assignAccountGroupsPermissions(this.selectedUser.Id, this.accountGroups).then((response) => {

        //not ideal but dirty quick fix - TO DO
        try {
          this.$parent.$parent.$children[1].getMyRecentAccounts()
        } catch {
          this.$parent.$parent.$children[5].getMyRecentAccounts()
        }
        //not ideal but dirty quick fix - TO DO

        this.getUsers()
        this.permissionsModal = false
        this.buttonClick = false
        this.selectedUser = []
      }).catch((error) => {
        this.selectedUser = []
        this.permissionsModal = false
        this.buttonClick = false
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
    },
    viewActionsClick () {
      this.$emit('details', this.user)
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getUsers () {
      this.$parent.usersLoading = true
      userService.getUsersAndAccountGroups().then((response) => {
        this.addEditUserForm = false
        this.users = response.data
        setTimeout(() => { this.$parent.usersLoading = false, this.usersLoaded = true }, 3000)
        this.buttonClick = false
      }).catch((error) => {
        this.addEditUserForm = false
        setTimeout(() => { this.$parent.usersLoading = false, this.usersLoaded = true }, 3000)
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
        this.buttonClick = false
      })
    },
    errorAlert (error, msg) {
      try {
        if (msg) {
          this.errorModal = true
          this.error = msg
          return
        }
        if (error.response.data != null) {
          if (error.response.data.Message != null) {
            this.errorModal = true
            this.error = error.response.data.Message
            if (this.error === '') { this.error = 'Sorry, an error has occured!' }
          } else if (error.response.data.error_description != null) {
            this.errorModal = true
            this.error = error.response.data.error_description
            if (this.error === '') { this.error = 'Sorry, an error has occured!' }
          } else {
            this.errorModal = true
            this.error = error.response.data
            if (this.error === '') { this.error = 'Sorry, an error has occured!' }
          }
        } else {
          this.errorModal = true
          this.error = 'Sorry an error has occurred!'
        }
        if (error.response.data.Message === 'Authorization has been denied for this request.') {
          this.logOut()
        }
      } catch {
        this.errorModal = true
        this.error = 'Sorry an error has occurred!'
      }
    },
    closeErrorModal () {
      this.errorModal = false
    }
  }
}
</script>

<style>
  .page-link {
    border: 1px solid #dee2e6 !important;
  }
  table.table thead th {
    outline: none;
    cursor: pointer;
  }
  .form-control:focus {
    border-color: #d0d0d0;
    box-shadow: none;
  }
  .ppPad {
    margin-bottom:40px !important;
  }
  .vMobTop {
    margin-top:30px !important;
  }
</style>

<style lang="scss" scoped>
  .noUsersMob {
    margin-top: 75px;
  }
  .usersLoadingMargins {
    margin-top: 40px !important;
    margin-left: 2px !important;
    margin-bottom: -13px;
  }
  .popover[x-placement^=left] {
     width: 150px !important;
  }
  @media (max-width: 576px) {
    .usersTop {
      margin-top: -25px;
    }
  }
  .addTop {
    margin-top: 60px !important;
  }
</style>
