<template>
  <div class="px-3 px-md-5 mt-3 mt-md-5 flex-grow-1" style="text-align:left;padding:2px;height:100%;">
    <div v-if="loadedQuestions">
      <div
        class="pt-2 pt-md-4 px-3 px-md-5 w-1/1 text-black rounded"
        style="background:rgb(225, 225, 225);"
        :class="{ 'paddOne' : mobileView }"
      >
        <div class="text-left font-bold mb-6 mt-2">
          Practice Question
        </div>
        <b-row
          no-gutters
          :class="{ 'paddTwo': mobileView }"
          class="text-bold text-black rounded text-center border-2 border-gray-400  bg-gray-100 px-4 py-3 py-lg-4 my-1"
        >
          <b-col
            cols="1"
            class="align-self-center"
            style="text-align: left;font-size: 1rem;"
          >
            <i
              v-show="!practiceQuestion?.QuestionVideoUploadedVideoId"
              :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
              class="col-span-1 fa fa-2x text-left fa-question-circle"
              style="font-size:1.2rem;"
            />
            <i
              v-show="practiceQuestion?.QuestionVideoUploadedVideoId"
              :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
              class="col-span-1 fa fa-2x text-left fa-video"
              style="font-size:1.2rem;"
            />
          </b-col>
          <b-col
            cols="6"
            class="align-self-center text-nowrap"
            style="text-align:left;font-size:smaller;overflow: hidden;"
          >
            {{ practiceQuestion?.Description }}
          </b-col>
          <b-col
            cols="3"
            class="font-extrabold align-self-center"
            style="font-size:smaller;"
          >
            {{ convertTimeLimit(practiceQuestion?.TimeLimit) }} mm:ss
          </b-col>
          <b-col
            cols="2"
            class="text-right align-self-center"
            style=""
          >
            <i
              :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
              class="col-span-1 fa fa-2x text-left fa-edit mx-2"
              style="font-size:1.2rem;cursor:pointer;"
              @click="openEditPracticeQuestionModal(practiceQuestion)"
            />
          </b-col>
        </b-row>
        <div class="text-left text-black font-bold my-6">
          Interview Questions
        </div>
        <draggable
          :list="nonPracticeQuestions"
          style="cursor:grab;"
          :options="{disabled : mobileView}"
          @start="drag=true"
          @end="updateQuestionOrder"
        >
          <div
            v-for="(question, i) in nonPracticeQuestions"
            :key="i"
            :class="{ 'paddTwo': mobileView }"
            class="d-flex text-bold text-black rounded text-center border-2 border-gray-400 bg-gray-100 px-2 py-2 my-1"
          >
            <QuestionSet
              v-if="question.QuestionSetId"
              :invites-have-been-sent="oneWayStageHasBeenStarted"
              :is-video-c-v="false"
              :deleting-set-id="deletingSetId"
              :question-set="question"
              @reloadQuestionSetSource="getStageInvitationQuestions()"
              @deleteSet="deleteQuestionSetFromOneWaySetup($event)"
            />

            <div
              v-else
              class="d-flex align-items-center justify-content-between"
              style="width:100%;"
            >
              <div class="align-self-center ml-2 ml-md-3 mt-1" style="text-align: left;font-size: 1rem;font-weight: bold;">
                <i
                  v-show="question.IsVideoQuestion == false"
                  :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
                  class="fa fa-2x text-left fa-question-circle"
                  style="font-size:1.2rem;"
                />
                <i
                  v-show="question.IsVideoQuestion"
                  :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
                  class="fa fa-2x text-left fa-video"
                  style="font-size:1.2rem;"
                />
              </div>
              <div class="text-wrap ml-2 ml-md-3 mr-auto" style="text-align:left;font-size:smaller;white-space: pre-line !important; ">
                {{ question.Description }}
              </div>
              <div class="align-self-center font-extrabold mx-2 mx-md-3" style="font-size:smaller;">
                {{ convertTimeLimit(question.TimeLimit) }} mm:ss
              </div>
              <div class="align-self-center text-right d-flex mr-2 mr-md-3 justify-content-between">
                <div
                  v-if="!question.IsPool"
                  class="mr-auto pr-3 mt-1"
                  @click="openEditQuestionModal(question)"
                  @touchstart="openEditQuestionModal(question)"
                >
                  <i
                    :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
                    class="fa fa-2x fa-edit"
                    style="font-size:1.2rem;cursor:pointer;"
                  />
                </div>
                <div
                  v-if="!oneWayStageHasBeenStarted"
                  class="ml-auto align-self-left mt-1"
                  @click="openConfirmDeleteQuestionModel(question)"
                  @touchstart="openConfirmDeleteQuestionModel(question)"
                >
                  <i
                    :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
                    class="fa fa-2x fa-times"
                    style="font-size:1.2rem;cursor:pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        </draggable>
        <div
          v-if="!addingFromPool && !oneWayStageHasBeenStarted"
          slot="footer"
          :class="{ 'smallerFont': mobileView }"
          class="rounded text-center border-2 border-gray-400 border-dashed p-3 mt-4"
          style="cursor:pointer;font-size:1rem;font-weight:bold;padding:20px !important;"
          @click="openAddQuestionModal()"
        >
          Add Question
        </div>
        <div
          v-if="addingFromPool && !oneWayStageHasBeenStarted"
          slot="footer"
          :class="{ 'smallerFont': mobileView }"
          class="rounded text-center border-2 border-gray-400 border-dashed p-3 mt-4"
          style="font-size:1rem;font-weight:bold;padding:20px !important;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </div>
        <div
          v-if="oneWayStageHasBeenStarted"
          class="mt-3 text-center"
          style="font-size:0.8rem; text-wrap: balance"
        >
          There are candidates with active invites in this stage - if you would like to edit questions you must reset all interviews in this stage.
        </div>
        <div style="height: 39px;" />
        <div ref="scrollToDiv" style="height: 1px;" />
      </div>

      <div class="min-h-full p-2 w-1/1 text-black rounded" style="margin-top:-2px;background:rgb(209, 209, 209);border-top-left-radius:0px !important;border-top-right-radius:0px !important;">
        <div
          :class="{ 'smallFont': mobileView }"
          class="text-right font-bold mb-6"
          style="margin-top: 10px;margin-bottom: 10px;"
        >
          {{ answerTimeIsEstimate ? 'Estimate ' : '' }}Total Answer Time: {{ convertTimeLimit(answerTime) }} mm:ss
        </div>
      </div>

      <div style="margin-bottom:68px;" class="d-flex align-items-center justify-content-between mt-3 mb-md-5">
        <mdb-btn
          class="btn btn-outline"
          size="sm"
          style="width:110px;"
          @click="$router.push(`/roles/add_edit/detail/${role.JobId}`)"
        >
          CANCEL
        </mdb-btn>
        <mdb-btn
          v-show="!buttonClick"
          :disabled="nonPracticeQuestions.length == 0"
          type="submit"
          class="m-0"
          size="sm"
          style="width:100px;"
          @click="goToPrepTime()"
        >
          NEXT
        </mdb-btn>
        <mdb-btn
          v-show="buttonClick"
          class="m-0"
          size="sm"
          style="width:100px;cursor:default;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </div>

      <mdb-modal
        id="addQuestion"
        :show="addEditQuestionModal"
        @close="closeAddEditQuestionModal()"
      >
        <!--header for add or edit modal-->
        <mdb-modal-header v-if="textOrVideoQuestionModal == 'text'" class="p-2 p-sm-4">
          <b-row
            style="width: 90%; margin-left: 20px;"
            align-h="between"
            class=""
          >
            <div
              style="font-size: smaller;"
              class="modal-title-container m-auto m-sm-0"
              :class="{'modal-title-underlined' : addEditQuestionModalPage == 1}"
            >
              <h6 :class="{'grey-text' : addEditQuestionModalPage == 2 || addEditQuestionModalPage == 3}" @click="setAddEditQuestionModalPage(1)">
                ADD/EDIT QUESTION
              </h6>
            </div>
            <div
              v-if="!isPractiseQuestion && !oneWayStageHasBeenStarted"
              style="font-size: smaller;"
              class="modal-title-container m-auto m-sm-0 text-center"
              :class="{'modal-title-underlined' : addEditQuestionModalPage == 2}"
            >
              <h6 :class="{'grey-text' : addEditQuestionModalPage == 1 || addEditQuestionModalPage == 3}" @click="setAddEditQuestionModalPage(2)">
                QUESTION POOL
              </h6>
            </div>
            <div
              v-if="!isPractiseQuestion && !oneWayStageHasBeenStarted"
              style="font-size: smaller;"
              class="modal-title-container m-auto m-sm-0 text-center"
              :class="{'modal-title-underlined' : addEditQuestionModalPage == 3}"
            >
              <h6 :class="{'grey-text' : addEditQuestionModalPage == 1 || addEditQuestionModalPage == 2}" @click="setAddEditQuestionModalPage(3)">
                QUESTION SET
              </h6>
            </div>
          </b-row>
        </mdb-modal-header>

        <!--add/edit question-->
        <div v-if="addEditQuestionModalPage == 1 && textOrVideoQuestionModal == 'text'">
          <mdb-modal-body :class="{ 'smFont': mobileView }" class="px-xs-2 py-xs-1 px-sm-4 py-sm-4">
            <div class="grey-text" style="font-size: 0.9rem;">
              Add a new question to this interview
            </div>
            <validation-observer v-slot="{ reset }">
              <form
                ref="questionform"
                @submit.prevent="addEditQuestion()"
                @reset.prevent="reset"
              >
                <div class="md-form form-sm text-left">
                  <validation-provider
                    v-slot="{ errors }"
                    mode="lazy"
                    name="QuestionType"
                    rules="required|time-limit"
                  >
                    <i
                      v-if="questionIsTextOrVideo == 2"
                      class="fas fa-play prefix"
                      style="margin-top: 6px;font-size: 1.3rem;"
                    />
                    <i
                      v-else
                      class="fas fa-bars prefix"
                      style="margin-top: 6px;font-size: 1.3rem;"
                    />
                    <mdb-select
                      v-model="questionTypeDropDownOptions"
                      :disabled="oneWayStageHasBeenStarted"
                      placeholder="Question Type"
                      style="margin-left:33px;"
                      size="sm"
                      @change="questionIsTextOrVideo = $event"
                    />
                    <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                      Please select question type
                    </div>
                  </validation-provider>
                </div>
                <div class="md-form form-sm text-left">
                  <i class="fas fa-question prefix float-left" style="margin-top: -5px;font-size: 1.3rem;" />
                  <mdb-input
                    v-model="question.Description"
                    type="textarea"
                    placeholder="Question?"
                    auto-complete="fna"
                    :rows="4"
                    size="sm"
                    maxlength="550"
                    style="margin-left:25px;width:calc(100% - 30px);"
                    @keyup.native="charCount(question.Description, 550)"
                  />
                  <div class="grey-text remainingChars">
                    {{ chars }}
                  </div>
                </div>
                <div v-if="questionIsTextOrVideo == 2" style="height: fit-content">
                  <LeaveFadeExit>
                    <b-row
                      v-if="videoToAddToList && Object.keys(videoToAddToList).length != 0"
                      key="pic"
                      no-gutters
                      align-h="between"
                      align-v="center"
                      class="my-2"
                    >
                      <SlideFade>
                        <div class="border-2 border-gray-400 bg-gray-100 my-1 p-1 text-bold text-black rounded text-center" style="width: 100%;">
                          <b-row
                            no-gutters
                            align-h="center"
                            align-v="center"
                          >
                            <b-col cols="4" style="background: black;">
                              <img
                                v-if="videoToAddToList.ThumbnailUrl"
                                :src="videoToAddToList.ThumbnailUrl"
                                class="m-auto"
                                style="height:80px; border-radius: 2px; object-fit: contain"
                              >
                              <img
                                v-if="videoToAddToList.Thumb"
                                :src="videoToAddToList.Thumb"
                                class="m-auto"
                                style="height:80px; border-radius: 2px; object-fit: contain"
                              >
                            </b-col>
                            <b-col offset="1" class="p-1">
                              <b-row no-gutters class="font-extrabold text-center">
                                {{ videoToAddToList.ShortDescription }}
                              </b-row>
                              <b-row
                                no-gutters
                                class="text-center"
                                style="font-size:smaller;"
                              >
                                {{ videoToAddToList.VideoTypeDesc }}
                              </b-row>
                            </b-col>
                            <b-col cols="2">
                              <div style="margin-bottom:-5px" class="text-center">
                                <i
                                  class=" fas fa-times prefix"
                                  style="color: black; margin-top: 6px;font-size: 1.3rem; cursor: pointer;"
                                  @click="videoToAddToList = {}"
                                />
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </SlideFade>
                    </b-row>

                    <!--if no video to add-->
                    <b-row
                      v-else
                      key="box"
                      no-gutters
                      align-h="between"
                    >
                      <b-col class="p-0" cols="auto">
                        <i class="fas fa-video prefix" style="margin-top: 6px;font-size: 1.3rem;" />
                      </b-col>
                      <b-col v-if="loading.status && loading.message === 'Preparing video'" class="align-self-center pl-3">
                        <div class="grey-text" style="font-size: 0.9rem;">
                          Preparing Video
                        </div>
                      </b-col>
                      <b-col v-else class="align-self-center pl-3">
                        <div class="grey-text" style="font-size: 0.9rem;text-align:left;">
                          No video selected
                        </div>
                      </b-col>
                      <i
                        class="mr-2 fas fa-plus prefix justify-self-end"
                        style="margin-top: 6px; cursor: pointer;"
                        @click="textOrVideoQuestionModal='video'"
                      />
                    </b-row>
                  </LeaveFadeExit>
                </div>
                <div class="md-form form-sm text-left">
                  <validation-provider
                    v-slot="{ errors }"
                    mode="lazy"
                    name="Invitation Method"
                    rules="required|time-limit"
                  >
                    <i class="fas fa-clock prefix" style="margin-top: 6px;font-size: 1.3rem;" />
                    <mdb-select
                      v-model="questionLength"
                      :disabled="oneWayStageHasBeenStarted"
                      placeholder="Time to answer this Question"
                      style="margin-left:33px;"
                      size="sm"
                    />
                    <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                      Please select time to answer question
                    </div>
                  </validation-provider>
                </div>
                <div
                  v-if="localSetup.TemplateId === -1 && !isPractiseQuestion"
                  class="d-flex align-items-center"
                  style="height: 50px;"
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-chart-simple-horizontal"
                    class="prefix ml-1"
                    style="scale: 1.5;"
                  />
                  <mdb-select
                    v-model="scoringTemplateOptions"
                    :disabled="oneWayStageHasBeenStarted"
                    placeholder="Choose Scoring Template"
                    style="margin-left:33px;width: calc(100% - 70px)"
                    size="sm"
                  />
                  <Tooltip
                    class="mx-2"
                    style="display: inline-block;"
                    tooltip-direction="left"
                    tool-tip-summary="The template you select will determine how users give points while reviewing each candidate. Please be aware that editing the template of a Question that is part of your Question Pool, will effect other roles where that question is used."
                  />
                </div>
                <div v-if="!isPractiseQuestion" class="md-form form-sm text-left">
                  <div class="md-form form-sm" @click="toggleAddToPool()">
                    <i class="fas fa-plus-square prefix" style="margin-top: 9px;font-size: 1.3rem;" />
                    <mdb-input
                      v-model="addToPool"
                      :disabled="oneWayStageHasBeenStarted"
                      type="checkbox"
                      label="Add to the Question Pool"
                      style="margin-left:15px;"
                      size="sm"
                    />
                  </div>
                </div>
              </form>
            </validation-observer>
          </mdb-modal-body>
          <mdb-modal-footer v-if="videoToAddToList" style="display:block;">
            <b-row align-h="between" align-v="center">
              <b-col>
                <mdb-btn
                  type="reset"
                  class="btn-outline"
                  size="sm"
                  style="width:100px;"
                  @click="closeAddEditQuestionModal()"
                >
                  CANCEL
                </mdb-btn>
              </b-col>
              <mdb-btn
                v-if="questionIsTextOrVideo == 2 && isPractiseQuestion && !waitingUpdate"
                type="submit"
                :disabled="(question.Description === null || question.Description === '') || timeLimit == 0 || Object.keys(videoToAddToList).length == 0"
                class="float-right"
                size="sm"
                style="width:100px;"
                @click="addEditQuestion()"
              >
                <span>SAVE</span>
              </mdb-btn>
              <mdb-btn
                v-if="questionIsTextOrVideo == 1 && isPractiseQuestion && !waitingUpdate"
                type="submit"
                :disabled="(question.Description === null || question.Description === '') || timeLimit == 0"
                class="float-right"
                size="sm"
                style="width:100px;"
                @click="addEditQuestion()"
              >
                <span>SAVE</span>
              </mdb-btn>
              <mdb-btn
                v-if="questionIsTextOrVideo == 2 && !isPractiseQuestion && !waitingUpdate"
                type="submit"
                :disabled="!question.Description || timeLimit == 0 || Object.keys(videoToAddToList).length == 0"
                class="float-right"
                size="sm"
                style="width:100px;"
                @click="addEditQuestion()"
              >
                <span>SAVE</span>
              </mdb-btn>
              <mdb-btn
                v-if="questionIsTextOrVideo == 1 && !isPractiseQuestion && !waitingUpdate"
                type="submit"
                :disabled="!question.Description || timeLimit == 0"
                class="float-right"
                size="sm"
                style="width:100px;"
                @click="addEditQuestion()"
              >
                <span>SAVE</span>
              </mdb-btn>
              <mdb-btn
                v-if="waitingUpdate"
                size="sm"
                style="width:100px;"
              >
                <mdb-icon icon="fa fa-pulse fa-spinner" />
              </mdb-btn>
            </b-row>
          </mdb-modal-footer>
        </div>

        <!--choose question from pool modal body-->
        <div v-if="addEditQuestionModalPage == 2 && textOrVideoQuestionModal == 'text'">
          <mdb-modal-body
            v-if="videoToAddToList != undefined && videoToAddToList != null"
            class="px-2 py-1 px-sm-4 py-sm-4 text-black"
            :class="{ 'modalHeightForRoles': mobileView }"
          >
            <b-row
              v-if="loading.message == 'Fetching questions from pool'"
              align-h="center"
              align-v="center"
            >
              <mdb-icon icon="fa fa-pulse fa-spinner" />
            </b-row>
            <b-row
              v-if="filteredQuestionPool.length == 0 && !loading.status"
              align-h="center"
              align-v="center"
            >
              <div class="text-center p-3" style="font-size:13px;">
                Either you have no questions in your question pool or all questions are already added to this role.
              </div>
            </b-row>
            <div v-if="filteredQuestionPool">
              <SlideFade>
                <div
                  v-if="filteredQuestionPool.length > 0 && loading.message != 'Fetching questions from pool'"
                  style="max-height: 243px; overflow: auto;"
                  class="p-2"
                >
                  <div
                    v-for="question in filteredQuestionPool"
                    :key="question.QuestionId"
                    class="border-2 border-gray-400  bg-gray-100 p-2 p-sm-3 my-1 text-bold text-black rounded text-center borderColorOnHover"
                    :class="{ 'paddTwo': mobileView }"
                    style="cursor: pointer;"
                    @click="selectedQuestionFromPool = question"
                  >
                    <b-row
                      no-gutters
                      align-h="center"
                      align-v="center"
                      class="flex-nowrap"
                    >
                      <b-col cols="1" class="mx-2">
                        <div v-if="question.QuestionVideoUploadedVideoId" class="text-center">
                          <i
                            :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
                            class="fa fa-2x text-left fa-video"
                            style="font-size:1.2rem;"
                          />
                        </div>
                        <div v-else class="text-center">
                          <i
                            :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
                            class="fa fa-2x text-left fa-question-circle"
                            style="font-size:1.2rem;"
                          />
                        </div>
                      </b-col>
                      <b-col cols="7" style="overflow: hidden;">
                        <div class="text-left pl-2" style="white-space:pre-line;font-size:smaller;">
                          {{ question.Description }}
                        </div>
                      </b-col>
                      <b-col cols="2">
                        <div class="font-extrabold text-center" style="font-size:smaller;">
                          {{ readableTimeLimit(question.TimeLimit) }} mm:ss
                        </div>
                      </b-col>
                      <b-col cols="2">
                        <div style="margin-bottom:-5px" class="text-center">
                          <i
                            v-if="selectedQuestionFromPool && selectedQuestionFromPool.GoCandidateQuestionId == question.GoCandidateQuestionId && !loading.status && error.message != 'Successfully added question to the job'"
                            :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
                            class="col-span-1 fa fa-2x text-left fa-check mx-2"
                            style="font-size:1.2rem;cursor:pointer;"
                          />
                          <i
                            v-if="selectedQuestionFromPool && selectedQuestionFromPool.GoCandidateQuestionId == question.GoCandidateQuestionId && (loading.status || error.message == 'Successfully added question to the job')"
                            :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
                            class="fa fa-pulse fa-spinner"
                          />
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </SlideFade>
              <div v-if="error && error.status" class="text-center py-3">
                {{ error.message }}
              </div>
            </div>
          </mdb-modal-body>
          <mdb-modal-footer v-if="selectedQuestionFromPool && filteredQuestionPool.length > 0" style="display:block;">
            <b-row
              align-h="between"
              align-v="center"
              no-gutters
            >
              <b-col>
                <mdb-btn
                  v-if="!confirmingDeletePoolQuestion"
                  type="reset"
                  :disabled="(loading.status || error.message == 'Successfully added question to the job')"
                  size="sm"
                  class="btn btn-outline"
                  style="width:100px;"
                  @click="confirmingDeletePoolQuestion = true"
                >
                  DELETE
                </mdb-btn>
                <mdb-btn
                  v-else
                  size="sm"
                  class="btn btn-outline"
                  style="width:100px;"
                  @click="confirmingDeletePoolQuestion = false"
                >
                  CANCEL
                </mdb-btn>
              </b-col>
              <div v-if="confirmingDeletePoolQuestion">
                <mdb-btn
                  style="transform:scale(0.90);"
                  type="reset"
                  size="sm"
                  @click="confirmingDeletePoolQuestion = false"
                >
                  <i
                    :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
                    :disabled="loading.status"
                    class="fa fa-1x text-left fa-times"
                    style="font-size:1rem;"
                  />
                </mdb-btn>
                <mdb-btn
                  style="transform:scale(0.85);"
                  type="reset"
                  size="sm"
                  class="btn-outline"
                  @click="confirmDeletePoolQuestion()"
                >
                  <i
                    :class="{ 'smFont': mobileView, 'margRight0' : mobileView }"
                    :disabled="loading.status"
                    class="fa fa-1x text-left fa-check"
                    style="font-size:1rem;"
                  />
                </mdb-btn>
              </div>
              <div v-if="!confirmingDeletePoolQuestion">
                <mdb-btn
                  type="reset"
                  :disabled="(loading.status || error.message == 'Successfully added question to the job')"
                  size="sm"
                  style="width:100px;"
                  @click="confirmAddPoolQuestion()"
                >
                  ADD
                </mdb-btn>
              </div>
            </b-row>
          </mdb-modal-footer>
        </div>

        <!-- QUESTION SETS -->
        <div v-if="addEditQuestionModalPage === 3">
          <mdb-modal-body class="px-2 py-1 px-sm-4 py-sm-4 text-black">
            <QuestionSetModalPage
              :already-added-question-set-ids="alreadyAddedQuestionSetIds"
              @addQuestionSet="addQuestionSetToOneWaySetup($event)"
            />
          </mdb-modal-body>
        </div>

        <!--add video question modal header and body-->
        <div v-if="textOrVideoQuestionModal == 'video'">
          <mdb-modal-header>
            <b-row style="width: 90%; margin-left: 20px;" align-h="between">
              <div
                class="modal-title-container m-auto m-sm-0"
                :class="{'modal-title-underlined' : videoModalPage == 'choose'}"
                style="font-size:smaller;margin-left:-20px !important;"
              >
                <h6 :class="{'grey-text' : videoModalPage != 'choose' }" @click="videoModalPage = 'choose'">
                  <span v-show="mobileView === false">CHOOSE VIDEO</span>
                  <span v-show="mobileView" style="font-size:12px;">CHOOSE <i class="fa fa-video" /></span>
                </h6>
              </div>
              <div
                class="modal-title-container m-auto m-sm-0"
                :class="{'modal-title-underlined' : videoModalPage == 'record'}"
                style="font-size:smaller;margin-left:-20px !important;"
              >
                <h6 :class="{'grey-text' : videoModalPage != 'record' }" @click="videoModalPage = 'record'">
                  <span v-show="mobileView === false">RECORD VIDEO</span>
                  <span v-show="mobileView" style="font-size:12px;">RECORD <i class="fa fa-video" /></span>
                </h6>
              </div>
              <div
                class="modal-title-container m-auto m-sm-0"
                :class="{'modal-title-underlined' : videoModalPage == 'upload'}"
                style="font-size:smaller;margin-left:-20px !important;"
              >
                <h6 :class="{'grey-text' : videoModalPage != 'upload' }" @click="videoModalPage = 'upload'">
                  <span v-show="mobileView === false">UPLOAD VIDEO</span>
                  <span v-show="mobileView" style="font-size:12px;">UPLOAD <i class="fa fa-video" /></span>
                </h6>
              </div>
            </b-row>
          </mdb-modal-header>

          <div v-if="videoModalPage == 'choose'">
            <SlideFade>
              <ChooseVideoPage
                :default-category="'Question'"
                :role-id="role.JobId"
                :set-text-or-video-question-modal="setTextOrVideoQuestionModal"
                @handleChosenVideo="handleChosenVideo($event)"
                @backToQuestionPage="addEditQuestionModalPage = 1"
              />
            </SlideFade>
          </div>
          <div v-if="videoModalPage == 'record'">
            <SlideFade>
              <RecordVideoPage
                :set-text-or-video-question-modal="setTextOrVideoQuestionModal"
                :default-category="'Question'"
                :role-id="role.JobId"
                @handleRecordedVideo="handleRecordedQuestion"
                @backToQuestionPage="addEditQuestionModalPage = 1"
              />
            </SlideFade>
          </div>
          <div v-if="videoModalPage == 'upload'">
            <SlideFade>
              <UploadVideoPage
                :default-category="'Question'"
                :role-id="role.JobId"
                @uploadVideo="handleVideoUpload"
                @backToQuestionPage="addEditQuestionModalPage = 1"
              />
            </SlideFade>
          </div>
        </div>
      </mdb-modal>
    </div>
  </div>
</template>

<script>
import { extend } from 'vee-validate'
import draggable from 'vuedraggable'
import libraryService from '@/api/services/library.service'
import oneWayQuestionService from '@/api/services/oneWayQuestion.service'

import ChooseVideoPage from '@/components/genericModals/InsertVideoModals/ChooseVideoPage.vue'
import RecordVideoPage from '@/components/genericModals/InsertVideoModals/RecordVideoPage.vue'
import UploadVideoPage from '@/components/genericModals/InsertVideoModals/UploadVideoPage.vue'
import QuestionSetModalPage from '@/views/questionSets/questionSetModalPage'
import QuestionSet from '@/views/questionSets/questionSetComponent.vue'

import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'
import LeaveFadeExit from '@/components/UI/TransitionComponents/leaveFadeExit.vue'

import scoringService from '@/api/services/scoring.service.js'
import Tooltip from '@/components/misc/tooltip.vue'

import { useMobile } from '@/composables/useMobile'
import { useUserData, useStatus, useWorkflow } from '@/pinia'
import { storeToRefs } from 'pinia'

extend('time-limit', {
  validate: timeLimit => { return timeLimit.length > 0 },
  lazy: true,
  message: 'Please select time to answer question'
})

export default {
  name: 'Questions',
  components: {
    draggable, ChooseVideoPage, RecordVideoPage, UploadVideoPage, SlideFade, LeaveFadeExit, QuestionSetModalPage, QuestionSet, Tooltip
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const workflowStore = useWorkflow()
    const { role, selectedWorkflowStage, videoToAddToList, oneWayStageHasBeenStarted } = storeToRefs(workflowStore)

    const statusStore = useStatus()
    const { loading, error } = storeToRefs(statusStore)
    const { showGenericErrorModal, closeGenericConfirmModal, showGenericConfirmModal } = useStatus()

    const { mobileView } = useMobile()

    return {
      authData,
      userData,
      showGenericErrorModal,
      closeGenericConfirmModal,
      showGenericConfirmModal,
      role,
      selectedWorkflowStage,
      videoToAddToList,
      mobileView,
      loading,
      error,
      oneWayStageHasBeenStarted
    }
  },
  data () {
    return {
      answerTimeIsEstimate: false,
      localSetup: {},
      disableScoring: false,
      addToPool: false,
      addingFromPool: false,
      addEditQuestionModalPage: 1,
      addEditVideoQuestionModalPage: 1,
      addEditQuestionModal: false,
      answerTime: 0,
      chars: '0/550',
      confirmingDeletePoolQuestion: false,

      // this is how the practise question is referred to even if its not default

      isPractiseQuestion: false,
      isAddOrEditQuestion: 'add',
      filteredQuestionPool: [],
      questionPool: [],
      question: { Description: null, IsPool: false, NoRatings: false },
      questions: [],
      practiceQuestion: {},
      nonPracticeQuestions: [],
      qid: 0,
      questionIsTextOrVideo: 1,
      selectedQuestionFromPool: null,
      textOrVideoQuestionModal: 'text',
      waitingUpdate: false,
      questionTypeDropDownOptions: [
        { text: 'Standard Question', value: 1, selected: true },
        { text: 'Video Question', value: 2 }
      ],
      questionLength: [
        { text: '00:30', value: 30, selected: true },
        { text: '00:45', value: 45 },
        { text: '01:00', value: 60 },
        { text: '01:15', value: 75 },
        { text: '01:30', value: 90 },
        { text: '01:45', value: 105 },
        { text: '02:00', value: 120 },
        { text: '02:15', value: 135 },
        { text: '02:30', value: 150 },
        { text: '02:45', value: 165 },
        { text: '03:00', value: 180 }
        //{ text: '03:15', value: 195 },
        //{ text: '03:30', value: 210 },
        //{ text: '03:45', value: 225 },
        //{ text: '04:00', value: 240 },
        //{ text: '04:15', value: 255 },
        //{ text: '04:30', value: 270 },
        //{ text: '04:45', value: 285 },
        //{ text: '05:00', value: 300 }
      ],
      videoModalPage: 'choose',
      deletingSetId: 0,
      defaultScoringTemplateOptions: [{ text: 'No Scoring', value: 0 }],
      scoringTemplateOptions: [],
      currentTemplateId: 0,
      buttonClick: false,
      loadedQuestions: false,
      totalQuestionCount: 0
    }
  },
  computed: {
    alreadyAddedQuestionSetIds () {
      try {
        const array = []
        this.questions.forEach(q => {
          if (q.QuestionSetId !== null && q.QuestionSetId !== 0 && q.QuestionSetId !== undefined) {
            array.push(q.Questions[0].BaseQuestionSetId)
          }
        })
        return array
      } catch (err) {
        return []
      }
    },
    selectedTimeLimit () {
      return this.questionLength.filter(i => i.selected)[0].value
    },
    selectedScoringTemplateId () {
      try {
        return this.scoringTemplateOptions.filter(t => t.selected)[0].value
      } catch { return 0 }
    },
    timeLimit: function () {
      try {
        return this.questionLength.filter(e => e.selected === true)[0].value
      } catch {
        return 0
      }
    }
  },
  watch: {
    questionIsTextOrVideo: {
      handler: function () {
        if (this.questionIsTextOrVideo === 1) {
          this.videoToAddToList = {}
        }
      },
      immediate: true
    },
    nonPracticeQuestions () {
      this.answerTime = 0
      this.answerTimeIsEstimate = false
      this.nonPracticeQuestions.forEach((element, i) => {
        if (element.QuestionSetId !== null && element.QuestionSetId !== 0) {
          let questionSetTime = 0
          element.Questions.forEach(q => { questionSetTime += q.TimeLimit })
          if (element.RandomiseQuestions === true) {
            questionSetTime = questionSetTime * (element.RandomiseCount / element.Questions.length)
            this.answerTimeIsEstimate = true
          }
          this.answerTime += questionSetTime
        } else {
          this.answerTime += element.TimeLimit
        }
      })
    },
    // filter question pool against already added questions
    questionPool () {
      const currentQuestionIds = []
      this.nonPracticeQuestions.forEach(q => {
        if (q.QuestionSetId) {
          q.Questions.forEach(qsq => currentQuestionIds.push(qsq.GoCandidateQuestionId))
        } else {
          currentQuestionIds.push(q.GoCandidateQuestionId)
        }
      })
      if (this.questionPool.length > 0) {
        this.filteredQuestionPool = this.questionPool.filter(item => !currentQuestionIds.includes(item.GoCandidateQuestionId) && (!item.PractiseQuestion || !item.IsPractiseQuestion))
      } else {
        this.filteredQuestionPool = []
      }
    },
    addEditQuestionModal () {
      if (this.addEditQuestionModal === true) {
        this.populateScoringTemplateOptions()
      } else {
        this.scoringTemplateOptions = [...this.defaultScoringTemplateOptions]
      }
    }
  },
  mounted () {
    this.localSetup = { ...this.selectedWorkflowStage.OneWaySetup, Step: 3 }
    this.getStageInvitationQuestions()
  },
  methods: {
    convertTimeLimit (seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(remainingSeconds).padStart(2, '0');
      return `${formattedMinutes}:${formattedSeconds}`;
    },
    goToPrepTime () {
      this.$emit('refreshWorkflowStage')
    },
    convertQuestionList (data) {
      this.questions = []
      this.practiceQuestion = {}
      try {
        if (!data) return
        // converts question list data and splits them by set or not
        const setIds = []
        const setShells = []
        data.map((q, i) => {
          if (q.QuestionSetId !== 0 && q.QuestionSetId !== null) {
            if (!setIds.includes(q.QuestionSetId)) {
              setIds.push(q.QuestionSetId)
            }
          } else {
            setIds.push({ NonQuestionSetQuestion: q })
          }
        })

        setIds.map(id => {
          if (typeof id === 'number') {
            setShells.push({ QuestionSetId: id, QuestionSetName: '', AddScenario: false, ScenarioText: '', RandomiseCount: 0, RandomiseQuestions: false, Questions: [] })
          } else {
            setShells.push(id.NonQuestionSetQuestion)
          }
        })
        data.map(q => {
          let index
          if (q.QuestionSetId) {
            setShells.forEach((sh, i) => {
              if (sh.QuestionSetId === q.QuestionSetId) { index = i }
            })
            setShells[index] = { ...setShells[index], QuestionSetName: q.QuestionSetName, AddScenario: q.AddScenario, ScenarioText: q.ScenarioText, RandomiseCount: q.RandomiseCount, RandomiseQuestions: q.RandomiseQuestions, GoScoringTemplateId: q.GoScoringTemplateId, Questions: [...setShells[index].Questions, q] }
          }
        })
        this.totalQuestionCount = data.length
        this.questions = setShells
        this.practiceQuestion = setShells.filter(q => q.IsPractiseQuestion)[0] || {}
        this.nonPracticeQuestions = setShells.filter(q => !q.IsPractiseQuestion)
        // the question page backend doesn't actually set the step so we just set it front end
        if (this.nonPracticeQuestions.length > 0 && this.selectedWorkflowStage.Step <= 2) {
          this.selectedWorkflowStage.Step = 2
        } else if (this.nonPracticeQuestions.length == 0) this.selectedWorkflowStage.Step = 1
        // setTimeout(() => {
        //   this.$refs.scrollToDiv.scrollIntoView({ block: 'end', inline: 'nearest', behaviour: 'smooth' })
        // }, 3000)
      } catch (err) { return [] }
    },
    async getStageInvitationQuestions () {
      this.loadedQuestions = false
      try {
        const res = await oneWayQuestionService.getCurrentStageInvitationQuestions(this.localSetup.RoleWorkflowStageId)
        this.convertQuestionList(res.data)
        this.loadedQuestions = true
      } catch (err) {
        this.showGenericErrorModal()
      }
    },
    addEditQuestion () {
      if (this.isAddOrEditQuestion === 'add') {
        this.saveAddInvitationQuestion()
        return
      }
      this.saveEditInvitationQuestion()
    },
    async saveAddInvitationQuestion () {
      const JSONdata = {
        InvitationQuestionId: 0,
        Ordinal: this.totalQuestionCount + 1,
        RoleWorkflowStageId: this.localSetup.RoleWorkflowStageId,
        IsPool: this.addToPool,
        NoRatings: false,
        IsScenario: false,
        Description: this.question.Description,
        QuestionVideoUploadedVideoId: this.videoToAddToList.UploadedVideoId ? this.videoToAddToList.UploadedVideoId : null,
        TimeLimit: this.selectedTimeLimit,
        GoCandidateQuestionId: 0,
        GoScoringTemplateId: this.selectedScoringTemplateId
      }
      try {
        const res = await oneWayQuestionService.saveAddInvitationQuestion(JSONdata)
        if (res) {
          this.convertQuestionList(res.data)
          this.closeAddEditQuestionModal()
        }
        else {
          this.showGenericErrorModal({ showing: true, errorTitle: 'DUPLICATE', errorMessage: 'Failed to add question. Make sure you are not adding a duplicate?' })
        }
      } catch (err) {
        this.showGenericErrorModal()
      }
    },
    async saveEditInvitationQuestion () {
      const JSONdata = {
        InvitationQuestionId: this.question.InvitationQuestionId,
        Ordinal: this.question.Ordinal,
        RoleWorkflowStageId: this.localSetup.RoleWorkflowStageId,
        IsPool: this.addToPool,
        NoRatings: false,
        IsScenario: false,
        Description: this.question.Description,
        QuestionVideoUploadedVideoId: this.videoToAddToList.UploadedVideoId ? this.videoToAddToList.UploadedVideoId : null,
        TimeLimit: this.selectedTimeLimit,
        GoCandidateQuestionId: this.question.GoCandidateQuestionId,
        GoScoringTemplateId: this.selectedScoringTemplateId
      }
      try {
        const res = await oneWayQuestionService.saveEditInvitationQuestion(JSONdata)
        this.convertQuestionList(res.data)
        this.closeAddEditQuestionModal()
      } catch (err) {
        this.showGenericErrorModal()
      }
    },
    async addQuestionSetToOneWaySetup (questionSetId) {
      const JSONData = {
        RoleWorkflowStageId: this.localSetup.RoleWorkflowStageId,
        QuestionSetId: questionSetId
      }
      try {
        const res = await oneWayQuestionService.addQuestionSetToOneWaySetup(JSONData)
        this.convertQuestionList(res.data)
      } catch (err) {
        this.showGenericErrorModal()
      }
      this.closeAddEditQuestionModal()
    },
    async deleteQuestionSetFromOneWaySetup ({ QuestionSetId }) {
      this.deletingSetId = QuestionSetId
      this.closeGenericConfirmModal()
      const JSONData = {
        RoleWorkflowStageId: this.localSetup.RoleWorkflowStageId,
        QuestionSetId: QuestionSetId
      }
      try {
        const res = await oneWayQuestionService.deleteQuestionSetFromOneWaySetup(JSONData)
        this.convertQuestionList(res.data)
      } catch (err) {
        this.showGenericErrorModal()
      }
      this.deletingSetId = 0
    },
    charCount (type, max) {
      this.maxCharacter = max
      this.chars = type.length + '/' + max
    },
    async confirmAddPoolQuestion () {
      const JSONdata = {
        InvitationQuestionId: 0,
        Ordinal: this.totalQuestionCount + 1,
        RoleWorkflowStageId: this.localSetup.RoleWorkflowStageId,
        IsPool: true,
        NoRatings: false,
        IsScenario: false,
        Description: this.selectedQuestionFromPool.Description,
        QuestionVideoUploadedVideoId: this.selectedQuestionFromPool.QuestionVideoUploadedVideoId ? this.selectedQuestionFromPool.QuestionVideoUploadedVideoId : null,
        TimeLimit: this.selectedQuestionFromPool.TimeLimit,
        GoCandidateQuestionId: this.selectedQuestionFromPool.GoCandidateQuestionId,
        GoScoringTemplateId: this.selectedQuestionFromPool.TemplateId || null
      }
      try {
        const res = await oneWayQuestionService.addPoolQuestionToList(JSONdata)
        this.convertQuestionList(res.data)
        this.closeAddEditQuestionModal()
      } catch (err) {
        this.showGenericErrorModal()
      }
    },
    openConfirmDeleteQuestionModel (question) {
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'DELETE QUESTION',
        confirmMessage: 'Are you sure you want to delete this question from your One-Way stage setup?',
        confirmButtonMessage: 'DELETE',
        confirmButtonFunction: () => this.confirmDeleteInvitationQuestion(question.InvitationQuestionId)
      })
    },
    async confirmDeleteInvitationQuestion (id) {
      const JSONData = {
        RoleWorkflowStageId: this.localSetup.RoleWorkflowStageId,
        InvitationQuestionId: id
      }
      try {
        const res = await oneWayQuestionService.deleteInvitationQuestion(JSONData)
        this.convertQuestionList(res.data)
      } catch (err) {
        this.showGenericErrorModal()
      }
      this.closeGenericConfirmModal()
    },
    async getQuestionPool () {
      try {
        const res = await oneWayQuestionService.getQuestionPool()
        this.questionPool = res.data
      } catch (err) {
        this.showGenericErrorModal()
      }
      this.closeGenericConfirmModal()
    },
    async confirmDeletePoolQuestion () {
      this.confirmingDeletePoolQuestion = false
      try {
        const res = await oneWayQuestionService.deleteQuestionPoolQuestion(this.selectedQuestionFromPool.GoCandidateQuestionId)
        if (res.data) {
          this.questionPool = res.data
        } else {
          this.showGenericErrorModal({ errorMessage: 'This pool question is used elsewhere therefore cannot be deleted.' })
        }
      } catch (err) {
        this.showGenericErrorModal()
      }
      this.closeGenericConfirmModal()
    },
    closeAddEditQuestionModal () {
      this.addEditQuestionModalPage = 1
      this.isPractiseQuestion = false
      this.addEditQuestionModal = false
      this.textOrVideoQuestionModal = 'text'
      this.waitingUpdate = false
      this.deleteQuestionModal = false
      this.addEditQuestionModalPage = 1
      this.videoToAddToList = {}
      this.questionIsTextOrVideo === 1
      this.reset()
      this.questionTypeDropDownOptions = [
        { text: 'Standard Question', value: 1, selected: true },
        { text: 'Video Question', value: 2 }
      ]
      this.currentTemplateId = -1
    },
    readableTimeLimit (time) {
      const result = this.questionLength.filter(q => q.value === time)[0]
      if (result !== undefined) { return result.text }
      return 'unknown'
    },
    handleChosenVideo (video) {
      this.videoToAddToList = video
      this.setTextOrVideoQuestionModal('text')
    },
    async handleRecordedQuestion () {
      this.setTextOrVideoQuestionModal('text')
      const res = await libraryService.getMostRecentVid(this.userData.aid)
      this.videoToAddToList = res.data
    },
    async handleVideoUpload () {
      this.setTextOrVideoQuestionModal('text')
      const res = await libraryService.getMostRecentVid(this.userData.aid)
      this.videoToAddToList = res.data
    },
    openAddQuestionModal () {
      this.questionIsTextOrVideo = 1
      this.isAddOrEditQuestion = 'add'
      this.isPractiseQuestion = false
      this.addEditQuestionModal = true
      this.question.Description = ''
      if (this.question.Description != null) {
        this.chars = (this.question.Description.length) + '/550'
      }
      this.currentTemplateId = -1
      this.questionLength = [
        { text: '00:30', value: 30, selected: true },
        { text: '00:45', value: 45 },
        { text: '01:00', value: 60 },
        { text: '01:15', value: 75 },
        { text: '01:30', value: 90 },
        { text: '01:45', value: 105 },
        { text: '02:00', value: 120 },
        { text: '02:15', value: 135 },
        { text: '02:30', value: 150 },
        { text: '02:45', value: 165 },
        { text: '03:00', value: 180 }
        //{ text: '03:15', value: 195 },
        //{ text: '03:30', value: 210 },
        //{ text: '03:45', value: 225 },
        //{ text: '04:00', value: 240 },
        //{ text: '04:15', value: 255 },
        //{ text: '04:30', value: 270 },
        //{ text: '04:45', value: 285 },
        //{ text: '05:00', value: 300 }
      ]
      this.questionTypeDropDownOptions = [
        { text: 'Standard Question', value: 1, selected: true },
        { text: 'Video Question', value: 2 }
      ]
      this.videoModalPage = 'choose'
      this.deletingSetId = 0
      this.defaultScoringTemplateOptions = [{ text: 'No Scoring', value: 0 }]
      this.scoringTemplateOptions = []
      this.question = { Description: null, IsPool: false, NoRatings: false }
    },
    async openEditPracticeQuestionModal () {
      this.isAddOrEditQuestion = 'edit'
      const clone = Object.assign([], this.practiceQuestion)
      this.question = clone
      this.isPractiseQuestion = true
      this.questionIsTextOrVideo = this.question.IsVideoQuestion ? 2 : 1
      this.populateEditModalWithData(this.question)
      if (this.question.Description != null) {
        this.chars = (this.question.Description.length) + '/550'
      }
    },
    async openEditQuestionModal (question) {
      this.isAddOrEditQuestion = 'edit'
      this.isPractiseQuestion = false
      this.questionIsTextOrVideo = question.IsVideoQuestion ? 2 : 1
      this.question = { ...question }
      this.populateEditModalWithData(question)
      this.chars = (this.question.Description.length) + '/550'
      this.currentTemplateId = question.GoScoringTemplateId
    },
    // sets the data within the modal and then fetches the associated video file if its a video question
    async populateEditModalWithData (question) {
      if (this.questionIsTextOrVideo === 1) {
        this.questionTypeDropDownOptions = [
          { text: 'Standard Question', value: 1, selected: true },
          { text: 'Video Question', value: 2 }
        ]
      } else {
        this.questionTypeDropDownOptions = [
          { text: 'Standard Question', value: 1 },
          { text: 'Video Question', value: 2, selected: true }
        ]
      }
      this.questionLength = this.questionLength.map(q => q.value === question.TimeLimit ? { ...q, selected: true } : { ...q, selected: false })
      if (question.QuestionVideoUploadedVideo != null) {
        this.videoToAddToList = question.QuestionVideoUploadedVideo
      } else { this.videoToAddToList = {} }
      this.addToPool = question.IsPool || false
      this.addEditQuestionModal = true
    },
    reset () {
      this.question = {}
      this.questionLength = this.questionLength.map(q => { return { ...q, selected: false } })
      this.confirmingDeletePoolQuestion = false
      this.addToPool = false
      this.disableScoring = false
      this.isPractiseQuestion = false
      try {
        this.$refs.questionform?.reset()
      } catch {
        //?
      }
      this.currentTemplateId = 0
      this.questionIsTextOrVideo === 1
    },
    async setAddEditQuestionModalPage (val) {
      this.selectedQuestionFromPool = null
      if (val === 2) this.getQuestionPool()
      this.addEditQuestionModalPage = val
    },
    setTextOrVideoQuestionModal (val) {
      this.textOrVideoQuestionModal = val
    },
    async updateQuestionOrder () {
      var ids = []
      this.nonPracticeQuestions.forEach(e => {
        if (e.QuestionSetId !== null && e.QuestionSetId !== undefined && e.QuestionSetId !== 0) {
          e.Questions.forEach(q => { ids.push(q.InvitationQuestionId) })
        } else {
          ids.push(e.InvitationQuestionId)
        }
      })
      const JSONData = {
        RoleWorkflowStageId: this.localSetup.RoleWorkflowStageId,
        Ids: ids
      }
      try {
        const res = await oneWayQuestionService.updateOneWaySetupOrdinals(JSONData)
        this.convertQuestionList(res.data)
      } catch (err) {
        this.showGenericErrorModal()
      }
    },
    toggleAddToPool () {
      if (this.oneWayStageHasBeenStarted) { return }
      if (this.addToPool === true) {
        this.addToPool = false
      } else {
        this.addToPool = true
      }
    },
    async populateScoringTemplateOptions () {
      // TemplateId on the setup is -1 if scoring templates are added per question
      if (this.localSetup.TemplateId === -1) {
        const res = await this.getAllTemplates()
        let options
        // value when adding a question
        if (this.currentTemplateId === -1) {
          options = res.map(t => { return { text: t.TemplateName, value: t.TemplateId, selected: t.IsDefault } })
          // value when editing a question
        } else {
          options = res.map(t => { return { text: t.TemplateName, value: t.TemplateId, selected: t.TemplateId === this.currentTemplateId } })
        }
        this.scoringTemplateOptions = [...this.defaultScoringTemplateOptions, ...options]
        // value when editing a question that has selected no template
        if (this.currentTemplateId === 0) {
          this.scoringTemplateOptions = this.scoringTemplateOptions.map(t => { return t.value === 0 ? { ...t, selected: true } : { ...t, selected: false } })
        }
      }
    },
    async getAllTemplates () {
      try {
        const includeDeleted = true
        const res = await scoringService.getAllTemplates(includeDeleted)
        return res.data
      } catch (err) {
        this.showGenericErrorModal()
      }
    }
  }
}
</script>
