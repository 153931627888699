import axios from 'axios'
import { computed } from 'vue'
import { API_URL, API_URLV2, AUTHORIZATION_BASIC_ENCODE } from '@/common/config'
import { logout } from '@/methods/auth.js'
import { useUserData } from '@/pinia/useUserData'

export default class instance {
  constructor (apiVersion, anon = false) {
    this.checkParameters(apiVersion, anon)

    const url = computed(() => {
      if (this.apiVersion === 1) return API_URL
      else if (this.apiVersion === 2) return API_URLV2
      return API_URL
    })

    const instance = axios.create({ baseURL: url.value })

    instance.interceptors.request.use((config) => {
      const userStore = useUserData()
      const { authData } = userStore
      let token = authData.access_token

      if (!token && !this.anon)  {
        logout()
      } else if (!token && this.anon) {
        config.headers.common.Authorization = AUTHORIZATION_BASIC_ENCODE;
      } else {
        config.headers.Authorization = `Bearer ${token}`
      }

      config.headers.common['Content-Type'] = 'application/json'
      config.headers.common['Access-Control-Allow-Origin'] = url.value
      if (this.apiVersion === 2) {
        config.headers.common.Authorization = AUTHORIZATION_BASIC_ENCODE;
      }

      return config
    })

    instance.interceptors.response.use((response) => { return response },
    (error) => { if (error.response.status === 401) logout() })

    return instance
  }

  checkParameters = (apiVersion, anon) => {
    if(typeof(apiVersion) !== 'number' || apiVersion < 1 || apiVersion > 2 ) {
      throw new Error("Invalid API Version")
    } else {
      this.apiVersion = apiVersion
    }

    if(typeof(anon) !== 'boolean') {
      throw new Error("Invalid API Anonymous Parameter")
    } else {
      this.anon = anon
    }
  }
}
