<template>
  <div class="text-black rounded pt-3" style="height:fit-content;overflow-y:auto;margin-bottom:65px;overflow-x:hidden;margin-top: 70px;">
    <div ref="scrollToDiv" style="height: 1px;">
      <div style="text-align:left;padding:2px;margin-bottom:50px;">
        <validation-observer v-slot="{ invalid, reset }">
          <form
            ref="form"
            @submit.prevent="saveAddEditUser()"
            @reset.prevent="reset"
          >
            <div
              class="flex-nowrap"
              style="cursor:pointer;margin-bottom:40px;"
              @click="editProfilePicture()"
            >
              <div style="border-radius:50%;display:flex;align-items:center;" class="pPic">
                <div
                  v-if="user.ProfilePicture === '' || user.ProfilePicture === undefined || user.ProfilePicture.includes('candidate-silhouette')"
                  style="height:95px;width:95px;border-radius:50%;border:2px solid var(--pr-color);position:relative;cursor:pointer;"
                  class="p-1 d-flex align-items-center justify-content-center"
                >
                  <i class="fa fa-camera fa-2x" style="border-radius:50%;" />
                  <i class="fa fa-plus" style="position:absolute; right:25%;top:25%;transform:scale(0.75)" />
                </div>
                <div
                  v-else
                  style="border-radius:50%;border:2px solid var(--pr-color); cursor:pointer;position:relative;height:95px;width:95px"
                  class="profilePicPresent d-flex align-items-center justify-content-center p-1"
                >
                  <img :src="user.ProfilePicture" style="object-fit:cover; display:block; border-radius:50%; width:100%; height:100%;">
                  <div class="position-absolute d-flex align-items-center justify-content-center editBlock" style="background:#3333334D;height:80px;width:80px;border-radius:50%;">
                    <i class="fa fa-edit" style="color:#fff" />
                  </div>
                </div>
              </div>
            </div>
            <div class="md-form form-sm">
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="First name"
                rules="required"
              >
                <i
                  class="fas fa-user prefix primary-colour-ni"
                  style="margin-top: 6px;font-size: 1.3rem;"
                  :class="{ 'grey-text': user.Role !== 'Super User' }"
                />
                <mdb-input
                  v-model="user.FirstName"
                  :disabled="user.IsSuperUser === false"
                  type="text"
                  auto-complete="fname"
                  label="First name"
                  style="margin-left:33px;"
                  size="sm"
                  :class="{ 'disabled': user.Role === 'Super User' }"
                />
                <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                  Please provide a first name
                </div>
              </validation-provider>
            </div>
            <div class="md-form form-sm">
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="Last name"
                rules="required"
              >
                <i
                  class="fas fa-user prefix primary-colour-ni"
                  style="margin-top: 6px;font-size: 1.3rem;"
                  :class="{ 'grey-text': user.Role !== 'Super User' }"
                />
                <mdb-input
                  v-model="user.Surname"
                  :disabled="user.IsSuperUser === false"
                  type="text"
                  auto-complete="sname"
                  label="Surname"
                  style="margin-left:33px;"
                  size="sm"
                  :class="{ 'disabled': user.Role === 'Super User' }"
                />
                <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                  Please provide a surname
                </div>
              </validation-provider>
            </div>
            <div class="md-form form-sm">
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="Email (username)"
                rules="required"
              >
                <i
                  class="fas fa-envelope prefix primary-colour-ni"
                  style="margin-top: 6px;font-size: 1.3rem;"
                  :class="{ 'grey-text': user.Role !== 'Super User' }"
                />
                <mdb-input
                  v-model="user.UserName"
                  :disabled="user.IsSuperUser === false"
                  type="email"
                  auto-complete="sname"
                  label="Email (username)"
                  style="margin-left:33px;"
                  size="sm"
                  :class="{ 'disabled': user.Role === 'Super User' }"
                />
                <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                  Please provide a valid email address
                </div>
              </validation-provider>
            </div>
            <div class="md-form form-sm">
              <i class="fas fa-user prefix primary-colour-ni" style="margin-top: 6px;font-size: 1.3rem;" />
              <mdb-input
                v-model="user.JobTitle"
                type="text"
                auto-complete="jname"
                label="Job title (optional)"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form form-sm">
              <i class="fas fa-mobile prefix primary-colour-ni" style="margin-top: 6px;font-size: 1.3rem;" />
              <mdb-input
                v-model="user.PhoneNumber"
                type="text"
                auto-complete="pname"
                label="Phone number (optional)"
                style="margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form form-sm">
              <mdb-icon
                fab
                icon="linkedin primary-colour-ni"
                style="margin-top: 12px;font-size: 1.3rem;"
              />
              <mdb-input
                v-model="user.LinkedInUrl"
                type="text"
                auto-complete="lname"
                label="LinkedIn profile link (optional)"
                style="margin-top:-36px;margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form form-sm">
              <mdb-icon
                fab
                icon="twitter-square primary-colour-ni"
                style="margin-top: 12px;font-size: 1.3rem;"
              />
              <mdb-input
                v-model="user.TwitterUrl"
                type="text"
                auto-complete="tname"
                label="Twitter handle (optional)"
                style="margin-top:-36px;margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form form-sm">
              <mdb-icon
                fab
                icon="facebook-square primary-colour-ni"
                style="margin-top: 12px;font-size: 1.3rem;"
              />
              <mdb-input
                v-model="user.FacebookUrl"
                type="text"
                auto-complete="fname"
                label="Facebook link (optional)"
                style="margin-top:-36px;margin-left:33px;"
                size="sm"
              />
            </div>
            <div class="md-form form-sm">
              <mdb-icon icon="globe primary-colour-ni" style="margin-top: 12px;font-size: 1.3rem;" />
              <mdb-input
                v-model="user.CompanyWebUrl"
                type="text"
                auto-complete="wname"
                label="Company website (optional)"
                style="margin-top:-36px;margin-left:33px;"
                size="sm"
              />
            </div>

            <div v-if="userData.rle !== 'Inviter'">
              <div
                v-if="env !== 'PRODUCTION'"
                class="md-form form-sm"
              >
                <mdb-icon icon="globe primary-colour-ni" style="margin-top: 12px;font-size: 1.3rem;" />
                <mdb-input
                  v-model="authData.access_token"
                  type="text"
                  auto-complete="token"
                  label="Bearer token"
                  style="width:calc(100% - 80px);margin-top:-36px;margin-left:33px;"
                  size="sm"
                />
                <div style="float:right;margin-right: 20px;">
                  <i
                    v-if="copied === false"
                    class="fas fa-copy prefix copyTop"
                    style="margin-top:10px;font-size: 0.8rem;cursor:pointer;"
                    title="Copy to clipboard!"
                    @click="copyToClipboard(authData.access_token)"
                  />
                  <i
                    v-else
                    class="fas fa-check prefix copyTop"
                    style="margin-top:10px;font-size: 0.8rem;cursor:none;"
                  />
                </div>
              </div>
            </div>

            <div class="md-form form-sm">
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="Role"
                rules="required|user-role"
              >
                <i class="fas fa-users prefix primary-colour-ni grey-text" style="margin-top: 6px;font-size: 1.3rem;" />
                <mdb-select
                  v-model="userRoles"
                  placeholder="Role"
                  style="margin-left:33px;"
                  size="sm"
                  class="disabled"
                />
                <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                  Please select a user role
                </div>
              </validation-provider>
            </div>
            <div
              v-if="userData.rle !== 'Inviter'"
              class="md-form form-sm"
            >
              <i class="fas fa-house-user prefix primary-colour-ni" style="margin-top: 4px;font-size: 1.3rem;" />
              <mdb-select
                v-model="pages"
                placeholder="Select a page"
                label="Default landing page"
                style="margin-left:33px;"
                class="sm"
              />
            </div>
            <mdb-btn
              v-show="!buttonClick"
              type="submit"
              :disabled="invalid || userRole.length < 1"
              class="float-right"
              size="sm"
              style="width:100px;"
            >
              SAVE
            </mdb-btn>
            <mdb-btn
              v-show="buttonClick"
              class="float-right"
              size="sm"
              style="width:100px;"
            >
              <mdb-icon icon="fa fa-pulse fa-spinner" />
            </mdb-btn>
          </form>
        </validation-observer>
      </div>
    </div>

    <!--error modal-->
    <mdb-modal
      id="errorModal"
      :show="errorModal"
      @close="errorModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>{{ errorModalTitle.toUpperCase() }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          <span v-html="error" />
        </p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="closeErrorModal"
        >
          CLOSE
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

    <!-- AvatarCropper -->
    <div v-if="avatarCropperModalShowing">
      <AvatarCropper
        :loading-crop-image="loadingCropImage"
        :avatar-cropper-modal-showing="avatarCropperModalShowing"
        @setLoadingCropImage="loadingCropImage = $event"
        @handleCrop="handleCrop($event)"
        @closeAvatarCroppingModal="avatarCropperModalShowing = false"
      />
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import { extend } from 'vee-validate'
import { logout } from '@/methods/auth.js'
import { setDefaultLanding, mapDefaultLanding } from '@/utils/defaultLanding.js'
import { userLanguage } from '@/common/userLanguage'
import { ENVIRONMENT } from '@/common/config'
import userService from '@/api/services/user.service'
import logoutService from '@/api/services/logout.service'

import AvatarCropper from '@/components/AvatarCropper/avatarCropper.vue'

extend('user-role', {
  validate: userRoles => { return userRoles.length > 0 },
  lazy: true,
  message: 'Please choose a user role'
})

export default {
  name: '',
  directives: {
    uploader: {
      bind (el, binding, vnode) {
        el.addEventListener('change', e => {
          if (e.target.files[0] !== undefined) {
            vnode.context.file = e.target.files[0]
          }
        })
      }
    }
  },
  components: {
    AvatarCropper
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    return {
      authData,
      userData,
      mobileView
    }
  },
  data () {
    return {
      errorModal: false,
      errorModalTitle: '',
      error: '',
      file: '',
      hasUserData: false,
      csvText: 'Select a new image to upload',
      loadingCropImage: false,
      buttonClick: false,
      buttonModalClick: false,
      userLanguage,
      userRoles: [],
      avatarCropperModalShowing: false,
      user: [],
      pages: [],
      copied: false,
      env: ENVIRONMENT
    }
  },
  computed: {
    userRole: function () {
      var c = ''
      this.userRoles.forEach(element => {
        if (element.selected === true) {
          c = element.value
        }
      })
      return c
    },
    selectedLandingPage () {
      return this.pages.filter(page => page.selected)[0].index
    }
  },
  watch: {
    file (val) {
      this.$emit('file-chosen', val)
    }
  },
  async created () {
    this.user = {
      Id: this.userData.uid,
      FirstName: this.userData.fne,
      Surname: this.userData.sne,
      ProfilePicture: this.userData.pic,
      UserName: this.userData.une,
      JobTitle: this.userData.jtl,
      PhoneNumber: this.userData.phn,
      TwitterUrl: this.userData.twt,
      LinkedInUrl: this.userData.lnk,
      Role: this.userData.rle,
      FacebookUrl: this.userData.fbk,
      CompanyWebUrl: this.userData.www,
      IsSuperUser: this.userData.iss,
      DefaultLanding: this.userData.dlp
    }
    this.getUserRoles(this.user.Role)
    this.pages = await mapDefaultLanding(this.userData.dlp)
  },
  methods: {
    logout,
    copyToClipboard (value) {
      navigator.clipboard.writeText(value)
      this.copied = true
      setTimeout(() => { this.copied = false }, 1500)
    },
    saveAddEditUser () {
      this.buttonClick = true
      if (this.edit === false) {
        this.user.Id = ''
        this.user.Role = this.userRole
      }
      var formData = new FormData()
      formData.append('Id', this.user.Id)
      formData.append('FirstName', this.user.FirstName)
      formData.append('Surname', this.user.Surname)
      formData.append('UserName', this.user.UserName)
      formData.append('PhoneNumber', this.user.PhoneNumber)
      formData.append('Role', this.user.Role)
      formData.append('ProfilePicture', this.user.ProfilePicture)
      formData.append('JobTitle', this.user.JobTitle)
      formData.append('LinkedInUrl', this.user.LinkedInUrl)
      formData.append('TwitterUrl', this.user.Twitter)
      formData.append('FacebookUrl', this.user.FacebookUrl)
      formData.append('CompanyWebUrl', this.user.CompanyWebUrl)
      formData.append('DefaultLanding', this.selectedLandingPage)

      userService.saveAddEditUser(formData).then(() => {
        this.userData.fne = this.user.FirstName
        this.userData.sne = this.user.Surname
        this.userData.pic = this.user.ProfilePicture
        this.userData.une = this.user.UserName
        this.userData.jtl = this.user.JobTitle
        this.userData.phn = this.user.PhoneNumber
        this.userData.twt = this.user.TwitterUrl
        this.userData.lnk = this.user.LinkedInUrl
        this.userData.fbk = this.user.FacebookUrl
        this.userData.www = this.user.CompanyWebUrl
        this.userData.rl = this.user.Role
        this.userData.dlp = this.selectedLandingPage
        setDefaultLanding(this.userData.dlp)
        this.buttonClick = false
      }).catch((error) => {
        this.buttonClick = false
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred! Please check user is not already registered on Shine Go or Shine!')
      })
    },
    handleCrop (blob) {
      this.file = blob
      this.uploadProfilePicture()
    },
    async uploadProfilePicture () {
      this.buttonModalClick = true
      var formData = new FormData()
      formData.append('userName', this.userData.une)
      formData.append('userId', this.user.Id)
      formData.append('file', this.file)
      userService.profilePicture(formData).then((response) => {
        this.user.ProfilePicture = response.data
        this.file = ''
        this.userData.pic = this.user.ProfilePicture
        this.loadingCropImage = false
      }).catch((error) => {
        this.loadingCropImage = false
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
        this.file = ''
        this.buttonModalClick = false
      })
    },
    editProfilePicture () {
      this.buttonClick = false
      this.avatarCropperModalShowing = true
    },
    launchCsvPicker () {
      this.$refs.file.click()
    },
    reset () {
      this.$refs.form.reset()
    },
    errorAlert (error, msg) {
      try {
        if (msg) {
          this.errorModal = true
          this.error = msg
          return
        }
        if (error.response.data != null) {
          if (error.response.data.Message != null) {
            this.errorModal = true
            this.error = error.response.data.Message
            if (this.error === '') { this.error = 'Sorry, an error has occured!' }
          } else if (error.response.data.error_description != null) {
            this.errorModal = true
            this.error = error.response.data.error_description
            if (this.error === '') { this.error = 'Sorry, an error has occured!' }
          } else {
            this.errorModal = true
            this.error = error.response.data
            if (this.error === '') { this.error = 'Sorry, an error has occured!' }
          }
        } else {
          this.errorModal = true
          this.error = 'Sorry an error has occurred!'
        }
        if (error.response.data.Message === 'Authorization has been denied for this request.') {
          this.errorModal = true
          this.error = 'Authorization has been denied for this request [mp].'
          this.doLogOut()
        }
      } catch {
        this.errorModal = true
        this.error = 'Sorry an error has occurred!'
      }
    },
    closeErrorModal () {
      this.errorModal = false
    },
    doLogOut () {
      logoutService.logout().then(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      }).catch(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      })
    },
    getUserRoles (role) {
      switch (role) {
        case 'Inviter':
          this.userRoles = [
            { text: 'Inviter', value: 'Inviter', selected: true }
          ]
          break
        case 'User':
          this.userRoles = [
            { text: 'User', value: 'User', selected: true }
          ]
          break
        case 'Manager':
          this.userRoles = [
            { text: 'User', value: 'User' },
            { text: 'Manager', value: 'Manager', selected: true }
          ]
          break
        case 'Admin':
          this.userRoles = [
            { text: 'User', value: 'User' },
            { text: 'Manager', value: 'Manager' },
            { text: 'Admin', value: 'Admin', selected: true }
          ]
          break
        case 'Super User':
          this.userRoles = [
            { text: 'User', value: 'User' },
            { text: 'Manager', value: 'Manager' },
            { text: 'Admin', value: 'Admin' },
            { text: 'Super User', value: 'Super User', selected: true }
          ]
          break
      }
    }
  }
}
</script>
