<template>
  <div
    style="border:solid 2px #c0c0c0;overflow:auto;min-height:calc(100vh - 300px);"
  >
    <div class="grey-text">
      <p
        style="padding:10px;font-size:small;"
        class="grey-text font-weight-bold"
      >
        <span
          style="cursor:pointer;"
          @click="showModal = true"
        >
          <i class="fa fa-plus-circle" />
          Add Note
        </span>
      </p>
      <div v-if="notes.length">
        <Note
          v-for="note in notes"
          :id="note.GoCandidateNoteId"
          :key="note.GoCandidateNoteId"
          :author="note.FullName"
          :date="note.DateAdded"
          :email="note.Email"
          :notes="note.CandidateNote"
          :picture="note.ProfilePicture"
          @deleteModal="showDeleteNoteModal"
        />
      </div>
      <AddNoteModal
        v-bind="{ showModal, waitingUpdate }"
        v-on="{ cancelAddComment, addEditComment }"
        @closeModal="showModal = false"
      />
    </div>
  </div>
</template>

<script>
import { useUserData, useCandidate, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import candidateService from '@/api/services/candidate.service'
import AddNoteModal from '@/components/misc/Notes/addNoteModal'
import Note from '@/components/misc/Notes/note'

export default {
  name: '',
  components: {
    AddNoteModal,
    Note
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const candidateStore = useCandidate()
    const { candidateToEdit } = storeToRefs(candidateStore)

    const { showGenericErrorModal, showGenericConfirmModal, closeGenericConfirmModal } = useStatus()

    return {
      authData,
      candidateToEdit,
      showGenericErrorModal,
      showGenericConfirmModal,
      closeGenericConfirmModal
    }
  },
  data () {
    return {
      showModal: false,
      waitingUpdate: false,
      comment: '',
      notes: []
    }
  },
  watch: {
    candidateToEdit: {
      handler () {
        this.getNotes()
      },
      immediate: true
    }
  },
  methods: {
    async getNotes () {
      try {
        await candidateService.getCandidateNotes(this.candidateToEdit.CandidateId).then((res) => {
          this.notes = res.data
        })
      } catch (err) {
        this.showGenericErrorModal({
          showing: true,
          errorTitle: 'Error',
          errorMessage: 'We could not load your notes successfully, please try again.'
        })
      }
    },

    async addEditComment (comment) {
      this.comment = comment
      const data = { CandidateNote: comment, CandidateId: this.candidateToEdit.CandidateId }
      try {
        await candidateService.addCandidateNote(data)
      } catch (err) {
        this.showGenericErrorModal({
          showing: true,
          errorTitle: 'Error',
          errorMessage: 'We could not post your note successfully, please try again.'
        })
      }
      this.cancelAddComment()
      await this.getNotes()
    },

    showDeleteNoteModal (noteId) {
      this.showGenericConfirmModal({
        showing: true,
        confirmTitle: 'DELETE COMMENT',
        confirmMessage: 'Are you sure you want to delete this note?',
        confirmButtonMessage: 'DELETE',
        confirmButtonFunction: () => this.confirmDeleteNote(noteId)
      })
    },

    async confirmDeleteNote (noteId) {
      this.waitingUpdate = true
      try {
        await candidateService.deleteCandidateNote(noteId)
        this.closeGenericConfirmModal()
      } catch (err) {
        this.closeGenericConfirmModal()
        this.showGenericErrorModal({ showing: true })
      }
      this.cancelAddComment()
      await this.getNotes()
    },

    cancelAddComment () {
      this.waitingUpdate = false
      this.comment = ''
      this.showModal = false
    }
  }
}
</script>
