<template>
  <div class="dark-grey-text">
    <div style="text-align: center;">
      Note: <span style="font-weight: bold;">Please Select</span> will be added to your dropdown!
    </div>
    <div class="d-flex align-items-center justify-content-center flex-wrap">
      <br><br>
      <mdb-input
        v-model="newOption"
        placeholder="Dropdown Option Text"
        type="text"
        iconCode="fa-pen-line"
      />
      <button
        :disabled="newOption == ''"
        class="py-0 px-3 m-0 btn-md btn btn-primary rounded-full font-bold h-10 ml-4"
        @click="addOptionToDropdown()"
      >
        ADD TO DROPDOWN
      </button>
      <button
        class="py-0 px-3 m-0 btn-md btn btn-outline rounded-full font-bold h-10 ml-4"
        @click="clear()"
      >
        CLEAR
      </button>
    </div>
    <div
      v-if="dropdownValues.length"
      class="w-100 d-flex align-items-center justify-content-center"
    >
      <i class="fas fa-user prefix" style="margin-top: 6px;font-size: 1.3rem;" />
      <mdb-select
        v-model="dropdownValues"
        :placeholder="currentCustomFormField.Question"
        auto-complete="rel"
        style="margin-left:33px;font-size: 0.9rem;"
        size="sm"
      />
    </div>
  </div>
</template>

<script>
import { useCandidate } from '@/pinia'
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia'

export default {
  name: 'DropdownMenuDesign',
  setup () {
    const candidateStore = useCandidate()
    const { currentCustomFormField } = storeToRefs(candidateStore)

    onMounted(() => {
      currentCustomFormField.value.Type = 'dropdown'
      currentCustomFormField.value.ReadableType = 'Dropdown Menu'
    })

    const dropdownValues = ref([])
    const backendOptionsToSave = ref([])
    const newOption = ref('')

    const addOptionToDropdown = () => {
      backendOptionsToSave.value.push(newOption.value)
      const obj = { value: newOption.value, text: newOption.value }
      dropdownValues.value.push(obj)
      currentCustomFormField.value.CustomFieldInformation = JSON.stringify(backendOptionsToSave.value)
      newOption.value = ''
    }
    const clear = () => {
      dropdownValues.value = []
      backendOptionsToSave.value = []
      currentCustomFormField.value.CustomFieldInformation = null
    }

    return {
      newOption,
      clear,
      addOptionToDropdown,
      dropdownValues,
      currentCustomFormField
    }
  }
}
</script>
