<template>
  <div>
    <div v-for="(comp, index) in template.ElementArray" :key="index">
      <CompetencyBlock
        v-if="comp.CompetencyName != ''"
        :score="getCompetencyScore(index)"
        :max-score="comp.MaxScore"
        :text="comp.CompetencyName"
        :description="comp.CompetencyDescription"
        :hide-score-summary="hideScoreSummary"
      >
        <QuestionItem
          v-for="(item, index) in comp.Elements"
          :key="index"
          :question="item.ElementName"
          :slider-values="item"
          :score="getScore(item.GoScoringElementId)"
          :comment="getComment(item.GoScoringElementId)"
          :hide-comment-box="hideCommentBox"
          @setSliderScore="$emit('setSliderScore', $event)"
          @setSliderComment="$emit('setSliderComment', $event)"
        />
      </CompetencyBlock>
      <QuestionItem
        v-else
        class="py-2 px-3"
        :question="comp.Elements[0].ElementName"
        :slider-values="comp.Elements[0]"
        :score="getScore(comp.Elements[0].GoScoringElementId)"
        :comment="getComment(comp.Elements[0].GoScoringElementId)"
        :hide-comment-box="hideCommentBox"
        @setSliderScore="$emit('setSliderScore', $event)"
        @setSliderComment="$emit('setSliderComment', $event)"
      />
    </div>
  </div>
</template>

<script>
import QuestionItem from '@/components/misc/ReviewModalV2/reviewModalComponents/scoring/question/QuestionItem'
import CompetencyBlock from '@/components/misc/ReviewModalV2/reviewModalComponents/scoring/question/CompetencyBlock'

export default {
  name: 'QuestionTemplate',
  components: {
    CompetencyBlock, QuestionItem
  },
  props: {
    template: {
      type: Object,
      default: () => {}
    },
    elementScores: {
      type: Array,
      default: () => []
    },
    hideScoreSummary: {
      type: Boolean,
      default: false
    },
    hideCommentBox: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    getScore (elementId) {
      try {
        return this.elementScores.filter(s => s.GoScoringElementId === elementId)[0].Score
      } catch { return 0 }
    },
    getComment (elementId) {
      try {
        return this.elementScores.filter(s => s.GoScoringElementId === elementId)[0].Comment
      } catch { return '' }
    },
    getCompetencyScore (competencyBlockIndex) {
      const idsOfElementsInCompBlock = this.template.ElementArray[competencyBlockIndex].Elements.map(el => el.GoScoringElementId)
      let totalOfScoresInElBlock = 0
      idsOfElementsInCompBlock.map(id => this.elementScores.forEach(score => {
        if (score.GoScoringElementId === id) {
          totalOfScoresInElBlock += score.Score
        }
      }))
      return totalOfScoresInElBlock
    }
  }
}
</script>
