<template>
  <ClickOutsideDetector
    class="floatingOptionsContainer"
    :class="{ showing: showing }"
    @clickOutside="setShowing(false)"
  >
    <div
      v-for="(button, i) in buttons"
      :key="i"
      :class="`buttonOffset-${i}`"
      @click="$emit(`${button.actionEmitName}`)"
    >
      <mdb-btn
        style="padding: 5px 10px !important;width:131px;"
        class="m-0 btn "
        :class="`${button.outerButtonClass}`"
      >
        <i
          :class="button.iconClass"
          class="mr-2"
        />
        {{ button.buttonText }}
      </mdb-btn>
    </div>
  </ClickOutsideDetector>
</template>

<script>
import ClickOutsideDetector from '@/components/UI/clickOutsideDetector.vue'

export default {
  name: 'FloatingOptions',
  components: {
    ClickOutsideDetector
  },
  props: {
    buttons: {
      type: Array,
      default: () => { }
    },
    showing: {
      type: Boolean,
      default: false
    }
  },
  setup (_, { emit }) {
    const setShowing = bool => emit('showing', bool)
    // const showing = ref(true)
    // const setShowing = bool => showing.value = bool
    return {
      setShowing
    }
  }
}
</script>

<style lang="scss" scoped>
@for $i from 0 through 5 {
  $top: ($i + 0.1) * 40px;
  $transitionTime: (2 * $i + 3) * 0.05s;
  $shortTransitionTime: $i * 1.5 * 0.03s;

  .buttonOffset-#{$i} {
    transform: scale(0);
    top: 0;
    opacity: 0;
    transition: opacity 0.1s, top 0s 1s, transform 0.4s;
    background: var(--bg-color);
    height: 34px;
  }

  .floatingOptionsContainer.showing .buttonOffset-#{$i} {
    transition: all $transitionTime cubic-bezier(0, 1, 0.7, 1.15) !important;
    opacity: 1 !important;
    top: $top !important;
    transform: scale(1) !important;
    background: #fff;
  }
}

.floatingOptionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
